import React from 'react';
import PropTypes from 'prop-types';
import {capacityService} from '../../../../services/capacity.service';
import svgEdit from '../../../../sources/images/interface/edit.svg';
import svgTrash from '../../../../sources/images/interface/trash.svg';
import {ReactSVG} from 'react-svg';
import {useTranslation} from 'react-i18next';
import {localeService} from '../../../../services/locale.service';
import Modal from '../../../../components/Modal';
import EditCapacityPassport from './EditCapacityPassport';
import CreateCapacityPassport from './CreateCapacityPassport';
import {deleteCapacityPassport} from '../../../../services/passport.service';
import '../../../../styles/list-view.scss';

function CapacityPassportList({capacity}) {
    const {t} = useTranslation();
    const [passportList, setPassportList] = React.useState([]);
    const [isOpenAddPassport, openAddPassport] = React.useState(false);
    const [viewPassport, setViewPassport] = React.useState(null);
    const [editPassport, setEditPassport] = React.useState(null);


    React.useEffect(() => {
        loadPassportList();
    }, [capacity]);

    const loadPassportList = () => {
        capacityService.getCapacity(capacity.id).then(
            capacity => {
                setPassportList(capacity.passportDynamic);
            }
        );
    };

    return (
        <>
            <div className={'mt-3'}>
                <ul className={'eco-list'} style={{padding: 0}}>
                    {passportList && passportList.map((passport, i) => {
                        return <li key={i}>
                          <button className={'btn btn-link'} onClick={() => setViewPassport(passport)}><i>{passport.name}</i></button>
                            <div className={'action-panel'}>
                                <div>
                                    <button
                                        title={t('edit')}
                                        className={'btn svg-btn default'}
                                        onClick={() => setEditPassport(passport)}>
                                        <ReactSVG src={svgEdit}/>
                                    </button>
                                </div>
                                <div>
                                    <button
                                        title={t('delete')}
                                        className={'btn svg-btn ms-3 danger'}
                                        onClick={() => {
                                            if(confirm(localeService.isRussian() ? 'Вы уверены, что хотите удалить паспорт?' : 'Are you sure, you want to delete passport?')){
                                                deleteCapacityPassport(passport.id, 'PASSPORT_DYNAMIC').then(() => loadPassportList());
                                            }
                                        }}
                                    >
                                        <ReactSVG src={svgTrash}/>
                                    </button>
                                </div>
                            </div>
                        </li>;
                    })}
                </ul>
                <br/>
                <button type={'button'} className={'btn eco-btn outlined success'}
                        onClick={() => openAddPassport(true)}>{localeService.isRussian() ? 'Добавить паспорт' : 'Add Passport'}</button>
            </div>
            <hr/>
            {/*{capacity && (*/}
            {/*<CreatePassportForm*/}
            {/*capacity={capacity}*/}
            {/*isShow={isOpenAddPassport}*/}
            {/*onCancel={() => openAddPassport(false)}*/}
            {/*onCreate={() => {*/}
            {/*openAddPassport(false);*/}
            {/*loadPassportList();*/}
            {/*}}*/}
            {/*/>*/}
            {/*)}*/}
            <Modal isOpen={isOpenAddPassport} isClosable={false} footer={false}
                   title={localeService.isRussian() ? 'Конструктор паспортов' : 'Passport constructor'}>
                <CreateCapacityPassport capacityId={capacity.id}
                                        onCancel={() => openAddPassport(false)}
                                        onSuccess={() => {
                                            loadPassportList();
                                            openAddPassport(false);
                                        }}/>
            </Modal>
            <Modal isOpen={viewPassport !== null} isClosable={false} footer={false} onCancel={() => setViewPassport(null)}
                   title={''}>
                <EditCapacityPassport
                    capacity={capacity}
                    passport={viewPassport}
                    isEditionMode={false}
                    cancelFunc={() => setViewPassport(null)}
                />
            </Modal>
            <Modal isOpen={editPassport !== null} isClosable={false} footer={false} onCancel={() => setEditPassport(null)}
                   title={''}>
                <EditCapacityPassport
                    isEditionMode={true}
                    capacity={capacity}
                    passport={editPassport}
                    cancelFunc={() => setEditPassport(null)}
                />
            </Modal>
        </>
    );
}

CapacityPassportList.propTypes = {
    capacity: PropTypes.object
};

export default CapacityPassportList;
