/* eslint-disable react/prop-types */
import {Component} from 'react';
import ReactDOM from 'react-dom';

class Portal extends  Component {
    constructor(props) {
        super(props);
        let el = document.createElement('div');
        el.className = 'modal-portal';
        this.el = el;
    }

    componentDidMount() {
        document.body.appendChild(this.el);
    }

    componentWillUnmount() {
        document.body.removeChild(this.el);
    }

    render() {
        const { children } = this.props;

        return ReactDOM.createPortal(children, this.el);
    }
}

export default Portal;