import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {localeService} from '../../../services/locale.service';
import moment from 'moment';
import {dashboardService} from '../../../services/dashboard.service';
import {connect} from 'react-redux';
import {withSize} from 'react-sizeme';
import * as numeral from 'numeral';
import SelectDateMultiple from '../../../components/SelectDateMultiple';
import {analyticService} from '../../../services/analyticService';
import Loader from '../../../components/Loader';

function PivotTableView({widgetProps, datetimeFilter, size}) {
    const [dateArray, setDateArray] = useState([]);
    const [selectedDateList, setSelectedDateList] = useState([]);
    const [widgetDataSources, setWidgetDataSources] = useState(widgetProps.dataSources);
    const [isFetching, setFetching] = useState(false);
    const [isRecalculatingTargetDays, setRecalculatingTargetDays] = useState(false);
    useEffect((() => {
        const dateArray = getDateArray(datetimeFilter);
        setDateArray(dateArray);
        setFetching(true);
        dashboardService.reloadPivotTableData(widgetProps.dataSources, dateArray)
            .then(
                dataSources => setWidgetDataSources(dataSources)
            ).finally(() => setFetching(false));
    }), [datetimeFilter]);

    const getDateArray = (datetimeFilter) => {
        const dateCount = datetimeFilter.finishDate.diff(datetimeFilter.startDate, 'days') + 1;
        let date = moment(datetimeFilter.startDate).subtract(1, 'days');
        let availableDates = Array.from(Array(dateCount), () => {
            date = moment(date).add(1, 'days');
            return date;
        });
        return availableDates;
    };


    // const addTargetDay = (date) => {
    //     console.log('Target Day::', date);
    //     analyticService.pivotTableAddTargetDay(widgetDataSources, date, datetimeFilter).then(
    //         newDSList => {
    //             console.log(newDSList);
    //             setWidgetDataSources(newDSList);
    //         }
    //     );
    // };

    const calculateTargetDays = (dateList) => {
        setRecalculatingTargetDays(true);
        analyticService.pivotTableRecalculateTargetDays(widgetDataSources, dateList, datetimeFilter).then(
            newDSList => {
                console.log(newDSList);
                setWidgetDataSources(newDSList);
            }
        ).finally(() => setRecalculatingTargetDays(false));
    };

    // const removeTargetDay = (date) => {
    //     widgetDataSources.map((ds, i) => {
    //         if (ds.parameters.type === 'MAIN_DATASOURCE') {
    //             ds.dataArray.map((dsData, j) => {
    //                 if (moment.unix(dsData.date).isSame(date, 'day')) {
    //                     const newWDS = widgetDataSources;
    //                     delete newWDS[i].dataArray[j].boolean;
    //                     setWidgetDataSources(newWDS);
    //                 }
    //             });
    //         }
    //     });
    // };

    return <>
        <div className={'pivot-table'}>
            <div style={{width: size.width - 300 + 'px'}} className={'summary-table'}>
                {isFetching && <Loader waitText={'Loading data...'}/>}
                {!isFetching &&
                <table className="table" style={{margin: 0 + 'px'}}>
                    <tbody>
                    <tr>
                        <td>{localeService.isRussian() ? 'Потребители' : 'Consumers'}</td>
                        {dateArray.map((date, d) => {
                            let className = '';
                            if (selectedDateList.findIndex(el => el.isSame(date, 'day')) >= 0) {
                                className = 'selected';
                            } else if (date.get('day') === 6 || date.get('day') === 0) {
                                className = 'weekend';
                            }

                            return <td key={d}
                                       className={className}>
                                {moment(date).format('DD')}
                            </td>;
                        })}
                    </tr>
                    {widgetDataSources && widgetDataSources.map((ds, i) => {
                        if (ds) {
                            const dsProps = ds.parameters;
                            if (dsProps.type === 'MAIN_DATASOURCE') {
                                return (<tr key={'ds-' + i}>
                                    <td>{dsProps.dataSourceName}</td>
                                    {ds.dataArray && ds.dataArray.map((dsData, dIdx) => {
                                        let title = localeService.isRussian() ? 'Не готов' : 'Unready';
                                        let className = 'unready';
                                        if (dsData.count > 0) {
                                            className = 'ready';
                                            title = localeService.isRussian() ? 'Готов' : 'Ready';
                                            if (dsData.boolean && dsData.boolean !== 'error' && dsData.boolean.findIndex(el => el.value === 0) >= 0) {
                                                className += ' but_didnt';
                                                title = localeService.isRussian() ? 'Не выполнил разгрузку!' : 'Ready but did\'t';
                                            }
                                            if (dsData.boolean && dsData.boolean !== 'error' && dsData.boolean.findIndex(el => el.value === 0) < 0) {
                                                className += ' did_it';
                                                title = localeService.isRussian() ? 'Разгрузка успешно выполнена!' : 'Done';
                                            }
                                            if (dsData.boolean && dsData.boolean === 'error') {
                                                className += ' error';
                                                title = localeService.isRussian() ? 'Ошибка выполнения скрипта! Не удалось определить статус разгрузки' : 'Error! Cannot get response!';
                                            }
                                        }
                                        if (selectedDateList.findIndex(el => el.isSame(dsData.date, 'day')) >= 0) {
                                            className += ' selected';
                                        }
                                        if (moment.unix(dsData.date).get('day') === 6 || moment.unix(dsData.date).get('day') === 0) {
                                            className = 'weekend';
                                        }

                                        return <td key={'ds-data-' + dIdx}
                                                   title={title}
                                                   className={className}>{dsData.boolean && dsData.value && numeral(dsData.value.toFixed(2)).format('0.00 a')}</td>;
                                    })}

                                </tr>);
                            }
                        }
                    })
                    }
                    </tbody>

                </table>
                }
            </div>
            <div className={'summary-info'}>
                <h6>{localeService.isRussian() ? 'Сводная информация за месяц' : 'Summary of the month'}</h6>
                {!isFetching && <SelectDateMultiple disabled={isRecalculatingTargetDays} datetimeFilter={datetimeFilter}
                                                    onSetDateList={(dateList) => {
                                                        setSelectedDateList(dateList);
                                                        calculateTargetDays(dateList);
                                                        // if (result.action === 'add') {
                                                        //     addTargetDay(result.date);
                                                        // }
                                                        // if (result.action === 'remove') {
                                                        //     removeTargetDay(result.date);
                                                        // }
                                                    }}
                                                    label={localeService.isRussian() ? 'Выбрать дни' : 'Add Target days'}/>
                }
                {isRecalculatingTargetDays && <Loader waitText={localeService.isRussian() ? 'Производится расчет...' : 'Calculating target days...'}/>}

            </div>
        </div>

    </>;
}

PivotTableView.propTypes = {
    widgetProps: PropTypes.object,
    size: PropTypes.object,
    datetimeFilter: PropTypes.object
};

const mapStateToProps = state => {
    const datetimeFilter = state.dashboardReducer.datetimeFilter;
    return {datetimeFilter};
};

export default withSize()(connect(mapStateToProps, null)(PivotTableView));