import React from 'react';
import PropTypes from 'prop-types';
import {dashboardConstants} from '../../../constants/index';
import {widgetList} from './WidgetList';
import { ReactSVG } from 'react-svg';
import svgSettings from '../../../sources/images/interface/settings.svg';
import svgBasket from '../../../sources/images/interface/basket.svg';
import {deleteWidget, updateWidget} from '../../../redux/actions/dashboardActions';
import {connect} from 'react-redux';
import '../../../styles/widget.scss';
// import Modal from '../../../components/Modal';
import {localeService} from '../../../services/locale.service';
import AlertError from '../../../components/AlertError';
import {deviceService} from '../../../services/device.service';
import {capacityService} from '../../../services/capacity.service';
// import {deviceService} from '../../../services';

function Widget({widgetProps, mode, deleteWidget, updateWidget, dashboardDataSources, sizeParams}){

    const WidgetComponent = widgetList[widgetProps.widgetType];
    const [error, setError] = React.useState('');
    const [wProps, setWProps] = React.useState(null);

    React.useEffect(() => {
        if(mode === dashboardConstants.VIEW_MODE) {
          {/**
           Добавляем к источнику данных информацию об устройстве
           */}
          Promise.all(widgetProps.dataSources.map(ds => {
            return deviceService.getDeviceBySourceId(ds.id).then(
              device => {
                {/**
                 Добавляем к источнику данных информацию о нагрузке
                 */}
                return capacityService.getCapacityByVariableId(ds.sourceID).then(
                  capacity => {
                    if(capacity.result){
                      return {...ds, capacity: capacity.result, device: device};
                    }else{
                      return {...ds, device: device};
                    }
                  }
                );
              },
              (error) => {
                console.log(error);
                  if(error.errorCode === 'NOT_FOUND' && error.errorMsg === 'datasource id'){
                    setError(localeService.isRussian() ? 'Источники данных не найдены!' : 'Data Source is not found!');
                  }else{
                    setError(localeService.isRussian() ? 'Ошибка при получении источников данных!' : 'Error when getting Data Source Info!');
                  }
                return {...ds, device: null};
              }
            );
          })).then(
            dsList => {
              setWProps({...widgetProps, dataSources: dsList});
            }
          );
        }else{
            setWProps(widgetProps);
        }
    }, [widgetProps]);

    return <div className={'widget ' + mode}>
        <div className={'widget-header'} style={{height: '2rem'}}>
            <label title={widgetProps.name !=='' ? widgetProps.name : widgetProps.widgetType}>{dashboardConstants.VIEW_MODE ? widgetProps.name : (widgetProps.name !=='' ? widgetProps.name : widgetProps.widgetType)}</label>
        </div>
        {mode === dashboardConstants.EDITION_MODE &&
        <div className="widget-props-panel non-draggable-area">
            <button key={'setting' + widgetProps.id} className={'btn svg-btn default round-btn'}
                    onClick={() => {
                        if(dashboardDataSources.length > 0){
                            updateWidget({...widgetProps, mode: dashboardConstants.EDIT_SINGLE_MODE});
                        }else{
                            setError(localeService.isRussian() ?
                                'Список используемых источников данных пуст. Добавьте источники данных перейдя по одноименной вкладке.'
                                : 'Dashboard Data Source List is empty. Please add Data Sources on the "Data Sources" tab.');
                        }
                    }}>
                <ReactSVG src={svgSettings}/>
            </button>
            <button key={'close' + widgetProps.id} className={'btn svg-btn default round-btn'} onClick={() => deleteWidget(widgetProps.id)}>
                <ReactSVG src={svgBasket}/>
            </button>
        </div>
        }
        {/*<Modal isOpen={error !== ''} onCancel={() => setError('')} isClosable={false} footer={false}>*/}
            {/*<AlertError message={error} isOpen={error !== ''} onCancel={() => setError('')}/>*/}
        {/*</Modal>*/}
        {error === '' && wProps && <WidgetComponent widgetProps={wProps} mode={mode} sizeParams={sizeParams}
                         onCloseEditSingleMode = {() => updateWidget({...widgetProps, mode: dashboardConstants.EDITION_MODE})}/>}
        <AlertError isOpen={error!==''} isClosable={false} message={error}/>
    </div>;

}

Widget.propTypes = {
    widgetProps: PropTypes.object,
    mode: PropTypes.oneOf([dashboardConstants.EDITION_MODE, dashboardConstants.EDIT_SINGLE_MODE, dashboardConstants.VIEW_MODE]),
    children: PropTypes.node,
    deleteWidget: PropTypes.func,
    updateWidget: PropTypes.func,
    dashboardDataSources: PropTypes.array,
    sizeParams: PropTypes.object
};

const mapStateToProps = state => {
    const dashboardDataSources = state.dashboardReducer.dataSourceList;
    return {dashboardDataSources};
};

const mapDispatchToProps = {
    deleteWidget: deleteWidget,
    updateWidget: updateWidget
};
export default connect(mapStateToProps, mapDispatchToProps)(Widget);
