/*eslint-disable no-unused-vars*/

import React, {useEffect, useMemo, useState} from 'react';
import {Map, Placemark, Polygon, YMaps, ZoomControl} from 'react-yandex-maps';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {updateWidget} from '../../../redux/actions/dashboardActions';
import {localeService} from '../../../services/locale.service';
import {dashboardService} from '../../../services';
import '../../../styles/maps.scss';
import {ReactSVG} from 'react-svg';
import mapSVG from '../../../sources/images/interface/map.svg';
import importSVG from '../../../sources/images/interface/import_data.svg';
import windSVG from '../../../sources/images/interface/wind-arrow.svg';
import {DIRTY_CLOUD2} from '../../../constants/map.constants';
import FileInput from "../../../components/FileInput";
import {BOUNDARY_DATA_CHEL, POLYGONS_DATA_CHEL} from "../../../constants/map_clouds";
import svgWarning from "../../../sources/images/interface/warning.svg";
import moment from "moment";
import {Sankey} from "recharts";

function MapWidgetView({widgetProps, size, datetimeFilter, updateWidget}) {
    const [state, setState] = useState(null);
    const [showCloud, setShowCloud] = useState(false);
    const [showHightMap, setShowHightMap] = useState(false);
    const [fileData, setFileData] = useState(BOUNDARY_DATA_CHEL);
    const [showFileInfo, setShowFileInfo] = useState(false);

    const setAllState = (point) => {
        setState({...state, center: [point.device.lat, point.device.lng]});
    };

    const [dataSources, setDataSources] = useState(widgetProps.dataSources.map((dataSource) => {
        return {...dataSource};
    }));

    const saveMapPresets = (center, zoom) => {
        updateWidget({
            ...widgetProps, parameters: {
                ...widgetProps.parameters, presets: {
                    ...widgetProps.parameters.presets,
                    center: center,
                    zoom: zoom
                }
            }
        });
    };

    const updateCenter = (fileData) => {
        if (fileData && fileData.metadata.point_pollution_centres) {
            let lon = 0;
            let lat = 0;

            const count = Object.keys(fileData.metadata.point_pollution_centres).length;

            Object.keys(fileData.metadata.point_pollution_centres).map(pc => {
                lon += fileData.metadata.point_pollution_centres[pc].lon;
                lat += fileData.metadata.point_pollution_centres[pc].lat;
            });
            setState({zoom: 10, center: coordinatesAdapter({lat: lat / count, lon: lon / count})});
        } else {
            setState({zoom: 10, center: [55.755864, 37.617698]});
        }
    };

    useEffect(() => {
        updateCenter(fileData);
    }, []);

    // React.useEffect(() => {
    //   if (widgetProps.parameters && widgetProps.parameters.function) {
    //     Promise.all(
    //       widgetProps.dataSources.map((dataSource) => {
    //         if (dataSource.parameters) {
    //           return dashboardService.getWidgetFunctionData(dataSource.id, datetimeFilter.startDate, datetimeFilter.finishDate, widgetProps.parameters.function.functionCode, widgetProps.parameters.function.functionParams, null)
    //             .then(result => {
    //                 return {
    //                   ...dataSource,
    //                   value: (result.list && result.list[0]) ? Number.parseFloat(result.list[0].value.toFixed(2)) : null
    //                 };
    //               }
    //             );
    //         } else {
    //           return null;
    //         }
    //       })
    //     ).then(dsList => {
    //       setDataSources(dsList);
    //       let widgetPresets = widgetProps.parameters.presets;
    //       if (widgetPresets && widgetPresets.center && widgetPresets.zoom) {
    //         setState({...state, center: widgetPresets.center, zoom: widgetPresets.zoom});
    //       } else {
    //         if (dsList.length > 0) {
    //           setState({zoom: 6, center: [dsList[0].device.lat, dsList[0].device.lng]});
    //         }else{
    //           setState({zoom: 6});
    //         }
    //       }
    //     });
    //   }
    // }, [datetimeFilter]);


    const importFile = data => {
        const fileReader = new FileReader();
        fileReader.readAsText(data, "UTF-8");
        fileReader.onload = e => {
            const fData = JSON.parse(e.target.result);
            setFileData(fData);
            updateCenter(fData);
        };
    };


    const getColor = (value) => {
        let color = '#FF';
        let hex = (0x1 * Math.round(100 - (value < 1 ? 1 : value))).toString(16);
        if (hex.length == 1) {
            hex = '0' + hex;
        }
        color += hex + '00';
        return color;
    };

    let fileChecker = React.createRef();

    console.log(state);

    function coordinatesAdapter(p) {
        return [p.lon, p.lat];
    }

    return (
        <YMaps>
            <div style={{
                display: 'flex',
                position: 'absolute',
                flexDirection: 'column',
                top: '-2rem',
                left: '0.5rem',
                zIndex: 1,
                backgroundColor: 'transparent',
                borderRadius: '0.5rem'
            }}>
                <form>
                    <input type="file" ref={fileChecker} accept={'.json'} onChange={(event) => {
                        event.preventDefault();
                        importFile(event.target.files[0]);
                        event.target.value = '';
                    }}
                           style={{display: 'none'}}/>
                    <button type="button" className={'btn default svg-round-btn'}
                            style={{backgroundColor: 'white', boxShadow: '2px 2px 1rem #afafaf'}} onClick={() => {
                        fileChecker.current.click();
                    }}>
                        <ReactSVG src={importSVG}/>
                    </button>

                    {fileData && fileData.geometry?.heightmap &&
                        <button className={'ml1 btn default svg-round-btn '}
                                style={{
                                    backgroundColor: showHightMap ? '#209F85' : 'white',
                                    boxShadow: '2px 2px 1rem #afafaf'
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setShowHightMap(!showHightMap);
                                    // updateCenter(fileData);
                                }}>
                            <ReactSVG src={mapSVG}/>
                        </button>
                    }
                </form>

                {fileData && fileData.metadata &&
                    <div style={{
                        backgroundColor: 'white',
                        marginTop: '1rem',
                        padding: '1rem',
                        borderRadius: '0.5rem',
                        boxShadow: '2px 2px 1rem #afafaf'
                    }}>
                        <h3>{fileData.metadata?.pollutant}</h3>
                        <p>{moment(fileData.metadata?.datetime).format('DD/MM/yyyy HH:mm')}</p>
                        <p>{fileData.metadata?.wind_speed_kph}{fileData.metadata?.wind_speed_kph && ' км/ч'}</p>
                        <div style={{
                            transform: `rotate(${fileData.metadata?.wind_dir_deg + 180}deg)`,
                            width: '2rem',
                            height: '2rem'
                        }}>
                            <ReactSVG src={windSVG}/>
                        </div>
                    </div>
                }
            </div>


            {state &&
                <Map style={
                    {
                        width: size.width + 6,
                        height: (widgetProps.h * 100) + 6,
                        top: -36,
                        left: -3,
                        position: 'absolute'
                    }
                }
                     modules={["geoObject.addon.balloon", "geoObject.addon.hint"]}
                     state={state}>
                    <ZoomControl options={{position: {right: 10, top: 10}}}/>
                    {dataSources && dataSources.map((point, key) => {
                        return <Placemark
                            key={key}
                            onClick={() => setAllState(point)}
                            geometry={[point.device.lat/* + (key * 0.00001)*/, point.device.lng/* + (key * 0.00001)*/]}
                            options={{
                                iconColor: point.parameters.color,
                                // iconContent: (widgetProps.parameters && widgetProps.parameters.function ? (point.value) : ''),
                            }}
                            properties={{
                                iconColor: point.parameters.color,
                                iconContent: (widgetProps.parameters && widgetProps.parameters.function ? (point.value) : ''),
                                balloonContentHeader: (localeService.isRussian() ? 'Имя переменной: ' : 'Variable name: ') + point.parameters.dataSourceName,
                                balloonContentBody: (localeService.isRussian() ? 'Устройство: ' : 'Device: ') + point.device.name + '<br/>' +
                                    (widgetProps.parameters && widgetProps.parameters.function ? (widgetProps.parameters.function.functionCode + ': ' + point.value + '<br/>') : '') +
                                    (localeService.isRussian() ? 'Источник: ' : 'Source: ') + point.device.variabels.find(item => item.id == point.sourceID).capacity.name
                                //balloonContentFooter: (localeService.isRussian() ? 'Нагрузка: ' : 'Capacity: ')+point.device.variabels.find(item => item.id == point.sourceID).capacity.name
                            }}
                            modules={[
                                'geoObject.addon.balloon'
                            ]}
                        />;
                    })}

                    {/*{showCloud && DIRTY_CLOUD2.map((cloudPoints, clId) => {*/}
                    {/*  console.log('cloud'+clId, cloudPoints);*/}
                    {/*  return <Polygon key={clId} geometry={[cloudPoints]}*/}
                    {/*                  options={{*/}
                    {/*                    fillColor: '#ff0600',*/}
                    {/*                    strokeColor: '#ff000c',*/}
                    {/*                    opacity: 0.5,*/}
                    {/*                    strokeWidth: 1,*/}
                    {/*                    strokeStyle: 'solid'*/}
                    {/*                  }}/>;*/}
                    {/*})}*/}


                    {showHightMap && fileData.geometry?.heightmap.map(((hmData, i) => {
                        let cloudData = hmData.data.map((pol) => {
                            return pol.map(p => {
                                return coordinatesAdapter(p);
                            });

                        });
                        return <Polygon key={"hmData" + i} geometry={cloudData}
                                        options={{
                                            fill: true,
                                            fillColor: '#001646',//'#4172ff',
                                            strokeOpacity: 0.1,
                                            strokeColor: '#001646',
                                            opacity: hmData.value / 10000 * 20,
                                            strokeWidth: 1,
                                            strokeStyle: 'solid'
                                        }}/>;
                    }))}

                    {fileData && fileData.geometry?.pollution.map(((pData, i) => {
                        const cloudData = pData.data.map((pol) => {
                            return pol.map(p => coordinatesAdapter(p));

                        });
                        return <Polygon key={"hmData" + i} geometry={cloudData}
                                        properties={{
                                            // balloonContent: `Значение: ${pData.value}`,
                                            hintContent: `Значение: ${pData.value} мкг/м3`
                                        }}
                                        options={{
                                            fillColor: getColor(pData.value),
                                            strokeColor: getColor(pData.value),
                                            opacity: pData.value / 100 * 50,
                                            strokeWidth: 0,
                                            strokeStyle: 'solid'
                                        }}/>;
                    }))}


                    {fileData && fileData.metadata.point_pollution_centres && Object.keys(fileData.metadata.point_pollution_centres).map(pc => {
                        return <Placemark key={pc}
                                          geometry={coordinatesAdapter(fileData.metadata.point_pollution_centres[pc])}
                                          properties={{
                                              iconColor: "#279d00",
                                              iconContent: pc
                                          }}/>;
                    })}
                </Map>}
        </YMaps>
    );
}

MapWidgetView.propTypes = {
    widgetProps: PropTypes.object,
    datetimeFilter: PropTypes.object,
    size: PropTypes.object,
    updateWidget: PropTypes.func
};

const mapStateToProps = state => {
    const datetimeFilter = state.dashboardReducer.datetimeFilter;
    return {datetimeFilter};
};
const mapDispatchToProps = {
    updateWidget: updateWidget
};

export default connect(mapStateToProps, mapDispatchToProps)(MapWidgetView);
