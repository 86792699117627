import React from 'react';
import {ReactSVG} from 'react-svg';
// import svgArrowTop from '../../../sources/images/interface/arrow.svg';
import {localeService} from '../../../services/locale.service';
import svgDuplicate from '../../../sources/images/duplicate.svg';
import svgHide from '../../../sources/images/interface/hide.svg';
import svgShow from '../../../sources/images/interface/show.svg';
import svgTrash from '../../../sources/images/trash.svg';
import svgMinus from '../../../sources/images/interface/minus.svg';
import svgPlus from '../../../sources/images/interface/plus.svg';
import PropTypes from 'prop-types';

/**
 *
 * @param scenarioModel
 * @returns {*}
 * @constructor {JSX.Element}
 */
const LocalAccordion = ({
                            scenarioStatement,
                            onCreateDuplicate,
                            onMoveUp,
                            onMoveDown,
                            onRemove,
                            onDisable,
                            children,
                            hasActions,
                            actionChildrenRender,
                        }) => {
    const [showStatus, setShowStatus] = React.useState(true);
    return (
        <>
            <div className="accordion">
                <div className="accordion__header">
                    <div className="accordion__header__left">
                        <ul className="accordion__action-list">
                            <li>
                                <button
                                    className="btn svg-btn dark"
                                    // style={{transform: showStatus ? 'rotate(180deg)' : 'none'}}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowStatus(!showStatus);
                                    }}
                                >
                                    <ReactSVG src={showStatus ? svgMinus : svgPlus}/>
                                </button>
                            </li>
                            <li className="accordion__action-list__item">
                <span className="accordion__name">
                  {scenarioStatement.code}
                </span>
                            </li>
                            {scenarioStatement.disabled && (
                                <li>
                  <span className="accordion__disable__span">
                    {localeService.isRussian() ? 'Не активно' : 'Disabled'}
                  </span>
                                </li>
                            )}
                        </ul>
                    </div>
                    {hasActions ? (
                        <div className="accordion__header__right">
                            <div className="d-flex justify-content-end">
                                <div className={'me-3'} style={{display: 'flex', flexDirection: 'column'}}>
                                    <button title={localeService.isRussian() ? 'Поднять выше' : 'Move Up'}
                                        className="btn arrow-btn to-up "
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onMoveUp(scenarioStatement);
                                        }}
                                    />
                                    <button title={localeService.isRussian() ? 'Опустить ниже' : 'Move Down'}
                                        className="btn arrow-btn to-down "
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onMoveDown(scenarioStatement);
                                        }}
                                    />
                                </div>
                                <button
                                    className="btn svg-btn dark me-3"
                                    title={localeService.isRussian() ? 'Дублировать' : 'Duplicate'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onCreateDuplicate(scenarioStatement);
                                    }}
                                >
                                    <ReactSVG src={svgDuplicate}/>
                                </button>
                                <button
                                    className="btn svg-btn dark me-3"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onDisable(scenarioStatement);
                                    }}
                                >
                                    <ReactSVG
                                        src={scenarioStatement.disabled ? svgHide : svgShow}
                                    />
                                </button>
                                <button
                                    className="btn svg-btn dark me-3"
                                    onClick={() => onRemove(scenarioStatement)}
                                >
                                    <ReactSVG src={svgTrash}/>
                                </button>
                            </div>
                        </div>
                    ) : (
                        actionChildrenRender && actionChildrenRender()
                    )}
                </div>
                {showStatus && (
                    <div
                        className={`accordion__content ${
                            scenarioStatement.disabled ? 'accordion__content--disabled' : ''
                            }`}
                    >
                        {children}
                    </div>
                )}
            </div>
        </>
    );
};

LocalAccordion.propTypes = {
    scenarioStatement: PropTypes.object,
    onCreateDuplicate: PropTypes.func,
    onMoveUp: PropTypes.func,
    onMoveDown: PropTypes.func,
    onRemove: PropTypes.func,
    onDisable: PropTypes.func,
    children: PropTypes.element,
    hasActions: PropTypes.bool,
    actionChildrenRender: PropTypes.func,
};
export default LocalAccordion;
