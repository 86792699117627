export const analyticsConstants = {
  GET_SCRIPT_LIST: 'GET_SCRIPT_LIST',
  GET_TRANSFORMATION_FUNCTION_LIST: 'GET_TRANSFORMATION_FUNCTION_LIST',
  GET_ANALYTIC_FUNCTION_LIST: 'GET_ANALYTIC_FUNCTION_LIST',
  UPLOAD_FUNCTION_LIST: 'UPLOAD_FUNCTION_LIST'
};

export const analyticFunctionCodes = [
    {
      name: 'analysis-demand-response-rmse',
      code: 'ANALYSISDEMANDRESPONSERMSE',
    },
    {
      name: 'analysis-demand-response-rmse-scalar',
      code: 'ANALYSISDEMANDRESPONSERMSESCALAR',
    },
    {
      name: 'analysis-modelica-demo',
      code: 'ANALYSISMODELICADEMO',
    },
    {
      name: 'analysis-prediction-cld',
      code: 'ANALYSISPREDICTIONCLD',
    },
    {
      name: 'analysis-prediction-hw',
      code: 'ANALYSISPREDICTIONHW',
    },
    {
      name: 'analysis-prediction-ndays',
      code: 'ANALYSISPREDICTIONNDAYS',
    },
    {
      name: 'analysis-prediction-par',
      code: 'ANALYSISPREDICTIONPAR',
    },
    {
      name: 'analysis-prediction-sarima',
      code: 'ANALYSISPREDICTIONSARIMA',
    },
    {
      name: 'analysis-prediction-reg',
      code: 'ANALYSISPREDICTIONREG',
    },
    {
      name: 'autocorrelation',
      code: 'AUTOCORRELATION',
    },
    {
      name: 'correlation',
      code: 'CORRELATION',
    },
    {
      name: 'demand-response-baseline',
      code: 'DEMAND_RESPONSE_BASELINE',
    },
    {
      name: 'demand-response-baseline-applicability',
      code: 'DEMAND_RESPONSE_BASELINE_APPLICABILITY',
    },
    {
      name: 'demand-response-boolean',
      code: 'DEMAND_RESPONSE_BOOLEAN',
    },
    {
      name: 'demand-response-check',
      code: 'DEMAND_RESPONSE_CHECK',
    },
    {
      name: 'demand-response-deviation',
      code: 'DEMAND_RESPONSE_DEVIATION',
    },
    {
      name: 'demand-response-discharge',
      code: 'DEMAND_RESPONSE_DISCHARGE',
    },
    {
      name: 'demand-response-expected',
      code: 'DEMAND_RESPONSE_EXPECTED',
    },
    {
      name: 'demand-response-rrmse',
      code: 'DEMAND_RESPONSE_RRMSE',
    },
    {
      name: 'electricity-cost-calculation',
      code: 'ELECTRICITYCOSTCALCULATION',
    },
    {
      name: 'normalization',
      code: 'NORMALIZATION',
    },
    {
      name: 'peak-prediction-ml',
      code: 'PEAK_PREDICTION_ML',
    },
    {
      name: 'peak-prediction-statistical',
      code: 'PEAK_PREDICTION_STATISTICAL',
    },
    {
      name: 'prediction-holt-winters',
      code: 'PREDICTION-HOLT-WINTERS',
    },
    {
      name: 'test',
      code: 'TEST',
    },
    {
      name: 'workload_stats',
      code: 'WORKLOAD_STATS',
    }
  ]
;

export const predictionFunctionCodes = [
  {
    name: 'analysis-demand-response-rmse',
    code: 'ANALYSISDEMANDRESPONSERMSE',
  },
  {
    name: 'analysis-demand-response-rmse-scalar',
    code: 'ANALYSISDEMANDRESPONSERMSESCALAR',
  },
  {
    name: 'analysis-modelica-demo',
    code: 'ANALYSISMODELICADEMO',
  },
  {
    name: 'analysis-prediction-cld',
    code: 'ANALYSISPREDICTIONCLD',
  },
  {
    name: 'analysis-prediction-hw',
    code: 'ANALYSISPREDICTIONHW',
  },
  {
    name: 'analysis-prediction-ndays',
    code: 'ANALYSISPREDICTIONNDAYS',
  },
  {
    name: 'analysis-prediction-par',
    code: 'ANALYSISPREDICTIONPAR',
  },
  {
    name: 'analysis-prediction-sarima',
    code: 'ANALYSISPREDICTIONSARIMA',
  },
  {
    name: 'analysis-prediction-reg',
    code: 'ANALYSISPREDICTIONREG',
  },
  {
    name: 'peak-prediction-ml',
    code: 'PEAK_PREDICTION_ML',
  },
  {
    name: 'peak-prediction-statistical',
    code: 'PEAK_PREDICTION_STATISTICAL',
  },
  {
    name: 'prediction-holt-winters',
    code: 'PREDICTION-HOLT-WINTERS',
  }
  ]
;
