import PropTypes from 'prop-types';
import React, {useState} from 'react';
import '../styles/dropdown.scss';
import ClickOutside from './ClickOutside';
import {useTranslation} from 'react-i18next';

function Select({valueList, onSelect, label, isRequired, translateName, value, isUnderstroke, style, className, isDisabled, placeholder, children, withAdditionalName}) {
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState({});

    React.useEffect(() => {
        if(value){
            setSelectedValue({name: translateName ? t(value.name) : value.name});
        }
    }, []);

    return <div className={className + ' mb-3 input-group' + (isUnderstroke ? ' ' : '') + (isRequired ? ' required' : '')} style={style}>
        <div className={'input-group-text'}>
            <label>{label}</label>
        </div>
        {isDisabled && <span className={'selected-item'} onClick={() => setIsOpen(!isOpen)}>{selectedValue ? selectedValue.name : (placeholder ? <i>{placeholder}</i> : '')}</span>}
        {!isDisabled && <div className={'dropdown-container' + (isOpen ? ' open' : '')}>
            <span className={'selected-item'} onClick={() => setIsOpen(!isOpen)}>{selectedValue ? selectedValue.name : (placeholder ? <i>{placeholder}</i> : '')}</span>
            <ClickOutside onClick={() => setIsOpen(false)}>
                <div className={'dropdown' + (isOpen ? ' open' : '')} style={{maxHeight: (valueList.length * 2) + 'rem'}}>
                    <ul style={{paddingLeft: 0}}>
                        {valueList.map((el, i) => {
                            return <li key={'val-' + i} onClick={() => {
                                setSelectedValue({name: translateName ? t(el.name) : el.name});
                                onSelect(el);
                                setIsOpen(false);
                            }}><span>
                                {translateName ? t(el.name) : el.name}
                                {withAdditionalName && el.capacity && (' (' + el.capacity.name + ') ')}
                            </span></li>;
                        })}
                    </ul>
                </div>
            </ClickOutside>
        </div>}
        {children}
    </div>;
}

Select.propTypes = {
    valueList: PropTypes.array,
    onSelect: PropTypes.func,
    label:PropTypes.string,
    value:PropTypes.object,
    isRequired: PropTypes.bool,
    children: PropTypes.node,
    translateName: PropTypes.bool,
    isUnderstroke: PropTypes.bool,
    isDisabled: PropTypes.bool,
    placeholder: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.object,
    withAdditionalName: PropTypes.bool
};

Select.defaultProps = {
    translateName: false,
    isUnderstroke: true,
    isDisabled: false,
    withAdditionalName: false
};

export default Select;