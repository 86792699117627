import React from 'react';
import PropTypes from 'prop-types';
import {apiConstants, dashboardConstants} from '../../../constants';
import {localeService} from '../../../services/locale.service';
import Modal from '../../../components/Modal';
import CapacityCostConfig from './CapacityCostCreate';
import CapacityCostView from './CapacityCostView';

function CapacityCost({widgetProps, mode, closeEditSingleMode, sizeParams}) {
    return (
        <>
            {mode === dashboardConstants.VIEW_MODE &&
            <CapacityCostView widgetProps={widgetProps}/>
            }
            {/*{mode === dashboardConstants.EDITION_MODE &&*/}
            {/*<table className={'table'}>*/}
                {/*<thead>*/}
                {/*<tr>*/}
                    {/*<th>Name</th>*/}
                    {/*<th>Value</th>*/}
                {/*</tr>*/}
                {/*</thead>*/}
                {/*<tbody>*/}
                {/*{[...Array(3).keys()].map((_,i) => {*/}
                    {/*return <tr key={i}>*/}
                        {/*<td>{'parameter_' + i}</td>*/}
                        {/*<td>{}</td>*/}
                    {/*</tr>;*/}
                {/*})}*/}
                {/*</tbody>*/}
            {/*</table>*/}
            {/*}*/}

            {mode === dashboardConstants.EDITION_MODE &&
            <div className={'widget-preview'} style={{width: sizeParams.width - (12*2) , height: (70 * widgetProps.h) - 30 }}>
                {widgetProps.image && widgetProps.image.id && <img style={{maxWidth: '100%', maxHeight: '100%'}} src={apiConstants.API_URL + '/file/download?fileId=' + widgetProps.image.id}/>}
            </div>
            }
            {mode === dashboardConstants.EDIT_SINGLE_MODE &&
            <Modal isOpen={mode === dashboardConstants.EDIT_SINGLE_MODE} isClosable={false} footer={false} onCancel={() => closeEditSingleMode()} title={localeService.isRussian() ? 'Настройка виджета' : 'Edit Widget'}>
                <CapacityCostConfig widgetProps={widgetProps} onClose = {() => closeEditSingleMode()}/>
            </Modal>
            }
        </>
    );
}

CapacityCost.propTypes = {
    widgetProps: PropTypes.object,
    closeEditSingleMode: PropTypes.func,
    mode: PropTypes.oneOf([dashboardConstants.EDIT_SINGLE_MODE, dashboardConstants.EDITION_MODE, dashboardConstants.VIEW_MODE]),
    sizeParams: PropTypes.object
};

export default CapacityCost;