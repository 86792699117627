import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {RadialBar, RadialBarChart} from 'recharts';
import {dashboardConstants} from '../../../constants';
import {connect} from 'react-redux';
import {withSize} from 'react-sizeme';
import {dashboardService} from '../../../services';
import Loader from '../../../components/Loader';
import * as numeral from 'numeral';
import AddDemandParametersToFunctionWidget from './components/AddDemandParametersToFunctionWidget';

function FunctionWidgetView({widgetProps, datetimeFilter, size, analyticFunctionList}) {

    const widgetSize = {width: size.width, height: (100 * widgetProps.h) - 40};

    const widgetParams = (widgetProps.dataSources && widgetProps.dataSources[0] && widgetProps.dataSources[0].parameters) || null;
    const initialValue = [{
        name: 'bgd',
        uv: 1000,
        pv: 1000,
        fill: 'white'
    },{
        name: widgetProps ? widgetProps.dataSourceName : widgetProps.name,
        uv: 0,
        pv: 1000,
        fill: widgetParams && widgetParams.color || '#216ba5'
    }];
    const [functionResult, setFunctionResult] = useState({chartData: initialValue});
    const [isFetching, setFetching] = useState(false);

    useEffect(() => {
        const widgetParams = (widgetProps.dataSources && widgetProps.dataSources[0] && widgetProps.dataSources[0].parameters) || null;
        if(widgetParams && widgetParams.functionCode && !widgetParams.functionCode.startsWith('DEMAND')) {
            setFunctionResult({...functionResult, error: ''});
            reloadFunctionData(datetimeFilter);
        }
    },[datetimeFilter]);

    const reloadFunctionData = (datetimeFilter) => {
        setFetching(true);
        dashboardService.loadFunctionWidgetData(widgetProps.dataSources[0].id, widgetParams, datetimeFilter).then(
            functionResult => {
                applyResult(functionResult);
            }, error => {
                setFunctionResult({
                    value: initialValue,
                    error: error.errorCode + ' ' + error.errorMsg
                });
            }
        ).finally(
            () => setFetching(false)
        );
    };

    const applyResult = (result) => {
        const name = widgetParams.dataSourceName + (widgetParams.unit ? (', ' + widgetParams.unit) : '');
        const uv = (result !== undefined && result !== 'false' && result !== 'true') ? result : (result === 'false' ? 0 : 1000);
        const pv = (result !== undefined && result !== 'false' && result !== 'true') ? Math.pow(10, result.toFixed().toString().length) : 1000;
        setFunctionResult({
            chartData: [
                {
                    name: 'bgd',
                    uv: pv,
                    pv: pv,
                    fill: 'white'
                },{
                    name: name,
                    uv: uv,
                    pv: pv,
                    fill: widgetParams.color
                }],
            value: result
        });
    };

    return <div>
        {isFetching && <Loader/>}
        {!isFetching &&
        <RadialBarChart  width={size.width}
                         height={widgetSize.height} innerRadius="60%"
                        outerRadius="100%" barSize={30} data={functionResult.chartData} startAngle={220}
                        endAngle={-40}>
            <RadialBar minAngle={0} background clockWise={true} dataKey="uv" label={{ position: 'insideStart', fill: '#fff' }} />
        </RadialBarChart>}
        {!isFetching && <div className={'function-value'} style={{color: widgetParams && widgetParams.color || '#216ba5'}}>
            {functionResult.value === undefined ? '?' : (functionResult.value !== 'true' && functionResult.value !== 'false') ? numeral(functionResult.value).format('0.0 a') : functionResult.value}
        </div>}

        {widgetParams && widgetParams.functionCode && widgetParams.functionCode.startsWith('DEMAND') && analyticFunctionList && widgetProps.dataSources[0] &&
        <AddDemandParametersToFunctionWidget
            onApplyFunction={(result) => applyResult(result)}
            datetimeFilter={datetimeFilter}
            analyticFunctionList={analyticFunctionList}
            dataSource={widgetProps.dataSources[0]} />}
        <div className={'widget-header'} style={{position: 'absolute', bottom: '4px',textAlign: 'center'}}>
            <label>{widgetParams && (widgetParams.dataSourceName + (widgetParams.unit ? (', ' + widgetParams.unit) : ''))}</label>
        </div>
    </div>;
}


FunctionWidgetView.propTypes = {
    widgetProps: PropTypes.object,
    mode: PropTypes.oneOf([dashboardConstants.EDITION_MODE, dashboardConstants.VIEW_MODE]),
    size: PropTypes.object,
    datetimeFilter: PropTypes.object,
    analyticFunctionList: PropTypes.array
};

const mapStateToProps = state => {
    const datetimeFilter = state.dashboardReducer.datetimeFilter;
    const analyticFunctionList = state.analyticReducer.analyticFunctionList;
    return {datetimeFilter, analyticFunctionList};
};

export default withSize()(connect(mapStateToProps, null)(FunctionWidgetView));