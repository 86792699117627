import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import '../styles/dropdown.scss';
import ClickOutside from './ClickOutside';
import CheckBox from './CheckBox';
import {useTranslation} from 'react-i18next';

function SelectMultiple({valueList, onSelect, label, defaultValue, isRequired, isTranslateName, translatePrefix}) {
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValues, setSelectedValue] = useState([]);

    useEffect(() => {
        if (defaultValue !== undefined) {
            setSelectedValue(defaultValue);
        }
    }, []);
    const addValues = (item) => {
        const idx = selectedValues.findIndex(sub => sub.name === item.name);
        if (idx >= 0) {
            setSelectedValue(selectedValues.filter(sub => sub.name !== item.name));
            onSelect(selectedValues.filter(sub => sub.name !== item.name));
        } else {
            setSelectedValue([...selectedValues, item]);
            onSelect([...selectedValues, item]);
        }
    };

    return <div className={'input-group ' + (isRequired ? ' required' : '')} style={{height: 'auto'}}>
      <span className="input-group-text">
            <label>{label}</label>
        </span>
        <div className={'dropdown-container' + (isOpen ? ' open' : '')}>
            <span className={'selected-item'} style={{height: 'auto', minHeight: '1.8rem'}}
                  onClick={() => setIsOpen(!isOpen)}>{selectedValues && selectedValues.length > 0 ? selectedValues.map(item => isTranslateName ? t(translatePrefix ? (translatePrefix + item.name) : item.name) : item.name).join(', ') : ''}</span>
            <ClickOutside onClick={() => setIsOpen(false)}>
                <div className={'dropdown' + (isOpen ? ' open' : '')}
                     style={{maxHeight: ((valueList.length || 1) * 1.8) + 'rem', top: '100%', marginTop: '1px'}}>
                    <ul>
                        {valueList && valueList.map((el, i) => {
                            return <li key={'val-' + i}>
                                {/*<span><input type="checkbox" readOnly={true} disabled={true}*/}
                                                      {/*checked={selectedValues && selectedValues.find(item => item.name === el.name) !== undefined}/>*/}
                                {/*{el.name}*/}
                                {/*</span>*/}
                                <CheckBox onChange={() => {addValues(el);}} checked={selectedValues && selectedValues.findIndex(item => item.name === el.name) >= 0}
                                          label={isTranslateName ? t(translatePrefix ? (translatePrefix + el.name) : el.name) : el.name}/>

                            </li>;
                        })}
                    </ul>
                </div>
            </ClickOutside>
        </div>
    </div>;
}

SelectMultiple.propTypes = {
    valueList: PropTypes.array,
    onSelect: PropTypes.func,
    label: PropTypes.string,
    defaultValue: PropTypes.object,
    isRequired: PropTypes.bool,
    isTranslateName: PropTypes.bool,
    translatePrefix: PropTypes.string
};

export default SelectMultiple;