import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {localeService} from '../../services/locale.service';
import {userService} from '../../services';
import FieldView from '../../components/FieldView';
import InputText from '../../components/InputText';
import Loader from '../../components/Loader';
import AlertError from '../../components/AlertError';
import Modal from '../../components/Modal';

function Profile() {
    const {t} = useTranslation();
    const [user, setUser] = React.useState(null);
    const [isEdit, setEdit] = React.useState(false);

    React.useEffect(() => {
        userService.getCurrentUser().then(
            resp => {
                setUser(resp.user);
            }
        );
    }, []);

    return <>
        {user &&
        <div className={'row'}>
            <div className={'col-60'} style={{margin: '1rem auto', float: 'none', display: 'block'}}>
                <FieldView label={t('last_name')} value={user.last_name}/>
                <FieldView label={t('first_name')} value={user.first_name}/>
                <FieldView label={t('middle_name')} value={user.middle_name}/>
                <FieldView label={t('email')} value={user.email}/>
                <div className={'to-right'}>
                    <button className={'btn eco-btn default'} onClick={() => setEdit(true)}>{t('edit')}</button>
                </div>
            </div>
        </div>
        }
        <Modal isOpen={isEdit} onCancel={() => setEdit(false)} isClosable={false} footer={false}
               title={localeService.isRussian() ? 'Редактировать профиль' : 'Edit Profile'}>
            <EditUserForm editableUser={user} onClose={(newUser) => {
                setEdit(false);
                setUser(newUser);
            }}/>
        </Modal>
    </>;
}

function EditUserForm({editableUser, onClose}) {
    const {t} = useTranslation();
    const [user, setUser] = React.useState(editableUser);
    const [isFetching, setFetching] = React.useState(false);
    const [error, setError] = React.useState('');

    const saveUser = () => {
        setFetching(true);
        userService.editUser(user).then(
            () => onClose(user)
            ,() => setError(localeService.isRussian() ? 'Ошибка! Не удалось обновить информацию профиля!' : 'Cannot update Profile!')
        ).finally(() => setFetching(false));
    };

    return (
        <>
            {!isFetching && !error &&
            <form>
                <InputText label={t('last_name')} value={user.last_name}
                           onChange={text => setUser({...user, last_name: text})}/>
                <InputText label={t('first_name')} value={user.first_name}
                           onChange={text => setUser({...user, first_name: text})}/>
                <InputText label={t('middle_name')} value={user.middle_name}
                           onChange={text => setUser({...user, middle_name: text})}/>
            </form>
            }
            {isFetching && <Loader/>}
            <AlertError isOpen={error !== ''} message={error} isClosable={false}/>
            <hr/>
            {!isFetching &&
            <div className={'d-flex justify-content-center'}>
                {!error && <button className={'btn eco-btn success mr2'} onClick={() => saveUser()}>{t('apply')}</button>}
                <button className={'btn eco-btn default'} onClick={() => onClose(editableUser)}>{t('cancel')}</button>
            </div>
            }
        </>);
}

EditUserForm.propTypes = {
  editableUser: PropTypes.object,
  onClose: PropTypes.func
};


export default Profile;