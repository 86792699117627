import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import {localeService} from '../services/locale.service';
import ClickOutside from './ClickOutside';
import moment from 'moment';

function SelectDateRange({start, finish, onSetDateRange}) {

    const [isOpen, setOpen] = React.useState(false);
    const [startDate, setStartDate] = React.useState(start);
    const [endDate, setEndDate] = React.useState(finish);

    const onChange = dates => {
        const [begin, end] = dates;
        setStartDate(begin);
        setEndDate(end);
        if(end !== null){
            onSetDateRange(dates);
            setOpen(false);
        }
    };

    return <div className={'input-group'}>
        <div className={'input-group-text'}>
            <label>{localeService.isRussian() ? 'Выбрать диапазон дат' : 'Select Date Range'}</label>
        </div>
        <div className={'dropdown-container' + (isOpen ? ' open' : '')}>
            <span className={'selected-item'} onClick={() => setOpen(!isOpen)}>{localeService.isRussian() ? 'c' : 'from'}  {moment(startDate).format('DD-MM-YYYY')} {localeService.isRussian() ? 'по' : 'to'}  {moment(endDate).format('DD-MM-YYYY')}</span>
            <ClickOutside onClick={() => setOpen(false)}>
                <div className={'dropdown' + (isOpen ? ' open' : '')} style={{border: 'none', backgroundColor: 'transparent', boxShadow: 'none', overflowY:'hidden'}}>
                    <DatePicker
                        selected={startDate}
                        onChange={onChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        inline
                    />
                </div>
            </ClickOutside>
        </div>

    </div>;
}

SelectDateRange.propTypes = {
    start: PropTypes.object,
    finish: PropTypes.object,
    onSetDateRange: PropTypes.func
};

export default SelectDateRange;