/* eslint-disable no-unused-vars */
import React from 'react';
import { useDrop } from 'react-dnd';
import  { ItemTypes }  from '../../constants/DragDropItemTypes';
import PropTypes from 'prop-types';


// const style = {
//     height: '12rem',
//     width: '12rem',
//     marginRight: '1.5rem',
//     marginBottom: '1.5rem',
//     color: 'white',
//     padding: '1rem',
//     textAlign: 'center',
//     fontSize: '1rem',
//     lineHeight: 'normal',
//     float: 'left',
// }
function DropZone({children}){
    const [{ canDrop, isOver }, drop] = useDrop({
        accept: ItemTypes.DASHBOARD_ITEM,
        drop: () => ({ name: 'DropZone' }),
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });
    return (
        <div ref={drop} className={'drop-zone'}>
            {children}
        </div>
    );
}


DropZone.propTypes = {
    children: PropTypes.node
};

export default DropZone;
