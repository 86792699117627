import React from 'react';
import PropTypes from 'prop-types';
import {localeService} from '../../../../../services/locale.service';
import Select from '../../../../../components/Select';
import InputText from '../../../../../components/InputText';
import ColorPicker from '../../../../../components/ColorPicker';
import AddAnalyticParametersToChart from '../../../../../components/AddAnalyticParametersToChart';
import {useTranslation} from 'react-i18next';

function Modelica({setSelectedFunction, selectedFunction, dataSources, datetimeFilter}) {

  React.useState(() => {
    console.log(selectedFunction, selectedFunction);
    console.log(dataSources, datetimeFilter);
    setSelectedFunction(selectedFunction);
  });

  const {t} = useTranslation();

  return (
    <>
      <h5>Modelica Testing</h5>
      <Select label={localeService.isRussian() ? 'Источник данных' : 'Data Source'}
              placeholder={localeService.isRussian() ? 'Выберите источник данных' : 'Select data source'}
              valueList={dataSources.map(ds => {
                return {id: ds.id, name: ds.parameters.dataSourceName};
              })}
              onSelect={(ds) => setSelectedFunction({
                ...selectedFunction,
                parameters: {...selectedFunction.parameters, dataSource: ds}
              })}
              defaultValue={dataSources.length === 1 ? {
                id: dataSources[0].id,
                name: dataSources[0].parameters.dataSourceName
              } : null}/>
      <div style={{padding: 0, display: 'flex', width: '100%', position: 'relative'}}>
        <InputText label={t('name')}
                   value={selectedFunction.parameters.dataSourceName}
                   onChange={text => setSelectedFunction({
                     ...selectedFunction,
                     parameters: {...selectedFunction.parameters, dataSourceName: text}
                   })}/>
        <ColorPicker style={{marginLeft: '1rem'}} color={selectedFunction.parameters.color}
                     setColor={(color) =>
                       setSelectedFunction({
                         ...selectedFunction,
                         parameters: {...selectedFunction.parameters, color: color}
                       })}/>
      </div>

      {selectedFunction && selectedFunction.args && selectedFunction.args.length > 0 &&
      <AddAnalyticParametersToChart datetimeFilter={datetimeFilter} demandFunction={selectedFunction}
                                    onChangeParam={func => {
                                      setSelectedFunction({...selectedFunction, ...func});
                                    }}/>
      }

      <InputText label={'outputsCount'}
                 value={selectedFunction.outputsCount}
                 onChange={text => setSelectedFunction({
                   ...selectedFunction,
                   outputsCount: text
                 })}/>

      {/*{selectedFunction.sourceType === 'TRANSFORMATION_FUNCTION' &&*/}
      {/*<div style={{marginTop: '20px'}}>{*/}
        {/*selectedFunction.functionParams.map((param, i) => {*/}
          {/*return (<InputText key={'funcParam' + i}*/}
                             {/*label={selectedFunction.code === 'HOLT_WINTERS' ?*/}
                               {/*(t('holtWintersArgs.' + i))*/}
                               {/*: ((localeService.isRussian() ? 'Параметр ' : 'Parameter ') + (i + 1))}*/}
            {/*// isDecimal={true}*/}
                             {/*value={param}*/}
                             {/*onChange={(text) => {*/}
                               {/*let params = selectedFunction.functionParams;*/}
                               {/*params[i] = text;*/}
                               {/*setSelectedFunction({*/}
                                 {/*...selectedFunction,*/}
                                 {/*functionParams: params*/}
                               {/*});*/}
                             {/*}}*/}
          {/*/>);*/}
        {/*})*/}
      {/*}*/}
      {/*</div>*/}
      {/*}*/}
    </>
  );
}

Modelica.propTypes = {
  setSelectedFunction: PropTypes.func,
  datetimeFilter: PropTypes.object,
  selectedFunction: PropTypes.object,
  dataSources: PropTypes.array
};

export default Modelica;