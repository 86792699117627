import PropTypes from 'prop-types';
import React, {useState} from 'react';
import '../styles/dropdown.scss';
import ClickOutside from './ClickOutside';
import {useTranslation} from 'react-i18next';

function CustomSelect({
                          valueList,
                          onSelect,
                          label,
                          children,
                          translateName,
                          value,
                          isRequired
                      }) {
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState(false);


    return (
        <div className="scenario-custom">
            {label && (
                <label
                    style={{
                        maxWidth: '200px',
                        minWidth: '50px',
                        fontWeight: 'bold',
                        color: '@default-text-color',
                        padding: '0 0.5rem',
                        marginRight: '1rem',
                        borderRadius: '5px',
                        backgroundColor: '#ececec ',
                    }}
                >
                    {label}
                </label>
            )}
            <div
                className={
                    'dropdown-container custom-container' + (isOpen ? ' open' : '')
                }
            >
        <span
            className={'selected-item dropdown-selected-item-custom'}
            style={{
                width: '100%',
                textAlign: 'center',
                fontWeight: 'bold',
                color: '@default-text-color',
                padding: '6px 10px',
                borderRadius: '5px',
                backgroundColor: '#ececec '
            }}
            onClick={() => setIsOpen(!isOpen)}
        >
             <input
                 style={{opacity: '0', position: 'absolute', width: 1}}
                 value={value}
                 required={isRequired}
             />
            {value ? value.name : ''}
        </span>
                <ClickOutside onClick={() => setIsOpen(false)}>
                    <div
                        className={'dropdown' + (isOpen ? ' open' : '')}
                        style={{
                            maxHeight: valueList.length * 2 + 'rem',
                            width: '300px',
                        }}
                    >
                        <ul>
                            {valueList.map((el, i) => {
                                return (
                                    <li
                                        key={'val-' + i}
                                        onClick={() => {
                                            onSelect(el);
                                            setIsOpen(false);
                                        }}
                                    >
                                        <span>{translateName ? t(el.name) : el.name}</span>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </ClickOutside>
            </div>
            {children}
        </div>
    );
}

CustomSelect.propTypes = {
    valueList: PropTypes.array,
    onSelect: PropTypes.func,
    label: PropTypes.string,
    value: PropTypes.object,
    isRequired: PropTypes.bool,
    children: PropTypes.node,
    translateName: PropTypes.bool,
    color: PropTypes.string
};

CustomSelect.defaultProps = {
    translateName: false,
    color: 'black'
};

export default CustomSelect;
