import React, {useEffect, useState} from 'react';
import {localeService} from '../../services/locale.service';
import ReactGridLayout from 'react-grid-layout';
// import WidgetCarousel from './WidgetCarousel';
import '../../styles/dashboard.scss';
import '../../../node_modules/react-grid-layout/css/styles.css';
import '../../../node_modules/react-resizable/css/styles.css';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import DropZone from './DropZone';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
    clearDashboard,
    setDataSourceList,
    setEditedWidgets,
    updateDashboardList,
    updateWidgetsPosition
} from '../../redux/actions/dashboardActions';
import {SizeMe} from 'react-sizeme';
import {dashboardConstants} from '../../constants';
import Widget from './widgets/Widget';
import Modal from '../../components/Modal';
import InputText from '../../components/InputText';
import {useTranslation} from 'react-i18next';
import {capacityService, dashboardService} from '../../services';
import Loader from '../../components/Loader';
import {Link, useHistory} from 'react-router-dom';
import AlertError from '../../components/AlertError';
import {uploadFunctionList} from '../../redux/actions/analyticsActions';
import {analyticService} from '../../services/analyticService';
import AlertSuccess from '../../components/AlertSuccess';
import SelectDashboardDatasources from './SelectDashboardDatasources';
import WidgetList from './WidgetList';
import {canDo} from '../../services/permitions.service';
// import SetDashboardAccessible from './components/SetDashboardAccessible';
import {ReactSVG} from 'react-svg';
import svgDelete from '../../sources/images/trash.svg';
import svgSave from '../../sources/images/interface/save.svg';
import {deviceService} from '../../services/device.service';

function EditDashboard({
                           dataSourceList,
                           dashboardId, setEditedWidgets, widgetList, clearDashboard,
                           updateWidgetsPosition, uploadFunctionList, setDataSourceList, updateDashboardList
                       }) {
    const history = useHistory();
    const {t} = useTranslation();
    const [isBlocked, setBlocked] = useState(false);
    const [dashboardName, setDashboardName] = useState('');
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [isDeleting, setDeleting] = useState(false);
    const [error, setError] = useState('');
    const [dashboard, setDashboard] = useState(null);
    const [isDeleted, setDeleted] = useState(false);

    const [page, setPage] = useState('data_sources');

    const [createDashboardForm, setCreateDashboardForm] = useState({
        isOpen: false,
        isFetching: false,
        newDashboardID: null,
        error: ''
    });

    const deleteDashboard = () => {
        setDeleting(true);
        dashboardService.deleteDashboard(dashboardId).then(
            () => {
                dashboardService.getDashboardList()
                    .then((dbList) => {
                        updateDashboardList(dbList);
                    });
                setDeleted(true);
            }, error => {
                setError((error.errorCode || '') + ' ' + (error.errorMsg || '') + '');
            }
        ).finally(() => {
            setDeleting(false);
        });
    };

    useEffect(() => {
        // setFetchingWidgetList(true);
        clearDashboard();
        analyticService.getFunctionList().then(
            functionList => {
                uploadFunctionList(functionList);
            }
        );
        dashboardService.getDashboard(dashboardId).then(
            dashboard => {
                setDashboard(dashboard);
                setDashboardName(dashboard.name);
                dashboardService.getDashboardWidgetList(dashboardId)
                    .then(
                        widgetList => {
                            setEditedWidgets(widgetList.map(w => {
                                return {
                                    ...w, dataSources: w.dataSources.map(ds => {
                                        return {...ds, name: ds.parameters.dataSourceName};
                                    })
                                };
                            }));
                            const dsList = [];
                            widgetList.map(w => {
                                w.dataSources.map(ds => {
                                    if (dsList.findIndex(el => el.sourceID === ds.sourceID) < 0) {
                                        dsList.push(ds);
                                    }
                                });
                            });

                          Promise.all(dsList.map(ds => {
                            if(ds.sourceID && ds.sourceID !== '0' && !ds.capacity){
                              return capacityService.getCapacityByVariableId(Number.parseInt(ds.sourceID)).then(
                                capResult => {
                                  const capacity = capResult.result;
                                  return deviceService.getVariablesByCapacity(capacity.id).then(
                                    capacityVariables => {
                                      let sourceVariable = capacityVariables.find(el => el.id === Number.parseInt(ds.sourceID));
                                      return {...sourceVariable, capacity: capacity};
                                    }
                                  );
                                }
                              );
                            }else{
                              return ds;
                            }
                          })).then(dsFullList => {
                            setDataSourceList(dsFullList);
                            console.log('dsFullList', dsFullList);
                          });

                            // Promise.all(dsList.map(ds => {
                            //     return capacityService.getCapacityByVariableId(ds.id).then(
                            //         capacity => {
                            //             console.log('capacity', capacity);
                            //             return {...ds, capacity: capacity.result};
                            //         });
                            // })).then(dsFullList => {
                            //     console.log('dsFullList', dsFullList);
                            //     setDataSourceList(dsFullList);
                            // });
                        }
                    );
            }
        );
    }, [dashboardId]);

    useEffect(() => {
        if (widgetList.findIndex(el => el.mode === dashboardConstants.EDIT_SINGLE_MODE) >= 0) {
            setBlocked(true);
        } else {
            setBlocked(false);
        }
    }, [widgetList]);


    const saveDashboard = (e) => {
        e.preventDefault();
        setCreateDashboardForm({...createDashboardForm, isFetching: true});
        dashboardService.editDashboard({
            id: dashboardId,
            name: dashboard.name,
            newName: dashboardName,
            widgetList: widgetList
        })
            .then(resp => {
                if (resp.dashboardID && resp.widgetResults.findIndex(el => el.status === 'FAIL') < 0) {
                    setCreateDashboardForm({
                        ...createDashboardForm,
                        newDashboardID: resp.dashboardID,
                        isFetching: false
                    });
                } else {
                    setCreateDashboardForm({
                        ...createDashboardForm,
                        isFetching: false,
                        error: localeService.isRussian() ? 'Не удалось сохранить дашборд!' : 'Cannot save dashboard'
                    });
                }
            }, error => {
                setCreateDashboardForm({...createDashboardForm, isFetching: false, error: JSON.stringify(error)});
            });
    };


    const closeSavingForm = () => {
        setCreateDashboardForm({
            isOpen: false,
            isFetching: false,
            newDashboardID: null,
            error: ''
        });
    };

    return (<>
            {!canDo('ROLE_EDIT_DASHBOARD') &&
            <div className={'page-header pt-5 mb-5'}>
                <div className={'container-700'}>
                    <h3>{localeService.isRussian() ? 'У Вас недостаточно прав для редактирования дашбордов!' : 'You have no access to edit dashboards!'}</h3>
                </div>
            </div>
            }
            {canDo('ROLE_EDIT_DASHBOARD') &&
            <>
                <AlertSuccess isOpen={isDeleted} onCancel={() => history.push('/dashboards')}
                              style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  flexDirection: 'column',
                                  alignItems: 'center'
                              }}
                              message={localeService.isRussian() ? 'Дашборд успешно удален   ' : 'The dashboard is successfully deleted   '}
                              isClosable={false}/>
                {!isDeleted &&
                <div className={'divided-page page-2-1'}>
                    <div className={'divided-page-header'} style={{minHeight: '12rem'}}>
                        <div className={'main-content'}>
                            <div className={'d-flex justify-content-between'}>
                                <h2 className={'mb-5'}>{localeService.isRussian() ? 'Редактирование дашборда' : 'Edit Dashboard'}</h2>
                                <div>
                                    <button className={'btn eco-btn default'}
                                            onClick={e => {
                                                e.preventDefault();
                                                history.goBack();
                                            }
                                            }>
                                        {localeService.isRussian() ? 'Назад к просмотру дашборда' : 'Back to Dashboard'}
                                    </button>
                                </div>
                            </div>
                            <div className={'d-flex justify-content-between'}>
                                <ul className="nav nav-pills">
                                    <li className="nav-item">
                                        <button className={'nav-link ' + (page === 'data_sources' ? 'active' : '')}
                                                onClick={() => setPage('data_sources')}>
                                            {localeService.isRussian() ? 'Источники данных' : 'Select Data Sources'}</button>
                                    </li>
                                    <li className="nav-item">
                                        <button className={'nav-link ' + (page.includes('widgets') ? 'active' : '')}
                                                onClick={() => {
                                                    setPage('widgets_info');
                                                }}>
                                            {localeService.isRussian() ? 'Виджеты' : 'Widgets'}</button>
                                    </li>
                                </ul>
                                <div className={'d-flex'}>
                                    {(canDo('ROLE_ADD_DELETE_DASHBOARD') || canDo('ROLE_DELETE_DASHBOARD')) &&
                                    <button className={'btn eco-btn danger'}
                                            title={localeService.isRussian() ? 'Удалить дашборд' : 'Remove Dashboard'}
                                            onClick={e => {
                                                e.preventDefault();
                                                setConfirmDelete(true);
                                            }}>
                                        <ReactSVG src={svgDelete}/>
                                    </button>
                                    }
                                    {/*{dashboard && <SetDashboardAccessible dashboard={dashboard}/>}*/}
                                    <button className={'ms-3 btn eco-btn success'}
                                            onClick={() => setCreateDashboardForm({
                                                ...createDashboardForm,
                                                isOpen: true
                                            })}><ReactSVG src={svgSave}/>
                                        <span>{localeService.isRussian() ? 'Сохранить изменения' : 'Apply changes'}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className={'info-content'}>
                            {page === 'data_sources' &&
                            <div className={'help-block'}>
                                <p>Перед тем, как создать дашборд необходимо выбрать источники данных.</p>
                                <p>Для этого выберите нужный источник из списка и отметьте необходимые переменные
                                    устройств.</p>
                            </div>}
                            {(page === 'widgets_info' || page === 'widgets_manage') &&
                            <div className={'help-block'}>
                                <p>Для создания дашборда перетаскивайте необходимые виджеты в левую часть экрана.
                                    Виджеты
                                    можно масштабировать и изменять их положение. При наведении на виджет доступно его
                                    редактирование.</p>
                            </div>}
                        </div>
                    </div>
                    <div className={'divided-page-body'}>
                        <DndProvider backend={HTML5Backend}>
                            <div className={'main-content'}>
                                {page === 'data_sources' && <SelectDashboardDatasources newStyle={true}/>}
                                {(page === 'widgets_info' || page === 'widgets_manage') &&
                                <SizeMe>{({size}) =>
                                    <DropZone>
                                        <ReactGridLayout className="dashboard-grid" layout={widgetList} cols={6}
                                                         rowHeight={70}
                                                         onLayoutChange={(items) => {
                                                             updateWidgetsPosition(items);
                                                         }}
                                                         isDraggable={!isBlocked}
                                                         width={size.width} margin={[4, 4]}>
                                            {widgetList.map(widget => {
                                                return <div key={widget.id} data-grid={widget}>
                                                    <Widget mode={widget.mode} widgetProps={widget}
                                                            sizeParams={{
                                                                w: widget.w,
                                                                h: widget.h,
                                                                width: (size.width / 6 * widget.w),
                                                                height: (70 * widget.h)
                                                            }}
                                                    />
                                                </div>;
                                            })}
                                        </ReactGridLayout>
                                    </DropZone>}</SizeMe>
                                }
                            </div>
                            <div className={'info-content'}>
                                {page === 'data_sources' &&
                                <div className={'d-flex flex-column align-items-start'}>
                                    <b className={'step mb-5'}>3. {localeService.isRussian() ? 'Источники данных дашборда' : 'Dashboard Data Sources'}</b>
                                    {dataSourceList.map(el => {
                                        if(el.capacity)
                                        return <p key={el.id} className={'datasource-info'}>
                                            {el.name}
                                            <p style={{opacity: 0.5}} className={'help-text'}><b>{t('capacity')}: </b>{el.capacity.name}</p>
                                        </p>;
                                    })}
                                </div>}
                                {page.includes('widgets') && <WidgetList type={page}/>}
                            </div>
                        </DndProvider>
                    </div>
                    <Modal isOpen={createDashboardForm.isOpen}
                           isClosable={false}
                           footer={false}
                           title={localeService.isRussian() ? 'Редактирование дашборда' : 'Edit Dashboard'}>
                        <form>
                            <InputText label={localeService.isRussian() ? 'Название дашборда' : 'Dashboard Name'}
                                       value={dashboardName} onChange={(text) => setDashboardName(text)}/>
                            {!createDashboardForm.isFetching && !createDashboardForm.newDashboardID && !createDashboardForm.error &&
                            <div className={'d-flex justify-content-center mt-5'}>
                                {dashboardName !== '' && <button className={'btn eco-btn success'}
                                                                 onClick={(e) => saveDashboard(e)}>{t('save')}</button>}
                                <button className={'btn eco-btn danger'}
                                        onClick={() => closeSavingForm()}>{t('cancel')}</button>
                            </div>}
                            <AlertError isOpen={createDashboardForm.error !== ''} message={createDashboardForm.error}
                                        onCancel={() => closeSavingForm()}
                            />
                            {createDashboardForm.isFetching && <Loader
                                waitText={localeService.isRussian() ? 'Сохранение дашборда...' : 'Saving dashboard...'}/>}
                            {!createDashboardForm.isFetching && createDashboardForm.newDashboardID &&
                            <div>
                                <div
                                    className={'to-center'}>{localeService.isRussian() ? 'Дашборд успешно создан!' : 'Dshboard is successfully created!'}
                                </div>
                                <div className={'d-flex justify-content-center mt-5'}>
                                    <Link to={'/dashboard/' + createDashboardForm.newDashboardID}>
                                        <button type="button" className={'btn eco-btn success'}>Ok</button>
                                    </Link>
                                </div>
                            </div>
                            }
                        </form>
                    </Modal>

                    <Modal isOpen={confirmDelete}
                           isConfirm={true}
                           title={localeService.isRussian() ? 'Удаление дашборда' : 'Removing Dashboard'}
                           onCancel={() => setConfirmDelete(false)}
                           width={'400px'}
                           onSubmit={() => deleteDashboard()}>
                        {!isDeleting && localeService.isRussian() ? 'Вы уверенны, что хотите удалить дашборд?' : 'Are you sure, you want to delete Dashboard?'}
                        {isDeleting &&
                        <Loader
                            waitText={localeService.isRussian() ? 'Удаление дашборда...' : 'Deleting dashboard...'}/>}
                        <AlertError isOpen={error !== ''} isClosable={false} message={error}/>
                    </Modal>
                </div>}

                {/*{!isDeleted &&*/}
                {/*<>*/}
                {/*<nav className="page-header">*/}
                {/*<ul className="nav-left">*/}
                {/*<li><h5>{localeService.isRussian() ? 'Редактирование дашборда' : 'Edit Dashboard'}</h5></li>*/}
                {/*</ul>*/}
                {/*<ul className="nav-right">*/}
                {/*<li>*/}
                {/*<button className={'btn eco-btn default'}*/}
                {/*onClick={e => {*/}
                {/*e.preventDefault();*/}
                {/*history.goBack();*/}
                {/*}*/}
                {/*}>*/}
                {/*{localeService.isRussian() ? 'Назад к просмотру дашборда' : 'Back to Dashboard'}*/}
                {/*</button>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*<button className={'btn eco-btn success'} onClick={() => setCreateDashboardForm({*/}
                {/*...createDashboardForm,*/}
                {/*isOpen: true*/}
                {/*})}>{localeService.isRussian() ? 'Сохранить дашборд' : 'Save Dashboard'}</button>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*<button className={'btn eco-btn danger'}*/}
                {/*onClick={e => {*/}
                {/*e.preventDefault();*/}
                {/*setConfirmDelete(true);*/}
                {/*}*/}
                {/*}>*/}
                {/*{localeService.isRussian() ? 'Удалить дашборд' : 'Remove Dashboard'}*/}
                {/*</button>*/}
                {/*</li>*/}
                {/*</ul>*/}
                {/*</nav>*/}
                {/*<DndProvider backend={HTML5Backend}>*/}
                {/*<div className={'container'}>*/}
                {/*<div className={'row'}>*/}
                {/*<WidgetCarousel/>*/}
                {/*</div>*/}
                {/*<div className={'dashboard-editor-container'}>*/}
                {/*<h5>{localeService.isRussian() ? 'Окно размещения элементов' : 'Item Placement Window'}</h5>*/}
                {/*{!isFetchingWidgetList &&*/}
                {/*<DropZone>*/}
                {/*<ReactGridLayout className="dashboard-grid" layout={widgetList} cols={6}*/}
                {/*rowHeight={80}*/}
                {/*onLayoutChange={(items) => {*/}
                {/*updateWidgetsPosition(items);*/}
                {/*}}*/}
                {/*isDraggable={!isBlocked}*/}
                {/*width={size.width - 32} margin={[8, 8]}>*/}
                {/*{widgetList.map(widget => {*/}
                {/*return <div key={widget.id} data-grid={widget}>*/}
                {/*<Widget mode={widget.mode} widgetProps={widget}*/}
                {/*sizeParams={{*/}
                {/*w: widget.w,*/}
                {/*h: widget.h,*/}
                {/*width: (size.width / 6 * widget.w) - 80,*/}
                {/*height: (100 * widget.h) - 120*/}
                {/*}}/>*/}
                {/*</div>;*/}
                {/*})}*/}
                {/*</ReactGridLayout>*/}
                {/*</DropZone>*/}
                {/*}*/}
                {/*{isFetchingWidgetList && <Loader/>}*/}
                {/*</div>*/}
                {/*{widgetList.length > 0 && <div className={'row'}>*/}
                {/*<div className={'to-right'}>*/}
                {/*<button className={'btn eco-btn success'} onClick={() => setCreateDashboardForm({*/}
                {/*...createDashboardForm,*/}
                {/*isOpen: true*/}
                {/*})}>{localeService.isRussian() ? 'Сохранить дашборд' : 'Save Dashboard'}</button>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*}*/}

                {/*</div>*/}

                {/*</DndProvider>*/}
                {/*</>}*/}
            </>}
        </>
    );
}

EditDashboard.propTypes = {
    dashboardId: PropTypes.number,
    widgetList: PropTypes.array,
    clearDashboard: PropTypes.func,
    updateWidgetsPosition: PropTypes.func,
    uploadFunctionList: PropTypes.func,
    setEditedWidgets: PropTypes.func,
    setDataSourceList: PropTypes.func,
    updateDashboardList: PropTypes.func,
    dataSourceList: PropTypes.array
};

const mapStateToProps = state => {
    const widgetList = state.dashboardReducer.widgetList;
    const dataSourceList = state.dashboardReducer.dataSourceList;
    return {widgetList, dataSourceList};
};
const mapDispatchToProps = {
    clearDashboard: clearDashboard,
    uploadFunctionList: uploadFunctionList,
    updateWidgetsPosition: updateWidgetsPosition,
    setEditedWidgets: setEditedWidgets,
    setDataSourceList: setDataSourceList,
    updateDashboardList: updateDashboardList
};
export default connect(mapStateToProps, mapDispatchToProps)(EditDashboard);
