import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {localeService} from '../../services/locale.service';
import {capacityService} from '../../services';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import SelectDeviceVariableFromCapacityTree from '../../components/SelectDeviceVariableFromCapacityTree';
import Import80020Props from './Import80020Props';
import CreateCapacityBalance from './CreateCapacityBalance';

function SetColsToDatasource({importConfig, onStartImport, onCancel}) {
    const {t} = useTranslation();
    const [importColumns, setImportColumns] = useState([]);
    const [capacityBalance, setCapacityBalance] = useState(null);
    const [capacityTree, setCapacityTree] = useState([]);
    const {preparseredData, importConf} = importConfig;

    useEffect(() => {
        capacityService.getCapacityTree().then(
            results => {
                setCapacityTree(results.list);
            }
        );
    }, []);

    const identifyColumnToDevice = (columnId, device) => {
        const column = {
            index: columnId,
            deviceUUID: device.deviceGUID,
            dataSourceID: device.id
        };
        setImportColumns([...importColumns, column]);
    };

    const startImport = () => {
        if (importConf.filename.endsWith('xml')) {
            const columns = [];
            importColumns.map(column => {
                columns.push({
                    dataSourceID: column.dataSourceID,
                    deviceUUID: column.deviceUUID,
                    uuidChannel: column.uuidChannelSub
                });
            });
            let importProps = {
                relationt: columns,
            };
            if(capacityBalance !== null){
                importProps['balance'] = capacityBalance;
            }
            onStartImport(importProps);
        } else {
            onStartImport(importColumns);
        }
    };

    return <div className={'container' + (importConf.filename.endsWith('xml') ? '-700' : '')}>
        <p className={'help-text'}>{localeService.isRussian() ? 'Выберите переменную устройства для каждой необходимой для импорта колонки' : 'Select device variable for every column'}</p>
        {!importConf.filename.endsWith('xml') &&
        <table className="table" style={{width: '100%', marginTop: '20px', marginBottom: '20px'}}>
            <thead>
            <tr className="head-header">
                {preparseredData.content[0].data.map((header, i) => {
                    if (i === 0) {
                        return (
                            <th key={i} width={(100 / preparseredData.content[0].data.length) + '%'}>
                                <h6>{localeService.isRussian() ? 'Дата и время' : 'Datetime'}</h6>
                            </th>);
                    }
                    return (
                        <th key={i} style={{position: 'relative', minWidth: '180px'}}>
                            <SelectDeviceVariableFromCapacityTree capacityTree={capacityTree}
                                                                  onSelect={(device) => identifyColumnToDevice(i, device)}/>
                        </th>);
                })
                }
            </tr>
            </thead>
            <tbody>
            {
                preparseredData.content.map((fileRow, i) => {
                    if (i > 4 || i === 0) {
                        return null;
                    }
                    return (<tr key={'tr_' + i}>
                        {
                            fileRow.data.map((cell, cellIndex) => {
                                if (cellIndex === 0) {
                                    return (
                                        <th key={'td_' + i + '_' + cellIndex}>{moment.unix(cell / 1000).utc().format('DD-MM-YYYY HH:mm:ss')}</th>);
                                }
                                return (<td key={'td_' + i + '_' + cellIndex}>{cell}</td>);
                            })
                        }
                    </tr>);
                })
            }
            </tbody>
        </table>
        }

        {importConf.filename.endsWith('xml') && preparseredData &&
        <Import80020Props capacityTree={capacityTree}
                          preparseredData={preparseredData}
                          importColumns={importColumns}
                          onChangeImportProps={(importColumns) => {
                              setImportColumns(importColumns);
                              console.log('importColumns', importColumns);
                          }}/>
        }

        <div className={'to-right'}>
            <button onClick={() => startImport(importColumns)}
                    className={'btn eco-btn success mr2'}>{localeService.isRussian() ? 'Начать импорт' : 'Start Import'}</button>
            {importConf.filename.endsWith('xml') &&
            <CreateCapacityBalance
                onChange={(balance) => {
                    console.log('balance', balance);
                    setCapacityBalance(balance);
                }}
                capacityTree={capacityTree} importColumns={importColumns}/>}

            <button onClick={() => onCancel()} className={'btn eco-btn danger'}>{t('cancel')}</button>
        </div>
    </div>;
}

SetColsToDatasource.propTypes = {
    importConfig: PropTypes.object,
    onStartImport: PropTypes.func,
    onCancel: PropTypes.func
};

export default SetColsToDatasource;