import React from 'react';
import PropTypes from 'prop-types';
import {timeGroupList} from '../../../../constants/widgets.constants';
import {localeService} from '../../../../services/locale.service';
import ClickOutside from '../../../../components/ClickOutside';
import moment from 'moment/moment';
import {useTranslation} from 'react-i18next';

function SeletTimeGroup({timeGroup, onChangeTimeGroup, datetimeFilter}) {

    const { t } = useTranslation();

    const [isDropDownOpen, setDropDownOpen] = React.useState(false);
    const [selectedTimeGroup, setSelectedTimeGroup] = React.useState(false);
    const [groupList, setGroupList] = React.useState([]);

    React.useEffect(() => {
        const tgIdx = timeGroupList.findIndex(el => el.value === timeGroup);
        if(tgIdx >= 0) {
            setSelectedTimeGroup(timeGroupList[tgIdx]);
        }else{
            setSelectedTimeGroup(timeGroupList[0]);
        }
    }, [timeGroup]);

    React.useEffect(() => {
        const difference = moment.duration(datetimeFilter.finishDate.diff(datetimeFilter.startDate)).asSeconds();
        let tgList = [];
        timeGroupList.map(el => {
            if(el.value !== 'auto'){
                if(difference / el.seconds <= 10800){
                    tgList.push(el);
                }
            }else{
                tgList.push(el);
            }
        });
        setGroupList(tgList);
    }, [datetimeFilter]);

    return (
        <>
            <div className={'dropdown-container datetime-range ' + (isDropDownOpen ? ' open' : '')}>
                <button className={'btn eco-btn dark default selected-item'}
                        style={{
                            display: 'flex',
                            padding: '0 2rem 0 0.5rem',
                            alignItems: 'center',
                            height: '2rem'
                        }}
                        title={localeService.isRussian() ? 'Группировка по времени' : 'Time group'}
                        onClick={() => setDropDownOpen(!isDropDownOpen)}>
                    {t('time.' + selectedTimeGroup.value)}
                </button>
                <ClickOutside onClick={() => setDropDownOpen(false)}>
                    <div className={'dropdown' + (isDropDownOpen ? ' open' : '')}>
                        <ul>
                            {groupList.map((el, i) => {
                                return <li key={'val-' + i} onClick={() => {
                                    setDropDownOpen(false);
                                    onChangeTimeGroup(el.value);
                                }}><span>{t('time.' + el.value)}</span>
                                </li>;
                            })}
                        </ul>
                    </div>
                </ClickOutside>
            </div>
        </>
    );
}

SeletTimeGroup.propTypes = {
    timeGroup: PropTypes.string,
    datetimeFilter: PropTypes.object,
    onChangeTimeGroup: PropTypes.func
};

export default SeletTimeGroup;