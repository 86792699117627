import React, {useState} from 'react';
import {localeService} from '../services/locale.service';
import {ReactSVG} from 'react-svg';
import {useTranslation} from 'react-i18next';
import {Link, useHistory, useLocation} from 'react-router-dom';
import qs from 'query-string';
import {loginService} from '../services/login.service';
import logoSvg from '../sources/images/eco_logo.svg';
import '../styles/login.scss';


function ConfirmEmail() {
  let location = useLocation();
  const history = useHistory();

  const {t} = useTranslation();
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [isFetching, setFetching] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const token = qs.parse(location.search).token || '';
  const warningText = localeService.isRussian() ?
    'Пароль должен содержать не меньше 6-ти символов латинского алфавита, и состоять не менее чем из одной заглавной буквы, одиного спец символа и одной цифры!'
    :
    'The password should contain at least 6 characters and consist of at least one capital letter, one special symbol and one digit!';

  function handleSubmit() {
    setError('');
    if (password && password2 && password === password2) {
      const reg = /(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}/g;
      const test = reg.test(password);
      if (test) {
        setFetching(true);
        loginService.confirmEmail(password, token).then(
          () => {
            setSuccess(localeService.isRussian() ? 'Ваша учетная запись успешно подтверждена! Перейдте на страницу авторизации для входа в систему.' : 'Your account nas been succesfully confirm! ');
          }, (err) => {
            setError('Error! ' + (err.errorCode || ''));
          }
        ).finally(() => setFetching(false));
      } else {
        setError(warningText);
      }
    } else {
      if (password === '') {
        setError(localeService.isRussian() ? 'Введите пароль' : 'Enter password');
      } else if (password2 === '') {
        setError(localeService.isRussian() ? 'Повторите пароль' : 'Repeat password');
      } else if (password !== password2) {
        setError(localeService.isRussian() ? 'Пароли не совпадают' : 'Passwords don\'t match');
      }
    }
  }

  return (<div className={'login-page'}>
    <div className="login-container">
      <div className={'header'}>
      </div>
      <div className={'body'}>
        <div className={'login-content'}>
          <div className={'logo-header'}>
            <div className={'logo'}>
              <ReactSVG src={logoSvg}/>
              <label>DIGITAL<br/>MONITORING</label>
            </div>
            {/*<select className="form-select" aria-label="Default select example">*/}
              {/*<option selected={localStorage.getItem('ems-language') === 'ru'} value={'ru'}>Рус</option>*/}
              {/*<option selected={localStorage.getItem('ems-language') !== 'ru'} value={'en'}>Eng</option>*/}
            {/*</select>*/}
            <h5 style={{color: 'white', width: '200px', textAlign: 'right', fontStyle: 'italic'}}>{localeService.isRussian() ? 'Завершение регистрации' : 'Complete Registration'}</h5>
          </div>

          {!success &&
          <form className="mt-5">
            <div className="mb-3">
                <label className="form-label">{localeService.isRussian() ? 'Придумайте пароль' : 'Enter password'}</label>
                <input type={'password'} className="form-control"
                       value={password} onChange={el => setPassword(el.target.value)}/>
            </div>
            <div className="mb-3">
                <label className="form-label">{localeService.isRussian() ? 'Повторите пароль' : 'Repeat password'}</label>
                <input type={'password'} className="form-control"
                       value={password2} onChange={el => setPassword2(el.target.value)}/>
            </div>
            {!error && <div className="mt-3 alert alert-success" style={{maxWidth: 'fit-content'}}>
              {warningText}
            </div>}
            {error &&
            <div className="mt-3 alert alert-danger" style={{maxWidth: 'fit-content'}}>{error}</div>
            }
            <div className="mt-3 d-flex justify-content-between align-items-center">
              <button type="button" className={'btn eco-btn success'}
                      onClick={handleSubmit.bind(this)}>
                {isFetching ? <small>{t('wait')}</small> : t('confirm')}
              </button>
              <Link to={'/login'} className="card-link">
                <span className={'link'}>{localeService.isRussian() ? 'Авторизация' : 'Authorize'}</span>
              </Link>
            </div>
          </form>
          }
          {success &&
          <div className="mt-5 alert alert-success" style={{maxWidth: 'fit-content'}}>
            {success}
            <button className={'btn eco-btn success mt-4'}
                    onClick={() => history.push('/login')}>{localeService.isRussian() ? 'Авторизация' : 'Authorize'}</button>
          </div>
          }
        </div>
      </div>

    </div>
  </div>);
}

export default ConfirmEmail;