import React, {useState} from 'react';
import PropTypes from 'prop-types';
import SelectCapacityTree from './SelectCapacityTree';
import {deviceService} from '../services';
import Loader from './Loader';
import {localeService} from '../services/locale.service';
// import {useTranslation} from 'react-i18next';

function SelectDataSourcesFromCapacityTree({dataSourceList, capacityTree, onSelectDevice, newStyle}) {
    const [capacity, setCapacity] = useState(null);
    const [isFetchingDeviceVariables, setFetchingDeviceVariables] = useState(false);
    const [capacityVariables, setCapacityVariables] = useState([]);
    const [usedVaraibleList, setUsedVariableList] = useState(dataSourceList);
    const [isAllSelected, setAllSelected] = useState(false);
    // const {t} = useTranslation();

    React.useEffect(() => {
        // Promise.all(dataSourceList.map(ds => {
        //    if(!ds.capacity){
        //        return deviceService.getDeviceBySourceId(ds.id).then(
        //                        respDevice => {
        //                            let dataSource = ds;
        //                            ds.device = {id: respDevice.id, name: respDevice.name};
        //                            return dataSource;
        //                        }
        //                    );
        //    }else{
        //        console.log(ds);
        //    }
        // })).then(dsList => {
        //     console.log('dsList', dsList);
        //     setUsedVariableList(dsList);
        // });

        setUsedVariableList(dataSourceList);
    }, [dataSourceList]);

    const onSelectCapacity = (capacity) => {
        setCapacity(capacity);
        setCapacityVariables([]);
        setFetchingDeviceVariables(true);
        deviceService.getVariablesByCapacity(capacity.id).then(
            variableList => {
                setCapacityVariables(variableList);
                setFetchingDeviceVariables(false);
            }
        );
        setAllSelected(false);
    };

    const addVariable = (variable) => {
        let varList = [];
        if (isChecked(variable.id)) {
            varList = usedVaraibleList.filter(el => el.id !== variable.id);
        } else {
            const newVar = {...variable, capacity: {name: capacity.name, id: capacity.id}};
            varList = [...usedVaraibleList, newVar];
        }
        // setUsedVariableList(varList);
        onSelectDevice(varList);
    };

    const isChecked = (varId) => {
        let isExist = false;
        if (usedVaraibleList.length > 0) {
            const idx = usedVaraibleList.findIndex(el => el.id === varId);
            if (idx >= 0) {
                isExist = true;
            }
        }
        return isExist;
    };


    const selectAll = () => {
        let varList = usedVaraibleList;
        if (!isAllSelected) {
            capacityVariables.map(variable => {
                const newVar = {...variable, capacity: {name: capacity.name, id: capacity.id}};
                varList = [...varList, newVar];
            });

            setAllSelected(true);
        } else {
            capacityVariables.map(variable => {
                varList = varList.filter(el => el.id !== variable.id);
            });
            setAllSelected(false);
        }
        onSelectDevice(varList);
    };


    // const groupDataSourcesByCapacity = (dataSourceList) => {
    //     let capacityList = new Map();
    //     dataSourceList.map(ds => {
    //         console.log('ds', ds);
    //         if(!ds.capacity){
    //             deviceService.getDeviceBySourceId(ds.id).then(
    //                 device => {
    //                     console.log('device',ds, device);
    //                     console.log('deviceVariable', device.variabels.find(el => el.id === ds.id));
    //                 }
    //             );
    //         }else{
    //             if(capacityList && capacityList.has(ds.capacity.id)){
    //                 let capacity = capacityList.get(ds.capacity.id);
    //                 capacity.dataSourceList.push(ds);
    //                 capacityList.set(ds.capacity.id, capacity);
    //             }else{
    //                 let capacity = {
    //                     capacity: ds.capacity,
    //                     dataSourceList: [ds]
    //                 };
    //                 capacityList.set(ds.capacity.id, capacity);
    //             }
    //         }
    //
    //     });
    //     setDataSourcesGroupedByCapacity(capacityList);
    //     console.log(capacityList);
    // };

    return (
        <>

        <div className={'select-db-datasources' + (newStyle ? '-eco-style':'')}>
                <div className={'col'}>
                    <b className={'step mb-5'}>1. {localeService.isRussian() ? 'Выберите источник' : 'Select Source'}</b>
                    <SelectCapacityTree capacityTree={capacityTree} onSelect={capacity => onSelectCapacity(capacity)}
                                        isAlwaysOpen={true} />
                </div>
                <div className={'col capacity-vars'} style={{borderLeft: '1px dashed #d8d8d8'}}>
                    <b className={'step mb-5'}>2. {localeService.isRussian() ? 'Выберите переменные устройства' : 'Select Device Variables'}</b>
                    <div className={'dropdown-container open is-always-open select-capacity-variable-container'}>
                        <div className={'dropdown open'} style={{minWidth: 'calc(100% - 1rem)'}}>
                            {!capacity &&
                            <p className={'help-text'}>{localeService.isRussian() ? 'Выберите источник' : 'Select Source'}</p>}
                            {capacity &&
                            <p className={'help-text'}>{(localeService.isRussian() ? 'Переменные источника ' : 'Variables of data source ') + '"' + capacity.name + '"'}</p>}
                            {isFetchingDeviceVariables && <Loader
                                waitText={localeService.isRussian() ? 'Загрузка переменных ...' : 'Fetching device variables...'}/>}
                            {!isFetchingDeviceVariables && capacityVariables.length > 0 &&
                            <ul className={'select-variable-list'}>
                                {capacityVariables.length > 1 &&
                                <li key={'var-all'}>
                                    <button type={'button'}
                                            className={'check-box' + (isAllSelected ? ' checked' : '')}
                                            onClick={() => selectAll()}></button>
                                    <span style={{borderBottom: '1px dashed grey'}} onClick={() => selectAll()}>
                                {localeService.isRussian() ? 'Выбрать все' : 'Select All'}
                            </span>
                                </li>}
                                {capacityVariables.map((variable) => {
                                    return (
                                        <li key={'var' + variable.id}>
                                            <button type={'button'}
                                                    className={'check-box' + (isChecked(variable.id) ? ' checked' : '')}
                                                    onClick={() => addVariable(variable)}></button>
                                            {variable.name}
                                        </li>
                                    );
                                })}

                            </ul>}
                            {!isFetchingDeviceVariables && capacity && capacityVariables.length === 0 &&
                            <p>{localeService.isRussian() ? 'В выбранном источнике нет переменных устройств' : 'Variable List for this capacity is empty'}</p>
                            }
                        </div>
                    </div>
                </div>
            {!newStyle &&
            <div className={'col'} style={{borderLeft: '1px dashed #d8d8d8'}}>
                    <b className={'step mb-5'}>3. {localeService.isRussian() ? 'Источники данных дашборда' : 'Dashboard Data Sources'}</b>
                    <div className={'dropdown-container open is-always-open select-capacity-variable-container'}>
                        <div className={'dropdown open'} style={{minWidth: 'calc(100% - 1rem)'}}>
                            <ul>
                                {usedVaraibleList.map(el => {
                                    return <li key={el.id} className={'datasource-info'}>
                                        <p>{el.name}</p>
                                        {/*<p className={'help-text'}><b>{t('capacity')}: </b>{el.capacity.name}</p>*/}
                                        </li>;
                                })}
                            </ul>
                        </div>
                    </div>
                </div>}
            </div>
        </>
    );
}

SelectDataSourcesFromCapacityTree.propTypes = {
    capacityTree: PropTypes.array.isRequired,
    onSelectDevice: PropTypes.func.isRequired,
    dataSourceList: PropTypes.array,
    newStyle: PropTypes.bool
};

export default SelectDataSourcesFromCapacityTree;