export const POLYGONS_DATA_CHEL = {
    "geometry": {
        "heightmap": [
            {
                "type": "MultiPolygon",
                "value": 100.0,
                "data": [
                    [
                        {
                            "lat": 55.453715360636515,
                            "lon": 61.43224602710196
                        },
                        {
                            "lat": 55.450131155026455,
                            "lon": 61.43270604891398
                        },
                        {
                            "lat": 55.451091644096756,
                            "lon": 61.43442264811216
                        },
                        {
                            "lat": 55.45205225438948,
                            "lon": 61.43613923809646
                        },
                        {
                            "lat": 55.448467570321284,
                            "lon": 61.43659923288691
                        },
                        {
                            "lat": 55.44942812320137,
                            "lon": 61.438315846319725
                        },
                        {
                            "lat": 55.45301298592574,
                            "lon": 61.437855818865316
                        },
                        {
                            "lat": 55.45659772738551,
                            "lon": 61.43739569905498
                        },
                        {
                            "lat": 55.457558758839966,
                            "lon": 61.43911223793389
                        },
                        {
                            "lat": 55.45851991159862,
                            "lon": 61.440828767590794
                        },
                        {
                            "lat": 55.45493481281317,
                            "lon": 61.44128895275047
                        },
                        {
                            "lat": 55.45589590820652,
                            "lon": 61.44300550586362
                        },
                        {
                            "lat": 55.456857124927765,
                            "lon": 61.44472204975502
                        },
                        {
                            "lat": 55.45327154739354,
                            "lon": 61.445182207899386
                        },
                        {
                            "lat": 55.454232706715004,
                            "lon": 61.44689877524857
                        },
                        {
                            "lat": 55.455193987388036,
                            "lon": 61.44861533337635
                        },
                        {
                            "lat": 55.45877992243836,
                            "lon": 61.44815510986645
                        },
                        {
                            "lat": 55.45974150326994,
                            "lon": 61.44987162608334
                        },
                        {
                            "lat": 55.46332749569376,
                            "lon": 61.44941127749045
                        },
                        {
                            "lat": 55.46428937672092,
                            "lon": 61.4511277517824
                        },
                        {
                            "lat": 55.46525137920007,
                            "lon": 61.45284421684137
                        },
                        {
                            "lat": 55.466213503152346,
                            "lon": 61.4545606726658
                        },
                        {
                            "lat": 55.467175748598905,
                            "lon": 61.456277119254075
                        },
                        {
                            "lat": 55.46813811556084,
                            "lon": 61.45799355660465
                        },
                        {
                            "lat": 55.46910060405933,
                            "lon": 61.459709984715964
                        },
                        {
                            "lat": 55.47006321411552,
                            "lon": 61.46142640358642
                        },
                        {
                            "lat": 55.47365033683246,
                            "lon": 61.46096573361508
                        },
                        {
                            "lat": 55.474613247359365,
                            "lon": 61.46268211051657
                        },
                        {
                            "lat": 55.47557627950481,
                            "lon": 61.464398478170665
                        },
                        {
                            "lat": 55.47916363838432,
                            "lon": 61.46393765030985
                        },
                        {
                            "lat": 55.482750875605575,
                            "lon": 61.46347673002231
                        },
                        {
                            "lat": 55.48371438718511,
                            "lon": 61.46519302294383
                        },
                        {
                            "lat": 55.487301681595,
                            "lon": 61.46473197748562
                        },
                        {
                            "lat": 55.49088885428838,
                            "lon": 61.46427083960755
                        },
                        {
                            "lat": 55.48992498499918,
                            "lon": 61.46255461219194
                        },
                        {
                            "lat": 55.488961237423815,
                            "lon": 61.460838375513575
                        },
                        {
                            "lat": 55.48799761154113,
                            "lon": 61.4591221295741
                        },
                        {
                            "lat": 55.49158412608585,
                            "lon": 61.458660997556166
                        },
                        {
                            "lat": 55.492547930754554,
                            "lon": 61.46037721074119
                        },
                        {
                            "lat": 55.49613450237411,
                            "lon": 61.45991595357261
                        },
                        {
                            "lat": 55.49517051894054,
                            "lon": 61.45819977314769
                        },
                        {
                            "lat": 55.49420665721548,
                            "lon": 61.45648358345631
                        },
                        {
                            "lat": 55.49324291717779,
                            "lon": 61.454767384500066
                        },
                        {
                            "lat": 55.48965688179727,
                            "lon": 61.45522854339871
                        },
                        {
                            "lat": 55.48869344213512,
                            "lon": 61.4535123024294
                        },
                        {
                            "lat": 55.485107463837416,
                            "lon": 61.45397333620443
                        },
                        {
                            "lat": 55.48414432451374,
                            "lon": 61.45225705323609
                        },
                        {
                            "lat": 55.48318130677695,
                            "lon": 61.45054076101445
                        },
                        {
                            "lat": 55.47959556431897,
                            "lon": 61.45100163694252
                        },
                        {
                            "lat": 55.478632846844015,
                            "lon": 61.44928530274088
                        },
                        {
                            "lat": 55.47504716155608,
                            "lon": 61.44974605357112
                        },
                        {
                            "lat": 55.47408474430592,
                            "lon": 61.448029677403504
                        },
                        {
                            "lat": 55.47312244854218,
                            "lon": 61.446313291994166
                        },
                        {
                            "lat": 55.47216027424376,
                            "lon": 61.444596897344724
                        },
                        {
                            "lat": 55.471198221389514,
                            "lon": 61.44288049345672
                        },
                        {
                            "lat": 55.47023628995832,
                            "lon": 61.44116408033172
                        },
                        {
                            "lat": 55.469274479929084,
                            "lon": 61.43944765797134
                        },
                        {
                            "lat": 55.47285909330223,
                            "lon": 61.438987103405466
                        },
                        {
                            "lat": 55.47189722606612,
                            "lon": 61.43727070450998
                        },
                        {
                            "lat": 55.47093548020823,
                            "lon": 61.435554296378804
                        },
                        {
                            "lat": 55.46997385570748,
                            "lon": 61.433837879013474
                        },
                        {
                            "lat": 55.46901235254278,
                            "lon": 61.43212145241567
                        },
                        {
                            "lat": 55.46542845340949,
                            "lon": 61.4325818762071
                        },
                        {
                            "lat": 55.46184443297973,
                            "lon": 61.43304220767868
                        },
                        {
                            "lat": 55.4608834081785,
                            "lon": 61.431325706485346
                        },
                        {
                            "lat": 55.45992250463415,
                            "lon": 61.42960919606944
                        },
                        {
                            "lat": 55.463506168012756,
                            "lon": 61.42914892995335
                        },
                        {
                            "lat": 55.462545207206375,
                            "lon": 61.42743244298904
                        },
                        {
                            "lat": 55.46612857085393,
                            "lon": 61.42697211724249
                        },
                        {
                            "lat": 55.469711813249795,
                            "lon": 61.4265116991991
                        },
                        {
                            "lat": 55.468750616781136,
                            "lon": 61.42479526836711
                        },
                        {
                            "lat": 55.467789541561594,
                            "lon": 61.423078828307
                        },
                        {
                            "lat": 55.464206656021645,
                            "lon": 61.42353918099308
                        },
                        {
                            "lat": 55.46062364927216,
                            "lon": 61.42399944139349
                        },
                        {
                            "lat": 55.45704052133157,
                            "lon": 61.424459609502
                        },
                        {
                            "lat": 55.45608010260399,
                            "lon": 61.42274306221149
                        },
                        {
                            "lat": 55.45966305210221,
                            "lon": 61.42228292676538
                        },
                        {
                            "lat": 55.46324588043038,
                            "lon": 61.42182269903291
                        },
                        {
                            "lat": 55.466828587570085,
                            "lon": 61.421362379020366
                        },
                        {
                            "lat": 55.47041117350281,
                            "lon": 61.42090196673397
                        },
                        {
                            "lat": 55.46945016235752,
                            "lon": 61.41918554089802
                        },
                        {
                            "lat": 55.47303244872516,
                            "lon": 61.41872506902526
                        },
                        {
                            "lat": 55.47207138046307,
                            "lon": 61.417008666640356
                        },
                        {
                            "lat": 55.475653367307736,
                            "lon": 61.41654813519523
                        },
                        {
                            "lat": 55.47469224196575,
                            "lon": 61.414831756261634
                        },
                        {
                            "lat": 55.47827392932957,
                            "lon": 61.41437116525809
                        },
                        {
                            "lat": 55.477312746944555,
                            "lon": 61.412654809776164
                        },
                        {
                            "lat": 55.476351685756114,
                            "lon": 61.410938445061866
                        },
                        {
                            "lat": 55.47993289547852,
                            "lon": 61.410477827198214
                        },
                        {
                            "lat": 55.478971777281345,
                            "lon": 61.40876148593402
                        },
                        {
                            "lat": 55.478010780257065,
                            "lon": 61.40704513543716
                        },
                        {
                            "lat": 55.48159151247758,
                            "lon": 61.40658449072831
                        },
                        {
                            "lat": 55.48063045847882,
                            "lon": 61.40486816368003
                        },
                        {
                            "lat": 55.48421089142375,
                            "lon": 61.404407459458966
                        },
                        {
                            "lat": 55.48324978048736,
                            "lon": 61.402691155859586
                        },
                        {
                            "lat": 55.48228879069762,
                            "lon": 61.40097484302536
                        },
                        {
                            "lat": 55.48132792203349,
                            "lon": 61.39925852095798
                        },
                        {
                            "lat": 55.48490769966848,
                            "lon": 61.39879782260324
                        },
                        {
                            "lat": 55.483946774098314,
                            "lon": 61.39708152398134
                        },
                        {
                            "lat": 55.48298596963011,
                            "lon": 61.395365216125896
                        },
                        {
                            "lat": 55.48202528624283,
                            "lon": 61.39364889903853
                        },
                        {
                            "lat": 55.481064723915466,
                            "lon": 61.39193257272079
                        },
                        {
                            "lat": 55.480104282627025,
                            "lon": 61.39021623717426
                        },
                        {
                            "lat": 55.48368304937519,
                            "lon": 61.38975561003635
                        },
                        {
                            "lat": 55.48272255120706,
                            "lon": 61.38803929792824
                        },
                        {
                            "lat": 55.481762174054246,
                            "lon": 61.38632297659104
                        },
                        {
                            "lat": 55.48534046403136,
                            "lon": 61.38586232266358
                        },
                        {
                            "lat": 55.4889186329958,
                            "lon": 61.385401576624815
                        },
                        {
                            "lat": 55.487958021178656,
                            "lon": 61.38368531139449
                        },
                        {
                            "lat": 55.491535891314236,
                            "lon": 61.383224505926236
                        },
                        {
                            "lat": 55.49057522272774,
                            "lon": 61.38150826413524
                        },
                        {
                            "lat": 55.486997530371596,
                            "lon": 61.381969036931366
                        },
                        {
                            "lat": 55.4860371605536,
                            "lon": 61.38025275323705
                        },
                        {
                            "lat": 55.485076911703686,
                            "lon": 61.3785364603131
                        },
                        {
                            "lat": 55.48865424855609,
                            "lon": 61.378075752851004
                        },
                        {
                            "lat": 55.487693942928956,
                            "lon": 61.37635948336087
                        },
                        {
                            "lat": 55.484116783800864,
                            "lon": 61.37682015816108
                        },
                        {
                            "lat": 55.48315677682415,
                            "lon": 61.375103846782544
                        },
                        {
                            "lat": 55.47957967449687,
                            "lon": 61.375564396852646
                        },
                        {
                            "lat": 55.47600245128281,
                            "lon": 61.37602485484485
                        },
                        {
                            "lat": 55.47696210278469,
                            "lon": 61.377741231523665
                        },
                        {
                            "lat": 55.47792187517596,
                            "lon": 61.37945759898276
                        },
                        {
                            "lat": 55.47434417553739,
                            "lon": 61.37991803017788
                        },
                        {
                            "lat": 55.473384580933384,
                            "lon": 61.37820163007359
                        },
                        {
                            "lat": 55.469806938211065,
                            "lon": 61.37866193652764
                        },
                        {
                            "lat": 55.46884764226788,
                            "lon": 61.376945494570855
                        },
                        {
                            "lat": 55.46527005650377,
                            "lon": 61.37740567629223
                        },
                        {
                            "lat": 55.46169234992639,
                            "lon": 61.37786576591089
                        },
                        {
                            "lat": 55.46265129022701,
                            "lon": 61.37958227312339
                        },
                        {
                            "lat": 55.46361035134344,
                            "lon": 61.38129877112989
                        },
                        {
                            "lat": 55.46456953329663,
                            "lon": 61.38301525992872
                        },
                        {
                            "lat": 55.465528836107616,
                            "lon": 61.384731739518394
                        },
                        {
                            "lat": 55.46195029721407,
                            "lon": 61.38519186752661
                        },
                        {
                            "lat": 55.46290954297743,
                            "lon": 61.386908370538706
                        },
                        {
                            "lat": 55.463868909622114,
                            "lon": 61.38862486434187
                        },
                        {
                            "lat": 55.46482839716909,
                            "lon": 61.39034134893459
                        },
                        {
                            "lat": 55.465788005639354,
                            "lon": 61.39205782431529
                        },
                        {
                            "lat": 55.46220863398571,
                            "lon": 61.39251799071682
                        },
                        {
                            "lat": 55.463168185379274,
                            "lon": 61.39423448952516
                        },
                        {
                            "lat": 55.4595885147578,
                            "lon": 61.39469459639643
                        },
                        {
                            "lat": 55.46054800903781,
                            "lon": 61.39641111863204
                        },
                        {
                            "lat": 55.46150762426722,
                            "lon": 61.39812763165776
                        },
                        {
                            "lat": 55.465087651027915,
                            "lon": 61.39766745950502
                        },
                        {
                            "lat": 55.466047565325034,
                            "lon": 61.39938393067343
                        },
                        {
                            "lat": 55.46246736046707,
                            "lon": 61.39984413547203
                        },
                        {
                            "lat": 55.45888703461768,
                            "lon": 61.40030424807622
                        },
                        {
                            "lat": 55.45984671367227,
                            "lon": 61.40202077532119
                        },
                        {
                            "lat": 55.45626608869215,
                            "lon": 61.40248082836282
                        },
                        {
                            "lat": 55.45722571056459,
                            "lon": 61.404197379038145
                        },
                        {
                            "lat": 55.45364478641176,
                            "lon": 61.404657372503294
                        },
                        {
                            "lat": 55.45460435106513,
                            "lon": 61.406373946608646
                        },
                        {
                            "lat": 55.4581854534336,
                            "lon": 61.40591392050415
                        },
                        {
                            "lat": 55.4591453173202,
                            "lon": 61.40763045275925
                        },
                        {
                            "lat": 55.45556403671767,
                            "lon": 61.408090511506565
                        },
                        {
                            "lat": 55.45198263509498,
                            "lon": 61.40855047801846
                        },
                        {
                            "lat": 55.45294226349411,
                            "lon": 61.410267066347934
                        },
                        {
                            "lat": 55.45652384339036,
                            "lon": 61.40980706719541
                        },
                        {
                            "lat": 55.460105302245424,
                            "lon": 61.40934697580193
                        },
                        {
                            "lat": 55.46106540823029,
                            "lon": 61.41106348963058
                        },
                        {
                            "lat": 55.45748377110424,
                            "lon": 61.41152361367368
                        },
                        {
                            "lat": 55.458443819880316,
                            "lon": 61.41324015093976
                        },
                        {
                            "lat": 55.45940398973966,
                            "lon": 61.41495667899213
                        },
                        {
                            "lat": 55.45582187491198,
                            "lon": 61.41541677608697
                        },
                        {
                            "lat": 55.45678198752819,
                            "lon": 61.417133327578284
                        },
                        {
                            "lat": 55.453199573246216,
                            "lon": 61.417593365057876
                        },
                        {
                            "lat": 55.44961703787587,
                            "lon": 61.41805331026167
                        },
                        {
                            "lat": 55.45057691480403,
                            "lon": 61.41976991783818
                        },
                        {
                            "lat": 55.451536912823144,
                            "lon": 61.4214865162065
                        },
                        {
                            "lat": 55.452497031954266,
                            "lon": 61.4232031053651
                        },
                        {
                            "lat": 55.453457272218415,
                            "lon": 61.42491968531237
                        },
                        {
                            "lat": 55.44987390195115,
                            "lon": 61.42537966881838
                        },
                        {
                            "lat": 55.45083408486642,
                            "lon": 61.42709627220738
                        },
                        {
                            "lat": 55.451794388938374,
                            "lon": 61.42881286638535
                        },
                        {
                            "lat": 55.45275481418805,
                            "lon": 61.430529451350736
                        },
                        {
                            "lat": 55.453715360636515,
                            "lon": 61.43224602710196
                        },
                        {
                            "lat": 55.46718841360274,
                            "lon": 61.38083861625766
                        },
                        {
                            "lat": 55.470766355006845,
                            "lon": 61.38037836927159
                        },
                        {
                            "lat": 55.471725892676204,
                            "lon": 61.38209479280106
                        },
                        {
                            "lat": 55.46814777342451,
                            "lon": 61.3825550724246
                        },
                        {
                            "lat": 55.46718841360274,
                            "lon": 61.38083861625766
                        }
                    ],
                    [
                        {
                            "lat": 55.49019348331361,
                            "lon": 61.46988070242821
                        },
                        {
                            "lat": 55.4892294276556,
                            "lon": 61.46816447004419
                        },
                        {
                            "lat": 55.485641775459264,
                            "lon": 61.46862558101304
                        },
                        {
                            "lat": 55.48660565219624,
                            "lon": 61.470341846157524
                        },
                        {
                            "lat": 55.49019348331361,
                            "lon": 61.46988070242821
                        }
                    ],
                    [
                        {
                            "lat": 55.498062834451545,
                            "lon": 61.46334828661682
                        },
                        {
                            "lat": 55.497098607537396,
                            "lon": 61.46163212472952
                        },
                        {
                            "lat": 55.49351185713448,
                            "lon": 61.462093414661595
                        },
                        {
                            "lat": 55.494475905246745,
                            "lon": 61.46380960931584
                        },
                        {
                            "lat": 55.49544007511256,
                            "lon": 61.46552579470238
                        },
                        {
                            "lat": 55.499027183137784,
                            "lon": 61.46506443923294
                        },
                        {
                            "lat": 55.498062834451545,
                            "lon": 61.46334828661682
                        }
                    ],
                    [
                        {
                            "lat": 55.49185284531261,
                            "lon": 61.46598705775885
                        },
                        {
                            "lat": 55.48826549375647,
                            "lon": 61.46644822839613
                        },
                        {
                            "lat": 55.4892294276556,
                            "lon": 61.46816447004419
                        },
                        {
                            "lat": 55.49281695809306,
                            "lon": 61.467703266644264
                        },
                        {
                            "lat": 55.49185284531261,
                            "lon": 61.46598705775885
                        }
                    ],
                    [
                        {
                            "lat": 55.500956245911205,
                            "lon": 61.46849671664538
                        },
                        {
                            "lat": 55.49736878018962,
                            "lon": 61.46895813766588
                        },
                        {
                            "lat": 55.49833331544323,
                            "lon": 61.47067429523971
                        },
                        {
                            "lat": 55.501920960040785,
                            "lon": 61.47021284143853
                        },
                        {
                            "lat": 55.500956245911205,
                            "lon": 61.46849671664538
                        }
                    ],
                    [
                        {
                            "lat": 55.50026275148665,
                            "lon": 61.47410658256361
                        },
                        {
                            "lat": 55.49929797253518,
                            "lon": 61.472390443539474
                        },
                        {
                            "lat": 55.49571002718359,
                            "lon": 61.47285183768946
                        },
                        {
                            "lat": 55.496674627200825,
                            "lon": 61.47456800949555
                        },
                        {
                            "lat": 55.50026275148665,
                            "lon": 61.47410658256361
                        }
                    ],
                    [
                        {
                            "lat": 55.500956245911205,
                            "lon": 61.46849671664538
                        },
                        {
                            "lat": 55.50454358979713,
                            "lon": 61.46803520320701
                        },
                        {
                            "lat": 55.503578818666945,
                            "lon": 61.46631910192079
                        },
                        {
                            "lat": 55.50716586188366,
                            "lon": 61.465857528859246
                        },
                        {
                            "lat": 55.506201033789914,
                            "lon": 61.46414145108028
                        },
                        {
                            "lat": 55.502614169369735,
                            "lon": 61.464602991356834
                        },
                        {
                            "lat": 55.499027183137784,
                            "lon": 61.46506443923294
                        },
                        {
                            "lat": 55.49999165361728,
                            "lon": 61.466780582576334
                        },
                        {
                            "lat": 55.500956245911205,
                            "lon": 61.46849671664538
                        }
                    ],
                    [
                        {
                            "lat": 55.514614029637194,
                            "lon": 61.47225995048917
                        },
                        {
                            "lat": 55.51820154433405,
                            "lon": 61.47179806141521
                        },
                        {
                            "lat": 55.51916733961183,
                            "lon": 61.47351402715117
                        },
                        {
                            "lat": 55.52275491116076,
                            "lon": 61.47305201284864
                        },
                        {
                            "lat": 55.52634236069661,
                            "lon": 61.47258990613713
                        },
                        {
                            "lat": 55.525376207789144,
                            "lon": 61.470874006044646
                        },
                        {
                            "lat": 55.52441017689204,
                            "lon": 61.46915809665089
                        },
                        {
                            "lat": 55.520823084946116,
                            "lon": 61.469620137716724
                        },
                        {
                            "lat": 55.51985735480539,
                            "lon": 61.467904186205615
                        },
                        {
                            "lat": 55.51889174661404,
                            "lon": 61.4661882253998
                        },
                        {
                            "lat": 55.515304890255855,
                            "lon": 61.46665010844713
                        },
                        {
                            "lat": 55.51433958274416,
                            "lon": 61.464934105543016
                        },
                        {
                            "lat": 55.510752783248904,
                            "lon": 61.465395863397895
                        },
                        {
                            "lat": 55.51171791198797,
                            "lon": 61.46711189910163
                        },
                        {
                            "lat": 55.50813081182889,
                            "lon": 61.467573597356996
                        },
                        {
                            "lat": 55.50909588364681,
                            "lon": 61.46928965657199
                        },
                        {
                            "lat": 55.505508482781494,
                            "lon": 61.46975129521386
                        },
                        {
                            "lat": 55.50647349764121,
                            "lon": 61.471467377939774
                        },
                        {
                            "lat": 55.50288579602723,
                            "lon": 61.471928956954166
                        },
                        {
                            "lat": 55.50385075389172,
                            "lon": 61.47364506319073
                        },
                        {
                            "lat": 55.507438634397516,
                            "lon": 61.473183451383186
                        },
                        {
                            "lat": 55.511026392985514,
                            "lon": 61.47272174714727
                        },
                        {
                            "lat": 55.510061077358614,
                            "lon": 61.4710057065026
                        },
                        {
                            "lat": 55.51364853516091,
                            "lon": 61.47054394264885
                        },
                        {
                            "lat": 55.514614029637194,
                            "lon": 61.47225995048917
                        }
                    ],
                    [
                        {
                            "lat": 55.516545384475506,
                            "lon": 61.475691938295334
                        },
                        {
                            "lat": 55.520133256884186,
                            "lon": 61.475229983591184
                        },
                        {
                            "lat": 55.51916733961183,
                            "lon": 61.47351402715117
                        },
                        {
                            "lat": 55.51557964606832,
                            "lon": 61.47397594903853
                        },
                        {
                            "lat": 55.51199183054876,
                            "lon": 61.4744377785044
                        },
                        {
                            "lat": 55.50840389307162,
                            "lon": 61.47489951554252
                        },
                        {
                            "lat": 55.50936927368476,
                            "lon": 61.47661557041618
                        },
                        {
                            "lat": 55.51033477625812,
                            "lon": 61.47833161600258
                        },
                        {
                            "lat": 55.513923071569046,
                            "lon": 61.47786981334974
                        },
                        {
                            "lat": 55.51295739006951,
                            "lon": 61.476153800572426
                        },
                        {
                            "lat": 55.516545384475506,
                            "lon": 61.475691938295334
                        }
                    ],
                    [
                        {
                            "lat": 55.52468722542772,
                            "lon": 61.476483850782785
                        },
                        {
                            "lat": 55.523721007277096,
                            "lon": 61.47476793646622
                        },
                        {
                            "lat": 55.520133256884186,
                            "lon": 61.475229983591184
                        },
                        {
                            "lat": 55.521099296172366,
                            "lon": 61.47694593073359
                        },
                        {
                            "lat": 55.52468722542772,
                            "lon": 61.476483850782785
                        }
                    ],
                    [
                        {
                            "lat": 55.49415279407644,
                            "lon": 61.38104739925142
                        },
                        {
                            "lat": 55.493192068757324,
                            "lon": 61.3793311809001
                        },
                        {
                            "lat": 55.489614675148694,
                            "lon": 61.379792013109636
                        },
                        {
                            "lat": 55.49057522272774,
                            "lon": 61.38150826413524
                        },
                        {
                            "lat": 55.49415279407644,
                            "lon": 61.38104739925142
                        }
                    ],
                    [
                        {
                            "lat": 55.54621223714549,
                            "lon": 61.47370943047443
                        },
                        {
                            "lat": 55.545244946266145,
                            "lon": 61.47199371323029
                        },
                        {
                            "lat": 55.54165792835666,
                            "lon": 61.47245631474853
                        },
                        {
                            "lat": 55.54262504050103,
                            "lon": 61.474172064852034
                        },
                        {
                            "lat": 55.54621223714549,
                            "lon": 61.47370943047443
                        }
                    ],
                    [
                        {
                            "lat": 55.533796235675915,
                            "lon": 61.47899108299321
                        },
                        {
                            "lat": 55.53282941565766,
                            "lon": 61.477275252965654
                        },
                        {
                            "lat": 55.52924155169345,
                            "lon": 61.47773755059094
                        },
                        {
                            "lat": 55.5302081928547,
                            "lon": 61.47945341346243
                        },
                        {
                            "lat": 55.533796235675915,
                            "lon": 61.47899108299321
                        }
                    ],
                    [
                        {
                            "lat": 55.53573024213886,
                            "lon": 61.48242271510879
                        },
                        {
                            "lat": 55.53214184154812,
                            "lon": 61.48288511127613
                        },
                        {
                            "lat": 55.53310884912282,
                            "lon": 61.48460094621524
                        },
                        {
                            "lat": 55.53669742862608,
                            "lon": 61.484138517193664
                        },
                        {
                            "lat": 55.53573024213886,
                            "lon": 61.48242271510879
                        }
                    ],
                    [
                        {
                            "lat": 55.56210060019608,
                            "lon": 61.488224328108444
                        },
                        {
                            "lat": 55.565689039158094,
                            "lon": 61.48776112056187
                        },
                        {
                            "lat": 55.56665808929193,
                            "lon": 61.48947664578693
                        },
                        {
                            "lat": 55.56762726186058,
                            "lon": 61.491192161662475
                        },
                        {
                            "lat": 55.57121593609705,
                            "lon": 61.4907287958661
                        },
                        {
                            "lat": 55.57218540999353,
                            "lon": 61.4924442694675
                        },
                        {
                            "lat": 55.575774140605766,
                            "lon": 61.491980778323594
                        },
                        {
                            "lat": 55.57674391586705,
                            "lon": 61.49369620963671
                        },
                        {
                            "lat": 55.58033270281244,
                            "lon": 61.49323259313696
                        },
                        {
                            "lat": 55.581302779475486,
                            "lon": 61.49494798214773
                        },
                        {
                            "lat": 55.58489162271137,
                            "lon": 61.494484240283796
                        },
                        {
                            "lat": 55.58586200081316,
                            "lon": 61.49619958697812
                        },
                        {
                            "lat": 55.58945090029685,
                            "lon": 61.495735719741646
                        },
                        {
                            "lat": 55.59042157987434,
                            "lon": 61.49745102410544
                        },
                        {
                            "lat": 55.59401053556316,
                            "lon": 61.49698703148811
                        },
                        {
                            "lat": 55.59498151665329,
                            "lon": 61.49870229350731
                        },
                        {
                            "lat": 55.59857052850454,
                            "lon": 61.498238175500816
                        },
                        {
                            "lat": 55.5975993685783,
                            "lon": 61.49652294645546
                        },
                        {
                            "lat": 55.60118807890131,
                            "lon": 61.49605876901377
                        },
                        {
                            "lat": 55.60477666651369,
                            "lon": 61.49559449916936
                        },
                        {
                            "lat": 55.603805271708396,
                            "lon": 61.493879326698305
                        },
                        {
                            "lat": 55.60021686287093,
                            "lon": 61.49434356356119
                        },
                        {
                            "lat": 55.599245769529816,
                            "lon": 61.49262834872346
                        },
                        {
                            "lat": 55.595657416780924,
                            "lon": 61.49309246021664
                        },
                        {
                            "lat": 55.59206894138256,
                            "lon": 61.49355647931195
                        },
                        {
                            "lat": 55.5910983282494,
                            "lon": 61.49184118915814
                        },
                        {
                            "lat": 55.59012783772588,
                            "lon": 61.490125889629354
                        },
                        {
                            "lat": 55.58915746979067,
                            "lon": 61.48841058072715
                        },
                        {
                            "lat": 55.588187224422434,
                            "lon": 61.48669526245314
                        },
                        {
                            "lat": 55.58721710159986,
                            "lon": 61.484979934808926
                        },
                        {
                            "lat": 55.583629397396024,
                            "lon": 61.48544369674506
                        },
                        {
                            "lat": 55.58004157068631,
                            "lon": 61.48590736629866
                        },
                        {
                            "lat": 55.5790719278288,
                            "lon": 61.484191963410126
                        },
                        {
                            "lat": 55.575484157384004,
                            "lon": 61.48465550764542
                        },
                        {
                            "lat": 55.571896264491585,
                            "lon": 61.48511895949114
                        },
                        {
                            "lat": 55.56830824917001,
                            "lon": 61.48558231894108
                        },
                        {
                            "lat": 55.564720111437744,
                            "lon": 61.48604558598892
                        },
                        {
                            "lat": 55.563751306109594,
                            "lon": 61.484330042069615
                        },
                        {
                            "lat": 55.56016322480419,
                            "lon": 61.484793183805365
                        },
                        {
                            "lat": 55.559194720647525,
                            "lon": 61.483077597641106
                        },
                        {
                            "lat": 55.55560669581157,
                            "lon": 61.4835406140731
                        },
                        {
                            "lat": 55.5546384927894,
                            "lon": 61.481824985677946
                        },
                        {
                            "lat": 55.55105052446549,
                            "lon": 61.48228787681455
                        },
                        {
                            "lat": 55.54746243386869,
                            "lon": 61.48275067554069
                        },
                        {
                            "lat": 55.543874221017525,
                            "lon": 61.48321338185012
                        },
                        {
                            "lat": 55.54484188750187,
                            "lon": 61.4849291088854
                        },
                        {
                            "lat": 55.548430279220256,
                            "lon": 61.484466369701536
                        },
                        {
                            "lat": 55.552018548662986,
                            "lon": 61.484003538095344
                        },
                        {
                            "lat": 55.55298669515464,
                            "lon": 61.48571919004333
                        },
                        {
                            "lat": 55.556575021146394,
                            "lon": 61.485256233132034
                        },
                        {
                            "lat": 55.55754346881511,
                            "lon": 61.48697184285312
                        },
                        {
                            "lat": 55.56113185131329,
                            "lon": 61.48650876062834
                        },
                        {
                            "lat": 55.56210060019608,
                            "lon": 61.488224328108444
                        }
                    ],
                    [
                        {
                            "lat": 55.60836513139703,
                            "lon": 61.49513013692849
                        },
                        {
                            "lat": 55.60477666651369,
                            "lon": 61.49559449916936
                        },
                        {
                            "lat": 55.60574818404806,
                            "lon": 61.497309662250224
                        },
                        {
                            "lat": 55.60933682770344,
                            "lon": 61.49684526701877
                        },
                        {
                            "lat": 55.60836513139703,
                            "lon": 61.49513013692849
                        }
                    ],
                    [
                        {
                            "lat": 55.612252653321434,
                            "lon": 61.501990600911824
                        },
                        {
                            "lat": 55.6132248408316,
                            "lon": 61.50370569341154
                        },
                        {
                            "lat": 55.616814076905285,
                            "lon": 61.50324107376506
                        },
                        {
                            "lat": 55.62040319010616,
                            "lon": 61.502776361706836
                        },
                        {
                            "lat": 55.623992180415705,
                            "lon": 61.50231155724319
                        },
                        {
                            "lat": 55.62496502724496,
                            "lon": 61.50402654128414
                        },
                        {
                            "lat": 55.62855407342356,
                            "lon": 61.503561611391135
                        },
                        {
                            "lat": 55.6295272219812,
                            "lon": 61.50527655298479
                        },
                        {
                            "lat": 55.63050049350985,
                            "lon": 61.50699148515974
                        },
                        {
                            "lat": 55.6314738880309,
                            "lon": 61.508706407914396
                        },
                        {
                            "lat": 55.63506334764274,
                            "lon": 61.50824128649487
                        },
                        {
                            "lat": 55.63865268422234,
                            "lon": 61.507776072666516
                        },
                        {
                            "lat": 55.63767893209722,
                            "lon": 61.50606121600979
                        },
                        {
                            "lat": 55.63670530300141,
                            "lon": 61.50434634992583
                        },
                        {
                            "lat": 55.63573179691348,
                            "lon": 61.50263147441624
                        },
                        {
                            "lat": 55.63932047418794,
                            "lon": 61.50216626734697
                        },
                        {
                            "lat": 55.638346912371894,
                            "lon": 61.500451415460304
                        },
                        {
                            "lat": 55.63475841381203,
                            "lon": 61.50091658948266
                        },
                        {
                            "lat": 55.63378515367569,
                            "lon": 61.49920169512665
                        },
                        {
                            "lat": 55.630196710868304,
                            "lon": 61.4996667437309
                        },
                        {
                            "lat": 55.62922375237475,
                            "lon": 61.497951806919644
                        },
                        {
                            "lat": 55.62563536536262,
                            "lon": 61.49841673011409
                        },
                        {
                            "lat": 55.62466270847501,
                            "lon": 61.49670175086168
                        },
                        {
                            "lat": 55.621074377300914,
                            "lon": 61.49716654865462
                        },
                        {
                            "lat": 55.61748592328119,
                            "lon": 61.49763125406515
                        },
                        {
                            "lat": 55.61651374668902,
                            "lon": 61.49591619937494
                        },
                        {
                            "lat": 55.6129253485899,
                            "lon": 61.49638077939158
                        },
                        {
                            "lat": 55.60933682770344,
                            "lon": 61.49684526701877
                        },
                        {
                            "lat": 55.610308646778726,
                            "lon": 61.49856038771381
                        },
                        {
                            "lat": 55.61128058864427,
                            "lon": 61.50027549901202
                        },
                        {
                            "lat": 55.607691587389475,
                            "lon": 61.500739960235066
                        },
                        {
                            "lat": 55.60866347323923,
                            "lon": 61.502455095135815
                        },
                        {
                            "lat": 55.612252653321434,
                            "lon": 61.501990600911824
                        }
                    ],
                    [
                        {
                            "lat": 55.68759703223071,
                            "lon": 61.49221488463969
                        },
                        {
                            "lat": 55.686621217119075,
                            "lon": 61.49050047704768
                        },
                        {
                            "lat": 55.683034798778216,
                            "lon": 61.490966877490976
                        },
                        {
                            "lat": 55.68401043551179,
                            "lon": 61.492681318200916
                        },
                        {
                            "lat": 55.68759703223071,
                            "lon": 61.49221488463969
                        }
                    ],
                    [
                        {
                            "lat": 55.72541281162389,
                            "lon": 61.511230684345605
                        },
                        {
                            "lat": 55.72182475919423,
                            "lon": 61.51169819508847
                        },
                        {
                            "lat": 55.72280337269323,
                            "lon": 61.51341227079849
                        },
                        {
                            "lat": 55.72639160360776,
                            "lon": 61.51294472685016
                        },
                        {
                            "lat": 55.72541281162389,
                            "lon": 61.511230684345605
                        }
                    ]
                ]
            },
            {
                "type": "MultiPolygon",
                "value": 105.0,
                "data": [
                    [
                        {
                            "lat": 55.35884016230761,
                            "lon": 61.39288572321481
                        },
                        {
                            "lat": 55.357886388237894,
                            "lon": 61.39116827230255
                        },
                        {
                            "lat": 55.354305065836414,
                            "lon": 61.39162559595766
                        },
                        {
                            "lat": 55.355258661446456,
                            "lon": 61.39334307932742
                        },
                        {
                            "lat": 55.35884016230761,
                            "lon": 61.39288572321481
                        }
                    ],
                    [
                        {
                            "lat": 55.36862963381156,
                            "lon": 61.38979574763068
                        },
                        {
                            "lat": 55.36504867224399,
                            "lon": 61.39025334813286
                        },
                        {
                            "lat": 55.36146759037992,
                            "lon": 61.390710856358886
                        },
                        {
                            "lat": 55.36242154288841,
                            "lon": 61.39242827480802
                        },
                        {
                            "lat": 55.36600280317036,
                            "lon": 61.39197073411331
                        },
                        {
                            "lat": 55.36958394313492,
                            "lon": 61.39151310113678
                        },
                        {
                            "lat": 55.36862963381156,
                            "lon": 61.38979574763068
                        }
                    ],
                    [
                        {
                            "lat": 55.37937179655017,
                            "lon": 61.38842239252886
                        },
                        {
                            "lat": 55.37579119598316,
                            "lon": 61.388880269822494
                        },
                        {
                            "lat": 55.37221047506412,
                            "lon": 61.38933805485849
                        },
                        {
                            "lat": 55.373164962763546,
                            "lon": 61.391055375884704
                        },
                        {
                            "lat": 55.374119570817406,
                            "lon": 61.39277268778996
                        },
                        {
                            "lat": 55.37770064846528,
                            "lon": 61.39231483777953
                        },
                        {
                            "lat": 55.38128160571929,
                            "lon": 61.3918568955003
                        },
                        {
                            "lat": 55.382236690911945,
                            "lon": 61.393574133292816
                        },
                        {
                            "lat": 55.38581770610394,
                            "lon": 61.393116066247494
                        },
                        {
                            "lat": 55.38677309009876,
                            "lon": 61.39483326240224
                        },
                        {
                            "lat": 55.39035416318686,
                            "lon": 61.39437507058239
                        },
                        {
                            "lat": 55.391309846020825,
                            "lon": 61.39609222508547
                        },
                        {
                            "lat": 55.392265649366955,
                            "lon": 61.397809370447774
                        },
                        {
                            "lat": 55.38868421952747,
                            "lon": 61.3982676273028
                        },
                        {
                            "lat": 55.38510266917336,
                            "lon": 61.398725791879016
                        },
                        {
                            "lat": 55.38152099832319,
                            "lon": 61.39918386417024
                        },
                        {
                            "lat": 55.38247638692924,
                            "lon": 61.400901097941514
                        },
                        {
                            "lat": 55.383431896033784,
                            "lon": 61.40261832257912
                        },
                        {
                            "lat": 55.38701392379318,
                            "lon": 61.4021601852615
                        },
                        {
                            "lat": 55.38605823622475,
                            "lon": 61.400442993138796
                        },
                        {
                            "lat": 55.38963996500325,
                            "lon": 61.39998479604548
                        },
                        {
                            "lat": 55.39059583101458,
                            "lon": 61.401701955647695
                        },
                        {
                            "lat": 55.39417761767948,
                            "lon": 61.40124363374387
                        },
                        {
                            "lat": 55.3932215732462,
                            "lon": 61.399526506667776
                        },
                        {
                            "lat": 55.39680306093509,
                            "lon": 61.39906812501183
                        },
                        {
                            "lat": 55.3977592837694,
                            "lon": 61.40078521955624
                        },
                        {
                            "lat": 55.40134082926579,
                            "lon": 61.40032671309101
                        },
                        {
                            "lat": 55.404922254150186,
                            "lon": 61.39986811435436
                        },
                        {
                            "lat": 55.40587895435416,
                            "lon": 61.40158513466299
                        },
                        {
                            "lat": 55.4068357752096,
                            "lon": 61.403302145814415
                        },
                        {
                            "lat": 55.407792716737475,
                            "lon": 61.405019147807074
                        },
                        {
                            "lat": 55.4113745561168,
                            "lon": 61.404560359139396
                        },
                        {
                            "lat": 55.41233179675022,
                            "lon": 61.406277319409654
                        },
                        {
                            "lat": 55.413289158116534,
                            "lon": 61.40799427051465
                        },
                        {
                            "lat": 55.4142466402367,
                            "lon": 61.409711212452784
                        },
                        {
                            "lat": 55.417828894133095,
                            "lon": 61.40925223379604
                        },
                        {
                            "lat": 55.41878667547479,
                            "lon": 61.41096913398791
                        },
                        {
                            "lat": 55.41974457763085,
                            "lon": 61.412686025006415
                        },
                        {
                            "lat": 55.42070260062225,
                            "lon": 61.414402906850015
                        },
                        {
                            "lat": 55.421660744469996,
                            "lon": 61.41611977951717
                        },
                        {
                            "lat": 55.41807777667589,
                            "lon": 61.41657888850603
                        },
                        {
                            "lat": 55.41449468802292,
                            "lon": 61.417037905179946
                        },
                        {
                            "lat": 55.41545259568562,
                            "lon": 61.41875483384656
                        },
                        {
                            "lat": 55.419035862880285,
                            "lon": 61.4182957845867
                        },
                        {
                            "lat": 55.42261900919511,
                            "lon": 61.41783664300628
                        },
                        {
                            "lat": 55.42620203461155,
                            "lon": 61.41737740911154
                        },
                        {
                            "lat": 55.42716059875324,
                            "lon": 61.4190942308205
                        },
                        {
                            "lat": 55.428119283832814,
                            "lon": 61.42081104334488
                        },
                        {
                            "lat": 55.42907808987131,
                            "lon": 61.422527846683145
                        },
                        {
                            "lat": 55.43003701688976,
                            "lon": 61.42424464083371
                        },
                        {
                            "lat": 55.42645327728945,
                            "lon": 61.42470400515115
                        },
                        {
                            "lat": 55.42741214671662,
                            "lon": 61.42642082272669
                        },
                        {
                            "lat": 55.43099606490921,
                            "lon": 61.425961425795016
                        },
                        {
                            "lat": 55.43457986207976,
                            "lon": 61.425501936527304
                        },
                        {
                            "lat": 55.43816353820977,
                            "lon": 61.42504235492971
                        },
                        {
                            "lat": 55.43912306440965,
                            "lon": 61.42675906544816
                        },
                        {
                            "lat": 55.442706798028254,
                            "lon": 61.4262993588925
                        },
                        {
                            "lat": 55.44366662387441,
                            "lon": 61.42801602757392
                        },
                        {
                            "lat": 55.44462657084021,
                            "lon": 61.429732687051164
                        },
                        {
                            "lat": 55.441042480070784,
                            "lon": 61.430192458885905
                        },
                        {
                            "lat": 55.44200236957413,
                            "lon": 61.43190914180204
                        },
                        {
                            "lat": 55.438417979058265,
                            "lon": 61.432368853935415
                        },
                        {
                            "lat": 55.43937781106214,
                            "lon": 61.43408556029014
                        },
                        {
                            "lat": 55.44296238022075,
                            "lon": 61.4336258155143
                        },
                        {
                            "lat": 55.443922512031705,
                            "lon": 61.43534248002108
                        },
                        {
                            "lat": 55.44488276502807,
                            "lon": 61.43705913532079
                        },
                        {
                            "lat": 55.44584313923091,
                            "lon": 61.43877578141194
                        },
                        {
                            "lat": 55.44680363466133,
                            "lon": 61.44049241829293
                        },
                        {
                            "lat": 55.44321835074644,
                            "lon": 61.44095229367295
                        },
                        {
                            "lat": 55.43963294560144,
                            "lon": 61.441412076672705
                        },
                        {
                            "lat": 55.43604741924484,
                            "lon": 61.44187176728603
                        },
                        {
                            "lat": 55.437007499654484,
                            "lon": 61.44358849291725
                        },
                        {
                            "lat": 55.440593204788634,
                            "lon": 61.443128769655544
                        },
                        {
                            "lat": 55.444178788690124,
                            "lon": 61.442668954001775
                        },
                        {
                            "lat": 55.447764251340395,
                            "lon": 61.44220904596217
                        },
                        {
                            "lat": 55.44872498928919,
                            "lon": 61.443925664418124
                        },
                        {
                            "lat": 55.44968584852883,
                            "lon": 61.44564227365919
                        },
                        {
                            "lat": 55.450646829080405,
                            "lon": 61.44735887368383
                        },
                        {
                            "lat": 55.45160793096502,
                            "lon": 61.44907546449047
                        },
                        {
                            "lat": 55.4525691542038,
                            "lon": 61.45079204607756
                        },
                        {
                            "lat": 55.44898279759856,
                            "lon": 61.45125211746642
                        },
                        {
                            "lat": 55.44994396336599,
                            "lon": 61.45296872251246
                        },
                        {
                            "lat": 55.450905250511255,
                            "lon": 61.45468531833923
                        },
                        {
                            "lat": 55.45449196482827,
                            "lon": 61.45422518158669
                        },
                        {
                            "lat": 55.45353049881785,
                            "lon": 61.452508618443474
                        },
                        {
                            "lat": 55.4571169128733,
                            "lon": 61.452048421961315
                        },
                        {
                            "lat": 55.45807855772777,
                            "lon": 61.45376495241538
                        },
                        {
                            "lat": 55.45904032401829,
                            "lon": 61.455481473641754
                        },
                        {
                            "lat": 55.46000221176598,
                            "lon": 61.457197985638814
                        },
                        {
                            "lat": 55.46358904093087,
                            "lon": 61.45673759865519
                        },
                        {
                            "lat": 55.46455122903542,
                            "lon": 61.45845406871641
                        },
                        {
                            "lat": 55.46096422099199,
                            "lon": 61.45891448840505
                        },
                        {
                            "lat": 55.46192635171747,
                            "lon": 61.46063098193887
                        },
                        {
                            "lat": 55.46288860396355,
                            "lon": 61.462347466238704
                        },
                        {
                            "lat": 55.46647596981968,
                            "lon": 61.4618869811297
                        },
                        {
                            "lat": 55.47006321411552,
                            "lon": 61.46142640358642
                        },
                        {
                            "lat": 55.46910060405933,
                            "lon": 61.459709984715964
                        },
                        {
                            "lat": 55.46813811556084,
                            "lon": 61.45799355660465
                        },
                        {
                            "lat": 55.467175748598905,
                            "lon": 61.456277119254075
                        },
                        {
                            "lat": 55.466213503152346,
                            "lon": 61.4545606726658
                        },
                        {
                            "lat": 55.46525137920007,
                            "lon": 61.45284421684137
                        },
                        {
                            "lat": 55.46428937672092,
                            "lon": 61.4511277517824
                        },
                        {
                            "lat": 55.46332749569376,
                            "lon": 61.44941127749045
                        },
                        {
                            "lat": 55.45974150326994,
                            "lon": 61.44987162608334
                        },
                        {
                            "lat": 55.45877992243836,
                            "lon": 61.44815510986645
                        },
                        {
                            "lat": 55.455193987388036,
                            "lon": 61.44861533337635
                        },
                        {
                            "lat": 55.454232706715004,
                            "lon": 61.44689877524857
                        },
                        {
                            "lat": 55.45327154739354,
                            "lon": 61.445182207899386
                        },
                        {
                            "lat": 55.456857124927765,
                            "lon": 61.44472204975502
                        },
                        {
                            "lat": 55.45589590820652,
                            "lon": 61.44300550586362
                        },
                        {
                            "lat": 55.45493481281317,
                            "lon": 61.44128895275047
                        },
                        {
                            "lat": 55.45851991159862,
                            "lon": 61.440828767590794
                        },
                        {
                            "lat": 55.457558758839966,
                            "lon": 61.43911223793389
                        },
                        {
                            "lat": 55.45659772738551,
                            "lon": 61.43739569905498
                        },
                        {
                            "lat": 55.45301298592574,
                            "lon": 61.437855818865316
                        },
                        {
                            "lat": 55.44942812320137,
                            "lon": 61.438315846319725
                        },
                        {
                            "lat": 55.448467570321284,
                            "lon": 61.43659923288691
                        },
                        {
                            "lat": 55.45205225438948,
                            "lon": 61.43613923809646
                        },
                        {
                            "lat": 55.451091644096756,
                            "lon": 61.43442264811216
                        },
                        {
                            "lat": 55.450131155026455,
                            "lon": 61.43270604891398
                        },
                        {
                            "lat": 55.453715360636515,
                            "lon": 61.43224602710196
                        },
                        {
                            "lat": 55.45275481418805,
                            "lon": 61.430529451350736
                        },
                        {
                            "lat": 55.451794388938374,
                            "lon": 61.42881286638535
                        },
                        {
                            "lat": 55.45083408486642,
                            "lon": 61.42709627220738
                        },
                        {
                            "lat": 55.44987390195115,
                            "lon": 61.42537966881838
                        },
                        {
                            "lat": 55.453457272218415,
                            "lon": 61.42491968531237
                        },
                        {
                            "lat": 55.452497031954266,
                            "lon": 61.4232031053651
                        },
                        {
                            "lat": 55.451536912823144,
                            "lon": 61.4214865162065
                        },
                        {
                            "lat": 55.45057691480403,
                            "lon": 61.41976991783818
                        },
                        {
                            "lat": 55.44961703787587,
                            "lon": 61.41805331026167
                        },
                        {
                            "lat": 55.453199573246216,
                            "lon": 61.417593365057876
                        },
                        {
                            "lat": 55.45678198752819,
                            "lon": 61.417133327578284
                        },
                        {
                            "lat": 55.45582187491198,
                            "lon": 61.41541677608697
                        },
                        {
                            "lat": 55.45940398973966,
                            "lon": 61.41495667899213
                        },
                        {
                            "lat": 55.458443819880316,
                            "lon": 61.41324015093976
                        },
                        {
                            "lat": 55.45748377110424,
                            "lon": 61.41152361367368
                        },
                        {
                            "lat": 55.46106540823029,
                            "lon": 61.41106348963058
                        },
                        {
                            "lat": 55.460105302245424,
                            "lon": 61.40934697580193
                        },
                        {
                            "lat": 55.45652384339036,
                            "lon": 61.40980706719541
                        },
                        {
                            "lat": 55.45294226349411,
                            "lon": 61.410267066347934
                        },
                        {
                            "lat": 55.45198263509498,
                            "lon": 61.40855047801846
                        },
                        {
                            "lat": 55.45556403671767,
                            "lon": 61.408090511506565
                        },
                        {
                            "lat": 55.4591453173202,
                            "lon": 61.40763045275925
                        },
                        {
                            "lat": 55.4581854534336,
                            "lon": 61.40591392050415
                        },
                        {
                            "lat": 55.45460435106513,
                            "lon": 61.406373946608646
                        },
                        {
                            "lat": 55.45364478641176,
                            "lon": 61.404657372503294
                        },
                        {
                            "lat": 55.45722571056459,
                            "lon": 61.404197379038145
                        },
                        {
                            "lat": 55.45626608869215,
                            "lon": 61.40248082836282
                        },
                        {
                            "lat": 55.45984671367227,
                            "lon": 61.40202077532119
                        },
                        {
                            "lat": 55.45888703461768,
                            "lon": 61.40030424807622
                        },
                        {
                            "lat": 55.46246736046707,
                            "lon": 61.39984413547203
                        },
                        {
                            "lat": 55.466047565325034,
                            "lon": 61.39938393067343
                        },
                        {
                            "lat": 55.465087651027915,
                            "lon": 61.39766745950502
                        },
                        {
                            "lat": 55.46150762426722,
                            "lon": 61.39812763165776
                        },
                        {
                            "lat": 55.46054800903781,
                            "lon": 61.39641111863204
                        },
                        {
                            "lat": 55.4595885147578,
                            "lon": 61.39469459639643
                        },
                        {
                            "lat": 55.463168185379274,
                            "lon": 61.39423448952516
                        },
                        {
                            "lat": 55.46220863398571,
                            "lon": 61.39251799071682
                        },
                        {
                            "lat": 55.465788005639354,
                            "lon": 61.39205782431529
                        },
                        {
                            "lat": 55.46482839716909,
                            "lon": 61.39034134893459
                        },
                        {
                            "lat": 55.463868909622114,
                            "lon": 61.38862486434187
                        },
                        {
                            "lat": 55.46290954297743,
                            "lon": 61.386908370538706
                        },
                        {
                            "lat": 55.46195029721407,
                            "lon": 61.38519186752661
                        },
                        {
                            "lat": 55.465528836107616,
                            "lon": 61.384731739518394
                        },
                        {
                            "lat": 55.46456953329663,
                            "lon": 61.38301525992872
                        },
                        {
                            "lat": 55.46361035134344,
                            "lon": 61.38129877112989
                        },
                        {
                            "lat": 55.46265129022701,
                            "lon": 61.37958227312339
                        },
                        {
                            "lat": 55.46169234992639,
                            "lon": 61.37786576591089
                        },
                        {
                            "lat": 55.45811452255416,
                            "lon": 61.378325763420655
                        },
                        {
                            "lat": 55.45453657440547,
                            "lon": 61.37878566881531
                        },
                        {
                            "lat": 55.450958505498704,
                            "lon": 61.379245482088606
                        },
                        {
                            "lat": 55.447380315852286,
                            "lon": 61.379705203234415
                        },
                        {
                            "lat": 55.44380200548462,
                            "lon": 61.38016483224649
                        },
                        {
                            "lat": 55.440223574414134,
                            "lon": 61.380624369118635
                        },
                        {
                            "lat": 55.43664502265922,
                            "lon": 61.38108381384464
                        },
                        {
                            "lat": 55.433066350238335,
                            "lon": 61.381543166418304
                        },
                        {
                            "lat": 55.42948755716989,
                            "lon": 61.38200242683345
                        },
                        {
                            "lat": 55.42590864347232,
                            "lon": 61.382461595083825
                        },
                        {
                            "lat": 55.42232960916407,
                            "lon": 61.38292067116331
                        },
                        {
                            "lat": 55.41875045426357,
                            "lon": 61.383379655065646
                        },
                        {
                            "lat": 55.41517117878931,
                            "lon": 61.38383854678464
                        },
                        {
                            "lat": 55.411591782759686,
                            "lon": 61.38429734631411
                        },
                        {
                            "lat": 55.4080122661932,
                            "lon": 61.38475605364787
                        },
                        {
                            "lat": 55.40443262910828,
                            "lon": 61.38521466877974
                        },
                        {
                            "lat": 55.40085287152342,
                            "lon": 61.385673191703454
                        },
                        {
                            "lat": 55.39727299345708,
                            "lon": 61.38613162241289
                        },
                        {
                            "lat": 55.393692994927754,
                            "lon": 61.38658996090182
                        },
                        {
                            "lat": 55.39011287595392,
                            "lon": 61.387048207164085
                        },
                        {
                            "lat": 55.38653263655403,
                            "lon": 61.387506361193466
                        },
                        {
                            "lat": 55.382952276746614,
                            "lon": 61.387964422983785
                        },
                        {
                            "lat": 55.37937179655017,
                            "lon": 61.38842239252886
                        }
                    ],
                    [
                        {
                            "lat": 55.41186920762961,
                            "lon": 61.419213790779686
                        },
                        {
                            "lat": 55.408285698730765,
                            "lon": 61.41967265537986
                        },
                        {
                            "lat": 55.409243370071295,
                            "lon": 61.421389640035656
                        },
                        {
                            "lat": 55.412827057572386,
                            "lon": 61.42093074285731
                        },
                        {
                            "lat": 55.41186920762961,
                            "lon": 61.419213790779686
                        }
                    ],
                    [
                        {
                            "lat": 55.42529011309097,
                            "lon": 61.443250284745105
                        },
                        {
                            "lat": 55.42624965704144,
                            "lon": 61.444967108287905
                        },
                        {
                            "lat": 55.42983572576233,
                            "lon": 61.444507662239666
                        },
                        {
                            "lat": 55.433421673306206,
                            "lon": 61.44404812378071
                        },
                        {
                            "lat": 55.432461771695216,
                            "lon": 61.44233136550671
                        },
                        {
                            "lat": 55.42887600297107,
                            "lon": 61.442790871328484
                        },
                        {
                            "lat": 55.42529011309097,
                            "lon": 61.443250284745105
                        }
                    ],
                    [
                        {
                            "lat": 55.46000221176598,
                            "lon": 61.457197985638814
                        },
                        {
                            "lat": 55.456415261122764,
                            "lon": 61.45765828019871
                        },
                        {
                            "lat": 55.45282818901978,
                            "lon": 61.45811848232864
                        },
                        {
                            "lat": 55.45378984042531,
                            "lon": 61.45983505048813
                        },
                        {
                            "lat": 55.45737709144911,
                            "lon": 61.45937481566438
                        },
                        {
                            "lat": 55.46096422099199,
                            "lon": 61.45891448840505
                        },
                        {
                            "lat": 55.46000221176598,
                            "lon": 61.457197985638814
                        }
                    ],
                    [
                        {
                            "lat": 55.464113306383325,
                            "lon": 61.47139030542421
                        },
                        {
                            "lat": 55.46770168874345,
                            "lon": 61.470929749172875
                        },
                        {
                            "lat": 55.471289949456,
                            "lon": 61.470469100453
                        },
                        {
                            "lat": 55.47032691028115,
                            "lon": 61.46875269507576
                        },
                        {
                            "lat": 55.47391487034033,
                            "lon": 61.468291986628245
                        },
                        {
                            "lat": 55.477502708736,
                            "lon": 61.46783118573025
                        },
                        {
                            "lat": 55.47846610586413,
                            "lon": 61.469547525632606
                        },
                        {
                            "lat": 55.482054001522584,
                            "lon": 61.46908659954459
                        },
                        {
                            "lat": 55.483017699317344,
                            "lon": 61.47080289744395
                        },
                        {
                            "lat": 55.47942962469547,
                            "lon": 61.47126385628125
                        },
                        {
                            "lat": 55.48039326525126,
                            "lon": 61.47298017767456
                        },
                        {
                            "lat": 55.483981518855096,
                            "lon": 61.47251918608456
                        },
                        {
                            "lat": 55.487569650694745,
                            "lon": 61.47205810203985
                        },
                        {
                            "lat": 55.48853377097596,
                            "lon": 61.473774348658424
                        },
                        {
                            "lat": 55.484945460157,
                            "lon": 61.474235465464886
                        },
                        {
                            "lat": 55.48590952324428,
                            "lon": 61.475951735583294
                        },
                        {
                            "lat": 55.48232091162089,
                            "lon": 61.476412792689
                        },
                        {
                            "lat": 55.483284917477135,
                            "lon": 61.478129086306986
                        },
                        {
                            "lat": 55.486873708138134,
                            "lon": 61.47766799643824
                        },
                        {
                            "lat": 55.48783801485976,
                            "lon": 61.47938424802814
                        },
                        {
                            "lat": 55.491426862727955,
                            "lon": 61.47892303291585
                        },
                        {
                            "lat": 55.49046237697137,
                            "lon": 61.47720681409801
                        },
                        {
                            "lat": 55.489498013061116,
                            "lon": 61.47549058601166
                        },
                        {
                            "lat": 55.4930863810528,
                            "lon": 61.47502934398032
                        },
                        {
                            "lat": 55.494050923958255,
                            "lon": 61.47674553929243
                        },
                        {
                            "lat": 55.49763934908027,
                            "lon": 61.476284172027874
                        },
                        {
                            "lat": 55.50122765231887,
                            "lon": 61.475822712310524
                        },
                        {
                            "lat": 55.50219267505304,
                            "lon": 61.477538832778656
                        },
                        {
                            "lat": 55.50315781971037,
                            "lon": 61.479254943966374
                        },
                        {
                            "lat": 55.50674635896572,
                            "lon": 61.47879332621021
                        },
                        {
                            "lat": 55.50771180455464,
                            "lon": 61.48050939531468
                        },
                        {
                            "lat": 55.50412308631213,
                            "lon": 61.48097104587216
                        },
                        {
                            "lat": 55.50508847487952,
                            "lon": 61.48268713849441
                        },
                        {
                            "lat": 55.501499455644435,
                            "lon": 61.48314872938775
                        },
                        {
                            "lat": 55.50246478715321,
                            "lon": 61.484864845527476
                        },
                        {
                            "lat": 55.503430240651554,
                            "lon": 61.486580952383925
                        },
                        {
                            "lat": 55.50701961799614,
                            "lon": 61.48611929588194
                        },
                        {
                            "lat": 55.50605398543377,
                            "lon": 61.484403221831535
                        },
                        {
                            "lat": 55.50964306170622,
                            "lon": 61.48394150566107
                        },
                        {
                            "lat": 55.50867737212773,
                            "lon": 61.48222545513217
                        },
                        {
                            "lat": 55.51226614737052,
                            "lon": 61.48176367930728
                        },
                        {
                            "lat": 55.51585480058937,
                            "lon": 61.48130181102601
                        },
                        {
                            "lat": 55.519443331765736,
                            "lon": 61.48083985029461
                        },
                        {
                            "lat": 55.520409558289515,
                            "lon": 61.48255580236539
                        },
                        {
                            "lat": 55.52399814634413,
                            "lon": 61.482093716359586
                        },
                        {
                            "lat": 55.52496467390794,
                            "lon": 61.48380962629582
                        },
                        {
                            "lat": 55.52855331879831,
                            "lon": 61.48334741500726
                        },
                        {
                            "lat": 55.52952014743921,
                            "lon": 61.485063282794975
                        },
                        {
                            "lat": 55.53048709824194,
                            "lon": 61.486779141272166
                        },
                        {
                            "lat": 55.526898095422936,
                            "lon": 61.48724141825033
                        },
                        {
                            "lat": 55.52330897043937,
                            "lon": 61.48770360276854
                        },
                        {
                            "lat": 55.52427568541971,
                            "lon": 61.489419517627894
                        },
                        {
                            "lat": 55.52786498941665,
                            "lon": 61.48895730026534
                        },
                        {
                            "lat": 55.53145417122775,
                            "lon": 61.48849499043725
                        },
                        {
                            "lat": 55.535043230834496,
                            "lon": 61.488032588149885
                        },
                        {
                            "lat": 55.53863216821835,
                            "lon": 61.48757009340951
                        },
                        {
                            "lat": 55.53959972136596,
                            "lon": 61.489285867537305
                        },
                        {
                            "lat": 55.54056739677624,
                            "lon": 61.49100163234295
                        },
                        {
                            "lat": 55.54415656983081,
                            "lon": 61.49053897941205
                        },
                        {
                            "lat": 55.5451245465089,
                            "lon": 61.49225470201685
                        },
                        {
                            "lat": 55.54609264551077,
                            "lon": 61.49397041529285
                        },
                        {
                            "lat": 55.54706086685773,
                            "lon": 61.49568611923847
                        },
                        {
                            "lat": 55.54802921057106,
                            "lon": 61.49740181385208
                        },
                        {
                            "lat": 55.55161897728438,
                            "lon": 61.49693893691823
                        },
                        {
                            "lat": 55.55065045455295,
                            "lon": 61.49522327519763
                        },
                        {
                            "lat": 55.55423991986329,
                            "lon": 61.49476033869465
                        },
                        {
                            "lat": 55.555208621591504,
                            "lon": 61.49647596751665
                        },
                        {
                            "lat": 55.55617744574444,
                            "lon": 61.498191586998196
                        },
                        {
                            "lat": 55.55976714662081,
                            "lon": 61.497728492227544
                        },
                        {
                            "lat": 55.56073627223229,
                            "lon": 61.499444069455954
                        },
                        {
                            "lat": 55.56432602963527,
                            "lon": 61.498980849307856
                        },
                        {
                            "lat": 55.56529545674233,
                            "lon": 61.50069638426899
                        },
                        {
                            "lat": 55.56888527062926,
                            "lon": 61.50023303873513
                        },
                        {
                            "lat": 55.56985499926892,
                            "lon": 61.50194853141496
                        },
                        {
                            "lat": 55.57082485047415,
                            "lon": 61.503664014737545
                        },
                        {
                            "lat": 55.571794824266284,
                            "lon": 61.50537948870132
                        },
                        {
                            "lat": 55.57276492066671,
                            "lon": 61.507094953304716
                        },
                        {
                            "lat": 55.56917439064123,
                            "lon": 61.50755843057596
                        },
                        {
                            "lat": 55.570144430590325,
                            "lon": 61.50927391876032
                        },
                        {
                            "lat": 55.56655359885146,
                            "lon": 61.50973733648095
                        },
                        {
                            "lat": 55.56752358231222,
                            "lon": 61.511452848246016
                        },
                        {
                            "lat": 55.56849368840826,
                            "lon": 61.513168350652805
                        },
                        {
                            "lat": 55.57208487840727,
                            "lon": 61.51270486704701
                        },
                        {
                            "lat": 55.575675945731085,
                            "lon": 61.51224129093643
                        },
                        {
                            "lat": 55.57926689036119,
                            "lon": 61.51177762232734
                        },
                        {
                            "lat": 55.57829624691124,
                            "lon": 61.51006221877027
                        },
                        {
                            "lat": 55.581886889753896,
                            "lon": 61.509598490630054
                        },
                        {
                            "lat": 55.580916189938094,
                            "lon": 61.50788311066192
                        },
                        {
                            "lat": 55.58450653103601,
                            "lon": 61.507419323004626
                        },
                        {
                            "lat": 55.58547740988718,
                            "lon": 61.50913467000953
                        },
                        {
                            "lat": 55.589067807292594,
                            "lon": 61.50867075691498
                        },
                        {
                            "lat": 55.59003898790412,
                            "lon": 61.510386061572056
                        },
                        {
                            "lat": 55.593629441574244,
                            "lon": 61.50992202303193
                        },
                        {
                            "lat": 55.59460092398315,
                            "lon": 61.511637285327076
                        },
                        {
                            "lat": 55.59819143387527,
                            "lon": 61.51117312133306
                        },
                        {
                            "lat": 55.599163218118534,
                            "lon": 61.51288834125216
                        },
                        {
                            "lat": 55.602753784189844,
                            "lon": 61.512424051795904
                        },
                        {
                            "lat": 55.60372587030447,
                            "lon": 61.51413922932483
                        },
                        {
                            "lat": 55.60731649251221,
                            "lon": 61.513674814398
                        },
                        {
                            "lat": 55.60828888053518,
                            "lon": 61.51538994952267
                        },
                        {
                            "lat": 55.61187955883653,
                            "lon": 61.51492540911696
                        },
                        {
                            "lat": 55.615470114192064,
                            "lon": 61.514460776247084
                        },
                        {
                            "lat": 55.616442983156986,
                            "lon": 61.51617583593032
                        },
                        {
                            "lat": 55.612852248804835,
                            "lon": 61.51664050182325
                        },
                        {
                            "lat": 55.613825061740364,
                            "lon": 61.518355585123615
                        },
                        {
                            "lat": 55.61479799766453,
                            "lon": 61.520070659016426
                        },
                        {
                            "lat": 55.61838909006535,
                            "lon": 61.51960592706691
                        },
                        {
                            "lat": 55.62198005943763,
                            "lon": 61.51914110264283
                        },
                        {
                            "lat": 55.625570905762864,
                            "lon": 61.51867618575046
                        },
                        {
                            "lat": 55.629161629022605,
                            "lon": 61.51821117639611
                        },
                        {
                            "lat": 55.63013540403654,
                            "lon": 61.519926108712355
                        },
                        {
                            "lat": 55.63372618317863,
                            "lon": 61.519460973846535
                        },
                        {
                            "lat": 55.637316839196835,
                            "lon": 61.51899574652581
                        },
                        {
                            "lat": 55.638291095264414,
                            "lon": 61.52071060329331
                        },
                        {
                            "lat": 55.639265474495794,
                            "lon": 61.522425450627615
                        },
                        {
                            "lat": 55.64285636527398,
                            "lon": 61.52196006471394
                        },
                        {
                            "lat": 55.64383104666948,
                            "lon": 61.523674869536094
                        },
                        {
                            "lat": 55.64742199320138,
                            "lon": 61.523209358086035
                        },
                        {
                            "lat": 55.65101281648961,
                            "lon": 61.52274375418325
                        },
                        {
                            "lat": 55.65198797904063,
                            "lon": 61.52445848338711
                        },
                        {
                            "lat": 55.65557885799973,
                            "lon": 61.52399275394036
                        },
                        {
                            "lat": 55.65655432278573,
                            "lon": 61.52570744059477
                        },
                        {
                            "lat": 55.660145257372754,
                            "lon": 61.52524158559574
                        },
                        {
                            "lat": 55.66112102443067,
                            "lon": 61.526956229686554
                        },
                        {
                            "lat": 55.66471201460266,
                            "lon": 61.52649024912696
                        },
                        {
                            "lat": 55.66830288139275,
                            "lon": 61.52602417612321
                        },
                        {
                            "lat": 55.671893624782484,
                            "lon": 61.525558010681635
                        },
                        {
                            "lat": 55.67287005197555,
                            "lon": 61.52727254593968
                        },
                        {
                            "lat": 55.67646085082745,
                            "lon": 61.52680625493071
                        },
                        {
                            "lat": 55.68005152622063,
                            "lon": 61.52633987149105
                        },
                        {
                            "lat": 55.68102843463119,
                            "lon": 61.52805433099419
                        },
                        {
                            "lat": 55.684619165403575,
                            "lon": 61.52758782197959
                        },
                        {
                            "lat": 55.68559637618758,
                            "lon": 61.529302238849574
                        },
                        {
                            "lat": 55.68918716229621,
                            "lon": 61.52883560425177
                        },
                        {
                            "lat": 55.690164675490514,
                            "lon": 61.53054997847444
                        },
                        {
                            "lat": 55.691142312263345,
                            "lon": 61.5322643432077
                        },
                        {
                            "lat": 55.694733332533794,
                            "lon": 61.531797549846374
                        },
                        {
                            "lat": 55.69832422918578,
                            "lon": 61.53133066405734
                        },
                        {
                            "lat": 55.701915002200856,
                            "lon": 61.53086368584697
                        },
                        {
                            "lat": 55.70289329917038,
                            "lon": 61.53257794154584
                        },
                        {
                            "lat": 55.70648412735262,
                            "lon": 61.53211083772817
                        },
                        {
                            "lat": 55.710074831839606,
                            "lon": 61.53164364149626
                        },
                        {
                            "lat": 55.713665412612905,
                            "lon": 61.53117635285644
                        },
                        {
                            "lat": 55.71725586965412,
                            "lon": 61.53070897181507
                        },
                        {
                            "lat": 55.720846202944834,
                            "lon": 61.53024149837848
                        },
                        {
                            "lat": 55.72443641246669,
                            "lon": 61.52977393255301
                        },
                        {
                            "lat": 55.72802649820124,
                            "lon": 61.529306274345
                        },
                        {
                            "lat": 55.73161646013012,
                            "lon": 61.52883852376082
                        },
                        {
                            "lat": 55.73520629823495,
                            "lon": 61.528370680806795
                        },
                        {
                            "lat": 55.73879601249735,
                            "lon": 61.5279027454893
                        },
                        {
                            "lat": 55.74238560289895,
                            "lon": 61.527434717814636
                        },
                        {
                            "lat": 55.74597506942137,
                            "lon": 61.5269665977892
                        },
                        {
                            "lat": 55.74956441204626,
                            "lon": 61.52649838541931
                        },
                        {
                            "lat": 55.75315363075524,
                            "lon": 61.52603008071135
                        },
                        {
                            "lat": 55.75674272552998,
                            "lon": 61.52556168367162
                        },
                        {
                            "lat": 55.760331696352125,
                            "lon": 61.52509319430652
                        },
                        {
                            "lat": 55.759350540807645,
                            "lon": 61.523379470351465
                        },
                        {
                            "lat": 55.75576174848481,
                            "lon": 61.52384792644089
                        },
                        {
                            "lat": 55.75478089537065,
                            "lon": 61.522134159656446
                        },
                        {
                            "lat": 55.75380016616597,
                            "lon": 61.52042038331999
                        },
                        {
                            "lat": 55.75738860154523,
                            "lon": 61.51995199377147
                        },
                        {
                            "lat": 55.760976913018204,
                            "lon": 61.5194835119206
                        },
                        {
                            "lat": 55.76456510056659,
                            "lon": 61.5190149377737
                        },
                        {
                            "lat": 55.76358395998164,
                            "lon": 61.51730125169915
                        },
                        {
                            "lat": 55.76260294331805,
                            "lon": 61.51558755606539
                        },
                        {
                            "lat": 55.76619065018255,
                            "lon": 61.515118956189085
                        },
                        {
                            "lat": 55.7652095790758,
                            "lon": 61.5134052842726
                        },
                        {
                            "lat": 55.76422863186567,
                            "lon": 61.51169160279671
                        },
                        {
                            "lat": 55.76781585818743,
                            "lon": 61.51122297720562
                        },
                        {
                            "lat": 55.771402960615816,
                            "lon": 61.51075425935978
                        },
                        {
                            "lat": 55.77042178073263,
                            "lon": 61.50904063487859
                        },
                        {
                            "lat": 55.77400858100758,
                            "lon": 61.50857185806888
                        },
                        {
                            "lat": 55.774989939132524,
                            "lon": 61.51028544926551
                        },
                        {
                            "lat": 55.77857679371924,
                            "lon": 61.509816546929166
                        },
                        {
                            "lat": 55.7775952573741,
                            "lon": 61.508102989022696
                        },
                        {
                            "lat": 55.78118180981388,
                            "lon": 61.507634027746306
                        },
                        {
                            "lat": 55.78020021921508,
                            "lon": 61.5059204935619
                        },
                        {
                            "lat": 55.783786469550996,
                            "lon": 61.505451473359415
                        },
                        {
                            "lat": 55.78737259594516,
                            "lon": 61.50498236094503
                        },
                        {
                            "lat": 55.78639077301026,
                            "lon": 61.50326888378269
                        },
                        {
                            "lat": 55.7899765973469,
                            "lon": 61.502799712468054
                        },
                        {
                            "lat": 55.7889947202713,
                            "lon": 61.50108625903025
                        },
                        {
                            "lat": 55.79258024259351,
                            "lon": 61.500617028829325
                        },
                        {
                            "lat": 55.79159831141372,
                            "lon": 61.4989035991163
                        },
                        {
                            "lat": 55.79518353176456,
                            "lon": 61.49843431004301
                        },
                        {
                            "lat": 55.79876862816517,
                            "lon": 61.49796492879983
                        },
                        {
                            "lat": 55.797786464939655,
                            "lon": 61.49625155612322
                        },
                        {
                            "lat": 55.79420154651712,
                            "lon": 61.4967209040549
                        },
                        {
                            "lat": 55.79061650416589,
                            "lon": 61.497190159822196
                        },
                        {
                            "lat": 55.78703133790423,
                            "lon": 61.49765932341882
                        },
                        {
                            "lat": 55.783446047750424,
                            "lon": 61.49812839483834
                        },
                        {
                            "lat": 55.784427498934434,
                            "lon": 61.499841900727716
                        },
                        {
                            "lat": 55.78084190684251,
                            "lon": 61.500310913253195
                        },
                        {
                            "lat": 55.78182330383945,
                            "lon": 61.50202444286134
                        },
                        {
                            "lat": 55.77823740976638,
                            "lon": 61.50249339647876
                        },
                        {
                            "lat": 55.779218752539755,
                            "lon": 61.5042069498055
                        },
                        {
                            "lat": 55.77563255644244,
                            "lon": 61.50467584450099
                        },
                        {
                            "lat": 55.77204623646147,
                            "lon": 61.50514464697738
                        },
                        {
                            "lat": 55.768459792615126,
                            "lon": 61.50561335722832
                        },
                        {
                            "lat": 55.769440724739574,
                            "lon": 61.5073270008342
                        },
                        {
                            "lat": 55.76585397881947,
                            "lon": 61.50779565212546
                        },
                        {
                            "lat": 55.762267109049056,
                            "lon": 61.508264211173014
                        },
                        {
                            "lat": 55.76128653339951,
                            "lon": 61.50655050102852
                        },
                        {
                            "lat": 55.76030608156044,
                            "lon": 61.50483678133107
                        },
                        {
                            "lat": 55.75932575351032,
                            "lon": 61.50312305208236
                        },
                        {
                            "lat": 55.75834554922765,
                            "lon": 61.50140931328395
                        },
                        {
                            "lat": 55.75736546869088,
                            "lon": 61.49969556493748
                        },
                        {
                            "lat": 55.75638551187855,
                            "lon": 61.49798180704462
                        },
                        {
                            "lat": 55.75997131243419,
                            "lon": 61.497513447544044
                        },
                        {
                            "lat": 55.763556989268615,
                            "lon": 61.497044995833036
                        },
                        {
                            "lat": 55.76714254236355,
                            "lon": 61.496576451917996
                        },
                        {
                            "lat": 55.76616217490335,
                            "lon": 61.49486278423393
                        },
                        {
                            "lat": 55.76974742616657,
                            "lon": 61.49439418138342
                        },
                        {
                            "lat": 55.77333255367523,
                            "lon": 61.493925486347095
                        },
                        {
                            "lat": 55.77235195386153,
                            "lon": 61.492211875632584
                        },
                        {
                            "lat": 55.77137147779917,
                            "lon": 61.49049825535913
                        },
                        {
                            "lat": 55.77495612552796,
                            "lon": 61.49002953467959
                        },
                        {
                            "lat": 55.7739755952193,
                            "lon": 61.488315938111775
                        },
                        {
                            "lat": 55.77299518863724,
                            "lon": 61.48660233198481
                        },
                        {
                            "lat": 55.76941089684242,
                            "lon": 61.48707098614198
                        },
                        {
                            "lat": 55.76843079190508,
                            "lon": 61.485357337201535
                        },
                        {
                            "lat": 55.76484655438447,
                            "lon": 61.48582586595093
                        },
                        {
                            "lat": 55.763866751055325,
                            "lon": 61.48411217421115
                        },
                        {
                            "lat": 55.7628870713518,
                            "lon": 61.48239847292406
                        },
                        {
                            "lat": 55.76190751525244,
                            "lon": 61.48068476209128
                        },
                        {
                            "lat": 55.76092808273578,
                            "lon": 61.478971041714445
                        },
                        {
                            "lat": 55.764511608594646,
                            "lon": 61.47850264593202
                        },
                        {
                            "lat": 55.76353212176942,
                            "lon": 61.47678894924584
                        },
                        {
                            "lat": 55.762552758502224,
                            "lon": 61.47507524301537
                        },
                        {
                            "lat": 55.76613580510315,
                            "lon": 61.47460682157698
                        },
                        {
                            "lat": 55.769718728149265,
                            "lon": 61.47413830802636
                        },
                        {
                            "lat": 55.773301527622316,
                            "lon": 61.47366970236981
                        },
                        {
                            "lat": 55.776884203504075,
                            "lon": 61.473201004613706
                        },
                        {
                            "lat": 55.7778642779629,
                            "lon": 61.4749145778705
                        },
                        {
                            "lat": 55.78144700797938,
                            "lon": 61.47444575476389
                        },
                        {
                            "lat": 55.780466755776295,
                            "lon": 61.47273221476436
                        },
                        {
                            "lat": 55.78404918442077,
                            "lon": 61.472263332828135
                        },
                        {
                            "lat": 55.78306887814086,
                            "lon": 61.47054981652789
                        },
                        {
                            "lat": 55.786651005456264,
                            "lon": 61.47008087577593
                        },
                        {
                            "lat": 55.79023300912893,
                            "lon": 61.46961184295527
                        },
                        {
                            "lat": 55.79381488914068,
                            "lon": 61.46914271807228
                        },
                        {
                            "lat": 55.794795728405546,
                            "lon": 61.47085613456142
                        },
                        {
                            "lat": 55.7983776623569,
                            "lon": 61.470386884340996
                        },
                        {
                            "lat": 55.79935880295928,
                            "lon": 61.472100257971334
                        },
                        {
                            "lat": 55.802940790807284,
                            "lon": 61.47163088240531
                        },
                        {
                            "lat": 55.80652265489678,
                            "lon": 61.47116141478485
                        },
                        {
                            "lat": 55.810104395209656,
                            "lon": 61.4706918551164
                        },
                        {
                            "lat": 55.81368601172766,
                            "lon": 61.47022220340625
                        },
                        {
                            "lat": 55.812704160792364,
                            "lon": 61.46850896297075
                        },
                        {
                            "lat": 55.80912272182536,
                            "lon": 61.468978581373904
                        },
                        {
                            "lat": 55.808141172214434,
                            "lon": 61.46726529804355
                        },
                        {
                            "lat": 55.811722433648605,
                            "lon": 61.46679571294392
                        },
                        {
                            "lat": 55.815303571312704,
                            "lon": 61.466326035819975
                        },
                        {
                            "lat": 55.814321790445845,
                            "lon": 61.46461280950904
                        },
                        {
                            "lat": 55.81334013334575,
                            "lon": 61.46289957360658
                        },
                        {
                            "lat": 55.816920792296365,
                            "lon": 61.462429871083366
                        },
                        {
                            "lat": 55.815939081506194,
                            "lon": 61.46071665889519
                        },
                        {
                            "lat": 55.819519439279446,
                            "lon": 61.46024689768104
                        },
                        {
                            "lat": 55.82050132748352,
                            "lon": 61.4619600765596
                        },
                        {
                            "lat": 55.82408173888904,
                            "lon": 61.46149019004159
                        },
                        {
                            "lat": 55.8276620264948,
                            "lon": 61.4610202115357
                        },
                        {
                            "lat": 55.831242190282616,
                            "lon": 61.460550141048266
                        },
                        {
                            "lat": 55.83482223023437,
                            "lon": 61.460079978585675
                        },
                        {
                            "lat": 55.83840214633191,
                            "lon": 61.45960972415425
                        },
                        {
                            "lat": 55.84198193855709,
                            "lon": 61.4591393777603
                        },
                        {
                            "lat": 55.84099898632053,
                            "lon": 61.45742639885542
                        },
                        {
                            "lat": 55.844578477391636,
                            "lon": 61.4569559938536
                        },
                        {
                            "lat": 55.848157844575645,
                            "lon": 61.4564854969075
                        },
                        {
                            "lat": 55.84717466173865,
                            "lon": 61.4547725750816
                        },
                        {
                            "lat": 55.850753727814926,
                            "lon": 61.454302019553374
                        },
                        {
                            "lat": 55.85433266998933,
                            "lon": 61.45383137209906
                        },
                        {
                            "lat": 55.85334925668882,
                            "lon": 61.4521185073619
                        },
                        {
                            "lat": 55.8523659672868,
                            "lon": 61.45040563299802
                        },
                        {
                            "lat": 55.85138280176182,
                            "lon": 61.44869274900909
                        },
                        {
                            "lat": 55.850399760092365,
                            "lon": 61.446979855396705
                        },
                        {
                            "lat": 55.84941684225702,
                            "lon": 61.445266952162534
                        },
                        {
                            "lat": 55.84843404823428,
                            "lon": 61.44355403930818
                        },
                        {
                            "lat": 55.84745137800271,
                            "lon": 61.44184111683535
                        },
                        {
                            "lat": 55.84646883154086,
                            "lon": 61.44012818474562
                        },
                        {
                            "lat": 55.84548640882728,
                            "lon": 61.43841524304065
                        },
                        {
                            "lat": 55.84450410984052,
                            "lon": 61.436702291722064
                        },
                        {
                            "lat": 55.84352193455916,
                            "lon": 61.43498933079152
                        },
                        {
                            "lat": 55.842539882961766,
                            "lon": 61.43327636025064
                        },
                        {
                            "lat": 55.841557955026886,
                            "lon": 61.43156338010106
                        },
                        {
                            "lat": 55.84057615073312,
                            "lon": 61.429850390344384
                        },
                        {
                            "lat": 55.83959447005905,
                            "lon": 61.42813739098233
                        },
                        {
                            "lat": 55.83861291298325,
                            "lon": 61.42642438201643
                        },
                        {
                            "lat": 55.83763147948433,
                            "lon": 61.424711363448395
                        },
                        {
                            "lat": 55.83665016954086,
                            "lon": 61.42299833527981
                        },
                        {
                            "lat": 55.835668983131484,
                            "lon": 61.421285297512355
                        },
                        {
                            "lat": 55.83468792023476,
                            "lon": 61.41957225014762
                        },
                        {
                            "lat": 55.83370698082933,
                            "lon": 61.417859193187255
                        },
                        {
                            "lat": 55.83272616489379,
                            "lon": 61.4161461266329
                        },
                        {
                            "lat": 55.831745472406794,
                            "lon": 61.41443305048619
                        },
                        {
                            "lat": 55.83076490334694,
                            "lon": 61.4127199647487
                        },
                        {
                            "lat": 55.829784457692845,
                            "lon": 61.41100686942216
                        },
                        {
                            "lat": 55.82880413542319,
                            "lon": 61.409293764508085
                        },
                        {
                            "lat": 55.82782393651657,
                            "lon": 61.4075806500082
                        },
                        {
                            "lat": 55.82684386095166,
                            "lon": 61.405867525924094
                        },
                        {
                            "lat": 55.825863908707085,
                            "lon": 61.40415439225739
                        },
                        {
                            "lat": 55.824884079761524,
                            "lon": 61.402441249009755
                        },
                        {
                            "lat": 55.82390437409362,
                            "lon": 61.400728096182746
                        },
                        {
                            "lat": 55.82292479168204,
                            "lon": 61.39901493377808
                        },
                        {
                            "lat": 55.821945332505464,
                            "lon": 61.39730176179731
                        },
                        {
                            "lat": 55.82096599654255,
                            "lon": 61.39558858024211
                        },
                        {
                            "lat": 55.819986783772,
                            "lon": 61.393875389114086
                        },
                        {
                            "lat": 55.819007694172456,
                            "lon": 61.392162188414886
                        },
                        {
                            "lat": 55.81802872772264,
                            "lon": 61.3904489781461
                        },
                        {
                            "lat": 55.81704988440123,
                            "lon": 61.38873575830938
                        },
                        {
                            "lat": 55.816071164186965,
                            "lon": 61.38702252890635
                        },
                        {
                            "lat": 55.815092567058464,
                            "lon": 61.38530928993861
                        },
                        {
                            "lat": 55.81411409299451,
                            "lon": 61.38359604140781
                        },
                        {
                            "lat": 55.81313574197377,
                            "lon": 61.38188278331558
                        },
                        {
                            "lat": 55.81215751397498,
                            "lon": 61.380169515663525
                        },
                        {
                            "lat": 55.811179408976855,
                            "lon": 61.37845623845327
                        },
                        {
                            "lat": 55.810201426958145,
                            "lon": 61.37674295168648
                        },
                        {
                            "lat": 55.80922356789754,
                            "lon": 61.375029655364706
                        },
                        {
                            "lat": 55.80824583177381,
                            "lon": 61.3733163494896
                        },
                        {
                            "lat": 55.80726821856568,
                            "lon": 61.3716030340628
                        },
                        {
                            "lat": 55.806290728251895,
                            "lon": 61.3698897090859
                        },
                        {
                            "lat": 55.80272044580593,
                            "lon": 61.37035872608873
                        },
                        {
                            "lat": 55.79915004050487,
                            "lon": 61.370827651434965
                        },
                        {
                            "lat": 55.80012717820504,
                            "lon": 61.37254104278922
                        },
                        {
                            "lat": 55.801104438763545,
                            "lon": 61.37425442460034
                        },
                        {
                            "lat": 55.79753355793004,
                            "lon": 61.37472332464803
                        },
                        {
                            "lat": 55.796556473728224,
                            "lon": 61.37300990965301
                        },
                        {
                            "lat": 55.79557951236673,
                            "lon": 61.37129648511827
                        },
                        {
                            "lat": 55.79200886140951,
                            "lon": 61.371765227132414
                        },
                        {
                            "lat": 55.79298564641108,
                            "lon": 61.3734786848421
                        },
                        {
                            "lat": 55.79396255423497,
                            "lon": 61.375192133015524
                        },
                        {
                            "lat": 55.79493958490237,
                            "lon": 61.376905571651065
                        },
                        {
                            "lat": 55.795916738434556,
                            "lon": 61.37861900074707
                        },
                        {
                            "lat": 55.796894014852796,
                            "lon": 61.38033242030195
                        },
                        {
                            "lat": 55.79787141417832,
                            "lon": 61.38204583031407
                        },
                        {
                            "lat": 55.79429958186265,
                            "lon": 61.38251467972132
                        },
                        {
                            "lat": 55.79527692762741,
                            "lon": 61.384228113379244
                        },
                        {
                            "lat": 55.798848936432414,
                            "lon": 61.38375923078183
                        },
                        {
                            "lat": 55.79982658163635,
                            "lon": 61.38547262170359
                        },
                        {
                            "lat": 55.803398643977644,
                            "lon": 61.38500361419306
                        },
                        {
                            "lat": 55.804376588656844,
                            "lon": 61.386716962364616
                        },
                        {
                            "lat": 55.80535465634647,
                            "lon": 61.38843030098346
                        },
                        {
                            "lat": 55.806332847067814,
                            "lon": 61.39014363004797
                        },
                        {
                            "lat": 55.80276025515994,
                            "lon": 61.39061273717666
                        },
                        {
                            "lat": 55.803738392376026,
                            "lon": 61.39232608989821
                        },
                        {
                            "lat": 55.80016550085361,
                            "lon": 61.39279513849842
                        },
                        {
                            "lat": 55.80114358452834,
                            "lon": 61.39450851487679
                        },
                        {
                            "lat": 55.797570393348785,
                            "lon": 61.394977504934666
                        },
                        {
                            "lat": 55.79854842344603,
                            "lon": 61.39669090496966
                        },
                        {
                            "lat": 55.79497493256676,
                            "lon": 61.39715983647134
                        },
                        {
                            "lat": 55.79140131864281,
                            "lon": 61.39762867620162
                        },
                        {
                            "lat": 55.79237911842882,
                            "lon": 61.39934213309437
                        },
                        {
                            "lat": 55.788805204759306,
                            "lon": 61.399810914242806
                        },
                        {
                            "lat": 55.789782950856214,
                            "lon": 61.40152439478966
                        },
                        {
                            "lat": 55.78620873739854,
                            "lon": 61.40199311734239
                        },
                        {
                            "lat": 55.782634400907796,
                            "lon": 61.402461748093785
                        },
                        {
                            "lat": 55.78165700834882,
                            "lon": 61.40074820116486
                        },
                        {
                            "lat": 55.780679738783526,
                            "lon": 61.399034644701125
                        },
                        {
                            "lat": 55.7842537217331,
                            "lon": 61.39856608032282
                        },
                        {
                            "lat": 55.78327639839674,
                            "lon": 61.39685254750733
                        },
                        {
                            "lat": 55.786850081633695,
                            "lon": 61.39638392452559
                        },
                        {
                            "lat": 55.785872704562415,
                            "lon": 61.39467041535851
                        },
                        {
                            "lat": 55.78489545045709,
                            "lon": 61.39295689665462
                        },
                        {
                            "lat": 55.783918319296454,
                            "lon": 61.39124336841552
                        },
                        {
                            "lat": 55.780345166117534,
                            "lon": 61.391711891857376
                        },
                        {
                            "lat": 55.77677189003329,
                            "lon": 61.3921803235309
                        },
                        {
                            "lat": 55.77579523511706,
                            "lon": 61.39046671941761
                        },
                        {
                            "lat": 55.77222201283796,
                            "lon": 61.390935026154494
                        },
                        {
                            "lat": 55.77319849106178,
                            "lon": 61.392648663429824
                        },
                        {
                            "lat": 55.77417509215477,
                            "lon": 61.394362291181935
                        },
                        {
                            "lat": 55.77060139358232,
                            "lon": 61.39483057245998
                        },
                        {
                            "lat": 55.771577940815924,
                            "lon": 61.39654422385072
                        },
                        {
                            "lat": 55.76800394259997,
                            "lon": 61.397012446493974
                        },
                        {
                            "lat": 55.76442982150849,
                            "lon": 61.397480577332715
                        },
                        {
                            "lat": 55.76345362783843,
                            "lon": 61.395766859631614
                        },
                        {
                            "lat": 55.767027572137515,
                            "lon": 61.39529876194529
                        },
                        {
                            "lat": 55.76605132452932,
                            "lon": 61.393585067878696
                        },
                        {
                            "lat": 55.76247755700455,
                            "lon": 61.394053132416055
                        },
                        {
                            "lat": 55.75890366666486,
                            "lon": 61.39452110515354
                        },
                        {
                            "lat": 55.75792789542335,
                            "lon": 61.3928073352851
                        },
                        {
                            "lat": 55.754354059085564,
                            "lon": 61.39327518308198
                        },
                        {
                            "lat": 55.753378587400235,
                            "lon": 61.39156137057466
                        },
                        {
                            "lat": 55.7498048051069,
                            "lon": 61.392029093439106
                        },
                        {
                            "lat": 55.74623090009547,
                            "lon": 61.39249672449585
                        },
                        {
                            "lat": 55.74265687238406,
                            "lon": 61.392964263738634
                        },
                        {
                            "lat": 55.7436318136,
                            "lon": 61.39467817560607
                        },
                        {
                            "lat": 55.744606877519054,
                            "lon": 61.39639207797952
                        },
                        {
                            "lat": 55.74558206416246,
                            "lon": 61.398105970857365
                        },
                        {
                            "lat": 55.74200738311793,
                            "lon": 61.39857351761729
                        },
                        {
                            "lat": 55.74298251558699,
                            "lon": 61.400287434117296
                        },
                        {
                            "lat": 55.73940753487369,
                            "lon": 61.400754922147925
                        },
                        {
                            "lat": 55.74038261313212,
                            "lon": 61.402468862269885
                        },
                        {
                            "lat": 55.736807332707556,
                            "lon": 61.402936291557396
                        },
                        {
                            "lat": 55.73778235671908,
                            "lon": 61.404650255301036
                        },
                        {
                            "lat": 55.738757503485616,
                            "lon": 61.40636420955299
                        },
                        {
                            "lat": 55.73518174626905,
                            "lon": 61.40683161319668
                        },
                        {
                            "lat": 55.73615683875551,
                            "lon": 61.40854559107191
                        },
                        {
                            "lat": 55.73258078170321,
                            "lon": 61.40901293594266
                        },
                        {
                            "lat": 55.73355581987333,
                            "lon": 61.410726937441005
                        },
                        {
                            "lat": 55.72997946294274,
                            "lon": 61.41119422352488
                        },
                        {
                            "lat": 55.72640298324777,
                            "lon": 61.41166141770106
                        },
                        {
                            "lat": 55.72542829933301,
                            "lon": 61.40994734999016
                        },
                        {
                            "lat": 55.72900460188967,
                            "lon": 61.40948018891749
                        },
                        {
                            "lat": 55.72802986357969,
                            "lon": 61.407766144825544
                        },
                        {
                            "lat": 55.727055247991544,
                            "lon": 61.40605209125063
                        },
                        {
                            "lat": 55.72347929965703,
                            "lon": 61.406519186126616
                        },
                        {
                            "lat": 55.72445373814319,
                            "lon": 61.40823327279814
                        },
                        {
                            "lat": 55.72087749000301,
                            "lon": 61.408700308867786
                        },
                        {
                            "lat": 55.71730111917735,
                            "lon": 61.40916725302817
                        },
                        {
                            "lat": 55.716327034958944,
                            "lon": 61.40745310017997
                        },
                        {
                            "lat": 55.71275071863171,
                            "lon": 61.40791991934473
                        },
                        {
                            "lat": 55.71177693420697,
                            "lon": 61.40620572394893
                        },
                        {
                            "lat": 55.70820067242069,
                            "lon": 61.40667241812632
                        },
                        {
                            "lat": 55.70917427967658,
                            "lon": 61.40838664659319
                        },
                        {
                            "lat": 55.710148009542195,
                            "lon": 61.41010086559599
                        },
                        {
                            "lat": 55.71372462568435,
                            "lon": 61.409634105273
                        },
                        {
                            "lat": 55.71469865538613,
                            "lon": 61.4113482817321
                        },
                        {
                            "lat": 55.71112186203878,
                            "lon": 61.41181507513307
                        },
                        {
                            "lat": 55.71209583718761,
                            "lon": 61.41352927520285
                        },
                        {
                            "lat": 55.70851874394338,
                            "lon": 61.41399600974607
                        },
                        {
                            "lat": 55.70949266450284,
                            "lon": 61.41571023342627
                        },
                        {
                            "lat": 55.71306993500992,
                            "lon": 61.41524346580371
                        },
                        {
                            "lat": 55.71404415552697,
                            "lon": 61.41695764693402
                        },
                        {
                            "lat": 55.71501849876005,
                            "lon": 61.41867181859219
                        },
                        {
                            "lat": 55.711440873672736,
                            "lon": 61.41913865238381
                        },
                        {
                            "lat": 55.712415162325705,
                            "lon": 61.420852847657954
                        },
                        {
                            "lat": 55.71599296473044,
                            "lon": 61.42038598077659
                        },
                        {
                            "lat": 55.71957064437656,
                            "lon": 61.419919021933964
                        },
                        {
                            "lat": 55.72054541041987,
                            "lon": 61.42163314154432
                        },
                        {
                            "lat": 55.7169675534594,
                            "lon": 61.42210013348564
                        },
                        {
                            "lat": 55.71338957371907,
                            "lon": 61.422567033460155
                        },
                        {
                            "lat": 55.71436410787408,
                            "lon": 61.42428120978883
                        },
                        {
                            "lat": 55.71794226496824,
                            "lon": 61.4238142767177
                        },
                        {
                            "lat": 55.71891709927824,
                            "lon": 61.42552841047119
                        },
                        {
                            "lat": 55.72249531092195,
                            "lon": 61.42506135232214
                        },
                        {
                            "lat": 55.723470445423324,
                            "lon": 61.426775443486356
                        },
                        {
                            "lat": 55.72444570278664,
                            "lon": 61.428489525165304
                        },
                        {
                            "lat": 55.7208671363869,
                            "lon": 61.42895664953592
                        },
                        {
                            "lat": 55.72184233922814,
                            "lon": 61.43067075484395
                        },
                        {
                            "lat": 55.718263472536684,
                            "lon": 61.43113782033604
                        },
                        {
                            "lat": 55.719238620819475,
                            "lon": 61.43285194927294
                        },
                        {
                            "lat": 55.71565945379371,
                            "lon": 61.43331895587264
                        },
                        {
                            "lat": 55.71663454748157,
                            "lon": 61.435033108438134
                        },
                        {
                            "lat": 55.713055080078846,
                            "lon": 61.43550005613151
                        },
                        {
                            "lat": 55.70947548980177,
                            "lon": 61.43596691180047
                        },
                        {
                            "lat": 55.71045035131306,
                            "lon": 61.43768112109855
                        },
                        {
                            "lat": 55.71403011913538,
                            "lon": 61.43721423232541
                        },
                        {
                            "lat": 55.715005281087564,
                            "lon": 61.438928399039746
                        },
                        {
                            "lat": 55.71142533570178,
                            "lon": 61.43939532092058
                        },
                        {
                            "lat": 55.71240044298925,
                            "lon": 61.441109511264955
                        },
                        {
                            "lat": 55.708820197101694,
                            "lon": 61.44157637420955
                        },
                        {
                            "lat": 55.70523982831228,
                            "lon": 61.442043145100484
                        },
                        {
                            "lat": 55.70165933663931,
                            "lon": 61.44250982393149
                        },
                        {
                            "lat": 55.70068476222489,
                            "lon": 61.44079553428698
                        },
                        {
                            "lat": 55.7042650762522,
                            "lon": 61.440328888550496
                        },
                        {
                            "lat": 55.70329044705437,
                            "lon": 61.438614622529734
                        },
                        {
                            "lat": 55.702315940697495,
                            "lon": 61.43690034703985
                        },
                        {
                            "lat": 55.698735981906786,
                            "lon": 61.437366926597676
                        },
                        {
                            "lat": 55.695155900314674,
                            "lon": 61.43783341410585
                        },
                        {
                            "lat": 55.69157569593941,
                            "lon": 61.438299809558096
                        },
                        {
                            "lat": 55.69254966940482,
                            "lon": 61.440014184294164
                        },
                        {
                            "lat": 55.68896916459156,
                            "lon": 61.44048052075515
                        },
                        {
                            "lat": 55.68994308315182,
                            "lon": 61.442194919106875
                        },
                        {
                            "lat": 55.686362277857945,
                            "lon": 61.44266119656266
                        },
                        {
                            "lat": 55.682781349793096,
                            "lon": 61.44312738193255
                        },
                        {
                            "lat": 55.67920029897552,
                            "lon": 61.44359347521019
                        },
                        {
                            "lat": 55.67561912542354,
                            "lon": 61.444059476389334
                        },
                        {
                            "lat": 55.67203782915539,
                            "lon": 61.44452538546366
                        },
                        {
                            "lat": 55.673010981637134,
                            "lon": 61.446239939664785
                        },
                        {
                            "lat": 55.66942938483365,
                            "lon": 61.44670578967263
                        },
                        {
                            "lat": 55.66584766532931,
                            "lon": 61.4471715475575
                        },
                        {
                            "lat": 55.66682058479738,
                            "lon": 61.448886158408015
                        },
                        {
                            "lat": 55.66323856471209,
                            "lon": 61.449351857200625
                        },
                        {
                            "lat": 55.66226582314239,
                            "lon": 61.44763721331318
                        },
                        {
                            "lat": 55.658683858291184,
                            "lon": 61.44810278693328
                        },
                        {
                            "lat": 55.65510177079403,
                            "lon": 61.448568268411584
                        },
                        {
                            "lat": 55.65151956066923,
                            "lon": 61.44903365774171
                        },
                        {
                            "lat": 55.65054747553176,
                            "lon": 61.447318905356035
                        },
                        {
                            "lat": 55.64957551298233,
                            "lon": 61.445604143551414
                        },
                        {
                            "lat": 55.64599353616033,
                            "lon": 61.44606937470794
                        },
                        {
                            "lat": 55.64696532076286,
                            "lon": 61.447784169520446
                        },
                        {
                            "lat": 55.64338304342423,
                            "lon": 61.448249341529696
                        },
                        {
                            "lat": 55.64241143678991,
                            "lon": 61.446534513714866
                        },
                        {
                            "lat": 55.63882921488936,
                            "lon": 61.44699956056591
                        },
                        {
                            "lat": 55.6378579087563,
                            "lon": 61.445284690347314
                        },
                        {
                            "lat": 55.63427574233639,
                            "lon": 61.4457496120484
                        },
                        {
                            "lat": 55.63330473666797,
                            "lon": 61.44403469944008
                        },
                        {
                            "lat": 55.63233385345044,
                            "lon": 61.44231977743142
                        },
                        {
                            "lat": 55.6287519205307,
                            "lon": 61.44278454101554
                        },
                        {
                            "lat": 55.62516986519969,
                            "lon": 61.44324921244156
                        },
                        {
                            "lat": 55.62419946035046,
                            "lon": 61.44153421509597
                        },
                        {
                            "lat": 55.62778133770154,
                            "lon": 61.44106957663625
                        },
                        {
                            "lat": 55.626810877262436,
                            "lon": 61.43935460286326
                        },
                        {
                            "lat": 55.625840539192154,
                            "lon": 61.437639619698174
                        },
                        {
                            "lat": 55.624870323469445,
                            "lon": 61.4359246271426
                        },
                        {
                            "lat": 55.62128897994821,
                            "lon": 61.436389166724034
                        },
                        {
                            "lat": 55.62031906445846,
                            "lon": 61.434674131826995
                        },
                        {
                            "lat": 55.61934927125548,
                            "lon": 61.43295908754611
                        },
                        {
                            "lat": 55.615768161263645,
                            "lon": 61.433423469094905
                        },
                        {
                            "lat": 55.61218692902457,
                            "lon": 61.43388775850637
                        },
                        {
                            "lat": 55.61121761389076,
                            "lon": 61.432172638968574
                        },
                        {
                            "lat": 55.61024842096462,
                            "lon": 61.43045751005699
                        },
                        {
                            "lat": 55.61382929739717,
                            "lon": 61.429993286511156
                        },
                        {
                            "lat": 55.61286004878173,
                            "lon": 61.42827818115502
                        },
                        {
                            "lat": 55.61189092234984,
                            "lon": 61.42656306642487
                        },
                        {
                            "lat": 55.61092191808032,
                            "lon": 61.42484794232225
                        },
                        {
                            "lat": 55.6073415752201,
                            "lon": 61.42531206709545
                        },
                        {
                            "lat": 55.606372870912544,
                            "lon": 61.42359690070468
                        },
                        {
                            "lat": 55.60995303595197,
                            "lon": 61.42313280884882
                        },
                        {
                            "lat": 55.608984275943556,
                            "lon": 61.42141766600609
                        },
                        {
                            "lat": 55.608015638033926,
                            "lon": 61.4197025137957
                        },
                        {
                            "lat": 55.61159532540914,
                            "lon": 61.41923839567129
                        },
                        {
                            "lat": 55.610626631832424,
                            "lon": 61.41752326700744
                        },
                        {
                            "lat": 55.60965806033041,
                            "lon": 61.41580812897563
                        },
                        {
                            "lat": 55.60868961088194,
                            "lon": 61.41409298157751
                        },
                        {
                            "lat": 55.61226864304576,
                            "lon": 61.41362887011057
                        },
                        {
                            "lat": 55.61130013796106,
                            "lon": 61.41191374625551
                        },
                        {
                            "lat": 55.610331754905815,
                            "lon": 61.41019861303389
                        },
                        {
                            "lat": 55.6093634938589,
                            "lon": 61.40848347044726
                        },
                        {
                            "lat": 55.61294187108476,
                            "lon": 61.4080193656464
                        },
                        {
                            "lat": 55.61652012630518,
                            "lon": 61.40755516880477
                        },
                        {
                            "lat": 55.615551632081505,
                            "lon": 61.405840082666394
                        },
                        {
                            "lat": 55.614583259860396,
                            "lon": 61.404124987159335
                        },
                        {
                            "lat": 55.61361500962068,
                            "lon": 61.40240988228523
                        },
                        {
                            "lat": 55.61264688134119,
                            "lon": 61.40069476804559
                        },
                        {
                            "lat": 55.61622430444676,
                            "lon": 61.400230610805416
                        },
                        {
                            "lat": 55.61525612062925,
                            "lon": 61.39851552010229
                        },
                        {
                            "lat": 55.61428805874796,
                            "lon": 61.396800420033415
                        },
                        {
                            "lat": 55.61332011878176,
                            "lon": 61.3950853106004
                        },
                        {
                            "lat": 55.61235230070948,
                            "lon": 61.39337019180483
                        },
                        {
                            "lat": 55.615928892143415,
                            "lon": 61.39290607416174
                        },
                        {
                            "lat": 55.61496101856103,
                            "lon": 61.39119097889741
                        },
                        {
                            "lat": 55.611384604510015,
                            "lon": 61.391655063648294
                        },
                        {
                            "lat": 55.61041703016221,
                            "lon": 61.38993992613234
                        },
                        {
                            "lat": 55.60944957764498,
                            "lon": 61.38822477925864
                        },
                        {
                            "lat": 55.61302563698492,
                            "lon": 61.387760760281886
                        },
                        {
                            "lat": 55.61660157449716,
                            "lon": 61.38729664934411
                        },
                        {
                            "lat": 55.61756938168611,
                            "lon": 61.38901173044159
                        },
                        {
                            "lat": 55.621145374656585,
                            "lon": 61.38854749465261
                        },
                        {
                            "lat": 55.62472124574177,
                            "lon": 61.388083166909595
                        },
                        {
                            "lat": 55.62375308396561,
                            "lon": 61.386368151610505
                        },
                        {
                            "lat": 55.62278504407186,
                            "lon": 61.384653126941494
                        },
                        {
                            "lat": 55.61920952753454,
                            "lon": 61.38511738888402
                        },
                        {
                            "lat": 55.618241786748655,
                            "lon": 61.383402321951586
                        },
                        {
                            "lat": 55.61727416778473,
                            "lon": 61.38168724565582
                        },
                        {
                            "lat": 55.613698883921494,
                            "lon": 61.382151349879656
                        },
                        {
                            "lat": 55.61273156398959,
                            "lon": 61.38043623133948
                        },
                        {
                            "lat": 55.61176436581922,
                            "lon": 61.37872110344259
                        },
                        {
                            "lat": 55.60818931465978,
                            "lon": 61.37918504997736
                        },
                        {
                            "lat": 55.60915633559608,
                            "lon": 61.380900210748
                        },
                        {
                            "lat": 55.605580985459305,
                            "lon": 61.38136409821767
                        },
                        {
                            "lat": 55.60654795086546,
                            "lon": 61.38307928250665
                        },
                        {
                            "lat": 55.6075150380177,
                            "lon": 61.38479445744418
                        },
                        {
                            "lat": 55.60393921154863,
                            "lon": 61.38525831870434
                        },
                        {
                            "lat": 55.60490624313708,
                            "lon": 61.386973517161806
                        },
                        {
                            "lat": 55.6013301175669,
                            "lon": 61.38743731932692
                        },
                        {
                            "lat": 55.60229709355505,
                            "lon": 61.389152541304064
                        },
                        {
                            "lat": 55.59872066884156,
                            "lon": 61.38961628436021
                        },
                        {
                            "lat": 55.59968758919286,
                            "lon": 61.39133152985673
                        },
                        {
                            "lat": 55.59611086529385,
                            "lon": 61.39179521379004
                        },
                        {
                            "lat": 55.59707772997179,
                            "lon": 61.39351048280565
                        },
                        {
                            "lat": 55.60065463131995,
                            "lon": 61.39304676600574
                        },
                        {
                            "lat": 55.601621795243894,
                            "lon": 61.39476199280567
                        },
                        {
                            "lat": 55.602589080985865,
                            "lon": 61.39647721025493
                        },
                        {
                            "lat": 55.5990118246846,
                            "lon": 61.396940992798335
                        },
                        {
                            "lat": 55.5954344465625,
                            "lon": 61.397404683327274
                        },
                        {
                            "lat": 55.59446751581303,
                            "lon": 61.39568940013664
                        },
                        {
                            "lat": 55.59350070684502,
                            "lon": 61.39397410760217
                        },
                        {
                            "lat": 55.59253401963734,
                            "lon": 61.39225880572548
                        },
                        {
                            "lat": 55.588957052241604,
                            "lon": 61.392722305656875
                        },
                        {
                            "lat": 55.587990664246234,
                            "lon": 61.39100696158744
                        },
                        {
                            "lat": 55.58441375262375,
                            "lon": 61.39147033666196
                        },
                        {
                            "lat": 55.583447663804115,
                            "lon": 61.38975495041379
                        },
                        {
                            "lat": 55.579870807997075,
                            "lon": 61.39021820063969
                        },
                        {
                            "lat": 55.57629383054788,
                            "lon": 61.390681358853875
                        },
                        {
                            "lat": 55.575328218367225,
                            "lon": 61.38896589761238
                        },
                        {
                            "lat": 55.57890501831661,
                            "lon": 61.388502772226786
                        },
                        {
                            "lat": 55.577939350257225,
                            "lon": 61.38678733448829
                        },
                        {
                            "lat": 55.58151585112512,
                            "lon": 61.38632414992724
                        },
                        {
                            "lat": 55.58509223037473,
                            "lon": 61.38586087337178
                        },
                        {
                            "lat": 55.584126329052125,
                            "lon": 61.3841454919694
                        },
                        {
                            "lat": 55.5877024092652,
                            "lon": 61.38368215626418
                        },
                        {
                            "lat": 55.59127836784454,
                            "lon": 61.3832187285826
                        },
                        {
                            "lat": 55.59031223339505,
                            "lon": 61.38150340352635
                        },
                        {
                            "lat": 55.58934622057645,
                            "lon": 61.379788069135536
                        },
                        {
                            "lat": 55.59292170284299,
                            "lon": 61.3793246151725
                        },
                        {
                            "lat": 55.59195563433121,
                            "lon": 61.3776093042879
                        },
                        {
                            "lat": 55.588380329367716,
                            "lon": 61.37807272541176
                        },
                        {
                            "lat": 55.587414559747735,
                            "lon": 61.376357372356594
                        },
                        {
                            "lat": 55.58644891169543,
                            "lon": 61.37464200997163
                        },
                        {
                            "lat": 55.58287383973659,
                            "lon": 61.37510527348498
                        },
                        {
                            "lat": 55.57929864624943,
                            "lon": 61.3755684450497
                        },
                        {
                            "lat": 55.578333474303456,
                            "lon": 61.37385300769531
                        },
                        {
                            "lat": 55.57736842384655,
                            "lon": 61.372137561021134
                        },
                        {
                            "lat": 55.57640349485762,
                            "lon": 61.37042210502875
                        },
                        {
                            "lat": 55.57997815652842,
                            "lon": 61.369959031907264
                        },
                        {
                            "lat": 55.58355269673401,
                            "lon": 61.36949586685374
                        },
                        {
                            "lat": 55.58258753474184,
                            "lon": 61.36778046716542
                        },
                        {
                            "lat": 55.581622494212084,
                            "lon": 61.36606505815519
                        },
                        {
                            "lat": 55.580657575123674,
                            "lon": 61.364349639824674
                        },
                        {
                            "lat": 55.57969277745559,
                            "lon": 61.36263421217542
                        },
                        {
                            "lat": 55.57611894599884,
                            "lon": 61.36309724599702
                        },
                        {
                            "lat": 55.57254499316104,
                            "lon": 61.36356018790879
                        },
                        {
                            "lat": 55.56897091896043,
                            "lon": 61.364023037904445
                        },
                        {
                            "lat": 55.56539672341527,
                            "lon": 61.364485795977814
                        },
                        {
                            "lat": 55.56182240654382,
                            "lon": 61.36494846212263
                        },
                        {
                            "lat": 55.55824796836431,
                            "lon": 61.36541103633267
                        },
                        {
                            "lat": 55.55467340889503,
                            "lon": 61.365873518601695
                        },
                        {
                            "lat": 55.55109872815423,
                            "lon": 61.36633590892346
                        },
                        {
                            "lat": 55.547523926160196,
                            "lon": 61.36679820729179
                        },
                        {
                            "lat": 55.5439490029312,
                            "lon": 61.367260413700386
                        },
                        {
                            "lat": 55.54037395848554,
                            "lon": 61.36772252814309
                        },
                        {
                            "lat": 55.536798792841466,
                            "lon": 61.36818455061363
                        },
                        {
                            "lat": 55.533223506017336,
                            "lon": 61.36864648110578
                        },
                        {
                            "lat": 55.52964809803137,
                            "lon": 61.36910831961335
                        },
                        {
                            "lat": 55.526072568901924,
                            "lon": 61.36957006613009
                        },
                        {
                            "lat": 55.5224969186473,
                            "lon": 61.37003172064977
                        },
                        {
                            "lat": 55.51892114728578,
                            "lon": 61.370493283166226
                        },
                        {
                            "lat": 55.5153452548357,
                            "lon": 61.370954753673146
                        },
                        {
                            "lat": 55.51176924131538,
                            "lon": 61.37141613216438
                        },
                        {
                            "lat": 55.50819310674313,
                            "lon": 61.37187741863366
                        },
                        {
                            "lat": 55.50461685113732,
                            "lon": 61.372338613074824
                        },
                        {
                            "lat": 55.50104047451624,
                            "lon": 61.37279971548163
                        },
                        {
                            "lat": 55.49746397689826,
                            "lon": 61.37326072584782
                        },
                        {
                            "lat": 55.4938873583017,
                            "lon": 61.37372164416723
                        },
                        {
                            "lat": 55.49031061874493,
                            "lon": 61.37418247043364
                        },
                        {
                            "lat": 55.4867337582463,
                            "lon": 61.374643204640805
                        },
                        {
                            "lat": 55.48315677682415,
                            "lon": 61.375103846782544
                        },
                        {
                            "lat": 55.484116783800864,
                            "lon": 61.37682015816108
                        },
                        {
                            "lat": 55.487693942928956,
                            "lon": 61.37635948336087
                        },
                        {
                            "lat": 55.48865424855609,
                            "lon": 61.378075752851004
                        },
                        {
                            "lat": 55.485076911703686,
                            "lon": 61.3785364603131
                        },
                        {
                            "lat": 55.4860371605536,
                            "lon": 61.38025275323705
                        },
                        {
                            "lat": 55.486997530371596,
                            "lon": 61.381969036931366
                        },
                        {
                            "lat": 55.49057522272774,
                            "lon": 61.38150826413524
                        },
                        {
                            "lat": 55.491535891314236,
                            "lon": 61.383224505926236
                        },
                        {
                            "lat": 55.487958021178656,
                            "lon": 61.38368531139449
                        },
                        {
                            "lat": 55.4889186329958,
                            "lon": 61.385401576624815
                        },
                        {
                            "lat": 55.48534046403136,
                            "lon": 61.38586232266358
                        },
                        {
                            "lat": 55.481762174054246,
                            "lon": 61.38632297659104
                        },
                        {
                            "lat": 55.48272255120706,
                            "lon": 61.38803929792824
                        },
                        {
                            "lat": 55.48368304937519,
                            "lon": 61.38975561003635
                        },
                        {
                            "lat": 55.480104282627025,
                            "lon": 61.39021623717426
                        },
                        {
                            "lat": 55.481064723915466,
                            "lon": 61.39193257272079
                        },
                        {
                            "lat": 55.48202528624283,
                            "lon": 61.39364889903853
                        },
                        {
                            "lat": 55.48298596963011,
                            "lon": 61.395365216125896
                        },
                        {
                            "lat": 55.483946774098314,
                            "lon": 61.39708152398134
                        },
                        {
                            "lat": 55.48490769966848,
                            "lon": 61.39879782260324
                        },
                        {
                            "lat": 55.48132792203349,
                            "lon": 61.39925852095798
                        },
                        {
                            "lat": 55.48228879069762,
                            "lon": 61.40097484302536
                        },
                        {
                            "lat": 55.48324978048736,
                            "lon": 61.402691155859586
                        },
                        {
                            "lat": 55.48421089142375,
                            "lon": 61.404407459458966
                        },
                        {
                            "lat": 55.48063045847882,
                            "lon": 61.40486816368003
                        },
                        {
                            "lat": 55.48159151247758,
                            "lon": 61.40658449072831
                        },
                        {
                            "lat": 55.478010780257065,
                            "lon": 61.40704513543716
                        },
                        {
                            "lat": 55.478971777281345,
                            "lon": 61.40876148593402
                        },
                        {
                            "lat": 55.47993289547852,
                            "lon": 61.410477827198214
                        },
                        {
                            "lat": 55.476351685756114,
                            "lon": 61.410938445061866
                        },
                        {
                            "lat": 55.477312746944555,
                            "lon": 61.412654809776164
                        },
                        {
                            "lat": 55.47827392932957,
                            "lon": 61.41437116525809
                        },
                        {
                            "lat": 55.47469224196575,
                            "lon": 61.414831756261634
                        },
                        {
                            "lat": 55.475653367307736,
                            "lon": 61.41654813519523
                        },
                        {
                            "lat": 55.47207138046307,
                            "lon": 61.417008666640356
                        },
                        {
                            "lat": 55.47303244872516,
                            "lon": 61.41872506902526
                        },
                        {
                            "lat": 55.46945016235752,
                            "lon": 61.41918554089802
                        },
                        {
                            "lat": 55.47041117350281,
                            "lon": 61.42090196673397
                        },
                        {
                            "lat": 55.466828587570085,
                            "lon": 61.421362379020366
                        },
                        {
                            "lat": 55.46324588043038,
                            "lon": 61.42182269903291
                        },
                        {
                            "lat": 55.45966305210221,
                            "lon": 61.42228292676538
                        },
                        {
                            "lat": 55.45608010260399,
                            "lon": 61.42274306221149
                        },
                        {
                            "lat": 55.45704052133157,
                            "lon": 61.424459609502
                        },
                        {
                            "lat": 55.46062364927216,
                            "lon": 61.42399944139349
                        },
                        {
                            "lat": 55.464206656021645,
                            "lon": 61.42353918099308
                        },
                        {
                            "lat": 55.467789541561594,
                            "lon": 61.423078828307
                        },
                        {
                            "lat": 55.468750616781136,
                            "lon": 61.42479526836711
                        },
                        {
                            "lat": 55.469711813249795,
                            "lon": 61.4265116991991
                        },
                        {
                            "lat": 55.46612857085393,
                            "lon": 61.42697211724249
                        },
                        {
                            "lat": 55.462545207206375,
                            "lon": 61.42743244298904
                        },
                        {
                            "lat": 55.463506168012756,
                            "lon": 61.42914892995335
                        },
                        {
                            "lat": 55.45992250463415,
                            "lon": 61.42960919606944
                        },
                        {
                            "lat": 55.4608834081785,
                            "lon": 61.431325706485346
                        },
                        {
                            "lat": 55.46184443297973,
                            "lon": 61.43304220767868
                        },
                        {
                            "lat": 55.46542845340949,
                            "lon": 61.4325818762071
                        },
                        {
                            "lat": 55.46901235254278,
                            "lon": 61.43212145241567
                        },
                        {
                            "lat": 55.46997385570748,
                            "lon": 61.433837879013474
                        },
                        {
                            "lat": 55.47093548020823,
                            "lon": 61.435554296378804
                        },
                        {
                            "lat": 55.47189722606612,
                            "lon": 61.43727070450998
                        },
                        {
                            "lat": 55.47285909330223,
                            "lon": 61.438987103405466
                        },
                        {
                            "lat": 55.469274479929084,
                            "lon": 61.43944765797134
                        },
                        {
                            "lat": 55.47023628995832,
                            "lon": 61.44116408033172
                        },
                        {
                            "lat": 55.471198221389514,
                            "lon": 61.44288049345672
                        },
                        {
                            "lat": 55.47216027424376,
                            "lon": 61.444596897344724
                        },
                        {
                            "lat": 55.47312244854218,
                            "lon": 61.446313291994166
                        },
                        {
                            "lat": 55.47408474430592,
                            "lon": 61.448029677403504
                        },
                        {
                            "lat": 55.47504716155608,
                            "lon": 61.44974605357112
                        },
                        {
                            "lat": 55.478632846844015,
                            "lon": 61.44928530274088
                        },
                        {
                            "lat": 55.47959556431897,
                            "lon": 61.45100163694252
                        },
                        {
                            "lat": 55.48318130677695,
                            "lon": 61.45054076101445
                        },
                        {
                            "lat": 55.48414432451374,
                            "lon": 61.45225705323609
                        },
                        {
                            "lat": 55.485107463837416,
                            "lon": 61.45397333620443
                        },
                        {
                            "lat": 55.48869344213512,
                            "lon": 61.4535123024294
                        },
                        {
                            "lat": 55.48965688179727,
                            "lon": 61.45522854339871
                        },
                        {
                            "lat": 55.49324291717779,
                            "lon": 61.454767384500066
                        },
                        {
                            "lat": 55.49420665721548,
                            "lon": 61.45648358345631
                        },
                        {
                            "lat": 55.49517051894054,
                            "lon": 61.45819977314769
                        },
                        {
                            "lat": 55.49613450237411,
                            "lon": 61.45991595357261
                        },
                        {
                            "lat": 55.492547930754554,
                            "lon": 61.46037721074119
                        },
                        {
                            "lat": 55.49158412608585,
                            "lon": 61.458660997556166
                        },
                        {
                            "lat": 55.48799761154113,
                            "lon": 61.4591221295741
                        },
                        {
                            "lat": 55.488961237423815,
                            "lon": 61.460838375513575
                        },
                        {
                            "lat": 55.48992498499918,
                            "lon": 61.46255461219194
                        },
                        {
                            "lat": 55.49088885428838,
                            "lon": 61.46427083960755
                        },
                        {
                            "lat": 55.487301681595,
                            "lon": 61.46473197748562
                        },
                        {
                            "lat": 55.48371438718511,
                            "lon": 61.46519302294383
                        },
                        {
                            "lat": 55.482750875605575,
                            "lon": 61.46347673002231
                        },
                        {
                            "lat": 55.47916363838432,
                            "lon": 61.46393765030985
                        },
                        {
                            "lat": 55.47557627950481,
                            "lon": 61.464398478170665
                        },
                        {
                            "lat": 55.474613247359365,
                            "lon": 61.46268211051657
                        },
                        {
                            "lat": 55.47365033683246,
                            "lon": 61.46096573361508
                        },
                        {
                            "lat": 55.47006321411552,
                            "lon": 61.46142640358642
                        },
                        {
                            "lat": 55.47102594575053,
                            "lon": 61.46314281321444
                        },
                        {
                            "lat": 55.47198879898558,
                            "lon": 61.464859213598466
                        },
                        {
                            "lat": 55.472951773841785,
                            "lon": 61.466575604736924
                        },
                        {
                            "lat": 55.469363992751255,
                            "lon": 61.467036280453236
                        },
                        {
                            "lat": 55.46577609003695,
                            "lon": 61.467496863718466
                        },
                        {
                            "lat": 55.466738828577,
                            "lon": 61.4692133110666
                        },
                        {
                            "lat": 55.463150625246435,
                            "lon": 61.4696738345945
                        },
                        {
                            "lat": 55.464113306383325,
                            "lon": 61.47139030542421
                        },
                        {
                            "lat": 55.78361191648174,
                            "lon": 61.40417528548635
                        },
                        {
                            "lat": 55.780037280157,
                            "lon": 61.404643857616364
                        },
                        {
                            "lat": 55.779059941402075,
                            "lon": 61.402930287037556
                        },
                        {
                            "lat": 55.782634400907796,
                            "lon": 61.402461748093785
                        },
                        {
                            "lat": 55.78361191648174,
                            "lon": 61.40417528548635
                        },
                        {
                            "lat": 55.78101474193014,
                            "lon": 61.40635741866063
                        },
                        {
                            "lat": 55.7774398057288,
                            "lon": 61.406825932155364
                        },
                        {
                            "lat": 55.776462520814086,
                            "lon": 61.40511233792693
                        },
                        {
                            "lat": 55.780037280157,
                            "lon": 61.404643857616364
                        },
                        {
                            "lat": 55.78101474193014,
                            "lon": 61.40635741866063
                        },
                        {
                            "lat": 55.765406138036006,
                            "lon": 61.39919428551772
                        },
                        {
                            "lat": 55.766382577442236,
                            "lon": 61.40090798418501
                        },
                        {
                            "lat": 55.76280797981167,
                            "lon": 61.401376089517164
                        },
                        {
                            "lat": 55.7618317172553,
                            "lon": 61.39966235769603
                        },
                        {
                            "lat": 55.76085557755958,
                            "lon": 61.39794861636064
                        },
                        {
                            "lat": 55.76442982150849,
                            "lon": 61.397480577332715
                        },
                        {
                            "lat": 55.765406138036006,
                            "lon": 61.39919428551772
                        },
                        {
                            "lat": 55.72737778990871,
                            "lon": 61.413375475929264
                        },
                        {
                            "lat": 55.72835271933715,
                            "lon": 61.41508952467311
                        },
                        {
                            "lat": 55.73192955336324,
                            "lon": 61.414622264279515
                        },
                        {
                            "lat": 55.732904782773254,
                            "lon": 61.41633627042352
                        },
                        {
                            "lat": 55.73648167116396,
                            "lon": 61.41586888499177
                        },
                        {
                            "lat": 55.740058436708274,
                            "lon": 61.415401407642065
                        },
                        {
                            "lat": 55.74103414330459,
                            "lon": 61.41711533804379
                        },
                        {
                            "lat": 55.737457200591855,
                            "lon": 61.41758284852189
                        },
                        {
                            "lat": 55.73843285288746,
                            "lon": 61.41929680255593
                        },
                        {
                            "lat": 55.734855610099174,
                            "lon": 61.41976425423682
                        },
                        {
                            "lat": 55.7312782444401,
                            "lon": 61.42023161398242
                        },
                        {
                            "lat": 55.730302946581546,
                            "lon": 61.41851759370129
                        },
                        {
                            "lat": 55.72672563532032,
                            "lon": 61.41898482839002
                        },
                        {
                            "lat": 55.725750637525366,
                            "lon": 61.41727076550789
                        },
                        {
                            "lat": 55.72477576252228,
                            "lon": 61.41555669314163
                        },
                        {
                            "lat": 55.72380101028978,
                            "lon": 61.413842611292914
                        },
                        {
                            "lat": 55.72282638080659,
                            "lon": 61.412128519963275
                        },
                        {
                            "lat": 55.72640298324777,
                            "lon": 61.41166141770106
                        },
                        {
                            "lat": 55.72737778990871,
                            "lon": 61.413375475929264
                        },
                        {
                            "lat": 55.69710432535355,
                            "lon": 61.44126208796273
                        },
                        {
                            "lat": 55.69613005143188,
                            "lon": 61.43954775576541
                        },
                        {
                            "lat": 55.69971031065449,
                            "lon": 61.43908123517516
                        },
                        {
                            "lat": 55.70068476222489,
                            "lon": 61.44079553428698
                        },
                        {
                            "lat": 55.69710432535355,
                            "lon": 61.44126208796273
                        },
                        {
                            "lat": 55.60511045668578,
                            "lon": 61.4145570009745
                        },
                        {
                            "lat": 55.60607872842621,
                            "lon": 61.416272181278295
                        },
                        {
                            "lat": 55.60704712220188,
                            "lon": 61.41798735221924
                        },
                        {
                            "lat": 55.6034674905149,
                            "lon": 61.418451345343726
                        },
                        {
                            "lat": 55.604435828581124,
                            "lon": 61.420166539827214
                        },
                        {
                            "lat": 55.60085589706906,
                            "lon": 61.42063047375961
                        },
                        {
                            "lat": 55.59727584351605,
                            "lon": 61.42109431558654
                        },
                        {
                            "lat": 55.593695667940416,
                            "lon": 61.421558065301866
                        },
                        {
                            "lat": 55.59011537036049,
                            "lon": 61.42202172289925
                        },
                        {
                            "lat": 55.58914774356944,
                            "lon": 61.42030639684321
                        },
                        {
                            "lat": 55.58556750187528,
                            "lon": 61.42076992943714
                        },
                        {
                            "lat": 55.58198713823468,
                            "lon": 61.42123336990618
                        },
                        {
                            "lat": 55.58295440926111,
                            "lon": 61.42294876171513
                        },
                        {
                            "lat": 55.58392180223682,
                            "lon": 61.42466414418207
                        },
                        {
                            "lat": 55.580340960821644,
                            "lon": 61.42512755825964
                        },
                        {
                            "lat": 55.57675999745437,
                            "lon": 61.425590880188665
                        },
                        {
                            "lat": 55.57579296036466,
                            "lon": 61.423875431984186
                        },
                        {
                            "lat": 55.5722120530384,
                            "lon": 61.424338628897985
                        },
                        {
                            "lat": 55.573178912153345,
                            "lon": 61.42605410996291
                        },
                        {
                            "lat": 55.57414589318368,
                            "lon": 61.427769581694534
                        },
                        {
                            "lat": 55.570564507953,
                            "lon": 61.42823275216606
                        },
                        {
                            "lat": 55.57153143288723,
                            "lon": 61.429948247424555
                        },
                        {
                            "lat": 55.567949747684544,
                            "lon": 61.43041135857936
                        },
                        {
                            "lat": 55.56436794056088,
                            "lon": 61.430874377549465
                        },
                        {
                            "lat": 55.560786011534596,
                            "lon": 61.43133730432863
                        },
                        {
                            "lat": 55.56175252412805,
                            "lon": 61.43305288881625
                        },
                        {
                            "lat": 55.55817029508183,
                            "lon": 61.43351575624112
                        },
                        {
                            "lat": 55.55458794414862,
                            "lon": 61.43397853145694
                        },
                        {
                            "lat": 55.551005471346805,
                            "lon": 61.43444121445745
                        },
                        {
                            "lat": 55.55003949322404,
                            "lon": 61.43272553145774
                        },
                        {
                            "lat": 55.54645707677125,
                            "lon": 61.433188089410486
                        },
                        {
                            "lat": 55.54287453850782,
                            "lon": 61.433650555140986
                        },
                        {
                            "lat": 55.5419090385969,
                            "lon": 61.43193479718889
                        },
                        {
                            "lat": 55.54549139865834,
                            "lon": 61.43147236427557
                        },
                        {
                            "lat": 55.54907363693031,
                            "lon": 61.43100983914563
                        },
                        {
                            "lat": 55.548107902444464,
                            "lon": 61.429294137522646
                        },
                        {
                            "lat": 55.547142289745324,
                            "lon": 61.42757842659041
                        },
                        {
                            "lat": 55.546176798811736,
                            "lon": 61.42586270635051
                        },
                        {
                            "lat": 55.54259509497186,
                            "lon": 61.42632513303271
                        },
                        {
                            "lat": 55.53901326940595,
                            "lon": 61.42678746751496
                        },
                        {
                            "lat": 55.53543132213241,
                            "lon": 61.42724970979101
                        },
                        {
                            "lat": 55.53184925316964,
                            "lon": 61.42771185985459
                        },
                        {
                            "lat": 55.52826706253607,
                            "lon": 61.42817391769949
                        },
                        {
                            "lat": 55.52923166263007,
                            "lon": 61.4298898019461
                        },
                        {
                            "lat": 55.5301963843976,
                            "lon": 61.431605676902215
                        },
                        {
                            "lat": 55.526613715630546,
                            "lon": 61.43206770809484
                        },
                        {
                            "lat": 55.525649172112765,
                            "lon": 61.43035180035067
                        },
                        {
                            "lat": 55.52206655994033,
                            "lon": 61.43081370651846
                        },
                        {
                            "lat": 55.52303092518722,
                            "lon": 61.43252964704511
                        },
                        {
                            "lat": 55.52399541209195,
                            "lon": 61.43424557828653
                        },
                        {
                            "lat": 55.524960020675636,
                            "lon": 61.43596150024108
                        },
                        {
                            "lat": 55.52137675193512,
                            "lon": 61.43642341250686
                        },
                        {
                            "lat": 55.522341303871606,
                            "lon": 61.438139357960246
                        },
                        {
                            "lat": 55.523305977510894,
                            "lon": 61.43985529412707
                        },
                        {
                            "lat": 55.524270772874175,
                            "lon": 61.441571221005766
                        },
                        {
                            "lat": 55.527854576711974,
                            "lon": 61.44110921036811
                        },
                        {
                            "lat": 55.52881967222304,
                            "lon": 61.442825095159606
                        },
                        {
                            "lat": 55.52523568998257,
                            "lon": 61.44328713859476
                        },
                        {
                            "lat": 55.52620072885729,
                            "lon": 61.44500304689247
                        },
                        {
                            "lat": 55.52716588951945,
                            "lon": 61.44671894589729
                        },
                        {
                            "lat": 55.528131171990275,
                            "lon": 61.44843483560765
                        },
                        {
                            "lat": 55.531715689549365,
                            "lon": 61.44797269375943
                        },
                        {
                            "lat": 55.532681272326435,
                            "lon": 61.44968854136254
                        },
                        {
                            "lat": 55.53626584649254,
                            "lon": 61.44922627439869
                        },
                        {
                            "lat": 55.53985029877072,
                            "lon": 61.44876391513675
                        },
                        {
                            "lat": 55.54343462914254,
                            "lon": 61.44830146358292
                        },
                        {
                            "lat": 55.54440086914663,
                            "lon": 61.4500172034075
                        },
                        {
                            "lat": 55.54081636034347,
                            "lon": 61.450479687792715
                        },
                        {
                            "lat": 55.53723172961276,
                            "lon": 61.4509420798805
                        },
                        {
                            "lat": 55.53819773464211,
                            "lon": 61.45265787605622
                        },
                        {
                            "lat": 55.541782543843766,
                            "lon": 61.45219545113921
                        },
                        {
                            "lat": 55.5427488492928,
                            "lon": 61.45391120517457
                        },
                        {
                            "lat": 55.54371527671177,
                            "lon": 61.45562694989726
                        },
                        {
                            "lat": 55.54013011051283,
                            "lon": 61.45608944048313
                        },
                        {
                            "lat": 55.54109648139661,
                            "lon": 61.45780520873113
                        },
                        {
                            "lat": 55.54206297427433,
                            "lon": 61.459520967666705
                        },
                        {
                            "lat": 55.53847732898915,
                            "lon": 61.45998343160356
                        },
                        {
                            "lat": 55.53489156170732,
                            "lon": 61.46044580320256
                        },
                        {
                            "lat": 55.533925425982815,
                            "lon": 61.458729978597674
                        },
                        {
                            "lat": 55.53033971533118,
                            "lon": 61.4591922250263
                        },
                        {
                            "lat": 55.52937388015359,
                            "lon": 61.45747635829307
                        },
                        {
                            "lat": 55.525788226174626,
                            "lon": 61.45793847955963
                        },
                        {
                            "lat": 55.52482269150702,
                            "lon": 61.456222570712086
                        },
                        {
                            "lat": 55.52123709424308,
                            "lon": 61.45668456682493
                        },
                        {
                            "lat": 55.51765137511997,
                            "lon": 61.457146470591645
                        },
                        {
                            "lat": 55.51668631954201,
                            "lon": 61.455430486844605
                        },
                        {
                            "lat": 55.513100657216,
                            "lon": 61.45589226546531
                        },
                        {
                            "lat": 55.5121359020768,
                            "lon": 61.45417623964105
                        },
                        {
                            "lat": 55.50855029659025,
                            "lon": 61.45463789312407
                        },
                        {
                            "lat": 55.507585841852894,
                            "lon": 61.45292182523661
                        },
                        {
                            "lat": 55.5066215088557,
                            "lon": 61.451205748072134
                        },
                        {
                            "lat": 55.50303613887562,
                            "lon": 61.45166724365369
                        },
                        {
                            "lat": 55.50207210620361,
                            "lon": 61.44995112444507
                        },
                        {
                            "lat": 55.49848679315041,
                            "lon": 61.45041249491472
                        },
                        {
                            "lat": 55.497523060766625,
                            "lon": 61.448696333675976
                        },
                        {
                            "lat": 55.49655945002255,
                            "lon": 61.44698016317183
                        },
                        {
                            "lat": 55.49559596089705,
                            "lon": 61.44526398340382
                        },
                        {
                            "lat": 55.499180738179135,
                            "lon": 61.44480271120297
                        },
                        {
                            "lat": 55.50276539384533,
                            "lon": 61.44434134667565
                        },
                        {
                            "lat": 55.50634992787713,
                            "lon": 61.443879889828125
                        },
                        {
                            "lat": 55.505386024752234,
                            "lon": 61.4421637990628
                        },
                        {
                            "lat": 55.504422243259036,
                            "lon": 61.440447699026535
                        },
                        {
                            "lat": 55.5034585833764,
                            "lon": 61.43873158972087
                        },
                        {
                            "lat": 55.50704246034082,
                            "lon": 61.43827013884752
                        },
                        {
                            "lat": 55.51062621569735,
                            "lon": 61.43780859568323
                        },
                        {
                            "lat": 55.5096623205561,
                            "lon": 61.43609254262706
                        },
                        {
                            "lat": 55.50607874361302,
                            "lon": 61.436554053029916
                        },
                        {
                            "lat": 55.50511514847196,
                            "lon": 61.43483795794261
                        },
                        {
                            "lat": 55.50415167489652,
                            "lon": 61.43312185358721
                        },
                        {
                            "lat": 55.50056833318048,
                            "lon": 61.43358320620321
                        },
                        {
                            "lat": 55.501531628358265,
                            "lon": 61.43529934330764
                        },
                        {
                            "lat": 55.49794798669741,
                            "lon": 61.43576063638659
                        },
                        {
                            "lat": 55.49698486993844,
                            "lon": 61.43404446653873
                        },
                        {
                            "lat": 55.4934012851888,
                            "lon": 61.43450563458756
                        },
                        {
                            "lat": 55.49243846835886,
                            "lon": 61.43278942274561
                        },
                        {
                            "lat": 55.49602187468704,
                            "lon": 61.43232828743122
                        },
                        {
                            "lat": 55.495059000922105,
                            "lon": 61.4306120990656
                        },
                        {
                            "lat": 55.49409624862254,
                            "lon": 61.42889590144351
                        },
                        {
                            "lat": 55.49051319908188,
                            "lon": 61.429356971299335
                        },
                        {
                            "lat": 55.489550746592606,
                            "lon": 61.427640731698105
                        },
                        {
                            "lat": 55.493133617767185,
                            "lon": 61.427179694566426
                        },
                        {
                            "lat": 55.496716367518616,
                            "lon": 61.42671856517036
                        },
                        {
                            "lat": 55.49575367975987,
                            "lon": 61.425002381766205
                        },
                        {
                            "lat": 55.494791113421634,
                            "lon": 61.42328618910684
                        },
                        {
                            "lat": 55.493828668482806,
                            "lon": 61.421569987193834
                        },
                        {
                            "lat": 55.49741076193168,
                            "lon": 61.42110886372499
                        },
                        {
                            "lat": 55.498373385139075,
                            "lon": 61.422825032912975
                        },
                        {
                            "lat": 55.50195553543866,
                            "lon": 61.42236378447833
                        },
                        {
                            "lat": 55.505537564301974,
                            "lon": 61.42190244380915
                        },
                        {
                            "lat": 55.50457458462079,
                            "lon": 61.42018634008798
                        },
                        {
                            "lat": 55.5036117263732,
                            "lon": 61.41847022710444
                        },
                        {
                            "lat": 55.50719327738972,
                            "lon": 61.418008859687106
                        },
                        {
                            "lat": 55.50623036238624,
                            "lon": 61.41629277017775
                        },
                        {
                            "lat": 55.502648989538145,
                            "lon": 61.41675410486016
                        },
                        {
                            "lat": 55.501686374094504,
                            "lon": 61.415037973356675
                        },
                        {
                            "lat": 55.50072388002122,
                            "lon": 61.413321832595564
                        },
                        {
                            "lat": 55.50430489658776,
                            "lon": 61.4128605633727
                        },
                        {
                            "lat": 55.50526756879262,
                            "lon": 61.41457667140574
                        },
                        {
                            "lat": 55.50884864210196,
                            "lon": 61.41411527725506
                        },
                        {
                            "lat": 55.51242959400412,
                            "lon": 61.413653790910836
                        },
                        {
                            "lat": 55.51146656559935,
                            "lon": 61.41193774835066
                        },
                        {
                            "lat": 55.515047218007595,
                            "lon": 61.41147620256396
                        },
                        {
                            "lat": 55.5140841329574,
                            "lon": 61.40976018347884
                        },
                        {
                            "lat": 55.51766448591389,
                            "lon": 61.4092985782636
                        },
                        {
                            "lat": 55.51670134425508,
                            "lon": 61.4075825826538
                        },
                        {
                            "lat": 55.51312116930904,
                            "lon": 61.40804415512552
                        },
                        {
                            "lat": 55.50954087298224,
                            "lon": 61.40850563543292
                        },
                        {
                            "lat": 55.5059604552931,
                            "lon": 61.40896702356974
                        },
                        {
                            "lat": 55.50499796907281,
                            "lon": 61.407250920486376
                        },
                        {
                            "lat": 55.508578208727755,
                            "lon": 61.40678956507851
                        },
                        {
                            "lat": 55.512158327041405,
                            "lon": 61.40632811750565
                        },
                        {
                            "lat": 55.51119560613347,
                            "lon": 61.404612070620736
                        },
                        {
                            "lat": 55.50761566581455,
                            "lon": 61.40507348546251
                        },
                        {
                            "lat": 55.50665324422155,
                            "lon": 61.40335739658647
                        },
                        {
                            "lat": 55.51023300656412,
                            "lon": 61.40289601447239
                        },
                        {
                            "lat": 55.50927052831234,
                            "lon": 61.40117994906216
                        },
                        {
                            "lat": 55.50569094392772,
                            "lon": 61.40164129845199
                        },
                        {
                            "lat": 55.504728764911974,
                            "lon": 61.39992519106066
                        },
                        {
                            "lat": 55.50376670715328,
                            "lon": 61.39820907441398
                        },
                        {
                            "lat": 55.50280477063061,
                            "lon": 61.3964929485136
                        },
                        {
                            "lat": 55.5018429553229,
                            "lon": 61.39477681336108
                        },
                        {
                            "lat": 55.505421828059575,
                            "lon": 61.394315594834104
                        },
                        {
                            "lat": 55.50445995607975,
                            "lon": 61.39259948313812
                        },
                        {
                            "lat": 55.508038529759126,
                            "lon": 61.392138205204084
                        },
                        {
                            "lat": 55.51161698222885,
                            "lon": 61.391676835162045
                        },
                        {
                            "lat": 55.51065487580809,
                            "lon": 61.38996077963783
                        },
                        {
                            "lat": 55.51423302926524,
                            "lon": 61.389499350214464
                        },
                        {
                            "lat": 55.51781106149705,
                            "lon": 61.38903782870115
                        },
                        {
                            "lat": 55.51684872077189,
                            "lon": 61.38732182935885
                        },
                        {
                            "lat": 55.52042645403587,
                            "lon": 61.38686024848992
                        },
                        {
                            "lat": 55.52400406605881,
                            "lon": 61.38639857554903
                        },
                        {
                            "lat": 55.52496676221121,
                            "lon": 61.38811450942944
                        },
                        {
                            "lat": 55.528544430656865,
                            "lon": 61.38765271168355
                        },
                        {
                            "lat": 55.52950742579006,
                            "lon": 61.38936860354991
                        },
                        {
                            "lat": 55.53308515061633,
                            "lon": 61.3889066809907
                        },
                        {
                            "lat": 55.534048444767,
                            "lon": 61.390622530829035
                        },
                        {
                            "lat": 55.53501186027685,
                            "lon": 61.39233837138598
                        },
                        {
                            "lat": 55.538589819136625,
                            "lon": 61.3918762912445
                        },
                        {
                            "lat": 55.54216765659784,
                            "lon": 61.391414119033755
                        },
                        {
                            "lat": 55.54313154889349,
                            "lon": 61.39312988477408
                        },
                        {
                            "lat": 55.539553533740026,
                            "lon": 61.39359208975447
                        },
                        {
                            "lat": 55.53597539716692,
                            "lon": 61.39405420266003
                        },
                        {
                            "lat": 55.53239713919252,
                            "lon": 61.394516223484516
                        },
                        {
                            "lat": 55.528818759835175,
                            "lon": 61.394978152221704
                        },
                        {
                            "lat": 55.52524025911321,
                            "lon": 61.39543998886534
                        },
                        {
                            "lat": 55.521661637045035,
                            "lon": 61.39590173340923
                        },
                        {
                            "lat": 55.5226245841991,
                            "lon": 61.397617686413106
                        },
                        {
                            "lat": 55.52620338408321,
                            "lon": 61.397155909123995
                        },
                        {
                            "lat": 55.52978206259999,
                            "lon": 61.39669403972951
                        },
                        {
                            "lat": 55.530745486750504,
                            "lon": 61.39840991795811
                        },
                        {
                            "lat": 55.531709032307724,
                            "lon": 61.40012578690586
                        },
                        {
                            "lat": 55.53528794504153,
                            "lon": 61.39966375988931
                        },
                        {
                            "lat": 55.5362517898554,
                            "lon": 61.40137958678802
                        },
                        {
                            "lat": 55.5372157561365,
                            "lon": 61.403095404399345
                        },
                        {
                            "lat": 55.54079490305807,
                            "lon": 61.40263321973095
                        },
                        {
                            "lat": 55.54437392847297,
                            "lon": 61.40217094295368
                        },
                        {
                            "lat": 55.54795283236287,
                            "lon": 61.40170857407378
                        },
                        {
                            "lat": 55.54698833266661,
                            "lon": 61.39999285480603
                        },
                        {
                            "lat": 55.54602395449262,
                            "lon": 61.39827712624059
                        },
                        {
                            "lat": 55.549602381331724,
                            "lon": 61.39781473084552
                        },
                        {
                            "lat": 55.55318068665128,
                            "lon": 61.3973522433714
                        },
                        {
                            "lat": 55.55675887043297,
                            "lon": 61.39688966382447
                        },
                        {
                            "lat": 55.56033693265849,
                            "lon": 61.39642699221102
                        },
                        {
                            "lat": 55.56391487330947,
                            "lon": 61.395964228537245
                        },
                        {
                            "lat": 55.56488013999527,
                            "lon": 61.39767979310269
                        },
                        {
                            "lat": 55.565845528295014,
                            "lon": 61.39939534835334
                        },
                        {
                            "lat": 55.566811038229766,
                            "lon": 61.40111089428759
                        },
                        {
                            "lat": 55.563232564543284,
                            "lon": 61.40157375640497
                        },
                        {
                            "lat": 55.56419801841903,
                            "lon": 61.40328932584265
                        },
                        {
                            "lat": 55.565163593953685,
                            "lon": 61.4050048859642
                        },
                        {
                            "lat": 55.568742423088814,
                            "lon": 61.404541958200625
                        },
                        {
                            "lat": 55.57232113052558,
                            "lon": 61.404078938355184
                        },
                        {
                            "lat": 55.57135519954509,
                            "lon": 61.402363443888824
                        },
                        {
                            "lat": 55.57038939026015,
                            "lon": 61.40064794009948
                        },
                        {
                            "lat": 55.573967620616045,
                            "lon": 61.400184893846905
                        },
                        {
                            "lat": 55.57754572927917,
                            "lon": 61.399721755536085
                        },
                        {
                            "lat": 55.57851189388888,
                            "lon": 61.40143719365484
                        },
                        {
                            "lat": 55.57947818023079,
                            "lon": 61.40315262244382
                        },
                        {
                            "lat": 55.57589971624569,
                            "lon": 61.40361582643419
                        },
                        {
                            "lat": 55.576865946652454,
                            "lon": 61.405331278736604
                        },
                        {
                            "lat": 55.58044458832599,
                            "lon": 61.40486804190135
                        },
                        {
                            "lat": 55.58402310822508,
                            "lon": 61.40440471299743
                        },
                        {
                            "lat": 55.58305652246254,
                            "lon": 61.40268932639037
                        },
                        {
                            "lat": 55.58663474292266,
                            "lon": 61.40222593828008
                        },
                        {
                            "lat": 55.590212841592866,
                            "lon": 61.40176245811924
                        },
                        {
                            "lat": 55.58924602236729,
                            "lon": 61.40004712788805
                        },
                        {
                            "lat": 55.592823821643606,
                            "lon": 61.399583588546555
                        },
                        {
                            "lat": 55.59379081845483,
                            "lon": 61.401298885914045
                        },
                        {
                            "lat": 55.597368673490244,
                            "lon": 61.400835221670775
                        },
                        {
                            "lat": 55.598335969710796,
                            "lon": 61.402550476820466
                        },
                        {
                            "lat": 55.5993033877973,
                            "lon": 61.404265722619954
                        },
                        {
                            "lat": 55.60027092777092,
                            "lon": 61.4059809590677
                        },
                        {
                            "lat": 55.60123858965278,
                            "lon": 61.40769618616212
                        },
                        {
                            "lat": 55.6048170331998,
                            "lon": 61.407232298353556
                        },
                        {
                            "lat": 55.605784994645894,
                            "lon": 61.40894748320107
                        },
                        {
                            "lat": 55.606753078060834,
                            "lon": 61.41066265868863
                        },
                        {
                            "lat": 55.60772128346579,
                            "lon": 61.41237782481464
                        },
                        {
                            "lat": 55.60868961088194,
                            "lon": 61.41409298157751
                        },
                        {
                            "lat": 55.60511045668578,
                            "lon": 61.4145570009745
                        },
                        {
                            "lat": 55.49024645365561,
                            "lon": 61.422031018421286
                        },
                        {
                            "lat": 55.48928430836626,
                            "lon": 61.42031477454015
                        },
                        {
                            "lat": 55.49286634492227,
                            "lon": 61.419853776028766
                        },
                        {
                            "lat": 55.493828668482806,
                            "lon": 61.421569987193834
                        },
                        {
                            "lat": 55.49024645365561,
                            "lon": 61.422031018421286
                        },
                        {
                            "lat": 55.54670944260901,
                            "lon": 61.39266758772509
                        },
                        {
                            "lat": 55.54767363403218,
                            "lon": 61.39438331139545
                        },
                        {
                            "lat": 55.54409556262714,
                            "lon": 61.394845641223036
                        },
                        {
                            "lat": 55.54313154889349,
                            "lon": 61.39312988477408
                        },
                        {
                            "lat": 55.54670944260901,
                            "lon": 61.39266758772509
                        },
                        {
                            "lat": 55.589923561957924,
                            "lon": 61.394437640389086
                        },
                        {
                            "lat": 55.590890193416286,
                            "lon": 61.39615296578248
                        },
                        {
                            "lat": 55.58731274925637,
                            "lon": 61.3966164394069
                        },
                        {
                            "lat": 55.58634629532877,
                            "lon": 61.394901081160086
                        },
                        {
                            "lat": 55.58276890697586,
                            "lon": 61.39536442990899
                        },
                        {
                            "lat": 55.5818027523056,
                            "lon": 61.39364902948237
                        },
                        {
                            "lat": 55.585379963124936,
                            "lon": 61.39318571357791
                        },
                        {
                            "lat": 55.588957052241604,
                            "lon": 61.392722305656875
                        },
                        {
                            "lat": 55.589923561957924,
                            "lon": 61.394437640389086
                        },
                        {
                            "lat": 55.489614675148694,
                            "lon": 61.379792013109636
                        },
                        {
                            "lat": 55.493192068757324,
                            "lon": 61.3793311809001
                        },
                        {
                            "lat": 55.49415279407644,
                            "lon": 61.38104739925142
                        },
                        {
                            "lat": 55.49057522272774,
                            "lon": 61.38150826413524
                        },
                        {
                            "lat": 55.489614675148694,
                            "lon": 61.379792013109636
                        },
                        {
                            "lat": 55.565689039158094,
                            "lon": 61.48776112056187
                        },
                        {
                            "lat": 55.56210060019608,
                            "lon": 61.488224328108444
                        },
                        {
                            "lat": 55.56113185131329,
                            "lon": 61.48650876062834
                        },
                        {
                            "lat": 55.55754346881511,
                            "lon": 61.48697184285312
                        },
                        {
                            "lat": 55.556575021146394,
                            "lon": 61.485256233132034
                        },
                        {
                            "lat": 55.55298669515464,
                            "lon": 61.48571919004333
                        },
                        {
                            "lat": 55.552018548662986,
                            "lon": 61.484003538095344
                        },
                        {
                            "lat": 55.548430279220256,
                            "lon": 61.484466369701536
                        },
                        {
                            "lat": 55.54484188750187,
                            "lon": 61.4849291088854
                        },
                        {
                            "lat": 55.543874221017525,
                            "lon": 61.48321338185012
                        },
                        {
                            "lat": 55.54746243386869,
                            "lon": 61.48275067554069
                        },
                        {
                            "lat": 55.55105052446549,
                            "lon": 61.48228787681455
                        },
                        {
                            "lat": 55.5546384927894,
                            "lon": 61.481824985677946
                        },
                        {
                            "lat": 55.55560669581157,
                            "lon": 61.4835406140731
                        },
                        {
                            "lat": 55.559194720647525,
                            "lon": 61.483077597641106
                        },
                        {
                            "lat": 55.56016322480419,
                            "lon": 61.484793183805365
                        },
                        {
                            "lat": 55.563751306109594,
                            "lon": 61.484330042069615
                        },
                        {
                            "lat": 55.564720111437744,
                            "lon": 61.48604558598892
                        },
                        {
                            "lat": 55.56830824917001,
                            "lon": 61.48558231894108
                        },
                        {
                            "lat": 55.571896264491585,
                            "lon": 61.48511895949114
                        },
                        {
                            "lat": 55.575484157384004,
                            "lon": 61.48465550764542
                        },
                        {
                            "lat": 55.5790719278288,
                            "lon": 61.484191963410126
                        },
                        {
                            "lat": 55.58004157068631,
                            "lon": 61.48590736629866
                        },
                        {
                            "lat": 55.583629397396024,
                            "lon": 61.48544369674506
                        },
                        {
                            "lat": 55.58721710159986,
                            "lon": 61.484979934808926
                        },
                        {
                            "lat": 55.588187224422434,
                            "lon": 61.48669526245314
                        },
                        {
                            "lat": 55.58915746979067,
                            "lon": 61.48841058072715
                        },
                        {
                            "lat": 55.59012783772588,
                            "lon": 61.490125889629354
                        },
                        {
                            "lat": 55.5910983282494,
                            "lon": 61.49184118915814
                        },
                        {
                            "lat": 55.59206894138256,
                            "lon": 61.49355647931195
                        },
                        {
                            "lat": 55.595657416780924,
                            "lon": 61.49309246021664
                        },
                        {
                            "lat": 55.599245769529816,
                            "lon": 61.49262834872346
                        },
                        {
                            "lat": 55.60021686287093,
                            "lon": 61.49434356356119
                        },
                        {
                            "lat": 55.603805271708396,
                            "lon": 61.493879326698305
                        },
                        {
                            "lat": 55.60477666651369,
                            "lon": 61.49559449916936
                        },
                        {
                            "lat": 55.60118807890131,
                            "lon": 61.49605876901377
                        },
                        {
                            "lat": 55.5975993685783,
                            "lon": 61.49652294645546
                        },
                        {
                            "lat": 55.59857052850454,
                            "lon": 61.498238175500816
                        },
                        {
                            "lat": 55.59498151665329,
                            "lon": 61.49870229350731
                        },
                        {
                            "lat": 55.59401053556316,
                            "lon": 61.49698703148811
                        },
                        {
                            "lat": 55.59042157987434,
                            "lon": 61.49745102410544
                        },
                        {
                            "lat": 55.58945090029685,
                            "lon": 61.495735719741646
                        },
                        {
                            "lat": 55.58586200081316,
                            "lon": 61.49619958697812
                        },
                        {
                            "lat": 55.58489162271137,
                            "lon": 61.494484240283796
                        },
                        {
                            "lat": 55.581302779475486,
                            "lon": 61.49494798214773
                        },
                        {
                            "lat": 55.58033270281244,
                            "lon": 61.49323259313696
                        },
                        {
                            "lat": 55.57674391586705,
                            "lon": 61.49369620963671
                        },
                        {
                            "lat": 55.575774140605766,
                            "lon": 61.491980778323594
                        },
                        {
                            "lat": 55.57218540999353,
                            "lon": 61.4924442694675
                        },
                        {
                            "lat": 55.57121593609705,
                            "lon": 61.4907287958661
                        },
                        {
                            "lat": 55.56762726186058,
                            "lon": 61.491192161662475
                        },
                        {
                            "lat": 55.56665808929193,
                            "lon": 61.48947664578693
                        },
                        {
                            "lat": 55.565689039158094,
                            "lon": 61.48776112056187
                        },
                        {
                            "lat": 55.60836513139703,
                            "lon": 61.49513013692849
                        },
                        {
                            "lat": 55.60933682770344,
                            "lon": 61.49684526701877
                        },
                        {
                            "lat": 55.60574818404806,
                            "lon": 61.497309662250224
                        },
                        {
                            "lat": 55.60477666651369,
                            "lon": 61.49559449916936
                        },
                        {
                            "lat": 55.60836513139703,
                            "lon": 61.49513013692849
                        },
                        {
                            "lat": 55.6129253485899,
                            "lon": 61.49638077939158
                        },
                        {
                            "lat": 55.61651374668902,
                            "lon": 61.49591619937494
                        },
                        {
                            "lat": 55.61748592328119,
                            "lon": 61.49763125406515
                        },
                        {
                            "lat": 55.621074377300914,
                            "lon": 61.49716654865462
                        },
                        {
                            "lat": 55.62466270847501,
                            "lon": 61.49670175086168
                        },
                        {
                            "lat": 55.62563536536262,
                            "lon": 61.49841673011409
                        },
                        {
                            "lat": 55.62922375237475,
                            "lon": 61.497951806919644
                        },
                        {
                            "lat": 55.630196710868304,
                            "lon": 61.4996667437309
                        },
                        {
                            "lat": 55.63378515367569,
                            "lon": 61.49920169512665
                        },
                        {
                            "lat": 55.63475841381203,
                            "lon": 61.50091658948266
                        },
                        {
                            "lat": 55.638346912371894,
                            "lon": 61.500451415460304
                        },
                        {
                            "lat": 55.63932047418794,
                            "lon": 61.50216626734697
                        },
                        {
                            "lat": 55.63573179691348,
                            "lon": 61.50263147441624
                        },
                        {
                            "lat": 55.63670530300141,
                            "lon": 61.50434634992583
                        },
                        {
                            "lat": 55.63767893209722,
                            "lon": 61.50606121600979
                        },
                        {
                            "lat": 55.63865268422234,
                            "lon": 61.507776072666516
                        },
                        {
                            "lat": 55.63506334764274,
                            "lon": 61.50824128649487
                        },
                        {
                            "lat": 55.6314738880309,
                            "lon": 61.508706407914396
                        },
                        {
                            "lat": 55.63050049350985,
                            "lon": 61.50699148515974
                        },
                        {
                            "lat": 55.6295272219812,
                            "lon": 61.50527655298479
                        },
                        {
                            "lat": 55.62855407342356,
                            "lon": 61.503561611391135
                        },
                        {
                            "lat": 55.62496502724496,
                            "lon": 61.50402654128414
                        },
                        {
                            "lat": 55.623992180415705,
                            "lon": 61.50231155724319
                        },
                        {
                            "lat": 55.62040319010616,
                            "lon": 61.502776361706836
                        },
                        {
                            "lat": 55.616814076905285,
                            "lon": 61.50324107376506
                        },
                        {
                            "lat": 55.6132248408316,
                            "lon": 61.50370569341154
                        },
                        {
                            "lat": 55.612252653321434,
                            "lon": 61.501990600911824
                        },
                        {
                            "lat": 55.60866347323923,
                            "lon": 61.502455095135815
                        },
                        {
                            "lat": 55.607691587389475,
                            "lon": 61.500739960235066
                        },
                        {
                            "lat": 55.61128058864427,
                            "lon": 61.50027549901202
                        },
                        {
                            "lat": 55.610308646778726,
                            "lon": 61.49856038771381
                        },
                        {
                            "lat": 55.60933682770344,
                            "lon": 61.49684526701877
                        },
                        {
                            "lat": 55.6129253485899,
                            "lon": 61.49638077939158
                        },
                        {
                            "lat": 55.567159073813855,
                            "lon": 61.45628113517489
                        },
                        {
                            "lat": 55.568126938203584,
                            "lon": 61.45799665475421
                        },
                        {
                            "lat": 55.56454196897583,
                            "lon": 61.45845979776056
                        },
                        {
                            "lat": 55.563574283021964,
                            "lon": 61.456744245301344
                        },
                        {
                            "lat": 55.567159073813855,
                            "lon": 61.45628113517489
                        },
                        {
                            "lat": 55.49929797253518,
                            "lon": 61.472390443539474
                        },
                        {
                            "lat": 55.50026275148665,
                            "lon": 61.47410658256361
                        },
                        {
                            "lat": 55.496674627200825,
                            "lon": 61.47456800949555
                        },
                        {
                            "lat": 55.49571002718359,
                            "lon": 61.47285183768946
                        },
                        {
                            "lat": 55.49929797253518,
                            "lon": 61.472390443539474
                        },
                        {
                            "lat": 55.501920960040785,
                            "lon": 61.47021284143853
                        },
                        {
                            "lat": 55.49833331544323,
                            "lon": 61.47067429523971
                        },
                        {
                            "lat": 55.49736878018962,
                            "lon": 61.46895813766588
                        },
                        {
                            "lat": 55.500956245911205,
                            "lon": 61.46849671664538
                        },
                        {
                            "lat": 55.501920960040785,
                            "lon": 61.47021284143853
                        },
                        {
                            "lat": 55.49999165361728,
                            "lon": 61.466780582576334
                        },
                        {
                            "lat": 55.499027183137784,
                            "lon": 61.46506443923294
                        },
                        {
                            "lat": 55.502614169369735,
                            "lon": 61.464602991356834
                        },
                        {
                            "lat": 55.506201033789914,
                            "lon": 61.46414145108028
                        },
                        {
                            "lat": 55.50716586188366,
                            "lon": 61.465857528859246
                        },
                        {
                            "lat": 55.503578818666945,
                            "lon": 61.46631910192079
                        },
                        {
                            "lat": 55.50454358979713,
                            "lon": 61.46803520320701
                        },
                        {
                            "lat": 55.500956245911205,
                            "lon": 61.46849671664538
                        },
                        {
                            "lat": 55.49999165361728,
                            "lon": 61.466780582576334
                        },
                        {
                            "lat": 55.49544007511256,
                            "lon": 61.46552579470238
                        },
                        {
                            "lat": 55.494475905246745,
                            "lon": 61.46380960931584
                        },
                        {
                            "lat": 55.49351185713448,
                            "lon": 61.462093414661595
                        },
                        {
                            "lat": 55.497098607537396,
                            "lon": 61.46163212472952
                        },
                        {
                            "lat": 55.498062834451545,
                            "lon": 61.46334828661682
                        },
                        {
                            "lat": 55.499027183137784,
                            "lon": 61.46506443923294
                        },
                        {
                            "lat": 55.49544007511256,
                            "lon": 61.46552579470238
                        },
                        {
                            "lat": 55.51433958274416,
                            "lon": 61.464934105543016
                        },
                        {
                            "lat": 55.515304890255855,
                            "lon": 61.46665010844713
                        },
                        {
                            "lat": 55.51889174661404,
                            "lon": 61.4661882253998
                        },
                        {
                            "lat": 55.51985735480539,
                            "lon": 61.467904186205615
                        },
                        {
                            "lat": 55.520823084946116,
                            "lon": 61.469620137716724
                        },
                        {
                            "lat": 55.52441017689204,
                            "lon": 61.46915809665089
                        },
                        {
                            "lat": 55.525376207789144,
                            "lon": 61.470874006044646
                        },
                        {
                            "lat": 55.52634236069661,
                            "lon": 61.47258990613713
                        },
                        {
                            "lat": 55.52275491116076,
                            "lon": 61.47305201284864
                        },
                        {
                            "lat": 55.51916733961183,
                            "lon": 61.47351402715117
                        },
                        {
                            "lat": 55.51820154433405,
                            "lon": 61.47179806141521
                        },
                        {
                            "lat": 55.514614029637194,
                            "lon": 61.47225995048917
                        },
                        {
                            "lat": 55.51364853516091,
                            "lon": 61.47054394264885
                        },
                        {
                            "lat": 55.510061077358614,
                            "lon": 61.4710057065026
                        },
                        {
                            "lat": 55.511026392985514,
                            "lon": 61.47272174714727
                        },
                        {
                            "lat": 55.507438634397516,
                            "lon": 61.473183451383186
                        },
                        {
                            "lat": 55.50385075389172,
                            "lon": 61.47364506319073
                        },
                        {
                            "lat": 55.50288579602723,
                            "lon": 61.471928956954166
                        },
                        {
                            "lat": 55.50647349764121,
                            "lon": 61.471467377939774
                        },
                        {
                            "lat": 55.505508482781494,
                            "lon": 61.46975129521386
                        },
                        {
                            "lat": 55.50909588364681,
                            "lon": 61.46928965657199
                        },
                        {
                            "lat": 55.50813081182889,
                            "lon": 61.467573597356996
                        },
                        {
                            "lat": 55.51171791198797,
                            "lon": 61.46711189910163
                        },
                        {
                            "lat": 55.510752783248904,
                            "lon": 61.465395863397895
                        },
                        {
                            "lat": 55.51433958274416,
                            "lon": 61.464934105543016
                        },
                        {
                            "lat": 55.520133256884186,
                            "lon": 61.475229983591184
                        },
                        {
                            "lat": 55.516545384475506,
                            "lon": 61.475691938295334
                        },
                        {
                            "lat": 55.51295739006951,
                            "lon": 61.476153800572426
                        },
                        {
                            "lat": 55.513923071569046,
                            "lon": 61.47786981334974
                        },
                        {
                            "lat": 55.51033477625812,
                            "lon": 61.47833161600258
                        },
                        {
                            "lat": 55.50936927368476,
                            "lon": 61.47661557041618
                        },
                        {
                            "lat": 55.50840389307162,
                            "lon": 61.47489951554252
                        },
                        {
                            "lat": 55.51199183054876,
                            "lon": 61.4744377785044
                        },
                        {
                            "lat": 55.51557964606832,
                            "lon": 61.47397594903853
                        },
                        {
                            "lat": 55.51916733961183,
                            "lon": 61.47351402715117
                        },
                        {
                            "lat": 55.520133256884186,
                            "lon": 61.475229983591184
                        },
                        {
                            "lat": 55.523721007277096,
                            "lon": 61.47476793646622
                        },
                        {
                            "lat": 55.52468722542772,
                            "lon": 61.476483850782785
                        },
                        {
                            "lat": 55.521099296172366,
                            "lon": 61.47694593073359
                        },
                        {
                            "lat": 55.520133256884186,
                            "lon": 61.475229983591184
                        },
                        {
                            "lat": 55.523721007277096,
                            "lon": 61.47476793646622
                        },
                        {
                            "lat": 55.4892294276556,
                            "lon": 61.46816447004419
                        },
                        {
                            "lat": 55.48826549375647,
                            "lon": 61.46644822839613
                        },
                        {
                            "lat": 55.49185284531261,
                            "lon": 61.46598705775885
                        },
                        {
                            "lat": 55.49281695809306,
                            "lon": 61.467703266644264
                        },
                        {
                            "lat": 55.4892294276556,
                            "lon": 61.46816447004419
                        },
                        {
                            "lat": 55.49019348331361,
                            "lon": 61.46988070242821
                        },
                        {
                            "lat": 55.48660565219624,
                            "lon": 61.470341846157524
                        },
                        {
                            "lat": 55.485641775459264,
                            "lon": 61.46862558101304
                        },
                        {
                            "lat": 55.4892294276556,
                            "lon": 61.46816447004419
                        },
                        {
                            "lat": 55.49019348331361,
                            "lon": 61.46988070242821
                        },
                        {
                            "lat": 55.54262504050103,
                            "lon": 61.474172064852034
                        },
                        {
                            "lat": 55.54165792835666,
                            "lon": 61.47245631474853
                        },
                        {
                            "lat": 55.545244946266145,
                            "lon": 61.47199371323029
                        },
                        {
                            "lat": 55.54621223714549,
                            "lon": 61.47370943047443
                        },
                        {
                            "lat": 55.54262504050103,
                            "lon": 61.474172064852034
                        },
                        {
                            "lat": 55.52509924982493,
                            "lon": 61.463548277679855
                        },
                        {
                            "lat": 55.52868556165525,
                            "lon": 61.463086150313494
                        },
                        {
                            "lat": 55.529651584045425,
                            "lon": 61.46480202196323
                        },
                        {
                            "lat": 55.526065093527315,
                            "lon": 61.46526418215158
                        },
                        {
                            "lat": 55.52509924982493,
                            "lon": 61.463548277679855
                        },
                        {
                            "lat": 55.53282941565766,
                            "lon": 61.477275252965654
                        },
                        {
                            "lat": 55.533796235675915,
                            "lon": 61.47899108299321
                        },
                        {
                            "lat": 55.5302081928547,
                            "lon": 61.47945341346243
                        },
                        {
                            "lat": 55.52924155169345,
                            "lon": 61.47773755059094
                        },
                        {
                            "lat": 55.53282941565766,
                            "lon": 61.477275252965654
                        },
                        {
                            "lat": 55.53669742862608,
                            "lon": 61.484138517193664
                        },
                        {
                            "lat": 55.53310884912282,
                            "lon": 61.48460094621524
                        },
                        {
                            "lat": 55.53214184154812,
                            "lon": 61.48288511127613
                        },
                        {
                            "lat": 55.53573024213886,
                            "lon": 61.48242271510879
                        },
                        {
                            "lat": 55.53669742862608,
                            "lon": 61.484138517193664
                        },
                        {
                            "lat": 55.56095687760105,
                            "lon": 61.458922848467346
                        },
                        {
                            "lat": 55.5619245072264,
                            "lon": 61.46063842446811
                        },
                        {
                            "lat": 55.56289225900158,
                            "lon": 61.462353991134485
                        },
                        {
                            "lat": 55.55930668848622,
                            "lon": 61.462817015283306
                        },
                        {
                            "lat": 55.558339115226254,
                            "lon": 61.46110141574129
                        },
                        {
                            "lat": 55.55475360109474,
                            "lon": 61.461564314696744
                        },
                        {
                            "lat": 55.553786328484165,
                            "lon": 61.45984867295716
                        },
                        {
                            "lat": 55.55737166409768,
                            "lon": 61.45938580686832
                        },
                        {
                            "lat": 55.556404335079264,
                            "lon": 61.45767018866599
                        },
                        {
                            "lat": 55.559989370104304,
                            "lon": 61.45720726313381
                        },
                        {
                            "lat": 55.56095687760105,
                            "lon": 61.458922848467346
                        },
                        {
                            "lat": 55.55185214951692,
                            "lon": 61.456417361501835
                        },
                        {
                            "lat": 55.55281917796531,
                            "lon": 61.45813302189165
                        },
                        {
                            "lat": 55.549233898780855,
                            "lon": 61.458595762804485
                        },
                        {
                            "lat": 55.54564849754439,
                            "lon": 61.45905841139825
                        },
                        {
                            "lat": 55.544681826121895,
                            "lon": 61.457342685305676
                        },
                        {
                            "lat": 55.54826704883481,
                            "lon": 61.45688006956051
                        },
                        {
                            "lat": 55.55185214951692,
                            "lon": 61.456417361501835
                        },
                        {
                            "lat": 55.550885243117804,
                            "lon": 61.45470169178929
                        },
                        {
                            "lat": 55.55447004328796,
                            "lon": 61.45423892427969
                        },
                        {
                            "lat": 55.55543712814976,
                            "lon": 61.455954561135925
                        },
                        {
                            "lat": 55.55185214951692,
                            "lon": 61.456417361501835
                        },
                        {
                            "lat": 55.550885243117804,
                            "lon": 61.45470169178929
                        },
                        {
                            "lat": 55.60679873156204,
                            "lon": 61.47876513561923
                        },
                        {
                            "lat": 55.605827904659236,
                            "lon": 61.477049977593495
                        },
                        {
                            "lat": 55.60485720032746,
                            "lon": 61.47533481018327
                        },
                        {
                            "lat": 55.60127094576297,
                            "lon": 61.475798994406944
                        },
                        {
                            "lat": 55.600300542488995,
                            "lon": 61.47408378465346
                        },
                        {
                            "lat": 55.6038866185454,
                            "lon": 61.47361963339008
                        },
                        {
                            "lat": 55.607472572073384,
                            "lon": 61.47315538981602
                        },
                        {
                            "lat": 55.60650193435136,
                            "lon": 61.47144023660392
                        },
                        {
                            "lat": 55.610087586885456,
                            "lon": 61.47097593369349
                        },
                        {
                            "lat": 55.61105840305452,
                            "lon": 61.472691053937574
                        },
                        {
                            "lat": 55.61202934178883,
                            "lon": 61.474406164793464
                        },
                        {
                            "lat": 55.61300040310968,
                            "lon": 61.47612126625951
                        },
                        {
                            "lat": 55.616586468432324,
                            "lon": 61.47565677212554
                        },
                        {
                            "lat": 55.62017241112868,
                            "lon": 61.475192185688634
                        },
                        {
                            "lat": 55.623758231180354,
                            "lon": 61.47472750695507
                        },
                        {
                            "lat": 55.624729950488,
                            "lon": 61.476442500061204
                        },
                        {
                            "lat": 55.621143951998015,
                            "lon": 61.47690721178984
                        },
                        {
                            "lat": 55.622115615533374,
                            "lon": 61.47862222849109
                        },
                        {
                            "lat": 55.61852931589931,
                            "lon": 61.47908688091042
                        },
                        {
                            "lat": 55.61494289359631,
                            "lon": 61.47955144101569
                        },
                        {
                            "lat": 55.61591432280473,
                            "lon": 61.48126651430258
                        },
                        {
                            "lat": 55.612327599312096,
                            "lon": 61.48173101507273
                        },
                        {
                            "lat": 55.61135634864279,
                            "lon": 61.48001590880057
                        },
                        {
                            "lat": 55.6103852206056,
                            "lon": 61.47830079313722
                        },
                        {
                            "lat": 55.60679873156204,
                            "lon": 61.47876513561923
                        },
                        {
                            "lat": 55.60776968105719,
                            "lon": 61.4804802842588
                        },
                        {
                            "lat": 55.604182890857906,
                            "lon": 61.480944567384086
                        },
                        {
                            "lat": 55.60321211992611,
                            "lon": 61.47922938577385
                        },
                        {
                            "lat": 55.60679873156204,
                            "lon": 61.47876513561923
                        },
                        {
                            "lat": 55.60776968105719,
                            "lon": 61.4804802842588
                        },
                        {
                            "lat": 55.595450363343026,
                            "lon": 61.46550759524974
                        },
                        {
                            "lat": 55.594480694809555,
                            "lon": 61.463792329252904
                        },
                        {
                            "lat": 55.5980657002042,
                            "lon": 61.4633283756788
                        },
                        {
                            "lat": 55.601650583198165,
                            "lon": 61.4628643298275
                        },
                        {
                            "lat": 55.600680680309864,
                            "lon": 61.46114912034021
                        },
                        {
                            "lat": 55.59971089981935,
                            "lon": 61.459433901479336
                        },
                        {
                            "lat": 55.60329530373939,
                            "lon": 61.458969829248176
                        },
                        {
                            "lat": 55.60687958526451,
                            "lon": 61.45850566476351
                        },
                        {
                            "lat": 55.607849722370226,
                            "lon": 61.46022081773113
                        },
                        {
                            "lat": 55.61143405975775,
                            "lon": 61.459756528044224
                        },
                        {
                            "lat": 55.6124044975921,
                            "lon": 61.46147163867293
                        },
                        {
                            "lat": 55.60881998191053,
                            "lon": 61.461935961318275
                        },
                        {
                            "lat": 55.60523534377309,
                            "lon": 61.462400191705214
                        },
                        {
                            "lat": 55.60620554743547,
                            "lon": 61.464115358866586
                        },
                        {
                            "lat": 55.60979036390668,
                            "lon": 61.46365109552332
                        },
                        {
                            "lat": 55.61076086837996,
                            "lon": 61.46536622034465
                        },
                        {
                            "lat": 55.60717587355663,
                            "lon": 61.46583051664766
                        },
                        {
                            "lat": 55.60359075625324,
                            "lon": 61.46629472067483
                        },
                        {
                            "lat": 55.60000551648823,
                            "lon": 61.466758832419934
                        },
                        {
                            "lat": 55.59642015428,
                            "lon": 61.46722285187665
                        },
                        {
                            "lat": 55.595450363343026,
                            "lon": 61.46550759524974
                        },
                        {
                            "lat": 55.598447345194,
                            "lon": 61.45039375906301
                        },
                        {
                            "lat": 55.597478120435795,
                            "lon": 61.448678516917155
                        },
                        {
                            "lat": 55.60106133256524,
                            "lon": 61.448214550056534
                        },
                        {
                            "lat": 55.60464442240892,
                            "lon": 61.447750490982024
                        },
                        {
                            "lat": 55.608227389948496,
                            "lon": 61.44728633969992
                        },
                        {
                            "lat": 55.609197149202956,
                            "lon": 61.4490014830429
                        },
                        {
                            "lat": 55.61016703080114,
                            "lon": 61.450716617008055
                        },
                        {
                            "lat": 55.606583706954865,
                            "lon": 61.4511808341733
                        },
                        {
                            "lat": 55.605614003519214,
                            "lon": 61.44946566726484
                        },
                        {
                            "lat": 55.60203073551011,
                            "lon": 61.44992975927365
                        },
                        {
                            "lat": 55.598447345194,
                            "lon": 61.45039375906301
                        },
                        {
                            "lat": 55.593894786038966,
                            "lon": 61.449142391557665
                        },
                        {
                            "lat": 55.592925861737655,
                            "lon": 61.44742710712621
                        },
                        {
                            "lat": 55.59650901794494,
                            "lon": 61.44696326540543
                        },
                        {
                            "lat": 55.597478120435795,
                            "lon": 61.448678516917155
                        },
                        {
                            "lat": 55.593894786038966,
                            "lon": 61.449142391557665
                        },
                        {
                            "lat": 55.58604995149199,
                            "lon": 61.455679555448135
                        },
                        {
                            "lat": 55.587018830000034,
                            "lon": 61.45739490118735
                        },
                        {
                            "lat": 55.5834342934357,
                            "lon": 61.457858538508766
                        },
                        {
                            "lat": 55.58246559327574,
                            "lon": 61.45614315985833
                        },
                        {
                            "lat": 55.58149701535493,
                            "lon": 61.45442777185445
                        },
                        {
                            "lat": 55.5850811952415,
                            "lon": 61.453964200352026
                        },
                        {
                            "lat": 55.58604995149199,
                            "lon": 61.455679555448135
                        },
                        {
                            "lat": 55.58963418745069,
                            "lon": 61.455215858775986
                        },
                        {
                            "lat": 55.58866525289176,
                            "lon": 61.45350053659325
                        },
                        {
                            "lat": 55.59224918828735,
                            "lon": 61.453036780584355
                        },
                        {
                            "lat": 55.59321830113345,
                            "lon": 61.45475206984819
                        },
                        {
                            "lat": 55.58963418745069,
                            "lon": 61.455215858775986
                        },
                        {
                            "lat": 55.6328737782379,
                            "lon": 61.47722771741158
                        },
                        {
                            "lat": 55.63190157939635,
                            "lon": 61.47551279972256
                        },
                        {
                            "lat": 55.635487209777914,
                            "lon": 61.47504781112505
                        },
                        {
                            "lat": 55.636459587012176,
                            "lon": 61.47676269579889
                        },
                        {
                            "lat": 55.6328737782379,
                            "lon": 61.47722771741158
                        },
                        {
                            "lat": 55.630929503276036,
                            "lon": 61.47379787262321
                        },
                        {
                            "lat": 55.627343928568926,
                            "lon": 61.47426273593114
                        },
                        {
                            "lat": 55.626372153525736,
                            "lon": 61.47254776642038
                        },
                        {
                            "lat": 55.62995754985556,
                            "lon": 61.47208293611515
                        },
                        {
                            "lat": 55.630929503276036,
                            "lon": 61.47379787262321
                        },
                        {
                            "lat": 55.61733331138034,
                            "lon": 61.44978790604651
                        },
                        {
                            "lat": 55.61375023228241,
                            "lon": 61.45025230763037
                        },
                        {
                            "lat": 55.61278017254295,
                            "lon": 61.44853720661407
                        },
                        {
                            "lat": 55.616363073520844,
                            "lon": 61.44807283798464
                        },
                        {
                            "lat": 55.61733331138034,
                            "lon": 61.44978790604651
                        },
                        {
                            "lat": 55.62727820407788,
                            "lon": 61.49452190505156
                        },
                        {
                            "lat": 55.62630561423179,
                            "lon": 61.492806939997905
                        },
                        {
                            "lat": 55.62989334235327,
                            "lon": 61.49234202351064
                        },
                        {
                            "lat": 55.6308661108432,
                            "lon": 61.49405695554022
                        },
                        {
                            "lat": 55.62727820407788,
                            "lon": 61.49452190505156
                        },
                        {
                            "lat": 55.620472654042004,
                            "lon": 61.48251695210289
                        },
                        {
                            "lat": 55.61950092362552,
                            "lon": 61.4808019212065
                        },
                        {
                            "lat": 55.62308740175607,
                            "lon": 61.48033723579076
                        },
                        {
                            "lat": 55.62667375717792,
                            "lon": 61.479872458061685
                        },
                        {
                            "lat": 55.627645844602824,
                            "lon": 61.48158742295281
                        },
                        {
                            "lat": 55.631232255769774,
                            "lon": 61.481122519905696
                        },
                        {
                            "lat": 55.63220464443372,
                            "lon": 61.48283744237419
                        },
                        {
                            "lat": 55.628618054776155,
                            "lon": 61.48330237843574
                        },
                        {
                            "lat": 55.62959038771927,
                            "lon": 61.485017324508846
                        },
                        {
                            "lat": 55.626003496761555,
                            "lon": 61.485482201264546
                        },
                        {
                            "lat": 55.62241648303113,
                            "lon": 61.4859469856902
                        },
                        {
                            "lat": 55.621444507170104,
                            "lon": 61.484231973597986
                        },
                        {
                            "lat": 55.61785754925843,
                            "lon": 61.48469663268609
                        },
                        {
                            "lat": 55.61688587468499,
                            "lon": 61.482981578193254
                        },
                        {
                            "lat": 55.620472654042004,
                            "lon": 61.48251695210289
                        },
                        {
                            "lat": 55.62570179248009,
                            "lon": 61.478157483763965
                        },
                        {
                            "lat": 55.62928784672106,
                            "lon": 61.47769264673538
                        },
                        {
                            "lat": 55.630259989872684,
                            "lon": 61.47940758802558
                        },
                        {
                            "lat": 55.62667375717792,
                            "lon": 61.479872458061685
                        },
                        {
                            "lat": 55.62570179248009,
                            "lon": 61.478157483763965
                        },
                        {
                            "lat": 55.61890147416185,
                            "lon": 61.46615240042931
                        },
                        {
                            "lat": 55.622486279741366,
                            "lon": 61.4656877943567
                        },
                        {
                            "lat": 55.62345756427183,
                            "lon": 61.46740280147249
                        },
                        {
                            "lat": 55.619872580367286,
                            "lon": 61.46786744052846
                        },
                        {
                            "lat": 55.61890147416185,
                            "lon": 61.46615240042931
                        },
                        {
                            "lat": 55.68401043551179,
                            "lon": 61.492681318200916
                        },
                        {
                            "lat": 55.683034798778216,
                            "lon": 61.490966877490976
                        },
                        {
                            "lat": 55.686621217119075,
                            "lon": 61.49050047704768
                        },
                        {
                            "lat": 55.68759703223071,
                            "lon": 61.49221488463969
                        },
                        {
                            "lat": 55.68401043551179,
                            "lon": 61.492681318200916
                        },
                        {
                            "lat": 55.65894543104844,
                            "lon": 61.47568530543836
                        },
                        {
                            "lat": 55.6625305583719,
                            "lon": 61.47521960492221
                        },
                        {
                            "lat": 55.66350442955798,
                            "lon": 61.47693423945303
                        },
                        {
                            "lat": 55.659919123954516,
                            "lon": 61.47739997303026
                        },
                        {
                            "lat": 55.65894543104844,
                            "lon": 61.47568530543836
                        },
                        {
                            "lat": 55.6615568100967,
                            "lon": 61.473504960950216
                        },
                        {
                            "lat": 55.66514163626896,
                            "lon": 61.47303920125317
                        },
                        {
                            "lat": 55.664167832661335,
                            "lon": 61.471324580901275
                        },
                        {
                            "lat": 55.667752357725114,
                            "lon": 61.47085876203733
                        },
                        {
                            "lat": 55.66872633953333,
                            "lon": 61.47257334932395
                        },
                        {
                            "lat": 55.66970044426776,
                            "lon": 61.47428792716406
                        },
                        {
                            "lat": 55.66611556278445,
                            "lon": 61.474753812162
                        },
                        {
                            "lat": 55.6625305583719,
                            "lon": 61.47521960492221
                        },
                        {
                            "lat": 55.6615568100967,
                            "lon": 61.473504960950216
                        },
                        {
                            "lat": 55.680487004500684,
                            "lon": 61.45263198768082
                        },
                        {
                            "lat": 55.68406906720984,
                            "lon": 61.452165821188515
                        },
                        {
                            "lat": 55.68504318929444,
                            "lon": 61.45388026202677
                        },
                        {
                            "lat": 55.681460948699,
                            "lon": 61.454346461592074
                        },
                        {
                            "lat": 55.680487004500684,
                            "lon": 61.45263198768082
                        },
                        {
                            "lat": 55.66713075805968,
                            "lon": 61.456210338409335
                        },
                        {
                            "lat": 55.67071348992152,
                            "lon": 61.45574450743421
                        },
                        {
                            "lat": 55.67168702312208,
                            "lon": 61.45745907108879
                        },
                        {
                            "lat": 55.66810411327033,
                            "lon": 61.45792493511817
                        },
                        {
                            "lat": 55.66713075805968,
                            "lon": 61.456210338409335
                        },
                        {
                            "lat": 55.71337567319681,
                            "lon": 61.442823692130034
                        },
                        {
                            "lat": 55.71435102634576,
                            "lon": 61.444537863514206
                        },
                        {
                            "lat": 55.71077042519742,
                            "lon": 61.445004792680365
                        },
                        {
                            "lat": 55.70718970110458,
                            "lon": 61.44547162978176
                        },
                        {
                            "lat": 55.70621470325598,
                            "lon": 61.44375739217812
                        },
                        {
                            "lat": 55.70979524968797,
                            "lon": 61.44329058818368
                        },
                        {
                            "lat": 55.71337567319681,
                            "lon": 61.442823692130034
                        },
                        {
                            "lat": 55.72182475919423,
                            "lon": 61.51169819508847
                        },
                        {
                            "lat": 55.72541281162389,
                            "lon": 61.511230684345605
                        },
                        {
                            "lat": 55.72639160360776,
                            "lon": 61.51294472685016
                        },
                        {
                            "lat": 55.72280337269323,
                            "lon": 61.51341227079849
                        },
                        {
                            "lat": 55.72182475919423,
                            "lon": 61.51169819508847
                        },
                        {
                            "lat": 55.81054239967475,
                            "lon": 61.38406532403887
                        },
                        {
                            "lat": 55.81152069729841,
                            "lon": 61.385778605788744
                        },
                        {
                            "lat": 55.80794870449186,
                            "lon": 61.386247829932834
                        },
                        {
                            "lat": 55.806970583329786,
                            "lon": 61.38453451496939
                        },
                        {
                            "lat": 55.81054239967475,
                            "lon": 61.38406532403887
                        }
                    ],
                    [
                        {
                            "lat": 55.471725892676204,
                            "lon": 61.38209479280106
                        },
                        {
                            "lat": 55.470766355006845,
                            "lon": 61.38037836927159
                        },
                        {
                            "lat": 55.46718841360274,
                            "lon": 61.38083861625766
                        },
                        {
                            "lat": 55.46814777342451,
                            "lon": 61.3825550724246
                        },
                        {
                            "lat": 55.471725892676204,
                            "lon": 61.38209479280106
                        }
                    ],
                    [
                        {
                            "lat": 55.473384580933384,
                            "lon": 61.37820163007359
                        },
                        {
                            "lat": 55.47434417553739,
                            "lon": 61.37991803017788
                        },
                        {
                            "lat": 55.47792187517596,
                            "lon": 61.37945759898276
                        },
                        {
                            "lat": 55.47696210278469,
                            "lon": 61.377741231523665
                        },
                        {
                            "lat": 55.47600245128281,
                            "lon": 61.37602485484485
                        },
                        {
                            "lat": 55.47242510720037,
                            "lon": 61.376485220753
                        },
                        {
                            "lat": 55.46884764226788,
                            "lon": 61.376945494570855
                        },
                        {
                            "lat": 55.469806938211065,
                            "lon": 61.37866193652764
                        },
                        {
                            "lat": 55.473384580933384,
                            "lon": 61.37820163007359
                        }
                    ],
                    [
                        {
                            "lat": 55.50815631382413,
                            "lon": 61.41972493993229
                        },
                        {
                            "lat": 55.511737921575445,
                            "lon": 61.41926344756012
                        },
                        {
                            "lat": 55.51531940785631,
                            "lon": 61.41880186297773
                        },
                        {
                            "lat": 55.51435601511151,
                            "lon": 61.417085848225916
                        },
                        {
                            "lat": 55.51339274383448,
                            "lon": 61.41536982420313
                        },
                        {
                            "lat": 55.509811613824496,
                            "lon": 61.41583134328999
                        },
                        {
                            "lat": 55.5107747069753,
                            "lon": 61.41754740005886
                        },
                        {
                            "lat": 55.50719327738972,
                            "lon": 61.418008859687106
                        },
                        {
                            "lat": 55.50815631382413,
                            "lon": 61.41972493993229
                        }
                    ],
                    [
                        {
                            "lat": 55.505807955831735,
                            "lon": 61.429228237687056
                        },
                        {
                            "lat": 55.50484466850521,
                            "lon": 61.42751213828116
                        },
                        {
                            "lat": 55.50842723215819,
                            "lon": 61.42705069938287
                        },
                        {
                            "lat": 55.507463888049585,
                            "lon": 61.42533462345815
                        },
                        {
                            "lat": 55.50388150267826,
                            "lon": 61.425796029609984
                        },
                        {
                            "lat": 55.502918458329766,
                            "lon": 61.42407991167519
                        },
                        {
                            "lat": 55.49933612976429,
                            "lon": 61.424541192843876
                        },
                        {
                            "lat": 55.50029899582846,
                            "lon": 61.42625734351614
                        },
                        {
                            "lat": 55.50126198335265,
                            "lon": 61.427973484928174
                        },
                        {
                            "lat": 55.50222509235797,
                            "lon": 61.42968961707841
                        },
                        {
                            "lat": 55.503188322865554,
                            "lon": 61.431405739965264
                        },
                        {
                            "lat": 55.50677136467899,
                            "lon": 61.43094432782618
                        },
                        {
                            "lat": 55.505807955831735,
                            "lon": 61.429228237687056
                        }
                    ],
                    [
                        {
                            "lat": 55.513245775893985,
                            "lon": 61.43563093994504
                        },
                        {
                            "lat": 55.51228182398405,
                            "lon": 61.433914910379364
                        },
                        {
                            "lat": 55.508698547020046,
                            "lon": 61.43437648029776
                        },
                        {
                            "lat": 55.5096623205561,
                            "lon": 61.43609254262706
                        },
                        {
                            "lat": 55.513245775893985,
                            "lon": 61.43563093994504
                        }
                    ],
                    [
                        {
                            "lat": 55.54056739677624,
                            "lon": 61.49100163234295
                        },
                        {
                            "lat": 55.53697810143771,
                            "lon": 61.491464192815926
                        },
                        {
                            "lat": 55.533388683833735,
                            "lon": 61.491926660824724
                        },
                        {
                            "lat": 55.53435612349645,
                            "lon": 61.49364248204393
                        },
                        {
                            "lat": 55.537945720126835,
                            "lon": 61.49317998116927
                        },
                        {
                            "lat": 55.5415351944705,
                            "lon": 61.49271738782481
                        },
                        {
                            "lat": 55.54056739677624,
                            "lon": 61.49100163234295
                        }
                    ],
                    [
                        {
                            "lat": 55.62503134234883,
                            "lon": 61.483767222179125
                        },
                        {
                            "lat": 55.624059310687414,
                            "lon": 61.4820522336873
                        },
                        {
                            "lat": 55.620472654042004,
                            "lon": 61.48251695210289
                        },
                        {
                            "lat": 55.621444507170104,
                            "lon": 61.484231973597986
                        },
                        {
                            "lat": 55.62503134234883,
                            "lon": 61.483767222179125
                        }
                    ],
                    [
                        {
                            "lat": 55.691142312263345,
                            "lon": 61.5322643432077
                        },
                        {
                            "lat": 55.68755116839285,
                            "lon": 61.53273104413502
                        },
                        {
                            "lat": 55.68852874985713,
                            "lon": 61.53444543254724
                        },
                        {
                            "lat": 55.69212007263623,
                            "lon": 61.53397869844996
                        },
                        {
                            "lat": 55.691142312263345,
                            "lon": 61.5322643432077
                        }
                    ],
                    [
                        {
                            "lat": 55.64854064820932,
                            "lon": 61.35553391833394
                        },
                        {
                            "lat": 55.64757248850289,
                            "lon": 61.35381911498999
                        },
                        {
                            "lat": 55.644000966746354,
                            "lon": 61.354283893913255
                        },
                        {
                            "lat": 55.64496894969294,
                            "lon": 61.35599873016512
                        },
                        {
                            "lat": 55.64854064820932,
                            "lon": 61.35553391833394
                        }
                    ],
                    [
                        {
                            "lat": 55.781862917253456,
                            "lon": 61.52228031963862
                        },
                        {
                            "lat": 55.78545101989404,
                            "lon": 61.52181118417375
                        },
                        {
                            "lat": 55.7890389984354,
                            "lon": 61.521341956434334
                        },
                        {
                            "lat": 55.79262685285925,
                            "lon": 61.52087263642676
                        },
                        {
                            "lat": 55.796214583147275,
                            "lon": 61.52040322415737
                        },
                        {
                            "lat": 55.799802189281166,
                            "lon": 61.519933719632526
                        },
                        {
                            "lat": 55.80338967124267,
                            "lon": 61.51946412285858
                        },
                        {
                            "lat": 55.806977029013474,
                            "lon": 61.51899443384189
                        },
                        {
                            "lat": 55.8105642625753,
                            "lon": 61.51852465258887
                        },
                        {
                            "lat": 55.81415137190986,
                            "lon": 61.51805477910585
                        },
                        {
                            "lat": 55.81773835699892,
                            "lon": 61.5175848133992
                        },
                        {
                            "lat": 55.82132521782418,
                            "lon": 61.517114755475276
                        },
                        {
                            "lat": 55.82491195436739,
                            "lon": 61.516644605340446
                        },
                        {
                            "lat": 55.82849856661031,
                            "lon": 61.51617436300112
                        },
                        {
                            "lat": 55.82751402367835,
                            "lon": 61.51446127233806
                        },
                        {
                            "lat": 55.82392758952467,
                            "lon": 61.51493148129635
                        },
                        {
                            "lat": 55.82034103109232,
                            "lon": 61.515401598055675
                        },
                        {
                            "lat": 55.81675434839951,
                            "lon": 61.51587162260967
                        },
                        {
                            "lat": 55.81577046404228,
                            "lon": 61.51415842220714
                        },
                        {
                            "lat": 55.812183835243076,
                            "lon": 61.51462832118846
                        },
                        {
                            "lat": 55.808597082241505,
                            "lon": 61.51509812795718
                        },
                        {
                            "lat": 55.805010205055886,
                            "lon": 61.515567842507046
                        },
                        {
                            "lat": 55.80142320370446,
                            "lon": 61.5160374648316
                        },
                        {
                            "lat": 55.80044015616742,
                            "lon": 61.514324121421225
                        },
                        {
                            "lat": 55.79685320887231,
                            "lon": 61.514793618178864
                        },
                        {
                            "lat": 55.79326613746954,
                            "lon": 61.51526302270405
                        },
                        {
                            "lat": 55.78967894197739,
                            "lon": 61.51573233499038
                        },
                        {
                            "lat": 55.78609162241415,
                            "lon": 61.51620155503153
                        },
                        {
                            "lat": 55.78707395700635,
                            "lon": 61.517915031749766
                        },
                        {
                            "lat": 55.783486335100164,
                            "lon": 61.518384192852515
                        },
                        {
                            "lat": 55.779898589137915,
                            "lon": 61.518853261691774
                        },
                        {
                            "lat": 55.78088069116604,
                            "lon": 61.52056679545426
                        },
                        {
                            "lat": 55.777292642814444,
                            "lon": 61.52103580532924
                        },
                        {
                            "lat": 55.77827469053198,
                            "lon": 61.52274936282259
                        },
                        {
                            "lat": 55.781862917253456,
                            "lon": 61.52228031963862
                        }
                    ]
                ]
            },
            {
                "type": "MultiPolygon",
                "value": 125.0,
                "data": [
                    [
                        {
                            "lat": 55.33734935358459,
                            "lon": 61.39562847526198
                        },
                        {
                            "lat": 55.338302176751895,
                            "lon": 61.397346111748305
                        },
                        {
                            "lat": 55.334719775994564,
                            "lon": 61.397802946393554
                        },
                        {
                            "lat": 55.335672540729654,
                            "lon": 61.39952060622201
                        },
                        {
                            "lat": 55.332089841157895,
                            "lon": 61.39997738094933
                        },
                        {
                            "lat": 55.33304254742364,
                            "lon": 61.401695064119586
                        },
                        {
                            "lat": 55.336625425657004,
                            "lon": 61.4012382569638
                        },
                        {
                            "lat": 55.34020818367721,
                            "lon": 61.40078135745378
                        },
                        {
                            "lat": 55.34379082146568,
                            "lon": 61.400324365595736
                        },
                        {
                            "lat": 55.34474418390387,
                            "lon": 61.40204194236871
                        },
                        {
                            "lat": 55.345697666613276,
                            "lon": 61.40375951004512
                        },
                        {
                            "lat": 55.342114671529465,
                            "lon": 61.40421656679275
                        },
                        {
                            "lat": 55.34306809585544,
                            "lon": 61.40593415782093
                        },
                        {
                            "lat": 55.33948480180164,
                            "lon": 61.4063911546536
                        },
                        {
                            "lat": 55.33590138747194,
                            "lon": 61.40684805911518
                        },
                        {
                            "lat": 55.33231785288496,
                            "lon": 61.40730487119956
                        },
                        {
                            "lat": 55.32873419805926,
                            "lon": 61.40776159090052
                        },
                        {
                            "lat": 55.32968702769414,
                            "lon": 61.409479302589475
                        },
                        {
                            "lat": 55.330639977570044,
                            "lon": 61.411197005194225
                        },
                        {
                            "lat": 55.33159304770789,
                            "lon": 61.4129146987132
                        },
                        {
                            "lat": 55.33517723892266,
                            "lon": 61.41245788170952
                        },
                        {
                            "lat": 55.33876130983597,
                            "lon": 61.412000972305584
                        },
                        {
                            "lat": 55.342345260429205,
                            "lon": 61.4115439705076
                        },
                        {
                            "lat": 55.34592909068383,
                            "lon": 61.41108687632173
                        },
                        {
                            "lat": 55.349512800581195,
                            "lon": 61.41062968975416
                        },
                        {
                            "lat": 55.35309639010277,
                            "lon": 61.41017241081112
                        },
                        {
                            "lat": 55.35405065321084,
                            "lon": 61.41188990051239
                        },
                        {
                            "lat": 55.357634301045984,
                            "lon": 61.411432496725325
                        },
                        {
                            "lat": 55.36121782844724,
                            "lon": 61.41097500056952
                        },
                        {
                            "lat": 55.36480123539603,
                            "lon": 61.410517412051185
                        },
                        {
                            "lat": 55.3683845218738,
                            "lon": 61.41005973117647
                        },
                        {
                            "lat": 55.369339620177655,
                            "lon": 61.41177708182251
                        },
                        {
                            "lat": 55.37029483899203,
                            "lon": 61.4134944233441
                        },
                        {
                            "lat": 55.3712501783379,
                            "lon": 61.41521175573977
                        },
                        {
                            "lat": 55.36766635581337,
                            "lon": 61.41566953410941
                        },
                        {
                            "lat": 55.368621636992295,
                            "lon": 61.41738688988271
                        },
                        {
                            "lat": 55.37220563823623,
                            "lon": 61.416929079007936
                        },
                        {
                            "lat": 55.37316121870795,
                            "lon": 61.41864639314704
                        },
                        {
                            "lat": 55.376745278095434,
                            "lon": 61.41818845738555
                        },
                        {
                            "lat": 55.375789518906736,
                            "lon": 61.41647117576058
                        },
                        {
                            "lat": 55.37937327898526,
                            "lon": 61.41601318014684
                        },
                        {
                            "lat": 55.382956918453274,
                            "lon": 61.41555509217294
                        },
                        {
                            "lat": 55.3820009225226,
                            "lon": 61.41383786645376
                        },
                        {
                            "lat": 55.385584262726006,
                            "lon": 61.4133797186534
                        },
                        {
                            "lat": 55.38916748230276,
                            "lon": 61.412921478510874
                        },
                        {
                            "lat": 55.39275058123432,
                            "lon": 61.41246314603234
                        },
                        {
                            "lat": 55.391794170123774,
                            "lon": 61.41074600876466
                        },
                        {
                            "lat": 55.39537696983753,
                            "lon": 61.41028761649726
                        },
                        {
                            "lat": 55.39442050083423,
                            "lon": 61.408570502620734
                        },
                        {
                            "lat": 55.39800300137214,
                            "lon": 61.40811205057849
                        },
                        {
                            "lat": 55.39895964889,
                            "lon": 61.40982913191191
                        },
                        {
                            "lat": 55.40254220726265,
                            "lon": 61.40937055501477
                        },
                        {
                            "lat": 55.40349915397242,
                            "lon": 61.41108759464286
                        },
                        {
                            "lat": 55.39991641708766,
                            "lon": 61.411546204092126
                        },
                        {
                            "lat": 55.400873305986124,
                            "lon": 61.413263267117635
                        },
                        {
                            "lat": 55.40183031560637,
                            "lon": 61.414980320986786
                        },
                        {
                            "lat": 55.39824710089909,
                            "lon": 61.41543890322195
                        },
                        {
                            "lat": 55.39466376546758,
                            "lon": 61.41589739312234
                        },
                        {
                            "lat": 55.39562053863228,
                            "lon": 61.417614502941575
                        },
                        {
                            "lat": 55.392036903864415,
                            "lon": 61.41807293304654
                        },
                        {
                            "lat": 55.38845314838842,
                            "lon": 61.418531270798674
                        },
                        {
                            "lat": 55.38940968496044,
                            "lon": 61.42024843655763
                        },
                        {
                            "lat": 55.390366342243034,
                            "lon": 61.42196559316919
                        },
                        {
                            "lat": 55.38678210867657,
                            "lon": 61.42242390364133
                        },
                        {
                            "lat": 55.38773870796247,
                            "lon": 61.424141083648585
                        },
                        {
                            "lat": 55.38869542798238,
                            "lon": 61.4258582545088
                        },
                        {
                            "lat": 55.38965226875728,
                            "lon": 61.427575416220435
                        },
                        {
                            "lat": 55.390609230308215,
                            "lon": 61.4292925687819
                        },
                        {
                            "lat": 55.39156631265617,
                            "lon": 61.43100971219165
                        },
                        {
                            "lat": 55.38798106444469,
                            "lon": 61.43146809301633
                        },
                        {
                            "lat": 55.388938088768576,
                            "lon": 61.43318525982882
                        },
                        {
                            "lat": 55.39252351582216,
                            "lon": 61.432726846448126
                        },
                        {
                            "lat": 55.39348083982722,
                            "lon": 61.4344439715498
                        },
                        {
                            "lat": 55.389895233912945,
                            "lon": 61.43490241748993
                        },
                        {
                            "lat": 55.39085249989881,
                            "lon": 61.436619565998065
                        },
                        {
                            "lat": 55.391809886747225,
                            "lon": 61.43833670535167
                        },
                        {
                            "lat": 55.39539585043861,
                            "lon": 61.43787819428244
                        },
                        {
                            "lat": 55.39898169322784,
                            "lon": 61.437419590786384
                        },
                        {
                            "lat": 55.39802394860479,
                            "lon": 61.43570251657088
                        },
                        {
                            "lat": 55.401609491617535,
                            "lon": 61.435243853231654
                        },
                        {
                            "lat": 55.40065168905695,
                            "lon": 61.43352680242888
                        },
                        {
                            "lat": 55.40423693233526,
                            "lon": 61.433068079260494
                        },
                        {
                            "lat": 55.4078220546978,
                            "lon": 61.43260926369514
                        },
                        {
                            "lat": 55.40686401546027,
                            "lon": 61.43089226888725
                        },
                        {
                            "lat": 55.41044883813291,
                            "lon": 61.430433393518534
                        },
                        {
                            "lat": 55.41403353987368,
                            "lon": 61.42997442577087
                        },
                        {
                            "lat": 55.414991936601275,
                            "lon": 61.431691355398264
                        },
                        {
                            "lat": 55.411407056125974,
                            "lon": 61.43215035573897
                        },
                        {
                            "lat": 55.412365395071944,
                            "lon": 61.43386730878599
                        },
                        {
                            "lat": 55.4159504543003,
                            "lon": 61.43340827584879
                        },
                        {
                            "lat": 55.419535392536154,
                            "lon": 61.43294915052763
                        },
                        {
                            "lat": 55.423120209760974,
                            "lon": 61.43248993282875
                        },
                        {
                            "lat": 55.426704905956214,
                            "lon": 61.43203062275834
                        },
                        {
                            "lat": 55.43028948110335,
                            "lon": 61.431571220322645
                        },
                        {
                            "lat": 55.43124883467081,
                            "lon": 61.43328800113922
                        },
                        {
                            "lat": 55.43483346741766,
                            "lon": 61.432828473716555
                        },
                        {
                            "lat": 55.435793120757694,
                            "lon": 61.43454521270816
                        },
                        {
                            "lat": 55.43675289522503,
                            "lon": 61.43626194250118
                        },
                        {
                            "lat": 55.44033776421181,
                            "lon": 61.43580225744284
                        },
                        {
                            "lat": 55.441297838528364,
                            "lon": 61.43751894539191
                        },
                        {
                            "lat": 55.43771279084071,
                            "lon": 61.437978663093965
                        },
                        {
                            "lat": 55.43412762198361,
                            "lon": 61.43843828842077
                        },
                        {
                            "lat": 55.43508746002825,
                            "lon": 61.44015503245336
                        },
                        {
                            "lat": 55.43150199125872,
                            "lon": 61.44061459803464
                        },
                        {
                            "lat": 55.42791640133574,
                            "lon": 61.44107407122265
                        },
                        {
                            "lat": 55.4243306902779,
                            "lon": 61.44153345201111
                        },
                        {
                            "lat": 55.42529011309097,
                            "lon": 61.443250284745105
                        },
                        {
                            "lat": 55.42170410207347,
                            "lon": 61.443709605750406
                        },
                        {
                            "lat": 55.41811796993715,
                            "lon": 61.44416883433816
                        },
                        {
                            "lat": 55.41907715614278,
                            "lon": 61.44588572312732
                        },
                        {
                            "lat": 55.41549072400213,
                            "lon": 61.446344891906044
                        },
                        {
                            "lat": 55.416449852406494,
                            "lon": 61.44806180412748
                        },
                        {
                            "lat": 55.42003646346974,
                            "lon": 61.44760260273055
                        },
                        {
                            "lat": 55.42362295339056,
                            "lon": 61.44714330889862
                        },
                        {
                            "lat": 55.42720932215038,
                            "lon": 61.44668392263792
                        },
                        {
                            "lat": 55.430795569730634,
                            "lon": 61.44622444395467
                        },
                        {
                            "lat": 55.43438169611279,
                            "lon": 61.44576487285511
                        },
                        {
                            "lat": 55.43796770127826,
                            "lon": 61.44530520934545
                        },
                        {
                            "lat": 55.43892802413724,
                            "lon": 61.447021916569014
                        },
                        {
                            "lat": 55.44251408688214,
                            "lon": 61.44656212800023
                        },
                        {
                            "lat": 55.44347470983063,
                            "lon": 61.448278793359
                        },
                        {
                            "lat": 55.447060830112775,
                            "lon": 61.44781887972272
                        },
                        {
                            "lat": 55.44802175318787,
                            "lon": 61.44953550320264
                        },
                        {
                            "lat": 55.44443545407509,
                            "lon": 61.449995449506595
                        },
                        {
                            "lat": 55.44539631963661,
                            "lon": 61.45171209644146
                        },
                        {
                            "lat": 55.44180972033646,
                            "lon": 61.452171982996454
                        },
                        {
                            "lat": 55.43822299971671,
                            "lon": 61.452631777125205
                        },
                        {
                            "lat": 55.43463615779589,
                            "lon": 61.45309147882144
                        },
                        {
                            "lat": 55.43559660796569,
                            "lon": 61.454808214532
                        },
                        {
                            "lat": 55.436557179439106,
                            "lon": 61.45652494103695
                        },
                        {
                            "lat": 55.44014437924064,
                            "lon": 61.45606517402185
                        },
                        {
                            "lat": 55.44373145769888,
                            "lon": 61.45560531456305
                        },
                        {
                            "lat": 55.44731841479528,
                            "lon": 61.45514536266676
                        },
                        {
                            "lat": 55.44827964443467,
                            "lon": 61.456861981954056
                        },
                        {
                            "lat": 55.451866659055476,
                            "lon": 61.456401904945146
                        },
                        {
                            "lat": 55.45282818901978,
                            "lon": 61.45811848232864
                        },
                        {
                            "lat": 55.456415261122764,
                            "lon": 61.45765828019871
                        },
                        {
                            "lat": 55.46000221176598,
                            "lon": 61.457197985638814
                        },
                        {
                            "lat": 55.45904032401829,
                            "lon": 61.455481473641754
                        },
                        {
                            "lat": 55.45807855772777,
                            "lon": 61.45376495241538
                        },
                        {
                            "lat": 55.4571169128733,
                            "lon": 61.452048421961315
                        },
                        {
                            "lat": 55.45353049881785,
                            "lon": 61.452508618443474
                        },
                        {
                            "lat": 55.45449196482827,
                            "lon": 61.45422518158669
                        },
                        {
                            "lat": 55.450905250511255,
                            "lon": 61.45468531833923
                        },
                        {
                            "lat": 55.44994396336599,
                            "lon": 61.45296872251246
                        },
                        {
                            "lat": 55.44898279759856,
                            "lon": 61.45125211746642
                        },
                        {
                            "lat": 55.4525691542038,
                            "lon": 61.45079204607756
                        },
                        {
                            "lat": 55.45160793096502,
                            "lon": 61.44907546449047
                        },
                        {
                            "lat": 55.450646829080405,
                            "lon": 61.44735887368383
                        },
                        {
                            "lat": 55.44968584852883,
                            "lon": 61.44564227365919
                        },
                        {
                            "lat": 55.44872498928919,
                            "lon": 61.443925664418124
                        },
                        {
                            "lat": 55.447764251340395,
                            "lon": 61.44220904596217
                        },
                        {
                            "lat": 55.444178788690124,
                            "lon": 61.442668954001775
                        },
                        {
                            "lat": 55.440593204788634,
                            "lon": 61.443128769655544
                        },
                        {
                            "lat": 55.437007499654484,
                            "lon": 61.44358849291725
                        },
                        {
                            "lat": 55.43604741924484,
                            "lon": 61.44187176728603
                        },
                        {
                            "lat": 55.43963294560144,
                            "lon": 61.441412076672705
                        },
                        {
                            "lat": 55.44321835074644,
                            "lon": 61.44095229367295
                        },
                        {
                            "lat": 55.44680363466133,
                            "lon": 61.44049241829293
                        },
                        {
                            "lat": 55.44584313923091,
                            "lon": 61.43877578141194
                        },
                        {
                            "lat": 55.44488276502807,
                            "lon": 61.43705913532079
                        },
                        {
                            "lat": 55.443922512031705,
                            "lon": 61.43534248002108
                        },
                        {
                            "lat": 55.44296238022075,
                            "lon": 61.4336258155143
                        },
                        {
                            "lat": 55.43937781106214,
                            "lon": 61.43408556029014
                        },
                        {
                            "lat": 55.438417979058265,
                            "lon": 61.432368853935415
                        },
                        {
                            "lat": 55.44200236957413,
                            "lon": 61.43190914180204
                        },
                        {
                            "lat": 55.441042480070784,
                            "lon": 61.430192458885905
                        },
                        {
                            "lat": 55.44462657084021,
                            "lon": 61.429732687051164
                        },
                        {
                            "lat": 55.44366662387441,
                            "lon": 61.42801602757392
                        },
                        {
                            "lat": 55.442706798028254,
                            "lon": 61.4262993588925
                        },
                        {
                            "lat": 55.43912306440965,
                            "lon": 61.42675906544816
                        },
                        {
                            "lat": 55.43816353820977,
                            "lon": 61.42504235492971
                        },
                        {
                            "lat": 55.43457986207976,
                            "lon": 61.425501936527304
                        },
                        {
                            "lat": 55.43099606490921,
                            "lon": 61.425961425795016
                        },
                        {
                            "lat": 55.42741214671662,
                            "lon": 61.42642082272669
                        },
                        {
                            "lat": 55.42645327728945,
                            "lon": 61.42470400515115
                        },
                        {
                            "lat": 55.43003701688976,
                            "lon": 61.42424464083371
                        },
                        {
                            "lat": 55.42907808987131,
                            "lon": 61.422527846683145
                        },
                        {
                            "lat": 55.428119283832814,
                            "lon": 61.42081104334488
                        },
                        {
                            "lat": 55.42716059875324,
                            "lon": 61.4190942308205
                        },
                        {
                            "lat": 55.42620203461155,
                            "lon": 61.41737740911154
                        },
                        {
                            "lat": 55.42261900919511,
                            "lon": 61.41783664300628
                        },
                        {
                            "lat": 55.419035862880285,
                            "lon": 61.4182957845867
                        },
                        {
                            "lat": 55.41545259568562,
                            "lon": 61.41875483384656
                        },
                        {
                            "lat": 55.41449468802292,
                            "lon": 61.417037905179946
                        },
                        {
                            "lat": 55.41807777667589,
                            "lon": 61.41657888850603
                        },
                        {
                            "lat": 55.421660744469996,
                            "lon": 61.41611977951717
                        },
                        {
                            "lat": 55.42070260062225,
                            "lon": 61.414402906850015
                        },
                        {
                            "lat": 55.41974457763085,
                            "lon": 61.412686025006415
                        },
                        {
                            "lat": 55.41878667547479,
                            "lon": 61.41096913398791
                        },
                        {
                            "lat": 55.417828894133095,
                            "lon": 61.40925223379604
                        },
                        {
                            "lat": 55.4142466402367,
                            "lon": 61.409711212452784
                        },
                        {
                            "lat": 55.413289158116534,
                            "lon": 61.40799427051465
                        },
                        {
                            "lat": 55.41233179675022,
                            "lon": 61.406277319409654
                        },
                        {
                            "lat": 55.4113745561168,
                            "lon": 61.404560359139396
                        },
                        {
                            "lat": 55.407792716737475,
                            "lon": 61.405019147807074
                        },
                        {
                            "lat": 55.4068357752096,
                            "lon": 61.403302145814415
                        },
                        {
                            "lat": 55.40587895435416,
                            "lon": 61.40158513466299
                        },
                        {
                            "lat": 55.404922254150186,
                            "lon": 61.39986811435436
                        },
                        {
                            "lat": 55.40134082926579,
                            "lon": 61.40032671309101
                        },
                        {
                            "lat": 55.3977592837694,
                            "lon": 61.40078521955624
                        },
                        {
                            "lat": 55.39680306093509,
                            "lon": 61.39906812501183
                        },
                        {
                            "lat": 55.3932215732462,
                            "lon": 61.399526506667776
                        },
                        {
                            "lat": 55.39417761767948,
                            "lon": 61.40124363374387
                        },
                        {
                            "lat": 55.39059583101458,
                            "lon": 61.401701955647695
                        },
                        {
                            "lat": 55.38963996500325,
                            "lon": 61.39998479604548
                        },
                        {
                            "lat": 55.38605823622475,
                            "lon": 61.400442993138796
                        },
                        {
                            "lat": 55.38701392379318,
                            "lon": 61.4021601852615
                        },
                        {
                            "lat": 55.383431896033784,
                            "lon": 61.40261832257912
                        },
                        {
                            "lat": 55.38247638692924,
                            "lon": 61.400901097941514
                        },
                        {
                            "lat": 55.38152099832319,
                            "lon": 61.39918386417024
                        },
                        {
                            "lat": 55.38510266917336,
                            "lon": 61.398725791879016
                        },
                        {
                            "lat": 55.38868421952747,
                            "lon": 61.3982676273028
                        },
                        {
                            "lat": 55.392265649366955,
                            "lon": 61.397809370447774
                        },
                        {
                            "lat": 55.391309846020825,
                            "lon": 61.39609222508547
                        },
                        {
                            "lat": 55.39035416318686,
                            "lon": 61.39437507058239
                        },
                        {
                            "lat": 55.38677309009876,
                            "lon": 61.39483326240224
                        },
                        {
                            "lat": 55.38581770610394,
                            "lon": 61.393116066247494
                        },
                        {
                            "lat": 55.382236690911945,
                            "lon": 61.393574133292816
                        },
                        {
                            "lat": 55.38128160571929,
                            "lon": 61.3918568955003
                        },
                        {
                            "lat": 55.37770064846528,
                            "lon": 61.39231483777953
                        },
                        {
                            "lat": 55.374119570817406,
                            "lon": 61.39277268778996
                        },
                        {
                            "lat": 55.373164962763546,
                            "lon": 61.391055375884704
                        },
                        {
                            "lat": 55.37221047506412,
                            "lon": 61.38933805485849
                        },
                        {
                            "lat": 55.36862963381156,
                            "lon": 61.38979574763068
                        },
                        {
                            "lat": 55.36958394313492,
                            "lon": 61.39151310113678
                        },
                        {
                            "lat": 55.36600280317036,
                            "lon": 61.39197073411331
                        },
                        {
                            "lat": 55.36242154288841,
                            "lon": 61.39242827480802
                        },
                        {
                            "lat": 55.36146759037992,
                            "lon": 61.390710856358886
                        },
                        {
                            "lat": 55.357886388237894,
                            "lon": 61.39116827230255
                        },
                        {
                            "lat": 55.35884016230761,
                            "lon": 61.39288572321481
                        },
                        {
                            "lat": 55.355258661446456,
                            "lon": 61.39334307932742
                        },
                        {
                            "lat": 55.354305065836414,
                            "lon": 61.39162559595766
                        },
                        {
                            "lat": 55.35072362319404,
                            "lon": 61.392082827318035
                        },
                        {
                            "lat": 55.34714206032931,
                            "lon": 61.392539966377505
                        },
                        {
                            "lat": 55.34356037726074,
                            "lon": 61.39299701312989
                        },
                        {
                            "lat": 55.33997857400689,
                            "lon": 61.393453967568995
                        },
                        {
                            "lat": 55.336396650586344,
                            "lon": 61.393910829688664
                        },
                        {
                            "lat": 55.33281460701762,
                            "lon": 61.39436759948271
                        },
                        {
                            "lat": 55.33376713143085,
                            "lon": 61.39608527747996
                        },
                        {
                            "lat": 55.33734935358459,
                            "lon": 61.39562847526198
                        },
                        {
                            "lat": 55.42887600297107,
                            "lon": 61.442790871328484
                        },
                        {
                            "lat": 55.432461771695216,
                            "lon": 61.44233136550671
                        },
                        {
                            "lat": 55.433421673306206,
                            "lon": 61.44404812378071
                        },
                        {
                            "lat": 55.42983572576233,
                            "lon": 61.444507662239666
                        },
                        {
                            "lat": 55.42624965704144,
                            "lon": 61.444967108287905
                        },
                        {
                            "lat": 55.42529011309097,
                            "lon": 61.443250284745105
                        },
                        {
                            "lat": 55.42887600297107,
                            "lon": 61.442790871328484
                        },
                        {
                            "lat": 55.409243370071295,
                            "lon": 61.421389640035656
                        },
                        {
                            "lat": 55.408285698730765,
                            "lon": 61.41967265537986
                        },
                        {
                            "lat": 55.41186920762961,
                            "lon": 61.419213790779686
                        },
                        {
                            "lat": 55.412827057572386,
                            "lon": 61.42093074285731
                        },
                        {
                            "lat": 55.409243370071295,
                            "lon": 61.421389640035656
                        }
                    ],
                    [
                        {
                            "lat": 55.36024117757464,
                            "lon": 61.46444127173533
                        },
                        {
                            "lat": 55.36383074189632,
                            "lon": 61.46398348001961
                        },
                        {
                            "lat": 55.367420185286676,
                            "lon": 61.46352559573509
                        },
                        {
                            "lat": 55.371009507727074,
                            "lon": 61.46306761888801
                        },
                        {
                            "lat": 55.37459870919886,
                            "lon": 61.46260954948462
                        },
                        {
                            "lat": 55.37818778968336,
                            "lon": 61.46215138753109
                        },
                        {
                            "lat": 55.37914549509273,
                            "lon": 61.46386863585156
                        },
                        {
                            "lat": 55.38010332152911,
                            "lon": 61.46558587501963
                        },
                        {
                            "lat": 55.37651388240945,
                            "lon": 61.46604410210806
                        },
                        {
                            "lat": 55.37747165054834,
                            "lon": 61.46776136469474
                        },
                        {
                            "lat": 55.38106126901356,
                            "lon": 61.467303105033714
                        },
                        {
                            "lat": 55.38465076640963,
                            "lon": 61.46684475281191
                        },
                        {
                            "lat": 55.38824014271787,
                            "lon": 61.46638630803554
                        },
                        {
                            "lat": 55.38919856993661,
                            "lon": 61.46810346372523
                        },
                        {
                            "lat": 55.39278800443933,
                            "lon": 61.46764489380769
                        },
                        {
                            "lat": 55.39637731779584,
                            "lon": 61.46718623134242
                        },
                        {
                            "lat": 55.397336224760174,
                            "lon": 61.468903312669994
                        },
                        {
                            "lat": 55.40092559622923,
                            "lon": 61.46844452505571
                        },
                        {
                            "lat": 55.4045148464937,
                            "lon": 61.46798564490053
                        },
                        {
                            "lat": 55.403555580995764,
                            "lon": 61.46626862879361
                        },
                        {
                            "lat": 55.39996650998754,
                            "lon": 61.46672747633566
                        },
                        {
                            "lat": 55.39900754492931,
                            "lon": 61.4650104184414
                        },
                        {
                            "lat": 55.39541853199634,
                            "lon": 61.46546914084407
                        },
                        {
                            "lat": 55.39182939791965,
                            "lon": 61.465927770710884
                        },
                        {
                            "lat": 55.39087091252507,
                            "lon": 61.46421063844825
                        },
                        {
                            "lat": 55.38728183660557,
                            "lon": 61.46466914318349
                        },
                        {
                            "lat": 55.386323651578635,
                            "lon": 61.46295196917062
                        },
                        {
                            "lat": 55.382734633858504,
                            "lon": 61.463410348782745
                        },
                        {
                            "lat": 55.38177674916196,
                            "lon": 61.46169313303361
                        },
                        {
                            "lat": 55.38081898548995,
                            "lon": 61.45997590813021
                        },
                        {
                            "lat": 55.37986134282142,
                            "lon": 61.45825867407409
                        },
                        {
                            "lat": 55.37627274186129,
                            "lon": 61.458716863439065
                        },
                        {
                            "lat": 55.37531539940643,
                            "lon": 61.45699958767065
                        },
                        {
                            "lat": 55.37172685673483,
                            "lon": 61.457457651947465
                        },
                        {
                            "lat": 55.36813819313915,
                            "lon": 61.457915623690994
                        },
                        {
                            "lat": 55.36454940863803,
                            "lon": 61.45837350289506
                        },
                        {
                            "lat": 55.36096050325014,
                            "lon": 61.4588312895534
                        },
                        {
                            "lat": 55.35737147699413,
                            "lon": 61.45928898365987
                        },
                        {
                            "lat": 55.35832792297676,
                            "lon": 61.46100642214955
                        },
                        {
                            "lat": 55.35928448982992,
                            "lon": 61.462723851508564
                        },
                        {
                            "lat": 55.36024117757464,
                            "lon": 61.46444127173533
                        }
                    ],
                    [
                        {
                            "lat": 55.408103975534985,
                            "lon": 61.467526672210674
                        },
                        {
                            "lat": 55.4045148464937,
                            "lon": 61.46798564490053
                        },
                        {
                            "lat": 55.40547423321484,
                            "lon": 61.46970265182826
                        },
                        {
                            "lat": 55.40906354150989,
                            "lon": 61.469243646516226
                        },
                        {
                            "lat": 55.408103975534985,
                            "lon": 61.467526672210674
                        }
                    ],
                    [
                        {
                            "lat": 55.41002322874772,
                            "lon": 61.47096061163761
                        },
                        {
                            "lat": 55.41098303726962,
                            "lon": 61.47267756757327
                        },
                        {
                            "lat": 55.414572582822885,
                            "lon": 61.47221840446134
                        },
                        {
                            "lat": 55.41361259503108,
                            "lon": 61.47050148116033
                        },
                        {
                            "lat": 55.41265272854199,
                            "lon": 61.46878454867014
                        },
                        {
                            "lat": 55.40906354150989,
                            "lon": 61.469243646516226
                        },
                        {
                            "lat": 55.41002322874772,
                            "lon": 61.47096061163761
                        }
                    ],
                    [
                        {
                            "lat": 55.419122295435315,
                            "lon": 61.473476030276984
                        },
                        {
                            "lat": 55.41816200705233,
                            "lon": 61.471759148810385
                        },
                        {
                            "lat": 55.414572582822885,
                            "lon": 61.47221840446134
                        },
                        {
                            "lat": 55.41553269193848,
                            "lon": 61.47393531857164
                        },
                        {
                            "lat": 55.41649292239904,
                            "lon": 61.47565222348967
                        },
                        {
                            "lat": 55.42008270518187,
                            "lon": 61.47519290254791
                        },
                        {
                            "lat": 55.419122295435315,
                            "lon": 61.473476030276984
                        }
                    ],
                    [
                        {
                            "lat": 55.44853792530703,
                            "lon": 61.46418848220695
                        },
                        {
                            "lat": 55.452125777218846,
                            "lon": 61.46372836689754
                        },
                        {
                            "lat": 55.451164061846534,
                            "lon": 61.46201178449564
                        },
                        {
                            "lat": 55.45475161329319,
                            "lon": 61.461551609422045
                        },
                        {
                            "lat": 55.458339043256366,
                            "lon": 61.461091341901046
                        },
                        {
                            "lat": 55.45930111656567,
                            "lon": 61.462807858907176
                        },
                        {
                            "lat": 55.46288860396355,
                            "lon": 61.462347466238704
                        },
                        {
                            "lat": 55.46192635171747,
                            "lon": 61.46063098193887
                        },
                        {
                            "lat": 55.46096422099199,
                            "lon": 61.45891448840505
                        },
                        {
                            "lat": 55.45737709144911,
                            "lon": 61.45937481566438
                        },
                        {
                            "lat": 55.45378984042531,
                            "lon": 61.45983505048813
                        },
                        {
                            "lat": 55.45282818901978,
                            "lon": 61.45811848232864
                        },
                        {
                            "lat": 55.44924099547557,
                            "lon": 61.458578592022334
                        },
                        {
                            "lat": 55.44565368050871,
                            "lon": 61.4590386092736
                        },
                        {
                            "lat": 55.44661497400921,
                            "lon": 61.460755242803955
                        },
                        {
                            "lat": 55.443027358654014,
                            "lon": 61.46121520028356
                        },
                        {
                            "lat": 55.44398859457697,
                            "lon": 61.46293185727565
                        },
                        {
                            "lat": 55.4404006787912,
                            "lon": 61.46339175496958
                        },
                        {
                            "lat": 55.441361857099444,
                            "lon": 61.465108435423076
                        },
                        {
                            "lat": 55.44494995192771,
                            "lon": 61.46464850505087
                        },
                        {
                            "lat": 55.445911430727385,
                            "lon": 61.466365143607675
                        },
                        {
                            "lat": 55.44949958314662,
                            "lon": 61.46590508807644
                        },
                        {
                            "lat": 55.44853792530703,
                            "lon": 61.46418848220695
                        }
                    ],
                    [
                        {
                            "lat": 55.46341303839997,
                            "lon": 61.47700022441769
                        },
                        {
                            "lat": 55.467002079652765,
                            "lon": 61.476539662467054
                        },
                        {
                            "lat": 55.470590999212995,
                            "lon": 61.47607900802482
                        },
                        {
                            "lat": 55.46962777404027,
                            "lon": 61.47436259765357
                        },
                        {
                            "lat": 55.46866467055748,
                            "lon": 61.47264617803573
                        },
                        {
                            "lat": 55.47225311029698,
                            "lon": 61.47218549658338
                        },
                        {
                            "lat": 55.471289949456,
                            "lon": 61.470469100453
                        },
                        {
                            "lat": 55.46770168874345,
                            "lon": 61.470929749172875
                        },
                        {
                            "lat": 55.464113306383325,
                            "lon": 61.47139030542421
                        },
                        {
                            "lat": 55.463150625246435,
                            "lon": 61.4696738345945
                        },
                        {
                            "lat": 55.466738828577,
                            "lon": 61.4692133110666
                        },
                        {
                            "lat": 55.46577609003695,
                            "lon": 61.467496863718466
                        },
                        {
                            "lat": 55.4621880657174,
                            "lon": 61.467957354526376
                        },
                        {
                            "lat": 55.46122562777503,
                            "lon": 61.46624086522139
                        },
                        {
                            "lat": 55.457637660882405,
                            "lon": 61.466701230854724
                        },
                        {
                            "lat": 55.45404957244284,
                            "lon": 61.46716150402386
                        },
                        {
                            "lat": 55.45501165233682,
                            "lon": 61.468878058745176
                        },
                        {
                            "lat": 55.45597385378036,
                            "lon": 61.47059460423651
                        },
                        {
                            "lat": 55.459562300308036,
                            "lon": 61.47013426565322
                        },
                        {
                            "lat": 55.46052480239418,
                            "lon": 61.47185076920077
                        },
                        {
                            "lat": 55.46148742609076,
                            "lon": 61.473567263511754
                        },
                        {
                            "lat": 55.46245017141897,
                            "lon": 61.47528374858458
                        },
                        {
                            "lat": 55.46341303839997,
                            "lon": 61.47700022441769
                        }
                    ],
                    [
                        {
                            "lat": 55.463766583447466,
                            "lon": 61.511916040604
                        },
                        {
                            "lat": 55.46735964145577,
                            "lon": 61.511455319250686
                        },
                        {
                            "lat": 55.47095257746119,
                            "lon": 61.51099450526792
                        },
                        {
                            "lat": 55.46998793355363,
                            "lon": 61.50927810694219
                        },
                        {
                            "lat": 55.466395177093084,
                            "lon": 61.509738888153976
                        },
                        {
                            "lat": 55.46543083471201,
                            "lon": 61.5080224478013
                        },
                        {
                            "lat": 55.461838135817366,
                            "lon": 61.50848310362731
                        },
                        {
                            "lat": 55.462802298650935,
                            "lon": 61.510199576741876
                        },
                        {
                            "lat": 55.463766583447466,
                            "lon": 61.511916040604
                        }
                    ],
                    [
                        {
                            "lat": 55.47813808338888,
                            "lon": 61.51007259943902
                        },
                        {
                            "lat": 55.477173080455344,
                            "lon": 61.50835626667214
                        },
                        {
                            "lat": 55.47358056801392,
                            "lon": 61.50881723311282
                        },
                        {
                            "lat": 55.474545391445105,
                            "lon": 61.51053359866193
                        },
                        {
                            "lat": 55.47813808338888,
                            "lon": 61.51007259943902
                        }
                    ],
                    [
                        {
                            "lat": 55.48269595776586,
                            "lon": 61.51132779831319
                        },
                        {
                            "lat": 55.481730653273885,
                            "lon": 61.50961150760541
                        },
                        {
                            "lat": 55.47813808338888,
                            "lon": 61.51007259943902
                        },
                        {
                            "lat": 55.479103208381176,
                            "lon": 61.511788922938074
                        },
                        {
                            "lat": 55.48269595776586,
                            "lon": 61.51132779831319
                        }
                    ],
                    [
                        {
                            "lat": 55.49286634492227,
                            "lon": 61.419853776028766
                        },
                        {
                            "lat": 55.48928430836626,
                            "lon": 61.42031477454015
                        },
                        {
                            "lat": 55.49024645365561,
                            "lon": 61.422031018421286
                        },
                        {
                            "lat": 55.493828668482806,
                            "lon": 61.421569987193834
                        },
                        {
                            "lat": 55.49286634492227,
                            "lon": 61.419853776028766
                        }
                    ],
                    [
                        {
                            "lat": 55.49243846835886,
                            "lon": 61.43278942274561
                        },
                        {
                            "lat": 55.4934012851888,
                            "lon": 61.43450563458756
                        },
                        {
                            "lat": 55.49698486993844,
                            "lon": 61.43404446653873
                        },
                        {
                            "lat": 55.49794798669741,
                            "lon": 61.43576063638659
                        },
                        {
                            "lat": 55.501531628358265,
                            "lon": 61.43529934330764
                        },
                        {
                            "lat": 55.50056833318048,
                            "lon": 61.43358320620321
                        },
                        {
                            "lat": 55.50415167489652,
                            "lon": 61.43312185358721
                        },
                        {
                            "lat": 55.50511514847196,
                            "lon": 61.43483795794261
                        },
                        {
                            "lat": 55.50607874361302,
                            "lon": 61.436554053029916
                        },
                        {
                            "lat": 55.5096623205561,
                            "lon": 61.43609254262706
                        },
                        {
                            "lat": 55.51062621569735,
                            "lon": 61.43780859568323
                        },
                        {
                            "lat": 55.50704246034082,
                            "lon": 61.43827013884752
                        },
                        {
                            "lat": 55.5034585833764,
                            "lon": 61.43873158972087
                        },
                        {
                            "lat": 55.504422243259036,
                            "lon": 61.440447699026535
                        },
                        {
                            "lat": 55.505386024752234,
                            "lon": 61.4421637990628
                        },
                        {
                            "lat": 55.50634992787713,
                            "lon": 61.443879889828125
                        },
                        {
                            "lat": 55.50276539384533,
                            "lon": 61.44434134667565
                        },
                        {
                            "lat": 55.499180738179135,
                            "lon": 61.44480271120297
                        },
                        {
                            "lat": 55.49559596089705,
                            "lon": 61.44526398340382
                        },
                        {
                            "lat": 55.49655945002255,
                            "lon": 61.44698016317183
                        },
                        {
                            "lat": 55.497523060766625,
                            "lon": 61.448696333675976
                        },
                        {
                            "lat": 55.49848679315041,
                            "lon": 61.45041249491472
                        },
                        {
                            "lat": 55.50207210620361,
                            "lon": 61.44995112444507
                        },
                        {
                            "lat": 55.50303613887562,
                            "lon": 61.45166724365369
                        },
                        {
                            "lat": 55.5066215088557,
                            "lon": 61.451205748072134
                        },
                        {
                            "lat": 55.507585841852894,
                            "lon": 61.45292182523661
                        },
                        {
                            "lat": 55.50855029659025,
                            "lon": 61.45463789312407
                        },
                        {
                            "lat": 55.5121359020768,
                            "lon": 61.45417623964105
                        },
                        {
                            "lat": 55.513100657216,
                            "lon": 61.45589226546531
                        },
                        {
                            "lat": 55.51668631954201,
                            "lon": 61.455430486844605
                        },
                        {
                            "lat": 55.51765137511997,
                            "lon": 61.457146470591645
                        },
                        {
                            "lat": 55.52123709424308,
                            "lon": 61.45668456682493
                        },
                        {
                            "lat": 55.52482269150702,
                            "lon": 61.456222570712086
                        },
                        {
                            "lat": 55.525788226174626,
                            "lon": 61.45793847955963
                        },
                        {
                            "lat": 55.52937388015359,
                            "lon": 61.45747635829307
                        },
                        {
                            "lat": 55.53033971533118,
                            "lon": 61.4591922250263
                        },
                        {
                            "lat": 55.533925425982815,
                            "lon": 61.458729978597674
                        },
                        {
                            "lat": 55.53489156170732,
                            "lon": 61.46044580320256
                        },
                        {
                            "lat": 55.53847732898915,
                            "lon": 61.45998343160356
                        },
                        {
                            "lat": 55.54206297427433,
                            "lon": 61.459520967666705
                        },
                        {
                            "lat": 55.54109648139661,
                            "lon": 61.45780520873113
                        },
                        {
                            "lat": 55.54013011051283,
                            "lon": 61.45608944048313
                        },
                        {
                            "lat": 55.54371527671177,
                            "lon": 61.45562694989726
                        },
                        {
                            "lat": 55.5427488492928,
                            "lon": 61.45391120517457
                        },
                        {
                            "lat": 55.541782543843766,
                            "lon": 61.45219545113921
                        },
                        {
                            "lat": 55.53819773464211,
                            "lon": 61.45265787605622
                        },
                        {
                            "lat": 55.53723172961276,
                            "lon": 61.4509420798805
                        },
                        {
                            "lat": 55.54081636034347,
                            "lon": 61.450479687792715
                        },
                        {
                            "lat": 55.54440086914663,
                            "lon": 61.4500172034075
                        },
                        {
                            "lat": 55.54343462914254,
                            "lon": 61.44830146358292
                        },
                        {
                            "lat": 55.53985029877072,
                            "lon": 61.44876391513675
                        },
                        {
                            "lat": 55.53626584649254,
                            "lon": 61.44922627439869
                        },
                        {
                            "lat": 55.532681272326435,
                            "lon": 61.44968854136254
                        },
                        {
                            "lat": 55.531715689549365,
                            "lon": 61.44797269375943
                        },
                        {
                            "lat": 55.528131171990275,
                            "lon": 61.44843483560765
                        },
                        {
                            "lat": 55.52716588951945,
                            "lon": 61.44671894589729
                        },
                        {
                            "lat": 55.52620072885729,
                            "lon": 61.44500304689247
                        },
                        {
                            "lat": 55.52523568998257,
                            "lon": 61.44328713859476
                        },
                        {
                            "lat": 55.52881967222304,
                            "lon": 61.442825095159606
                        },
                        {
                            "lat": 55.527854576711974,
                            "lon": 61.44110921036811
                        },
                        {
                            "lat": 55.524270772874175,
                            "lon": 61.441571221005766
                        },
                        {
                            "lat": 55.523305977510894,
                            "lon": 61.43985529412707
                        },
                        {
                            "lat": 55.522341303871606,
                            "lon": 61.438139357960246
                        },
                        {
                            "lat": 55.52137675193512,
                            "lon": 61.43642341250686
                        },
                        {
                            "lat": 55.524960020675636,
                            "lon": 61.43596150024108
                        },
                        {
                            "lat": 55.52399541209195,
                            "lon": 61.43424557828653
                        },
                        {
                            "lat": 55.52303092518722,
                            "lon": 61.43252964704511
                        },
                        {
                            "lat": 55.52206655994033,
                            "lon": 61.43081370651846
                        },
                        {
                            "lat": 55.525649172112765,
                            "lon": 61.43035180035067
                        },
                        {
                            "lat": 55.526613715630546,
                            "lon": 61.43206770809484
                        },
                        {
                            "lat": 55.5301963843976,
                            "lon": 61.431605676902215
                        },
                        {
                            "lat": 55.52923166263007,
                            "lon": 61.4298898019461
                        },
                        {
                            "lat": 55.52826706253607,
                            "lon": 61.42817391769949
                        },
                        {
                            "lat": 55.53184925316964,
                            "lon": 61.42771185985459
                        },
                        {
                            "lat": 55.53543132213241,
                            "lon": 61.42724970979101
                        },
                        {
                            "lat": 55.53901326940595,
                            "lon": 61.42678746751496
                        },
                        {
                            "lat": 55.54259509497186,
                            "lon": 61.42632513303271
                        },
                        {
                            "lat": 55.546176798811736,
                            "lon": 61.42586270635051
                        },
                        {
                            "lat": 55.547142289745324,
                            "lon": 61.42757842659041
                        },
                        {
                            "lat": 55.548107902444464,
                            "lon": 61.429294137522646
                        },
                        {
                            "lat": 55.54907363693031,
                            "lon": 61.43100983914563
                        },
                        {
                            "lat": 55.54549139865834,
                            "lon": 61.43147236427557
                        },
                        {
                            "lat": 55.5419090385969,
                            "lon": 61.43193479718889
                        },
                        {
                            "lat": 55.54287453850782,
                            "lon": 61.433650555140986
                        },
                        {
                            "lat": 55.54645707677125,
                            "lon": 61.433188089410486
                        },
                        {
                            "lat": 55.55003949322404,
                            "lon": 61.43272553145774
                        },
                        {
                            "lat": 55.551005471346805,
                            "lon": 61.43444121445745
                        },
                        {
                            "lat": 55.55458794414862,
                            "lon": 61.43397853145694
                        },
                        {
                            "lat": 55.55817029508183,
                            "lon": 61.43351575624112
                        },
                        {
                            "lat": 55.56175252412805,
                            "lon": 61.43305288881625
                        },
                        {
                            "lat": 55.560786011534596,
                            "lon": 61.43133730432863
                        },
                        {
                            "lat": 55.56436794056088,
                            "lon": 61.430874377549465
                        },
                        {
                            "lat": 55.567949747684544,
                            "lon": 61.43041135857936
                        },
                        {
                            "lat": 55.57153143288723,
                            "lon": 61.429948247424555
                        },
                        {
                            "lat": 55.570564507953,
                            "lon": 61.42823275216606
                        },
                        {
                            "lat": 55.57414589318368,
                            "lon": 61.427769581694534
                        },
                        {
                            "lat": 55.573178912153345,
                            "lon": 61.42605410996291
                        },
                        {
                            "lat": 55.5722120530384,
                            "lon": 61.424338628897985
                        },
                        {
                            "lat": 55.57579296036466,
                            "lon": 61.423875431984186
                        },
                        {
                            "lat": 55.57675999745437,
                            "lon": 61.425590880188665
                        },
                        {
                            "lat": 55.580340960821644,
                            "lon": 61.42512755825964
                        },
                        {
                            "lat": 55.58392180223682,
                            "lon": 61.42466414418207
                        },
                        {
                            "lat": 55.58295440926111,
                            "lon": 61.42294876171513
                        },
                        {
                            "lat": 55.58198713823468,
                            "lon": 61.42123336990618
                        },
                        {
                            "lat": 55.58556750187528,
                            "lon": 61.42076992943714
                        },
                        {
                            "lat": 55.58914774356944,
                            "lon": 61.42030639684321
                        },
                        {
                            "lat": 55.59011537036049,
                            "lon": 61.42202172289925
                        },
                        {
                            "lat": 55.593695667940416,
                            "lon": 61.421558065301866
                        },
                        {
                            "lat": 55.59727584351605,
                            "lon": 61.42109431558654
                        },
                        {
                            "lat": 55.60085589706906,
                            "lon": 61.42063047375961
                        },
                        {
                            "lat": 55.604435828581124,
                            "lon": 61.420166539827214
                        },
                        {
                            "lat": 55.6034674905149,
                            "lon": 61.418451345343726
                        },
                        {
                            "lat": 55.60704712220188,
                            "lon": 61.41798735221924
                        },
                        {
                            "lat": 55.60607872842621,
                            "lon": 61.416272181278295
                        },
                        {
                            "lat": 55.60511045668578,
                            "lon": 61.4145570009745
                        },
                        {
                            "lat": 55.60868961088194,
                            "lon": 61.41409298157751
                        },
                        {
                            "lat": 55.60772128346579,
                            "lon": 61.41237782481464
                        },
                        {
                            "lat": 55.606753078060834,
                            "lon": 61.41066265868863
                        },
                        {
                            "lat": 55.605784994645894,
                            "lon": 61.40894748320107
                        },
                        {
                            "lat": 55.6048170331998,
                            "lon": 61.407232298353556
                        },
                        {
                            "lat": 55.60123858965278,
                            "lon": 61.40769618616212
                        },
                        {
                            "lat": 55.60027092777092,
                            "lon": 61.4059809590677
                        },
                        {
                            "lat": 55.5993033877973,
                            "lon": 61.404265722619954
                        },
                        {
                            "lat": 55.598335969710796,
                            "lon": 61.402550476820466
                        },
                        {
                            "lat": 55.597368673490244,
                            "lon": 61.400835221670775
                        },
                        {
                            "lat": 55.59379081845483,
                            "lon": 61.401298885914045
                        },
                        {
                            "lat": 55.592823821643606,
                            "lon": 61.399583588546555
                        },
                        {
                            "lat": 55.58924602236729,
                            "lon": 61.40004712788805
                        },
                        {
                            "lat": 55.590212841592866,
                            "lon": 61.40176245811924
                        },
                        {
                            "lat": 55.58663474292266,
                            "lon": 61.40222593828008
                        },
                        {
                            "lat": 55.58305652246254,
                            "lon": 61.40268932639037
                        },
                        {
                            "lat": 55.58402310822508,
                            "lon": 61.40440471299743
                        },
                        {
                            "lat": 55.58044458832599,
                            "lon": 61.40486804190135
                        },
                        {
                            "lat": 55.576865946652454,
                            "lon": 61.405331278736604
                        },
                        {
                            "lat": 55.57589971624569,
                            "lon": 61.40361582643419
                        },
                        {
                            "lat": 55.57947818023079,
                            "lon": 61.40315262244382
                        },
                        {
                            "lat": 55.57851189388888,
                            "lon": 61.40143719365484
                        },
                        {
                            "lat": 55.57754572927917,
                            "lon": 61.399721755536085
                        },
                        {
                            "lat": 55.573967620616045,
                            "lon": 61.400184893846905
                        },
                        {
                            "lat": 55.57038939026015,
                            "lon": 61.40064794009948
                        },
                        {
                            "lat": 55.57135519954509,
                            "lon": 61.402363443888824
                        },
                        {
                            "lat": 55.57232113052558,
                            "lon": 61.404078938355184
                        },
                        {
                            "lat": 55.568742423088814,
                            "lon": 61.404541958200625
                        },
                        {
                            "lat": 55.565163593953685,
                            "lon": 61.4050048859642
                        },
                        {
                            "lat": 55.56419801841903,
                            "lon": 61.40328932584265
                        },
                        {
                            "lat": 55.563232564543284,
                            "lon": 61.40157375640497
                        },
                        {
                            "lat": 55.566811038229766,
                            "lon": 61.40111089428759
                        },
                        {
                            "lat": 55.565845528295014,
                            "lon": 61.39939534835334
                        },
                        {
                            "lat": 55.56488013999527,
                            "lon": 61.39767979310269
                        },
                        {
                            "lat": 55.56391487330947,
                            "lon": 61.395964228537245
                        },
                        {
                            "lat": 55.56033693265849,
                            "lon": 61.39642699221102
                        },
                        {
                            "lat": 55.55675887043297,
                            "lon": 61.39688966382447
                        },
                        {
                            "lat": 55.55318068665128,
                            "lon": 61.3973522433714
                        },
                        {
                            "lat": 55.549602381331724,
                            "lon": 61.39781473084552
                        },
                        {
                            "lat": 55.54602395449262,
                            "lon": 61.39827712624059
                        },
                        {
                            "lat": 55.54698833266661,
                            "lon": 61.39999285480603
                        },
                        {
                            "lat": 55.54795283236287,
                            "lon": 61.40170857407378
                        },
                        {
                            "lat": 55.54437392847297,
                            "lon": 61.40217094295368
                        },
                        {
                            "lat": 55.54079490305807,
                            "lon": 61.40263321973095
                        },
                        {
                            "lat": 55.5372157561365,
                            "lon": 61.403095404399345
                        },
                        {
                            "lat": 55.5362517898554,
                            "lon": 61.40137958678802
                        },
                        {
                            "lat": 55.53528794504153,
                            "lon": 61.39966375988931
                        },
                        {
                            "lat": 55.531709032307724,
                            "lon": 61.40012578690586
                        },
                        {
                            "lat": 55.530745486750504,
                            "lon": 61.39840991795811
                        },
                        {
                            "lat": 55.52978206259999,
                            "lon": 61.39669403972951
                        },
                        {
                            "lat": 55.52620338408321,
                            "lon": 61.397155909123995
                        },
                        {
                            "lat": 55.5226245841991,
                            "lon": 61.397617686413106
                        },
                        {
                            "lat": 55.521661637045035,
                            "lon": 61.39590173340923
                        },
                        {
                            "lat": 55.52524025911321,
                            "lon": 61.39543998886534
                        },
                        {
                            "lat": 55.528818759835175,
                            "lon": 61.394978152221704
                        },
                        {
                            "lat": 55.53239713919252,
                            "lon": 61.394516223484516
                        },
                        {
                            "lat": 55.53597539716692,
                            "lon": 61.39405420266003
                        },
                        {
                            "lat": 55.539553533740026,
                            "lon": 61.39359208975447
                        },
                        {
                            "lat": 55.54313154889349,
                            "lon": 61.39312988477408
                        },
                        {
                            "lat": 55.54216765659784,
                            "lon": 61.391414119033755
                        },
                        {
                            "lat": 55.538589819136625,
                            "lon": 61.3918762912445
                        },
                        {
                            "lat": 55.53501186027685,
                            "lon": 61.39233837138598
                        },
                        {
                            "lat": 55.534048444767,
                            "lon": 61.390622530829035
                        },
                        {
                            "lat": 55.53308515061633,
                            "lon": 61.3889066809907
                        },
                        {
                            "lat": 55.52950742579006,
                            "lon": 61.38936860354991
                        },
                        {
                            "lat": 55.528544430656865,
                            "lon": 61.38765271168355
                        },
                        {
                            "lat": 55.52496676221121,
                            "lon": 61.38811450942944
                        },
                        {
                            "lat": 55.52400406605881,
                            "lon": 61.38639857554903
                        },
                        {
                            "lat": 55.52042645403587,
                            "lon": 61.38686024848992
                        },
                        {
                            "lat": 55.51684872077189,
                            "lon": 61.38732182935885
                        },
                        {
                            "lat": 55.51781106149705,
                            "lon": 61.38903782870115
                        },
                        {
                            "lat": 55.51423302926524,
                            "lon": 61.389499350214464
                        },
                        {
                            "lat": 55.51065487580809,
                            "lon": 61.38996077963783
                        },
                        {
                            "lat": 55.51161698222885,
                            "lon": 61.391676835162045
                        },
                        {
                            "lat": 55.508038529759126,
                            "lon": 61.392138205204084
                        },
                        {
                            "lat": 55.50445995607975,
                            "lon": 61.39259948313812
                        },
                        {
                            "lat": 55.505421828059575,
                            "lon": 61.394315594834104
                        },
                        {
                            "lat": 55.5018429553229,
                            "lon": 61.39477681336108
                        },
                        {
                            "lat": 55.50280477063061,
                            "lon": 61.3964929485136
                        },
                        {
                            "lat": 55.50376670715328,
                            "lon": 61.39820907441398
                        },
                        {
                            "lat": 55.504728764911974,
                            "lon": 61.39992519106066
                        },
                        {
                            "lat": 55.50569094392772,
                            "lon": 61.40164129845199
                        },
                        {
                            "lat": 55.50927052831234,
                            "lon": 61.40117994906216
                        },
                        {
                            "lat": 55.51023300656412,
                            "lon": 61.40289601447239
                        },
                        {
                            "lat": 55.50665324422155,
                            "lon": 61.40335739658647
                        },
                        {
                            "lat": 55.50761566581455,
                            "lon": 61.40507348546251
                        },
                        {
                            "lat": 55.51119560613347,
                            "lon": 61.404612070620736
                        },
                        {
                            "lat": 55.512158327041405,
                            "lon": 61.40632811750565
                        },
                        {
                            "lat": 55.508578208727755,
                            "lon": 61.40678956507851
                        },
                        {
                            "lat": 55.50499796907281,
                            "lon": 61.407250920486376
                        },
                        {
                            "lat": 55.5059604552931,
                            "lon": 61.40896702356974
                        },
                        {
                            "lat": 55.50954087298224,
                            "lon": 61.40850563543292
                        },
                        {
                            "lat": 55.51312116930904,
                            "lon": 61.40804415512552
                        },
                        {
                            "lat": 55.51670134425508,
                            "lon": 61.4075825826538
                        },
                        {
                            "lat": 55.51766448591389,
                            "lon": 61.4092985782636
                        },
                        {
                            "lat": 55.5140841329574,
                            "lon": 61.40976018347884
                        },
                        {
                            "lat": 55.515047218007595,
                            "lon": 61.41147620256396
                        },
                        {
                            "lat": 55.51146656559935,
                            "lon": 61.41193774835066
                        },
                        {
                            "lat": 55.51242959400412,
                            "lon": 61.413653790910836
                        },
                        {
                            "lat": 55.50884864210196,
                            "lon": 61.41411527725506
                        },
                        {
                            "lat": 55.50526756879262,
                            "lon": 61.41457667140574
                        },
                        {
                            "lat": 55.50430489658776,
                            "lon": 61.4128605633727
                        },
                        {
                            "lat": 55.50072388002122,
                            "lon": 61.413321832595564
                        },
                        {
                            "lat": 55.501686374094504,
                            "lon": 61.415037973356675
                        },
                        {
                            "lat": 55.502648989538145,
                            "lon": 61.41675410486016
                        },
                        {
                            "lat": 55.50623036238624,
                            "lon": 61.41629277017775
                        },
                        {
                            "lat": 55.50719327738972,
                            "lon": 61.418008859687106
                        },
                        {
                            "lat": 55.5036117263732,
                            "lon": 61.41847022710444
                        },
                        {
                            "lat": 55.50457458462079,
                            "lon": 61.42018634008798
                        },
                        {
                            "lat": 55.505537564301974,
                            "lon": 61.42190244380915
                        },
                        {
                            "lat": 55.50195553543866,
                            "lon": 61.42236378447833
                        },
                        {
                            "lat": 55.498373385139075,
                            "lon": 61.422825032912975
                        },
                        {
                            "lat": 55.49741076193168,
                            "lon": 61.42110886372499
                        },
                        {
                            "lat": 55.493828668482806,
                            "lon": 61.421569987193834
                        },
                        {
                            "lat": 55.494791113421634,
                            "lon": 61.42328618910684
                        },
                        {
                            "lat": 55.49575367975987,
                            "lon": 61.425002381766205
                        },
                        {
                            "lat": 55.496716367518616,
                            "lon": 61.42671856517036
                        },
                        {
                            "lat": 55.493133617767185,
                            "lon": 61.427179694566426
                        },
                        {
                            "lat": 55.489550746592606,
                            "lon": 61.427640731698105
                        },
                        {
                            "lat": 55.49051319908188,
                            "lon": 61.429356971299335
                        },
                        {
                            "lat": 55.49409624862254,
                            "lon": 61.42889590144351
                        },
                        {
                            "lat": 55.495059000922105,
                            "lon": 61.4306120990656
                        },
                        {
                            "lat": 55.49602187468704,
                            "lon": 61.43232828743122
                        },
                        {
                            "lat": 55.49243846835886,
                            "lon": 61.43278942274561
                        },
                        {
                            "lat": 55.508698547020046,
                            "lon": 61.43437648029776
                        },
                        {
                            "lat": 55.51228182398405,
                            "lon": 61.433914910379364
                        },
                        {
                            "lat": 55.513245775893985,
                            "lon": 61.43563093994504
                        },
                        {
                            "lat": 55.5096623205561,
                            "lon": 61.43609254262706
                        },
                        {
                            "lat": 55.508698547020046,
                            "lon": 61.43437648029776
                        },
                        {
                            "lat": 55.5107747069753,
                            "lon": 61.41754740005886
                        },
                        {
                            "lat": 55.509811613824496,
                            "lon": 61.41583134328999
                        },
                        {
                            "lat": 55.51339274383448,
                            "lon": 61.41536982420313
                        },
                        {
                            "lat": 55.51435601511151,
                            "lon": 61.417085848225916
                        },
                        {
                            "lat": 55.51531940785631,
                            "lon": 61.41880186297773
                        },
                        {
                            "lat": 55.511737921575445,
                            "lon": 61.41926344756012
                        },
                        {
                            "lat": 55.50815631382413,
                            "lon": 61.41972493993229
                        },
                        {
                            "lat": 55.50719327738972,
                            "lon": 61.418008859687106
                        },
                        {
                            "lat": 55.5107747069753,
                            "lon": 61.41754740005886
                        },
                        {
                            "lat": 55.50126198335265,
                            "lon": 61.427973484928174
                        },
                        {
                            "lat": 55.50029899582846,
                            "lon": 61.42625734351614
                        },
                        {
                            "lat": 55.49933612976429,
                            "lon": 61.424541192843876
                        },
                        {
                            "lat": 55.502918458329766,
                            "lon": 61.42407991167519
                        },
                        {
                            "lat": 55.50388150267826,
                            "lon": 61.425796029609984
                        },
                        {
                            "lat": 55.507463888049585,
                            "lon": 61.42533462345815
                        },
                        {
                            "lat": 55.50842723215819,
                            "lon": 61.42705069938287
                        },
                        {
                            "lat": 55.50484466850521,
                            "lon": 61.42751213828116
                        },
                        {
                            "lat": 55.505807955831735,
                            "lon": 61.429228237687056
                        },
                        {
                            "lat": 55.50677136467899,
                            "lon": 61.43094432782618
                        },
                        {
                            "lat": 55.503188322865554,
                            "lon": 61.431405739965264
                        },
                        {
                            "lat": 55.50222509235797,
                            "lon": 61.42968961707841
                        },
                        {
                            "lat": 55.50126198335265,
                            "lon": 61.427973484928174
                        }
                    ],
                    [
                        {
                            "lat": 55.46455122903542,
                            "lon": 61.45845406871641
                        },
                        {
                            "lat": 55.46358904093087,
                            "lon": 61.45673759865519
                        },
                        {
                            "lat": 55.46000221176598,
                            "lon": 61.457197985638814
                        },
                        {
                            "lat": 55.46096422099199,
                            "lon": 61.45891448840505
                        },
                        {
                            "lat": 55.46455122903542,
                            "lon": 61.45845406871641
                        }
                    ],
                    [
                        {
                            "lat": 55.47102594575053,
                            "lon": 61.46314281321444
                        },
                        {
                            "lat": 55.47006321411552,
                            "lon": 61.46142640358642
                        },
                        {
                            "lat": 55.46647596981968,
                            "lon": 61.4618869811297
                        },
                        {
                            "lat": 55.46288860396355,
                            "lon": 61.462347466238704
                        },
                        {
                            "lat": 55.463850977751385,
                            "lon": 61.464063941302946
                        },
                        {
                            "lat": 55.46481347310212,
                            "lon": 61.46578040713007
                        },
                        {
                            "lat": 55.46577609003695,
                            "lon": 61.467496863718466
                        },
                        {
                            "lat": 55.469363992751255,
                            "lon": 61.467036280453236
                        },
                        {
                            "lat": 55.472951773841785,
                            "lon": 61.466575604736924
                        },
                        {
                            "lat": 55.47198879898558,
                            "lon": 61.464859213598466
                        },
                        {
                            "lat": 55.47102594575053,
                            "lon": 61.46314281321444
                        }
                    ],
                    [
                        {
                            "lat": 55.47846610586413,
                            "lon": 61.469547525632606
                        },
                        {
                            "lat": 55.477502708736,
                            "lon": 61.46783118573025
                        },
                        {
                            "lat": 55.47391487034033,
                            "lon": 61.468291986628245
                        },
                        {
                            "lat": 55.47032691028115,
                            "lon": 61.46875269507576
                        },
                        {
                            "lat": 55.471289949456,
                            "lon": 61.470469100453
                        },
                        {
                            "lat": 55.47487808850241,
                            "lon": 61.47000835927083
                        },
                        {
                            "lat": 55.47584142834918,
                            "lon": 61.47172472266313
                        },
                        {
                            "lat": 55.47680488990181,
                            "lon": 61.47344107680356
                        },
                        {
                            "lat": 55.48039326525126,
                            "lon": 61.47298017767456
                        },
                        {
                            "lat": 55.47942962469547,
                            "lon": 61.47126385628125
                        },
                        {
                            "lat": 55.483017699317344,
                            "lon": 61.47080289744395
                        },
                        {
                            "lat": 55.482054001522584,
                            "lon": 61.46908659954459
                        },
                        {
                            "lat": 55.47846610586413,
                            "lon": 61.469547525632606
                        }
                    ],
                    [
                        {
                            "lat": 55.48135702755267,
                            "lon": 61.474696489811
                        },
                        {
                            "lat": 55.48232091162089,
                            "lon": 61.476412792689
                        },
                        {
                            "lat": 55.48590952324428,
                            "lon": 61.475951735583294
                        },
                        {
                            "lat": 55.484945460157,
                            "lon": 61.474235465464886
                        },
                        {
                            "lat": 55.48853377097596,
                            "lon": 61.473774348658424
                        },
                        {
                            "lat": 55.487569650694745,
                            "lon": 61.47205810203985
                        },
                        {
                            "lat": 55.483981518855096,
                            "lon": 61.47251918608456
                        },
                        {
                            "lat": 55.48039326525126,
                            "lon": 61.47298017767456
                        },
                        {
                            "lat": 55.48135702755267,
                            "lon": 61.474696489811
                        }
                    ],
                    [
                        {
                            "lat": 55.48783801485976,
                            "lon": 61.47938424802814
                        },
                        {
                            "lat": 55.486873708138134,
                            "lon": 61.47766799643824
                        },
                        {
                            "lat": 55.483284917477135,
                            "lon": 61.478129086306986
                        },
                        {
                            "lat": 55.48232091162089,
                            "lon": 61.476412792689
                        },
                        {
                            "lat": 55.47873217820948,
                            "lon": 61.476873757322515
                        },
                        {
                            "lat": 55.47514332302861,
                            "lon": 61.47733462947757
                        },
                        {
                            "lat": 55.476106970746024,
                            "lon": 61.479050988604726
                        },
                        {
                            "lat": 55.47969600500691,
                            "lon": 61.47859008369785
                        },
                        {
                            "lat": 55.480659953595,
                            "lon": 61.48030640081506
                        },
                        {
                            "lat": 55.48424904514259,
                            "lon": 61.479845370663305
                        },
                        {
                            "lat": 55.48783801485976,
                            "lon": 61.47938424802814
                        }
                    ],
                    [
                        {
                            "lat": 55.493356199864984,
                            "lon": 61.48235544273975
                        },
                        {
                            "lat": 55.49239147035208,
                            "lon": 61.48063924246362
                        },
                        {
                            "lat": 55.49598037538502,
                            "lon": 61.48017790209934
                        },
                        {
                            "lat": 55.496945283948754,
                            "lon": 61.48189406959097
                        },
                        {
                            "lat": 55.50053424610398,
                            "lon": 61.48143260396633
                        },
                        {
                            "lat": 55.501499455644435,
                            "lon": 61.48314872938775
                        },
                        {
                            "lat": 55.50508847487952,
                            "lon": 61.48268713849441
                        },
                        {
                            "lat": 55.50412308631213,
                            "lon": 61.48097104587216
                        },
                        {
                            "lat": 55.50771180455464,
                            "lon": 61.48050939531468
                        },
                        {
                            "lat": 55.50674635896572,
                            "lon": 61.47879332621021
                        },
                        {
                            "lat": 55.50315781971037,
                            "lon": 61.479254943966374
                        },
                        {
                            "lat": 55.50219267505304,
                            "lon": 61.477538832778656
                        },
                        {
                            "lat": 55.50122765231887,
                            "lon": 61.475822712310524
                        },
                        {
                            "lat": 55.49763934908027,
                            "lon": 61.476284172027874
                        },
                        {
                            "lat": 55.494050923958255,
                            "lon": 61.47674553929243
                        },
                        {
                            "lat": 55.4930863810528,
                            "lon": 61.47502934398032
                        },
                        {
                            "lat": 55.489498013061116,
                            "lon": 61.47549058601166
                        },
                        {
                            "lat": 55.49046237697137,
                            "lon": 61.47720681409801
                        },
                        {
                            "lat": 55.491426862727955,
                            "lon": 61.47892303291585
                        },
                        {
                            "lat": 55.48783801485976,
                            "lon": 61.47938424802814
                        },
                        {
                            "lat": 55.48880244343039,
                            "lon": 61.4811004903514
                        },
                        {
                            "lat": 55.48976699387121,
                            "lon": 61.48281672340646
                        },
                        {
                            "lat": 55.493356199864984,
                            "lon": 61.48235544273975
                        }
                    ],
                    [
                        {
                            "lat": 55.522342377605064,
                            "lon": 61.48598767860391
                        },
                        {
                            "lat": 55.51875330949157,
                            "lon": 61.48644973782057
                        },
                        {
                            "lat": 55.515164119271866,
                            "lon": 61.48691170457033
                        },
                        {
                            "lat": 55.511574806964425,
                            "lon": 61.48737357884692
                        },
                        {
                            "lat": 55.512540862686635,
                            "lon": 61.48908960150068
                        },
                        {
                            "lat": 55.51613035405268,
                            "lon": 61.48862769439995
                        },
                        {
                            "lat": 55.517096710942475,
                            "lon": 61.490343674931246
                        },
                        {
                            "lat": 55.51806318996246,
                            "lon": 61.49205964616261
                        },
                        {
                            "lat": 55.51902979113394,
                            "lon": 61.493775608092456
                        },
                        {
                            "lat": 55.52261969761438,
                            "lon": 61.493313510003176
                        },
                        {
                            "lat": 55.526209481903514,
                            "lon": 61.49285131942462
                        },
                        {
                            "lat": 55.52524252256734,
                            "lon": 61.49113542318046
                        },
                        {
                            "lat": 55.52883200559619,
                            "lon": 61.49067317297013
                        },
                        {
                            "lat": 55.52979914398282,
                            "lon": 61.492389036363036
                        },
                        {
                            "lat": 55.53076640459784,
                            "lon": 61.49410489044253
                        },
                        {
                            "lat": 55.53173378746254,
                            "lon": 61.49582073520698
                        },
                        {
                            "lat": 55.5327012925982,
                            "lon": 61.49753657065486
                        },
                        {
                            "lat": 55.536291369647806,
                            "lon": 61.49707409652538
                        },
                        {
                            "lat": 55.53988132438662,
                            "lon": 61.49661152990861
                        },
                        {
                            "lat": 55.5389134611027,
                            "lon": 61.49489576020069
                        },
                        {
                            "lat": 55.54250311447001,
                            "lon": 61.494433133981325
                        },
                        {
                            "lat": 55.5434711567961,
                            "lon": 61.496148870810885
                        },
                        {
                            "lat": 55.54443932147003,
                            "lon": 61.49786459831191
                        },
                        {
                            "lat": 55.54540760851314,
                            "lon": 61.499580316482806
                        },
                        {
                            "lat": 55.5463760179467,
                            "lon": 61.50129602532196
                        },
                        {
                            "lat": 55.54996626518211,
                            "lon": 61.500833175076984
                        },
                        {
                            "lat": 55.550934976122456,
                            "lon": 61.50254884168506
                        },
                        {
                            "lat": 55.5545252800012,
                            "lon": 61.50208586605152
                        },
                        {
                            "lat": 55.55549429248538,
                            "lon": 61.50380149041447
                        },
                        {
                            "lat": 55.55908465296483,
                            "lon": 61.50333838938402
                        },
                        {
                            "lat": 55.5600539670299,
                            "lon": 61.50505397148771
                        },
                        {
                            "lat": 55.56102340362636,
                            "lon": 61.50676954424294
                        },
                        {
                            "lat": 55.56199296277549,
                            "lon": 61.50848510764817
                        },
                        {
                            "lat": 55.56296264449867,
                            "lon": 61.51020066170171
                        },
                        {
                            "lat": 55.56393244881726,
                            "lon": 61.51191620640203
                        },
                        {
                            "lat": 55.56034119270556,
                            "lon": 61.51237947204623
                        },
                        {
                            "lat": 55.556749813995616,
                            "lon": 61.51284264517239
                        },
                        {
                            "lat": 55.55578036802542,
                            "lon": 61.51112703461833
                        },
                        {
                            "lat": 55.55218904594203,
                            "lon": 61.51159008230163
                        },
                        {
                            "lat": 55.548597601318875,
                            "lon": 61.512053037459864
                        },
                        {
                            "lat": 55.5476286363037,
                            "lon": 61.510337351735075
                        },
                        {
                            "lat": 55.544037248389664,
                            "lon": 61.510800181458166
                        },
                        {
                            "lat": 55.54044573799429,
                            "lon": 61.51126291864921
                        },
                        {
                            "lat": 55.53685410513613,
                            "lon": 61.51172556330195
                        },
                        {
                            "lat": 55.53326234983375,
                            "lon": 61.51218811541008
                        },
                        {
                            "lat": 55.529670472105735,
                            "lon": 61.51265057496734
                        },
                        {
                            "lat": 55.52870252586283,
                            "lon": 61.51093471545884
                        },
                        {
                            "lat": 55.52773470200571,
                            "lon": 61.50921884663137
                        },
                        {
                            "lat": 55.524143060488946,
                            "lon": 61.509681147890184
                        },
                        {
                            "lat": 55.52317553827756,
                            "lon": 61.50796523687986
                        },
                        {
                            "lat": 55.519583953717586,
                            "lon": 61.50842741272657
                        },
                        {
                            "lat": 55.51599224685163,
                            "lon": 61.50888949602035
                        },
                        {
                            "lat": 55.51695941043644,
                            "lon": 61.510605472744814
                        },
                        {
                            "lat": 55.52055129662634,
                            "lon": 61.51014335659675
                        },
                        {
                            "lat": 55.521518761862374,
                            "lon": 61.511859291156476
                        },
                        {
                            "lat": 55.525110705046195,
                            "lon": 61.51139704958657
                        },
                        {
                            "lat": 55.52607847197063,
                            "lon": 61.51311294196744
                        },
                        {
                            "lat": 55.527046361283546,
                            "lon": 61.51482882503119
                        },
                        {
                            "lat": 55.52801437300631,
                            "lon": 61.51654469877624
                        },
                        {
                            "lat": 55.53160673183408,
                            "lon": 61.51608226602105
                        },
                        {
                            "lat": 55.53519896821209,
                            "lon": 61.5156197406975
                        },
                        {
                            "lat": 55.5387910821218,
                            "lon": 61.51515712281184
                        },
                        {
                            "lat": 55.5423830735446,
                            "lon": 61.51469441237042
                        },
                        {
                            "lat": 55.545974942461946,
                            "lon": 61.51423160937944
                        },
                        {
                            "lat": 55.549566688855265,
                            "lon": 61.51376871384529
                        },
                        {
                            "lat": 55.55053589893424,
                            "lon": 61.515484380889674
                        },
                        {
                            "lat": 55.55412770203111,
                            "lon": 61.51502135990223
                        },
                        {
                            "lat": 55.55509721393869,
                            "lon": 61.51673698468421
                        },
                        {
                            "lat": 55.5586890736964,
                            "lon": 61.516273838235136
                        },
                        {
                            "lat": 55.5622808108317,
                            "lon": 61.515810599250486
                        },
                        {
                            "lat": 55.56587242532608,
                            "lon": 61.51534726773659
                        },
                        {
                            "lat": 55.56946391716096,
                            "lon": 61.51488384369969
                        },
                        {
                            "lat": 55.57305528631782,
                            "lon": 61.51442032714614
                        },
                        {
                            "lat": 55.57664653277813,
                            "lon": 61.5139567180822
                        },
                        {
                            "lat": 55.577617242540256,
                            "lon": 61.51567213585957
                        },
                        {
                            "lat": 55.58120854541914,
                            "lon": 61.51520840132913
                        },
                        {
                            "lat": 55.584799725543036,
                            "lon": 61.514744574295314
                        },
                        {
                            "lat": 55.58382865753501,
                            "lon": 61.51302922244833
                        },
                        {
                            "lat": 55.58285771227908,
                            "lon": 61.51131386122605
                        },
                        {
                            "lat": 55.581886889753896,
                            "lon": 61.509598490630054
                        },
                        {
                            "lat": 55.57829624691124,
                            "lon": 61.51006221877027
                        },
                        {
                            "lat": 55.57926689036119,
                            "lon": 61.51177762232734
                        },
                        {
                            "lat": 55.575675945731085,
                            "lon": 61.51224129093643
                        },
                        {
                            "lat": 55.57208487840727,
                            "lon": 61.51270486704701
                        },
                        {
                            "lat": 55.56849368840826,
                            "lon": 61.513168350652805
                        },
                        {
                            "lat": 55.56752358231222,
                            "lon": 61.511452848246016
                        },
                        {
                            "lat": 55.56655359885146,
                            "lon": 61.50973733648095
                        },
                        {
                            "lat": 55.570144430590325,
                            "lon": 61.50927391876032
                        },
                        {
                            "lat": 55.56917439064123,
                            "lon": 61.50755843057596
                        },
                        {
                            "lat": 55.57276492066671,
                            "lon": 61.507094953304716
                        },
                        {
                            "lat": 55.571794824266284,
                            "lon": 61.50537948870132
                        },
                        {
                            "lat": 55.57082485047415,
                            "lon": 61.503664014737545
                        },
                        {
                            "lat": 55.56985499926892,
                            "lon": 61.50194853141496
                        },
                        {
                            "lat": 55.56888527062926,
                            "lon": 61.50023303873513
                        },
                        {
                            "lat": 55.56529545674233,
                            "lon": 61.50069638426899
                        },
                        {
                            "lat": 55.56432602963527,
                            "lon": 61.498980849307856
                        },
                        {
                            "lat": 55.56073627223229,
                            "lon": 61.499444069455954
                        },
                        {
                            "lat": 55.55976714662081,
                            "lon": 61.497728492227544
                        },
                        {
                            "lat": 55.55617744574444,
                            "lon": 61.498191586998196
                        },
                        {
                            "lat": 55.555208621591504,
                            "lon": 61.49647596751665
                        },
                        {
                            "lat": 55.55423991986329,
                            "lon": 61.49476033869465
                        },
                        {
                            "lat": 55.55065045455295,
                            "lon": 61.49522327519763
                        },
                        {
                            "lat": 55.55161897728438,
                            "lon": 61.49693893691823
                        },
                        {
                            "lat": 55.54802921057106,
                            "lon": 61.49740181385208
                        },
                        {
                            "lat": 55.54706086685773,
                            "lon": 61.49568611923847
                        },
                        {
                            "lat": 55.54609264551077,
                            "lon": 61.49397041529285
                        },
                        {
                            "lat": 55.5451245465089,
                            "lon": 61.49225470201685
                        },
                        {
                            "lat": 55.54415656983081,
                            "lon": 61.49053897941205
                        },
                        {
                            "lat": 55.54056739677624,
                            "lon": 61.49100163234295
                        },
                        {
                            "lat": 55.53959972136596,
                            "lon": 61.489285867537305
                        },
                        {
                            "lat": 55.53863216821835,
                            "lon": 61.48757009340951
                        },
                        {
                            "lat": 55.535043230834496,
                            "lon": 61.488032588149885
                        },
                        {
                            "lat": 55.53145417122775,
                            "lon": 61.48849499043725
                        },
                        {
                            "lat": 55.52786498941665,
                            "lon": 61.48895730026534
                        },
                        {
                            "lat": 55.52427568541971,
                            "lon": 61.489419517627894
                        },
                        {
                            "lat": 55.52330897043937,
                            "lon": 61.48770360276854
                        },
                        {
                            "lat": 55.526898095422936,
                            "lon": 61.48724141825033
                        },
                        {
                            "lat": 55.53048709824194,
                            "lon": 61.486779141272166
                        },
                        {
                            "lat": 55.52952014743921,
                            "lon": 61.485063282794975
                        },
                        {
                            "lat": 55.52855331879831,
                            "lon": 61.48334741500726
                        },
                        {
                            "lat": 55.52496467390794,
                            "lon": 61.48380962629582
                        },
                        {
                            "lat": 55.52399814634413,
                            "lon": 61.482093716359586
                        },
                        {
                            "lat": 55.520409558289515,
                            "lon": 61.48255580236539
                        },
                        {
                            "lat": 55.519443331765736,
                            "lon": 61.48083985029461
                        },
                        {
                            "lat": 55.51585480058937,
                            "lon": 61.48130181102601
                        },
                        {
                            "lat": 55.51226614737052,
                            "lon": 61.48176367930728
                        },
                        {
                            "lat": 55.50867737212773,
                            "lon": 61.48222545513217
                        },
                        {
                            "lat": 55.50964306170622,
                            "lon": 61.48394150566107
                        },
                        {
                            "lat": 55.50605398543377,
                            "lon": 61.484403221831535
                        },
                        {
                            "lat": 55.50701961799614,
                            "lon": 61.48611929588194
                        },
                        {
                            "lat": 55.510608873311384,
                            "lon": 61.48565754689987
                        },
                        {
                            "lat": 55.51419800657873,
                            "lon": 61.485195705443964
                        },
                        {
                            "lat": 55.51778701777961,
                            "lon": 61.484733771520446
                        },
                        {
                            "lat": 55.52137590689553,
                            "lon": 61.48427174513568
                        },
                        {
                            "lat": 55.522342377605064,
                            "lon": 61.48598767860391
                        },
                        {
                            "lat": 55.5415351944705,
                            "lon": 61.49271738782481
                        },
                        {
                            "lat": 55.537945720126835,
                            "lon": 61.49317998116927
                        },
                        {
                            "lat": 55.53435612349645,
                            "lon": 61.49364248204393
                        },
                        {
                            "lat": 55.533388683833735,
                            "lon": 61.491926660824724
                        },
                        {
                            "lat": 55.53697810143771,
                            "lon": 61.491464192815926
                        },
                        {
                            "lat": 55.54056739677624,
                            "lon": 61.49100163234295
                        },
                        {
                            "lat": 55.5415351944705,
                            "lon": 61.49271738782481
                        }
                    ],
                    [
                        {
                            "lat": 55.50246478715321,
                            "lon": 61.484864845527476
                        },
                        {
                            "lat": 55.501499455644435,
                            "lon": 61.48314872938775
                        },
                        {
                            "lat": 55.497910314441036,
                            "lon": 61.483610227805926
                        },
                        {
                            "lat": 55.498875466883085,
                            "lon": 61.48532637674263
                        },
                        {
                            "lat": 55.50246478715321,
                            "lon": 61.484864845527476
                        }
                    ],
                    [
                        {
                            "lat": 55.507985372587854,
                            "lon": 61.48783536064407
                        },
                        {
                            "lat": 55.50701961799614,
                            "lon": 61.48611929588194
                        },
                        {
                            "lat": 55.503430240651554,
                            "lon": 61.486580952383925
                        },
                        {
                            "lat": 55.499840741296154,
                            "lon": 61.48704251639954
                        },
                        {
                            "lat": 55.50080613770145,
                            "lon": 61.48875864677503
                        },
                        {
                            "lat": 55.504395816160674,
                            "lon": 61.488297049955534
                        },
                        {
                            "lat": 55.507985372587854,
                            "lon": 61.48783536064407
                        }
                    ],
                    [
                        {
                            "lat": 55.50784178365337,
                            "lon": 61.50809733320937
                        },
                        {
                            "lat": 55.50424988935026,
                            "lon": 61.508559105972765
                        },
                        {
                            "lat": 55.50065787283685,
                            "lon": 61.509020786163774
                        },
                        {
                            "lat": 55.49706573413168,
                            "lon": 61.5094823737761
                        },
                        {
                            "lat": 55.493473473253395,
                            "lon": 61.50994386880347
                        },
                        {
                            "lat": 55.49443943827129,
                            "lon": 61.51166005182055
                        },
                        {
                            "lat": 55.49084687578402,
                            "lon": 61.51212148706824
                        },
                        {
                            "lat": 55.49181278350466,
                            "lon": 61.513837693615564
                        },
                        {
                            "lat": 55.495405525464996,
                            "lon": 61.51337622555283
                        },
                        {
                            "lat": 55.49899814522822,
                            "lon": 61.51291466488768
                        },
                        {
                            "lat": 55.498031878582744,
                            "lon": 61.511198523975985
                        },
                        {
                            "lat": 55.50162419669977,
                            "lon": 61.51073690354088
                        },
                        {
                            "lat": 55.50521639260377,
                            "lon": 61.510275190521504
                        },
                        {
                            "lat": 55.508808466276136,
                            "lon": 61.50981338492409
                        },
                        {
                            "lat": 55.51240041769828,
                            "lon": 61.50935148675496
                        },
                        {
                            "lat": 55.51599224685163,
                            "lon": 61.50888949602035
                        },
                        {
                            "lat": 55.51502520555422,
                            "lon": 61.507173509990466
                        },
                        {
                            "lat": 55.51143355572755,
                            "lon": 61.50763546787985
                        },
                        {
                            "lat": 55.50784178365337,
                            "lon": 61.50809733320937
                        }
                    ],
                    [
                        {
                            "lat": 55.49084687578402,
                            "lon": 61.51212148706824
                        },
                        {
                            "lat": 55.4898810902206,
                            "lon": 61.51040527123963
                        },
                        {
                            "lat": 55.486288585051874,
                            "lon": 61.510866581078275
                        },
                        {
                            "lat": 55.48725419113956,
                            "lon": 61.512582829712876
                        },
                        {
                            "lat": 55.49084687578402,
                            "lon": 61.51212148706824
                        }
                    ],
                    [
                        {
                            "lat": 55.55070956503113,
                            "lon": 61.543071398810014
                        },
                        {
                            "lat": 55.54711445677674,
                            "lon": 61.54353445076147
                        },
                        {
                            "lat": 55.54808473240642,
                            "lon": 61.545250132906965
                        },
                        {
                            "lat": 55.54448932168845,
                            "lon": 61.5457131251384
                        },
                        {
                            "lat": 55.54545954044091,
                            "lon": 61.54742883087453
                        },
                        {
                            "lat": 55.541863827216545,
                            "lon": 61.547891763371844
                        },
                        {
                            "lat": 55.53826799119478,
                            "lon": 61.54835460318728
                        },
                        {
                            "lat": 55.53467203239428,
                            "lon": 61.54881735031454
                        },
                        {
                            "lat": 55.53107595083369,
                            "lon": 61.54928000474731
                        },
                        {
                            "lat": 55.527479746531604,
                            "lon": 61.54974256647928
                        },
                        {
                            "lat": 55.528449189233584,
                            "lon": 61.55145842750704
                        },
                        {
                            "lat": 55.5320455733518,
                            "lon": 61.55099583285817
                        },
                        {
                            "lat": 55.533015318632785,
                            "lon": 61.55271165163229
                        },
                        {
                            "lat": 55.53398518669808,
                            "lon": 61.55442746106813
                        },
                        {
                            "lat": 55.53758180769878,
                            "lon": 61.55396470785743
                        },
                        {
                            "lat": 55.541178305875086,
                            "lon": 61.55350186193546
                        },
                        {
                            "lat": 55.54477468120838,
                            "lon": 61.55303892330843
                        },
                        {
                            "lat": 55.54837093368002,
                            "lon": 61.55257589198267
                        },
                        {
                            "lat": 55.55196706327134,
                            "lon": 61.5521127679645
                        },
                        {
                            "lat": 55.55556306996375,
                            "lon": 61.5516495512602
                        },
                        {
                            "lat": 55.5545921232279,
                            "lon": 61.54993393948516
                        },
                        {
                            "lat": 55.558187827299385,
                            "lon": 61.549470663064156
                        },
                        {
                            "lat": 55.56178340845614,
                            "lon": 61.54900729397525
                        },
                        {
                            "lat": 55.56275471457735,
                            "lon": 61.55072283981845
                        },
                        {
                            "lat": 55.5663503524613,
                            "lon": 61.550259345093636
                        },
                        {
                            "lat": 55.569945867371864,
                            "lon": 61.54979575770791
                        },
                        {
                            "lat": 55.57354125929045,
                            "lon": 61.549332077667586
                        },
                        {
                            "lat": 55.57713652819847,
                            "lon": 61.54886830497899
                        },
                        {
                            "lat": 55.57616450356389,
                            "lon": 61.547152891066716
                        },
                        {
                            "lat": 55.57519260193703,
                            "lon": 61.54543746777455
                        },
                        {
                            "lat": 55.57878738868502,
                            "lon": 61.544973668434004
                        },
                        {
                            "lat": 55.57781543050686,
                            "lon": 61.54325826875333
                        },
                        {
                            "lat": 55.581409914733925,
                            "lon": 61.54279440978377
                        },
                        {
                            "lat": 55.58500427595897,
                            "lon": 61.54233045820164
                        },
                        {
                            "lat": 55.58403208179063,
                            "lon": 61.54061511513031
                        },
                        {
                            "lat": 55.58762614054051,
                            "lon": 61.54015110394512
                        },
                        {
                            "lat": 55.59122007627269,
                            "lon": 61.53968700016558
                        },
                        {
                            "lat": 55.59481388896866,
                            "lon": 61.53922280379804
                        },
                        {
                            "lat": 55.59840757860982,
                            "lon": 61.538758514848794
                        },
                        {
                            "lat": 55.59938049046802,
                            "lon": 61.540473725873916
                        },
                        {
                            "lat": 55.60297423640467,
                            "lon": 61.54000931132372
                        },
                        {
                            "lat": 55.60394745074504,
                            "lon": 61.541724479918905
                        },
                        {
                            "lat": 55.60754125293428,
                            "lon": 61.541259939759435
                        },
                        {
                            "lat": 55.60851476979392,
                            "lon": 61.54297506591052
                        },
                        {
                            "lat": 55.6121086281928,
                            "lon": 61.54251040013342
                        },
                        {
                            "lat": 55.61308244760877,
                            "lon": 61.544225483826246
                        },
                        {
                            "lat": 55.61667636217438,
                            "lon": 61.54376069242323
                        },
                        {
                            "lat": 55.6202701535066,
                            "lon": 61.54329580844768
                        },
                        {
                            "lat": 55.62386382158694,
                            "lon": 61.54283083190595
                        },
                        {
                            "lat": 55.62745736639683,
                            "lon": 61.54236576280435
                        },
                        {
                            "lat": 55.63105078791775,
                            "lon": 61.54190060114923
                        },
                        {
                            "lat": 55.630076071916264,
                            "lon": 61.54018568277598
                        },
                        {
                            "lat": 55.63366919087705,
                            "lon": 61.539720461654305
                        },
                        {
                            "lat": 55.63269441892738,
                            "lon": 61.53800556692635
                        },
                        {
                            "lat": 55.63628723537101,
                            "lon": 61.537540286352176
                        },
                        {
                            "lat": 55.635312407510206,
                            "lon": 61.535825415269755
                        },
                        {
                            "lat": 55.63890492147963,
                            "lon": 61.53536007525709
                        },
                        {
                            "lat": 55.6379300377447,
                            "lon": 61.53364522782046
                        },
                        {
                            "lat": 55.64152224928288,
                            "lon": 61.533179828383346
                        },
                        {
                            "lat": 55.6451143375255,
                            "lon": 61.53271433644664
                        },
                        {
                            "lat": 55.64608957950435,
                            "lon": 61.534429117703645
                        },
                        {
                            "lat": 55.64706494481873,
                            "lon": 61.53614388951456
                        },
                        {
                            "lat": 55.64347249833517,
                            "lon": 61.53660944763996
                        },
                        {
                            "lat": 55.64444780785839,
                            "lon": 61.53832424310277
                        },
                        {
                            "lat": 55.64804043349013,
                            "lon": 61.53785865187782
                        },
                        {
                            "lat": 55.64901604554003,
                            "lon": 61.539573404791746
                        },
                        {
                            "lat": 55.65260872693847,
                            "lon": 61.539107687948174
                        },
                        {
                            "lat": 55.65620128491841,
                            "lon": 61.538641878595314
                        },
                        {
                            "lat": 55.659793719461376,
                            "lon": 61.53817597673954
                        },
                        {
                            "lat": 55.66338603054888,
                            "lon": 61.537709982387135
                        },
                        {
                            "lat": 55.66697821816244,
                            "lon": 61.53724389554447
                        },
                        {
                            "lat": 55.670570282283606,
                            "lon": 61.536777716217856
                        },
                        {
                            "lat": 55.67416222289388,
                            "lon": 61.536311444413634
                        },
                        {
                            "lat": 55.677754039974836,
                            "lon": 61.53584508013813
                        },
                        {
                            "lat": 55.681345733508,
                            "lon": 61.53537862339767
                        },
                        {
                            "lat": 55.68493730347492,
                            "lon": 61.534912074198594
                        },
                        {
                            "lat": 55.68852874985713,
                            "lon": 61.53444543254724
                        },
                        {
                            "lat": 55.68755116839285,
                            "lon": 61.53273104413502
                        },
                        {
                            "lat": 55.691142312263345,
                            "lon": 61.5322643432077
                        },
                        {
                            "lat": 55.690164675490514,
                            "lon": 61.53054997847444
                        },
                        {
                            "lat": 55.68918716229621,
                            "lon": 61.52883560425177
                        },
                        {
                            "lat": 55.68559637618758,
                            "lon": 61.529302238849574
                        },
                        {
                            "lat": 55.684619165403575,
                            "lon": 61.52758782197959
                        },
                        {
                            "lat": 55.68102843463119,
                            "lon": 61.52805433099419
                        },
                        {
                            "lat": 55.68005152622063,
                            "lon": 61.52633987149105
                        },
                        {
                            "lat": 55.67646085082745,
                            "lon": 61.52680625493071
                        },
                        {
                            "lat": 55.67287005197555,
                            "lon": 61.52727254593968
                        },
                        {
                            "lat": 55.671893624782484,
                            "lon": 61.525558010681635
                        },
                        {
                            "lat": 55.66830288139275,
                            "lon": 61.52602417612321
                        },
                        {
                            "lat": 55.66471201460266,
                            "lon": 61.52649024912696
                        },
                        {
                            "lat": 55.66112102443067,
                            "lon": 61.526956229686554
                        },
                        {
                            "lat": 55.660145257372754,
                            "lon": 61.52524158559574
                        },
                        {
                            "lat": 55.65655432278573,
                            "lon": 61.52570744059477
                        },
                        {
                            "lat": 55.65557885799973,
                            "lon": 61.52399275394036
                        },
                        {
                            "lat": 55.65198797904063,
                            "lon": 61.52445848338711
                        },
                        {
                            "lat": 55.65101281648961,
                            "lon": 61.52274375418325
                        },
                        {
                            "lat": 55.64742199320138,
                            "lon": 61.523209358086035
                        },
                        {
                            "lat": 55.64383104666948,
                            "lon": 61.523674869536094
                        },
                        {
                            "lat": 55.64285636527398,
                            "lon": 61.52196006471394
                        },
                        {
                            "lat": 55.639265474495794,
                            "lon": 61.522425450627615
                        },
                        {
                            "lat": 55.638291095264414,
                            "lon": 61.52071060329331
                        },
                        {
                            "lat": 55.637316839196835,
                            "lon": 61.51899574652581
                        },
                        {
                            "lat": 55.63372618317863,
                            "lon": 61.519460973846535
                        },
                        {
                            "lat": 55.63013540403654,
                            "lon": 61.519926108712355
                        },
                        {
                            "lat": 55.629161629022605,
                            "lon": 61.51821117639611
                        },
                        {
                            "lat": 55.625570905762864,
                            "lon": 61.51867618575046
                        },
                        {
                            "lat": 55.62198005943763,
                            "lon": 61.51914110264283
                        },
                        {
                            "lat": 55.61838909006535,
                            "lon": 61.51960592706691
                        },
                        {
                            "lat": 55.61479799766453,
                            "lon": 61.520070659016426
                        },
                        {
                            "lat": 55.613825061740364,
                            "lon": 61.518355585123615
                        },
                        {
                            "lat": 55.612852248804835,
                            "lon": 61.51664050182325
                        },
                        {
                            "lat": 55.616442983156986,
                            "lon": 61.51617583593032
                        },
                        {
                            "lat": 55.615470114192064,
                            "lon": 61.514460776247084
                        },
                        {
                            "lat": 55.61187955883653,
                            "lon": 61.51492540911696
                        },
                        {
                            "lat": 55.60828888053518,
                            "lon": 61.51538994952267
                        },
                        {
                            "lat": 55.60731649251221,
                            "lon": 61.513674814398
                        },
                        {
                            "lat": 55.60372587030447,
                            "lon": 61.51413922932483
                        },
                        {
                            "lat": 55.602753784189844,
                            "lon": 61.512424051795904
                        },
                        {
                            "lat": 55.599163218118534,
                            "lon": 61.51288834125216
                        },
                        {
                            "lat": 55.600135125209334,
                            "lon": 61.514603551780496
                        },
                        {
                            "lat": 55.601107155169046,
                            "lon": 61.516318752916476
                        },
                        {
                            "lat": 55.60469807930654,
                            "lon": 61.51585439745796
                        },
                        {
                            "lat": 55.605670411217396,
                            "lon": 61.51756955619367
                        },
                        {
                            "lat": 55.606642866058486,
                            "lon": 61.51928470553034
                        },
                        {
                            "lat": 55.60761544385123,
                            "lon": 61.52099984546643
                        },
                        {
                            "lat": 55.60402398247573,
                            "lon": 61.521464299954296
                        },
                        {
                            "lat": 55.600432398145706,
                            "lon": 61.52192866194236
                        },
                        {
                            "lat": 55.601404740657394,
                            "lon": 61.52364385850404
                        },
                        {
                            "lat": 55.597812854232146,
                            "lon": 61.524108160991496
                        },
                        {
                            "lat": 55.59422084486795,
                            "lon": 61.524572370960946
                        },
                        {
                            "lat": 55.59062871258335,
                            "lon": 61.525036488406045
                        },
                        {
                            "lat": 55.58703645739687,
                            "lon": 61.5255005133205
                        },
                        {
                            "lat": 55.58344407932704,
                            "lon": 61.525964445698044
                        },
                        {
                            "lat": 55.579851578392386,
                            "lon": 61.526428285532326
                        },
                        {
                            "lat": 55.57625895461146,
                            "lon": 61.52689203281707
                        },
                        {
                            "lat": 55.5726662080028,
                            "lon": 61.52735568754596
                        },
                        {
                            "lat": 55.57363723945073,
                            "lon": 61.529071138626094
                        },
                        {
                            "lat": 55.57723016538698,
                            "lon": 61.52860745092737
                        },
                        {
                            "lat": 55.58082296847408,
                            "lon": 61.52814367066718
                        },
                        {
                            "lat": 55.58179448142346,
                            "lon": 61.52985904642432
                        },
                        {
                            "lat": 55.585387340946205,
                            "lon": 61.52939514062451
                        },
                        {
                            "lat": 55.588980077561295,
                            "lon": 61.52893114227025
                        },
                        {
                            "lat": 55.59257269125022,
                            "lon": 61.528467051367876
                        },
                        {
                            "lat": 55.5961651819944,
                            "lon": 61.52800286792368
                        },
                        {
                            "lat": 55.5971375349918,
                            "lon": 61.52971810231608
                        },
                        {
                            "lat": 55.598110010973876,
                            "lon": 61.531433327313685
                        },
                        {
                            "lat": 55.59908260996206,
                            "lon": 61.5331485429149
                        },
                        {
                            "lat": 55.59548958138948,
                            "lon": 61.533612825383734
                        },
                        {
                            "lat": 55.591896429807875,
                            "lon": 61.53407701529395
                        },
                        {
                            "lat": 55.592868793175754,
                            "lon": 61.53579228752184
                        },
                        {
                            "lat": 55.589275339247614,
                            "lon": 61.536256417871115
                        },
                        {
                            "lat": 55.58568176232612,
                            "lon": 61.536720455643525
                        },
                        {
                            "lat": 55.58208806242984,
                            "lon": 61.53718440083285
                        },
                        {
                            "lat": 55.57849423957731,
                            "lon": 61.53764825343272
                        },
                        {
                            "lat": 55.57946600832992,
                            "lon": 61.539363648264015
                        },
                        {
                            "lat": 55.57587188307936,
                            "lon": 61.539827441253166
                        },
                        {
                            "lat": 55.57684359531213,
                            "lon": 61.54154285969253
                        },
                        {
                            "lat": 55.57324916762064,
                            "lon": 61.54200659305692
                        },
                        {
                            "lat": 55.57227763488826,
                            "lon": 61.54029114163466
                        },
                        {
                            "lat": 55.5686832637752,
                            "lon": 61.54075474940221
                        },
                        {
                            "lat": 55.56508876975876,
                            "lon": 61.5412182645495
                        },
                        {
                            "lat": 55.56149415285755,
                            "lon": 61.54168168707024
                        },
                        {
                            "lat": 55.5605232816211,
                            "lon": 61.53996612736987
                        },
                        {
                            "lat": 55.5569287214212,
                            "lon": 61.54042942430073
                        },
                        {
                            "lat": 55.557899413090155,
                            "lon": 61.54214501695808
                        },
                        {
                            "lat": 55.55430455047512,
                            "lon": 61.5426082542068
                        },
                        {
                            "lat": 55.55070956503113,
                            "lon": 61.543071398810014
                        }
                    ],
                    [
                        {
                            "lat": 55.55281917796531,
                            "lon": 61.45813302189165
                        },
                        {
                            "lat": 55.55185214951692,
                            "lon": 61.456417361501835
                        },
                        {
                            "lat": 55.54826704883481,
                            "lon": 61.45688006956051
                        },
                        {
                            "lat": 55.544681826121895,
                            "lon": 61.457342685305676
                        },
                        {
                            "lat": 55.54564849754439,
                            "lon": 61.45905841139825
                        },
                        {
                            "lat": 55.549233898780855,
                            "lon": 61.458595762804485
                        },
                        {
                            "lat": 55.55281917796531,
                            "lon": 61.45813302189165
                        }
                    ],
                    [
                        {
                            "lat": 55.529651584045425,
                            "lon": 61.46480202196323
                        },
                        {
                            "lat": 55.52868556165525,
                            "lon": 61.463086150313494
                        },
                        {
                            "lat": 55.52509924982493,
                            "lon": 61.463548277679855
                        },
                        {
                            "lat": 55.526065093527315,
                            "lon": 61.46526418215158
                        },
                        {
                            "lat": 55.529651584045425,
                            "lon": 61.46480202196323
                        }
                    ],
                    [
                        {
                            "lat": 55.55543712814976,
                            "lon": 61.455954561135925
                        },
                        {
                            "lat": 55.55447004328796,
                            "lon": 61.45423892427969
                        },
                        {
                            "lat": 55.550885243117804,
                            "lon": 61.45470169178929
                        },
                        {
                            "lat": 55.55185214951692,
                            "lon": 61.456417361501835
                        },
                        {
                            "lat": 55.55543712814976,
                            "lon": 61.455954561135925
                        }
                    ],
                    [
                        {
                            "lat": 55.5619245072264,
                            "lon": 61.46063842446811
                        },
                        {
                            "lat": 55.56095687760105,
                            "lon": 61.458922848467346
                        },
                        {
                            "lat": 55.559989370104304,
                            "lon": 61.45720726313381
                        },
                        {
                            "lat": 55.556404335079264,
                            "lon": 61.45767018866599
                        },
                        {
                            "lat": 55.55737166409768,
                            "lon": 61.45938580686832
                        },
                        {
                            "lat": 55.553786328484165,
                            "lon": 61.45984867295716
                        },
                        {
                            "lat": 55.55475360109474,
                            "lon": 61.461564314696744
                        },
                        {
                            "lat": 55.558339115226254,
                            "lon": 61.46110141574129
                        },
                        {
                            "lat": 55.55930668848622,
                            "lon": 61.462817015283306
                        },
                        {
                            "lat": 55.56289225900158,
                            "lon": 61.462353991134485
                        },
                        {
                            "lat": 55.5619245072264,
                            "lon": 61.46063842446811
                        }
                    ],
                    [
                        {
                            "lat": 55.567159073813855,
                            "lon": 61.45628113517489
                        },
                        {
                            "lat": 55.563574283021964,
                            "lon": 61.456744245301344
                        },
                        {
                            "lat": 55.56454196897583,
                            "lon": 61.45845979776056
                        },
                        {
                            "lat": 55.568126938203584,
                            "lon": 61.45799665475421
                        },
                        {
                            "lat": 55.567159073813855,
                            "lon": 61.45628113517489
                        }
                    ],
                    [
                        {
                            "lat": 55.58547740988718,
                            "lon": 61.50913467000953
                        },
                        {
                            "lat": 55.58450653103601,
                            "lon": 61.507419323004626
                        },
                        {
                            "lat": 55.580916189938094,
                            "lon": 61.50788311066192
                        },
                        {
                            "lat": 55.581886889753896,
                            "lon": 61.509598490630054
                        },
                        {
                            "lat": 55.58547740988718,
                            "lon": 61.50913467000953
                        }
                    ],
                    [
                        {
                            "lat": 55.59003898790412,
                            "lon": 61.510386061572056
                        },
                        {
                            "lat": 55.589067807292594,
                            "lon": 61.50867075691498
                        },
                        {
                            "lat": 55.58547740988718,
                            "lon": 61.50913467000953
                        },
                        {
                            "lat": 55.58644841146622,
                            "lon": 61.51085000763886
                        },
                        {
                            "lat": 55.59003898790412,
                            "lon": 61.510386061572056
                        }
                    ],
                    [
                        {
                            "lat": 55.593629441574244,
                            "lon": 61.50992202303193
                        },
                        {
                            "lat": 55.59003898790412,
                            "lon": 61.510386061572056
                        },
                        {
                            "lat": 55.59101029128341,
                            "lon": 61.512101356848504
                        },
                        {
                            "lat": 55.59460092398315,
                            "lon": 61.511637285327076
                        },
                        {
                            "lat": 55.593629441574244,
                            "lon": 61.50992202303193
                        }
                    ],
                    [
                        {
                            "lat": 55.599163218118534,
                            "lon": 61.51288834125216
                        },
                        {
                            "lat": 55.59819143387527,
                            "lon": 61.51117312133306
                        },
                        {
                            "lat": 55.59460092398315,
                            "lon": 61.511637285327076
                        },
                        {
                            "lat": 55.595572529199686,
                            "lon": 61.51335253823653
                        },
                        {
                            "lat": 55.599163218118534,
                            "lon": 61.51288834125216
                        }
                    ],
                    [
                        {
                            "lat": 55.54670944260901,
                            "lon": 61.39266758772509
                        },
                        {
                            "lat": 55.54313154889349,
                            "lon": 61.39312988477408
                        },
                        {
                            "lat": 55.54409556262714,
                            "lon": 61.394845641223036
                        },
                        {
                            "lat": 55.54767363403218,
                            "lon": 61.39438331139545
                        },
                        {
                            "lat": 55.54670944260901,
                            "lon": 61.39266758772509
                        }
                    ],
                    [
                        {
                            "lat": 55.59321830113345,
                            "lon": 61.45475206984819
                        },
                        {
                            "lat": 55.59224918828735,
                            "lon": 61.453036780584355
                        },
                        {
                            "lat": 55.58866525289176,
                            "lon": 61.45350053659325
                        },
                        {
                            "lat": 55.58963418745069,
                            "lon": 61.455215858775986
                        },
                        {
                            "lat": 55.59321830113345,
                            "lon": 61.45475206984819
                        }
                    ],
                    [
                        {
                            "lat": 55.58604995149199,
                            "lon": 61.455679555448135
                        },
                        {
                            "lat": 55.5850811952415,
                            "lon": 61.453964200352026
                        },
                        {
                            "lat": 55.58149701535493,
                            "lon": 61.45442777185445
                        },
                        {
                            "lat": 55.58246559327574,
                            "lon": 61.45614315985833
                        },
                        {
                            "lat": 55.5834342934357,
                            "lon": 61.457858538508766
                        },
                        {
                            "lat": 55.587018830000034,
                            "lon": 61.45739490118735
                        },
                        {
                            "lat": 55.58604995149199,
                            "lon": 61.455679555448135
                        }
                    ],
                    [
                        {
                            "lat": 55.60717587355663,
                            "lon": 61.46583051664766
                        },
                        {
                            "lat": 55.61076086837996,
                            "lon": 61.46536622034465
                        },
                        {
                            "lat": 55.60979036390668,
                            "lon": 61.46365109552332
                        },
                        {
                            "lat": 55.60620554743547,
                            "lon": 61.464115358866586
                        },
                        {
                            "lat": 55.60523534377309,
                            "lon": 61.462400191705214
                        },
                        {
                            "lat": 55.60881998191053,
                            "lon": 61.461935961318275
                        },
                        {
                            "lat": 55.6124044975921,
                            "lon": 61.46147163867293
                        },
                        {
                            "lat": 55.61143405975775,
                            "lon": 61.459756528044224
                        },
                        {
                            "lat": 55.607849722370226,
                            "lon": 61.46022081773113
                        },
                        {
                            "lat": 55.60687958526451,
                            "lon": 61.45850566476351
                        },
                        {
                            "lat": 55.60329530373939,
                            "lon": 61.458969829248176
                        },
                        {
                            "lat": 55.59971089981935,
                            "lon": 61.459433901479336
                        },
                        {
                            "lat": 55.600680680309864,
                            "lon": 61.46114912034021
                        },
                        {
                            "lat": 55.601650583198165,
                            "lon": 61.4628643298275
                        },
                        {
                            "lat": 55.5980657002042,
                            "lon": 61.4633283756788
                        },
                        {
                            "lat": 55.594480694809555,
                            "lon": 61.463792329252904
                        },
                        {
                            "lat": 55.595450363343026,
                            "lon": 61.46550759524974
                        },
                        {
                            "lat": 55.59642015428,
                            "lon": 61.46722285187665
                        },
                        {
                            "lat": 55.60000551648823,
                            "lon": 61.466758832419934
                        },
                        {
                            "lat": 55.60359075625324,
                            "lon": 61.46629472067483
                        },
                        {
                            "lat": 55.60717587355663,
                            "lon": 61.46583051664766
                        }
                    ],
                    [
                        {
                            "lat": 55.61135634864279,
                            "lon": 61.48001590880057
                        },
                        {
                            "lat": 55.612327599312096,
                            "lon": 61.48173101507273
                        },
                        {
                            "lat": 55.61591432280473,
                            "lon": 61.48126651430258
                        },
                        {
                            "lat": 55.61494289359631,
                            "lon": 61.47955144101569
                        },
                        {
                            "lat": 55.61852931589931,
                            "lon": 61.47908688091042
                        },
                        {
                            "lat": 55.622115615533374,
                            "lon": 61.47862222849109
                        },
                        {
                            "lat": 55.621143951998015,
                            "lon": 61.47690721178984
                        },
                        {
                            "lat": 55.624729950488,
                            "lon": 61.476442500061204
                        },
                        {
                            "lat": 55.623758231180354,
                            "lon": 61.47472750695507
                        },
                        {
                            "lat": 55.62017241112868,
                            "lon": 61.475192185688634
                        },
                        {
                            "lat": 55.616586468432324,
                            "lon": 61.47565677212554
                        },
                        {
                            "lat": 55.61300040310968,
                            "lon": 61.47612126625951
                        },
                        {
                            "lat": 55.61202934178883,
                            "lon": 61.474406164793464
                        },
                        {
                            "lat": 55.61105840305452,
                            "lon": 61.472691053937574
                        },
                        {
                            "lat": 55.610087586885456,
                            "lon": 61.47097593369349
                        },
                        {
                            "lat": 55.60650193435136,
                            "lon": 61.47144023660392
                        },
                        {
                            "lat": 55.607472572073384,
                            "lon": 61.47315538981602
                        },
                        {
                            "lat": 55.6038866185454,
                            "lon": 61.47361963339008
                        },
                        {
                            "lat": 55.600300542488995,
                            "lon": 61.47408378465346
                        },
                        {
                            "lat": 55.60127094576297,
                            "lon": 61.475798994406944
                        },
                        {
                            "lat": 55.60485720032746,
                            "lon": 61.47533481018327
                        },
                        {
                            "lat": 55.605827904659236,
                            "lon": 61.477049977593495
                        },
                        {
                            "lat": 55.60679873156204,
                            "lon": 61.47876513561923
                        },
                        {
                            "lat": 55.6103852206056,
                            "lon": 61.47830079313722
                        },
                        {
                            "lat": 55.61135634864279,
                            "lon": 61.48001590880057
                        }
                    ],
                    [
                        {
                            "lat": 55.60776968105719,
                            "lon": 61.4804802842588
                        },
                        {
                            "lat": 55.60679873156204,
                            "lon": 61.47876513561923
                        },
                        {
                            "lat": 55.60321211992611,
                            "lon": 61.47922938577385
                        },
                        {
                            "lat": 55.604182890857906,
                            "lon": 61.480944567384086
                        },
                        {
                            "lat": 55.60776968105719,
                            "lon": 61.4804802842588
                        }
                    ],
                    [
                        {
                            "lat": 55.579870807997075,
                            "lon": 61.39021820063969
                        },
                        {
                            "lat": 55.583447663804115,
                            "lon": 61.38975495041379
                        },
                        {
                            "lat": 55.58441375262375,
                            "lon": 61.39147033666196
                        },
                        {
                            "lat": 55.587990664246234,
                            "lon": 61.39100696158744
                        },
                        {
                            "lat": 55.588957052241604,
                            "lon": 61.392722305656875
                        },
                        {
                            "lat": 55.59253401963734,
                            "lon": 61.39225880572548
                        },
                        {
                            "lat": 55.59350070684502,
                            "lon": 61.39397410760217
                        },
                        {
                            "lat": 55.59446751581303,
                            "lon": 61.39568940013664
                        },
                        {
                            "lat": 55.5954344465625,
                            "lon": 61.397404683327274
                        },
                        {
                            "lat": 55.5990118246846,
                            "lon": 61.396940992798335
                        },
                        {
                            "lat": 55.602589080985865,
                            "lon": 61.39647721025493
                        },
                        {
                            "lat": 55.601621795243894,
                            "lon": 61.39476199280567
                        },
                        {
                            "lat": 55.60065463131995,
                            "lon": 61.39304676600574
                        },
                        {
                            "lat": 55.59707772997179,
                            "lon": 61.39351048280565
                        },
                        {
                            "lat": 55.59611086529385,
                            "lon": 61.39179521379004
                        },
                        {
                            "lat": 55.59968758919286,
                            "lon": 61.39133152985673
                        },
                        {
                            "lat": 55.59872066884156,
                            "lon": 61.38961628436021
                        },
                        {
                            "lat": 55.60229709355505,
                            "lon": 61.389152541304064
                        },
                        {
                            "lat": 55.6013301175669,
                            "lon": 61.38743731932692
                        },
                        {
                            "lat": 55.60490624313708,
                            "lon": 61.386973517161806
                        },
                        {
                            "lat": 55.60393921154863,
                            "lon": 61.38525831870434
                        },
                        {
                            "lat": 55.6075150380177,
                            "lon": 61.38479445744418
                        },
                        {
                            "lat": 55.60654795086546,
                            "lon": 61.38307928250665
                        },
                        {
                            "lat": 55.605580985459305,
                            "lon": 61.38136409821767
                        },
                        {
                            "lat": 55.60915633559608,
                            "lon": 61.380900210748
                        },
                        {
                            "lat": 55.60818931465978,
                            "lon": 61.37918504997736
                        },
                        {
                            "lat": 55.61176436581922,
                            "lon": 61.37872110344259
                        },
                        {
                            "lat": 55.61273156398959,
                            "lon": 61.38043623133948
                        },
                        {
                            "lat": 55.613698883921494,
                            "lon": 61.382151349879656
                        },
                        {
                            "lat": 55.61727416778473,
                            "lon": 61.38168724565582
                        },
                        {
                            "lat": 55.618241786748655,
                            "lon": 61.383402321951586
                        },
                        {
                            "lat": 55.61920952753454,
                            "lon": 61.38511738888402
                        },
                        {
                            "lat": 55.62278504407186,
                            "lon": 61.384653126941494
                        },
                        {
                            "lat": 55.62375308396561,
                            "lon": 61.386368151610505
                        },
                        {
                            "lat": 55.62472124574177,
                            "lon": 61.388083166909595
                        },
                        {
                            "lat": 55.621145374656585,
                            "lon": 61.38854749465261
                        },
                        {
                            "lat": 55.61756938168611,
                            "lon": 61.38901173044159
                        },
                        {
                            "lat": 55.61660157449716,
                            "lon": 61.38729664934411
                        },
                        {
                            "lat": 55.61302563698492,
                            "lon": 61.387760760281886
                        },
                        {
                            "lat": 55.60944957764498,
                            "lon": 61.38822477925864
                        },
                        {
                            "lat": 55.61041703016221,
                            "lon": 61.38993992613234
                        },
                        {
                            "lat": 55.611384604510015,
                            "lon": 61.391655063648294
                        },
                        {
                            "lat": 55.61496101856103,
                            "lon": 61.39119097889741
                        },
                        {
                            "lat": 55.615928892143415,
                            "lon": 61.39290607416174
                        },
                        {
                            "lat": 55.61235230070948,
                            "lon": 61.39337019180483
                        },
                        {
                            "lat": 55.61332011878176,
                            "lon": 61.3950853106004
                        },
                        {
                            "lat": 55.61428805874796,
                            "lon": 61.396800420033415
                        },
                        {
                            "lat": 55.61525612062925,
                            "lon": 61.39851552010229
                        },
                        {
                            "lat": 55.61622430444676,
                            "lon": 61.400230610805416
                        },
                        {
                            "lat": 55.61264688134119,
                            "lon": 61.40069476804559
                        },
                        {
                            "lat": 55.61361500962068,
                            "lon": 61.40240988228523
                        },
                        {
                            "lat": 55.614583259860396,
                            "lon": 61.404124987159335
                        },
                        {
                            "lat": 55.615551632081505,
                            "lon": 61.405840082666394
                        },
                        {
                            "lat": 55.61652012630518,
                            "lon": 61.40755516880477
                        },
                        {
                            "lat": 55.61294187108476,
                            "lon": 61.4080193656464
                        },
                        {
                            "lat": 55.6093634938589,
                            "lon": 61.40848347044726
                        },
                        {
                            "lat": 55.610331754905815,
                            "lon": 61.41019861303389
                        },
                        {
                            "lat": 55.61130013796106,
                            "lon": 61.41191374625551
                        },
                        {
                            "lat": 55.61226864304576,
                            "lon": 61.41362887011057
                        },
                        {
                            "lat": 55.60868961088194,
                            "lon": 61.41409298157751
                        },
                        {
                            "lat": 55.60965806033041,
                            "lon": 61.41580812897563
                        },
                        {
                            "lat": 55.610626631832424,
                            "lon": 61.41752326700744
                        },
                        {
                            "lat": 55.61159532540914,
                            "lon": 61.41923839567129
                        },
                        {
                            "lat": 55.608015638033926,
                            "lon": 61.4197025137957
                        },
                        {
                            "lat": 55.608984275943556,
                            "lon": 61.42141766600609
                        },
                        {
                            "lat": 55.60995303595197,
                            "lon": 61.42313280884882
                        },
                        {
                            "lat": 55.606372870912544,
                            "lon": 61.42359690070468
                        },
                        {
                            "lat": 55.6073415752201,
                            "lon": 61.42531206709545
                        },
                        {
                            "lat": 55.61092191808032,
                            "lon": 61.42484794232225
                        },
                        {
                            "lat": 55.61189092234984,
                            "lon": 61.42656306642487
                        },
                        {
                            "lat": 55.61286004878173,
                            "lon": 61.42827818115502
                        },
                        {
                            "lat": 55.61382929739717,
                            "lon": 61.429993286511156
                        },
                        {
                            "lat": 55.61024842096462,
                            "lon": 61.43045751005699
                        },
                        {
                            "lat": 55.61121761389076,
                            "lon": 61.432172638968574
                        },
                        {
                            "lat": 55.61218692902457,
                            "lon": 61.43388775850637
                        },
                        {
                            "lat": 55.615768161263645,
                            "lon": 61.433423469094905
                        },
                        {
                            "lat": 55.61934927125548,
                            "lon": 61.43295908754611
                        },
                        {
                            "lat": 55.62031906445846,
                            "lon": 61.434674131826995
                        },
                        {
                            "lat": 55.62128897994821,
                            "lon": 61.436389166724034
                        },
                        {
                            "lat": 55.624870323469445,
                            "lon": 61.4359246271426
                        },
                        {
                            "lat": 55.625840539192154,
                            "lon": 61.437639619698174
                        },
                        {
                            "lat": 55.626810877262436,
                            "lon": 61.43935460286326
                        },
                        {
                            "lat": 55.62778133770154,
                            "lon": 61.44106957663625
                        },
                        {
                            "lat": 55.62419946035046,
                            "lon": 61.44153421509597
                        },
                        {
                            "lat": 55.62516986519969,
                            "lon": 61.44324921244156
                        },
                        {
                            "lat": 55.6287519205307,
                            "lon": 61.44278454101554
                        },
                        {
                            "lat": 55.63233385345044,
                            "lon": 61.44231977743142
                        },
                        {
                            "lat": 55.63330473666797,
                            "lon": 61.44403469944008
                        },
                        {
                            "lat": 55.63427574233639,
                            "lon": 61.4457496120484
                        },
                        {
                            "lat": 55.6378579087563,
                            "lon": 61.445284690347314
                        },
                        {
                            "lat": 55.63882921488936,
                            "lon": 61.44699956056591
                        },
                        {
                            "lat": 55.64241143678991,
                            "lon": 61.446534513714866
                        },
                        {
                            "lat": 55.64338304342423,
                            "lon": 61.448249341529696
                        },
                        {
                            "lat": 55.64696532076286,
                            "lon": 61.447784169520446
                        },
                        {
                            "lat": 55.64599353616033,
                            "lon": 61.44606937470794
                        },
                        {
                            "lat": 55.64957551298233,
                            "lon": 61.445604143551414
                        },
                        {
                            "lat": 55.65054747553176,
                            "lon": 61.447318905356035
                        },
                        {
                            "lat": 55.65151956066923,
                            "lon": 61.44903365774171
                        },
                        {
                            "lat": 55.65510177079403,
                            "lon": 61.448568268411584
                        },
                        {
                            "lat": 55.658683858291184,
                            "lon": 61.44810278693328
                        },
                        {
                            "lat": 55.66226582314239,
                            "lon": 61.44763721331318
                        },
                        {
                            "lat": 55.66323856471209,
                            "lon": 61.449351857200625
                        },
                        {
                            "lat": 55.66682058479738,
                            "lon": 61.448886158408015
                        },
                        {
                            "lat": 55.66584766532931,
                            "lon": 61.4471715475575
                        },
                        {
                            "lat": 55.66942938483365,
                            "lon": 61.44670578967263
                        },
                        {
                            "lat": 55.673010981637134,
                            "lon": 61.446239939664785
                        },
                        {
                            "lat": 55.67203782915539,
                            "lon": 61.44452538546366
                        },
                        {
                            "lat": 55.67561912542354,
                            "lon": 61.444059476389334
                        },
                        {
                            "lat": 55.67920029897552,
                            "lon": 61.44359347521019
                        },
                        {
                            "lat": 55.682781349793096,
                            "lon": 61.44312738193255
                        },
                        {
                            "lat": 55.686362277857945,
                            "lon": 61.44266119656266
                        },
                        {
                            "lat": 55.68994308315182,
                            "lon": 61.442194919106875
                        },
                        {
                            "lat": 55.68896916459156,
                            "lon": 61.44048052075515
                        },
                        {
                            "lat": 55.69254966940482,
                            "lon": 61.440014184294164
                        },
                        {
                            "lat": 55.69157569593941,
                            "lon": 61.438299809558096
                        },
                        {
                            "lat": 55.695155900314674,
                            "lon": 61.43783341410585
                        },
                        {
                            "lat": 55.698735981906786,
                            "lon": 61.437366926597676
                        },
                        {
                            "lat": 55.702315940697495,
                            "lon": 61.43690034703985
                        },
                        {
                            "lat": 55.70329044705437,
                            "lon": 61.438614622529734
                        },
                        {
                            "lat": 55.7042650762522,
                            "lon": 61.440328888550496
                        },
                        {
                            "lat": 55.70068476222489,
                            "lon": 61.44079553428698
                        },
                        {
                            "lat": 55.70165933663931,
                            "lon": 61.44250982393149
                        },
                        {
                            "lat": 55.70523982831228,
                            "lon": 61.442043145100484
                        },
                        {
                            "lat": 55.708820197101694,
                            "lon": 61.44157637420955
                        },
                        {
                            "lat": 55.71240044298925,
                            "lon": 61.441109511264955
                        },
                        {
                            "lat": 55.71142533570178,
                            "lon": 61.43939532092058
                        },
                        {
                            "lat": 55.715005281087564,
                            "lon": 61.438928399039746
                        },
                        {
                            "lat": 55.71403011913538,
                            "lon": 61.43721423232541
                        },
                        {
                            "lat": 55.71045035131306,
                            "lon": 61.43768112109855
                        },
                        {
                            "lat": 55.70947548980177,
                            "lon": 61.43596691180047
                        },
                        {
                            "lat": 55.713055080078846,
                            "lon": 61.43550005613151
                        },
                        {
                            "lat": 55.71663454748157,
                            "lon": 61.435033108438134
                        },
                        {
                            "lat": 55.71565945379371,
                            "lon": 61.43331895587264
                        },
                        {
                            "lat": 55.719238620819475,
                            "lon": 61.43285194927294
                        },
                        {
                            "lat": 55.718263472536684,
                            "lon": 61.43113782033604
                        },
                        {
                            "lat": 55.72184233922814,
                            "lon": 61.43067075484395
                        },
                        {
                            "lat": 55.7208671363869,
                            "lon": 61.42895664953592
                        },
                        {
                            "lat": 55.72444570278664,
                            "lon": 61.428489525165304
                        },
                        {
                            "lat": 55.723470445423324,
                            "lon": 61.426775443486356
                        },
                        {
                            "lat": 55.72249531092195,
                            "lon": 61.42506135232214
                        },
                        {
                            "lat": 55.71891709927824,
                            "lon": 61.42552841047119
                        },
                        {
                            "lat": 55.71794226496824,
                            "lon": 61.4238142767177
                        },
                        {
                            "lat": 55.71436410787408,
                            "lon": 61.42428120978883
                        },
                        {
                            "lat": 55.71338957371907,
                            "lon": 61.422567033460155
                        },
                        {
                            "lat": 55.7169675534594,
                            "lon": 61.42210013348564
                        },
                        {
                            "lat": 55.72054541041987,
                            "lon": 61.42163314154432
                        },
                        {
                            "lat": 55.71957064437656,
                            "lon": 61.419919021933964
                        },
                        {
                            "lat": 55.71599296473044,
                            "lon": 61.42038598077659
                        },
                        {
                            "lat": 55.712415162325705,
                            "lon": 61.420852847657954
                        },
                        {
                            "lat": 55.711440873672736,
                            "lon": 61.41913865238381
                        },
                        {
                            "lat": 55.71501849876005,
                            "lon": 61.41867181859219
                        },
                        {
                            "lat": 55.71404415552697,
                            "lon": 61.41695764693402
                        },
                        {
                            "lat": 55.71306993500992,
                            "lon": 61.41524346580371
                        },
                        {
                            "lat": 55.70949266450284,
                            "lon": 61.41571023342627
                        },
                        {
                            "lat": 55.70851874394338,
                            "lon": 61.41399600974607
                        },
                        {
                            "lat": 55.71209583718761,
                            "lon": 61.41352927520285
                        },
                        {
                            "lat": 55.71112186203878,
                            "lon": 61.41181507513307
                        },
                        {
                            "lat": 55.71469865538613,
                            "lon": 61.4113482817321
                        },
                        {
                            "lat": 55.71372462568435,
                            "lon": 61.409634105273
                        },
                        {
                            "lat": 55.710148009542195,
                            "lon": 61.41010086559599
                        },
                        {
                            "lat": 55.70917427967658,
                            "lon": 61.40838664659319
                        },
                        {
                            "lat": 55.70820067242069,
                            "lon": 61.40667241812632
                        },
                        {
                            "lat": 55.71177693420697,
                            "lon": 61.40620572394893
                        },
                        {
                            "lat": 55.71275071863171,
                            "lon": 61.40791991934473
                        },
                        {
                            "lat": 55.716327034958944,
                            "lon": 61.40745310017997
                        },
                        {
                            "lat": 55.71730111917735,
                            "lon": 61.40916725302817
                        },
                        {
                            "lat": 55.72087749000301,
                            "lon": 61.408700308867786
                        },
                        {
                            "lat": 55.72445373814319,
                            "lon": 61.40823327279814
                        },
                        {
                            "lat": 55.72347929965703,
                            "lon": 61.406519186126616
                        },
                        {
                            "lat": 55.727055247991544,
                            "lon": 61.40605209125063
                        },
                        {
                            "lat": 55.72802986357969,
                            "lon": 61.407766144825544
                        },
                        {
                            "lat": 55.72900460188967,
                            "lon": 61.40948018891749
                        },
                        {
                            "lat": 55.72542829933301,
                            "lon": 61.40994734999016
                        },
                        {
                            "lat": 55.72640298324777,
                            "lon": 61.41166141770106
                        },
                        {
                            "lat": 55.72997946294274,
                            "lon": 61.41119422352488
                        },
                        {
                            "lat": 55.73355581987333,
                            "lon": 61.410726937441005
                        },
                        {
                            "lat": 55.73258078170321,
                            "lon": 61.40901293594266
                        },
                        {
                            "lat": 55.73615683875551,
                            "lon": 61.40854559107191
                        },
                        {
                            "lat": 55.73518174626905,
                            "lon": 61.40683161319668
                        },
                        {
                            "lat": 55.738757503485616,
                            "lon": 61.40636420955299
                        },
                        {
                            "lat": 55.73778235671908,
                            "lon": 61.404650255301036
                        },
                        {
                            "lat": 55.736807332707556,
                            "lon": 61.402936291557396
                        },
                        {
                            "lat": 55.74038261313212,
                            "lon": 61.402468862269885
                        },
                        {
                            "lat": 55.73940753487369,
                            "lon": 61.400754922147925
                        },
                        {
                            "lat": 55.74298251558699,
                            "lon": 61.400287434117296
                        },
                        {
                            "lat": 55.74200738311793,
                            "lon": 61.39857351761729
                        },
                        {
                            "lat": 55.74558206416246,
                            "lon": 61.398105970857365
                        },
                        {
                            "lat": 55.744606877519054,
                            "lon": 61.39639207797952
                        },
                        {
                            "lat": 55.7436318136,
                            "lon": 61.39467817560607
                        },
                        {
                            "lat": 55.74265687238406,
                            "lon": 61.392964263738634
                        },
                        {
                            "lat": 55.74623090009547,
                            "lon": 61.39249672449585
                        },
                        {
                            "lat": 55.7498048051069,
                            "lon": 61.392029093439106
                        },
                        {
                            "lat": 55.753378587400235,
                            "lon": 61.39156137057466
                        },
                        {
                            "lat": 55.754354059085564,
                            "lon": 61.39327518308198
                        },
                        {
                            "lat": 55.75792789542335,
                            "lon": 61.3928073352851
                        },
                        {
                            "lat": 55.75890366666486,
                            "lon": 61.39452110515354
                        },
                        {
                            "lat": 55.76247755700455,
                            "lon": 61.394053132416055
                        },
                        {
                            "lat": 55.76605132452932,
                            "lon": 61.393585067878696
                        },
                        {
                            "lat": 55.767027572137515,
                            "lon": 61.39529876194529
                        },
                        {
                            "lat": 55.76345362783843,
                            "lon": 61.395766859631614
                        },
                        {
                            "lat": 55.76442982150849,
                            "lon": 61.397480577332715
                        },
                        {
                            "lat": 55.76800394259997,
                            "lon": 61.397012446493974
                        },
                        {
                            "lat": 55.771577940815924,
                            "lon": 61.39654422385072
                        },
                        {
                            "lat": 55.77060139358232,
                            "lon": 61.39483057245998
                        },
                        {
                            "lat": 55.77417509215477,
                            "lon": 61.394362291181935
                        },
                        {
                            "lat": 55.77319849106178,
                            "lon": 61.392648663429824
                        },
                        {
                            "lat": 55.77222201283796,
                            "lon": 61.390935026154494
                        },
                        {
                            "lat": 55.77579523511706,
                            "lon": 61.39046671941761
                        },
                        {
                            "lat": 55.77677189003329,
                            "lon": 61.3921803235309
                        },
                        {
                            "lat": 55.780345166117534,
                            "lon": 61.391711891857376
                        },
                        {
                            "lat": 55.783918319296454,
                            "lon": 61.39124336841552
                        },
                        {
                            "lat": 55.78489545045709,
                            "lon": 61.39295689665462
                        },
                        {
                            "lat": 55.785872704562415,
                            "lon": 61.39467041535851
                        },
                        {
                            "lat": 55.786850081633695,
                            "lon": 61.39638392452559
                        },
                        {
                            "lat": 55.78327639839674,
                            "lon": 61.39685254750733
                        },
                        {
                            "lat": 55.7842537217331,
                            "lon": 61.39856608032282
                        },
                        {
                            "lat": 55.780679738783526,
                            "lon": 61.399034644701125
                        },
                        {
                            "lat": 55.78165700834882,
                            "lon": 61.40074820116486
                        },
                        {
                            "lat": 55.782634400907796,
                            "lon": 61.402461748093785
                        },
                        {
                            "lat": 55.78620873739854,
                            "lon": 61.40199311734239
                        },
                        {
                            "lat": 55.789782950856214,
                            "lon": 61.40152439478966
                        },
                        {
                            "lat": 55.788805204759306,
                            "lon": 61.399810914242806
                        },
                        {
                            "lat": 55.79237911842882,
                            "lon": 61.39934213309437
                        },
                        {
                            "lat": 55.79140131864281,
                            "lon": 61.39762867620162
                        },
                        {
                            "lat": 55.79497493256676,
                            "lon": 61.39715983647134
                        },
                        {
                            "lat": 55.79854842344603,
                            "lon": 61.39669090496966
                        },
                        {
                            "lat": 55.797570393348785,
                            "lon": 61.394977504934666
                        },
                        {
                            "lat": 55.80114358452834,
                            "lon": 61.39450851487679
                        },
                        {
                            "lat": 55.80016550085361,
                            "lon": 61.39279513849842
                        },
                        {
                            "lat": 55.803738392376026,
                            "lon": 61.39232608989821
                        },
                        {
                            "lat": 55.80276025515994,
                            "lon": 61.39061273717666
                        },
                        {
                            "lat": 55.806332847067814,
                            "lon": 61.39014363004797
                        },
                        {
                            "lat": 55.80535465634647,
                            "lon": 61.38843030098346
                        },
                        {
                            "lat": 55.804376588656844,
                            "lon": 61.386716962364616
                        },
                        {
                            "lat": 55.803398643977644,
                            "lon": 61.38500361419306
                        },
                        {
                            "lat": 55.79982658163635,
                            "lon": 61.38547262170359
                        },
                        {
                            "lat": 55.798848936432414,
                            "lon": 61.38375923078183
                        },
                        {
                            "lat": 55.79527692762741,
                            "lon": 61.384228113379244
                        },
                        {
                            "lat": 55.79429958186265,
                            "lon": 61.38251467972132
                        },
                        {
                            "lat": 55.79787141417832,
                            "lon": 61.38204583031407
                        },
                        {
                            "lat": 55.796894014852796,
                            "lon": 61.38033242030195
                        },
                        {
                            "lat": 55.795916738434556,
                            "lon": 61.37861900074707
                        },
                        {
                            "lat": 55.79493958490237,
                            "lon": 61.376905571651065
                        },
                        {
                            "lat": 55.79396255423497,
                            "lon": 61.375192133015524
                        },
                        {
                            "lat": 55.79298564641108,
                            "lon": 61.3734786848421
                        },
                        {
                            "lat": 55.79200886140951,
                            "lon": 61.371765227132414
                        },
                        {
                            "lat": 55.79557951236673,
                            "lon": 61.37129648511827
                        },
                        {
                            "lat": 55.796556473728224,
                            "lon": 61.37300990965301
                        },
                        {
                            "lat": 55.79753355793004,
                            "lon": 61.37472332464803
                        },
                        {
                            "lat": 55.801104438763545,
                            "lon": 61.37425442460034
                        },
                        {
                            "lat": 55.80012717820504,
                            "lon": 61.37254104278922
                        },
                        {
                            "lat": 55.79915004050487,
                            "lon": 61.370827651434965
                        },
                        {
                            "lat": 55.80272044580593,
                            "lon": 61.37035872608873
                        },
                        {
                            "lat": 55.806290728251895,
                            "lon": 61.3698897090859
                        },
                        {
                            "lat": 55.80531336081122,
                            "lon": 61.36817637456058
                        },
                        {
                            "lat": 55.8043361162224,
                            "lon": 61.36646303048838
                        },
                        {
                            "lat": 55.80335899446418,
                            "lon": 61.36474967687098
                        },
                        {
                            "lat": 55.80238199551534,
                            "lon": 61.36303631370997
                        },
                        {
                            "lat": 55.80140511935465,
                            "lon": 61.36132294100698
                        },
                        {
                            "lat": 55.80042836596089,
                            "lon": 61.359609558763644
                        },
                        {
                            "lat": 55.79945173531283,
                            "lon": 61.357896166981575
                        },
                        {
                            "lat": 55.79847522738926,
                            "lon": 61.35618276566236
                        },
                        {
                            "lat": 55.79749884216894,
                            "lon": 61.35446935480764
                        },
                        {
                            "lat": 55.7965225796307,
                            "lon": 61.352755934419044
                        },
                        {
                            "lat": 55.795546439753345,
                            "lon": 61.35104250449816
                        },
                        {
                            "lat": 55.79457042251563,
                            "lon": 61.34932906504664
                        },
                        {
                            "lat": 55.79359452789639,
                            "lon": 61.34761561606606
                        },
                        {
                            "lat": 55.79261875587445,
                            "lon": 61.34590215755808
                        },
                        {
                            "lat": 55.79164310642861,
                            "lon": 61.34418868952429
                        },
                        {
                            "lat": 55.79066757953768,
                            "lon": 61.3424752119663
                        },
                        {
                            "lat": 55.78969217518053,
                            "lon": 61.34076172488576
                        },
                        {
                            "lat": 55.78871689333596,
                            "lon": 61.33904822828425
                        },
                        {
                            "lat": 55.787741733982784,
                            "lon": 61.337334722163384
                        },
                        {
                            "lat": 55.78676669709988,
                            "lon": 61.335621206524806
                        },
                        {
                            "lat": 55.78319993679896,
                            "lon": 61.33608956042302
                        },
                        {
                            "lat": 55.779633054066885,
                            "lon": 61.33655782277438
                        },
                        {
                            "lat": 55.77606604892165,
                            "lon": 61.3370259935726
                        },
                        {
                            "lat": 55.77249892138121,
                            "lon": 61.33749407281144
                        },
                        {
                            "lat": 55.76893167146354,
                            "lon": 61.33796206048454
                        },
                        {
                            "lat": 55.76536429918666,
                            "lon": 61.33842995658567
                        },
                        {
                            "lat": 55.761796804568505,
                            "lon": 61.338897761108555
                        },
                        {
                            "lat": 55.75822918762712,
                            "lon": 61.339365474046886
                        },
                        {
                            "lat": 55.754661448380475,
                            "lon": 61.33983309539442
                        },
                        {
                            "lat": 55.751093586846565,
                            "lon": 61.340300625144856
                        },
                        {
                            "lat": 55.74752560304343,
                            "lon": 61.34076806329192
                        },
                        {
                            "lat": 55.74395749698905,
                            "lon": 61.341235409829345
                        },
                        {
                            "lat": 55.74038926870147,
                            "lon": 61.34170266475086
                        },
                        {
                            "lat": 55.73682091819866,
                            "lon": 61.3421698280502
                        },
                        {
                            "lat": 55.733252445498714,
                            "lon": 61.34263689972105
                        },
                        {
                            "lat": 55.72968385061962,
                            "lon": 61.34310387975718
                        },
                        {
                            "lat": 55.72611513357944,
                            "lon": 61.34357076815232
                        },
                        {
                            "lat": 55.7225462943962,
                            "lon": 61.344037564900184
                        },
                        {
                            "lat": 55.71897733308795,
                            "lon": 61.344504269994474
                        },
                        {
                            "lat": 55.715408249672734,
                            "lon": 61.344970883428985
                        },
                        {
                            "lat": 55.71183904416863,
                            "lon": 61.34543740519742
                        },
                        {
                            "lat": 55.70826971659365,
                            "lon": 61.3459038352935
                        },
                        {
                            "lat": 55.704700266965915,
                            "lon": 61.34637017371098
                        },
                        {
                            "lat": 55.70113069530344,
                            "lon": 61.34683642044357
                        },
                        {
                            "lat": 55.69756100162436,
                            "lon": 61.34730257548504
                        },
                        {
                            "lat": 55.69399118594669,
                            "lon": 61.347768638829095
                        },
                        {
                            "lat": 55.69042124828857,
                            "lon": 61.3482346104695
                        },
                        {
                            "lat": 55.68685118866805,
                            "lon": 61.348700490399956
                        },
                        {
                            "lat": 55.68328100710324,
                            "lon": 61.349166278614234
                        },
                        {
                            "lat": 55.67971070361224,
                            "lon": 61.34963197510607
                        },
                        {
                            "lat": 55.67614027821312,
                            "lon": 61.35009757986919
                        },
                        {
                            "lat": 55.672569730924046,
                            "lon": 61.35056309289736
                        },
                        {
                            "lat": 55.66899906176308,
                            "lon": 61.35102851418429
                        },
                        {
                            "lat": 55.66542827074838,
                            "lon": 61.35149384372376
                        },
                        {
                            "lat": 55.66185735789802,
                            "lon": 61.3519590815095
                        },
                        {
                            "lat": 55.658286323230165,
                            "lon": 61.352424227535224
                        },
                        {
                            "lat": 55.65471516676293,
                            "lon": 61.352889281794724
                        },
                        {
                            "lat": 55.65114388851446,
                            "lon": 61.35335424428173
                        },
                        {
                            "lat": 55.64757248850289,
                            "lon": 61.35381911498999
                        },
                        {
                            "lat": 55.64854064820932,
                            "lon": 61.35553391833394
                        },
                        {
                            "lat": 55.64496894969294,
                            "lon": 61.35599873016512
                        },
                        {
                            "lat": 55.644000966746354,
                            "lon": 61.354283893913255
                        },
                        {
                            "lat": 55.64042932326301,
                            "lon": 61.354748581045236
                        },
                        {
                            "lat": 55.636857558071036,
                            "lon": 61.35521317637978
                        },
                        {
                            "lat": 55.633285671188546,
                            "lon": 61.355677679910514
                        },
                        {
                            "lat": 55.62971366263374,
                            "lon": 61.3561420916313
                        },
                        {
                            "lat": 55.62614153242479,
                            "lon": 61.3566064115358
                        },
                        {
                            "lat": 55.62256928057985,
                            "lon": 61.35707063961783
                        },
                        {
                            "lat": 55.6189969071171,
                            "lon": 61.357534775871116
                        },
                        {
                            "lat": 55.61542441205473,
                            "lon": 61.357998820289424
                        },
                        {
                            "lat": 55.61185179541093,
                            "lon": 61.35846277286652
                        },
                        {
                            "lat": 55.60827905720388,
                            "lon": 61.35892663359615
                        },
                        {
                            "lat": 55.604706197451804,
                            "lon": 61.359390402472066
                        },
                        {
                            "lat": 55.60113321617287,
                            "lon": 61.35985407948801
                        },
                        {
                            "lat": 55.597560113385306,
                            "lon": 61.36031766463778
                        },
                        {
                            "lat": 55.593986889107335,
                            "lon": 61.36078115791512
                        },
                        {
                            "lat": 55.59041354335714,
                            "lon": 61.36124455931379
                        },
                        {
                            "lat": 55.58684007615297,
                            "lon": 61.36170786882755
                        },
                        {
                            "lat": 55.58326648751304,
                            "lon": 61.36217108645018
                        },
                        {
                            "lat": 55.57969277745559,
                            "lon": 61.36263421217542
                        },
                        {
                            "lat": 55.580657575123674,
                            "lon": 61.364349639824674
                        },
                        {
                            "lat": 55.581622494212084,
                            "lon": 61.36606505815519
                        },
                        {
                            "lat": 55.58258753474184,
                            "lon": 61.36778046716542
                        },
                        {
                            "lat": 55.58355269673401,
                            "lon": 61.36949586685374
                        },
                        {
                            "lat": 55.57997815652842,
                            "lon": 61.369959031907264
                        },
                        {
                            "lat": 55.57640349485762,
                            "lon": 61.37042210502875
                        },
                        {
                            "lat": 55.57736842384655,
                            "lon": 61.372137561021134
                        },
                        {
                            "lat": 55.578333474303456,
                            "lon": 61.37385300769531
                        },
                        {
                            "lat": 55.57929864624943,
                            "lon": 61.3755684450497
                        },
                        {
                            "lat": 55.58287383973659,
                            "lon": 61.37510527348498
                        },
                        {
                            "lat": 55.58644891169543,
                            "lon": 61.37464200997163
                        },
                        {
                            "lat": 55.587414559747735,
                            "lon": 61.376357372356594
                        },
                        {
                            "lat": 55.588380329367716,
                            "lon": 61.37807272541176
                        },
                        {
                            "lat": 55.59195563433121,
                            "lon": 61.3776093042879
                        },
                        {
                            "lat": 55.59292170284299,
                            "lon": 61.3793246151725
                        },
                        {
                            "lat": 55.58934622057645,
                            "lon": 61.379788069135536
                        },
                        {
                            "lat": 55.59031223339505,
                            "lon": 61.38150340352635
                        },
                        {
                            "lat": 55.59127836784454,
                            "lon": 61.3832187285826
                        },
                        {
                            "lat": 55.5877024092652,
                            "lon": 61.38368215626418
                        },
                        {
                            "lat": 55.584126329052125,
                            "lon": 61.3841454919694
                        },
                        {
                            "lat": 55.58509223037473,
                            "lon": 61.38586087337178
                        },
                        {
                            "lat": 55.58151585112512,
                            "lon": 61.38632414992724
                        },
                        {
                            "lat": 55.577939350257225,
                            "lon": 61.38678733448829
                        },
                        {
                            "lat": 55.57890501831661,
                            "lon": 61.388502772226786
                        },
                        {
                            "lat": 55.575328218367225,
                            "lon": 61.38896589761238
                        },
                        {
                            "lat": 55.57629383054788,
                            "lon": 61.390681358853875
                        },
                        {
                            "lat": 55.579870807997075,
                            "lon": 61.39021820063969
                        }
                    ],
                    [
                        {
                            "lat": 55.58276890697586,
                            "lon": 61.39536442990899
                        },
                        {
                            "lat": 55.58634629532877,
                            "lon": 61.394901081160086
                        },
                        {
                            "lat": 55.58731274925637,
                            "lon": 61.3966164394069
                        },
                        {
                            "lat": 55.590890193416286,
                            "lon": 61.39615296578248
                        },
                        {
                            "lat": 55.589923561957924,
                            "lon": 61.394437640389086
                        },
                        {
                            "lat": 55.588957052241604,
                            "lon": 61.392722305656875
                        },
                        {
                            "lat": 55.585379963124936,
                            "lon": 61.39318571357791
                        },
                        {
                            "lat": 55.5818027523056,
                            "lon": 61.39364902948237
                        },
                        {
                            "lat": 55.58276890697586,
                            "lon": 61.39536442990899
                        }
                    ],
                    [
                        {
                            "lat": 55.597478120435795,
                            "lon": 61.448678516917155
                        },
                        {
                            "lat": 55.59650901794494,
                            "lon": 61.44696326540543
                        },
                        {
                            "lat": 55.592925861737655,
                            "lon": 61.44742710712621
                        },
                        {
                            "lat": 55.593894786038966,
                            "lon": 61.449142391557665
                        },
                        {
                            "lat": 55.597478120435795,
                            "lon": 61.448678516917155
                        }
                    ],
                    [
                        {
                            "lat": 55.609197149202956,
                            "lon": 61.4490014830429
                        },
                        {
                            "lat": 55.608227389948496,
                            "lon": 61.44728633969992
                        },
                        {
                            "lat": 55.60464442240892,
                            "lon": 61.447750490982024
                        },
                        {
                            "lat": 55.60106133256524,
                            "lon": 61.448214550056534
                        },
                        {
                            "lat": 55.597478120435795,
                            "lon": 61.448678516917155
                        },
                        {
                            "lat": 55.598447345194,
                            "lon": 61.45039375906301
                        },
                        {
                            "lat": 55.60203073551011,
                            "lon": 61.44992975927365
                        },
                        {
                            "lat": 55.605614003519214,
                            "lon": 61.44946566726484
                        },
                        {
                            "lat": 55.606583706954865,
                            "lon": 61.4511808341733
                        },
                        {
                            "lat": 55.61016703080114,
                            "lon": 61.450716617008055
                        },
                        {
                            "lat": 55.609197149202956,
                            "lon": 61.4490014830429
                        }
                    ],
                    [
                        {
                            "lat": 55.61733331138034,
                            "lon": 61.44978790604651
                        },
                        {
                            "lat": 55.616363073520844,
                            "lon": 61.44807283798464
                        },
                        {
                            "lat": 55.61278017254295,
                            "lon": 61.44853720661407
                        },
                        {
                            "lat": 55.61375023228241,
                            "lon": 61.45025230763037
                        },
                        {
                            "lat": 55.61733331138034,
                            "lon": 61.44978790604651
                        }
                    ],
                    [
                        {
                            "lat": 55.622486279741366,
                            "lon": 61.4656877943567
                        },
                        {
                            "lat": 55.61890147416185,
                            "lon": 61.46615240042931
                        },
                        {
                            "lat": 55.619872580367286,
                            "lon": 61.46786744052846
                        },
                        {
                            "lat": 55.62345756427183,
                            "lon": 61.46740280147249
                        },
                        {
                            "lat": 55.622486279741366,
                            "lon": 61.4656877943567
                        }
                    ],
                    [
                        {
                            "lat": 55.630929503276036,
                            "lon": 61.47379787262321
                        },
                        {
                            "lat": 55.62995754985556,
                            "lon": 61.47208293611515
                        },
                        {
                            "lat": 55.626372153525736,
                            "lon": 61.47254776642038
                        },
                        {
                            "lat": 55.627343928568926,
                            "lon": 61.47426273593114
                        },
                        {
                            "lat": 55.630929503276036,
                            "lon": 61.47379787262321
                        }
                    ],
                    [
                        {
                            "lat": 55.636459587012176,
                            "lon": 61.47676269579889
                        },
                        {
                            "lat": 55.635487209777914,
                            "lon": 61.47504781112505
                        },
                        {
                            "lat": 55.63190157939635,
                            "lon": 61.47551279972256
                        },
                        {
                            "lat": 55.6328737782379,
                            "lon": 61.47722771741158
                        },
                        {
                            "lat": 55.636459587012176,
                            "lon": 61.47676269579889
                        }
                    ],
                    [
                        {
                            "lat": 55.630259989872684,
                            "lon": 61.47940758802558
                        },
                        {
                            "lat": 55.62928784672106,
                            "lon": 61.47769264673538
                        },
                        {
                            "lat": 55.62570179248009,
                            "lon": 61.478157483763965
                        },
                        {
                            "lat": 55.62667375717792,
                            "lon": 61.479872458061685
                        },
                        {
                            "lat": 55.630259989872684,
                            "lon": 61.47940758802558
                        }
                    ],
                    [
                        {
                            "lat": 55.626003496761555,
                            "lon": 61.485482201264546
                        },
                        {
                            "lat": 55.62959038771927,
                            "lon": 61.485017324508846
                        },
                        {
                            "lat": 55.628618054776155,
                            "lon": 61.48330237843574
                        },
                        {
                            "lat": 55.63220464443372,
                            "lon": 61.48283744237419
                        },
                        {
                            "lat": 55.631232255769774,
                            "lon": 61.481122519905696
                        },
                        {
                            "lat": 55.627645844602824,
                            "lon": 61.48158742295281
                        },
                        {
                            "lat": 55.62667375717792,
                            "lon": 61.479872458061685
                        },
                        {
                            "lat": 55.62308740175607,
                            "lon": 61.48033723579076
                        },
                        {
                            "lat": 55.61950092362552,
                            "lon": 61.4808019212065
                        },
                        {
                            "lat": 55.620472654042004,
                            "lon": 61.48251695210289
                        },
                        {
                            "lat": 55.624059310687414,
                            "lon": 61.4820522336873
                        },
                        {
                            "lat": 55.62503134234883,
                            "lon": 61.483767222179125
                        },
                        {
                            "lat": 55.621444507170104,
                            "lon": 61.484231973597986
                        },
                        {
                            "lat": 55.62241648303113,
                            "lon": 61.4859469856902
                        },
                        {
                            "lat": 55.626003496761555,
                            "lon": 61.485482201264546
                        }
                    ],
                    [
                        {
                            "lat": 55.621444507170104,
                            "lon": 61.484231973597986
                        },
                        {
                            "lat": 55.620472654042004,
                            "lon": 61.48251695210289
                        },
                        {
                            "lat": 55.61688587468499,
                            "lon": 61.482981578193254
                        },
                        {
                            "lat": 55.61785754925843,
                            "lon": 61.48469663268609
                        },
                        {
                            "lat": 55.621444507170104,
                            "lon": 61.484231973597986
                        }
                    ],
                    [
                        {
                            "lat": 55.6308661108432,
                            "lon": 61.49405695554022
                        },
                        {
                            "lat": 55.62989334235327,
                            "lon": 61.49234202351064
                        },
                        {
                            "lat": 55.62630561423179,
                            "lon": 61.492806939997905
                        },
                        {
                            "lat": 55.62727820407788,
                            "lon": 61.49452190505156
                        },
                        {
                            "lat": 55.6308661108432,
                            "lon": 61.49405695554022
                        }
                    ],
                    [
                        {
                            "lat": 55.66970044426776,
                            "lon": 61.47428792716406
                        },
                        {
                            "lat": 55.66872633953333,
                            "lon": 61.47257334932395
                        },
                        {
                            "lat": 55.667752357725114,
                            "lon": 61.47085876203733
                        },
                        {
                            "lat": 55.664167832661335,
                            "lon": 61.471324580901275
                        },
                        {
                            "lat": 55.66514163626896,
                            "lon": 61.47303920125317
                        },
                        {
                            "lat": 55.6615568100967,
                            "lon": 61.473504960950216
                        },
                        {
                            "lat": 55.6625305583719,
                            "lon": 61.47521960492221
                        },
                        {
                            "lat": 55.66611556278445,
                            "lon": 61.474753812162
                        },
                        {
                            "lat": 55.66970044426776,
                            "lon": 61.47428792716406
                        }
                    ],
                    [
                        {
                            "lat": 55.66350442955798,
                            "lon": 61.47693423945303
                        },
                        {
                            "lat": 55.6625305583719,
                            "lon": 61.47521960492221
                        },
                        {
                            "lat": 55.65894543104844,
                            "lon": 61.47568530543836
                        },
                        {
                            "lat": 55.659919123954516,
                            "lon": 61.47739997303026
                        },
                        {
                            "lat": 55.66350442955798,
                            "lon": 61.47693423945303
                        }
                    ],
                    [
                        {
                            "lat": 55.701915002200856,
                            "lon": 61.53086368584697
                        },
                        {
                            "lat": 55.69832422918578,
                            "lon": 61.53133066405734
                        },
                        {
                            "lat": 55.694733332533794,
                            "lon": 61.531797549846374
                        },
                        {
                            "lat": 55.691142312263345,
                            "lon": 61.5322643432077
                        },
                        {
                            "lat": 55.69212007263623,
                            "lon": 61.53397869844996
                        },
                        {
                            "lat": 55.69571127179374,
                            "lon": 61.53351187191306
                        },
                        {
                            "lat": 55.69930234731129,
                            "lon": 61.53304495294292
                        },
                        {
                            "lat": 55.70289329917038,
                            "lon": 61.53257794154584
                        },
                        {
                            "lat": 55.701915002200856,
                            "lon": 61.53086368584697
                        }
                    ],
                    [
                        {
                            "lat": 55.67071348992152,
                            "lon": 61.45574450743421
                        },
                        {
                            "lat": 55.66713075805968,
                            "lon": 61.456210338409335
                        },
                        {
                            "lat": 55.66810411327033,
                            "lon": 61.45792493511817
                        },
                        {
                            "lat": 55.67168702312208,
                            "lon": 61.45745907108879
                        },
                        {
                            "lat": 55.67071348992152,
                            "lon": 61.45574450743421
                        }
                    ],
                    [
                        {
                            "lat": 55.68406906720984,
                            "lon": 61.452165821188515
                        },
                        {
                            "lat": 55.680487004500684,
                            "lon": 61.45263198768082
                        },
                        {
                            "lat": 55.681460948699,
                            "lon": 61.454346461592074
                        },
                        {
                            "lat": 55.68504318929444,
                            "lon": 61.45388026202677
                        },
                        {
                            "lat": 55.68406906720984,
                            "lon": 61.452165821188515
                        }
                    ],
                    [
                        {
                            "lat": 55.70068476222489,
                            "lon": 61.44079553428698
                        },
                        {
                            "lat": 55.69971031065449,
                            "lon": 61.43908123517516
                        },
                        {
                            "lat": 55.69613005143188,
                            "lon": 61.43954775576541
                        },
                        {
                            "lat": 55.69710432535355,
                            "lon": 61.44126208796273
                        },
                        {
                            "lat": 55.70068476222489,
                            "lon": 61.44079553428698
                        }
                    ],
                    [
                        {
                            "lat": 55.71435102634576,
                            "lon": 61.444537863514206
                        },
                        {
                            "lat": 55.71337567319681,
                            "lon": 61.442823692130034
                        },
                        {
                            "lat": 55.70979524968797,
                            "lon": 61.44329058818368
                        },
                        {
                            "lat": 55.70621470325598,
                            "lon": 61.44375739217812
                        },
                        {
                            "lat": 55.70718970110458,
                            "lon": 61.44547162978176
                        },
                        {
                            "lat": 55.71077042519742,
                            "lon": 61.445004792680365
                        },
                        {
                            "lat": 55.71435102634576,
                            "lon": 61.444537863514206
                        }
                    ],
                    [
                        {
                            "lat": 55.76392054320333,
                            "lon": 61.524624612622375
                        },
                        {
                            "lat": 55.76750926606525,
                            "lon": 61.524155938625576
                        },
                        {
                            "lat": 55.77109786491954,
                            "lon": 61.523687172322425
                        },
                        {
                            "lat": 55.774686339747895,
                            "lon": 61.52321831371932
                        },
                        {
                            "lat": 55.77827469053198,
                            "lon": 61.52274936282259
                        },
                        {
                            "lat": 55.777292642814444,
                            "lon": 61.52103580532924
                        },
                        {
                            "lat": 55.78088069116604,
                            "lon": 61.52056679545426
                        },
                        {
                            "lat": 55.779898589137915,
                            "lon": 61.518853261691774
                        },
                        {
                            "lat": 55.783486335100164,
                            "lon": 61.518384192852515
                        },
                        {
                            "lat": 55.78707395700635,
                            "lon": 61.517915031749766
                        },
                        {
                            "lat": 55.78609162241415,
                            "lon": 61.51620155503153
                        },
                        {
                            "lat": 55.78967894197739,
                            "lon": 61.51573233499038
                        },
                        {
                            "lat": 55.79326613746954,
                            "lon": 61.51526302270405
                        },
                        {
                            "lat": 55.79685320887231,
                            "lon": 61.514793618178864
                        },
                        {
                            "lat": 55.80044015616742,
                            "lon": 61.514324121421225
                        },
                        {
                            "lat": 55.80142320370446,
                            "lon": 61.5160374648316
                        },
                        {
                            "lat": 55.805010205055886,
                            "lon": 61.515567842507046
                        },
                        {
                            "lat": 55.808597082241505,
                            "lon": 61.51509812795718
                        },
                        {
                            "lat": 55.812183835243076,
                            "lon": 61.51462832118846
                        },
                        {
                            "lat": 55.81577046404228,
                            "lon": 61.51415842220714
                        },
                        {
                            "lat": 55.81675434839951,
                            "lon": 61.51587162260967
                        },
                        {
                            "lat": 55.82034103109232,
                            "lon": 61.515401598055675
                        },
                        {
                            "lat": 55.82392758952467,
                            "lon": 61.51493148129635
                        },
                        {
                            "lat": 55.82751402367835,
                            "lon": 61.51446127233806
                        },
                        {
                            "lat": 55.82849856661031,
                            "lon": 61.51617436300112
                        },
                        {
                            "lat": 55.832085054534645,
                            "lon": 61.515704028463595
                        },
                        {
                            "lat": 55.83567141812219,
                            "lon": 61.51523360173432
                        },
                        {
                            "lat": 55.839257657354686,
                            "lon": 61.514763082819606
                        },
                        {
                            "lat": 55.84284377221391,
                            "lon": 61.51429247172584
                        },
                        {
                            "lat": 55.8464297626816,
                            "lon": 61.51382176845944
                        },
                        {
                            "lat": 55.850015628739555,
                            "lon": 61.513350973026704
                        },
                        {
                            "lat": 55.85360137036955,
                            "lon": 61.51288008543407
                        },
                        {
                            "lat": 55.85718698755336,
                            "lon": 61.51240910568786
                        },
                        {
                            "lat": 55.86077248027277,
                            "lon": 61.51193803379448
                        },
                        {
                            "lat": 55.864357848509584,
                            "lon": 61.51146686976033
                        },
                        {
                            "lat": 55.8679430922456,
                            "lon": 61.51099561359175
                        },
                        {
                            "lat": 55.87152821146261,
                            "lon": 61.51052426529513
                        },
                        {
                            "lat": 55.875113206142395,
                            "lon": 61.51005282487685
                        },
                        {
                            "lat": 55.87869807626684,
                            "lon": 61.509581292343306
                        },
                        {
                            "lat": 55.88228282181769,
                            "lon": 61.50910966770085
                        },
                        {
                            "lat": 55.885867442776785,
                            "lon": 61.50863795095586
                        },
                        {
                            "lat": 55.88488005335619,
                            "lon": 61.50692539514128
                        },
                        {
                            "lat": 55.883892788523845,
                            "lon": 61.505212829647306
                        },
                        {
                            "lat": 55.88290564825817,
                            "lon": 61.50350025447561
                        },
                        {
                            "lat": 55.88191863253752,
                            "lon": 61.50178766962783
                        },
                        {
                            "lat": 55.88093174134025,
                            "lon": 61.50007507510565
                        },
                        {
                            "lat": 55.87994497464477,
                            "lon": 61.49836247091071
                        },
                        {
                            "lat": 55.87895833242948,
                            "lon": 61.49664985704465
                        },
                        {
                            "lat": 55.87797181467274,
                            "lon": 61.49493723350912
                        },
                        {
                            "lat": 55.87698542135298,
                            "lon": 61.493224600305794
                        },
                        {
                            "lat": 55.87599915244859,
                            "lon": 61.491511957436266
                        },
                        {
                            "lat": 55.875013007937966,
                            "lon": 61.489799304902235
                        },
                        {
                            "lat": 55.87402698779955,
                            "lon": 61.48808664270534
                        },
                        {
                            "lat": 55.87304109201173,
                            "lon": 61.48637397084722
                        },
                        {
                            "lat": 55.872055320552946,
                            "lon": 61.48466128932954
                        },
                        {
                            "lat": 55.8710696734016,
                            "lon": 61.4829485981539
                        },
                        {
                            "lat": 55.87008415053615,
                            "lon": 61.48123589732198
                        },
                        {
                            "lat": 55.869098751935034,
                            "lon": 61.47952318683543
                        },
                        {
                            "lat": 55.868113477576685,
                            "lon": 61.47781046669588
                        },
                        {
                            "lat": 55.86712832743954,
                            "lon": 61.47609773690501
                        },
                        {
                            "lat": 55.86614330150207,
                            "lon": 61.47438499746441
                        },
                        {
                            "lat": 55.865158399742725,
                            "lon": 61.47267224837579
                        },
                        {
                            "lat": 55.86417362213995,
                            "lon": 61.47095948964074
                        },
                        {
                            "lat": 55.86318896867224,
                            "lon": 61.46924672126094
                        },
                        {
                            "lat": 55.86220443931804,
                            "lon": 61.467533943237974
                        },
                        {
                            "lat": 55.861220034055826,
                            "lon": 61.46582115557356
                        },
                        {
                            "lat": 55.860235752864114,
                            "lon": 61.46410835826927
                        },
                        {
                            "lat": 55.85925159572135,
                            "lon": 61.462395551326836
                        },
                        {
                            "lat": 55.858267562606045,
                            "lon": 61.46068273474782
                        },
                        {
                            "lat": 55.857283653496694,
                            "lon": 61.45896990853391
                        },
                        {
                            "lat": 55.856299868371785,
                            "lon": 61.457257072686694
                        },
                        {
                            "lat": 55.855316207209825,
                            "lon": 61.45554422720785
                        },
                        {
                            "lat": 55.85433266998933,
                            "lon": 61.45383137209906
                        },
                        {
                            "lat": 55.850753727814926,
                            "lon": 61.454302019553374
                        },
                        {
                            "lat": 55.84717466173865,
                            "lon": 61.4547725750816
                        },
                        {
                            "lat": 55.848157844575645,
                            "lon": 61.4564854969075
                        },
                        {
                            "lat": 55.844578477391636,
                            "lon": 61.4569559938536
                        },
                        {
                            "lat": 55.84099898632053,
                            "lon": 61.45742639885542
                        },
                        {
                            "lat": 55.84198193855709,
                            "lon": 61.4591393777603
                        },
                        {
                            "lat": 55.83840214633191,
                            "lon": 61.45960972415425
                        },
                        {
                            "lat": 55.83482223023437,
                            "lon": 61.460079978585675
                        },
                        {
                            "lat": 55.831242190282616,
                            "lon": 61.460550141048266
                        },
                        {
                            "lat": 55.8276620264948,
                            "lon": 61.4610202115357
                        },
                        {
                            "lat": 55.82408173888904,
                            "lon": 61.46149019004159
                        },
                        {
                            "lat": 55.82050132748352,
                            "lon": 61.4619600765596
                        },
                        {
                            "lat": 55.819519439279446,
                            "lon": 61.46024689768104
                        },
                        {
                            "lat": 55.815939081506194,
                            "lon": 61.46071665889519
                        },
                        {
                            "lat": 55.816920792296365,
                            "lon": 61.462429871083366
                        },
                        {
                            "lat": 55.81334013334575,
                            "lon": 61.46289957360658
                        },
                        {
                            "lat": 55.814321790445845,
                            "lon": 61.46461280950904
                        },
                        {
                            "lat": 55.815303571312704,
                            "lon": 61.466326035819975
                        },
                        {
                            "lat": 55.811722433648605,
                            "lon": 61.46679571294392
                        },
                        {
                            "lat": 55.808141172214434,
                            "lon": 61.46726529804355
                        },
                        {
                            "lat": 55.80912272182536,
                            "lon": 61.468978581373904
                        },
                        {
                            "lat": 55.812704160792364,
                            "lon": 61.46850896297075
                        },
                        {
                            "lat": 55.81368601172766,
                            "lon": 61.47022220340625
                        },
                        {
                            "lat": 55.810104395209656,
                            "lon": 61.4706918551164
                        },
                        {
                            "lat": 55.80652265489678,
                            "lon": 61.47116141478485
                        },
                        {
                            "lat": 55.802940790807284,
                            "lon": 61.47163088240531
                        },
                        {
                            "lat": 55.79935880295928,
                            "lon": 61.472100257971334
                        },
                        {
                            "lat": 55.7983776623569,
                            "lon": 61.470386884340996
                        },
                        {
                            "lat": 55.794795728405546,
                            "lon": 61.47085613456142
                        },
                        {
                            "lat": 55.79381488914068,
                            "lon": 61.46914271807228
                        },
                        {
                            "lat": 55.79023300912893,
                            "lon": 61.46961184295527
                        },
                        {
                            "lat": 55.786651005456264,
                            "lon": 61.47008087577593
                        },
                        {
                            "lat": 55.78306887814086,
                            "lon": 61.47054981652789
                        },
                        {
                            "lat": 55.78404918442077,
                            "lon": 61.472263332828135
                        },
                        {
                            "lat": 55.780466755776295,
                            "lon": 61.47273221476436
                        },
                        {
                            "lat": 55.78144700797938,
                            "lon": 61.47444575476389
                        },
                        {
                            "lat": 55.7778642779629,
                            "lon": 61.4749145778705
                        },
                        {
                            "lat": 55.776884203504075,
                            "lon": 61.473201004613706
                        },
                        {
                            "lat": 55.773301527622316,
                            "lon": 61.47366970236981
                        },
                        {
                            "lat": 55.769718728149265,
                            "lon": 61.47413830802636
                        },
                        {
                            "lat": 55.76613580510315,
                            "lon": 61.47460682157698
                        },
                        {
                            "lat": 55.762552758502224,
                            "lon": 61.47507524301537
                        },
                        {
                            "lat": 55.76353212176942,
                            "lon": 61.47678894924584
                        },
                        {
                            "lat": 55.764511608594646,
                            "lon": 61.47850264593202
                        },
                        {
                            "lat": 55.76092808273578,
                            "lon": 61.478971041714445
                        },
                        {
                            "lat": 55.76190751525244,
                            "lon": 61.48068476209128
                        },
                        {
                            "lat": 55.7628870713518,
                            "lon": 61.48239847292406
                        },
                        {
                            "lat": 55.763866751055325,
                            "lon": 61.48411217421115
                        },
                        {
                            "lat": 55.76484655438447,
                            "lon": 61.48582586595093
                        },
                        {
                            "lat": 55.76843079190508,
                            "lon": 61.485357337201535
                        },
                        {
                            "lat": 55.76941089684242,
                            "lon": 61.48707098614198
                        },
                        {
                            "lat": 55.77299518863724,
                            "lon": 61.48660233198481
                        },
                        {
                            "lat": 55.7739755952193,
                            "lon": 61.488315938111775
                        },
                        {
                            "lat": 55.77495612552796,
                            "lon": 61.49002953467959
                        },
                        {
                            "lat": 55.77137147779917,
                            "lon": 61.49049825535913
                        },
                        {
                            "lat": 55.77235195386153,
                            "lon": 61.492211875632584
                        },
                        {
                            "lat": 55.77333255367523,
                            "lon": 61.493925486347095
                        },
                        {
                            "lat": 55.76974742616657,
                            "lon": 61.49439418138342
                        },
                        {
                            "lat": 55.76616217490335,
                            "lon": 61.49486278423393
                        },
                        {
                            "lat": 55.76714254236355,
                            "lon": 61.496576451917996
                        },
                        {
                            "lat": 55.763556989268615,
                            "lon": 61.497044995833036
                        },
                        {
                            "lat": 55.75997131243419,
                            "lon": 61.497513447544044
                        },
                        {
                            "lat": 55.75638551187855,
                            "lon": 61.49798180704462
                        },
                        {
                            "lat": 55.75736546869088,
                            "lon": 61.49969556493748
                        },
                        {
                            "lat": 55.75834554922765,
                            "lon": 61.50140931328395
                        },
                        {
                            "lat": 55.75932575351032,
                            "lon": 61.50312305208236
                        },
                        {
                            "lat": 55.76030608156044,
                            "lon": 61.50483678133107
                        },
                        {
                            "lat": 55.76128653339951,
                            "lon": 61.50655050102852
                        },
                        {
                            "lat": 55.762267109049056,
                            "lon": 61.508264211173014
                        },
                        {
                            "lat": 55.76585397881947,
                            "lon": 61.50779565212546
                        },
                        {
                            "lat": 55.769440724739574,
                            "lon": 61.5073270008342
                        },
                        {
                            "lat": 55.768459792615126,
                            "lon": 61.50561335722832
                        },
                        {
                            "lat": 55.77204623646147,
                            "lon": 61.50514464697738
                        },
                        {
                            "lat": 55.77563255644244,
                            "lon": 61.50467584450099
                        },
                        {
                            "lat": 55.779218752539755,
                            "lon": 61.5042069498055
                        },
                        {
                            "lat": 55.77823740976638,
                            "lon": 61.50249339647876
                        },
                        {
                            "lat": 55.78182330383945,
                            "lon": 61.50202444286134
                        },
                        {
                            "lat": 55.78084190684251,
                            "lon": 61.500310913253195
                        },
                        {
                            "lat": 55.784427498934434,
                            "lon": 61.499841900727716
                        },
                        {
                            "lat": 55.783446047750424,
                            "lon": 61.49812839483834
                        },
                        {
                            "lat": 55.78703133790423,
                            "lon": 61.49765932341882
                        },
                        {
                            "lat": 55.79061650416589,
                            "lon": 61.497190159822196
                        },
                        {
                            "lat": 55.79420154651712,
                            "lon": 61.4967209040549
                        },
                        {
                            "lat": 55.797786464939655,
                            "lon": 61.49625155612322
                        },
                        {
                            "lat": 55.79876862816517,
                            "lon": 61.49796492879983
                        },
                        {
                            "lat": 55.79518353176456,
                            "lon": 61.49843431004301
                        },
                        {
                            "lat": 55.79159831141372,
                            "lon": 61.4989035991163
                        },
                        {
                            "lat": 55.79258024259351,
                            "lon": 61.500617028829325
                        },
                        {
                            "lat": 55.7889947202713,
                            "lon": 61.50108625903025
                        },
                        {
                            "lat": 55.7899765973469,
                            "lon": 61.502799712468054
                        },
                        {
                            "lat": 55.78639077301026,
                            "lon": 61.50326888378269
                        },
                        {
                            "lat": 55.78737259594516,
                            "lon": 61.50498236094503
                        },
                        {
                            "lat": 55.783786469550996,
                            "lon": 61.505451473359415
                        },
                        {
                            "lat": 55.78020021921508,
                            "lon": 61.5059204935619
                        },
                        {
                            "lat": 55.78118180981388,
                            "lon": 61.507634027746306
                        },
                        {
                            "lat": 55.7775952573741,
                            "lon": 61.508102989022696
                        },
                        {
                            "lat": 55.77857679371924,
                            "lon": 61.509816546929166
                        },
                        {
                            "lat": 55.774989939132524,
                            "lon": 61.51028544926551
                        },
                        {
                            "lat": 55.77400858100758,
                            "lon": 61.50857185806888
                        },
                        {
                            "lat": 55.77042178073263,
                            "lon": 61.50904063487859
                        },
                        {
                            "lat": 55.771402960615816,
                            "lon": 61.51075425935978
                        },
                        {
                            "lat": 55.76781585818743,
                            "lon": 61.51122297720562
                        },
                        {
                            "lat": 55.76422863186567,
                            "lon": 61.51169160279671
                        },
                        {
                            "lat": 55.7652095790758,
                            "lon": 61.5134052842726
                        },
                        {
                            "lat": 55.76619065018255,
                            "lon": 61.515118956189085
                        },
                        {
                            "lat": 55.76260294331805,
                            "lon": 61.51558755606539
                        },
                        {
                            "lat": 55.76358395998164,
                            "lon": 61.51730125169915
                        },
                        {
                            "lat": 55.76456510056659,
                            "lon": 61.5190149377737
                        },
                        {
                            "lat": 55.760976913018204,
                            "lon": 61.5194835119206
                        },
                        {
                            "lat": 55.75738860154523,
                            "lon": 61.51995199377147
                        },
                        {
                            "lat": 55.75380016616597,
                            "lon": 61.52042038331999
                        },
                        {
                            "lat": 55.75478089537065,
                            "lon": 61.522134159656446
                        },
                        {
                            "lat": 55.75576174848481,
                            "lon": 61.52384792644089
                        },
                        {
                            "lat": 55.759350540807645,
                            "lon": 61.523379470351465
                        },
                        {
                            "lat": 55.760331696352125,
                            "lon": 61.52509319430652
                        },
                        {
                            "lat": 55.76392054320333,
                            "lon": 61.524624612622375
                        }
                    ],
                    [
                        {
                            "lat": 55.74103414330459,
                            "lon": 61.41711533804379
                        },
                        {
                            "lat": 55.740058436708274,
                            "lon": 61.415401407642065
                        },
                        {
                            "lat": 55.73648167116396,
                            "lon": 61.41586888499177
                        },
                        {
                            "lat": 55.732904782773254,
                            "lon": 61.41633627042352
                        },
                        {
                            "lat": 55.73192955336324,
                            "lon": 61.414622264279515
                        },
                        {
                            "lat": 55.72835271933715,
                            "lon": 61.41508952467311
                        },
                        {
                            "lat": 55.72737778990871,
                            "lon": 61.413375475929264
                        },
                        {
                            "lat": 55.72640298324777,
                            "lon": 61.41166141770106
                        },
                        {
                            "lat": 55.72282638080659,
                            "lon": 61.412128519963275
                        },
                        {
                            "lat": 55.72380101028978,
                            "lon": 61.413842611292914
                        },
                        {
                            "lat": 55.72477576252228,
                            "lon": 61.41555669314163
                        },
                        {
                            "lat": 55.725750637525366,
                            "lon": 61.41727076550789
                        },
                        {
                            "lat": 55.72672563532032,
                            "lon": 61.41898482839002
                        },
                        {
                            "lat": 55.730302946581546,
                            "lon": 61.41851759370129
                        },
                        {
                            "lat": 55.7312782444401,
                            "lon": 61.42023161398242
                        },
                        {
                            "lat": 55.734855610099174,
                            "lon": 61.41976425423682
                        },
                        {
                            "lat": 55.73843285288746,
                            "lon": 61.41929680255593
                        },
                        {
                            "lat": 55.737457200591855,
                            "lon": 61.41758284852189
                        },
                        {
                            "lat": 55.74103414330459,
                            "lon": 61.41711533804379
                        }
                    ],
                    [
                        {
                            "lat": 55.765406138036006,
                            "lon": 61.39919428551772
                        },
                        {
                            "lat": 55.76442982150849,
                            "lon": 61.397480577332715
                        },
                        {
                            "lat": 55.76085557755958,
                            "lon": 61.39794861636064
                        },
                        {
                            "lat": 55.7618317172553,
                            "lon": 61.39966235769603
                        },
                        {
                            "lat": 55.76280797981167,
                            "lon": 61.401376089517164
                        },
                        {
                            "lat": 55.766382577442236,
                            "lon": 61.40090798418501
                        },
                        {
                            "lat": 55.765406138036006,
                            "lon": 61.39919428551772
                        }
                    ],
                    [
                        {
                            "lat": 55.782634400907796,
                            "lon": 61.402461748093785
                        },
                        {
                            "lat": 55.779059941402075,
                            "lon": 61.402930287037556
                        },
                        {
                            "lat": 55.780037280157,
                            "lon": 61.404643857616364
                        },
                        {
                            "lat": 55.78361191648174,
                            "lon": 61.40417528548635
                        },
                        {
                            "lat": 55.782634400907796,
                            "lon": 61.402461748093785
                        }
                    ],
                    [
                        {
                            "lat": 55.780037280157,
                            "lon": 61.404643857616364
                        },
                        {
                            "lat": 55.776462520814086,
                            "lon": 61.40511233792693
                        },
                        {
                            "lat": 55.7774398057288,
                            "lon": 61.406825932155364
                        },
                        {
                            "lat": 55.78101474193014,
                            "lon": 61.40635741866063
                        },
                        {
                            "lat": 55.780037280157,
                            "lon": 61.404643857616364
                        }
                    ],
                    [
                        {
                            "lat": 55.81054239967475,
                            "lon": 61.38406532403887
                        },
                        {
                            "lat": 55.806970583329786,
                            "lon": 61.38453451496939
                        },
                        {
                            "lat": 55.80794870449186,
                            "lon": 61.386247829932834
                        },
                        {
                            "lat": 55.81152069729841,
                            "lon": 61.385778605788744
                        },
                        {
                            "lat": 55.81054239967475,
                            "lon": 61.38406532403887
                        }
                    ]
                ]
            },
            {
                "type": "MultiPolygon",
                "value": 150.0,
                "data": [
                    [
                        {
                            "lat": 55.33948480180164,
                            "lon": 61.4063911546536
                        },
                        {
                            "lat": 55.34306809585544,
                            "lon": 61.40593415782093
                        },
                        {
                            "lat": 55.342114671529465,
                            "lon": 61.40421656679275
                        },
                        {
                            "lat": 55.345697666613276,
                            "lon": 61.40375951004512
                        },
                        {
                            "lat": 55.34474418390387,
                            "lon": 61.40204194236871
                        },
                        {
                            "lat": 55.34379082146568,
                            "lon": 61.400324365595736
                        },
                        {
                            "lat": 55.34020818367721,
                            "lon": 61.40078135745378
                        },
                        {
                            "lat": 55.336625425657004,
                            "lon": 61.4012382569638
                        },
                        {
                            "lat": 55.33304254742364,
                            "lon": 61.401695064119586
                        },
                        {
                            "lat": 55.332089841157895,
                            "lon": 61.39997738094933
                        },
                        {
                            "lat": 55.335672540729654,
                            "lon": 61.39952060622201
                        },
                        {
                            "lat": 55.334719775994564,
                            "lon": 61.397802946393554
                        },
                        {
                            "lat": 55.338302176751895,
                            "lon": 61.397346111748305
                        },
                        {
                            "lat": 55.33734935358459,
                            "lon": 61.39562847526198
                        },
                        {
                            "lat": 55.33376713143085,
                            "lon": 61.39608527747996
                        },
                        {
                            "lat": 55.33281460701762,
                            "lon": 61.39436759948271
                        },
                        {
                            "lat": 55.32923244331928,
                            "lon": 61.394824276944966
                        },
                        {
                            "lat": 55.32565015950993,
                            "lon": 61.39528086206923
                        },
                        {
                            "lat": 55.3220677556081,
                            "lon": 61.39573735484938
                        },
                        {
                            "lat": 55.32301974414098,
                            "lon": 61.397455130084644
                        },
                        {
                            "lat": 55.32397185276862,
                            "lon": 61.39917289624651
                        },
                        {
                            "lat": 55.32492408151191,
                            "lon": 61.400890653333406
                        },
                        {
                            "lat": 55.325876430391716,
                            "lon": 61.40260840134378
                        },
                        {
                            "lat": 55.326828899428946,
                            "lon": 61.404326140276126
                        },
                        {
                            "lat": 55.3277814886445,
                            "lon": 61.4060438701289
                        },
                        {
                            "lat": 55.32873419805926,
                            "lon": 61.40776159090052
                        },
                        {
                            "lat": 55.33231785288496,
                            "lon": 61.40730487119956
                        },
                        {
                            "lat": 55.33590138747194,
                            "lon": 61.40684805911518
                        },
                        {
                            "lat": 55.33948480180164,
                            "lon": 61.4063911546536
                        }
                    ],
                    [
                        {
                            "lat": 55.33922194075185,
                            "lon": 61.426655919592456
                        },
                        {
                            "lat": 55.34017609418909,
                            "lon": 61.42837353127011
                        },
                        {
                            "lat": 55.34113036809768,
                            "lon": 61.430091133846545
                        },
                        {
                            "lat": 55.34208476249858,
                            "lon": 61.43180872732026
                        },
                        {
                            "lat": 55.34303927741276,
                            "lon": 61.433526311689604
                        },
                        {
                            "lat": 55.343993912861194,
                            "lon": 61.43524388695313
                        },
                        {
                            "lat": 55.34494866886487,
                            "lon": 61.43696145310926
                        },
                        {
                            "lat": 55.34590354544477,
                            "lon": 61.43867901015641
                        },
                        {
                            "lat": 55.34685854262189,
                            "lon": 61.440396558093084
                        },
                        {
                            "lat": 55.3478136604172,
                            "lon": 61.44211409691769
                        },
                        {
                            "lat": 55.34876889885172,
                            "lon": 61.443831626628715
                        },
                        {
                            "lat": 55.34972425794646,
                            "lon": 61.44554914722456
                        },
                        {
                            "lat": 55.35067973772242,
                            "lon": 61.44726665870374
                        },
                        {
                            "lat": 55.35163533820062,
                            "lon": 61.44898416106462
                        },
                        {
                            "lat": 55.35259105940208,
                            "lon": 61.45070165430574
                        },
                        {
                            "lat": 55.35354690134781,
                            "lon": 61.452419138425476
                        },
                        {
                            "lat": 55.35450286405884,
                            "lon": 61.454136613422335
                        },
                        {
                            "lat": 55.35545894755621,
                            "lon": 61.45585407929472
                        },
                        {
                            "lat": 55.35641515186095,
                            "lon": 61.45757153604109
                        },
                        {
                            "lat": 55.35737147699413,
                            "lon": 61.45928898365987
                        },
                        {
                            "lat": 55.36096050325014,
                            "lon": 61.4588312895534
                        },
                        {
                            "lat": 55.36454940863803,
                            "lon": 61.45837350289506
                        },
                        {
                            "lat": 55.36813819313915,
                            "lon": 61.457915623690994
                        },
                        {
                            "lat": 55.37172685673483,
                            "lon": 61.457457651947465
                        },
                        {
                            "lat": 55.37531539940643,
                            "lon": 61.45699958767065
                        },
                        {
                            "lat": 55.37627274186129,
                            "lon": 61.458716863439065
                        },
                        {
                            "lat": 55.37986134282142,
                            "lon": 61.45825867407409
                        },
                        {
                            "lat": 55.38081898548995,
                            "lon": 61.45997590813021
                        },
                        {
                            "lat": 55.38177674916196,
                            "lon": 61.46169313303361
                        },
                        {
                            "lat": 55.382734633858504,
                            "lon": 61.463410348782745
                        },
                        {
                            "lat": 55.386323651578635,
                            "lon": 61.46295196917062
                        },
                        {
                            "lat": 55.38728183660557,
                            "lon": 61.46466914318349
                        },
                        {
                            "lat": 55.39087091252507,
                            "lon": 61.46421063844825
                        },
                        {
                            "lat": 55.39182939791965,
                            "lon": 61.465927770710884
                        },
                        {
                            "lat": 55.39541853199634,
                            "lon": 61.46546914084407
                        },
                        {
                            "lat": 55.39900754492931,
                            "lon": 61.4650104184414
                        },
                        {
                            "lat": 55.39996650998754,
                            "lon": 61.46672747633566
                        },
                        {
                            "lat": 55.403555580995764,
                            "lon": 61.46626862879361
                        },
                        {
                            "lat": 55.4045148464937,
                            "lon": 61.46798564490053
                        },
                        {
                            "lat": 55.40092559622923,
                            "lon": 61.46844452505571
                        },
                        {
                            "lat": 55.397336224760174,
                            "lon": 61.468903312669994
                        },
                        {
                            "lat": 55.39637731779584,
                            "lon": 61.46718623134242
                        },
                        {
                            "lat": 55.39278800443933,
                            "lon": 61.46764489380769
                        },
                        {
                            "lat": 55.38919856993661,
                            "lon": 61.46810346372523
                        },
                        {
                            "lat": 55.38824014271787,
                            "lon": 61.46638630803554
                        },
                        {
                            "lat": 55.38465076640963,
                            "lon": 61.46684475281191
                        },
                        {
                            "lat": 55.38106126901356,
                            "lon": 61.467303105033714
                        },
                        {
                            "lat": 55.37747165054834,
                            "lon": 61.46776136469474
                        },
                        {
                            "lat": 55.37651388240945,
                            "lon": 61.46604410210806
                        },
                        {
                            "lat": 55.38010332152911,
                            "lon": 61.46558587501963
                        },
                        {
                            "lat": 55.37914549509273,
                            "lon": 61.46386863585156
                        },
                        {
                            "lat": 55.37818778968336,
                            "lon": 61.46215138753109
                        },
                        {
                            "lat": 55.37459870919886,
                            "lon": 61.46260954948462
                        },
                        {
                            "lat": 55.371009507727074,
                            "lon": 61.46306761888801
                        },
                        {
                            "lat": 55.367420185286676,
                            "lon": 61.46352559573509
                        },
                        {
                            "lat": 55.36383074189632,
                            "lon": 61.46398348001961
                        },
                        {
                            "lat": 55.36024117757464,
                            "lon": 61.46444127173533
                        },
                        {
                            "lat": 55.36119798623203,
                            "lon": 61.46615868282834
                        },
                        {
                            "lat": 55.36215491582307,
                            "lon": 61.467876084786
                        },
                        {
                            "lat": 55.363111966368926,
                            "lon": 61.46959347760677
                        },
                        {
                            "lat": 55.364069137890624,
                            "lon": 61.47131086128909
                        },
                        {
                            "lat": 55.365026430409245,
                            "lon": 61.47302823583139
                        },
                        {
                            "lat": 55.36598384394589,
                            "lon": 61.474745601232165
                        },
                        {
                            "lat": 55.36694137852163,
                            "lon": 61.47646295748974
                        },
                        {
                            "lat": 55.367899034157595,
                            "lon": 61.47818030460272
                        },
                        {
                            "lat": 55.371490034142425,
                            "lon": 61.47772225244525
                        },
                        {
                            "lat": 55.372447990401625,
                            "lon": 61.47943955784134
                        },
                        {
                            "lat": 55.368856810874846,
                            "lon": 61.479897642569426
                        },
                        {
                            "lat": 55.3698147086945,
                            "lon": 61.48161497138836
                        },
                        {
                            "lat": 55.37077272763768,
                            "lon": 61.48333229105793
                        },
                        {
                            "lat": 55.37173086772549,
                            "lon": 61.485049601576605
                        },
                        {
                            "lat": 55.37268912897904,
                            "lon": 61.4867669029428
                        },
                        {
                            "lat": 55.37364751141948,
                            "lon": 61.48848419515498
                        },
                        {
                            "lat": 55.37460601506791,
                            "lon": 61.49020147821158
                        },
                        {
                            "lat": 55.37556463994548,
                            "lon": 61.49191875211101
                        },
                        {
                            "lat": 55.37652338607333,
                            "lon": 61.49363601685174
                        },
                        {
                            "lat": 55.377482253472586,
                            "lon": 61.49535327243219
                        },
                        {
                            "lat": 55.378441242164406,
                            "lon": 61.497070518850826
                        },
                        {
                            "lat": 55.379400352169945,
                            "lon": 61.498787756106054
                        },
                        {
                            "lat": 55.38299350789192,
                            "lon": 61.49832931287546
                        },
                        {
                            "lat": 55.386586542260275,
                            "lon": 61.49787077696356
                        },
                        {
                            "lat": 55.38754613311218,
                            "lon": 61.499587939824835
                        },
                        {
                            "lat": 55.39113922583225,
                            "lon": 61.49912927861491
                        },
                        {
                            "lat": 55.39209911782031,
                            "lon": 61.500846399676455
                        },
                        {
                            "lat": 55.393059131241685,
                            "lon": 61.5025635115596
                        },
                        {
                            "lat": 55.39401926611759,
                            "lon": 61.504280614262846
                        },
                        {
                            "lat": 55.39761277664616,
                            "lon": 61.503821762472136
                        },
                        {
                            "lat": 55.39665246201133,
                            "lon": 61.50210469240778
                        },
                        {
                            "lat": 55.40024567130776,
                            "lon": 61.50164578057643
                        },
                        {
                            "lat": 55.39928529842711,
                            "lon": 61.499928733969746
                        },
                        {
                            "lat": 55.40287820653377,
                            "lon": 61.49946976211184
                        },
                        {
                            "lat": 55.40647099315101,
                            "lon": 61.49901069759253
                        },
                        {
                            "lat": 55.405510382403946,
                            "lon": 61.49729370709279
                        },
                        {
                            "lat": 55.40910286787627,
                            "lon": 61.496834582572866
                        },
                        {
                            "lat": 55.40814219899801,
                            "lon": 61.49511761553369
                        },
                        {
                            "lat": 55.41173438336779,
                            "lon": 61.494658431027155
                        },
                        {
                            "lat": 55.415326446234474,
                            "lon": 61.49419915388922
                        },
                        {
                            "lat": 55.41891838757939,
                            "lon": 61.493739784126085
                        },
                        {
                            "lat": 55.422510207383894,
                            "lon": 61.493280321744
                        },
                        {
                            "lat": 55.42610190562932,
                            "lon": 61.492820766749226
                        },
                        {
                            "lat": 55.429693482297004,
                            "lon": 61.49236111914798
                        },
                        {
                            "lat": 55.43065522843914,
                            "lon": 61.49407789016257
                        },
                        {
                            "lat": 55.434246862980274,
                            "lon": 61.4936181172707
                        },
                        {
                            "lat": 55.437838375885164,
                            "lon": 61.49315825177921
                        },
                        {
                            "lat": 55.44142976713521,
                            "lon": 61.492698293694374
                        },
                        {
                            "lat": 55.44046748264721,
                            "lon": 61.49098162076795
                        },
                        {
                            "lat": 55.436876270824584,
                            "lon": 61.491441546151115
                        },
                        {
                            "lat": 55.43591428739766,
                            "lon": 61.489724831303164
                        },
                        {
                            "lat": 55.4349524255832,
                            "lon": 61.48800810723696
                        },
                        {
                            "lat": 55.43399068536004,
                            "lon": 61.4862913739541
                        },
                        {
                            "lat": 55.43758135901217,
                            "lon": 61.48583154665536
                        },
                        {
                            "lat": 55.4366195610062,
                            "lon": 61.484114836845286
                        },
                        {
                            "lat": 55.440209933737876,
                            "lon": 61.48365494967545
                        },
                        {
                            "lat": 55.43924807798634,
                            "lon": 61.48193826333853
                        },
                        {
                            "lat": 55.44283814983994,
                            "lon": 61.481478316311645
                        },
                        {
                            "lat": 55.446428100109955,
                            "lon": 61.48101827674385
                        },
                        {
                            "lat": 55.44546600739793,
                            "lon": 61.47930164657831
                        },
                        {
                            "lat": 55.44905565683479,
                            "lon": 61.47884154717935
                        },
                        {
                            "lat": 55.44809350649144,
                            "lon": 61.47712494048977
                        },
                        {
                            "lat": 55.44713147772656,
                            "lon": 61.47540832457421
                        },
                        {
                            "lat": 55.450720647199994,
                            "lon": 61.474948198060396
                        },
                        {
                            "lat": 55.45430969509758,
                            "lon": 61.47448797904123
                        },
                        {
                            "lat": 55.455272082186475,
                            "lon": 61.476204529546635
                        },
                        {
                            "lat": 55.456234590891,
                            "lon": 61.477921070819214
                        },
                        {
                            "lat": 55.45982387547319,
                            "lon": 61.47746069387048
                        },
                        {
                            "lat": 55.46078668498194,
                            "lon": 61.4791771931883
                        },
                        {
                            "lat": 55.461749616167275,
                            "lon": 61.48089368326663
                        },
                        {
                            "lat": 55.45815997323169,
                            "lon": 61.48135412565948
                        },
                        {
                            "lat": 55.45912284691021,
                            "lon": 61.483070639224046
                        },
                        {
                            "lat": 55.462712669050404,
                            "lon": 61.482610164103995
                        },
                        {
                            "lat": 55.46367584365249,
                            "lon": 61.48432663569875
                        },
                        {
                            "lat": 55.464639139994745,
                            "lon": 61.48604309804937
                        },
                        {
                            "lat": 55.46822919883852,
                            "lon": 61.48558246493244
                        },
                        {
                            "lat": 55.46919279618132,
                            "lon": 61.48729888529424
                        },
                        {
                            "lat": 55.47278291246305,
                            "lon": 61.48683812690269
                        },
                        {
                            "lat": 55.4718191359023,
                            "lon": 61.48512173928967
                        },
                        {
                            "lat": 55.47540895116752,
                            "lon": 61.484660921127244
                        },
                        {
                            "lat": 55.4789986446156,
                            "lon": 61.48420001045148
                        },
                        {
                            "lat": 55.47996277954862,
                            "lon": 61.48591633255021
                        },
                        {
                            "lat": 55.476372906925015,
                            "lon": 61.48637727598593
                        },
                        {
                            "lat": 55.47733698452088,
                            "lon": 61.488093621587005
                        },
                        {
                            "lat": 55.480927036338585,
                            "lon": 61.48763264538788
                        },
                        {
                            "lat": 55.48451696627811,
                            "lon": 61.487171576670455
                        },
                        {
                            "lat": 55.4854815241377,
                            "lon": 61.48888784747309
                        },
                        {
                            "lat": 55.4890715113507,
                            "lon": 61.48842665346556
                        },
                        {
                            "lat": 55.49003637031706,
                            "lon": 61.49014288221907
                        },
                        {
                            "lat": 55.493626414761074,
                            "lon": 61.489681562913056
                        },
                        {
                            "lat": 55.49459157487135,
                            "lon": 61.49139774960339
                        },
                        {
                            "lat": 55.49818167650388,
                            "lon": 61.490936304990505
                        },
                        {
                            "lat": 55.49914713779522,
                            "lon": 61.49265244960359
                        },
                        {
                            "lat": 55.50273729657378,
                            "lon": 61.49219087967545
                        },
                        {
                            "lat": 55.503703059083314,
                            "lon": 61.493906982197224
                        },
                        {
                            "lat": 55.507293274965384,
                            "lon": 61.49344528694546
                        },
                        {
                            "lat": 55.50825933873025,
                            "lon": 61.49516134736183
                        },
                        {
                            "lat": 55.50922552461219,
                            "lon": 61.496877398485445
                        },
                        {
                            "lat": 55.51281597673062,
                            "lon": 61.496415545074974
                        },
                        {
                            "lat": 55.51378246394488,
                            "lon": 61.49813155407412
                        },
                        {
                            "lat": 55.510191832632486,
                            "lon": 61.49859344031473
                        },
                        {
                            "lat": 55.50660107916049,
                            "lon": 61.499055234029775
                        },
                        {
                            "lat": 55.503010203547426,
                            "lon": 61.49951693521297
                        },
                        {
                            "lat": 55.49941920581187,
                            "lon": 61.49997854385808
                        },
                        {
                            "lat": 55.498453435501006,
                            "lon": 61.498262403572
                        },
                        {
                            "lat": 55.49486249494058,
                            "lon": 61.498723886864994
                        },
                        {
                            "lat": 55.4938970259728,
                            "lon": 61.497007704490635
                        },
                        {
                            "lat": 55.49030614263002,
                            "lon": 61.49746906243992
                        },
                        {
                            "lat": 55.48671513726302,
                            "lon": 61.49793032784351
                        },
                        {
                            "lat": 55.485750148885536,
                            "lon": 61.49621407060532
                        },
                        {
                            "lat": 55.48215920081856,
                            "lon": 61.49667521067292
                        },
                        {
                            "lat": 55.48119451371245,
                            "lon": 61.494958911383584
                        },
                        {
                            "lat": 55.4847852824924,
                            "lon": 61.49449780409656
                        },
                        {
                            "lat": 55.483820538062375,
                            "lon": 61.49278152831886
                        },
                        {
                            "lat": 55.48022994855088,
                            "lon": 61.49324260282877
                        },
                        {
                            "lat": 55.4766392371161,
                            "lon": 61.49370358479726
                        },
                        {
                            "lat": 55.47304840377663,
                            "lon": 61.49416447421809
                        },
                        {
                            "lat": 55.472084319101754,
                            "lon": 61.49244809086877
                        },
                        {
                            "lat": 55.46849354318975,
                            "lon": 61.492908854978815
                        },
                        {
                            "lat": 55.469457448551054,
                            "lon": 61.49462527108502
                        },
                        {
                            "lat": 55.465866371458034,
                            "lon": 61.49508597539178
                        },
                        {
                            "lat": 55.46490264543149,
                            "lon": 61.49336952653431
                        },
                        {
                            "lat": 55.461311625845596,
                            "lon": 61.49383010552897
                        },
                        {
                            "lat": 55.4603482009841,
                            "lon": 61.492113614680655
                        },
                        {
                            "lat": 55.45675723894777,
                            "lon": 61.49257406837166
                        },
                        {
                            "lat": 55.457720484450654,
                            "lon": 61.49429059195656
                        },
                        {
                            "lat": 55.45412922126528,
                            "lon": 61.49475098581083
                        },
                        {
                            "lat": 55.45316615514226,
                            "lon": 61.49303442949495
                        },
                        {
                            "lat": 55.44957494958617,
                            "lon": 61.493494698044266
                        },
                        {
                            "lat": 55.4459836222981,
                            "lon": 61.49395487401335
                        },
                        {
                            "lat": 55.446946329597786,
                            "lon": 61.4956714957744
                        },
                        {
                            "lat": 55.4433547011529,
                            "lon": 61.49613161187119
                        },
                        {
                            "lat": 55.43976295099211,
                            "lon": 61.49659163536966
                        },
                        {
                            "lat": 55.44072542108088,
                            "lon": 61.49830831332884
                        },
                        {
                            "lat": 55.437133369718275,
                            "lon": 61.49876827692907
                        },
                        {
                            "lat": 55.438095782002335,
                            "lon": 61.50048497837194
                        },
                        {
                            "lat": 55.43450342939547,
                            "lon": 61.50094488205987
                        },
                        {
                            "lat": 55.43546578383752,
                            "lon": 61.50266160698608
                        },
                        {
                            "lat": 55.43642826000317,
                            "lon": 61.504378322689895
                        },
                        {
                            "lat": 55.432835426506685,
                            "lon": 61.50483819915694
                        },
                        {
                            "lat": 55.42924247128404,
                            "lon": 61.50529798297816
                        },
                        {
                            "lat": 55.428280354345496,
                            "lon": 61.50358120186916
                        },
                        {
                            "lat": 55.42468745706071,
                            "lon": 61.50404086034419
                        },
                        {
                            "lat": 55.42109443810833,
                            "lon": 61.504500426166516
                        },
                        {
                            "lat": 55.42205619573489,
                            "lon": 61.50621727265825
                        },
                        {
                            "lat": 55.41846287544576,
                            "lon": 61.5066767785046
                        },
                        {
                            "lat": 55.414869433505146,
                            "lon": 61.50713619168014
                        },
                        {
                            "lat": 55.41583095336767,
                            "lon": 61.508853094328735
                        },
                        {
                            "lat": 55.41223721004534,
                            "lon": 61.50931244750236
                        },
                        {
                            "lat": 55.41319867179426,
                            "lon": 61.51102937362458
                        },
                        {
                            "lat": 55.409604627047806,
                            "lon": 61.511488666782164
                        },
                        {
                            "lat": 55.40601046066351,
                            "lon": 61.51194786723899
                        },
                        {
                            "lat": 55.40697168443266,
                            "lon": 61.513664849505226
                        },
                        {
                            "lat": 55.40793302984214,
                            "lon": 61.51538182257371
                        },
                        {
                            "lat": 55.40889449691315,
                            "lon": 61.517098786442844
                        },
                        {
                            "lat": 55.41248920283982,
                            "lon": 61.516639487963545
                        },
                        {
                            "lat": 55.41152755590262,
                            "lon": 61.514922556772014
                        },
                        {
                            "lat": 55.41512196028285,
                            "lon": 61.51446319825829
                        },
                        {
                            "lat": 55.418716242964116,
                            "lon": 61.514003747038764
                        },
                        {
                            "lat": 55.419678249569955,
                            "lon": 61.51572061285823
                        },
                        {
                            "lat": 55.42327259033603,
                            "lon": 61.515261036244674
                        },
                        {
                            "lat": 55.42686680934451,
                            "lon": 61.514801366932176
                        },
                        {
                            "lat": 55.43046090657668,
                            "lon": 61.51434160492702
                        },
                        {
                            "lat": 55.43142357432123,
                            "lon": 61.51605836342467
                        },
                        {
                            "lat": 55.43238636388202,
                            "lon": 61.51777511269923
                        },
                        {
                            "lat": 55.43598069885281,
                            "lon": 61.51731519257463
                        },
                        {
                            "lat": 55.43957491196742,
                            "lon": 61.51685517975857
                        },
                        {
                            "lat": 55.44053818293407,
                            "lon": 61.51857185435951
                        },
                        {
                            "lat": 55.43694379004587,
                            "lon": 61.51903189990285
                        },
                        {
                            "lat": 55.43334927528028,
                            "lon": 61.519491852749155
                        },
                        {
                            "lat": 55.43431230853727,
                            "lon": 61.521208583572815
                        },
                        {
                            "lat": 55.43527546367426,
                            "lon": 61.52292530516866
                        },
                        {
                            "lat": 55.431680467361666,
                            "lon": 61.52338523075405
                        },
                        {
                            "lat": 55.43264356452773,
                            "lon": 61.525101975846866
                        },
                        {
                            "lat": 55.42904826643916,
                            "lon": 61.525561841432186
                        },
                        {
                            "lat": 55.43001130559688,
                            "lon": 61.52727861002165
                        },
                        {
                            "lat": 55.42641570568979,
                            "lon": 61.52773841559279
                        },
                        {
                            "lat": 55.422819983895025,
                            "lon": 61.52819812841935
                        },
                        {
                            "lat": 55.41922414023131,
                            "lon": 61.52865774849507
                        },
                        {
                            "lat": 55.41562817471735,
                            "lon": 61.52911727581367
                        },
                        {
                            "lat": 55.41659061584534,
                            "lon": 61.530834166031624
                        },
                        {
                            "lat": 55.41299434846268,
                            "lon": 61.53129363328634
                        },
                        {
                            "lat": 55.409397959246014,
                            "lon": 61.53175300776578
                        },
                        {
                            "lat": 55.41036016209361,
                            "lon": 61.533469954170414
                        },
                        {
                            "lat": 55.41132248677778,
                            "lon": 61.53518689136691
                        },
                        {
                            "lat": 55.41491923616759,
                            "lon": 61.53472745148951
                        },
                        {
                            "lat": 55.415881862824264,
                            "lon": 61.53644434677211
                        },
                        {
                            "lat": 55.41947867043094,
                            "lon": 61.53598478139842
                        },
                        {
                            "lat": 55.420441599097586,
                            "lon": 61.53770163475306
                        },
                        {
                            "lat": 55.42140464970213,
                            "lon": 61.53941847888794
                        },
                        {
                            "lat": 55.41780748185239,
                            "lon": 61.53987810969152
                        },
                        {
                            "lat": 55.41877047426644,
                            "lon": 61.541594977325175
                        },
                        {
                            "lat": 55.41517300430536,
                            "lon": 61.542054548039744
                        },
                        {
                            "lat": 55.411575412401305,
                            "lon": 61.54251402593902
                        },
                        {
                            "lat": 55.41253816637638,
                            "lon": 61.54423094978338
                        },
                        {
                            "lat": 55.41613593849141,
                            "lon": 61.54377143917184
                        },
                        {
                            "lat": 55.41973358864219,
                            "lon": 61.543311835739345
                        },
                        {
                            "lat": 55.42333111680996,
                            "lon": 61.54285213949214
                        },
                        {
                            "lat": 55.42692852297599,
                            "lon": 61.54239235043646
                        },
                        {
                            "lat": 55.42596504828475,
                            "lon": 61.540675557475005
                        },
                        {
                            "lat": 55.42956215230448,
                            "lon": 61.54021570835198
                        },
                        {
                            "lat": 55.433159134306266,
                            "lon": 61.53975576643865
                        },
                        {
                            "lat": 55.43219542142139,
                            "lon": 61.53803902971923
                        },
                        {
                            "lat": 55.43579210132205,
                            "lon": 61.53757902776452
                        },
                        {
                            "lat": 55.43482833040683,
                            "lon": 61.535862314552645
                        },
                        {
                            "lat": 55.43842470824896,
                            "lon": 61.53540225257065
                        },
                        {
                            "lat": 55.43746087934082,
                            "lon": 61.53368556286662
                        },
                        {
                            "lat": 55.4364971724429,
                            "lon": 61.53196886392717
                        },
                        {
                            "lat": 55.44009306830336,
                            "lon": 61.53150877467553
                        },
                        {
                            "lat": 55.44368884215621,
                            "lon": 61.53104859268121
                        },
                        {
                            "lat": 55.44465290896425,
                            "lon": 61.53276522612788
                        },
                        {
                            "lat": 55.441056955167,
                            "lon": 61.53322544087141
                        },
                        {
                            "lat": 55.442020964059566,
                            "lon": 61.53494209782844
                        },
                        {
                            "lat": 55.44561709781993,
                            "lon": 61.53448185033227
                        },
                        {
                            "lat": 55.44921310951135,
                            "lon": 61.53402151008838
                        },
                        {
                            "lat": 55.44824874071384,
                            "lon": 61.53230491864227
                        },
                        {
                            "lat": 55.45184445039709,
                            "lon": 61.53184451842089
                        },
                        {
                            "lat": 55.45088002376412,
                            "lon": 61.53012795048949
                        },
                        {
                            "lat": 55.454475431481804,
                            "lon": 61.52966749030466
                        },
                        {
                            "lat": 55.458070717117046,
                            "lon": 61.52920693740214
                        },
                        {
                            "lat": 55.457106052845454,
                            "lon": 61.52749042575409
                        },
                        {
                            "lat": 55.46070103656033,
                            "lon": 61.52702981291407
                        },
                        {
                            "lat": 55.459736314568026,
                            "lon": 61.525313324783546
                        },
                        {
                            "lat": 55.46333099640513,
                            "lon": 61.524852652020165
                        },
                        {
                            "lat": 55.466925556146364,
                            "lon": 61.52439188656915
                        },
                        {
                            "lat": 55.4659605967314,
                            "lon": 61.522675454734724
                        },
                        {
                            "lat": 55.46955485464008,
                            "lon": 61.522214629386255
                        },
                        {
                            "lat": 55.46858983761905,
                            "lon": 61.52049822107219
                        },
                        {
                            "lat": 55.47218379373777,
                            "lon": 61.520037335840314
                        },
                        {
                            "lat": 55.47577762774722,
                            "lon": 61.51957635795091
                        },
                        {
                            "lat": 55.476743004103014,
                            "lon": 61.521292700687276
                        },
                        {
                            "lat": 55.47314899043684,
                            "lon": 61.52175371136834
                        },
                        {
                            "lat": 55.47411430926649,
                            "lon": 61.52347007762943
                        },
                        {
                            "lat": 55.47770850260807,
                            "lon": 61.52300903415325
                        },
                        {
                            "lat": 55.478674123283646,
                            "lon": 61.52472535834721
                        },
                        {
                            "lat": 55.475079750248014,
                            "lon": 61.52518643462194
                        },
                        {
                            "lat": 55.476045313402714,
                            "lon": 61.526902782344344
                        },
                        {
                            "lat": 55.47701099875191,
                            "lon": 61.528619120795014
                        },
                        {
                            "lat": 55.480605731231606,
                            "lon": 61.528157978912816
                        },
                        {
                            "lat": 55.481571718546604,
                            "lon": 61.52987427528128
                        },
                        {
                            "lat": 55.482537828117415,
                            "lon": 61.531590562371406
                        },
                        {
                            "lat": 55.486132797838685,
                            "lon": 61.531129262177345
                        },
                        {
                            "lat": 55.48972764526418,
                            "lon": 61.53066786929898
                        },
                        {
                            "lat": 55.49332237037527,
                            "lon": 61.53020638374251
                        },
                        {
                            "lat": 55.49691697315327,
                            "lon": 61.5297448055143
                        },
                        {
                            "lat": 55.495950144721036,
                            "lon": 61.528028649729734
                        },
                        {
                            "lat": 55.499544445485284,
                            "lon": 61.52756701167642
                        },
                        {
                            "lat": 55.50313862390051,
                            "lon": 61.52710528096952
                        },
                        {
                            "lat": 55.506732679948065,
                            "lon": 61.52664345761523
                        },
                        {
                            "lat": 55.51032661360935,
                            "lon": 61.52618154161992
                        },
                        {
                            "lat": 55.51392042486572,
                            "lon": 61.52571953298982
                        },
                        {
                            "lat": 55.51488815139466,
                            "lon": 61.52743552451607
                        },
                        {
                            "lat": 55.518482019782816,
                            "lon": 61.526973390388996
                        },
                        {
                            "lat": 55.51945004833058,
                            "lon": 61.5286893397323
                        },
                        {
                            "lat": 55.51585600036851,
                            "lon": 61.52915150673133
                        },
                        {
                            "lat": 55.51682397180856,
                            "lon": 61.53086747963404
                        },
                        {
                            "lat": 55.51322962176636,
                            "lon": 61.53132958686315
                        },
                        {
                            "lat": 55.51419753606147,
                            "lon": 61.533045583324956
                        },
                        {
                            "lat": 55.51060288389642,
                            "lon": 61.53350763077009
                        },
                        {
                            "lat": 55.51157074100933,
                            "lon": 61.53522365079068
                        },
                        {
                            "lat": 55.51516557284691,
                            "lon": 61.53476157047444
                        },
                        {
                            "lat": 55.51613373214401,
                            "lon": 61.536477548310074
                        },
                        {
                            "lat": 55.5197286211397,
                            "lon": 61.53601534245084
                        },
                        {
                            "lat": 55.5233233875837,
                            "lon": 61.535553043929475
                        },
                        {
                            "lat": 55.524292028769324,
                            "lon": 61.53726894667677
                        },
                        {
                            "lat": 55.527886852288674,
                            "lon": 61.53680652260468
                        },
                        {
                            "lat": 55.52885579573027,
                            "lon": 61.5385223831297
                        },
                        {
                            "lat": 55.53245067628221,
                            "lon": 61.53805983349852
                        },
                        {
                            "lat": 55.53604543418384,
                            "lon": 61.53759719121281
                        },
                        {
                            "lat": 55.537014859558795,
                            "lon": 61.53931297658848
                        },
                        {
                            "lat": 55.53341992201703,
                            "lon": 61.53977565178718
                        },
                        {
                            "lat": 55.52982486180357,
                            "lon": 61.54023823432571
                        },
                        {
                            "lat": 55.52622967893701,
                            "lon": 61.540700724197855
                        },
                        {
                            "lat": 55.52526079254662,
                            "lon": 61.538984840100106
                        },
                        {
                            "lat": 55.52166566674991,
                            "lon": 61.53944720440339
                        },
                        {
                            "lat": 55.52069708265828,
                            "lon": 61.53773127808737
                        },
                        {
                            "lat": 55.51710201397419,
                            "lon": 61.53819351683019
                        },
                        {
                            "lat": 55.51807041835877,
                            "lon": 61.53990947603329
                        },
                        {
                            "lat": 55.514475047391805,
                            "lon": 61.540371654983474
                        },
                        {
                            "lat": 55.510879553867696,
                            "lon": 61.540833741247724
                        },
                        {
                            "lat": 55.50728393780504,
                            "lon": 61.5412957348197
                        },
                        {
                            "lat": 55.50368819922253,
                            "lon": 61.54175763569314
                        },
                        {
                            "lat": 55.50465600706637,
                            "lon": 61.5434737170835
                        },
                        {
                            "lat": 55.50562393743278,
                            "lon": 61.54518978916743
                        },
                        {
                            "lat": 55.502027716665225,
                            "lon": 61.54565166306435
                        },
                        {
                            "lat": 55.501059966150066,
                            "lon": 61.54393555811454
                        },
                        {
                            "lat": 55.497463802729854,
                            "lon": 61.544397306428856
                        },
                        {
                            "lat": 55.49386751682439,
                            "lon": 61.544858962020136
                        },
                        {
                            "lat": 55.490271108452326,
                            "lon": 61.545320524882136
                        },
                        {
                            "lat": 55.486674577632364,
                            "lon": 61.545781995008525
                        },
                        {
                            "lat": 55.48570766916516,
                            "lon": 61.54406574936219
                        },
                        {
                            "lat": 55.48211119585541,
                            "lon": 61.54452709391238
                        },
                        {
                            "lat": 55.4811445897168,
                            "lon": 61.54281080614761
                        },
                        {
                            "lat": 55.48474088310583,
                            "lon": 61.54234949442829
                        },
                        {
                            "lat": 55.48377421943297,
                            "lon": 61.54063323020841
                        },
                        {
                            "lat": 55.480178105945996,
                            "lon": 61.5410945091003
                        },
                        {
                            "lat": 55.47658187011258,
                            "lon": 61.54155569526091
                        },
                        {
                            "lat": 55.47298551195137,
                            "lon": 61.54201678868397
                        },
                        {
                            "lat": 55.47201951035784,
                            "lon": 61.54030041672465
                        },
                        {
                            "lat": 55.468423209834945,
                            "lon": 61.54076138459676
                        },
                        {
                            "lat": 55.464826787043,
                            "lon": 61.541222259724414
                        },
                        {
                            "lat": 55.463861267615975,
                            "lon": 61.539505812890965
                        },
                        {
                            "lat": 55.460264902545056,
                            "lon": 61.53996656247543
                        },
                        {
                            "lat": 55.4612302420007,
                            "lon": 61.54168304210127
                        },
                        {
                            "lat": 55.45763357472672,
                            "lon": 61.54214373172112
                        },
                        {
                            "lat": 55.454036785239815,
                            "lon": 61.54260432857764
                        },
                        {
                            "lat": 55.45500188688293,
                            "lon": 61.54432086451617
                        },
                        {
                            "lat": 55.45140479514768,
                            "lon": 61.54478140138206
                        },
                        {
                            "lat": 55.45236983893356,
                            "lon": 61.546497960846224
                        },
                        {
                            "lat": 55.45333500493761,
                            "lon": 61.54821451105547
                        },
                        {
                            "lat": 55.45693245683717,
                            "lon": 61.5477539086213
                        },
                        {
                            "lat": 55.457897925191006,
                            "lon": 61.54947041678472
                        },
                        {
                            "lat": 55.458863515824426,
                            "lon": 61.55118691568663
                        },
                        {
                            "lat": 55.45526570368562,
                            "lon": 61.55164758370284
                        },
                        {
                            "lat": 55.45166776926461,
                            "lon": 61.55210815891505
                        },
                        {
                            "lat": 55.45263312188219,
                            "lon": 61.553824714140596
                        },
                        {
                            "lat": 55.453598596784616,
                            "lon": 61.55554126010835
                        },
                        {
                            "lat": 55.45000017970055,
                            "lon": 61.55600180808359
                        },
                        {
                            "lat": 55.44640164032905,
                            "lon": 61.55646226323102
                        },
                        {
                            "lat": 55.447366877060524,
                            "lon": 61.55817886551745
                        },
                        {
                            "lat": 55.450965596681264,
                            "lon": 61.55771837758381
                        },
                        {
                            "lat": 55.454564193993214,
                            "lon": 61.55725779681673
                        },
                        {
                            "lat": 55.45552991352936,
                            "lon": 61.55897432426414
                        },
                        {
                            "lat": 55.45649575541442,
                            "lon": 61.56069084244899
                        },
                        {
                            "lat": 55.45289679759045,
                            "lon": 61.56115148881013
                        },
                        {
                            "lat": 55.45386258156167,
                            "lon": 61.562868030533075
                        },
                        {
                            "lat": 55.450263321080655,
                            "lon": 61.563328616846
                        },
                        {
                            "lat": 55.45122904710051,
                            "lon": 61.565045182106694
                        },
                        {
                            "lat": 55.45482848790579,
                            "lon": 61.56458456299374
                        },
                        {
                            "lat": 55.45842780631676,
                            "lon": 61.564123851024775
                        },
                        {
                            "lat": 55.46202700231472,
                            "lon": 61.563663046206045
                        },
                        {
                            "lat": 55.46562607588086,
                            "lon": 61.56320214854389
                        },
                        {
                            "lat": 55.46922502699648,
                            "lon": 61.56274115804456
                        },
                        {
                            "lat": 55.47282385564284,
                            "lon": 61.562280074714344
                        },
                        {
                            "lat": 55.47642256180118,
                            "lon": 61.56181889855952
                        },
                        {
                            "lat": 55.480021145452795,
                            "lon": 61.5613576295864
                        },
                        {
                            "lat": 55.483619606578955,
                            "lon": 61.56089626780122
                        },
                        {
                            "lat": 55.487217945160914,
                            "lon": 61.5604348132103
                        },
                        {
                            "lat": 55.49081616118,
                            "lon": 61.55997326581993
                        },
                        {
                            "lat": 55.494414254617496,
                            "lon": 61.55951162563642
                        },
                        {
                            "lat": 55.498012225454666,
                            "lon": 61.559049892665996
                        },
                        {
                            "lat": 55.50161007367284,
                            "lon": 61.55858806691499
                        },
                        {
                            "lat": 55.50520779925331,
                            "lon": 61.558126148389725
                        },
                        {
                            "lat": 55.508805402177394,
                            "lon": 61.55766413709644
                        },
                        {
                            "lat": 55.5124028824264,
                            "lon": 61.55720203304141
                        },
                        {
                            "lat": 55.51600023998164,
                            "lon": 61.55673983623101
                        },
                        {
                            "lat": 55.51959747482446,
                            "lon": 61.55627754667149
                        },
                        {
                            "lat": 55.52319458693618,
                            "lon": 61.55581516436912
                        },
                        {
                            "lat": 55.52679157629813,
                            "lon": 61.55535268933025
                        },
                        {
                            "lat": 55.53038844289165,
                            "lon": 61.554890121561144
                        },
                        {
                            "lat": 55.53398518669808,
                            "lon": 61.55442746106813
                        },
                        {
                            "lat": 55.533015318632785,
                            "lon": 61.55271165163229
                        },
                        {
                            "lat": 55.5320455733518,
                            "lon": 61.55099583285817
                        },
                        {
                            "lat": 55.528449189233584,
                            "lon": 61.55145842750704
                        },
                        {
                            "lat": 55.527479746531604,
                            "lon": 61.54974256647928
                        },
                        {
                            "lat": 55.53107595083369,
                            "lon": 61.54928000474731
                        },
                        {
                            "lat": 55.53467203239428,
                            "lon": 61.54881735031454
                        },
                        {
                            "lat": 55.53826799119478,
                            "lon": 61.54835460318728
                        },
                        {
                            "lat": 55.541863827216545,
                            "lon": 61.547891763371844
                        },
                        {
                            "lat": 55.54545954044091,
                            "lon": 61.54742883087453
                        },
                        {
                            "lat": 55.54448932168845,
                            "lon": 61.5457131251384
                        },
                        {
                            "lat": 55.54808473240642,
                            "lon": 61.545250132906965
                        },
                        {
                            "lat": 55.54711445677674,
                            "lon": 61.54353445076147
                        },
                        {
                            "lat": 55.55070956503113,
                            "lon": 61.543071398810014
                        },
                        {
                            "lat": 55.55430455047512,
                            "lon": 61.5426082542068
                        },
                        {
                            "lat": 55.557899413090155,
                            "lon": 61.54214501695808
                        },
                        {
                            "lat": 55.5569287214212,
                            "lon": 61.54042942430073
                        },
                        {
                            "lat": 55.5605232816211,
                            "lon": 61.53996612736987
                        },
                        {
                            "lat": 55.56149415285755,
                            "lon": 61.54168168707024
                        },
                        {
                            "lat": 55.56508876975876,
                            "lon": 61.5412182645495
                        },
                        {
                            "lat": 55.5686832637752,
                            "lon": 61.54075474940221
                        },
                        {
                            "lat": 55.57227763488826,
                            "lon": 61.54029114163466
                        },
                        {
                            "lat": 55.57324916762064,
                            "lon": 61.54200659305692
                        },
                        {
                            "lat": 55.57684359531213,
                            "lon": 61.54154285969253
                        },
                        {
                            "lat": 55.57587188307936,
                            "lon": 61.539827441253166
                        },
                        {
                            "lat": 55.57946600832992,
                            "lon": 61.539363648264015
                        },
                        {
                            "lat": 55.57849423957731,
                            "lon": 61.53764825343272
                        },
                        {
                            "lat": 55.58208806242984,
                            "lon": 61.53718440083285
                        },
                        {
                            "lat": 55.58568176232612,
                            "lon": 61.536720455643525
                        },
                        {
                            "lat": 55.589275339247614,
                            "lon": 61.536256417871115
                        },
                        {
                            "lat": 55.592868793175754,
                            "lon": 61.53579228752184
                        },
                        {
                            "lat": 55.591896429807875,
                            "lon": 61.53407701529395
                        },
                        {
                            "lat": 55.59548958138948,
                            "lon": 61.533612825383734
                        },
                        {
                            "lat": 55.59908260996206,
                            "lon": 61.5331485429149
                        },
                        {
                            "lat": 55.598110010973876,
                            "lon": 61.531433327313685
                        },
                        {
                            "lat": 55.5971375349918,
                            "lon": 61.52971810231608
                        },
                        {
                            "lat": 55.5961651819944,
                            "lon": 61.52800286792368
                        },
                        {
                            "lat": 55.59257269125022,
                            "lon": 61.528467051367876
                        },
                        {
                            "lat": 55.588980077561295,
                            "lon": 61.52893114227025
                        },
                        {
                            "lat": 55.585387340946205,
                            "lon": 61.52939514062451
                        },
                        {
                            "lat": 55.58179448142346,
                            "lon": 61.52985904642432
                        },
                        {
                            "lat": 55.58082296847408,
                            "lon": 61.52814367066718
                        },
                        {
                            "lat": 55.57723016538698,
                            "lon": 61.52860745092737
                        },
                        {
                            "lat": 55.57363723945073,
                            "lon": 61.529071138626094
                        },
                        {
                            "lat": 55.5726662080028,
                            "lon": 61.52735568754596
                        },
                        {
                            "lat": 55.57625895461146,
                            "lon": 61.52689203281707
                        },
                        {
                            "lat": 55.579851578392386,
                            "lon": 61.526428285532326
                        },
                        {
                            "lat": 55.58344407932704,
                            "lon": 61.525964445698044
                        },
                        {
                            "lat": 55.58703645739687,
                            "lon": 61.5255005133205
                        },
                        {
                            "lat": 55.59062871258335,
                            "lon": 61.525036488406045
                        },
                        {
                            "lat": 55.59422084486795,
                            "lon": 61.524572370960946
                        },
                        {
                            "lat": 55.597812854232146,
                            "lon": 61.524108160991496
                        },
                        {
                            "lat": 55.601404740657394,
                            "lon": 61.52364385850404
                        },
                        {
                            "lat": 55.600432398145706,
                            "lon": 61.52192866194236
                        },
                        {
                            "lat": 55.60402398247573,
                            "lon": 61.521464299954296
                        },
                        {
                            "lat": 55.60761544385123,
                            "lon": 61.52099984546643
                        },
                        {
                            "lat": 55.606642866058486,
                            "lon": 61.51928470553034
                        },
                        {
                            "lat": 55.605670411217396,
                            "lon": 61.51756955619367
                        },
                        {
                            "lat": 55.60469807930654,
                            "lon": 61.51585439745796
                        },
                        {
                            "lat": 55.601107155169046,
                            "lon": 61.516318752916476
                        },
                        {
                            "lat": 55.600135125209334,
                            "lon": 61.514603551780496
                        },
                        {
                            "lat": 55.599163218118534,
                            "lon": 61.51288834125216
                        },
                        {
                            "lat": 55.595572529199686,
                            "lon": 61.51335253823653
                        },
                        {
                            "lat": 55.59460092398315,
                            "lon": 61.511637285327076
                        },
                        {
                            "lat": 55.59101029128341,
                            "lon": 61.512101356848504
                        },
                        {
                            "lat": 55.59003898790412,
                            "lon": 61.510386061572056
                        },
                        {
                            "lat": 55.58644841146622,
                            "lon": 61.51085000763886
                        },
                        {
                            "lat": 55.58547740988718,
                            "lon": 61.50913467000953
                        },
                        {
                            "lat": 55.581886889753896,
                            "lon": 61.509598490630054
                        },
                        {
                            "lat": 55.58285771227908,
                            "lon": 61.51131386122605
                        },
                        {
                            "lat": 55.58382865753501,
                            "lon": 61.51302922244833
                        },
                        {
                            "lat": 55.584799725543036,
                            "lon": 61.514744574295314
                        },
                        {
                            "lat": 55.58120854541914,
                            "lon": 61.51520840132913
                        },
                        {
                            "lat": 55.577617242540256,
                            "lon": 61.51567213585957
                        },
                        {
                            "lat": 55.57664653277813,
                            "lon": 61.5139567180822
                        },
                        {
                            "lat": 55.57305528631782,
                            "lon": 61.51442032714614
                        },
                        {
                            "lat": 55.56946391716096,
                            "lon": 61.51488384369969
                        },
                        {
                            "lat": 55.56587242532608,
                            "lon": 61.51534726773659
                        },
                        {
                            "lat": 55.5622808108317,
                            "lon": 61.515810599250486
                        },
                        {
                            "lat": 55.5586890736964,
                            "lon": 61.516273838235136
                        },
                        {
                            "lat": 55.55509721393869,
                            "lon": 61.51673698468421
                        },
                        {
                            "lat": 55.55412770203111,
                            "lon": 61.51502135990223
                        },
                        {
                            "lat": 55.55053589893424,
                            "lon": 61.515484380889674
                        },
                        {
                            "lat": 55.549566688855265,
                            "lon": 61.51376871384529
                        },
                        {
                            "lat": 55.545974942461946,
                            "lon": 61.51423160937944
                        },
                        {
                            "lat": 55.5423830735446,
                            "lon": 61.51469441237042
                        },
                        {
                            "lat": 55.5387910821218,
                            "lon": 61.51515712281184
                        },
                        {
                            "lat": 55.53519896821209,
                            "lon": 61.5156197406975
                        },
                        {
                            "lat": 55.53160673183408,
                            "lon": 61.51608226602105
                        },
                        {
                            "lat": 55.52801437300631,
                            "lon": 61.51654469877624
                        },
                        {
                            "lat": 55.527046361283546,
                            "lon": 61.51482882503119
                        },
                        {
                            "lat": 55.52607847197063,
                            "lon": 61.51311294196744
                        },
                        {
                            "lat": 55.525110705046195,
                            "lon": 61.51139704958657
                        },
                        {
                            "lat": 55.521518761862374,
                            "lon": 61.511859291156476
                        },
                        {
                            "lat": 55.52055129662634,
                            "lon": 61.51014335659675
                        },
                        {
                            "lat": 55.51695941043644,
                            "lon": 61.510605472744814
                        },
                        {
                            "lat": 55.51599224685163,
                            "lon": 61.50888949602035
                        },
                        {
                            "lat": 55.519583953717586,
                            "lon": 61.50842741272657
                        },
                        {
                            "lat": 55.52317553827756,
                            "lon": 61.50796523687986
                        },
                        {
                            "lat": 55.524143060488946,
                            "lon": 61.509681147890184
                        },
                        {
                            "lat": 55.52773470200571,
                            "lon": 61.50921884663137
                        },
                        {
                            "lat": 55.52870252586283,
                            "lon": 61.51093471545884
                        },
                        {
                            "lat": 55.529670472105735,
                            "lon": 61.51265057496734
                        },
                        {
                            "lat": 55.53326234983375,
                            "lon": 61.51218811541008
                        },
                        {
                            "lat": 55.53685410513613,
                            "lon": 61.51172556330195
                        },
                        {
                            "lat": 55.54044573799429,
                            "lon": 61.51126291864921
                        },
                        {
                            "lat": 55.544037248389664,
                            "lon": 61.510800181458166
                        },
                        {
                            "lat": 55.5476286363037,
                            "lon": 61.510337351735075
                        },
                        {
                            "lat": 55.548597601318875,
                            "lon": 61.512053037459864
                        },
                        {
                            "lat": 55.55218904594203,
                            "lon": 61.51159008230163
                        },
                        {
                            "lat": 55.55578036802542,
                            "lon": 61.51112703461833
                        },
                        {
                            "lat": 55.556749813995616,
                            "lon": 61.51284264517239
                        },
                        {
                            "lat": 55.56034119270556,
                            "lon": 61.51237947204623
                        },
                        {
                            "lat": 55.56393244881726,
                            "lon": 61.51191620640203
                        },
                        {
                            "lat": 55.56296264449867,
                            "lon": 61.51020066170171
                        },
                        {
                            "lat": 55.56199296277549,
                            "lon": 61.50848510764817
                        },
                        {
                            "lat": 55.56102340362636,
                            "lon": 61.50676954424294
                        },
                        {
                            "lat": 55.5600539670299,
                            "lon": 61.50505397148771
                        },
                        {
                            "lat": 55.55908465296483,
                            "lon": 61.50333838938402
                        },
                        {
                            "lat": 55.55549429248538,
                            "lon": 61.50380149041447
                        },
                        {
                            "lat": 55.5545252800012,
                            "lon": 61.50208586605152
                        },
                        {
                            "lat": 55.550934976122456,
                            "lon": 61.50254884168506
                        },
                        {
                            "lat": 55.54996626518211,
                            "lon": 61.500833175076984
                        },
                        {
                            "lat": 55.5463760179467,
                            "lon": 61.50129602532196
                        },
                        {
                            "lat": 55.54540760851314,
                            "lon": 61.499580316482806
                        },
                        {
                            "lat": 55.54443932147003,
                            "lon": 61.49786459831191
                        },
                        {
                            "lat": 55.5434711567961,
                            "lon": 61.496148870810885
                        },
                        {
                            "lat": 55.54250311447001,
                            "lon": 61.494433133981325
                        },
                        {
                            "lat": 55.5389134611027,
                            "lon": 61.49489576020069
                        },
                        {
                            "lat": 55.53988132438662,
                            "lon": 61.49661152990861
                        },
                        {
                            "lat": 55.536291369647806,
                            "lon": 61.49707409652538
                        },
                        {
                            "lat": 55.5327012925982,
                            "lon": 61.49753657065486
                        },
                        {
                            "lat": 55.53173378746254,
                            "lon": 61.49582073520698
                        },
                        {
                            "lat": 55.53076640459784,
                            "lon": 61.49410489044253
                        },
                        {
                            "lat": 55.52979914398282,
                            "lon": 61.492389036363036
                        },
                        {
                            "lat": 55.52883200559619,
                            "lon": 61.49067317297013
                        },
                        {
                            "lat": 55.52524252256734,
                            "lon": 61.49113542318046
                        },
                        {
                            "lat": 55.526209481903514,
                            "lon": 61.49285131942462
                        },
                        {
                            "lat": 55.52261969761438,
                            "lon": 61.493313510003176
                        },
                        {
                            "lat": 55.51902979113394,
                            "lon": 61.493775608092456
                        },
                        {
                            "lat": 55.51806318996246,
                            "lon": 61.49205964616261
                        },
                        {
                            "lat": 55.517096710942475,
                            "lon": 61.490343674931246
                        },
                        {
                            "lat": 55.51613035405268,
                            "lon": 61.48862769439995
                        },
                        {
                            "lat": 55.512540862686635,
                            "lon": 61.48908960150068
                        },
                        {
                            "lat": 55.511574806964425,
                            "lon": 61.48737357884692
                        },
                        {
                            "lat": 55.515164119271866,
                            "lon": 61.48691170457033
                        },
                        {
                            "lat": 55.51875330949157,
                            "lon": 61.48644973782057
                        },
                        {
                            "lat": 55.522342377605064,
                            "lon": 61.48598767860391
                        },
                        {
                            "lat": 55.52137590689553,
                            "lon": 61.48427174513568
                        },
                        {
                            "lat": 55.51778701777961,
                            "lon": 61.484733771520446
                        },
                        {
                            "lat": 55.51419800657873,
                            "lon": 61.485195705443964
                        },
                        {
                            "lat": 55.510608873311384,
                            "lon": 61.48565754689987
                        },
                        {
                            "lat": 55.50701961799614,
                            "lon": 61.48611929588194
                        },
                        {
                            "lat": 55.507985372587854,
                            "lon": 61.48783536064407
                        },
                        {
                            "lat": 55.504395816160674,
                            "lon": 61.488297049955534
                        },
                        {
                            "lat": 55.50080613770145,
                            "lon": 61.48875864677503
                        },
                        {
                            "lat": 55.499840741296154,
                            "lon": 61.48704251639954
                        },
                        {
                            "lat": 55.503430240651554,
                            "lon": 61.486580952383925
                        },
                        {
                            "lat": 55.50246478715321,
                            "lon": 61.484864845527476
                        },
                        {
                            "lat": 55.498875466883085,
                            "lon": 61.48532637674263
                        },
                        {
                            "lat": 55.497910314441036,
                            "lon": 61.483610227805926
                        },
                        {
                            "lat": 55.501499455644435,
                            "lon": 61.48314872938775
                        },
                        {
                            "lat": 55.50053424610398,
                            "lon": 61.48143260396633
                        },
                        {
                            "lat": 55.496945283948754,
                            "lon": 61.48189406959097
                        },
                        {
                            "lat": 55.49598037538502,
                            "lon": 61.48017790209934
                        },
                        {
                            "lat": 55.49239147035208,
                            "lon": 61.48063924246362
                        },
                        {
                            "lat": 55.493356199864984,
                            "lon": 61.48235544273975
                        },
                        {
                            "lat": 55.48976699387121,
                            "lon": 61.48281672340646
                        },
                        {
                            "lat": 55.48880244343039,
                            "lon": 61.4811004903514
                        },
                        {
                            "lat": 55.48783801485976,
                            "lon": 61.47938424802814
                        },
                        {
                            "lat": 55.48424904514259,
                            "lon": 61.479845370663305
                        },
                        {
                            "lat": 55.480659953595,
                            "lon": 61.48030640081506
                        },
                        {
                            "lat": 55.47969600500691,
                            "lon": 61.47859008369785
                        },
                        {
                            "lat": 55.476106970746024,
                            "lon": 61.479050988604726
                        },
                        {
                            "lat": 55.47514332302861,
                            "lon": 61.47733462947757
                        },
                        {
                            "lat": 55.47873217820948,
                            "lon": 61.476873757322515
                        },
                        {
                            "lat": 55.48232091162089,
                            "lon": 61.476412792689
                        },
                        {
                            "lat": 55.48135702755267,
                            "lon": 61.474696489811
                        },
                        {
                            "lat": 55.48039326525126,
                            "lon": 61.47298017767456
                        },
                        {
                            "lat": 55.47680488990181,
                            "lon": 61.47344107680356
                        },
                        {
                            "lat": 55.47584142834918,
                            "lon": 61.47172472266313
                        },
                        {
                            "lat": 55.47487808850241,
                            "lon": 61.47000835927083
                        },
                        {
                            "lat": 55.471289949456,
                            "lon": 61.470469100453
                        },
                        {
                            "lat": 55.47225311029698,
                            "lon": 61.47218549658338
                        },
                        {
                            "lat": 55.46866467055748,
                            "lon": 61.47264617803573
                        },
                        {
                            "lat": 55.46962777404027,
                            "lon": 61.47436259765357
                        },
                        {
                            "lat": 55.470590999212995,
                            "lon": 61.47607900802482
                        },
                        {
                            "lat": 55.467002079652765,
                            "lon": 61.476539662467054
                        },
                        {
                            "lat": 55.46341303839997,
                            "lon": 61.47700022441769
                        },
                        {
                            "lat": 55.46245017141897,
                            "lon": 61.47528374858458
                        },
                        {
                            "lat": 55.46148742609076,
                            "lon": 61.473567263511754
                        },
                        {
                            "lat": 55.46052480239418,
                            "lon": 61.47185076920077
                        },
                        {
                            "lat": 55.459562300308036,
                            "lon": 61.47013426565322
                        },
                        {
                            "lat": 55.45597385378036,
                            "lon": 61.47059460423651
                        },
                        {
                            "lat": 55.45501165233682,
                            "lon": 61.468878058745176
                        },
                        {
                            "lat": 55.45404957244284,
                            "lon": 61.46716150402386
                        },
                        {
                            "lat": 55.457637660882405,
                            "lon": 61.466701230854724
                        },
                        {
                            "lat": 55.46122562777503,
                            "lon": 61.46624086522139
                        },
                        {
                            "lat": 55.4621880657174,
                            "lon": 61.467957354526376
                        },
                        {
                            "lat": 55.46577609003695,
                            "lon": 61.467496863718466
                        },
                        {
                            "lat": 55.46481347310212,
                            "lon": 61.46578040713007
                        },
                        {
                            "lat": 55.463850977751385,
                            "lon": 61.464063941302946
                        },
                        {
                            "lat": 55.46288860396355,
                            "lon": 61.462347466238704
                        },
                        {
                            "lat": 55.45930111656567,
                            "lon": 61.462807858907176
                        },
                        {
                            "lat": 55.458339043256366,
                            "lon": 61.461091341901046
                        },
                        {
                            "lat": 55.45475161329319,
                            "lon": 61.461551609422045
                        },
                        {
                            "lat": 55.451164061846534,
                            "lon": 61.46201178449564
                        },
                        {
                            "lat": 55.452125777218846,
                            "lon": 61.46372836689754
                        },
                        {
                            "lat": 55.44853792530703,
                            "lon": 61.46418848220695
                        },
                        {
                            "lat": 55.44949958314662,
                            "lon": 61.46590508807644
                        },
                        {
                            "lat": 55.445911430727385,
                            "lon": 61.466365143607675
                        },
                        {
                            "lat": 55.44494995192771,
                            "lon": 61.46464850505087
                        },
                        {
                            "lat": 55.441361857099444,
                            "lon": 61.465108435423076
                        },
                        {
                            "lat": 55.4404006787912,
                            "lon": 61.46339175496958
                        },
                        {
                            "lat": 55.44398859457697,
                            "lon": 61.46293185727565
                        },
                        {
                            "lat": 55.443027358654014,
                            "lon": 61.46121520028356
                        },
                        {
                            "lat": 55.44661497400921,
                            "lon": 61.460755242803955
                        },
                        {
                            "lat": 55.44565368050871,
                            "lon": 61.4590386092736
                        },
                        {
                            "lat": 55.44924099547557,
                            "lon": 61.458578592022334
                        },
                        {
                            "lat": 55.45282818901978,
                            "lon": 61.45811848232864
                        },
                        {
                            "lat": 55.451866659055476,
                            "lon": 61.456401904945146
                        },
                        {
                            "lat": 55.44827964443467,
                            "lon": 61.456861981954056
                        },
                        {
                            "lat": 55.44731841479528,
                            "lon": 61.45514536266676
                        },
                        {
                            "lat": 55.44373145769888,
                            "lon": 61.45560531456305
                        },
                        {
                            "lat": 55.44014437924064,
                            "lon": 61.45606517402185
                        },
                        {
                            "lat": 55.436557179439106,
                            "lon": 61.45652494103695
                        },
                        {
                            "lat": 55.43559660796569,
                            "lon": 61.454808214532
                        },
                        {
                            "lat": 55.43463615779589,
                            "lon": 61.45309147882144
                        },
                        {
                            "lat": 55.43822299971671,
                            "lon": 61.452631777125205
                        },
                        {
                            "lat": 55.44180972033646,
                            "lon": 61.452171982996454
                        },
                        {
                            "lat": 55.44539631963661,
                            "lon": 61.45171209644146
                        },
                        {
                            "lat": 55.44443545407509,
                            "lon": 61.449995449506595
                        },
                        {
                            "lat": 55.44802175318787,
                            "lon": 61.44953550320264
                        },
                        {
                            "lat": 55.447060830112775,
                            "lon": 61.44781887972272
                        },
                        {
                            "lat": 55.44347470983063,
                            "lon": 61.448278793359
                        },
                        {
                            "lat": 55.44251408688214,
                            "lon": 61.44656212800023
                        },
                        {
                            "lat": 55.43892802413724,
                            "lon": 61.447021916569014
                        },
                        {
                            "lat": 55.43796770127826,
                            "lon": 61.44530520934545
                        },
                        {
                            "lat": 55.43438169611279,
                            "lon": 61.44576487285511
                        },
                        {
                            "lat": 55.430795569730634,
                            "lon": 61.44622444395467
                        },
                        {
                            "lat": 55.42720932215038,
                            "lon": 61.44668392263792
                        },
                        {
                            "lat": 55.42362295339056,
                            "lon": 61.44714330889862
                        },
                        {
                            "lat": 55.42003646346974,
                            "lon": 61.44760260273055
                        },
                        {
                            "lat": 55.416449852406494,
                            "lon": 61.44806180412748
                        },
                        {
                            "lat": 55.41549072400213,
                            "lon": 61.446344891906044
                        },
                        {
                            "lat": 55.41907715614278,
                            "lon": 61.44588572312732
                        },
                        {
                            "lat": 55.41811796993715,
                            "lon": 61.44416883433816
                        },
                        {
                            "lat": 55.42170410207347,
                            "lon": 61.443709605750406
                        },
                        {
                            "lat": 55.42529011309097,
                            "lon": 61.443250284745105
                        },
                        {
                            "lat": 55.4243306902779,
                            "lon": 61.44153345201111
                        },
                        {
                            "lat": 55.42791640133574,
                            "lon": 61.44107407122265
                        },
                        {
                            "lat": 55.43150199125872,
                            "lon": 61.44061459803464
                        },
                        {
                            "lat": 55.43508746002825,
                            "lon": 61.44015503245336
                        },
                        {
                            "lat": 55.43412762198361,
                            "lon": 61.43843828842077
                        },
                        {
                            "lat": 55.43771279084071,
                            "lon": 61.437978663093965
                        },
                        {
                            "lat": 55.441297838528364,
                            "lon": 61.43751894539191
                        },
                        {
                            "lat": 55.44033776421181,
                            "lon": 61.43580225744284
                        },
                        {
                            "lat": 55.43675289522503,
                            "lon": 61.43626194250118
                        },
                        {
                            "lat": 55.435793120757694,
                            "lon": 61.43454521270816
                        },
                        {
                            "lat": 55.43483346741766,
                            "lon": 61.432828473716555
                        },
                        {
                            "lat": 55.43124883467081,
                            "lon": 61.43328800113922
                        },
                        {
                            "lat": 55.43028948110335,
                            "lon": 61.431571220322645
                        },
                        {
                            "lat": 55.426704905956214,
                            "lon": 61.43203062275834
                        },
                        {
                            "lat": 55.423120209760974,
                            "lon": 61.43248993282875
                        },
                        {
                            "lat": 55.419535392536154,
                            "lon": 61.43294915052763
                        },
                        {
                            "lat": 55.4159504543003,
                            "lon": 61.43340827584879
                        },
                        {
                            "lat": 55.412365395071944,
                            "lon": 61.43386730878599
                        },
                        {
                            "lat": 55.411407056125974,
                            "lon": 61.43215035573897
                        },
                        {
                            "lat": 55.414991936601275,
                            "lon": 61.431691355398264
                        },
                        {
                            "lat": 55.41403353987368,
                            "lon": 61.42997442577087
                        },
                        {
                            "lat": 55.41044883813291,
                            "lon": 61.430433393518534
                        },
                        {
                            "lat": 55.40686401546027,
                            "lon": 61.43089226888725
                        },
                        {
                            "lat": 55.4078220546978,
                            "lon": 61.43260926369514
                        },
                        {
                            "lat": 55.40423693233526,
                            "lon": 61.433068079260494
                        },
                        {
                            "lat": 55.40065168905695,
                            "lon": 61.43352680242888
                        },
                        {
                            "lat": 55.401609491617535,
                            "lon": 61.435243853231654
                        },
                        {
                            "lat": 55.39802394860479,
                            "lon": 61.43570251657088
                        },
                        {
                            "lat": 55.39898169322784,
                            "lon": 61.437419590786384
                        },
                        {
                            "lat": 55.39539585043861,
                            "lon": 61.43787819428244
                        },
                        {
                            "lat": 55.391809886747225,
                            "lon": 61.43833670535167
                        },
                        {
                            "lat": 55.39085249989881,
                            "lon": 61.436619565998065
                        },
                        {
                            "lat": 55.389895233912945,
                            "lon": 61.43490241748993
                        },
                        {
                            "lat": 55.39348083982722,
                            "lon": 61.4344439715498
                        },
                        {
                            "lat": 55.39252351582216,
                            "lon": 61.432726846448126
                        },
                        {
                            "lat": 55.388938088768576,
                            "lon": 61.43318525982882
                        },
                        {
                            "lat": 55.38798106444469,
                            "lon": 61.43146809301633
                        },
                        {
                            "lat": 55.39156631265617,
                            "lon": 61.43100971219165
                        },
                        {
                            "lat": 55.390609230308215,
                            "lon": 61.4292925687819
                        },
                        {
                            "lat": 55.38965226875728,
                            "lon": 61.427575416220435
                        },
                        {
                            "lat": 55.38869542798238,
                            "lon": 61.4258582545088
                        },
                        {
                            "lat": 55.38773870796247,
                            "lon": 61.424141083648585
                        },
                        {
                            "lat": 55.38678210867657,
                            "lon": 61.42242390364133
                        },
                        {
                            "lat": 55.390366342243034,
                            "lon": 61.42196559316919
                        },
                        {
                            "lat": 55.38940968496044,
                            "lon": 61.42024843655763
                        },
                        {
                            "lat": 55.38845314838842,
                            "lon": 61.418531270798674
                        },
                        {
                            "lat": 55.392036903864415,
                            "lon": 61.41807293304654
                        },
                        {
                            "lat": 55.39562053863228,
                            "lon": 61.417614502941575
                        },
                        {
                            "lat": 55.39466376546758,
                            "lon": 61.41589739312234
                        },
                        {
                            "lat": 55.39824710089909,
                            "lon": 61.41543890322195
                        },
                        {
                            "lat": 55.40183031560637,
                            "lon": 61.414980320986786
                        },
                        {
                            "lat": 55.400873305986124,
                            "lon": 61.413263267117635
                        },
                        {
                            "lat": 55.39991641708766,
                            "lon": 61.411546204092126
                        },
                        {
                            "lat": 55.40349915397242,
                            "lon": 61.41108759464286
                        },
                        {
                            "lat": 55.40254220726265,
                            "lon": 61.40937055501477
                        },
                        {
                            "lat": 55.39895964889,
                            "lon": 61.40982913191191
                        },
                        {
                            "lat": 55.39800300137214,
                            "lon": 61.40811205057849
                        },
                        {
                            "lat": 55.39442050083423,
                            "lon": 61.408570502620734
                        },
                        {
                            "lat": 55.39537696983753,
                            "lon": 61.41028761649726
                        },
                        {
                            "lat": 55.391794170123774,
                            "lon": 61.41074600876466
                        },
                        {
                            "lat": 55.39275058123432,
                            "lon": 61.41246314603234
                        },
                        {
                            "lat": 55.38916748230276,
                            "lon": 61.412921478510874
                        },
                        {
                            "lat": 55.385584262726006,
                            "lon": 61.4133797186534
                        },
                        {
                            "lat": 55.3820009225226,
                            "lon": 61.41383786645376
                        },
                        {
                            "lat": 55.382956918453274,
                            "lon": 61.41555509217294
                        },
                        {
                            "lat": 55.37937327898526,
                            "lon": 61.41601318014684
                        },
                        {
                            "lat": 55.375789518906736,
                            "lon": 61.41647117576058
                        },
                        {
                            "lat": 55.376745278095434,
                            "lon": 61.41818845738555
                        },
                        {
                            "lat": 55.37316121870795,
                            "lon": 61.41864639314704
                        },
                        {
                            "lat": 55.37220563823623,
                            "lon": 61.416929079007936
                        },
                        {
                            "lat": 55.368621636992295,
                            "lon": 61.41738688988271
                        },
                        {
                            "lat": 55.36766635581337,
                            "lon": 61.41566953410941
                        },
                        {
                            "lat": 55.3712501783379,
                            "lon": 61.41521175573977
                        },
                        {
                            "lat": 55.37029483899203,
                            "lon": 61.4134944233441
                        },
                        {
                            "lat": 55.369339620177655,
                            "lon": 61.41177708182251
                        },
                        {
                            "lat": 55.3683845218738,
                            "lon": 61.41005973117647
                        },
                        {
                            "lat": 55.36480123539603,
                            "lon": 61.410517412051185
                        },
                        {
                            "lat": 55.36121782844724,
                            "lon": 61.41097500056952
                        },
                        {
                            "lat": 55.357634301045984,
                            "lon": 61.411432496725325
                        },
                        {
                            "lat": 55.35405065321084,
                            "lon": 61.41188990051239
                        },
                        {
                            "lat": 55.35309639010277,
                            "lon": 61.41017241081112
                        },
                        {
                            "lat": 55.349512800581195,
                            "lon": 61.41062968975416
                        },
                        {
                            "lat": 55.34592909068383,
                            "lon": 61.41108687632173
                        },
                        {
                            "lat": 55.342345260429205,
                            "lon": 61.4115439705076
                        },
                        {
                            "lat": 55.33876130983597,
                            "lon": 61.412000972305584
                        },
                        {
                            "lat": 55.33517723892266,
                            "lon": 61.41245788170952
                        },
                        {
                            "lat": 55.33159304770789,
                            "lon": 61.4129146987132
                        },
                        {
                            "lat": 55.33254623812861,
                            "lon": 61.41463238314489
                        },
                        {
                            "lat": 55.3334995488531,
                            "lon": 61.41635005848773
                        },
                        {
                            "lat": 55.33445297990231,
                            "lon": 61.41806772474017
                        },
                        {
                            "lat": 55.335406531297146,
                            "lon": 61.41978538190066
                        },
                        {
                            "lat": 55.33636020305858,
                            "lon": 61.421503029967695
                        },
                        {
                            "lat": 55.337313995207545,
                            "lon": 61.42322066893973
                        },
                        {
                            "lat": 55.33826790776499,
                            "lon": 61.42493829881513
                        },
                        {
                            "lat": 55.33922194075185,
                            "lon": 61.426655919592456
                        },
                        {
                            "lat": 55.408103975534985,
                            "lon": 61.467526672210674
                        },
                        {
                            "lat": 55.40906354150989,
                            "lon": 61.469243646516226
                        },
                        {
                            "lat": 55.40547423321484,
                            "lon": 61.46970265182826
                        },
                        {
                            "lat": 55.4045148464937,
                            "lon": 61.46798564490053
                        },
                        {
                            "lat": 55.408103975534985,
                            "lon": 61.467526672210674
                        },
                        {
                            "lat": 55.41265272854199,
                            "lon": 61.46878454867014
                        },
                        {
                            "lat": 55.41361259503108,
                            "lon": 61.47050148116033
                        },
                        {
                            "lat": 55.414572582822885,
                            "lon": 61.47221840446134
                        },
                        {
                            "lat": 55.41098303726962,
                            "lon": 61.47267756757327
                        },
                        {
                            "lat": 55.41002322874772,
                            "lon": 61.47096061163761
                        },
                        {
                            "lat": 55.40906354150989,
                            "lon": 61.469243646516226
                        },
                        {
                            "lat": 55.41265272854199,
                            "lon": 61.46878454867014
                        },
                        {
                            "lat": 55.41816200705233,
                            "lon": 61.471759148810385
                        },
                        {
                            "lat": 55.419122295435315,
                            "lon": 61.473476030276984
                        },
                        {
                            "lat": 55.42008270518187,
                            "lon": 61.47519290254791
                        },
                        {
                            "lat": 55.41649292239904,
                            "lon": 61.47565222348967
                        },
                        {
                            "lat": 55.41553269193848,
                            "lon": 61.47393531857164
                        },
                        {
                            "lat": 55.414572582822885,
                            "lon": 61.47221840446134
                        },
                        {
                            "lat": 55.41816200705233,
                            "lon": 61.471759148810385
                        },
                        {
                            "lat": 55.48312400989041,
                            "lon": 61.49839150069515
                        },
                        {
                            "lat": 55.47953276053079,
                            "lon": 61.49885258098856
                        },
                        {
                            "lat": 55.4785681307858,
                            "lon": 61.49713625818794
                        },
                        {
                            "lat": 55.48215920081856,
                            "lon": 61.49667521067292
                        },
                        {
                            "lat": 55.48312400989041,
                            "lon": 61.49839150069515
                        },
                        {
                            "lat": 55.474812373519306,
                            "lon": 61.51786000594566
                        },
                        {
                            "lat": 55.47840590578403,
                            "lon": 61.517398968198776
                        },
                        {
                            "lat": 55.481999315923474,
                            "lon": 61.51693783781252
                        },
                        {
                            "lat": 55.482964929363675,
                            "lon": 61.518654124224476
                        },
                        {
                            "lat": 55.47937133962873,
                            "lon": 61.5191152874102
                        },
                        {
                            "lat": 55.47577762774722,
                            "lon": 61.51957635795091
                        },
                        {
                            "lat": 55.474812373519306,
                            "lon": 61.51786000594566
                        },
                        {
                            "lat": 55.522354868968385,
                            "lon": 61.53383713185979
                        },
                        {
                            "lat": 55.52594933321503,
                            "lon": 61.533374773573925
                        },
                        {
                            "lat": 55.5269180314574,
                            "lon": 61.535090652752196
                        },
                        {
                            "lat": 55.5233233875837,
                            "lon": 61.535553043929475
                        },
                        {
                            "lat": 55.522354868968385,
                            "lon": 61.53383713185979
                        },
                        {
                            "lat": 55.51240041769828,
                            "lon": 61.50935148675496
                        },
                        {
                            "lat": 55.508808466276136,
                            "lon": 61.50981338492409
                        },
                        {
                            "lat": 55.50521639260377,
                            "lon": 61.510275190521504
                        },
                        {
                            "lat": 55.50162419669977,
                            "lon": 61.51073690354088
                        },
                        {
                            "lat": 55.498031878582744,
                            "lon": 61.511198523975985
                        },
                        {
                            "lat": 55.49899814522822,
                            "lon": 61.51291466488768
                        },
                        {
                            "lat": 55.495405525464996,
                            "lon": 61.51337622555283
                        },
                        {
                            "lat": 55.49181278350466,
                            "lon": 61.513837693615564
                        },
                        {
                            "lat": 55.49084687578402,
                            "lon": 61.51212148706824
                        },
                        {
                            "lat": 55.49443943827129,
                            "lon": 61.51166005182055
                        },
                        {
                            "lat": 55.493473473253395,
                            "lon": 61.50994386880347
                        },
                        {
                            "lat": 55.49706573413168,
                            "lon": 61.5094823737761
                        },
                        {
                            "lat": 55.50065787283685,
                            "lon": 61.509020786163774
                        },
                        {
                            "lat": 55.50424988935026,
                            "lon": 61.508559105972765
                        },
                        {
                            "lat": 55.50784178365337,
                            "lon": 61.50809733320937
                        },
                        {
                            "lat": 55.51143355572755,
                            "lon": 61.50763546787985
                        },
                        {
                            "lat": 55.51502520555422,
                            "lon": 61.507173509990466
                        },
                        {
                            "lat": 55.51599224685163,
                            "lon": 61.50888949602035
                        },
                        {
                            "lat": 55.51240041769828,
                            "lon": 61.50935148675496
                        },
                        {
                            "lat": 55.48725419113956,
                            "lon": 61.512582829712876
                        },
                        {
                            "lat": 55.486288585051874,
                            "lon": 61.510866581078275
                        },
                        {
                            "lat": 55.4898810902206,
                            "lon": 61.51040527123963
                        },
                        {
                            "lat": 55.49084687578402,
                            "lon": 61.51212148706824
                        },
                        {
                            "lat": 55.48725419113956,
                            "lon": 61.512582829712876
                        },
                        {
                            "lat": 55.38681149711106,
                            "lon": 61.47760629279729
                        },
                        {
                            "lat": 55.387770292522745,
                            "lon": 61.47932345868984
                        },
                        {
                            "lat": 55.38417941811623,
                            "lon": 61.47978194645471
                        },
                        {
                            "lat": 55.383220802180716,
                            "lon": 61.47806474796561
                        },
                        {
                            "lat": 55.38681149711106,
                            "lon": 61.47760629279729
                        },
                        {
                            "lat": 55.462802298650935,
                            "lon": 61.510199576741876
                        },
                        {
                            "lat": 55.461838135817366,
                            "lon": 61.50848310362731
                        },
                        {
                            "lat": 55.46543083471201,
                            "lon": 61.5080224478013
                        },
                        {
                            "lat": 55.466395177093084,
                            "lon": 61.509738888153976
                        },
                        {
                            "lat": 55.46998793355363,
                            "lon": 61.50927810694219
                        },
                        {
                            "lat": 55.47095257746119,
                            "lon": 61.51099450526792
                        },
                        {
                            "lat": 55.46735964145577,
                            "lon": 61.511455319250686
                        },
                        {
                            "lat": 55.463766583447466,
                            "lon": 61.511916040604
                        },
                        {
                            "lat": 55.462802298650935,
                            "lon": 61.510199576741876
                        },
                        {
                            "lat": 55.4713856248974,
                            "lon": 61.49805807553504
                        },
                        {
                            "lat": 55.46779418907895,
                            "lon": 61.49851884535465
                        },
                        {
                            "lat": 55.466830219333445,
                            "lon": 61.496802414999074
                        },
                        {
                            "lat": 55.47042147577988,
                            "lon": 61.49634167793762
                        },
                        {
                            "lat": 55.4713856248974,
                            "lon": 61.49805807553504
                        },
                        {
                            "lat": 55.47358056801392,
                            "lon": 61.50881723311282
                        },
                        {
                            "lat": 55.477173080455344,
                            "lon": 61.50835626667214
                        },
                        {
                            "lat": 55.47813808338888,
                            "lon": 61.51007259943902
                        },
                        {
                            "lat": 55.474545391445105,
                            "lon": 61.51053359866193
                        },
                        {
                            "lat": 55.47358056801392,
                            "lon": 61.50881723311282
                        },
                        {
                            "lat": 55.481730653273885,
                            "lon": 61.50961150760541
                        },
                        {
                            "lat": 55.48269595776586,
                            "lon": 61.51132779831319
                        },
                        {
                            "lat": 55.479103208381176,
                            "lon": 61.511788922938074
                        },
                        {
                            "lat": 55.47813808338888,
                            "lon": 61.51007259943902
                        },
                        {
                            "lat": 55.481730653273885,
                            "lon": 61.50961150760541
                        },
                        {
                            "lat": 55.518339761662695,
                            "lon": 61.49938554217479
                        },
                        {
                            "lat": 55.52193032777003,
                            "lon": 61.49892343805707
                        },
                        {
                            "lat": 55.52289741776023,
                            "lon": 61.50063936276502
                        },
                        {
                            "lat": 55.5193066724644,
                            "lon": 61.501101499730986
                        },
                        {
                            "lat": 55.518339761662695,
                            "lon": 61.49938554217479
                        },
                        {
                            "lat": 55.54004265772716,
                            "lon": 61.524198640662206
                        },
                        {
                            "lat": 55.54101176326999,
                            "lon": 61.525914392656084
                        },
                        {
                            "lat": 55.53741845049611,
                            "lon": 61.52637711536139
                        },
                        {
                            "lat": 55.53644952439904,
                            "lon": 61.524661330461946
                        },
                        {
                            "lat": 55.54004265772716,
                            "lon": 61.524198640662206
                        },
                        {
                            "lat": 55.544204366552705,
                            "lon": 61.538387348235915
                        },
                        {
                            "lat": 55.54060967441024,
                            "lon": 61.53885020873599
                        },
                        {
                            "lat": 55.539640069416535,
                            "lon": 61.53713445627889
                        },
                        {
                            "lat": 55.543234581961684,
                            "lon": 61.536671628703026
                        },
                        {
                            "lat": 55.544204366552705,
                            "lon": 61.538387348235915
                        }
                    ],
                    [
                        {
                            "lat": 55.56178340845614,
                            "lon": 61.54900729397525
                        },
                        {
                            "lat": 55.558187827299385,
                            "lon": 61.549470663064156
                        },
                        {
                            "lat": 55.5545921232279,
                            "lon": 61.54993393948516
                        },
                        {
                            "lat": 55.55556306996375,
                            "lon": 61.5516495512602
                        },
                        {
                            "lat": 55.55915895373863,
                            "lon": 61.55118624187607
                        },
                        {
                            "lat": 55.56275471457735,
                            "lon": 61.55072283981845
                        },
                        {
                            "lat": 55.56178340845614,
                            "lon": 61.54900729397525
                        }
                    ],
                    [
                        {
                            "lat": 55.580731674077306,
                            "lon": 61.54840443964843
                        },
                        {
                            "lat": 55.584326696908384,
                            "lon": 61.54794048168219
                        },
                        {
                            "lat": 55.587921596673134,
                            "lon": 61.547476431086636
                        },
                        {
                            "lat": 55.59151637335295,
                            "lon": 61.54701228786803
                        },
                        {
                            "lat": 55.59511102692928,
                            "lon": 61.54654805203269
                        },
                        {
                            "lat": 55.59870555738354,
                            "lon": 61.54608372358696
                        },
                        {
                            "lat": 55.602299964697174,
                            "lon": 61.545619302537126
                        },
                        {
                            "lat": 55.60589424885162,
                            "lon": 61.54515478888954
                        },
                        {
                            "lat": 55.60948840982835,
                            "lon": 61.54469018265046
                        },
                        {
                            "lat": 55.61308244760877,
                            "lon": 61.544225483826246
                        },
                        {
                            "lat": 55.6121086281928,
                            "lon": 61.54251040013342
                        },
                        {
                            "lat": 55.60851476979392,
                            "lon": 61.54297506591052
                        },
                        {
                            "lat": 55.60754125293428,
                            "lon": 61.541259939759435
                        },
                        {
                            "lat": 55.60394745074504,
                            "lon": 61.541724479918905
                        },
                        {
                            "lat": 55.60297423640467,
                            "lon": 61.54000931132372
                        },
                        {
                            "lat": 55.59938049046802,
                            "lon": 61.540473725873916
                        },
                        {
                            "lat": 55.59840757860982,
                            "lon": 61.538758514848794
                        },
                        {
                            "lat": 55.59481388896866,
                            "lon": 61.53922280379804
                        },
                        {
                            "lat": 55.59122007627269,
                            "lon": 61.53968700016558
                        },
                        {
                            "lat": 55.58762614054051,
                            "lon": 61.54015110394512
                        },
                        {
                            "lat": 55.58403208179063,
                            "lon": 61.54061511513031
                        },
                        {
                            "lat": 55.58500427595897,
                            "lon": 61.54233045820164
                        },
                        {
                            "lat": 55.581409914733925,
                            "lon": 61.54279440978377
                        },
                        {
                            "lat": 55.57781543050686,
                            "lon": 61.54325826875333
                        },
                        {
                            "lat": 55.57878738868502,
                            "lon": 61.544973668434004
                        },
                        {
                            "lat": 55.57519260193703,
                            "lon": 61.54543746777455
                        },
                        {
                            "lat": 55.57616450356389,
                            "lon": 61.547152891066716
                        },
                        {
                            "lat": 55.57713652819847,
                            "lon": 61.54886830497899
                        },
                        {
                            "lat": 55.580731674077306,
                            "lon": 61.54840443964843
                        }
                    ],
                    [
                        {
                            "lat": 55.63366919087705,
                            "lon": 61.539720461654305
                        },
                        {
                            "lat": 55.630076071916264,
                            "lon": 61.54018568277598
                        },
                        {
                            "lat": 55.63105078791775,
                            "lon": 61.54190060114923
                        },
                        {
                            "lat": 55.6346440861312,
                            "lon": 61.54143534694688
                        },
                        {
                            "lat": 55.63823726101867,
                            "lon": 61.54097000020363
                        },
                        {
                            "lat": 55.641830312561645,
                            "lon": 61.540504560925804
                        },
                        {
                            "lat": 55.64542324074159,
                            "lon": 61.540039029119725
                        },
                        {
                            "lat": 55.64901604554003,
                            "lon": 61.539573404791746
                        },
                        {
                            "lat": 55.64804043349013,
                            "lon": 61.53785865187782
                        },
                        {
                            "lat": 55.64444780785839,
                            "lon": 61.53832424310277
                        },
                        {
                            "lat": 55.64347249833517,
                            "lon": 61.53660944763996
                        },
                        {
                            "lat": 55.64706494481873,
                            "lon": 61.53614388951456
                        },
                        {
                            "lat": 55.64608957950435,
                            "lon": 61.534429117703645
                        },
                        {
                            "lat": 55.6451143375255,
                            "lon": 61.53271433644664
                        },
                        {
                            "lat": 55.64152224928288,
                            "lon": 61.533179828383346
                        },
                        {
                            "lat": 55.6379300377447,
                            "lon": 61.53364522782046
                        },
                        {
                            "lat": 55.63890492147963,
                            "lon": 61.53536007525709
                        },
                        {
                            "lat": 55.635312407510206,
                            "lon": 61.535825415269755
                        },
                        {
                            "lat": 55.63628723537101,
                            "lon": 61.537540286352176
                        },
                        {
                            "lat": 55.63269441892738,
                            "lon": 61.53800556692635
                        },
                        {
                            "lat": 55.63366919087705,
                            "lon": 61.539720461654305
                        }
                    ]
                ]
            },
            {
                "type": "MultiPolygon",
                "value": 175.0,
                "data": [
                    [
                        {
                            "lat": 55.371490034142425,
                            "lon": 61.47772225244525
                        },
                        {
                            "lat": 55.367899034157595,
                            "lon": 61.47818030460272
                        },
                        {
                            "lat": 55.368856810874846,
                            "lon": 61.479897642569426
                        },
                        {
                            "lat": 55.372447990401625,
                            "lon": 61.47943955784134
                        },
                        {
                            "lat": 55.371490034142425,
                            "lon": 61.47772225244525
                        }
                    ],
                    [
                        {
                            "lat": 55.38611752077069,
                            "lon": 61.510808160186464
                        },
                        {
                            "lat": 55.387077602048045,
                            "lon": 61.51252532407828
                        },
                        {
                            "lat": 55.388037804829715,
                            "lon": 61.51424247879266
                        },
                        {
                            "lat": 55.38899812913689,
                            "lon": 61.515959624328005
                        },
                        {
                            "lat": 55.38995857499077,
                            "lon": 61.51767676068274
                        },
                        {
                            "lat": 55.39091914241259,
                            "lon": 61.51939388785532
                        },
                        {
                            "lat": 55.391879831423545,
                            "lon": 61.52111100584418
                        },
                        {
                            "lat": 55.39284064204486,
                            "lon": 61.52282811464772
                        },
                        {
                            "lat": 55.39380157429777,
                            "lon": 61.52454521426442
                        },
                        {
                            "lat": 55.39476262820348,
                            "lon": 61.5262623046927
                        },
                        {
                            "lat": 55.39572380378324,
                            "lon": 61.52797938593096
                        },
                        {
                            "lat": 55.39668510105831,
                            "lon": 61.52969645797767
                        },
                        {
                            "lat": 55.397646520049875,
                            "lon": 61.53141352083124
                        },
                        {
                            "lat": 55.39860806077924,
                            "lon": 61.53313057449008
                        },
                        {
                            "lat": 55.39956972326765,
                            "lon": 61.534847618952675
                        },
                        {
                            "lat": 55.40053150753635,
                            "lon": 61.53656465421743
                        },
                        {
                            "lat": 55.401493413606595,
                            "lon": 61.53828168028274
                        },
                        {
                            "lat": 55.40245544149968,
                            "lon": 61.5399986971471
                        },
                        {
                            "lat": 55.40341759123685,
                            "lon": 61.54171570480887
                        },
                        {
                            "lat": 55.404379862839406,
                            "lon": 61.54343270326654
                        },
                        {
                            "lat": 55.40534225632864,
                            "lon": 61.5451496925185
                        },
                        {
                            "lat": 55.406304771725786,
                            "lon": 61.5468666725632
                        },
                        {
                            "lat": 55.40726740905221,
                            "lon": 61.54858364339905
                        },
                        {
                            "lat": 55.408230168329155,
                            "lon": 61.55030060502448
                        },
                        {
                            "lat": 55.409193049577915,
                            "lon": 61.55201755743792
                        },
                        {
                            "lat": 55.41015605281986,
                            "lon": 61.5537345006378
                        },
                        {
                            "lat": 55.41111917807625,
                            "lon": 61.55545143462256
                        },
                        {
                            "lat": 55.4120824253684,
                            "lon": 61.557168359390566
                        },
                        {
                            "lat": 55.413045794717675,
                            "lon": 61.55888527494033
                        },
                        {
                            "lat": 55.41400928614536,
                            "lon": 61.560602181270205
                        },
                        {
                            "lat": 55.4149728996728,
                            "lon": 61.56231907837866
                        },
                        {
                            "lat": 55.41593663532134,
                            "lon": 61.56403596626409
                        },
                        {
                            "lat": 55.416900493112294,
                            "lon": 61.565752844924944
                        },
                        {
                            "lat": 55.41786447306705,
                            "lon": 61.56746971435963
                        },
                        {
                            "lat": 55.41882857520691,
                            "lon": 61.569186574566565
                        },
                        {
                            "lat": 55.42242911671607,
                            "lon": 61.56872679144027
                        },
                        {
                            "lat": 55.426029535999916,
                            "lon": 61.56826691540146
                        },
                        {
                            "lat": 55.42962983303969,
                            "lon": 61.567806946456436
                        },
                        {
                            "lat": 55.43323000781659,
                            "lon": 61.56734688461142
                        },
                        {
                            "lat": 55.43683006031182,
                            "lon": 61.56688672987275
                        },
                        {
                            "lat": 55.4404299905066,
                            "lon": 61.566426482246634
                        },
                        {
                            "lat": 55.444029798382154,
                            "lon": 61.56596614173942
                        },
                        {
                            "lat": 55.44762948391972,
                            "lon": 61.565505708357335
                        },
                        {
                            "lat": 55.45122904710051,
                            "lon": 61.565045182106694
                        },
                        {
                            "lat": 55.450263321080655,
                            "lon": 61.563328616846
                        },
                        {
                            "lat": 55.45386258156167,
                            "lon": 61.562868030533075
                        },
                        {
                            "lat": 55.45289679759045,
                            "lon": 61.56115148881013
                        },
                        {
                            "lat": 55.45649575541442,
                            "lon": 61.56069084244899
                        },
                        {
                            "lat": 55.45552991352936,
                            "lon": 61.55897432426414
                        },
                        {
                            "lat": 55.454564193993214,
                            "lon": 61.55725779681673
                        },
                        {
                            "lat": 55.450965596681264,
                            "lon": 61.55771837758381
                        },
                        {
                            "lat": 55.447366877060524,
                            "lon": 61.55817886551745
                        },
                        {
                            "lat": 55.44640164032905,
                            "lon": 61.55646226323102
                        },
                        {
                            "lat": 55.45000017970055,
                            "lon": 61.55600180808359
                        },
                        {
                            "lat": 55.453598596784616,
                            "lon": 61.55554126010835
                        },
                        {
                            "lat": 55.45263312188219,
                            "lon": 61.553824714140596
                        },
                        {
                            "lat": 55.45166776926461,
                            "lon": 61.55210815891505
                        },
                        {
                            "lat": 55.45526570368562,
                            "lon": 61.55164758370284
                        },
                        {
                            "lat": 55.458863515824426,
                            "lon": 61.55118691568663
                        },
                        {
                            "lat": 55.457897925191006,
                            "lon": 61.54947041678472
                        },
                        {
                            "lat": 55.45693245683717,
                            "lon": 61.5477539086213
                        },
                        {
                            "lat": 55.45333500493761,
                            "lon": 61.54821451105547
                        },
                        {
                            "lat": 55.45236983893356,
                            "lon": 61.546497960846224
                        },
                        {
                            "lat": 55.45140479514768,
                            "lon": 61.54478140138206
                        },
                        {
                            "lat": 55.45500188688293,
                            "lon": 61.54432086451617
                        },
                        {
                            "lat": 55.454036785239815,
                            "lon": 61.54260432857764
                        },
                        {
                            "lat": 55.45763357472672,
                            "lon": 61.54214373172112
                        },
                        {
                            "lat": 55.4612302420007,
                            "lon": 61.54168304210127
                        },
                        {
                            "lat": 55.460264902545056,
                            "lon": 61.53996656247543
                        },
                        {
                            "lat": 55.463861267615975,
                            "lon": 61.539505812890965
                        },
                        {
                            "lat": 55.464826787043,
                            "lon": 61.541222259724414
                        },
                        {
                            "lat": 55.468423209834945,
                            "lon": 61.54076138459676
                        },
                        {
                            "lat": 55.47201951035784,
                            "lon": 61.54030041672465
                        },
                        {
                            "lat": 55.47298551195137,
                            "lon": 61.54201678868397
                        },
                        {
                            "lat": 55.47658187011258,
                            "lon": 61.54155569526091
                        },
                        {
                            "lat": 55.480178105945996,
                            "lon": 61.5410945091003
                        },
                        {
                            "lat": 55.48377421943297,
                            "lon": 61.54063323020841
                        },
                        {
                            "lat": 55.48474088310583,
                            "lon": 61.54234949442829
                        },
                        {
                            "lat": 55.4811445897168,
                            "lon": 61.54281080614761
                        },
                        {
                            "lat": 55.48211119585541,
                            "lon": 61.54452709391238
                        },
                        {
                            "lat": 55.48570766916516,
                            "lon": 61.54406574936219
                        },
                        {
                            "lat": 55.486674577632364,
                            "lon": 61.545781995008525
                        },
                        {
                            "lat": 55.490271108452326,
                            "lon": 61.545320524882136
                        },
                        {
                            "lat": 55.49386751682439,
                            "lon": 61.544858962020136
                        },
                        {
                            "lat": 55.497463802729854,
                            "lon": 61.544397306428856
                        },
                        {
                            "lat": 55.501059966150066,
                            "lon": 61.54393555811454
                        },
                        {
                            "lat": 55.502027716665225,
                            "lon": 61.54565166306435
                        },
                        {
                            "lat": 55.50562393743278,
                            "lon": 61.54518978916743
                        },
                        {
                            "lat": 55.50465600706637,
                            "lon": 61.5434737170835
                        },
                        {
                            "lat": 55.50368819922253,
                            "lon": 61.54175763569314
                        },
                        {
                            "lat": 55.50728393780504,
                            "lon": 61.5412957348197
                        },
                        {
                            "lat": 55.510879553867696,
                            "lon": 61.540833741247724
                        },
                        {
                            "lat": 55.514475047391805,
                            "lon": 61.540371654983474
                        },
                        {
                            "lat": 55.51807041835877,
                            "lon": 61.53990947603329
                        },
                        {
                            "lat": 55.51710201397419,
                            "lon": 61.53819351683019
                        },
                        {
                            "lat": 55.52069708265828,
                            "lon": 61.53773127808737
                        },
                        {
                            "lat": 55.52166566674991,
                            "lon": 61.53944720440339
                        },
                        {
                            "lat": 55.52526079254662,
                            "lon": 61.538984840100106
                        },
                        {
                            "lat": 55.52622967893701,
                            "lon": 61.540700724197855
                        },
                        {
                            "lat": 55.52982486180357,
                            "lon": 61.54023823432571
                        },
                        {
                            "lat": 55.53341992201703,
                            "lon": 61.53977565178718
                        },
                        {
                            "lat": 55.537014859558795,
                            "lon": 61.53931297658848
                        },
                        {
                            "lat": 55.53604543418384,
                            "lon": 61.53759719121281
                        },
                        {
                            "lat": 55.53245067628221,
                            "lon": 61.53805983349852
                        },
                        {
                            "lat": 55.52885579573027,
                            "lon": 61.5385223831297
                        },
                        {
                            "lat": 55.527886852288674,
                            "lon": 61.53680652260468
                        },
                        {
                            "lat": 55.524292028769324,
                            "lon": 61.53726894667677
                        },
                        {
                            "lat": 55.5233233875837,
                            "lon": 61.535553043929475
                        },
                        {
                            "lat": 55.5197286211397,
                            "lon": 61.53601534245084
                        },
                        {
                            "lat": 55.51613373214401,
                            "lon": 61.536477548310074
                        },
                        {
                            "lat": 55.51516557284691,
                            "lon": 61.53476157047444
                        },
                        {
                            "lat": 55.51157074100933,
                            "lon": 61.53522365079068
                        },
                        {
                            "lat": 55.51060288389642,
                            "lon": 61.53350763077009
                        },
                        {
                            "lat": 55.51419753606147,
                            "lon": 61.533045583324956
                        },
                        {
                            "lat": 55.51322962176636,
                            "lon": 61.53132958686315
                        },
                        {
                            "lat": 55.51682397180856,
                            "lon": 61.53086747963404
                        },
                        {
                            "lat": 55.51585600036851,
                            "lon": 61.52915150673133
                        },
                        {
                            "lat": 55.51945004833058,
                            "lon": 61.5286893397323
                        },
                        {
                            "lat": 55.518482019782816,
                            "lon": 61.526973390388996
                        },
                        {
                            "lat": 55.51488815139466,
                            "lon": 61.52743552451607
                        },
                        {
                            "lat": 55.51392042486572,
                            "lon": 61.52571953298982
                        },
                        {
                            "lat": 55.51032661360935,
                            "lon": 61.52618154161992
                        },
                        {
                            "lat": 55.506732679948065,
                            "lon": 61.52664345761523
                        },
                        {
                            "lat": 55.50313862390051,
                            "lon": 61.52710528096952
                        },
                        {
                            "lat": 55.499544445485284,
                            "lon": 61.52756701167642
                        },
                        {
                            "lat": 55.495950144721036,
                            "lon": 61.528028649729734
                        },
                        {
                            "lat": 55.49691697315327,
                            "lon": 61.5297448055143
                        },
                        {
                            "lat": 55.49332237037527,
                            "lon": 61.53020638374251
                        },
                        {
                            "lat": 55.48972764526418,
                            "lon": 61.53066786929898
                        },
                        {
                            "lat": 55.486132797838685,
                            "lon": 61.531129262177345
                        },
                        {
                            "lat": 55.482537828117415,
                            "lon": 61.531590562371406
                        },
                        {
                            "lat": 55.481571718546604,
                            "lon": 61.52987427528128
                        },
                        {
                            "lat": 55.480605731231606,
                            "lon": 61.528157978912816
                        },
                        {
                            "lat": 55.47701099875191,
                            "lon": 61.528619120795014
                        },
                        {
                            "lat": 55.476045313402714,
                            "lon": 61.526902782344344
                        },
                        {
                            "lat": 55.475079750248014,
                            "lon": 61.52518643462194
                        },
                        {
                            "lat": 55.478674123283646,
                            "lon": 61.52472535834721
                        },
                        {
                            "lat": 55.47770850260807,
                            "lon": 61.52300903415325
                        },
                        {
                            "lat": 55.47411430926649,
                            "lon": 61.52347007762943
                        },
                        {
                            "lat": 55.47314899043684,
                            "lon": 61.52175371136834
                        },
                        {
                            "lat": 55.476743004103014,
                            "lon": 61.521292700687276
                        },
                        {
                            "lat": 55.47577762774722,
                            "lon": 61.51957635795091
                        },
                        {
                            "lat": 55.47218379373777,
                            "lon": 61.520037335840314
                        },
                        {
                            "lat": 55.46858983761905,
                            "lon": 61.52049822107219
                        },
                        {
                            "lat": 55.46955485464008,
                            "lon": 61.522214629386255
                        },
                        {
                            "lat": 55.4659605967314,
                            "lon": 61.522675454734724
                        },
                        {
                            "lat": 55.466925556146364,
                            "lon": 61.52439188656915
                        },
                        {
                            "lat": 55.46333099640513,
                            "lon": 61.524852652020165
                        },
                        {
                            "lat": 55.459736314568026,
                            "lon": 61.525313324783546
                        },
                        {
                            "lat": 55.46070103656033,
                            "lon": 61.52702981291407
                        },
                        {
                            "lat": 55.457106052845454,
                            "lon": 61.52749042575409
                        },
                        {
                            "lat": 55.458070717117046,
                            "lon": 61.52920693740214
                        },
                        {
                            "lat": 55.454475431481804,
                            "lon": 61.52966749030466
                        },
                        {
                            "lat": 55.45088002376412,
                            "lon": 61.53012795048949
                        },
                        {
                            "lat": 55.45184445039709,
                            "lon": 61.53184451842089
                        },
                        {
                            "lat": 55.44824874071384,
                            "lon": 61.53230491864227
                        },
                        {
                            "lat": 55.44921310951135,
                            "lon": 61.53402151008838
                        },
                        {
                            "lat": 55.44561709781993,
                            "lon": 61.53448185033227
                        },
                        {
                            "lat": 55.442020964059566,
                            "lon": 61.53494209782844
                        },
                        {
                            "lat": 55.441056955167,
                            "lon": 61.53322544087141
                        },
                        {
                            "lat": 55.44465290896425,
                            "lon": 61.53276522612788
                        },
                        {
                            "lat": 55.44368884215621,
                            "lon": 61.53104859268121
                        },
                        {
                            "lat": 55.44009306830336,
                            "lon": 61.53150877467553
                        },
                        {
                            "lat": 55.4364971724429,
                            "lon": 61.53196886392717
                        },
                        {
                            "lat": 55.43746087934082,
                            "lon": 61.53368556286662
                        },
                        {
                            "lat": 55.43842470824896,
                            "lon": 61.53540225257065
                        },
                        {
                            "lat": 55.43482833040683,
                            "lon": 61.535862314552645
                        },
                        {
                            "lat": 55.43579210132205,
                            "lon": 61.53757902776452
                        },
                        {
                            "lat": 55.43219542142139,
                            "lon": 61.53803902971923
                        },
                        {
                            "lat": 55.433159134306266,
                            "lon": 61.53975576643865
                        },
                        {
                            "lat": 55.42956215230448,
                            "lon": 61.54021570835198
                        },
                        {
                            "lat": 55.42596504828475,
                            "lon": 61.540675557475005
                        },
                        {
                            "lat": 55.42692852297599,
                            "lon": 61.54239235043646
                        },
                        {
                            "lat": 55.42333111680996,
                            "lon": 61.54285213949214
                        },
                        {
                            "lat": 55.41973358864219,
                            "lon": 61.543311835739345
                        },
                        {
                            "lat": 55.41613593849141,
                            "lon": 61.54377143917184
                        },
                        {
                            "lat": 55.41253816637638,
                            "lon": 61.54423094978338
                        },
                        {
                            "lat": 55.411575412401305,
                            "lon": 61.54251402593902
                        },
                        {
                            "lat": 55.41517300430536,
                            "lon": 61.542054548039744
                        },
                        {
                            "lat": 55.41877047426644,
                            "lon": 61.541594977325175
                        },
                        {
                            "lat": 55.41780748185239,
                            "lon": 61.53987810969152
                        },
                        {
                            "lat": 55.42140464970213,
                            "lon": 61.53941847888794
                        },
                        {
                            "lat": 55.420441599097586,
                            "lon": 61.53770163475306
                        },
                        {
                            "lat": 55.41947867043094,
                            "lon": 61.53598478139842
                        },
                        {
                            "lat": 55.415881862824264,
                            "lon": 61.53644434677211
                        },
                        {
                            "lat": 55.41491923616759,
                            "lon": 61.53472745148951
                        },
                        {
                            "lat": 55.41132248677778,
                            "lon": 61.53518689136691
                        },
                        {
                            "lat": 55.41036016209361,
                            "lon": 61.533469954170414
                        },
                        {
                            "lat": 55.409397959246014,
                            "lon": 61.53175300776578
                        },
                        {
                            "lat": 55.41299434846268,
                            "lon": 61.53129363328634
                        },
                        {
                            "lat": 55.41659061584534,
                            "lon": 61.530834166031624
                        },
                        {
                            "lat": 55.41562817471735,
                            "lon": 61.52911727581367
                        },
                        {
                            "lat": 55.41922414023131,
                            "lon": 61.52865774849507
                        },
                        {
                            "lat": 55.422819983895025,
                            "lon": 61.52819812841935
                        },
                        {
                            "lat": 55.42641570568979,
                            "lon": 61.52773841559279
                        },
                        {
                            "lat": 55.43001130559688,
                            "lon": 61.52727861002165
                        },
                        {
                            "lat": 55.42904826643916,
                            "lon": 61.525561841432186
                        },
                        {
                            "lat": 55.43264356452773,
                            "lon": 61.525101975846866
                        },
                        {
                            "lat": 55.431680467361666,
                            "lon": 61.52338523075405
                        },
                        {
                            "lat": 55.43527546367426,
                            "lon": 61.52292530516866
                        },
                        {
                            "lat": 55.43431230853727,
                            "lon": 61.521208583572815
                        },
                        {
                            "lat": 55.43334927528028,
                            "lon": 61.519491852749155
                        },
                        {
                            "lat": 55.43694379004587,
                            "lon": 61.51903189990285
                        },
                        {
                            "lat": 55.44053818293407,
                            "lon": 61.51857185435951
                        },
                        {
                            "lat": 55.43957491196742,
                            "lon": 61.51685517975857
                        },
                        {
                            "lat": 55.43598069885281,
                            "lon": 61.51731519257463
                        },
                        {
                            "lat": 55.43238636388202,
                            "lon": 61.51777511269923
                        },
                        {
                            "lat": 55.43142357432123,
                            "lon": 61.51605836342467
                        },
                        {
                            "lat": 55.43046090657668,
                            "lon": 61.51434160492702
                        },
                        {
                            "lat": 55.42686680934451,
                            "lon": 61.514801366932176
                        },
                        {
                            "lat": 55.42327259033603,
                            "lon": 61.515261036244674
                        },
                        {
                            "lat": 55.419678249569955,
                            "lon": 61.51572061285823
                        },
                        {
                            "lat": 55.418716242964116,
                            "lon": 61.514003747038764
                        },
                        {
                            "lat": 55.41512196028285,
                            "lon": 61.51446319825829
                        },
                        {
                            "lat": 55.41152755590262,
                            "lon": 61.514922556772014
                        },
                        {
                            "lat": 55.41248920283982,
                            "lon": 61.516639487963545
                        },
                        {
                            "lat": 55.40889449691315,
                            "lon": 61.517098786442844
                        },
                        {
                            "lat": 55.40793302984214,
                            "lon": 61.51538182257371
                        },
                        {
                            "lat": 55.40697168443266,
                            "lon": 61.513664849505226
                        },
                        {
                            "lat": 55.40601046066351,
                            "lon": 61.51194786723899
                        },
                        {
                            "lat": 55.409604627047806,
                            "lon": 61.511488666782164
                        },
                        {
                            "lat": 55.41319867179426,
                            "lon": 61.51102937362458
                        },
                        {
                            "lat": 55.41223721004534,
                            "lon": 61.50931244750236
                        },
                        {
                            "lat": 55.41583095336767,
                            "lon": 61.508853094328735
                        },
                        {
                            "lat": 55.414869433505146,
                            "lon": 61.50713619168014
                        },
                        {
                            "lat": 55.41846287544576,
                            "lon": 61.5066767785046
                        },
                        {
                            "lat": 55.42205619573489,
                            "lon": 61.50621727265825
                        },
                        {
                            "lat": 55.42109443810833,
                            "lon": 61.504500426166516
                        },
                        {
                            "lat": 55.42468745706071,
                            "lon": 61.50404086034419
                        },
                        {
                            "lat": 55.428280354345496,
                            "lon": 61.50358120186916
                        },
                        {
                            "lat": 55.42924247128404,
                            "lon": 61.50529798297816
                        },
                        {
                            "lat": 55.432835426506685,
                            "lon": 61.50483819915694
                        },
                        {
                            "lat": 55.43642826000317,
                            "lon": 61.504378322689895
                        },
                        {
                            "lat": 55.43546578383752,
                            "lon": 61.50266160698608
                        },
                        {
                            "lat": 55.43450342939547,
                            "lon": 61.50094488205987
                        },
                        {
                            "lat": 55.438095782002335,
                            "lon": 61.50048497837194
                        },
                        {
                            "lat": 55.437133369718275,
                            "lon": 61.49876827692907
                        },
                        {
                            "lat": 55.44072542108088,
                            "lon": 61.49830831332884
                        },
                        {
                            "lat": 55.43976295099211,
                            "lon": 61.49659163536966
                        },
                        {
                            "lat": 55.4433547011529,
                            "lon": 61.49613161187119
                        },
                        {
                            "lat": 55.446946329597786,
                            "lon": 61.4956714957744
                        },
                        {
                            "lat": 55.4459836222981,
                            "lon": 61.49395487401335
                        },
                        {
                            "lat": 55.44957494958617,
                            "lon": 61.493494698044266
                        },
                        {
                            "lat": 55.45316615514226,
                            "lon": 61.49303442949495
                        },
                        {
                            "lat": 55.45412922126528,
                            "lon": 61.49475098581083
                        },
                        {
                            "lat": 55.457720484450654,
                            "lon": 61.49429059195656
                        },
                        {
                            "lat": 55.45675723894777,
                            "lon": 61.49257406837166
                        },
                        {
                            "lat": 55.4603482009841,
                            "lon": 61.492113614680655
                        },
                        {
                            "lat": 55.461311625845596,
                            "lon": 61.49383010552897
                        },
                        {
                            "lat": 55.46490264543149,
                            "lon": 61.49336952653431
                        },
                        {
                            "lat": 55.465866371458034,
                            "lon": 61.49508597539178
                        },
                        {
                            "lat": 55.469457448551054,
                            "lon": 61.49462527108502
                        },
                        {
                            "lat": 55.46849354318975,
                            "lon": 61.492908854978815
                        },
                        {
                            "lat": 55.472084319101754,
                            "lon": 61.49244809086877
                        },
                        {
                            "lat": 55.47304840377663,
                            "lon": 61.49416447421809
                        },
                        {
                            "lat": 55.4766392371161,
                            "lon": 61.49370358479726
                        },
                        {
                            "lat": 55.48022994855088,
                            "lon": 61.49324260282877
                        },
                        {
                            "lat": 55.483820538062375,
                            "lon": 61.49278152831886
                        },
                        {
                            "lat": 55.4847852824924,
                            "lon": 61.49449780409656
                        },
                        {
                            "lat": 55.48119451371245,
                            "lon": 61.494958911383584
                        },
                        {
                            "lat": 55.48215920081856,
                            "lon": 61.49667521067292
                        },
                        {
                            "lat": 55.485750148885536,
                            "lon": 61.49621407060532
                        },
                        {
                            "lat": 55.48671513726302,
                            "lon": 61.49793032784351
                        },
                        {
                            "lat": 55.49030614263002,
                            "lon": 61.49746906243992
                        },
                        {
                            "lat": 55.4938970259728,
                            "lon": 61.497007704490635
                        },
                        {
                            "lat": 55.49486249494058,
                            "lon": 61.498723886864994
                        },
                        {
                            "lat": 55.498453435501006,
                            "lon": 61.498262403572
                        },
                        {
                            "lat": 55.49941920581187,
                            "lon": 61.49997854385808
                        },
                        {
                            "lat": 55.503010203547426,
                            "lon": 61.49951693521297
                        },
                        {
                            "lat": 55.50660107916049,
                            "lon": 61.499055234029775
                        },
                        {
                            "lat": 55.510191832632486,
                            "lon": 61.49859344031473
                        },
                        {
                            "lat": 55.51378246394488,
                            "lon": 61.49813155407412
                        },
                        {
                            "lat": 55.51281597673062,
                            "lon": 61.496415545074974
                        },
                        {
                            "lat": 55.50922552461219,
                            "lon": 61.496877398485445
                        },
                        {
                            "lat": 55.50825933873025,
                            "lon": 61.49516134736183
                        },
                        {
                            "lat": 55.507293274965384,
                            "lon": 61.49344528694546
                        },
                        {
                            "lat": 55.503703059083314,
                            "lon": 61.493906982197224
                        },
                        {
                            "lat": 55.50273729657378,
                            "lon": 61.49219087967545
                        },
                        {
                            "lat": 55.49914713779522,
                            "lon": 61.49265244960359
                        },
                        {
                            "lat": 55.49818167650388,
                            "lon": 61.490936304990505
                        },
                        {
                            "lat": 55.49459157487135,
                            "lon": 61.49139774960339
                        },
                        {
                            "lat": 55.493626414761074,
                            "lon": 61.489681562913056
                        },
                        {
                            "lat": 55.49003637031706,
                            "lon": 61.49014288221907
                        },
                        {
                            "lat": 55.4890715113507,
                            "lon": 61.48842665346556
                        },
                        {
                            "lat": 55.4854815241377,
                            "lon": 61.48888784747309
                        },
                        {
                            "lat": 55.48451696627811,
                            "lon": 61.487171576670455
                        },
                        {
                            "lat": 55.480927036338585,
                            "lon": 61.48763264538788
                        },
                        {
                            "lat": 55.47733698452088,
                            "lon": 61.488093621587005
                        },
                        {
                            "lat": 55.476372906925015,
                            "lon": 61.48637727598593
                        },
                        {
                            "lat": 55.47996277954862,
                            "lon": 61.48591633255021
                        },
                        {
                            "lat": 55.4789986446156,
                            "lon": 61.48420001045148
                        },
                        {
                            "lat": 55.47540895116752,
                            "lon": 61.484660921127244
                        },
                        {
                            "lat": 55.4718191359023,
                            "lon": 61.48512173928967
                        },
                        {
                            "lat": 55.47278291246305,
                            "lon": 61.48683812690269
                        },
                        {
                            "lat": 55.46919279618132,
                            "lon": 61.48729888529424
                        },
                        {
                            "lat": 55.46822919883852,
                            "lon": 61.48558246493244
                        },
                        {
                            "lat": 55.464639139994745,
                            "lon": 61.48604309804937
                        },
                        {
                            "lat": 55.46367584365249,
                            "lon": 61.48432663569875
                        },
                        {
                            "lat": 55.462712669050404,
                            "lon": 61.482610164103995
                        },
                        {
                            "lat": 55.45912284691021,
                            "lon": 61.483070639224046
                        },
                        {
                            "lat": 55.45815997323169,
                            "lon": 61.48135412565948
                        },
                        {
                            "lat": 55.461749616167275,
                            "lon": 61.48089368326663
                        },
                        {
                            "lat": 55.46078668498194,
                            "lon": 61.4791771931883
                        },
                        {
                            "lat": 55.45982387547319,
                            "lon": 61.47746069387048
                        },
                        {
                            "lat": 55.456234590891,
                            "lon": 61.477921070819214
                        },
                        {
                            "lat": 55.455272082186475,
                            "lon": 61.476204529546635
                        },
                        {
                            "lat": 55.45430969509758,
                            "lon": 61.47448797904123
                        },
                        {
                            "lat": 55.450720647199994,
                            "lon": 61.474948198060396
                        },
                        {
                            "lat": 55.44713147772656,
                            "lon": 61.47540832457421
                        },
                        {
                            "lat": 55.44809350649144,
                            "lon": 61.47712494048977
                        },
                        {
                            "lat": 55.44905565683479,
                            "lon": 61.47884154717935
                        },
                        {
                            "lat": 55.44546600739793,
                            "lon": 61.47930164657831
                        },
                        {
                            "lat": 55.446428100109955,
                            "lon": 61.48101827674385
                        },
                        {
                            "lat": 55.44283814983994,
                            "lon": 61.481478316311645
                        },
                        {
                            "lat": 55.43924807798634,
                            "lon": 61.48193826333853
                        },
                        {
                            "lat": 55.440209933737876,
                            "lon": 61.48365494967545
                        },
                        {
                            "lat": 55.4366195610062,
                            "lon": 61.484114836845286
                        },
                        {
                            "lat": 55.43758135901217,
                            "lon": 61.48583154665536
                        },
                        {
                            "lat": 55.43399068536004,
                            "lon": 61.4862913739541
                        },
                        {
                            "lat": 55.4349524255832,
                            "lon": 61.48800810723696
                        },
                        {
                            "lat": 55.43591428739766,
                            "lon": 61.489724831303164
                        },
                        {
                            "lat": 55.436876270824584,
                            "lon": 61.491441546151115
                        },
                        {
                            "lat": 55.44046748264721,
                            "lon": 61.49098162076795
                        },
                        {
                            "lat": 55.44142976713521,
                            "lon": 61.492698293694374
                        },
                        {
                            "lat": 55.437838375885164,
                            "lon": 61.49315825177921
                        },
                        {
                            "lat": 55.434246862980274,
                            "lon": 61.4936181172707
                        },
                        {
                            "lat": 55.43065522843914,
                            "lon": 61.49407789016257
                        },
                        {
                            "lat": 55.429693482297004,
                            "lon": 61.49236111914798
                        },
                        {
                            "lat": 55.42610190562932,
                            "lon": 61.492820766749226
                        },
                        {
                            "lat": 55.422510207383894,
                            "lon": 61.493280321744
                        },
                        {
                            "lat": 55.41891838757939,
                            "lon": 61.493739784126085
                        },
                        {
                            "lat": 55.415326446234474,
                            "lon": 61.49419915388922
                        },
                        {
                            "lat": 55.41173438336779,
                            "lon": 61.494658431027155
                        },
                        {
                            "lat": 55.40814219899801,
                            "lon": 61.49511761553369
                        },
                        {
                            "lat": 55.40910286787627,
                            "lon": 61.496834582572866
                        },
                        {
                            "lat": 55.405510382403946,
                            "lon": 61.49729370709279
                        },
                        {
                            "lat": 55.40647099315101,
                            "lon": 61.49901069759253
                        },
                        {
                            "lat": 55.40287820653377,
                            "lon": 61.49946976211184
                        },
                        {
                            "lat": 55.39928529842711,
                            "lon": 61.499928733969746
                        },
                        {
                            "lat": 55.40024567130776,
                            "lon": 61.50164578057643
                        },
                        {
                            "lat": 55.39665246201133,
                            "lon": 61.50210469240778
                        },
                        {
                            "lat": 55.39761277664616,
                            "lon": 61.503821762472136
                        },
                        {
                            "lat": 55.39401926611759,
                            "lon": 61.504280614262846
                        },
                        {
                            "lat": 55.393059131241685,
                            "lon": 61.5025635115596
                        },
                        {
                            "lat": 55.39209911782031,
                            "lon": 61.500846399676455
                        },
                        {
                            "lat": 55.39113922583225,
                            "lon": 61.49912927861491
                        },
                        {
                            "lat": 55.38754613311218,
                            "lon": 61.499587939824835
                        },
                        {
                            "lat": 55.386586542260275,
                            "lon": 61.49787077696356
                        },
                        {
                            "lat": 55.38299350789192,
                            "lon": 61.49832931287546
                        },
                        {
                            "lat": 55.379400352169945,
                            "lon": 61.498787756106054
                        },
                        {
                            "lat": 55.380359583510376,
                            "lon": 61.50050498419633
                        },
                        {
                            "lat": 55.38131893620683,
                            "lon": 61.502222203120105
                        },
                        {
                            "lat": 55.3822784102805,
                            "lon": 61.5039394128758
                        },
                        {
                            "lat": 55.38323800575254,
                            "lon": 61.50565661346184
                        },
                        {
                            "lat": 55.38419772264412,
                            "lon": 61.50737380487667
                        },
                        {
                            "lat": 55.38515756097645,
                            "lon": 61.50909098711874
                        },
                        {
                            "lat": 55.38611752077069,
                            "lon": 61.510808160186464
                        }
                    ],
                    [
                        {
                            "lat": 55.383220802180716,
                            "lon": 61.47806474796561
                        },
                        {
                            "lat": 55.38417941811623,
                            "lon": 61.47978194645471
                        },
                        {
                            "lat": 55.387770292522745,
                            "lon": 61.47932345868984
                        },
                        {
                            "lat": 55.38681149711106,
                            "lon": 61.47760629279729
                        },
                        {
                            "lat": 55.383220802180716,
                            "lon": 61.47806474796561
                        }
                    ],
                    [
                        {
                            "lat": 55.466830219333445,
                            "lon": 61.496802414999074
                        },
                        {
                            "lat": 55.46779418907895,
                            "lon": 61.49851884535465
                        },
                        {
                            "lat": 55.4713856248974,
                            "lon": 61.49805807553504
                        },
                        {
                            "lat": 55.47042147577988,
                            "lon": 61.49634167793762
                        },
                        {
                            "lat": 55.466830219333445,
                            "lon": 61.496802414999074
                        }
                    ],
                    [
                        {
                            "lat": 55.4785681307858,
                            "lon": 61.49713625818794
                        },
                        {
                            "lat": 55.47953276053079,
                            "lon": 61.49885258098856
                        },
                        {
                            "lat": 55.48312400989041,
                            "lon": 61.49839150069515
                        },
                        {
                            "lat": 55.48215920081856,
                            "lon": 61.49667521067292
                        },
                        {
                            "lat": 55.4785681307858,
                            "lon": 61.49713625818794
                        }
                    ],
                    [
                        {
                            "lat": 55.47937133962873,
                            "lon": 61.5191152874102
                        },
                        {
                            "lat": 55.482964929363675,
                            "lon": 61.518654124224476
                        },
                        {
                            "lat": 55.481999315923474,
                            "lon": 61.51693783781252
                        },
                        {
                            "lat": 55.47840590578403,
                            "lon": 61.517398968198776
                        },
                        {
                            "lat": 55.474812373519306,
                            "lon": 61.51786000594566
                        },
                        {
                            "lat": 55.47577762774722,
                            "lon": 61.51957635795091
                        },
                        {
                            "lat": 55.47937133962873,
                            "lon": 61.5191152874102
                        }
                    ],
                    [
                        {
                            "lat": 55.52193032777003,
                            "lon": 61.49892343805707
                        },
                        {
                            "lat": 55.518339761662695,
                            "lon": 61.49938554217479
                        },
                        {
                            "lat": 55.5193066724644,
                            "lon": 61.501101499730986
                        },
                        {
                            "lat": 55.52289741776023,
                            "lon": 61.50063936276502
                        },
                        {
                            "lat": 55.52193032777003,
                            "lon": 61.49892343805707
                        }
                    ],
                    [
                        {
                            "lat": 55.53644952439904,
                            "lon": 61.524661330461946
                        },
                        {
                            "lat": 55.53741845049611,
                            "lon": 61.52637711536139
                        },
                        {
                            "lat": 55.54101176326999,
                            "lon": 61.525914392656084
                        },
                        {
                            "lat": 55.54004265772716,
                            "lon": 61.524198640662206
                        },
                        {
                            "lat": 55.53644952439904,
                            "lon": 61.524661330461946
                        }
                    ],
                    [
                        {
                            "lat": 55.522354868968385,
                            "lon": 61.53383713185979
                        },
                        {
                            "lat": 55.5233233875837,
                            "lon": 61.535553043929475
                        },
                        {
                            "lat": 55.5269180314574,
                            "lon": 61.535090652752196
                        },
                        {
                            "lat": 55.52594933321503,
                            "lon": 61.533374773573925
                        },
                        {
                            "lat": 55.522354868968385,
                            "lon": 61.53383713185979
                        }
                    ],
                    [
                        {
                            "lat": 55.539640069416535,
                            "lon": 61.53713445627889
                        },
                        {
                            "lat": 55.54060967441024,
                            "lon": 61.53885020873599
                        },
                        {
                            "lat": 55.544204366552705,
                            "lon": 61.538387348235915
                        },
                        {
                            "lat": 55.543234581961684,
                            "lon": 61.536671628703026
                        },
                        {
                            "lat": 55.539640069416535,
                            "lon": 61.53713445627889
                        }
                    ]
                ]
            }
        ],
        "pollution": [
            {
                "type": "MultiPolygon",
                "value": 0.01,
                "data": [
                    [
                        {
                            "lat": 55.34356037726074,
                            "lon": 61.39299701312989
                        },
                        {
                            "lat": 55.33997857400689,
                            "lon": 61.393453967568995
                        },
                        {
                            "lat": 55.34093145556928,
                            "lon": 61.39517158071283
                        },
                        {
                            "lat": 55.34451343736637,
                            "lon": 61.39471459383859
                        },
                        {
                            "lat": 55.34356037726074,
                            "lon": 61.39299701312989
                        }
                    ],
                    [
                        {
                            "lat": 55.33734935358459,
                            "lon": 61.39562847526198
                        },
                        {
                            "lat": 55.338302176751895,
                            "lon": 61.397346111748305
                        },
                        {
                            "lat": 55.341884457319296,
                            "lon": 61.39688918476626
                        },
                        {
                            "lat": 55.34093145556928,
                            "lon": 61.39517158071283
                        },
                        {
                            "lat": 55.33734935358459,
                            "lon": 61.39562847526198
                        }
                    ],
                    [
                        {
                            "lat": 55.373164962763546,
                            "lon": 61.391055375884704
                        },
                        {
                            "lat": 55.374119570817406,
                            "lon": 61.39277268778996
                        },
                        {
                            "lat": 55.37770064846528,
                            "lon": 61.39231483777953
                        },
                        {
                            "lat": 55.37674586203775,
                            "lon": 61.39059755836318
                        },
                        {
                            "lat": 55.373164962763546,
                            "lon": 61.391055375884704
                        }
                    ],
                    [
                        {
                            "lat": 55.334719775994564,
                            "lon": 61.397802946393554
                        },
                        {
                            "lat": 55.331137255065855,
                            "lon": 61.39825968869578
                        },
                        {
                            "lat": 55.332089841157895,
                            "lon": 61.39997738094933
                        },
                        {
                            "lat": 55.335672540729654,
                            "lon": 61.39952060622201
                        },
                        {
                            "lat": 55.334719775994564,
                            "lon": 61.397802946393554
                        }
                    ],
                    [
                        {
                            "lat": 55.328507021412456,
                            "lon": 61.40043406332185
                        },
                        {
                            "lat": 55.32945954899567,
                            "lon": 61.402151778915
                        },
                        {
                            "lat": 55.33304254742364,
                            "lon": 61.401695064119586
                        },
                        {
                            "lat": 55.332089841157895,
                            "lon": 61.39997738094933
                        },
                        {
                            "lat": 55.328507021412456,
                            "lon": 61.40043406332185
                        }
                    ],
                    [
                        {
                            "lat": 55.37149292281027,
                            "lon": 61.39494778079475
                        },
                        {
                            "lat": 55.37244759320409,
                            "lon": 61.39666510694352
                        },
                        {
                            "lat": 55.37602914807206,
                            "lon": 61.39620728423132
                        },
                        {
                            "lat": 55.37507429924659,
                            "lon": 61.39448999057265
                        },
                        {
                            "lat": 55.37149292281027,
                            "lon": 61.39494778079475
                        }
                    ],
                    [
                        {
                            "lat": 55.33231785288496,
                            "lon": 61.40730487119956
                        },
                        {
                            "lat": 55.32873419805926,
                            "lon": 61.40776159090052
                        },
                        {
                            "lat": 55.32968702769414,
                            "lon": 61.409479302589475
                        },
                        {
                            "lat": 55.330639977570044,
                            "lon": 61.411197005194225
                        },
                        {
                            "lat": 55.33159304770789,
                            "lon": 61.4129146987132
                        },
                        {
                            "lat": 55.33517723892266,
                            "lon": 61.41245788170952
                        },
                        {
                            "lat": 55.33422398996986,
                            "lon": 61.41074022062818
                        },
                        {
                            "lat": 55.33327086129759,
                            "lon": 61.40902255045766
                        },
                        {
                            "lat": 55.33231785288496,
                            "lon": 61.40730487119956
                        }
                    ],
                    [
                        {
                            "lat": 55.365519949815926,
                            "lon": 61.40490762450747
                        },
                        {
                            "lat": 55.366474686713865,
                            "lon": 61.40662500251741
                        },
                        {
                            "lat": 55.37005749547223,
                            "lon": 61.40616729428336
                        },
                        {
                            "lat": 55.36910257998719,
                            "lon": 61.40444994876373
                        },
                        {
                            "lat": 55.365519949815926,
                            "lon": 61.40490762450747
                        }
                    ],
                    [
                        {
                            "lat": 55.35930870787263,
                            "lon": 61.407540141962436
                        },
                        {
                            "lat": 55.35835432821167,
                            "lon": 61.405822698988686
                        },
                        {
                            "lat": 55.354771336815794,
                            "lon": 61.40628009771377
                        },
                        {
                            "lat": 55.355725537826864,
                            "lon": 61.40799757316102
                        },
                        {
                            "lat": 55.35667985922995,
                            "lon": 61.40971503949873
                        },
                        {
                            "lat": 55.36026320794417,
                            "lon": 61.409257575823226
                        },
                        {
                            "lat": 55.35930870787263,
                            "lon": 61.407540141962436
                        }
                    ],
                    [
                        {
                            "lat": 55.344975305411694,
                            "lon": 61.40936931258698
                        },
                        {
                            "lat": 55.34592909068383,
                            "lon": 61.41108687632173
                        },
                        {
                            "lat": 55.349512800581195,
                            "lon": 61.41062968975416
                        },
                        {
                            "lat": 55.34855883657782,
                            "lon": 61.40891215847949
                        },
                        {
                            "lat": 55.344975305411694,
                            "lon": 61.40936931258698
                        }
                    ],
                    [
                        {
                            "lat": 55.37364018376434,
                            "lon": 61.40570949371658
                        },
                        {
                            "lat": 55.37459539830032,
                            "lon": 61.40742679761371
                        },
                        {
                            "lat": 55.37817814467141,
                            "lon": 61.40696887221545
                        },
                        {
                            "lat": 55.37722275157166,
                            "lon": 61.4052516008232
                        },
                        {
                            "lat": 55.37364018376434,
                            "lon": 61.40570949371658
                        }
                    ],
                    [
                        {
                            "lat": 55.3334995488531,
                            "lon": 61.41635005848773
                        },
                        {
                            "lat": 55.33445297990231,
                            "lon": 61.41806772474017
                        },
                        {
                            "lat": 55.33803770767351,
                            "lon": 61.41761081040317
                        },
                        {
                            "lat": 55.33708409775357,
                            "lon": 61.41589317659856
                        },
                        {
                            "lat": 55.3334995488531,
                            "lon": 61.41635005848773
                        }
                    ],
                    [
                        {
                            "lat": 55.35858886329591,
                            "lon": 61.41314994483929
                        },
                        {
                            "lat": 55.35500503673423,
                            "lon": 61.413607381104455
                        },
                        {
                            "lat": 55.35595954069387,
                            "lon": 61.41532485258569
                        },
                        {
                            "lat": 55.35954354600067,
                            "lon": 61.41486738383904
                        },
                        {
                            "lat": 55.35858886329591,
                            "lon": 61.41314994483929
                        }
                    ],
                    [
                        {
                            "lat": 55.37483388030999,
                            "lon": 61.414753885003115
                        },
                        {
                            "lat": 55.378417461711074,
                            "lon": 61.41429592190574
                        },
                        {
                            "lat": 55.37746176502645,
                            "lon": 61.41257865453033
                        },
                        {
                            "lat": 55.37650618891043,
                            "lon": 61.41086137802218
                        },
                        {
                            "lat": 55.37292296480859,
                            "lon": 61.41131927609712
                        },
                        {
                            "lat": 55.373878362284266,
                            "lon": 61.4130365851148
                        },
                        {
                            "lat": 55.37483388030999,
                            "lon": 61.414753885003115
                        }
                    ],
                    [
                        {
                            "lat": 55.3820009225226,
                            "lon": 61.41383786645376
                        },
                        {
                            "lat": 55.378417461711074,
                            "lon": 61.41429592190574
                        },
                        {
                            "lat": 55.37937327898526,
                            "lon": 61.41601318014684
                        },
                        {
                            "lat": 55.382956918453274,
                            "lon": 61.41555509217294
                        },
                        {
                            "lat": 55.3820009225226,
                            "lon": 61.41383786645376
                        }
                    ],
                    [
                        {
                            "lat": 55.37220563823623,
                            "lon": 61.416929079007936
                        },
                        {
                            "lat": 55.37316121870795,
                            "lon": 61.41864639314704
                        },
                        {
                            "lat": 55.376745278095434,
                            "lon": 61.41818845738555
                        },
                        {
                            "lat": 55.375789518906736,
                            "lon": 61.41647117576058
                        },
                        {
                            "lat": 55.37220563823623,
                            "lon": 61.416929079007936
                        }
                    ],
                    [
                        {
                            "lat": 55.38203421813876,
                            "lon": 61.49661210822849
                        },
                        {
                            "lat": 55.38299350789192,
                            "lon": 61.49832931287546
                        },
                        {
                            "lat": 55.379400352169945,
                            "lon": 61.498787756106054
                        },
                        {
                            "lat": 55.380359583510376,
                            "lon": 61.50050498419633
                        },
                        {
                            "lat": 55.38395291899866,
                            "lon": 61.50004650835407
                        },
                        {
                            "lat": 55.38491245148015,
                            "lon": 61.50176369466273
                        },
                        {
                            "lat": 55.38850584535754,
                            "lon": 61.50130509351277
                        },
                        {
                            "lat": 55.39209911782031,
                            "lon": 61.500846399676455
                        },
                        {
                            "lat": 55.393059131241685,
                            "lon": 61.5025635115596
                        },
                        {
                            "lat": 55.39401926611759,
                            "lon": 61.504280614262846
                        },
                        {
                            "lat": 55.39761277664616,
                            "lon": 61.503821762472136
                        },
                        {
                            "lat": 55.39665246201133,
                            "lon": 61.50210469240778
                        },
                        {
                            "lat": 55.40024567130776,
                            "lon": 61.50164578057643
                        },
                        {
                            "lat": 55.40120616568032,
                            "lon": 61.503362817996305
                        },
                        {
                            "lat": 55.404799433201376,
                            "lon": 61.50290378084158
                        },
                        {
                            "lat": 55.403838759112276,
                            "lon": 61.50118677607182
                        },
                        {
                            "lat": 55.40743172540619,
                            "lon": 61.5007276789002
                        },
                        {
                            "lat": 55.411024570170795,
                            "lon": 61.50026848906779
                        },
                        {
                            "lat": 55.41461729338743,
                            "lon": 61.49980920658087
                        },
                        {
                            "lat": 55.418209895037414,
                            "lon": 61.49934983144562
                        },
                        {
                            "lat": 55.41724862387937,
                            "lon": 61.497632948128675
                        },
                        {
                            "lat": 55.4208409243521,
                            "lon": 61.497173513026205
                        },
                        {
                            "lat": 55.424433103242045,
                            "lon": 61.496713985293546
                        },
                        {
                            "lat": 55.42347159452284,
                            "lon": 61.494997158122615
                        },
                        {
                            "lat": 55.42706347228045,
                            "lon": 61.49453757044864
                        },
                        {
                            "lat": 55.43065522843914,
                            "lon": 61.49407789016257
                        },
                        {
                            "lat": 55.43161709619887,
                            "lon": 61.49579465196265
                        },
                        {
                            "lat": 55.43520891022844,
                            "lon": 61.49533484637687
                        },
                        {
                            "lat": 55.434246862980274,
                            "lon": 61.4936181172707
                        },
                        {
                            "lat": 55.433284937368306,
                            "lon": 61.491901378946544
                        },
                        {
                            "lat": 55.43232313337136,
                            "lon": 61.490184631405974
                        },
                        {
                            "lat": 55.42873185775123,
                            "lon": 61.490644338920426
                        },
                        {
                            "lat": 55.429693482297004,
                            "lon": 61.49236111914798
                        },
                        {
                            "lat": 55.42610190562932,
                            "lon": 61.492820766749226
                        },
                        {
                            "lat": 55.422510207383894,
                            "lon": 61.493280321744
                        },
                        {
                            "lat": 55.41891838757939,
                            "lon": 61.493739784126085
                        },
                        {
                            "lat": 55.415326446234474,
                            "lon": 61.49419915388922
                        },
                        {
                            "lat": 55.41173438336779,
                            "lon": 61.494658431027155
                        },
                        {
                            "lat": 55.410773656395676,
                            "lon": 61.49294148744889
                        },
                        {
                            "lat": 55.414365539705265,
                            "lon": 61.492482242969835
                        },
                        {
                            "lat": 55.413404754676634,
                            "lon": 61.49076532285279
                        },
                        {
                            "lat": 55.40981305090548,
                            "lon": 61.49122453467638
                        },
                        {
                            "lat": 55.406221225673576,
                            "lon": 61.49168365387976
                        },
                        {
                            "lat": 55.402629278999584,
                            "lon": 61.49214268045672
                        },
                        {
                            "lat": 55.39903721090219,
                            "lon": 61.49260161440107
                        },
                        {
                            "lat": 55.39544502140008,
                            "lon": 61.493060455706505
                        },
                        {
                            "lat": 55.39640490852485,
                            "lon": 61.49477753904467
                        },
                        {
                            "lat": 55.39281241799242,
                            "lon": 61.495236320332346
                        },
                        {
                            "lat": 55.393772246861445,
                            "lon": 61.49695342712075
                        },
                        {
                            "lat": 55.39017945525636,
                            "lon": 61.497412148376576
                        },
                        {
                            "lat": 55.38921980607147,
                            "lon": 61.49569500896301
                        },
                        {
                            "lat": 55.38562707278067,
                            "lon": 61.496153604930456
                        },
                        {
                            "lat": 55.384667724652196,
                            "lon": 61.494436423727144
                        },
                        {
                            "lat": 55.38370849785368,
                            "lon": 61.49271923335515
                        },
                        {
                            "lat": 55.380116002608574,
                            "lon": 61.493177671435646
                        },
                        {
                            "lat": 55.381075049718014,
                            "lon": 61.49489489441469
                        },
                        {
                            "lat": 55.38203421813876,
                            "lon": 61.49661210822849
                        },
                        {
                            "lat": 55.39113922583225,
                            "lon": 61.49912927861491
                        },
                        {
                            "lat": 55.38754613311218,
                            "lon": 61.499587939824835
                        },
                        {
                            "lat": 55.386586542260275,
                            "lon": 61.49787077696356
                        },
                        {
                            "lat": 55.39017945525636,
                            "lon": 61.497412148376576
                        },
                        {
                            "lat": 55.39113922583225,
                            "lon": 61.49912927861491
                        }
                    ],
                    [
                        {
                            "lat": 55.388260278256396,
                            "lon": 61.493977860375786
                        },
                        {
                            "lat": 55.39185271051191,
                            "lon": 61.49351920436682
                        },
                        {
                            "lat": 55.39544502140008,
                            "lon": 61.493060455706505
                        },
                        {
                            "lat": 55.39448525566136,
                            "lon": 61.49134336318938
                        },
                        {
                            "lat": 55.39089312439878,
                            "lon": 61.49180207922578
                        },
                        {
                            "lat": 55.38730087178999,
                            "lon": 61.492260702616434
                        },
                        {
                            "lat": 55.388260278256396,
                            "lon": 61.493977860375786
                        }
                    ],
                    [
                        {
                            "lat": 55.42417913703905,
                            "lon": 61.48938713172338
                        },
                        {
                            "lat": 55.42514046055591,
                            "lon": 61.49110395384029
                        },
                        {
                            "lat": 55.42873185775123,
                            "lon": 61.490644338920426
                        },
                        {
                            "lat": 55.42777035478066,
                            "lon": 61.48892754948147
                        },
                        {
                            "lat": 55.42417913703905,
                            "lon": 61.48938713172338
                        }
                    ],
                    [
                        {
                            "lat": 55.42802516053051,
                            "lon": 61.496254364936945
                        },
                        {
                            "lat": 55.4289869704007,
                            "lon": 61.49797115021257
                        },
                        {
                            "lat": 55.43257908559736,
                            "lon": 61.49751140454659
                        },
                        {
                            "lat": 55.43161709619887,
                            "lon": 61.49579465196265
                        },
                        {
                            "lat": 55.42802516053051,
                            "lon": 61.496254364936945
                        }
                    ],
                    [
                        {
                            "lat": 55.3822784102805,
                            "lon": 61.5039394128758
                        },
                        {
                            "lat": 55.38323800575254,
                            "lon": 61.50565661346184
                        },
                        {
                            "lat": 55.38683188065206,
                            "lon": 61.505198039763926
                        },
                        {
                            "lat": 55.38587210535755,
                            "lon": 61.50348087179986
                        },
                        {
                            "lat": 55.3822784102805,
                            "lon": 61.5039394128758
                        }
                    ],
                    [
                        {
                            "lat": 55.38683188065206,
                            "lon": 61.505198039763926
                        },
                        {
                            "lat": 55.38779177738484,
                            "lon": 61.506915198553386
                        },
                        {
                            "lat": 55.39138571066609,
                            "lon": 61.5064564995206
                        },
                        {
                            "lat": 55.39042563411331,
                            "lon": 61.5047393733622
                        },
                        {
                            "lat": 55.38683188065206,
                            "lon": 61.505198039763926
                        }
                    ],
                    [
                        {
                            "lat": 55.39497952246918,
                            "lon": 61.50599770778459
                        },
                        {
                            "lat": 55.39138571066609,
                            "lon": 61.5064564995206
                        },
                        {
                            "lat": 55.392345908697045,
                            "lon": 61.50817361649938
                        },
                        {
                            "lat": 55.39593990031765,
                            "lon": 61.507714792123274
                        },
                        {
                            "lat": 55.39497952246918,
                            "lon": 61.50599770778459
                        }
                    ],
                    [
                        {
                            "lat": 55.40312751898591,
                            "lon": 61.50679686526942
                        },
                        {
                            "lat": 55.40408837796135,
                            "lon": 61.50851387511952
                        },
                        {
                            "lat": 55.40768218474425,
                            "lon": 61.50805473999384
                        },
                        {
                            "lat": 55.406721145996165,
                            "lon": 61.50633776280417
                        },
                        {
                            "lat": 55.40312751898591,
                            "lon": 61.50679686526942
                        }
                    ],
                    [
                        {
                            "lat": 55.433797844795556,
                            "lon": 61.5065549383456
                        },
                        {
                            "lat": 55.437390857913655,
                            "lon": 61.506095029169714
                        },
                        {
                            "lat": 55.43642826000317,
                            "lon": 61.504378322689895
                        },
                        {
                            "lat": 55.432835426506685,
                            "lon": 61.50483819915694
                        },
                        {
                            "lat": 55.42924247128404,
                            "lon": 61.50529798297816
                        },
                        {
                            "lat": 55.43020470993009,
                            "lon": 61.50701475487009
                        },
                        {
                            "lat": 55.433797844795556,
                            "lon": 61.5065549383456
                        }
                    ],
                    [
                        {
                            "lat": 55.44098374926566,
                            "lon": 61.505635027348646
                        },
                        {
                            "lat": 55.441946648561256,
                            "lon": 61.50735169188503
                        },
                        {
                            "lat": 55.44553959772635,
                            "lon": 61.506891564701505
                        },
                        {
                            "lat": 55.44457651883296,
                            "lon": 61.505174932888636
                        },
                        {
                            "lat": 55.44098374926566,
                            "lon": 61.505635027348646
                        }
                    ],
                    [
                        {
                            "lat": 55.42885583844131,
                            "lon": 61.54582590867402
                        },
                        {
                            "lat": 55.42981967925795,
                            "lon": 61.54754267394696
                        },
                        {
                            "lat": 55.43341750389333,
                            "lon": 61.54708269386397
                        },
                        {
                            "lat": 55.43245348289464,
                            "lon": 61.54536596133621
                        },
                        {
                            "lat": 55.42885583844131,
                            "lon": 61.54582590867402
                        }
                    ],
                    [
                        {
                            "lat": 55.39106825522003,
                            "lon": 61.388765365706846
                        },
                        {
                            "lat": 55.39202375493309,
                            "lon": 61.39048251511163
                        },
                        {
                            "lat": 55.39560423042662,
                            "lon": 61.39002420381906
                        },
                        {
                            "lat": 55.39918458543377,
                            "lon": 61.389565800288594
                        },
                        {
                            "lat": 55.39822872920348,
                            "lon": 61.38784871592314
                        },
                        {
                            "lat": 55.39464855244447,
                            "lon": 61.388307086931135
                        },
                        {
                            "lat": 55.39106825522003,
                            "lon": 61.388765365706846
                        }
                    ],
                    [
                        {
                            "lat": 55.3839072994488,
                            "lon": 61.3896816465367
                        },
                        {
                            "lat": 55.38486244256093,
                            "lon": 61.39139886095846
                        },
                        {
                            "lat": 55.38844315897171,
                            "lon": 61.390940734160175
                        },
                        {
                            "lat": 55.38748783754864,
                            "lon": 61.389223552244104
                        },
                        {
                            "lat": 55.3839072994488,
                            "lon": 61.3896816465367
                        }
                    ],
                    [
                        {
                            "lat": 55.412548231215524,
                            "lon": 61.386014309692264
                        },
                        {
                            "lat": 55.413504800229155,
                            "lon": 61.38773126391203
                        },
                        {
                            "lat": 55.417084552527314,
                            "lon": 61.38727239928515
                        },
                        {
                            "lat": 55.41612780537016,
                            "lon": 61.385555477615796
                        },
                        {
                            "lat": 55.412548231215524,
                            "lon": 61.386014309692264
                        }
                    ],
                    [
                        {
                            "lat": 55.40396567457672,
                            "lon": 61.39815108489009
                        },
                        {
                            "lat": 55.404922254150186,
                            "lon": 61.39986811435436
                        },
                        {
                            "lat": 55.408503558404064,
                            "lon": 61.39940942335249
                        },
                        {
                            "lat": 55.407546800492455,
                            "lon": 61.39769242643669
                        },
                        {
                            "lat": 55.40396567457672,
                            "lon": 61.39815108489009
                        }
                    ],
                    [
                        {
                            "lat": 55.38821124976725,
                            "lon": 61.41120430870786
                        },
                        {
                            "lat": 55.38916748230276,
                            "lon": 61.412921478510874
                        },
                        {
                            "lat": 55.39275058123432,
                            "lon": 61.41246314603234
                        },
                        {
                            "lat": 55.391794170123774,
                            "lon": 61.41074600876466
                        },
                        {
                            "lat": 55.38821124976725,
                            "lon": 61.41120430870786
                        }
                    ],
                    [
                        {
                            "lat": 55.41353690120056,
                            "lon": 61.41532096734213
                        },
                        {
                            "lat": 55.40995387025148,
                            "lon": 61.415779859117954
                        },
                        {
                            "lat": 55.41091147852963,
                            "lon": 61.41749682953271
                        },
                        {
                            "lat": 55.41449468802292,
                            "lon": 61.417037905179946
                        },
                        {
                            "lat": 55.41545259568562,
                            "lon": 61.41875483384656
                        },
                        {
                            "lat": 55.419035862880285,
                            "lon": 61.4182957845867
                        },
                        {
                            "lat": 55.42261900919511,
                            "lon": 61.41783664300628
                        },
                        {
                            "lat": 55.421660744469996,
                            "lon": 61.41611977951717
                        },
                        {
                            "lat": 55.42524359138674,
                            "lon": 61.4156605782196
                        },
                        {
                            "lat": 55.42882631740763,
                            "lon": 61.41520128461949
                        },
                        {
                            "lat": 55.42786781661854,
                            "lon": 61.413484477145424
                        },
                        {
                            "lat": 55.43145024328589,
                            "lon": 61.41302512385393
                        },
                        {
                            "lat": 55.430491684969475,
                            "lon": 61.411308339798076
                        },
                        {
                            "lat": 55.434073812325316,
                            "lon": 61.41084892682916
                        },
                        {
                            "lat": 55.435032549041395,
                            "lon": 61.41256567827794
                        },
                        {
                            "lat": 55.4359914066878,
                            "lon": 61.4142824205366
                        },
                        {
                            "lat": 55.4395737699101,
                            "lon": 61.41382285006621
                        },
                        {
                            "lat": 55.43861473386655,
                            "lon": 61.41210614042365
                        },
                        {
                            "lat": 55.44219679774289,
                            "lon": 61.41164651029731
                        },
                        {
                            "lat": 55.443156012162525,
                            "lon": 61.41336318731818
                        },
                        {
                            "lat": 55.44411534757052,
                            "lon": 61.41507985514054
                        },
                        {
                            "lat": 55.44507480398788,
                            "lon": 61.41679651376281
                        },
                        {
                            "lat": 55.44865728201759,
                            "lon": 61.416336693478534
                        },
                        {
                            "lat": 55.45223963899847,
                            "lon": 61.41587678091785
                        },
                        {
                            "lat": 55.45582187491198,
                            "lon": 61.41541677608697
                        },
                        {
                            "lat": 55.45940398973966,
                            "lon": 61.41495667899213
                        },
                        {
                            "lat": 55.458443819880316,
                            "lon": 61.41324015093976
                        },
                        {
                            "lat": 55.45486188338158,
                            "lon": 61.41370021538376
                        },
                        {
                            "lat": 55.453902012915975,
                            "lon": 61.411983645470215
                        },
                        {
                            "lat": 55.45748377110424,
                            "lon": 61.41152361367368
                        },
                        {
                            "lat": 55.46106540823029,
                            "lon": 61.41106348963058
                        },
                        {
                            "lat": 55.46464692427566,
                            "lon": 61.410603273347085
                        },
                        {
                            "lat": 55.46822831922189,
                            "lon": 61.41014296482948
                        },
                        {
                            "lat": 55.47180959305056,
                            "lon": 61.40968256408395
                        },
                        {
                            "lat": 55.47084895237892,
                            "lon": 61.407966148237634
                        },
                        {
                            "lat": 55.467267856758156,
                            "lon": 61.4084265163168
                        },
                        {
                            "lat": 55.46630751539096,
                            "lon": 61.4067100585833
                        },
                        {
                            "lat": 55.469888432822295,
                            "lon": 61.40624972316706
                        },
                        {
                            "lat": 55.468928034359614,
                            "lon": 61.404533288873786
                        },
                        {
                            "lat": 55.46796775696984,
                            "lon": 61.40281684535935
                        },
                        {
                            "lat": 55.46438719586209,
                            "lon": 61.40327711545995
                        },
                        {
                            "lat": 55.46342721765835,
                            "lon": 61.4015606300733
                        },
                        {
                            "lat": 55.45984671367227,
                            "lon": 61.40202077532119
                        },
                        {
                            "lat": 55.45626608869215,
                            "lon": 61.40248082836282
                        },
                        {
                            "lat": 55.45268534273649,
                            "lon": 61.40294078919202
                        },
                        {
                            "lat": 55.45172602001834,
                            "lon": 61.401224196676424
                        },
                        {
                            "lat": 55.44814533130529,
                            "lon": 61.40168403265996
                        },
                        {
                            "lat": 55.444564521674565,
                            "lon": 61.40214377642423
                        },
                        {
                            "lat": 55.440983591144644,
                            "lon": 61.402603427963015
                        },
                        {
                            "lat": 55.441942379200604,
                            "lon": 61.404320118342774
                        },
                        {
                            "lat": 55.442901288158296,
                            "lon": 61.406036799528394
                        },
                        {
                            "lat": 55.4393198802037,
                            "lon": 61.406496424059284
                        },
                        {
                            "lat": 55.435738351344824,
                            "lon": 61.40695595634106
                        },
                        {
                            "lat": 55.43215670160016,
                            "lon": 61.407415396367526
                        },
                        {
                            "lat": 55.42857493098815,
                            "lon": 61.40787474413246
                        },
                        {
                            "lat": 55.42761673528126,
                            "lon": 61.40615793252585
                        },
                        {
                            "lat": 55.42403502218562,
                            "lon": 61.406617155431775
                        },
                        {
                            "lat": 55.420453188280625,
                            "lon": 61.40707628606937
                        },
                        {
                            "lat": 55.42141102723613,
                            "lon": 61.40879316285293
                        },
                        {
                            "lat": 55.417828894133095,
                            "lon": 61.40925223379604
                        },
                        {
                            "lat": 55.41878667547479,
                            "lon": 61.41096913398791
                        },
                        {
                            "lat": 55.41520424313174,
                            "lon": 61.41142814522258
                        },
                        {
                            "lat": 55.4142466402367,
                            "lon": 61.409711212452784
                        },
                        {
                            "lat": 55.413289158116534,
                            "lon": 61.40799427051465
                        },
                        {
                            "lat": 55.40970696189442,
                            "lon": 61.408453124309915
                        },
                        {
                            "lat": 55.406124644936966,
                            "lon": 61.408911885812024
                        },
                        {
                            "lat": 55.40708177013784,
                            "lon": 61.41062889288242
                        },
                        {
                            "lat": 55.41066426556545,
                            "lon": 61.410170098817005
                        },
                        {
                            "lat": 55.411621689992835,
                            "lon": 61.411887064159096
                        },
                        {
                            "lat": 55.412579235197526,
                            "lon": 61.413604020334674
                        },
                        {
                            "lat": 55.41353690120056,
                            "lon": 61.41532096734213
                        },
                        {
                            "lat": 55.462726476884114,
                            "lon": 61.40717030178275
                        },
                        {
                            "lat": 55.4591453173202,
                            "lon": 61.40763045275925
                        },
                        {
                            "lat": 55.460105302245424,
                            "lon": 61.40934697580193
                        },
                        {
                            "lat": 55.45652384339036,
                            "lon": 61.40980706719541
                        },
                        {
                            "lat": 55.45294226349411,
                            "lon": 61.410267066347934
                        },
                        {
                            "lat": 55.45198263509498,
                            "lon": 61.40855047801846
                        },
                        {
                            "lat": 55.45102312769753,
                            "lon": 61.406833880483354
                        },
                        {
                            "lat": 55.45460435106513,
                            "lon": 61.406373946608646
                        },
                        {
                            "lat": 55.45364478641176,
                            "lon": 61.404657372503294
                        },
                        {
                            "lat": 55.45722571056459,
                            "lon": 61.404197379038145
                        },
                        {
                            "lat": 55.4581854534336,
                            "lon": 61.40591392050415
                        },
                        {
                            "lat": 55.46176643478446,
                            "lon": 61.405453802176
                        },
                        {
                            "lat": 55.465347295099285,
                            "lon": 61.40499359163048
                        },
                        {
                            "lat": 55.46630751539096,
                            "lon": 61.4067100585833
                        },
                        {
                            "lat": 55.462726476884114,
                            "lon": 61.40717030178275
                        },
                        {
                            "lat": 55.451279825818055,
                            "lon": 61.41416018756935
                        },
                        {
                            "lat": 55.447697647208216,
                            "lon": 61.41462006749035
                        },
                        {
                            "lat": 55.44673813342664,
                            "lon": 61.41290343229868
                        },
                        {
                            "lat": 55.45032013368395,
                            "lon": 61.41244358501395
                        },
                        {
                            "lat": 55.451279825818055,
                            "lon": 61.41416018756935
                        },
                        {
                            "lat": 55.44552348797226,
                            "lon": 61.40386043418821
                        },
                        {
                            "lat": 55.4491044758237,
                            "lon": 61.40340065780256
                        },
                        {
                            "lat": 55.45006374128078,
                            "lon": 61.405117273744196
                        },
                        {
                            "lat": 55.44648257519014,
                            "lon": 61.40557708275465
                        },
                        {
                            "lat": 55.44552348797226,
                            "lon": 61.40386043418821
                        }
                    ],
                    [
                        {
                            "lat": 55.40708177013784,
                            "lon": 61.41062889288242
                        },
                        {
                            "lat": 55.40349915397242,
                            "lon": 61.41108759464286
                        },
                        {
                            "lat": 55.40254220726265,
                            "lon": 61.40937055501477
                        },
                        {
                            "lat": 55.39895964889,
                            "lon": 61.40982913191191
                        },
                        {
                            "lat": 55.39991641708766,
                            "lon": 61.411546204092126
                        },
                        {
                            "lat": 55.400873305986124,
                            "lon": 61.413263267117635
                        },
                        {
                            "lat": 55.40183031560637,
                            "lon": 61.414980320986786
                        },
                        {
                            "lat": 55.40541340957089,
                            "lon": 61.41452164642304
                        },
                        {
                            "lat": 55.408996382774106,
                            "lon": 61.41406287953692
                        },
                        {
                            "lat": 55.40803901607658,
                            "lon": 61.41234589079124
                        },
                        {
                            "lat": 55.40708177013784,
                            "lon": 61.41062889288242
                        }
                    ],
                    [
                        {
                            "lat": 55.408285698730765,
                            "lon": 61.41967265537986
                        },
                        {
                            "lat": 55.409243370071295,
                            "lon": 61.421389640035656
                        },
                        {
                            "lat": 55.412827057572386,
                            "lon": 61.42093074285731
                        },
                        {
                            "lat": 55.41186920762961,
                            "lon": 61.419213790779686
                        },
                        {
                            "lat": 55.408285698730765,
                            "lon": 61.41967265537986
                        }
                    ],
                    [
                        {
                            "lat": 55.44668701972721,
                            "lon": 61.48834487552706
                        },
                        {
                            "lat": 55.443096230594215,
                            "lon": 61.48880495335747
                        },
                        {
                            "lat": 55.44213401002062,
                            "lon": 61.48708829468649
                        },
                        {
                            "lat": 55.43854327860687,
                            "lon": 61.48754824724685
                        },
                        {
                            "lat": 55.43950531981149,
                            "lon": 61.48926493861825
                        },
                        {
                            "lat": 55.43591428739766,
                            "lon": 61.489724831303164
                        },
                        {
                            "lat": 55.436876270824584,
                            "lon": 61.491441546151115
                        },
                        {
                            "lat": 55.44046748264721,
                            "lon": 61.49098162076795
                        },
                        {
                            "lat": 55.44142976713521,
                            "lon": 61.492698293694374
                        },
                        {
                            "lat": 55.44502103671176,
                            "lon": 61.49223824302248
                        },
                        {
                            "lat": 55.44405857281752,
                            "lon": 61.490521602803305
                        },
                        {
                            "lat": 55.44764954131691,
                            "lon": 61.490061492263436
                        },
                        {
                            "lat": 55.45124038812677,
                            "lon": 61.489601289154585
                        },
                        {
                            "lat": 55.45220321076983,
                            "lon": 61.491317863942335
                        },
                        {
                            "lat": 55.45579411521413,
                            "lon": 61.4908575355466
                        },
                        {
                            "lat": 55.45483111322845,
                            "lon": 61.48914099348297
                        },
                        {
                            "lat": 55.45842171660336,
                            "lon": 61.488680605254885
                        },
                        {
                            "lat": 55.45938489791043,
                            "lon": 61.49039711458878
                        },
                        {
                            "lat": 55.4603482009841,
                            "lon": 61.492113614680655
                        },
                        {
                            "lat": 55.4639390412326,
                            "lon": 61.491653068428214
                        },
                        {
                            "lat": 55.46752975967467,
                            "lon": 61.49119242962055
                        },
                        {
                            "lat": 55.47112035629171,
                            "lon": 61.49073169826396
                        },
                        {
                            "lat": 55.470156515325314,
                            "lon": 61.48901529640524
                        },
                        {
                            "lat": 55.47374681084361,
                            "lon": 61.48855450526154
                        },
                        {
                            "lat": 55.47733698452088,
                            "lon": 61.488093621587005
                        },
                        {
                            "lat": 55.480927036338585,
                            "lon": 61.48763264538788
                        },
                        {
                            "lat": 55.47996277954862,
                            "lon": 61.48591633255021
                        },
                        {
                            "lat": 55.48355253031528,
                            "lon": 61.48545529660175
                        },
                        {
                            "lat": 55.48714215920641,
                            "lon": 61.48499416814686
                        },
                        {
                            "lat": 55.490731666203466,
                            "lon": 61.484532947191724
                        },
                        {
                            "lat": 55.48976699387121,
                            "lon": 61.48281672340646
                        },
                        {
                            "lat": 55.493356199864984,
                            "lon": 61.48235544273975
                        },
                        {
                            "lat": 55.496945283948754,
                            "lon": 61.48189406959097
                        },
                        {
                            "lat": 55.50053424610398,
                            "lon": 61.48143260396633
                        },
                        {
                            "lat": 55.50412308631213,
                            "lon": 61.48097104587216
                        },
                        {
                            "lat": 55.50315781971037,
                            "lon": 61.479254943966374
                        },
                        {
                            "lat": 55.49956915851062,
                            "lon": 61.47971646926483
                        },
                        {
                            "lat": 55.49598037538502,
                            "lon": 61.48017790209934
                        },
                        {
                            "lat": 55.49239147035208,
                            "lon": 61.48063924246362
                        },
                        {
                            "lat": 55.48880244343039,
                            "lon": 61.4811004903514
                        },
                        {
                            "lat": 55.485213294638484,
                            "lon": 61.481561645756436
                        },
                        {
                            "lat": 55.48162402399493,
                            "lon": 61.482022708672474
                        },
                        {
                            "lat": 55.48258821622796,
                            "lon": 61.48373900726857
                        },
                        {
                            "lat": 55.4789986446156,
                            "lon": 61.48420001045148
                        },
                        {
                            "lat": 55.478034631518305,
                            "lon": 61.48248367909325
                        },
                        {
                            "lat": 55.47444511722716,
                            "lon": 61.48294455701253
                        },
                        {
                            "lat": 55.4708554811401,
                            "lon": 61.48340534242405
                        },
                        {
                            "lat": 55.4718191359023,
                            "lon": 61.48512173928967
                        },
                        {
                            "lat": 55.46822919883852,
                            "lon": 61.48558246493244
                        },
                        {
                            "lat": 55.467265723275666,
                            "lon": 61.48386603532153
                        },
                        {
                            "lat": 55.46367584365249,
                            "lon": 61.48432663569875
                        },
                        {
                            "lat": 55.460085842289104,
                            "lon": 61.484787143549454
                        },
                        {
                            "lat": 55.45649571920416,
                            "lon": 61.4852475588674
                        },
                        {
                            "lat": 55.455532903069646,
                            "lon": 61.48353102181695
                        },
                        {
                            "lat": 55.4519428375473,
                            "lon": 61.48399131187644
                        },
                        {
                            "lat": 55.44835265036176,
                            "lon": 61.48445150939626
                        },
                        {
                            "lat": 55.44931510794393,
                            "lon": 61.48616811187997
                        },
                        {
                            "lat": 55.44572461980586,
                            "lon": 61.486628249562116
                        },
                        {
                            "lat": 55.44668701972721,
                            "lon": 61.48834487552706
                        },
                        {
                            "lat": 55.46201219823286,
                            "lon": 61.488220124476584
                        },
                        {
                            "lat": 55.46104895938961,
                            "lon": 61.486503638634176
                        },
                        {
                            "lat": 55.464639139994745,
                            "lon": 61.48604309804937
                        },
                        {
                            "lat": 55.46560255809838,
                            "lon": 61.48775955115428
                        },
                        {
                            "lat": 55.46201219823286,
                            "lon": 61.488220124476584
                        }
                    ],
                    [
                        {
                            "lat": 55.4663023694716,
                            "lon": 61.482149596463024
                        },
                        {
                            "lat": 55.467265723275666,
                            "lon": 61.48386603532153
                        },
                        {
                            "lat": 55.4708554811401,
                            "lon": 61.48340534242405
                        },
                        {
                            "lat": 55.46989194815525,
                            "lon": 61.4816889363074
                        },
                        {
                            "lat": 55.4663023694716,
                            "lon": 61.482149596463024
                        }
                    ],
                    [
                        {
                            "lat": 55.46805901171793,
                            "lon": 61.505845282514244
                        },
                        {
                            "lat": 55.47165128718691,
                            "lon": 61.505384474227775
                        },
                        {
                            "lat": 55.47068682974854,
                            "lon": 61.503668080895
                        },
                        {
                            "lat": 55.46709473374731,
                            "lon": 61.50412885641519
                        },
                        {
                            "lat": 55.46350251580961,
                            "lon": 61.5045895393346
                        },
                        {
                            "lat": 55.46446661429126,
                            "lon": 61.50630599819435
                        },
                        {
                            "lat": 55.46805901171793,
                            "lon": 61.505845282514244
                        }
                    ],
                    [
                        {
                            "lat": 55.48462693306714,
                            "lon": 61.51476035190863
                        },
                        {
                            "lat": 55.485592603919,
                            "lon": 61.51647661479311
                        },
                        {
                            "lat": 55.489185769751984,
                            "lon": 61.51601529914684
                        },
                        {
                            "lat": 55.48821991936584,
                            "lon": 61.51429906906958
                        },
                        {
                            "lat": 55.48462693306714,
                            "lon": 61.51476035190863
                        }
                    ],
                    [
                        {
                            "lat": 55.456842880969226,
                            "lon": 61.52016380486961
                        },
                        {
                            "lat": 55.4532486163445,
                            "lon": 61.52062428664847
                        },
                        {
                            "lat": 55.454212792424514,
                            "lon": 61.522340835298714
                        },
                        {
                            "lat": 55.457807236793776,
                            "lon": 61.52188032075975
                        },
                        {
                            "lat": 55.456842880969226,
                            "lon": 61.52016380486961
                        }
                    ],
                    [
                        {
                            "lat": 55.4604370235806,
                            "lon": 61.519703230413754
                        },
                        {
                            "lat": 55.46140155912838,
                            "lon": 61.521419713538144
                        },
                        {
                            "lat": 55.464995759409696,
                            "lon": 61.52095901364021
                        },
                        {
                            "lat": 55.46403104415995,
                            "lon": 61.51924256328716
                        },
                        {
                            "lat": 55.4604370235806,
                            "lon": 61.519703230413754
                        }
                    ],
                    [
                        {
                            "lat": 55.42686580430269,
                            "lon": 61.38417842824054
                        },
                        {
                            "lat": 55.42782308576469,
                            "lon": 61.385895252225225
                        },
                        {
                            "lat": 55.43140235556327,
                            "lon": 61.38543601883277
                        },
                        {
                            "lat": 55.43044489604155,
                            "lon": 61.3837192274208
                        },
                        {
                            "lat": 55.42686580430269,
                            "lon": 61.38417842824054
                        }
                    ],
                    [
                        {
                            "lat": 55.45549515897965,
                            "lon": 61.380502241261254
                        },
                        {
                            "lat": 55.45191691217825,
                            "lon": 61.38096208714293
                        },
                        {
                            "lat": 55.452875439618396,
                            "lon": 61.38267868300142
                        },
                        {
                            "lat": 55.456453864332865,
                            "lon": 61.38221880450799
                        },
                        {
                            "lat": 55.45549515897965,
                            "lon": 61.380502241261254
                        }
                    ],
                    [
                        {
                            "lat": 55.45837163746018,
                            "lon": 61.38565190339744
                        },
                        {
                            "lat": 55.45741269048604,
                            "lon": 61.38393535855388
                        },
                        {
                            "lat": 55.45383408784011,
                            "lon": 61.384395269662484
                        },
                        {
                            "lat": 55.45025536439165,
                            "lon": 61.38485508862688
                        },
                        {
                            "lat": 55.451213955445056,
                            "lon": 61.386571698702014
                        },
                        {
                            "lat": 55.45479285686436,
                            "lon": 61.38611184712464
                        },
                        {
                            "lat": 55.45837163746018,
                            "lon": 61.38565190339744
                        }
                    ],
                    [
                        {
                            "lat": 55.440729865265865,
                            "lon": 61.395277053424415
                        },
                        {
                            "lat": 55.43714964751829,
                            "lon": 61.3957365745341
                        },
                        {
                            "lat": 55.43810795217723,
                            "lon": 61.39745330167469
                        },
                        {
                            "lat": 55.44168834809273,
                            "lon": 61.39699374796283
                        },
                        {
                            "lat": 55.440729865265865,
                            "lon": 61.395277053424415
                        }
                    ],
                    [
                        {
                            "lat": 55.467007600632,
                            "lon": 61.40110039262539
                        },
                        {
                            "lat": 55.46796775696984,
                            "lon": 61.40281684535935
                        },
                        {
                            "lat": 55.471548197025726,
                            "lon": 61.402356483059386
                        },
                        {
                            "lat": 55.47058786257476,
                            "lon": 61.40064006298368
                        },
                        {
                            "lat": 55.467007600632,
                            "lon": 61.40110039262539
                        }
                    ],
                    [
                        {
                            "lat": 55.45460435106513,
                            "lon": 61.406373946608646
                        },
                        {
                            "lat": 55.45556403671767,
                            "lon": 61.408090511506565
                        },
                        {
                            "lat": 55.4591453173202,
                            "lon": 61.40763045275925
                        },
                        {
                            "lat": 55.4581854534336,
                            "lon": 61.40591392050415
                        },
                        {
                            "lat": 55.45460435106513,
                            "lon": 61.406373946608646
                        }
                    ],
                    [
                        {
                            "lat": 55.47539074574318,
                            "lon": 61.409222071116716
                        },
                        {
                            "lat": 55.478971777281345,
                            "lon": 61.40876148593402
                        },
                        {
                            "lat": 55.4825526876466,
                            "lon": 61.40830080854208
                        },
                        {
                            "lat": 55.48351398400698,
                            "lon": 61.41001711711975
                        },
                        {
                            "lat": 55.47993289547852,
                            "lon": 61.410477827198214
                        },
                        {
                            "lat": 55.476351685756114,
                            "lon": 61.410938445061866
                        },
                        {
                            "lat": 55.472770354858206,
                            "lon": 61.41139897070444
                        },
                        {
                            "lat": 55.46918890280321,
                            "lon": 61.411859404119745
                        },
                        {
                            "lat": 55.4656073296096,
                            "lon": 61.41231974530156
                        },
                        {
                            "lat": 55.46656785606371,
                            "lon": 61.41403620803544
                        },
                        {
                            "lat": 55.470149607523155,
                            "lon": 61.413575834186005
                        },
                        {
                            "lat": 55.47373123782292,
                            "lon": 61.413115368097515
                        },
                        {
                            "lat": 55.477312746944555,
                            "lon": 61.412654809776164
                        },
                        {
                            "lat": 55.47827392932957,
                            "lon": 61.41437116525809
                        },
                        {
                            "lat": 55.48185549547576,
                            "lon": 61.41391048202234
                        },
                        {
                            "lat": 55.4854369403859,
                            "lon": 61.413449706560584
                        },
                        {
                            "lat": 55.48901826404156,
                            "lon": 61.41298883887909
                        },
                        {
                            "lat": 55.49259946642427,
                            "lon": 61.412527878984086
                        },
                        {
                            "lat": 55.49356148285875,
                            "lon": 61.41424409443385
                        },
                        {
                            "lat": 55.49714274210541,
                            "lon": 61.41378300961803
                        },
                        {
                            "lat": 55.49618054751562,
                            "lon": 61.41206682688178
                        },
                        {
                            "lat": 55.49521847423563,
                            "lon": 61.41035063489449
                        },
                        {
                            "lat": 55.49879925590138,
                            "lon": 61.40988952330675
                        },
                        {
                            "lat": 55.4997615072972,
                            "lon": 61.411605682578404
                        },
                        {
                            "lat": 55.50334234575058,
                            "lon": 61.41114444608018
                        },
                        {
                            "lat": 55.50692306285734,
                            "lon": 61.410683117393354
                        },
                        {
                            "lat": 55.51050365859909,
                            "lon": 61.410221696524175
                        },
                        {
                            "lat": 55.5140841329574,
                            "lon": 61.40976018347884
                        },
                        {
                            "lat": 55.515047218007595,
                            "lon": 61.41147620256396
                        },
                        {
                            "lat": 55.51601042448068,
                            "lon": 61.41319221237935
                        },
                        {
                            "lat": 55.51242959400412,
                            "lon": 61.413653790910836
                        },
                        {
                            "lat": 55.50884864210196,
                            "lon": 61.41411527725506
                        },
                        {
                            "lat": 55.509811613824496,
                            "lon": 61.41583134328999
                        },
                        {
                            "lat": 55.51339274383448,
                            "lon": 61.41536982420313
                        },
                        {
                            "lat": 55.51435601511151,
                            "lon": 61.417085848225916
                        },
                        {
                            "lat": 55.517937201779944,
                            "lon": 61.416624204194584
                        },
                        {
                            "lat": 55.518900772648294,
                            "lon": 61.41834018619131
                        },
                        {
                            "lat": 55.52248201593301,
                            "lon": 61.41787841720715
                        },
                        {
                            "lat": 55.526063137692034,
                            "lon": 61.41741655603147
                        },
                        {
                            "lat": 55.52964413790696,
                            "lon": 61.41695460267052
                        },
                        {
                            "lat": 55.53060836458182,
                            "lon": 61.41867047708228
                        },
                        {
                            "lat": 55.53418942129182,
                            "lon": 61.41820839876152
                        },
                        {
                            "lat": 55.53322501655942,
                            "lon": 61.41649255713052
                        },
                        {
                            "lat": 55.53226073340792,
                            "lon": 61.41477670620978
                        },
                        {
                            "lat": 55.53584131246148,
                            "lon": 61.4143146012799
                        },
                        {
                            "lat": 55.53680577363101,
                            "lon": 61.41603041941772
                        },
                        {
                            "lat": 55.53777035639981,
                            "lon": 61.41774622826241
                        },
                        {
                            "lat": 55.54135116988741,
                            "lon": 61.41728396559117
                        },
                        {
                            "lat": 55.54493186173628,
                            "lon": 61.416821610754035
                        },
                        {
                            "lat": 55.54589692217167,
                            "lon": 61.418537344707715
                        },
                        {
                            "lat": 55.549477670354825,
                            "lon": 61.418074864904504
                        },
                        {
                            "lat": 55.5504430304783,
                            "lon": 61.419790556745085
                        },
                        {
                            "lat": 55.55402383495348,
                            "lon": 61.419327951967475
                        },
                        {
                            "lat": 55.55498949480177,
                            "lon": 61.42104360168095
                        },
                        {
                            "lat": 55.55857035552671,
                            "lon": 61.42058087192062
                        },
                        {
                            "lat": 55.562151094476,
                            "lon": 61.42011805000274
                        },
                        {
                            "lat": 55.561185078677035,
                            "lon": 61.41840246594374
                        },
                        {
                            "lat": 55.56021918465095,
                            "lon": 61.416686872566245
                        },
                        {
                            "lat": 55.563799445937335,
                            "lon": 61.41622402416479
                        },
                        {
                            "lat": 55.564765517888695,
                            "lon": 61.41793958471012
                        },
                        {
                            "lat": 55.56573171163128,
                            "lon": 61.41965513593353
                        },
                        {
                            "lat": 55.56931220697415,
                            "lon": 61.41919212971925
                        },
                        {
                            "lat": 55.5728925804863,
                            "lon": 61.41872903136618
                        },
                        {
                            "lat": 55.57647283214936,
                            "lon": 61.41826584088054
                        },
                        {
                            "lat": 55.577439681463375,
                            "lon": 61.41998128423
                        },
                        {
                            "lat": 55.57840665266598,
                            "lon": 61.421696718244064
                        },
                        {
                            "lat": 55.57937374577835,
                            "lon": 61.42341214292114
                        },
                        {
                            "lat": 55.58295440926111,
                            "lon": 61.42294876171513
                        },
                        {
                            "lat": 55.58198713823468,
                            "lon": 61.42123336990618
                        },
                        {
                            "lat": 55.58101998913635,
                            "lon": 61.419517968756814
                        },
                        {
                            "lat": 55.584600174902405,
                            "lon": 61.419054561158035
                        },
                        {
                            "lat": 55.58556750187528,
                            "lon": 61.42076992943714
                        },
                        {
                            "lat": 55.58653495079462,
                            "lon": 61.42248528837241
                        },
                        {
                            "lat": 55.59011537036049,
                            "lon": 61.42202172289925
                        },
                        {
                            "lat": 55.59108311913751,
                            "lon": 61.42373703960636
                        },
                        {
                            "lat": 55.59205098992165,
                            "lon": 61.42545234696301
                        },
                        {
                            "lat": 55.59563164325769,
                            "lon": 61.42498862358573
                        },
                        {
                            "lat": 55.59921217454706,
                            "lon": 61.424524808079404
                        },
                        {
                            "lat": 55.60279258377146,
                            "lon": 61.4240609004503
                        },
                        {
                            "lat": 55.603761110237045,
                            "lon": 61.42577609975285
                        },
                        {
                            "lat": 55.6073415752201,
                            "lon": 61.42531206709545
                        },
                        {
                            "lat": 55.61092191808032,
                            "lon": 61.42484794232225
                        },
                        {
                            "lat": 55.61189092234984,
                            "lon": 61.42656306642487
                        },
                        {
                            "lat": 55.6083104016505,
                            "lon": 61.42702722411884
                        },
                        {
                            "lat": 55.60472975880712,
                            "lon": 61.42749128969146
                        },
                        {
                            "lat": 55.60569852950292,
                            "lon": 61.42920647026449
                        },
                        {
                            "lat": 55.60666742234563,
                            "lon": 61.430921641470384
                        },
                        {
                            "lat": 55.61024842096462,
                            "lon": 61.43045751005699
                        },
                        {
                            "lat": 55.61121761389076,
                            "lon": 61.432172638968574
                        },
                        {
                            "lat": 55.61218692902457,
                            "lon": 61.43388775850637
                        },
                        {
                            "lat": 55.61315636638724,
                            "lon": 61.43560286866878
                        },
                        {
                            "lat": 55.614125926000035,
                            "lon": 61.43731796945422
                        },
                        {
                            "lat": 55.615095607884186,
                            "lon": 61.439033060861085
                        },
                        {
                            "lat": 55.611513719505254,
                            "lon": 61.43949735693659
                        },
                        {
                            "lat": 55.61248334567497,
                            "lon": 61.44121247190605
                        },
                        {
                            "lat": 55.61345309414013,
                            "lon": 61.44292757749716
                        },
                        {
                            "lat": 55.61442296492199,
                            "lon": 61.444642673708366
                        },
                        {
                            "lat": 55.615392958041824,
                            "lon": 61.44635776053807
                        },
                        {
                            "lat": 55.616363073520844,
                            "lon": 61.44807283798464
                        },
                        {
                            "lat": 55.61278017254295,
                            "lon": 61.44853720661407
                        },
                        {
                            "lat": 55.609197149202956,
                            "lon": 61.4490014830429
                        },
                        {
                            "lat": 55.605614003519214,
                            "lon": 61.44946566726484
                        },
                        {
                            "lat": 55.60203073551011,
                            "lon": 61.44992975927365
                        },
                        {
                            "lat": 55.598447345194,
                            "lon": 61.45039375906301
                        },
                        {
                            "lat": 55.59486383258928,
                            "lon": 61.45085766662665
                        },
                        {
                            "lat": 55.59128019771433,
                            "lon": 61.45132148195833
                        },
                        {
                            "lat": 55.59224918828735,
                            "lon": 61.453036780584355
                        },
                        {
                            "lat": 55.58866525289176,
                            "lon": 61.45350053659325
                        },
                        {
                            "lat": 55.58963418745069,
                            "lon": 61.455215858775986
                        },
                        {
                            "lat": 55.59321830113345,
                            "lon": 61.45475206984819
                        },
                        {
                            "lat": 55.594187536273886,
                            "lon": 61.45646734974825
                        },
                        {
                            "lat": 55.59777170594661,
                            "lon": 61.456003435643126
                        },
                        {
                            "lat": 55.59874124170536,
                            "lon": 61.45771867324643
                        },
                        {
                            "lat": 55.59971089981935,
                            "lon": 61.459433901479336
                        },
                        {
                            "lat": 55.60329530373939,
                            "lon": 61.458969829248176
                        },
                        {
                            "lat": 55.60687958526451,
                            "lon": 61.45850566476351
                        },
                        {
                            "lat": 55.61046374437634,
                            "lon": 61.458041408031534
                        },
                        {
                            "lat": 55.61404778105648,
                            "lon": 61.45757705905862
                        },
                        {
                            "lat": 55.61307740987073,
                            "lon": 61.45586196262069
                        },
                        {
                            "lat": 55.616661145886034,
                            "lon": 61.45539755437574
                        },
                        {
                            "lat": 55.62024475945418,
                            "lon": 61.45493305390794
                        },
                        {
                            "lat": 55.61927415432574,
                            "lon": 61.45321801400976
                        },
                        {
                            "lat": 55.61830367164154,
                            "lon": 61.451502964722096
                        },
                        {
                            "lat": 55.62188680645485,
                            "lon": 61.45103843797494
                        },
                        {
                            "lat": 55.625469818826815,
                            "lon": 61.45057381902864
                        },
                        {
                            "lat": 55.624499102352644,
                            "lon": 61.448858826285495
                        },
                        {
                            "lat": 55.6235285083169,
                            "lon": 61.447143824149116
                        },
                        {
                            "lat": 55.62711104209835,
                            "lon": 61.44667917895559
                        },
                        {
                            "lat": 55.6261403924419,
                            "lon": 61.444964200395304
                        },
                        {
                            "lat": 55.62972262577123,
                            "lon": 61.44449949599951
                        },
                        {
                            "lat": 55.6287519205307,
                            "lon": 61.44278454101554
                        },
                        {
                            "lat": 55.63233385345044,
                            "lon": 61.44231977743142
                        },
                        {
                            "lat": 55.63591566394049,
                            "lon": 61.44185492169552
                        },
                        {
                            "lat": 55.634944725215185,
                            "lon": 61.440140023265556
                        },
                        {
                            "lat": 55.63852623534107,
                            "lon": 61.43967510836712
                        },
                        {
                            "lat": 55.63755524114462,
                            "lon": 61.43796023351605
                        },
                        {
                            "lat": 55.63397390891659,
                            "lon": 61.43842511543495
                        },
                        {
                            "lat": 55.63039245428309,
                            "lon": 61.43888990521947
                        },
                        {
                            "lat": 55.631363092662575,
                            "lon": 61.440604846024016
                        },
                        {
                            "lat": 55.62778133770154,
                            "lon": 61.44106957663625
                        },
                        {
                            "lat": 55.626810877262436,
                            "lon": 61.43935460286326
                        },
                        {
                            "lat": 55.625840539192154,
                            "lon": 61.437639619698174
                        },
                        {
                            "lat": 55.624870323469445,
                            "lon": 61.4359246271426
                        },
                        {
                            "lat": 55.62128897994821,
                            "lon": 61.436389166724034
                        },
                        {
                            "lat": 55.62031906445846,
                            "lon": 61.434674131826995
                        },
                        {
                            "lat": 55.61934927125548,
                            "lon": 61.43295908754611
                        },
                        {
                            "lat": 55.61837960031805,
                            "lon": 61.43124403388296
                        },
                        {
                            "lat": 55.61741005162494,
                            "lon": 61.42952897083913
                        },
                        {
                            "lat": 55.61644062515494,
                            "lon": 61.427813898416204
                        },
                        {
                            "lat": 55.61547132088682,
                            "lon": 61.42609881661581
                        },
                        {
                            "lat": 55.61450213879938,
                            "lon": 61.424383725439526
                        },
                        {
                            "lat": 55.61808223735899,
                            "lon": 61.42391941645354
                        },
                        {
                            "lat": 55.619051597243114,
                            "lon": 61.42563447469794
                        },
                        {
                            "lat": 55.62263175140043,
                            "lon": 61.425170040677536
                        },
                        {
                            "lat": 55.62166221374076,
                            "lon": 61.42345501537056
                        },
                        {
                            "lat": 55.62069279827723,
                            "lon": 61.42173998068244
                        },
                        {
                            "lat": 55.62427247472694,
                            "lon": 61.42127552044828
                        },
                        {
                            "lat": 55.623303003720586,
                            "lon": 61.419560509316646
                        },
                        {
                            "lat": 55.61972350498857,
                            "lon": 61.420024936614716
                        },
                        {
                            "lat": 55.61614388410285,
                            "lon": 61.42048927183316
                        },
                        {
                            "lat": 55.615174890688465,
                            "lon": 61.41877418546034
                        },
                        {
                            "lat": 55.61159532540914,
                            "lon": 61.41923839567129
                        },
                        {
                            "lat": 55.610626631832424,
                            "lon": 61.41752326700744
                        },
                        {
                            "lat": 55.61420601938828,
                            "lon": 61.41705908971456
                        },
                        {
                            "lat": 55.617785284851124,
                            "lon": 61.41659482034692
                        },
                        {
                            "lat": 55.61681635795997,
                            "lon": 61.414879748150035
                        },
                        {
                            "lat": 55.61323727018111,
                            "lon": 61.41534398459745
                        },
                        {
                            "lat": 55.60965806033041,
                            "lon": 61.41580812897563
                        },
                        {
                            "lat": 55.60868961088194,
                            "lon": 61.41409298157751
                        },
                        {
                            "lat": 55.61226864304576,
                            "lon": 61.41362887011057
                        },
                        {
                            "lat": 55.61130013796106,
                            "lon": 61.41191374625551
                        },
                        {
                            "lat": 55.60772128346579,
                            "lon": 61.41237782481464
                        },
                        {
                            "lat": 55.606753078060834,
                            "lon": 61.41066265868863
                        },
                        {
                            "lat": 55.605784994645894,
                            "lon": 61.40894748320107
                        },
                        {
                            "lat": 55.6048170331998,
                            "lon": 61.407232298353556
                        },
                        {
                            "lat": 55.60839535479911,
                            "lon": 61.406768318497235
                        },
                        {
                            "lat": 55.60742733770532,
                            "lon": 61.40505315718542
                        },
                        {
                            "lat": 55.61100535976441,
                            "lon": 61.40458911818721
                        },
                        {
                            "lat": 55.614583259860396,
                            "lon": 61.404124987159335
                        },
                        {
                            "lat": 55.618161037974986,
                            "lon": 61.40366076410804
                        },
                        {
                            "lat": 55.6217386940899,
                            "lon": 61.40319644903957
                        },
                        {
                            "lat": 55.62077008884404,
                            "lon": 61.401481409985536
                        },
                        {
                            "lat": 55.61719261022162,
                            "lon": 61.4019456921412
                        },
                        {
                            "lat": 55.61622430444676,
                            "lon": 61.400230610805416
                        },
                        {
                            "lat": 55.61525612062925,
                            "lon": 61.39851552010229
                        },
                        {
                            "lat": 55.61428805874796,
                            "lon": 61.396800420033415
                        },
                        {
                            "lat": 55.61332011878176,
                            "lon": 61.3950853106004
                        },
                        {
                            "lat": 55.609743228052714,
                            "lon": 61.3955493691497
                        },
                        {
                            "lat": 55.60877558740275,
                            "lon": 61.39383421746403
                        },
                        {
                            "lat": 55.60519875224147,
                            "lon": 61.39429815113306
                        },
                        {
                            "lat": 55.6042314108712,
                            "lon": 61.392582957208695
                        },
                        {
                            "lat": 55.60065463131995,
                            "lon": 61.39304676600574
                        },
                        {
                            "lat": 55.601621795243894,
                            "lon": 61.39476199280567
                        },
                        {
                            "lat": 55.59804471642832,
                            "lon": 61.39522574247562
                        },
                        {
                            "lat": 55.5990118246846,
                            "lon": 61.396940992798335
                        },
                        {
                            "lat": 55.5954344465625,
                            "lon": 61.397404683327274
                        },
                        {
                            "lat": 55.59446751581303,
                            "lon": 61.39568940013664
                        },
                        {
                            "lat": 55.590890193416286,
                            "lon": 61.39615296578248
                        },
                        {
                            "lat": 55.58731274925637,
                            "lon": 61.3966164394069
                        },
                        {
                            "lat": 55.588279324928834,
                            "lon": 61.39833178831674
                        },
                        {
                            "lat": 55.58470158145387,
                            "lon": 61.39879520276477
                        },
                        {
                            "lat": 55.581123716231225,
                            "lon": 61.39925852517328
                        },
                        {
                            "lat": 55.57754572927917,
                            "lon": 61.399721755536085
                        },
                        {
                            "lat": 55.576579686380555,
                            "lon": 61.39800630808905
                        },
                        {
                            "lat": 55.57561376517192,
                            "lon": 61.39629085131534
                        },
                        {
                            "lat": 55.57464796563214,
                            "lon": 61.39457538521657
                        },
                        {
                            "lat": 55.57107038981461,
                            "lon": 61.395038425033775
                        },
                        {
                            "lat": 55.570104889501195,
                            "lon": 61.39332291678713
                        },
                        {
                            "lat": 55.57368228774014,
                            "lon": 61.39285990979426
                        },
                        {
                            "lat": 55.572716731474806,
                            "lon": 61.39114442505006
                        },
                        {
                            "lat": 55.57175129681503,
                            "lon": 61.3894289309855
                        },
                        {
                            "lat": 55.570785983739775,
                            "lon": 61.38771342760223
                        },
                        {
                            "lat": 55.56720911812679,
                            "lon": 61.38817633614265
                        },
                        {
                            "lat": 55.56817425367836,
                            "lon": 61.389891872339994
                        },
                        {
                            "lat": 55.56913951079615,
                            "lon": 61.39160739922202
                        },
                        {
                            "lat": 55.5655621685302,
                            "lon": 61.39207028136352
                        },
                        {
                            "lat": 55.5645970889755,
                            "lon": 61.390354721669546
                        },
                        {
                            "lat": 55.56101980272475,
                            "lon": 61.390817478967946
                        },
                        {
                            "lat": 55.56198470469529,
                            "lon": 61.392533071468286
                        },
                        {
                            "lat": 55.55840711930972,
                            "lon": 61.39299576953011
                        },
                        {
                            "lat": 55.5548294123918,
                            "lon": 61.39345837554274
                        },
                        {
                            "lat": 55.55125158395983,
                            "lon": 61.39392088949993
                        },
                        {
                            "lat": 55.55028721486821,
                            "lon": 61.39220519861373
                        },
                        {
                            "lat": 55.54670944260901,
                            "lon": 61.39266758772509
                        },
                        {
                            "lat": 55.545745372642145,
                            "lon": 61.390951854759955
                        },
                        {
                            "lat": 55.54216765659784,
                            "lon": 61.391414119033755
                        },
                        {
                            "lat": 55.538589819136625,
                            "lon": 61.3918762912445
                        },
                        {
                            "lat": 55.53501186027685,
                            "lon": 61.39233837138598
                        },
                        {
                            "lat": 55.531433780036835,
                            "lon": 61.392800359451975
                        },
                        {
                            "lat": 55.527855578434924,
                            "lon": 61.393262255436206
                        },
                        {
                            "lat": 55.528818759835175,
                            "lon": 61.394978152221704
                        },
                        {
                            "lat": 55.53239713919252,
                            "lon": 61.394516223484516
                        },
                        {
                            "lat": 55.53597539716692,
                            "lon": 61.39405420266003
                        },
                        {
                            "lat": 55.5369390554583,
                            "lon": 61.39577002464954
                        },
                        {
                            "lat": 55.53790283517202,
                            "lon": 61.39748583735295
                        },
                        {
                            "lat": 55.5343242216738,
                            "lon": 61.397947923704756
                        },
                        {
                            "lat": 55.53336061973116,
                            "lon": 61.396232078235954
                        },
                        {
                            "lat": 55.52978206259999,
                            "lon": 61.39669403972951
                        },
                        {
                            "lat": 55.52620338408321,
                            "lon": 61.397155909123995
                        },
                        {
                            "lat": 55.52716663042048,
                            "lon": 61.398871820106784
                        },
                        {
                            "lat": 55.52358765270211,
                            "lon": 61.3993336301446
                        },
                        {
                            "lat": 55.5226245841991,
                            "lon": 61.397617686413106
                        },
                        {
                            "lat": 55.51904566296607,
                            "lon": 61.39807937159066
                        },
                        {
                            "lat": 55.518082893648966,
                            "lon": 61.3963633858471
                        },
                        {
                            "lat": 55.521661637045035,
                            "lon": 61.39590173340923
                        },
                        {
                            "lat": 55.52524025911321,
                            "lon": 61.39543998886534
                        },
                        {
                            "lat": 55.52427725548948,
                            "lon": 61.39372405933248
                        },
                        {
                            "lat": 55.52069881121884,
                            "lon": 61.39418577113451
                        },
                        {
                            "lat": 55.51712024564139,
                            "lon": 61.39464739083613
                        },
                        {
                            "lat": 55.513541558775465,
                            "lon": 61.395108918431056
                        },
                        {
                            "lat": 55.50996275063945,
                            "lon": 61.39557035391311
                        },
                        {
                            "lat": 55.50638382125169,
                            "lon": 61.39603169727604
                        },
                        {
                            "lat": 55.50280477063061,
                            "lon": 61.3964929485136
                        },
                        {
                            "lat": 55.50376670715328,
                            "lon": 61.39820907441398
                        },
                        {
                            "lat": 55.504728764911974,
                            "lon": 61.39992519106066
                        },
                        {
                            "lat": 55.50569094392772,
                            "lon": 61.40164129845199
                        },
                        {
                            "lat": 55.50211123826499,
                            "lon": 61.402102555693524
                        },
                        {
                            "lat": 55.49853141134255,
                            "lon": 61.40256372078058
                        },
                        {
                            "lat": 55.49756958826907,
                            "lon": 61.40084754796424
                        },
                        {
                            "lat": 55.49660788641588,
                            "lon": 61.39913136589946
                        },
                        {
                            "lat": 55.49302829423914,
                            "lon": 61.399592373420894
                        },
                        {
                            "lat": 55.48944858088159,
                            "lon": 61.400053288786516
                        },
                        {
                            "lat": 55.49040992674514,
                            "lon": 61.40176953624715
                        },
                        {
                            "lat": 55.48682991419878,
                            "lon": 61.40223039214028
                        },
                        {
                            "lat": 55.48324978048736,
                            "lon": 61.402691155859586
                        },
                        {
                            "lat": 55.48421089142375,
                            "lon": 61.404407459458966
                        },
                        {
                            "lat": 55.485172123527775,
                            "lon": 61.406123753822015
                        },
                        {
                            "lat": 55.48159151247758,
                            "lon": 61.40658449072831
                        },
                        {
                            "lat": 55.48063045847882,
                            "lon": 61.40486816368003
                        },
                        {
                            "lat": 55.477049904384664,
                            "lon": 61.405328775709194
                        },
                        {
                            "lat": 55.47346922915966,
                            "lon": 61.40578929554027
                        },
                        {
                            "lat": 55.469888432822295,
                            "lon": 61.40624972316706
                        },
                        {
                            "lat": 55.47084895237892,
                            "lon": 61.407966148237634
                        },
                        {
                            "lat": 55.47442992688471,
                            "lon": 61.40750568794234
                        },
                        {
                            "lat": 55.47539074574318,
                            "lon": 61.409222071116716
                        },
                        {
                            "lat": 55.4978371258127,
                            "lon": 61.40817335478221
                        },
                        {
                            "lat": 55.50141760809498,
                            "lon": 61.40771218372302
                        },
                        {
                            "lat": 55.50237991626001,
                            "lon": 61.40942831952978
                        },
                        {
                            "lat": 55.49879925590138,
                            "lon": 61.40988952330675
                        },
                        {
                            "lat": 55.4978371258127,
                            "lon": 61.40817335478221
                        },
                        {
                            "lat": 55.500455421234435,
                            "lon": 61.40599603866153
                        },
                        {
                            "lat": 55.5040356041754,
                            "lon": 61.40553480814484
                        },
                        {
                            "lat": 55.50499796907281,
                            "lon": 61.407250920486376
                        },
                        {
                            "lat": 55.50141760809498,
                            "lon": 61.40771218372302
                        },
                        {
                            "lat": 55.500455421234435,
                            "lon": 61.40599603866153
                        },
                        {
                            "lat": 55.51959113351328,
                            "lon": 61.412730541666825
                        },
                        {
                            "lat": 55.52317172108346,
                            "lon": 61.412268778779485
                        },
                        {
                            "lat": 55.522208158536955,
                            "lon": 61.41055283447038
                        },
                        {
                            "lat": 55.5257884466213,
                            "lon": 61.41009101217599
                        },
                        {
                            "lat": 55.529368613227554,
                            "lon": 61.4096290977248
                        },
                        {
                            "lat": 55.53294865833733,
                            "lon": 61.40916709112311
                        },
                        {
                            "lat": 55.53391275483575,
                            "lon": 61.41088293713102
                        },
                        {
                            "lat": 55.53033253176308,
                            "lon": 61.41134497650534
                        },
                        {
                            "lat": 55.53129657181616,
                            "lon": 61.41306084600087
                        },
                        {
                            "lat": 55.52771604922359,
                            "lon": 61.41352282598959
                        },
                        {
                            "lat": 55.52413540511075,
                            "lon": 61.41398471381041
                        },
                        {
                            "lat": 55.52055463949601,
                            "lon": 61.41444650945712
                        },
                        {
                            "lat": 55.51697375239778,
                            "lon": 61.414908212923415
                        },
                        {
                            "lat": 55.51601042448068,
                            "lon": 61.41319221237935
                        },
                        {
                            "lat": 55.51959113351328,
                            "lon": 61.412730541666825
                        },
                        {
                            "lat": 55.53749285637246,
                            "lon": 61.41042080560683
                        },
                        {
                            "lat": 55.538457252366975,
                            "lon": 61.412136609544675
                        },
                        {
                            "lat": 55.534876972870094,
                            "lon": 61.41259877385047
                        },
                        {
                            "lat": 55.53391275483575,
                            "lon": 61.41088293713102
                        },
                        {
                            "lat": 55.53749285637246,
                            "lon": 61.41042080560683
                        },
                        {
                            "lat": 55.56283349575608,
                            "lon": 61.4145084542991
                        },
                        {
                            "lat": 55.56186766732378,
                            "lon": 61.41279287511464
                        },
                        {
                            "lat": 55.56544745108686,
                            "lon": 61.41233000024434
                        },
                        {
                            "lat": 55.569027113103765,
                            "lon": 61.41186703325751
                        },
                        {
                            "lat": 55.568061050722285,
                            "lon": 61.41015151041628
                        },
                        {
                            "lat": 55.57164041316785,
                            "lon": 61.40968848415764
                        },
                        {
                            "lat": 55.5752196538517,
                            "lon": 61.409225365800545
                        },
                        {
                            "lat": 55.57618607182568,
                            "lon": 61.410940822959134
                        },
                        {
                            "lat": 55.579765368494,
                            "lon": 61.410477579660096
                        },
                        {
                            "lat": 55.58073208605205,
                            "lon": 61.41219299463469
                        },
                        {
                            "lat": 55.57715261160086,
                            "lon": 61.41265627078682
                        },
                        {
                            "lat": 55.57811927319837,
                            "lon": 61.41437170928203
                        },
                        {
                            "lat": 55.57453949910248,
                            "lon": 61.41483492618173
                        },
                        {
                            "lat": 55.57095960318146,
                            "lon": 61.41529805096627
                        },
                        {
                            "lat": 55.567379585453615,
                            "lon": 61.41576108362937
                        },
                        {
                            "lat": 55.563799445937335,
                            "lon": 61.41622402416479
                        },
                        {
                            "lat": 55.56283349575608,
                            "lon": 61.4145084542991
                        },
                        {
                            "lat": 55.558287761832865,
                            "lon": 61.41325565786211
                        },
                        {
                            "lat": 55.55925341237661,
                            "lon": 61.414971269871856
                        },
                        {
                            "lat": 55.55567320726653,
                            "lon": 61.41543399330997
                        },
                        {
                            "lat": 55.554707734632466,
                            "lon": 61.413718348480494
                        },
                        {
                            "lat": 55.55374238368952,
                            "lon": 61.4120026943407
                        },
                        {
                            "lat": 55.5573222329986,
                            "lon": 61.4115400365386
                        },
                        {
                            "lat": 55.56090196061932,
                            "lon": 61.41107728661302
                        },
                        {
                            "lat": 55.56186766732378,
                            "lon": 61.41279287511464
                        },
                        {
                            "lat": 55.558287761832865,
                            "lon": 61.41325565786211
                        },
                        {
                            "lat": 55.61472041440525,
                            "lon": 61.45196739926379
                        },
                        {
                            "lat": 55.61375023228241,
                            "lon": 61.45025230763037
                        },
                        {
                            "lat": 55.61733331138034,
                            "lon": 61.44978790604651
                        },
                        {
                            "lat": 55.61830367164154,
                            "lon": 61.451502964722096
                        },
                        {
                            "lat": 55.61472041440525,
                            "lon": 61.45196739926379
                        },
                        {
                            "lat": 55.615690718932704,
                            "lon": 61.4536824815128
                        },
                        {
                            "lat": 55.61210716111377,
                            "lon": 61.45414685679857
                        },
                        {
                            "lat": 55.608523480887314,
                            "lon": 61.45461113986075
                        },
                        {
                            "lat": 55.60755353273716,
                            "lon": 61.452895991705795
                        },
                        {
                            "lat": 55.61113703476433,
                            "lon": 61.45243174159383
                        },
                        {
                            "lat": 55.61472041440525,
                            "lon": 61.45196739926379
                        },
                        {
                            "lat": 55.615690718932704,
                            "lon": 61.4536824815128
                        },
                        {
                            "lat": 55.610710990578355,
                            "lon": 61.397264511476266
                        },
                        {
                            "lat": 55.60713380051192,
                            "lon": 61.39772851091784
                        },
                        {
                            "lat": 55.606166215447985,
                            "lon": 61.396013335703294
                        },
                        {
                            "lat": 55.609743228052714,
                            "lon": 61.3955493691497
                        },
                        {
                            "lat": 55.610710990578355,
                            "lon": 61.397264511476266
                        },
                        {
                            "lat": 55.49398981810804,
                            "lon": 61.401308588186396
                        },
                        {
                            "lat": 55.4949514631788,
                            "lon": 61.40302479370684
                        },
                        {
                            "lat": 55.49137139379212,
                            "lon": 61.403485774466155
                        },
                        {
                            "lat": 55.49040992674514,
                            "lon": 61.40176953624715
                        },
                        {
                            "lat": 55.49398981810804,
                            "lon": 61.401308588186396
                        },
                        {
                            "lat": 55.48875261338927,
                            "lon": 61.40566292472449
                        },
                        {
                            "lat": 55.489714144784706,
                            "lon": 61.40737917715535
                        },
                        {
                            "lat": 55.486133476820534,
                            "lon": 61.40784003894712
                        },
                        {
                            "lat": 55.485172123527775,
                            "lon": 61.406123753822015
                        },
                        {
                            "lat": 55.48875261338927,
                            "lon": 61.40566292472449
                        },
                        {
                            "lat": 55.493294691520674,
                            "lon": 61.40691822317301
                        },
                        {
                            "lat": 55.49425652224432,
                            "lon": 61.4086344336577
                        },
                        {
                            "lat": 55.4906757974083,
                            "lon": 61.40909542034327
                        },
                        {
                            "lat": 55.489714144784706,
                            "lon": 61.40737917715535
                        },
                        {
                            "lat": 55.493294691520674,
                            "lon": 61.40691822317301
                        },
                        {
                            "lat": 55.52647758651537,
                            "lon": 61.40448140568886
                        },
                        {
                            "lat": 55.52551415383917,
                            "lon": 61.40276548978405
                        },
                        {
                            "lat": 55.52909348728121,
                            "lon": 61.40230361423865
                        },
                        {
                            "lat": 55.5300570978468,
                            "lon": 61.40401949738455
                        },
                        {
                            "lat": 55.52647758651537,
                            "lon": 61.40448140568886
                        },
                        {
                            "lat": 55.514504028943385,
                            "lon": 61.39682494617275
                        },
                        {
                            "lat": 55.51546662040246,
                            "lon": 61.398540964650444
                        },
                        {
                            "lat": 55.51188745652666,
                            "lon": 61.399002465586165
                        },
                        {
                            "lat": 55.51092504294667,
                            "lon": 61.397286414379934
                        },
                        {
                            "lat": 55.514504028943385,
                            "lon": 61.39682494617275
                        },
                        {
                            "lat": 55.52097156561304,
                            "lon": 61.401511315269296
                        },
                        {
                            "lat": 55.517392167278246,
                            "lon": 61.401972973807574
                        },
                        {
                            "lat": 55.516429333173726,
                            "lon": 61.40025697386258
                        },
                        {
                            "lat": 55.52000855361373,
                            "lon": 61.39979534806526
                        },
                        {
                            "lat": 55.52097156561304,
                            "lon": 61.401511315269296
                        },
                        {
                            "lat": 55.52289795375076,
                            "lon": 61.404943221859405
                        },
                        {
                            "lat": 55.523861329931336,
                            "lon": 61.40665916124231
                        },
                        {
                            "lat": 55.52028139780196,
                            "lon": 61.40712091802399
                        },
                        {
                            "lat": 55.51931819957129,
                            "lon": 61.405404945889856
                        },
                        {
                            "lat": 55.52289795375076,
                            "lon": 61.404943221859405
                        },
                        {
                            "lat": 55.58789066941416,
                            "lon": 61.41126616604538
                        },
                        {
                            "lat": 55.58692359635376,
                            "lon": 61.409550816793605
                        },
                        {
                            "lat": 55.59050252750856,
                            "lon": 61.40908729723866
                        },
                        {
                            "lat": 55.58953539860321,
                            "lon": 61.407371971512
                        },
                        {
                            "lat": 55.59311403020353,
                            "lon": 61.40690839275614
                        },
                        {
                            "lat": 55.59408133678886,
                            "lon": 61.40862368561091
                        },
                        {
                            "lat": 55.595048765266974,
                            "lon": 61.41033896911765
                        },
                        {
                            "lat": 55.59601631565905,
                            "lon": 61.41205424327471
                        },
                        {
                            "lat": 55.5969839879862,
                            "lon": 61.41376950808055
                        },
                        {
                            "lat": 55.593404645556056,
                            "lon": 61.414233218344485
                        },
                        {
                            "lat": 55.592437150963825,
                            "lon": 61.4125179206565
                        },
                        {
                            "lat": 55.58885786435183,
                            "lon": 61.412981505954356
                        },
                        {
                            "lat": 55.58527845584138,
                            "lon": 61.413444999162095
                        },
                        {
                            "lat": 55.584311438662596,
                            "lon": 61.41172962638549
                        },
                        {
                            "lat": 55.58789066941416,
                            "lon": 61.41126616604538
                        },
                        {
                            "lat": 55.59214684548986,
                            "lon": 61.40519309055487
                        },
                        {
                            "lat": 55.59572517757798,
                            "lon": 61.40472945261201
                        },
                        {
                            "lat": 55.5966925399322,
                            "lon": 61.406444721939266
                        },
                        {
                            "lat": 55.59311403020353,
                            "lon": 61.40690839275614
                        },
                        {
                            "lat": 55.59214684548986,
                            "lon": 61.40519309055487
                        },
                        {
                            "lat": 55.58402310822508,
                            "lon": 61.40440471299743
                        },
                        {
                            "lat": 55.58305652246254,
                            "lon": 61.40268932639037
                        },
                        {
                            "lat": 55.58663474292266,
                            "lon": 61.40222593828008
                        },
                        {
                            "lat": 55.58760150633141,
                            "lon": 61.403941292031135
                        },
                        {
                            "lat": 55.58856839155121,
                            "lon": 61.405656636442295
                        },
                        {
                            "lat": 55.58498981578038,
                            "lon": 61.40612009026805
                        },
                        {
                            "lat": 55.58402310822508,
                            "lon": 61.40440471299743
                        },
                        {
                            "lat": 55.58566810130387,
                            "lon": 61.40051057519077
                        },
                        {
                            "lat": 55.58924602236729,
                            "lon": 61.40004712788805
                        },
                        {
                            "lat": 55.590212841592866,
                            "lon": 61.40176245811924
                        },
                        {
                            "lat": 55.58663474292266,
                            "lon": 61.40222593828008
                        },
                        {
                            "lat": 55.58566810130387,
                            "lon": 61.40051057519077
                        },
                        {
                            "lat": 55.54465269476459,
                            "lon": 61.40949626613392
                        },
                        {
                            "lat": 55.541072836354886,
                            "lon": 61.40995858193905
                        },
                        {
                            "lat": 55.540108383993996,
                            "lon": 61.4082428014931
                        },
                        {
                            "lat": 55.539144053184714,
                            "lon": 61.40652701175347
                        },
                        {
                            "lat": 55.54272355581363,
                            "lon": 61.40606476152353
                        },
                        {
                            "lat": 55.543688064504146,
                            "lon": 61.40778051847729
                        },
                        {
                            "lat": 55.54726762344434,
                            "lon": 61.40731814333591
                        },
                        {
                            "lat": 55.54630293689372,
                            "lon": 61.40560241917363
                        },
                        {
                            "lat": 55.549882196406585,
                            "lon": 61.40513998470995
                        },
                        {
                            "lat": 55.550847060796215,
                            "lon": 61.40685567607522
                        },
                        {
                            "lat": 55.55181204679252,
                            "lon": 61.408571358136484
                        },
                        {
                            "lat": 55.55277715441658,
                            "lon": 61.41028703089218
                        },
                        {
                            "lat": 55.54919736133161,
                            "lon": 61.41074956375719
                        },
                        {
                            "lat": 55.54823243158325,
                            "lon": 61.409033858197645
                        },
                        {
                            "lat": 55.54465269476459,
                            "lon": 61.40949626613392
                        },
                        {
                            "lat": 55.5372157561365,
                            "lon": 61.403095404399345
                        },
                        {
                            "lat": 55.53363648772661,
                            "lon": 61.4035574969526
                        },
                        {
                            "lat": 55.532672699292746,
                            "lon": 61.40184164657122
                        },
                        {
                            "lat": 55.5362517898554,
                            "lon": 61.40137958678802
                        },
                        {
                            "lat": 55.539830758950835,
                            "lon": 61.40091743489524
                        },
                        {
                            "lat": 55.54340960656067,
                            "lon": 61.40045519089917
                        },
                        {
                            "lat": 55.54698833266661,
                            "lon": 61.39999285480603
                        },
                        {
                            "lat": 55.54795283236287,
                            "lon": 61.40170857407378
                        },
                        {
                            "lat": 55.54437392847297,
                            "lon": 61.40217094295368
                        },
                        {
                            "lat": 55.54079490305807,
                            "lon": 61.40263321973095
                        },
                        {
                            "lat": 55.5372157561365,
                            "lon": 61.403095404399345
                        },
                        {
                            "lat": 55.55318068665128,
                            "lon": 61.3973522433714
                        },
                        {
                            "lat": 55.55221607454717,
                            "lon": 61.395636571086335
                        },
                        {
                            "lat": 55.55579408064483,
                            "lon": 61.395174024336
                        },
                        {
                            "lat": 55.559371965207376,
                            "lon": 61.394711385524666
                        },
                        {
                            "lat": 55.5629497282165,
                            "lon": 61.39424865465858
                        },
                        {
                            "lat": 55.56391487330947,
                            "lon": 61.395964228537245
                        },
                        {
                            "lat": 55.56488013999527,
                            "lon": 61.39767979310269
                        },
                        {
                            "lat": 55.565845528295014,
                            "lon": 61.39939534835334
                        },
                        {
                            "lat": 55.56226723230534,
                            "lon": 61.39985817765277
                        },
                        {
                            "lat": 55.561302021684114,
                            "lon": 61.39814258958758
                        },
                        {
                            "lat": 55.56033693265849,
                            "lon": 61.39642699221102
                        },
                        {
                            "lat": 55.55675887043297,
                            "lon": 61.39688966382447
                        },
                        {
                            "lat": 55.55318068665128,
                            "lon": 61.3973522433714
                        },
                        {
                            "lat": 55.56942370264965,
                            "lon": 61.398932426988736
                        },
                        {
                            "lat": 55.57038939026015,
                            "lon": 61.40064794009948
                        },
                        {
                            "lat": 55.566811038229766,
                            "lon": 61.40111089428759
                        },
                        {
                            "lat": 55.565845528295014,
                            "lon": 61.39939534835334
                        },
                        {
                            "lat": 55.56942370264965,
                            "lon": 61.398932426988736
                        },
                        {
                            "lat": 55.563232564543284,
                            "lon": 61.40157375640497
                        },
                        {
                            "lat": 55.559653969218964,
                            "lon": 61.402036526445364
                        },
                        {
                            "lat": 55.55868881469895,
                            "lon": 61.40032091488076
                        },
                        {
                            "lat": 55.56226723230534,
                            "lon": 61.39985817765277
                        },
                        {
                            "lat": 55.563232564543284,
                            "lon": 61.40157375640497
                        },
                        {
                            "lat": 55.56061924535844,
                            "lon": 61.403752128698876
                        },
                        {
                            "lat": 55.55704035065729,
                            "lon": 61.40421483946635
                        },
                        {
                            "lat": 55.55607525227517,
                            "lon": 61.40249920440255
                        },
                        {
                            "lat": 55.559653969218964,
                            "lon": 61.402036526445364
                        },
                        {
                            "lat": 55.56061924535844,
                            "lon": 61.403752128698876
                        },
                        {
                            "lat": 55.54505969781982,
                            "lon": 61.3965613883791
                        },
                        {
                            "lat": 55.54602395449262,
                            "lon": 61.39827712624059
                        },
                        {
                            "lat": 55.54244540615232,
                            "lon": 61.398739429550425
                        },
                        {
                            "lat": 55.541481327226805,
                            "lon": 61.39702365890899
                        },
                        {
                            "lat": 55.540517369763066,
                            "lon": 61.39530787897648
                        },
                        {
                            "lat": 55.54409556262714,
                            "lon": 61.394845641223036
                        },
                        {
                            "lat": 55.54505969781982,
                            "lon": 61.3965613883791
                        },
                        {
                            "lat": 55.568742423088814,
                            "lon": 61.404541958200625
                        },
                        {
                            "lat": 55.569708298055325,
                            "lon": 61.406257476176215
                        },
                        {
                            "lat": 55.57067429474129,
                            "lon": 61.40797298482908
                        },
                        {
                            "lat": 55.567095110084196,
                            "lon": 61.40843597825263
                        },
                        {
                            "lat": 55.56612929116837,
                            "lon": 61.40672043676806
                        },
                        {
                            "lat": 55.565163593953685,
                            "lon": 61.4050048859642
                        },
                        {
                            "lat": 55.568742423088814,
                            "lon": 61.404541958200625
                        },
                        {
                            "lat": 55.56777666982067,
                            "lon": 61.402826430903865
                        },
                        {
                            "lat": 55.57135519954509,
                            "lon": 61.402363443888824
                        },
                        {
                            "lat": 55.57232113052558,
                            "lon": 61.404078938355184
                        },
                        {
                            "lat": 55.568742423088814,
                            "lon": 61.404541958200625
                        },
                        {
                            "lat": 55.56777666982067,
                            "lon": 61.402826430903865
                        },
                        {
                            "lat": 55.55897091230937,
                            "lon": 61.40764608166454
                        },
                        {
                            "lat": 55.562550162580266,
                            "lon": 61.40718330526626
                        },
                        {
                            "lat": 55.563515803704846,
                            "lon": 61.40889887957693
                        },
                        {
                            "lat": 55.559936375621554,
                            "lon": 61.409361688795784
                        },
                        {
                            "lat": 55.55897091230937,
                            "lon": 61.40764608166454
                        },
                        {
                            "lat": 55.576865946652454,
                            "lon": 61.405331278736604
                        },
                        {
                            "lat": 55.57589971624569,
                            "lon": 61.40361582643419
                        },
                        {
                            "lat": 55.57947818023079,
                            "lon": 61.40315262244382
                        },
                        {
                            "lat": 55.58044458832599,
                            "lon": 61.40486804190135
                        },
                        {
                            "lat": 55.581411118195646,
                            "lon": 61.40658345202586
                        },
                        {
                            "lat": 55.577832298815345,
                            "lon": 61.40704672170947
                        },
                        {
                            "lat": 55.576865946652454,
                            "lon": 61.405331278736604
                        },
                        {
                            "lat": 55.60317427922586,
                            "lon": 61.41112661228452
                        },
                        {
                            "lat": 55.604142306959375,
                            "lon": 61.41284181130937
                        },
                        {
                            "lat": 55.600563208460066,
                            "lon": 61.41330570573343
                        },
                        {
                            "lat": 55.59959535841915,
                            "lon": 61.41159047381531
                        },
                        {
                            "lat": 55.60317427922586,
                            "lon": 61.41112661228452
                        },
                        {
                            "lat": 55.60452401800831,
                            "lon": 61.399907617095046
                        },
                        {
                            "lat": 55.60549166933107,
                            "lon": 61.40162280648273
                        },
                        {
                            "lat": 55.609069336296535,
                            "lon": 61.40115883327349
                        },
                        {
                            "lat": 55.60810150745441,
                            "lon": 61.399443676775086
                        },
                        {
                            "lat": 55.611678875000806,
                            "lon": 61.398979644442086
                        },
                        {
                            "lat": 55.61264688134119,
                            "lon": 61.40069476804559
                        },
                        {
                            "lat": 55.61361500962068,
                            "lon": 61.40240988228523
                        },
                        {
                            "lat": 55.6100372870595,
                            "lon": 61.402873980411385
                        },
                        {
                            "lat": 55.606459442556364,
                            "lon": 61.403337986513385
                        },
                        {
                            "lat": 55.60288147612953,
                            "lon": 61.403801900585016
                        },
                        {
                            "lat": 55.60191388046306,
                            "lon": 61.40208668766716
                        },
                        {
                            "lat": 55.598335969710796,
                            "lon": 61.402550476820466
                        },
                        {
                            "lat": 55.597368673490244,
                            "lon": 61.400835221670775
                        },
                        {
                            "lat": 55.60094640668083,
                            "lon": 61.40037146539569
                        },
                        {
                            "lat": 55.60452401800831,
                            "lon": 61.399907617095046
                        },
                        {
                            "lat": 55.58818023874317,
                            "lon": 61.41859106143989
                        },
                        {
                            "lat": 55.58721285586053,
                            "lon": 61.416875716690896
                        },
                        {
                            "lat": 55.590792619943755,
                            "lon": 61.41641215773762
                        },
                        {
                            "lat": 55.594372262086246,
                            "lon": 61.41594850668308
                        },
                        {
                            "lat": 55.59795178226962,
                            "lon": 61.41548476353354
                        },
                        {
                            "lat": 55.60153118047556,
                            "lon": 61.41502092829525
                        },
                        {
                            "lat": 55.60249927448678,
                            "lon": 61.41673614149923
                        },
                        {
                            "lat": 55.598919698530445,
                            "lon": 61.4172000096321
                        },
                        {
                            "lat": 55.59988773678987,
                            "lon": 61.418915246374624
                        },
                        {
                            "lat": 55.60085589706906,
                            "lon": 61.42063047375961
                        },
                        {
                            "lat": 55.59727584351605,
                            "lon": 61.42109431558654
                        },
                        {
                            "lat": 55.59630786104507,
                            "lon": 61.4193790553057
                        },
                        {
                            "lat": 55.59534000057551,
                            "lon": 61.41766378567066
                        },
                        {
                            "lat": 55.59176018064032,
                            "lon": 61.41812746960869
                        },
                        {
                            "lat": 55.58818023874317,
                            "lon": 61.41859106143989
                        },
                        {
                            "lat": 55.601355753285354,
                            "lon": 61.4555394292893
                        },
                        {
                            "lat": 55.60038616159754,
                            "lon": 61.45382421525068
                        },
                        {
                            "lat": 55.6039699083421,
                            "lon": 61.45336014959352
                        },
                        {
                            "lat": 55.60493967827172,
                            "lon": 61.45507533069309
                        },
                        {
                            "lat": 55.601355753285354,
                            "lon": 61.4555394292893
                        }
                    ],
                    [
                        {
                            "lat": 55.51130040081297,
                            "lon": 61.48004765230014
                        },
                        {
                            "lat": 55.514888875068586,
                            "lon": 61.479585816834806
                        },
                        {
                            "lat": 55.513923071569046,
                            "lon": 61.47786981334974
                        },
                        {
                            "lat": 55.51033477625812,
                            "lon": 61.47833161600258
                        },
                        {
                            "lat": 55.50674635896572,
                            "lon": 61.47879332621021
                        },
                        {
                            "lat": 55.50771180455464,
                            "lon": 61.48050939531468
                        },
                        {
                            "lat": 55.51130040081297,
                            "lon": 61.48004765230014
                        }
                    ],
                    [
                        {
                            "lat": 55.514888875068586,
                            "lon": 61.479585816834806
                        },
                        {
                            "lat": 55.51585480058937,
                            "lon": 61.48130181102601
                        },
                        {
                            "lat": 55.519443331765736,
                            "lon": 61.48083985029461
                        },
                        {
                            "lat": 55.51847722730298,
                            "lon": 61.47912388892496
                        },
                        {
                            "lat": 55.514888875068586,
                            "lon": 61.479585816834806
                        }
                    ],
                    [
                        {
                            "lat": 55.501499455644435,
                            "lon": 61.48314872938775
                        },
                        {
                            "lat": 55.50246478715321,
                            "lon": 61.484864845527476
                        },
                        {
                            "lat": 55.50605398543377,
                            "lon": 61.484403221831535
                        },
                        {
                            "lat": 55.50508847487952,
                            "lon": 61.48268713849441
                        },
                        {
                            "lat": 55.501499455644435,
                            "lon": 61.48314872938775
                        }
                    ],
                    [
                        {
                            "lat": 55.519719723309784,
                            "lon": 61.48816569482049
                        },
                        {
                            "lat": 55.51613035405268,
                            "lon": 61.48862769439995
                        },
                        {
                            "lat": 55.517096710942475,
                            "lon": 61.490343674931246
                        },
                        {
                            "lat": 55.52068625925546,
                            "lon": 61.489881642518625
                        },
                        {
                            "lat": 55.519719723309784,
                            "lon": 61.48816569482049
                        }
                    ],
                    [
                        {
                            "lat": 55.514749073337306,
                            "lon": 61.49984755377389
                        },
                        {
                            "lat": 55.51571580492923,
                            "lon": 61.50156354417269
                        },
                        {
                            "lat": 55.5193066724644,
                            "lon": 61.501101499730986
                        },
                        {
                            "lat": 55.518339761662695,
                            "lon": 61.49938554217479
                        },
                        {
                            "lat": 55.514749073337306,
                            "lon": 61.49984755377389
                        }
                    ],
                    [
                        {
                            "lat": 55.51502520555422,
                            "lon": 61.507173509990466
                        },
                        {
                            "lat": 55.51143355572755,
                            "lon": 61.50763546787985
                        },
                        {
                            "lat": 55.50784178365337,
                            "lon": 61.50809733320937
                        },
                        {
                            "lat": 55.508808466276136,
                            "lon": 61.50981338492409
                        },
                        {
                            "lat": 55.51240041769828,
                            "lon": 61.50935148675496
                        },
                        {
                            "lat": 55.51599224685163,
                            "lon": 61.50888949602035
                        },
                        {
                            "lat": 55.51502520555422,
                            "lon": 61.507173509990466
                        }
                    ],
                    [
                        {
                            "lat": 55.50162419669977,
                            "lon": 61.51073690354088
                        },
                        {
                            "lat": 55.50065787283685,
                            "lon": 61.509020786163774
                        },
                        {
                            "lat": 55.49706573413168,
                            "lon": 61.5094823737761
                        },
                        {
                            "lat": 55.493473473253395,
                            "lon": 61.50994386880347
                        },
                        {
                            "lat": 55.49443943827129,
                            "lon": 61.51166005182055
                        },
                        {
                            "lat": 55.498031878582744,
                            "lon": 61.511198523975985
                        },
                        {
                            "lat": 55.50162419669977,
                            "lon": 61.51073690354088
                        }
                    ],
                    [
                        {
                            "lat": 55.508808466276136,
                            "lon": 61.50981338492409
                        },
                        {
                            "lat": 55.50521639260377,
                            "lon": 61.510275190521504
                        },
                        {
                            "lat": 55.50618301808888,
                            "lon": 61.511991265775116
                        },
                        {
                            "lat": 55.50259064277572,
                            "lon": 61.512453011626334
                        },
                        {
                            "lat": 55.50355721108599,
                            "lon": 61.51416911041855
                        },
                        {
                            "lat": 55.507149765826924,
                            "lon": 61.513707331732036
                        },
                        {
                            "lat": 55.51074219829363,
                            "lon": 61.51324546045629
                        },
                        {
                            "lat": 55.509775271149124,
                            "lon": 61.511529427340264
                        },
                        {
                            "lat": 55.508808466276136,
                            "lon": 61.50981338492409
                        }
                    ],
                    [
                        {
                            "lat": 55.49733806646503,
                            "lon": 61.516808545156806
                        },
                        {
                            "lat": 55.50093104518763,
                            "lon": 61.516346918840064
                        },
                        {
                            "lat": 55.49996453408941,
                            "lon": 61.51463079650954
                        },
                        {
                            "lat": 55.49899814522822,
                            "lon": 61.51291466488768
                        },
                        {
                            "lat": 55.495405525464996,
                            "lon": 61.51337622555283
                        },
                        {
                            "lat": 55.49637173485581,
                            "lon": 61.51509238999881
                        },
                        {
                            "lat": 55.49733806646503,
                            "lon": 61.516808545156806
                        }
                    ],
                    [
                        {
                            "lat": 55.51461113001318,
                            "lon": 61.52010949988725
                        },
                        {
                            "lat": 55.515578668835076,
                            "lon": 61.52182548647877
                        },
                        {
                            "lat": 55.51917187621367,
                            "lon": 61.52136335833584
                        },
                        {
                            "lat": 55.518204157913644,
                            "lon": 61.519647404608065
                        },
                        {
                            "lat": 55.51461113001318,
                            "lon": 61.52010949988725
                        }
                    ],
                    [
                        {
                            "lat": 55.52538984650575,
                            "lon": 61.51872293625865
                        },
                        {
                            "lat": 55.52635792369805,
                            "lon": 61.5204388242421
                        },
                        {
                            "lat": 55.52995076376666,
                            "lon": 61.51997641830388
                        },
                        {
                            "lat": 55.52898250716024,
                            "lon": 61.51826056320101
                        },
                        {
                            "lat": 55.52538984650575,
                            "lon": 61.51872293625865
                        }
                    ],
                    [
                        {
                            "lat": 55.515578668835076,
                            "lon": 61.52182548647877
                        },
                        {
                            "lat": 55.511985339057034,
                            "lon": 61.52228752201073
                        },
                        {
                            "lat": 55.512952820760276,
                            "lon": 61.52400353215417
                        },
                        {
                            "lat": 55.5165463300564,
                            "lon": 61.52354146376063
                        },
                        {
                            "lat": 55.515578668835076,
                            "lon": 61.52182548647877
                        }
                    ],
                    [
                        {
                            "lat": 55.558187827299385,
                            "lon": 61.549470663064156
                        },
                        {
                            "lat": 55.56178340845614,
                            "lon": 61.54900729397525
                        },
                        {
                            "lat": 55.56537886667957,
                            "lon": 61.548543832224745
                        },
                        {
                            "lat": 55.56897420195107,
                            "lon": 61.54808027781895
                        },
                        {
                            "lat": 55.568002659500806,
                            "lon": 61.54636478855702
                        },
                        {
                            "lat": 55.56440750384978,
                            "lon": 61.54682830998634
                        },
                        {
                            "lat": 55.56081222526825,
                            "lon": 61.54729173876601
                        },
                        {
                            "lat": 55.559841164992235,
                            "lon": 61.54557617419233
                        },
                        {
                            "lat": 55.55624594314356,
                            "lon": 61.54603947735421
                        },
                        {
                            "lat": 55.55265059842305,
                            "lon": 61.54650268785941
                        },
                        {
                            "lat": 55.5490551308493,
                            "lon": 61.54696580570161
                        },
                        {
                            "lat": 55.550025652126784,
                            "lon": 61.548681469143816
                        },
                        {
                            "lat": 55.55362129938814,
                            "lon": 61.54821831835105
                        },
                        {
                            "lat": 55.5545921232279,
                            "lon": 61.54993393948516
                        },
                        {
                            "lat": 55.558187827299385,
                            "lon": 61.549470663064156
                        }
                    ],
                    [
                        {
                            "lat": 55.53923797328092,
                            "lon": 61.550070365447525
                        },
                        {
                            "lat": 55.54020807816721,
                            "lon": 61.55178611836413
                        },
                        {
                            "lat": 55.54380427371135,
                            "lon": 61.551323212677744
                        },
                        {
                            "lat": 55.542833989054536,
                            "lon": 61.54960749269835
                        },
                        {
                            "lat": 55.53923797328092,
                            "lon": 61.550070365447525
                        }
                    ],
                    [
                        {
                            "lat": 55.55099629626034,
                            "lon": 61.550397123231974
                        },
                        {
                            "lat": 55.547400346415266,
                            "lon": 61.55086021429826
                        },
                        {
                            "lat": 55.54837093368002,
                            "lon": 61.55257589198267
                        },
                        {
                            "lat": 55.55196706327134,
                            "lon": 61.5521127679645
                        },
                        {
                            "lat": 55.55099629626034,
                            "lon": 61.550397123231974
                        }
                    ],
                    [
                        {
                            "lat": 55.484116783800864,
                            "lon": 61.37682015816108
                        },
                        {
                            "lat": 55.485076911703686,
                            "lon": 61.3785364603131
                        },
                        {
                            "lat": 55.48865424855609,
                            "lon": 61.378075752851004
                        },
                        {
                            "lat": 55.487693942928956,
                            "lon": 61.37635948336087
                        },
                        {
                            "lat": 55.484116783800864,
                            "lon": 61.37682015816108
                        }
                    ],
                    [
                        {
                            "lat": 55.471986421682985,
                            "lon": 61.38942080495093
                        },
                        {
                            "lat": 55.47294638609538,
                            "lon": 61.39113721503933
                        },
                        {
                            "lat": 55.47652539486096,
                            "lon": 61.390676772177315
                        },
                        {
                            "lat": 55.475565252508986,
                            "lon": 61.38896039474346
                        },
                        {
                            "lat": 55.471986421682985,
                            "lon": 61.38942080495093
                        }
                    ],
                    [
                        {
                            "lat": 55.47032716376322,
                            "lon": 61.39331399927436
                        },
                        {
                            "lat": 55.47128719216143,
                            "lon": 61.395030423576145
                        },
                        {
                            "lat": 55.47486667788447,
                            "lon": 61.394570007553284
                        },
                        {
                            "lat": 55.473906471488874,
                            "lon": 61.3928536159073
                        },
                        {
                            "lat": 55.47294638609538,
                            "lon": 61.39113721503933
                        },
                        {
                            "lat": 55.46936725634871,
                            "lon": 61.391597565754054
                        },
                        {
                            "lat": 55.47032716376322,
                            "lon": 61.39331399927436
                        }
                    ],
                    [
                        {
                            "lat": 55.474168003468215,
                            "lon": 61.400179641154395
                        },
                        {
                            "lat": 55.47512851601125,
                            "lon": 61.40189602856624
                        },
                        {
                            "lat": 55.47608914964306,
                            "lon": 61.40361240675169
                        },
                        {
                            "lat": 55.479669525629326,
                            "lon": 61.40315182739879
                        },
                        {
                            "lat": 55.478708713908034,
                            "lon": 61.40143548188615
                        },
                        {
                            "lat": 55.48228879069762,
                            "lon": 61.40097484302536
                        },
                        {
                            "lat": 55.48132792203349,
                            "lon": 61.39925852095798
                        },
                        {
                            "lat": 55.47774802329394,
                            "lon": 61.39971912714375
                        },
                        {
                            "lat": 55.474168003468215,
                            "lon": 61.400179641154395
                        }
                    ],
                    [
                        {
                            "lat": 55.55600526704893,
                            "lon": 61.47060535443897
                        },
                        {
                            "lat": 55.559591796266425,
                            "lon": 61.470142383450565
                        },
                        {
                            "lat": 55.558623912878765,
                            "lon": 61.46842678836433
                        },
                        {
                            "lat": 55.56221014126681,
                            "lon": 61.46796375790792
                        },
                        {
                            "lat": 55.56124220148525,
                            "lon": 61.46624818636825
                        },
                        {
                            "lat": 55.56482812908637,
                            "lon": 61.46578509645779
                        },
                        {
                            "lat": 55.565796247438485,
                            "lon": 61.4675006351115
                        },
                        {
                            "lat": 55.56938223137534,
                            "lon": 61.46703741998135
                        },
                        {
                            "lat": 55.57296809305894,
                            "lon": 61.466574112523745
                        },
                        {
                            "lat": 55.57655383247084,
                            "lon": 61.46611071274494
                        },
                        {
                            "lat": 55.57558517853459,
                            "lon": 61.46439527278263
                        },
                        {
                            "lat": 55.57199961762949,
                            "lon": 61.46485863965872
                        },
                        {
                            "lat": 55.57103126445046,
                            "lon": 61.46314315744766
                        },
                        {
                            "lat": 55.57461664686722,
                            "lon": 61.462679823470836
                        },
                        {
                            "lat": 55.573648237447486,
                            "lon": 61.46096436481116
                        },
                        {
                            "lat": 55.577233319167945,
                            "lon": 61.46050097143198
                        },
                        {
                            "lat": 55.57820190703636,
                            "lon": 61.462216397190254
                        },
                        {
                            "lat": 55.57917061717082,
                            "lon": 61.46393181359723
                        },
                        {
                            "lat": 55.580139449592586,
                            "lon": 61.465647220651256
                        },
                        {
                            "lat": 55.58372494440575,
                            "lon": 61.465183636248916
                        },
                        {
                            "lat": 55.58275593351973,
                            "lon": 61.463468262108755
                        },
                        {
                            "lat": 55.58634112756289,
                            "lon": 61.463004618323495
                        },
                        {
                            "lat": 55.587310316891916,
                            "lon": 61.46471995954422
                        },
                        {
                            "lat": 55.59089556703266,
                            "lon": 61.46425619054346
                        },
                        {
                            "lat": 55.59186505714726,
                            "lon": 61.46597148947433
                        },
                        {
                            "lat": 55.595450363343026,
                            "lon": 61.46550759524974
                        },
                        {
                            "lat": 55.594480694809555,
                            "lon": 61.463792329252904
                        },
                        {
                            "lat": 55.59351114865834,
                            "lon": 61.46207705388775
                        },
                        {
                            "lat": 55.59254172486813,
                            "lon": 61.46036176915589
                        },
                        {
                            "lat": 55.59612637352281,
                            "lon": 61.45989788145066
                        },
                        {
                            "lat": 55.59515689372991,
                            "lon": 61.45818262028295
                        },
                        {
                            "lat": 55.591572423417595,
                            "lon": 61.45864647505893
                        },
                        {
                            "lat": 55.58798783078686,
                            "lon": 61.4591102375681
                        },
                        {
                            "lat": 55.587018830000034,
                            "lon": 61.45739490118735
                        },
                        {
                            "lat": 55.59060324428556,
                            "lon": 61.4569311715984
                        },
                        {
                            "lat": 55.58963418745069,
                            "lon": 61.455215858775986
                        },
                        {
                            "lat": 55.58604995149199,
                            "lon": 61.455679555448135
                        },
                        {
                            "lat": 55.58246559327574,
                            "lon": 61.45614315985833
                        },
                        {
                            "lat": 55.5834342934357,
                            "lon": 61.457858538508766
                        },
                        {
                            "lat": 55.58440311585605,
                            "lon": 61.45957390780417
                        },
                        {
                            "lat": 55.58081827864361,
                            "lon": 61.460037485760914
                        },
                        {
                            "lat": 55.579849634611016,
                            "lon": 61.45832208355639
                        },
                        {
                            "lat": 55.57626485354434,
                            "lon": 61.45878553632396
                        },
                        {
                            "lat": 55.57267995025411,
                            "lon": 61.45924889680517
                        },
                        {
                            "lat": 55.56909492475878,
                            "lon": 61.459712164993796
                        },
                        {
                            "lat": 55.565509777076706,
                            "lon": 61.460175340883545
                        },
                        {
                            "lat": 55.5619245072264,
                            "lon": 61.46063842446811
                        },
                        {
                            "lat": 55.558339115226254,
                            "lon": 61.46110141574129
                        },
                        {
                            "lat": 55.55475360109474,
                            "lon": 61.461564314696744
                        },
                        {
                            "lat": 55.555720995818234,
                            "lon": 61.46327994710884
                        },
                        {
                            "lat": 55.552135181016084,
                            "lon": 61.46374278660487
                        },
                        {
                            "lat": 55.548549244098204,
                            "lon": 61.46420553376507
                        },
                        {
                            "lat": 55.54496318508306,
                            "lon": 61.46466818858317
                        },
                        {
                            "lat": 55.54593016614861,
                            "lon": 61.46638391025326
                        },
                        {
                            "lat": 55.54234380641481,
                            "lon": 61.4668465055742
                        },
                        {
                            "lat": 55.54137700398913,
                            "lon": 61.46513075105294
                        },
                        {
                            "lat": 55.53779070083487,
                            "lon": 61.46559322116811
                        },
                        {
                            "lat": 55.538757324599466,
                            "lon": 61.467309008535004
                        },
                        {
                            "lat": 55.53517072072103,
                            "lon": 61.46777141912932
                        },
                        {
                            "lat": 55.53613728784789,
                            "lon": 61.4694872300262
                        },
                        {
                            "lat": 55.532550383202846,
                            "lon": 61.46994958108567
                        },
                        {
                            "lat": 55.528963356510424,
                            "lon": 61.47041183976057
                        },
                        {
                            "lat": 55.525376207789144,
                            "lon": 61.470874006044646
                        },
                        {
                            "lat": 55.52178893705752,
                            "lon": 61.471336079931625
                        },
                        {
                            "lat": 55.51820154433405,
                            "lon": 61.47179806141521
                        },
                        {
                            "lat": 55.51916733961183,
                            "lon": 61.47351402715117
                        },
                        {
                            "lat": 55.52275491116076,
                            "lon": 61.47305201284864
                        },
                        {
                            "lat": 55.52634236069661,
                            "lon": 61.47258990613713
                        },
                        {
                            "lat": 55.52730863563566,
                            "lon": 61.47430579692669
                        },
                        {
                            "lat": 55.53089614194139,
                            "lon": 61.47384356497891
                        },
                        {
                            "lat": 55.53448352617581,
                            "lon": 61.47338124062912
                        },
                        {
                            "lat": 55.538070788320404,
                            "lon": 61.47291882388356
                        },
                        {
                            "lat": 55.54165792835666,
                            "lon": 61.47245631474853
                        },
                        {
                            "lat": 55.545244946266145,
                            "lon": 61.47199371323029
                        },
                        {
                            "lat": 55.548831842030324,
                            "lon": 61.47153101933508
                        },
                        {
                            "lat": 55.55241861563075,
                            "lon": 61.47106823306923
                        },
                        {
                            "lat": 55.55600526704893,
                            "lon": 61.47060535443897
                        },
                        {
                            "lat": 55.54331073092204,
                            "lon": 61.46856225077859
                        },
                        {
                            "lat": 55.54689726931416,
                            "lon": 61.46809962260299
                        },
                        {
                            "lat": 55.547864494600994,
                            "lon": 61.46981532563081
                        },
                        {
                            "lat": 55.54427777753209,
                            "lon": 61.47027798666449
                        },
                        {
                            "lat": 55.54331073092204,
                            "lon": 61.46856225077859
                        }
                    ],
                    [
                        {
                            "lat": 55.51557964606832,
                            "lon": 61.47397594903853
                        },
                        {
                            "lat": 55.516545384475506,
                            "lon": 61.475691938295334
                        },
                        {
                            "lat": 55.520133256884186,
                            "lon": 61.475229983591184
                        },
                        {
                            "lat": 55.51916733961183,
                            "lon": 61.47351402715117
                        },
                        {
                            "lat": 55.51557964606832,
                            "lon": 61.47397594903853
                        }
                    ],
                    [
                        {
                            "lat": 55.55105052446549,
                            "lon": 61.48228787681455
                        },
                        {
                            "lat": 55.550082622540835,
                            "lon": 61.48057220620255
                        },
                        {
                            "lat": 55.5491148428678,
                            "lon": 61.47885652626098
                        },
                        {
                            "lat": 55.54552710990739,
                            "lon": 61.479319259237364
                        },
                        {
                            "lat": 55.54649471077149,
                            "lon": 61.48103497205211
                        },
                        {
                            "lat": 55.542906676769036,
                            "lon": 61.48149764549053
                        },
                        {
                            "lat": 55.543874221017525,
                            "lon": 61.48321338185012
                        },
                        {
                            "lat": 55.54746243386869,
                            "lon": 61.48275067554069
                        },
                        {
                            "lat": 55.548430279220256,
                            "lon": 61.484466369701536
                        },
                        {
                            "lat": 55.552018548662986,
                            "lon": 61.484003538095344
                        },
                        {
                            "lat": 55.55105052446549,
                            "lon": 61.48228787681455
                        }
                    ],
                    [
                        {
                            "lat": 55.5463760179467,
                            "lon": 61.50129602532196
                        },
                        {
                            "lat": 55.54996626518211,
                            "lon": 61.500833175076984
                        },
                        {
                            "lat": 55.553556389987186,
                            "lon": 61.50037023234679
                        },
                        {
                            "lat": 55.552587622422024,
                            "lon": 61.49865458930185
                        },
                        {
                            "lat": 55.54899767667207,
                            "lon": 61.49911749913212
                        },
                        {
                            "lat": 55.54540760851314,
                            "lon": 61.499580316482806
                        },
                        {
                            "lat": 55.5463760179467,
                            "lon": 61.50129602532196
                        }
                    ],
                    [
                        {
                            "lat": 55.54210004424272,
                            "lon": 61.507368716199345
                        },
                        {
                            "lat": 55.538508892295084,
                            "lon": 61.507831387604305
                        },
                        {
                            "lat": 55.53947725392397,
                            "lon": 61.50954715779143
                        },
                        {
                            "lat": 55.54306858508619,
                            "lon": 61.50908445349515
                        },
                        {
                            "lat": 55.54210004424272,
                            "lon": 61.507368716199345
                        }
                    ],
                    [
                        {
                            "lat": 55.50296238612747,
                            "lon": 61.37623200229903
                        },
                        {
                            "lat": 55.506539117968984,
                            "lon": 61.37577083451625
                        },
                        {
                            "lat": 55.510115728753256,
                            "lon": 61.375309574688
                        },
                        {
                            "lat": 55.513692218461955,
                            "lon": 61.37484822282043
                        },
                        {
                            "lat": 55.51726858707674,
                            "lon": 61.374386778919856
                        },
                        {
                            "lat": 55.5163068604106,
                            "lon": 61.37267077092512
                        },
                        {
                            "lat": 55.51273066935221,
                            "lon": 61.37313218211931
                        },
                        {
                            "lat": 55.50915435722089,
                            "lon": 61.37359350128601
                        },
                        {
                            "lat": 55.50557792403504,
                            "lon": 61.37405472841902
                        },
                        {
                            "lat": 55.5020013698129,
                            "lon": 61.37451586351212
                        },
                        {
                            "lat": 55.4984246945729,
                            "lon": 61.374976906559056
                        },
                        {
                            "lat": 55.499385533247086,
                            "lon": 61.37669307803013
                        },
                        {
                            "lat": 55.50296238612747,
                            "lon": 61.37623200229903
                        }
                    ],
                    [
                        {
                            "lat": 55.491270981112564,
                            "lon": 61.37589871648964
                        },
                        {
                            "lat": 55.49223146444304,
                            "lon": 61.377614953312325
                        },
                        {
                            "lat": 55.49580855934615,
                            "lon": 61.37715406170329
                        },
                        {
                            "lat": 55.494847898333326,
                            "lon": 61.37543785755363
                        },
                        {
                            "lat": 55.491270981112564,
                            "lon": 61.37589871648964
                        }
                    ],
                    [
                        {
                            "lat": 55.500346492941816,
                            "lon": 61.378409240259465
                        },
                        {
                            "lat": 55.501307573678055,
                            "lon": 61.38012539324555
                        },
                        {
                            "lat": 55.50488478189425,
                            "lon": 61.379664252135925
                        },
                        {
                            "lat": 55.503923523480914,
                            "lon": 61.377948131840824
                        },
                        {
                            "lat": 55.500346492941816,
                            "lon": 61.378409240259465
                        }
                    ],
                    [
                        {
                            "lat": 55.4999196882682,
                            "lon": 61.39134451530578
                        },
                        {
                            "lat": 55.500881261209095,
                            "lon": 61.393060668957936
                        },
                        {
                            "lat": 55.50445995607975,
                            "lon": 61.39259948313812
                        },
                        {
                            "lat": 55.5034982052912,
                            "lon": 61.39088336218971
                        },
                        {
                            "lat": 55.4999196882682,
                            "lon": 61.39134451530578
                        }
                    ],
                    [
                        {
                            "lat": 55.5224969186473,
                            "lon": 61.37003172064977
                        },
                        {
                            "lat": 55.523458879235356,
                            "lon": 61.37174767247914
                        },
                        {
                            "lat": 55.527034706965075,
                            "lon": 61.3712859852398
                        },
                        {
                            "lat": 55.526072568901924,
                            "lon": 61.36957006613009
                        },
                        {
                            "lat": 55.5224969186473,
                            "lon": 61.37003172064977
                        }
                    ],
                    [
                        {
                            "lat": 55.53061041354861,
                            "lon": 61.37082420599787
                        },
                        {
                            "lat": 55.527034706965075,
                            "lon": 61.3712859852398
                        },
                        {
                            "lat": 55.52799696617442,
                            "lon": 61.37300189508206
                        },
                        {
                            "lat": 55.53157285023034,
                            "lon": 61.372540083111524
                        },
                        {
                            "lat": 55.53061041354861,
                            "lon": 61.37082420599787
                        }
                    ],
                    [
                        {
                            "lat": 55.519464056827594,
                            "lon": 61.38514427260938
                        },
                        {
                            "lat": 55.52042645403587,
                            "lon": 61.38686024848992
                        },
                        {
                            "lat": 55.52400406605881,
                            "lon": 61.38639857554903
                        },
                        {
                            "lat": 55.5230414911658,
                            "lon": 61.38468263239881
                        },
                        {
                            "lat": 55.519464056827594,
                            "lon": 61.38514427260938
                        }
                    ],
                    [
                        {
                            "lat": 55.594686624867045,
                            "lon": 61.491377203026325
                        },
                        {
                            "lat": 55.5910983282494,
                            "lon": 61.49184118915814
                        },
                        {
                            "lat": 55.59206894138256,
                            "lon": 61.49355647931195
                        },
                        {
                            "lat": 55.595657416780924,
                            "lon": 61.49309246021664
                        },
                        {
                            "lat": 55.594686624867045,
                            "lon": 61.491377203026325
                        }
                    ],
                    [
                        {
                            "lat": 55.5970959756738,
                            "lon": 61.46161313324982
                        },
                        {
                            "lat": 55.5980657002042,
                            "lon": 61.4633283756788
                        },
                        {
                            "lat": 55.601650583198165,
                            "lon": 61.4628643298275
                        },
                        {
                            "lat": 55.600680680309864,
                            "lon": 61.46114912034021
                        },
                        {
                            "lat": 55.5970959756738,
                            "lon": 61.46161313324982
                        }
                    ],
                    [
                        {
                            "lat": 55.57050198108414,
                            "lon": 61.38038845160391
                        },
                        {
                            "lat": 55.57146698546167,
                            "lon": 61.38210395944046
                        },
                        {
                            "lat": 55.57504307559468,
                            "lon": 61.381640965334995
                        },
                        {
                            "lat": 55.57407789380879,
                            "lon": 61.3799254903099
                        },
                        {
                            "lat": 55.57050198108414,
                            "lon": 61.38038845160391
                        }
                    ],
                    [
                        {
                            "lat": 55.57629383054788,
                            "lon": 61.390681358853875
                        },
                        {
                            "lat": 55.577259564352396,
                            "lon": 61.3923968107716
                        },
                        {
                            "lat": 55.580836719319706,
                            "lon": 61.391933619725414
                        },
                        {
                            "lat": 55.5818027523056,
                            "lon": 61.39364902948237
                        },
                        {
                            "lat": 55.585379963124936,
                            "lon": 61.39318571357791
                        },
                        {
                            "lat": 55.58441375262375,
                            "lon": 61.39147033666196
                        },
                        {
                            "lat": 55.583447663804115,
                            "lon": 61.38975495041379
                        },
                        {
                            "lat": 55.582481696644926,
                            "lon": 61.38803955483502
                        },
                        {
                            "lat": 55.57890501831661,
                            "lon": 61.388502772226786
                        },
                        {
                            "lat": 55.575328218367225,
                            "lon": 61.38896589761238
                        },
                        {
                            "lat": 55.57629383054788,
                            "lon": 61.390681358853875
                        }
                    ],
                    [
                        {
                            "lat": 55.61542441205473,
                            "lon": 61.357998820289424
                        },
                        {
                            "lat": 55.61639098016409,
                            "lon": 61.359713919605795
                        },
                        {
                            "lat": 55.61996365215483,
                            "lon": 61.359249842323756
                        },
                        {
                            "lat": 55.62353620252487,
                            "lon": 61.35878567320122
                        },
                        {
                            "lat": 55.62710863125608,
                            "lon": 61.35832141224443
                        },
                        {
                            "lat": 55.62614153242479,
                            "lon": 61.3566064115358
                        },
                        {
                            "lat": 55.62256928057985,
                            "lon": 61.35707063961783
                        },
                        {
                            "lat": 55.6189969071171,
                            "lon": 61.357534775871116
                        },
                        {
                            "lat": 55.61542441205473,
                            "lon": 61.357998820289424
                        }
                    ],
                    [
                        {
                            "lat": 55.787741733982784,
                            "lon": 61.337334722163384
                        },
                        {
                            "lat": 55.78417479774541,
                            "lon": 61.33780310918411
                        },
                        {
                            "lat": 55.785149781144156,
                            "lon": 61.33951664843094
                        },
                        {
                            "lat": 55.78871689333596,
                            "lon": 61.33904822828425
                        },
                        {
                            "lat": 55.787741733982784,
                            "lon": 61.337334722163384
                        }
                    ],
                    [
                        {
                            "lat": 55.62732865588534,
                            "lon": 61.385903764827205
                        },
                        {
                            "lat": 55.62375308396561,
                            "lon": 61.386368151610505
                        },
                        {
                            "lat": 55.62278504407186,
                            "lon": 61.384653126941494
                        },
                        {
                            "lat": 55.6218171260394,
                            "lon": 61.38293809290417
                        },
                        {
                            "lat": 55.618241786748655,
                            "lon": 61.383402321951586
                        },
                        {
                            "lat": 55.61920952753454,
                            "lon": 61.38511738888402
                        },
                        {
                            "lat": 55.620177390163484,
                            "lon": 61.386832446451564
                        },
                        {
                            "lat": 55.61660157449716,
                            "lon": 61.38729664934411
                        },
                        {
                            "lat": 55.61756938168611,
                            "lon": 61.38901173044159
                        },
                        {
                            "lat": 55.621145374656585,
                            "lon": 61.38854749465261
                        },
                        {
                            "lat": 55.62472124574177,
                            "lon": 61.388083166909595
                        },
                        {
                            "lat": 55.62829699492341,
                            "lon": 61.38761874721879
                        },
                        {
                            "lat": 55.62732865588534,
                            "lon": 61.385903764827205
                        }
                    ],
                    [
                        {
                            "lat": 55.59968758919286,
                            "lon": 61.39133152985673
                        },
                        {
                            "lat": 55.59611086529385,
                            "lon": 61.39179521379004
                        },
                        {
                            "lat": 55.59707772997179,
                            "lon": 61.39351048280565
                        },
                        {
                            "lat": 55.60065463131995,
                            "lon": 61.39304676600574
                        },
                        {
                            "lat": 55.59968758919286,
                            "lon": 61.39133152985673
                        }
                    ],
                    [
                        {
                            "lat": 55.64320206776358,
                            "lon": 61.40040862661914
                        },
                        {
                            "lat": 55.64417185964746,
                            "lon": 61.40212345868532
                        },
                        {
                            "lat": 55.647748838615975,
                            "lon": 61.4016584667171
                        },
                        {
                            "lat": 55.64677886936951,
                            "lon": 61.39994366760593
                        },
                        {
                            "lat": 55.64580902226875,
                            "lon": 61.39822885909676
                        },
                        {
                            "lat": 55.64223239800715,
                            "lon": 61.398693785158365
                        },
                        {
                            "lat": 55.64320206776358,
                            "lon": 61.40040862661914
                        }
                    ],
                    [
                        {
                            "lat": 55.6227074213537,
                            "lon": 61.40491147871966
                        },
                        {
                            "lat": 55.619129587728025,
                            "lon": 61.40537582670437
                        },
                        {
                            "lat": 55.62009825950188,
                            "lon": 61.40709087992861
                        },
                        {
                            "lat": 55.6236762706566,
                            "lon": 61.406626499024206
                        },
                        {
                            "lat": 55.6227074213537,
                            "lon": 61.40491147871966
                        }
                    ],
                    [
                        {
                            "lat": 55.61294187108476,
                            "lon": 61.4080193656464
                        },
                        {
                            "lat": 55.61652012630518,
                            "lon": 61.40755516880477
                        },
                        {
                            "lat": 55.615551632081505,
                            "lon": 61.405840082666394
                        },
                        {
                            "lat": 55.61197355443245,
                            "lon": 61.406304246599426
                        },
                        {
                            "lat": 55.60839535479911,
                            "lon": 61.406768318497235
                        },
                        {
                            "lat": 55.6093634938589,
                            "lon": 61.40848347044726
                        },
                        {
                            "lat": 55.610331754905815,
                            "lon": 61.41019861303389
                        },
                        {
                            "lat": 55.61391030974249,
                            "lon": 61.409734475326516
                        },
                        {
                            "lat": 55.61294187108476,
                            "lon": 61.4080193656464
                        }
                    ],
                    [
                        {
                            "lat": 55.61391030974249,
                            "lon": 61.409734475326516
                        },
                        {
                            "lat": 55.61487887042683,
                            "lon": 61.41144957563825
                        },
                        {
                            "lat": 55.618457480844825,
                            "lon": 61.410985312969096
                        },
                        {
                            "lat": 55.61748874255255,
                            "lon": 61.40927024557288
                        },
                        {
                            "lat": 55.61391030974249,
                            "lon": 61.409734475326516
                        }
                    ],
                    [
                        {
                            "lat": 55.70820067242069,
                            "lon": 61.40667241812632
                        },
                        {
                            "lat": 55.70917427967658,
                            "lon": 61.40838664659319
                        },
                        {
                            "lat": 55.71275071863171,
                            "lon": 61.40791991934473
                        },
                        {
                            "lat": 55.71177693420697,
                            "lon": 61.40620572394893
                        },
                        {
                            "lat": 55.70820067242069,
                            "lon": 61.40667241812632
                        }
                    ],
                    [
                        {
                            "lat": 55.69189128866137,
                            "lon": 61.445623687437944
                        },
                        {
                            "lat": 55.69547232660351,
                            "lon": 61.4451572517433
                        },
                        {
                            "lat": 55.694497984715554,
                            "lon": 61.443442905388366
                        },
                        {
                            "lat": 55.69352376565644,
                            "lon": 61.44172854957145
                        },
                        {
                            "lat": 55.68994308315182,
                            "lon": 61.442194919106875
                        },
                        {
                            "lat": 55.69091712450132,
                            "lon": 61.4439093080017
                        },
                        {
                            "lat": 55.69189128866137,
                            "lon": 61.445623687437944
                        }
                    ],
                    [
                        {
                            "lat": 55.68928423711182,
                            "lon": 61.44780443409729
                        },
                        {
                            "lat": 55.69025846917121,
                            "lon": 61.44951882769438
                        },
                        {
                            "lat": 55.69383998549845,
                            "lon": 61.44905241792842
                        },
                        {
                            "lat": 55.6928655756533,
                            "lon": 61.447338057414065
                        },
                        {
                            "lat": 55.68928423711182,
                            "lon": 61.44780443409729
                        }
                    ],
                    [
                        {
                            "lat": 55.709140065601844,
                            "lon": 61.44890007656068
                        },
                        {
                            "lat": 55.71011543229321,
                            "lon": 61.45061428573272
                        },
                        {
                            "lat": 55.7136966894781,
                            "lon": 61.450147349289665
                        },
                        {
                            "lat": 55.71272114507114,
                            "lon": 61.44843317323496
                        },
                        {
                            "lat": 55.709140065601844,
                            "lon": 61.44890007656068
                        }
                    ],
                    [
                        {
                            "lat": 55.68633251277794,
                            "lon": 61.46291868932475
                        },
                        {
                            "lat": 55.68730719436062,
                            "lon": 61.46463310649529
                        },
                        {
                            "lat": 55.69089050265814,
                            "lon": 61.46416670841938
                        },
                        {
                            "lat": 55.68991564307937,
                            "lon": 61.46245232434259
                        },
                        {
                            "lat": 55.68633251277794,
                            "lon": 61.46291868932475
                        }
                    ],
                    [
                        {
                            "lat": 55.78759327645028,
                            "lon": 61.45153914265804
                        },
                        {
                            "lat": 55.78857305732593,
                            "lon": 61.45325262155004
                        },
                        {
                            "lat": 55.79215309240441,
                            "lon": 61.45278361198748
                        },
                        {
                            "lat": 55.79117313411334,
                            "lon": 61.45107016634769
                        },
                        {
                            "lat": 55.78759327645028,
                            "lon": 61.45153914265804
                        }
                    ]
                ]
            },
            {
                "type": "MultiPolygon",
                "value": 0.07,
                "data": [
                    [
                        {
                            "lat": 55.33254623812861,
                            "lon": 61.41463238314489
                        },
                        {
                            "lat": 55.3334995488531,
                            "lon": 61.41635005848773
                        },
                        {
                            "lat": 55.33708409775357,
                            "lon": 61.41589317659856
                        },
                        {
                            "lat": 55.33803770767351,
                            "lon": 61.41761081040317
                        },
                        {
                            "lat": 55.33445297990231,
                            "lon": 61.41806772474017
                        },
                        {
                            "lat": 55.335406531297146,
                            "lon": 61.41978538190066
                        },
                        {
                            "lat": 55.33636020305858,
                            "lon": 61.421503029967695
                        },
                        {
                            "lat": 55.337313995207545,
                            "lon": 61.42322066893973
                        },
                        {
                            "lat": 55.33826790776499,
                            "lon": 61.42493829881513
                        },
                        {
                            "lat": 55.33922194075185,
                            "lon": 61.426655919592456
                        },
                        {
                            "lat": 55.34017609418909,
                            "lon": 61.42837353127011
                        },
                        {
                            "lat": 55.34376189557562,
                            "lon": 61.42791642217467
                        },
                        {
                            "lat": 55.34280756315596,
                            "lon": 61.426198842965256
                        },
                        {
                            "lat": 55.346393065091064,
                            "lon": 61.42574167389291
                        },
                        {
                            "lat": 55.34543867419757,
                            "lon": 61.42402411805089
                        },
                        {
                            "lat": 55.341853351205295,
                            "lon": 61.4244812546528
                        },
                        {
                            "lat": 55.34089925970267,
                            "lon": 61.4227636572388
                        },
                        {
                            "lat": 55.34448440377069,
                            "lon": 61.42230655310396
                        },
                        {
                            "lat": 55.34806942739303,
                            "lon": 61.42184935654141
                        },
                        {
                            "lat": 55.3471150985272,
                            "lon": 61.420131814960335
                        },
                        {
                            "lat": 55.35069982282151,
                            "lon": 61.4196745584511
                        },
                        {
                            "lat": 55.3542844266539,
                            "lon": 61.419217209532135
                        },
                        {
                            "lat": 55.355239113226226,
                            "lon": 61.420934686157814
                        },
                        {
                            "lat": 55.35882377539989,
                            "lon": 61.42047721234921
                        },
                        {
                            "lat": 55.36240831705348,
                            "lon": 61.42001964613763
                        },
                        {
                            "lat": 55.365992738168394,
                            "lon": 61.41956198752927
                        },
                        {
                            "lat": 55.36957703872608,
                            "lon": 61.41910423653034
                        },
                        {
                            "lat": 55.37053256103566,
                            "lon": 61.42082157405077
                        },
                        {
                            "lat": 55.37411691977406,
                            "lon": 61.420363698155526
                        },
                        {
                            "lat": 55.3777011578971,
                            "lon": 61.41990572987652
                        },
                        {
                            "lat": 55.38128527538621,
                            "lon": 61.419447669219885
                        },
                        {
                            "lat": 55.382241454554915,
                            "lon": 61.42116490004872
                        },
                        {
                            "lat": 55.378657158332665,
                            "lon": 61.42162299323191
                        },
                        {
                            "lat": 55.379613279423154,
                            "lon": 61.42334024745018
                        },
                        {
                            "lat": 55.38319775439709,
                            "lon": 61.42288212173705
                        },
                        {
                            "lat": 55.38678210867657,
                            "lon": 61.42242390364133
                        },
                        {
                            "lat": 55.38773870796247,
                            "lon": 61.424141083648585
                        },
                        {
                            "lat": 55.3913231202572,
                            "lon": 61.423682740631904
                        },
                        {
                            "lat": 55.394907411799366,
                            "lon": 61.42322430523942
                        },
                        {
                            "lat": 55.398491582570415,
                            "lon": 61.422765777477345
                        },
                        {
                            "lat": 55.3994488387678,
                            "lon": 61.42448285067679
                        },
                        {
                            "lat": 55.40303306743299,
                            "lon": 61.424024197986554
                        },
                        {
                            "lat": 55.40207563255178,
                            "lon": 61.422307157351895
                        },
                        {
                            "lat": 55.40111831847878,
                            "lon": 61.42059010755654
                        },
                        {
                            "lat": 55.40470206900765,
                            "lon": 61.42013142764089
                        },
                        {
                            "lat": 55.40565956172492,
                            "lon": 61.42184844486926
                        },
                        {
                            "lat": 55.40661717526894,
                            "lon": 61.42356545293355
                        },
                        {
                            "lat": 55.41020116225712,
                            "lon": 61.42310661552394
                        },
                        {
                            "lat": 55.41378502837899,
                            "lon": 61.42264768576399
                        },
                        {
                            "lat": 55.412827057572386,
                            "lon": 61.42093074285731
                        },
                        {
                            "lat": 55.41641062420965,
                            "lon": 61.420471753340394
                        },
                        {
                            "lat": 55.417368773616026,
                            "lon": 61.4221886636599
                        },
                        {
                            "lat": 55.42095239794969,
                            "lon": 61.42172954921789
                        },
                        {
                            "lat": 55.421910846856704,
                            "lon": 61.42344641776532
                        },
                        {
                            "lat": 55.42286941670666,
                            "lon": 61.4251632771319
                        },
                        {
                            "lat": 55.42382810752053,
                            "lon": 61.42688012731605
                        },
                        {
                            "lat": 55.42741214671662,
                            "lon": 61.42642082272669
                        },
                        {
                            "lat": 55.43099606490921,
                            "lon": 61.425961425795016
                        },
                        {
                            "lat": 55.43457986207976,
                            "lon": 61.425501936527304
                        },
                        {
                            "lat": 55.43816353820977,
                            "lon": 61.42504235492971
                        },
                        {
                            "lat": 55.43720413306894,
                            "lon": 61.4233256352136
                        },
                        {
                            "lat": 55.436244848966105,
                            "lon": 61.4216089063014
                        },
                        {
                            "lat": 55.43982804699728,
                            "lon": 61.421149297638735
                        },
                        {
                            "lat": 55.443411123993,
                            "lon": 61.42068959666982
                        },
                        {
                            "lat": 55.44699407993475,
                            "lon": 61.42022980340091
                        },
                        {
                            "lat": 55.44795389950635,
                            "lon": 61.421946434413584
                        },
                        {
                            "lat": 55.448913840171485,
                            "lon": 61.423663056219944
                        },
                        {
                            "lat": 55.452497031954266,
                            "lon": 61.4232031053651
                        },
                        {
                            "lat": 55.451536912823144,
                            "lon": 61.4214865162065
                        },
                        {
                            "lat": 55.45511980502795,
                            "lon": 61.421026505706266
                        },
                        {
                            "lat": 55.45608010260399,
                            "lon": 61.42274306221149
                        },
                        {
                            "lat": 55.45966305210221,
                            "lon": 61.42228292676538
                        },
                        {
                            "lat": 55.45870257610227,
                            "lon": 61.42056640291905
                        },
                        {
                            "lat": 55.46228522602761,
                            "lon": 61.420106207851155
                        },
                        {
                            "lat": 55.46586775478553,
                            "lon": 61.41964592050872
                        },
                        {
                            "lat": 55.46945016235752,
                            "lon": 61.41918554089802
                        },
                        {
                            "lat": 55.47041117350281,
                            "lon": 61.42090196673397
                        },
                        {
                            "lat": 55.47399363821008,
                            "lon": 61.42044146217992
                        },
                        {
                            "lat": 55.47303244872516,
                            "lon": 61.41872506902526
                        },
                        {
                            "lat": 55.476614613869955,
                            "lon": 61.41826450489668
                        },
                        {
                            "lat": 55.477575981673475,
                            "lon": 61.41998086536449
                        },
                        {
                            "lat": 55.48115820387451,
                            "lon": 61.41952017629385
                        },
                        {
                            "lat": 55.48474030479473,
                            "lon": 61.419059394974276
                        },
                        {
                            "lat": 55.488322284415695,
                            "lon": 61.418598521411965
                        },
                        {
                            "lat": 55.48928430836626,
                            "lon": 61.42031477454015
                        },
                        {
                            "lat": 55.49024645365561,
                            "lon": 61.422031018421286
                        },
                        {
                            "lat": 55.493828668482806,
                            "lon": 61.421569987193834
                        },
                        {
                            "lat": 55.49741076193168,
                            "lon": 61.42110886372499
                        },
                        {
                            "lat": 55.49644826012104,
                            "lon": 61.41939268528152
                        },
                        {
                            "lat": 55.500030053944165,
                            "lon": 61.41893150230467
                        },
                        {
                            "lat": 55.5036117263732,
                            "lon": 61.41847022710444
                        },
                        {
                            "lat": 55.50457458462079,
                            "lon": 61.42018634008798
                        },
                        {
                            "lat": 55.505537564301974,
                            "lon": 61.42190244380915
                        },
                        {
                            "lat": 55.50650066543788,
                            "lon": 61.423618538266425
                        },
                        {
                            "lat": 55.51008275107017,
                            "lon": 61.42315707262375
                        },
                        {
                            "lat": 55.51366471520822,
                            "lon": 61.42269551475348
                        },
                        {
                            "lat": 55.51462829428306,
                            "lon": 61.42441153444244
                        },
                        {
                            "lat": 55.51821031510834,
                            "lon": 61.423949851591026
                        },
                        {
                            "lat": 55.51724655783362,
                            "lon": 61.42223386466183
                        },
                        {
                            "lat": 55.52082827892794,
                            "lon": 61.421772122355016
                        },
                        {
                            "lat": 55.52179221438144,
                            "lon": 61.423488076518865
                        },
                        {
                            "lat": 55.52537399208394,
                            "lon": 61.42302620923225
                        },
                        {
                            "lat": 55.5289556481974,
                            "lon": 61.42256424973739
                        },
                        {
                            "lat": 55.53253718270344,
                            "lon": 61.42210219804055
                        },
                        {
                            "lat": 55.533501774192445,
                            "lon": 61.42381804458386
                        },
                        {
                            "lat": 55.537083365185346,
                            "lon": 61.42335586790021
                        },
                        {
                            "lat": 55.54066483451291,
                            "lon": 61.422893599021506
                        },
                        {
                            "lat": 55.544246182156726,
                            "lon": 61.422431237953965
                        },
                        {
                            "lat": 55.54782740809841,
                            "lon": 61.421968784703886
                        },
                        {
                            "lat": 55.54879283363197,
                            "lon": 61.4236844907432
                        },
                        {
                            "lat": 55.55237411589967,
                            "lon": 61.42322191249999
                        },
                        {
                            "lat": 55.55595527640732,
                            "lon": 61.42275924208116
                        },
                        {
                            "lat": 55.55692117979133,
                            "lon": 61.42447487316662
                        },
                        {
                            "lat": 55.55788720497489,
                            "lon": 61.426190494935675
                        },
                        {
                            "lat": 55.56146859976781,
                            "lon": 61.42572766668139
                        },
                        {
                            "lat": 55.5650498727216,
                            "lon": 61.42526474625284
                        },
                        {
                            "lat": 55.56601637582355,
                            "lon": 61.426980293021984
                        },
                        {
                            "lat": 55.56959770493697,
                            "lon": 61.42651724757609
                        },
                        {
                            "lat": 55.56863102381792,
                            "lon": 61.42480173365628
                        },
                        {
                            "lat": 55.5722120530384,
                            "lon": 61.424338628897985
                        },
                        {
                            "lat": 55.573178912153345,
                            "lon": 61.42605410996291
                        },
                        {
                            "lat": 55.57675999745437,
                            "lon": 61.425590880188665
                        },
                        {
                            "lat": 55.57772715647783,
                            "lon": 61.427306319056434
                        },
                        {
                            "lat": 55.581308297817024,
                            "lon": 61.42684296425795
                        },
                        {
                            "lat": 55.58488931718298,
                            "lon": 61.42637951730539
                        },
                        {
                            "lat": 55.588470214557304,
                            "lon": 61.42591597820498
                        },
                        {
                            "lat": 55.58943802944301,
                            "lon": 61.42763130909912
                        },
                        {
                            "lat": 55.59040596635986,
                            "lon": 61.42934663064303
                        },
                        {
                            "lat": 55.59398709759606,
                            "lon": 61.42888293361851
                        },
                        {
                            "lat": 55.59756810676158,
                            "lon": 61.42841914444757
                        },
                        {
                            "lat": 55.59853652163647,
                            "lon": 61.430134390841246
                        },
                        {
                            "lat": 55.60211758663398,
                            "lon": 61.4296704766226
                        },
                        {
                            "lat": 55.6030863015585,
                            "lon": 61.43138568074504
                        },
                        {
                            "lat": 55.6040551386328,
                            "lon": 61.43310087550214
                        },
                        {
                            "lat": 55.607636437356454,
                            "lon": 61.43263680330749
                        },
                        {
                            "lat": 55.6086055745566,
                            "lon": 61.43435195577429
                        },
                        {
                            "lat": 55.60957483396731,
                            "lon": 61.436067098869124
                        },
                        {
                            "lat": 55.605993179315604,
                            "lon": 61.43653123691406
                        },
                        {
                            "lat": 55.60696238296653,
                            "lon": 61.438246403565664
                        },
                        {
                            "lat": 55.60793170885212,
                            "lon": 61.43996156084561
                        },
                        {
                            "lat": 55.60890115699356,
                            "lon": 61.44167670875222
                        },
                        {
                            "lat": 55.60531884603505,
                            "lon": 61.44214085342004
                        },
                        {
                            "lat": 55.6062882383858,
                            "lon": 61.44385602488693
                        },
                        {
                            "lat": 55.60725775301653,
                            "lon": 61.445571186980736
                        },
                        {
                            "lat": 55.60367496360274,
                            "lon": 61.44603530532642
                        },
                        {
                            "lat": 55.60464442240892,
                            "lon": 61.447750490982024
                        },
                        {
                            "lat": 55.60106133256524,
                            "lon": 61.448214550056534
                        },
                        {
                            "lat": 55.597478120435795,
                            "lon": 61.448678516917155
                        },
                        {
                            "lat": 55.59650901794494,
                            "lon": 61.44696326540543
                        },
                        {
                            "lat": 55.592925861737655,
                            "lon": 61.44742710712621
                        },
                        {
                            "lat": 55.593894786038966,
                            "lon": 61.449142391557665
                        },
                        {
                            "lat": 55.59031132939319,
                            "lon": 61.44960617397172
                        },
                        {
                            "lat": 55.59128019771433,
                            "lon": 61.45132148195833
                        },
                        {
                            "lat": 55.59486383258928,
                            "lon": 61.45085766662665
                        },
                        {
                            "lat": 55.598447345194,
                            "lon": 61.45039375906301
                        },
                        {
                            "lat": 55.60203073551011,
                            "lon": 61.44992975927365
                        },
                        {
                            "lat": 55.605614003519214,
                            "lon": 61.44946566726484
                        },
                        {
                            "lat": 55.609197149202956,
                            "lon": 61.4490014830429
                        },
                        {
                            "lat": 55.61278017254295,
                            "lon": 61.44853720661407
                        },
                        {
                            "lat": 55.616363073520844,
                            "lon": 61.44807283798464
                        },
                        {
                            "lat": 55.615392958041824,
                            "lon": 61.44635776053807
                        },
                        {
                            "lat": 55.61442296492199,
                            "lon": 61.444642673708366
                        },
                        {
                            "lat": 55.61345309414013,
                            "lon": 61.44292757749716
                        },
                        {
                            "lat": 55.61248334567497,
                            "lon": 61.44121247190605
                        },
                        {
                            "lat": 55.611513719505254,
                            "lon": 61.43949735693659
                        },
                        {
                            "lat": 55.615095607884186,
                            "lon": 61.439033060861085
                        },
                        {
                            "lat": 55.614125926000035,
                            "lon": 61.43731796945422
                        },
                        {
                            "lat": 55.61315636638724,
                            "lon": 61.43560286866878
                        },
                        {
                            "lat": 55.61218692902457,
                            "lon": 61.43388775850637
                        },
                        {
                            "lat": 55.61121761389076,
                            "lon": 61.432172638968574
                        },
                        {
                            "lat": 55.61024842096462,
                            "lon": 61.43045751005699
                        },
                        {
                            "lat": 55.60666742234563,
                            "lon": 61.430921641470384
                        },
                        {
                            "lat": 55.60569852950292,
                            "lon": 61.42920647026449
                        },
                        {
                            "lat": 55.60472975880712,
                            "lon": 61.42749128969146
                        },
                        {
                            "lat": 55.6083104016505,
                            "lon": 61.42702722411884
                        },
                        {
                            "lat": 55.61189092234984,
                            "lon": 61.42656306642487
                        },
                        {
                            "lat": 55.61092191808032,
                            "lon": 61.42484794232225
                        },
                        {
                            "lat": 55.6073415752201,
                            "lon": 61.42531206709545
                        },
                        {
                            "lat": 55.603761110237045,
                            "lon": 61.42577609975285
                        },
                        {
                            "lat": 55.60279258377146,
                            "lon": 61.4240609004503
                        },
                        {
                            "lat": 55.59921217454706,
                            "lon": 61.424524808079404
                        },
                        {
                            "lat": 55.59563164325769,
                            "lon": 61.42498862358573
                        },
                        {
                            "lat": 55.59205098992165,
                            "lon": 61.42545234696301
                        },
                        {
                            "lat": 55.59108311913751,
                            "lon": 61.42373703960636
                        },
                        {
                            "lat": 55.59011537036049,
                            "lon": 61.42202172289925
                        },
                        {
                            "lat": 55.58653495079462,
                            "lon": 61.42248528837241
                        },
                        {
                            "lat": 55.58556750187528,
                            "lon": 61.42076992943714
                        },
                        {
                            "lat": 55.584600174902405,
                            "lon": 61.419054561158035
                        },
                        {
                            "lat": 55.58101998913635,
                            "lon": 61.419517968756814
                        },
                        {
                            "lat": 55.58198713823468,
                            "lon": 61.42123336990618
                        },
                        {
                            "lat": 55.58295440926111,
                            "lon": 61.42294876171513
                        },
                        {
                            "lat": 55.57937374577835,
                            "lon": 61.42341214292114
                        },
                        {
                            "lat": 55.57840665266598,
                            "lon": 61.421696718244064
                        },
                        {
                            "lat": 55.577439681463375,
                            "lon": 61.41998128423
                        },
                        {
                            "lat": 55.57647283214936,
                            "lon": 61.41826584088054
                        },
                        {
                            "lat": 55.5728925804863,
                            "lon": 61.41872903136618
                        },
                        {
                            "lat": 55.56931220697415,
                            "lon": 61.41919212971925
                        },
                        {
                            "lat": 55.56573171163128,
                            "lon": 61.41965513593353
                        },
                        {
                            "lat": 55.564765517888695,
                            "lon": 61.41793958471012
                        },
                        {
                            "lat": 55.563799445937335,
                            "lon": 61.41622402416479
                        },
                        {
                            "lat": 55.56021918465095,
                            "lon": 61.416686872566245
                        },
                        {
                            "lat": 55.561185078677035,
                            "lon": 61.41840246594374
                        },
                        {
                            "lat": 55.562151094476,
                            "lon": 61.42011805000274
                        },
                        {
                            "lat": 55.55857035552671,
                            "lon": 61.42058087192062
                        },
                        {
                            "lat": 55.55498949480177,
                            "lon": 61.42104360168095
                        },
                        {
                            "lat": 55.55402383495348,
                            "lon": 61.419327951967475
                        },
                        {
                            "lat": 55.5504430304783,
                            "lon": 61.419790556745085
                        },
                        {
                            "lat": 55.549477670354825,
                            "lon": 61.418074864904504
                        },
                        {
                            "lat": 55.54589692217167,
                            "lon": 61.418537344707715
                        },
                        {
                            "lat": 55.54493186173628,
                            "lon": 61.416821610754035
                        },
                        {
                            "lat": 55.54135116988741,
                            "lon": 61.41728396559117
                        },
                        {
                            "lat": 55.53777035639981,
                            "lon": 61.41774622826241
                        },
                        {
                            "lat": 55.53680577363101,
                            "lon": 61.41603041941772
                        },
                        {
                            "lat": 55.53584131246148,
                            "lon": 61.4143146012799
                        },
                        {
                            "lat": 55.53226073340792,
                            "lon": 61.41477670620978
                        },
                        {
                            "lat": 55.53322501655942,
                            "lon": 61.41649255713052
                        },
                        {
                            "lat": 55.53418942129182,
                            "lon": 61.41820839876152
                        },
                        {
                            "lat": 55.53060836458182,
                            "lon": 61.41867047708228
                        },
                        {
                            "lat": 55.52964413790696,
                            "lon": 61.41695460267052
                        },
                        {
                            "lat": 55.526063137692034,
                            "lon": 61.41741655603147
                        },
                        {
                            "lat": 55.52248201593301,
                            "lon": 61.41787841720715
                        },
                        {
                            "lat": 55.518900772648294,
                            "lon": 61.41834018619131
                        },
                        {
                            "lat": 55.517937201779944,
                            "lon": 61.416624204194584
                        },
                        {
                            "lat": 55.51435601511151,
                            "lon": 61.417085848225916
                        },
                        {
                            "lat": 55.51339274383448,
                            "lon": 61.41536982420313
                        },
                        {
                            "lat": 55.509811613824496,
                            "lon": 61.41583134328999
                        },
                        {
                            "lat": 55.50884864210196,
                            "lon": 61.41411527725506
                        },
                        {
                            "lat": 55.51242959400412,
                            "lon": 61.413653790910836
                        },
                        {
                            "lat": 55.51601042448068,
                            "lon": 61.41319221237935
                        },
                        {
                            "lat": 55.515047218007595,
                            "lon": 61.41147620256396
                        },
                        {
                            "lat": 55.5140841329574,
                            "lon": 61.40976018347884
                        },
                        {
                            "lat": 55.51050365859909,
                            "lon": 61.410221696524175
                        },
                        {
                            "lat": 55.50692306285734,
                            "lon": 61.410683117393354
                        },
                        {
                            "lat": 55.50334234575058,
                            "lon": 61.41114444608018
                        },
                        {
                            "lat": 55.4997615072972,
                            "lon": 61.411605682578404
                        },
                        {
                            "lat": 55.49879925590138,
                            "lon": 61.40988952330675
                        },
                        {
                            "lat": 55.49521847423563,
                            "lon": 61.41035063489449
                        },
                        {
                            "lat": 55.49618054751562,
                            "lon": 61.41206682688178
                        },
                        {
                            "lat": 55.49714274210541,
                            "lon": 61.41378300961803
                        },
                        {
                            "lat": 55.49356148285875,
                            "lon": 61.41424409443385
                        },
                        {
                            "lat": 55.49259946642427,
                            "lon": 61.412527878984086
                        },
                        {
                            "lat": 55.48901826404156,
                            "lon": 61.41298883887909
                        },
                        {
                            "lat": 55.4854369403859,
                            "lon": 61.413449706560584
                        },
                        {
                            "lat": 55.48185549547576,
                            "lon": 61.41391048202234
                        },
                        {
                            "lat": 55.47827392932957,
                            "lon": 61.41437116525809
                        },
                        {
                            "lat": 55.477312746944555,
                            "lon": 61.412654809776164
                        },
                        {
                            "lat": 55.47373123782292,
                            "lon": 61.413115368097515
                        },
                        {
                            "lat": 55.470149607523155,
                            "lon": 61.413575834186005
                        },
                        {
                            "lat": 55.46656785606371,
                            "lon": 61.41403620803544
                        },
                        {
                            "lat": 55.4656073296096,
                            "lon": 61.41231974530156
                        },
                        {
                            "lat": 55.46918890280321,
                            "lon": 61.411859404119745
                        },
                        {
                            "lat": 55.472770354858206,
                            "lon": 61.41139897070444
                        },
                        {
                            "lat": 55.476351685756114,
                            "lon": 61.410938445061866
                        },
                        {
                            "lat": 55.47993289547852,
                            "lon": 61.410477827198214
                        },
                        {
                            "lat": 55.48351398400698,
                            "lon": 61.41001711711975
                        },
                        {
                            "lat": 55.4825526876466,
                            "lon": 61.40830080854208
                        },
                        {
                            "lat": 55.478971777281345,
                            "lon": 61.40876148593402
                        },
                        {
                            "lat": 55.47539074574318,
                            "lon": 61.409222071116716
                        },
                        {
                            "lat": 55.47442992688471,
                            "lon": 61.40750568794234
                        },
                        {
                            "lat": 55.47084895237892,
                            "lon": 61.407966148237634
                        },
                        {
                            "lat": 55.47180959305056,
                            "lon": 61.40968256408395
                        },
                        {
                            "lat": 55.46822831922189,
                            "lon": 61.41014296482948
                        },
                        {
                            "lat": 55.46464692427566,
                            "lon": 61.410603273347085
                        },
                        {
                            "lat": 55.46106540823029,
                            "lon": 61.41106348963058
                        },
                        {
                            "lat": 55.45748377110424,
                            "lon": 61.41152361367368
                        },
                        {
                            "lat": 55.453902012915975,
                            "lon": 61.411983645470215
                        },
                        {
                            "lat": 55.45486188338158,
                            "lon": 61.41370021538376
                        },
                        {
                            "lat": 55.458443819880316,
                            "lon": 61.41324015093976
                        },
                        {
                            "lat": 55.45940398973966,
                            "lon": 61.41495667899213
                        },
                        {
                            "lat": 55.45582187491198,
                            "lon": 61.41541677608697
                        },
                        {
                            "lat": 55.45223963899847,
                            "lon": 61.41587678091785
                        },
                        {
                            "lat": 55.44865728201759,
                            "lon": 61.416336693478534
                        },
                        {
                            "lat": 55.44507480398788,
                            "lon": 61.41679651376281
                        },
                        {
                            "lat": 55.44411534757052,
                            "lon": 61.41507985514054
                        },
                        {
                            "lat": 55.443156012162525,
                            "lon": 61.41336318731818
                        },
                        {
                            "lat": 55.44219679774289,
                            "lon": 61.41164651029731
                        },
                        {
                            "lat": 55.43861473386655,
                            "lon": 61.41210614042365
                        },
                        {
                            "lat": 55.4395737699101,
                            "lon": 61.41382285006621
                        },
                        {
                            "lat": 55.4359914066878,
                            "lon": 61.4142824205366
                        },
                        {
                            "lat": 55.435032549041395,
                            "lon": 61.41256567827794
                        },
                        {
                            "lat": 55.434073812325316,
                            "lon": 61.41084892682916
                        },
                        {
                            "lat": 55.430491684969475,
                            "lon": 61.411308339798076
                        },
                        {
                            "lat": 55.43145024328589,
                            "lon": 61.41302512385393
                        },
                        {
                            "lat": 55.42786781661854,
                            "lon": 61.413484477145424
                        },
                        {
                            "lat": 55.42882631740763,
                            "lon": 61.41520128461949
                        },
                        {
                            "lat": 55.42524359138674,
                            "lon": 61.4156605782196
                        },
                        {
                            "lat": 55.421660744469996,
                            "lon": 61.41611977951717
                        },
                        {
                            "lat": 55.42261900919511,
                            "lon": 61.41783664300628
                        },
                        {
                            "lat": 55.419035862880285,
                            "lon": 61.4182957845867
                        },
                        {
                            "lat": 55.41545259568562,
                            "lon": 61.41875483384656
                        },
                        {
                            "lat": 55.41449468802292,
                            "lon": 61.417037905179946
                        },
                        {
                            "lat": 55.41091147852963,
                            "lon": 61.41749682953271
                        },
                        {
                            "lat": 55.40995387025148,
                            "lon": 61.415779859117954
                        },
                        {
                            "lat": 55.41353690120056,
                            "lon": 61.41532096734213
                        },
                        {
                            "lat": 55.412579235197526,
                            "lon": 61.413604020334674
                        },
                        {
                            "lat": 55.411621689992835,
                            "lon": 61.411887064159096
                        },
                        {
                            "lat": 55.41066426556545,
                            "lon": 61.410170098817005
                        },
                        {
                            "lat": 55.40708177013784,
                            "lon": 61.41062889288242
                        },
                        {
                            "lat": 55.40803901607658,
                            "lon": 61.41234589079124
                        },
                        {
                            "lat": 55.408996382774106,
                            "lon": 61.41406287953692
                        },
                        {
                            "lat": 55.40541340957089,
                            "lon": 61.41452164642304
                        },
                        {
                            "lat": 55.40183031560637,
                            "lon": 61.414980320986786
                        },
                        {
                            "lat": 55.400873305986124,
                            "lon": 61.413263267117635
                        },
                        {
                            "lat": 55.39991641708766,
                            "lon": 61.411546204092126
                        },
                        {
                            "lat": 55.39895964889,
                            "lon": 61.40982913191191
                        },
                        {
                            "lat": 55.39800300137214,
                            "lon": 61.40811205057849
                        },
                        {
                            "lat": 55.39442050083423,
                            "lon": 61.408570502620734
                        },
                        {
                            "lat": 55.393464152471246,
                            "lon": 61.40685337959594
                        },
                        {
                            "lat": 55.38988170981,
                            "lon": 61.40731170679168
                        },
                        {
                            "lat": 55.38629914654793,
                            "lon": 61.407769941674424
                        },
                        {
                            "lat": 55.38534327582219,
                            "lon": 61.40605274444714
                        },
                        {
                            "lat": 55.38176077051821,
                            "lon": 61.40651085449117
                        },
                        {
                            "lat": 55.38271646270355,
                            "lon": 61.408228084238
                        },
                        {
                            "lat": 55.38367227545266,
                            "lon": 61.409945304848335
                        },
                        {
                            "lat": 55.38462820878648,
                            "lon": 61.41166251632065
                        },
                        {
                            "lat": 55.38104504720004,
                            "lon": 61.412120631596885
                        },
                        {
                            "lat": 55.380089292464646,
                            "lon": 61.41040338760386
                        },
                        {
                            "lat": 55.379133658295395,
                            "lon": 61.408686134476156
                        },
                        {
                            "lat": 55.37555073334204,
                            "lon": 61.40914409238278
                        },
                        {
                            "lat": 55.37650618891043,
                            "lon": 61.41086137802218
                        },
                        {
                            "lat": 55.37746176502645,
                            "lon": 61.41257865453033
                        },
                        {
                            "lat": 55.378417461711074,
                            "lon": 61.41429592190574
                        },
                        {
                            "lat": 55.37483388030999,
                            "lon": 61.414753885003115
                        },
                        {
                            "lat": 55.373878362284266,
                            "lon": 61.4130365851148
                        },
                        {
                            "lat": 55.37292296480859,
                            "lon": 61.41131927609712
                        },
                        {
                            "lat": 55.37196768786199,
                            "lon": 61.40960195795163
                        },
                        {
                            "lat": 55.37101253142351,
                            "lon": 61.40788463067984
                        },
                        {
                            "lat": 55.36742954405953,
                            "lon": 61.408342371407585
                        },
                        {
                            "lat": 55.3683845218738,
                            "lon": 61.41005973117647
                        },
                        {
                            "lat": 55.369339620177655,
                            "lon": 61.41177708182251
                        },
                        {
                            "lat": 55.365756155036195,
                            "lon": 61.412234795192106
                        },
                        {
                            "lat": 55.36480123539603,
                            "lon": 61.410517412051185
                        },
                        {
                            "lat": 55.36121782844724,
                            "lon": 61.41097500056952
                        },
                        {
                            "lat": 55.357634301045984,
                            "lon": 61.411432496725325
                        },
                        {
                            "lat": 55.35858886329591,
                            "lon": 61.41314994483929
                        },
                        {
                            "lat": 55.35954354600067,
                            "lon": 61.41486738383904
                        },
                        {
                            "lat": 55.35595954069387,
                            "lon": 61.41532485258569
                        },
                        {
                            "lat": 55.35500503673423,
                            "lon": 61.413607381104455
                        },
                        {
                            "lat": 55.35405065321084,
                            "lon": 61.41188990051239
                        },
                        {
                            "lat": 55.35309639010277,
                            "lon": 61.41017241081112
                        },
                        {
                            "lat": 55.349512800581195,
                            "lon": 61.41062968975416
                        },
                        {
                            "lat": 55.34592909068383,
                            "lon": 61.41108687632173
                        },
                        {
                            "lat": 55.342345260429205,
                            "lon": 61.4115439705076
                        },
                        {
                            "lat": 55.343298987287795,
                            "lon": 61.413261557599355
                        },
                        {
                            "lat": 55.339714857902834,
                            "lon": 61.41371859184959
                        },
                        {
                            "lat": 55.33876130983597,
                            "lon": 61.412000972305584
                        },
                        {
                            "lat": 55.33517723892266,
                            "lon": 61.41245788170952
                        },
                        {
                            "lat": 55.336130608176916,
                            "lon": 61.41417553370016
                        },
                        {
                            "lat": 55.33254623812861,
                            "lon": 61.41463238314489
                        },
                        {
                            "lat": 55.409243370071295,
                            "lon": 61.421389640035656
                        },
                        {
                            "lat": 55.408285698730765,
                            "lon": 61.41967265537986
                        },
                        {
                            "lat": 55.41186920762961,
                            "lon": 61.419213790779686
                        },
                        {
                            "lat": 55.412827057572386,
                            "lon": 61.42093074285731
                        },
                        {
                            "lat": 55.409243370071295,
                            "lon": 61.421389640035656
                        },
                        {
                            "lat": 55.475653367307736,
                            "lon": 61.41654813519523
                        },
                        {
                            "lat": 55.47923523293218,
                            "lon": 61.41608751150606
                        },
                        {
                            "lat": 55.48019665777347,
                            "lon": 61.4178038485185
                        },
                        {
                            "lat": 55.476614613869955,
                            "lon": 61.41826450489668
                        },
                        {
                            "lat": 55.475653367307736,
                            "lon": 61.41654813519523
                        },
                        {
                            "lat": 55.39537696983753,
                            "lon": 61.41028761649726
                        },
                        {
                            "lat": 55.391794170123774,
                            "lon": 61.41074600876466
                        },
                        {
                            "lat": 55.39083787965599,
                            "lon": 61.409028862350596
                        },
                        {
                            "lat": 55.39442050083423,
                            "lon": 61.408570502620734
                        },
                        {
                            "lat": 55.39537696983753,
                            "lon": 61.41028761649726
                        },
                        {
                            "lat": 55.39275058123432,
                            "lon": 61.41246314603234
                        },
                        {
                            "lat": 55.38916748230276,
                            "lon": 61.412921478510874
                        },
                        {
                            "lat": 55.38821124976725,
                            "lon": 61.41120430870786
                        },
                        {
                            "lat": 55.391794170123774,
                            "lon": 61.41074600876466
                        },
                        {
                            "lat": 55.39275058123432,
                            "lon": 61.41246314603234
                        },
                        {
                            "lat": 55.3820009225226,
                            "lon": 61.41383786645376
                        },
                        {
                            "lat": 55.382956918453274,
                            "lon": 61.41555509217294
                        },
                        {
                            "lat": 55.37937327898526,
                            "lon": 61.41601318014684
                        },
                        {
                            "lat": 55.378417461711074,
                            "lon": 61.41429592190574
                        },
                        {
                            "lat": 55.3820009225226,
                            "lon": 61.41383786645376
                        },
                        {
                            "lat": 55.39920405267344,
                            "lon": 61.417155980490016
                        },
                        {
                            "lat": 55.400161125193,
                            "lon": 61.41887304860205
                        },
                        {
                            "lat": 55.396577432523635,
                            "lon": 61.41933160360816
                        },
                        {
                            "lat": 55.39562053863228,
                            "lon": 61.417614502941575
                        },
                        {
                            "lat": 55.39920405267344,
                            "lon": 61.417155980490016
                        },
                        {
                            "lat": 55.37316121870795,
                            "lon": 61.41864639314704
                        },
                        {
                            "lat": 55.37220563823623,
                            "lon": 61.416929079007936
                        },
                        {
                            "lat": 55.375789518906736,
                            "lon": 61.41647117576058
                        },
                        {
                            "lat": 55.376745278095434,
                            "lon": 61.41818845738555
                        },
                        {
                            "lat": 55.37316121870795,
                            "lon": 61.41864639314704
                        },
                        {
                            "lat": 55.39108030933038,
                            "lon": 61.416355790681756
                        },
                        {
                            "lat": 55.392036903864415,
                            "lon": 61.41807293304654
                        },
                        {
                            "lat": 55.38845314838842,
                            "lon": 61.418531270798674
                        },
                        {
                            "lat": 55.387496732506,
                            "lon": 61.41681409589398
                        },
                        {
                            "lat": 55.39108030933038,
                            "lon": 61.416355790681756
                        }
                    ],
                    [
                        {
                            "lat": 55.35165433055109,
                            "lon": 61.42139206755728
                        },
                        {
                            "lat": 55.34806942739303,
                            "lon": 61.42184935654141
                        },
                        {
                            "lat": 55.34902387672317,
                            "lon": 61.42356688901564
                        },
                        {
                            "lat": 55.349978446538564,
                            "lon": 61.42528441238161
                        },
                        {
                            "lat": 55.353563707479836,
                            "lon": 61.424827058437515
                        },
                        {
                            "lat": 55.35260895876353,
                            "lon": 61.423109567553276
                        },
                        {
                            "lat": 55.35165433055109,
                            "lon": 61.42139206755728
                        }
                    ],
                    [
                        {
                            "lat": 55.3571488478963,
                            "lon": 61.42436961206689
                        },
                        {
                            "lat": 55.353563707479836,
                            "lon": 61.424827058437515
                        },
                        {
                            "lat": 55.354518576720984,
                            "lon": 61.42654454020852
                        },
                        {
                            "lat": 55.35093313686018,
                            "lon": 61.42700192663769
                        },
                        {
                            "lat": 55.351887947708974,
                            "lon": 61.428719431782326
                        },
                        {
                            "lat": 55.35547356650789,
                            "lon": 61.42826201286465
                        },
                        {
                            "lat": 55.35905906474006,
                            "lon": 61.4278045015092
                        },
                        {
                            "lat": 55.358103896035985,
                            "lon": 61.426087061347154
                        },
                        {
                            "lat": 55.3616890947866,
                            "lon": 61.42562949005982
                        },
                        {
                            "lat": 55.36073386776933,
                            "lon": 61.42391207327585
                        },
                        {
                            "lat": 55.3571488478963,
                            "lon": 61.42436961206689
                        }
                    ],
                    [
                        {
                            "lat": 55.367899034157595,
                            "lon": 61.47818030460272
                        },
                        {
                            "lat": 55.368856810874846,
                            "lon": 61.479897642569426
                        },
                        {
                            "lat": 55.372447990401625,
                            "lon": 61.47943955784134
                        },
                        {
                            "lat": 55.371490034142425,
                            "lon": 61.47772225244525
                        },
                        {
                            "lat": 55.37053219898322,
                            "lon": 61.47600493789946
                        },
                        {
                            "lat": 55.36694137852163,
                            "lon": 61.47646295748974
                        },
                        {
                            "lat": 55.367899034157595,
                            "lon": 61.47818030460272
                        }
                    ],
                    [
                        {
                            "lat": 55.38681149711106,
                            "lon": 61.47760629279729
                        },
                        {
                            "lat": 55.387770292522745,
                            "lon": 61.47932345868984
                        },
                        {
                            "lat": 55.391361045712365,
                            "lon": 61.47886487832486
                        },
                        {
                            "lat": 55.39040207084562,
                            "lon": 61.47714774503445
                        },
                        {
                            "lat": 55.38681149711106,
                            "lon": 61.47760629279729
                        }
                    ],
                    [
                        {
                            "lat": 55.37340606778192,
                            "lon": 61.48115685408625
                        },
                        {
                            "lat": 55.3698147086945,
                            "lon": 61.48161497138836
                        },
                        {
                            "lat": 55.37077272763768,
                            "lon": 61.48333229105793
                        },
                        {
                            "lat": 55.37173086772549,
                            "lon": 61.485049601576605
                        },
                        {
                            "lat": 55.37268912897904,
                            "lon": 61.4867669029428
                        },
                        {
                            "lat": 55.37364751141948,
                            "lon": 61.48848419515498
                        },
                        {
                            "lat": 55.37460601506791,
                            "lon": 61.49020147821158
                        },
                        {
                            "lat": 55.37556463994548,
                            "lon": 61.49191875211101
                        },
                        {
                            "lat": 55.37652338607333,
                            "lon": 61.49363601685174
                        },
                        {
                            "lat": 55.377482253472586,
                            "lon": 61.49535327243219
                        },
                        {
                            "lat": 55.378441242164406,
                            "lon": 61.497070518850826
                        },
                        {
                            "lat": 55.38203421813876,
                            "lon": 61.49661210822849
                        },
                        {
                            "lat": 55.381075049718014,
                            "lon": 61.49489489441469
                        },
                        {
                            "lat": 55.380116002608574,
                            "lon": 61.493177671435646
                        },
                        {
                            "lat": 55.38370849785368,
                            "lon": 61.49271923335515
                        },
                        {
                            "lat": 55.384667724652196,
                            "lon": 61.494436423727144
                        },
                        {
                            "lat": 55.38562707278067,
                            "lon": 61.496153604930456
                        },
                        {
                            "lat": 55.38921980607147,
                            "lon": 61.49569500896301
                        },
                        {
                            "lat": 55.39017945525636,
                            "lon": 61.497412148376576
                        },
                        {
                            "lat": 55.393772246861445,
                            "lon": 61.49695342712075
                        },
                        {
                            "lat": 55.39281241799242,
                            "lon": 61.495236320332346
                        },
                        {
                            "lat": 55.39185271051191,
                            "lon": 61.49351920436682
                        },
                        {
                            "lat": 55.388260278256396,
                            "lon": 61.493977860375786
                        },
                        {
                            "lat": 55.38730087178999,
                            "lon": 61.492260702616434
                        },
                        {
                            "lat": 55.39089312439878,
                            "lon": 61.49180207922578
                        },
                        {
                            "lat": 55.39448525566136,
                            "lon": 61.49134336318938
                        },
                        {
                            "lat": 55.39544502140008,
                            "lon": 61.493060455706505
                        },
                        {
                            "lat": 55.39903721090219,
                            "lon": 61.49260161440107
                        },
                        {
                            "lat": 55.402629278999584,
                            "lon": 61.49214268045672
                        },
                        {
                            "lat": 55.406221225673576,
                            "lon": 61.49168365387976
                        },
                        {
                            "lat": 55.40981305090548,
                            "lon": 61.49122453467638
                        },
                        {
                            "lat": 55.413404754676634,
                            "lon": 61.49076532285279
                        },
                        {
                            "lat": 55.414365539705265,
                            "lon": 61.492482242969835
                        },
                        {
                            "lat": 55.410773656395676,
                            "lon": 61.49294148744889
                        },
                        {
                            "lat": 55.41173438336779,
                            "lon": 61.494658431027155
                        },
                        {
                            "lat": 55.415326446234474,
                            "lon": 61.49419915388922
                        },
                        {
                            "lat": 55.41891838757939,
                            "lon": 61.493739784126085
                        },
                        {
                            "lat": 55.422510207383894,
                            "lon": 61.493280321744
                        },
                        {
                            "lat": 55.42610190562932,
                            "lon": 61.492820766749226
                        },
                        {
                            "lat": 55.429693482297004,
                            "lon": 61.49236111914798
                        },
                        {
                            "lat": 55.42873185775123,
                            "lon": 61.490644338920426
                        },
                        {
                            "lat": 55.43232313337136,
                            "lon": 61.490184631405974
                        },
                        {
                            "lat": 55.433284937368306,
                            "lon": 61.491901378946544
                        },
                        {
                            "lat": 55.434246862980274,
                            "lon": 61.4936181172707
                        },
                        {
                            "lat": 55.437838375885164,
                            "lon": 61.49315825177921
                        },
                        {
                            "lat": 55.436876270824584,
                            "lon": 61.491441546151115
                        },
                        {
                            "lat": 55.43591428739766,
                            "lon": 61.489724831303164
                        },
                        {
                            "lat": 55.43950531981149,
                            "lon": 61.48926493861825
                        },
                        {
                            "lat": 55.43854327860687,
                            "lon": 61.48754824724685
                        },
                        {
                            "lat": 55.44213401002062,
                            "lon": 61.48708829468649
                        },
                        {
                            "lat": 55.443096230594215,
                            "lon": 61.48880495335747
                        },
                        {
                            "lat": 55.44668701972721,
                            "lon": 61.48834487552706
                        },
                        {
                            "lat": 55.44572461980586,
                            "lon": 61.486628249562116
                        },
                        {
                            "lat": 55.44931510794393,
                            "lon": 61.48616811187997
                        },
                        {
                            "lat": 55.44835265036176,
                            "lon": 61.48445150939626
                        },
                        {
                            "lat": 55.4519428375473,
                            "lon": 61.48399131187644
                        },
                        {
                            "lat": 55.455532903069646,
                            "lon": 61.48353102181695
                        },
                        {
                            "lat": 55.45649571920416,
                            "lon": 61.4852475588674
                        },
                        {
                            "lat": 55.460085842289104,
                            "lon": 61.484787143549454
                        },
                        {
                            "lat": 55.46367584365249,
                            "lon": 61.48432663569875
                        },
                        {
                            "lat": 55.467265723275666,
                            "lon": 61.48386603532153
                        },
                        {
                            "lat": 55.4663023694716,
                            "lon": 61.482149596463024
                        },
                        {
                            "lat": 55.46989194815525,
                            "lon": 61.4816889363074
                        },
                        {
                            "lat": 55.4708554811401,
                            "lon": 61.48340534242405
                        },
                        {
                            "lat": 55.47444511722716,
                            "lon": 61.48294455701253
                        },
                        {
                            "lat": 55.478034631518305,
                            "lon": 61.48248367909325
                        },
                        {
                            "lat": 55.4789986446156,
                            "lon": 61.48420001045148
                        },
                        {
                            "lat": 55.48258821622796,
                            "lon": 61.48373900726857
                        },
                        {
                            "lat": 55.48162402399493,
                            "lon": 61.482022708672474
                        },
                        {
                            "lat": 55.485213294638484,
                            "lon": 61.481561645756436
                        },
                        {
                            "lat": 55.48880244343039,
                            "lon": 61.4811004903514
                        },
                        {
                            "lat": 55.49239147035208,
                            "lon": 61.48063924246362
                        },
                        {
                            "lat": 55.49598037538502,
                            "lon": 61.48017790209934
                        },
                        {
                            "lat": 55.49956915851062,
                            "lon": 61.47971646926483
                        },
                        {
                            "lat": 55.50315781971037,
                            "lon": 61.479254943966374
                        },
                        {
                            "lat": 55.50674635896572,
                            "lon": 61.47879332621021
                        },
                        {
                            "lat": 55.51033477625812,
                            "lon": 61.47833161600258
                        },
                        {
                            "lat": 55.50936927368476,
                            "lon": 61.47661557041618
                        },
                        {
                            "lat": 55.50840389307162,
                            "lon": 61.47489951554252
                        },
                        {
                            "lat": 55.51199183054876,
                            "lon": 61.4744377785044
                        },
                        {
                            "lat": 55.51557964606832,
                            "lon": 61.47397594903853
                        },
                        {
                            "lat": 55.51916733961183,
                            "lon": 61.47351402715117
                        },
                        {
                            "lat": 55.51820154433405,
                            "lon": 61.47179806141521
                        },
                        {
                            "lat": 55.52178893705752,
                            "lon": 61.471336079931625
                        },
                        {
                            "lat": 55.525376207789144,
                            "lon": 61.470874006044646
                        },
                        {
                            "lat": 55.528963356510424,
                            "lon": 61.47041183976057
                        },
                        {
                            "lat": 55.532550383202846,
                            "lon": 61.46994958108567
                        },
                        {
                            "lat": 55.53613728784789,
                            "lon": 61.4694872300262
                        },
                        {
                            "lat": 55.53517072072103,
                            "lon": 61.46777141912932
                        },
                        {
                            "lat": 55.538757324599466,
                            "lon": 61.467309008535004
                        },
                        {
                            "lat": 55.53779070083487,
                            "lon": 61.46559322116811
                        },
                        {
                            "lat": 55.54137700398913,
                            "lon": 61.46513075105294
                        },
                        {
                            "lat": 55.54234380641481,
                            "lon": 61.4668465055742
                        },
                        {
                            "lat": 55.54593016614861,
                            "lon": 61.46638391025326
                        },
                        {
                            "lat": 55.54496318508306,
                            "lon": 61.46466818858317
                        },
                        {
                            "lat": 55.548549244098204,
                            "lon": 61.46420553376507
                        },
                        {
                            "lat": 55.552135181016084,
                            "lon": 61.46374278660487
                        },
                        {
                            "lat": 55.555720995818234,
                            "lon": 61.46327994710884
                        },
                        {
                            "lat": 55.55475360109474,
                            "lon": 61.461564314696744
                        },
                        {
                            "lat": 55.55116796485027,
                            "lon": 61.462027121328255
                        },
                        {
                            "lat": 55.55020087077894,
                            "lon": 61.46031144672761
                        },
                        {
                            "lat": 55.54661529100044,
                            "lon": 61.460774128173426
                        },
                        {
                            "lat": 55.54302958916716,
                            "lon": 61.46123671728833
                        },
                        {
                            "lat": 55.54399632609633,
                            "lon": 61.46295245759434
                        },
                        {
                            "lat": 55.54041032362378,
                            "lon": 61.46341498721635
                        },
                        {
                            "lat": 55.53944376529752,
                            "lon": 61.46169921406603
                        },
                        {
                            "lat": 55.53585781941001,
                            "lon": 61.462161618500325
                        },
                        {
                            "lat": 55.5322717515231,
                            "lon": 61.46262393058489
                        },
                        {
                            "lat": 55.52868556165525,
                            "lon": 61.463086150313494
                        },
                        {
                            "lat": 55.529651584045425,
                            "lon": 61.46480202196323
                        },
                        {
                            "lat": 55.526065093527315,
                            "lon": 61.46526418215158
                        },
                        {
                            "lat": 55.52509924982493,
                            "lon": 61.463548277679855
                        },
                        {
                            "lat": 55.52151281605066,
                            "lon": 61.46401031267776
                        },
                        {
                            "lat": 55.522478481044026,
                            "lon": 61.46572624996585
                        },
                        {
                            "lat": 55.51889174661404,
                            "lon": 61.4661882253998
                        },
                        {
                            "lat": 55.5179262603509,
                            "lon": 61.464472255300905
                        },
                        {
                            "lat": 55.51433958274416,
                            "lon": 61.464934105543016
                        },
                        {
                            "lat": 55.51337439712092,
                            "lon": 61.463218093350825
                        },
                        {
                            "lat": 55.50978777637981,
                            "lon": 61.46367981840952
                        },
                        {
                            "lat": 55.510752783248904,
                            "lon": 61.465395863397895
                        },
                        {
                            "lat": 55.50716586188366,
                            "lon": 61.465857528859246
                        },
                        {
                            "lat": 55.506201033789914,
                            "lon": 61.46414145108028
                        },
                        {
                            "lat": 55.502614169369735,
                            "lon": 61.464602991356834
                        },
                        {
                            "lat": 55.499027183137784,
                            "lon": 61.46506443923294
                        },
                        {
                            "lat": 55.49999165361728,
                            "lon": 61.466780582576334
                        },
                        {
                            "lat": 55.496404366753126,
                            "lon": 61.46724197081956
                        },
                        {
                            "lat": 55.49544007511256,
                            "lon": 61.46552579470238
                        },
                        {
                            "lat": 55.49185284531261,
                            "lon": 61.46598705775885
                        },
                        {
                            "lat": 55.48826549375647,
                            "lon": 61.46644822839613
                        },
                        {
                            "lat": 55.4892294276556,
                            "lon": 61.46816447004419
                        },
                        {
                            "lat": 55.485641775459264,
                            "lon": 61.46862558101304
                        },
                        {
                            "lat": 55.482054001522584,
                            "lon": 61.46908659954459
                        },
                        {
                            "lat": 55.481090425449615,
                            "lon": 61.467370292388075
                        },
                        {
                            "lat": 55.477502708736,
                            "lon": 61.46783118573025
                        },
                        {
                            "lat": 55.47846610586413,
                            "lon": 61.469547525632606
                        },
                        {
                            "lat": 55.47487808850241,
                            "lon": 61.47000835927083
                        },
                        {
                            "lat": 55.471289949456,
                            "lon": 61.470469100453
                        },
                        {
                            "lat": 55.46770168874345,
                            "lon": 61.470929749172875
                        },
                        {
                            "lat": 55.466738828577,
                            "lon": 61.4692133110666
                        },
                        {
                            "lat": 55.463150625246435,
                            "lon": 61.4696738345945
                        },
                        {
                            "lat": 55.459562300308036,
                            "lon": 61.47013426565322
                        },
                        {
                            "lat": 55.46052480239418,
                            "lon": 61.47185076920077
                        },
                        {
                            "lat": 55.464113306383325,
                            "lon": 61.47139030542421
                        },
                        {
                            "lat": 55.465076109149216,
                            "lon": 61.473106767013924
                        },
                        {
                            "lat": 55.46603903356531,
                            "lon": 61.47482321936206
                        },
                        {
                            "lat": 55.46245017141897,
                            "lon": 61.47528374858458
                        },
                        {
                            "lat": 55.46341303839997,
                            "lon": 61.47700022441769
                        },
                        {
                            "lat": 55.45982387547319,
                            "lon": 61.47746069387048
                        },
                        {
                            "lat": 55.45886118761983,
                            "lon": 61.47574418531486
                        },
                        {
                            "lat": 55.455272082186475,
                            "lon": 61.476204529546635
                        },
                        {
                            "lat": 55.45430969509758,
                            "lon": 61.47448797904123
                        },
                        {
                            "lat": 55.450720647199994,
                            "lon": 61.474948198060396
                        },
                        {
                            "lat": 55.44713147772656,
                            "lon": 61.47540832457421
                        },
                        {
                            "lat": 55.44809350649144,
                            "lon": 61.47712494048977
                        },
                        {
                            "lat": 55.444504036266956,
                            "lon": 61.47758500718864
                        },
                        {
                            "lat": 55.443542186695815,
                            "lon": 61.47586835857641
                        },
                        {
                            "lat": 55.439952774126404,
                            "lon": 61.47632830006077
                        },
                        {
                            "lat": 55.43899122529027,
                            "lon": 61.47461160953994
                        },
                        {
                            "lat": 55.435401870418275,
                            "lon": 61.47507142581818
                        },
                        {
                            "lat": 55.431812394065936,
                            "lon": 61.475531149571694
                        },
                        {
                            "lat": 55.42822279625187,
                            "lon": 61.475990780794255
                        },
                        {
                            "lat": 55.42463307699475,
                            "lon": 61.47645031947962
                        },
                        {
                            "lat": 55.42104323631313,
                            "lon": 61.47690976562157
                        },
                        {
                            "lat": 55.41745327422565,
                            "lon": 61.477369119213826
                        },
                        {
                            "lat": 55.41649292239904,
                            "lon": 61.47565222348967
                        },
                        {
                            "lat": 55.41290301825009,
                            "lon": 61.47611145188112
                        },
                        {
                            "lat": 55.41386319075095,
                            "lon": 61.47782838025017
                        },
                        {
                            "lat": 55.41027298590764,
                            "lon": 61.478287548724424
                        },
                        {
                            "lat": 55.4066826597144,
                            "lon": 61.478746624630276
                        },
                        {
                            "lat": 55.40309221218982,
                            "lon": 61.47920560796153
                        },
                        {
                            "lat": 55.404051967899164,
                            "lon": 61.48092262504989
                        },
                        {
                            "lat": 55.400461219632945,
                            "lon": 61.48138154842618
                        },
                        {
                            "lat": 55.39950164335258,
                            "lon": 61.479664498711955
                        },
                        {
                            "lat": 55.395910953221296,
                            "lon": 61.48012329687531
                        },
                        {
                            "lat": 55.39687035005157,
                            "lon": 61.48184037920978
                        },
                        {
                            "lat": 55.39327935917369,
                            "lon": 61.48229911739446
                        },
                        {
                            "lat": 55.39232014181467,
                            "lon": 61.48058200244538
                        },
                        {
                            "lat": 55.38872920915134,
                            "lon": 61.48104061541594
                        },
                        {
                            "lat": 55.387770292522745,
                            "lon": 61.47932345868984
                        },
                        {
                            "lat": 55.38417941811623,
                            "lon": 61.47978194645471
                        },
                        {
                            "lat": 55.38513815524998,
                            "lon": 61.48149913578072
                        },
                        {
                            "lat": 55.386097013603106,
                            "lon": 61.483216315942194
                        },
                        {
                            "lat": 55.38250565894773,
                            "lon": 61.48367477629275
                        },
                        {
                            "lat": 55.38154698012926,
                            "lon": 61.48195756353355
                        },
                        {
                            "lat": 55.37795568380784,
                            "lon": 61.48241589866818
                        },
                        {
                            "lat": 55.37436426630443,
                            "lon": 61.48287414117838
                        },
                        {
                            "lat": 55.37340606778192,
                            "lon": 61.48115685408625
                        },
                        {
                            "lat": 55.42514046055591,
                            "lon": 61.49110395384029
                        },
                        {
                            "lat": 55.42417913703905,
                            "lon": 61.48938713172338
                        },
                        {
                            "lat": 55.42777035478066,
                            "lon": 61.48892754948147
                        },
                        {
                            "lat": 55.42873185775123,
                            "lon": 61.490644338920426
                        },
                        {
                            "lat": 55.42514046055591,
                            "lon": 61.49110395384029
                        },
                        {
                            "lat": 55.49281695809306,
                            "lon": 61.467703266644264
                        },
                        {
                            "lat": 55.49378119265091,
                            "lon": 61.46941946626223
                        },
                        {
                            "lat": 55.49019348331361,
                            "lon": 61.46988070242821
                        },
                        {
                            "lat": 55.4892294276556,
                            "lon": 61.46816447004419
                        },
                        {
                            "lat": 55.49281695809306,
                            "lon": 61.467703266644264
                        },
                        {
                            "lat": 55.491157660751675,
                            "lon": 61.471596925546606
                        },
                        {
                            "lat": 55.487569650694745,
                            "lon": 61.47205810203985
                        },
                        {
                            "lat": 55.48660565219624,
                            "lon": 61.470341846157524
                        },
                        {
                            "lat": 55.49019348331361,
                            "lon": 61.46988070242821
                        },
                        {
                            "lat": 55.491157660751675,
                            "lon": 61.471596925546606
                        }
                    ],
                    [
                        {
                            "lat": 55.428407109816185,
                            "lon": 61.558763146331046
                        },
                        {
                            "lat": 55.42937144498109,
                            "lon": 61.560479912463876
                        },
                        {
                            "lat": 55.432970775864526,
                            "lon": 61.560019888791714
                        },
                        {
                            "lat": 55.432006260343606,
                            "lon": 61.55830315541689
                        },
                        {
                            "lat": 55.428407109816185,
                            "lon": 61.558763146331046
                        }
                    ],
                    [
                        {
                            "lat": 55.4045148464937,
                            "lon": 61.46798564490053
                        },
                        {
                            "lat": 55.40547423321484,
                            "lon": 61.46970265182826
                        },
                        {
                            "lat": 55.40906354150989,
                            "lon": 61.469243646516226
                        },
                        {
                            "lat": 55.408103975534985,
                            "lon": 61.467526672210674
                        },
                        {
                            "lat": 55.4045148464937,
                            "lon": 61.46798564490053
                        }
                    ],
                    [
                        {
                            "lat": 55.42692852297599,
                            "lon": 61.54239235043646
                        },
                        {
                            "lat": 55.42789211968774,
                            "lon": 61.54410913417
                        },
                        {
                            "lat": 55.4314895839778,
                            "lon": 61.54364921957388
                        },
                        {
                            "lat": 55.43052580712152,
                            "lon": 61.54193246857862
                        },
                        {
                            "lat": 55.42692852297599,
                            "lon": 61.54239235043646
                        }
                    ],
                    [
                        {
                            "lat": 55.37244759320409,
                            "lon": 61.39666510694352
                        },
                        {
                            "lat": 55.37340238399658,
                            "lon": 61.39838242397009
                        },
                        {
                            "lat": 55.3769841173147,
                            "lon": 61.39792456876438
                        },
                        {
                            "lat": 55.37602914807206,
                            "lon": 61.39620728423132
                        },
                        {
                            "lat": 55.37244759320409,
                            "lon": 61.39666510694352
                        }
                    ],
                    [
                        {
                            "lat": 55.41098303726962,
                            "lon": 61.47267756757327
                        },
                        {
                            "lat": 55.414572582822885,
                            "lon": 61.47221840446134
                        },
                        {
                            "lat": 55.41361259503108,
                            "lon": 61.47050148116033
                        },
                        {
                            "lat": 55.41265272854199,
                            "lon": 61.46878454867014
                        },
                        {
                            "lat": 55.40906354150989,
                            "lon": 61.469243646516226
                        },
                        {
                            "lat": 55.41002322874772,
                            "lon": 61.47096061163761
                        },
                        {
                            "lat": 55.41098303726962,
                            "lon": 61.47267756757327
                        }
                    ],
                    [
                        {
                            "lat": 55.41553269193848,
                            "lon": 61.47393531857164
                        },
                        {
                            "lat": 55.41649292239904,
                            "lon": 61.47565222348967
                        },
                        {
                            "lat": 55.42008270518187,
                            "lon": 61.47519290254791
                        },
                        {
                            "lat": 55.419122295435315,
                            "lon": 61.473476030276984
                        },
                        {
                            "lat": 55.41816200705233,
                            "lon": 61.471759148810385
                        },
                        {
                            "lat": 55.414572582822885,
                            "lon": 61.47221840446134
                        },
                        {
                            "lat": 55.41553269193848,
                            "lon": 61.47393531857164
                        }
                    ],
                    [
                        {
                            "lat": 55.39704647451313,
                            "lon": 61.40639496009342
                        },
                        {
                            "lat": 55.39800300137214,
                            "lon": 61.40811205057849
                        },
                        {
                            "lat": 55.401585381251216,
                            "lon": 61.40765350623004
                        },
                        {
                            "lat": 55.400628675917154,
                            "lon": 61.405936448290284
                        },
                        {
                            "lat": 55.39704647451313,
                            "lon": 61.40639496009342
                        }
                    ],
                    [
                        {
                            "lat": 55.40349915397242,
                            "lon": 61.41108759464286
                        },
                        {
                            "lat": 55.40708177013784,
                            "lon": 61.41062889288242
                        },
                        {
                            "lat": 55.406124644936966,
                            "lon": 61.408911885812024
                        },
                        {
                            "lat": 55.40516764045293,
                            "lon": 61.407194869581645
                        },
                        {
                            "lat": 55.401585381251216,
                            "lon": 61.40765350623004
                        },
                        {
                            "lat": 55.40254220726265,
                            "lon": 61.40937055501477
                        },
                        {
                            "lat": 55.40349915397242,
                            "lon": 61.41108759464286
                        }
                    ],
                    [
                        {
                            "lat": 55.417828894133095,
                            "lon": 61.40925223379604
                        },
                        {
                            "lat": 55.42141102723613,
                            "lon": 61.40879316285293
                        },
                        {
                            "lat": 55.420453188280625,
                            "lon": 61.40707628606937
                        },
                        {
                            "lat": 55.42403502218562,
                            "lon": 61.406617155431775
                        },
                        {
                            "lat": 55.42307712565327,
                            "lon": 61.40490030205685
                        },
                        {
                            "lat": 55.42211934990928,
                            "lon": 61.403183439506385
                        },
                        {
                            "lat": 55.41853787272119,
                            "lon": 61.40364250498285
                        },
                        {
                            "lat": 55.419495470115976,
                            "lon": 61.405359400112154
                        },
                        {
                            "lat": 55.415913693808776,
                            "lon": 61.40581840589849
                        },
                        {
                            "lat": 55.41687123358476,
                            "lon": 61.40753532443239
                        },
                        {
                            "lat": 55.417828894133095,
                            "lon": 61.40925223379604
                        }
                    ],
                    [
                        {
                            "lat": 55.4142466402367,
                            "lon": 61.409711212452784
                        },
                        {
                            "lat": 55.41520424313174,
                            "lon": 61.41142814522258
                        },
                        {
                            "lat": 55.41878667547479,
                            "lon": 61.41096913398791
                        },
                        {
                            "lat": 55.417828894133095,
                            "lon": 61.40925223379604
                        },
                        {
                            "lat": 55.4142466402367,
                            "lon": 61.409711212452784
                        }
                    ],
                    [
                        {
                            "lat": 55.45842171660336,
                            "lon": 61.488680605254885
                        },
                        {
                            "lat": 55.45483111322845,
                            "lon": 61.48914099348297
                        },
                        {
                            "lat": 55.45579411521413,
                            "lon": 61.4908575355466
                        },
                        {
                            "lat": 55.45938489791043,
                            "lon": 61.49039711458878
                        },
                        {
                            "lat": 55.45842171660336,
                            "lon": 61.488680605254885
                        }
                    ],
                    [
                        {
                            "lat": 55.46446661429126,
                            "lon": 61.50630599819435
                        },
                        {
                            "lat": 55.460874094925494,
                            "lon": 61.50676662126183
                        },
                        {
                            "lat": 55.461838135817366,
                            "lon": 61.50848310362731
                        },
                        {
                            "lat": 55.46543083471201,
                            "lon": 61.5080224478013
                        },
                        {
                            "lat": 55.466395177093084,
                            "lon": 61.509738888153976
                        },
                        {
                            "lat": 55.46998793355363,
                            "lon": 61.50927810694219
                        },
                        {
                            "lat": 55.46902341164629,
                            "lon": 61.50756169935712
                        },
                        {
                            "lat": 55.46805901171793,
                            "lon": 61.505845282514244
                        },
                        {
                            "lat": 55.46446661429126,
                            "lon": 61.50630599819435
                        }
                    ],
                    [
                        {
                            "lat": 55.456415261122764,
                            "lon": 61.45765828019871
                        },
                        {
                            "lat": 55.45737709144911,
                            "lon": 61.45937481566438
                        },
                        {
                            "lat": 55.46096422099199,
                            "lon": 61.45891448840505
                        },
                        {
                            "lat": 55.46000221176598,
                            "lon": 61.457197985638814
                        },
                        {
                            "lat": 55.456415261122764,
                            "lon": 61.45765828019871
                        }
                    ],
                    [
                        {
                            "lat": 55.45142326331297,
                            "lon": 61.469338272143624
                        },
                        {
                            "lat": 55.45238528568199,
                            "lon": 61.47105485033815
                        },
                        {
                            "lat": 55.45597385378036,
                            "lon": 61.47059460423651
                        },
                        {
                            "lat": 55.45501165233682,
                            "lon": 61.468878058745176
                        },
                        {
                            "lat": 55.45142326331297,
                            "lon": 61.469338272143624
                        }
                    ],
                    [
                        {
                            "lat": 55.45693617679459,
                            "lon": 61.472311140496295
                        },
                        {
                            "lat": 55.45789862140069,
                            "lon": 61.47402766752291
                        },
                        {
                            "lat": 55.46148742609076,
                            "lon": 61.473567263511754
                        },
                        {
                            "lat": 55.46052480239418,
                            "lon": 61.47185076920077
                        },
                        {
                            "lat": 55.45693617679459,
                            "lon": 61.472311140496295
                        }
                    ],
                    [
                        {
                            "lat": 55.467265723275666,
                            "lon": 61.48386603532153
                        },
                        {
                            "lat": 55.46822919883852,
                            "lon": 61.48558246493244
                        },
                        {
                            "lat": 55.4718191359023,
                            "lon": 61.48512173928967
                        },
                        {
                            "lat": 55.4708554811401,
                            "lon": 61.48340534242405
                        },
                        {
                            "lat": 55.467265723275666,
                            "lon": 61.48386603532153
                        }
                    ],
                    [
                        {
                            "lat": 55.46104895938961,
                            "lon": 61.486503638634176
                        },
                        {
                            "lat": 55.46201219823286,
                            "lon": 61.488220124476584
                        },
                        {
                            "lat": 55.46560255809838,
                            "lon": 61.48775955115428
                        },
                        {
                            "lat": 55.464639139994745,
                            "lon": 61.48604309804937
                        },
                        {
                            "lat": 55.46104895938961,
                            "lon": 61.486503638634176
                        }
                    ],
                    [
                        {
                            "lat": 55.49235572162639,
                            "lon": 61.52849019512316
                        },
                        {
                            "lat": 55.48876117622002,
                            "lon": 61.528951647850405
                        },
                        {
                            "lat": 55.48972764526418,
                            "lon": 61.53066786929898
                        },
                        {
                            "lat": 55.49332237037527,
                            "lon": 61.53020638374251
                        },
                        {
                            "lat": 55.49235572162639,
                            "lon": 61.52849019512316
                        }
                    ],
                    [
                        {
                            "lat": 55.45364478641176,
                            "lon": 61.404657372503294
                        },
                        {
                            "lat": 55.45460435106513,
                            "lon": 61.406373946608646
                        },
                        {
                            "lat": 55.4581854534336,
                            "lon": 61.40591392050415
                        },
                        {
                            "lat": 55.45722571056459,
                            "lon": 61.404197379038145
                        },
                        {
                            "lat": 55.45364478641176,
                            "lon": 61.404657372503294
                        }
                    ],
                    [
                        {
                            "lat": 55.44673813342664,
                            "lon": 61.41290343229868
                        },
                        {
                            "lat": 55.447697647208216,
                            "lon": 61.41462006749035
                        },
                        {
                            "lat": 55.451279825818055,
                            "lon": 61.41416018756935
                        },
                        {
                            "lat": 55.45032013368395,
                            "lon": 61.41244358501395
                        },
                        {
                            "lat": 55.44673813342664,
                            "lon": 61.41290343229868
                        }
                    ],
                    [
                        {
                            "lat": 55.5193066724644,
                            "lon": 61.501101499730986
                        },
                        {
                            "lat": 55.51571580492923,
                            "lon": 61.50156354417269
                        },
                        {
                            "lat": 55.51668265874193,
                            "lon": 61.503279525268965
                        },
                        {
                            "lat": 55.52027370550543,
                            "lon": 61.50281744798114
                        },
                        {
                            "lat": 55.5193066724644,
                            "lon": 61.501101499730986
                        }
                    ],
                    [
                        {
                            "lat": 55.50162419669977,
                            "lon": 61.51073690354088
                        },
                        {
                            "lat": 55.50521639260377,
                            "lon": 61.510275190521504
                        },
                        {
                            "lat": 55.508808466276136,
                            "lon": 61.50981338492409
                        },
                        {
                            "lat": 55.50784178365337,
                            "lon": 61.50809733320937
                        },
                        {
                            "lat": 55.50424988935026,
                            "lon": 61.508559105972765
                        },
                        {
                            "lat": 55.50065787283685,
                            "lon": 61.509020786163774
                        },
                        {
                            "lat": 55.50162419669977,
                            "lon": 61.51073690354088
                        }
                    ],
                    [
                        {
                            "lat": 55.51461113001318,
                            "lon": 61.52010949988725
                        },
                        {
                            "lat": 55.51101797973462,
                            "lon": 61.52057150256107
                        },
                        {
                            "lat": 55.511985339057034,
                            "lon": 61.52228752201073
                        },
                        {
                            "lat": 55.515578668835076,
                            "lon": 61.52182548647877
                        },
                        {
                            "lat": 55.51461113001318,
                            "lon": 61.52010949988725
                        }
                    ],
                    [
                        {
                            "lat": 55.509635149255175,
                            "lon": 61.53179160144065
                        },
                        {
                            "lat": 55.51322962176636,
                            "lon": 61.53132958686315
                        },
                        {
                            "lat": 55.51682397180856,
                            "lon": 61.53086747963404
                        },
                        {
                            "lat": 55.51585600036851,
                            "lon": 61.52915150673133
                        },
                        {
                            "lat": 55.51226182994019,
                            "lon": 61.52961358109066
                        },
                        {
                            "lat": 55.508667537064284,
                            "lon": 61.530075562803994
                        },
                        {
                            "lat": 55.509635149255175,
                            "lon": 61.53179160144065
                        }
                    ],
                    [
                        {
                            "lat": 55.5187602821728,
                            "lon": 61.53429939749538
                        },
                        {
                            "lat": 55.51516557284691,
                            "lon": 61.53476157047444
                        },
                        {
                            "lat": 55.51613373214401,
                            "lon": 61.536477548310074
                        },
                        {
                            "lat": 55.5197286211397,
                            "lon": 61.53601534245084
                        },
                        {
                            "lat": 55.5187602821728,
                            "lon": 61.53429939749538
                        }
                    ],
                    [
                        {
                            "lat": 55.47678745376598,
                            "lon": 61.39800276317309
                        },
                        {
                            "lat": 55.47774802329394,
                            "lon": 61.39971912714375
                        },
                        {
                            "lat": 55.48132792203349,
                            "lon": 61.39925852095798
                        },
                        {
                            "lat": 55.48036717447392,
                            "lon": 61.39754218965888
                        },
                        {
                            "lat": 55.47678745376598,
                            "lon": 61.39800276317309
                        }
                    ],
                    [
                        {
                            "lat": 55.48063045847882,
                            "lon": 61.40486816368003
                        },
                        {
                            "lat": 55.48159151247758,
                            "lon": 61.40658449072831
                        },
                        {
                            "lat": 55.485172123527775,
                            "lon": 61.406123753822015
                        },
                        {
                            "lat": 55.48421089142375,
                            "lon": 61.404407459458966
                        },
                        {
                            "lat": 55.48324978048736,
                            "lon": 61.402691155859586
                        },
                        {
                            "lat": 55.48228879069762,
                            "lon": 61.40097484302536
                        },
                        {
                            "lat": 55.478708713908034,
                            "lon": 61.40143548188615
                        },
                        {
                            "lat": 55.479669525629326,
                            "lon": 61.40315182739879
                        },
                        {
                            "lat": 55.47608914964306,
                            "lon": 61.40361240675169
                        },
                        {
                            "lat": 55.47512851601125,
                            "lon": 61.40189602856624
                        },
                        {
                            "lat": 55.471548197025726,
                            "lon": 61.402356483059386
                        },
                        {
                            "lat": 55.46796775696984,
                            "lon": 61.40281684535935
                        },
                        {
                            "lat": 55.468928034359614,
                            "lon": 61.404533288873786
                        },
                        {
                            "lat": 55.469888432822295,
                            "lon": 61.40624972316706
                        },
                        {
                            "lat": 55.47346922915966,
                            "lon": 61.40578929554027
                        },
                        {
                            "lat": 55.477049904384664,
                            "lon": 61.405328775709194
                        },
                        {
                            "lat": 55.48063045847882,
                            "lon": 61.40486816368003
                        }
                    ],
                    [
                        {
                            "lat": 55.48875261338927,
                            "lon": 61.40566292472449
                        },
                        {
                            "lat": 55.485172123527775,
                            "lon": 61.406123753822015
                        },
                        {
                            "lat": 55.486133476820534,
                            "lon": 61.40784003894712
                        },
                        {
                            "lat": 55.489714144784706,
                            "lon": 61.40737917715535
                        },
                        {
                            "lat": 55.48875261338927,
                            "lon": 61.40566292472449
                        }
                    ],
                    [
                        {
                            "lat": 55.489714144784706,
                            "lon": 61.40737917715535
                        },
                        {
                            "lat": 55.4906757974083,
                            "lon": 61.40909542034327
                        },
                        {
                            "lat": 55.49425652224432,
                            "lon": 61.4086344336577
                        },
                        {
                            "lat": 55.493294691520674,
                            "lon": 61.40691822317301
                        },
                        {
                            "lat": 55.489714144784706,
                            "lon": 61.40737917715535
                        }
                    ],
                    [
                        {
                            "lat": 55.47399363821008,
                            "lon": 61.42044146217992
                        },
                        {
                            "lat": 55.474954948938894,
                            "lon": 61.42215784610275
                        },
                        {
                            "lat": 55.47853747073933,
                            "lon": 61.42169721659701
                        },
                        {
                            "lat": 55.477575981673475,
                            "lon": 61.41998086536449
                        },
                        {
                            "lat": 55.47399363821008,
                            "lon": 61.42044146217992
                        }
                    ],
                    [
                        {
                            "lat": 55.494475905246745,
                            "lon": 61.46380960931584
                        },
                        {
                            "lat": 55.49544007511256,
                            "lon": 61.46552579470238
                        },
                        {
                            "lat": 55.499027183137784,
                            "lon": 61.46506443923294
                        },
                        {
                            "lat": 55.498062834451545,
                            "lon": 61.46334828661682
                        },
                        {
                            "lat": 55.494475905246745,
                            "lon": 61.46380960931584
                        }
                    ],
                    [
                        {
                            "lat": 55.523721007277096,
                            "lon": 61.47476793646622
                        },
                        {
                            "lat": 55.52730863563566,
                            "lon": 61.47430579692669
                        },
                        {
                            "lat": 55.52634236069661,
                            "lon": 61.47258990613713
                        },
                        {
                            "lat": 55.52275491116076,
                            "lon": 61.47305201284864
                        },
                        {
                            "lat": 55.51916733961183,
                            "lon": 61.47351402715117
                        },
                        {
                            "lat": 55.520133256884186,
                            "lon": 61.475229983591184
                        },
                        {
                            "lat": 55.523721007277096,
                            "lon": 61.47476793646622
                        }
                    ],
                    [
                        {
                            "lat": 55.53519896821209,
                            "lon": 61.5156197406975
                        },
                        {
                            "lat": 55.5387910821218,
                            "lon": 61.51515712281184
                        },
                        {
                            "lat": 55.5423830735446,
                            "lon": 61.51469441237042
                        },
                        {
                            "lat": 55.545974942461946,
                            "lon": 61.51423160937944
                        },
                        {
                            "lat": 55.54500603417446,
                            "lon": 61.51251590008678
                        },
                        {
                            "lat": 55.54141434452738,
                            "lon": 61.512978670176075
                        },
                        {
                            "lat": 55.54044573799429,
                            "lon": 61.51126291864921
                        },
                        {
                            "lat": 55.53685410513613,
                            "lon": 61.51172556330195
                        },
                        {
                            "lat": 55.537822532396184,
                            "lon": 61.513441347721425
                        },
                        {
                            "lat": 55.53423059779943,
                            "lon": 61.513903932716595
                        },
                        {
                            "lat": 55.530638540755696,
                            "lon": 61.514366425155266
                        },
                        {
                            "lat": 55.527046361283546,
                            "lon": 61.51482882503119
                        },
                        {
                            "lat": 55.52801437300631,
                            "lon": 61.51654469877624
                        },
                        {
                            "lat": 55.53160673183408,
                            "lon": 61.51608226602105
                        },
                        {
                            "lat": 55.53519896821209,
                            "lon": 61.5156197406975
                        }
                    ],
                    [
                        {
                            "lat": 55.505421828059575,
                            "lon": 61.394315594834104
                        },
                        {
                            "lat": 55.50445995607975,
                            "lon": 61.39259948313812
                        },
                        {
                            "lat": 55.500881261209095,
                            "lon": 61.393060668957936
                        },
                        {
                            "lat": 55.49730244516555,
                            "lon": 61.39352176265732
                        },
                        {
                            "lat": 55.49826396139228,
                            "lon": 61.39523793976206
                        },
                        {
                            "lat": 55.5018429553229,
                            "lon": 61.39477681336108
                        },
                        {
                            "lat": 55.505421828059575,
                            "lon": 61.394315594834104
                        }
                    ],
                    [
                        {
                            "lat": 55.50569094392772,
                            "lon": 61.40164129845199
                        },
                        {
                            "lat": 55.504728764911974,
                            "lon": 61.39992519106066
                        },
                        {
                            "lat": 55.50376670715328,
                            "lon": 61.39820907441398
                        },
                        {
                            "lat": 55.50280477063061,
                            "lon": 61.3964929485136
                        },
                        {
                            "lat": 55.49922559879454,
                            "lon": 61.39695410761959
                        },
                        {
                            "lat": 55.49826396139228,
                            "lon": 61.39523793976206
                        },
                        {
                            "lat": 55.494684846286134,
                            "lon": 61.395698974030786
                        },
                        {
                            "lat": 55.4956463057619,
                            "lon": 61.39741517458779
                        },
                        {
                            "lat": 55.49206689155109,
                            "lon": 61.397876149411935
                        },
                        {
                            "lat": 55.49302829423914,
                            "lon": 61.399592373420894
                        },
                        {
                            "lat": 55.49660788641588,
                            "lon": 61.39913136589946
                        },
                        {
                            "lat": 55.49756958826907,
                            "lon": 61.40084754796424
                        },
                        {
                            "lat": 55.49853141134255,
                            "lon": 61.40256372078058
                        },
                        {
                            "lat": 55.50211123826499,
                            "lon": 61.402102555693524
                        },
                        {
                            "lat": 55.50569094392772,
                            "lon": 61.40164129845199
                        }
                    ],
                    [
                        {
                            "lat": 55.51092504294667,
                            "lon": 61.397286414379934
                        },
                        {
                            "lat": 55.51188745652666,
                            "lon": 61.399002465586165
                        },
                        {
                            "lat": 55.51546662040246,
                            "lon": 61.398540964650444
                        },
                        {
                            "lat": 55.514504028943385,
                            "lon": 61.39682494617275
                        },
                        {
                            "lat": 55.51092504294667,
                            "lon": 61.397286414379934
                        }
                    ],
                    [
                        {
                            "lat": 55.49040992674514,
                            "lon": 61.40176953624715
                        },
                        {
                            "lat": 55.49137139379212,
                            "lon": 61.403485774466155
                        },
                        {
                            "lat": 55.4949514631788,
                            "lon": 61.40302479370684
                        },
                        {
                            "lat": 55.49398981810804,
                            "lon": 61.401308588186396
                        },
                        {
                            "lat": 55.49040992674514,
                            "lon": 61.40176953624715
                        }
                    ],
                    [
                        {
                            "lat": 55.500455421234435,
                            "lon": 61.40599603866153
                        },
                        {
                            "lat": 55.50141760809498,
                            "lon": 61.40771218372302
                        },
                        {
                            "lat": 55.50499796907281,
                            "lon": 61.407250920486376
                        },
                        {
                            "lat": 55.5040356041754,
                            "lon": 61.40553480814484
                        },
                        {
                            "lat": 55.500455421234435,
                            "lon": 61.40599603866153
                        }
                    ],
                    [
                        {
                            "lat": 55.4978371258127,
                            "lon": 61.40817335478221
                        },
                        {
                            "lat": 55.49879925590138,
                            "lon": 61.40988952330675
                        },
                        {
                            "lat": 55.50237991626001,
                            "lon": 61.40942831952978
                        },
                        {
                            "lat": 55.50141760809498,
                            "lon": 61.40771218372302
                        },
                        {
                            "lat": 55.4978371258127,
                            "lon": 61.40817335478221
                        }
                    ],
                    [
                        {
                            "lat": 55.51601042448068,
                            "lon": 61.41319221237935
                        },
                        {
                            "lat": 55.51697375239778,
                            "lon": 61.414908212923415
                        },
                        {
                            "lat": 55.52055463949601,
                            "lon": 61.41444650945712
                        },
                        {
                            "lat": 55.52413540511075,
                            "lon": 61.41398471381041
                        },
                        {
                            "lat": 55.52771604922359,
                            "lon": 61.41352282598959
                        },
                        {
                            "lat": 55.53129657181616,
                            "lon": 61.41306084600087
                        },
                        {
                            "lat": 55.53033253176308,
                            "lon": 61.41134497650534
                        },
                        {
                            "lat": 55.529368613227554,
                            "lon": 61.4096290977248
                        },
                        {
                            "lat": 55.5257884466213,
                            "lon": 61.41009101217599
                        },
                        {
                            "lat": 55.522208158536955,
                            "lon": 61.41055283447038
                        },
                        {
                            "lat": 55.52317172108346,
                            "lon": 61.412268778779485
                        },
                        {
                            "lat": 55.51959113351328,
                            "lon": 61.412730541666825
                        },
                        {
                            "lat": 55.51601042448068,
                            "lon": 61.41319221237935
                        }
                    ],
                    [
                        {
                            "lat": 55.49741076193168,
                            "lon": 61.42110886372499
                        },
                        {
                            "lat": 55.498373385139075,
                            "lon": 61.422825032912975
                        },
                        {
                            "lat": 55.50195553543866,
                            "lon": 61.42236378447833
                        },
                        {
                            "lat": 55.50099273398382,
                            "lon": 61.42064764802095
                        },
                        {
                            "lat": 55.49741076193168,
                            "lon": 61.42110886372499
                        }
                    ],
                    [
                        {
                            "lat": 55.54331073092204,
                            "lon": 61.46856225077859
                        },
                        {
                            "lat": 55.54427777753209,
                            "lon": 61.47027798666449
                        },
                        {
                            "lat": 55.547864494600994,
                            "lon": 61.46981532563081
                        },
                        {
                            "lat": 55.54689726931416,
                            "lon": 61.46809962260299
                        },
                        {
                            "lat": 55.54331073092204,
                            "lon": 61.46856225077859
                        }
                    ],
                    [
                        {
                            "lat": 55.55161897728438,
                            "lon": 61.49693893691823
                        },
                        {
                            "lat": 55.54802921057106,
                            "lon": 61.49740181385208
                        },
                        {
                            "lat": 55.54443932147003,
                            "lon": 61.49786459831191
                        },
                        {
                            "lat": 55.54540760851314,
                            "lon": 61.499580316482806
                        },
                        {
                            "lat": 55.54899767667207,
                            "lon": 61.49911749913212
                        },
                        {
                            "lat": 55.552587622422024,
                            "lon": 61.49865458930185
                        },
                        {
                            "lat": 55.55161897728438,
                            "lon": 61.49693893691823
                        }
                    ],
                    [
                        {
                            "lat": 55.51892114728578,
                            "lon": 61.370493283166226
                        },
                        {
                            "lat": 55.51988293037776,
                            "lon": 61.37220926770966
                        },
                        {
                            "lat": 55.523458879235356,
                            "lon": 61.37174767247914
                        },
                        {
                            "lat": 55.5224969186473,
                            "lon": 61.37003172064977
                        },
                        {
                            "lat": 55.51892114728578,
                            "lon": 61.370493283166226
                        }
                    ],
                    [
                        {
                            "lat": 55.53239713919252,
                            "lon": 61.394516223484516
                        },
                        {
                            "lat": 55.528818759835175,
                            "lon": 61.394978152221704
                        },
                        {
                            "lat": 55.52524025911321,
                            "lon": 61.39543998886534
                        },
                        {
                            "lat": 55.521661637045035,
                            "lon": 61.39590173340923
                        },
                        {
                            "lat": 55.5226245841991,
                            "lon": 61.397617686413106
                        },
                        {
                            "lat": 55.52358765270211,
                            "lon": 61.3993336301446
                        },
                        {
                            "lat": 55.52716663042048,
                            "lon": 61.398871820106784
                        },
                        {
                            "lat": 55.52620338408321,
                            "lon": 61.397155909123995
                        },
                        {
                            "lat": 55.52978206259999,
                            "lon": 61.39669403972951
                        },
                        {
                            "lat": 55.53336061973116,
                            "lon": 61.396232078235954
                        },
                        {
                            "lat": 55.5343242216738,
                            "lon": 61.397947923704756
                        },
                        {
                            "lat": 55.53790283517202,
                            "lon": 61.39748583735295
                        },
                        {
                            "lat": 55.5369390554583,
                            "lon": 61.39577002464954
                        },
                        {
                            "lat": 55.53597539716692,
                            "lon": 61.39405420266003
                        },
                        {
                            "lat": 55.53239713919252,
                            "lon": 61.394516223484516
                        }
                    ],
                    [
                        {
                            "lat": 55.54244540615232,
                            "lon": 61.398739429550425
                        },
                        {
                            "lat": 55.54602395449262,
                            "lon": 61.39827712624059
                        },
                        {
                            "lat": 55.54505969781982,
                            "lon": 61.3965613883791
                        },
                        {
                            "lat": 55.54409556262714,
                            "lon": 61.394845641223036
                        },
                        {
                            "lat": 55.540517369763066,
                            "lon": 61.39530787897648
                        },
                        {
                            "lat": 55.541481327226805,
                            "lon": 61.39702365890899
                        },
                        {
                            "lat": 55.54244540615232,
                            "lon": 61.398739429550425
                        }
                    ],
                    [
                        {
                            "lat": 55.55318068665128,
                            "lon": 61.3973522433714
                        },
                        {
                            "lat": 55.55675887043297,
                            "lon": 61.39688966382447
                        },
                        {
                            "lat": 55.56033693265849,
                            "lon": 61.39642699221102
                        },
                        {
                            "lat": 55.56391487330947,
                            "lon": 61.395964228537245
                        },
                        {
                            "lat": 55.5629497282165,
                            "lon": 61.39424865465858
                        },
                        {
                            "lat": 55.559371965207376,
                            "lon": 61.394711385524666
                        },
                        {
                            "lat": 55.55579408064483,
                            "lon": 61.395174024336
                        },
                        {
                            "lat": 55.55221607454717,
                            "lon": 61.395636571086335
                        },
                        {
                            "lat": 55.55318068665128,
                            "lon": 61.3973522433714
                        }
                    ],
                    [
                        {
                            "lat": 55.52551415383917,
                            "lon": 61.40276548978405
                        },
                        {
                            "lat": 55.52647758651537,
                            "lon": 61.40448140568886
                        },
                        {
                            "lat": 55.5300570978468,
                            "lon": 61.40401949738455
                        },
                        {
                            "lat": 55.52909348728121,
                            "lon": 61.40230361423865
                        },
                        {
                            "lat": 55.52551415383917,
                            "lon": 61.40276548978405
                        }
                    ],
                    [
                        {
                            "lat": 55.539830758950835,
                            "lon": 61.40091743489524
                        },
                        {
                            "lat": 55.5362517898554,
                            "lon": 61.40137958678802
                        },
                        {
                            "lat": 55.532672699292746,
                            "lon": 61.40184164657122
                        },
                        {
                            "lat": 55.53363648772661,
                            "lon": 61.4035574969526
                        },
                        {
                            "lat": 55.5372157561365,
                            "lon": 61.403095404399345
                        },
                        {
                            "lat": 55.54079490305807,
                            "lon": 61.40263321973095
                        },
                        {
                            "lat": 55.54437392847297,
                            "lon": 61.40217094295368
                        },
                        {
                            "lat": 55.54795283236287,
                            "lon": 61.40170857407378
                        },
                        {
                            "lat": 55.54698833266661,
                            "lon": 61.39999285480603
                        },
                        {
                            "lat": 55.54340960656067,
                            "lon": 61.40045519089917
                        },
                        {
                            "lat": 55.539830758950835,
                            "lon": 61.40091743489524
                        }
                    ],
                    [
                        {
                            "lat": 55.55897091230937,
                            "lon": 61.40764608166454
                        },
                        {
                            "lat": 55.559936375621554,
                            "lon": 61.409361688795784
                        },
                        {
                            "lat": 55.563515803704846,
                            "lon": 61.40889887957693
                        },
                        {
                            "lat": 55.562550162580266,
                            "lon": 61.40718330526626
                        },
                        {
                            "lat": 55.55897091230937,
                            "lon": 61.40764608166454
                        }
                    ],
                    [
                        {
                            "lat": 55.558339115226254,
                            "lon": 61.46110141574129
                        },
                        {
                            "lat": 55.5619245072264,
                            "lon": 61.46063842446811
                        },
                        {
                            "lat": 55.565509777076706,
                            "lon": 61.460175340883545
                        },
                        {
                            "lat": 55.56909492475878,
                            "lon": 61.459712164993796
                        },
                        {
                            "lat": 55.57267995025411,
                            "lon": 61.45924889680517
                        },
                        {
                            "lat": 55.57626485354434,
                            "lon": 61.45878553632396
                        },
                        {
                            "lat": 55.579849634611016,
                            "lon": 61.45832208355639
                        },
                        {
                            "lat": 55.5834342934357,
                            "lon": 61.457858538508766
                        },
                        {
                            "lat": 55.58246559327574,
                            "lon": 61.45614315985833
                        },
                        {
                            "lat": 55.58604995149199,
                            "lon": 61.455679555448135
                        },
                        {
                            "lat": 55.58963418745069,
                            "lon": 61.455215858775986
                        },
                        {
                            "lat": 55.58866525289176,
                            "lon": 61.45350053659325
                        },
                        {
                            "lat": 55.59224918828735,
                            "lon": 61.453036780584355
                        },
                        {
                            "lat": 55.59128019771433,
                            "lon": 61.45132148195833
                        },
                        {
                            "lat": 55.587696440587536,
                            "lon": 61.45178520505174
                        },
                        {
                            "lat": 55.58411256122729,
                            "lon": 61.452248835900626
                        },
                        {
                            "lat": 55.5850811952415,
                            "lon": 61.453964200352026
                        },
                        {
                            "lat": 55.58149701535493,
                            "lon": 61.45442777185445
                        },
                        {
                            "lat": 55.58052855965199,
                            "lon": 61.4527123744987
                        },
                        {
                            "lat": 55.57694443588004,
                            "lon": 61.453175820839704
                        },
                        {
                            "lat": 55.57791271325047,
                            "lon": 61.45489125109422
                        },
                        {
                            "lat": 55.57432828894652,
                            "lon": 61.45535463806504
                        },
                        {
                            "lat": 55.575296510144284,
                            "lon": 61.45707009186776
                        },
                        {
                            "lat": 55.571711785265876,
                            "lon": 61.457533419454485
                        },
                        {
                            "lat": 55.57074374246151,
                            "lon": 61.455817932760695
                        },
                        {
                            "lat": 55.567159073813855,
                            "lon": 61.45628113517489
                        },
                        {
                            "lat": 55.566191331568355,
                            "lon": 61.45456560625753
                        },
                        {
                            "lat": 55.5626067191939,
                            "lon": 61.455028683507486
                        },
                        {
                            "lat": 55.55902198471491,
                            "lon": 61.45549166846907
                        },
                        {
                            "lat": 55.559989370104304,
                            "lon": 61.45720726313381
                        },
                        {
                            "lat": 55.556404335079264,
                            "lon": 61.45767018866599
                        },
                        {
                            "lat": 55.55281917796531,
                            "lon": 61.45813302189165
                        },
                        {
                            "lat": 55.553786328484165,
                            "lon": 61.45984867295716
                        },
                        {
                            "lat": 55.55475360109474,
                            "lon": 61.461564314696744
                        },
                        {
                            "lat": 55.558339115226254,
                            "lon": 61.46110141574129
                        }
                    ],
                    [
                        {
                            "lat": 55.5548294123918,
                            "lon": 61.39345837554274
                        },
                        {
                            "lat": 55.55840711930972,
                            "lon": 61.39299576953011
                        },
                        {
                            "lat": 55.56198470469529,
                            "lon": 61.392533071468286
                        },
                        {
                            "lat": 55.56101980272475,
                            "lon": 61.390817478967946
                        },
                        {
                            "lat": 55.5574423949444,
                            "lon": 61.39128014422893
                        },
                        {
                            "lat": 55.55386486565278,
                            "lon": 61.39174271744627
                        },
                        {
                            "lat": 55.5548294123918,
                            "lon": 61.39345837554274
                        }
                    ],
                    [
                        {
                            "lat": 55.58276890697586,
                            "lon": 61.39536442990899
                        },
                        {
                            "lat": 55.57919139691747,
                            "lon": 61.39582768662947
                        },
                        {
                            "lat": 55.57561376517192,
                            "lon": 61.39629085131534
                        },
                        {
                            "lat": 55.576579686380555,
                            "lon": 61.39800630808905
                        },
                        {
                            "lat": 55.57754572927917,
                            "lon": 61.399721755536085
                        },
                        {
                            "lat": 55.581123716231225,
                            "lon": 61.39925852517328
                        },
                        {
                            "lat": 55.58470158145387,
                            "lon": 61.39879520276477
                        },
                        {
                            "lat": 55.588279324928834,
                            "lon": 61.39833178831674
                        },
                        {
                            "lat": 55.58731274925637,
                            "lon": 61.3966164394069
                        },
                        {
                            "lat": 55.58373518335157,
                            "lon": 61.39707982100363
                        },
                        {
                            "lat": 55.58276890697586,
                            "lon": 61.39536442990899
                        }
                    ],
                    [
                        {
                            "lat": 55.565845528295014,
                            "lon": 61.39939534835334
                        },
                        {
                            "lat": 55.566811038229766,
                            "lon": 61.40111089428759
                        },
                        {
                            "lat": 55.57038939026015,
                            "lon": 61.40064794009948
                        },
                        {
                            "lat": 55.56942370264965,
                            "lon": 61.398932426988736
                        },
                        {
                            "lat": 55.565845528295014,
                            "lon": 61.39939534835334
                        }
                    ],
                    [
                        {
                            "lat": 55.57135519954509,
                            "lon": 61.402363443888824
                        },
                        {
                            "lat": 55.56777666982067,
                            "lon": 61.402826430903865
                        },
                        {
                            "lat": 55.568742423088814,
                            "lon": 61.404541958200625
                        },
                        {
                            "lat": 55.57232113052558,
                            "lon": 61.404078938355184
                        },
                        {
                            "lat": 55.57135519954509,
                            "lon": 61.402363443888824
                        }
                    ],
                    [
                        {
                            "lat": 55.606459442556364,
                            "lon": 61.403337986513385
                        },
                        {
                            "lat": 55.6100372870595,
                            "lon": 61.402873980411385
                        },
                        {
                            "lat": 55.61361500962068,
                            "lon": 61.40240988228523
                        },
                        {
                            "lat": 55.61264688134119,
                            "lon": 61.40069476804559
                        },
                        {
                            "lat": 55.611678875000806,
                            "lon": 61.398979644442086
                        },
                        {
                            "lat": 55.60810150745441,
                            "lon": 61.399443676775086
                        },
                        {
                            "lat": 55.609069336296535,
                            "lon": 61.40115883327349
                        },
                        {
                            "lat": 55.60549166933107,
                            "lon": 61.40162280648273
                        },
                        {
                            "lat": 55.60452401800831,
                            "lon": 61.399907617095046
                        },
                        {
                            "lat": 55.60094640668083,
                            "lon": 61.40037146539569
                        },
                        {
                            "lat": 55.597368673490244,
                            "lon": 61.400835221670775
                        },
                        {
                            "lat": 55.598335969710796,
                            "lon": 61.402550476820466
                        },
                        {
                            "lat": 55.60191388046306,
                            "lon": 61.40208668766716
                        },
                        {
                            "lat": 55.60288147612953,
                            "lon": 61.403801900585016
                        },
                        {
                            "lat": 55.606459442556364,
                            "lon": 61.403337986513385
                        }
                    ],
                    [
                        {
                            "lat": 55.58305652246254,
                            "lon": 61.40268932639037
                        },
                        {
                            "lat": 55.58402310822508,
                            "lon": 61.40440471299743
                        },
                        {
                            "lat": 55.58760150633141,
                            "lon": 61.403941292031135
                        },
                        {
                            "lat": 55.58663474292266,
                            "lon": 61.40222593828008
                        },
                        {
                            "lat": 55.58305652246254,
                            "lon": 61.40268932639037
                        }
                    ],
                    [
                        {
                            "lat": 55.58044458832599,
                            "lon": 61.40486804190135
                        },
                        {
                            "lat": 55.57947818023079,
                            "lon": 61.40315262244382
                        },
                        {
                            "lat": 55.57589971624569,
                            "lon": 61.40361582643419
                        },
                        {
                            "lat": 55.576865946652454,
                            "lon": 61.405331278736604
                        },
                        {
                            "lat": 55.577832298815345,
                            "lon": 61.40704672170947
                        },
                        {
                            "lat": 55.581411118195646,
                            "lon": 61.40658345202586
                        },
                        {
                            "lat": 55.58044458832599,
                            "lon": 61.40486804190135
                        }
                    ],
                    [
                        {
                            "lat": 55.569708298055325,
                            "lon": 61.406257476176215
                        },
                        {
                            "lat": 55.568742423088814,
                            "lon": 61.404541958200625
                        },
                        {
                            "lat": 55.565163593953685,
                            "lon": 61.4050048859642
                        },
                        {
                            "lat": 55.56612929116837,
                            "lon": 61.40672043676806
                        },
                        {
                            "lat": 55.567095110084196,
                            "lon": 61.40843597825263
                        },
                        {
                            "lat": 55.57067429474129,
                            "lon": 61.40797298482908
                        },
                        {
                            "lat": 55.569708298055325,
                            "lon": 61.406257476176215
                        }
                    ],
                    [
                        {
                            "lat": 55.61197355443245,
                            "lon": 61.406304246599426
                        },
                        {
                            "lat": 55.615551632081505,
                            "lon": 61.405840082666394
                        },
                        {
                            "lat": 55.614583259860396,
                            "lon": 61.404124987159335
                        },
                        {
                            "lat": 55.61100535976441,
                            "lon": 61.40458911818721
                        },
                        {
                            "lat": 55.60742733770532,
                            "lon": 61.40505315718542
                        },
                        {
                            "lat": 55.60839535479911,
                            "lon": 61.406768318497235
                        },
                        {
                            "lat": 55.61197355443245,
                            "lon": 61.406304246599426
                        }
                    ],
                    [
                        {
                            "lat": 55.6048170331998,
                            "lon": 61.407232298353556
                        },
                        {
                            "lat": 55.605784994645894,
                            "lon": 61.40894748320107
                        },
                        {
                            "lat": 55.606753078060834,
                            "lon": 61.41066265868863
                        },
                        {
                            "lat": 55.60772128346579,
                            "lon": 61.41237782481464
                        },
                        {
                            "lat": 55.61130013796106,
                            "lon": 61.41191374625551
                        },
                        {
                            "lat": 55.610331754905815,
                            "lon": 61.41019861303389
                        },
                        {
                            "lat": 55.6093634938589,
                            "lon": 61.40848347044726
                        },
                        {
                            "lat": 55.60839535479911,
                            "lon": 61.406768318497235
                        },
                        {
                            "lat": 55.6048170331998,
                            "lon": 61.407232298353556
                        }
                    ],
                    [
                        {
                            "lat": 55.58721285586053,
                            "lon": 61.416875716690896
                        },
                        {
                            "lat": 55.58818023874317,
                            "lon": 61.41859106143989
                        },
                        {
                            "lat": 55.59176018064032,
                            "lon": 61.41812746960869
                        },
                        {
                            "lat": 55.590792619943755,
                            "lon": 61.41641215773762
                        },
                        {
                            "lat": 55.58721285586053,
                            "lon": 61.416875716690896
                        }
                    ],
                    [
                        {
                            "lat": 55.61113703476433,
                            "lon": 61.45243174159383
                        },
                        {
                            "lat": 55.61210716111377,
                            "lon": 61.45414685679857
                        },
                        {
                            "lat": 55.615690718932704,
                            "lon": 61.4536824815128
                        },
                        {
                            "lat": 55.61472041440525,
                            "lon": 61.45196739926379
                        },
                        {
                            "lat": 55.61113703476433,
                            "lon": 61.45243174159383
                        }
                    ]
                ]
            },
            {
                "type": "MultiPolygon",
                "value": 0.28,
                "data": [
                    [
                        {
                            "lat": 55.34089925970267,
                            "lon": 61.4227636572388
                        },
                        {
                            "lat": 55.341853351205295,
                            "lon": 61.4244812546528
                        },
                        {
                            "lat": 55.34543867419757,
                            "lon": 61.42402411805089
                        },
                        {
                            "lat": 55.346393065091064,
                            "lon": 61.42574167389291
                        },
                        {
                            "lat": 55.34280756315596,
                            "lon": 61.426198842965256
                        },
                        {
                            "lat": 55.34376189557562,
                            "lon": 61.42791642217467
                        },
                        {
                            "lat": 55.34017609418909,
                            "lon": 61.42837353127011
                        },
                        {
                            "lat": 55.34113036809768,
                            "lon": 61.430091133846545
                        },
                        {
                            "lat": 55.34208476249858,
                            "lon": 61.43180872732026
                        },
                        {
                            "lat": 55.34303927741276,
                            "lon": 61.433526311689604
                        },
                        {
                            "lat": 55.343993912861194,
                            "lon": 61.43524388695313
                        },
                        {
                            "lat": 55.34494866886487,
                            "lon": 61.43696145310926
                        },
                        {
                            "lat": 55.34590354544477,
                            "lon": 61.43867901015641
                        },
                        {
                            "lat": 55.34685854262189,
                            "lon": 61.440396558093084
                        },
                        {
                            "lat": 55.3478136604172,
                            "lon": 61.44211409691769
                        },
                        {
                            "lat": 55.34876889885172,
                            "lon": 61.443831626628715
                        },
                        {
                            "lat": 55.34972425794646,
                            "lon": 61.44554914722456
                        },
                        {
                            "lat": 55.35067973772242,
                            "lon": 61.44726665870374
                        },
                        {
                            "lat": 55.35163533820062,
                            "lon": 61.44898416106462
                        },
                        {
                            "lat": 55.35259105940208,
                            "lon": 61.45070165430574
                        },
                        {
                            "lat": 55.356179189255165,
                            "lon": 61.45024412281314
                        },
                        {
                            "lat": 55.35522328882907,
                            "lon": 61.44852666208458
                        },
                        {
                            "lat": 55.35426750914488,
                            "lon": 61.4468091922328
                        },
                        {
                            "lat": 55.35785515984647,
                            "lon": 61.446351633249336
                        },
                        {
                            "lat": 55.35689932171683,
                            "lon": 61.44463418678722
                        },
                        {
                            "lat": 55.353311850181576,
                            "lon": 61.44509171325936
                        },
                        {
                            "lat": 55.35235631191812,
                            "lon": 61.443374225165805
                        },
                        {
                            "lat": 55.351400894333544,
                            "lon": 61.44165672795369
                        },
                        {
                            "lat": 55.354988007592034,
                            "lon": 61.44119926649399
                        },
                        {
                            "lat": 55.35403253155486,
                            "lon": 61.439481792665994
                        },
                        {
                            "lat": 55.35307717617315,
                            "lon": 61.43776430971909
                        },
                        {
                            "lat": 55.34949042111688,
                            "lon": 61.43822170618
                        },
                        {
                            "lat": 55.34853536544282,
                            "lon": 61.43650418162151
                        },
                        {
                            "lat": 55.35212194142592,
                            "lon": 61.436046817654905
                        },
                        {
                            "lat": 55.355708396795535,
                            "lon": 61.43558936121568
                        },
                        {
                            "lat": 55.35666381059494,
                            "lon": 61.43730682077995
                        },
                        {
                            "lat": 55.36025032436363,
                            "lon": 61.436849239368726
                        },
                        {
                            "lat": 55.359294731533055,
                            "lon": 61.43513181230998
                        },
                        {
                            "lat": 55.36288094561984,
                            "lon": 61.43467417094404
                        },
                        {
                            "lat": 55.3619252944484,
                            "lon": 61.432956767270426
                        },
                        {
                            "lat": 55.3655112088953,
                            "lon": 61.43249906596379
                        },
                        {
                            "lat": 55.36646703903732,
                            "lon": 61.43421643712405
                        },
                        {
                            "lat": 55.370053011766856,
                            "lon": 61.433758610856195
                        },
                        {
                            "lat": 55.373638863789864,
                            "lon": 61.4333006921467
                        },
                        {
                            "lat": 55.37268267576968,
                            "lon": 61.43158338602997
                        },
                        {
                            "lat": 55.37626822815999,
                            "lon": 61.431125407415216
                        },
                        {
                            "lat": 55.377224595087725,
                            "lon": 61.43284268100175
                        },
                        {
                            "lat": 55.38081020564189,
                            "lon": 61.432384577427584
                        },
                        {
                            "lat": 55.38176687221851,
                            "lon": 61.43410180933531
                        },
                        {
                            "lat": 55.382723659578446,
                            "lon": 61.43581903209843
                        },
                        {
                            "lat": 55.38630950715715,
                            "lon": 61.435360771008206
                        },
                        {
                            "lat": 55.38726659424273,
                            "lon": 61.43707795207358
                        },
                        {
                            "lat": 55.39085249989881,
                            "lon": 61.436619565998065
                        },
                        {
                            "lat": 55.391809886747225,
                            "lon": 61.43833670535167
                        },
                        {
                            "lat": 55.39539585043861,
                            "lon": 61.43787819428244
                        },
                        {
                            "lat": 55.39898169322784,
                            "lon": 61.437419590786384
                        },
                        {
                            "lat": 55.39802394860479,
                            "lon": 61.43570251657088
                        },
                        {
                            "lat": 55.39443828469236,
                            "lon": 61.43616108749508
                        },
                        {
                            "lat": 55.39348083982722,
                            "lon": 61.4344439715498
                        },
                        {
                            "lat": 55.397066324881415,
                            "lon": 61.433985433194046
                        },
                        {
                            "lat": 55.396108822036695,
                            "lon": 61.4322683406574
                        },
                        {
                            "lat": 55.399694007393606,
                            "lon": 61.43180974246288
                        },
                        {
                            "lat": 55.40065168905695,
                            "lon": 61.43352680242888
                        },
                        {
                            "lat": 55.40423693233526,
                            "lon": 61.433068079260494
                        },
                        {
                            "lat": 55.4078220546978,
                            "lon": 61.43260926369514
                        },
                        {
                            "lat": 55.40878021486967,
                            "lon": 61.43432624933298
                        },
                        {
                            "lat": 55.412365395071944,
                            "lon": 61.43386730878599
                        },
                        {
                            "lat": 55.4159504543003,
                            "lon": 61.43340827584879
                        },
                        {
                            "lat": 55.414991936601275,
                            "lon": 61.431691355398264
                        },
                        {
                            "lat": 55.418576696105106,
                            "lon": 61.43123226267918
                        },
                        {
                            "lat": 55.41761812066405,
                            "lon": 61.42951536565046
                        },
                        {
                            "lat": 55.42120258048547,
                            "lon": 61.4290562131635
                        },
                        {
                            "lat": 55.4247869193194,
                            "lon": 61.4285969683162
                        },
                        {
                            "lat": 55.42574585212428,
                            "lon": 61.43031380013084
                        },
                        {
                            "lat": 55.429330248602525,
                            "lon": 61.42985443031399
                        },
                        {
                            "lat": 55.43291452403519,
                            "lon": 61.42939496814366
                        },
                        {
                            "lat": 55.43649867840373,
                            "lon": 61.42893541362606
                        },
                        {
                            "lat": 55.437458268179164,
                            "lon": 61.430652138380204
                        },
                        {
                            "lat": 55.438417979058265,
                            "lon": 61.432368853935415
                        },
                        {
                            "lat": 55.43483346741766,
                            "lon": 61.432828473716555
                        },
                        {
                            "lat": 55.43387393518384,
                            "lon": 61.431111725527856
                        },
                        {
                            "lat": 55.43028948110335,
                            "lon": 61.431571220322645
                        },
                        {
                            "lat": 55.43124883467081,
                            "lon": 61.43328800113922
                        },
                        {
                            "lat": 55.43220830932598,
                            "lon": 61.43500477276213
                        },
                        {
                            "lat": 55.435793120757694,
                            "lon": 61.43454521270816
                        },
                        {
                            "lat": 55.43675289522503,
                            "lon": 61.43626194250118
                        },
                        {
                            "lat": 55.44033776421181,
                            "lon": 61.43580225744284
                        },
                        {
                            "lat": 55.43937781106214,
                            "lon": 61.43408556029014
                        },
                        {
                            "lat": 55.44296238022075,
                            "lon": 61.4336258155143
                        },
                        {
                            "lat": 55.44200236957413,
                            "lon": 61.43190914180204
                        },
                        {
                            "lat": 55.441042480070784,
                            "lon": 61.430192458885905
                        },
                        {
                            "lat": 55.44462657084021,
                            "lon": 61.429732687051164
                        },
                        {
                            "lat": 55.4482105404689,
                            "lon": 61.42927282288226
                        },
                        {
                            "lat": 55.449170787157534,
                            "lon": 61.43098944050349
                        },
                        {
                            "lat": 55.45275481418805,
                            "lon": 61.430529451350736
                        },
                        {
                            "lat": 55.453715360636515,
                            "lon": 61.43224602710196
                        },
                        {
                            "lat": 55.4572994450267,
                            "lon": 61.431785912957004
                        },
                        {
                            "lat": 55.45633872001975,
                            "lon": 61.43006936987065
                        },
                        {
                            "lat": 55.45537811623009,
                            "lon": 61.428352817566704
                        },
                        {
                            "lat": 55.4589617223256,
                            "lon": 61.42789267643253
                        },
                        {
                            "lat": 55.45800106123177,
                            "lon": 61.42617614757617
                        },
                        {
                            "lat": 55.4615843676332,
                            "lon": 61.425715946801915
                        },
                        {
                            "lat": 55.462545207206375,
                            "lon": 61.42743244298904
                        },
                        {
                            "lat": 55.46612857085393,
                            "lon": 61.42697211724249
                        },
                        {
                            "lat": 55.469711813249795,
                            "lon": 61.4265116991991
                        },
                        {
                            "lat": 55.470673130988615,
                            "lon": 61.4282281208014
                        },
                        {
                            "lat": 55.4742564305489,
                            "lon": 61.42776757777799
                        },
                        {
                            "lat": 55.47521804803206,
                            "lon": 61.429483957456206
                        },
                        {
                            "lat": 55.4788014047146,
                            "lon": 61.429023289444444
                        },
                        {
                            "lat": 55.477839608799464,
                            "lon": 61.42730694246461
                        },
                        {
                            "lat": 55.48142266572181,
                            "lon": 61.42684621486748
                        },
                        {
                            "lat": 55.48500560129747,
                            "lon": 61.42638539499285
                        },
                        {
                            "lat": 55.48404356994674,
                            "lon": 61.424669104181596
                        },
                        {
                            "lat": 55.48762620580704,
                            "lon": 61.4242082247474
                        },
                        {
                            "lat": 55.488588415508026,
                            "lon": 61.42592448284694
                        },
                        {
                            "lat": 55.49217110833499,
                            "lon": 61.42546347843599
                        },
                        {
                            "lat": 55.49120872030481,
                            "lon": 61.42374725305374
                        },
                        {
                            "lat": 55.494791113421634,
                            "lon": 61.42328618910684
                        },
                        {
                            "lat": 55.49575367975987,
                            "lon": 61.425002381766205
                        },
                        {
                            "lat": 55.49933612976429,
                            "lon": 61.424541192843876
                        },
                        {
                            "lat": 55.50029899582846,
                            "lon": 61.42625734351614
                        },
                        {
                            "lat": 55.50388150267826,
                            "lon": 61.425796029609984
                        },
                        {
                            "lat": 55.50484466850521,
                            "lon": 61.42751213828116
                        },
                        {
                            "lat": 55.50126198335265,
                            "lon": 61.427973484928174
                        },
                        {
                            "lat": 55.50222509235797,
                            "lon": 61.42968961707841
                        },
                        {
                            "lat": 55.503188322865554,
                            "lon": 61.431405739965264
                        },
                        {
                            "lat": 55.50677136467899,
                            "lon": 61.43094432782618
                        },
                        {
                            "lat": 55.505807955831735,
                            "lon": 61.429228237687056
                        },
                        {
                            "lat": 55.50939069778482,
                            "lon": 61.42876676603888
                        },
                        {
                            "lat": 55.51297331819877,
                            "lon": 61.428305202140116
                        },
                        {
                            "lat": 55.5165558170551,
                            "lon": 61.42784354599696
                        },
                        {
                            "lat": 55.51751976079459,
                            "lon": 61.42955953785944
                        },
                        {
                            "lat": 55.52110231633012,
                            "lon": 61.42909775670813
                        },
                        {
                            "lat": 55.52013819433547,
                            "lon": 61.427381797615745
                        },
                        {
                            "lat": 55.52372045002139,
                            "lon": 61.42691995700262
                        },
                        {
                            "lat": 55.52468475025009,
                            "lon": 61.4286358833194
                        },
                        {
                            "lat": 55.52826706253607,
                            "lon": 61.42817391769949
                        },
                        {
                            "lat": 55.53184925316964,
                            "lon": 61.42771185985459
                        },
                        {
                            "lat": 55.53543132213241,
                            "lon": 61.42724970979101
                        },
                        {
                            "lat": 55.53901326940595,
                            "lon": 61.42678746751496
                        },
                        {
                            "lat": 55.54259509497186,
                            "lon": 61.42632513303271
                        },
                        {
                            "lat": 55.546176798811736,
                            "lon": 61.42586270635051
                        },
                        {
                            "lat": 55.547142289745324,
                            "lon": 61.42757842659041
                        },
                        {
                            "lat": 55.548107902444464,
                            "lon": 61.429294137522646
                        },
                        {
                            "lat": 55.551689840767274,
                            "lon": 61.42883155300721
                        },
                        {
                            "lat": 55.55527165728499,
                            "lon": 61.42836887629315
                        },
                        {
                            "lat": 55.55885335197918,
                            "lon": 61.42790610738675
                        },
                        {
                            "lat": 55.55981962082537,
                            "lon": 61.42962171051827
                        },
                        {
                            "lat": 55.563401371755475,
                            "lon": 61.42915881658417
                        },
                        {
                            "lat": 55.56698300080416,
                            "lon": 61.42869583046468
                        },
                        {
                            "lat": 55.567949747684544,
                            "lon": 61.43041135857936
                        },
                        {
                            "lat": 55.57153143288723,
                            "lon": 61.429948247424555
                        },
                        {
                            "lat": 55.57511299615056,
                            "lon": 61.4294850440913
                        },
                        {
                            "lat": 55.57869443745617,
                            "lon": 61.429021748585875
                        },
                        {
                            "lat": 55.582275756785684,
                            "lon": 61.428558360914515
                        },
                        {
                            "lat": 55.583243337748776,
                            "lon": 61.43027374822776
                        },
                        {
                            "lat": 55.58682471307133,
                            "lon": 61.42981023551488
                        },
                        {
                            "lat": 55.58779259405589,
                            "lon": 61.431525580597864
                        },
                        {
                            "lat": 55.59137402532907,
                            "lon": 61.43106194283515
                        },
                        {
                            "lat": 55.59495533452872,
                            "lon": 61.430598212914184
                        },
                        {
                            "lat": 55.59592369355326,
                            "lon": 61.43231348285304
                        },
                        {
                            "lat": 55.5995050586216,
                            "lon": 61.431849627874655
                        },
                        {
                            "lat": 55.60047371773817,
                            "lon": 61.43356485554621
                        },
                        {
                            "lat": 55.6014424990074,
                            "lon": 61.43528007385428
                        },
                        {
                            "lat": 55.60241140245047,
                            "lon": 61.436995282797284
                        },
                        {
                            "lat": 55.60338042808863,
                            "lon": 61.43871048237368
                        },
                        {
                            "lat": 55.604349575943075,
                            "lon": 61.44042567258178
                        },
                        {
                            "lat": 55.60076732079651,
                            "lon": 61.44088969213891
                        },
                        {
                            "lat": 55.60173641281778,
                            "lon": 61.44260490590328
                        },
                        {
                            "lat": 55.60270562707945,
                            "lon": 61.44432011029966
                        },
                        {
                            "lat": 55.59912289351148,
                            "lon": 61.44478410351589
                        },
                        {
                            "lat": 55.6000920519061,
                            "lon": 61.44649933147007
                        },
                        {
                            "lat": 55.59650901794494,
                            "lon": 61.44696326540543
                        },
                        {
                            "lat": 55.597478120435795,
                            "lon": 61.448678516917155
                        },
                        {
                            "lat": 55.60106133256524,
                            "lon": 61.448214550056534
                        },
                        {
                            "lat": 55.60464442240892,
                            "lon": 61.447750490982024
                        },
                        {
                            "lat": 55.60367496360274,
                            "lon": 61.44603530532642
                        },
                        {
                            "lat": 55.60725775301653,
                            "lon": 61.445571186980736
                        },
                        {
                            "lat": 55.6062882383858,
                            "lon": 61.44385602488693
                        },
                        {
                            "lat": 55.60531884603505,
                            "lon": 61.44214085342004
                        },
                        {
                            "lat": 55.60890115699356,
                            "lon": 61.44167670875222
                        },
                        {
                            "lat": 55.60793170885212,
                            "lon": 61.43996156084561
                        },
                        {
                            "lat": 55.60696238296653,
                            "lon": 61.438246403565664
                        },
                        {
                            "lat": 55.605993179315604,
                            "lon": 61.43653123691406
                        },
                        {
                            "lat": 55.60957483396731,
                            "lon": 61.436067098869124
                        },
                        {
                            "lat": 55.6086055745566,
                            "lon": 61.43435195577429
                        },
                        {
                            "lat": 55.607636437356454,
                            "lon": 61.43263680330749
                        },
                        {
                            "lat": 55.6040551386328,
                            "lon": 61.43310087550214
                        },
                        {
                            "lat": 55.6030863015585,
                            "lon": 61.43138568074504
                        },
                        {
                            "lat": 55.60211758663398,
                            "lon": 61.4296704766226
                        },
                        {
                            "lat": 55.59853652163647,
                            "lon": 61.430134390841246
                        },
                        {
                            "lat": 55.59756810676158,
                            "lon": 61.42841914444757
                        },
                        {
                            "lat": 55.59398709759606,
                            "lon": 61.42888293361851
                        },
                        {
                            "lat": 55.59040596635986,
                            "lon": 61.42934663064303
                        },
                        {
                            "lat": 55.58943802944301,
                            "lon": 61.42763130909912
                        },
                        {
                            "lat": 55.588470214557304,
                            "lon": 61.42591597820498
                        },
                        {
                            "lat": 55.58488931718298,
                            "lon": 61.42637951730539
                        },
                        {
                            "lat": 55.581308297817024,
                            "lon": 61.42684296425795
                        },
                        {
                            "lat": 55.57772715647783,
                            "lon": 61.427306319056434
                        },
                        {
                            "lat": 55.57675999745437,
                            "lon": 61.425590880188665
                        },
                        {
                            "lat": 55.573178912153345,
                            "lon": 61.42605410996291
                        },
                        {
                            "lat": 55.5722120530384,
                            "lon": 61.424338628897985
                        },
                        {
                            "lat": 55.56863102381792,
                            "lon": 61.42480173365628
                        },
                        {
                            "lat": 55.56959770493697,
                            "lon": 61.42651724757609
                        },
                        {
                            "lat": 55.56601637582355,
                            "lon": 61.426980293021984
                        },
                        {
                            "lat": 55.5650498727216,
                            "lon": 61.42526474625284
                        },
                        {
                            "lat": 55.56146859976781,
                            "lon": 61.42572766668139
                        },
                        {
                            "lat": 55.55788720497489,
                            "lon": 61.426190494935675
                        },
                        {
                            "lat": 55.55692117979133,
                            "lon": 61.42447487316662
                        },
                        {
                            "lat": 55.55595527640732,
                            "lon": 61.42275924208116
                        },
                        {
                            "lat": 55.55237411589967,
                            "lon": 61.42322191249999
                        },
                        {
                            "lat": 55.54879283363197,
                            "lon": 61.4236844907432
                        },
                        {
                            "lat": 55.54782740809841,
                            "lon": 61.421968784703886
                        },
                        {
                            "lat": 55.544246182156726,
                            "lon": 61.422431237953965
                        },
                        {
                            "lat": 55.54066483451291,
                            "lon": 61.422893599021506
                        },
                        {
                            "lat": 55.537083365185346,
                            "lon": 61.42335586790021
                        },
                        {
                            "lat": 55.533501774192445,
                            "lon": 61.42381804458386
                        },
                        {
                            "lat": 55.53253718270344,
                            "lon": 61.42210219804055
                        },
                        {
                            "lat": 55.5289556481974,
                            "lon": 61.42256424973739
                        },
                        {
                            "lat": 55.52537399208394,
                            "lon": 61.42302620923225
                        },
                        {
                            "lat": 55.52179221438144,
                            "lon": 61.423488076518865
                        },
                        {
                            "lat": 55.52082827892794,
                            "lon": 61.421772122355016
                        },
                        {
                            "lat": 55.51724655783362,
                            "lon": 61.42223386466183
                        },
                        {
                            "lat": 55.51821031510834,
                            "lon": 61.423949851591026
                        },
                        {
                            "lat": 55.51462829428306,
                            "lon": 61.42441153444244
                        },
                        {
                            "lat": 55.51366471520822,
                            "lon": 61.42269551475348
                        },
                        {
                            "lat": 55.51008275107017,
                            "lon": 61.42315707262375
                        },
                        {
                            "lat": 55.50650066543788,
                            "lon": 61.423618538266425
                        },
                        {
                            "lat": 55.505537564301974,
                            "lon": 61.42190244380915
                        },
                        {
                            "lat": 55.50457458462079,
                            "lon": 61.42018634008798
                        },
                        {
                            "lat": 55.5036117263732,
                            "lon": 61.41847022710444
                        },
                        {
                            "lat": 55.500030053944165,
                            "lon": 61.41893150230467
                        },
                        {
                            "lat": 55.49644826012104,
                            "lon": 61.41939268528152
                        },
                        {
                            "lat": 55.49741076193168,
                            "lon": 61.42110886372499
                        },
                        {
                            "lat": 55.493828668482806,
                            "lon": 61.421569987193834
                        },
                        {
                            "lat": 55.49024645365561,
                            "lon": 61.422031018421286
                        },
                        {
                            "lat": 55.48928430836626,
                            "lon": 61.42031477454015
                        },
                        {
                            "lat": 55.488322284415695,
                            "lon": 61.418598521411965
                        },
                        {
                            "lat": 55.48474030479473,
                            "lon": 61.419059394974276
                        },
                        {
                            "lat": 55.48115820387451,
                            "lon": 61.41952017629385
                        },
                        {
                            "lat": 55.477575981673475,
                            "lon": 61.41998086536449
                        },
                        {
                            "lat": 55.47853747073933,
                            "lon": 61.42169721659701
                        },
                        {
                            "lat": 55.474954948938894,
                            "lon": 61.42215784610275
                        },
                        {
                            "lat": 55.47399363821008,
                            "lon": 61.42044146217992
                        },
                        {
                            "lat": 55.47041117350281,
                            "lon": 61.42090196673397
                        },
                        {
                            "lat": 55.46945016235752,
                            "lon": 61.41918554089802
                        },
                        {
                            "lat": 55.46586775478553,
                            "lon": 61.41964592050872
                        },
                        {
                            "lat": 55.46228522602761,
                            "lon": 61.420106207851155
                        },
                        {
                            "lat": 55.45870257610227,
                            "lon": 61.42056640291905
                        },
                        {
                            "lat": 55.45966305210221,
                            "lon": 61.42228292676538
                        },
                        {
                            "lat": 55.45608010260399,
                            "lon": 61.42274306221149
                        },
                        {
                            "lat": 55.45511980502795,
                            "lon": 61.421026505706266
                        },
                        {
                            "lat": 55.451536912823144,
                            "lon": 61.4214865162065
                        },
                        {
                            "lat": 55.452497031954266,
                            "lon": 61.4232031053651
                        },
                        {
                            "lat": 55.448913840171485,
                            "lon": 61.423663056219944
                        },
                        {
                            "lat": 55.44795389950635,
                            "lon": 61.421946434413584
                        },
                        {
                            "lat": 55.44699407993475,
                            "lon": 61.42022980340091
                        },
                        {
                            "lat": 55.443411123993,
                            "lon": 61.42068959666982
                        },
                        {
                            "lat": 55.43982804699728,
                            "lon": 61.421149297638735
                        },
                        {
                            "lat": 55.436244848966105,
                            "lon": 61.4216089063014
                        },
                        {
                            "lat": 55.43720413306894,
                            "lon": 61.4233256352136
                        },
                        {
                            "lat": 55.43816353820977,
                            "lon": 61.42504235492971
                        },
                        {
                            "lat": 55.43457986207976,
                            "lon": 61.425501936527304
                        },
                        {
                            "lat": 55.43099606490921,
                            "lon": 61.425961425795016
                        },
                        {
                            "lat": 55.42741214671662,
                            "lon": 61.42642082272669
                        },
                        {
                            "lat": 55.42382810752053,
                            "lon": 61.42688012731605
                        },
                        {
                            "lat": 55.42286941670666,
                            "lon": 61.4251632771319
                        },
                        {
                            "lat": 55.421910846856704,
                            "lon": 61.42344641776532
                        },
                        {
                            "lat": 55.42095239794969,
                            "lon": 61.42172954921789
                        },
                        {
                            "lat": 55.417368773616026,
                            "lon": 61.4221886636599
                        },
                        {
                            "lat": 55.41641062420965,
                            "lon": 61.420471753340394
                        },
                        {
                            "lat": 55.412827057572386,
                            "lon": 61.42093074285731
                        },
                        {
                            "lat": 55.41378502837899,
                            "lon": 61.42264768576399
                        },
                        {
                            "lat": 55.41020116225712,
                            "lon": 61.42310661552394
                        },
                        {
                            "lat": 55.40661717526894,
                            "lon": 61.42356545293355
                        },
                        {
                            "lat": 55.40565956172492,
                            "lon": 61.42184844486926
                        },
                        {
                            "lat": 55.40470206900765,
                            "lon": 61.42013142764089
                        },
                        {
                            "lat": 55.40111831847878,
                            "lon": 61.42059010755654
                        },
                        {
                            "lat": 55.40207563255178,
                            "lon": 61.422307157351895
                        },
                        {
                            "lat": 55.40303306743299,
                            "lon": 61.424024197986554
                        },
                        {
                            "lat": 55.3994488387678,
                            "lon": 61.42448285067679
                        },
                        {
                            "lat": 55.398491582570415,
                            "lon": 61.422765777477345
                        },
                        {
                            "lat": 55.394907411799366,
                            "lon": 61.42322430523942
                        },
                        {
                            "lat": 55.3913231202572,
                            "lon": 61.423682740631904
                        },
                        {
                            "lat": 55.38773870796247,
                            "lon": 61.424141083648585
                        },
                        {
                            "lat": 55.38678210867657,
                            "lon": 61.42242390364133
                        },
                        {
                            "lat": 55.38319775439709,
                            "lon": 61.42288212173705
                        },
                        {
                            "lat": 55.379613279423154,
                            "lon": 61.42334024745018
                        },
                        {
                            "lat": 55.378657158332665,
                            "lon": 61.42162299323191
                        },
                        {
                            "lat": 55.382241454554915,
                            "lon": 61.42116490004872
                        },
                        {
                            "lat": 55.38128527538621,
                            "lon": 61.419447669219885
                        },
                        {
                            "lat": 55.3777011578971,
                            "lon": 61.41990572987652
                        },
                        {
                            "lat": 55.37411691977406,
                            "lon": 61.420363698155526
                        },
                        {
                            "lat": 55.37053256103566,
                            "lon": 61.42082157405077
                        },
                        {
                            "lat": 55.36957703872608,
                            "lon": 61.41910423653034
                        },
                        {
                            "lat": 55.365992738168394,
                            "lon": 61.41956198752927
                        },
                        {
                            "lat": 55.36240831705348,
                            "lon": 61.42001964613763
                        },
                        {
                            "lat": 55.35882377539989,
                            "lon": 61.42047721234921
                        },
                        {
                            "lat": 55.355239113226226,
                            "lon": 61.420934686157814
                        },
                        {
                            "lat": 55.3542844266539,
                            "lon": 61.419217209532135
                        },
                        {
                            "lat": 55.35069982282151,
                            "lon": 61.4196745584511
                        },
                        {
                            "lat": 55.3471150985272,
                            "lon": 61.420131814960335
                        },
                        {
                            "lat": 55.34806942739303,
                            "lon": 61.42184935654141
                        },
                        {
                            "lat": 55.34448440377069,
                            "lon": 61.42230655310396
                        },
                        {
                            "lat": 55.34089925970267,
                            "lon": 61.4227636572388
                        },
                        {
                            "lat": 55.37985365982757,
                            "lon": 61.430667336376814
                        },
                        {
                            "lat": 55.383438970753865,
                            "lon": 61.430209172921
                        },
                        {
                            "lat": 55.38439569543373,
                            "lon": 61.43192638143035
                        },
                        {
                            "lat": 55.38081020564189,
                            "lon": 61.432384577427584
                        },
                        {
                            "lat": 55.37985365982757,
                            "lon": 61.430667336376814
                        },
                        {
                            "lat": 55.411407056125974,
                            "lon": 61.43215035573897
                        },
                        {
                            "lat": 55.41044883813291,
                            "lon": 61.430433393518534
                        },
                        {
                            "lat": 55.41403353987368,
                            "lon": 61.42997442577087
                        },
                        {
                            "lat": 55.414991936601275,
                            "lon": 61.431691355398264
                        },
                        {
                            "lat": 55.411407056125974,
                            "lon": 61.43215035573897
                        },
                        {
                            "lat": 55.50099273398382,
                            "lon": 61.42064764802095
                        },
                        {
                            "lat": 55.50195553543866,
                            "lon": 61.42236378447833
                        },
                        {
                            "lat": 55.498373385139075,
                            "lon": 61.422825032912975
                        },
                        {
                            "lat": 55.49741076193168,
                            "lon": 61.42110886372499
                        },
                        {
                            "lat": 55.50099273398382,
                            "lon": 61.42064764802095
                        },
                        {
                            "lat": 55.35165433055109,
                            "lon": 61.42139206755728
                        },
                        {
                            "lat": 55.35260895876353,
                            "lon": 61.423109567553276
                        },
                        {
                            "lat": 55.353563707479836,
                            "lon": 61.424827058437515
                        },
                        {
                            "lat": 55.349978446538564,
                            "lon": 61.42528441238161
                        },
                        {
                            "lat": 55.34902387672317,
                            "lon": 61.42356688901564
                        },
                        {
                            "lat": 55.34806942739303,
                            "lon": 61.42184935654141
                        },
                        {
                            "lat": 55.35165433055109,
                            "lon": 61.42139206755728
                        },
                        {
                            "lat": 55.3571488478963,
                            "lon": 61.42436961206689
                        },
                        {
                            "lat": 55.36073386776933,
                            "lon": 61.42391207327585
                        },
                        {
                            "lat": 55.3616890947866,
                            "lon": 61.42562949005982
                        },
                        {
                            "lat": 55.358103896035985,
                            "lon": 61.426087061347154
                        },
                        {
                            "lat": 55.35905906474006,
                            "lon": 61.4278045015092
                        },
                        {
                            "lat": 55.35547356650789,
                            "lon": 61.42826201286465
                        },
                        {
                            "lat": 55.351887947708974,
                            "lon": 61.428719431782326
                        },
                        {
                            "lat": 55.35093313686018,
                            "lon": 61.42700192663769
                        },
                        {
                            "lat": 55.354518576720984,
                            "lon": 61.42654454020852
                        },
                        {
                            "lat": 55.353563707479836,
                            "lon": 61.424827058437515
                        },
                        {
                            "lat": 55.3571488478963,
                            "lon": 61.42436961206689
                        }
                    ],
                    [
                        {
                            "lat": 55.35641515186095,
                            "lon": 61.45757153604109
                        },
                        {
                            "lat": 55.36000399879908,
                            "lon": 61.45711387446421
                        },
                        {
                            "lat": 55.3590476151951,
                            "lon": 61.455396450244045
                        },
                        {
                            "lat": 55.35809135241715,
                            "lon": 61.453679016894434
                        },
                        {
                            "lat": 55.35450286405884,
                            "lon": 61.454136613422335
                        },
                        {
                            "lat": 55.35545894755621,
                            "lon": 61.45585407929472
                        },
                        {
                            "lat": 55.35641515186095,
                            "lon": 61.45757153604109
                        }
                    ],
                    [
                        {
                            "lat": 55.36119798623203,
                            "lon": 61.46615868282834
                        },
                        {
                            "lat": 55.36215491582307,
                            "lon": 61.467876084786
                        },
                        {
                            "lat": 55.363111966368926,
                            "lon": 61.46959347760677
                        },
                        {
                            "lat": 55.364069137890624,
                            "lon": 61.47131086128909
                        },
                        {
                            "lat": 55.365026430409245,
                            "lon": 61.47302823583139
                        },
                        {
                            "lat": 55.36598384394589,
                            "lon": 61.474745601232165
                        },
                        {
                            "lat": 55.36694137852163,
                            "lon": 61.47646295748974
                        },
                        {
                            "lat": 55.37053219898322,
                            "lon": 61.47600493789946
                        },
                        {
                            "lat": 55.371490034142425,
                            "lon": 61.47772225244525
                        },
                        {
                            "lat": 55.372447990401625,
                            "lon": 61.47943955784134
                        },
                        {
                            "lat": 55.368856810874846,
                            "lon": 61.479897642569426
                        },
                        {
                            "lat": 55.3698147086945,
                            "lon": 61.48161497138836
                        },
                        {
                            "lat": 55.37340606778192,
                            "lon": 61.48115685408625
                        },
                        {
                            "lat": 55.37436426630443,
                            "lon": 61.48287414117838
                        },
                        {
                            "lat": 55.37795568380784,
                            "lon": 61.48241589866818
                        },
                        {
                            "lat": 55.38154698012926,
                            "lon": 61.48195756353355
                        },
                        {
                            "lat": 55.38250565894773,
                            "lon": 61.48367477629275
                        },
                        {
                            "lat": 55.386097013603106,
                            "lon": 61.483216315942194
                        },
                        {
                            "lat": 55.38513815524998,
                            "lon": 61.48149913578072
                        },
                        {
                            "lat": 55.38417941811623,
                            "lon": 61.47978194645471
                        },
                        {
                            "lat": 55.387770292522745,
                            "lon": 61.47932345868984
                        },
                        {
                            "lat": 55.38872920915134,
                            "lon": 61.48104061541594
                        },
                        {
                            "lat": 55.39232014181467,
                            "lon": 61.48058200244538
                        },
                        {
                            "lat": 55.39327935917369,
                            "lon": 61.48229911739446
                        },
                        {
                            "lat": 55.39687035005157,
                            "lon": 61.48184037920978
                        },
                        {
                            "lat": 55.395910953221296,
                            "lon": 61.48012329687531
                        },
                        {
                            "lat": 55.39950164335258,
                            "lon": 61.479664498711955
                        },
                        {
                            "lat": 55.400461219632945,
                            "lon": 61.48138154842618
                        },
                        {
                            "lat": 55.404051967899164,
                            "lon": 61.48092262504989
                        },
                        {
                            "lat": 55.40309221218982,
                            "lon": 61.47920560796153
                        },
                        {
                            "lat": 55.4066826597144,
                            "lon": 61.478746624630276
                        },
                        {
                            "lat": 55.41027298590764,
                            "lon": 61.478287548724424
                        },
                        {
                            "lat": 55.41386319075095,
                            "lon": 61.47782838025017
                        },
                        {
                            "lat": 55.41290301825009,
                            "lon": 61.47611145188112
                        },
                        {
                            "lat": 55.41649292239904,
                            "lon": 61.47565222348967
                        },
                        {
                            "lat": 55.41745327422565,
                            "lon": 61.477369119213826
                        },
                        {
                            "lat": 55.42104323631313,
                            "lon": 61.47690976562157
                        },
                        {
                            "lat": 55.42463307699475,
                            "lon": 61.47645031947962
                        },
                        {
                            "lat": 55.42822279625187,
                            "lon": 61.475990780794255
                        },
                        {
                            "lat": 55.431812394065936,
                            "lon": 61.475531149571694
                        },
                        {
                            "lat": 55.435401870418275,
                            "lon": 61.47507142581818
                        },
                        {
                            "lat": 55.43899122529027,
                            "lon": 61.47461160953994
                        },
                        {
                            "lat": 55.439952774126404,
                            "lon": 61.47632830006077
                        },
                        {
                            "lat": 55.443542186695815,
                            "lon": 61.47586835857641
                        },
                        {
                            "lat": 55.444504036266956,
                            "lon": 61.47758500718864
                        },
                        {
                            "lat": 55.44809350649144,
                            "lon": 61.47712494048977
                        },
                        {
                            "lat": 55.44713147772656,
                            "lon": 61.47540832457421
                        },
                        {
                            "lat": 55.450720647199994,
                            "lon": 61.474948198060396
                        },
                        {
                            "lat": 55.45430969509758,
                            "lon": 61.47448797904123
                        },
                        {
                            "lat": 55.455272082186475,
                            "lon": 61.476204529546635
                        },
                        {
                            "lat": 55.45886118761983,
                            "lon": 61.47574418531486
                        },
                        {
                            "lat": 55.45982387547319,
                            "lon": 61.47746069387048
                        },
                        {
                            "lat": 55.46341303839997,
                            "lon": 61.47700022441769
                        },
                        {
                            "lat": 55.46245017141897,
                            "lon": 61.47528374858458
                        },
                        {
                            "lat": 55.46603903356531,
                            "lon": 61.47482321936206
                        },
                        {
                            "lat": 55.465076109149216,
                            "lon": 61.473106767013924
                        },
                        {
                            "lat": 55.464113306383325,
                            "lon": 61.47139030542421
                        },
                        {
                            "lat": 55.46052480239418,
                            "lon": 61.47185076920077
                        },
                        {
                            "lat": 55.459562300308036,
                            "lon": 61.47013426565322
                        },
                        {
                            "lat": 55.463150625246435,
                            "lon": 61.4696738345945
                        },
                        {
                            "lat": 55.466738828577,
                            "lon": 61.4692133110666
                        },
                        {
                            "lat": 55.46770168874345,
                            "lon": 61.470929749172875
                        },
                        {
                            "lat": 55.471289949456,
                            "lon": 61.470469100453
                        },
                        {
                            "lat": 55.47487808850241,
                            "lon": 61.47000835927083
                        },
                        {
                            "lat": 55.47846610586413,
                            "lon": 61.469547525632606
                        },
                        {
                            "lat": 55.477502708736,
                            "lon": 61.46783118573025
                        },
                        {
                            "lat": 55.481090425449615,
                            "lon": 61.467370292388075
                        },
                        {
                            "lat": 55.482054001522584,
                            "lon": 61.46908659954459
                        },
                        {
                            "lat": 55.485641775459264,
                            "lon": 61.46862558101304
                        },
                        {
                            "lat": 55.4892294276556,
                            "lon": 61.46816447004419
                        },
                        {
                            "lat": 55.48826549375647,
                            "lon": 61.46644822839613
                        },
                        {
                            "lat": 55.49185284531261,
                            "lon": 61.46598705775885
                        },
                        {
                            "lat": 55.49544007511256,
                            "lon": 61.46552579470238
                        },
                        {
                            "lat": 55.494475905246745,
                            "lon": 61.46380960931584
                        },
                        {
                            "lat": 55.498062834451545,
                            "lon": 61.46334828661682
                        },
                        {
                            "lat": 55.499027183137784,
                            "lon": 61.46506443923294
                        },
                        {
                            "lat": 55.502614169369735,
                            "lon": 61.464602991356834
                        },
                        {
                            "lat": 55.506201033789914,
                            "lon": 61.46414145108028
                        },
                        {
                            "lat": 55.50716586188366,
                            "lon": 61.465857528859246
                        },
                        {
                            "lat": 55.510752783248904,
                            "lon": 61.465395863397895
                        },
                        {
                            "lat": 55.50978777637981,
                            "lon": 61.46367981840952
                        },
                        {
                            "lat": 55.51337439712092,
                            "lon": 61.463218093350825
                        },
                        {
                            "lat": 55.51433958274416,
                            "lon": 61.464934105543016
                        },
                        {
                            "lat": 55.5179262603509,
                            "lon": 61.464472255300905
                        },
                        {
                            "lat": 55.51889174661404,
                            "lon": 61.4661882253998
                        },
                        {
                            "lat": 55.522478481044026,
                            "lon": 61.46572624996585
                        },
                        {
                            "lat": 55.52151281605066,
                            "lon": 61.46401031267776
                        },
                        {
                            "lat": 55.52509924982493,
                            "lon": 61.463548277679855
                        },
                        {
                            "lat": 55.526065093527315,
                            "lon": 61.46526418215158
                        },
                        {
                            "lat": 55.529651584045425,
                            "lon": 61.46480202196323
                        },
                        {
                            "lat": 55.52868556165525,
                            "lon": 61.463086150313494
                        },
                        {
                            "lat": 55.5322717515231,
                            "lon": 61.46262393058489
                        },
                        {
                            "lat": 55.53585781941001,
                            "lon": 61.462161618500325
                        },
                        {
                            "lat": 55.53944376529752,
                            "lon": 61.46169921406603
                        },
                        {
                            "lat": 55.54041032362378,
                            "lon": 61.46341498721635
                        },
                        {
                            "lat": 55.54399632609633,
                            "lon": 61.46295245759434
                        },
                        {
                            "lat": 55.54302958916716,
                            "lon": 61.46123671728833
                        },
                        {
                            "lat": 55.54661529100044,
                            "lon": 61.460774128173426
                        },
                        {
                            "lat": 55.55020087077894,
                            "lon": 61.46031144672761
                        },
                        {
                            "lat": 55.55116796485027,
                            "lon": 61.462027121328255
                        },
                        {
                            "lat": 55.55475360109474,
                            "lon": 61.461564314696744
                        },
                        {
                            "lat": 55.553786328484165,
                            "lon": 61.45984867295716
                        },
                        {
                            "lat": 55.55281917796531,
                            "lon": 61.45813302189165
                        },
                        {
                            "lat": 55.556404335079264,
                            "lon": 61.45767018866599
                        },
                        {
                            "lat": 55.559989370104304,
                            "lon": 61.45720726313381
                        },
                        {
                            "lat": 55.55902198471491,
                            "lon": 61.45549166846907
                        },
                        {
                            "lat": 55.5626067191939,
                            "lon": 61.455028683507486
                        },
                        {
                            "lat": 55.566191331568355,
                            "lon": 61.45456560625753
                        },
                        {
                            "lat": 55.567159073813855,
                            "lon": 61.45628113517489
                        },
                        {
                            "lat": 55.57074374246151,
                            "lon": 61.455817932760695
                        },
                        {
                            "lat": 55.571711785265876,
                            "lon": 61.457533419454485
                        },
                        {
                            "lat": 55.575296510144284,
                            "lon": 61.45707009186776
                        },
                        {
                            "lat": 55.57432828894652,
                            "lon": 61.45535463806504
                        },
                        {
                            "lat": 55.57791271325047,
                            "lon": 61.45489125109422
                        },
                        {
                            "lat": 55.57694443588004,
                            "lon": 61.453175820839704
                        },
                        {
                            "lat": 55.58052855965199,
                            "lon": 61.4527123744987
                        },
                        {
                            "lat": 55.58149701535493,
                            "lon": 61.45442777185445
                        },
                        {
                            "lat": 55.5850811952415,
                            "lon": 61.453964200352026
                        },
                        {
                            "lat": 55.58411256122729,
                            "lon": 61.452248835900626
                        },
                        {
                            "lat": 55.587696440587536,
                            "lon": 61.45178520505174
                        },
                        {
                            "lat": 55.59128019771433,
                            "lon": 61.45132148195833
                        },
                        {
                            "lat": 55.59031132939319,
                            "lon": 61.44960617397172
                        },
                        {
                            "lat": 55.593894786038966,
                            "lon": 61.449142391557665
                        },
                        {
                            "lat": 55.592925861737655,
                            "lon": 61.44742710712621
                        },
                        {
                            "lat": 55.58934258330261,
                            "lon": 61.44789085662612
                        },
                        {
                            "lat": 55.585759182658194,
                            "lon": 61.44835451389891
                        },
                        {
                            "lat": 55.58217565982282,
                            "lon": 61.44881807893832
                        },
                        {
                            "lat": 55.57859201481483,
                            "lon": 61.449281551738046
                        },
                        {
                            "lat": 55.575008247652676,
                            "lon": 61.44974493229185
                        },
                        {
                            "lat": 55.57404033675329,
                            "lon": 61.448029474001665
                        },
                        {
                            "lat": 55.57045662575385,
                            "lon": 61.44849272942038
                        },
                        {
                            "lat": 55.56687279265826,
                            "lon": 61.44895589258619
                        },
                        {
                            "lat": 55.56328883748491,
                            "lon": 61.44941896349282
                        },
                        {
                            "lat": 55.564256213425104,
                            "lon": 61.45113452041489
                        },
                        {
                            "lat": 55.56067195783025,
                            "lon": 61.451597531922246
                        },
                        {
                            "lat": 55.55970476025224,
                            "lon": 61.44988194213403
                        },
                        {
                            "lat": 55.556120560978655,
                            "lon": 61.45034482850353
                        },
                        {
                            "lat": 55.5570875801733,
                            "lon": 61.45206045115233
                        },
                        {
                            "lat": 55.55350308047264,
                            "lon": 61.45252327809886
                        },
                        {
                            "lat": 55.55253623968259,
                            "lon": 61.45080762259504
                        },
                        {
                            "lat": 55.54895179638246,
                            "lon": 61.45127032440233
                        },
                        {
                            "lat": 55.54991845874671,
                            "lon": 61.452986012755595
                        },
                        {
                            "lat": 55.546333715013944,
                            "lon": 61.45344865511624
                        },
                        {
                            "lat": 55.5427488492928,
                            "lon": 61.45391120517457
                        },
                        {
                            "lat": 55.541782543843766,
                            "lon": 61.45219545113921
                        },
                        {
                            "lat": 55.54081636034347,
                            "lon": 61.450479687792715
                        },
                        {
                            "lat": 55.53723172961276,
                            "lon": 61.4509420798805
                        },
                        {
                            "lat": 55.533646976972975,
                            "lon": 61.45140437966458
                        },
                        {
                            "lat": 55.532681272326435,
                            "lon": 61.44968854136254
                        },
                        {
                            "lat": 55.52909657629089,
                            "lon": 61.45015071602198
                        },
                        {
                            "lat": 55.53006210244253,
                            "lon": 61.451866587138696
                        },
                        {
                            "lat": 55.52647710603989,
                            "lon": 61.45232870229661
                        },
                        {
                            "lat": 55.522891987783545,
                            "lon": 61.45279072513205
                        },
                        {
                            "lat": 55.519306747691914,
                            "lon": 61.45325265563879
                        },
                        {
                            "lat": 55.51572138578352,
                            "lon": 61.45371449381054
                        },
                        {
                            "lat": 55.5121359020768,
                            "lon": 61.45417623964105
                        },
                        {
                            "lat": 55.51117126871745,
                            "lon": 61.452460204534745
                        },
                        {
                            "lat": 55.507585841852894,
                            "lon": 61.45292182523661
                        },
                        {
                            "lat": 55.5066215088557,
                            "lon": 61.451205748072134
                        },
                        {
                            "lat": 55.50303613887562,
                            "lon": 61.45166724365369
                        },
                        {
                            "lat": 55.50207210620361,
                            "lon": 61.44995112444507
                        },
                        {
                            "lat": 55.49848679315041,
                            "lon": 61.45041249491472
                        },
                        {
                            "lat": 55.499450647195054,
                            "lon": 61.45212864688648
                        },
                        {
                            "lat": 55.49586503383245,
                            "lon": 61.45258995776415
                        },
                        {
                            "lat": 55.4949013584363,
                            "lon": 61.450873773034886
                        },
                        {
                            "lat": 55.491315802079846,
                            "lon": 61.45133495879933
                        },
                        {
                            "lat": 55.48773012409949,
                            "lon": 61.451796052201814
                        },
                        {
                            "lat": 55.4867669276692,
                            "lon": 61.45007979271752
                        },
                        {
                            "lat": 55.48318130677695,
                            "lon": 61.45054076101445
                        },
                        {
                            "lat": 55.48221841060587,
                            "lon": 61.44882445954112
                        },
                        {
                            "lat": 55.478632846844015,
                            "lon": 61.44928530274088
                        },
                        {
                            "lat": 55.47504716155608,
                            "lon": 61.44974605357112
                        },
                        {
                            "lat": 55.476009700313774,
                            "lon": 61.451462420495496
                        },
                        {
                            "lat": 55.47242371477988,
                            "lon": 61.45192311166713
                        },
                        {
                            "lat": 55.47338619630939,
                            "lon": 61.45363950206719
                        },
                        {
                            "lat": 55.46979991048727,
                            "lon": 61.454100133566236
                        },
                        {
                            "lat": 55.466213503152346,
                            "lon": 61.4545606726658
                        },
                        {
                            "lat": 55.467175748598905,
                            "lon": 61.456277119254075
                        },
                        {
                            "lat": 55.46358904093087,
                            "lon": 61.45673759865519
                        },
                        {
                            "lat": 55.46262697432318,
                            "lon": 61.455021119359714
                        },
                        {
                            "lat": 55.45904032401829,
                            "lon": 61.455481473641754
                        },
                        {
                            "lat": 55.45807855772777,
                            "lon": 61.45376495241538
                        },
                        {
                            "lat": 55.4571169128733,
                            "lon": 61.452048421961315
                        },
                        {
                            "lat": 55.45353049881785,
                            "lon": 61.452508618443474
                        },
                        {
                            "lat": 55.4525691542038,
                            "lon": 61.45079204607756
                        },
                        {
                            "lat": 55.44898279759856,
                            "lon": 61.45125211746642
                        },
                        {
                            "lat": 55.44994396336599,
                            "lon": 61.45296872251246
                        },
                        {
                            "lat": 55.450905250511255,
                            "lon": 61.45468531833923
                        },
                        {
                            "lat": 55.45449196482827,
                            "lon": 61.45422518158669
                        },
                        {
                            "lat": 55.4554535522562,
                            "lon": 61.45594173550565
                        },
                        {
                            "lat": 55.451866659055476,
                            "lon": 61.456401904945146
                        },
                        {
                            "lat": 55.44827964443467,
                            "lon": 61.456861981954056
                        },
                        {
                            "lat": 55.44731841479528,
                            "lon": 61.45514536266676
                        },
                        {
                            "lat": 55.44373145769888,
                            "lon": 61.45560531456305
                        },
                        {
                            "lat": 55.4446925084123,
                            "lon": 61.457321966526116
                        },
                        {
                            "lat": 55.441105251006995,
                            "lon": 61.457781858655096
                        },
                        {
                            "lat": 55.44014437924064,
                            "lon": 61.45606517402185
                        },
                        {
                            "lat": 55.436557179439106,
                            "lon": 61.45652494103695
                        },
                        {
                            "lat": 55.43559660796569,
                            "lon": 61.454808214532
                        },
                        {
                            "lat": 55.43200946581018,
                            "lon": 61.4552678564416
                        },
                        {
                            "lat": 55.43296985831285,
                            "lon": 61.45698461560211
                        },
                        {
                            "lat": 55.43393037212167,
                            "lon": 61.45870136555888
                        },
                        {
                            "lat": 55.43751787223725,
                            "lon": 61.45824165833476
                        },
                        {
                            "lat": 55.43847868638123,
                            "lon": 61.45995836642385
                        },
                        {
                            "lat": 55.434891007257754,
                            "lon": 61.460418106310364
                        },
                        {
                            "lat": 55.43130320678592,
                            "lon": 61.46087775372949
                        },
                        {
                            "lat": 55.42771528498424,
                            "lon": 61.46133730867499
                        },
                        {
                            "lat": 55.424127241871375,
                            "lon": 61.461796771140655
                        },
                        {
                            "lat": 55.42316714388569,
                            "lon": 61.46007993243566
                        },
                        {
                            "lat": 55.41957915857254,
                            "lon": 61.46053926977528
                        },
                        {
                            "lat": 55.42053907746586,
                            "lon": 61.46225614112024
                        },
                        {
                            "lat": 55.41695079178631,
                            "lon": 61.46271541860754
                        },
                        {
                            "lat": 55.41336238485135,
                            "lon": 61.46317460359628
                        },
                        {
                            "lat": 55.41240282420608,
                            "lon": 61.461457666988224
                        },
                        {
                            "lat": 55.41144338477654,
                            "lon": 61.459740721195416
                        },
                        {
                            "lat": 55.407855214897495,
                            "lon": 61.46019974843656
                        },
                        {
                            "lat": 55.40881447518997,
                            "lon": 61.46191672684908
                        },
                        {
                            "lat": 55.405226004976754,
                            "lon": 61.462375694204574
                        },
                        {
                            "lat": 55.40163741358502,
                            "lon": 61.46283456904845
                        },
                        {
                            "lat": 55.39804870103344,
                            "lon": 61.46329335137451
                        },
                        {
                            "lat": 55.39900754492931,
                            "lon": 61.4650104184414
                        },
                        {
                            "lat": 55.39541853199634,
                            "lon": 61.46546914084407
                        },
                        {
                            "lat": 55.394459867340565,
                            "lon": 61.4637520411765
                        },
                        {
                            "lat": 55.39087091252507,
                            "lon": 61.46421063844825
                        },
                        {
                            "lat": 55.38728183660557,
                            "lon": 61.46466914318349
                        },
                        {
                            "lat": 55.386323651578635,
                            "lon": 61.46295196917062
                        },
                        {
                            "lat": 55.382734633858504,
                            "lon": 61.463410348782745
                        },
                        {
                            "lat": 55.37914549509273,
                            "lon": 61.46386863585156
                        },
                        {
                            "lat": 55.37555623529998,
                            "lon": 61.46432683037083
                        },
                        {
                            "lat": 55.37459870919886,
                            "lon": 61.46260954948462
                        },
                        {
                            "lat": 55.373641304085,
                            "lon": 61.46089225945094
                        },
                        {
                            "lat": 55.37005228192391,
                            "lon": 61.46135029629764
                        },
                        {
                            "lat": 55.36646313881525,
                            "lon": 61.461808240593626
                        },
                        {
                            "lat": 55.365506213272724,
                            "lon": 61.46009087631309
                        },
                        {
                            "lat": 55.36191712856933,
                            "lon": 61.460548695510084
                        },
                        {
                            "lat": 55.36096050325014,
                            "lon": 61.4588312895534
                        },
                        {
                            "lat": 55.35737147699413,
                            "lon": 61.45928898365987
                        },
                        {
                            "lat": 55.35832792297676,
                            "lon": 61.46100642214955
                        },
                        {
                            "lat": 55.35928448982992,
                            "lon": 61.462723851508564
                        },
                        {
                            "lat": 55.36024117757464,
                            "lon": 61.46444127173533
                        },
                        {
                            "lat": 55.36119798623203,
                            "lon": 61.46615868282834
                        },
                        {
                            "lat": 55.38681149711106,
                            "lon": 61.47760629279729
                        },
                        {
                            "lat": 55.39040207084562,
                            "lon": 61.47714774503445
                        },
                        {
                            "lat": 55.391361045712365,
                            "lon": 61.47886487832486
                        },
                        {
                            "lat": 55.387770292522745,
                            "lon": 61.47932345868984
                        },
                        {
                            "lat": 55.38681149711106,
                            "lon": 61.47760629279729
                        },
                        {
                            "lat": 55.41553269193848,
                            "lon": 61.47393531857164
                        },
                        {
                            "lat": 55.414572582822885,
                            "lon": 61.47221840446134
                        },
                        {
                            "lat": 55.41816200705233,
                            "lon": 61.471759148810385
                        },
                        {
                            "lat": 55.419122295435315,
                            "lon": 61.473476030276984
                        },
                        {
                            "lat": 55.42008270518187,
                            "lon": 61.47519290254791
                        },
                        {
                            "lat": 55.41649292239904,
                            "lon": 61.47565222348967
                        },
                        {
                            "lat": 55.41553269193848,
                            "lon": 61.47393531857164
                        },
                        {
                            "lat": 55.41098303726962,
                            "lon": 61.47267756757327
                        },
                        {
                            "lat": 55.41002322874772,
                            "lon": 61.47096061163761
                        },
                        {
                            "lat": 55.40906354150989,
                            "lon": 61.469243646516226
                        },
                        {
                            "lat": 55.41265272854199,
                            "lon": 61.46878454867014
                        },
                        {
                            "lat": 55.41361259503108,
                            "lon": 61.47050148116033
                        },
                        {
                            "lat": 55.414572582822885,
                            "lon": 61.47221840446134
                        },
                        {
                            "lat": 55.41098303726962,
                            "lon": 61.47267756757327
                        },
                        {
                            "lat": 55.40547423321484,
                            "lon": 61.46970265182826
                        },
                        {
                            "lat": 55.4045148464937,
                            "lon": 61.46798564490053
                        },
                        {
                            "lat": 55.408103975534985,
                            "lon": 61.467526672210674
                        },
                        {
                            "lat": 55.40906354150989,
                            "lon": 61.469243646516226
                        },
                        {
                            "lat": 55.40547423321484,
                            "lon": 61.46970265182826
                        },
                        {
                            "lat": 55.46148742609076,
                            "lon": 61.473567263511754
                        },
                        {
                            "lat": 55.45789862140069,
                            "lon": 61.47402766752291
                        },
                        {
                            "lat": 55.45693617679459,
                            "lon": 61.472311140496295
                        },
                        {
                            "lat": 55.46052480239418,
                            "lon": 61.47185076920077
                        },
                        {
                            "lat": 55.46148742609076,
                            "lon": 61.473567263511754
                        },
                        {
                            "lat": 55.47959556431897,
                            "lon": 61.45100163694252
                        },
                        {
                            "lat": 55.48055840334114,
                            "lon": 61.4527179618959
                        },
                        {
                            "lat": 55.4769723606002,
                            "lon": 61.45317877817502
                        },
                        {
                            "lat": 55.476009700313774,
                            "lon": 61.451462420495496
                        },
                        {
                            "lat": 55.47959556431897,
                            "lon": 61.45100163694252
                        },
                        {
                            "lat": 55.41432206673343,
                            "lon": 61.464891531018
                        },
                        {
                            "lat": 55.410733359387336,
                            "lon": 61.46535065612855
                        },
                        {
                            "lat": 55.40977385667955,
                            "lon": 61.46363369608028
                        },
                        {
                            "lat": 55.41336238485135,
                            "lon": 61.46317460359628
                        },
                        {
                            "lat": 55.41432206673343,
                            "lon": 61.464891531018
                        },
                        {
                            "lat": 55.45238528568199,
                            "lon": 61.47105485033815
                        },
                        {
                            "lat": 55.45142326331297,
                            "lon": 61.469338272143624
                        },
                        {
                            "lat": 55.45501165233682,
                            "lon": 61.468878058745176
                        },
                        {
                            "lat": 55.45597385378036,
                            "lon": 61.47059460423651
                        },
                        {
                            "lat": 55.45238528568199,
                            "lon": 61.47105485033815
                        },
                        {
                            "lat": 55.38824014271787,
                            "lon": 61.46638630803554
                        },
                        {
                            "lat": 55.39182939791965,
                            "lon": 61.465927770710884
                        },
                        {
                            "lat": 55.39278800443933,
                            "lon": 61.46764489380769
                        },
                        {
                            "lat": 55.38919856993661,
                            "lon": 61.46810346372523
                        },
                        {
                            "lat": 55.38824014271787,
                            "lon": 61.46638630803554
                        },
                        {
                            "lat": 55.46813811556084,
                            "lon": 61.45799355660465
                        },
                        {
                            "lat": 55.46910060405933,
                            "lon": 61.459709984715964
                        },
                        {
                            "lat": 55.46551353865797,
                            "lon": 61.46017052954176
                        },
                        {
                            "lat": 55.46455122903542,
                            "lon": 61.45845406871641
                        },
                        {
                            "lat": 55.46813811556084,
                            "lon": 61.45799355660465
                        },
                        {
                            "lat": 55.45737709144911,
                            "lon": 61.45937481566438
                        },
                        {
                            "lat": 55.456415261122764,
                            "lon": 61.45765828019871
                        },
                        {
                            "lat": 55.46000221176598,
                            "lon": 61.457197985638814
                        },
                        {
                            "lat": 55.46096422099199,
                            "lon": 61.45891448840505
                        },
                        {
                            "lat": 55.45737709144911,
                            "lon": 61.45937481566438
                        }
                    ],
                    [
                        {
                            "lat": 55.387496732506,
                            "lon": 61.41681409589398
                        },
                        {
                            "lat": 55.38845314838842,
                            "lon": 61.418531270798674
                        },
                        {
                            "lat": 55.392036903864415,
                            "lon": 61.41807293304654
                        },
                        {
                            "lat": 55.39108030933038,
                            "lon": 61.416355790681756
                        },
                        {
                            "lat": 55.387496732506,
                            "lon": 61.41681409589398
                        }
                    ],
                    [
                        {
                            "lat": 55.39562053863228,
                            "lon": 61.417614502941575
                        },
                        {
                            "lat": 55.396577432523635,
                            "lon": 61.41933160360816
                        },
                        {
                            "lat": 55.400161125193,
                            "lon": 61.41887304860205
                        },
                        {
                            "lat": 55.39920405267344,
                            "lon": 61.417155980490016
                        },
                        {
                            "lat": 55.39562053863228,
                            "lon": 61.417614502941575
                        }
                    ],
                    [
                        {
                            "lat": 55.397066324881415,
                            "lon": 61.433985433194046
                        },
                        {
                            "lat": 55.39802394860479,
                            "lon": 61.43570251657088
                        },
                        {
                            "lat": 55.401609491617535,
                            "lon": 61.435243853231654
                        },
                        {
                            "lat": 55.40065168905695,
                            "lon": 61.43352680242888
                        },
                        {
                            "lat": 55.397066324881415,
                            "lon": 61.433985433194046
                        }
                    ],
                    [
                        {
                            "lat": 55.41811796993715,
                            "lon": 61.44416883433816
                        },
                        {
                            "lat": 55.41907715614278,
                            "lon": 61.44588572312732
                        },
                        {
                            "lat": 55.42266346716205,
                            "lon": 61.44542646191918
                        },
                        {
                            "lat": 55.42170410207347,
                            "lon": 61.443709605750406
                        },
                        {
                            "lat": 55.41811796993715,
                            "lon": 61.44416883433816
                        }
                    ],
                    [
                        {
                            "lat": 55.42529011309097,
                            "lon": 61.443250284745105
                        },
                        {
                            "lat": 55.42624965704144,
                            "lon": 61.444967108287905
                        },
                        {
                            "lat": 55.42983572576233,
                            "lon": 61.444507662239666
                        },
                        {
                            "lat": 55.433421673306206,
                            "lon": 61.44404812378071
                        },
                        {
                            "lat": 55.432461771695216,
                            "lon": 61.44233136550671
                        },
                        {
                            "lat": 55.42887600297107,
                            "lon": 61.442790871328484
                        },
                        {
                            "lat": 55.42529011309097,
                            "lon": 61.443250284745105
                        }
                    ],
                    [
                        {
                            "lat": 55.43604741924484,
                            "lon": 61.44187176728603
                        },
                        {
                            "lat": 55.437007499654484,
                            "lon": 61.44358849291725
                        },
                        {
                            "lat": 55.440593204788634,
                            "lon": 61.443128769655544
                        },
                        {
                            "lat": 55.43963294560144,
                            "lon": 61.441412076672705
                        },
                        {
                            "lat": 55.43604741924484,
                            "lon": 61.44187176728603
                        }
                    ],
                    [
                        {
                            "lat": 55.44347470983063,
                            "lon": 61.448278793359
                        },
                        {
                            "lat": 55.44443545407509,
                            "lon": 61.449995449506595
                        },
                        {
                            "lat": 55.44802175318787,
                            "lon": 61.44953550320264
                        },
                        {
                            "lat": 55.447060830112775,
                            "lon": 61.44781887972272
                        },
                        {
                            "lat": 55.44347470983063,
                            "lon": 61.448278793359
                        }
                    ],
                    [
                        {
                            "lat": 55.451091644096756,
                            "lon": 61.43442264811216
                        },
                        {
                            "lat": 55.45205225438948,
                            "lon": 61.43613923809646
                        },
                        {
                            "lat": 55.45563681721416,
                            "lon": 61.435679150955636
                        },
                        {
                            "lat": 55.45467602830487,
                            "lon": 61.43396259363745
                        },
                        {
                            "lat": 55.453715360636515,
                            "lon": 61.43224602710196
                        },
                        {
                            "lat": 55.450131155026455,
                            "lon": 61.43270604891398
                        },
                        {
                            "lat": 55.451091644096756,
                            "lon": 61.43442264811216
                        }
                    ],
                    [
                        {
                            "lat": 55.46236573609747,
                            "lon": 61.44769479396707
                        },
                        {
                            "lat": 55.45877992243836,
                            "lon": 61.44815510986645
                        },
                        {
                            "lat": 55.45781846299802,
                            "lon": 61.44643858442318
                        },
                        {
                            "lat": 55.454232706715004,
                            "lon": 61.44689877524857
                        },
                        {
                            "lat": 55.455193987388036,
                            "lon": 61.44861533337635
                        },
                        {
                            "lat": 55.456155389433775,
                            "lon": 61.45033188228113
                        },
                        {
                            "lat": 55.45974150326994,
                            "lon": 61.44987162608334
                        },
                        {
                            "lat": 55.46332749569376,
                            "lon": 61.44941127749045
                        },
                        {
                            "lat": 55.46236573609747,
                            "lon": 61.44769479396707
                        }
                    ],
                    [
                        {
                            "lat": 55.47242371477988,
                            "lon": 61.45192311166713
                        },
                        {
                            "lat": 55.47146135476055,
                            "lon": 61.45020671202563
                        },
                        {
                            "lat": 55.470499116230265,
                            "lon": 61.44849030314428
                        },
                        {
                            "lat": 55.466913366686704,
                            "lon": 61.44895083650869
                        },
                        {
                            "lat": 55.46332749569376,
                            "lon": 61.44941127749045
                        },
                        {
                            "lat": 55.46428937672092,
                            "lon": 61.4511277517824
                        },
                        {
                            "lat": 55.46787542647599,
                            "lon": 61.450667278098116
                        },
                        {
                            "lat": 55.4688376077358,
                            "lon": 61.45238371045119
                        },
                        {
                            "lat": 55.47242371477988,
                            "lon": 61.45192311166713
                        }
                    ],
                    [
                        {
                            "lat": 55.46525137920007,
                            "lon": 61.45284421684137
                        },
                        {
                            "lat": 55.461665029191224,
                            "lon": 61.453304630831525
                        },
                        {
                            "lat": 55.46262697432318,
                            "lon": 61.455021119359714
                        },
                        {
                            "lat": 55.466213503152346,
                            "lon": 61.4545606726658
                        },
                        {
                            "lat": 55.46525137920007,
                            "lon": 61.45284421684137
                        }
                    ],
                    [
                        {
                            "lat": 55.4589617223256,
                            "lon": 61.42789267643253
                        },
                        {
                            "lat": 55.45992250463415,
                            "lon": 61.42960919606944
                        },
                        {
                            "lat": 55.463506168012756,
                            "lon": 61.42914892995335
                        },
                        {
                            "lat": 55.462545207206375,
                            "lon": 61.42743244298904
                        },
                        {
                            "lat": 55.4589617223256,
                            "lon": 61.42789267643253
                        }
                    ],
                    [
                        {
                            "lat": 55.4716345700187,
                            "lon": 61.429944533172424
                        },
                        {
                            "lat": 55.468050970693,
                            "lon": 61.43040501658682
                        },
                        {
                            "lat": 55.46901235254278,
                            "lon": 61.43212145241567
                        },
                        {
                            "lat": 55.472596130361126,
                            "lon": 61.431660936310614
                        },
                        {
                            "lat": 55.4716345700187,
                            "lon": 61.429944533172424
                        }
                    ],
                    [
                        {
                            "lat": 55.49544007511256,
                            "lon": 61.46552579470238
                        },
                        {
                            "lat": 55.496404366753126,
                            "lon": 61.46724197081956
                        },
                        {
                            "lat": 55.49999165361728,
                            "lon": 61.466780582576334
                        },
                        {
                            "lat": 55.499027183137784,
                            "lon": 61.46506443923294
                        },
                        {
                            "lat": 55.49544007511256,
                            "lon": 61.46552579470238
                        }
                    ],
                    [
                        {
                            "lat": 55.4892294276556,
                            "lon": 61.46816447004419
                        },
                        {
                            "lat": 55.49019348331361,
                            "lon": 61.46988070242821
                        },
                        {
                            "lat": 55.49378119265091,
                            "lon": 61.46941946626223
                        },
                        {
                            "lat": 55.49281695809306,
                            "lon": 61.467703266644264
                        },
                        {
                            "lat": 55.4892294276556,
                            "lon": 61.46816447004419
                        }
                    ],
                    [
                        {
                            "lat": 55.48660565219624,
                            "lon": 61.470341846157524
                        },
                        {
                            "lat": 55.487569650694745,
                            "lon": 61.47205810203985
                        },
                        {
                            "lat": 55.491157660751675,
                            "lon": 61.471596925546606
                        },
                        {
                            "lat": 55.49019348331361,
                            "lon": 61.46988070242821
                        },
                        {
                            "lat": 55.48660565219624,
                            "lon": 61.470341846157524
                        }
                    ],
                    [
                        {
                            "lat": 55.475653367307736,
                            "lon": 61.41654813519523
                        },
                        {
                            "lat": 55.476614613869955,
                            "lon": 61.41826450489668
                        },
                        {
                            "lat": 55.48019665777347,
                            "lon": 61.4178038485185
                        },
                        {
                            "lat": 55.47923523293218,
                            "lon": 61.41608751150606
                        },
                        {
                            "lat": 55.475653367307736,
                            "lon": 61.41654813519523
                        }
                    ],
                    [
                        {
                            "lat": 55.476614613869955,
                            "lon": 61.41826450489668
                        },
                        {
                            "lat": 55.47303244872516,
                            "lon": 61.41872506902526
                        },
                        {
                            "lat": 55.47399363821008,
                            "lon": 61.42044146217992
                        },
                        {
                            "lat": 55.477575981673475,
                            "lon": 61.41998086536449
                        },
                        {
                            "lat": 55.476614613869955,
                            "lon": 61.41826450489668
                        }
                    ],
                    [
                        {
                            "lat": 55.50607874361302,
                            "lon": 61.436554053029916
                        },
                        {
                            "lat": 55.50704246034082,
                            "lon": 61.43827013884752
                        },
                        {
                            "lat": 55.51062621569735,
                            "lon": 61.43780859568323
                        },
                        {
                            "lat": 55.5096623205561,
                            "lon": 61.43609254262706
                        },
                        {
                            "lat": 55.50607874361302,
                            "lon": 61.436554053029916
                        }
                    ],
                    [
                        {
                            "lat": 55.525649172112765,
                            "lon": 61.43035180035067
                        },
                        {
                            "lat": 55.526613715630546,
                            "lon": 61.43206770809484
                        },
                        {
                            "lat": 55.5301963843976,
                            "lon": 61.431605676902215
                        },
                        {
                            "lat": 55.52923166263007,
                            "lon": 61.4298898019461
                        },
                        {
                            "lat": 55.525649172112765,
                            "lon": 61.43035180035067
                        }
                    ],
                    [
                        {
                            "lat": 55.508698547020046,
                            "lon": 61.43437648029776
                        },
                        {
                            "lat": 55.5096623205561,
                            "lon": 61.43609254262706
                        },
                        {
                            "lat": 55.513245775893985,
                            "lon": 61.43563093994504
                        },
                        {
                            "lat": 55.51228182398405,
                            "lon": 61.433914910379364
                        },
                        {
                            "lat": 55.508698547020046,
                            "lon": 61.43437648029776
                        }
                    ]
                ]
            },
            {
                "type": "MultiPolygon",
                "value": 0.97,
                "data": [
                    [
                        {
                            "lat": 55.34853536544282,
                            "lon": 61.43650418162151
                        },
                        {
                            "lat": 55.34949042111688,
                            "lon": 61.43822170618
                        },
                        {
                            "lat": 55.35307717617315,
                            "lon": 61.43776430971909
                        },
                        {
                            "lat": 55.35403253155486,
                            "lon": 61.439481792665994
                        },
                        {
                            "lat": 55.354988007592034,
                            "lon": 61.44119926649399
                        },
                        {
                            "lat": 55.351400894333544,
                            "lon": 61.44165672795369
                        },
                        {
                            "lat": 55.35235631191812,
                            "lon": 61.443374225165805
                        },
                        {
                            "lat": 55.353311850181576,
                            "lon": 61.44509171325936
                        },
                        {
                            "lat": 55.35689932171683,
                            "lon": 61.44463418678722
                        },
                        {
                            "lat": 55.35785515984647,
                            "lon": 61.446351633249336
                        },
                        {
                            "lat": 55.35426750914488,
                            "lon": 61.4468091922328
                        },
                        {
                            "lat": 55.35522328882907,
                            "lon": 61.44852666208458
                        },
                        {
                            "lat": 55.356179189255165,
                            "lon": 61.45024412281314
                        },
                        {
                            "lat": 55.35259105940208,
                            "lon": 61.45070165430574
                        },
                        {
                            "lat": 55.35354690134781,
                            "lon": 61.452419138425476
                        },
                        {
                            "lat": 55.35450286405884,
                            "lon": 61.454136613422335
                        },
                        {
                            "lat": 55.35809135241715,
                            "lon": 61.453679016894434
                        },
                        {
                            "lat": 55.3590476151951,
                            "lon": 61.455396450244045
                        },
                        {
                            "lat": 55.36000399879908,
                            "lon": 61.45711387446421
                        },
                        {
                            "lat": 55.35641515186095,
                            "lon": 61.45757153604109
                        },
                        {
                            "lat": 55.35737147699413,
                            "lon": 61.45928898365987
                        },
                        {
                            "lat": 55.36096050325014,
                            "lon": 61.4588312895534
                        },
                        {
                            "lat": 55.36191712856933,
                            "lon": 61.460548695510084
                        },
                        {
                            "lat": 55.365506213272724,
                            "lon": 61.46009087631309
                        },
                        {
                            "lat": 55.36646313881525,
                            "lon": 61.461808240593626
                        },
                        {
                            "lat": 55.37005228192391,
                            "lon": 61.46135029629764
                        },
                        {
                            "lat": 55.373641304085,
                            "lon": 61.46089225945094
                        },
                        {
                            "lat": 55.37459870919886,
                            "lon": 61.46260954948462
                        },
                        {
                            "lat": 55.37555623529998,
                            "lon": 61.46432683037083
                        },
                        {
                            "lat": 55.37914549509273,
                            "lon": 61.46386863585156
                        },
                        {
                            "lat": 55.382734633858504,
                            "lon": 61.463410348782745
                        },
                        {
                            "lat": 55.386323651578635,
                            "lon": 61.46295196917062
                        },
                        {
                            "lat": 55.38728183660557,
                            "lon": 61.46466914318349
                        },
                        {
                            "lat": 55.39087091252507,
                            "lon": 61.46421063844825
                        },
                        {
                            "lat": 55.394459867340565,
                            "lon": 61.4637520411765
                        },
                        {
                            "lat": 55.39541853199634,
                            "lon": 61.46546914084407
                        },
                        {
                            "lat": 55.39900754492931,
                            "lon": 61.4650104184414
                        },
                        {
                            "lat": 55.39804870103344,
                            "lon": 61.46329335137451
                        },
                        {
                            "lat": 55.40163741358502,
                            "lon": 61.46283456904845
                        },
                        {
                            "lat": 55.405226004976754,
                            "lon": 61.462375694204574
                        },
                        {
                            "lat": 55.40881447518997,
                            "lon": 61.46191672684908
                        },
                        {
                            "lat": 55.407855214897495,
                            "lon": 61.46019974843656
                        },
                        {
                            "lat": 55.41144338477654,
                            "lon": 61.459740721195416
                        },
                        {
                            "lat": 55.41240282420608,
                            "lon": 61.461457666988224
                        },
                        {
                            "lat": 55.41336238485135,
                            "lon": 61.46317460359628
                        },
                        {
                            "lat": 55.41695079178631,
                            "lon": 61.46271541860754
                        },
                        {
                            "lat": 55.42053907746586,
                            "lon": 61.46225614112024
                        },
                        {
                            "lat": 55.41957915857254,
                            "lon": 61.46053926977528
                        },
                        {
                            "lat": 55.42316714388569,
                            "lon": 61.46007993243566
                        },
                        {
                            "lat": 55.424127241871375,
                            "lon": 61.461796771140655
                        },
                        {
                            "lat": 55.42771528498424,
                            "lon": 61.46133730867499
                        },
                        {
                            "lat": 55.43130320678592,
                            "lon": 61.46087775372949
                        },
                        {
                            "lat": 55.434891007257754,
                            "lon": 61.460418106310364
                        },
                        {
                            "lat": 55.43847868638123,
                            "lon": 61.45995836642385
                        },
                        {
                            "lat": 55.43751787223725,
                            "lon": 61.45824165833476
                        },
                        {
                            "lat": 55.43393037212167,
                            "lon": 61.45870136555888
                        },
                        {
                            "lat": 55.43296985831285,
                            "lon": 61.45698461560211
                        },
                        {
                            "lat": 55.43200946581018,
                            "lon": 61.4552678564416
                        },
                        {
                            "lat": 55.43559660796569,
                            "lon": 61.454808214532
                        },
                        {
                            "lat": 55.436557179439106,
                            "lon": 61.45652494103695
                        },
                        {
                            "lat": 55.44014437924064,
                            "lon": 61.45606517402185
                        },
                        {
                            "lat": 55.441105251006995,
                            "lon": 61.457781858655096
                        },
                        {
                            "lat": 55.4446925084123,
                            "lon": 61.457321966526116
                        },
                        {
                            "lat": 55.44373145769888,
                            "lon": 61.45560531456305
                        },
                        {
                            "lat": 55.44731841479528,
                            "lon": 61.45514536266676
                        },
                        {
                            "lat": 55.44827964443467,
                            "lon": 61.456861981954056
                        },
                        {
                            "lat": 55.451866659055476,
                            "lon": 61.456401904945146
                        },
                        {
                            "lat": 55.4554535522562,
                            "lon": 61.45594173550565
                        },
                        {
                            "lat": 55.45449196482827,
                            "lon": 61.45422518158669
                        },
                        {
                            "lat": 55.450905250511255,
                            "lon": 61.45468531833923
                        },
                        {
                            "lat": 55.44994396336599,
                            "lon": 61.45296872251246
                        },
                        {
                            "lat": 55.44898279759856,
                            "lon": 61.45125211746642
                        },
                        {
                            "lat": 55.4525691542038,
                            "lon": 61.45079204607756
                        },
                        {
                            "lat": 55.45353049881785,
                            "lon": 61.452508618443474
                        },
                        {
                            "lat": 55.4571169128733,
                            "lon": 61.452048421961315
                        },
                        {
                            "lat": 55.45807855772777,
                            "lon": 61.45376495241538
                        },
                        {
                            "lat": 55.45904032401829,
                            "lon": 61.455481473641754
                        },
                        {
                            "lat": 55.46262697432318,
                            "lon": 61.455021119359714
                        },
                        {
                            "lat": 55.461665029191224,
                            "lon": 61.453304630831525
                        },
                        {
                            "lat": 55.46525137920007,
                            "lon": 61.45284421684137
                        },
                        {
                            "lat": 55.466213503152346,
                            "lon": 61.4545606726658
                        },
                        {
                            "lat": 55.46979991048727,
                            "lon": 61.454100133566236
                        },
                        {
                            "lat": 55.47338619630939,
                            "lon": 61.45363950206719
                        },
                        {
                            "lat": 55.47242371477988,
                            "lon": 61.45192311166713
                        },
                        {
                            "lat": 55.476009700313774,
                            "lon": 61.451462420495496
                        },
                        {
                            "lat": 55.47504716155608,
                            "lon": 61.44974605357112
                        },
                        {
                            "lat": 55.478632846844015,
                            "lon": 61.44928530274088
                        },
                        {
                            "lat": 55.48221841060587,
                            "lon": 61.44882445954112
                        },
                        {
                            "lat": 55.48318130677695,
                            "lon": 61.45054076101445
                        },
                        {
                            "lat": 55.4867669276692,
                            "lon": 61.45007979271752
                        },
                        {
                            "lat": 55.48773012409949,
                            "lon": 61.451796052201814
                        },
                        {
                            "lat": 55.491315802079846,
                            "lon": 61.45133495879933
                        },
                        {
                            "lat": 55.4949013584363,
                            "lon": 61.450873773034886
                        },
                        {
                            "lat": 55.49586503383245,
                            "lon": 61.45258995776415
                        },
                        {
                            "lat": 55.499450647195054,
                            "lon": 61.45212864688648
                        },
                        {
                            "lat": 55.49848679315041,
                            "lon": 61.45041249491472
                        },
                        {
                            "lat": 55.50207210620361,
                            "lon": 61.44995112444507
                        },
                        {
                            "lat": 55.50303613887562,
                            "lon": 61.45166724365369
                        },
                        {
                            "lat": 55.5066215088557,
                            "lon": 61.451205748072134
                        },
                        {
                            "lat": 55.507585841852894,
                            "lon": 61.45292182523661
                        },
                        {
                            "lat": 55.51117126871745,
                            "lon": 61.452460204534745
                        },
                        {
                            "lat": 55.5121359020768,
                            "lon": 61.45417623964105
                        },
                        {
                            "lat": 55.51572138578352,
                            "lon": 61.45371449381054
                        },
                        {
                            "lat": 55.519306747691914,
                            "lon": 61.45325265563879
                        },
                        {
                            "lat": 55.522891987783545,
                            "lon": 61.45279072513205
                        },
                        {
                            "lat": 55.52647710603989,
                            "lon": 61.45232870229661
                        },
                        {
                            "lat": 55.53006210244253,
                            "lon": 61.451866587138696
                        },
                        {
                            "lat": 55.52909657629089,
                            "lon": 61.45015071602198
                        },
                        {
                            "lat": 55.532681272326435,
                            "lon": 61.44968854136254
                        },
                        {
                            "lat": 55.533646976972975,
                            "lon": 61.45140437966458
                        },
                        {
                            "lat": 55.53723172961276,
                            "lon": 61.4509420798805
                        },
                        {
                            "lat": 55.54081636034347,
                            "lon": 61.450479687792715
                        },
                        {
                            "lat": 55.541782543843766,
                            "lon": 61.45219545113921
                        },
                        {
                            "lat": 55.5427488492928,
                            "lon": 61.45391120517457
                        },
                        {
                            "lat": 55.546333715013944,
                            "lon": 61.45344865511624
                        },
                        {
                            "lat": 55.54991845874671,
                            "lon": 61.452986012755595
                        },
                        {
                            "lat": 55.54895179638246,
                            "lon": 61.45127032440233
                        },
                        {
                            "lat": 55.55253623968259,
                            "lon": 61.45080762259504
                        },
                        {
                            "lat": 55.55350308047264,
                            "lon": 61.45252327809886
                        },
                        {
                            "lat": 55.5570875801733,
                            "lon": 61.45206045115233
                        },
                        {
                            "lat": 55.556120560978655,
                            "lon": 61.45034482850353
                        },
                        {
                            "lat": 55.55970476025224,
                            "lon": 61.44988194213403
                        },
                        {
                            "lat": 55.56067195783025,
                            "lon": 61.451597531922246
                        },
                        {
                            "lat": 55.564256213425104,
                            "lon": 61.45113452041489
                        },
                        {
                            "lat": 55.56328883748491,
                            "lon": 61.44941896349282
                        },
                        {
                            "lat": 55.56687279265826,
                            "lon": 61.44895589258619
                        },
                        {
                            "lat": 55.57045662575385,
                            "lon": 61.44849272942038
                        },
                        {
                            "lat": 55.57404033675329,
                            "lon": 61.448029474001665
                        },
                        {
                            "lat": 55.575008247652676,
                            "lon": 61.44974493229185
                        },
                        {
                            "lat": 55.57859201481483,
                            "lon": 61.449281551738046
                        },
                        {
                            "lat": 55.58217565982282,
                            "lon": 61.44881807893832
                        },
                        {
                            "lat": 55.585759182658194,
                            "lon": 61.44835451389891
                        },
                        {
                            "lat": 55.58934258330261,
                            "lon": 61.44789085662612
                        },
                        {
                            "lat": 55.592925861737655,
                            "lon": 61.44742710712621
                        },
                        {
                            "lat": 55.59650901794494,
                            "lon": 61.44696326540543
                        },
                        {
                            "lat": 55.6000920519061,
                            "lon": 61.44649933147007
                        },
                        {
                            "lat": 55.59912289351148,
                            "lon": 61.44478410351589
                        },
                        {
                            "lat": 55.60270562707945,
                            "lon": 61.44432011029966
                        },
                        {
                            "lat": 55.60173641281778,
                            "lon": 61.44260490590328
                        },
                        {
                            "lat": 55.60076732079651,
                            "lon": 61.44088969213891
                        },
                        {
                            "lat": 55.59718494343078,
                            "lon": 61.44135361951069
                        },
                        {
                            "lat": 55.5981538573601,
                            "lon": 61.443068866195574
                        },
                        {
                            "lat": 55.59457117968039,
                            "lon": 61.443532734290685
                        },
                        {
                            "lat": 55.595540037700225,
                            "lon": 61.4452480045294
                        },
                        {
                            "lat": 55.59195705966408,
                            "lon": 61.445711813333894
                        },
                        {
                            "lat": 55.58837395942142,
                            "lon": 61.44617552992312
                        },
                        {
                            "lat": 55.58479073699062,
                            "lon": 61.44663915429077
                        },
                        {
                            "lat": 55.583822413492776,
                            "lon": 61.44492378533024
                        },
                        {
                            "lat": 55.58023924710869,
                            "lon": 61.44538728457393
                        },
                        {
                            "lat": 55.576655958594436,
                            "lon": 61.445850691589094
                        },
                        {
                            "lat": 55.57307254796845,
                            "lon": 61.44631400636944
                        },
                        {
                            "lat": 55.572104881276964,
                            "lon": 61.44459852939676
                        },
                        {
                            "lat": 55.568521526819325,
                            "lon": 61.44506171906005
                        },
                        {
                            "lat": 55.564938050307966,
                            "lon": 61.44552481648157
                        },
                        {
                            "lat": 55.56397086219643,
                            "lon": 61.443809264430506
                        },
                        {
                            "lat": 55.56038744193545,
                            "lon": 61.444272236742584
                        },
                        {
                            "lat": 55.55942055410532,
                            "lon": 61.44255664250313
                        },
                        {
                            "lat": 55.55583719013713,
                            "lon": 61.44301948971409
                        },
                        {
                            "lat": 55.55225370421284,
                            "lon": 61.44348224467566
                        },
                        {
                            "lat": 55.551287294918595,
                            "lon": 61.441766575418455
                        },
                        {
                            "lat": 55.54770386536909,
                            "lon": 61.44222920528654
                        },
                        {
                            "lat": 55.54673775628538,
                            "lon": 61.44051349387806
                        },
                        {
                            "lat": 55.54315438315302,
                            "lon": 61.440975998660946
                        },
                        {
                            "lat": 55.54218857424301,
                            "lon": 61.43926024511521
                        },
                        {
                            "lat": 55.54122288717033,
                            "lon": 61.437544482262574
                        },
                        {
                            "lat": 55.5448059037275,
                            "lon": 61.43708204312699
                        },
                        {
                            "lat": 55.54384016021096,
                            "lon": 61.43536630378757
                        },
                        {
                            "lat": 55.54742287669478,
                            "lon": 61.43490380523639
                        },
                        {
                            "lat": 55.551005471346805,
                            "lon": 61.43444121445745
                        },
                        {
                            "lat": 55.55458794414862,
                            "lon": 61.43397853145694
                        },
                        {
                            "lat": 55.55817029508183,
                            "lon": 61.43351575624112
                        },
                        {
                            "lat": 55.56175252412805,
                            "lon": 61.43305288881625
                        },
                        {
                            "lat": 55.565334631268875,
                            "lon": 61.43258992918864
                        },
                        {
                            "lat": 55.56891661648592,
                            "lon": 61.43212687736446
                        },
                        {
                            "lat": 55.57249847976081,
                            "lon": 61.43166373335003
                        },
                        {
                            "lat": 55.576080221075166,
                            "lon": 61.43120049715158
                        },
                        {
                            "lat": 55.57704756797867,
                            "lon": 61.43291594087382
                        },
                        {
                            "lat": 55.58062936536233,
                            "lon": 61.432452579623444
                        },
                        {
                            "lat": 55.58421104072752,
                            "lon": 61.43198912619605
                        },
                        {
                            "lat": 55.58779259405589,
                            "lon": 61.431525580597864
                        },
                        {
                            "lat": 55.58682471307133,
                            "lon": 61.42981023551488
                        },
                        {
                            "lat": 55.583243337748776,
                            "lon": 61.43027374822776
                        },
                        {
                            "lat": 55.582275756785684,
                            "lon": 61.428558360914515
                        },
                        {
                            "lat": 55.57869443745617,
                            "lon": 61.429021748585875
                        },
                        {
                            "lat": 55.57511299615056,
                            "lon": 61.4294850440913
                        },
                        {
                            "lat": 55.57153143288723,
                            "lon": 61.429948247424555
                        },
                        {
                            "lat": 55.567949747684544,
                            "lon": 61.43041135857936
                        },
                        {
                            "lat": 55.56698300080416,
                            "lon": 61.42869583046468
                        },
                        {
                            "lat": 55.563401371755475,
                            "lon": 61.42915881658417
                        },
                        {
                            "lat": 55.55981962082537,
                            "lon": 61.42962171051827
                        },
                        {
                            "lat": 55.55885335197918,
                            "lon": 61.42790610738675
                        },
                        {
                            "lat": 55.55527165728499,
                            "lon": 61.42836887629315
                        },
                        {
                            "lat": 55.551689840767274,
                            "lon": 61.42883155300721
                        },
                        {
                            "lat": 55.548107902444464,
                            "lon": 61.429294137522646
                        },
                        {
                            "lat": 55.547142289745324,
                            "lon": 61.42757842659041
                        },
                        {
                            "lat": 55.546176798811736,
                            "lon": 61.42586270635051
                        },
                        {
                            "lat": 55.54259509497186,
                            "lon": 61.42632513303271
                        },
                        {
                            "lat": 55.53901326940595,
                            "lon": 61.42678746751496
                        },
                        {
                            "lat": 55.53543132213241,
                            "lon": 61.42724970979101
                        },
                        {
                            "lat": 55.53184925316964,
                            "lon": 61.42771185985459
                        },
                        {
                            "lat": 55.52826706253607,
                            "lon": 61.42817391769949
                        },
                        {
                            "lat": 55.52468475025009,
                            "lon": 61.4286358833194
                        },
                        {
                            "lat": 55.52372045002139,
                            "lon": 61.42691995700262
                        },
                        {
                            "lat": 55.52013819433547,
                            "lon": 61.427381797615745
                        },
                        {
                            "lat": 55.52110231633012,
                            "lon": 61.42909775670813
                        },
                        {
                            "lat": 55.51751976079459,
                            "lon": 61.42955953785944
                        },
                        {
                            "lat": 55.5165558170551,
                            "lon": 61.42784354599696
                        },
                        {
                            "lat": 55.51297331819877,
                            "lon": 61.428305202140116
                        },
                        {
                            "lat": 55.50939069778482,
                            "lon": 61.42876676603888
                        },
                        {
                            "lat": 55.505807955831735,
                            "lon": 61.429228237687056
                        },
                        {
                            "lat": 55.50677136467899,
                            "lon": 61.43094432782618
                        },
                        {
                            "lat": 55.503188322865554,
                            "lon": 61.431405739965264
                        },
                        {
                            "lat": 55.50222509235797,
                            "lon": 61.42968961707841
                        },
                        {
                            "lat": 55.50126198335265,
                            "lon": 61.427973484928174
                        },
                        {
                            "lat": 55.50484466850521,
                            "lon": 61.42751213828116
                        },
                        {
                            "lat": 55.50388150267826,
                            "lon": 61.425796029609984
                        },
                        {
                            "lat": 55.50029899582846,
                            "lon": 61.42625734351614
                        },
                        {
                            "lat": 55.49933612976429,
                            "lon": 61.424541192843876
                        },
                        {
                            "lat": 55.49575367975987,
                            "lon": 61.425002381766205
                        },
                        {
                            "lat": 55.494791113421634,
                            "lon": 61.42328618910684
                        },
                        {
                            "lat": 55.49120872030481,
                            "lon": 61.42374725305374
                        },
                        {
                            "lat": 55.49217110833499,
                            "lon": 61.42546347843599
                        },
                        {
                            "lat": 55.488588415508026,
                            "lon": 61.42592448284694
                        },
                        {
                            "lat": 55.48762620580704,
                            "lon": 61.4242082247474
                        },
                        {
                            "lat": 55.48404356994674,
                            "lon": 61.424669104181596
                        },
                        {
                            "lat": 55.48500560129747,
                            "lon": 61.42638539499285
                        },
                        {
                            "lat": 55.48142266572181,
                            "lon": 61.42684621486748
                        },
                        {
                            "lat": 55.477839608799464,
                            "lon": 61.42730694246461
                        },
                        {
                            "lat": 55.4788014047146,
                            "lon": 61.429023289444444
                        },
                        {
                            "lat": 55.47521804803206,
                            "lon": 61.429483957456206
                        },
                        {
                            "lat": 55.4742564305489,
                            "lon": 61.42776757777799
                        },
                        {
                            "lat": 55.470673130988615,
                            "lon": 61.4282281208014
                        },
                        {
                            "lat": 55.469711813249795,
                            "lon": 61.4265116991991
                        },
                        {
                            "lat": 55.46612857085393,
                            "lon": 61.42697211724249
                        },
                        {
                            "lat": 55.462545207206375,
                            "lon": 61.42743244298904
                        },
                        {
                            "lat": 55.463506168012756,
                            "lon": 61.42914892995335
                        },
                        {
                            "lat": 55.45992250463415,
                            "lon": 61.42960919606944
                        },
                        {
                            "lat": 55.4589617223256,
                            "lon": 61.42789267643253
                        },
                        {
                            "lat": 55.45537811623009,
                            "lon": 61.428352817566704
                        },
                        {
                            "lat": 55.45633872001975,
                            "lon": 61.43006936987065
                        },
                        {
                            "lat": 55.4572994450267,
                            "lon": 61.431785912957004
                        },
                        {
                            "lat": 55.453715360636515,
                            "lon": 61.43224602710196
                        },
                        {
                            "lat": 55.45275481418805,
                            "lon": 61.430529451350736
                        },
                        {
                            "lat": 55.449170787157534,
                            "lon": 61.43098944050349
                        },
                        {
                            "lat": 55.4482105404689,
                            "lon": 61.42927282288226
                        },
                        {
                            "lat": 55.44462657084021,
                            "lon": 61.429732687051164
                        },
                        {
                            "lat": 55.441042480070784,
                            "lon": 61.430192458885905
                        },
                        {
                            "lat": 55.44200236957413,
                            "lon": 61.43190914180204
                        },
                        {
                            "lat": 55.44296238022075,
                            "lon": 61.4336258155143
                        },
                        {
                            "lat": 55.43937781106214,
                            "lon": 61.43408556029014
                        },
                        {
                            "lat": 55.44033776421181,
                            "lon": 61.43580225744284
                        },
                        {
                            "lat": 55.43675289522503,
                            "lon": 61.43626194250118
                        },
                        {
                            "lat": 55.435793120757694,
                            "lon": 61.43454521270816
                        },
                        {
                            "lat": 55.43220830932598,
                            "lon": 61.43500477276213
                        },
                        {
                            "lat": 55.43124883467081,
                            "lon": 61.43328800113922
                        },
                        {
                            "lat": 55.43028948110335,
                            "lon": 61.431571220322645
                        },
                        {
                            "lat": 55.43387393518384,
                            "lon": 61.431111725527856
                        },
                        {
                            "lat": 55.43483346741766,
                            "lon": 61.432828473716555
                        },
                        {
                            "lat": 55.438417979058265,
                            "lon": 61.432368853935415
                        },
                        {
                            "lat": 55.437458268179164,
                            "lon": 61.430652138380204
                        },
                        {
                            "lat": 55.43649867840373,
                            "lon": 61.42893541362606
                        },
                        {
                            "lat": 55.43291452403519,
                            "lon": 61.42939496814366
                        },
                        {
                            "lat": 55.429330248602525,
                            "lon": 61.42985443031399
                        },
                        {
                            "lat": 55.42574585212428,
                            "lon": 61.43031380013084
                        },
                        {
                            "lat": 55.4247869193194,
                            "lon": 61.4285969683162
                        },
                        {
                            "lat": 55.42120258048547,
                            "lon": 61.4290562131635
                        },
                        {
                            "lat": 55.41761812066405,
                            "lon": 61.42951536565046
                        },
                        {
                            "lat": 55.418576696105106,
                            "lon": 61.43123226267918
                        },
                        {
                            "lat": 55.414991936601275,
                            "lon": 61.431691355398264
                        },
                        {
                            "lat": 55.4159504543003,
                            "lon": 61.43340827584879
                        },
                        {
                            "lat": 55.412365395071944,
                            "lon": 61.43386730878599
                        },
                        {
                            "lat": 55.40878021486967,
                            "lon": 61.43432624933298
                        },
                        {
                            "lat": 55.4078220546978,
                            "lon": 61.43260926369514
                        },
                        {
                            "lat": 55.40423693233526,
                            "lon": 61.433068079260494
                        },
                        {
                            "lat": 55.40065168905695,
                            "lon": 61.43352680242888
                        },
                        {
                            "lat": 55.401609491617535,
                            "lon": 61.435243853231654
                        },
                        {
                            "lat": 55.39802394860479,
                            "lon": 61.43570251657088
                        },
                        {
                            "lat": 55.39898169322784,
                            "lon": 61.437419590786384
                        },
                        {
                            "lat": 55.39539585043861,
                            "lon": 61.43787819428244
                        },
                        {
                            "lat": 55.391809886747225,
                            "lon": 61.43833670535167
                        },
                        {
                            "lat": 55.39085249989881,
                            "lon": 61.436619565998065
                        },
                        {
                            "lat": 55.38726659424273,
                            "lon": 61.43707795207358
                        },
                        {
                            "lat": 55.38630950715715,
                            "lon": 61.435360771008206
                        },
                        {
                            "lat": 55.382723659578446,
                            "lon": 61.43581903209843
                        },
                        {
                            "lat": 55.38176687221851,
                            "lon": 61.43410180933531
                        },
                        {
                            "lat": 55.38081020564189,
                            "lon": 61.432384577427584
                        },
                        {
                            "lat": 55.377224595087725,
                            "lon": 61.43284268100175
                        },
                        {
                            "lat": 55.37626822815999,
                            "lon": 61.431125407415216
                        },
                        {
                            "lat": 55.37268267576968,
                            "lon": 61.43158338602997
                        },
                        {
                            "lat": 55.373638863789864,
                            "lon": 61.4333006921467
                        },
                        {
                            "lat": 55.370053011766856,
                            "lon": 61.433758610856195
                        },
                        {
                            "lat": 55.36646703903732,
                            "lon": 61.43421643712405
                        },
                        {
                            "lat": 55.3655112088953,
                            "lon": 61.43249906596379
                        },
                        {
                            "lat": 55.3619252944484,
                            "lon": 61.432956767270426
                        },
                        {
                            "lat": 55.36288094561984,
                            "lon": 61.43467417094404
                        },
                        {
                            "lat": 55.359294731533055,
                            "lon": 61.43513181230998
                        },
                        {
                            "lat": 55.36025032436363,
                            "lon": 61.436849239368726
                        },
                        {
                            "lat": 55.35666381059494,
                            "lon": 61.43730682077995
                        },
                        {
                            "lat": 55.355708396795535,
                            "lon": 61.43558936121568
                        },
                        {
                            "lat": 55.35212194142592,
                            "lon": 61.436046817654905
                        },
                        {
                            "lat": 55.34853536544282,
                            "lon": 61.43650418162151
                        },
                        {
                            "lat": 55.4688376077358,
                            "lon": 61.45238371045119
                        },
                        {
                            "lat": 55.46787542647599,
                            "lon": 61.450667278098116
                        },
                        {
                            "lat": 55.46428937672092,
                            "lon": 61.4511277517824
                        },
                        {
                            "lat": 55.46332749569376,
                            "lon": 61.44941127749045
                        },
                        {
                            "lat": 55.466913366686704,
                            "lon": 61.44895083650869
                        },
                        {
                            "lat": 55.470499116230265,
                            "lon": 61.44849030314428
                        },
                        {
                            "lat": 55.47146135476055,
                            "lon": 61.45020671202563
                        },
                        {
                            "lat": 55.47242371477988,
                            "lon": 61.45192311166713
                        },
                        {
                            "lat": 55.4688376077358,
                            "lon": 61.45238371045119
                        },
                        {
                            "lat": 55.45974150326994,
                            "lon": 61.44987162608334
                        },
                        {
                            "lat": 55.456155389433775,
                            "lon": 61.45033188228113
                        },
                        {
                            "lat": 55.455193987388036,
                            "lon": 61.44861533337635
                        },
                        {
                            "lat": 55.454232706715004,
                            "lon": 61.44689877524857
                        },
                        {
                            "lat": 55.45781846299802,
                            "lon": 61.44643858442318
                        },
                        {
                            "lat": 55.45877992243836,
                            "lon": 61.44815510986645
                        },
                        {
                            "lat": 55.46236573609747,
                            "lon": 61.44769479396707
                        },
                        {
                            "lat": 55.46332749569376,
                            "lon": 61.44941127749045
                        },
                        {
                            "lat": 55.45974150326994,
                            "lon": 61.44987162608334
                        },
                        {
                            "lat": 55.54025732191376,
                            "lon": 61.435828710104644
                        },
                        {
                            "lat": 55.53929187845217,
                            "lon": 61.43411292864301
                        },
                        {
                            "lat": 55.54287453850782,
                            "lon": 61.433650555140986
                        },
                        {
                            "lat": 55.54384016021096,
                            "lon": 61.43536630378757
                        },
                        {
                            "lat": 55.54025732191376,
                            "lon": 61.435828710104644
                        },
                        {
                            "lat": 55.45467602830487,
                            "lon": 61.43396259363745
                        },
                        {
                            "lat": 55.45563681721416,
                            "lon": 61.435679150955636
                        },
                        {
                            "lat": 55.45205225438948,
                            "lon": 61.43613923809646
                        },
                        {
                            "lat": 55.451091644096756,
                            "lon": 61.43442264811216
                        },
                        {
                            "lat": 55.450131155026455,
                            "lon": 61.43270604891398
                        },
                        {
                            "lat": 55.453715360636515,
                            "lon": 61.43224602710196
                        },
                        {
                            "lat": 55.45467602830487,
                            "lon": 61.43396259363745
                        },
                        {
                            "lat": 55.42887600297107,
                            "lon": 61.442790871328484
                        },
                        {
                            "lat": 55.432461771695216,
                            "lon": 61.44233136550671
                        },
                        {
                            "lat": 55.433421673306206,
                            "lon": 61.44404812378071
                        },
                        {
                            "lat": 55.42983572576233,
                            "lon": 61.444507662239666
                        },
                        {
                            "lat": 55.42624965704144,
                            "lon": 61.444967108287905
                        },
                        {
                            "lat": 55.42529011309097,
                            "lon": 61.443250284745105
                        },
                        {
                            "lat": 55.42887600297107,
                            "lon": 61.442790871328484
                        },
                        {
                            "lat": 55.42266346716205,
                            "lon": 61.44542646191918
                        },
                        {
                            "lat": 55.41907715614278,
                            "lon": 61.44588572312732
                        },
                        {
                            "lat": 55.41811796993715,
                            "lon": 61.44416883433816
                        },
                        {
                            "lat": 55.42170410207347,
                            "lon": 61.443709605750406
                        },
                        {
                            "lat": 55.42266346716205,
                            "lon": 61.44542646191918
                        },
                        {
                            "lat": 55.43963294560144,
                            "lon": 61.441412076672705
                        },
                        {
                            "lat": 55.440593204788634,
                            "lon": 61.443128769655544
                        },
                        {
                            "lat": 55.437007499654484,
                            "lon": 61.44358849291725
                        },
                        {
                            "lat": 55.43604741924484,
                            "lon": 61.44187176728603
                        },
                        {
                            "lat": 55.43963294560144,
                            "lon": 61.441412076672705
                        },
                        {
                            "lat": 55.44802175318787,
                            "lon": 61.44953550320264
                        },
                        {
                            "lat": 55.44443545407509,
                            "lon": 61.449995449506595
                        },
                        {
                            "lat": 55.44347470983063,
                            "lon": 61.448278793359
                        },
                        {
                            "lat": 55.447060830112775,
                            "lon": 61.44781887972272
                        },
                        {
                            "lat": 55.44802175318787,
                            "lon": 61.44953550320264
                        },
                        {
                            "lat": 55.5096623205561,
                            "lon": 61.43609254262706
                        },
                        {
                            "lat": 55.51062621569735,
                            "lon": 61.43780859568323
                        },
                        {
                            "lat": 55.50704246034082,
                            "lon": 61.43827013884752
                        },
                        {
                            "lat": 55.50607874361302,
                            "lon": 61.436554053029916
                        },
                        {
                            "lat": 55.5096623205561,
                            "lon": 61.43609254262706
                        },
                        {
                            "lat": 55.508698547020046,
                            "lon": 61.43437648029776
                        },
                        {
                            "lat": 55.51228182398405,
                            "lon": 61.433914910379364
                        },
                        {
                            "lat": 55.513245775893985,
                            "lon": 61.43563093994504
                        },
                        {
                            "lat": 55.5096623205561,
                            "lon": 61.43609254262706
                        },
                        {
                            "lat": 55.508698547020046,
                            "lon": 61.43437648029776
                        },
                        {
                            "lat": 55.472596130361126,
                            "lon": 61.431660936310614
                        },
                        {
                            "lat": 55.46901235254278,
                            "lon": 61.43212145241567
                        },
                        {
                            "lat": 55.468050970693,
                            "lon": 61.43040501658682
                        },
                        {
                            "lat": 55.4716345700187,
                            "lon": 61.429944533172424
                        },
                        {
                            "lat": 55.472596130361126,
                            "lon": 61.431660936310614
                        },
                        {
                            "lat": 55.52923166263007,
                            "lon": 61.4298898019461
                        },
                        {
                            "lat": 55.5301963843976,
                            "lon": 61.431605676902215
                        },
                        {
                            "lat": 55.526613715630546,
                            "lon": 61.43206770809484
                        },
                        {
                            "lat": 55.525649172112765,
                            "lon": 61.43035180035067
                        },
                        {
                            "lat": 55.52923166263007,
                            "lon": 61.4298898019461
                        }
                    ],
                    [
                        {
                            "lat": 55.37985365982757,
                            "lon": 61.430667336376814
                        },
                        {
                            "lat": 55.38081020564189,
                            "lon": 61.432384577427584
                        },
                        {
                            "lat": 55.38439569543373,
                            "lon": 61.43192638143035
                        },
                        {
                            "lat": 55.383438970753865,
                            "lon": 61.430209172921
                        },
                        {
                            "lat": 55.37985365982757,
                            "lon": 61.430667336376814
                        }
                    ],
                    [
                        {
                            "lat": 55.38824014271787,
                            "lon": 61.46638630803554
                        },
                        {
                            "lat": 55.38919856993661,
                            "lon": 61.46810346372523
                        },
                        {
                            "lat": 55.39278800443933,
                            "lon": 61.46764489380769
                        },
                        {
                            "lat": 55.39182939791965,
                            "lon": 61.465927770710884
                        },
                        {
                            "lat": 55.38824014271787,
                            "lon": 61.46638630803554
                        }
                    ],
                    [
                        {
                            "lat": 55.399694007393606,
                            "lon": 61.43180974246288
                        },
                        {
                            "lat": 55.396108822036695,
                            "lon": 61.4322683406574
                        },
                        {
                            "lat": 55.397066324881415,
                            "lon": 61.433985433194046
                        },
                        {
                            "lat": 55.40065168905695,
                            "lon": 61.43352680242888
                        },
                        {
                            "lat": 55.399694007393606,
                            "lon": 61.43180974246288
                        }
                    ],
                    [
                        {
                            "lat": 55.39348083982722,
                            "lon": 61.4344439715498
                        },
                        {
                            "lat": 55.39443828469236,
                            "lon": 61.43616108749508
                        },
                        {
                            "lat": 55.39802394860479,
                            "lon": 61.43570251657088
                        },
                        {
                            "lat": 55.397066324881415,
                            "lon": 61.433985433194046
                        },
                        {
                            "lat": 55.39348083982722,
                            "lon": 61.4344439715498
                        }
                    ],
                    [
                        {
                            "lat": 55.40977385667955,
                            "lon": 61.46363369608028
                        },
                        {
                            "lat": 55.410733359387336,
                            "lon": 61.46535065612855
                        },
                        {
                            "lat": 55.41432206673343,
                            "lon": 61.464891531018
                        },
                        {
                            "lat": 55.41336238485135,
                            "lon": 61.46317460359628
                        },
                        {
                            "lat": 55.40977385667955,
                            "lon": 61.46363369608028
                        }
                    ],
                    [
                        {
                            "lat": 55.41403353987368,
                            "lon": 61.42997442577087
                        },
                        {
                            "lat": 55.41044883813291,
                            "lon": 61.430433393518534
                        },
                        {
                            "lat": 55.411407056125974,
                            "lon": 61.43215035573897
                        },
                        {
                            "lat": 55.414991936601275,
                            "lon": 61.431691355398264
                        },
                        {
                            "lat": 55.41403353987368,
                            "lon": 61.42997442577087
                        }
                    ],
                    [
                        {
                            "lat": 55.45800106123177,
                            "lon": 61.42617614757617
                        },
                        {
                            "lat": 55.4589617223256,
                            "lon": 61.42789267643253
                        },
                        {
                            "lat": 55.462545207206375,
                            "lon": 61.42743244298904
                        },
                        {
                            "lat": 55.4615843676332,
                            "lon": 61.425715946801915
                        },
                        {
                            "lat": 55.45800106123177,
                            "lon": 61.42617614757617
                        }
                    ],
                    [
                        {
                            "lat": 55.476009700313774,
                            "lon": 61.451462420495496
                        },
                        {
                            "lat": 55.4769723606002,
                            "lon": 61.45317877817502
                        },
                        {
                            "lat": 55.48055840334114,
                            "lon": 61.4527179618959
                        },
                        {
                            "lat": 55.47959556431897,
                            "lon": 61.45100163694252
                        },
                        {
                            "lat": 55.476009700313774,
                            "lon": 61.451462420495496
                        }
                    ],
                    [
                        {
                            "lat": 55.46262697432318,
                            "lon": 61.455021119359714
                        },
                        {
                            "lat": 55.46358904093087,
                            "lon": 61.45673759865519
                        },
                        {
                            "lat": 55.467175748598905,
                            "lon": 61.456277119254075
                        },
                        {
                            "lat": 55.466213503152346,
                            "lon": 61.4545606726658
                        },
                        {
                            "lat": 55.46262697432318,
                            "lon": 61.455021119359714
                        }
                    ],
                    [
                        {
                            "lat": 55.46455122903542,
                            "lon": 61.45845406871641
                        },
                        {
                            "lat": 55.46551353865797,
                            "lon": 61.46017052954176
                        },
                        {
                            "lat": 55.46910060405933,
                            "lon": 61.459709984715964
                        },
                        {
                            "lat": 55.46813811556084,
                            "lon": 61.45799355660465
                        },
                        {
                            "lat": 55.46455122903542,
                            "lon": 61.45845406871641
                        }
                    ],
                    [
                        {
                            "lat": 55.59786077796282,
                            "lon": 61.435743994653826
                        },
                        {
                            "lat": 55.59882950339026,
                            "lon": 61.437459236512595
                        },
                        {
                            "lat": 55.59979835099441,
                            "lon": 61.43917446900815
                        },
                        {
                            "lat": 55.60076732079651,
                            "lon": 61.44088969213891
                        },
                        {
                            "lat": 55.604349575943075,
                            "lon": 61.44042567258178
                        },
                        {
                            "lat": 55.60338042808863,
                            "lon": 61.43871048237368
                        },
                        {
                            "lat": 55.60241140245047,
                            "lon": 61.436995282797284
                        },
                        {
                            "lat": 55.6014424990074,
                            "lon": 61.43528007385428
                        },
                        {
                            "lat": 55.60047371773817,
                            "lon": 61.43356485554621
                        },
                        {
                            "lat": 55.5995050586216,
                            "lon": 61.431849627874655
                        },
                        {
                            "lat": 55.59592369355326,
                            "lon": 61.43231348285304
                        },
                        {
                            "lat": 55.59495533452872,
                            "lon": 61.430598212914184
                        },
                        {
                            "lat": 55.59137402532907,
                            "lon": 61.43106194283515
                        },
                        {
                            "lat": 55.58779259405589,
                            "lon": 61.431525580597864
                        },
                        {
                            "lat": 55.58876059709564,
                            "lon": 61.43324091633088
                        },
                        {
                            "lat": 55.59234220637181,
                            "lon": 61.43277724567386
                        },
                        {
                            "lat": 55.593310509509315,
                            "lon": 61.43449253915755
                        },
                        {
                            "lat": 55.59689217469089,
                            "lon": 61.434028743433416
                        },
                        {
                            "lat": 55.59786077796282,
                            "lon": 61.435743994653826
                        }
                    ]
                ]
            },
            {
                "type": "MultiPolygon",
                "value": 1.0,
                "data": [
                    [
                        {
                            "lat": 55.54287453850782,
                            "lon": 61.433650555140986
                        },
                        {
                            "lat": 55.53929187845217,
                            "lon": 61.43411292864301
                        },
                        {
                            "lat": 55.54025732191376,
                            "lon": 61.435828710104644
                        },
                        {
                            "lat": 55.54384016021096,
                            "lon": 61.43536630378757
                        },
                        {
                            "lat": 55.54287453850782,
                            "lon": 61.433650555140986
                        }
                    ],
                    [
                        {
                            "lat": 55.54218857424301,
                            "lon": 61.43926024511521
                        },
                        {
                            "lat": 55.54315438315302,
                            "lon": 61.440975998660946
                        },
                        {
                            "lat": 55.54673775628538,
                            "lon": 61.44051349387806
                        },
                        {
                            "lat": 55.54770386536909,
                            "lon": 61.44222920528654
                        },
                        {
                            "lat": 55.551287294918595,
                            "lon": 61.441766575418455
                        },
                        {
                            "lat": 55.55225370421284,
                            "lon": 61.44348224467566
                        },
                        {
                            "lat": 55.55583719013713,
                            "lon": 61.44301948971409
                        },
                        {
                            "lat": 55.55942055410532,
                            "lon": 61.44255664250313
                        },
                        {
                            "lat": 55.56038744193545,
                            "lon": 61.444272236742584
                        },
                        {
                            "lat": 55.56397086219643,
                            "lon": 61.443809264430506
                        },
                        {
                            "lat": 55.564938050307966,
                            "lon": 61.44552481648157
                        },
                        {
                            "lat": 55.568521526819325,
                            "lon": 61.44506171906005
                        },
                        {
                            "lat": 55.572104881276964,
                            "lon": 61.44459852939676
                        },
                        {
                            "lat": 55.571137336657614,
                            "lon": 61.4428830430852
                        },
                        {
                            "lat": 55.56755416044327,
                            "lon": 61.44334619987593
                        },
                        {
                            "lat": 55.56658691609974,
                            "lon": 61.441630671358
                        },
                        {
                            "lat": 55.563003796099,
                            "lon": 61.442093703049
                        },
                        {
                            "lat": 55.56203685199449,
                            "lon": 61.44037813233871
                        },
                        {
                            "lat": 55.55845378824971,
                            "lon": 61.44084103893828
                        },
                        {
                            "lat": 55.5548706025516,
                            "lon": 61.44130385330031
                        },
                        {
                            "lat": 55.55390413690099,
                            "lon": 61.43958820756616
                        },
                        {
                            "lat": 55.55293779316411,
                            "lon": 61.43787255251323
                        },
                        {
                            "lat": 55.55652062237726,
                            "lon": 61.437409803838854
                        },
                        {
                            "lat": 55.56010332967946,
                            "lon": 61.43694696293804
                        },
                        {
                            "lat": 55.559136751426564,
                            "lon": 61.43523136425079
                        },
                        {
                            "lat": 55.56271915862689,
                            "lon": 61.4347684639796
                        },
                        {
                            "lat": 55.56630144390065,
                            "lon": 61.434305471500025
                        },
                        {
                            "lat": 55.56988360722945,
                            "lon": 61.433842386818355
                        },
                        {
                            "lat": 55.573465648594926,
                            "lon": 61.43337920994088
                        },
                        {
                            "lat": 55.57704756797867,
                            "lon": 61.43291594087382
                        },
                        {
                            "lat": 55.576080221075166,
                            "lon": 61.43120049715158
                        },
                        {
                            "lat": 55.57249847976081,
                            "lon": 61.43166373335003
                        },
                        {
                            "lat": 55.56891661648592,
                            "lon": 61.43212687736446
                        },
                        {
                            "lat": 55.565334631268875,
                            "lon": 61.43258992918864
                        },
                        {
                            "lat": 55.56175252412805,
                            "lon": 61.43305288881625
                        },
                        {
                            "lat": 55.55817029508183,
                            "lon": 61.43351575624112
                        },
                        {
                            "lat": 55.55458794414862,
                            "lon": 61.43397853145694
                        },
                        {
                            "lat": 55.551005471346805,
                            "lon": 61.43444121445745
                        },
                        {
                            "lat": 55.54742287669478,
                            "lon": 61.43490380523639
                        },
                        {
                            "lat": 55.54384016021096,
                            "lon": 61.43536630378757
                        },
                        {
                            "lat": 55.5448059037275,
                            "lon": 61.43708204312699
                        },
                        {
                            "lat": 55.54122288717033,
                            "lon": 61.437544482262574
                        },
                        {
                            "lat": 55.54218857424301,
                            "lon": 61.43926024511521
                        }
                    ],
                    [
                        {
                            "lat": 55.58779259405589,
                            "lon": 61.431525580597864
                        },
                        {
                            "lat": 55.58421104072752,
                            "lon": 61.43198912619605
                        },
                        {
                            "lat": 55.58517886574309,
                            "lon": 61.43370449481782
                        },
                        {
                            "lat": 55.58876059709564,
                            "lon": 61.43324091633088
                        },
                        {
                            "lat": 55.58779259405589,
                            "lon": 61.431525580597864
                        }
                    ],
                    [
                        {
                            "lat": 55.58876059709564,
                            "lon": 61.43324091633088
                        },
                        {
                            "lat": 55.58972872221178,
                            "lon": 61.43495624271234
                        },
                        {
                            "lat": 55.593310509509315,
                            "lon": 61.43449253915755
                        },
                        {
                            "lat": 55.59234220637181,
                            "lon": 61.43277724567386
                        },
                        {
                            "lat": 55.58876059709564,
                            "lon": 61.43324091633088
                        }
                    ],
                    [
                        {
                            "lat": 55.593310509509315,
                            "lon": 61.43449253915755
                        },
                        {
                            "lat": 55.594278934762734,
                            "lon": 61.436207823284704
                        },
                        {
                            "lat": 55.59524748215332,
                            "lon": 61.437923098053666
                        },
                        {
                            "lat": 55.59621615170225,
                            "lon": 61.43963836346286
                        },
                        {
                            "lat": 55.59718494343078,
                            "lon": 61.44135361951069
                        },
                        {
                            "lat": 55.60076732079651,
                            "lon": 61.44088969213891
                        },
                        {
                            "lat": 55.59979835099441,
                            "lon": 61.43917446900815
                        },
                        {
                            "lat": 55.59882950339026,
                            "lon": 61.437459236512595
                        },
                        {
                            "lat": 55.59786077796282,
                            "lon": 61.435743994653826
                        },
                        {
                            "lat": 55.59689217469089,
                            "lon": 61.434028743433416
                        },
                        {
                            "lat": 55.593310509509315,
                            "lon": 61.43449253915755
                        }
                    ],
                    [
                        {
                            "lat": 55.58023924710869,
                            "lon": 61.44538728457393
                        },
                        {
                            "lat": 55.579271223957434,
                            "lon": 61.443671873369944
                        },
                        {
                            "lat": 55.57568811366246,
                            "lon": 61.444135247497954
                        },
                        {
                            "lat": 55.572104881276964,
                            "lon": 61.44459852939676
                        },
                        {
                            "lat": 55.57307254796845,
                            "lon": 61.44631400636944
                        },
                        {
                            "lat": 55.576655958594436,
                            "lon": 61.445850691589094
                        },
                        {
                            "lat": 55.58023924710869,
                            "lon": 61.44538728457393
                        }
                    ],
                    [
                        {
                            "lat": 55.587405457728366,
                            "lon": 61.444460193864295
                        },
                        {
                            "lat": 55.583822413492776,
                            "lon": 61.44492378533024
                        },
                        {
                            "lat": 55.58479073699062,
                            "lon": 61.44663915429077
                        },
                        {
                            "lat": 55.58837395942142,
                            "lon": 61.44617552992312
                        },
                        {
                            "lat": 55.59195705966408,
                            "lon": 61.445711813333894
                        },
                        {
                            "lat": 55.590988379797025,
                            "lon": 61.44399651018235
                        },
                        {
                            "lat": 55.587405457728366,
                            "lon": 61.444460193864295
                        }
                    ]
                ]
            },
            {
                "type": "MultiPolygon",
                "value": 2.0,
                "data": [
                    [
                        {
                            "lat": 55.55652062237726,
                            "lon": 61.437409803838854
                        },
                        {
                            "lat": 55.55293779316411,
                            "lon": 61.43787255251323
                        },
                        {
                            "lat": 55.55390413690099,
                            "lon": 61.43958820756616
                        },
                        {
                            "lat": 55.5548706025516,
                            "lon": 61.44130385330031
                        },
                        {
                            "lat": 55.55845378824971,
                            "lon": 61.44084103893828
                        },
                        {
                            "lat": 55.56203685199449,
                            "lon": 61.44037813233871
                        },
                        {
                            "lat": 55.563003796099,
                            "lon": 61.442093703049
                        },
                        {
                            "lat": 55.56658691609974,
                            "lon": 61.441630671358
                        },
                        {
                            "lat": 55.56755416044327,
                            "lon": 61.44334619987593
                        },
                        {
                            "lat": 55.571137336657614,
                            "lon": 61.4428830430852
                        },
                        {
                            "lat": 55.572104881276964,
                            "lon": 61.44459852939676
                        },
                        {
                            "lat": 55.57568811366246,
                            "lon": 61.444135247497954
                        },
                        {
                            "lat": 55.579271223957434,
                            "lon": 61.443671873369944
                        },
                        {
                            "lat": 55.57830332291508,
                            "lon": 61.44195645282017
                        },
                        {
                            "lat": 55.57472039082101,
                            "lon": 61.44241979406453
                        },
                        {
                            "lat": 55.573752790048864,
                            "lon": 61.44070433129036
                        },
                        {
                            "lat": 55.57016991408916,
                            "lon": 61.44116754743638
                        },
                        {
                            "lat": 55.56920261355045,
                            "lon": 61.43945204245183
                        },
                        {
                            "lat": 55.568235435020256,
                            "lon": 61.437736528133215
                        },
                        {
                            "lat": 55.564652793425445,
                            "lon": 61.43819958632693
                        },
                        {
                            "lat": 55.563685915052304,
                            "lon": 61.436484029817024
                        },
                        {
                            "lat": 55.567268378477394,
                            "lon": 61.436021004482086
                        },
                        {
                            "lat": 55.570850719936324,
                            "lon": 61.4355578869395
                        },
                        {
                            "lat": 55.574432939410755,
                            "lon": 61.435094677195536
                        },
                        {
                            "lat": 55.57801503688227,
                            "lon": 61.434631375256394
                        },
                        {
                            "lat": 55.58159701233251,
                            "lon": 61.4341679811284
                        },
                        {
                            "lat": 55.58517886574309,
                            "lon": 61.43370449481782
                        },
                        {
                            "lat": 55.58421104072752,
                            "lon": 61.43198912619605
                        },
                        {
                            "lat": 55.58062936536233,
                            "lon": 61.432452579623444
                        },
                        {
                            "lat": 55.57704756797867,
                            "lon": 61.43291594087382
                        },
                        {
                            "lat": 55.573465648594926,
                            "lon": 61.43337920994088
                        },
                        {
                            "lat": 55.56988360722945,
                            "lon": 61.433842386818355
                        },
                        {
                            "lat": 55.56630144390065,
                            "lon": 61.434305471500025
                        },
                        {
                            "lat": 55.56271915862689,
                            "lon": 61.4347684639796
                        },
                        {
                            "lat": 55.559136751426564,
                            "lon": 61.43523136425079
                        },
                        {
                            "lat": 55.56010332967946,
                            "lon": 61.43694696293804
                        },
                        {
                            "lat": 55.55652062237726,
                            "lon": 61.437409803838854
                        }
                    ],
                    [
                        {
                            "lat": 55.58285421214348,
                            "lon": 61.443208407018936
                        },
                        {
                            "lat": 55.579271223957434,
                            "lon": 61.443671873369944
                        },
                        {
                            "lat": 55.58023924710869,
                            "lon": 61.44538728457393
                        },
                        {
                            "lat": 55.583822413492776,
                            "lon": 61.44492378533024
                        },
                        {
                            "lat": 55.58285421214348,
                            "lon": 61.443208407018936
                        }
                    ],
                    [
                        {
                            "lat": 55.59457117968039,
                            "lon": 61.443532734290685
                        },
                        {
                            "lat": 55.590988379797025,
                            "lon": 61.44399651018235
                        },
                        {
                            "lat": 55.59195705966408,
                            "lon": 61.445711813333894
                        },
                        {
                            "lat": 55.595540037700225,
                            "lon": 61.4452480045294
                        },
                        {
                            "lat": 55.59457117968039,
                            "lon": 61.443532734290685
                        }
                    ]
                ]
            },
            {
                "type": "MultiPolygon",
                "value": 3.0,
                "data": [
                    [
                        {
                            "lat": 55.568235435020256,
                            "lon": 61.437736528133215
                        },
                        {
                            "lat": 55.56920261355045,
                            "lon": 61.43945204245183
                        },
                        {
                            "lat": 55.57016991408916,
                            "lon": 61.44116754743638
                        },
                        {
                            "lat": 55.573752790048864,
                            "lon": 61.44070433129036
                        },
                        {
                            "lat": 55.57278531132485,
                            "lon": 61.43898885917708
                        },
                        {
                            "lat": 55.57181795462773,
                            "lon": 61.43727337772628
                        },
                        {
                            "lat": 55.575400352229494,
                            "lon": 61.43681013511237
                        },
                        {
                            "lat": 55.574432939410755,
                            "lon": 61.435094677195536
                        },
                        {
                            "lat": 55.570850719936324,
                            "lon": 61.4355578869395
                        },
                        {
                            "lat": 55.567268378477394,
                            "lon": 61.436021004482086
                        },
                        {
                            "lat": 55.563685915052304,
                            "lon": 61.436484029817024
                        },
                        {
                            "lat": 55.564652793425445,
                            "lon": 61.43819958632693
                        },
                        {
                            "lat": 55.568235435020256,
                            "lon": 61.437736528133215
                        }
                    ],
                    [
                        {
                            "lat": 55.58614681281667,
                            "lon": 61.43541985409147
                        },
                        {
                            "lat": 55.58972872221178,
                            "lon": 61.43495624271234
                        },
                        {
                            "lat": 55.58876059709564,
                            "lon": 61.43324091633088
                        },
                        {
                            "lat": 55.58517886574309,
                            "lon": 61.43370449481782
                        },
                        {
                            "lat": 55.58614681281667,
                            "lon": 61.43541985409147
                        }
                    ],
                    [
                        {
                            "lat": 55.57472039082101,
                            "lon": 61.44241979406453
                        },
                        {
                            "lat": 55.57830332291508,
                            "lon": 61.44195645282017
                        },
                        {
                            "lat": 55.577335543960466,
                            "lon": 61.44024102292629
                        },
                        {
                            "lat": 55.573752790048864,
                            "lon": 61.44070433129036
                        },
                        {
                            "lat": 55.57472039082101,
                            "lon": 61.44241979406453
                        }
                    ],
                    [
                        {
                            "lat": 55.58285421214348,
                            "lon": 61.443208407018936
                        },
                        {
                            "lat": 55.581886132921454,
                            "lon": 61.441493019358454
                        },
                        {
                            "lat": 55.57830332291508,
                            "lon": 61.44195645282017
                        },
                        {
                            "lat": 55.579271223957434,
                            "lon": 61.443671873369944
                        },
                        {
                            "lat": 55.58285421214348,
                            "lon": 61.443208407018936
                        }
                    ],
                    [
                        {
                            "lat": 55.587405457728366,
                            "lon": 61.444460193864295
                        },
                        {
                            "lat": 55.5864370782022,
                            "lon": 61.44274484845125
                        },
                        {
                            "lat": 55.58285421214348,
                            "lon": 61.443208407018936
                        },
                        {
                            "lat": 55.583822413492776,
                            "lon": 61.44492378533024
                        },
                        {
                            "lat": 55.587405457728366,
                            "lon": 61.444460193864295
                        }
                    ]
                ]
            },
            {
                "type": "MultiPolygon",
                "value": 4.0,
                "data": [
                    [
                        {
                            "lat": 55.574432939410755,
                            "lon": 61.435094677195536
                        },
                        {
                            "lat": 55.575400352229494,
                            "lon": 61.43681013511237
                        },
                        {
                            "lat": 55.57898262780716,
                            "lon": 61.436346800297734
                        },
                        {
                            "lat": 55.57801503688227,
                            "lon": 61.434631375256394
                        },
                        {
                            "lat": 55.574432939410755,
                            "lon": 61.435094677195536
                        }
                    ],
                    [
                        {
                            "lat": 55.57278531132485,
                            "lon": 61.43898885917708
                        },
                        {
                            "lat": 55.573752790048864,
                            "lon": 61.44070433129036
                        },
                        {
                            "lat": 55.577335543960466,
                            "lon": 61.44024102292629
                        },
                        {
                            "lat": 55.57636788707232,
                            "lon": 61.43852558368981
                        },
                        {
                            "lat": 55.575400352229494,
                            "lon": 61.43681013511237
                        },
                        {
                            "lat": 55.57181795462773,
                            "lon": 61.43727337772628
                        },
                        {
                            "lat": 55.57278531132485,
                            "lon": 61.43898885917708
                        }
                    ],
                    [
                        {
                            "lat": 55.590696969425494,
                            "lon": 61.43667155974067
                        },
                        {
                            "lat": 55.594278934762734,
                            "lon": 61.436207823284704
                        },
                        {
                            "lat": 55.593310509509315,
                            "lon": 61.43449253915755
                        },
                        {
                            "lat": 55.58972872221178,
                            "lon": 61.43495624271234
                        },
                        {
                            "lat": 55.590696969425494,
                            "lon": 61.43667155974067
                        }
                    ],
                    [
                        {
                            "lat": 55.57830332291508,
                            "lon": 61.44195645282017
                        },
                        {
                            "lat": 55.581886132921454,
                            "lon": 61.441493019358454
                        },
                        {
                            "lat": 55.58091817580555,
                            "lon": 61.43977762235035
                        },
                        {
                            "lat": 55.577335543960466,
                            "lon": 61.44024102292629
                        },
                        {
                            "lat": 55.57830332291508,
                            "lon": 61.44195645282017
                        }
                    ],
                    [
                        {
                            "lat": 55.58285421214348,
                            "lon": 61.443208407018936
                        },
                        {
                            "lat": 55.5864370782022,
                            "lon": 61.44274484845125
                        },
                        {
                            "lat": 55.585468820821745,
                            "lon": 61.441029493685576
                        },
                        {
                            "lat": 55.581886132921454,
                            "lon": 61.441493019358454
                        },
                        {
                            "lat": 55.58285421214348,
                            "lon": 61.443208407018936
                        }
                    ]
                ]
            },
            {
                "type": "MultiPolygon",
                "value": 5.0,
                "data": [
                    [
                        {
                            "lat": 55.57898262780716,
                            "lon": 61.436346800297734
                        },
                        {
                            "lat": 55.582564781342334,
                            "lon": 61.435883373288675
                        },
                        {
                            "lat": 55.58614681281667,
                            "lon": 61.43541985409147
                        },
                        {
                            "lat": 55.58517886574309,
                            "lon": 61.43370449481782
                        },
                        {
                            "lat": 55.58159701233251,
                            "lon": 61.4341679811284
                        },
                        {
                            "lat": 55.57801503688227,
                            "lon": 61.434631375256394
                        },
                        {
                            "lat": 55.57898262780716,
                            "lon": 61.436346800297734
                        }
                    ],
                    [
                        {
                            "lat": 55.575400352229494,
                            "lon": 61.43681013511237
                        },
                        {
                            "lat": 55.57636788707232,
                            "lon": 61.43852558368981
                        },
                        {
                            "lat": 55.577335543960466,
                            "lon": 61.44024102292629
                        },
                        {
                            "lat": 55.58091817580555,
                            "lon": 61.43977762235035
                        },
                        {
                            "lat": 55.579950340774516,
                            "lon": 61.43806221599627
                        },
                        {
                            "lat": 55.57898262780716,
                            "lon": 61.436346800297734
                        },
                        {
                            "lat": 55.575400352229494,
                            "lon": 61.43681013511237
                        }
                    ],
                    [
                        {
                            "lat": 55.587405457728366,
                            "lon": 61.444460193864295
                        },
                        {
                            "lat": 55.590988379797025,
                            "lon": 61.44399651018235
                        },
                        {
                            "lat": 55.590019822115245,
                            "lon": 61.44228119767312
                        },
                        {
                            "lat": 55.5864370782022,
                            "lon": 61.44274484845125
                        },
                        {
                            "lat": 55.587405457728366,
                            "lon": 61.444460193864295
                        }
                    ]
                ]
            },
            {
                "type": "MultiPolygon",
                "value": 6.0,
                "data": [
                    [
                        {
                            "lat": 55.58450068556576,
                            "lon": 61.43931412956887
                        },
                        {
                            "lat": 55.58091817580555,
                            "lon": 61.43977762235035
                        },
                        {
                            "lat": 55.581886132921454,
                            "lon": 61.441493019358454
                        },
                        {
                            "lat": 55.585468820821745,
                            "lon": 61.441029493685576
                        },
                        {
                            "lat": 55.58450068556576,
                            "lon": 61.43931412956887
                        }
                    ],
                    [
                        {
                            "lat": 55.59524748215332,
                            "lon": 61.437923098053666
                        },
                        {
                            "lat": 55.591665338758006,
                            "lon": 61.43838686741426
                        },
                        {
                            "lat": 55.59263383023051,
                            "lon": 61.44010216573151
                        },
                        {
                            "lat": 55.59621615170225,
                            "lon": 61.43963836346286
                        },
                        {
                            "lat": 55.59524748215332,
                            "lon": 61.437923098053666
                        }
                    ]
                ]
            },
            {
                "type": "Polygon",
                "value": 7.0,
                "data": [
                    [
                        {
                            "lat": 55.59524748215332,
                            "lon": 61.437923098053666
                        },
                        {
                            "lat": 55.594278934762734,
                            "lon": 61.436207823284704
                        },
                        {
                            "lat": 55.590696969425494,
                            "lon": 61.43667155974067
                        },
                        {
                            "lat": 55.591665338758006,
                            "lon": 61.43838686741426
                        },
                        {
                            "lat": 55.59524748215332,
                            "lon": 61.437923098053666
                        }
                    ]
                ]
            },
            {
                "type": "MultiPolygon",
                "value": 8.0,
                "data": [
                    [
                        {
                            "lat": 55.57898262780716,
                            "lon": 61.436346800297734
                        },
                        {
                            "lat": 55.579950340774516,
                            "lon": 61.43806221599627
                        },
                        {
                            "lat": 55.58091817580555,
                            "lon": 61.43977762235035
                        },
                        {
                            "lat": 55.58450068556576,
                            "lon": 61.43931412956887
                        },
                        {
                            "lat": 55.58353267241303,
                            "lon": 61.43759875610271
                        },
                        {
                            "lat": 55.582564781342334,
                            "lon": 61.435883373288675
                        },
                        {
                            "lat": 55.57898262780716,
                            "lon": 61.436346800297734
                        }
                    ],
                    [
                        {
                            "lat": 55.58614681281667,
                            "lon": 61.43541985409147
                        },
                        {
                            "lat": 55.58711488196947,
                            "lon": 61.43713520401542
                        },
                        {
                            "lat": 55.590696969425494,
                            "lon": 61.43667155974067
                        },
                        {
                            "lat": 55.58972872221178,
                            "lon": 61.43495624271234
                        },
                        {
                            "lat": 55.58614681281667,
                            "lon": 61.43541985409147
                        }
                    ],
                    [
                        {
                            "lat": 55.585468820821745,
                            "lon": 61.441029493685576
                        },
                        {
                            "lat": 55.5864370782022,
                            "lon": 61.44274484845125
                        },
                        {
                            "lat": 55.590019822115245,
                            "lon": 61.44228119767312
                        },
                        {
                            "lat": 55.58905138659755,
                            "lon": 61.44056587580784
                        },
                        {
                            "lat": 55.585468820821745,
                            "lon": 61.441029493685576
                        }
                    ]
                ]
            },
            {
                "type": "MultiPolygon",
                "value": 9.0,
                "data": [
                    [
                        {
                            "lat": 55.58808307322269,
                            "lon": 61.438850544588064
                        },
                        {
                            "lat": 55.58450068556576,
                            "lon": 61.43931412956887
                        },
                        {
                            "lat": 55.585468820821745,
                            "lon": 61.441029493685576
                        },
                        {
                            "lat": 55.58905138659755,
                            "lon": 61.44056587580784
                        },
                        {
                            "lat": 55.58808307322269,
                            "lon": 61.438850544588064
                        }
                    ],
                    [
                        {
                            "lat": 55.59718494343078,
                            "lon": 61.44135361951069
                        },
                        {
                            "lat": 55.593602443864235,
                            "lon": 61.441817454690856
                        },
                        {
                            "lat": 55.59457117968039,
                            "lon": 61.443532734290685
                        },
                        {
                            "lat": 55.5981538573601,
                            "lon": 61.443068866195574
                        },
                        {
                            "lat": 55.59718494343078,
                            "lon": 61.44135361951069
                        }
                    ]
                ]
            },
            {
                "type": "MultiPolygon",
                "value": 10.0,
                "data": [
                    [
                        {
                            "lat": 55.58614681281667,
                            "lon": 61.43541985409147
                        },
                        {
                            "lat": 55.582564781342334,
                            "lon": 61.435883373288675
                        },
                        {
                            "lat": 55.58353267241303,
                            "lon": 61.43759875610271
                        },
                        {
                            "lat": 55.58711488196947,
                            "lon": 61.43713520401542
                        },
                        {
                            "lat": 55.58614681281667,
                            "lon": 61.43541985409147
                        }
                    ],
                    [
                        {
                            "lat": 55.591665338758006,
                            "lon": 61.43838686741426
                        },
                        {
                            "lat": 55.58808307322269,
                            "lon": 61.438850544588064
                        },
                        {
                            "lat": 55.58905138659755,
                            "lon": 61.44056587580784
                        },
                        {
                            "lat": 55.59263383023051,
                            "lon": 61.44010216573151
                        },
                        {
                            "lat": 55.591665338758006,
                            "lon": 61.43838686741426
                        }
                    ]
                ]
            },
            {
                "type": "MultiPolygon",
                "value": 13.0,
                "data": [
                    [
                        {
                            "lat": 55.58711488196947,
                            "lon": 61.43713520401542
                        },
                        {
                            "lat": 55.58353267241303,
                            "lon": 61.43759875610271
                        },
                        {
                            "lat": 55.58450068556576,
                            "lon": 61.43931412956887
                        },
                        {
                            "lat": 55.58808307322269,
                            "lon": 61.438850544588064
                        },
                        {
                            "lat": 55.58711488196947,
                            "lon": 61.43713520401542
                        }
                    ],
                    [
                        {
                            "lat": 55.593602443864235,
                            "lon": 61.441817454690856
                        },
                        {
                            "lat": 55.590019822115245,
                            "lon": 61.44228119767312
                        },
                        {
                            "lat": 55.590988379797025,
                            "lon": 61.44399651018235
                        },
                        {
                            "lat": 55.59457117968039,
                            "lon": 61.443532734290685
                        },
                        {
                            "lat": 55.593602443864235,
                            "lon": 61.441817454690856
                        }
                    ]
                ]
            },
            {
                "type": "Polygon",
                "value": 16.0,
                "data": [
                    [
                        {
                            "lat": 55.591665338758006,
                            "lon": 61.43838686741426
                        },
                        {
                            "lat": 55.590696969425494,
                            "lon": 61.43667155974067
                        },
                        {
                            "lat": 55.58711488196947,
                            "lon": 61.43713520401542
                        },
                        {
                            "lat": 55.58808307322269,
                            "lon": 61.438850544588064
                        },
                        {
                            "lat": 55.591665338758006,
                            "lon": 61.43838686741426
                        }
                    ]
                ]
            },
            {
                "type": "Polygon",
                "value": 17.0,
                "data": [
                    [
                        {
                            "lat": 55.59718494343078,
                            "lon": 61.44135361951069
                        },
                        {
                            "lat": 55.59621615170225,
                            "lon": 61.43963836346286
                        },
                        {
                            "lat": 55.59263383023051,
                            "lon": 61.44010216573151
                        },
                        {
                            "lat": 55.593602443864235,
                            "lon": 61.441817454690856
                        },
                        {
                            "lat": 55.59718494343078,
                            "lon": 61.44135361951069
                        }
                    ]
                ]
            },
            {
                "type": "Polygon",
                "value": 22.0,
                "data": [
                    [
                        {
                            "lat": 55.593602443864235,
                            "lon": 61.441817454690856
                        },
                        {
                            "lat": 55.59263383023051,
                            "lon": 61.44010216573151
                        },
                        {
                            "lat": 55.58905138659755,
                            "lon": 61.44056587580784
                        },
                        {
                            "lat": 55.590019822115245,
                            "lon": 61.44228119767312
                        },
                        {
                            "lat": 55.593602443864235,
                            "lon": 61.441817454690856
                        }
                    ]
                ]
            }
        ]
    },
    "metadata": {
        "pollutant": "H2S",
        "datetime": "2023-01-14T23:00:00",
        "wind_speed_kph": 0.85,
        "wind_dir_deg": 80.0,
        "point_pollution_centres": {
            "0118": {
                "lat": 55.58839458788105,
                "lon": 61.420575093499394
            },
            "0207": {
                "lat": 55.56388058716606,
                "lon": 61.46000110873254
            },
            "0004": {
                "lat": 55.58409720477011,
                "lon": 61.42015868110498
            },
            "0042": {
                "lat": 55.593344130316616,
                "lon": 61.419447285382724
            },
            "0304": {
                "lat": 55.55531125778275,
                "lon": 61.46013495129219
            },
            "0310": {
                "lat": 55.553286409392584,
                "lon": 61.46015246832853
            },
            "0027": {
                "lat": 55.550640201175995,
                "lon": 61.45635845727941
            },
            "0048": {
                "lat": 55.557529491298546,
                "lon": 61.45595495417675
            },
            "0110": {
                "lat": 55.559576004979824,
                "lon": 61.45958431248594
            },
            "0111": {
                "lat": 55.55583760363411,
                "lon": 61.45836360990409
            },
            "0010": {
                "lat": 55.585649482738674,
                "lon": 61.422025648155326
            },
            "0063": {
                "lat": 55.596093277028835,
                "lon": 61.44233064987615
            }
        },
        "areal_pollution_centres": {
            "156": {
                "lat": 55.59438447618462,
                "lon": 61.43840297537321
            },
            "157": {
                "lat": 55.591118750938165,
                "lon": 61.43799745681853
            }
        }
    }
};

export const BOUNDARY_DATA_CHEL = {
    "geometry": {
        "heightmap": [
            {
                "type": "MultiLineString",
                "value": 100.0,
                "data": [
                    [
                        {
                            "lat": 61.429428578730246,
                            "lon": 55.10363981947256
                        },
                        {
                            "lat": 61.4262984440452,
                            "lon": 55.10357282966457
                        },
                        {
                            "lat": 61.42618152951369,
                            "lon": 55.10536750912527
                        },
                        {
                            "lat": 61.42606460194944,
                            "lon": 55.10716218788675
                        },
                        {
                            "lat": 61.4229342003412,
                            "lon": 55.10709510881547
                        },
                        {
                            "lat": 61.42281711974192,
                            "lon": 55.10888978242609
                        },
                        {
                            "lat": 61.425947661350484,
                            "lon": 55.10895686594896
                        },
                        {
                            "lat": 61.429078215995716,
                            "lon": 55.10902386909457
                        },
                        {
                            "lat": 61.42896140237791,
                            "lon": 55.11081855090419
                        },
                        {
                            "lat": 61.428844575736974,
                            "lon": 55.112613232014915
                        },
                        {
                            "lat": 61.42571374104047,
                            "lon": 55.112546219975584
                        },
                        {
                            "lat": 61.42559676132548,
                            "lon": 55.11434089593994
                        },
                        {
                            "lat": 61.425479768567854,
                            "lon": 55.11613557120497
                        },
                        {
                            "lat": 61.42234866680247,
                            "lon": 55.11606846987176
                        },
                        {
                            "lat": 61.42223152092211,
                            "lon": 55.11786313998389
                        },
                        {
                            "lat": 61.4221143619796,
                            "lon": 55.119657809396294
                        },
                        {
                            "lat": 61.425245743916754,
                            "lon": 55.119724919637065
                        },
                        {
                            "lat": 61.42512871201933,
                            "lon": 55.121519592804084
                        },
                        {
                            "lat": 61.4282602471163,
                            "lon": 55.12158662708473
                        },
                        {
                            "lat": 61.428143342295115,
                            "lon": 55.123381304001846
                        },
                        {
                            "lat": 61.42802642443696,
                            "lon": 55.125175980219986
                        },
                        {
                            "lat": 61.42790949353985,
                            "lon": 55.126970655739115
                        },
                        {
                            "lat": 61.42779254960181,
                            "lon": 55.128765330559226
                        },
                        {
                            "lat": 61.42767559262086,
                            "lon": 55.13056000468034
                        },
                        {
                            "lat": 61.427558622595015,
                            "lon": 55.132354678102416
                        },
                        {
                            "lat": 61.427441639522286,
                            "lon": 55.13414935082543
                        },
                        {
                            "lat": 61.430574168882885,
                            "lon": 55.13421633580806
                        },
                        {
                            "lat": 61.43045731299916,
                            "lon": 55.13601101227893
                        },
                        {
                            "lat": 61.430340444080166,
                            "lon": 55.137805688051124
                        },
                        {
                            "lat": 61.43347326696931,
                            "lon": 55.13787260146408
                        },
                        {
                            "lat": 61.43660610288009,
                            "lon": 55.13793943441243
                        },
                        {
                            "lat": 61.436489501467896,
                            "lon": 55.13973411836536
                        },
                        {
                            "lat": 61.439622490659694,
                            "lon": 55.13980087528007
                        },
                        {
                            "lat": 61.44275549284391,
                            "lon": 55.13986755172342
                        },
                        {
                            "lat": 61.442871813704166,
                            "lon": 55.138072858912366
                        },
                        {
                            "lat": 61.44298812158956,
                            "lon": 55.13627816540424
                        },
                        {
                            "lat": 61.443104416502074,
                            "lon": 55.13448347119899
                        },
                        {
                            "lat": 61.44623701087312,
                            "lon": 55.13455005390818
                        },
                        {
                            "lat": 61.44612085620833,
                            "lon": 55.1363447525337
                        },
                        {
                            "lat": 61.44925360378439,
                            "lon": 55.13641125920097
                        },
                        {
                            "lat": 61.44936961819947,
                            "lon": 55.13461655616051
                        },
                        {
                            "lat": 61.44948561967482,
                            "lon": 55.13282185242375
                        },
                        {
                            "lat": 61.44960160821239,
                            "lon": 55.13102714799072
                        },
                        {
                            "lat": 61.44646928133868,
                            "lon": 55.130960654567104
                        },
                        {
                            "lat": 61.44658539714341,
                            "lon": 55.12916595385158
                        },
                        {
                            "lat": 61.44345322342204,
                            "lon": 55.12909938440084
                        },
                        {
                            "lat": 61.44356946646274,
                            "lon": 55.12730468740738
                        },
                        {
                            "lat": 61.443685696540406,
                            "lon": 55.12550998971688
                        },
                        {
                            "lat": 61.440553816135285,
                            "lon": 55.12544334867362
                        },
                        {
                            "lat": 61.44067017340414,
                            "lon": 55.123648654709335
                        },
                        {
                            "lat": 61.437538446125956,
                            "lon": 55.12358193766586
                        },
                        {
                            "lat": 61.437654930554864,
                            "lon": 55.121787247432316
                        },
                        {
                            "lat": 61.4377714019955,
                            "lon": 55.119992556501025
                        },
                        {
                            "lat": 61.43788786044982,
                            "lon": 55.11819786487196
                        },
                        {
                            "lat": 61.43800430591983,
                            "lon": 55.116403172545134
                        },
                        {
                            "lat": 61.438120738407505,
                            "lon": 55.11460847952056
                        },
                        {
                            "lat": 61.43823715791478,
                            "lon": 55.112813785798274
                        },
                        {
                            "lat": 61.441368044618265,
                            "lon": 55.112880476278754
                        },
                        {
                            "lat": 61.44148431110584,
                            "lon": 55.11108577743296
                        },
                        {
                            "lat": 61.44160056463252,
                            "lon": 55.10929107788988
                        },
                        {
                            "lat": 61.44171680520028,
                            "lon": 55.107496377649504
                        },
                        {
                            "lat": 61.441833032811104,
                            "lon": 55.10570167671185
                        },
                        {
                            "lat": 61.43870270617946,
                            "lon": 55.10563500393214
                        },
                        {
                            "lat": 61.43557239251835,
                            "lon": 55.10556825078291
                        },
                        {
                            "lat": 61.43568888713038,
                            "lon": 55.10377355800172
                        },
                        {
                            "lat": 61.43580536875829,
                            "lon": 55.10197886452247
                        },
                        {
                            "lat": 61.43893540248076,
                            "lon": 55.10204560881307
                        },
                        {
                            "lat": 61.43905173118051,
                            "lon": 55.10025091020706
                        },
                        {
                            "lat": 61.44218163792157,
                            "lon": 55.10031756971533
                        },
                        {
                            "lat": 61.44531155761171,
                            "lon": 55.10038414886932
                        },
                        {
                            "lat": 61.44542759348002,
                            "lon": 55.098589440724986
                        },
                        {
                            "lat": 61.44554361641677,
                            "lon": 55.09679473188382
                        },
                        {
                            "lat": 61.442413976579886,
                            "lon": 55.096728161564876
                        },
                        {
                            "lat": 61.43928434968814,
                            "lon": 55.09666151090222
                        },
                        {
                            "lat": 61.43615473575708,
                            "lon": 55.09659477989667
                        },
                        {
                            "lat": 61.43627116546826,
                            "lon": 55.09480008362539
                        },
                        {
                            "lat": 61.43940063949994,
                            "lon": 55.09486681020342
                        },
                        {
                            "lat": 61.442530126490325,
                            "lon": 55.094933456443826
                        },
                        {
                            "lat": 61.445659626423904,
                            "lon": 55.095000022345864
                        },
                        {
                            "lat": 61.44878913928512,
                            "lon": 55.09506650790885
                        },
                        {
                            "lat": 61.448904996474866,
                            "lon": 55.0932717932629
                        },
                        {
                            "lat": 61.45203438235649,
                            "lon": 55.093338194080204
                        },
                        {
                            "lat": 61.45215008675994,
                            "lon": 55.09154347433236
                        },
                        {
                            "lat": 61.455279345658056,
                            "lon": 55.09160979041427
                        },
                        {
                            "lat": 61.45539489730627,
                            "lon": 55.08981506557066
                        },
                        {
                            "lat": 61.45852402921697,
                            "lon": 55.08988129692751
                        },
                        {
                            "lat": 61.458639428141026,
                            "lon": 55.08808656699427
                        },
                        {
                            "lat": 61.45875481420741,
                            "lon": 55.08629183636585
                        },
                        {
                            "lat": 61.46188367929134,
                            "lon": 55.086357978619546
                        },
                        {
                            "lat": 61.46199891268212,
                            "lon": 55.08456324290799
                        },
                        {
                            "lat": 61.462114133234635,
                            "lon": 55.08276850650171
                        },
                        {
                            "lat": 61.465242731530616,
                            "lon": 55.08283455967435
                        },
                        {
                            "lat": 61.46535779945605,
                            "lon": 55.08103981819143
                        },
                        {
                            "lat": 61.46848627078007,
                            "lon": 55.08110578668132
                        },
                        {
                            "lat": 61.46860118610948,
                            "lon": 55.079311040127884
                        },
                        {
                            "lat": 61.46871608863751,
                            "lon": 55.077516292880524
                        },
                        {
                            "lat": 61.468830978366114,
                            "lon": 55.07572154493925
                        },
                        {
                            "lat": 61.47195904320256,
                            "lon": 55.0757874200161
                        },
                        {
                            "lat": 61.4720737804011,
                            "lon": 55.07399266701121
                        },
                        {
                            "lat": 61.47218850481959,
                            "lon": 55.07219791331283
                        },
                        {
                            "lat": 61.47230321646002,
                            "lon": 55.07040315892095
                        },
                        {
                            "lat": 61.47241791532429,
                            "lon": 55.06860840383559
                        },
                        {
                            "lat": 61.47253260141434,
                            "lon": 55.0668136480568
                        },
                        {
                            "lat": 61.4756599805152,
                            "lon": 55.06687942102924
                        },
                        {
                            "lat": 61.475774514176,
                            "lon": 55.06508466019453
                        },
                        {
                            "lat": 61.47588903508197,
                            "lon": 55.06328989866677
                        },
                        {
                            "lat": 61.47901614763726,
                            "lon": 55.0633555826648
                        },
                        {
                            "lat": 61.48214327292995,
                            "lon": 55.0634211864122
                        },
                        {
                            "lat": 61.48225750182526,
                            "lon": 55.061626415484
                        },
                        {
                            "lat": 61.48538450020807,
                            "lon": 55.06169193463432
                        },
                        {
                            "lat": 61.48549857676713,
                            "lon": 55.05989715866853
                        },
                        {
                            "lat": 61.48237171800059,
                            "lon": 55.059831643863546
                        },
                        {
                            "lat": 61.48248592145788,
                            "lon": 55.05803687155087
                        },
                        {
                            "lat": 61.48260011219907,
                            "lon": 55.05624209854595
                        },
                        {
                            "lat": 61.48572669177955,
                            "lon": 55.0563076046614
                        },
                        {
                            "lat": 61.485840730236745,
                            "lon": 55.05451282662008
                        },
                        {
                            "lat": 61.482714290226085,
                            "lon": 55.05444732484883
                        },
                        {
                            "lat": 61.48282845554081,
                            "lon": 55.05265255045951
                        },
                        {
                            "lat": 61.47970216779478,
                            "lon": 55.05258697281257
                        },
                        {
                            "lat": 61.476575892774534,
                            "lon": 55.05252131494682
                        },
                        {
                            "lat": 61.47646144835696,
                            "lon": 55.05431608063264
                        },
                        {
                            "lat": 61.47634699119617,
                            "lon": 55.05611084562547
                        },
                        {
                            "lat": 61.47322044980472,
                            "lon": 55.05604509882184
                        },
                        {
                            "lat": 61.47333504652953,
                            "lon": 55.054250338189114
                        },
                        {
                            "lat": 61.4702086574608,
                            "lon": 55.05418451552291
                        },
                        {
                            "lat": 61.470323380973454,
                            "lon": 55.052389758561844
                        },
                        {
                            "lat": 61.46719714422359,
                            "lon": 55.052323860044034
                        },
                        {
                            "lat": 61.46407092026147,
                            "lon": 55.05225788131029
                        },
                        {
                            "lat": 61.46395591766147,
                            "lon": 55.05405262952539
                        },
                        {
                            "lat": 61.46384090225628,
                            "lon": 55.05584737704596
                        },
                        {
                            "lat": 61.46372587404396,
                            "lon": 55.05764212387197
                        },
                        {
                            "lat": 61.46361083302257,
                            "lon": 55.05943687000341
                        },
                        {
                            "lat": 61.46048406360772,
                            "lon": 55.059370793528345
                        },
                        {
                            "lat": 61.46036887017259,
                            "lon": 55.061165534582734
                        },
                        {
                            "lat": 61.460253663909,
                            "lon": 55.06296027494214
                        },
                        {
                            "lat": 61.460138444815,
                            "lon": 55.064755014606554
                        },
                        {
                            "lat": 61.46002321288868,
                            "lon": 55.06654975357598
                        },
                        {
                            "lat": 61.45689589780422,
                            "lon": 55.06648357931158
                        },
                        {
                            "lat": 61.456780513380835,
                            "lon": 55.06827831319661
                        },
                        {
                            "lat": 61.45365307148532,
                            "lon": 55.0682120542813
                        },
                        {
                            "lat": 61.45353753453384,
                            "lon": 55.070006783075826
                        },
                        {
                            "lat": 61.453421984713174,
                            "lon": 55.07180151117454
                        },
                        {
                            "lat": 61.45654970597685,
                            "lon": 55.0718677788804
                        },
                        {
                            "lat": 61.45643428299236,
                            "lon": 55.07366251067913
                        },
                        {
                            "lat": 61.45330642202135,
                            "lon": 55.073596238577416
                        },
                        {
                            "lat": 61.45017857392341,
                            "lon": 55.07352988619893
                        },
                        {
                            "lat": 61.450062858637565,
                            "lon": 55.07532460850441
                        },
                        {
                            "lat": 61.44693488370929,
                            "lon": 55.07525817144302
                        },
                        {
                            "lat": 61.4468190157984,
                            "lon": 55.07705288864495
                        },
                        {
                            "lat": 61.4436909140437,
                            "lon": 55.07698636689036
                        },
                        {
                            "lat": 61.44357489347674,
                            "lon": 55.07878107898266
                        },
                        {
                            "lat": 61.44670313497947,
                            "lon": 55.07884760515022
                        },
                        {
                            "lat": 61.44658724125054,
                            "lon": 55.08064232095883
                        },
                        {
                            "lat": 61.44345885998427,
                            "lon": 55.08057579037789
                        },
                        {
                            "lat": 61.440330491645476,
                            "lon": 55.080509179501114
                        },
                        {
                            "lat": 61.4402143054484,
                            "lon": 55.0823038857802
                        },
                        {
                            "lat": 61.4433428135643,
                            "lon": 55.08237050107606
                        },
                        {
                            "lat": 61.44647133460964,
                            "lon": 55.08243703607075
                        },
                        {
                            "lat": 61.446355415054825,
                            "lon": 55.08423175048598
                        },
                        {
                            "lat": 61.44322675421491,
                            "lon": 55.08416521107712
                        },
                        {
                            "lat": 61.443110681934094,
                            "lon": 55.08595992038109
                        },
                        {
                            "lat": 61.442994596719934,
                            "lon": 55.08775462898792
                        },
                        {
                            "lat": 61.43986566917957,
                            "lon": 55.087688000432486
                        },
                        {
                            "lat": 61.43974943119086,
                            "lon": 55.08948270392155
                        },
                        {
                            "lat": 61.436620376764104,
                            "lon": 55.089415990623785
                        },
                        {
                            "lat": 61.433491335305725,
                            "lon": 55.08934919700505
                        },
                        {
                            "lat": 61.433374804660595,
                            "lon": 55.091143890938525
                        },
                        {
                            "lat": 61.43325826102964,
                            "lon": 55.09293858417368
                        },
                        {
                            "lat": 61.43314170441085,
                            "lon": 55.09473327671044
                        },
                        {
                            "lat": 61.433025134802264,
                            "lon": 55.09652796854886
                        },
                        {
                            "lat": 61.429895546839205,
                            "lon": 55.09646107685958
                        },
                        {
                            "lat": 61.42977882432786,
                            "lon": 55.098255763561035
                        },
                        {
                            "lat": 61.42966208880724,
                            "lon": 55.100050449563675
                        },
                        {
                            "lat": 61.42954534027536,
                            "lon": 55.10184513486754
                        },
                        {
                            "lat": 61.429428578730246,
                            "lon": 55.10363981947256
                        }
                    ],
                    [
                        {
                            "lat": 61.46696740531929,
                            "lon": 55.05591336453136
                        },
                        {
                            "lat": 61.47009392117394,
                            "lon": 55.05597927179024
                        },
                        {
                            "lat": 61.46997917211092,
                            "lon": 55.05777402736376
                        },
                        {
                            "lat": 61.46685251668065,
                            "lon": 55.05770811573378
                        },
                        {
                            "lat": 61.46696740531929,
                            "lon": 55.05591336453136
                        }
                    ],
                    [
                        {
                            "lat": 61.43927302928563,
                            "lon": 55.14518493624889
                        },
                        {
                            "lat": 61.439389529406036,
                            "lon": 55.143390249956816
                        },
                        {
                            "lat": 61.43625625961748,
                            "lon": 55.14332348417745
                        },
                        {
                            "lat": 61.43613961917531,
                            "lon": 55.1451181660366
                        },
                        {
                            "lat": 61.43927302928563,
                            "lon": 55.14518493624889
                        }
                    ],
                    [
                        {
                            "lat": 61.4490215361272,
                            "lon": 55.140000663192964
                        },
                        {
                            "lat": 61.44913757642763,
                            "lon": 55.138205961545125
                        },
                        {
                            "lat": 61.446004688586264,
                            "lon": 55.1381394504625
                        },
                        {
                            "lat": 61.445888508004934,
                            "lon": 55.139934147694625
                        },
                        {
                            "lat": 61.4457723144624,
                            "lon": 55.14172884423
                        },
                        {
                            "lat": 61.44890548288115,
                            "lon": 55.141795364144485
                        },
                        {
                            "lat": 61.4490215361272,
                            "lon": 55.140000663192964
                        }
                    ],
                    [
                        {
                            "lat": 61.44263915900683,
                            "lon": 55.141662243837345
                        },
                        {
                            "lat": 61.43950601653005,
                            "lon": 55.14159556296722
                        },
                        {
                            "lat": 61.439389529406036,
                            "lon": 55.143390249956816
                        },
                        {
                            "lat": 61.44252281219095,
                            "lon": 55.14345693525412
                        },
                        {
                            "lat": 61.44263915900683,
                            "lon": 55.141662243837345
                        }
                    ],
                    [
                        {
                            "lat": 61.44867333754427,
                            "lon": 55.145384763958496
                        },
                        {
                            "lat": 61.445539888485726,
                            "lon": 55.145318235210546
                        },
                        {
                            "lat": 61.44542365604767,
                            "lon": 55.14711292965568
                        },
                        {
                            "lat": 61.44855724544953,
                            "lon": 55.14717946282095
                        },
                        {
                            "lat": 61.44867333754427,
                            "lon": 55.145384763958496
                        }
                    ],
                    [
                        {
                            "lat": 61.44519115226225,
                            "lon": 55.1507023164556
                        },
                        {
                            "lat": 61.445307410640496,
                            "lon": 55.148907623404035
                        },
                        {
                            "lat": 61.44217369385081,
                            "lon": 55.14884100532157
                        },
                        {
                            "lat": 61.44205729510001,
                            "lon": 55.150635693949695
                        },
                        {
                            "lat": 61.44519115226225,
                            "lon": 55.1507023164556
                        }
                    ],
                    [
                        {
                            "lat": 61.44867333754427,
                            "lon": 55.145384763958496
                        },
                        {
                            "lat": 61.45180679955436,
                            "lon": 55.14545121221689
                        },
                        {
                            "lat": 61.451922738367905,
                            "lon": 55.14365650824645
                        },
                        {
                            "lat": 61.455056072982295,
                            "lon": 55.1437228716083
                        },
                        {
                            "lat": 61.45517185854584,
                            "lon": 55.14192816253605
                        },
                        {
                            "lat": 61.452038664247496,
                            "lon": 55.14186180358009
                        },
                        {
                            "lat": 61.44890548288115,
                            "lon": 55.141795364144485
                        },
                        {
                            "lat": 61.4487894166875,
                            "lon": 55.14359006439967
                        },
                        {
                            "lat": 61.44867333754427,
                            "lon": 55.145384763958496
                        }
                    ],
                    [
                        {
                            "lat": 61.45772671048561,
                            "lon": 55.150968001423756
                        },
                        {
                            "lat": 61.46086063235699,
                            "lon": 55.15103422139831
                        },
                        {
                            "lat": 61.46074506297605,
                            "lon": 55.15282893579229
                        },
                        {
                            "lat": 61.46387913814237,
                            "lon": 55.15289507965025
                        },
                        {
                            "lat": 61.46701322619002,
                            "lon": 55.152961142993725
                        },
                        {
                            "lat": 61.46712851476906,
                            "lon": 55.15116641982139
                        },
                        {
                            "lat": 61.46724379048437,
                            "lon": 55.14937169595499
                        },
                        {
                            "lat": 61.46410998322495,
                            "lon": 55.14930564138418
                        },
                        {
                            "lat": 61.464225386449144,
                            "lon": 55.14751092120946
                        },
                        {
                            "lat": 61.464340776797876,
                            "lon": 55.145716200340374
                        },
                        {
                            "lat": 61.46120726313773,
                            "lon": 55.14565007404745
                        },
                        {
                            "lat": 61.46132278095049,
                            "lon": 55.14385535687421
                        },
                        {
                            "lat": 61.45818942051501,
                            "lon": 55.143789154484466
                        },
                        {
                            "lat": 61.4580737623667,
                            "lon": 55.14558386726209
                        },
                        {
                            "lat": 61.454940274500366,
                            "lon": 55.14551757998499
                        },
                        {
                            "lat": 61.45482446309812,
                            "lon": 55.14731228766608
                        },
                        {
                            "lat": 61.45169084780489,
                            "lon": 55.14724591549131
                        },
                        {
                            "lat": 61.451574883117516,
                            "lon": 55.14904061806977
                        },
                        {
                            "lat": 61.448441140401265,
                            "lon": 55.148974160987024
                        },
                        {
                            "lat": 61.44832502239754,
                            "lon": 55.15076885845669
                        },
                        {
                            "lat": 61.45145890549029,
                            "lon": 55.15083531995223
                        },
                        {
                            "lat": 61.45459280152484,
                            "lon": 55.15090170094149
                        },
                        {
                            "lat": 61.45470863877362,
                            "lon": 55.14910699465158
                        },
                        {
                            "lat": 61.45784240735395,
                            "lon": 55.149173290731746
                        },
                        {
                            "lat": 61.45772671048561,
                            "lon": 55.150968001423756
                        }
                    ],
                    [
                        {
                            "lat": 61.45749527801516,
                            "lon": 55.15455742072214
                        },
                        {
                            "lat": 61.46062948069816,
                            "lon": 55.15462364949143
                        },
                        {
                            "lat": 61.46074506297605,
                            "lon": 55.15282893579229
                        },
                        {
                            "lat": 61.457611000706684,
                            "lon": 55.15276271142056
                        },
                        {
                            "lat": 61.454476951349854,
                            "lon": 55.15269640653579
                        },
                        {
                            "lat": 61.45134291492122,
                            "lon": 55.152630021138684
                        },
                        {
                            "lat": 61.45122691140837,
                            "lon": 55.1544247216291
                        },
                        {
                            "lat": 61.45111089494975,
                            "lon": 55.15621942142347
                        },
                        {
                            "lat": 61.45424521221337,
                            "lon": 55.15628581563746
                        },
                        {
                            "lat": 61.45436108824669,
                            "lon": 55.15449111143445
                        },
                        {
                            "lat": 61.45749527801516,
                            "lon": 55.15455742072214
                        }
                    ],
                    [
                        {
                            "lat": 61.46364824153446,
                            "lon": 55.156484515138544
                        },
                        {
                            "lat": 61.46376369628006,
                            "lon": 55.15468979774163
                        },
                        {
                            "lat": 61.46062948069816,
                            "lon": 55.15462364949143
                        },
                        {
                            "lat": 61.46051388552137,
                            "lon": 55.156418362495714
                        },
                        {
                            "lat": 61.46364824153446,
                            "lon": 55.156484515138544
                        }
                    ],
                    [
                        {
                            "lat": 61.48862544823619,
                            "lon": 55.05996259323207
                        },
                        {
                            "lat": 61.48873937249,
                            "lon": 55.05816781223475
                        },
                        {
                            "lat": 61.48561264062364,
                            "lon": 55.05810238201087
                        },
                        {
                            "lat": 61.48549857676713,
                            "lon": 55.05989715866853
                        },
                        {
                            "lat": 61.48862544823619,
                            "lon": 55.05996259323207
                        }
                    ],
                    [
                        {
                            "lat": 61.48245464765644,
                            "lon": 55.156879739942106
                        },
                        {
                            "lat": 61.482569259773776,
                            "lon": 55.15508499630137
                        },
                        {
                            "lat": 61.47943496712646,
                            "lon": 55.155019331180895
                        },
                        {
                            "lat": 61.4793202145662,
                            "lon": 55.156814070461
                        },
                        {
                            "lat": 61.48245464765644,
                            "lon": 55.156879739942106
                        }
                    ],
                    [
                        {
                            "lat": 61.46968660588218,
                            "lon": 55.160206029099314
                        },
                        {
                            "lat": 61.469801805469466,
                            "lon": 55.158411304320516
                        },
                        {
                            "lat": 61.46666728325076,
                            "lon": 55.15834530834635
                        },
                        {
                            "lat": 61.46655194319711,
                            "lon": 55.160140028742404
                        },
                        {
                            "lat": 61.46968660588218,
                            "lon": 55.160206029099314
                        }
                    ],
                    [
                        {
                            "lat": 61.469456168131934,
                            "lon": 55.163795476575736
                        },
                        {
                            "lat": 61.46632122446724,
                            "lon": 55.16372946745213
                        },
                        {
                            "lat": 61.466205845787094,
                            "lon": 55.16552418576582
                        },
                        {
                            "lat": 61.46934092996505,
                            "lon": 55.165590199273346
                        },
                        {
                            "lat": 61.469456168131934,
                            "lon": 55.163795476575736
                        }
                    ],
                    [
                        {
                            "lat": 61.487693996720346,
                            "lon": 55.17316357731716
                        },
                        {
                            "lat": 61.49082973288039,
                            "lon": 55.17322904432697
                        },
                        {
                            "lat": 61.490715414569905,
                            "lon": 55.175023794107474
                        },
                        {
                            "lat": 61.49060108349536,
                            "lon": 55.176818543196916
                        },
                        {
                            "lat": 61.49373711364725,
                            "lon": 55.17688393831388
                        },
                        {
                            "lat": 61.493622910445104,
                            "lon": 55.17867869105618
                        },
                        {
                            "lat": 61.49675909398762,
                            "lon": 55.17874400992075
                        },
                        {
                            "lat": 61.49664501868915,
                            "lon": 55.18053876631134
                        },
                        {
                            "lat": 61.499781355626155,
                            "lon": 55.180604008912546
                        },
                        {
                            "lat": 61.49966740826269,
                            "lon": 55.182398768946854
                        },
                        {
                            "lat": 61.50280389859806,
                            "lon": 55.182463935273645
                        },
                        {
                            "lat": 61.5026900792009,
                            "lon": 55.18425869894712
                        },
                        {
                            "lat": 61.50582672293853,
                            "lon": 55.184323788988515
                        },
                        {
                            "lat": 61.50571303153902,
                            "lon": 55.18611855629657
                        },
                        {
                            "lat": 61.50884982868274,
                            "lon": 55.18618357004154
                        },
                        {
                            "lat": 61.508736265312194,
                            "lon": 55.187978340979576
                        },
                        {
                            "lat": 61.511873215865904,
                            "lon": 55.188043278417176
                        },
                        {
                            "lat": 61.511986638508176,
                            "lon": 55.186248503165274
                        },
                        {
                            "lat": 61.515123460999654,
                            "lon": 55.18631335566702
                        },
                        {
                            "lat": 61.51826029614149,
                            "lon": 55.18637812754607
                        },
                        {
                            "lat": 61.518373424686736,
                            "lon": 55.1845833429948
                        },
                        {
                            "lat": 61.51523673026136,
                            "lon": 55.184518575418544
                        },
                        {
                            "lat": 61.51534998687461,
                            "lon": 55.18272379448202
                        },
                        {
                            "lat": 61.51221344579655,
                            "lon": 55.182658950596185
                        },
                        {
                            "lat": 61.50907691738066,
                            "lon": 55.182594026099025
                        },
                        {
                            "lat": 61.50919044271188,
                            "lon": 55.18079925309455
                        },
                        {
                            "lat": 61.50930395536719,
                            "lon": 55.17900447940128
                        },
                        {
                            "lat": 61.50941745534851,
                            "lon": 55.17720970501924
                        },
                        {
                            "lat": 61.509530942657776,
                            "lon": 55.175414929948424
                        },
                        {
                            "lat": 61.5096444172969,
                            "lon": 55.17362015418887
                        },
                        {
                            "lat": 61.50650860479904,
                            "lon": 55.17355517066747
                        },
                        {
                            "lat": 61.50337280498502,
                            "lon": 55.1734901065629
                        },
                        {
                            "lat": 61.50348654815617,
                            "lon": 55.171695338752144
                        },
                        {
                            "lat": 61.50035090163887,
                            "lon": 55.17163019839189
                        },
                        {
                            "lat": 61.497215267834775,
                            "lon": 55.1715649774552
                        },
                        {
                            "lat": 61.49407964675954,
                            "lon": 55.171499675942805
                        },
                        {
                            "lat": 61.49094403842881,
                            "lon": 55.17143429385541
                        },
                        {
                            "lat": 61.49105833121706,
                            "lon": 55.16963954269277
                        },
                        {
                            "lat": 61.4879228762203,
                            "lon": 55.16957408437884
                        },
                        {
                            "lat": 61.488037296808514,
                            "lon": 55.16777933687252
                        },
                        {
                            "lat": 61.48490199514184,
                            "lon": 55.16771380234305
                        },
                        {
                            "lat": 61.48501654349875,
                            "lon": 55.165919058497636
                        },
                        {
                            "lat": 61.48188139515827,
                            "lon": 55.16585344776362
                        },
                        {
                            "lat": 61.478746259619015,
                            "lon": 55.16578775647346
                        },
                        {
                            "lat": 61.47561113689664,
                            "lon": 55.16572198462789
                        },
                        {
                            "lat": 61.475496166929624,
                            "lon": 55.16751671538436
                        },
                        {
                            "lat": 61.47863143018676,
                            "lon": 55.16758249159826
                        },
                        {
                            "lat": 61.48176670626269,
                            "lon": 55.16764818725138
                        },
                        {
                            "lat": 61.481652004563955,
                            "lon": 55.16944292604697
                        },
                        {
                            "lat": 61.484787433997376,
                            "lon": 55.16950854549667
                        },
                        {
                            "lat": 61.48467286006343,
                            "lon": 55.17130328795845
                        },
                        {
                            "lat": 61.48780844285821,
                            "lon": 55.171368831193725
                        },
                        {
                            "lat": 61.487693996720346,
                            "lon": 55.17316357731716
                        }
                    ],
                    [
                        {
                            "lat": 61.52139714391803,
                            "lon": 55.18644281880173
                        },
                        {
                            "lat": 61.51826029614149,
                            "lon": 55.18637812754607
                        },
                        {
                            "lat": 61.518147154961525,
                            "lon": 55.188172911409644
                        },
                        {
                            "lat": 61.521284143472116,
                            "lon": 55.18823760696314
                        },
                        {
                            "lat": 61.52139714391803,
                            "lon": 55.18644281880173
                        }
                    ],
                    [
                        {
                            "lat": 61.520945066400934,
                            "lon": 55.19362196732363
                        },
                        {
                            "lat": 61.52083201545967,
                            "lon": 55.19541675273579
                        },
                        {
                            "lat": 61.5239695796918,
                            "lon": 55.19548138483367
                        },
                        {
                            "lat": 61.527107156536914,
                            "lon": 55.19554593628001
                        },
                        {
                            "lat": 61.5302447459793,
                            "lon": 55.195610407074135
                        },
                        {
                            "lat": 61.53013210485817,
                            "lon": 55.19740520466619
                        },
                        {
                            "lat": 61.533269847702265,
                            "lon": 55.197469599085764
                        },
                        {
                            "lat": 61.53315733483337,
                            "lon": 55.199264400270366
                        },
                        {
                            "lat": 61.533044809394745,
                            "lon": 55.20105920076917
                        },
                        {
                            "lat": 61.532932271384446,
                            "lon": 55.202854000582114
                        },
                        {
                            "lat": 61.53607044935652,
                            "lon": 55.20291832716409
                        },
                        {
                            "lat": 61.53920863988648,
                            "lon": 55.20298257307038
                        },
                        {
                            "lat": 61.53932089615674,
                            "lon": 55.2011877647144
                        },
                        {
                            "lat": 61.53943313988668,
                            "lon": 55.19939295567337
                        },
                        {
                            "lat": 61.53954537107826,
                            "lon": 55.19759814594727
                        },
                        {
                            "lat": 61.542683151578814,
                            "lon": 55.19766229838778
                        },
                        {
                            "lat": 61.54279522940808,
                            "lon": 55.19586748371428
                        },
                        {
                            "lat": 61.539657589733366,
                            "lon": 55.1958033355361
                        },
                        {
                            "lat": 61.53976979585391,
                            "lon": 55.194008524439894
                        },
                        {
                            "lat": 61.53663230952199,
                            "lon": 55.19394429987398
                        },
                        {
                            "lat": 61.536744643902445,
                            "lon": 55.19214949235967
                        },
                        {
                            "lat": 61.5336073109095,
                            "lon": 55.19208519141705
                        },
                        {
                            "lat": 61.533719773518506,
                            "lon": 55.190290387489235
                        },
                        {
                            "lat": 61.53058259386073,
                            "lon": 55.19022601018089
                        },
                        {
                            "lat": 61.52744542677881,
                            "lon": 55.19016155223565
                        },
                        {
                            "lat": 61.52755815834048,
                            "lon": 55.188366756181104
                        },
                        {
                            "lat": 61.52442114460366,
                            "lon": 55.1883022218872
                        },
                        {
                            "lat": 61.521284143472116,
                            "lon": 55.18823760696314
                        },
                        {
                            "lat": 61.521171130405236,
                            "lon": 55.19003239443728
                        },
                        {
                            "lat": 61.521058104715486,
                            "lon": 55.191827181224106
                        },
                        {
                            "lat": 61.5179208346898,
                            "lon": 55.191762477073794
                        },
                        {
                            "lat": 61.51780765559418,
                            "lon": 55.19355725887431
                        },
                        {
                            "lat": 61.520945066400934,
                            "lon": 55.19362196732363
                        }
                    ],
                    [
                        {
                            "lat": 61.58683358596946,
                            "lon": 55.194962214575135
                        },
                        {
                            "lat": 61.586943667441304,
                            "lon": 55.19316733943142
                        },
                        {
                            "lat": 61.583806135587096,
                            "lon": 55.19310432887073
                        },
                        {
                            "lat": 61.58369591329432,
                            "lon": 55.194899199827695
                        },
                        {
                            "lat": 61.58683358596946,
                            "lon": 55.194962214575135
                        }
                    ],
                    [
                        {
                            "lat": 61.60737828464673,
                            "lon": 55.21873475361555
                        },
                        {
                            "lat": 61.6042386939488,
                            "lon": 55.218672249528126
                        },
                        {
                            "lat": 61.60412928642636,
                            "lon": 55.22046714019964
                        },
                        {
                            "lat": 61.607269018178116,
                            "lon": 55.22052964844156
                        },
                        {
                            "lat": 61.60737828464673,
                            "lon": 55.21873475361555
                        }
                    ]
                ]
            },
            {
                "type": "MultiLineString",
                "value": 105.0,
                "data": [
                    [
                        {
                            "lat": 61.373297009144615,
                            "lon": 55.0521042263592
                        },
                        {
                            "lat": 61.37341605762921,
                            "lon": 55.05030960734501
                        },
                        {
                            "lat": 61.3702902244987,
                            "lon": 55.050241222469936
                        },
                        {
                            "lat": 61.37017103653066,
                            "lon": 55.052035836949386
                        },
                        {
                            "lat": 61.373297009144615,
                            "lon": 55.0521042263592
                        }
                    ],
                    [
                        {
                            "lat": 61.382793636471646,
                            "lon": 55.050514280802645
                        },
                        {
                            "lat": 61.37966776363115,
                            "lon": 55.05044613651208
                        },
                        {
                            "lat": 61.376541904011845,
                            "lon": 55.050377912025944
                        },
                        {
                            "lat": 61.37642299501272,
                            "lon": 55.05217253556959
                        },
                        {
                            "lat": 61.37954899411948,
                            "lon": 55.05224076457989
                        },
                        {
                            "lat": 61.38267500644945,
                            "lon": 55.052308913389304
                        },
                        {
                            "lat": 61.382793636471646,
                            "lon": 55.050514280802645
                        }
                    ],
                    [
                        {
                            "lat": 61.39217133416556,
                            "lon": 55.05071823249357
                        },
                        {
                            "lat": 61.38904542175431,
                            "lon": 55.05065032879413
                        },
                        {
                            "lat": 61.38591952251787,
                            "lon": 55.05058234489692
                        },
                        {
                            "lat": 61.385801031987164,
                            "lon": 55.05237698199712
                        },
                        {
                            "lat": 61.38568252826421,
                            "lon": 55.054171618392864
                        },
                        {
                            "lat": 61.388808706503106,
                            "lon": 55.05423961130698
                        },
                        {
                            "lat": 61.39193489792078,
                            "lon": 55.05430752401269
                        },
                        {
                            "lat": 61.39181666005444,
                            "lon": 55.05610216871678
                        },
                        {
                            "lat": 61.39494300416382,
                            "lon": 55.05617000571182
                        },
                        {
                            "lat": 61.39482489267612,
                            "lon": 55.05796465421109
                        },
                        {
                            "lat": 61.397951389481186,
                            "lon": 55.0580324154845
                        },
                        {
                            "lat": 61.397833404403094,
                            "lon": 55.05982706777441
                        },
                        {
                            "lat": 61.3977154061867,
                            "lon": 55.061621719361455
                        },
                        {
                            "lat": 61.394588630243355,
                            "lon": 55.06155394909979
                        },
                        {
                            "lat": 61.391461867455796,
                            "lon": 55.061486098607034
                        },
                        {
                            "lat": 61.38833511783949,
                            "lon": 55.061418167883936
                        },
                        {
                            "lat": 61.38821668771169,
                            "lon": 55.063212805267945
                        },
                        {
                            "lat": 61.3880982443951,
                            "lon": 55.06500744194783
                        },
                        {
                            "lat": 61.39122527320365,
                            "lon": 55.06507538168202
                        },
                        {
                            "lat": 61.39134357691635,
                            "lon": 55.06328074049638
                        },
                        {
                            "lat": 61.3944704792943,
                            "lon": 55.06334859548917
                        },
                        {
                            "lat": 61.394352315187454,
                            "lon": 55.06514324117526
                        },
                        {
                            "lat": 61.39747937033103,
                            "lon": 55.06521102042681
                        },
                        {
                            "lat": 61.39759739483,
                            "lon": 55.063416370245584
                        },
                        {
                            "lat": 61.400724323507994,
                            "lon": 55.06348406476487
                        },
                        {
                            "lat": 61.40060643861887,
                            "lon": 55.06527871943593
                        },
                        {
                            "lat": 61.40373352003551,
                            "lon": 55.06534633820189
                        },
                        {
                            "lat": 61.40686061456541,
                            "lon": 55.06541387672394
                        },
                        {
                            "lat": 61.40674299580418,
                            "lon": 55.06720853965698
                        },
                        {
                            "lat": 61.40662536394317,
                            "lon": 55.06900320188829
                        },
                        {
                            "lat": 61.40650771898039,
                            "lon": 55.070797863417845
                        },
                        {
                            "lat": 61.40963524554856,
                            "lon": 55.07086533511939
                        },
                        {
                            "lat": 61.40951772715986,
                            "lon": 55.07266000042266
                        },
                        {
                            "lat": 61.409400195680924,
                            "lon": 55.07445466502457
                        },
                        {
                            "lat": 61.40928265110978,
                            "lon": 55.07624932892505
                        },
                        {
                            "lat": 61.412410609852095,
                            "lon": 55.076316733777105
                        },
                        {
                            "lat": 61.412293191913086,
                            "lon": 55.07811140144758
                        },
                        {
                            "lat": 61.41217576089339,
                            "lon": 55.079906068417
                        },
                        {
                            "lat": 61.41205831679104,
                            "lon": 55.081700734685406
                        },
                        {
                            "lat": 61.41194085960403,
                            "lon": 55.08349540025272
                        },
                        {
                            "lat": 61.40881234186329,
                            "lon": 55.083427977512706
                        },
                        {
                            "lat": 61.405683837224714,
                            "lon": 55.083360474474226
                        },
                        {
                            "lat": 61.40556608737626,
                            "lon": 55.0851551303892
                        },
                        {
                            "lat": 61.40869473180127,
                            "lon": 55.08522263790598
                        },
                        {
                            "lat": 61.41182338933041,
                            "lon": 55.08529006511898
                        },
                        {
                            "lat": 61.41495205994816,
                            "lon": 55.085357412027506
                        },
                        {
                            "lat": 61.41483471639183,
                            "lon": 55.08715208066075
                        },
                        {
                            "lat": 61.41471735976041,
                            "lon": 55.0889467485933
                        },
                        {
                            "lat": 61.41459999005193,
                            "lon": 55.090741415825136
                        },
                        {
                            "lat": 61.41448260726441,
                            "lon": 55.09253608235625
                        },
                        {
                            "lat": 61.41135337732985,
                            "lon": 55.09246871757305
                        },
                        {
                            "lat": 61.41123584159329,
                            "lon": 55.09426337893373
                        },
                        {
                            "lat": 61.41436521139586,
                            "lon": 55.094330748186614
                        },
                        {
                            "lat": 61.41749459428145,
                            "lon": 55.09439803710769
                        },
                        {
                            "lat": 61.42062399023451,
                            "lon": 55.09446524569623
                        },
                        {
                            "lat": 61.42050686105601,
                            "lon": 55.096259919750025
                        },
                        {
                            "lat": 61.42363640995053,
                            "lon": 55.09632705245943
                        },
                        {
                            "lat": 61.42351940762316,
                            "lon": 55.09812173026778
                        },
                        {
                            "lat": 61.42340239225543,
                            "lon": 55.099916407376554
                        },
                        {
                            "lat": 61.42027256353528,
                            "lon": 55.0998492657577
                        },
                        {
                            "lat": 61.42015539518904,
                            "lon": 55.10164393771156
                        },
                        {
                            "lat": 61.41702543959262,
                            "lon": 55.10157671128287
                        },
                        {
                            "lat": 61.41690811823684,
                            "lon": 55.103371378075664
                        },
                        {
                            "lat": 61.42003821378295,
                            "lon": 55.103438608965405
                        },
                        {
                            "lat": 61.419921019315005,
                            "lon": 55.10523327951919
                        },
                        {
                            "lat": 61.41980381178322,
                            "lon": 55.10702794937296
                        },
                        {
                            "lat": 61.41968659118562,
                            "lon": 55.108822618526695
                        },
                        {
                            "lat": 61.41956935752021,
                            "lon": 55.11061728698033
                        },
                        {
                            "lat": 61.416438702019796,
                            "lon": 55.1105500382425
                        },
                        {
                            "lat": 61.41330805960474,
                            "lon": 55.11048270912427
                        },
                        {
                            "lat": 61.41017743029059,
                            "lon": 55.110415299626396
                        },
                        {
                            "lat": 61.41005976347896,
                            "lon": 55.112209953974926
                        },
                        {
                            "lat": 61.413190532816614,
                            "lon": 55.11227736794647
                        },
                        {
                            "lat": 61.41632131525713,
                            "lon": 55.11234470153304
                        },
                        {
                            "lat": 61.419452110784995,
                            "lon": 55.1124119547339
                        },
                        {
                            "lat": 61.419334850978025,
                            "lon": 55.114206621787375
                        },
                        {
                            "lat": 61.41921757809728,
                            "lon": 55.11600128814073
                        },
                        {
                            "lat": 61.419100292140776,
                            "lon": 55.11779595379396
                        },
                        {
                            "lat": 61.41898299310656,
                            "lon": 55.119590618747075
                        },
                        {
                            "lat": 61.41886568099263,
                            "lon": 55.12138528300003
                        },
                        {
                            "lat": 61.41573418509406,
                            "lon": 55.12131800747806
                        },
                        {
                            "lat": 61.41561671977755,
                            "lon": 55.12311266656544
                        },
                        {
                            "lat": 61.41549924136179,
                            "lon": 55.12490732495224
                        },
                        {
                            "lat": 61.41863101751766,
                            "lon": 55.12497460940545
                        },
                        {
                            "lat": 61.41874835579699,
                            "lon": 55.12317994655281
                        },
                        {
                            "lat": 61.4218800049001,
                            "lon": 55.12324714612183
                        },
                        {
                            "lat": 61.421762806759176,
                            "lon": 55.12504181343496
                        },
                        {
                            "lat": 61.42164559554815,
                            "lon": 55.12683648004827
                        },
                        {
                            "lat": 61.421528371265026,
                            "lon": 55.12863114596178
                        },
                        {
                            "lat": 61.424660453904174,
                            "lon": 55.1286982784784
                        },
                        {
                            "lat": 61.42454335673411,
                            "lon": 55.13049294814847
                        },
                        {
                            "lat": 61.42141113390782,
                            "lon": 55.130425811175485
                        },
                        {
                            "lat": 61.421293883474554,
                            "lon": 55.132220475689365
                        },
                        {
                            "lat": 61.42117661996325,
                            "lon": 55.13401513950336
                        },
                        {
                            "lat": 61.42430912321054,
                            "lon": 55.13408228539028
                        },
                        {
                            "lat": 61.427441639522286,
                            "lon": 55.13414935082543
                        },
                        {
                            "lat": 61.427558622595015,
                            "lon": 55.132354678102416
                        },
                        {
                            "lat": 61.42767559262086,
                            "lon": 55.13056000468034
                        },
                        {
                            "lat": 61.42779254960181,
                            "lon": 55.128765330559226
                        },
                        {
                            "lat": 61.42790949353985,
                            "lon": 55.126970655739115
                        },
                        {
                            "lat": 61.42802642443696,
                            "lon": 55.125175980219986
                        },
                        {
                            "lat": 61.428143342295115,
                            "lon": 55.123381304001846
                        },
                        {
                            "lat": 61.4282602471163,
                            "lon": 55.12158662708473
                        },
                        {
                            "lat": 61.42512871201933,
                            "lon": 55.121519592804084
                        },
                        {
                            "lat": 61.425245743916754,
                            "lon": 55.119724919637065
                        },
                        {
                            "lat": 61.4221143619796,
                            "lon": 55.119657809396294
                        },
                        {
                            "lat": 61.42223152092211,
                            "lon": 55.11786313998389
                        },
                        {
                            "lat": 61.42234866680247,
                            "lon": 55.11606846987176
                        },
                        {
                            "lat": 61.425479768567854,
                            "lon": 55.11613557120497
                        },
                        {
                            "lat": 61.42559676132548,
                            "lon": 55.11434089593994
                        },
                        {
                            "lat": 61.42571374104047,
                            "lon": 55.112546219975584
                        },
                        {
                            "lat": 61.428844575736974,
                            "lon": 55.112613232014915
                        },
                        {
                            "lat": 61.42896140237791,
                            "lon": 55.11081855090419
                        },
                        {
                            "lat": 61.429078215995716,
                            "lon": 55.10902386909457
                        },
                        {
                            "lat": 61.425947661350484,
                            "lon": 55.10895686594896
                        },
                        {
                            "lat": 61.42281711974192,
                            "lon": 55.10888978242609
                        },
                        {
                            "lat": 61.4229342003412,
                            "lon": 55.10709510881547
                        },
                        {
                            "lat": 61.42606460194944,
                            "lon": 55.10716218788675
                        },
                        {
                            "lat": 61.42618152951369,
                            "lon": 55.10536750912527
                        },
                        {
                            "lat": 61.4262984440452,
                            "lon": 55.10357282966457
                        },
                        {
                            "lat": 61.429428578730246,
                            "lon": 55.10363981947256
                        },
                        {
                            "lat": 61.42954534027536,
                            "lon": 55.10184513486754
                        },
                        {
                            "lat": 61.42966208880724,
                            "lon": 55.100050449563675
                        },
                        {
                            "lat": 61.42977882432786,
                            "lon": 55.098255763561035
                        },
                        {
                            "lat": 61.429895546839205,
                            "lon": 55.09646107685958
                        },
                        {
                            "lat": 61.433025134802264,
                            "lon": 55.09652796854886
                        },
                        {
                            "lat": 61.43314170441085,
                            "lon": 55.09473327671044
                        },
                        {
                            "lat": 61.43325826102964,
                            "lon": 55.09293858417368
                        },
                        {
                            "lat": 61.433374804660595,
                            "lon": 55.091143890938525
                        },
                        {
                            "lat": 61.433491335305725,
                            "lon": 55.08934919700505
                        },
                        {
                            "lat": 61.436620376764104,
                            "lon": 55.089415990623785
                        },
                        {
                            "lat": 61.43974943119086,
                            "lon": 55.08948270392155
                        },
                        {
                            "lat": 61.43986566917957,
                            "lon": 55.087688000432486
                        },
                        {
                            "lat": 61.442994596719934,
                            "lon": 55.08775462898792
                        },
                        {
                            "lat": 61.443110681934094,
                            "lon": 55.08595992038109
                        },
                        {
                            "lat": 61.44322675421491,
                            "lon": 55.08416521107712
                        },
                        {
                            "lat": 61.446355415054825,
                            "lon": 55.08423175048598
                        },
                        {
                            "lat": 61.44647133460964,
                            "lon": 55.08243703607075
                        },
                        {
                            "lat": 61.4433428135643,
                            "lon": 55.08237050107606
                        },
                        {
                            "lat": 61.4402143054484,
                            "lon": 55.0823038857802
                        },
                        {
                            "lat": 61.440330491645476,
                            "lon": 55.080509179501114
                        },
                        {
                            "lat": 61.44345885998427,
                            "lon": 55.08057579037789
                        },
                        {
                            "lat": 61.44658724125054,
                            "lon": 55.08064232095883
                        },
                        {
                            "lat": 61.44670313497947,
                            "lon": 55.07884760515022
                        },
                        {
                            "lat": 61.44357489347674,
                            "lon": 55.07878107898266
                        },
                        {
                            "lat": 61.4436909140437,
                            "lon": 55.07698636689036
                        },
                        {
                            "lat": 61.4468190157984,
                            "lon": 55.07705288864495
                        },
                        {
                            "lat": 61.44693488370929,
                            "lon": 55.07525817144302
                        },
                        {
                            "lat": 61.450062858637565,
                            "lon": 55.07532460850441
                        },
                        {
                            "lat": 61.45017857392341,
                            "lon": 55.07352988619893
                        },
                        {
                            "lat": 61.45330642202135,
                            "lon": 55.073596238577416
                        },
                        {
                            "lat": 61.45643428299236,
                            "lon": 55.07366251067913
                        },
                        {
                            "lat": 61.45654970597685,
                            "lon": 55.0718677788804
                        },
                        {
                            "lat": 61.453421984713174,
                            "lon": 55.07180151117454
                        },
                        {
                            "lat": 61.45353753453384,
                            "lon": 55.070006783075826
                        },
                        {
                            "lat": 61.45365307148532,
                            "lon": 55.0682120542813
                        },
                        {
                            "lat": 61.456780513380835,
                            "lon": 55.06827831319661
                        },
                        {
                            "lat": 61.45689589780422,
                            "lon": 55.06648357931158
                        },
                        {
                            "lat": 61.46002321288868,
                            "lon": 55.06654975357598
                        },
                        {
                            "lat": 61.460138444815,
                            "lon": 55.064755014606554
                        },
                        {
                            "lat": 61.460253663909,
                            "lon": 55.06296027494214
                        },
                        {
                            "lat": 61.46036887017259,
                            "lon": 55.061165534582734
                        },
                        {
                            "lat": 61.46048406360772,
                            "lon": 55.059370793528345
                        },
                        {
                            "lat": 61.46361083302257,
                            "lon": 55.05943687000341
                        },
                        {
                            "lat": 61.46372587404396,
                            "lon": 55.05764212387197
                        },
                        {
                            "lat": 61.46384090225628,
                            "lon": 55.05584737704596
                        },
                        {
                            "lat": 61.46395591766147,
                            "lon": 55.05405262952539
                        },
                        {
                            "lat": 61.46407092026147,
                            "lon": 55.05225788131029
                        },
                        {
                            "lat": 61.46094470910262,
                            "lon": 55.05219182236132
                        },
                        {
                            "lat": 61.45781851076248,
                            "lon": 55.052125683197836
                        },
                        {
                            "lat": 61.454692325256545,
                            "lon": 55.05205946382059
                        },
                        {
                            "lat": 61.45156615260032,
                            "lon": 55.05199316423028
                        },
                        {
                            "lat": 61.4484399928093,
                            "lon": 55.051926784427614
                        },
                        {
                            "lat": 61.44531384589891,
                            "lon": 55.05186032441331
                        },
                        {
                            "lat": 61.44218771188468,
                            "lon": 55.05179378418813
                        },
                        {
                            "lat": 61.439061590782075,
                            "lon": 55.05172716375276
                        },
                        {
                            "lat": 61.435935482606595,
                            "lon": 55.051660463107915
                        },
                        {
                            "lat": 61.432809387373695,
                            "lon": 55.05159368225435
                        },
                        {
                            "lat": 61.429683305098884,
                            "lon": 55.05152682119275
                        },
                        {
                            "lat": 61.42655723579762,
                            "lon": 55.051459879923875
                        },
                        {
                            "lat": 61.423431179485405,
                            "lon": 55.05139285844844
                        },
                        {
                            "lat": 61.420305136177696,
                            "lon": 55.051325756767156
                        },
                        {
                            "lat": 61.41717910588998,
                            "lon": 55.0512585748808
                        },
                        {
                            "lat": 61.414053088637736,
                            "lon": 55.05119131279004
                        },
                        {
                            "lat": 61.41092708443646,
                            "lon": 55.05112397049564
                        },
                        {
                            "lat": 61.4078010933016,
                            "lon": 55.05105654799832
                        },
                        {
                            "lat": 61.404675115248665,
                            "lon": 55.05098904529881
                        },
                        {
                            "lat": 61.40154915029311,
                            "lon": 55.050921462397845
                        },
                        {
                            "lat": 61.39842319845041,
                            "lon": 55.05085379929617
                        },
                        {
                            "lat": 61.39529725973608,
                            "lon": 55.0507860559945
                        },
                        {
                            "lat": 61.39217133416556,
                            "lon": 55.05071823249357
                        }
                    ],
                    [
                        {
                            "lat": 61.402437456070906,
                            "lon": 55.08508754256939
                        },
                        {
                            "lat": 61.39930883790074,
                            "lon": 55.085019874447255
                        },
                        {
                            "lat": 61.399190795334746,
                            "lon": 55.08681452068701
                        },
                        {
                            "lat": 61.40231955330286,
                            "lon": 55.08688219329845
                        },
                        {
                            "lat": 61.402437456070906,
                            "lon": 55.08508754256939
                        }
                    ],
                    [
                        {
                            "lat": 61.40078562110907,
                            "lon": 55.11021258886214
                        },
                        {
                            "lat": 61.40066753423899,
                            "lon": 55.11200722975776
                        },
                        {
                            "lat": 61.4037982641746,
                            "lon": 55.11207488487984
                        },
                        {
                            "lat": 61.406929007259784,
                            "lon": 55.112142459619136
                        },
                        {
                            "lat": 61.40704681409288,
                            "lon": 55.110347809749584
                        },
                        {
                            "lat": 61.40391621102721,
                            "lon": 55.110280239494585
                        },
                        {
                            "lat": 61.40078562110907,
                            "lon": 55.11021258886214
                        }
                    ],
                    [
                        {
                            "lat": 61.421528371265026,
                            "lon": 55.12863114596178
                        },
                        {
                            "lat": 61.41839630169995,
                            "lon": 55.128563933010106
                        },
                        {
                            "lat": 61.41526424522451,
                            "lon": 55.12849663962406
                        },
                        {
                            "lat": 61.41514672749902,
                            "lon": 55.13029129590904
                        },
                        {
                            "lat": 61.4182789241576,
                            "lon": 55.13035859376209
                        },
                        {
                            "lat": 61.42141113390782,
                            "lon": 55.130425811175485
                        },
                        {
                            "lat": 61.421528371265026,
                            "lon": 55.12863114596178
                        }
                    ],
                    [
                        {
                            "lat": 61.41745691468366,
                            "lon": 55.142921199418915
                        },
                        {
                            "lat": 61.42059010616636,
                            "lon": 55.14298844807531
                        },
                        {
                            "lat": 61.423723310739035,
                            "lon": 55.14305561625401
                        },
                        {
                            "lat": 61.423840499374144,
                            "lon": 55.14126095148028
                        },
                        {
                            "lat": 61.42697357672294,
                            "lon": 55.14132803472675
                        },
                        {
                            "lat": 61.43010666712854,
                            "lon": 55.14139503749941
                        },
                        {
                            "lat": 61.429989759091896,
                            "lon": 55.14318971117547
                        },
                        {
                            "lat": 61.4331230028409,
                            "lon": 55.143256637916764
                        },
                        {
                            "lat": 61.43300622207892,
                            "lon": 55.145051315337675
                        },
                        {
                            "lat": 61.4298728380121,
                            "lon": 55.14498438415283
                        },
                        {
                            "lat": 61.429755903887134,
                            "lon": 55.146779056431406
                        },
                        {
                            "lat": 61.43288942828738,
                            "lon": 55.14684599206022
                        },
                        {
                            "lat": 61.43602296571916,
                            "lon": 55.14691284719779
                        },
                        {
                            "lat": 61.435906299247094,
                            "lon": 55.14870752766103
                        },
                        {
                            "lat": 61.432772621464295,
                            "lon": 55.14864066808443
                        },
                        {
                            "lat": 61.4326558016077,
                            "lon": 55.15043534341025
                        },
                        {
                            "lat": 61.42952199649378,
                            "lon": 55.1503683988923
                        },
                        {
                            "lat": 61.42940502322143,
                            "lon": 55.152163069074575
                        },
                        {
                            "lat": 61.43253896871559,
                            "lon": 55.152230018037685
                        },
                        {
                            "lat": 61.432422122786015,
                            "lon": 55.154024691966725
                        },
                        {
                            "lat": 61.43555622171551,
                            "lon": 55.15409156486281
                        },
                        {
                            "lat": 61.43567292724725,
                            "lon": 55.152296886493566
                        },
                        {
                            "lat": 61.435789619757124,
                            "lon": 55.1505022074263
                        },
                        {
                            "lat": 61.438923450926424,
                            "lon": 55.15056899093967
                        },
                        {
                            "lat": 61.438806898800756,
                            "lon": 55.15236367444143
                        },
                        {
                            "lat": 61.44194088336054,
                            "lon": 55.15243038188062
                        },
                        {
                            "lat": 61.445074880910944,
                            "lon": 55.15249700881038
                        },
                        {
                            "lat": 61.44495859658461,
                            "lon": 55.15429170046831
                        },
                        {
                            "lat": 61.44484229928127,
                            "lon": 55.15608639142945
                        },
                        {
                            "lat": 61.447976590633836,
                            "lon": 55.156152946687236
                        },
                        {
                            "lat": 61.44786042078852,
                            "lon": 55.1579476413712
                        },
                        {
                            "lat": 61.444725988998975,
                            "lon": 55.15788108169372
                        },
                        {
                            "lat": 61.44460966573572,
                            "lon": 55.159675771261135
                        },
                        {
                            "lat": 61.441475106476474,
                            "lon": 55.159609126632084
                        },
                        {
                            "lat": 61.441358629764046,
                            "lon": 55.16140381107684
                        },
                        {
                            "lat": 61.441242140051074,
                            "lon": 55.16319849482431
                        },
                        {
                            "lat": 61.444376980258475,
                            "lon": 55.1632651483054
                        },
                        {
                            "lat": 61.444493329489546,
                            "lon": 55.16147046013171
                        },
                        {
                            "lat": 61.44762804219994,
                            "lon": 55.16153702864976
                        },
                        {
                            "lat": 61.447744237977886,
                            "lon": 55.159742335358715
                        },
                        {
                            "lat": 61.450878823187324,
                            "lon": 55.159808818924084
                        },
                        {
                            "lat": 61.454013421348456,
                            "lon": 55.1598752219565
                        },
                        {
                            "lat": 61.457148032445616,
                            "lon": 55.159941544455265
                        },
                        {
                            "lat": 61.457032258084205,
                            "lon": 55.161736250975764
                        },
                        {
                            "lat": 61.4601670225779,
                            "lon": 55.16180249733929
                        },
                        {
                            "lat": 61.460051375785845,
                            "lon": 55.163597207564024
                        },
                        {
                            "lat": 61.46318629368001,
                            "lon": 55.16366337778133
                        },
                        {
                            "lat": 61.46307077448857,
                            "lon": 55.16545809170572
                        },
                        {
                            "lat": 61.46295524240208,
                            "lon": 55.16725280493557
                        },
                        {
                            "lat": 61.45982004347362,
                            "lon": 55.16718662592869
                        },
                        {
                            "lat": 61.45668485745782,
                            "lon": 55.16712036636536
                        },
                        {
                            "lat": 61.45656903139507,
                            "lon": 55.16891507010456
                        },
                        {
                            "lat": 61.45970435794951,
                            "lon": 55.16898133406862
                        },
                        {
                            "lat": 61.46283969741858,
                            "lon": 55.1690475174709
                        },
                        {
                            "lat": 61.4659750497866,
                            "lon": 55.16911362031069
                        },
                        {
                            "lat": 61.46911041503796,
                            "lon": 55.169179642587274
                        },
                        {
                            "lat": 61.46899513827387,
                            "lon": 55.17097436320356
                        },
                        {
                            "lat": 61.468879848640114,
                            "lon": 55.17276908312608
                        },
                        {
                            "lat": 61.47201550789906,
                            "lon": 55.172835033598645
                        },
                        {
                            "lat": 61.4719003459871,
                            "lon": 55.17462975720791
                        },
                        {
                            "lat": 61.47178517121723,
                            "lon": 55.17642448012373
                        },
                        {
                            "lat": 61.471669983587454,
                            "lon": 55.17821920234613
                        },
                        {
                            "lat": 61.47155478309586,
                            "lon": 55.18001392387508
                        },
                        {
                            "lat": 61.474691017686126,
                            "lon": 55.18007981127553
                        },
                        {
                            "lat": 61.47480607753541,
                            "lon": 55.17828508537019
                        },
                        {
                            "lat": 61.47794218432954,
                            "lon": 55.1783508878022
                        },
                        {
                            "lat": 61.47782726512454,
                            "lon": 55.180145618078605
                        },
                        {
                            "lat": 61.47771233308703,
                            "lon": 55.181940347662305
                        },
                        {
                            "lat": 61.480848734019794,
                            "lon": 55.18200607823334
                        },
                        {
                            "lat": 61.48073392982539,
                            "lon": 55.183800811490876
                        },
                        {
                            "lat": 61.48387048425642,
                            "lon": 55.183866465818895
                        },
                        {
                            "lat": 61.48375580793644,
                            "lon": 55.185661202745656
                        },
                        {
                            "lat": 61.48689251586963,
                            "lon": 55.18572678081964
                        },
                        {
                            "lat": 61.486777967455396,
                            "lon": 55.18752152141106
                        },
                        {
                            "lat": 61.48666340624783,
                            "lon": 55.18931626131092
                        },
                        {
                            "lat": 61.48654883224503,
                            "lon": 55.191111000519214
                        },
                        {
                            "lat": 61.48643424544501,
                            "lon": 55.192905739035915
                        },
                        {
                            "lat": 61.483296974566784,
                            "lon": 55.192840143533196
                        },
                        {
                            "lat": 61.4831822341922,
                            "lon": 55.19463487700015
                        },
                        {
                            "lat": 61.480044835355294,
                            "lon": 55.19456919649698
                        },
                        {
                            "lat": 61.47992994137478,
                            "lon": 55.19636392490805
                        },
                        {
                            "lat": 61.47981503455984,
                            "lon": 55.19815865262671
                        },
                        {
                            "lat": 61.48295271499074,
                            "lon": 55.19822434185805
                        },
                        {
                            "lat": 61.48609040824231,
                            "lon": 55.19828995043643
                        },
                        {
                            "lat": 61.48922811429886,
                            "lon": 55.198355478361115
                        },
                        {
                            "lat": 61.489342598693426,
                            "lon": 55.19656073756563
                        },
                        {
                            "lat": 61.49248017672862,
                            "lon": 55.196626180487655
                        },
                        {
                            "lat": 61.4925945075408,
                            "lon": 55.19483143465308
                        },
                        {
                            "lat": 61.49573195755071,
                            "lon": 55.19489679258282
                        },
                        {
                            "lat": 61.49561776753548,
                            "lon": 55.19669154275992
                        },
                        {
                            "lat": 61.49875537109836,
                            "lon": 55.19675682438169
                        },
                        {
                            "lat": 61.49864130914168,
                            "lon": 55.19855157820589
                        },
                        {
                            "lat": 61.50177906626141,
                            "lon": 55.198616783508676
                        },
                        {
                            "lat": 61.50166513239464,
                            "lon": 55.2004115409754
                        },
                        {
                            "lat": 61.50480304307511,
                            "lon": 55.20047666994818
                        },
                        {
                            "lat": 61.50468923732963,
                            "lon": 55.20227143105287
                        },
                        {
                            "lat": 61.50782730157466,
                            "lon": 55.20233648368467
                        },
                        {
                            "lat": 61.50771362398184,
                            "lon": 55.20413124842272
                        },
                        {
                            "lat": 61.51085184179536,
                            "lon": 55.20419622470248
                        },
                        {
                            "lat": 61.510738292386556,
                            "lon": 55.205990993069314
                        },
                        {
                            "lat": 61.5138766637724,
                            "lon": 55.206055892986015
                        },
                        {
                            "lat": 61.51701504782981,
                            "lon": 55.20612071222125
                        },
                        {
                            "lat": 61.516901767541064,
                            "lon": 55.20791548851972
                        },
                        {
                            "lat": 61.51376324257902,
                            "lon": 55.20785066497707
                        },
                        {
                            "lat": 61.51364980871216,
                            "lon": 55.20964543627992
                        },
                        {
                            "lat": 61.51353636216984,
                            "lon": 55.211440206894565
                        },
                        {
                            "lat": 61.5166751689882,
                            "lon": 55.2115050390532
                        },
                        {
                            "lat": 61.519813988468215,
                            "lon": 55.21156979051363
                        },
                        {
                            "lat": 61.5229528205942,
                            "lon": 55.21163446127512
                        },
                        {
                            "lat": 61.52609166535044,
                            "lon": 55.21169905133696
                        },
                        {
                            "lat": 61.52597876994918,
                            "lon": 55.21349383846562
                        },
                        {
                            "lat": 61.52911776827939,
                            "lon": 55.21355835211429
                        },
                        {
                            "lat": 61.53225677921039,
                            "lon": 55.21362278505654
                        },
                        {
                            "lat": 61.5321441531448,
                            "lon": 55.21541758006831
                        },
                        {
                            "lat": 61.5320315144922,
                            "lon": 55.21721237439414
                        },
                        {
                            "lat": 61.53517081998189,
                            "lon": 55.2172767351834
                        },
                        {
                            "lat": 61.53505830975076,
                            "lon": 55.21907153310093
                        },
                        {
                            "lat": 61.53819776882595,
                            "lon": 55.21913581744396
                        },
                        {
                            "lat": 61.54133724046055,
                            "lon": 55.219200021062456
                        },
                        {
                            "lat": 61.541224999712185,
                            "lon": 55.2209948268344
                        },
                        {
                            "lat": 61.544364624922295,
                            "lon": 55.22105895398957
                        },
                        {
                            "lat": 61.5442525126759,
                            "lon": 55.22285376333904
                        },
                        {
                            "lat": 61.547392291465336,
                            "lon": 55.2229178140199
                        },
                        {
                            "lat": 61.54728030775231,
                            "lon": 55.2247126269423
                        },
                        {
                            "lat": 61.550420240124915,
                            "lon": 55.224776601137805
                        },
                        {
                            "lat": 61.55356018499978,
                            "lon": 55.22484049458989
                        },
                        {
                            "lat": 61.5567001423612,
                            "lon": 55.22490430729785
                        },
                        {
                            "lat": 61.55658856938428,
                            "lon": 55.226699132277346
                        },
                        {
                            "lat": 61.55972868030503,
                            "lon": 55.22676286847682
                        },
                        {
                            "lat": 61.56286880368276,
                            "lon": 55.226826523925446
                        },
                        {
                            "lat": 61.56275750044348,
                            "lon": 55.22862135669007
                        },
                        {
                            "lat": 61.56589777737052,
                            "lon": 55.22868493561313
                        },
                        {
                            "lat": 61.565786602811855,
                            "lon": 55.23047977192199
                        },
                        {
                            "lat": 61.56892703329196,
                            "lon": 55.230543274308474
                        },
                        {
                            "lat": 61.56881598744535,
                            "lon": 55.232338114156924
                        },
                        {
                            "lat": 61.5687049291832,
                            "lon": 55.234132953323886
                        },
                        {
                            "lat": 61.57184565437921,
                            "lon": 55.234196383379214
                        },
                        {
                            "lat": 61.574986391976886,
                            "lon": 55.2342597326595
                        },
                        {
                            "lat": 61.57812714196046,
                            "lon": 55.23432300116399
                        },
                        {
                            "lat": 61.57801649481084,
                            "lon": 55.2361178522839
                        },
                        {
                            "lat": 61.581157398345006,
                            "lon": 55.2361810442127
                        },
                        {
                            "lat": 61.58429831423549,
                            "lon": 55.236244155359
                        },
                        {
                            "lat": 61.58743924246657,
                            "lon": 55.23630718572212
                        },
                        {
                            "lat": 61.5905801830225,
                            "lon": 55.23637013530136
                        },
                        {
                            "lat": 61.59372113588752,
                            "lon": 55.23643300409602
                        },
                        {
                            "lat": 61.5968621010459,
                            "lon": 55.23649579210544
                        },
                        {
                            "lat": 61.60000307848191,
                            "lon": 55.2365584993289
                        },
                        {
                            "lat": 61.60314406817981,
                            "lon": 55.23662112576575
                        },
                        {
                            "lat": 61.60628507012383,
                            "lon": 55.23668367141529
                        },
                        {
                            "lat": 61.609426084298256,
                            "lon": 55.23674613627682
                        },
                        {
                            "lat": 61.61256711068731,
                            "lon": 55.236808520349676
                        },
                        {
                            "lat": 61.615708149275285,
                            "lon": 55.236870823633176
                        },
                        {
                            "lat": 61.61884920004642,
                            "lon": 55.23693304612665
                        },
                        {
                            "lat": 61.621990262984994,
                            "lon": 55.23699518782937
                        },
                        {
                            "lat": 61.62513133807522,
                            "lon": 55.23705724874071
                        },
                        {
                            "lat": 61.628272425301375,
                            "lon": 55.237119228859974
                        },
                        {
                            "lat": 61.62838081333974,
                            "lon": 55.23532431117098
                        },
                        {
                            "lat": 61.62523986732201,
                            "lon": 55.2352623351721
                        },
                        {
                            "lat": 61.6253483844347,
                            "lon": 55.23346742092874
                        },
                        {
                            "lat": 61.62545688941516,
                            "lon": 55.23167250601066
                        },
                        {
                            "lat": 61.6285975530633,
                            "lon": 55.231734473769876
                        },
                        {
                            "lat": 61.6317382288261,
                            "lon": 55.2317963607524
                        },
                        {
                            "lat": 61.6348789166878,
                            "lon": 55.231858166957565
                        },
                        {
                            "lat": 61.63498698604877,
                            "lon": 55.23006323902304
                        },
                        {
                            "lat": 61.635095043328405,
                            "lon": 55.22826831041491
                        },
                        {
                            "lat": 61.63823546095555,
                            "lon": 55.228330027636005
                        },
                        {
                            "lat": 61.63834336502046,
                            "lon": 55.226535094251815
                        },
                        {
                            "lat": 61.63845125702346,
                            "lon": 55.224740160194436
                        },
                        {
                            "lat": 61.64159140445591,
                            "lon": 55.22480178845368
                        },
                        {
                            "lat": 61.64473156393269,
                            "lon": 55.22486333595495
                        },
                        {
                            "lat": 61.644839161662524,
                            "lon": 55.223068393036975
                        },
                        {
                            "lat": 61.647979192058614,
                            "lon": 55.22312985569396
                        },
                        {
                            "lat": 61.64787173543808,
                            "lon": 55.22492480269761
                        },
                        {
                            "lat": 61.651011918956335,
                            "lon": 55.22498618868096
                        },
                        {
                            "lat": 61.65111923446575,
                            "lon": 55.22319123759699
                        },
                        {
                            "lat": 61.65425928886821,
                            "lon": 55.22325253874543
                        },
                        {
                            "lat": 61.65436645128507,
                            "lon": 55.221457582915164
                        },
                        {
                            "lat": 61.65750637656806,
                            "lon": 55.221518799239064
                        },
                        {
                            "lat": 61.66064631381307,
                            "lon": 55.22157993481237
                        },
                        {
                            "lat": 61.66075318208371,
                            "lon": 55.219784970178395
                        },
                        {
                            "lat": 61.663892990188074,
                            "lon": 55.21984602094226
                        },
                        {
                            "lat": 61.66399970544295,
                            "lon": 55.218051051579884
                        },
                        {
                            "lat": 61.66713938440303,
                            "lon": 55.21811201754475
                        },
                        {
                            "lat": 61.66724594667362,
                            "lon": 55.21631704346006
                        },
                        {
                            "lat": 61.67038549648574,
                            "lon": 55.2163779246363
                        },
                        {
                            "lat": 61.67352505819157,
                            "lon": 55.216438725075314
                        },
                        {
                            "lat": 61.67363132646409,
                            "lon": 55.21464374223342
                        },
                        {
                            "lat": 61.67049190580353,
                            "lon": 55.2145829458354
                        },
                        {
                            "lat": 61.66735249703483,
                            "lon": 55.21452206870551
                        },
                        {
                            "lat": 61.66421310017372,
                            "lon": 55.21446111084445
                        },
                        {
                            "lat": 61.66107371523591,
                            "lon": 55.21440007225287
                        },
                        {
                            "lat": 61.6609668827933,
                            "lon": 55.21619503889864
                        },
                        {
                            "lat": 61.65782736875654,
                            "lon": 55.216133915514796
                        },
                        {
                            "lat": 61.65772038331938,
                            "lon": 55.21792887742718
                        },
                        {
                            "lat": 61.65458074018731,
                            "lon": 55.217867669240654
                        },
                        {
                            "lat": 61.65447360172415,
                            "lon": 55.21966262641358
                        },
                        {
                            "lat": 61.65133382950044,
                            "lon": 55.21960133341397
                        },
                        {
                            "lat": 61.648194069268364,
                            "lon": 55.219539959670456
                        },
                        {
                            "lat": 61.645054321043695,
                            "lon": 55.21947850518371
                        },
                        {
                            "lat": 61.64494674736558,
                            "lon": 55.221273449446564
                        },
                        {
                            "lat": 61.64180687008801,
                            "lon": 55.22121191012695
                        },
                        {
                            "lat": 61.63866700485109,
                            "lon": 55.2211502900601
                        },
                        {
                            "lat": 61.638774860679376,
                            "lon": 55.21935535398318
                        },
                        {
                            "lat": 61.63888270445312,
                            "lon": 55.21756041723309
                        },
                        {
                            "lat": 61.63899053617413,
                            "lon": 55.21576547980983
                        },
                        {
                            "lat": 61.639098355844276,
                            "lon": 55.21397054171344
                        },
                        {
                            "lat": 61.639206163465346,
                            "lon": 55.212175602943894
                        },
                        {
                            "lat": 61.63931395903921,
                            "lon": 55.21038066350125
                        },
                        {
                            "lat": 61.64245297806687,
                            "lon": 55.21044225899975
                        },
                        {
                            "lat": 61.645592009124194,
                            "lon": 55.21050377378314
                        },
                        {
                            "lat": 61.64873105219546,
                            "lon": 55.21056520785071
                        },
                        {
                            "lat": 61.648838412778844,
                            "lon": 55.20877025547069
                        },
                        {
                            "lat": 61.651977326863,
                            "lon": 55.208831604744404
                        },
                        {
                            "lat": 61.655116252927854,
                            "lon": 55.208892873306354
                        },
                        {
                            "lat": 61.65522331957367,
                            "lon": 55.207097912105624
                        },
                        {
                            "lat": 61.65533037425633,
                            "lon": 55.20530295023362
                        },
                        {
                            "lat": 61.658469030355285,
                            "lon": 55.20536412995083
                        },
                        {
                            "lat": 61.658575932134696,
                            "lon": 55.20356916334199
                        },
                        {
                            "lat": 61.658682821970274,
                            "lon": 55.201774196062274
                        },
                        {
                            "lat": 61.6555444477394,
                            "lon": 55.20171302447588
                        },
                        {
                            "lat": 61.65565146654345,
                            "lon": 55.199918060590115
                        },
                        {
                            "lat": 61.65251324517893,
                            "lon": 55.19985681238287
                        },
                        {
                            "lat": 61.65262039292008,
                            "lon": 55.19806185189572
                        },
                        {
                            "lat": 61.65272752869143,
                            "lon": 55.19626689073701
                        },
                        {
                            "lat": 61.65283465249488,
                            "lon": 55.19447192890669
                        },
                        {
                            "lat": 61.6529417643322,
                            "lon": 55.19267696640482
                        },
                        {
                            "lat": 61.65607942221903,
                            "lon": 55.19273819833491
                        },
                        {
                            "lat": 61.656186381261854,
                            "lon": 55.19094323109309
                        },
                        {
                            "lat": 61.656293328357904,
                            "lon": 55.189148263180094
                        },
                        {
                            "lat": 61.659430716544996,
                            "lon": 55.18920940632016
                        },
                        {
                            "lat": 61.66256811666138,
                            "lon": 55.18927046880593
                        },
                        {
                            "lat": 61.66570552869136,
                            "lon": 55.189331450636764
                        },
                        {
                            "lat": 61.66884295261925,
                            "lon": 55.189392351811975
                        },
                        {
                            "lat": 61.6687365687994,
                            "lon": 55.19118733594358
                        },
                        {
                            "lat": 61.67187414543309,
                            "lon": 55.19124816050379
                        },
                        {
                            "lat": 61.67198038842936,
                            "lon": 55.189453172330914
                        },
                        {
                            "lat": 61.67511783610602,
                            "lon": 55.18951391219289
                        },
                        {
                            "lat": 61.67522392642605,
                            "lon": 55.18771891931507
                        },
                        {
                            "lat": 61.67836124514206,
                            "lon": 55.18777957448923
                        },
                        {
                            "lat": 61.68149857569143,
                            "lon": 55.18784014901046
                        },
                        {
                            "lat": 61.68463591805848,
                            "lon": 55.1879006428781
                        },
                        {
                            "lat": 61.684530250178355,
                            "lon": 55.18969564783056
                        },
                        {
                            "lat": 61.6876677451643,
                            "lon": 55.18975606505815
                        },
                        {
                            "lat": 61.687562206312975,
                            "lon": 55.191551073357246
                        },
                        {
                            "lat": 61.690699853921394,
                            "lon": 55.191611413933764
                        },
                        {
                            "lat": 61.69383751330405,
                            "lon": 55.191671673844056
                        },
                        {
                            "lat": 61.6969751844452,
                            "lon": 55.19173185308741
                        },
                        {
                            "lat": 61.700112867329175,
                            "lon": 55.19179195166322
                        },
                        {
                            "lat": 61.70021784283223,
                            "lon": 55.18999692735943
                        },
                        {
                            "lat": 61.697080300788,
                            "lon": 55.18993683277677
                        },
                        {
                            "lat": 61.69718540538977,
                            "lon": 55.18814181179964
                        },
                        {
                            "lat": 61.70032280660992,
                            "lon": 55.1882019023895
                        },
                        {
                            "lat": 61.70346021955365,
                            "lon": 55.188261912321806
                        },
                        {
                            "lat": 61.70356503079762,
                            "lon": 55.186466882698696
                        },
                        {
                            "lat": 61.703669830335485,
                            "lon": 55.18467185240982
                        },
                        {
                            "lat": 61.706806973378896,
                            "lon": 55.18473177372079
                        },
                        {
                            "lat": 61.706911620431875,
                            "lon": 55.18293673878514
                        },
                        {
                            "lat": 61.71004863438154,
                            "lon": 55.18299657547231
                        },
                        {
                            "lat": 61.70994412811092,
                            "lon": 55.18479161438363
                        },
                        {
                            "lat": 61.71308129451589,
                            "lon": 55.18485137439769
                        },
                        {
                            "lat": 61.716218472578134,
                            "lon": 55.18491105376228
                        },
                        {
                            "lat": 61.71935566228195,
                            "lon": 55.18497065247678
                        },
                        {
                            "lat": 61.72249286361165,
                            "lon": 55.185030170540514
                        },
                        {
                            "lat": 61.725630076551575,
                            "lon": 55.18508960795286
                        },
                        {
                            "lat": 61.72876730108602,
                            "lon": 55.18514896471314
                        },
                        {
                            "lat": 61.728870962625265,
                            "lon": 55.18335390205998
                        },
                        {
                            "lat": 61.73200805794381,
                            "lon": 55.18341317422932
                        },
                        {
                            "lat": 61.73514516482374,
                            "lon": 55.18347236575065
                        },
                        {
                            "lat": 61.73524853322603,
                            "lon": 55.18167729456412
                        },
                        {
                            "lat": 61.73838551086908,
                            "lon": 55.1817364015096
                        },
                        {
                            "lat": 61.741522500040396,
                            "lon": 55.181795427811146
                        },
                        {
                            "lat": 61.74162557536319,
                            "lon": 55.18000034811405
                        },
                        {
                            "lat": 61.741728639175136,
                            "lon": 55.178205267755516
                        },
                        {
                            "lat": 61.741831691477984,
                            "lon": 55.176410186735495
                        },
                        {
                            "lat": 61.741934732273506,
                            "lon": 55.17461510505404
                        },
                        {
                            "lat": 61.74203776156345,
                            "lon": 55.172820022711115
                        },
                        {
                            "lat": 61.74214077934956,
                            "lon": 55.1710249397068
                        },
                        {
                            "lat": 61.74224378563358,
                            "lon": 55.169229856041014
                        },
                        {
                            "lat": 61.742346780417286,
                            "lon": 55.16743477171382
                        },
                        {
                            "lat": 61.7424497637024,
                            "lon": 55.165639686725186
                        },
                        {
                            "lat": 61.742552735490676,
                            "lon": 55.163844601075176
                        },
                        {
                            "lat": 61.74265569578388,
                            "lon": 55.16204951476376
                        },
                        {
                            "lat": 61.74275864458376,
                            "lon": 55.16025442779093
                        },
                        {
                            "lat": 61.742861581892036,
                            "lon": 55.158459340156725
                        },
                        {
                            "lat": 61.74296450771048,
                            "lon": 55.156664251861145
                        },
                        {
                            "lat": 61.743067422040845,
                            "lon": 55.154869162904205
                        },
                        {
                            "lat": 61.74317032488485,
                            "lon": 55.15307407328591
                        },
                        {
                            "lat": 61.74327321624429,
                            "lon": 55.151278983006236
                        },
                        {
                            "lat": 61.743376096120855,
                            "lon": 55.14948389206523
                        },
                        {
                            "lat": 61.74347896451633,
                            "lon": 55.14768880046286
                        },
                        {
                            "lat": 61.74358182143244,
                            "lon": 55.14589370819919
                        },
                        {
                            "lat": 61.74368466687096,
                            "lon": 55.14409861527418
                        },
                        {
                            "lat": 61.7437875008336,
                            "lon": 55.14230352168787
                        },
                        {
                            "lat": 61.74389032332213,
                            "lon": 55.140508427440224
                        },
                        {
                            "lat": 61.74399313433826,
                            "lon": 55.1387133325313
                        },
                        {
                            "lat": 61.74409593388378,
                            "lon": 55.13691823696108
                        },
                        {
                            "lat": 61.744198721960416,
                            "lon": 55.13512314072954
                        },
                        {
                            "lat": 61.7443014985699,
                            "lon": 55.13332804383677
                        },
                        {
                            "lat": 61.744404263713975,
                            "lon": 55.13153294628269
                        },
                        {
                            "lat": 61.74450701739441,
                            "lon": 55.12973784806735
                        },
                        {
                            "lat": 61.74460975961291,
                            "lon": 55.12794274919077
                        },
                        {
                            "lat": 61.74471249037125,
                            "lon": 55.126147649652914
                        },
                        {
                            "lat": 61.74481520967115,
                            "lon": 55.12435254945385
                        },
                        {
                            "lat": 61.74491791751436,
                            "lon": 55.122557448593554
                        },
                        {
                            "lat": 61.745020613902625,
                            "lon": 55.120762347071995
                        },
                        {
                            "lat": 61.74512329883767,
                            "lon": 55.11896724488925
                        },
                        {
                            "lat": 61.74522597232125,
                            "lon": 55.11717214204528
                        },
                        {
                            "lat": 61.745328634355104,
                            "lon": 55.115377038540096
                        },
                        {
                            "lat": 61.745431284940956,
                            "lon": 55.11358193437375
                        },
                        {
                            "lat": 61.74553392408058,
                            "lon": 55.11178682954618
                        },
                        {
                            "lat": 61.745636551775675,
                            "lon": 55.10999172405742
                        },
                        {
                            "lat": 61.745739168028,
                            "lon": 55.10819661790752
                        },
                        {
                            "lat": 61.7458417728393,
                            "lon": 55.106401511096436
                        },
                        {
                            "lat": 61.7459443662113,
                            "lon": 55.10460640362419
                        },
                        {
                            "lat": 61.74604694814572,
                            "lon": 55.1028112954908
                        },
                        {
                            "lat": 61.74614951864434,
                            "lon": 55.10101618669628
                        },
                        {
                            "lat": 61.74625207770884,
                            "lon": 55.09922107724061
                        },
                        {
                            "lat": 61.74635462534102,
                            "lon": 55.09742596712379
                        },
                        {
                            "lat": 61.74645716154257,
                            "lon": 55.09563085634587
                        },
                        {
                            "lat": 61.74655968631525,
                            "lon": 55.09383574490683
                        },
                        {
                            "lat": 61.74342957641216,
                            "lon": 55.093776910347344
                        },
                        {
                            "lat": 61.740299477951815,
                            "lon": 55.09371799540531
                        },
                        {
                            "lat": 61.74019667314349,
                            "lon": 55.095513099029574
                        },
                        {
                            "lat": 61.740093856875134,
                            "lon": 55.09730820199205
                        },
                        {
                            "lat": 61.736963489827055,
                            "lon": 55.09724919883706
                        },
                        {
                            "lat": 61.73706644612618,
                            "lon": 55.09545409979031
                        },
                        {
                            "lat": 61.737169390949724,
                            "lon": 55.09365900008136
                        },
                        {
                            "lat": 61.73403931542148,
                            "lon": 55.093599924376164
                        },
                        {
                            "lat": 61.73393623058445,
                            "lon": 55.0953950201644
                        },
                        {
                            "lat": 61.73383313425627,
                            "lon": 55.097190115290154
                        },
                        {
                            "lat": 61.7337300264352,
                            "lon": 55.09898520975339
                        },
                        {
                            "lat": 61.733626907119486,
                            "lon": 55.10078030355406
                        },
                        {
                            "lat": 61.73352377630737,
                            "lon": 55.10257539669223
                        },
                        {
                            "lat": 61.73342063399714,
                            "lon": 55.104370489167835
                        },
                        {
                            "lat": 61.730289729654345,
                            "lon": 55.10431130952074
                        },
                        {
                            "lat": 61.73018643573906,
                            "lon": 55.106106397405696
                        },
                        {
                            "lat": 61.73331748018702,
                            "lon": 55.10616558098089
                        },
                        {
                            "lat": 61.73321431487528,
                            "lon": 55.107960672131405
                        },
                        {
                            "lat": 61.73634551094737,
                            "lon": 55.10801977921185
                        },
                        {
                            "lat": 61.736242474270334,
                            "lon": 55.10981487362327
                        },
                        {
                            "lat": 61.73613942610376,
                            "lon": 55.11160996737247
                        },
                        {
                            "lat": 61.7360363664459,
                            "lon": 55.11340506045944
                        },
                        {
                            "lat": 61.732904749912805,
                            "lon": 55.11334594160749
                        },
                        {
                            "lat": 61.732801538577085,
                            "lon": 55.11514103010768
                        },
                        {
                            "lat": 61.729669793369496,
                            "lon": 55.11508182688667
                        },
                        {
                            "lat": 61.729566430324724,
                            "lon": 55.11687691079404
                        },
                        {
                            "lat": 61.726434556446144,
                            "lon": 55.11681762319362
                        },
                        {
                            "lat": 61.726331041661105,
                            "lon": 55.118612702502155
                        },
                        {
                            "lat": 61.723199039115066,
                            "lon": 55.11855333051192
                        },
                        {
                            "lat": 61.72006704812967,
                            "lon": 55.11849387806847
                        },
                        {
                            "lat": 61.719963241348566,
                            "lon": 55.120288948825184
                        },
                        {
                            "lat": 61.71683112171656,
                            "lon": 55.120229411976894
                        },
                        {
                            "lat": 61.71672716311898,
                            "lon": 55.122024478117005
                        },
                        {
                            "lat": 61.713594914843874,
                            "lon": 55.121964856853516
                        },
                        {
                            "lat": 61.71046267817974,
                            "lon": 55.12190515512806
                        },
                        {
                            "lat": 61.71056691724896,
                            "lon": 55.120110096909116
                        },
                        {
                            "lat": 61.710671144693364,
                            "lon": 55.11831503802509
                        },
                        {
                            "lat": 61.71380310090321,
                            "lon": 55.1183747318244
                        },
                        {
                            "lat": 61.71390717652084,
                            "lon": 55.11657966831275
                        },
                        {
                            "lat": 61.71703900413262,
                            "lon": 55.11663927770355
                        },
                        {
                            "lat": 61.717142927954654,
                            "lon": 55.11484420957032
                        },
                        {
                            "lat": 61.71724684018836,
                            "lon": 55.11304914077272
                        },
                        {
                            "lat": 61.71735074083548,
                            "lon": 55.11125407131079
                        },
                        {
                            "lat": 61.714219333746655,
                            "lon": 55.11119447378952
                        },
                        {
                            "lat": 61.711087938258224,
                            "lon": 55.11113479583825
                        },
                        {
                            "lat": 61.71119210760507,
                            "lon": 55.10933973362889
                        },
                        {
                            "lat": 61.70806086387223,
                            "lon": 55.109279979214726
                        },
                        {
                            "lat": 61.70795655438577,
                            "lon": 55.11107503745764
                        },
                        {
                            "lat": 61.70785223326773,
                            "lon": 55.112870095035156
                        },
                        {
                            "lat": 61.7047207208734,
                            "lon": 55.112810252253716
                        },
                        {
                            "lat": 61.70461624795299,
                            "lon": 55.11460530519331
                        },
                        {
                            "lat": 61.701484607040314,
                            "lon": 55.114545378000706
                        },
                        {
                            "lat": 61.69835297779392,
                            "lon": 55.114485370370055
                        },
                        {
                            "lat": 61.69845773104707,
                            "lon": 55.11269032539142
                        },
                        {
                            "lat": 61.70158922012797,
                            "lon": 55.11275032903892
                        },
                        {
                            "lat": 61.70169382155113,
                            "lon": 55.11095527941103
                        },
                        {
                            "lat": 61.698562472620125,
                            "lon": 55.11089527974631
                        },
                        {
                            "lat": 61.69543113536743,
                            "lon": 55.11083519965486
                        },
                        {
                            "lat": 61.69553600539469,
                            "lon": 55.10904015733103
                        },
                        {
                            "lat": 61.69240481996666,
                            "lon": 55.10898000080659
                        },
                        {
                            "lat": 61.69250981841697,
                            "lon": 55.10718496180866
                        },
                        {
                            "lat": 61.689378784810074,
                            "lon": 55.10712472886217
                        },
                        {
                            "lat": 61.686247762924665,
                            "lon": 55.10706441550157
                        },
                        {
                            "lat": 61.68311675277634,
                            "lon": 55.10700402172749
                        },
                        {
                            "lat": 61.68301133399115,
                            "lon": 55.10879904871528
                        },
                        {
                            "lat": 61.68290590345152,
                            "lon": 55.110594075034804
                        },
                        {
                            "lat": 61.682800461155644,
                            "lon": 55.11238910068609
                        },
                        {
                            "lat": 61.679669042389065,
                            "lon": 55.112328614456054
                        },
                        {
                            "lat": 61.67956344818035,
                            "lon": 55.11412363542395
                        },
                        {
                            "lat": 61.67643190103439,
                            "lon": 55.114063064745345
                        },
                        {
                            "lat": 61.6763261548817,
                            "lon": 55.11585808102381
                        },
                        {
                            "lat": 61.67319447935989,
                            "lon": 55.11579742588632
                        },
                        {
                            "lat": 61.67308858123201,
                            "lon": 55.117592437469305
                        },
                        {
                            "lat": 61.67298267129398,
                            "lon": 55.11938744838292
                        },
                        {
                            "lat": 61.66985072720372,
                            "lon": 55.11932670474397
                        },
                        {
                            "lat": 61.669744665241964,
                            "lon": 55.12112171095571
                        },
                        {
                            "lat": 61.66661259276927,
                            "lon": 55.121060882831436
                        },
                        {
                            "lat": 61.66650637875256,
                            "lon": 55.12285588433518
                        },
                        {
                            "lat": 61.663374177901034,
                            "lon": 55.12279497171524
                        },
                        {
                            "lat": 61.66024198891182,
                            "lon": 55.12273397863853
                        },
                        {
                            "lat": 61.66034848337432,
                            "lon": 55.120938985227205
                        },
                        {
                            "lat": 61.66348053214152,
                            "lon": 55.12099997425506
                        },
                        {
                            "lat": 61.663586874521485,
                            "lon": 55.11920497612438
                        },
                        {
                            "lat": 61.66369320504272,
                            "lon": 55.117409977323234
                        },
                        {
                            "lat": 61.6605614366727,
                            "lon": 55.11734899639205
                        },
                        {
                            "lat": 61.660454965960675,
                            "lon": 55.11914399114504
                        },
                        {
                            "lat": 61.65732306927417,
                            "lon": 55.119082925720235
                        },
                        {
                            "lat": 61.65419118447758,
                            "lon": 55.119021779850655
                        },
                        {
                            "lat": 61.654297935565786,
                            "lon": 55.11722679320995
                        },
                        {
                            "lat": 61.651166202860075,
                            "lon": 55.11716557096034
                        },
                        {
                            "lat": 61.651273082214374,
                            "lon": 55.11537058771188
                        },
                        {
                            "lat": 61.64814150159594,
                            "lon": 55.11530928909322
                        },
                        {
                            "lat": 61.64803448207366,
                            "lon": 55.1171042682726
                        },
                        {
                            "lat": 61.6479274506165,
                            "lon": 55.118899246779705
                        },
                        {
                            "lat": 61.65105931158649,
                            "lon": 55.11896055353691
                        },
                        {
                            "lat": 61.6509524083918,
                            "lon": 55.12075553544157
                        },
                        {
                            "lat": 61.64782040722265,
                            "lon": 55.12069422461453
                        },
                        {
                            "lat": 61.64771335189032,
                            "lon": 55.122489201777064
                        },
                        {
                            "lat": 61.644581222446725,
                            "lon": 55.12242780642638
                        },
                        {
                            "lat": 61.644474014945516,
                            "lon": 55.124222778840746
                        },
                        {
                            "lat": 61.64760628461763,
                            "lon": 55.124284178267324
                        },
                        {
                            "lat": 61.64749920540283,
                            "lon": 55.12607915408527
                        },
                        {
                            "lat": 61.6473921142441,
                            "lon": 55.1278741292309
                        },
                        {
                            "lat": 61.64425956406812,
                            "lon": 55.127812721651466
                        },
                        {
                            "lat": 61.64415232068828,
                            "lon": 55.12960769204781
                        },
                        {
                            "lat": 61.647285011139594,
                            "lon": 55.12966910370421
                        },
                        {
                            "lat": 61.65041771353847,
                            "lon": 55.12973043488583
                        },
                        {
                            "lat": 61.65031073877914,
                            "lon": 55.13152541275883
                        },
                        {
                            "lat": 61.647177896087506,
                            "lon": 55.13146407750519
                        },
                        {
                            "lat": 61.64404506534526,
                            "lon": 55.131402661771475
                        },
                        {
                            "lat": 61.64393779803724,
                            "lon": 55.133197630822394
                        },
                        {
                            "lat": 61.647070769086035,
                            "lon": 55.13325905063383
                        },
                        {
                            "lat": 61.64696363013335,
                            "lon": 55.13505402309013
                        },
                        {
                            "lat": 61.650096753457305,
                            "lon": 55.13511536648887
                        },
                        {
                            "lat": 61.64998974289115,
                            "lon": 55.136910342345914
                        },
                        {
                            "lat": 61.64988272038581,
                            "lon": 55.138705317530935
                        },
                        {
                            "lat": 61.646749316367064,
                            "lon": 55.138643965985636
                        },
                        {
                            "lat": 61.64664214154986,
                            "lon": 55.14043893642482
                        },
                        {
                            "lat": 61.64350860911879,
                            "lon": 55.14037750029891
                        },
                        {
                            "lat": 61.64340128195848,
                            "lon": 55.142172465986185
                        },
                        {
                            "lat": 61.640267621118724,
                            "lon": 55.142110945269316
                        },
                        {
                            "lat": 61.64016014158409,
                            "lon": 55.14390590619859
                        },
                        {
                            "lat": 61.637026352339284,
                            "lon": 55.143844300880396
                        },
                        {
                            "lat": 61.633892575103445,
                            "lon": 55.14378261504677
                        },
                        {
                            "lat": 61.633784802752835,
                            "lon": 55.14557756711572
                        },
                        {
                            "lat": 61.636918720399066,
                            "lon": 55.145639257045616
                        },
                        {
                            "lat": 61.63681107644806,
                            "lon": 55.14743421253732
                        },
                        {
                            "lat": 61.63367701837583,
                            "lon": 55.14737251851082
                        },
                        {
                            "lat": 61.633569221970525,
                            "lon": 55.14916746923201
                        },
                        {
                            "lat": 61.630435035486755,
                            "lon": 55.14910569057774
                        },
                        {
                            "lat": 61.62730086104872,
                            "lon": 55.14904383139339
                        },
                        {
                            "lat": 61.62416669867199,
                            "lon": 55.148981891679625
                        },
                        {
                            "lat": 61.624274916391215,
                            "lon": 55.147186953281434
                        },
                        {
                            "lat": 61.627408938331776,
                            "lon": 55.1472488888822
                        },
                        {
                            "lat": 61.627517003555326,
                            "lon": 55.145453945696374
                        },
                        {
                            "lat": 61.62762505672121,
                            "lon": 55.143659001835935
                        },
                        {
                            "lat": 61.62449131560604,
                            "lon": 55.143597074460054
                        },
                        {
                            "lat": 61.621357586562354,
                            "lon": 55.14353506657146
                        },
                        {
                            "lat": 61.61822386960576,
                            "lon": 55.1434729781708
                        },
                        {
                            "lat": 61.618115395230696,
                            "lon": 55.145267909678445
                        },
                        {
                            "lat": 61.61498154997743,
                            "lon": 55.14520573663815
                        },
                        {
                            "lat": 61.61487292308284,
                            "lon": 55.14700066334143
                        },
                        {
                            "lat": 61.61173894953657,
                            "lon": 55.14693840565112
                        },
                        {
                            "lat": 61.60860498812797,
                            "lon": 55.14687606744011
                        },
                        {
                            "lat": 61.6054710388726,
                            "lon": 55.14681364870908
                        },
                        {
                            "lat": 61.60233710178612,
                            "lon": 55.14675114945874
                        },
                        {
                            "lat": 61.59920317688416,
                            "lon": 55.14668856968972
                        },
                        {
                            "lat": 61.59909383574212,
                            "lon": 55.148483474991885
                        },
                        {
                            "lat": 61.59595978262076,
                            "lon": 55.14842081054389
                        },
                        {
                            "lat": 61.59282574171699,
                            "lon": 55.14835806557327
                        },
                        {
                            "lat": 61.592716107504515,
                            "lon": 55.15015296186936
                        },
                        {
                            "lat": 61.58958193840257,
                            "lon": 55.15009013220476
                        },
                        {
                            "lat": 61.58969171304648,
                            "lon": 55.1482952400807
                        },
                        {
                            "lat": 61.58655769662483,
                            "lon": 55.1482323340669
                        },
                        {
                            "lat": 61.58342369246764,
                            "lon": 55.14816934753249
                        },
                        {
                            "lat": 61.58028970059057,
                            "lon": 55.148106280478224
                        },
                        {
                            "lat": 61.58039988422187,
                            "lon": 55.146311400222125
                        },
                        {
                            "lat": 61.58051005555932,
                            "lon": 55.14451651928582
                        },
                        {
                            "lat": 61.57737635677904,
                            "lon": 55.14445338009799
                        },
                        {
                            "lat": 61.57726604504888,
                            "lon": 55.14624825684165
                        },
                        {
                            "lat": 61.57413221818537,
                            "lon": 55.14618503294802
                        },
                        {
                            "lat": 61.5742426703064,
                            "lon": 55.14439016040231
                        },
                        {
                            "lat": 61.57110899615696,
                            "lon": 55.144326860199484
                        },
                        {
                            "lat": 61.57121957632812,
                            "lon": 55.14253199117579
                        },
                        {
                            "lat": 61.56808605488902,
                            "lon": 55.14246861467474
                        },
                        {
                            "lat": 61.56819676307909,
                            "lon": 55.14067374917762
                        },
                        {
                            "lat": 61.568307458918454,
                            "lon": 55.13887888299886
                        },
                        {
                            "lat": 61.56517423052433,
                            "lon": 55.138815434423414
                        },
                        {
                            "lat": 61.56204101449462,
                            "lon": 55.1387519053589
                        },
                        {
                            "lat": 61.56215197862883,
                            "lon": 55.136957046928714
                        },
                        {
                            "lat": 61.565285054337636,
                            "lon": 55.137020571775444
                        },
                        {
                            "lat": 61.56539586578839,
                            "lon": 55.13522570844546
                        },
                        {
                            "lat": 61.56550666487847,
                            "lon": 55.13343084443345
                        },
                        {
                            "lat": 61.56561745160974,
                            "lon": 55.13163597973944
                        },
                        {
                            "lat": 61.56248479677,
                            "lon": 55.13157246754382
                        },
                        {
                            "lat": 61.56259571140285,
                            "lon": 55.12977760638413
                        },
                        {
                            "lat": 61.562706613665064,
                            "lon": 55.127982744542074
                        },
                        {
                            "lat": 61.55957425169545,
                            "lon": 55.1279191603222
                        },
                        {
                            "lat": 61.55644190211023,
                            "lon": 55.1278554956466
                        },
                        {
                            "lat": 61.556553072452616,
                            "lon": 55.126060641569254
                        },
                        {
                            "lat": 61.55666423039694,
                            "lon": 55.12426578680881
                        },
                        {
                            "lat": 61.559796299550705,
                            "lon": 55.124329443032416
                        },
                        {
                            "lat": 61.55990730490648,
                            "lon": 55.122534583363475
                        },
                        {
                            "lat": 61.56001829788356,
                            "lon": 55.120739723011845
                        },
                        {
                            "lat": 61.56012927848381,
                            "lon": 55.11894486197756
                        },
                        {
                            "lat": 61.556997629860355,
                            "lon": 55.11888121842908
                        },
                        {
                            "lat": 61.55710873823126,
                            "lon": 55.11708636093641
                        },
                        {
                            "lat": 61.56024024670911,
                            "lon": 55.11715000026059
                        },
                        {
                            "lat": 61.56035120256132,
                            "lon": 55.115355137860966
                        },
                        {
                            "lat": 61.560462146042354,
                            "lon": 55.1135602747787
                        },
                        {
                            "lat": 61.56359338664503,
                            "lon": 55.1136238252423
                        },
                        {
                            "lat": 61.56370417765601,
                            "lon": 55.11182895725956
                        },
                        {
                            "lat": 61.56381495631509,
                            "lon": 55.1100340885946
                        },
                        {
                            "lat": 61.56392572262421,
                            "lon": 55.108239219247395
                        },
                        {
                            "lat": 61.56705655531947,
                            "lon": 55.108302676660756
                        },
                        {
                            "lat": 61.56716716922456,
                            "lon": 55.10650780241994
                        },
                        {
                            "lat": 61.567277770798974,
                            "lon": 55.1047129274973
                        },
                        {
                            "lat": 61.56738836004462,
                            "lon": 55.10291805189283
                        },
                        {
                            "lat": 61.57051878494574,
                            "lon": 55.1029814162906
                        },
                        {
                            "lat": 61.57364922215823,
                            "lon": 55.10304470030512
                        },
                        {
                            "lat": 61.57375951905288,
                            "lon": 55.10124981561428
                        },
                        {
                            "lat": 61.57386980365358,
                            "lon": 55.099454930242395
                        },
                        {
                            "lat": 61.57398007596222,
                            "lon": 55.097660044189475
                        },
                        {
                            "lat": 61.57409033598066,
                            "lon": 55.09586515745551
                        },
                        {
                            "lat": 61.57722022552299,
                            "lon": 55.095928344311154
                        },
                        {
                            "lat": 61.5773303333005,
                            "lon": 55.09413345270339
                        },
                        {
                            "lat": 61.577440428807066,
                            "lon": 55.092338560414966
                        },
                        {
                            "lat": 61.57755051204459,
                            "lon": 55.09054366744594
                        },
                        {
                            "lat": 61.577660583014904,
                            "lon": 55.0887487737963
                        },
                        {
                            "lat": 61.580789925105464,
                            "lon": 55.088811863540926
                        },
                        {
                            "lat": 61.58089984391818,
                            "lon": 55.08701696502471
                        },
                        {
                            "lat": 61.57777064171991,
                            "lon": 55.08695387946605
                        },
                        {
                            "lat": 61.57788068816143,
                            "lon": 55.0851589844552
                        },
                        {
                            "lat": 61.57799072234133,
                            "lon": 55.083364088763766
                        },
                        {
                            "lat": 61.58111964480168,
                            "lon": 55.08342716595158
                        },
                        {
                            "lat": 61.58424857950624,
                            "lon": 55.0834901628126
                        },
                        {
                            "lat": 61.584138825050566,
                            "lon": 55.08528506686918
                        },
                        {
                            "lat": 61.58726791184871,
                            "lon": 55.08534798757727
                        },
                        {
                            "lat": 61.59039701086183,
                            "lon": 55.0854108279519
                        },
                        {
                            "lat": 61.59050648558582,
                            "lon": 55.083615915551434
                        },
                        {
                            "lat": 61.59061594811223,
                            "lon": 55.08182100247189
                        },
                        {
                            "lat": 61.587487128817095,
                            "lon": 55.08175817043548
                        },
                        {
                            "lat": 61.58759671898343,
                            "lon": 55.0799632608454
                        },
                        {
                            "lat": 61.587706296940326,
                            "lon": 55.07816835057591
                        },
                        {
                            "lat": 61.5845777695086,
                            "lon": 55.07810544656397
                        },
                        {
                            "lat": 61.5846874750607,
                            "lon": 55.07631053978815
                        },
                        {
                            "lat": 61.58479716839154,
                            "lon": 55.07451563233255
                        },
                        {
                            "lat": 61.58166893276921,
                            "lon": 55.074452656365516
                        },
                        {
                            "lat": 61.581559099653006,
                            "lon": 55.076247559643065
                        },
                        {
                            "lat": 61.578430736482076,
                            "lon": 55.07618449919246
                        },
                        {
                            "lat": 61.57832075133001,
                            "lon": 55.0779793976061
                        },
                        {
                            "lat": 61.57821075392376,
                            "lon": 55.07977429533922
                        },
                        {
                            "lat": 61.575082123395234,
                            "lon": 55.07971114620525
                        },
                        {
                            "lat": 61.574971973904695,
                            "lon": 55.081506039068024
                        },
                        {
                            "lat": 61.57184321582132,
                            "lon": 55.08144280542413
                        },
                        {
                            "lat": 61.57173291421544,
                            "lon": 55.08323769341047
                        },
                        {
                            "lat": 61.568604028580936,
                            "lon": 55.083174375246315
                        },
                        {
                            "lat": 61.56849357482863,
                            "lon": 55.08496925835015
                        },
                        {
                            "lat": 61.565364561646746,
                            "lon": 55.08490585565546
                        },
                        {
                            "lat": 61.56525395571693,
                            "lon": 55.0867007338707
                        },
                        {
                            "lat": 61.56838310876808,
                            "lon": 55.08676414077228
                        },
                        {
                            "lat": 61.56827263039744,
                            "lon": 55.08855902251268
                        },
                        {
                            "lat": 61.56816213971483,
                            "lon": 55.09035390357136
                        },
                        {
                            "lat": 61.56503270687852,
                            "lon": 55.09029048825491
                        },
                        {
                            "lat": 61.56190328637157,
                            "lon": 55.09022699259383
                        },
                        {
                            "lat": 61.56201405685302,
                            "lon": 55.08843211995571
                        },
                        {
                            "lat": 61.56212481499143,
                            "lon": 55.08663724663513
                        },
                        {
                            "lat": 61.56223556078863,
                            "lon": 55.08484237263207
                        },
                        {
                            "lat": 61.559106572269826,
                            "lon": 55.08477880928068
                        },
                        {
                            "lat": 61.559217445577744,
                            "lon": 55.0829839388123
                        },
                        {
                            "lat": 61.55608860926189,
                            "lon": 55.08292029935604
                        },
                        {
                            "lat": 61.55619961004943,
                            "lon": 55.081125432426916
                        },
                        {
                            "lat": 61.55307092593278,
                            "lon": 55.08106171687674
                        },
                        {
                            "lat": 61.54994225419828,
                            "lon": 55.08099792101126
                        },
                        {
                            "lat": 61.55005352224767,
                            "lon": 55.07920306185827
                        },
                        {
                            "lat": 61.553182054168865,
                            "lon": 55.07926685349141
                        },
                        {
                            "lat": 61.55329317002467,
                            "lon": 55.07747198942255
                        },
                        {
                            "lat": 61.5564215745265,
                            "lon": 55.07753569651908
                        },
                        {
                            "lat": 61.55954999139118,
                            "lon": 55.07759932331026
                        },
                        {
                            "lat": 61.55966081529848,
                            "lon": 55.075804450110645
                        },
                        {
                            "lat": 61.56278910472265,
                            "lon": 55.07586799238015
                        },
                        {
                            "lat": 61.56591740647674,
                            "lon": 55.0759314543482
                        },
                        {
                            "lat": 61.56602793849202,
                            "lon": 55.07413657204062
                        },
                        {
                            "lat": 61.56613845819475,
                            "lon": 55.072341689051015
                        },
                        {
                            "lat": 61.56926649272663,
                            "lon": 55.072405062308114
                        },
                        {
                            "lat": 61.56937686037363,
                            "lon": 55.07061017443265
                        },
                        {
                            "lat": 61.56624896558678,
                            "lon": 55.070546805379436
                        },
                        {
                            "lat": 61.56635946067001,
                            "lon": 55.06875192102584
                        },
                        {
                            "lat": 61.566469943446265,
                            "lon": 55.066957035990264
                        },
                        {
                            "lat": 61.56334234040802,
                            "lon": 55.06689359506812
                        },
                        {
                            "lat": 61.56021474969036,
                            "lon": 55.06683007387111
                        },
                        {
                            "lat": 61.56032549955292,
                            "lon": 55.06503519657498
                        },
                        {
                            "lat": 61.560436237081234,
                            "lon": 55.06324031859614
                        },
                        {
                            "lat": 61.560546962277186,
                            "lon": 55.06144543993461
                        },
                        {
                            "lat": 61.56367413395712,
                            "lon": 55.06150894849263
                        },
                        {
                            "lat": 61.56680131795205,
                            "lon": 55.0615723767917
                        },
                        {
                            "lat": 61.56691175151919,
                            "lon": 55.059777489028306
                        },
                        {
                            "lat": 61.567022172788704,
                            "lon": 55.05798260058296
                        },
                        {
                            "lat": 61.567132581762465,
                            "lon": 55.0561877114557
                        },
                        {
                            "lat": 61.567242978442344,
                            "lon": 55.05439282164652
                        },
                        {
                            "lat": 61.564116352954755,
                            "lon": 55.05432941017292
                        },
                        {
                            "lat": 61.5609897397747,
                            "lon": 55.0542659184616
                        },
                        {
                            "lat": 61.55786313891766,
                            "lon": 55.0542023465133
                        },
                        {
                            "lat": 61.55473655039915,
                            "lon": 55.054138694328685
                        },
                        {
                            "lat": 61.55160997423464,
                            "lon": 55.05407496190844
                        },
                        {
                            "lat": 61.54848341043964,
                            "lon": 55.05401114925327
                        },
                        {
                            "lat": 61.545356859029624,
                            "lon": 55.05394725636387
                        },
                        {
                            "lat": 61.542230320020124,
                            "lon": 55.05388328324089
                        },
                        {
                            "lat": 61.539103793426634,
                            "lon": 55.05381922988509
                        },
                        {
                            "lat": 61.53597727926461,
                            "lon": 55.05375509629714
                        },
                        {
                            "lat": 61.53285077754959,
                            "lon": 55.05369088247771
                        },
                        {
                            "lat": 61.52972428829703,
                            "lon": 55.05362658842753
                        },
                        {
                            "lat": 61.52659781152245,
                            "lon": 55.05356221414728
                        },
                        {
                            "lat": 61.52347134724135,
                            "lon": 55.05349775963765
                        },
                        {
                            "lat": 61.52034489546918,
                            "lon": 55.053433224899365
                        },
                        {
                            "lat": 61.51721845622148,
                            "lon": 55.053368609933116
                        },
                        {
                            "lat": 61.514092029513726,
                            "lon": 55.053303914739594
                        },
                        {
                            "lat": 61.51096561536138,
                            "lon": 55.053239139319494
                        },
                        {
                            "lat": 61.50783921377999,
                            "lon": 55.053174283673556
                        },
                        {
                            "lat": 61.50471282478499,
                            "lon": 55.05310934780245
                        },
                        {
                            "lat": 61.50158644839189,
                            "lon": 55.05304433170688
                        },
                        {
                            "lat": 61.49846008461621,
                            "lon": 55.05297923538756
                        },
                        {
                            "lat": 61.4953337334734,
                            "lon": 55.05291405884521
                        },
                        {
                            "lat": 61.49220739497897,
                            "lon": 55.052848802080526
                        },
                        {
                            "lat": 61.48908106914841,
                            "lon": 55.052783465094194
                        },
                        {
                            "lat": 61.485954755997184,
                            "lon": 55.052718047886955
                        },
                        {
                            "lat": 61.48282845554081,
                            "lon": 55.05265255045951
                        },
                        {
                            "lat": 61.482714290226085,
                            "lon": 55.05444732484883
                        },
                        {
                            "lat": 61.485840730236745,
                            "lon": 55.05451282662008
                        },
                        {
                            "lat": 61.48572669177955,
                            "lon": 55.0563076046614
                        },
                        {
                            "lat": 61.48260011219907,
                            "lon": 55.05624209854595
                        },
                        {
                            "lat": 61.48248592145788,
                            "lon": 55.05803687155087
                        },
                        {
                            "lat": 61.48237171800059,
                            "lon": 55.059831643863546
                        },
                        {
                            "lat": 61.48549857676713,
                            "lon": 55.05989715866853
                        },
                        {
                            "lat": 61.48538450020807,
                            "lon": 55.06169193463432
                        },
                        {
                            "lat": 61.48225750182526,
                            "lon": 55.061626415484
                        },
                        {
                            "lat": 61.48214327292995,
                            "lon": 55.0634211864122
                        },
                        {
                            "lat": 61.47901614763726,
                            "lon": 55.0633555826648
                        },
                        {
                            "lat": 61.47588903508197,
                            "lon": 55.06328989866677
                        },
                        {
                            "lat": 61.475774514176,
                            "lon": 55.06508466019453
                        },
                        {
                            "lat": 61.4756599805152,
                            "lon": 55.06687942102924
                        },
                        {
                            "lat": 61.47253260141434,
                            "lon": 55.0668136480568
                        },
                        {
                            "lat": 61.47241791532429,
                            "lon": 55.06860840383559
                        },
                        {
                            "lat": 61.47230321646002,
                            "lon": 55.07040315892095
                        },
                        {
                            "lat": 61.47218850481959,
                            "lon": 55.07219791331283
                        },
                        {
                            "lat": 61.4720737804011,
                            "lon": 55.07399266701121
                        },
                        {
                            "lat": 61.47195904320256,
                            "lon": 55.0757874200161
                        },
                        {
                            "lat": 61.468830978366114,
                            "lon": 55.07572154493925
                        },
                        {
                            "lat": 61.46871608863751,
                            "lon": 55.077516292880524
                        },
                        {
                            "lat": 61.46860118610948,
                            "lon": 55.079311040127884
                        },
                        {
                            "lat": 61.46848627078007,
                            "lon": 55.08110578668132
                        },
                        {
                            "lat": 61.46535779945605,
                            "lon": 55.08103981819143
                        },
                        {
                            "lat": 61.465242731530616,
                            "lon": 55.08283455967435
                        },
                        {
                            "lat": 61.462114133234635,
                            "lon": 55.08276850650171
                        },
                        {
                            "lat": 61.46199891268212,
                            "lon": 55.08456324290799
                        },
                        {
                            "lat": 61.46188367929134,
                            "lon": 55.086357978619546
                        },
                        {
                            "lat": 61.45875481420741,
                            "lon": 55.08629183636585
                        },
                        {
                            "lat": 61.458639428141026,
                            "lon": 55.08808656699427
                        },
                        {
                            "lat": 61.45852402921697,
                            "lon": 55.08988129692751
                        },
                        {
                            "lat": 61.45539489730627,
                            "lon": 55.08981506557066
                        },
                        {
                            "lat": 61.455279345658056,
                            "lon": 55.09160979041427
                        },
                        {
                            "lat": 61.45215008675994,
                            "lon": 55.09154347433236
                        },
                        {
                            "lat": 61.45203438235649,
                            "lon": 55.093338194080204
                        },
                        {
                            "lat": 61.448904996474866,
                            "lon": 55.0932717932629
                        },
                        {
                            "lat": 61.44878913928512,
                            "lon": 55.09506650790885
                        },
                        {
                            "lat": 61.445659626423904,
                            "lon": 55.095000022345864
                        },
                        {
                            "lat": 61.442530126490325,
                            "lon": 55.094933456443826
                        },
                        {
                            "lat": 61.43940063949994,
                            "lon": 55.09486681020342
                        },
                        {
                            "lat": 61.43627116546826,
                            "lon": 55.09480008362539
                        },
                        {
                            "lat": 61.43615473575708,
                            "lon": 55.09659477989667
                        },
                        {
                            "lat": 61.43928434968814,
                            "lon": 55.09666151090222
                        },
                        {
                            "lat": 61.442413976579886,
                            "lon": 55.096728161564876
                        },
                        {
                            "lat": 61.44554361641677,
                            "lon": 55.09679473188382
                        },
                        {
                            "lat": 61.44542759348002,
                            "lon": 55.098589440724986
                        },
                        {
                            "lat": 61.44531155761171,
                            "lon": 55.10038414886932
                        },
                        {
                            "lat": 61.44218163792157,
                            "lon": 55.10031756971533
                        },
                        {
                            "lat": 61.43905173118051,
                            "lon": 55.10025091020706
                        },
                        {
                            "lat": 61.43893540248076,
                            "lon": 55.10204560881307
                        },
                        {
                            "lat": 61.43580536875829,
                            "lon": 55.10197886452247
                        },
                        {
                            "lat": 61.43568888713038,
                            "lon": 55.10377355800172
                        },
                        {
                            "lat": 61.43557239251835,
                            "lon": 55.10556825078291
                        },
                        {
                            "lat": 61.43870270617946,
                            "lon": 55.10563500393214
                        },
                        {
                            "lat": 61.441833032811104,
                            "lon": 55.10570167671185
                        },
                        {
                            "lat": 61.44171680520028,
                            "lon": 55.107496377649504
                        },
                        {
                            "lat": 61.44160056463252,
                            "lon": 55.10929107788988
                        },
                        {
                            "lat": 61.44148431110584,
                            "lon": 55.11108577743296
                        },
                        {
                            "lat": 61.441368044618265,
                            "lon": 55.112880476278754
                        },
                        {
                            "lat": 61.43823715791478,
                            "lon": 55.112813785798274
                        },
                        {
                            "lat": 61.438120738407505,
                            "lon": 55.11460847952056
                        },
                        {
                            "lat": 61.43800430591983,
                            "lon": 55.116403172545134
                        },
                        {
                            "lat": 61.43788786044982,
                            "lon": 55.11819786487196
                        },
                        {
                            "lat": 61.4377714019955,
                            "lon": 55.119992556501025
                        },
                        {
                            "lat": 61.437654930554864,
                            "lon": 55.121787247432316
                        },
                        {
                            "lat": 61.437538446125956,
                            "lon": 55.12358193766586
                        },
                        {
                            "lat": 61.44067017340414,
                            "lon": 55.123648654709335
                        },
                        {
                            "lat": 61.440553816135285,
                            "lon": 55.12544334867362
                        },
                        {
                            "lat": 61.443685696540406,
                            "lon": 55.12550998971688
                        },
                        {
                            "lat": 61.44356946646274,
                            "lon": 55.12730468740738
                        },
                        {
                            "lat": 61.44345322342204,
                            "lon": 55.12909938440084
                        },
                        {
                            "lat": 61.44658539714341,
                            "lon": 55.12916595385158
                        },
                        {
                            "lat": 61.44646928133868,
                            "lon": 55.130960654567104
                        },
                        {
                            "lat": 61.44960160821239,
                            "lon": 55.13102714799072
                        },
                        {
                            "lat": 61.44948561967482,
                            "lon": 55.13282185242375
                        },
                        {
                            "lat": 61.44936961819947,
                            "lon": 55.13461655616051
                        },
                        {
                            "lat": 61.44925360378439,
                            "lon": 55.13641125920097
                        },
                        {
                            "lat": 61.44612085620833,
                            "lon": 55.1363447525337
                        },
                        {
                            "lat": 61.44623701087312,
                            "lon": 55.13455005390818
                        },
                        {
                            "lat": 61.443104416502074,
                            "lon": 55.13448347119899
                        },
                        {
                            "lat": 61.44298812158956,
                            "lon": 55.13627816540424
                        },
                        {
                            "lat": 61.442871813704166,
                            "lon": 55.138072858912366
                        },
                        {
                            "lat": 61.44275549284391,
                            "lon": 55.13986755172342
                        },
                        {
                            "lat": 61.439622490659694,
                            "lon": 55.13980087528007
                        },
                        {
                            "lat": 61.436489501467896,
                            "lon": 55.13973411836536
                        },
                        {
                            "lat": 61.43660610288009,
                            "lon": 55.13793943441243
                        },
                        {
                            "lat": 61.43347326696931,
                            "lon": 55.13787260146408
                        },
                        {
                            "lat": 61.430340444080166,
                            "lon": 55.137805688051124
                        },
                        {
                            "lat": 61.43045731299916,
                            "lon": 55.13601101227893
                        },
                        {
                            "lat": 61.430574168882885,
                            "lon": 55.13421633580806
                        },
                        {
                            "lat": 61.427441639522286,
                            "lon": 55.13414935082543
                        },
                        {
                            "lat": 61.4273246434007,
                            "lon": 55.13594402284939
                        },
                        {
                            "lat": 61.42720763422826,
                            "lon": 55.13773869417425
                        },
                        {
                            "lat": 61.427090612003006,
                            "lon": 55.13953336480006
                        },
                        {
                            "lat": 61.42395767493688,
                            "lon": 55.139466286007014
                        },
                        {
                            "lat": 61.420824750941144,
                            "lon": 55.13939912674621
                        },
                        {
                            "lat": 61.420707435097746,
                            "lon": 55.14119378776071
                        },
                        {
                            "lat": 61.41757438390933,
                            "lon": 55.1411265435688
                        },
                        {
                            "lat": 61.41745691468366,
                            "lon": 55.142921199418915
                        }
                    ],
                    [
                        {
                            "lat": 61.71035842748396,
                            "lon": 55.123700212681875
                        },
                        {
                            "lat": 61.70722606219762,
                            "lon": 55.12364042652619
                        },
                        {
                            "lat": 61.707330453142184,
                            "lon": 55.121845372941294
                        },
                        {
                            "lat": 61.71046267817974,
                            "lon": 55.12190515512806
                        },
                        {
                            "lat": 61.71035842748396,
                            "lon": 55.123700212681875
                        }
                    ],
                    [
                        {
                            "lat": 61.707121659609136,
                            "lon": 55.125435479445635
                        },
                        {
                            "lat": 61.70398916570414,
                            "lon": 55.125375608849325
                        },
                        {
                            "lat": 61.70409370855523,
                            "lon": 55.1235805599045
                        },
                        {
                            "lat": 61.70722606219762,
                            "lon": 55.12364042652619
                        },
                        {
                            "lat": 61.707121659609136,
                            "lon": 55.125435479445635
                        }
                    ],
                    [
                        {
                            "lat": 61.69824821285893,
                            "lon": 55.116280414682215
                        },
                        {
                            "lat": 61.69814343624025,
                            "lon": 55.11807545832787
                        },
                        {
                            "lat": 61.695011538301536,
                            "lon": 55.11801536228182
                        },
                        {
                            "lat": 61.69511645511509,
                            "lon": 55.11622032262534
                        },
                        {
                            "lat": 61.6952213602294,
                            "lon": 55.11442528230201
                        },
                        {
                            "lat": 61.69835297779392,
                            "lon": 55.114485370370055
                        },
                        {
                            "lat": 61.69824821285893,
                            "lon": 55.116280414682215
                        }
                    ],
                    [
                        {
                            "lat": 61.660135482571405,
                            "lon": 55.12452897137903
                        },
                        {
                            "lat": 61.660028964351255,
                            "lon": 55.12632396344866
                        },
                        {
                            "lat": 61.66316143383122,
                            "lon": 55.126384964624215
                        },
                        {
                            "lat": 61.66305504399829,
                            "lon": 55.12817996007297
                        },
                        {
                            "lat": 61.666187665614785,
                            "lon": 55.12824088482569
                        },
                        {
                            "lat": 61.669320299083395,
                            "lon": 55.128301729105004
                        },
                        {
                            "lat": 61.66921417795691,
                            "lon": 55.13009673196792
                        },
                        {
                            "lat": 61.6660814042003,
                            "lon": 55.13003588364894
                        },
                        {
                            "lat": 61.66597513093189,
                            "lon": 55.13183088180202
                        },
                        {
                            "lat": 61.66284222872741,
                            "lon": 55.131769948958926
                        },
                        {
                            "lat": 61.6597093383943,
                            "lon": 55.1317089356324
                        },
                        {
                            "lat": 61.659815892264504,
                            "lon": 55.12991394557535
                        },
                        {
                            "lat": 61.65668315411652,
                            "lon": 55.12985285582207
                        },
                        {
                            "lat": 61.65678983638415,
                            "lon": 55.128057869149806
                        },
                        {
                            "lat": 61.65689650675284,
                            "lon": 55.126262881806326
                        },
                        {
                            "lat": 61.657003165224374,
                            "lon": 55.12446789379161
                        },
                        {
                            "lat": 61.65710981180057,
                            "lon": 55.12267290510569
                        },
                        {
                            "lat": 61.66024198891182,
                            "lon": 55.12273397863853
                        },
                        {
                            "lat": 61.660135482571405,
                            "lon": 55.12452897137903
                        }
                    ],
                    [
                        {
                            "lat": 61.62114090652578,
                            "lon": 55.147124937157265
                        },
                        {
                            "lat": 61.62124925258852,
                            "lon": 55.14533000220204
                        },
                        {
                            "lat": 61.624383122035276,
                            "lon": 55.14539201420824
                        },
                        {
                            "lat": 61.624274916391215,
                            "lon": 55.147186953281434
                        },
                        {
                            "lat": 61.62114090652578,
                            "lon": 55.147124937157265
                        }
                    ],
                    [
                        {
                            "lat": 61.56079490227707,
                            "lon": 55.10817568143617
                        },
                        {
                            "lat": 61.56068399589834,
                            "lon": 55.1099705465663
                        },
                        {
                            "lat": 61.56057307715406,
                            "lon": 55.1117654110138
                        },
                        {
                            "lat": 61.55744198901955,
                            "lon": 55.11170178436024
                        },
                        {
                            "lat": 61.557330917808976,
                            "lon": 55.113496643901975
                        },
                        {
                            "lat": 61.55419970196049,
                            "lon": 55.11343293261282
                        },
                        {
                            "lat": 61.55106849851246,
                            "lon": 55.113369140911935
                        },
                        {
                            "lat": 61.54793730748047,
                            "lon": 55.11330526879998
                        },
                        {
                            "lat": 61.54480612888008,
                            "lon": 55.113241316277694
                        },
                        {
                            "lat": 61.54491776046732,
                            "lon": 55.11144647368183
                        },
                        {
                            "lat": 61.541786734403566,
                            "lon": 55.11138244499968
                        },
                        {
                            "lat": 61.53865572080065,
                            "lon": 55.111318335913865
                        },
                        {
                            "lat": 61.53854380903644,
                            "lon": 55.113113170004844
                        },
                        {
                            "lat": 61.538431884793866,
                            "lon": 55.114908003410534
                        },
                        {
                            "lat": 61.53530060348051,
                            "lon": 55.114843805400554
                        },
                        {
                            "lat": 61.53216933466295,
                            "lon": 55.11477952697769
                        },
                        {
                            "lat": 61.53228153910608,
                            "lon": 55.112984702098935
                        },
                        {
                            "lat": 61.529150422897324,
                            "lon": 55.112920347535336
                        },
                        {
                            "lat": 61.52903807835677,
                            "lon": 55.11471516814265
                        },
                        {
                            "lat": 61.52892572128927,
                            "lon": 55.11650998806351
                        },
                        {
                            "lat": 61.52579433739893,
                            "lon": 55.116445544539815
                        },
                        {
                            "lat": 61.52568182767592,
                            "lon": 55.11824035949668
                        },
                        {
                            "lat": 61.52255031620523,
                            "lon": 55.118175831274016
                        },
                        {
                            "lat": 61.51941881729644,
                            "lon": 55.11811122263061
                        },
                        {
                            "lat": 61.51628733096514,
                            "lon": 55.11804653356718
                        },
                        {
                            "lat": 61.516174388280476,
                            "lon": 55.1198413349715
                        },
                        {
                            "lat": 61.51304277440762,
                            "lon": 55.119776561189525
                        },
                        {
                            "lat": 61.509911173145305,
                            "lon": 55.119711706983594
                        },
                        {
                            "lat": 61.506779584509125,
                            "lon": 55.11964677235443
                        },
                        {
                            "lat": 61.50689294759189,
                            "lon": 55.11785198386375
                        },
                        {
                            "lat": 61.5037615117263,
                            "lon": 55.11778697312724
                        },
                        {
                            "lat": 61.50063008851605,
                            "lon": 55.11772188197422
                        },
                        {
                            "lat": 61.50074371918368,
                            "lon": 55.11592710142933
                        },
                        {
                            "lat": 61.503875002282555,
                            "lon": 55.11599218826221
                        },
                        {
                            "lat": 61.50700629803488,
                            "lon": 55.11605719468392
                        },
                        {
                            "lat": 61.50711963583998,
                            "lon": 55.11426240481493
                        },
                        {
                            "lat": 61.50723296100914,
                            "lon": 55.11246761425683
                        },
                        {
                            "lat": 61.50734627354426,
                            "lon": 55.11067282300963
                        },
                        {
                            "lat": 61.50421539803837,
                            "lon": 55.11060782953004
                        },
                        {
                            "lat": 61.50108453518017,
                            "lon": 55.11054275565522
                        },
                        {
                            "lat": 61.4979536849852,
                            "lon": 55.11047760138591
                        },
                        {
                            "lat": 61.49482284746905,
                            "lon": 55.11041236672281
                        },
                        {
                            "lat": 61.49169202264727,
                            "lon": 55.110347051666615
                        },
                        {
                            "lat": 61.491578009798495,
                            "lon": 55.11214182129235
                        },
                        {
                            "lat": 61.491463984237875,
                            "lon": 55.11393659022704
                        },
                        {
                            "lat": 61.48833289199655,
                            "lon": 55.11387118609757
                        },
                        {
                            "lat": 61.4884470576297,
                            "lon": 55.112076421503524
                        },
                        {
                            "lat": 61.48531611818832,
                            "lon": 55.1120109413177
                        },
                        {
                            "lat": 61.48520181248461,
                            "lon": 55.11380570156579
                        },
                        {
                            "lat": 61.485087494035945,
                            "lon": 55.11560046112204
                        },
                        {
                            "lat": 61.48497316284042,
                            "lon": 55.11739521998644
                        },
                        {
                            "lat": 61.48184181588939,
                            "lon": 55.11732964634931
                        },
                        {
                            "lat": 61.48172733182969,
                            "lon": 55.11912440016942
                        },
                        {
                            "lat": 61.48161283500367,
                            "lon": 55.120919153297244
                        },
                        {
                            "lat": 61.48149832540937,
                            "lon": 55.12271390573281
                        },
                        {
                            "lat": 61.48463009275317,
                            "lon": 55.12277949242849
                        },
                        {
                            "lat": 61.484515710550774,
                            "lon": 55.1245742485254
                        },
                        {
                            "lat": 61.4813838030449,
                            "lon": 55.12450865747611
                        },
                        {
                            "lat": 61.48126926790827,
                            "lon": 55.12630340852711
                        },
                        {
                            "lat": 61.481154719997555,
                            "lon": 55.12809815888579
                        },
                        {
                            "lat": 61.48104015931084,
                            "lon": 55.12989290855215
                        },
                        {
                            "lat": 61.48417248739649,
                            "lon": 55.12995851266464
                        },
                        {
                            "lat": 61.48405805415623,
                            "lon": 55.13175326599387
                        },
                        {
                            "lat": 61.4871905352306,
                            "lon": 55.1318187940067
                        },
                        {
                            "lat": 61.49032302905371,
                            "lon": 55.13188424156399
                        },
                        {
                            "lat": 61.493455535609925,
                            "lon": 55.13194960866499
                        },
                        {
                            "lat": 61.49334151033705,
                            "lon": 55.13374437433703
                        },
                        {
                            "lat": 61.49020886353499,
                            "lon": 55.133679002896415
                        },
                        {
                            "lat": 61.487076229467995,
                            "lon": 55.13361355099422
                        },
                        {
                            "lat": 61.48696191095471,
                            "lon": 55.13540830729015
                        },
                        {
                            "lat": 61.490094685281214,
                            "lon": 55.13547376353764
                        },
                        {
                            "lat": 61.489980494290414,
                            "lon": 55.13726852348767
                        },
                        {
                            "lat": 61.48986629056069,
                            "lon": 55.1390632827465
                        },
                        {
                            "lat": 61.486733235668424,
                            "lon": 55.1389978178072
                        },
                        {
                            "lat": 61.48661887889155,
                            "lon": 55.140792572028346
                        },
                        {
                            "lat": 61.48650450935624,
                            "lon": 55.14258732555783
                        },
                        {
                            "lat": 61.48337118659616,
                            "lon": 55.14252177143771
                        },
                        {
                            "lat": 61.480237876612,
                            "lon": 55.14245613683078
                        },
                        {
                            "lat": 61.48035252678527,
                            "lon": 55.14066139201098
                        },
                        {
                            "lat": 61.47721936990877,
                            "lon": 55.14059568128079
                        },
                        {
                            "lat": 61.47733414759286,
                            "lon": 55.138800940131055
                        },
                        {
                            "lat": 61.47420114382008,
                            "lon": 55.138735153288614
                        },
                        {
                            "lat": 61.47431604898384,
                            "lon": 55.13694041581348
                        },
                        {
                            "lat": 61.4711831983109,
                            "lon": 55.13687455286975
                        },
                        {
                            "lat": 61.4680503604705,
                            "lon": 55.13680860945804
                        },
                        {
                            "lat": 61.468165533346095,
                            "lon": 55.13501388003976
                        },
                        {
                            "lat": 61.46503284861529,
                            "lon": 55.13494786054382
                        },
                        {
                            "lat": 61.4651481488906,
                            "lon": 55.13315313481418
                        },
                        {
                            "lat": 61.46201561726547,
                            "lon": 55.13308703924494
                        },
                        {
                            "lat": 61.46213104490931,
                            "lon": 55.13129231720854
                        },
                        {
                            "lat": 61.46224645967971,
                            "lon": 55.12949759447744
                        },
                        {
                            "lat": 61.45911422136713,
                            "lon": 55.12943142723839
                        },
                        {
                            "lat": 61.45922976346126,
                            "lon": 55.127636708204676
                        },
                        {
                            "lat": 61.45609767822899,
                            "lon": 55.12757046491923
                        },
                        {
                            "lat": 61.45621334761565,
                            "lon": 55.12577574958747
                        },
                        {
                            "lat": 61.45632900410356,
                            "lon": 55.123981033560284
                        },
                        {
                            "lat": 61.45644464769469,
                            "lon": 55.12218631683764
                        },
                        {
                            "lat": 61.45957631244072,
                            "lon": 55.122252546933254
                        },
                        {
                            "lat": 61.462707990066,
                            "lon": 55.12231869660638
                        },
                        {
                            "lat": 61.46583968055493,
                            "lon": 55.12238476585631
                        },
                        {
                            "lat": 61.4659548908526,
                            "lon": 55.12059003526676
                        },
                        {
                            "lat": 61.46607008830414,
                            "lon": 55.11879530398299
                        },
                        {
                            "lat": 61.46618527291149,
                            "lon": 55.11700057200505
                        },
                        {
                            "lat": 61.46931655588489,
                            "lon": 55.11706654769266
                        },
                        {
                            "lat": 61.47244785168494,
                            "lon": 55.117132442971624
                        },
                        {
                            "lat": 61.47256274326798,
                            "lon": 55.11533770154742
                        },
                        {
                            "lat": 61.46943158755993,
                            "lon": 55.115271810641815
                        },
                        {
                            "lat": 61.46954660641025,
                            "lon": 55.11347707289717
                        },
                        {
                            "lat": 61.46966161243782,
                            "lon": 55.111682334458756
                        },
                        {
                            "lat": 61.466530749687905,
                            "lon": 55.11161637190601
                        },
                        {
                            "lat": 61.46641560360142,
                            "lon": 55.11341110596652
                        },
                        {
                            "lat": 61.46328461363093,
                            "lon": 55.11334505863857
                        },
                        {
                            "lat": 61.46339989977439,
                            "lon": 55.11155032896127
                        },
                        {
                            "lat": 61.46026906271283,
                            "lon": 55.11148420562525
                        },
                        {
                            "lat": 61.46038447604381,
                            "lon": 55.10968947964155
                        },
                        {
                            "lat": 61.463515173065915,
                            "lon": 55.1097555985894
                        },
                        {
                            "lat": 61.463630433507426,
                            "lon": 55.10796086752302
                        },
                        {
                            "lat": 61.463745681100896,
                            "lon": 55.106166135762116
                        },
                        {
                            "lat": 61.46061526411107,
                            "lon": 55.10610002558938
                        },
                        {
                            "lat": 61.46073063885123,
                            "lon": 55.10430529752092
                        },
                        {
                            "lat": 61.463860915848265,
                            "lon": 55.10437140330669
                        },
                        {
                            "lat": 61.46699120568947,
                            "lon": 55.10443742872247
                        },
                        {
                            "lat": 61.46710628761349,
                            "lon": 55.10264269119128
                        },
                        {
                            "lat": 61.46722135671089,
                            "lon": 55.10084795296599
                        },
                        {
                            "lat": 61.46733641298357,
                            "lon": 55.099053214046656
                        },
                        {
                            "lat": 61.47046629575658,
                            "lon": 55.09911914596506
                        },
                        {
                            "lat": 61.47035137943392,
                            "lon": 55.10091388925958
                        },
                        {
                            "lat": 61.47348141496612,
                            "lon": 55.10097974519238
                        },
                        {
                            "lat": 61.47661146329191,
                            "lon": 55.10104552076368
                        },
                        {
                            "lat": 61.47672609970867,
                            "lon": 55.09925076873479
                        },
                        {
                            "lat": 61.476840723349305,
                            "lon": 55.097456016013005
                        },
                        {
                            "lat": 61.479970504556974,
                            "lon": 55.09752170250407
                        },
                        {
                            "lat": 61.480084975498656,
                            "lon": 55.09572694473096
                        },
                        {
                            "lat": 61.47695533421575,
                            "lon": 55.09566126259834
                        },
                        {
                            "lat": 61.477069932309995,
                            "lon": 55.09386650849079
                        },
                        {
                            "lat": 61.47718451763393,
                            "lon": 55.09207175369037
                        },
                        {
                            "lat": 61.48031387911391,
                            "lon": 55.0921374271073
                        },
                        {
                            "lat": 61.48019943368366,
                            "lon": 55.09393218626537
                        },
                        {
                            "lat": 61.483328947812076,
                            "lon": 55.093997783698306
                        },
                        {
                            "lat": 61.4864584746797,
                            "lon": 55.09406330078887
                        },
                        {
                            "lat": 61.48657264031677,
                            "lon": 55.092268532931456
                        },
                        {
                            "lat": 61.489702040008574,
                            "lon": 55.092333965337254
                        },
                        {
                            "lat": 61.48981605303996,
                            "lon": 55.09053919244683
                        },
                        {
                            "lat": 61.492945325552085,
                            "lon": 55.09060454017815
                        },
                        {
                            "lat": 61.49305918600888,
                            "lon": 55.088809762260844
                        },
                        {
                            "lat": 61.48993005336708,
                            "lon": 55.08874441886514
                        },
                        {
                            "lat": 61.48680093342763,
                            "lon": 55.0886789951416
                        },
                        {
                            "lat": 61.48367182620606,
                            "lon": 55.08861349109091
                        },
                        {
                            "lat": 61.48378609353465,
                            "lon": 55.08681872550435
                        },
                        {
                            "lat": 61.48691506090529,
                            "lon": 55.08688422520917
                        },
                        {
                            "lat": 61.49004404099187,
                            "lon": 55.08694964459218
                        },
                        {
                            "lat": 61.49015801591623,
                            "lon": 55.08515486962795
                        },
                        {
                            "lat": 61.48702917566698,
                            "lon": 55.085089454585095
                        },
                        {
                            "lat": 61.48714327771468,
                            "lon": 55.08329468326939
                        },
                        {
                            "lat": 61.4902719781421,
                            "lon": 55.08336009397248
                        },
                        {
                            "lat": 61.49038592767142,
                            "lon": 55.08156531762579
                        },
                        {
                            "lat": 61.48725736705028,
                            "lon": 55.081499911262064
                        },
                        {
                            "lat": 61.48737144367571,
                            "lon": 55.07970513856311
                        },
                        {
                            "lat": 61.48748550759291,
                            "lon": 55.0779103651726
                        },
                        {
                            "lat": 61.48759955880383,
                            "lon": 55.07611559109047
                        },
                        {
                            "lat": 61.48771359731035,
                            "lon": 55.07432081631677
                        },
                        {
                            "lat": 61.490841598861614,
                            "lon": 55.07438620532685
                        },
                        {
                            "lat": 61.49095548493701,
                            "lon": 55.072591425524166
                        },
                        {
                            "lat": 61.49408335944459,
                            "lon": 55.07265672991677
                        },
                        {
                            "lat": 61.49721124662167,
                            "lon": 55.07272195402867
                        },
                        {
                            "lat": 61.497324840579964,
                            "lon": 55.07092716487663
                        },
                        {
                            "lat": 61.5004526006921,
                            "lon": 55.07099230438595
                        },
                        {
                            "lat": 61.503580373440755,
                            "lon": 55.07105736361845
                        },
                        {
                            "lat": 61.503693675338255,
                            "lon": 55.06926256513988
                        },
                        {
                            "lat": 61.5068213210007,
                            "lon": 55.0693275397847
                        },
                        {
                            "lat": 61.509948979266774,
                            "lon": 55.069392434156605
                        },
                        {
                            "lat": 61.509835956785665,
                            "lon": 55.07118724125007
                        },
                        {
                            "lat": 61.51296376735089,
                            "lon": 55.07125205964782
                        },
                        {
                            "lat": 61.512850872006354,
                            "lon": 55.07304687035235
                        },
                        {
                            "lat": 61.51597883487455,
                            "lon": 55.07311161276499
                        },
                        {
                            "lat": 61.51586606669763,
                            "lon": 55.07490642707597
                        },
                        {
                            "lat": 61.515753285957935,
                            "lon": 55.0767012406989
                        },
                        {
                            "lat": 61.518881540894334,
                            "lon": 55.076765911405516
                        },
                        {
                            "lat": 61.52200980837993,
                            "lon": 55.07683050181513
                        },
                        {
                            "lat": 61.52189729463133,
                            "lon": 55.07862532332542
                        },
                        {
                            "lat": 61.518768887366896,
                            "lon": 55.078560728630706
                        },
                        {
                            "lat": 61.51564049265353,
                            "lon": 55.078496053633685
                        },
                        {
                            "lat": 61.51251211050677,
                            "lon": 55.07843129833505
                        },
                        {
                            "lat": 61.50938374094213,
                            "lon": 55.07836646273552
                        },
                        {
                            "lat": 61.506255383975116,
                            "lon": 55.07830154683577
                        },
                        {
                            "lat": 61.503127039621276,
                            "lon": 55.07823655063652
                        },
                        {
                            "lat": 61.50301367459947,
                            "lon": 55.08003134566693
                        },
                        {
                            "lat": 61.50614215873814,
                            "lon": 55.080096346178216
                        },
                        {
                            "lat": 61.5092706554919,
                            "lon": 55.080161266384685
                        },
                        {
                            "lat": 61.50915755744211,
                            "lon": 55.08195606934496
                        },
                        {
                            "lat": 61.50904444679084,
                            "lon": 55.08375087161633
                        },
                        {
                            "lat": 61.51217323576807,
                            "lon": 55.083815720121244
                        },
                        {
                            "lat": 61.512060252348675,
                            "lon": 55.08561052600624
                        },
                        {
                            "lat": 61.51194725633952,
                            "lon": 55.0874053312027
                        },
                        {
                            "lat": 61.51507633759443,
                            "lon": 55.08747010798564
                        },
                        {
                            "lat": 61.51820543142548,
                            "lon": 55.08753480444039
                        },
                        {
                            "lat": 61.52133453781711,
                            "lon": 55.08759942056627
                        },
                        {
                            "lat": 61.52144711426247,
                            "lon": 55.08580460249288
                        },
                        {
                            "lat": 61.52155967816467,
                            "lon": 55.084009783732085
                        },
                        {
                            "lat": 61.52468851740008,
                            "lon": 55.084074310965384
                        },
                        {
                            "lat": 61.52781736916121,
                            "lon": 55.08413875787904
                        },
                        {
                            "lat": 61.530946233432566,
                            "lon": 55.08420312447232
                        },
                        {
                            "lat": 61.53407511019853,
                            "lon": 55.08426741074456
                        },
                        {
                            "lat": 61.53720399944363,
                            "lon": 55.08433161669502
                        },
                        {
                            "lat": 61.5370921347748,
                            "lon": 55.086126456809154
                        },
                        {
                            "lat": 61.53698025764048,
                            "lon": 55.0879212962378
                        },
                        {
                            "lat": 61.536868368038796,
                            "lon": 55.08971613498094
                        },
                        {
                            "lat": 61.53373905919568,
                            "lon": 55.089651916249224
                        },
                        {
                            "lat": 61.533627017227644,
                            "lon": 55.09144675004566
                        },
                        {
                            "lat": 61.53351496277291,
                            "lon": 55.093241583156214
                        },
                        {
                            "lat": 61.53664455142572,
                            "lon": 55.093305810410655
                        },
                        {
                            "lat": 61.53977415255159,
                            "lon": 55.09336995731608
                        },
                        {
                            "lat": 61.539885927179185,
                            "lon": 55.09157511568775
                        },
                        {
                            "lat": 61.53999768935116,
                            "lon": 55.08978027337427
                        },
                        {
                            "lat": 61.54312702311725,
                            "lon": 55.089844331428495
                        },
                        {
                            "lat": 61.546256369321526,
                            "lon": 55.08990830914291
                        },
                        {
                            "lat": 61.546144886953165,
                            "lon": 55.09170315995217
                        },
                        {
                            "lat": 61.54603339216027,
                            "lon": 55.093498010077035
                        },
                        {
                            "lat": 61.54290376613494,
                            "lon": 55.09343402387177
                        },
                        {
                            "lat": 61.5427921189818,
                            "lon": 55.095228869066254
                        },
                        {
                            "lat": 61.54592188494096,
                            "lon": 55.0952928595175
                        },
                        {
                            "lat": 61.54905166332842,
                            "lon": 55.09535676961231
                        },
                        {
                            "lat": 61.549163030612,
                            "lon": 55.09356191593117
                        },
                        {
                            "lat": 61.55229268147458,
                            "lon": 55.09362574143348
                        },
                        {
                            "lat": 61.5554223447325,
                            "lon": 55.09368948658327
                        },
                        {
                            "lat": 61.555533419759186,
                            "lon": 55.09189462375349
                        },
                        {
                            "lat": 61.55866295547103,
                            "lon": 55.09195828432577
                        },
                        {
                            "lat": 61.55855202037016,
                            "lon": 55.09375315137986
                        },
                        {
                            "lat": 61.561681708372035,
                            "lon": 55.09381673582257
                        },
                        {
                            "lat": 61.56157090085021,
                            "lon": 55.095611606413165
                        },
                        {
                            "lat": 61.561460080977824,
                            "lon": 55.09740647632125
                        },
                        {
                            "lat": 61.56134924875303,
                            "lon": 55.0992013455468
                        },
                        {
                            "lat": 61.561238404173935,
                            "lon": 55.100996214089804
                        },
                        {
                            "lat": 61.564368664398316,
                            "lon": 55.10105973503646
                        },
                        {
                            "lat": 61.56425794747038,
                            "lon": 55.10285460711247
                        },
                        {
                            "lat": 61.56414721819998,
                            "lon": 55.10464947850632
                        },
                        {
                            "lat": 61.56403647658522,
                            "lon": 55.10644434921796
                        },
                        {
                            "lat": 61.56392572262421,
                            "lon": 55.108239219247395
                        },
                        {
                            "lat": 61.56079490227707,
                            "lon": 55.10817568143617
                        }
                    ],
                    [
                        {
                            "lat": 61.4642065430333,
                            "lon": 55.09898720177347
                        },
                        {
                            "lat": 61.46432172641578,
                            "lon": 55.09719246654014
                        },
                        {
                            "lat": 61.467451456433494,
                            "lon": 55.097258474433225
                        },
                        {
                            "lat": 61.46733641298357,
                            "lon": 55.099053214046656
                        },
                        {
                            "lat": 61.4642065430333,
                            "lon": 55.09898720177347
                        }
                    ],
                    [
                        {
                            "lat": 61.525025714431315,
                            "lon": 55.078689837717114
                        },
                        {
                            "lat": 61.52491332794346,
                            "lon": 55.08048466282019
                        },
                        {
                            "lat": 61.5217847683472,
                            "lon": 55.080420144148334
                        },
                        {
                            "lat": 61.52189729463133,
                            "lon": 55.07862532332542
                        },
                        {
                            "lat": 61.525025714431315,
                            "lon": 55.078689837717114
                        }
                    ],
                    [
                        {
                            "lat": 61.55899568660707,
                            "lon": 55.08657367906623
                        },
                        {
                            "lat": 61.558884788587605,
                            "lon": 55.08836854816893
                        },
                        {
                            "lat": 61.5557555326808,
                            "lon": 55.088304896044214
                        },
                        {
                            "lat": 61.555866570579454,
                            "lon": 55.0865100311647
                        },
                        {
                            "lat": 61.55273746692407,
                            "lon": 55.086446302931236
                        },
                        {
                            "lat": 61.55284863231226,
                            "lon": 55.084651441596655
                        },
                        {
                            "lat": 61.55597759610586,
                            "lon": 55.08471516560198
                        },
                        {
                            "lat": 61.559106572269826,
                            "lon": 55.08477880928068
                        },
                        {
                            "lat": 61.55899568660707,
                            "lon": 55.08657367906623
                        }
                    ],
                    [
                        {
                            "lat": 61.48561264062364,
                            "lon": 55.05810238201087
                        },
                        {
                            "lat": 61.48873937249,
                            "lon": 55.05816781223475
                        },
                        {
                            "lat": 61.48862544823619,
                            "lon": 55.05996259323207
                        },
                        {
                            "lat": 61.48549857676713,
                            "lon": 55.05989715866853
                        },
                        {
                            "lat": 61.48561264062364,
                            "lon": 55.05810238201087
                        }
                    ],
                    [
                        {
                            "lat": 61.49082973288039,
                            "lon": 55.17322904432697
                        },
                        {
                            "lat": 61.487693996720346,
                            "lon": 55.17316357731716
                        },
                        {
                            "lat": 61.48780844285821,
                            "lon": 55.171368831193725
                        },
                        {
                            "lat": 61.48467286006343,
                            "lon": 55.17130328795845
                        },
                        {
                            "lat": 61.484787433997376,
                            "lon": 55.16950854549667
                        },
                        {
                            "lat": 61.481652004563955,
                            "lon": 55.16944292604697
                        },
                        {
                            "lat": 61.48176670626269,
                            "lon": 55.16764818725138
                        },
                        {
                            "lat": 61.47863143018676,
                            "lon": 55.16758249159826
                        },
                        {
                            "lat": 61.475496166929624,
                            "lon": 55.16751671538436
                        },
                        {
                            "lat": 61.47561113689664,
                            "lon": 55.16572198462789
                        },
                        {
                            "lat": 61.478746259619015,
                            "lon": 55.16578775647346
                        },
                        {
                            "lat": 61.48188139515827,
                            "lon": 55.16585344776362
                        },
                        {
                            "lat": 61.48501654349875,
                            "lon": 55.165919058497636
                        },
                        {
                            "lat": 61.48490199514184,
                            "lon": 55.16771380234305
                        },
                        {
                            "lat": 61.488037296808514,
                            "lon": 55.16777933687252
                        },
                        {
                            "lat": 61.4879228762203,
                            "lon": 55.16957408437884
                        },
                        {
                            "lat": 61.49105833121706,
                            "lon": 55.16963954269277
                        },
                        {
                            "lat": 61.49094403842881,
                            "lon": 55.17143429385541
                        },
                        {
                            "lat": 61.49407964675954,
                            "lon": 55.171499675942805
                        },
                        {
                            "lat": 61.497215267834775,
                            "lon": 55.1715649774552
                        },
                        {
                            "lat": 61.50035090163887,
                            "lon": 55.17163019839189
                        },
                        {
                            "lat": 61.50348654815617,
                            "lon": 55.171695338752144
                        },
                        {
                            "lat": 61.50337280498502,
                            "lon": 55.1734901065629
                        },
                        {
                            "lat": 61.50650860479904,
                            "lon": 55.17355517066747
                        },
                        {
                            "lat": 61.5096444172969,
                            "lon": 55.17362015418887
                        },
                        {
                            "lat": 61.509530942657776,
                            "lon": 55.175414929948424
                        },
                        {
                            "lat": 61.50941745534851,
                            "lon": 55.17720970501924
                        },
                        {
                            "lat": 61.50930395536719,
                            "lon": 55.17900447940128
                        },
                        {
                            "lat": 61.50919044271188,
                            "lon": 55.18079925309455
                        },
                        {
                            "lat": 61.50907691738066,
                            "lon": 55.182594026099025
                        },
                        {
                            "lat": 61.51221344579655,
                            "lon": 55.182658950596185
                        },
                        {
                            "lat": 61.51534998687461,
                            "lon": 55.18272379448202
                        },
                        {
                            "lat": 61.51523673026136,
                            "lon": 55.184518575418544
                        },
                        {
                            "lat": 61.518373424686736,
                            "lon": 55.1845833429948
                        },
                        {
                            "lat": 61.51826029614149,
                            "lon": 55.18637812754607
                        },
                        {
                            "lat": 61.515123460999654,
                            "lon": 55.18631335566702
                        },
                        {
                            "lat": 61.511986638508176,
                            "lon": 55.186248503165274
                        },
                        {
                            "lat": 61.511873215865904,
                            "lon": 55.188043278417176
                        },
                        {
                            "lat": 61.508736265312194,
                            "lon": 55.187978340979576
                        },
                        {
                            "lat": 61.50884982868274,
                            "lon": 55.18618357004154
                        },
                        {
                            "lat": 61.50571303153902,
                            "lon": 55.18611855629657
                        },
                        {
                            "lat": 61.50582672293853,
                            "lon": 55.184323788988515
                        },
                        {
                            "lat": 61.5026900792009,
                            "lon": 55.18425869894712
                        },
                        {
                            "lat": 61.50280389859806,
                            "lon": 55.182463935273645
                        },
                        {
                            "lat": 61.49966740826269,
                            "lon": 55.182398768946854
                        },
                        {
                            "lat": 61.499781355626155,
                            "lon": 55.180604008912546
                        },
                        {
                            "lat": 61.49664501868915,
                            "lon": 55.18053876631134
                        },
                        {
                            "lat": 61.49675909398762,
                            "lon": 55.17874400992075
                        },
                        {
                            "lat": 61.493622910445104,
                            "lon": 55.17867869105618
                        },
                        {
                            "lat": 61.49373711364725,
                            "lon": 55.17688393831388
                        },
                        {
                            "lat": 61.49060108349536,
                            "lon": 55.176818543196916
                        },
                        {
                            "lat": 61.490715414569905,
                            "lon": 55.175023794107474
                        },
                        {
                            "lat": 61.49082973288039,
                            "lon": 55.17322904432697
                        }
                    ],
                    [
                        {
                            "lat": 61.52139714391803,
                            "lon": 55.18644281880173
                        },
                        {
                            "lat": 61.521284143472116,
                            "lon": 55.18823760696314
                        },
                        {
                            "lat": 61.518147154961525,
                            "lon": 55.188172911409644
                        },
                        {
                            "lat": 61.51826029614149,
                            "lon": 55.18637812754607
                        },
                        {
                            "lat": 61.52139714391803,
                            "lon": 55.18644281880173
                        }
                    ],
                    [
                        {
                            "lat": 61.52442114460366,
                            "lon": 55.1883022218872
                        },
                        {
                            "lat": 61.52755815834048,
                            "lon": 55.188366756181104
                        },
                        {
                            "lat": 61.52744542677881,
                            "lon": 55.19016155223565
                        },
                        {
                            "lat": 61.53058259386073,
                            "lon": 55.19022601018089
                        },
                        {
                            "lat": 61.533719773518506,
                            "lon": 55.190290387489235
                        },
                        {
                            "lat": 61.5336073109095,
                            "lon": 55.19208519141705
                        },
                        {
                            "lat": 61.536744643902445,
                            "lon": 55.19214949235967
                        },
                        {
                            "lat": 61.53663230952199,
                            "lon": 55.19394429987398
                        },
                        {
                            "lat": 61.53976979585391,
                            "lon": 55.194008524439894
                        },
                        {
                            "lat": 61.539657589733366,
                            "lon": 55.1958033355361
                        },
                        {
                            "lat": 61.54279522940808,
                            "lon": 55.19586748371428
                        },
                        {
                            "lat": 61.542683151578814,
                            "lon": 55.19766229838778
                        },
                        {
                            "lat": 61.53954537107826,
                            "lon": 55.19759814594727
                        },
                        {
                            "lat": 61.53943313988668,
                            "lon": 55.19939295567337
                        },
                        {
                            "lat": 61.53932089615674,
                            "lon": 55.2011877647144
                        },
                        {
                            "lat": 61.53920863988648,
                            "lon": 55.20298257307038
                        },
                        {
                            "lat": 61.53607044935652,
                            "lon": 55.20291832716409
                        },
                        {
                            "lat": 61.532932271384446,
                            "lon": 55.202854000582114
                        },
                        {
                            "lat": 61.533044809394745,
                            "lon": 55.20105920076917
                        },
                        {
                            "lat": 61.53315733483337,
                            "lon": 55.199264400270366
                        },
                        {
                            "lat": 61.533269847702265,
                            "lon": 55.197469599085764
                        },
                        {
                            "lat": 61.53013210485817,
                            "lon": 55.19740520466619
                        },
                        {
                            "lat": 61.5302447459793,
                            "lon": 55.195610407074135
                        },
                        {
                            "lat": 61.527107156536914,
                            "lon": 55.19554593628001
                        },
                        {
                            "lat": 61.5239695796918,
                            "lon": 55.19548138483367
                        },
                        {
                            "lat": 61.52083201545967,
                            "lon": 55.19541675273579
                        },
                        {
                            "lat": 61.520945066400934,
                            "lon": 55.19362196732363
                        },
                        {
                            "lat": 61.51780765559418,
                            "lon": 55.19355725887431
                        },
                        {
                            "lat": 61.5179208346898,
                            "lon": 55.191762477073794
                        },
                        {
                            "lat": 61.521058104715486,
                            "lon": 55.191827181224106
                        },
                        {
                            "lat": 61.521171130405236,
                            "lon": 55.19003239443728
                        },
                        {
                            "lat": 61.521284143472116,
                            "lon": 55.18823760696314
                        },
                        {
                            "lat": 61.52442114460366,
                            "lon": 55.1883022218872
                        }
                    ],
                    [
                        {
                            "lat": 61.508438125092766,
                            "lon": 55.143043950708176
                        },
                        {
                            "lat": 61.50832472520021,
                            "lon": 55.14483873386483
                        },
                        {
                            "lat": 61.505191173096314,
                            "lon": 55.14477373885706
                        },
                        {
                            "lat": 61.50530471333796,
                            "lon": 55.14297896001567
                        },
                        {
                            "lat": 61.508438125092766,
                            "lon": 55.143043950708176
                        }
                    ],
                    [
                        {
                            "lat": 61.445307410640496,
                            "lon": 55.148907623404035
                        },
                        {
                            "lat": 61.44519115226225,
                            "lon": 55.1507023164556
                        },
                        {
                            "lat": 61.44205729510001,
                            "lon": 55.150635693949695
                        },
                        {
                            "lat": 61.44217369385081,
                            "lon": 55.14884100532157
                        },
                        {
                            "lat": 61.445307410640496,
                            "lon": 55.148907623404035
                        }
                    ],
                    [
                        {
                            "lat": 61.44855724544953,
                            "lon": 55.14717946282095
                        },
                        {
                            "lat": 61.44542365604767,
                            "lon": 55.14711292965568
                        },
                        {
                            "lat": 61.445539888485726,
                            "lon": 55.145318235210546
                        },
                        {
                            "lat": 61.44867333754427,
                            "lon": 55.145384763958496
                        },
                        {
                            "lat": 61.44855724544953,
                            "lon": 55.14717946282095
                        }
                    ],
                    [
                        {
                            "lat": 61.4487894166875,
                            "lon": 55.14359006439967
                        },
                        {
                            "lat": 61.44890548288115,
                            "lon": 55.141795364144485
                        },
                        {
                            "lat": 61.452038664247496,
                            "lon": 55.14186180358009
                        },
                        {
                            "lat": 61.45517185854584,
                            "lon": 55.14192816253605
                        },
                        {
                            "lat": 61.455056072982295,
                            "lon": 55.1437228716083
                        },
                        {
                            "lat": 61.451922738367905,
                            "lon": 55.14365650824645
                        },
                        {
                            "lat": 61.45180679955436,
                            "lon": 55.14545121221689
                        },
                        {
                            "lat": 61.44867333754427,
                            "lon": 55.145384763958496
                        },
                        {
                            "lat": 61.4487894166875,
                            "lon": 55.14359006439967
                        }
                    ],
                    [
                        {
                            "lat": 61.4457723144624,
                            "lon": 55.14172884423
                        },
                        {
                            "lat": 61.445888508004934,
                            "lon": 55.139934147694625
                        },
                        {
                            "lat": 61.446004688586264,
                            "lon": 55.1381394504625
                        },
                        {
                            "lat": 61.44913757642763,
                            "lon": 55.138205961545125
                        },
                        {
                            "lat": 61.4490215361272,
                            "lon": 55.140000663192964
                        },
                        {
                            "lat": 61.44890548288115,
                            "lon": 55.141795364144485
                        },
                        {
                            "lat": 61.4457723144624,
                            "lon": 55.14172884423
                        }
                    ],
                    [
                        {
                            "lat": 61.46132278095049,
                            "lon": 55.14385535687421
                        },
                        {
                            "lat": 61.46120726313773,
                            "lon": 55.14565007404745
                        },
                        {
                            "lat": 61.464340776797876,
                            "lon": 55.145716200340374
                        },
                        {
                            "lat": 61.464225386449144,
                            "lon": 55.14751092120946
                        },
                        {
                            "lat": 61.46410998322495,
                            "lon": 55.14930564138418
                        },
                        {
                            "lat": 61.46724379048437,
                            "lon": 55.14937169595499
                        },
                        {
                            "lat": 61.46712851476906,
                            "lon": 55.15116641982139
                        },
                        {
                            "lat": 61.46701322619002,
                            "lon": 55.152961142993725
                        },
                        {
                            "lat": 61.46387913814237,
                            "lon": 55.15289507965025
                        },
                        {
                            "lat": 61.46074506297605,
                            "lon": 55.15282893579229
                        },
                        {
                            "lat": 61.46086063235699,
                            "lon": 55.15103422139831
                        },
                        {
                            "lat": 61.45772671048561,
                            "lon": 55.150968001423756
                        },
                        {
                            "lat": 61.45784240735395,
                            "lon": 55.149173290731746
                        },
                        {
                            "lat": 61.45470863877362,
                            "lon": 55.14910699465158
                        },
                        {
                            "lat": 61.45459280152484,
                            "lon": 55.15090170094149
                        },
                        {
                            "lat": 61.45145890549029,
                            "lon": 55.15083531995223
                        },
                        {
                            "lat": 61.44832502239754,
                            "lon": 55.15076885845669
                        },
                        {
                            "lat": 61.448441140401265,
                            "lon": 55.148974160987024
                        },
                        {
                            "lat": 61.451574883117516,
                            "lon": 55.14904061806977
                        },
                        {
                            "lat": 61.45169084780489,
                            "lon": 55.14724591549131
                        },
                        {
                            "lat": 61.45482446309812,
                            "lon": 55.14731228766608
                        },
                        {
                            "lat": 61.454940274500366,
                            "lon": 55.14551757998499
                        },
                        {
                            "lat": 61.4580737623667,
                            "lon": 55.14558386726209
                        },
                        {
                            "lat": 61.45818942051501,
                            "lon": 55.143789154484466
                        },
                        {
                            "lat": 61.46132278095049,
                            "lon": 55.14385535687421
                        }
                    ],
                    [
                        {
                            "lat": 61.46062948069816,
                            "lon": 55.15462364949143
                        },
                        {
                            "lat": 61.45749527801516,
                            "lon": 55.15455742072214
                        },
                        {
                            "lat": 61.45436108824669,
                            "lon": 55.15449111143445
                        },
                        {
                            "lat": 61.45424521221337,
                            "lon": 55.15628581563746
                        },
                        {
                            "lat": 61.45111089494975,
                            "lon": 55.15621942142347
                        },
                        {
                            "lat": 61.45122691140837,
                            "lon": 55.1544247216291
                        },
                        {
                            "lat": 61.45134291492122,
                            "lon": 55.152630021138684
                        },
                        {
                            "lat": 61.454476951349854,
                            "lon": 55.15269640653579
                        },
                        {
                            "lat": 61.457611000706684,
                            "lon": 55.15276271142056
                        },
                        {
                            "lat": 61.46074506297605,
                            "lon": 55.15282893579229
                        },
                        {
                            "lat": 61.46062948069816,
                            "lon": 55.15462364949143
                        }
                    ],
                    [
                        {
                            "lat": 61.46376369628006,
                            "lon": 55.15468979774163
                        },
                        {
                            "lat": 61.46364824153446,
                            "lon": 55.156484515138544
                        },
                        {
                            "lat": 61.46051388552137,
                            "lon": 55.156418362495714
                        },
                        {
                            "lat": 61.46062948069816,
                            "lon": 55.15462364949143
                        },
                        {
                            "lat": 61.46376369628006,
                            "lon": 55.15468979774163
                        }
                    ],
                    [
                        {
                            "lat": 61.439389529406036,
                            "lon": 55.143390249956816
                        },
                        {
                            "lat": 61.43950601653005,
                            "lon": 55.14159556296722
                        },
                        {
                            "lat": 61.44263915900683,
                            "lon": 55.141662243837345
                        },
                        {
                            "lat": 61.44252281219095,
                            "lon": 55.14345693525412
                        },
                        {
                            "lat": 61.439389529406036,
                            "lon": 55.143390249956816
                        }
                    ],
                    [
                        {
                            "lat": 61.43927302928563,
                            "lon": 55.14518493624889
                        },
                        {
                            "lat": 61.43613961917531,
                            "lon": 55.1451181660366
                        },
                        {
                            "lat": 61.43625625961748,
                            "lon": 55.14332348417745
                        },
                        {
                            "lat": 61.439389529406036,
                            "lon": 55.143390249956816
                        },
                        {
                            "lat": 61.43927302928563,
                            "lon": 55.14518493624889
                        }
                    ],
                    [
                        {
                            "lat": 61.4793202145662,
                            "lon": 55.156814070461
                        },
                        {
                            "lat": 61.47943496712646,
                            "lon": 55.155019331180895
                        },
                        {
                            "lat": 61.482569259773776,
                            "lon": 55.15508499630137
                        },
                        {
                            "lat": 61.48245464765644,
                            "lon": 55.156879739942106
                        },
                        {
                            "lat": 61.4793202145662,
                            "lon": 55.156814070461
                        }
                    ],
                    [
                        {
                            "lat": 61.470722939517366,
                            "lon": 55.14405348111792
                        },
                        {
                            "lat": 61.47385635140778,
                            "lon": 55.1441193615549
                        },
                        {
                            "lat": 61.47374139495683,
                            "lon": 55.14591409625725
                        },
                        {
                            "lat": 61.47060784272303,
                            "lon": 55.14584821144599
                        },
                        {
                            "lat": 61.470722939517366,
                            "lon": 55.14405348111792
                        }
                    ],
                    [
                        {
                            "lat": 61.469801805469466,
                            "lon": 55.158411304320516
                        },
                        {
                            "lat": 61.46968660588218,
                            "lon": 55.160206029099314
                        },
                        {
                            "lat": 61.46655194319711,
                            "lon": 55.160140028742404
                        },
                        {
                            "lat": 61.46666728325076,
                            "lon": 55.15834530834635
                        },
                        {
                            "lat": 61.469801805469466,
                            "lon": 55.158411304320516
                        }
                    ],
                    [
                        {
                            "lat": 61.46934092996505,
                            "lon": 55.165590199273346
                        },
                        {
                            "lat": 61.466205845787094,
                            "lon": 55.16552418576582
                        },
                        {
                            "lat": 61.46632122446724,
                            "lon": 55.16372946745213
                        },
                        {
                            "lat": 61.469456168131934,
                            "lon": 55.163795476575736
                        },
                        {
                            "lat": 61.46934092996505,
                            "lon": 55.165590199273346
                        }
                    ],
                    [
                        {
                            "lat": 61.50205763366105,
                            "lon": 55.14470866335215
                        },
                        {
                            "lat": 61.50194394038802,
                            "lon": 55.14650343718324
                        },
                        {
                            "lat": 61.50183023442879,
                            "lon": 55.14829821032459
                        },
                        {
                            "lat": 61.49869642694048,
                            "lon": 55.14823304567026
                        },
                        {
                            "lat": 61.49881027327615,
                            "lon": 55.146438276855605
                        },
                        {
                            "lat": 61.49567661886608,
                            "lon": 55.14637303602695
                        },
                        {
                            "lat": 61.49579059285889,
                            "lon": 55.144578270853835
                        },
                        {
                            "lat": 61.49892410691003,
                            "lon": 55.144643507350835
                        },
                        {
                            "lat": 61.49903792784407,
                            "lon": 55.142848737155965
                        },
                        {
                            "lat": 61.50217131424986,
                            "lon": 55.14291388883136
                        },
                        {
                            "lat": 61.50205763366105,
                            "lon": 55.14470866335215
                        }
                    ],
                    [
                        {
                            "lat": 61.496018502700025,
                            "lon": 55.14098873843614
                        },
                        {
                            "lat": 61.495904554136224,
                            "lon": 55.142783504990206
                        },
                        {
                            "lat": 61.492771193141884,
                            "lon": 55.14271819233479
                        },
                        {
                            "lat": 61.489637844876704,
                            "lon": 55.14265279919045
                        },
                        {
                            "lat": 61.4897520740901,
                            "lon": 55.14085804131409
                        },
                        {
                            "lat": 61.492885282031466,
                            "lon": 55.14092343011692
                        },
                        {
                            "lat": 61.496018502700025,
                            "lon": 55.14098873843614
                        }
                    ],
                    [
                        {
                            "lat": 61.49613243855224,
                            "lon": 55.13919397119159
                        },
                        {
                            "lat": 61.499265531620296,
                            "lon": 55.139259194695995
                        },
                        {
                            "lat": 61.499151736080194,
                            "lon": 55.14105396627102
                        },
                        {
                            "lat": 61.496018502700025,
                            "lon": 55.14098873843614
                        },
                        {
                            "lat": 61.49613243855224,
                            "lon": 55.13919397119159
                        }
                    ],
                    [
                        {
                            "lat": 61.5286847818443,
                            "lon": 55.1704187578786
                        },
                        {
                            "lat": 61.52879737502552,
                            "lon": 55.16862395427292
                        },
                        {
                            "lat": 61.52890995563816,
                            "lon": 55.166829149980806
                        },
                        {
                            "lat": 61.525774629722385,
                            "lon": 55.166764667107394
                        },
                        {
                            "lat": 61.52588733831841,
                            "lon": 55.16496986641144
                        },
                        {
                            "lat": 61.529022523684155,
                            "lon": 55.165034345002326
                        },
                        {
                            "lat": 61.532157721614645,
                            "lon": 55.1650987430318
                        },
                        {
                            "lat": 61.5322701365596,
                            "lon": 55.1633039330901
                        },
                        {
                            "lat": 61.535405206500975,
                            "lon": 55.16336824628591
                        },
                        {
                            "lat": 61.535292932094215,
                            "lon": 55.165163060499104
                        },
                        {
                            "lat": 61.53518064515399,
                            "lon": 55.16695787402668
                        },
                        {
                            "lat": 61.535068345678425,
                            "lon": 55.16875268686861
                        },
                        {
                            "lat": 61.538203849818586,
                            "lon": 55.16881693230654
                        },
                        {
                            "lat": 61.541339366480386,
                            "lon": 55.16888109717026
                        },
                        {
                            "lat": 61.544474895648165,
                            "lon": 55.16894518145908
                        },
                        {
                            "lat": 61.54436300540215,
                            "lon": 55.17074000640127
                        },
                        {
                            "lat": 61.54122733564353,
                            "lon": 55.17067591785584
                        },
                        {
                            "lat": 61.541115292298784,
                            "lon": 55.172470737856536
                        },
                        {
                            "lat": 61.53797949444345,
                            "lon": 55.1724065644685
                        },
                        {
                            "lat": 61.53484370911355,
                            "lon": 55.17234231049558
                        },
                        {
                            "lat": 61.53473137202042,
                            "lon": 55.1741371212806
                        },
                        {
                            "lat": 61.53159545861516,
                            "lon": 55.1740727824501
                        },
                        {
                            "lat": 61.53170793632471,
                            "lon": 55.172277975938485
                        },
                        {
                            "lat": 61.531820401477525,
                            "lon": 55.170483168740844
                        },
                        {
                            "lat": 61.5286847818443,
                            "lon": 55.1704187578786
                        }
                    ],
                    [
                        {
                            "lat": 61.528572176092624,
                            "lon": 55.1722135607979
                        },
                        {
                            "lat": 61.52543642843291,
                            "lon": 55.17214906507454
                        },
                        {
                            "lat": 61.52554917478157,
                            "lon": 55.17035426643893
                        },
                        {
                            "lat": 61.5286847818443,
                            "lon": 55.1704187578786
                        },
                        {
                            "lat": 61.528572176092624,
                            "lon": 55.1722135607979
                        }
                    ],
                    [
                        {
                            "lat": 61.526450692631144,
                            "lon": 55.15599585263057
                        },
                        {
                            "lat": 61.52656332577361,
                            "lon": 55.154201047814226
                        },
                        {
                            "lat": 61.52969766816756,
                            "lon": 55.154265500717905
                        },
                        {
                            "lat": 61.532832023114786,
                            "lon": 55.154329873092145
                        },
                        {
                            "lat": 61.53294436280524,
                            "lon": 55.15253505903472
                        },
                        {
                            "lat": 61.53305668995996,
                            "lon": 55.15074024429141
                        },
                        {
                            "lat": 61.53619077657164,
                            "lon": 55.15080452759752
                        },
                        {
                            "lat": 61.539324875701524,
                            "lon": 55.15086873038347
                        },
                        {
                            "lat": 61.5392128294063,
                            "lon": 55.15266355365909
                        },
                        {
                            "lat": 61.542347081471355,
                            "lon": 55.152727680182345
                        },
                        {
                            "lat": 61.54223516311985,
                            "lon": 55.15452250703134
                        },
                        {
                            "lat": 61.539100770606545,
                            "lon": 55.15445837624956
                        },
                        {
                            "lat": 61.53596639059966,
                            "lon": 55.15439416493627
                        },
                        {
                            "lat": 61.53585417883159,
                            "lon": 55.15618898257732
                        },
                        {
                            "lat": 61.538988699300425,
                            "lon": 55.156253198154864
                        },
                        {
                            "lat": 61.538876615486004,
                            "lon": 55.158048019374995
                        },
                        {
                            "lat": 61.53574195453959,
                            "lon": 55.15798379953283
                        },
                        {
                            "lat": 61.53260730611902,
                            "lon": 55.15791949914918
                        },
                        {
                            "lat": 61.529472670239905,
                            "lon": 55.15785511822471
                        },
                        {
                            "lat": 61.526338046917864,
                            "lon": 55.15779065676017
                        },
                        {
                            "lat": 61.526450692631144,
                            "lon": 55.15599585263057
                        }
                    ],
                    [
                        {
                            "lat": 61.5367515224661,
                            "lon": 55.141830422254245
                        },
                        {
                            "lat": 61.53686363411126,
                            "lon": 55.140035599129156
                        },
                        {
                            "lat": 61.53999689098499,
                            "lon": 55.14009977634223
                        },
                        {
                            "lat": 61.54313016034992,
                            "lon": 55.14016387306646
                        },
                        {
                            "lat": 61.54626344219042,
                            "lon": 55.14022788930112
                        },
                        {
                            "lat": 61.54615175156193,
                            "lon": 55.142022725193804
                        },
                        {
                            "lat": 61.546040048471546,
                            "lon": 55.143817560402155
                        },
                        {
                            "lat": 61.54290648593386,
                            "lon": 55.14375353566606
                        },
                        {
                            "lat": 61.54301832938062,
                            "lon": 55.141958704708614
                        },
                        {
                            "lat": 61.53988491967683,
                            "lon": 55.14189460372852
                        },
                        {
                            "lat": 61.5367515224661,
                            "lon": 55.141830422254245
                        }
                    ],
                    [
                        {
                            "lat": 61.53373038974435,
                            "lon": 55.139971341427895
                        },
                        {
                            "lat": 61.53384262920219,
                            "lon": 55.13817652188349
                        },
                        {
                            "lat": 61.53697573324963,
                            "lon": 55.13824077531861
                        },
                        {
                            "lat": 61.53686363411126,
                            "lon": 55.140035599129156
                        },
                        {
                            "lat": 61.53373038974435,
                            "lon": 55.139971341427895
                        }
                    ],
                    [
                        {
                            "lat": 61.52399267520275,
                            "lon": 55.14516250142222
                        },
                        {
                            "lat": 61.52387996450949,
                            "lon": 55.14695730538822
                        },
                        {
                            "lat": 61.52074620892232,
                            "lon": 55.14689270858765
                        },
                        {
                            "lat": 61.52085905998987,
                            "lon": 55.14509790891062
                        },
                        {
                            "lat": 61.52097189846686,
                            "lon": 55.14330310854623
                        },
                        {
                            "lat": 61.524105373321056,
                            "lon": 55.14336769676921
                        },
                        {
                            "lat": 61.52399267520275,
                            "lon": 55.14516250142222
                        }
                    ],
                    [
                        {
                            "lat": 61.527126302990595,
                            "lon": 55.145227013432454
                        },
                        {
                            "lat": 61.52723886074829,
                            "lon": 55.143432204496165
                        },
                        {
                            "lat": 61.53037236073299,
                            "lon": 55.14349663172638
                        },
                        {
                            "lat": 61.53025994333778,
                            "lon": 55.145291444940625
                        },
                        {
                            "lat": 61.527126302990595,
                            "lon": 55.145227013432454
                        }
                    ],
                    [
                        {
                            "lat": 61.55052276086087,
                            "lon": 55.172662774504275
                        },
                        {
                            "lat": 61.55063438238042,
                            "lon": 55.170867941748604
                        },
                        {
                            "lat": 61.55377008956878,
                            "lon": 55.17093178854909
                        },
                        {
                            "lat": 61.55365860866143,
                            "lon": 55.172726625545764
                        },
                        {
                            "lat": 61.55052276086087,
                            "lon": 55.172662774504275
                        }
                    ],
                    [
                        {
                            "lat": 61.55074599143905,
                            "lon": 55.16907310830917
                        },
                        {
                            "lat": 61.547610437306275,
                            "lon": 55.16900918517227
                        },
                        {
                            "lat": 61.547722174484846,
                            "lon": 55.16721435529471
                        },
                        {
                            "lat": 61.550857588038625,
                            "lon": 55.16727827418604
                        },
                        {
                            "lat": 61.55074599143905,
                            "lon": 55.16907310830917
                        }
                    ],
                    [
                        {
                            "lat": 61.55230721092262,
                            "lon": 55.14394536837091
                        },
                        {
                            "lat": 61.549173623473,
                            "lon": 55.14388150463735
                        },
                        {
                            "lat": 61.54928518620509,
                            "lon": 55.14208666518341
                        },
                        {
                            "lat": 61.5524186332945,
                            "lon": 55.14215052467673
                        },
                        {
                            "lat": 61.55230721092262,
                            "lon": 55.14394536837091
                        }
                    ],
                    [
                        {
                            "lat": 61.53696927502599,
                            "lon": 55.18855987527488
                        },
                        {
                            "lat": 61.53708157177288,
                            "lon": 55.18676506570442
                        },
                        {
                            "lat": 61.54021849502862,
                            "lon": 55.18682927320493
                        },
                        {
                            "lat": 61.54010633902725,
                            "lon": 55.188624087041156
                        },
                        {
                            "lat": 61.53696927502599,
                            "lon": 55.18855987527488
                        }
                    ],
                    [
                        {
                            "lat": 61.53775508896654,
                            "lon": 55.17599619388937
                        },
                        {
                            "lat": 61.53786729796867,
                            "lon": 55.17420137952157
                        },
                        {
                            "lat": 61.54100323644427,
                            "lon": 55.17426555717232
                        },
                        {
                            "lat": 61.54413918743152,
                            "lon": 55.17432965423214
                        },
                        {
                            "lat": 61.5440272597031,
                            "lon": 55.176124477120794
                        },
                        {
                            "lat": 61.54716336382611,
                            "lon": 55.17618849784141
                        },
                        {
                            "lat": 61.54705156425511,
                            "lon": 55.17798332429834
                        },
                        {
                            "lat": 61.54391531947674,
                            "lon": 55.17791929932492
                        },
                        {
                            "lat": 61.54380336675055,
                            "lon": 55.179714120844494
                        },
                        {
                            "lat": 61.54066699380291,
                            "lon": 55.17965001101023
                        },
                        {
                            "lat": 61.537530633372675,
                            "lon": 55.179585820569024
                        },
                        {
                            "lat": 61.53764286743519,
                            "lon": 55.17779100757184
                        },
                        {
                            "lat": 61.534506660203306,
                            "lon": 55.17772674079362
                        },
                        {
                            "lat": 61.53461902238432,
                            "lon": 55.175931931379935
                        },
                        {
                            "lat": 61.53775508896654,
                            "lon": 55.17599619388937
                        }
                    ],
                    [
                        {
                            "lat": 61.54425110266391,
                            "lon": 55.17253483065897
                        },
                        {
                            "lat": 61.54738692552315,
                            "lon": 55.1725988428751
                        },
                        {
                            "lat": 61.54727515091483,
                            "lon": 55.17439367070034
                        },
                        {
                            "lat": 61.54413918743152,
                            "lon": 55.17432965423214
                        },
                        {
                            "lat": 61.54425110266391,
                            "lon": 55.17253483065897
                        }
                    ],
                    [
                        {
                            "lat": 61.54503415956126,
                            "lon": 55.159971046481026
                        },
                        {
                            "lat": 61.54816899849027,
                            "lon": 55.16003502894352
                        },
                        {
                            "lat": 61.548057311191464,
                            "lon": 55.16182986155743
                        },
                        {
                            "lat": 61.54492233174789,
                            "lon": 55.16176587484555
                        },
                        {
                            "lat": 61.54503415956126,
                            "lon": 55.159971046481026
                        }
                    ],
                    [
                        {
                            "lat": 61.58369591329432,
                            "lon": 55.194899199827695
                        },
                        {
                            "lat": 61.583806135587096,
                            "lon": 55.19310432887073
                        },
                        {
                            "lat": 61.586943667441304,
                            "lon": 55.19316733943142
                        },
                        {
                            "lat": 61.58683358596946,
                            "lon": 55.194962214575135
                        },
                        {
                            "lat": 61.58369591329432,
                            "lon": 55.194899199827695
                        }
                    ],
                    [
                        {
                            "lat": 61.57236330675978,
                            "lon": 55.174902901063746
                        },
                        {
                            "lat": 61.57549938200289,
                            "lon": 55.174966192176
                        },
                        {
                            "lat": 61.5753888607567,
                            "lon": 55.176761057348585
                        },
                        {
                            "lat": 61.572252644856874,
                            "lon": 55.17669776203197
                        },
                        {
                            "lat": 61.57236330675978,
                            "lon": 55.174902901063746
                        }
                    ],
                    [
                        {
                            "lat": 61.57560989090962,
                            "lon": 55.17317132632266
                        },
                        {
                            "lat": 61.578745837849254,
                            "lon": 55.1732345326377
                        },
                        {
                            "lat": 61.57885619379109,
                            "lon": 55.17143966190538
                        },
                        {
                            "lat": 61.58199201242353,
                            "lon": 55.171502783433574
                        },
                        {
                            "lat": 61.58188179711084,
                            "lon": 55.17329765835883
                        },
                        {
                            "lat": 61.581771569491885,
                            "lon": 55.175092532604104
                        },
                        {
                            "lat": 61.57863546958547,
                            "lon": 55.17502940268968
                        },
                        {
                            "lat": 61.57549938200289,
                            "lon": 55.174966192176
                        },
                        {
                            "lat": 61.57560989090962,
                            "lon": 55.17317132632266
                        }
                    ],
                    [
                        {
                            "lat": 61.60179097630069,
                            "lon": 55.15572568997192
                        },
                        {
                            "lat": 61.60492561565962,
                            "lon": 55.1557882099777
                        },
                        {
                            "lat": 61.60481649449003,
                            "lon": 55.15758312019969
                        },
                        {
                            "lat": 61.60168171462968,
                            "lon": 55.15752059604173
                        },
                        {
                            "lat": 61.60179097630069,
                            "lon": 55.15572568997192
                        }
                    ],
                    [
                        {
                            "lat": 61.589142717301044,
                            "lon": 55.157269693909996
                        },
                        {
                            "lat": 61.59227744828503,
                            "lon": 55.15733254026653
                        },
                        {
                            "lat": 61.59216775287844,
                            "lon": 55.15912743316896
                        },
                        {
                            "lat": 61.58903288138488,
                            "lon": 55.15906458263849
                        },
                        {
                            "lat": 61.589142717301044,
                            "lon": 55.157269693909996
                        }
                    ],
                    [
                        {
                            "lat": 61.63346141353515,
                            "lon": 55.1509624192793
                        },
                        {
                            "lat": 61.63335359306785,
                            "lon": 55.15275736865269
                        },
                        {
                            "lat": 61.63021912565757,
                            "lon": 55.152695581792685
                        },
                        {
                            "lat": 61.62708467029669,
                            "lon": 55.15263371439188
                        },
                        {
                            "lat": 61.627192771704294,
                            "lon": 55.15083877322994
                        },
                        {
                            "lat": 61.63032708659595,
                            "lon": 55.150900636522344
                        },
                        {
                            "lat": 61.63346141353515,
                            "lon": 55.1509624192793
                        }
                    ],
                    [
                        {
                            "lat": 61.6042386939488,
                            "lon": 55.218672249528126
                        },
                        {
                            "lat": 61.60737828464673,
                            "lon": 55.21873475361555
                        },
                        {
                            "lat": 61.607269018178116,
                            "lon": 55.22052964844156
                        },
                        {
                            "lat": 61.60412928642636,
                            "lon": 55.22046714019964
                        },
                        {
                            "lat": 61.6042386939488,
                            "lon": 55.218672249528126
                        }
                    ],
                    [
                        {
                            "lat": 61.74260793753932,
                            "lon": 55.108137752100546
                        },
                        {
                            "lat": 61.74250518114368,
                            "lon": 55.10993285434297
                        },
                        {
                            "lat": 61.73937382197003,
                            "lon": 55.109873904198025
                        },
                        {
                            "lat": 61.73947671850724,
                            "lon": 55.108078805868445
                        },
                        {
                            "lat": 61.74260793753932,
                            "lon": 55.108137752100546
                        }
                    ],
                    [
                        {
                            "lat": 61.46997917211092,
                            "lon": 55.05777402736376
                        },
                        {
                            "lat": 61.47009392117394,
                            "lon": 55.05597927179024
                        },
                        {
                            "lat": 61.46696740531929,
                            "lon": 55.05591336453136
                        },
                        {
                            "lat": 61.46685251668065,
                            "lon": 55.05770811573378
                        },
                        {
                            "lat": 61.46997917211092,
                            "lon": 55.05777402736376
                        }
                    ],
                    [
                        {
                            "lat": 61.47333504652953,
                            "lon": 55.054250338189114
                        },
                        {
                            "lat": 61.47322044980472,
                            "lon": 55.05604509882184
                        },
                        {
                            "lat": 61.47634699119617,
                            "lon": 55.05611084562547
                        },
                        {
                            "lat": 61.47646144835696,
                            "lon": 55.05431608063264
                        },
                        {
                            "lat": 61.476575892774534,
                            "lon": 55.05252131494682
                        },
                        {
                            "lat": 61.473449630495594,
                            "lon": 55.05245557686302
                        },
                        {
                            "lat": 61.470323380973454,
                            "lon": 55.052389758561844
                        },
                        {
                            "lat": 61.4702086574608,
                            "lon": 55.05418451552291
                        },
                        {
                            "lat": 61.47333504652953,
                            "lon": 55.054250338189114
                        }
                    ],
                    [
                        {
                            "lat": 61.47985602085665,
                            "lon": 55.09931645958467
                        },
                        {
                            "lat": 61.48298595476518,
                            "lon": 55.09938207007695
                        },
                        {
                            "lat": 61.48611590141872,
                            "lon": 55.09944760021089
                        },
                        {
                            "lat": 61.486230105232615,
                            "lon": 55.09765283442861
                        },
                        {
                            "lat": 61.48634429631896,
                            "lon": 55.09585806795458
                        },
                        {
                            "lat": 61.48321462953823,
                            "lon": 55.09579254651663
                        },
                        {
                            "lat": 61.48310029852325,
                            "lon": 55.09758730864285
                        },
                        {
                            "lat": 61.479970504556974,
                            "lon": 55.09752170250407
                        },
                        {
                            "lat": 61.47985602085665,
                            "lon": 55.09931645958467
                        }
                    ],
                    [
                        {
                            "lat": 61.47302218152803,
                            "lon": 55.108158728916706
                        },
                        {
                            "lat": 61.47313700908475,
                            "lon": 55.10636398402563
                        },
                        {
                            "lat": 61.47626747736564,
                            "lon": 55.10642977269283
                        },
                        {
                            "lat": 61.476382152122355,
                            "lon": 55.10463502274271
                        },
                        {
                            "lat": 61.473251823842055,
                            "lon": 55.104569238441194
                        },
                        {
                            "lat": 61.47336662580185,
                            "lon": 55.102774492163434
                        },
                        {
                            "lat": 61.47023645030212,
                            "lon": 55.10270863186042
                        },
                        {
                            "lat": 61.47012150835924,
                            "lon": 55.10450337376754
                        },
                        {
                            "lat": 61.470006553603305,
                            "lon": 55.106298114980945
                        },
                        {
                            "lat": 61.46989158603238,
                            "lon": 55.10809285550062
                        },
                        {
                            "lat": 61.46977660564454,
                            "lon": 55.10988759532657
                        },
                        {
                            "lat": 61.47290734116993,
                            "lon": 55.109953473114444
                        },
                        {
                            "lat": 61.47302218152803,
                            "lon": 55.108158728916706
                        }
                    ],
                    [
                        {
                            "lat": 61.475693911785164,
                            "lon": 55.11540351204894
                        },
                        {
                            "lat": 61.47580865048065,
                            "lon": 55.1136087655637
                        },
                        {
                            "lat": 61.47267762204186,
                            "lon": 55.1135429594298
                        },
                        {
                            "lat": 61.47256274326798,
                            "lon": 55.11533770154742
                        },
                        {
                            "lat": 61.475693911785164,
                            "lon": 55.11540351204894
                        }
                    ],
                    [
                        {
                            "lat": 61.468879848640114,
                            "lon": 55.17276908312608
                        },
                        {
                            "lat": 61.46574420225278,
                            "lon": 55.17270305207891
                        },
                        {
                            "lat": 61.46260856875271,
                            "lon": 55.172636940457814
                        },
                        {
                            "lat": 61.46249298506638,
                            "lon": 55.174431650909405
                        },
                        {
                            "lat": 61.465628759155955,
                            "lon": 55.17449776692172
                        },
                        {
                            "lat": 61.46876454613474,
                            "lon": 55.17456380235478
                        },
                        {
                            "lat": 61.468879848640114,
                            "lon": 55.17276908312608
                        }
                    ],
                    [
                        {
                            "lat": 61.54077908719822,
                            "lon": 55.177855193749195
                        },
                        {
                            "lat": 61.54089116807801,
                            "lon": 55.1760603758032
                        },
                        {
                            "lat": 61.53775508896654,
                            "lon": 55.17599619388937
                        },
                        {
                            "lat": 61.53764286743519,
                            "lon": 55.17779100757184
                        },
                        {
                            "lat": 61.54077908719822,
                            "lon": 55.177855193749195
                        }
                    ],
                    [
                        {
                            "lat": 61.5687049291832,
                            "lon": 55.234132953323886
                        },
                        {
                            "lat": 61.565564216404574,
                            "lon": 55.23406944249416
                        },
                        {
                            "lat": 61.5654530045522,
                            "lon": 55.235864276757496
                        },
                        {
                            "lat": 61.568593858503675,
                            "lon": 55.2359277918094
                        },
                        {
                            "lat": 61.5687049291832,
                            "lon": 55.234132953323886
                        }
                    ],
                    [
                        {
                            "lat": 61.62654343666086,
                            "lon": 55.05737736228465
                        },
                        {
                            "lat": 61.62665118052116,
                            "lon": 55.05558239357389
                        },
                        {
                            "lat": 61.623524324135694,
                            "lon": 55.05552050674616
                        },
                        {
                            "lat": 61.623416440636774,
                            "lon": 55.05731547135198
                        },
                        {
                            "lat": 61.62654343666086,
                            "lon": 55.05737736228465
                        }
                    ],
                    [
                        {
                            "lat": 61.6471192026311,
                            "lon": 55.237489412903976
                        },
                        {
                            "lat": 61.65026037436788,
                            "lon": 55.237550827459756
                        },
                        {
                            "lat": 61.65340155811461,
                            "lon": 55.23761216121806
                        },
                        {
                            "lat": 61.65654275385554,
                            "lon": 55.237673414178225
                        },
                        {
                            "lat": 61.659683961574935,
                            "lon": 55.23773458633956
                        },
                        {
                            "lat": 61.66282518125704,
                            "lon": 55.23779567770141
                        },
                        {
                            "lat": 61.66596641288609,
                            "lon": 55.23785668826312
                        },
                        {
                            "lat": 61.66910765644637,
                            "lon": 55.237917618024014
                        },
                        {
                            "lat": 61.672248911922104,
                            "lon": 55.237978466983414
                        },
                        {
                            "lat": 61.675390179297544,
                            "lon": 55.238039235140654
                        },
                        {
                            "lat": 61.678531458556925,
                            "lon": 55.23809992249509
                        },
                        {
                            "lat": 61.68167274968454,
                            "lon": 55.23816052904604
                        },
                        {
                            "lat": 61.684814052664585,
                            "lon": 55.238221054792845
                        },
                        {
                            "lat": 61.687955367481365,
                            "lon": 55.23828149973485
                        },
                        {
                            "lat": 61.68806107221151,
                            "lon": 55.2364865047767
                        },
                        {
                            "lat": 61.68491989863788,
                            "lon": 55.236426063853266
                        },
                        {
                            "lat": 61.681778736899126,
                            "lon": 55.23636554213037
                        },
                        {
                            "lat": 61.67863758701102,
                            "lon": 55.236304939608694
                        },
                        {
                            "lat": 61.67874370359869,
                            "lon": 55.234509956053806
                        },
                        {
                            "lat": 61.675602706798934,
                            "lon": 55.2344492767683
                        },
                        {
                            "lat": 61.67246172187951,
                            "lon": 55.23438851669069
                        },
                        {
                            "lat": 61.669320748856144,
                            "lon": 55.23432767582165
                        },
                        {
                            "lat": 61.66617978774463,
                            "lon": 55.234266754161844
                        },
                        {
                            "lat": 61.666286457281615,
                            "lon": 55.23247178610628
                        },
                        {
                            "lat": 61.66314564929661,
                            "lon": 55.23241078771172
                        },
                        {
                            "lat": 61.660004853253085,
                            "lon": 55.232349708533086
                        },
                        {
                            "lat": 61.65686406916681,
                            "lon": 55.23228854857105
                        },
                        {
                            "lat": 61.65372329705351,
                            "lon": 55.232227307826264
                        },
                        {
                            "lat": 61.6536160627317,
                            "lon": 55.23402225962826
                        },
                        {
                            "lat": 61.65047516141549,
                            "lon": 55.23396093402463
                        },
                        {
                            "lat": 61.64733427210556,
                            "lon": 55.233899527634236
                        },
                        {
                            "lat": 61.64722674338027,
                            "lon": 55.23569447060517
                        },
                        {
                            "lat": 61.644085724888654,
                            "lon": 55.235632979340835
                        },
                        {
                            "lat": 61.64397804292003,
                            "lon": 55.23742791755139
                        },
                        {
                            "lat": 61.6471192026311,
                            "lon": 55.237489412903976
                        }
                    ]
                ]
            },
            {
                "type": "MultiLineString",
                "value": 125.0,
                "data": [
                    [
                        {
                            "lat": 61.35454137281478,
                            "lon": 55.051692686935546
                        },
                        {
                            "lat": 61.354421474112655,
                            "lon": 55.053487277953195
                        },
                        {
                            "lat": 61.351295441861744,
                            "lon": 55.05341840279571
                        },
                        {
                            "lat": 61.3511753903083,
                            "lon": 55.055212988537484
                        },
                        {
                            "lat": 61.34804923192159,
                            "lon": 55.05514402860781
                        },
                        {
                            "lat": 61.34792902748586,
                            "lon": 55.05693860906757
                        },
                        {
                            "lat": 61.351055325388316,
                            "lon": 55.057007573570324
                        },
                        {
                            "lat": 61.35418163665932,
                            "lon": 55.05707645786299
                        },
                        {
                            "lat": 61.35730796128338,
                            "lon": 55.05714526194482
                        },
                        {
                            "lat": 61.35718816206341,
                            "lon": 55.05893985539984
                        },
                        {
                            "lat": 61.35706834950382,
                            "lon": 55.060734448146704
                        },
                        {
                            "lat": 61.3539417457937,
                            "lon": 55.06066563493867
                        },
                        {
                            "lat": 61.35382178032622,
                            "lon": 55.06246022241369
                        },
                        {
                            "lat": 61.350695050408994,
                            "lon": 55.0623913244152
                        },
                        {
                            "lat": 61.34756833386639,
                            "lon": 55.06232234619073
                        },
                        {
                            "lat": 61.34444163071392,
                            "lon": 55.062253287740994
                        },
                        {
                            "lat": 61.34131494096702,
                            "lon": 55.062184149066766
                        },
                        {
                            "lat": 61.34119440383372,
                            "lon": 55.06397871752371
                        },
                        {
                            "lat": 61.341073853277294,
                            "lon": 55.065773285270424
                        },
                        {
                            "lat": 61.34095328929571,
                            "lon": 55.06756785230691
                        },
                        {
                            "lat": 61.344080397809954,
                            "lon": 55.06763700473835
                        },
                        {
                            "lat": 61.34720751973587,
                            "lon": 55.0677060769294
                        },
                        {
                            "lat": 61.35033465505799,
                            "lon": 55.067775068879264
                        },
                        {
                            "lat": 61.353461803760844,
                            "lon": 55.06784398058724
                        },
                        {
                            "lat": 61.356588965828905,
                            "lon": 55.067912812052555
                        },
                        {
                            "lat": 61.3597161412467,
                            "lon": 55.06798156327444
                        },
                        {
                            "lat": 61.35959640158895,
                            "lon": 55.06977615704044
                        },
                        {
                            "lat": 61.36272372997323,
                            "lon": 55.069844832572876
                        },
                        {
                            "lat": 61.365851071678286,
                            "lon": 55.069913427855134
                        },
                        {
                            "lat": 61.36897842668858,
                            "lon": 55.06998194288643
                        },
                        {
                            "lat": 61.37210579498864,
                            "lon": 55.07005037766602
                        },
                        {
                            "lat": 61.37198660059779,
                            "lon": 55.071844988912865
                        },
                        {
                            "lat": 61.37186739293067,
                            "lon": 55.07363959945351
                        },
                        {
                            "lat": 61.37174817198525,
                            "lon": 55.07543420928792
                        },
                        {
                            "lat": 61.36862038467725,
                            "lon": 55.075365760889504
                        },
                        {
                            "lat": 61.3685010107512,
                            "lon": 55.077160365477255
                        },
                        {
                            "lat": 61.37162893775954,
                            "lon": 55.077228818416096
                        },
                        {
                            "lat": 61.371509690251486,
                            "lon": 55.079023426838035
                        },
                        {
                            "lat": 61.37463777026,
                            "lon": 55.079091804038725
                        },
                        {
                            "lat": 61.374756878050185,
                            "lon": 55.07729719108128
                        },
                        {
                            "lat": 61.37788483160762,
                            "lon": 55.077365483472036
                        },
                        {
                            "lat": 61.38101279841639,
                            "lon": 55.07743369558765
                        },
                        {
                            "lat": 61.38113161352904,
                            "lon": 55.07563907287006
                        },
                        {
                            "lat": 61.38425945386523,
                            "lon": 55.0757072001906
                        },
                        {
                            "lat": 61.387387307419665,
                            "lon": 55.07577524723979
                        },
                        {
                            "lat": 61.39051517417689,
                            "lon": 55.07584321401691
                        },
                        {
                            "lat": 61.390633556971174,
                            "lon": 55.0740485770539
                        },
                        {
                            "lat": 61.39376129721678,
                            "lon": 55.07411645905538
                        },
                        {
                            "lat": 61.39387952714243,
                            "lon": 55.072321816886095
                        },
                        {
                            "lat": 61.397007140872425,
                            "lon": 55.072389614122194
                        },
                        {
                            "lat": 61.396889050632126,
                            "lon": 55.07418426078861
                        },
                        {
                            "lat": 61.40001681720173,
                            "lon": 55.07425198225288
                        },
                        {
                            "lat": 61.399898853510045,
                            "lon": 55.07604663270854
                        },
                        {
                            "lat": 61.39677094723758,
                            "lon": 55.075978906752034
                        },
                        {
                            "lat": 61.39665283068679,
                            "lon": 55.07777355201243
                        },
                        {
                            "lat": 61.39653470097776,
                            "lon": 55.079568196569795
                        },
                        {
                            "lat": 61.39340652841329,
                            "lon": 55.07950038134272
                        },
                        {
                            "lat": 61.39027836902455,
                            "lon": 55.079432485831475
                        },
                        {
                            "lat": 61.390159946662486,
                            "lon": 55.081227120682996
                        },
                        {
                            "lat": 61.3870316607216,
                            "lon": 55.08115914037897
                        },
                        {
                            "lat": 61.38390338798949,
                            "lon": 55.08109107978695
                        },
                        {
                            "lat": 61.38378467291834,
                            "lon": 55.08288570490974
                        },
                        {
                            "lat": 61.38366594462087,
                            "lon": 55.08468032932788
                        },
                        {
                            "lat": 61.38053740558774,
                            "lon": 55.08461217940741
                        },
                        {
                            "lat": 61.38041852427603,
                            "lon": 55.08640679859963
                        },
                        {
                            "lat": 61.38029962971848,
                            "lon": 55.08820141708674
                        },
                        {
                            "lat": 61.38018072191309,
                            "lon": 55.08999603486876
                        },
                        {
                            "lat": 61.38006180085784,
                            "lon": 55.091790651945594
                        },
                        {
                            "lat": 61.379942866550735,
                            "lon": 55.09358526831729
                        },
                        {
                            "lat": 61.37681364168652,
                            "lon": 55.093517015463085
                        },
                        {
                            "lat": 61.37669455426397,
                            "lon": 55.09531162660107
                        },
                        {
                            "lat": 61.37982391898975,
                            "lon": 55.09537988398381
                        },
                        {
                            "lat": 61.37970495817287,
                            "lon": 55.09717449894514
                        },
                        {
                            "lat": 61.37657545356999,
                            "lon": 55.09710623703347
                        },
                        {
                            "lat": 61.376456339602576,
                            "lon": 55.09890084676026
                        },
                        {
                            "lat": 61.37633721235973,
                            "lon": 55.10069545578145
                        },
                        {
                            "lat": 61.379466996763384,
                            "lon": 55.1007637267522
                        },
                        {
                            "lat": 61.382596794428956,
                            "lon": 55.100831917377995
                        },
                        {
                            "lat": 61.38271564185349,
                            "lon": 55.099037299302644
                        },
                        {
                            "lat": 61.38584531285327,
                            "lon": 55.09910540506362
                        },
                        {
                            "lat": 61.38596400713675,
                            "lon": 55.09731078176476
                        },
                        {
                            "lat": 61.38909355146671,
                            "lon": 55.09737880267121
                        },
                        {
                            "lat": 61.39222310900795,
                            "lon": 55.09744674324111
                        },
                        {
                            "lat": 61.39234151029637,
                            "lon": 55.09565211021718
                        },
                        {
                            "lat": 61.39547094114624,
                            "lon": 55.09571996594744
                        },
                        {
                            "lat": 61.398600385174326,
                            "lon": 55.09578774134497
                        },
                        {
                            "lat": 61.39848226366218,
                            "lon": 55.0975823833683
                        },
                        {
                            "lat": 61.39535267974497,
                            "lon": 55.097514603473705
                        },
                        {
                            "lat": 61.395234405163464,
                            "lon": 55.09930924029682
                        },
                        {
                            "lat": 61.39836412898535,
                            "lon": 55.099377024688856
                        },
                        {
                            "lat": 61.40149386597392,
                            "lon": 55.099444728736806
                        },
                        {
                            "lat": 61.40462361611361,
                            "lon": 55.099512352439945
                        },
                        {
                            "lat": 61.40775337938892,
                            "lon": 55.099579895797525
                        },
                        {
                            "lat": 61.41088315578427,
                            "lon": 55.09964735880884
                        },
                        {
                            "lat": 61.41076556764156,
                            "lon": 55.10144201736482
                        },
                        {
                            "lat": 61.41389549707162,
                            "lon": 55.10150940450038
                        },
                        {
                            "lat": 61.413778035768125,
                            "lon": 55.103304066826844
                        },
                        {
                            "lat": 61.41366056137167,
                            "lon": 55.10509872845247
                        },
                        {
                            "lat": 61.41679078380364,
                            "lon": 55.105166044168044
                        },
                        {
                            "lat": 61.416673436291056,
                            "lon": 55.10696070956001
                        },
                        {
                            "lat": 61.41354307388029,
                            "lon": 55.106893389377255
                        },
                        {
                            "lat": 61.410412724566406,
                            "lon": 55.10682598882552
                        },
                        {
                            "lat": 61.41029508398573,
                            "lon": 55.10862064457657
                        },
                        {
                            "lat": 61.407164607793966,
                            "lon": 55.108553159178356
                        },
                        {
                            "lat": 61.404034144732215,
                            "lon": 55.10848559340726
                        },
                        {
                            "lat": 61.40090369481603,
                            "lon": 55.10841794726404
                        },
                        {
                            "lat": 61.40078562110907,
                            "lon": 55.11021258886214
                        },
                        {
                            "lat": 61.39765504435407,
                            "lon": 55.110144857852966
                        },
                        {
                            "lat": 61.39452448077776,
                            "lon": 55.11007704646781
                        },
                        {
                            "lat": 61.39440611387868,
                            "lon": 55.11187167836824
                        },
                        {
                            "lat": 61.39127542348509,
                            "lon": 55.11180378210225
                        },
                        {
                            "lat": 61.39115690336272,
                            "lon": 55.113598408793386
                        },
                        {
                            "lat": 61.39428773378336,
                            "lon": 55.113666309565346
                        },
                        {
                            "lat": 61.39741857740221,
                            "lon": 55.11373412995144
                        },
                        {
                            "lat": 61.40054943420377,
                            "lon": 55.11380186995088
                        },
                        {
                            "lat": 61.40368030417243,
                            "lon": 55.113869529563
                        },
                        {
                            "lat": 61.40681118729267,
                            "lon": 55.113937108786985
                        },
                        {
                            "lat": 61.409942083548906,
                            "lon": 55.114004607622164
                        },
                        {
                            "lat": 61.4098243904984,
                            "lon": 55.115799260568096
                        },
                        {
                            "lat": 61.41295543992967,
                            "lon": 55.11586668348818
                        },
                        {
                            "lat": 61.41283787382688,
                            "lon": 55.11766134020764
                        },
                        {
                            "lat": 61.4159690764372,
                            "lon": 55.11772868720163
                        },
                        {
                            "lat": 61.41585163731327,
                            "lon": 55.11952334769013
                        },
                        {
                            "lat": 61.41272029461524,
                            "lon": 55.11945599622618
                        },
                        {
                            "lat": 61.41260270229275,
                            "lon": 55.121250651543775
                        },
                        {
                            "lat": 61.40947123260428,
                            "lon": 55.12118321519792
                        },
                        {
                            "lat": 61.406339776044234,
                            "lon": 55.1211156984412
                        },
                        {
                            "lat": 61.403208332628154,
                            "lon": 55.121048101274376
                        },
                        {
                            "lat": 61.4030903068482,
                            "lon": 55.12284274244686
                        },
                        {
                            "lat": 61.40297226790669,
                            "lon": 55.124637382917165
                        },
                        {
                            "lat": 61.40610399156407,
                            "lon": 55.12470498905781
                        },
                        {
                            "lat": 61.40923572836944,
                            "lon": 55.12477251477769
                        },
                        {
                            "lat": 61.412367478307196,
                            "lon": 55.12483996007607
                        },
                        {
                            "lat": 61.41224984664018,
                            "lon": 55.126634613290754
                        },
                        {
                            "lat": 61.41538174984479,
                            "lon": 55.12670198263845
                        },
                        {
                            "lat": 61.41526424522451,
                            "lon": 55.12849663962406
                        },
                        {
                            "lat": 61.41839630169995,
                            "lon": 55.128563933010106
                        },
                        {
                            "lat": 61.421528371265026,
                            "lon": 55.12863114596178
                        },
                        {
                            "lat": 61.42164559554815,
                            "lon": 55.12683648004827
                        },
                        {
                            "lat": 61.421762806759176,
                            "lon": 55.12504181343496
                        },
                        {
                            "lat": 61.4218800049001,
                            "lon": 55.12324714612183
                        },
                        {
                            "lat": 61.41874835579699,
                            "lon": 55.12317994655281
                        },
                        {
                            "lat": 61.41863101751766,
                            "lon": 55.12497460940545
                        },
                        {
                            "lat": 61.41549924136179,
                            "lon": 55.12490732495224
                        },
                        {
                            "lat": 61.41561671977755,
                            "lon": 55.12311266656544
                        },
                        {
                            "lat": 61.41573418509406,
                            "lon": 55.12131800747806
                        },
                        {
                            "lat": 61.41886568099263,
                            "lon": 55.12138528300003
                        },
                        {
                            "lat": 61.41898299310656,
                            "lon": 55.119590618747075
                        },
                        {
                            "lat": 61.419100292140776,
                            "lon": 55.11779595379396
                        },
                        {
                            "lat": 61.41921757809728,
                            "lon": 55.11600128814073
                        },
                        {
                            "lat": 61.419334850978025,
                            "lon": 55.114206621787375
                        },
                        {
                            "lat": 61.419452110784995,
                            "lon": 55.1124119547339
                        },
                        {
                            "lat": 61.41632131525713,
                            "lon": 55.11234470153304
                        },
                        {
                            "lat": 61.413190532816614,
                            "lon": 55.11227736794647
                        },
                        {
                            "lat": 61.41005976347896,
                            "lon": 55.112209953974926
                        },
                        {
                            "lat": 61.41017743029059,
                            "lon": 55.110415299626396
                        },
                        {
                            "lat": 61.41330805960474,
                            "lon": 55.11048270912427
                        },
                        {
                            "lat": 61.416438702019796,
                            "lon": 55.1105500382425
                        },
                        {
                            "lat": 61.41956935752021,
                            "lon": 55.11061728698033
                        },
                        {
                            "lat": 61.41968659118562,
                            "lon": 55.108822618526695
                        },
                        {
                            "lat": 61.41980381178322,
                            "lon": 55.10702794937296
                        },
                        {
                            "lat": 61.419921019315005,
                            "lon": 55.10523327951919
                        },
                        {
                            "lat": 61.42003821378295,
                            "lon": 55.103438608965405
                        },
                        {
                            "lat": 61.41690811823684,
                            "lon": 55.103371378075664
                        },
                        {
                            "lat": 61.41702543959262,
                            "lon": 55.10157671128287
                        },
                        {
                            "lat": 61.42015539518904,
                            "lon": 55.10164393771156
                        },
                        {
                            "lat": 61.42027256353528,
                            "lon": 55.0998492657577
                        },
                        {
                            "lat": 61.42340239225543,
                            "lon": 55.099916407376554
                        },
                        {
                            "lat": 61.42351940762316,
                            "lon": 55.09812173026778
                        },
                        {
                            "lat": 61.42363640995053,
                            "lon": 55.09632705245943
                        },
                        {
                            "lat": 61.42050686105601,
                            "lon": 55.096259919750025
                        },
                        {
                            "lat": 61.42062399023451,
                            "lon": 55.09446524569623
                        },
                        {
                            "lat": 61.41749459428145,
                            "lon": 55.09439803710769
                        },
                        {
                            "lat": 61.41436521139586,
                            "lon": 55.094330748186614
                        },
                        {
                            "lat": 61.41123584159329,
                            "lon": 55.09426337893373
                        },
                        {
                            "lat": 61.41135337732985,
                            "lon": 55.09246871757305
                        },
                        {
                            "lat": 61.41448260726441,
                            "lon": 55.09253608235625
                        },
                        {
                            "lat": 61.41459999005193,
                            "lon": 55.090741415825136
                        },
                        {
                            "lat": 61.41471735976041,
                            "lon": 55.0889467485933
                        },
                        {
                            "lat": 61.41483471639183,
                            "lon": 55.08715208066075
                        },
                        {
                            "lat": 61.41495205994816,
                            "lon": 55.085357412027506
                        },
                        {
                            "lat": 61.41182338933041,
                            "lon": 55.08529006511898
                        },
                        {
                            "lat": 61.40869473180127,
                            "lon": 55.08522263790598
                        },
                        {
                            "lat": 61.40556608737626,
                            "lon": 55.0851551303892
                        },
                        {
                            "lat": 61.405683837224714,
                            "lon": 55.083360474474226
                        },
                        {
                            "lat": 61.40881234186329,
                            "lon": 55.083427977512706
                        },
                        {
                            "lat": 61.41194085960403,
                            "lon": 55.08349540025272
                        },
                        {
                            "lat": 61.41205831679104,
                            "lon": 55.081700734685406
                        },
                        {
                            "lat": 61.41217576089339,
                            "lon": 55.079906068417
                        },
                        {
                            "lat": 61.412293191913086,
                            "lon": 55.07811140144758
                        },
                        {
                            "lat": 61.412410609852095,
                            "lon": 55.076316733777105
                        },
                        {
                            "lat": 61.40928265110978,
                            "lon": 55.07624932892505
                        },
                        {
                            "lat": 61.409400195680924,
                            "lon": 55.07445466502457
                        },
                        {
                            "lat": 61.40951772715986,
                            "lon": 55.07266000042266
                        },
                        {
                            "lat": 61.40963524554856,
                            "lon": 55.07086533511939
                        },
                        {
                            "lat": 61.40650771898039,
                            "lon": 55.070797863417845
                        },
                        {
                            "lat": 61.40662536394317,
                            "lon": 55.06900320188829
                        },
                        {
                            "lat": 61.40674299580418,
                            "lon": 55.06720853965698
                        },
                        {
                            "lat": 61.40686061456541,
                            "lon": 55.06541387672394
                        },
                        {
                            "lat": 61.40373352003551,
                            "lon": 55.06534633820189
                        },
                        {
                            "lat": 61.40060643861887,
                            "lon": 55.06527871943593
                        },
                        {
                            "lat": 61.400724323507994,
                            "lon": 55.06348406476487
                        },
                        {
                            "lat": 61.39759739483,
                            "lon": 55.063416370245584
                        },
                        {
                            "lat": 61.39747937033103,
                            "lon": 55.06521102042681
                        },
                        {
                            "lat": 61.394352315187454,
                            "lon": 55.06514324117526
                        },
                        {
                            "lat": 61.3944704792943,
                            "lon": 55.06334859548917
                        },
                        {
                            "lat": 61.39134357691635,
                            "lon": 55.06328074049638
                        },
                        {
                            "lat": 61.39122527320365,
                            "lon": 55.06507538168202
                        },
                        {
                            "lat": 61.3880982443951,
                            "lon": 55.06500744194783
                        },
                        {
                            "lat": 61.38821668771169,
                            "lon": 55.063212805267945
                        },
                        {
                            "lat": 61.38833511783949,
                            "lon": 55.061418167883936
                        },
                        {
                            "lat": 61.391461867455796,
                            "lon": 55.061486098607034
                        },
                        {
                            "lat": 61.394588630243355,
                            "lon": 55.06155394909979
                        },
                        {
                            "lat": 61.3977154061867,
                            "lon": 55.061621719361455
                        },
                        {
                            "lat": 61.397833404403094,
                            "lon": 55.05982706777441
                        },
                        {
                            "lat": 61.397951389481186,
                            "lon": 55.0580324154845
                        },
                        {
                            "lat": 61.39482489267612,
                            "lon": 55.05796465421109
                        },
                        {
                            "lat": 61.39494300416382,
                            "lon": 55.05617000571182
                        },
                        {
                            "lat": 61.39181666005444,
                            "lon": 55.05610216871678
                        },
                        {
                            "lat": 61.39193489792078,
                            "lon": 55.05430752401269
                        },
                        {
                            "lat": 61.388808706503106,
                            "lon": 55.05423961130698
                        },
                        {
                            "lat": 61.38568252826421,
                            "lon": 55.054171618392864
                        },
                        {
                            "lat": 61.385801031987164,
                            "lon": 55.05237698199712
                        },
                        {
                            "lat": 61.38591952251787,
                            "lon": 55.05058234489692
                        },
                        {
                            "lat": 61.382793636471646,
                            "lon": 55.050514280802645
                        },
                        {
                            "lat": 61.38267500644945,
                            "lon": 55.052308913389304
                        },
                        {
                            "lat": 61.37954899411948,
                            "lon": 55.05224076457989
                        },
                        {
                            "lat": 61.37642299501272,
                            "lon": 55.05217253556959
                        },
                        {
                            "lat": 61.376541904011845,
                            "lon": 55.050377912025944
                        },
                        {
                            "lat": 61.37341605762921,
                            "lon": 55.05030960734501
                        },
                        {
                            "lat": 61.373297009144615,
                            "lon": 55.0521042263592
                        },
                        {
                            "lat": 61.37017103653066,
                            "lon": 55.052035836949386
                        },
                        {
                            "lat": 61.3702902244987,
                            "lon": 55.050241222469936
                        },
                        {
                            "lat": 61.36716440463582,
                            "lon": 55.050172757401555
                        },
                        {
                            "lat": 61.364038598056034,
                            "lon": 55.05010421214055
                        },
                        {
                            "lat": 61.360912804774806,
                            "lon": 55.05003558668771
                        },
                        {
                            "lat": 61.35778702480761,
                            "lon": 55.04996688104375
                        },
                        {
                            "lat": 61.354661258169884,
                            "lon": 55.049898095209436
                        },
                        {
                            "lat": 61.35153550487717,
                            "lon": 55.04982922918548
                        },
                        {
                            "lat": 61.3514154800507,
                            "lon": 55.051623816345035
                        },
                        {
                            "lat": 61.35454137281478,
                            "lon": 55.051692686935546
                        }
                    ],
                    [
                        {
                            "lat": 61.40391621102721,
                            "lon": 55.110280239494585
                        },
                        {
                            "lat": 61.40704681409288,
                            "lon": 55.110347809749584
                        },
                        {
                            "lat": 61.406929007259784,
                            "lon": 55.112142459619136
                        },
                        {
                            "lat": 61.4037982641746,
                            "lon": 55.11207488487984
                        },
                        {
                            "lat": 61.40066753423899,
                            "lon": 55.11200722975776
                        },
                        {
                            "lat": 61.40078562110907,
                            "lon": 55.11021258886214
                        },
                        {
                            "lat": 61.40391621102721,
                            "lon": 55.110280239494585
                        }
                    ],
                    [
                        {
                            "lat": 61.399190795334746,
                            "lon": 55.08681452068701
                        },
                        {
                            "lat": 61.39930883790074,
                            "lon": 55.085019874447255
                        },
                        {
                            "lat": 61.402437456070906,
                            "lon": 55.08508754256939
                        },
                        {
                            "lat": 61.40231955330286,
                            "lon": 55.08688219329845
                        },
                        {
                            "lat": 61.399190795334746,
                            "lon": 55.08681452068701
                        }
                    ],
                    [
                        {
                            "lat": 61.337330117036,
                            "lon": 55.12140453306718
                        },
                        {
                            "lat": 61.340461420902905,
                            "lon": 55.12147382327363
                        },
                        {
                            "lat": 61.34359273824271,
                            "lon": 55.12154303308029
                        },
                        {
                            "lat": 61.34672406903985,
                            "lon": 55.12161216248639
                        },
                        {
                            "lat": 61.34985541327877,
                            "lon": 55.121681211491165
                        },
                        {
                            "lat": 61.352986770943886,
                            "lon": 55.12175018009388
                        },
                        {
                            "lat": 61.35286649022075,
                            "lon": 55.12354474804663
                        },
                        {
                            "lat": 61.352746196084915,
                            "lon": 55.12533931529076
                        },
                        {
                            "lat": 61.34961455821596,
                            "lon": 55.125270337532484
                        },
                        {
                            "lat": 61.349494110540135,
                            "lon": 55.1270648994895
                        },
                        {
                            "lat": 61.352625888534384,
                            "lon": 55.12713388182616
                        },
                        {
                            "lat": 61.35575767994537,
                            "lon": 55.12720278374405
                        },
                        {
                            "lat": 61.35888948475755,
                            "lon": 55.12727160524242
                        },
                        {
                            "lat": 61.35876944407803,
                            "lon": 55.129066180210785
                        },
                        {
                            "lat": 61.36190140242278,
                            "lon": 55.129134925851695
                        },
                        {
                            "lat": 61.36503337413958,
                            "lon": 55.12920359106627
                        },
                        {
                            "lat": 61.364913600399525,
                            "lon": 55.130998174447896
                        },
                        {
                            "lat": 61.36804572563942,
                            "lon": 55.13106676378796
                        },
                        {
                            "lat": 61.37117786422226,
                            "lon": 55.13113527269489
                        },
                        {
                            "lat": 61.371297357627114,
                            "lon": 55.12934068021338
                        },
                        {
                            "lat": 61.36816535921288,
                            "lon": 55.12927217585376
                        },
                        {
                            "lat": 61.36828497944545,
                            "lon": 55.12747758721284
                        },
                        {
                            "lat": 61.365153134523155,
                            "lon": 55.127409006977565
                        },
                        {
                            "lat": 61.36202130295533,
                            "lon": 55.12734034632052
                        },
                        {
                            "lat": 61.36214119011784,
                            "lon": 55.12554576608182
                        },
                        {
                            "lat": 61.35900951205143,
                            "lon": 55.125477029566184
                        },
                        {
                            "lat": 61.359129525961755,
                            "lon": 55.12368245318207
                        },
                        {
                            "lat": 61.35599800139269,
                            "lon": 55.123613640818796
                        },
                        {
                            "lat": 61.356118142019646,
                            "lon": 55.12181906829377
                        },
                        {
                            "lat": 61.356238269251534,
                            "lon": 55.1200244950605
                        },
                        {
                            "lat": 61.35635838309037,
                            "lon": 55.11822992111897
                        },
                        {
                            "lat": 61.353227292160256,
                            "lon": 55.11816104206237
                        },
                        {
                            "lat": 61.35334753265757,
                            "lon": 55.11636647198367
                        },
                        {
                            "lat": 61.350216595181465,
                            "lon": 55.11629751711122
                        },
                        {
                            "lat": 61.34708567112547,
                            "lon": 55.11622848185268
                        },
                        {
                            "lat": 61.34395476050511,
                            "lon": 55.116159366208784
                        },
                        {
                            "lat": 61.340823863335984,
                            "lon": 55.116090170180286
                        },
                        {
                            "lat": 61.3376929796336,
                            "lon": 55.11602089376794
                        },
                        {
                            "lat": 61.33757203891945,
                            "lon": 55.11781544091169
                        },
                        {
                            "lat": 61.33745108472093,
                            "lon": 55.11960998734478
                        },
                        {
                            "lat": 61.337330117036,
                            "lon": 55.12140453306718
                        }
                    ],
                    [
                        {
                            "lat": 61.374310016132455,
                            "lon": 55.13120370116789
                        },
                        {
                            "lat": 61.37117786422226,
                            "lon": 55.13113527269489
                        },
                        {
                            "lat": 61.37105835749003,
                            "lon": 55.1329298644701
                        },
                        {
                            "lat": 61.37419064958644,
                            "lon": 55.132998297485464
                        },
                        {
                            "lat": 61.374310016132455,
                            "lon": 55.13120370116789
                        }
                    ],
                    [
                        {
                            "lat": 61.37407126972663,
                            "lon": 55.13479289309715
                        },
                        {
                            "lat": 61.37395187655095,
                            "lon": 55.13658748800291
                        },
                        {
                            "lat": 61.37708446238442,
                            "lon": 55.13665584965357
                        },
                        {
                            "lat": 61.377203715340684,
                            "lon": 55.13486125020996
                        },
                        {
                            "lat": 61.37732295499668,
                            "lon": 55.133066650060826
                        },
                        {
                            "lat": 61.37419064958644,
                            "lon": 55.132998297485464
                        },
                        {
                            "lat": 61.37407126972663,
                            "lon": 55.13479289309715
                        }
                    ],
                    [
                        {
                            "lat": 61.38009793549865,
                            "lon": 55.1385187341239
                        },
                        {
                            "lat": 61.38021706152015,
                            "lon": 55.136724130852855
                        },
                        {
                            "lat": 61.37708446238442,
                            "lon": 55.13665584965357
                        },
                        {
                            "lat": 61.376965196125894,
                            "lon": 55.13845044839167
                        },
                        {
                            "lat": 61.37684591656308,
                            "lon": 55.140245046424255
                        },
                        {
                            "lat": 61.37997879618843,
                            "lon": 55.140313336689815
                        },
                        {
                            "lat": 61.38009793549865,
                            "lon": 55.1385187341239
                        }
                    ],
                    [
                        {
                            "lat": 61.40864673793029,
                            "lon": 55.13374575145255
                        },
                        {
                            "lat": 61.411779188763916,
                            "lon": 55.13381321913927
                        },
                        {
                            "lat": 61.41189687291819,
                            "lon": 55.1320185687287
                        },
                        {
                            "lat": 61.4150291966663,
                            "lon": 55.132085951493416
                        },
                        {
                            "lat": 61.41816153352361,
                            "lon": 55.132153253813875
                        },
                        {
                            "lat": 61.41804412979598,
                            "lon": 55.1339479131654
                        },
                        {
                            "lat": 61.42117661996325,
                            "lon": 55.13401513950336
                        },
                        {
                            "lat": 61.421293883474554,
                            "lon": 55.132220475689365
                        },
                        {
                            "lat": 61.42141113390782,
                            "lon": 55.130425811175485
                        },
                        {
                            "lat": 61.4182789241576,
                            "lon": 55.13035859376209
                        },
                        {
                            "lat": 61.41514672749902,
                            "lon": 55.13029129590904
                        },
                        {
                            "lat": 61.41526424522451,
                            "lon": 55.12849663962406
                        },
                        {
                            "lat": 61.41213220185434,
                            "lon": 55.128429265804414
                        },
                        {
                            "lat": 61.40900017160497,
                            "lon": 55.1283618115519
                        },
                        {
                            "lat": 61.40888237351913,
                            "lon": 55.13015645888689
                        },
                        {
                            "lat": 61.40575021622898,
                            "lon": 55.13008891971924
                        },
                        {
                            "lat": 61.405632264812006,
                            "lon": 55.13188356186942
                        },
                        {
                            "lat": 61.40249998048507,
                            "lon": 55.13181593777633
                        },
                        {
                            "lat": 61.40238187570577,
                            "lon": 55.13361057473554
                        },
                        {
                            "lat": 61.40551430023904,
                            "lon": 55.133678203317736
                        },
                        {
                            "lat": 61.40539632250812,
                            "lon": 55.135472844064196
                        },
                        {
                            "lat": 61.40852890042329,
                            "lon": 55.13554039668321
                        },
                        {
                            "lat": 61.40864673793029,
                            "lon": 55.13374575145255
                        }
                    ],
                    [
                        {
                            "lat": 61.413970829017664,
                            "lon": 55.14823782022203
                        },
                        {
                            "lat": 61.41710442836524,
                            "lon": 55.1483051627672
                        },
                        {
                            "lat": 61.42023804082437,
                            "lon": 55.14837242481945
                        },
                        {
                            "lat": 61.42035540903166,
                            "lon": 55.146577766604686
                        },
                        {
                            "lat": 61.420472764144996,
                            "lon": 55.14478310768995
                        },
                        {
                            "lat": 61.423606109029535,
                            "lon": 55.144850280328235
                        },
                        {
                            "lat": 61.423723310739035,
                            "lon": 55.14305561625401
                        },
                        {
                            "lat": 61.42059010616636,
                            "lon": 55.14298844807531
                        },
                        {
                            "lat": 61.41745691468366,
                            "lon": 55.142921199418915
                        },
                        {
                            "lat": 61.41757438390933,
                            "lon": 55.1411265435688
                        },
                        {
                            "lat": 61.420707435097746,
                            "lon": 55.14119378776071
                        },
                        {
                            "lat": 61.420824750941144,
                            "lon": 55.13939912674621
                        },
                        {
                            "lat": 61.41769184003139,
                            "lon": 55.139331887018386
                        },
                        {
                            "lat": 61.417809283051874,
                            "lon": 55.13753722976768
                        },
                        {
                            "lat": 61.41467652550481,
                            "lon": 55.13746991404257
                        },
                        {
                            "lat": 61.411543781072965,
                            "lon": 55.13740251785723
                        },
                        {
                            "lat": 61.41142605753229,
                            "lon": 55.13919716616461
                        },
                        {
                            "lat": 61.41130832085885,
                            "lon": 55.14099181377086
                        },
                        {
                            "lat": 61.41444134582451,
                            "lon": 55.14105921890527
                        },
                        {
                            "lat": 61.41432373630656,
                            "lon": 55.14285387028557
                        },
                        {
                            "lat": 61.41420611366744,
                            "lon": 55.14464852096513
                        },
                        {
                            "lat": 61.41408847790514,
                            "lon": 55.14644317094397
                        },
                        {
                            "lat": 61.413970829017664,
                            "lon": 55.14823782022203
                        }
                    ],
                    [
                        {
                            "lat": 61.396051857716714,
                            "lon": 55.18199767660325
                        },
                        {
                            "lat": 61.39918806049263,
                            "lon": 55.182065507134034
                        },
                        {
                            "lat": 61.402324276496294,
                            "lon": 55.18213325707424
                        },
                        {
                            "lat": 61.40244259703758,
                            "lon": 55.18033863458101
                        },
                        {
                            "lat": 61.399306521661906,
                            "lon": 55.180270889140786
                        },
                        {
                            "lat": 61.39942496960544,
                            "lon": 55.17847627044488
                        },
                        {
                            "lat": 61.39628904806583,
                            "lon": 55.1784084489243
                        },
                        {
                            "lat": 61.396170459511964,
                            "lon": 55.18020306311529
                        },
                        {
                            "lat": 61.396051857716714,
                            "lon": 55.18199767660325
                        }
                    ],
                    [
                        {
                            "lat": 61.408596748124275,
                            "lon": 55.182268515179906
                        },
                        {
                            "lat": 61.408714787403575,
                            "lon": 55.18047388370273
                        },
                        {
                            "lat": 61.40557868562336,
                            "lon": 55.180406299435234
                        },
                        {
                            "lat": 61.40546050571204,
                            "lon": 55.18220092642312
                        },
                        {
                            "lat": 61.408596748124275,
                            "lon": 55.182268515179906
                        }
                    ],
                    [
                        {
                            "lat": 61.41161509190688,
                            "lon": 55.18413065860401
                        },
                        {
                            "lat": 61.411733003717295,
                            "lon": 55.182336023343886
                        },
                        {
                            "lat": 61.408596748124275,
                            "lon": 55.182268515179906
                        },
                        {
                            "lat": 61.408478695664186,
                            "lon": 55.18406314595566
                        },
                        {
                            "lat": 61.41161509190688,
                            "lon": 55.18413065860401
                        }
                    ],
                    [
                        {
                            "lat": 61.467451456433494,
                            "lon": 55.097258474433225
                        },
                        {
                            "lat": 61.46432172641578,
                            "lon": 55.09719246654014
                        },
                        {
                            "lat": 61.4642065430333,
                            "lon": 55.09898720177347
                        },
                        {
                            "lat": 61.46733641298357,
                            "lon": 55.099053214046656
                        },
                        {
                            "lat": 61.467451456433494,
                            "lon": 55.097258474433225
                        }
                    ],
                    [
                        {
                            "lat": 61.46038447604381,
                            "lon": 55.10968947964155
                        },
                        {
                            "lat": 61.46026906271283,
                            "lon": 55.11148420562525
                        },
                        {
                            "lat": 61.46339989977439,
                            "lon": 55.11155032896127
                        },
                        {
                            "lat": 61.46328461363093,
                            "lon": 55.11334505863857
                        },
                        {
                            "lat": 61.46641560360142,
                            "lon": 55.11341110596652
                        },
                        {
                            "lat": 61.466530749687905,
                            "lon": 55.11161637190601
                        },
                        {
                            "lat": 61.46966161243782,
                            "lon": 55.111682334458756
                        },
                        {
                            "lat": 61.46954660641025,
                            "lon": 55.11347707289717
                        },
                        {
                            "lat": 61.46943158755993,
                            "lon": 55.115271810641815
                        },
                        {
                            "lat": 61.47256274326798,
                            "lon": 55.11533770154742
                        },
                        {
                            "lat": 61.47244785168494,
                            "lon": 55.117132442971624
                        },
                        {
                            "lat": 61.46931655588489,
                            "lon": 55.11706654769266
                        },
                        {
                            "lat": 61.46618527291149,
                            "lon": 55.11700057200505
                        },
                        {
                            "lat": 61.46607008830414,
                            "lon": 55.11879530398299
                        },
                        {
                            "lat": 61.4659548908526,
                            "lon": 55.12059003526676
                        },
                        {
                            "lat": 61.46583968055493,
                            "lon": 55.12238476585631
                        },
                        {
                            "lat": 61.462707990066,
                            "lon": 55.12231869660638
                        },
                        {
                            "lat": 61.45957631244072,
                            "lon": 55.122252546933254
                        },
                        {
                            "lat": 61.45644464769469,
                            "lon": 55.12218631683764
                        },
                        {
                            "lat": 61.45632900410356,
                            "lon": 55.123981033560284
                        },
                        {
                            "lat": 61.45621334761565,
                            "lon": 55.12577574958747
                        },
                        {
                            "lat": 61.45609767822899,
                            "lon": 55.12757046491923
                        },
                        {
                            "lat": 61.45922976346126,
                            "lon": 55.127636708204676
                        },
                        {
                            "lat": 61.45911422136713,
                            "lon": 55.12943142723839
                        },
                        {
                            "lat": 61.46224645967971,
                            "lon": 55.12949759447744
                        },
                        {
                            "lat": 61.46213104490931,
                            "lon": 55.13129231720854
                        },
                        {
                            "lat": 61.46201561726547,
                            "lon": 55.13308703924494
                        },
                        {
                            "lat": 61.4651481488906,
                            "lon": 55.13315313481418
                        },
                        {
                            "lat": 61.46503284861529,
                            "lon": 55.13494786054382
                        },
                        {
                            "lat": 61.468165533346095,
                            "lon": 55.13501388003976
                        },
                        {
                            "lat": 61.4680503604705,
                            "lon": 55.13680860945804
                        },
                        {
                            "lat": 61.4711831983109,
                            "lon": 55.13687455286975
                        },
                        {
                            "lat": 61.47431604898384,
                            "lon": 55.13694041581348
                        },
                        {
                            "lat": 61.47420114382008,
                            "lon": 55.138735153288614
                        },
                        {
                            "lat": 61.47733414759286,
                            "lon": 55.138800940131055
                        },
                        {
                            "lat": 61.47721936990877,
                            "lon": 55.14059568128079
                        },
                        {
                            "lat": 61.48035252678527,
                            "lon": 55.14066139201098
                        },
                        {
                            "lat": 61.480237876612,
                            "lon": 55.14245613683078
                        },
                        {
                            "lat": 61.48337118659616,
                            "lon": 55.14252177143771
                        },
                        {
                            "lat": 61.48650450935624,
                            "lon": 55.14258732555783
                        },
                        {
                            "lat": 61.48661887889155,
                            "lon": 55.140792572028346
                        },
                        {
                            "lat": 61.486733235668424,
                            "lon": 55.1389978178072
                        },
                        {
                            "lat": 61.48986629056069,
                            "lon": 55.1390632827465
                        },
                        {
                            "lat": 61.489980494290414,
                            "lon": 55.13726852348767
                        },
                        {
                            "lat": 61.490094685281214,
                            "lon": 55.13547376353764
                        },
                        {
                            "lat": 61.48696191095471,
                            "lon": 55.13540830729015
                        },
                        {
                            "lat": 61.487076229467995,
                            "lon": 55.13361355099422
                        },
                        {
                            "lat": 61.49020886353499,
                            "lon": 55.133679002896415
                        },
                        {
                            "lat": 61.49334151033705,
                            "lon": 55.13374437433703
                        },
                        {
                            "lat": 61.493455535609925,
                            "lon": 55.13194960866499
                        },
                        {
                            "lat": 61.49032302905371,
                            "lon": 55.13188424156399
                        },
                        {
                            "lat": 61.4871905352306,
                            "lon": 55.1318187940067
                        },
                        {
                            "lat": 61.48405805415623,
                            "lon": 55.13175326599387
                        },
                        {
                            "lat": 61.48417248739649,
                            "lon": 55.12995851266464
                        },
                        {
                            "lat": 61.48104015931084,
                            "lon": 55.12989290855215
                        },
                        {
                            "lat": 61.481154719997555,
                            "lon": 55.12809815888579
                        },
                        {
                            "lat": 61.48126926790827,
                            "lon": 55.12630340852711
                        },
                        {
                            "lat": 61.4813838030449,
                            "lon": 55.12450865747611
                        },
                        {
                            "lat": 61.484515710550774,
                            "lon": 55.1245742485254
                        },
                        {
                            "lat": 61.48463009275317,
                            "lon": 55.12277949242849
                        },
                        {
                            "lat": 61.48149832540937,
                            "lon": 55.12271390573281
                        },
                        {
                            "lat": 61.48161283500367,
                            "lon": 55.120919153297244
                        },
                        {
                            "lat": 61.48172733182969,
                            "lon": 55.11912440016942
                        },
                        {
                            "lat": 61.48184181588939,
                            "lon": 55.11732964634931
                        },
                        {
                            "lat": 61.48497316284042,
                            "lon": 55.11739521998644
                        },
                        {
                            "lat": 61.485087494035945,
                            "lon": 55.11560046112204
                        },
                        {
                            "lat": 61.48520181248461,
                            "lon": 55.11380570156579
                        },
                        {
                            "lat": 61.48531611818832,
                            "lon": 55.1120109413177
                        },
                        {
                            "lat": 61.4884470576297,
                            "lon": 55.112076421503524
                        },
                        {
                            "lat": 61.48833289199655,
                            "lon": 55.11387118609757
                        },
                        {
                            "lat": 61.491463984237875,
                            "lon": 55.11393659022704
                        },
                        {
                            "lat": 61.491578009798495,
                            "lon": 55.11214182129235
                        },
                        {
                            "lat": 61.49169202264727,
                            "lon": 55.110347051666615
                        },
                        {
                            "lat": 61.49482284746905,
                            "lon": 55.11041236672281
                        },
                        {
                            "lat": 61.4979536849852,
                            "lon": 55.11047760138591
                        },
                        {
                            "lat": 61.50108453518017,
                            "lon": 55.11054275565522
                        },
                        {
                            "lat": 61.50421539803837,
                            "lon": 55.11060782953004
                        },
                        {
                            "lat": 61.50734627354426,
                            "lon": 55.11067282300963
                        },
                        {
                            "lat": 61.50723296100914,
                            "lon": 55.11246761425683
                        },
                        {
                            "lat": 61.50711963583998,
                            "lon": 55.11426240481493
                        },
                        {
                            "lat": 61.50700629803488,
                            "lon": 55.11605719468392
                        },
                        {
                            "lat": 61.503875002282555,
                            "lon": 55.11599218826221
                        },
                        {
                            "lat": 61.50074371918368,
                            "lon": 55.11592710142933
                        },
                        {
                            "lat": 61.50063008851605,
                            "lon": 55.11772188197422
                        },
                        {
                            "lat": 61.5037615117263,
                            "lon": 55.11778697312724
                        },
                        {
                            "lat": 61.50689294759189,
                            "lon": 55.11785198386375
                        },
                        {
                            "lat": 61.506779584509125,
                            "lon": 55.11964677235443
                        },
                        {
                            "lat": 61.509911173145305,
                            "lon": 55.119711706983594
                        },
                        {
                            "lat": 61.51304277440762,
                            "lon": 55.119776561189525
                        },
                        {
                            "lat": 61.516174388280476,
                            "lon": 55.1198413349715
                        },
                        {
                            "lat": 61.51628733096514,
                            "lon": 55.11804653356718
                        },
                        {
                            "lat": 61.51941881729644,
                            "lon": 55.11811122263061
                        },
                        {
                            "lat": 61.52255031620523,
                            "lon": 55.118175831274016
                        },
                        {
                            "lat": 61.52568182767592,
                            "lon": 55.11824035949668
                        },
                        {
                            "lat": 61.52579433739893,
                            "lon": 55.116445544539815
                        },
                        {
                            "lat": 61.52892572128927,
                            "lon": 55.11650998806351
                        },
                        {
                            "lat": 61.52903807835677,
                            "lon": 55.11471516814265
                        },
                        {
                            "lat": 61.529150422897324,
                            "lon": 55.112920347535336
                        },
                        {
                            "lat": 61.53228153910608,
                            "lon": 55.112984702098935
                        },
                        {
                            "lat": 61.53216933466295,
                            "lon": 55.11477952697769
                        },
                        {
                            "lat": 61.53530060348051,
                            "lon": 55.114843805400554
                        },
                        {
                            "lat": 61.538431884793866,
                            "lon": 55.114908003410534
                        },
                        {
                            "lat": 61.53854380903644,
                            "lon": 55.113113170004844
                        },
                        {
                            "lat": 61.53865572080065,
                            "lon": 55.111318335913865
                        },
                        {
                            "lat": 61.541786734403566,
                            "lon": 55.11138244499968
                        },
                        {
                            "lat": 61.54491776046732,
                            "lon": 55.11144647368183
                        },
                        {
                            "lat": 61.54480612888008,
                            "lon": 55.113241316277694
                        },
                        {
                            "lat": 61.54793730748047,
                            "lon": 55.11330526879998
                        },
                        {
                            "lat": 61.55106849851246,
                            "lon": 55.113369140911935
                        },
                        {
                            "lat": 61.55419970196049,
                            "lon": 55.11343293261282
                        },
                        {
                            "lat": 61.557330917808976,
                            "lon": 55.113496643901975
                        },
                        {
                            "lat": 61.55744198901955,
                            "lon": 55.11170178436024
                        },
                        {
                            "lat": 61.56057307715406,
                            "lon": 55.1117654110138
                        },
                        {
                            "lat": 61.56068399589834,
                            "lon": 55.1099705465663
                        },
                        {
                            "lat": 61.56079490227707,
                            "lon": 55.10817568143617
                        },
                        {
                            "lat": 61.56392572262421,
                            "lon": 55.108239219247395
                        },
                        {
                            "lat": 61.56403647658522,
                            "lon": 55.10644434921796
                        },
                        {
                            "lat": 61.56414721819998,
                            "lon": 55.10464947850632
                        },
                        {
                            "lat": 61.56425794747038,
                            "lon": 55.10285460711247
                        },
                        {
                            "lat": 61.564368664398316,
                            "lon": 55.10105973503646
                        },
                        {
                            "lat": 61.561238404173935,
                            "lon": 55.100996214089804
                        },
                        {
                            "lat": 61.56134924875303,
                            "lon": 55.0992013455468
                        },
                        {
                            "lat": 61.561460080977824,
                            "lon": 55.09740647632125
                        },
                        {
                            "lat": 61.56157090085021,
                            "lon": 55.095611606413165
                        },
                        {
                            "lat": 61.561681708372035,
                            "lon": 55.09381673582257
                        },
                        {
                            "lat": 61.55855202037016,
                            "lon": 55.09375315137986
                        },
                        {
                            "lat": 61.55866295547103,
                            "lon": 55.09195828432577
                        },
                        {
                            "lat": 61.555533419759186,
                            "lon": 55.09189462375349
                        },
                        {
                            "lat": 61.5554223447325,
                            "lon": 55.09368948658327
                        },
                        {
                            "lat": 61.55229268147458,
                            "lon": 55.09362574143348
                        },
                        {
                            "lat": 61.549163030612,
                            "lon": 55.09356191593117
                        },
                        {
                            "lat": 61.54905166332842,
                            "lon": 55.09535676961231
                        },
                        {
                            "lat": 61.54592188494096,
                            "lon": 55.0952928595175
                        },
                        {
                            "lat": 61.5427921189818,
                            "lon": 55.095228869066254
                        },
                        {
                            "lat": 61.54290376613494,
                            "lon": 55.09343402387177
                        },
                        {
                            "lat": 61.54603339216027,
                            "lon": 55.093498010077035
                        },
                        {
                            "lat": 61.546144886953165,
                            "lon": 55.09170315995217
                        },
                        {
                            "lat": 61.546256369321526,
                            "lon": 55.08990830914291
                        },
                        {
                            "lat": 61.54312702311725,
                            "lon": 55.089844331428495
                        },
                        {
                            "lat": 61.53999768935116,
                            "lon": 55.08978027337427
                        },
                        {
                            "lat": 61.539885927179185,
                            "lon": 55.09157511568775
                        },
                        {
                            "lat": 61.53977415255159,
                            "lon": 55.09336995731608
                        },
                        {
                            "lat": 61.53664455142572,
                            "lon": 55.093305810410655
                        },
                        {
                            "lat": 61.53351496277291,
                            "lon": 55.093241583156214
                        },
                        {
                            "lat": 61.533627017227644,
                            "lon": 55.09144675004566
                        },
                        {
                            "lat": 61.53373905919568,
                            "lon": 55.089651916249224
                        },
                        {
                            "lat": 61.536868368038796,
                            "lon": 55.08971613498094
                        },
                        {
                            "lat": 61.53698025764048,
                            "lon": 55.0879212962378
                        },
                        {
                            "lat": 61.5370921347748,
                            "lon": 55.086126456809154
                        },
                        {
                            "lat": 61.53720399944363,
                            "lon": 55.08433161669502
                        },
                        {
                            "lat": 61.53407511019853,
                            "lon": 55.08426741074456
                        },
                        {
                            "lat": 61.530946233432566,
                            "lon": 55.08420312447232
                        },
                        {
                            "lat": 61.52781736916121,
                            "lon": 55.08413875787904
                        },
                        {
                            "lat": 61.52468851740008,
                            "lon": 55.084074310965384
                        },
                        {
                            "lat": 61.52155967816467,
                            "lon": 55.084009783732085
                        },
                        {
                            "lat": 61.52144711426247,
                            "lon": 55.08580460249288
                        },
                        {
                            "lat": 61.52133453781711,
                            "lon": 55.08759942056627
                        },
                        {
                            "lat": 61.51820543142548,
                            "lon": 55.08753480444039
                        },
                        {
                            "lat": 61.51507633759443,
                            "lon": 55.08747010798564
                        },
                        {
                            "lat": 61.51194725633952,
                            "lon": 55.0874053312027
                        },
                        {
                            "lat": 61.512060252348675,
                            "lon": 55.08561052600624
                        },
                        {
                            "lat": 61.51217323576807,
                            "lon": 55.083815720121244
                        },
                        {
                            "lat": 61.50904444679084,
                            "lon": 55.08375087161633
                        },
                        {
                            "lat": 61.50915755744211,
                            "lon": 55.08195606934496
                        },
                        {
                            "lat": 61.5092706554919,
                            "lon": 55.080161266384685
                        },
                        {
                            "lat": 61.50614215873814,
                            "lon": 55.080096346178216
                        },
                        {
                            "lat": 61.50301367459947,
                            "lon": 55.08003134566693
                        },
                        {
                            "lat": 61.503127039621276,
                            "lon": 55.07823655063652
                        },
                        {
                            "lat": 61.506255383975116,
                            "lon": 55.07830154683577
                        },
                        {
                            "lat": 61.50938374094213,
                            "lon": 55.07836646273552
                        },
                        {
                            "lat": 61.51251211050677,
                            "lon": 55.07843129833505
                        },
                        {
                            "lat": 61.51564049265353,
                            "lon": 55.078496053633685
                        },
                        {
                            "lat": 61.518768887366896,
                            "lon": 55.078560728630706
                        },
                        {
                            "lat": 61.52189729463133,
                            "lon": 55.07862532332542
                        },
                        {
                            "lat": 61.52200980837993,
                            "lon": 55.07683050181513
                        },
                        {
                            "lat": 61.518881540894334,
                            "lon": 55.076765911405516
                        },
                        {
                            "lat": 61.515753285957935,
                            "lon": 55.0767012406989
                        },
                        {
                            "lat": 61.51586606669763,
                            "lon": 55.07490642707597
                        },
                        {
                            "lat": 61.51597883487455,
                            "lon": 55.07311161276499
                        },
                        {
                            "lat": 61.512850872006354,
                            "lon": 55.07304687035235
                        },
                        {
                            "lat": 61.51296376735089,
                            "lon": 55.07125205964782
                        },
                        {
                            "lat": 61.509835956785665,
                            "lon": 55.07118724125007
                        },
                        {
                            "lat": 61.509948979266774,
                            "lon": 55.069392434156605
                        },
                        {
                            "lat": 61.5068213210007,
                            "lon": 55.0693275397847
                        },
                        {
                            "lat": 61.503693675338255,
                            "lon": 55.06926256513988
                        },
                        {
                            "lat": 61.503580373440755,
                            "lon": 55.07105736361845
                        },
                        {
                            "lat": 61.5004526006921,
                            "lon": 55.07099230438595
                        },
                        {
                            "lat": 61.497324840579964,
                            "lon": 55.07092716487663
                        },
                        {
                            "lat": 61.49721124662167,
                            "lon": 55.07272195402867
                        },
                        {
                            "lat": 61.49408335944459,
                            "lon": 55.07265672991677
                        },
                        {
                            "lat": 61.49095548493701,
                            "lon": 55.072591425524166
                        },
                        {
                            "lat": 61.490841598861614,
                            "lon": 55.07438620532685
                        },
                        {
                            "lat": 61.48771359731035,
                            "lon": 55.07432081631677
                        },
                        {
                            "lat": 61.48759955880383,
                            "lon": 55.07611559109047
                        },
                        {
                            "lat": 61.48748550759291,
                            "lon": 55.0779103651726
                        },
                        {
                            "lat": 61.48737144367571,
                            "lon": 55.07970513856311
                        },
                        {
                            "lat": 61.48725736705028,
                            "lon": 55.081499911262064
                        },
                        {
                            "lat": 61.49038592767142,
                            "lon": 55.08156531762579
                        },
                        {
                            "lat": 61.4902719781421,
                            "lon": 55.08336009397248
                        },
                        {
                            "lat": 61.48714327771468,
                            "lon": 55.08329468326939
                        },
                        {
                            "lat": 61.48702917566698,
                            "lon": 55.085089454585095
                        },
                        {
                            "lat": 61.49015801591623,
                            "lon": 55.08515486962795
                        },
                        {
                            "lat": 61.49004404099187,
                            "lon": 55.08694964459218
                        },
                        {
                            "lat": 61.48691506090529,
                            "lon": 55.08688422520917
                        },
                        {
                            "lat": 61.48378609353465,
                            "lon": 55.08681872550435
                        },
                        {
                            "lat": 61.48367182620606,
                            "lon": 55.08861349109091
                        },
                        {
                            "lat": 61.48680093342763,
                            "lon": 55.0886789951416
                        },
                        {
                            "lat": 61.48993005336708,
                            "lon": 55.08874441886514
                        },
                        {
                            "lat": 61.49305918600888,
                            "lon": 55.088809762260844
                        },
                        {
                            "lat": 61.492945325552085,
                            "lon": 55.09060454017815
                        },
                        {
                            "lat": 61.48981605303996,
                            "lon": 55.09053919244683
                        },
                        {
                            "lat": 61.489702040008574,
                            "lon": 55.092333965337254
                        },
                        {
                            "lat": 61.48657264031677,
                            "lon": 55.092268532931456
                        },
                        {
                            "lat": 61.4864584746797,
                            "lon": 55.09406330078887
                        },
                        {
                            "lat": 61.483328947812076,
                            "lon": 55.093997783698306
                        },
                        {
                            "lat": 61.48019943368366,
                            "lon": 55.09393218626537
                        },
                        {
                            "lat": 61.48031387911391,
                            "lon": 55.0921374271073
                        },
                        {
                            "lat": 61.47718451763393,
                            "lon": 55.09207175369037
                        },
                        {
                            "lat": 61.477069932309995,
                            "lon": 55.09386650849079
                        },
                        {
                            "lat": 61.47695533421575,
                            "lon": 55.09566126259834
                        },
                        {
                            "lat": 61.480084975498656,
                            "lon": 55.09572694473096
                        },
                        {
                            "lat": 61.479970504556974,
                            "lon": 55.09752170250407
                        },
                        {
                            "lat": 61.476840723349305,
                            "lon": 55.097456016013005
                        },
                        {
                            "lat": 61.47672609970867,
                            "lon": 55.09925076873479
                        },
                        {
                            "lat": 61.47661146329191,
                            "lon": 55.10104552076368
                        },
                        {
                            "lat": 61.47348141496612,
                            "lon": 55.10097974519238
                        },
                        {
                            "lat": 61.47035137943392,
                            "lon": 55.10091388925958
                        },
                        {
                            "lat": 61.47046629575658,
                            "lon": 55.09911914596506
                        },
                        {
                            "lat": 61.46733641298357,
                            "lon": 55.099053214046656
                        },
                        {
                            "lat": 61.46722135671089,
                            "lon": 55.10084795296599
                        },
                        {
                            "lat": 61.46710628761349,
                            "lon": 55.10264269119128
                        },
                        {
                            "lat": 61.46699120568947,
                            "lon": 55.10443742872247
                        },
                        {
                            "lat": 61.463860915848265,
                            "lon": 55.10437140330669
                        },
                        {
                            "lat": 61.46073063885123,
                            "lon": 55.10430529752092
                        },
                        {
                            "lat": 61.46061526411107,
                            "lon": 55.10610002558938
                        },
                        {
                            "lat": 61.463745681100896,
                            "lon": 55.106166135762116
                        },
                        {
                            "lat": 61.463630433507426,
                            "lon": 55.10796086752302
                        },
                        {
                            "lat": 61.463515173065915,
                            "lon": 55.1097555985894
                        },
                        {
                            "lat": 61.46038447604381,
                            "lon": 55.10968947964155
                        }
                    ],
                    [
                        {
                            "lat": 61.47267762204186,
                            "lon": 55.1135429594298
                        },
                        {
                            "lat": 61.47580865048065,
                            "lon": 55.1136087655637
                        },
                        {
                            "lat": 61.475693911785164,
                            "lon": 55.11540351204894
                        },
                        {
                            "lat": 61.47256274326798,
                            "lon": 55.11533770154742
                        },
                        {
                            "lat": 61.47267762204186,
                            "lon": 55.1135429594298
                        }
                    ],
                    [
                        {
                            "lat": 61.48310029852325,
                            "lon": 55.09758730864285
                        },
                        {
                            "lat": 61.48321462953823,
                            "lon": 55.09579254651663
                        },
                        {
                            "lat": 61.48634429631896,
                            "lon": 55.09585806795458
                        },
                        {
                            "lat": 61.486230105232615,
                            "lon": 55.09765283442861
                        },
                        {
                            "lat": 61.48611590141872,
                            "lon": 55.09944760021089
                        },
                        {
                            "lat": 61.48298595476518,
                            "lon": 55.09938207007695
                        },
                        {
                            "lat": 61.47985602085665,
                            "lon": 55.09931645958467
                        },
                        {
                            "lat": 61.479970504556974,
                            "lon": 55.09752170250407
                        },
                        {
                            "lat": 61.48310029852325,
                            "lon": 55.09758730864285
                        }
                    ],
                    [
                        {
                            "lat": 61.470006553603305,
                            "lon": 55.106298114980945
                        },
                        {
                            "lat": 61.47012150835924,
                            "lon": 55.10450337376754
                        },
                        {
                            "lat": 61.47023645030212,
                            "lon": 55.10270863186042
                        },
                        {
                            "lat": 61.47336662580185,
                            "lon": 55.102774492163434
                        },
                        {
                            "lat": 61.473251823842055,
                            "lon": 55.104569238441194
                        },
                        {
                            "lat": 61.476382152122355,
                            "lon": 55.10463502274271
                        },
                        {
                            "lat": 61.47626747736564,
                            "lon": 55.10642977269283
                        },
                        {
                            "lat": 61.47313700908475,
                            "lon": 55.10636398402563
                        },
                        {
                            "lat": 61.47302218152803,
                            "lon": 55.108158728916706
                        },
                        {
                            "lat": 61.47290734116993,
                            "lon": 55.109953473114444
                        },
                        {
                            "lat": 61.46977660564454,
                            "lon": 55.10988759532657
                        },
                        {
                            "lat": 61.46989158603238,
                            "lon": 55.10809285550062
                        },
                        {
                            "lat": 61.470006553603305,
                            "lon": 55.106298114980945
                        }
                    ],
                    [
                        {
                            "lat": 61.42454335673411,
                            "lon": 55.13049294814847
                        },
                        {
                            "lat": 61.424660453904174,
                            "lon": 55.1286982784784
                        },
                        {
                            "lat": 61.421528371265026,
                            "lon": 55.12863114596178
                        },
                        {
                            "lat": 61.42141113390782,
                            "lon": 55.130425811175485
                        },
                        {
                            "lat": 61.42454335673411,
                            "lon": 55.13049294814847
                        }
                    ],
                    [
                        {
                            "lat": 61.4273246434007,
                            "lon": 55.13594402284939
                        },
                        {
                            "lat": 61.427441639522286,
                            "lon": 55.13414935082543
                        },
                        {
                            "lat": 61.42430912321054,
                            "lon": 55.13408228539028
                        },
                        {
                            "lat": 61.42117661996325,
                            "lon": 55.13401513950336
                        },
                        {
                            "lat": 61.42105934337191,
                            "lon": 55.13580980261753
                        },
                        {
                            "lat": 61.42094205369852,
                            "lon": 55.137604465031814
                        },
                        {
                            "lat": 61.420824750941144,
                            "lon": 55.13939912674621
                        },
                        {
                            "lat": 61.42395767493688,
                            "lon": 55.139466286007014
                        },
                        {
                            "lat": 61.427090612003006,
                            "lon": 55.13953336480006
                        },
                        {
                            "lat": 61.42720763422826,
                            "lon": 55.13773869417425
                        },
                        {
                            "lat": 61.4273246434007,
                            "lon": 55.13594402284939
                        }
                    ],
                    [
                        {
                            "lat": 61.429989759091896,
                            "lon": 55.14318971117547
                        },
                        {
                            "lat": 61.43010666712854,
                            "lon": 55.14139503749941
                        },
                        {
                            "lat": 61.42697357672294,
                            "lon": 55.14132803472675
                        },
                        {
                            "lat": 61.423840499374144,
                            "lon": 55.14126095148028
                        },
                        {
                            "lat": 61.423723310739035,
                            "lon": 55.14305561625401
                        },
                        {
                            "lat": 61.426856528386075,
                            "lon": 55.14312270395431
                        },
                        {
                            "lat": 61.42673946699044,
                            "lon": 55.14491737248276
                        },
                        {
                            "lat": 61.42662239253404,
                            "lon": 55.146712040312046
                        },
                        {
                            "lat": 61.429755903887134,
                            "lon": 55.146779056431406
                        },
                        {
                            "lat": 61.4298728380121,
                            "lon": 55.14498438415283
                        },
                        {
                            "lat": 61.43300622207892,
                            "lon": 55.145051315337675
                        },
                        {
                            "lat": 61.4331230028409,
                            "lon": 55.143256637916764
                        },
                        {
                            "lat": 61.429989759091896,
                            "lon": 55.14318971117547
                        }
                    ],
                    [
                        {
                            "lat": 61.429638956715024,
                            "lon": 55.14857372801124
                        },
                        {
                            "lat": 61.42952199649378,
                            "lon": 55.1503683988923
                        },
                        {
                            "lat": 61.4326558016077,
                            "lon": 55.15043534341025
                        },
                        {
                            "lat": 61.432772621464295,
                            "lon": 55.14864066808443
                        },
                        {
                            "lat": 61.435906299247094,
                            "lon": 55.14870752766103
                        },
                        {
                            "lat": 61.43602296571916,
                            "lon": 55.14691284719779
                        },
                        {
                            "lat": 61.43288942828738,
                            "lon": 55.14684599206022
                        },
                        {
                            "lat": 61.429755903887134,
                            "lon": 55.146779056431406
                        },
                        {
                            "lat": 61.429638956715024,
                            "lon": 55.14857372801124
                        }
                    ],
                    [
                        {
                            "lat": 61.432422122786015,
                            "lon": 55.154024691966725
                        },
                        {
                            "lat": 61.43253896871559,
                            "lon": 55.152230018037685
                        },
                        {
                            "lat": 61.42940502322143,
                            "lon": 55.152163069074575
                        },
                        {
                            "lat": 61.42952199649378,
                            "lon": 55.1503683988923
                        },
                        {
                            "lat": 61.426388204430964,
                            "lon": 55.150301373873155
                        },
                        {
                            "lat": 61.42325442543489,
                            "lon": 55.150234268353564
                        },
                        {
                            "lat": 61.42313717140799,
                            "lon": 55.15202892962953
                        },
                        {
                            "lat": 61.426271090780354,
                            "lon": 55.15209603960494
                        },
                        {
                            "lat": 61.42615396406102,
                            "lon": 55.15389070463754
                        },
                        {
                            "lat": 61.42928803689599,
                            "lon": 55.15395773855805
                        },
                        {
                            "lat": 61.432422122786015,
                            "lon": 55.154024691966725
                        }
                    ],
                    [
                        {
                            "lat": 61.43532277157848,
                            "lon": 55.15768091950726
                        },
                        {
                            "lat": 61.43543950315992,
                            "lon": 55.15588624253406
                        },
                        {
                            "lat": 61.43857375552871,
                            "lon": 55.15595303935213
                        },
                        {
                            "lat": 61.43845716437834,
                            "lon": 55.15774772076102
                        },
                        {
                            "lat": 61.44159157019038,
                            "lon": 55.15781444149008
                        },
                        {
                            "lat": 61.441475106476474,
                            "lon": 55.159609126632084
                        },
                        {
                            "lat": 61.44460966573572,
                            "lon": 55.159675771261135
                        },
                        {
                            "lat": 61.444725988998975,
                            "lon": 55.15788108169372
                        },
                        {
                            "lat": 61.44786042078852,
                            "lon": 55.1579476413712
                        },
                        {
                            "lat": 61.447976590633836,
                            "lon": 55.156152946687236
                        },
                        {
                            "lat": 61.44484229928127,
                            "lon": 55.15608639142945
                        },
                        {
                            "lat": 61.44495859658461,
                            "lon": 55.15429170046831
                        },
                        {
                            "lat": 61.445074880910944,
                            "lon": 55.15249700881038
                        },
                        {
                            "lat": 61.44194088336054,
                            "lon": 55.15243038188062
                        },
                        {
                            "lat": 61.438806898800756,
                            "lon": 55.15236367444143
                        },
                        {
                            "lat": 61.438923450926424,
                            "lon": 55.15056899093967
                        },
                        {
                            "lat": 61.435789619757124,
                            "lon": 55.1505022074263
                        },
                        {
                            "lat": 61.43567292724725,
                            "lon": 55.152296886493566
                        },
                        {
                            "lat": 61.43555622171551,
                            "lon": 55.15409156486281
                        },
                        {
                            "lat": 61.432422122786015,
                            "lon": 55.154024691966725
                        },
                        {
                            "lat": 61.432305263816964,
                            "lon": 55.15581936519735
                        },
                        {
                            "lat": 61.432188391806456,
                            "lon": 55.15761403772953
                        },
                        {
                            "lat": 61.43532277157848,
                            "lon": 55.15768091950726
                        }
                    ],
                    [
                        {
                            "lat": 61.45680067059228,
                            "lon": 55.16532566193082
                        },
                        {
                            "lat": 61.453665638025775,
                            "lon": 55.16525932621741
                        },
                        {
                            "lat": 61.450530618401245,
                            "lon": 55.16519290995437
                        },
                        {
                            "lat": 61.44739561173429,
                            "lon": 55.16512641314237
                        },
                        {
                            "lat": 61.44727937704262,
                            "lon": 55.166921104343906
                        },
                        {
                            "lat": 61.45041452422667,
                            "lon": 55.16698760557223
                        },
                        {
                            "lat": 61.45029841709256,
                            "lon": 55.16878230049397
                        },
                        {
                            "lat": 61.4501822969969,
                            "lon": 55.1705769947196
                        },
                        {
                            "lat": 61.45006616393775,
                            "lon": 55.17237168824905
                        },
                        {
                            "lat": 61.4532017457324,
                            "lon": 55.172438122158475
                        },
                        {
                            "lat": 61.45633734047685,
                            "lon": 55.17250447549689
                        },
                        {
                            "lat": 61.45645319240207,
                            "lon": 55.1707097731484
                        },
                        {
                            "lat": 61.45958865951081,
                            "lon": 55.170776041513584
                        },
                        {
                            "lat": 61.45947294815551,
                            "lon": 55.17257074826358
                        },
                        {
                            "lat": 61.45935722388167,
                            "lon": 55.174365454318604
                        },
                        {
                            "lat": 61.459241486687326,
                            "lon": 55.176160159678616
                        },
                        {
                            "lat": 61.45912573657052,
                            "lon": 55.17795486434362
                        },
                        {
                            "lat": 61.46226177897722,
                            "lon": 55.17802106972874
                        },
                        {
                            "lat": 61.46539783429271,
                            "lon": 55.178087194524686
                        },
                        {
                            "lat": 61.465513303169914,
                            "lon": 55.176292481070305
                        },
                        {
                            "lat": 61.468649230755815,
                            "lon": 55.176358520889664
                        },
                        {
                            "lat": 61.46853390250134,
                            "lon": 55.17815323873072
                        },
                        {
                            "lat": 61.46841856136939,
                            "lon": 55.17994795587794
                        },
                        {
                            "lat": 61.46830320735799,
                            "lon": 55.181742672331296
                        },
                        {
                            "lat": 61.46818784046518,
                            "lon": 55.183537388090784
                        },
                        {
                            "lat": 61.47132434351932,
                            "lon": 55.18360336485256
                        },
                        {
                            "lat": 61.47120910443048,
                            "lon": 55.185398084301085
                        },
                        {
                            "lat": 61.47434576104162,
                            "lon": 55.185463984833085
                        },
                        {
                            "lat": 61.47423064978803,
                            "lon": 55.187258707966045
                        },
                        {
                            "lat": 61.47736745996009,
                            "lon": 55.18732453225726
                        },
                        {
                            "lat": 61.477252476573106,
                            "lon": 55.18911925907012
                        },
                        {
                            "lat": 61.47713748034389,
                            "lon": 55.19091398519026
                        },
                        {
                            "lat": 61.477022471270445,
                            "lon": 55.19270871061763
                        },
                        {
                            "lat": 61.47690744935084,
                            "lon": 55.194503435352246
                        },
                        {
                            "lat": 61.476792414583116,
                            "lon": 55.19629815939407
                        },
                        {
                            "lat": 61.47365490064154,
                            "lon": 55.196232313233885
                        },
                        {
                            "lat": 61.47051739956571,
                            "lon": 55.196166386428246
                        },
                        {
                            "lat": 61.47063271590197,
                            "lon": 55.19437167114091
                        },
                        {
                            "lat": 61.46749536848888,
                            "lon": 55.19430566807577
                        },
                        {
                            "lat": 61.46435803397096,
                            "lon": 55.194239584371935
                        },
                        {
                            "lat": 61.46447361895711,
                            "lon": 55.192444877166146
                        },
                        {
                            "lat": 61.46133643811171,
                            "lon": 55.192378717219896
                        },
                        {
                            "lat": 61.45819927019082,
                            "lon": 55.192312476641725
                        },
                        {
                            "lat": 61.455062115210104,
                            "lon": 55.1922461554324
                        },
                        {
                            "lat": 61.451924973185264,
                            "lon": 55.19217975359258
                        },
                        {
                            "lat": 61.44878784413194,
                            "lon": 55.19211327112304
                        },
                        {
                            "lat": 61.448904119919824,
                            "lon": 55.19031858525216
                        },
                        {
                            "lat": 61.449020382722495,
                            "lon": 55.18852389868498
                        },
                        {
                            "lat": 61.445883548113066,
                            "lon": 55.188457344429764
                        },
                        {
                            "lat": 61.445999938637385,
                            "lon": 55.186662661587384
                        },
                        {
                            "lat": 61.44286325772976,
                            "lon": 55.186596031141
                        },
                        {
                            "lat": 61.43972658983473,
                            "lon": 55.18652932008311
                        },
                        {
                            "lat": 61.43960991790662,
                            "lon": 55.188323994067304
                        },
                        {
                            "lat": 61.44274672650257,
                            "lon": 55.18839070955698
                        },
                        {
                            "lat": 61.44263018226082,
                            "lon": 55.19018538727585
                        },
                        {
                            "lat": 61.44576714458989,
                            "lon": 55.19025202657548
                        },
                        {
                            "lat": 61.44565072806581,
                            "lon": 55.19204670802449
                        },
                        {
                            "lat": 61.44553429853885,
                            "lon": 55.19384138877679
                        },
                        {
                            "lat": 61.44541785600707,
                            "lon": 55.195636068832364
                        },
                        {
                            "lat": 61.448555253592616,
                            "lon": 55.19570264077586
                        },
                        {
                            "lat": 61.451692664169286,
                            "lon": 55.19576913207967
                        },
                        {
                            "lat": 61.45483008772145,
                            "lon": 55.19583554274303
                        },
                        {
                            "lat": 61.457967524233396,
                            "lon": 55.19590187276526
                        },
                        {
                            "lat": 61.46110497368947,
                            "lon": 55.19596812214563
                        },
                        {
                            "lat": 61.464242436073995,
                            "lon": 55.196034290883375
                        },
                        {
                            "lat": 61.46412682526426,
                            "lon": 55.19782899670046
                        },
                        {
                            "lat": 61.467264441356605,
                            "lon": 55.197895089185906
                        },
                        {
                            "lat": 61.46714895844284,
                            "lon": 55.19968979870001
                        },
                        {
                            "lat": 61.470286728246926,
                            "lon": 55.199755814922135
                        },
                        {
                            "lat": 61.47342451093636,
                            "lon": 55.1998217504888
                        },
                        {
                            "lat": 61.476562306495474,
                            "lon": 55.19988760539932
                        },
                        {
                            "lat": 61.47970011490858,
                            "lon": 55.19995337965293
                        },
                        {
                            "lat": 61.482837936159974,
                            "lon": 55.200019073248974
                        },
                        {
                            "lat": 61.485975770234006,
                            "lon": 55.20008468618668
                        },
                        {
                            "lat": 61.48586111941877,
                            "lon": 55.2018794212453
                        },
                        {
                            "lat": 61.48899910713985,
                            "lon": 55.20194495787835
                        },
                        {
                            "lat": 61.49213710765412,
                            "lon": 55.2020104138463
                        },
                        {
                            "lat": 61.49225147678722,
                            "lon": 55.200215670084276
                        },
                        {
                            "lat": 61.49236583314474,
                            "lon": 55.1984209256314
                        },
                        {
                            "lat": 61.49248017672862,
                            "lon": 55.196626180487655
                        },
                        {
                            "lat": 61.489342598693426,
                            "lon": 55.19656073756563
                        },
                        {
                            "lat": 61.48922811429886,
                            "lon": 55.198355478361115
                        },
                        {
                            "lat": 61.48609040824231,
                            "lon": 55.19828995043643
                        },
                        {
                            "lat": 61.48295271499074,
                            "lon": 55.19822434185805
                        },
                        {
                            "lat": 61.47981503455984,
                            "lon": 55.19815865262671
                        },
                        {
                            "lat": 61.47992994137478,
                            "lon": 55.19636392490805
                        },
                        {
                            "lat": 61.480044835355294,
                            "lon": 55.19456919649698
                        },
                        {
                            "lat": 61.4831822341922,
                            "lon": 55.19463487700015
                        },
                        {
                            "lat": 61.483296974566784,
                            "lon": 55.192840143533196
                        },
                        {
                            "lat": 61.48643424544501,
                            "lon": 55.192905739035915
                        },
                        {
                            "lat": 61.48654883224503,
                            "lon": 55.191111000519214
                        },
                        {
                            "lat": 61.48666340624783,
                            "lon": 55.18931626131092
                        },
                        {
                            "lat": 61.486777967455396,
                            "lon": 55.18752152141106
                        },
                        {
                            "lat": 61.48689251586963,
                            "lon": 55.18572678081964
                        },
                        {
                            "lat": 61.48375580793644,
                            "lon": 55.185661202745656
                        },
                        {
                            "lat": 61.48387048425642,
                            "lon": 55.183866465818895
                        },
                        {
                            "lat": 61.48073392982539,
                            "lon": 55.183800811490876
                        },
                        {
                            "lat": 61.480848734019794,
                            "lon": 55.18200607823334
                        },
                        {
                            "lat": 61.47771233308703,
                            "lon": 55.181940347662305
                        },
                        {
                            "lat": 61.47782726512454,
                            "lon": 55.180145618078605
                        },
                        {
                            "lat": 61.47794218432954,
                            "lon": 55.1783508878022
                        },
                        {
                            "lat": 61.47480607753541,
                            "lon": 55.17828508537019
                        },
                        {
                            "lat": 61.474691017686126,
                            "lon": 55.18007981127553
                        },
                        {
                            "lat": 61.47155478309586,
                            "lon": 55.18001392387508
                        },
                        {
                            "lat": 61.471669983587454,
                            "lon": 55.17821920234613
                        },
                        {
                            "lat": 61.47178517121723,
                            "lon": 55.17642448012373
                        },
                        {
                            "lat": 61.4719003459871,
                            "lon": 55.17462975720791
                        },
                        {
                            "lat": 61.47201550789906,
                            "lon": 55.172835033598645
                        },
                        {
                            "lat": 61.468879848640114,
                            "lon": 55.17276908312608
                        },
                        {
                            "lat": 61.46899513827387,
                            "lon": 55.17097436320356
                        },
                        {
                            "lat": 61.46911041503796,
                            "lon": 55.169179642587274
                        },
                        {
                            "lat": 61.4659750497866,
                            "lon": 55.16911362031069
                        },
                        {
                            "lat": 61.46283969741858,
                            "lon": 55.1690475174709
                        },
                        {
                            "lat": 61.45970435794951,
                            "lon": 55.16898133406862
                        },
                        {
                            "lat": 61.45656903139507,
                            "lon": 55.16891507010456
                        },
                        {
                            "lat": 61.45668485745782,
                            "lon": 55.16712036636536
                        },
                        {
                            "lat": 61.45982004347362,
                            "lon": 55.16718662592869
                        },
                        {
                            "lat": 61.46295524240208,
                            "lon": 55.16725280493557
                        },
                        {
                            "lat": 61.46307077448857,
                            "lon": 55.16545809170572
                        },
                        {
                            "lat": 61.46318629368001,
                            "lon": 55.16366337778133
                        },
                        {
                            "lat": 61.460051375785845,
                            "lon": 55.163597207564024
                        },
                        {
                            "lat": 61.4601670225779,
                            "lon": 55.16180249733929
                        },
                        {
                            "lat": 61.457032258084205,
                            "lon": 55.161736250975764
                        },
                        {
                            "lat": 61.457148032445616,
                            "lon": 55.159941544455265
                        },
                        {
                            "lat": 61.454013421348456,
                            "lon": 55.1598752219565
                        },
                        {
                            "lat": 61.450878823187324,
                            "lon": 55.159808818924084
                        },
                        {
                            "lat": 61.447744237977886,
                            "lon": 55.159742335358715
                        },
                        {
                            "lat": 61.44762804219994,
                            "lon": 55.16153702864976
                        },
                        {
                            "lat": 61.444493329489546,
                            "lon": 55.16147046013171
                        },
                        {
                            "lat": 61.444376980258475,
                            "lon": 55.1632651483054
                        },
                        {
                            "lat": 61.44751183345274,
                            "lon": 55.163331721244305
                        },
                        {
                            "lat": 61.45064669961823,
                            "lon": 55.16339821364038
                        },
                        {
                            "lat": 61.453781578739324,
                            "lon": 55.16346462549282
                        },
                        {
                            "lat": 61.45691647080041,
                            "lon": 55.16353095680095
                        },
                        {
                            "lat": 61.45680067059228,
                            "lon": 55.16532566193082
                        }
                    ],
                    [
                        {
                            "lat": 61.46876454613474,
                            "lon": 55.17456380235478
                        },
                        {
                            "lat": 61.465628759155955,
                            "lon": 55.17449776692172
                        },
                        {
                            "lat": 61.46249298506638,
                            "lon": 55.174431650909405
                        },
                        {
                            "lat": 61.46260856875271,
                            "lon": 55.172636940457814
                        },
                        {
                            "lat": 61.46574420225278,
                            "lon": 55.17270305207891
                        },
                        {
                            "lat": 61.468879848640114,
                            "lon": 55.17276908312608
                        },
                        {
                            "lat": 61.46876454613474,
                            "lon": 55.17456380235478
                        }
                    ],
                    [
                        {
                            "lat": 61.441358629764046,
                            "lon": 55.16140381107684
                        },
                        {
                            "lat": 61.441475106476474,
                            "lon": 55.159609126632084
                        },
                        {
                            "lat": 61.438340560215785,
                            "lon": 55.15954240147228
                        },
                        {
                            "lat": 61.43822394303907,
                            "lon": 55.161337081485875
                        },
                        {
                            "lat": 61.441358629764046,
                            "lon": 55.16140381107684
                        }
                    ],
                    [
                        {
                            "lat": 61.44426061804052,
                            "lon": 55.16505983578218
                        },
                        {
                            "lat": 61.444376980258475,
                            "lon": 55.1632651483054
                        },
                        {
                            "lat": 61.441242140051074,
                            "lon": 55.16319849482431
                        },
                        {
                            "lat": 61.43810731284618,
                            "lon": 55.1631317608018
                        },
                        {
                            "lat": 61.43799066963519,
                            "lon": 55.16492643942002
                        },
                        {
                            "lat": 61.44112563733562,
                            "lon": 55.16499317787447
                        },
                        {
                            "lat": 61.44426061804052,
                            "lon": 55.16505983578218
                        }
                    ],
                    [
                        {
                            "lat": 61.433570233409434,
                            "lon": 55.18460099033266
                        },
                        {
                            "lat": 61.430433745325395,
                            "lon": 55.184534041891894
                        },
                        {
                            "lat": 61.42729727031459,
                            "lon": 55.18446701284784
                        },
                        {
                            "lat": 61.42416080839265,
                            "lon": 55.184399903201246
                        },
                        {
                            "lat": 61.421024359575256,
                            "lon": 55.18433271295283
                        },
                        {
                            "lat": 61.42090685660564,
                            "lon": 55.186127360934144
                        },
                        {
                            "lat": 61.417770280237114,
                            "lon": 55.1860600856159
                        },
                        {
                            "lat": 61.41765262345831,
                            "lon": 55.18785472842822
                        },
                        {
                            "lat": 61.42078934051381,
                            "lon": 55.187922008215615
                        },
                        {
                            "lat": 61.42392607069349,
                            "lon": 55.18798920739125
                        },
                        {
                            "lat": 61.424043446096334,
                            "lon": 55.18619455564597
                        },
                        {
                            "lat": 61.427180048693586,
                            "lon": 55.18626166975063
                        },
                        {
                            "lat": 61.43031666438169,
                            "lon": 55.1863287032474
                        },
                        {
                            "lat": 61.43345329314503,
                            "lon": 55.18639565613556
                        },
                        {
                            "lat": 61.43658993496792,
                            "lon": 55.18646252841437
                        },
                        {
                            "lat": 61.43972658983473,
                            "lon": 55.18652932008311
                        },
                        {
                            "lat": 61.439843248734576,
                            "lon": 55.18473464540145
                        },
                        {
                            "lat": 61.436706734551045,
                            "lon": 55.18466785816942
                        },
                        {
                            "lat": 61.433570233409434,
                            "lon": 55.18460099033266
                        }
                    ],
                    [
                        {
                            "lat": 61.417770280237114,
                            "lon": 55.1860600856159
                        },
                        {
                            "lat": 61.41788792387807,
                            "lon": 55.18426544210333
                        },
                        {
                            "lat": 61.414751501316715,
                            "lon": 55.18419809065348
                        },
                        {
                            "lat": 61.414633717006446,
                            "lon": 55.18599272969196
                        },
                        {
                            "lat": 61.417770280237114,
                            "lon": 55.1860600856159
                        }
                    ],
                    [
                        {
                            "lat": 61.44983232224449,
                            "lon": 55.224484059751816
                        },
                        {
                            "lat": 61.4466926572225,
                            "lon": 55.22441749771693
                        },
                        {
                            "lat": 61.446576134339445,
                            "lon": 55.226212170355645
                        },
                        {
                            "lat": 61.44343634130599,
                            "lon": 55.226145523166096
                        },
                        {
                            "lat": 61.4433196643481,
                            "lon": 55.22794019067845
                        },
                        {
                            "lat": 61.4401797433072,
                            "lon": 55.227873458323884
                        },
                        {
                            "lat": 61.43703983532451,
                            "lon": 55.22780664523481
                        },
                        {
                            "lat": 61.43389994041579,
                            "lon": 55.22773975141198
                        },
                        {
                            "lat": 61.430760058596746,
                            "lon": 55.22767277685614
                        },
                        {
                            "lat": 61.42762018988307,
                            "lon": 55.227605721568004
                        },
                        {
                            "lat": 61.42750279458394,
                            "lon": 55.22940036615204
                        },
                        {
                            "lat": 61.43064280435335,
                            "lon": 55.22946742589716
                        },
                        {
                            "lat": 61.43052553700259,
                            "lon": 55.231262074239616
                        },
                        {
                            "lat": 61.43040825654243,
                            "lon": 55.23305672188346
                        },
                        {
                            "lat": 61.43354856158188,
                            "lon": 55.23312370979536
                        },
                        {
                            "lat": 61.436688879717,
                            "lon": 55.233190616958154
                        },
                        {
                            "lat": 61.43982921093204,
                            "lon": 55.233257443371144
                        },
                        {
                            "lat": 61.44296955521132,
                            "lon": 55.23332418903361
                        },
                        {
                            "lat": 61.446109912539065,
                            "lon": 55.23339085394478
                        },
                        {
                            "lat": 61.449250282899584,
                            "lon": 55.233457438103954
                        },
                        {
                            "lat": 61.44936671679474,
                            "lon": 55.23166276382591
                        },
                        {
                            "lat": 61.45250695907118,
                            "lon": 55.23172926281205
                        },
                        {
                            "lat": 61.455647214346975,
                            "lon": 55.231795681050095
                        },
                        {
                            "lat": 61.45553106265606,
                            "lon": 55.23359036416339
                        },
                        {
                            "lat": 61.458671472020555,
                            "lon": 55.23365670606219
                        },
                        {
                            "lat": 61.46181189435493,
                            "lon": 55.23372296720609
                        },
                        {
                            "lat": 61.46495232964346,
                            "lon": 55.23378914759435
                        },
                        {
                            "lat": 61.46809277787042,
                            "lon": 55.233855247226266
                        },
                        {
                            "lat": 61.468208365129136,
                            "lon": 55.23206054650671
                        },
                        {
                            "lat": 61.46832393946712,
                            "lon": 55.230265845093335
                        },
                        {
                            "lat": 61.47146411840654,
                            "lon": 55.230331855192205
                        },
                        {
                            "lat": 61.47157953874422,
                            "lon": 55.228537148697605
                        },
                        {
                            "lat": 61.47471958950319,
                            "lon": 55.22860307366734
                        },
                        {
                            "lat": 61.477859653147505,
                            "lon": 55.2286689178946
                        },
                        {
                            "lat": 61.477974778446594,
                            "lon": 55.226874201948654
                        },
                        {
                            "lat": 61.481114713888836,
                            "lon": 55.2269399610618
                        },
                        {
                            "lat": 61.484254662183055,
                            "lon": 55.2270056394364
                        },
                        {
                            "lat": 61.4873946233135,
                            "lon": 55.227071237071726
                        },
                        {
                            "lat": 61.49053459726447,
                            "lon": 55.22713675396709
                        },
                        {
                            "lat": 61.490420036263956,
                            "lon": 55.22893148736469
                        },
                        {
                            "lat": 61.49356016409928,
                            "lon": 55.22899692786888
                        },
                        {
                            "lat": 61.493445731387254,
                            "lon": 55.230791664925356
                        },
                        {
                            "lat": 61.496586013110786,
                            "lon": 55.230857029027334
                        },
                        {
                            "lat": 61.49647170871871,
                            "lon": 55.232651769738084
                        },
                        {
                            "lat": 61.49961214433437,
                            "lon": 55.23271705742684
                        },
                        {
                            "lat": 61.499497968293674,
                            "lon": 55.234511801787285
                        },
                        {
                            "lat": 61.502638557805376,
                            "lon": 55.23457701305179
                        },
                        {
                            "lat": 61.505779160066766,
                            "lon": 55.234642143551355
                        },
                        {
                            "lat": 61.508919775062125,
                            "lon": 55.23470719328521
                        },
                        {
                            "lat": 61.5120604027757,
                            "lon": 55.23477216225269
                        },
                        {
                            "lat": 61.515201043191816,
                            "lon": 55.234837050453066
                        },
                        {
                            "lat": 61.51531451355039,
                            "lon": 55.233042284472965
                        },
                        {
                            "lat": 61.51845502551104,
                            "lon": 55.233107087597666
                        },
                        {
                            "lat": 61.518568342058266,
                            "lon": 55.23131231662211
                        },
                        {
                            "lat": 61.52170872555957,
                            "lon": 55.23137703468153
                        },
                        {
                            "lat": 61.52182188832691,
                            "lon": 55.22958225871666
                        },
                        {
                            "lat": 61.52496214336503,
                            "lon": 55.229646891721174
                        },
                        {
                            "lat": 61.525075152383934,
                            "lon": 55.22785211077312
                        },
                        {
                            "lat": 61.52821527895502,
                            "lon": 55.22791665873312
                        },
                        {
                            "lat": 61.53135541814226,
                            "lon": 55.2279811259439
                        },
                        {
                            "lat": 61.53124269132696,
                            "lon": 55.22977591546788
                        },
                        {
                            "lat": 61.53112995190931,
                            "lon": 55.23157070430585
                        },
                        {
                            "lat": 61.527989530500825,
                            "lon": 55.23150622852411
                        },
                        {
                            "lat": 61.5278766373447,
                            "lon": 55.23330101238998
                        },
                        {
                            "lat": 61.531017199887394,
                            "lon": 55.23336549245778
                        },
                        {
                            "lat": 61.530904435259295,
                            "lon": 55.23516027992367
                        },
                        {
                            "lat": 61.53404515156005,
                            "lon": 55.23522468350658
                        },
                        {
                            "lat": 61.5371858804532,
                            "lon": 55.23528900631745
                        },
                        {
                            "lat": 61.54032662192297,
                            "lon": 55.23535324835557
                        },
                        {
                            "lat": 61.54346737595367,
                            "lon": 55.235417409620254
                        },
                        {
                            "lat": 61.546608142529536,
                            "lon": 55.23548149011076
                        },
                        {
                            "lat": 61.54974892163485,
                            "lon": 55.23554548982644
                        },
                        {
                            "lat": 61.552889713253855,
                            "lon": 55.23560940876653
                        },
                        {
                            "lat": 61.556030517370836,
                            "lon": 55.23567324693042
                        },
                        {
                            "lat": 61.55917133397004,
                            "lon": 55.235737004317315
                        },
                        {
                            "lat": 61.562312163035735,
                            "lon": 55.23580068092659
                        },
                        {
                            "lat": 61.5654530045522,
                            "lon": 55.235864276757496
                        },
                        {
                            "lat": 61.565564216404574,
                            "lon": 55.23406944249416
                        },
                        {
                            "lat": 61.5687049291832,
                            "lon": 55.234132953323886
                        },
                        {
                            "lat": 61.56881598744535,
                            "lon": 55.232338114156924
                        },
                        {
                            "lat": 61.56892703329196,
                            "lon": 55.230543274308474
                        },
                        {
                            "lat": 61.565786602811855,
                            "lon": 55.23047977192199
                        },
                        {
                            "lat": 61.56589777737052,
                            "lon": 55.22868493561313
                        },
                        {
                            "lat": 61.56275750044348,
                            "lon": 55.22862135669007
                        },
                        {
                            "lat": 61.56286880368276,
                            "lon": 55.226826523925446
                        },
                        {
                            "lat": 61.55972868030503,
                            "lon": 55.22676286847682
                        },
                        {
                            "lat": 61.55658856938428,
                            "lon": 55.226699132277346
                        },
                        {
                            "lat": 61.5567001423612,
                            "lon": 55.22490430729785
                        },
                        {
                            "lat": 61.55356018499978,
                            "lon": 55.22484049458989
                        },
                        {
                            "lat": 61.550420240124915,
                            "lon": 55.224776601137805
                        },
                        {
                            "lat": 61.54728030775231,
                            "lon": 55.2247126269423
                        },
                        {
                            "lat": 61.547392291465336,
                            "lon": 55.2229178140199
                        },
                        {
                            "lat": 61.5442525126759,
                            "lon": 55.22285376333904
                        },
                        {
                            "lat": 61.544364624922295,
                            "lon": 55.22105895398957
                        },
                        {
                            "lat": 61.541224999712185,
                            "lon": 55.2209948268344
                        },
                        {
                            "lat": 61.54133724046055,
                            "lon": 55.219200021062456
                        },
                        {
                            "lat": 61.53819776882595,
                            "lon": 55.21913581744396
                        },
                        {
                            "lat": 61.53505830975076,
                            "lon": 55.21907153310093
                        },
                        {
                            "lat": 61.53517081998189,
                            "lon": 55.2172767351834
                        },
                        {
                            "lat": 61.5320315144922,
                            "lon": 55.21721237439414
                        },
                        {
                            "lat": 61.5321441531448,
                            "lon": 55.21541758006831
                        },
                        {
                            "lat": 61.53225677921039,
                            "lon": 55.21362278505654
                        },
                        {
                            "lat": 61.52911776827939,
                            "lon": 55.21355835211429
                        },
                        {
                            "lat": 61.52597876994918,
                            "lon": 55.21349383846562
                        },
                        {
                            "lat": 61.52609166535044,
                            "lon": 55.21169905133696
                        },
                        {
                            "lat": 61.5229528205942,
                            "lon": 55.21163446127512
                        },
                        {
                            "lat": 61.519813988468215,
                            "lon": 55.21156979051363
                        },
                        {
                            "lat": 61.5166751689882,
                            "lon": 55.2115050390532
                        },
                        {
                            "lat": 61.51353636216984,
                            "lon": 55.211440206894565
                        },
                        {
                            "lat": 61.51364980871216,
                            "lon": 55.20964543627992
                        },
                        {
                            "lat": 61.51376324257902,
                            "lon": 55.20785066497707
                        },
                        {
                            "lat": 61.516901767541064,
                            "lon": 55.20791548851972
                        },
                        {
                            "lat": 61.51701504782981,
                            "lon": 55.20612071222125
                        },
                        {
                            "lat": 61.5138766637724,
                            "lon": 55.206055892986015
                        },
                        {
                            "lat": 61.510738292386556,
                            "lon": 55.205990993069314
                        },
                        {
                            "lat": 61.51085184179536,
                            "lon": 55.20419622470248
                        },
                        {
                            "lat": 61.50771362398184,
                            "lon": 55.20413124842272
                        },
                        {
                            "lat": 61.50782730157466,
                            "lon": 55.20233648368467
                        },
                        {
                            "lat": 61.50468923732963,
                            "lon": 55.20227143105287
                        },
                        {
                            "lat": 61.50457541886937,
                            "lon": 55.204066191468236
                        },
                        {
                            "lat": 61.50446158769238,
                            "lon": 55.20586095119429
                        },
                        {
                            "lat": 61.507599933687985,
                            "lon": 55.20592601247183
                        },
                        {
                            "lat": 61.50748623069119,
                            "lon": 55.20772077583199
                        },
                        {
                            "lat": 61.50737251498948,
                            "lon": 55.20951553850316
                        },
                        {
                            "lat": 61.507258786580934,
                            "lon": 55.21131030048537
                        },
                        {
                            "lat": 61.50412001784178,
                            "lon": 55.21124522623627
                        },
                        {
                            "lat": 61.50098126182709,
                            "lon": 55.21118007129177
                        },
                        {
                            "lat": 61.5008672388199,
                            "lon": 55.21297482393039
                        },
                        {
                            "lat": 61.49772835460334,
                            "lon": 55.21290958395587
                        },
                        {
                            "lat": 61.494589483144594,
                            "lon": 55.21284426328205
                        },
                        {
                            "lat": 61.491450624459354,
                            "lon": 55.21277886190961
                        },
                        {
                            "lat": 61.488311778563315,
                            "lon": 55.212713379839286
                        },
                        {
                            "lat": 61.48517294547218,
                            "lon": 55.21264781707177
                        },
                        {
                            "lat": 61.48203412520165,
                            "lon": 55.21258217360781
                        },
                        {
                            "lat": 61.47889531776744,
                            "lon": 55.21251644944809
                        },
                        {
                            "lat": 61.475756523185204,
                            "lon": 55.21245064459335
                        },
                        {
                            "lat": 61.4756413598286,
                            "lon": 55.21424536170668
                        },
                        {
                            "lat": 61.478780295354895,
                            "lon": 55.2143111709346
                        },
                        {
                            "lat": 61.48191924373515,
                            "lon": 55.21437689946211
                        },
                        {
                            "lat": 61.481804349430405,
                            "lon": 55.21617162462428
                        },
                        {
                            "lat": 61.48494345161263,
                            "lon": 55.216237276813565
                        },
                        {
                            "lat": 61.48808256661935,
                            "lon": 55.21630284829568
                        },
                        {
                            "lat": 61.49122169443488,
                            "lon": 55.21636833906991
                        },
                        {
                            "lat": 61.49436083504351,
                            "lon": 55.21643374913557
                        },
                        {
                            "lat": 61.49424649182782,
                            "lon": 55.218228491026466
                        },
                        {
                            "lat": 61.49413213583279,
                            "lon": 55.220023232226765
                        },
                        {
                            "lat": 61.494017767056484,
                            "lon": 55.221817972736446
                        },
                        {
                            "lat": 61.49087820344496,
                            "lon": 55.22175254962805
                        },
                        {
                            "lat": 61.48773865263242,
                            "lon": 55.22168704579501
                        },
                        {
                            "lat": 61.48762398901005,
                            "lon": 55.223481776912
                        },
                        {
                            "lat": 61.48448430998369,
                            "lon": 55.223416187995966
                        },
                        {
                            "lat": 61.48134464378966,
                            "lon": 55.22335051835139
                        },
                        {
                            "lat": 61.47820499044367,
                            "lon": 55.223284767978946
                        },
                        {
                            "lat": 61.47506534996144,
                            "lon": 55.22321893687939
                        },
                        {
                            "lat": 61.474950109357444,
                            "lon": 55.225013649835034
                        },
                        {
                            "lat": 61.471810340718335,
                            "lon": 55.22494773362819
                        },
                        {
                            "lat": 61.47169494618083,
                            "lon": 55.22674244150961
                        },
                        {
                            "lat": 61.468555049388755,
                            "lon": 55.22667644018513
                        },
                        {
                            "lat": 61.46867058497636,
                            "lon": 55.224881736690335
                        },
                        {
                            "lat": 61.465530842147245,
                            "lon": 55.224815659022156
                        },
                        {
                            "lat": 61.462391112246706,
                            "lon": 55.22474950062438
                        },
                        {
                            "lat": 61.45925139529045,
                            "lon": 55.22468326149774
                        },
                        {
                            "lat": 61.45936734105653,
                            "lon": 55.222888570499904
                        },
                        {
                            "lat": 61.45622777808675,
                            "lon": 55.22282225505273
                        },
                        {
                            "lat": 61.45611169129418,
                            "lon": 55.22461694164294
                        },
                        {
                            "lat": 61.45297200027362,
                            "lon": 55.22455054106072
                        },
                        {
                            "lat": 61.44983232224449,
                            "lon": 55.224484059751816
                        }
                    ],
                    [
                        {
                            "lat": 61.495904554136224,
                            "lon": 55.142783504990206
                        },
                        {
                            "lat": 61.496018502700025,
                            "lon": 55.14098873843614
                        },
                        {
                            "lat": 61.492885282031466,
                            "lon": 55.14092343011692
                        },
                        {
                            "lat": 61.4897520740901,
                            "lon": 55.14085804131409
                        },
                        {
                            "lat": 61.489637844876704,
                            "lon": 55.14265279919045
                        },
                        {
                            "lat": 61.492771193141884,
                            "lon": 55.14271819233479
                        },
                        {
                            "lat": 61.495904554136224,
                            "lon": 55.142783504990206
                        }
                    ],
                    [
                        {
                            "lat": 61.47374139495683,
                            "lon": 55.14591409625725
                        },
                        {
                            "lat": 61.47385635140778,
                            "lon": 55.1441193615549
                        },
                        {
                            "lat": 61.470722939517366,
                            "lon": 55.14405348111792
                        },
                        {
                            "lat": 61.47060784272303,
                            "lon": 55.14584821144599
                        },
                        {
                            "lat": 61.47374139495683,
                            "lon": 55.14591409625725
                        }
                    ],
                    [
                        {
                            "lat": 61.499151736080194,
                            "lon": 55.14105396627102
                        },
                        {
                            "lat": 61.499265531620296,
                            "lon": 55.139259194695995
                        },
                        {
                            "lat": 61.49613243855224,
                            "lon": 55.13919397119159
                        },
                        {
                            "lat": 61.496018502700025,
                            "lon": 55.14098873843614
                        },
                        {
                            "lat": 61.499151736080194,
                            "lon": 55.14105396627102
                        }
                    ],
                    [
                        {
                            "lat": 61.50194394038802,
                            "lon": 55.14650343718324
                        },
                        {
                            "lat": 61.50205763366105,
                            "lon": 55.14470866335215
                        },
                        {
                            "lat": 61.50217131424986,
                            "lon": 55.14291388883136
                        },
                        {
                            "lat": 61.49903792784407,
                            "lon": 55.142848737155965
                        },
                        {
                            "lat": 61.49892410691003,
                            "lon": 55.144643507350835
                        },
                        {
                            "lat": 61.49579059285889,
                            "lon": 55.144578270853835
                        },
                        {
                            "lat": 61.49567661886608,
                            "lon": 55.14637303602695
                        },
                        {
                            "lat": 61.49881027327615,
                            "lon": 55.146438276855605
                        },
                        {
                            "lat": 61.49869642694048,
                            "lon": 55.14823304567026
                        },
                        {
                            "lat": 61.50183023442879,
                            "lon": 55.14829821032459
                        },
                        {
                            "lat": 61.50194394038802,
                            "lon": 55.14650343718324
                        }
                    ],
                    [
                        {
                            "lat": 61.508438125092766,
                            "lon": 55.143043950708176
                        },
                        {
                            "lat": 61.50530471333796,
                            "lon": 55.14297896001567
                        },
                        {
                            "lat": 61.505191173096314,
                            "lon": 55.14477373885706
                        },
                        {
                            "lat": 61.50832472520021,
                            "lon": 55.14483873386483
                        },
                        {
                            "lat": 61.508438125092766,
                            "lon": 55.143043950708176
                        }
                    ],
                    [
                        {
                            "lat": 61.49561776753548,
                            "lon": 55.19669154275992
                        },
                        {
                            "lat": 61.49573195755071,
                            "lon": 55.19489679258282
                        },
                        {
                            "lat": 61.4925945075408,
                            "lon": 55.19483143465308
                        },
                        {
                            "lat": 61.49248017672862,
                            "lon": 55.196626180487655
                        },
                        {
                            "lat": 61.49561776753548,
                            "lon": 55.19669154275992
                        }
                    ],
                    [
                        {
                            "lat": 61.49864130914168,
                            "lon": 55.19855157820589
                        },
                        {
                            "lat": 61.49875537109836,
                            "lon": 55.19675682438169
                        },
                        {
                            "lat": 61.49561776753548,
                            "lon": 55.19669154275992
                        },
                        {
                            "lat": 61.49550356476422,
                            "lon": 55.19848629224655
                        },
                        {
                            "lat": 61.49864130914168,
                            "lon": 55.19855157820589
                        }
                    ],
                    [
                        {
                            "lat": 61.50177906626141,
                            "lon": 55.198616783508676
                        },
                        {
                            "lat": 61.49864130914168,
                            "lon": 55.19855157820589
                        },
                        {
                            "lat": 61.49852723444274,
                            "lon": 55.20034633134001
                        },
                        {
                            "lat": 61.50166513239464,
                            "lon": 55.2004115409754
                        },
                        {
                            "lat": 61.50177906626141,
                            "lon": 55.198616783508676
                        }
                    ],
                    [
                        {
                            "lat": 61.50468923732963,
                            "lon": 55.20227143105287
                        },
                        {
                            "lat": 61.50480304307511,
                            "lon": 55.20047666994818
                        },
                        {
                            "lat": 61.50166513239464,
                            "lon": 55.2004115409754
                        },
                        {
                            "lat": 61.50155118579936,
                            "lon": 55.20220629775244
                        },
                        {
                            "lat": 61.50468923732963,
                            "lon": 55.20227143105287
                        }
                    ],
                    [
                        {
                            "lat": 61.525025714431315,
                            "lon": 55.078689837717114
                        },
                        {
                            "lat": 61.52189729463133,
                            "lon": 55.07862532332542
                        },
                        {
                            "lat": 61.5217847683472,
                            "lon": 55.080420144148334
                        },
                        {
                            "lat": 61.52491332794346,
                            "lon": 55.08048466282019
                        },
                        {
                            "lat": 61.525025714431315,
                            "lon": 55.078689837717114
                        }
                    ],
                    [
                        {
                            "lat": 61.53025994333778,
                            "lon": 55.145291444940625
                        },
                        {
                            "lat": 61.53037236073299,
                            "lon": 55.14349663172638
                        },
                        {
                            "lat": 61.52723886074829,
                            "lon": 55.143432204496165
                        },
                        {
                            "lat": 61.527126302990595,
                            "lon": 55.145227013432454
                        },
                        {
                            "lat": 61.53025994333778,
                            "lon": 55.145291444940625
                        }
                    ],
                    [
                        {
                            "lat": 61.52399267520275,
                            "lon": 55.14516250142222
                        },
                        {
                            "lat": 61.524105373321056,
                            "lon": 55.14336769676921
                        },
                        {
                            "lat": 61.52097189846686,
                            "lon": 55.14330310854623
                        },
                        {
                            "lat": 61.52085905998987,
                            "lon": 55.14509790891062
                        },
                        {
                            "lat": 61.52074620892232,
                            "lon": 55.14689270858765
                        },
                        {
                            "lat": 61.52387996450949,
                            "lon": 55.14695730538822
                        },
                        {
                            "lat": 61.52399267520275,
                            "lon": 55.14516250142222
                        }
                    ],
                    [
                        {
                            "lat": 61.53574195453959,
                            "lon": 55.15798379953283
                        },
                        {
                            "lat": 61.538876615486004,
                            "lon": 55.158048019374995
                        },
                        {
                            "lat": 61.538988699300425,
                            "lon": 55.156253198154864
                        },
                        {
                            "lat": 61.53585417883159,
                            "lon": 55.15618898257732
                        },
                        {
                            "lat": 61.53596639059966,
                            "lon": 55.15439416493627
                        },
                        {
                            "lat": 61.539100770606545,
                            "lon": 55.15445837624956
                        },
                        {
                            "lat": 61.54223516311985,
                            "lon": 55.15452250703134
                        },
                        {
                            "lat": 61.542347081471355,
                            "lon": 55.152727680182345
                        },
                        {
                            "lat": 61.5392128294063,
                            "lon": 55.15266355365909
                        },
                        {
                            "lat": 61.539324875701524,
                            "lon": 55.15086873038347
                        },
                        {
                            "lat": 61.53619077657164,
                            "lon": 55.15080452759752
                        },
                        {
                            "lat": 61.53305668995996,
                            "lon": 55.15074024429141
                        },
                        {
                            "lat": 61.53294436280524,
                            "lon": 55.15253505903472
                        },
                        {
                            "lat": 61.532832023114786,
                            "lon": 55.154329873092145
                        },
                        {
                            "lat": 61.52969766816756,
                            "lon": 55.154265500717905
                        },
                        {
                            "lat": 61.52656332577361,
                            "lon": 55.154201047814226
                        },
                        {
                            "lat": 61.526450692631144,
                            "lon": 55.15599585263057
                        },
                        {
                            "lat": 61.526338046917864,
                            "lon": 55.15779065676017
                        },
                        {
                            "lat": 61.529472670239905,
                            "lon": 55.15785511822471
                        },
                        {
                            "lat": 61.53260730611902,
                            "lon": 55.15791949914918
                        },
                        {
                            "lat": 61.53574195453959,
                            "lon": 55.15798379953283
                        }
                    ],
                    [
                        {
                            "lat": 61.53170793632471,
                            "lon": 55.172277975938485
                        },
                        {
                            "lat": 61.53159545861516,
                            "lon": 55.1740727824501
                        },
                        {
                            "lat": 61.53473137202042,
                            "lon": 55.1741371212806
                        },
                        {
                            "lat": 61.53484370911355,
                            "lon": 55.17234231049558
                        },
                        {
                            "lat": 61.53797949444345,
                            "lon": 55.1724065644685
                        },
                        {
                            "lat": 61.541115292298784,
                            "lon": 55.172470737856536
                        },
                        {
                            "lat": 61.54122733564353,
                            "lon": 55.17067591785584
                        },
                        {
                            "lat": 61.54436300540215,
                            "lon": 55.17074000640127
                        },
                        {
                            "lat": 61.544474895648165,
                            "lon": 55.16894518145908
                        },
                        {
                            "lat": 61.541339366480386,
                            "lon": 55.16888109717026
                        },
                        {
                            "lat": 61.538203849818586,
                            "lon": 55.16881693230654
                        },
                        {
                            "lat": 61.535068345678425,
                            "lon": 55.16875268686861
                        },
                        {
                            "lat": 61.53518064515399,
                            "lon": 55.16695787402668
                        },
                        {
                            "lat": 61.535292932094215,
                            "lon": 55.165163060499104
                        },
                        {
                            "lat": 61.535405206500975,
                            "lon": 55.16336824628591
                        },
                        {
                            "lat": 61.5322701365596,
                            "lon": 55.1633039330901
                        },
                        {
                            "lat": 61.532157721614645,
                            "lon": 55.1650987430318
                        },
                        {
                            "lat": 61.529022523684155,
                            "lon": 55.165034345002326
                        },
                        {
                            "lat": 61.52588733831841,
                            "lon": 55.16496986641144
                        },
                        {
                            "lat": 61.525774629722385,
                            "lon": 55.166764667107394
                        },
                        {
                            "lat": 61.52890995563816,
                            "lon": 55.166829149980806
                        },
                        {
                            "lat": 61.52879737502552,
                            "lon": 55.16862395427292
                        },
                        {
                            "lat": 61.5286847818443,
                            "lon": 55.1704187578786
                        },
                        {
                            "lat": 61.531820401477525,
                            "lon": 55.170483168740844
                        },
                        {
                            "lat": 61.53170793632471,
                            "lon": 55.172277975938485
                        }
                    ],
                    [
                        {
                            "lat": 61.528572176092624,
                            "lon": 55.1722135607979
                        },
                        {
                            "lat": 61.5286847818443,
                            "lon": 55.1704187578786
                        },
                        {
                            "lat": 61.52554917478157,
                            "lon": 55.17035426643893
                        },
                        {
                            "lat": 61.52543642843291,
                            "lon": 55.17214906507454
                        },
                        {
                            "lat": 61.528572176092624,
                            "lon": 55.1722135607979
                        }
                    ],
                    [
                        {
                            "lat": 61.55307092593278,
                            "lon": 55.08106171687674
                        },
                        {
                            "lat": 61.55619961004943,
                            "lon": 55.081125432426916
                        },
                        {
                            "lat": 61.55608860926189,
                            "lon": 55.08292029935604
                        },
                        {
                            "lat": 61.559217445577744,
                            "lon": 55.0829839388123
                        },
                        {
                            "lat": 61.559106572269826,
                            "lon": 55.08477880928068
                        },
                        {
                            "lat": 61.56223556078863,
                            "lon": 55.08484237263207
                        },
                        {
                            "lat": 61.56212481499143,
                            "lon": 55.08663724663513
                        },
                        {
                            "lat": 61.56201405685302,
                            "lon": 55.08843211995571
                        },
                        {
                            "lat": 61.56190328637157,
                            "lon": 55.09022699259383
                        },
                        {
                            "lat": 61.56503270687852,
                            "lon": 55.09029048825491
                        },
                        {
                            "lat": 61.56816213971483,
                            "lon": 55.09035390357136
                        },
                        {
                            "lat": 61.56827263039744,
                            "lon": 55.08855902251268
                        },
                        {
                            "lat": 61.56838310876808,
                            "lon": 55.08676414077228
                        },
                        {
                            "lat": 61.56525395571693,
                            "lon": 55.0867007338707
                        },
                        {
                            "lat": 61.565364561646746,
                            "lon": 55.08490585565546
                        },
                        {
                            "lat": 61.56849357482863,
                            "lon": 55.08496925835015
                        },
                        {
                            "lat": 61.568604028580936,
                            "lon": 55.083174375246315
                        },
                        {
                            "lat": 61.57173291421544,
                            "lon": 55.08323769341047
                        },
                        {
                            "lat": 61.57184321582132,
                            "lon": 55.08144280542413
                        },
                        {
                            "lat": 61.574971973904695,
                            "lon": 55.081506039068024
                        },
                        {
                            "lat": 61.575082123395234,
                            "lon": 55.07971114620525
                        },
                        {
                            "lat": 61.57821075392376,
                            "lon": 55.07977429533922
                        },
                        {
                            "lat": 61.57832075133001,
                            "lon": 55.0779793976061
                        },
                        {
                            "lat": 61.578430736482076,
                            "lon": 55.07618449919246
                        },
                        {
                            "lat": 61.581559099653006,
                            "lon": 55.076247559643065
                        },
                        {
                            "lat": 61.58166893276921,
                            "lon": 55.074452656365516
                        },
                        {
                            "lat": 61.58479716839154,
                            "lon": 55.07451563233255
                        },
                        {
                            "lat": 61.5846874750607,
                            "lon": 55.07631053978815
                        },
                        {
                            "lat": 61.5845777695086,
                            "lon": 55.07810544656397
                        },
                        {
                            "lat": 61.587706296940326,
                            "lon": 55.07816835057591
                        },
                        {
                            "lat": 61.58759671898343,
                            "lon": 55.0799632608454
                        },
                        {
                            "lat": 61.587487128817095,
                            "lon": 55.08175817043548
                        },
                        {
                            "lat": 61.59061594811223,
                            "lon": 55.08182100247189
                        },
                        {
                            "lat": 61.59050648558582,
                            "lon": 55.083615915551434
                        },
                        {
                            "lat": 61.59039701086183,
                            "lon": 55.0854108279519
                        },
                        {
                            "lat": 61.58726791184871,
                            "lon": 55.08534798757727
                        },
                        {
                            "lat": 61.584138825050566,
                            "lon": 55.08528506686918
                        },
                        {
                            "lat": 61.58424857950624,
                            "lon": 55.0834901628126
                        },
                        {
                            "lat": 61.58111964480168,
                            "lon": 55.08342716595158
                        },
                        {
                            "lat": 61.57799072234133,
                            "lon": 55.083364088763766
                        },
                        {
                            "lat": 61.57788068816143,
                            "lon": 55.0851589844552
                        },
                        {
                            "lat": 61.57777064171991,
                            "lon": 55.08695387946605
                        },
                        {
                            "lat": 61.58089984391818,
                            "lon": 55.08701696502471
                        },
                        {
                            "lat": 61.580789925105464,
                            "lon": 55.088811863540926
                        },
                        {
                            "lat": 61.577660583014904,
                            "lon": 55.0887487737963
                        },
                        {
                            "lat": 61.57755051204459,
                            "lon": 55.09054366744594
                        },
                        {
                            "lat": 61.577440428807066,
                            "lon": 55.092338560414966
                        },
                        {
                            "lat": 61.5773303333005,
                            "lon": 55.09413345270339
                        },
                        {
                            "lat": 61.57722022552299,
                            "lon": 55.095928344311154
                        },
                        {
                            "lat": 61.57409033598066,
                            "lon": 55.09586515745551
                        },
                        {
                            "lat": 61.57398007596222,
                            "lon": 55.097660044189475
                        },
                        {
                            "lat": 61.57386980365358,
                            "lon": 55.099454930242395
                        },
                        {
                            "lat": 61.57375951905288,
                            "lon": 55.10124981561428
                        },
                        {
                            "lat": 61.57364922215823,
                            "lon": 55.10304470030512
                        },
                        {
                            "lat": 61.57051878494574,
                            "lon": 55.1029814162906
                        },
                        {
                            "lat": 61.56738836004462,
                            "lon": 55.10291805189283
                        },
                        {
                            "lat": 61.567277770798974,
                            "lon": 55.1047129274973
                        },
                        {
                            "lat": 61.56716716922456,
                            "lon": 55.10650780241994
                        },
                        {
                            "lat": 61.56705655531947,
                            "lon": 55.108302676660756
                        },
                        {
                            "lat": 61.56392572262421,
                            "lon": 55.108239219247395
                        },
                        {
                            "lat": 61.56381495631509,
                            "lon": 55.1100340885946
                        },
                        {
                            "lat": 61.56370417765601,
                            "lon": 55.11182895725956
                        },
                        {
                            "lat": 61.56359338664503,
                            "lon": 55.1136238252423
                        },
                        {
                            "lat": 61.560462146042354,
                            "lon": 55.1135602747787
                        },
                        {
                            "lat": 61.56035120256132,
                            "lon": 55.115355137860966
                        },
                        {
                            "lat": 61.56024024670911,
                            "lon": 55.11715000026059
                        },
                        {
                            "lat": 61.55710873823126,
                            "lon": 55.11708636093641
                        },
                        {
                            "lat": 61.556997629860355,
                            "lon": 55.11888121842908
                        },
                        {
                            "lat": 61.56012927848381,
                            "lon": 55.11894486197756
                        },
                        {
                            "lat": 61.56001829788356,
                            "lon": 55.120739723011845
                        },
                        {
                            "lat": 61.55990730490648,
                            "lon": 55.122534583363475
                        },
                        {
                            "lat": 61.559796299550705,
                            "lon": 55.124329443032416
                        },
                        {
                            "lat": 61.55666423039694,
                            "lon": 55.12426578680881
                        },
                        {
                            "lat": 61.556553072452616,
                            "lon": 55.126060641569254
                        },
                        {
                            "lat": 61.55644190211023,
                            "lon": 55.1278554956466
                        },
                        {
                            "lat": 61.55957425169545,
                            "lon": 55.1279191603222
                        },
                        {
                            "lat": 61.562706613665064,
                            "lon": 55.127982744542074
                        },
                        {
                            "lat": 61.56259571140285,
                            "lon": 55.12977760638413
                        },
                        {
                            "lat": 61.56248479677,
                            "lon": 55.13157246754382
                        },
                        {
                            "lat": 61.56561745160974,
                            "lon": 55.13163597973944
                        },
                        {
                            "lat": 61.56550666487847,
                            "lon": 55.13343084443345
                        },
                        {
                            "lat": 61.56539586578839,
                            "lon": 55.13522570844546
                        },
                        {
                            "lat": 61.565285054337636,
                            "lon": 55.137020571775444
                        },
                        {
                            "lat": 61.56215197862883,
                            "lon": 55.136957046928714
                        },
                        {
                            "lat": 61.56204101449462,
                            "lon": 55.1387519053589
                        },
                        {
                            "lat": 61.56517423052433,
                            "lon": 55.138815434423414
                        },
                        {
                            "lat": 61.568307458918454,
                            "lon": 55.13887888299886
                        },
                        {
                            "lat": 61.56819676307909,
                            "lon": 55.14067374917762
                        },
                        {
                            "lat": 61.56808605488902,
                            "lon": 55.14246861467474
                        },
                        {
                            "lat": 61.57121957632812,
                            "lon": 55.14253199117579
                        },
                        {
                            "lat": 61.57110899615696,
                            "lon": 55.144326860199484
                        },
                        {
                            "lat": 61.5742426703064,
                            "lon": 55.14439016040231
                        },
                        {
                            "lat": 61.57413221818537,
                            "lon": 55.14618503294802
                        },
                        {
                            "lat": 61.57726604504888,
                            "lon": 55.14624825684165
                        },
                        {
                            "lat": 61.57737635677904,
                            "lon": 55.14445338009799
                        },
                        {
                            "lat": 61.58051005555932,
                            "lon": 55.14451651928582
                        },
                        {
                            "lat": 61.58039988422187,
                            "lon": 55.146311400222125
                        },
                        {
                            "lat": 61.58028970059057,
                            "lon": 55.148106280478224
                        },
                        {
                            "lat": 61.58342369246764,
                            "lon": 55.14816934753249
                        },
                        {
                            "lat": 61.58655769662483,
                            "lon": 55.1482323340669
                        },
                        {
                            "lat": 61.58969171304648,
                            "lon": 55.1482952400807
                        },
                        {
                            "lat": 61.58958193840257,
                            "lon": 55.15009013220476
                        },
                        {
                            "lat": 61.592716107504515,
                            "lon": 55.15015296186936
                        },
                        {
                            "lat": 61.59282574171699,
                            "lon": 55.14835806557327
                        },
                        {
                            "lat": 61.59595978262076,
                            "lon": 55.14842081054389
                        },
                        {
                            "lat": 61.59909383574212,
                            "lon": 55.148483474991885
                        },
                        {
                            "lat": 61.59920317688416,
                            "lon": 55.14668856968972
                        },
                        {
                            "lat": 61.60233710178612,
                            "lon": 55.14675114945874
                        },
                        {
                            "lat": 61.6054710388726,
                            "lon": 55.14681364870908
                        },
                        {
                            "lat": 61.60860498812797,
                            "lon": 55.14687606744011
                        },
                        {
                            "lat": 61.61173894953657,
                            "lon": 55.14693840565112
                        },
                        {
                            "lat": 61.61487292308284,
                            "lon": 55.14700066334143
                        },
                        {
                            "lat": 61.61498154997743,
                            "lon": 55.14520573663815
                        },
                        {
                            "lat": 61.618115395230696,
                            "lon": 55.145267909678445
                        },
                        {
                            "lat": 61.61822386960576,
                            "lon": 55.1434729781708
                        },
                        {
                            "lat": 61.621357586562354,
                            "lon": 55.14353506657146
                        },
                        {
                            "lat": 61.62449131560604,
                            "lon": 55.143597074460054
                        },
                        {
                            "lat": 61.62762505672121,
                            "lon": 55.143659001835935
                        },
                        {
                            "lat": 61.627517003555326,
                            "lon": 55.145453945696374
                        },
                        {
                            "lat": 61.627408938331776,
                            "lon": 55.1472488888822
                        },
                        {
                            "lat": 61.624274916391215,
                            "lon": 55.147186953281434
                        },
                        {
                            "lat": 61.62416669867199,
                            "lon": 55.148981891679625
                        },
                        {
                            "lat": 61.62730086104872,
                            "lon": 55.14904383139339
                        },
                        {
                            "lat": 61.630435035486755,
                            "lon": 55.14910569057774
                        },
                        {
                            "lat": 61.633569221970525,
                            "lon": 55.14916746923201
                        },
                        {
                            "lat": 61.63367701837583,
                            "lon": 55.14737251851082
                        },
                        {
                            "lat": 61.63681107644806,
                            "lon": 55.14743421253732
                        },
                        {
                            "lat": 61.636918720399066,
                            "lon": 55.145639257045616
                        },
                        {
                            "lat": 61.633784802752835,
                            "lon": 55.14557756711572
                        },
                        {
                            "lat": 61.633892575103445,
                            "lon": 55.14378261504677
                        },
                        {
                            "lat": 61.637026352339284,
                            "lon": 55.143844300880396
                        },
                        {
                            "lat": 61.64016014158409,
                            "lon": 55.14390590619859
                        },
                        {
                            "lat": 61.640267621118724,
                            "lon": 55.142110945269316
                        },
                        {
                            "lat": 61.64340128195848,
                            "lon": 55.142172465986185
                        },
                        {
                            "lat": 61.64350860911879,
                            "lon": 55.14037750029891
                        },
                        {
                            "lat": 61.64664214154986,
                            "lon": 55.14043893642482
                        },
                        {
                            "lat": 61.646749316367064,
                            "lon": 55.138643965985636
                        },
                        {
                            "lat": 61.64988272038581,
                            "lon": 55.138705317530935
                        },
                        {
                            "lat": 61.64998974289115,
                            "lon": 55.136910342345914
                        },
                        {
                            "lat": 61.650096753457305,
                            "lon": 55.13511536648887
                        },
                        {
                            "lat": 61.64696363013335,
                            "lon": 55.13505402309013
                        },
                        {
                            "lat": 61.647070769086035,
                            "lon": 55.13325905063383
                        },
                        {
                            "lat": 61.64393779803724,
                            "lon": 55.133197630822394
                        },
                        {
                            "lat": 61.64404506534526,
                            "lon": 55.131402661771475
                        },
                        {
                            "lat": 61.647177896087506,
                            "lon": 55.13146407750519
                        },
                        {
                            "lat": 61.65031073877914,
                            "lon": 55.13152541275883
                        },
                        {
                            "lat": 61.65041771353847,
                            "lon": 55.12973043488583
                        },
                        {
                            "lat": 61.647285011139594,
                            "lon": 55.12966910370421
                        },
                        {
                            "lat": 61.64415232068828,
                            "lon": 55.12960769204781
                        },
                        {
                            "lat": 61.64425956406812,
                            "lon": 55.127812721651466
                        },
                        {
                            "lat": 61.6473921142441,
                            "lon": 55.1278741292309
                        },
                        {
                            "lat": 61.64749920540283,
                            "lon": 55.12607915408527
                        },
                        {
                            "lat": 61.64760628461763,
                            "lon": 55.124284178267324
                        },
                        {
                            "lat": 61.644474014945516,
                            "lon": 55.124222778840746
                        },
                        {
                            "lat": 61.644581222446725,
                            "lon": 55.12242780642638
                        },
                        {
                            "lat": 61.64771335189032,
                            "lon": 55.122489201777064
                        },
                        {
                            "lat": 61.64782040722265,
                            "lon": 55.12069422461453
                        },
                        {
                            "lat": 61.6509524083918,
                            "lon": 55.12075553544157
                        },
                        {
                            "lat": 61.65105931158649,
                            "lon": 55.11896055353691
                        },
                        {
                            "lat": 61.6479274506165,
                            "lon": 55.118899246779705
                        },
                        {
                            "lat": 61.64803448207366,
                            "lon": 55.1171042682726
                        },
                        {
                            "lat": 61.64814150159594,
                            "lon": 55.11530928909322
                        },
                        {
                            "lat": 61.651273082214374,
                            "lon": 55.11537058771188
                        },
                        {
                            "lat": 61.651166202860075,
                            "lon": 55.11716557096034
                        },
                        {
                            "lat": 61.654297935565786,
                            "lon": 55.11722679320995
                        },
                        {
                            "lat": 61.65419118447758,
                            "lon": 55.119021779850655
                        },
                        {
                            "lat": 61.65732306927417,
                            "lon": 55.119082925720235
                        },
                        {
                            "lat": 61.660454965960675,
                            "lon": 55.11914399114504
                        },
                        {
                            "lat": 61.6605614366727,
                            "lon": 55.11734899639205
                        },
                        {
                            "lat": 61.66369320504272,
                            "lon": 55.117409977323234
                        },
                        {
                            "lat": 61.663586874521485,
                            "lon": 55.11920497612438
                        },
                        {
                            "lat": 61.66348053214152,
                            "lon": 55.12099997425506
                        },
                        {
                            "lat": 61.66034848337432,
                            "lon": 55.120938985227205
                        },
                        {
                            "lat": 61.66024198891182,
                            "lon": 55.12273397863853
                        },
                        {
                            "lat": 61.663374177901034,
                            "lon": 55.12279497171524
                        },
                        {
                            "lat": 61.66650637875256,
                            "lon": 55.12285588433518
                        },
                        {
                            "lat": 61.66661259276927,
                            "lon": 55.121060882831436
                        },
                        {
                            "lat": 61.669744665241964,
                            "lon": 55.12112171095571
                        },
                        {
                            "lat": 61.66985072720372,
                            "lon": 55.11932670474397
                        },
                        {
                            "lat": 61.67298267129398,
                            "lon": 55.11938744838292
                        },
                        {
                            "lat": 61.67308858123201,
                            "lon": 55.117592437469305
                        },
                        {
                            "lat": 61.67319447935989,
                            "lon": 55.11579742588632
                        },
                        {
                            "lat": 61.6763261548817,
                            "lon": 55.11585808102381
                        },
                        {
                            "lat": 61.67643190103439,
                            "lon": 55.114063064745345
                        },
                        {
                            "lat": 61.67956344818035,
                            "lon": 55.11412363542395
                        },
                        {
                            "lat": 61.679669042389065,
                            "lon": 55.112328614456054
                        },
                        {
                            "lat": 61.682800461155644,
                            "lon": 55.11238910068609
                        },
                        {
                            "lat": 61.68290590345152,
                            "lon": 55.110594075034804
                        },
                        {
                            "lat": 61.68301133399115,
                            "lon": 55.10879904871528
                        },
                        {
                            "lat": 61.68311675277634,
                            "lon": 55.10700402172749
                        },
                        {
                            "lat": 61.686247762924665,
                            "lon": 55.10706441550157
                        },
                        {
                            "lat": 61.689378784810074,
                            "lon": 55.10712472886217
                        },
                        {
                            "lat": 61.69250981841697,
                            "lon": 55.10718496180866
                        },
                        {
                            "lat": 61.69240481996666,
                            "lon": 55.10898000080659
                        },
                        {
                            "lat": 61.69553600539469,
                            "lon": 55.10904015733103
                        },
                        {
                            "lat": 61.69543113536743,
                            "lon": 55.11083519965486
                        },
                        {
                            "lat": 61.698562472620125,
                            "lon": 55.11089527974631
                        },
                        {
                            "lat": 61.70169382155113,
                            "lon": 55.11095527941103
                        },
                        {
                            "lat": 61.70158922012797,
                            "lon": 55.11275032903892
                        },
                        {
                            "lat": 61.69845773104707,
                            "lon": 55.11269032539142
                        },
                        {
                            "lat": 61.69835297779392,
                            "lon": 55.114485370370055
                        },
                        {
                            "lat": 61.701484607040314,
                            "lon": 55.114545378000706
                        },
                        {
                            "lat": 61.70461624795299,
                            "lon": 55.11460530519331
                        },
                        {
                            "lat": 61.7047207208734,
                            "lon": 55.112810252253716
                        },
                        {
                            "lat": 61.70785223326773,
                            "lon": 55.112870095035156
                        },
                        {
                            "lat": 61.70795655438577,
                            "lon": 55.11107503745764
                        },
                        {
                            "lat": 61.70806086387223,
                            "lon": 55.109279979214726
                        },
                        {
                            "lat": 61.71119210760507,
                            "lon": 55.10933973362889
                        },
                        {
                            "lat": 61.711087938258224,
                            "lon": 55.11113479583825
                        },
                        {
                            "lat": 61.714219333746655,
                            "lon": 55.11119447378952
                        },
                        {
                            "lat": 61.71735074083548,
                            "lon": 55.11125407131079
                        },
                        {
                            "lat": 61.71724684018836,
                            "lon": 55.11304914077272
                        },
                        {
                            "lat": 61.717142927954654,
                            "lon": 55.11484420957032
                        },
                        {
                            "lat": 61.71703900413262,
                            "lon": 55.11663927770355
                        },
                        {
                            "lat": 61.71390717652084,
                            "lon": 55.11657966831275
                        },
                        {
                            "lat": 61.71380310090321,
                            "lon": 55.1183747318244
                        },
                        {
                            "lat": 61.710671144693364,
                            "lon": 55.11831503802509
                        },
                        {
                            "lat": 61.71056691724896,
                            "lon": 55.120110096909116
                        },
                        {
                            "lat": 61.71046267817974,
                            "lon": 55.12190515512806
                        },
                        {
                            "lat": 61.713594914843874,
                            "lon": 55.121964856853516
                        },
                        {
                            "lat": 61.71672716311898,
                            "lon": 55.122024478117005
                        },
                        {
                            "lat": 61.71683112171656,
                            "lon": 55.120229411976894
                        },
                        {
                            "lat": 61.719963241348566,
                            "lon": 55.120288948825184
                        },
                        {
                            "lat": 61.72006704812967,
                            "lon": 55.11849387806847
                        },
                        {
                            "lat": 61.723199039115066,
                            "lon": 55.11855333051192
                        },
                        {
                            "lat": 61.726331041661105,
                            "lon": 55.118612702502155
                        },
                        {
                            "lat": 61.726434556446144,
                            "lon": 55.11681762319362
                        },
                        {
                            "lat": 61.729566430324724,
                            "lon": 55.11687691079404
                        },
                        {
                            "lat": 61.729669793369496,
                            "lon": 55.11508182688667
                        },
                        {
                            "lat": 61.732801538577085,
                            "lon": 55.11514103010768
                        },
                        {
                            "lat": 61.732904749912805,
                            "lon": 55.11334594160749
                        },
                        {
                            "lat": 61.7360363664459,
                            "lon": 55.11340506045944
                        },
                        {
                            "lat": 61.73613942610376,
                            "lon": 55.11160996737247
                        },
                        {
                            "lat": 61.736242474270334,
                            "lon": 55.10981487362327
                        },
                        {
                            "lat": 61.73634551094737,
                            "lon": 55.10801977921185
                        },
                        {
                            "lat": 61.73321431487528,
                            "lon": 55.107960672131405
                        },
                        {
                            "lat": 61.73331748018702,
                            "lon": 55.10616558098089
                        },
                        {
                            "lat": 61.73018643573906,
                            "lon": 55.106106397405696
                        },
                        {
                            "lat": 61.730289729654345,
                            "lon": 55.10431130952074
                        },
                        {
                            "lat": 61.73342063399714,
                            "lon": 55.104370489167835
                        },
                        {
                            "lat": 61.73352377630737,
                            "lon": 55.10257539669223
                        },
                        {
                            "lat": 61.733626907119486,
                            "lon": 55.10078030355406
                        },
                        {
                            "lat": 61.7337300264352,
                            "lon": 55.09898520975339
                        },
                        {
                            "lat": 61.73383313425627,
                            "lon": 55.097190115290154
                        },
                        {
                            "lat": 61.73393623058445,
                            "lon": 55.0953950201644
                        },
                        {
                            "lat": 61.73403931542148,
                            "lon": 55.093599924376164
                        },
                        {
                            "lat": 61.737169390949724,
                            "lon": 55.09365900008136
                        },
                        {
                            "lat": 61.73706644612618,
                            "lon": 55.09545409979031
                        },
                        {
                            "lat": 61.736963489827055,
                            "lon": 55.09724919883706
                        },
                        {
                            "lat": 61.740093856875134,
                            "lon": 55.09730820199205
                        },
                        {
                            "lat": 61.74019667314349,
                            "lon": 55.095513099029574
                        },
                        {
                            "lat": 61.740299477951815,
                            "lon": 55.09371799540531
                        },
                        {
                            "lat": 61.74342957641216,
                            "lon": 55.093776910347344
                        },
                        {
                            "lat": 61.74655968631525,
                            "lon": 55.09383574490683
                        },
                        {
                            "lat": 61.746662199660776,
                            "lon": 55.09204063280669
                        },
                        {
                            "lat": 61.746764701580894,
                            "lon": 55.09024552004546
                        },
                        {
                            "lat": 61.746867192077325,
                            "lon": 55.08845040662312
                        },
                        {
                            "lat": 61.74696967115183,
                            "lon": 55.0866552925397
                        },
                        {
                            "lat": 61.74707213880614,
                            "lon": 55.08486017779521
                        },
                        {
                            "lat": 61.74717459504197,
                            "lon": 55.08306506238966
                        },
                        {
                            "lat": 61.74727703986104,
                            "lon": 55.08126994632303
                        },
                        {
                            "lat": 61.74737947326512,
                            "lon": 55.079474829595355
                        },
                        {
                            "lat": 61.74748189525591,
                            "lon": 55.077679712206624
                        },
                        {
                            "lat": 61.747584305835154,
                            "lon": 55.07588459415686
                        },
                        {
                            "lat": 61.74768670500461,
                            "lon": 55.074089475446065
                        },
                        {
                            "lat": 61.74778909276598,
                            "lon": 55.07229435607425
                        },
                        {
                            "lat": 61.74789146912097,
                            "lon": 55.07049923604141
                        },
                        {
                            "lat": 61.74799383407137,
                            "lon": 55.068704115347565
                        },
                        {
                            "lat": 61.74809618761888,
                            "lon": 55.066908993992726
                        },
                        {
                            "lat": 61.748198529765226,
                            "lon": 55.065113871976884
                        },
                        {
                            "lat": 61.74830086051212,
                            "lon": 55.06331874930006
                        },
                        {
                            "lat": 61.748403179861334,
                            "lon": 55.06152362596225
                        },
                        {
                            "lat": 61.74850548781456,
                            "lon": 55.05972850196346
                        },
                        {
                            "lat": 61.74860778437356,
                            "lon": 55.05793337730372
                        },
                        {
                            "lat": 61.74548047157866,
                            "lon": 55.05787462076528
                        },
                        {
                            "lat": 61.74235317019179,
                            "lon": 55.05781578395069
                        },
                        {
                            "lat": 61.73922588022846,
                            "lon": 55.05775686686056
                        },
                        {
                            "lat": 61.7360986017042,
                            "lon": 55.05769786949555
                        },
                        {
                            "lat": 61.732971334634534,
                            "lon": 55.05763879185628
                        },
                        {
                            "lat": 61.729844079034976,
                            "lon": 55.05757963394342
                        },
                        {
                            "lat": 61.72671683492104,
                            "lon": 55.05752039575758
                        },
                        {
                            "lat": 61.72358960230822,
                            "lon": 55.05746107729942
                        },
                        {
                            "lat": 61.72046238121209,
                            "lon": 55.0574016785696
                        },
                        {
                            "lat": 61.71733517164811,
                            "lon": 55.05734219956874
                        },
                        {
                            "lat": 61.714207973631844,
                            "lon": 55.05728264029748
                        },
                        {
                            "lat": 61.71108078717876,
                            "lon": 55.057223000756494
                        },
                        {
                            "lat": 61.707953612304394,
                            "lon": 55.057163280946405
                        },
                        {
                            "lat": 61.70482644902429,
                            "lon": 55.05710348086788
                        },
                        {
                            "lat": 61.701699297353905,
                            "lon": 55.057043600521574
                        },
                        {
                            "lat": 61.6985721573088,
                            "lon": 55.05698363990809
                        },
                        {
                            "lat": 61.69544502890447,
                            "lon": 55.05692359902812
                        },
                        {
                            "lat": 61.692317912156426,
                            "lon": 55.05686347788232
                        },
                        {
                            "lat": 61.68919080708019,
                            "lon": 55.056803276471335
                        },
                        {
                            "lat": 61.68606371369126,
                            "lon": 55.05674299479579
                        },
                        {
                            "lat": 61.68293663200518,
                            "lon": 55.05668263285638
                        },
                        {
                            "lat": 61.67980956203742,
                            "lon": 55.05662219065373
                        },
                        {
                            "lat": 61.67668250380353,
                            "lon": 55.05656166818851
                        },
                        {
                            "lat": 61.673555457319,
                            "lon": 55.05650106546138
                        },
                        {
                            "lat": 61.67042842259933,
                            "lon": 55.05644038247299
                        },
                        {
                            "lat": 61.66730139966005,
                            "lon": 55.056379619223996
                        },
                        {
                            "lat": 61.66417438851667,
                            "lon": 55.05631877571508
                        },
                        {
                            "lat": 61.661047389184695,
                            "lon": 55.05625785194686
                        },
                        {
                            "lat": 61.65792040167965,
                            "lon": 55.05619684792005
                        },
                        {
                            "lat": 61.654793426016994,
                            "lon": 55.05613576363526
                        },
                        {
                            "lat": 61.6516664622123,
                            "lon": 55.05607459909318
                        },
                        {
                            "lat": 61.64853951028104,
                            "lon": 55.056013354294485
                        },
                        {
                            "lat": 61.64541257023873,
                            "lon": 55.05595202923982
                        },
                        {
                            "lat": 61.64228564210087,
                            "lon": 55.05589062392985
                        },
                        {
                            "lat": 61.63915872588297,
                            "lon": 55.055829138365254
                        },
                        {
                            "lat": 61.636031821600554,
                            "lon": 55.055767572546685
                        },
                        {
                            "lat": 61.6329049292691,
                            "lon": 55.05570592647483
                        },
                        {
                            "lat": 61.62977804890414,
                            "lon": 55.05564420015033
                        },
                        {
                            "lat": 61.62665118052116,
                            "lon": 55.05558239357389
                        },
                        {
                            "lat": 61.62654343666086,
                            "lon": 55.05737736228465
                        },
                        {
                            "lat": 61.623416440636774,
                            "lon": 55.05731547135198
                        },
                        {
                            "lat": 61.623524324135694,
                            "lon": 55.05552050674616
                        },
                        {
                            "lat": 61.620397479763206,
                            "lon": 55.05545853966781
                        },
                        {
                            "lat": 61.617270647419225,
                            "lon": 55.055396492339504
                        },
                        {
                            "lat": 61.61414382711926,
                            "lon": 55.05533436476193
                        },
                        {
                            "lat": 61.61101701887879,
                            "lon": 55.05527215693577
                        },
                        {
                            "lat": 61.607890222713344,
                            "lon": 55.055209868861695
                        },
                        {
                            "lat": 61.60476343863843,
                            "lon": 55.055147500540365
                        },
                        {
                            "lat": 61.60163666666952,
                            "lon": 55.05508505197248
                        },
                        {
                            "lat": 61.59850990682214,
                            "lon": 55.055022523158684
                        },
                        {
                            "lat": 61.595383159111776,
                            "lon": 55.0549599140997
                        },
                        {
                            "lat": 61.59225642355395,
                            "lon": 55.05489722479614
                        },
                        {
                            "lat": 61.58912970016414,
                            "lon": 55.05483445524876
                        },
                        {
                            "lat": 61.58600298895787,
                            "lon": 55.05477160545822
                        },
                        {
                            "lat": 61.58287628995063,
                            "lon": 55.05470867542516
                        },
                        {
                            "lat": 61.57974960315792,
                            "lon": 55.05464566515031
                        },
                        {
                            "lat": 61.57662292859523,
                            "lon": 55.054582574634345
                        },
                        {
                            "lat": 61.57349626627808,
                            "lon": 55.05451940387793
                        },
                        {
                            "lat": 61.570369616221946,
                            "lon": 55.054456152881755
                        },
                        {
                            "lat": 61.567242978442344,
                            "lon": 55.05439282164652
                        },
                        {
                            "lat": 61.567132581762465,
                            "lon": 55.0561877114557
                        },
                        {
                            "lat": 61.567022172788704,
                            "lon": 55.05798260058296
                        },
                        {
                            "lat": 61.56691175151919,
                            "lon": 55.059777489028306
                        },
                        {
                            "lat": 61.56680131795205,
                            "lon": 55.0615723767917
                        },
                        {
                            "lat": 61.56367413395712,
                            "lon": 55.06150894849263
                        },
                        {
                            "lat": 61.560546962277186,
                            "lon": 55.06144543993461
                        },
                        {
                            "lat": 61.560436237081234,
                            "lon": 55.06324031859614
                        },
                        {
                            "lat": 61.56032549955292,
                            "lon": 55.06503519657498
                        },
                        {
                            "lat": 61.56021474969036,
                            "lon": 55.06683007387111
                        },
                        {
                            "lat": 61.56334234040802,
                            "lon": 55.06689359506812
                        },
                        {
                            "lat": 61.566469943446265,
                            "lon": 55.066957035990264
                        },
                        {
                            "lat": 61.56635946067001,
                            "lon": 55.06875192102584
                        },
                        {
                            "lat": 61.56624896558678,
                            "lon": 55.070546805379436
                        },
                        {
                            "lat": 61.56937686037363,
                            "lon": 55.07061017443265
                        },
                        {
                            "lat": 61.56926649272663,
                            "lon": 55.072405062308114
                        },
                        {
                            "lat": 61.56613845819475,
                            "lon": 55.072341689051015
                        },
                        {
                            "lat": 61.56602793849202,
                            "lon": 55.07413657204062
                        },
                        {
                            "lat": 61.56591740647674,
                            "lon": 55.0759314543482
                        },
                        {
                            "lat": 61.56278910472265,
                            "lon": 55.07586799238015
                        },
                        {
                            "lat": 61.55966081529848,
                            "lon": 55.075804450110645
                        },
                        {
                            "lat": 61.55954999139118,
                            "lon": 55.07759932331026
                        },
                        {
                            "lat": 61.5564215745265,
                            "lon": 55.07753569651908
                        },
                        {
                            "lat": 61.55329317002467,
                            "lon": 55.07747198942255
                        },
                        {
                            "lat": 61.553182054168865,
                            "lon": 55.07926685349141
                        },
                        {
                            "lat": 61.55005352224767,
                            "lon": 55.07920306185827
                        },
                        {
                            "lat": 61.54994225419828,
                            "lon": 55.08099792101126
                        },
                        {
                            "lat": 61.55307092593278,
                            "lon": 55.08106171687674
                        }
                    ],
                    [
                        {
                            "lat": 61.55273746692407,
                            "lon": 55.086446302931236
                        },
                        {
                            "lat": 61.555866570579454,
                            "lon": 55.0865100311647
                        },
                        {
                            "lat": 61.5557555326808,
                            "lon": 55.088304896044214
                        },
                        {
                            "lat": 61.558884788587605,
                            "lon": 55.08836854816893
                        },
                        {
                            "lat": 61.55899568660707,
                            "lon": 55.08657367906623
                        },
                        {
                            "lat": 61.559106572269826,
                            "lon": 55.08477880928068
                        },
                        {
                            "lat": 61.55597759610586,
                            "lon": 55.08471516560198
                        },
                        {
                            "lat": 61.55284863231226,
                            "lon": 55.084651441596655
                        },
                        {
                            "lat": 61.55273746692407,
                            "lon": 55.086446302931236
                        }
                    ],
                    [
                        {
                            "lat": 61.53686363411126,
                            "lon": 55.140035599129156
                        },
                        {
                            "lat": 61.53697573324963,
                            "lon": 55.13824077531861
                        },
                        {
                            "lat": 61.53384262920219,
                            "lon": 55.13817652188349
                        },
                        {
                            "lat": 61.53373038974435,
                            "lon": 55.139971341427895
                        },
                        {
                            "lat": 61.53686363411126,
                            "lon": 55.140035599129156
                        }
                    ],
                    [
                        {
                            "lat": 61.54615175156193,
                            "lon": 55.142022725193804
                        },
                        {
                            "lat": 61.54626344219042,
                            "lon": 55.14022788930112
                        },
                        {
                            "lat": 61.54313016034992,
                            "lon": 55.14016387306646
                        },
                        {
                            "lat": 61.53999689098499,
                            "lon": 55.14009977634223
                        },
                        {
                            "lat": 61.53686363411126,
                            "lon": 55.140035599129156
                        },
                        {
                            "lat": 61.5367515224661,
                            "lon": 55.141830422254245
                        },
                        {
                            "lat": 61.53988491967683,
                            "lon": 55.14189460372852
                        },
                        {
                            "lat": 61.54301832938062,
                            "lon": 55.141958704708614
                        },
                        {
                            "lat": 61.54290648593386,
                            "lon": 55.14375353566606
                        },
                        {
                            "lat": 61.546040048471546,
                            "lon": 55.143817560402155
                        },
                        {
                            "lat": 61.54615175156193,
                            "lon": 55.142022725193804
                        }
                    ],
                    [
                        {
                            "lat": 61.55230721092262,
                            "lon": 55.14394536837091
                        },
                        {
                            "lat": 61.5524186332945,
                            "lon": 55.14215052467673
                        },
                        {
                            "lat": 61.54928518620509,
                            "lon": 55.14208666518341
                        },
                        {
                            "lat": 61.549173623473,
                            "lon": 55.14388150463735
                        },
                        {
                            "lat": 61.55230721092262,
                            "lon": 55.14394536837091
                        }
                    ],
                    [
                        {
                            "lat": 61.54816899849027,
                            "lon": 55.16003502894352
                        },
                        {
                            "lat": 61.54503415956126,
                            "lon": 55.159971046481026
                        },
                        {
                            "lat": 61.54492233174789,
                            "lon": 55.16176587484555
                        },
                        {
                            "lat": 61.548057311191464,
                            "lon": 55.16182986155743
                        },
                        {
                            "lat": 61.54816899849027,
                            "lon": 55.16003502894352
                        }
                    ],
                    [
                        {
                            "lat": 61.55074599143905,
                            "lon": 55.16907310830917
                        },
                        {
                            "lat": 61.550857588038625,
                            "lon": 55.16727827418604
                        },
                        {
                            "lat": 61.547722174484846,
                            "lon": 55.16721435529471
                        },
                        {
                            "lat": 61.547610437306275,
                            "lon": 55.16900918517227
                        },
                        {
                            "lat": 61.55074599143905,
                            "lon": 55.16907310830917
                        }
                    ],
                    [
                        {
                            "lat": 61.55365860866143,
                            "lon": 55.172726625545764
                        },
                        {
                            "lat": 61.55377008956878,
                            "lon": 55.17093178854909
                        },
                        {
                            "lat": 61.55063438238042,
                            "lon": 55.170867941748604
                        },
                        {
                            "lat": 61.55052276086087,
                            "lon": 55.172662774504275
                        },
                        {
                            "lat": 61.55365860866143,
                            "lon": 55.172726625545764
                        }
                    ],
                    [
                        {
                            "lat": 61.54727515091483,
                            "lon": 55.17439367070034
                        },
                        {
                            "lat": 61.54738692552315,
                            "lon": 55.1725988428751
                        },
                        {
                            "lat": 61.54425110266391,
                            "lon": 55.17253483065897
                        },
                        {
                            "lat": 61.54413918743152,
                            "lon": 55.17432965423214
                        },
                        {
                            "lat": 61.54727515091483,
                            "lon": 55.17439367070034
                        }
                    ],
                    [
                        {
                            "lat": 61.54066699380291,
                            "lon": 55.17965001101023
                        },
                        {
                            "lat": 61.54380336675055,
                            "lon": 55.179714120844494
                        },
                        {
                            "lat": 61.54391531947674,
                            "lon": 55.17791929932492
                        },
                        {
                            "lat": 61.54705156425511,
                            "lon": 55.17798332429834
                        },
                        {
                            "lat": 61.54716336382611,
                            "lon": 55.17618849784141
                        },
                        {
                            "lat": 61.5440272597031,
                            "lon": 55.176124477120794
                        },
                        {
                            "lat": 61.54413918743152,
                            "lon": 55.17432965423214
                        },
                        {
                            "lat": 61.54100323644427,
                            "lon": 55.17426555717232
                        },
                        {
                            "lat": 61.53786729796867,
                            "lon": 55.17420137952157
                        },
                        {
                            "lat": 61.53775508896654,
                            "lon": 55.17599619388937
                        },
                        {
                            "lat": 61.54089116807801,
                            "lon": 55.1760603758032
                        },
                        {
                            "lat": 61.54077908719822,
                            "lon": 55.177855193749195
                        },
                        {
                            "lat": 61.53764286743519,
                            "lon": 55.17779100757184
                        },
                        {
                            "lat": 61.537530633372675,
                            "lon": 55.179585820569024
                        },
                        {
                            "lat": 61.54066699380291,
                            "lon": 55.17965001101023
                        }
                    ],
                    [
                        {
                            "lat": 61.53764286743519,
                            "lon": 55.17779100757184
                        },
                        {
                            "lat": 61.53775508896654,
                            "lon": 55.17599619388937
                        },
                        {
                            "lat": 61.53461902238432,
                            "lon": 55.175931931379935
                        },
                        {
                            "lat": 61.534506660203306,
                            "lon": 55.17772674079362
                        },
                        {
                            "lat": 61.53764286743519,
                            "lon": 55.17779100757184
                        }
                    ],
                    [
                        {
                            "lat": 61.54010633902725,
                            "lon": 55.188624087041156
                        },
                        {
                            "lat": 61.54021849502862,
                            "lon": 55.18682927320493
                        },
                        {
                            "lat": 61.53708157177288,
                            "lon": 55.18676506570442
                        },
                        {
                            "lat": 61.53696927502599,
                            "lon": 55.18855987527488
                        },
                        {
                            "lat": 61.54010633902725,
                            "lon": 55.188624087041156
                        }
                    ],
                    [
                        {
                            "lat": 61.581771569491885,
                            "lon": 55.175092532604104
                        },
                        {
                            "lat": 61.58188179711084,
                            "lon": 55.17329765835883
                        },
                        {
                            "lat": 61.58199201242353,
                            "lon": 55.171502783433574
                        },
                        {
                            "lat": 61.57885619379109,
                            "lon": 55.17143966190538
                        },
                        {
                            "lat": 61.578745837849254,
                            "lon": 55.1732345326377
                        },
                        {
                            "lat": 61.57560989090962,
                            "lon": 55.17317132632266
                        },
                        {
                            "lat": 61.57549938200289,
                            "lon": 55.174966192176
                        },
                        {
                            "lat": 61.57863546958547,
                            "lon": 55.17502940268968
                        },
                        {
                            "lat": 61.581771569491885,
                            "lon": 55.175092532604104
                        }
                    ],
                    [
                        {
                            "lat": 61.5753888607567,
                            "lon": 55.176761057348585
                        },
                        {
                            "lat": 61.57549938200289,
                            "lon": 55.174966192176
                        },
                        {
                            "lat": 61.57236330675978,
                            "lon": 55.174902901063746
                        },
                        {
                            "lat": 61.572252644856874,
                            "lon": 55.17669776203197
                        },
                        {
                            "lat": 61.5753888607567,
                            "lon": 55.176761057348585
                        }
                    ],
                    [
                        {
                            "lat": 61.57812714196046,
                            "lon": 55.23432300116399
                        },
                        {
                            "lat": 61.574986391976886,
                            "lon": 55.2342597326595
                        },
                        {
                            "lat": 61.57184565437921,
                            "lon": 55.234196383379214
                        },
                        {
                            "lat": 61.5687049291832,
                            "lon": 55.234132953323886
                        },
                        {
                            "lat": 61.568593858503675,
                            "lon": 55.2359277918094
                        },
                        {
                            "lat": 61.571734724874425,
                            "lon": 55.23599122608155
                        },
                        {
                            "lat": 61.574875603648735,
                            "lon": 55.23605457957328
                        },
                        {
                            "lat": 61.57801649481084,
                            "lon": 55.2361178522839
                        },
                        {
                            "lat": 61.57812714196046,
                            "lon": 55.23432300116399
                        }
                    ],
                    [
                        {
                            "lat": 61.59227744828503,
                            "lon": 55.15733254026653
                        },
                        {
                            "lat": 61.589142717301044,
                            "lon": 55.157269693909996
                        },
                        {
                            "lat": 61.58903288138488,
                            "lon": 55.15906458263849
                        },
                        {
                            "lat": 61.59216775287844,
                            "lon": 55.15912743316896
                        },
                        {
                            "lat": 61.59227744828503,
                            "lon": 55.15733254026653
                        }
                    ],
                    [
                        {
                            "lat": 61.60492561565962,
                            "lon": 55.1557882099777
                        },
                        {
                            "lat": 61.60179097630069,
                            "lon": 55.15572568997192
                        },
                        {
                            "lat": 61.60168171462968,
                            "lon": 55.15752059604173
                        },
                        {
                            "lat": 61.60481649449003,
                            "lon": 55.15758312019969
                        },
                        {
                            "lat": 61.60492561565962,
                            "lon": 55.1557882099777
                        }
                    ],
                    [
                        {
                            "lat": 61.624274916391215,
                            "lon": 55.147186953281434
                        },
                        {
                            "lat": 61.624383122035276,
                            "lon": 55.14539201420824
                        },
                        {
                            "lat": 61.62124925258852,
                            "lon": 55.14533000220204
                        },
                        {
                            "lat": 61.62114090652578,
                            "lon": 55.147124937157265
                        },
                        {
                            "lat": 61.624274916391215,
                            "lon": 55.147186953281434
                        }
                    ],
                    [
                        {
                            "lat": 61.63335359306785,
                            "lon": 55.15275736865269
                        },
                        {
                            "lat": 61.63346141353515,
                            "lon": 55.1509624192793
                        },
                        {
                            "lat": 61.63032708659595,
                            "lon": 55.150900636522344
                        },
                        {
                            "lat": 61.627192771704294,
                            "lon": 55.15083877322994
                        },
                        {
                            "lat": 61.62708467029669,
                            "lon": 55.15263371439188
                        },
                        {
                            "lat": 61.63021912565757,
                            "lon": 55.152695581792685
                        },
                        {
                            "lat": 61.63335359306785,
                            "lon": 55.15275736865269
                        }
                    ],
                    [
                        {
                            "lat": 61.63141352464773,
                            "lon": 55.23718112818648
                        },
                        {
                            "lat": 61.63455463609851,
                            "lon": 55.237242946719554
                        },
                        {
                            "lat": 61.637695759637985,
                            "lon": 55.23730468445851
                        },
                        {
                            "lat": 61.640836895250416,
                            "lon": 55.23736634140268
                        },
                        {
                            "lat": 61.64397804292003,
                            "lon": 55.23742791755139
                        },
                        {
                            "lat": 61.644085724888654,
                            "lon": 55.235632979340835
                        },
                        {
                            "lat": 61.64722674338027,
                            "lon": 55.23569447060517
                        },
                        {
                            "lat": 61.64733427210556,
                            "lon": 55.233899527634236
                        },
                        {
                            "lat": 61.65047516141549,
                            "lon": 55.23396093402463
                        },
                        {
                            "lat": 61.6536160627317,
                            "lon": 55.23402225962826
                        },
                        {
                            "lat": 61.65372329705351,
                            "lon": 55.232227307826264
                        },
                        {
                            "lat": 61.65686406916681,
                            "lon": 55.23228854857105
                        },
                        {
                            "lat": 61.660004853253085,
                            "lon": 55.232349708533086
                        },
                        {
                            "lat": 61.66314564929661,
                            "lon": 55.23241078771172
                        },
                        {
                            "lat": 61.666286457281615,
                            "lon": 55.23247178610628
                        },
                        {
                            "lat": 61.66617978774463,
                            "lon": 55.234266754161844
                        },
                        {
                            "lat": 61.669320748856144,
                            "lon": 55.23432767582165
                        },
                        {
                            "lat": 61.67246172187951,
                            "lon": 55.23438851669069
                        },
                        {
                            "lat": 61.675602706798934,
                            "lon": 55.2344492767683
                        },
                        {
                            "lat": 61.67874370359869,
                            "lon": 55.234509956053806
                        },
                        {
                            "lat": 61.67863758701102,
                            "lon": 55.236304939608694
                        },
                        {
                            "lat": 61.681778736899126,
                            "lon": 55.23636554213037
                        },
                        {
                            "lat": 61.68491989863788,
                            "lon": 55.236426063853266
                        },
                        {
                            "lat": 61.68806107221151,
                            "lon": 55.2364865047767
                        },
                        {
                            "lat": 61.687955367481365,
                            "lon": 55.23828149973485
                        },
                        {
                            "lat": 61.69109669411906,
                            "lon": 55.23834186387141
                        },
                        {
                            "lat": 61.694238032561955,
                            "lon": 55.2384021472018
                        },
                        {
                            "lat": 61.69737938279431,
                            "lon": 55.23846234972544
                        },
                        {
                            "lat": 61.700520744800336,
                            "lon": 55.238522471441634
                        },
                        {
                            "lat": 61.70366211856432,
                            "lon": 55.238582512349716
                        },
                        {
                            "lat": 61.70680350407046,
                            "lon": 55.23864247244905
                        },
                        {
                            "lat": 61.70994490130304,
                            "lon": 55.23870235173896
                        },
                        {
                            "lat": 61.7130863102463,
                            "lon": 55.23876215021883
                        },
                        {
                            "lat": 61.71622773088444,
                            "lon": 55.238821867887964
                        },
                        {
                            "lat": 61.71936916320177,
                            "lon": 55.23888150474572
                        },
                        {
                            "lat": 61.722510607182485,
                            "lon": 55.23894106079147
                        },
                        {
                            "lat": 61.72565206281085,
                            "lon": 55.23900053602451
                        },
                        {
                            "lat": 61.728793530071115,
                            "lon": 55.23905993044423
                        },
                        {
                            "lat": 61.731935008947495,
                            "lon": 55.23911924404999
                        },
                        {
                            "lat": 61.73507649942425,
                            "lon": 55.23917847684112
                        },
                        {
                            "lat": 61.73821800148564,
                            "lon": 55.23923762881695
                        },
                        {
                            "lat": 61.7383214460825,
                            "lon": 55.23744257029088
                        },
                        {
                            "lat": 61.738424879112266,
                            "lon": 55.235647511103096
                        },
                        {
                            "lat": 61.7385283005767,
                            "lon": 55.23385245125358
                        },
                        {
                            "lat": 61.738631710477556,
                            "lon": 55.232057390742405
                        },
                        {
                            "lat": 61.73873510881662,
                            "lon": 55.23026232956954
                        },
                        {
                            "lat": 61.73883849559562,
                            "lon": 55.22846726773497
                        },
                        {
                            "lat": 61.738941870816355,
                            "lon": 55.226672205238735
                        },
                        {
                            "lat": 61.73904523448057,
                            "lon": 55.224877142080835
                        },
                        {
                            "lat": 61.73914858659002,
                            "lon": 55.22308207826127
                        },
                        {
                            "lat": 61.73925192714649,
                            "lon": 55.221287013780056
                        },
                        {
                            "lat": 61.739355256151704,
                            "lon": 55.219491948637206
                        },
                        {
                            "lat": 61.73945857360745,
                            "lon": 55.217696882832705
                        },
                        {
                            "lat": 61.739561879515485,
                            "lon": 55.21590181636658
                        },
                        {
                            "lat": 61.73966517387757,
                            "lon": 55.214106749238844
                        },
                        {
                            "lat": 61.73976845669544,
                            "lon": 55.2123116814495
                        },
                        {
                            "lat": 61.73987172797089,
                            "lon": 55.21051661299856
                        },
                        {
                            "lat": 61.73997498770564,
                            "lon": 55.208721543885986
                        },
                        {
                            "lat": 61.74007823590149,
                            "lon": 55.20692647411183
                        },
                        {
                            "lat": 61.74018147256017,
                            "lon": 55.20513140367612
                        },
                        {
                            "lat": 61.74028469768344,
                            "lon": 55.20333633257883
                        },
                        {
                            "lat": 61.74038791127307,
                            "lon": 55.20154126081996
                        },
                        {
                            "lat": 61.740491113330805,
                            "lon": 55.19974618839952
                        },
                        {
                            "lat": 61.74059430385842,
                            "lon": 55.197951115317544
                        },
                        {
                            "lat": 61.740697482857634,
                            "lon": 55.19615604157402
                        },
                        {
                            "lat": 61.740800650330236,
                            "lon": 55.19436096716896
                        },
                        {
                            "lat": 61.74090380627798,
                            "lon": 55.192565892102344
                        },
                        {
                            "lat": 61.741006950702584,
                            "lon": 55.190770816374254
                        },
                        {
                            "lat": 61.741110083605854,
                            "lon": 55.188975739984635
                        },
                        {
                            "lat": 61.74121320498951,
                            "lon": 55.18718066293349
                        },
                        {
                            "lat": 61.741316314855325,
                            "lon": 55.18538558522088
                        },
                        {
                            "lat": 61.74141941320503,
                            "lon": 55.18359050684675
                        },
                        {
                            "lat": 61.741522500040396,
                            "lon": 55.181795427811146
                        },
                        {
                            "lat": 61.73838551086908,
                            "lon": 55.1817364015096
                        },
                        {
                            "lat": 61.73524853322603,
                            "lon": 55.18167729456412
                        },
                        {
                            "lat": 61.73514516482374,
                            "lon": 55.18347236575065
                        },
                        {
                            "lat": 61.73200805794381,
                            "lon": 55.18341317422932
                        },
                        {
                            "lat": 61.728870962625265,
                            "lon": 55.18335390205998
                        },
                        {
                            "lat": 61.72876730108602,
                            "lon": 55.18514896471314
                        },
                        {
                            "lat": 61.725630076551575,
                            "lon": 55.18508960795286
                        },
                        {
                            "lat": 61.72249286361165,
                            "lon": 55.185030170540514
                        },
                        {
                            "lat": 61.71935566228195,
                            "lon": 55.18497065247678
                        },
                        {
                            "lat": 61.716218472578134,
                            "lon": 55.18491105376228
                        },
                        {
                            "lat": 61.71308129451589,
                            "lon": 55.18485137439769
                        },
                        {
                            "lat": 61.70994412811092,
                            "lon": 55.18479161438363
                        },
                        {
                            "lat": 61.71004863438154,
                            "lon": 55.18299657547231
                        },
                        {
                            "lat": 61.706911620431875,
                            "lon": 55.18293673878514
                        },
                        {
                            "lat": 61.706806973378896,
                            "lon": 55.18473177372079
                        },
                        {
                            "lat": 61.703669830335485,
                            "lon": 55.18467185240982
                        },
                        {
                            "lat": 61.70356503079762,
                            "lon": 55.186466882698696
                        },
                        {
                            "lat": 61.70346021955365,
                            "lon": 55.188261912321806
                        },
                        {
                            "lat": 61.70032280660992,
                            "lon": 55.1882019023895
                        },
                        {
                            "lat": 61.69718540538977,
                            "lon": 55.18814181179964
                        },
                        {
                            "lat": 61.697080300788,
                            "lon": 55.18993683277677
                        },
                        {
                            "lat": 61.70021784283223,
                            "lon": 55.18999692735943
                        },
                        {
                            "lat": 61.700112867329175,
                            "lon": 55.19179195166322
                        },
                        {
                            "lat": 61.6969751844452,
                            "lon": 55.19173185308741
                        },
                        {
                            "lat": 61.69383751330405,
                            "lon": 55.191671673844056
                        },
                        {
                            "lat": 61.690699853921394,
                            "lon": 55.191611413933764
                        },
                        {
                            "lat": 61.687562206312975,
                            "lon": 55.191551073357246
                        },
                        {
                            "lat": 61.6876677451643,
                            "lon": 55.18975606505815
                        },
                        {
                            "lat": 61.684530250178355,
                            "lon": 55.18969564783056
                        },
                        {
                            "lat": 61.68463591805848,
                            "lon": 55.1879006428781
                        },
                        {
                            "lat": 61.68149857569143,
                            "lon": 55.18784014901046
                        },
                        {
                            "lat": 61.67836124514206,
                            "lon": 55.18777957448923
                        },
                        {
                            "lat": 61.67522392642605,
                            "lon": 55.18771891931507
                        },
                        {
                            "lat": 61.67511783610602,
                            "lon": 55.18951391219289
                        },
                        {
                            "lat": 61.67198038842936,
                            "lon": 55.189453172330914
                        },
                        {
                            "lat": 61.67187414543309,
                            "lon": 55.19124816050379
                        },
                        {
                            "lat": 61.6687365687994,
                            "lon": 55.19118733594358
                        },
                        {
                            "lat": 61.66884295261925,
                            "lon": 55.189392351811975
                        },
                        {
                            "lat": 61.66570552869136,
                            "lon": 55.189331450636764
                        },
                        {
                            "lat": 61.66256811666138,
                            "lon": 55.18927046880593
                        },
                        {
                            "lat": 61.659430716544996,
                            "lon": 55.18920940632016
                        },
                        {
                            "lat": 61.656293328357904,
                            "lon": 55.189148263180094
                        },
                        {
                            "lat": 61.656186381261854,
                            "lon": 55.19094323109309
                        },
                        {
                            "lat": 61.65607942221903,
                            "lon": 55.19273819833491
                        },
                        {
                            "lat": 61.6529417643322,
                            "lon": 55.19267696640482
                        },
                        {
                            "lat": 61.65283465249488,
                            "lon": 55.19447192890669
                        },
                        {
                            "lat": 61.65272752869143,
                            "lon": 55.19626689073701
                        },
                        {
                            "lat": 61.65262039292008,
                            "lon": 55.19806185189572
                        },
                        {
                            "lat": 61.65251324517893,
                            "lon": 55.19985681238287
                        },
                        {
                            "lat": 61.65565146654345,
                            "lon": 55.199918060590115
                        },
                        {
                            "lat": 61.6555444477394,
                            "lon": 55.20171302447588
                        },
                        {
                            "lat": 61.658682821970274,
                            "lon": 55.201774196062274
                        },
                        {
                            "lat": 61.658575932134696,
                            "lon": 55.20356916334199
                        },
                        {
                            "lat": 61.658469030355285,
                            "lon": 55.20536412995083
                        },
                        {
                            "lat": 61.65533037425633,
                            "lon": 55.20530295023362
                        },
                        {
                            "lat": 61.65522331957367,
                            "lon": 55.207097912105624
                        },
                        {
                            "lat": 61.655116252927854,
                            "lon": 55.208892873306354
                        },
                        {
                            "lat": 61.651977326863,
                            "lon": 55.208831604744404
                        },
                        {
                            "lat": 61.648838412778844,
                            "lon": 55.20877025547069
                        },
                        {
                            "lat": 61.64873105219546,
                            "lon": 55.21056520785071
                        },
                        {
                            "lat": 61.645592009124194,
                            "lon": 55.21050377378314
                        },
                        {
                            "lat": 61.64245297806687,
                            "lon": 55.21044225899975
                        },
                        {
                            "lat": 61.63931395903921,
                            "lon": 55.21038066350125
                        },
                        {
                            "lat": 61.639206163465346,
                            "lon": 55.212175602943894
                        },
                        {
                            "lat": 61.639098355844276,
                            "lon": 55.21397054171344
                        },
                        {
                            "lat": 61.63899053617413,
                            "lon": 55.21576547980983
                        },
                        {
                            "lat": 61.63888270445312,
                            "lon": 55.21756041723309
                        },
                        {
                            "lat": 61.638774860679376,
                            "lon": 55.21935535398318
                        },
                        {
                            "lat": 61.63866700485109,
                            "lon": 55.2211502900601
                        },
                        {
                            "lat": 61.64180687008801,
                            "lon": 55.22121191012695
                        },
                        {
                            "lat": 61.64494674736558,
                            "lon": 55.221273449446564
                        },
                        {
                            "lat": 61.645054321043695,
                            "lon": 55.21947850518371
                        },
                        {
                            "lat": 61.648194069268364,
                            "lon": 55.219539959670456
                        },
                        {
                            "lat": 61.65133382950044,
                            "lon": 55.21960133341397
                        },
                        {
                            "lat": 61.65447360172415,
                            "lon": 55.21966262641358
                        },
                        {
                            "lat": 61.65458074018731,
                            "lon": 55.217867669240654
                        },
                        {
                            "lat": 61.65772038331938,
                            "lon": 55.21792887742718
                        },
                        {
                            "lat": 61.65782736875654,
                            "lon": 55.216133915514796
                        },
                        {
                            "lat": 61.6609668827933,
                            "lon": 55.21619503889864
                        },
                        {
                            "lat": 61.66107371523591,
                            "lon": 55.21440007225287
                        },
                        {
                            "lat": 61.66421310017372,
                            "lon": 55.21446111084445
                        },
                        {
                            "lat": 61.66735249703483,
                            "lon": 55.21452206870551
                        },
                        {
                            "lat": 61.67049190580353,
                            "lon": 55.2145829458354
                        },
                        {
                            "lat": 61.67363132646409,
                            "lon": 55.21464374223342
                        },
                        {
                            "lat": 61.67352505819157,
                            "lon": 55.216438725075314
                        },
                        {
                            "lat": 61.67038549648574,
                            "lon": 55.2163779246363
                        },
                        {
                            "lat": 61.66724594667362,
                            "lon": 55.21631704346006
                        },
                        {
                            "lat": 61.66713938440303,
                            "lon": 55.21811201754475
                        },
                        {
                            "lat": 61.66399970544295,
                            "lon": 55.218051051579884
                        },
                        {
                            "lat": 61.663892990188074,
                            "lon": 55.21984602094226
                        },
                        {
                            "lat": 61.66075318208371,
                            "lon": 55.219784970178395
                        },
                        {
                            "lat": 61.66064631381307,
                            "lon": 55.22157993481237
                        },
                        {
                            "lat": 61.65750637656806,
                            "lon": 55.221518799239064
                        },
                        {
                            "lat": 61.65436645128507,
                            "lon": 55.221457582915164
                        },
                        {
                            "lat": 61.65425928886821,
                            "lon": 55.22325253874543
                        },
                        {
                            "lat": 61.65111923446575,
                            "lon": 55.22319123759699
                        },
                        {
                            "lat": 61.651011918956335,
                            "lon": 55.22498618868096
                        },
                        {
                            "lat": 61.64787173543808,
                            "lon": 55.22492480269761
                        },
                        {
                            "lat": 61.647979192058614,
                            "lon": 55.22312985569396
                        },
                        {
                            "lat": 61.644839161662524,
                            "lon": 55.223068393036975
                        },
                        {
                            "lat": 61.64473156393269,
                            "lon": 55.22486333595495
                        },
                        {
                            "lat": 61.64159140445591,
                            "lon": 55.22480178845368
                        },
                        {
                            "lat": 61.63845125702346,
                            "lon": 55.224740160194436
                        },
                        {
                            "lat": 61.63834336502046,
                            "lon": 55.226535094251815
                        },
                        {
                            "lat": 61.63823546095555,
                            "lon": 55.228330027636005
                        },
                        {
                            "lat": 61.635095043328405,
                            "lon": 55.22826831041491
                        },
                        {
                            "lat": 61.63498698604877,
                            "lon": 55.23006323902304
                        },
                        {
                            "lat": 61.6348789166878,
                            "lon": 55.231858166957565
                        },
                        {
                            "lat": 61.6317382288261,
                            "lon": 55.2317963607524
                        },
                        {
                            "lat": 61.6285975530633,
                            "lon": 55.231734473769876
                        },
                        {
                            "lat": 61.62545688941516,
                            "lon": 55.23167250601066
                        },
                        {
                            "lat": 61.6253483844347,
                            "lon": 55.23346742092874
                        },
                        {
                            "lat": 61.62523986732201,
                            "lon": 55.2352623351721
                        },
                        {
                            "lat": 61.62838081333974,
                            "lon": 55.23532431117098
                        },
                        {
                            "lat": 61.628272425301375,
                            "lon": 55.237119228859974
                        },
                        {
                            "lat": 61.63141352464773,
                            "lon": 55.23718112818648
                        }
                    ],
                    [
                        {
                            "lat": 61.66921417795691,
                            "lon": 55.13009673196792
                        },
                        {
                            "lat": 61.669320299083395,
                            "lon": 55.128301729105004
                        },
                        {
                            "lat": 61.666187665614785,
                            "lon": 55.12824088482569
                        },
                        {
                            "lat": 61.66305504399829,
                            "lon": 55.12817996007297
                        },
                        {
                            "lat": 61.66316143383122,
                            "lon": 55.126384964624215
                        },
                        {
                            "lat": 61.660028964351255,
                            "lon": 55.12632396344866
                        },
                        {
                            "lat": 61.660135482571405,
                            "lon": 55.12452897137903
                        },
                        {
                            "lat": 61.66024198891182,
                            "lon": 55.12273397863853
                        },
                        {
                            "lat": 61.65710981180057,
                            "lon": 55.12267290510569
                        },
                        {
                            "lat": 61.657003165224374,
                            "lon": 55.12446789379161
                        },
                        {
                            "lat": 61.65689650675284,
                            "lon": 55.126262881806326
                        },
                        {
                            "lat": 61.65678983638415,
                            "lon": 55.128057869149806
                        },
                        {
                            "lat": 61.65668315411652,
                            "lon": 55.12985285582207
                        },
                        {
                            "lat": 61.659815892264504,
                            "lon": 55.12991394557535
                        },
                        {
                            "lat": 61.6597093383943,
                            "lon": 55.1317089356324
                        },
                        {
                            "lat": 61.66284222872741,
                            "lon": 55.131769948958926
                        },
                        {
                            "lat": 61.66597513093189,
                            "lon": 55.13183088180202
                        },
                        {
                            "lat": 61.6660814042003,
                            "lon": 55.13003588364894
                        },
                        {
                            "lat": 61.66921417795691,
                            "lon": 55.13009673196792
                        }
                    ],
                    [
                        {
                            "lat": 61.69824821285893,
                            "lon": 55.116280414682215
                        },
                        {
                            "lat": 61.69835297779392,
                            "lon": 55.114485370370055
                        },
                        {
                            "lat": 61.6952213602294,
                            "lon": 55.11442528230201
                        },
                        {
                            "lat": 61.69511645511509,
                            "lon": 55.11622032262534
                        },
                        {
                            "lat": 61.695011538301536,
                            "lon": 55.11801536228182
                        },
                        {
                            "lat": 61.69814343624025,
                            "lon": 55.11807545832787
                        },
                        {
                            "lat": 61.69824821285893,
                            "lon": 55.116280414682215
                        }
                    ],
                    [
                        {
                            "lat": 61.71046267817974,
                            "lon": 55.12190515512806
                        },
                        {
                            "lat": 61.707330453142184,
                            "lon": 55.121845372941294
                        },
                        {
                            "lat": 61.70722606219762,
                            "lon": 55.12364042652619
                        },
                        {
                            "lat": 61.71035842748396,
                            "lon": 55.123700212681875
                        },
                        {
                            "lat": 61.71046267817974,
                            "lon": 55.12190515512806
                        }
                    ],
                    [
                        {
                            "lat": 61.70722606219762,
                            "lon": 55.12364042652619
                        },
                        {
                            "lat": 61.70409370855523,
                            "lon": 55.1235805599045
                        },
                        {
                            "lat": 61.70398916570414,
                            "lon": 55.125375608849325
                        },
                        {
                            "lat": 61.707121659609136,
                            "lon": 55.125435479445635
                        },
                        {
                            "lat": 61.70722606219762,
                            "lon": 55.12364042652619
                        }
                    ],
                    [
                        {
                            "lat": 61.74260793753932,
                            "lon": 55.108137752100546
                        },
                        {
                            "lat": 61.73947671850724,
                            "lon": 55.108078805868445
                        },
                        {
                            "lat": 61.73937382197003,
                            "lon": 55.109873904198025
                        },
                        {
                            "lat": 61.74250518114368,
                            "lon": 55.10993285434297
                        },
                        {
                            "lat": 61.74260793753932,
                            "lon": 55.108137752100546
                        }
                    ]
                ]
            },
            {
                "type": "MultiLineString",
                "value": 150.0,
                "data": [
                    [
                        {
                            "lat": 61.350695050408994,
                            "lon": 55.0623913244152
                        },
                        {
                            "lat": 61.35382178032622,
                            "lon": 55.06246022241369
                        },
                        {
                            "lat": 61.3539417457937,
                            "lon": 55.06066563493867
                        },
                        {
                            "lat": 61.35706834950382,
                            "lon": 55.060734448146704
                        },
                        {
                            "lat": 61.35718816206341,
                            "lon": 55.05893985539984
                        },
                        {
                            "lat": 61.35730796128338,
                            "lon": 55.05714526194482
                        },
                        {
                            "lat": 61.35418163665932,
                            "lon": 55.05707645786299
                        },
                        {
                            "lat": 61.351055325388316,
                            "lon": 55.057007573570324
                        },
                        {
                            "lat": 61.34792902748586,
                            "lon": 55.05693860906757
                        },
                        {
                            "lat": 61.34804923192159,
                            "lon": 55.05514402860781
                        },
                        {
                            "lat": 61.3511753903083,
                            "lon": 55.055212988537484
                        },
                        {
                            "lat": 61.351295441861744,
                            "lon": 55.05341840279571
                        },
                        {
                            "lat": 61.354421474112655,
                            "lon": 55.053487277953195
                        },
                        {
                            "lat": 61.35454137281478,
                            "lon": 55.051692686935546
                        },
                        {
                            "lat": 61.3514154800507,
                            "lon": 55.051623816345035
                        },
                        {
                            "lat": 61.35153550487717,
                            "lon": 55.04982922918548
                        },
                        {
                            "lat": 61.34840976494488,
                            "lon": 55.04976028297264
                        },
                        {
                            "lat": 61.3452840383885,
                            "lon": 55.04969125657169
                        },
                        {
                            "lat": 61.34215832522353,
                            "lon": 55.04962214998336
                        },
                        {
                            "lat": 61.34203788199514,
                            "lon": 55.05141672341139
                        },
                        {
                            "lat": 61.341917425357856,
                            "lon": 55.05321129612935
                        },
                        {
                            "lat": 61.34179695530967,
                            "lon": 55.05500586813714
                        },
                        {
                            "lat": 61.341676471848515,
                            "lon": 55.056800439434795
                        },
                        {
                            "lat": 61.34155597497239,
                            "lon": 55.0585950100223
                        },
                        {
                            "lat": 61.34143546467923,
                            "lon": 55.060389579899635
                        },
                        {
                            "lat": 61.34131494096702,
                            "lon": 55.062184149066766
                        },
                        {
                            "lat": 61.34444163071392,
                            "lon": 55.062253287740994
                        },
                        {
                            "lat": 61.34756833386639,
                            "lon": 55.06232234619073
                        },
                        {
                            "lat": 61.350695050408994,
                            "lon": 55.0623913244152
                        }
                    ],
                    [
                        {
                            "lat": 61.3399882938929,
                            "lon": 55.08192436302853
                        },
                        {
                            "lat": 61.33986760899322,
                            "lon": 55.083718923672215
                        },
                        {
                            "lat": 61.339746910647996,
                            "lon": 55.085513483605524
                        },
                        {
                            "lat": 61.339626198855186,
                            "lon": 55.08730804282842
                        },
                        {
                            "lat": 61.339505473612775,
                            "lon": 55.089102601340926
                        },
                        {
                            "lat": 61.3393847349187,
                            "lon": 55.09089715914297
                        },
                        {
                            "lat": 61.33926398277093,
                            "lon": 55.09269171623458
                        },
                        {
                            "lat": 61.339143217167404,
                            "lon": 55.094486272615754
                        },
                        {
                            "lat": 61.339022438106106,
                            "lon": 55.096280828286396
                        },
                        {
                            "lat": 61.338901645584976,
                            "lon": 55.09807538324657
                        },
                        {
                            "lat": 61.33878083960198,
                            "lon": 55.09986993749626
                        },
                        {
                            "lat": 61.33866002015507,
                            "lon": 55.10166449103541
                        },
                        {
                            "lat": 61.338539187242205,
                            "lon": 55.10345904386402
                        },
                        {
                            "lat": 61.33841834086134,
                            "lon": 55.10525359598207
                        },
                        {
                            "lat": 61.338297481010436,
                            "lon": 55.107048147389555
                        },
                        {
                            "lat": 61.33817660768743,
                            "lon": 55.10884269808646
                        },
                        {
                            "lat": 61.338055720890296,
                            "lon": 55.11063724807277
                        },
                        {
                            "lat": 61.33793482061697,
                            "lon": 55.11243179734847
                        },
                        {
                            "lat": 61.337813906865414,
                            "lon": 55.11422634591353
                        },
                        {
                            "lat": 61.3376929796336,
                            "lon": 55.11602089376794
                        },
                        {
                            "lat": 61.340823863335984,
                            "lon": 55.116090170180286
                        },
                        {
                            "lat": 61.34395476050511,
                            "lon": 55.116159366208784
                        },
                        {
                            "lat": 61.34708567112547,
                            "lon": 55.11622848185268
                        },
                        {
                            "lat": 61.350216595181465,
                            "lon": 55.11629751711122
                        },
                        {
                            "lat": 61.35334753265757,
                            "lon": 55.11636647198367
                        },
                        {
                            "lat": 61.353227292160256,
                            "lon": 55.11816104206237
                        },
                        {
                            "lat": 61.35635838309037,
                            "lon": 55.11822992111897
                        },
                        {
                            "lat": 61.356238269251534,
                            "lon": 55.1200244950605
                        },
                        {
                            "lat": 61.356118142019646,
                            "lon": 55.12181906829377
                        },
                        {
                            "lat": 61.35599800139269,
                            "lon": 55.123613640818796
                        },
                        {
                            "lat": 61.359129525961755,
                            "lon": 55.12368245318207
                        },
                        {
                            "lat": 61.35900951205143,
                            "lon": 55.125477029566184
                        },
                        {
                            "lat": 61.36214119011784,
                            "lon": 55.12554576608182
                        },
                        {
                            "lat": 61.36202130295533,
                            "lon": 55.12734034632052
                        },
                        {
                            "lat": 61.365153134523155,
                            "lon": 55.127409006977565
                        },
                        {
                            "lat": 61.36828497944545,
                            "lon": 55.12747758721284
                        },
                        {
                            "lat": 61.36816535921288,
                            "lon": 55.12927217585376
                        },
                        {
                            "lat": 61.371297357627114,
                            "lon": 55.12934068021338
                        },
                        {
                            "lat": 61.37117786422226,
                            "lon": 55.13113527269489
                        },
                        {
                            "lat": 61.36804572563942,
                            "lon": 55.13106676378796
                        },
                        {
                            "lat": 61.364913600399525,
                            "lon": 55.130998174447896
                        },
                        {
                            "lat": 61.36503337413958,
                            "lon": 55.12920359106627
                        },
                        {
                            "lat": 61.36190140242278,
                            "lon": 55.129134925851695
                        },
                        {
                            "lat": 61.35876944407803,
                            "lon": 55.129066180210785
                        },
                        {
                            "lat": 61.35888948475755,
                            "lon": 55.12727160524242
                        },
                        {
                            "lat": 61.35575767994537,
                            "lon": 55.12720278374405
                        },
                        {
                            "lat": 61.352625888534384,
                            "lon": 55.12713388182616
                        },
                        {
                            "lat": 61.349494110540135,
                            "lon": 55.1270648994895
                        },
                        {
                            "lat": 61.34961455821596,
                            "lon": 55.125270337532484
                        },
                        {
                            "lat": 61.352746196084915,
                            "lon": 55.12533931529076
                        },
                        {
                            "lat": 61.35286649022075,
                            "lon": 55.12354474804663
                        },
                        {
                            "lat": 61.352986770943886,
                            "lon": 55.12175018009388
                        },
                        {
                            "lat": 61.34985541327877,
                            "lon": 55.121681211491165
                        },
                        {
                            "lat": 61.34672406903985,
                            "lon": 55.12161216248639
                        },
                        {
                            "lat": 61.34359273824271,
                            "lon": 55.12154303308029
                        },
                        {
                            "lat": 61.340461420902905,
                            "lon": 55.12147382327363
                        },
                        {
                            "lat": 61.337330117036,
                            "lon": 55.12140453306718
                        },
                        {
                            "lat": 61.33720913586261,
                            "lon": 55.123199078078855
                        },
                        {
                            "lat": 61.33708814119869,
                            "lon": 55.124993622379826
                        },
                        {
                            "lat": 61.336967133042215,
                            "lon": 55.12678816597004
                        },
                        {
                            "lat": 61.336846111391125,
                            "lon": 55.12858270884953
                        },
                        {
                            "lat": 61.336725076243354,
                            "lon": 55.130377251018224
                        },
                        {
                            "lat": 61.33660402759688,
                            "lon": 55.13217179247615
                        },
                        {
                            "lat": 61.33648296544966,
                            "lon": 55.13396633322327
                        },
                        {
                            "lat": 61.3363618897996,
                            "lon": 55.1357608732596
                        },
                        {
                            "lat": 61.339494314790166,
                            "lon": 55.13583020026845
                        },
                        {
                            "lat": 61.3393733658458,
                            "lon": 55.13762474419609
                        },
                        {
                            "lat": 61.336240800644674,
                            "lon": 55.13755541258508
                        },
                        {
                            "lat": 61.336119697982824,
                            "lon": 55.13934995119973
                        },
                        {
                            "lat": 61.335998581812,
                            "lon": 55.14114448910351
                        },
                        {
                            "lat": 61.335877452130156,
                            "lon": 55.142939026296425
                        },
                        {
                            "lat": 61.33575630893523,
                            "lon": 55.14473356277844
                        },
                        {
                            "lat": 61.33563515222517,
                            "lon": 55.14652809854956
                        },
                        {
                            "lat": 61.335513981997934,
                            "lon": 55.148322633609745
                        },
                        {
                            "lat": 61.335392798251426,
                            "lon": 55.15011716795902
                        },
                        {
                            "lat": 61.33527160098364,
                            "lon": 55.15191170159733
                        },
                        {
                            "lat": 61.33515039019248,
                            "lon": 55.15370623452466
                        },
                        {
                            "lat": 61.33502916587593,
                            "lon": 55.15550076674103
                        },
                        {
                            "lat": 61.33490792803189,
                            "lon": 55.15729529824638
                        },
                        {
                            "lat": 61.33804203657818,
                            "lon": 55.157364680508195
                        },
                        {
                            "lat": 61.341176158638355,
                            "lon": 55.157433982263676
                        },
                        {
                            "lat": 61.341055188062306,
                            "lon": 55.15922852226691
                        },
                        {
                            "lat": 61.34418946402263,
                            "lon": 55.159297748111754
                        },
                        {
                            "lat": 61.344068620363636,
                            "lon": 55.1610922920016
                        },
                        {
                            "lat": 61.343947763217855,
                            "lon": 55.16288683518164
                        },
                        {
                            "lat": 61.343826892583245,
                            "lon": 55.16468137765183
                        },
                        {
                            "lat": 61.34696160333361,
                            "lon": 55.16475053675933
                        },
                        {
                            "lat": 61.347082333517506,
                            "lon": 55.16295598969679
                        },
                        {
                            "lat": 61.35021691729037,
                            "lon": 55.163025063687385
                        },
                        {
                            "lat": 61.35033749356388,
                            "lon": 55.161230511328824
                        },
                        {
                            "lat": 61.35347195035516,
                            "lon": 55.16129950021285
                        },
                        {
                            "lat": 61.356606420586374,
                            "lon": 55.161368408576124
                        },
                        {
                            "lat": 61.35672670255511,
                            "lon": 55.15957384635221
                        },
                        {
                            "lat": 61.35986104578298,
                            "lon": 55.15964266962383
                        },
                        {
                            "lat": 61.35998117391739,
                            "lon": 55.15784810212198
                        },
                        {
                            "lat": 61.36311539013782,
                            "lon": 55.15791684031224
                        },
                        {
                            "lat": 61.36624961974728,
                            "lon": 55.157985497990175
                        },
                        {
                            "lat": 61.36938386273015,
                            "lon": 55.15805407515503
                        },
                        {
                            "lat": 61.37251811907079,
                            "lon": 55.15812257180607
                        },
                        {
                            "lat": 61.37565238875361,
                            "lon": 55.15819098794255
                        },
                        {
                            "lat": 61.37878667176299,
                            "lon": 55.158259323563705
                        },
                        {
                            "lat": 61.37866738614363,
                            "lon": 55.16005391837228
                        },
                        {
                            "lat": 61.381801822890225,
                            "lon": 55.16012217800985
                        },
                        {
                            "lat": 61.38493627293416,
                            "lon": 55.1601903571253
                        },
                        {
                            "lat": 61.388070736259834,
                            "lon": 55.16025845571787
                        },
                        {
                            "lat": 61.38818960059431,
                            "lon": 55.15846384732791
                        },
                        {
                            "lat": 61.385055277698946,
                            "lon": 55.158395753257146
                        },
                        {
                            "lat": 61.385174269184034,
                            "lon": 55.15660114868451
                        },
                        {
                            "lat": 61.38529324739142,
                            "lon": 55.15480654340743
                        },
                        {
                            "lat": 61.38541221232313,
                            "lon": 55.15301193742589
                        },
                        {
                            "lat": 61.38854611402123,
                            "lon": 55.15308001793368
                        },
                        {
                            "lat": 61.38866492531144,
                            "lon": 55.151285406727546
                        },
                        {
                            "lat": 61.39179869989769,
                            "lon": 55.1513534022182
                        },
                        {
                            "lat": 61.3919173575776,
                            "lon": 55.14955878579358
                        },
                        {
                            "lat": 61.395051005048,
                            "lon": 55.14962669627741
                        },
                        {
                            "lat": 61.398184665741205,
                            "lon": 55.149694526268156
                        },
                        {
                            "lat": 61.398303029499345,
                            "lon": 55.147899900127854
                        },
                        {
                            "lat": 61.40143656305505,
                            "lon": 55.1479676451267
                        },
                        {
                            "lat": 61.401554773278896,
                            "lon": 55.14617301378595
                        },
                        {
                            "lat": 61.401672970315154,
                            "lon": 55.14437838174285
                        },
                        {
                            "lat": 61.40480623641387,
                            "lon": 55.144446037268175
                        },
                        {
                            "lat": 61.40793951568254,
                            "lon": 55.14451361231413
                        },
                        {
                            "lat": 61.407821599279266,
                            "lon": 55.14630825333576
                        },
                        {
                            "lat": 61.4077036697196,
                            "lon": 55.148102893655825
                        },
                        {
                            "lat": 61.41083724279726,
                            "lon": 55.14817039718466
                        },
                        {
                            "lat": 61.41071944042992,
                            "lon": 55.149965041285206
                        },
                        {
                            "lat": 61.410601624917824,
                            "lon": 55.1517596846846
                        },
                        {
                            "lat": 61.40746777112317,
                            "lon": 55.15169217219122
                        },
                        {
                            "lat": 61.40734980208238,
                            "lon": 55.15348681040655
                        },
                        {
                            "lat": 61.41048379625896,
                            "lon": 55.153554327382786
                        },
                        {
                            "lat": 61.41036595445132,
                            "lon": 55.155348969379766
                        },
                        {
                            "lat": 61.410248099492925,
                            "lon": 55.157143610675526
                        },
                        {
                            "lat": 61.413382387632936,
                            "lon": 55.157211056100614
                        },
                        {
                            "lat": 61.41326465995255,
                            "lon": 55.15900570117387
                        },
                        {
                            "lat": 61.41639910166247,
                            "lon": 55.15907307055352
                        },
                        {
                            "lat": 61.416516688910114,
                            "lon": 55.157278421006836
                        },
                        {
                            "lat": 61.4196510033088,
                            "lon": 55.157345705393475
                        },
                        {
                            "lat": 61.42278533081341,
                            "lon": 55.1574129092598
                        },
                        {
                            "lat": 61.42266802443728,
                            "lon": 55.1592075677373
                        },
                        {
                            "lat": 61.419533556495935,
                            "lon": 55.159140359408234
                        },
                        {
                            "lat": 61.419416096575155,
                            "lon": 55.16093501272293
                        },
                        {
                            "lat": 61.422550704968856,
                            "lon": 55.161002225515155
                        },
                        {
                            "lat": 61.42568532645686,
                            "lon": 55.16106935777566
                        },
                        {
                            "lat": 61.42556813436418,
                            "lon": 55.16286401931206
                        },
                        {
                            "lat": 61.42870290940279,
                            "lon": 55.162931075492985
                        },
                        {
                            "lat": 61.42858584471707,
                            "lon": 55.16472574078338
                        },
                        {
                            "lat": 61.431720773310275,
                            "lon": 55.164792720873756
                        },
                        {
                            "lat": 61.43160383606278,
                            "lon": 55.16658738991359
                        },
                        {
                            "lat": 61.43473891821458,
                            "lon": 55.16665429390239
                        },
                        {
                            "lat": 61.43462210843655,
                            "lon": 55.16844896668714
                        },
                        {
                            "lat": 61.43775734415088,
                            "lon": 55.16851579456337
                        },
                        {
                            "lat": 61.43764066187362,
                            "lon": 55.17031047108845
                        },
                        {
                            "lat": 61.44077605115446,
                            "lon": 55.17037722284112
                        },
                        {
                            "lat": 61.44065949640921,
                            "lon": 55.172171903102004
                        },
                        {
                            "lat": 61.440542928651574,
                            "lon": 55.17396658266552
                        },
                        {
                            "lat": 61.443678612078585,
                            "lon": 55.1740332627122
                        },
                        {
                            "lat": 61.44356217189792,
                            "lon": 55.175827946007345
                        },
                        {
                            "lat": 61.440426347879566,
                            "lon": 55.17576126153169
                        },
                        {
                            "lat": 61.437290536877555,
                            "lon": 55.17569449647724
                        },
                        {
                            "lat": 61.434154738907566,
                            "lon": 55.175627650844746
                        },
                        {
                            "lat": 61.43101895398519,
                            "lon": 55.17556072463495
                        },
                        {
                            "lat": 61.431135956519334,
                            "lon": 55.17376605908776
                        },
                        {
                            "lat": 61.428000325243666,
                            "lon": 55.17369905675171
                        },
                        {
                            "lat": 61.42811745528429,
                            "lon": 55.17190439495591
                        },
                        {
                            "lat": 61.42498197765133,
                            "lon": 55.1718373165046
                        },
                        {
                            "lat": 61.42184651310893,
                            "lon": 55.17177015748884
                        },
                        {
                            "lat": 61.421963911172874,
                            "lon": 55.169975503909164
                        },
                        {
                            "lat": 61.418828600282886,
                            "lon": 55.16990826879522
                        },
                        {
                            "lat": 61.418946125773104,
                            "lon": 55.16811361898097
                        },
                        {
                            "lat": 61.422081296132575,
                            "lon": 55.16818084962977
                        },
                        {
                            "lat": 61.42219866799003,
                            "lon": 55.166386194650656
                        },
                        {
                            "lat": 61.41906363814544,
                            "lon": 55.166318968466605
                        },
                        {
                            "lat": 61.415928621416725,
                            "lon": 55.166251661735544
                        },
                        {
                            "lat": 61.41279361781951,
                            "lon": 55.16618427445827
                        },
                        {
                            "lat": 61.41291139806847,
                            "lon": 55.1643896321885
                        },
                        {
                            "lat": 61.40977654811167,
                            "lon": 55.16432216884604
                        },
                        {
                            "lat": 61.40965862736943,
                            "lon": 55.16611680663546
                        },
                        {
                            "lat": 61.406523650082136,
                            "lon": 55.166049258267854
                        },
                        {
                            "lat": 61.40664171131563,
                            "lon": 55.16425462496412
                        },
                        {
                            "lat": 61.403506887695976,
                            "lon": 55.1641870005435
                        },
                        {
                            "lat": 61.403625076226696,
                            "lon": 55.162392371028666
                        },
                        {
                            "lat": 61.4004904062707,
                            "lon": 55.162324670566015
                        },
                        {
                            "lat": 61.40037207726834,
                            "lon": 55.1641192955849
                        },
                        {
                            "lat": 61.397237280048365,
                            "lon": 55.164051510089074
                        },
                        {
                            "lat": 61.3973557495203,
                            "lon": 55.162256889571466
                        },
                        {
                            "lat": 61.39422110599119,
                            "lon": 55.16218902804574
                        },
                        {
                            "lat": 61.39108647569896,
                            "lon": 55.16212108598959
                        },
                        {
                            "lat": 61.39096772529382,
                            "lon": 55.16391569748864
                        },
                        {
                            "lat": 61.387832967790516,
                            "lon": 55.16384767038553
                        },
                        {
                            "lat": 61.38469822355737,
                            "lon": 55.16377956274815
                        },
                        {
                            "lat": 61.384579178941316,
                            "lon": 55.16557416450277
                        },
                        {
                            "lat": 61.38144430751877,
                            "lon": 55.1655059718035
                        },
                        {
                            "lat": 61.381325109124155,
                            "lon": 55.16730056832487
                        },
                        {
                            "lat": 61.37819011051625,
                            "lon": 55.16723229055339
                        },
                        {
                            "lat": 61.37807075831181,
                            "lon": 55.16902688183531
                        },
                        {
                            "lat": 61.37795139278424,
                            "lon": 55.17082147241186
                        },
                        {
                            "lat": 61.37481612647708,
                            "lon": 55.1707531050176
                        },
                        {
                            "lat": 61.371680873510655,
                            "lon": 55.17068465707072
                        },
                        {
                            "lat": 61.37180052007217,
                            "lon": 55.16889007557997
                        },
                        {
                            "lat": 61.36866542097606,
                            "lon": 55.16882155163208
                        },
                        {
                            "lat": 61.365530335249936,
                            "lon": 55.16875294713839
                        },
                        {
                            "lat": 61.3654104076626,
                            "lon": 55.17054751952201
                        },
                        {
                            "lat": 61.36227519481222,
                            "lon": 55.17047882992167
                        },
                        {
                            "lat": 61.35913999536514,
                            "lon": 55.17041005977171
                        },
                        {
                            "lat": 61.35901977334121,
                            "lon": 55.17220462231902
                        },
                        {
                            "lat": 61.3558844467916,
                            "lon": 55.17213576704753
                        },
                        {
                            "lat": 61.355764070809684,
                            "lon": 55.173930324313936
                        },
                        {
                            "lat": 61.35262861716166,
                            "lon": 55.17386138391057
                        },
                        {
                            "lat": 61.3494931769679,
                            "lon": 55.173792362949115
                        },
                        {
                            "lat": 61.34937250644814,
                            "lon": 55.17558691034433
                        },
                        {
                            "lat": 61.349251822456445,
                            "lon": 55.17738145703045
                        },
                        {
                            "lat": 61.349131124990734,
                            "lon": 55.17917600300746
                        },
                        {
                            "lat": 61.35226698687717,
                            "lon": 55.17924503772252
                        },
                        {
                            "lat": 61.352387543762994,
                            "lon": 55.1774504871606
                        },
                        {
                            "lat": 61.355523278527926,
                            "lon": 55.17751943672196
                        },
                        {
                            "lat": 61.358659026735566,
                            "lon": 55.177588305713854
                        },
                        {
                            "lat": 61.35853875101558,
                            "lon": 55.17938286542971
                        },
                        {
                            "lat": 61.36167465323629,
                            "lon": 55.179451658420305
                        },
                        {
                            "lat": 61.364810568870475,
                            "lon": 55.179520370834595
                        },
                        {
                            "lat": 61.36794649790249,
                            "lon": 55.17958900267182
                        },
                        {
                            "lat": 61.36782663056442,
                            "lon": 55.18138357537181
                        },
                        {
                            "lat": 61.36770674984214,
                            "lon": 55.18317814736509
                        },
                        {
                            "lat": 61.370842973487484,
                            "lon": 55.18324670773159
                        },
                        {
                            "lat": 61.373979210503464,
                            "lon": 55.183315187508846
                        },
                        {
                            "lat": 61.37385959767503,
                            "lon": 55.1851097678984
                        },
                        {
                            "lat": 61.37072322001797,
                            "lon": 55.18504128357233
                        },
                        {
                            "lat": 61.36758685573355,
                            "lon": 55.184972718651686
                        },
                        {
                            "lat": 61.3674669482367,
                            "lon": 55.18676728923155
                        },
                        {
                            "lat": 61.36734702734948,
                            "lon": 55.18856185910468
                        },
                        {
                            "lat": 61.36421039513241,
                            "lon": 55.18849320446998
                        },
                        {
                            "lat": 61.36409032016885,
                            "lon": 55.19028776907562
                        },
                        {
                            "lat": 61.36095356067789,
                            "lon": 55.1902190292711
                        },
                        {
                            "lat": 61.36083333160666,
                            "lon": 55.1920135886031
                        },
                        {
                            "lat": 61.35769644484588,
                            "lon": 55.19194476361841
                        },
                        {
                            "lat": 61.35455957152854,
                            "lon": 55.1918758580208
                        },
                        {
                            "lat": 61.35142271167027,
                            "lon": 55.191806871811
                        },
                        {
                            "lat": 61.34828586528676,
                            "lon": 55.191737804989764
                        },
                        {
                            "lat": 61.34816505995527,
                            "lon": 55.19353234529324
                        },
                        {
                            "lat": 61.34502808635917,
                            "lon": 55.19346319326774
                        },
                        {
                            "lat": 61.34189112627117,
                            "lon": 55.19339396062697
                        },
                        {
                            "lat": 61.341770026011964,
                            "lon": 55.19518849102788
                        },
                        {
                            "lat": 61.34164891222694,
                            "lon": 55.19698302071869
                        },
                        {
                            "lat": 61.344786153763785,
                            "lon": 55.19705226255852
                        },
                        {
                            "lat": 61.34466516719877,
                            "lon": 55.19884679613937
                        },
                        {
                            "lat": 61.347802562997835,
                            "lon": 55.19891596194807
                        },
                        {
                            "lat": 61.34768170368418,
                            "lon": 55.20071049941443
                        },
                        {
                            "lat": 61.34756083086983,
                            "lon": 55.20250503617147
                        },
                        {
                            "lat": 61.344423153523806,
                            "lon": 55.20243586117192
                        },
                        {
                            "lat": 61.34430212640976,
                            "lon": 55.20423039262358
                        },
                        {
                            "lat": 61.34116432178721,
                            "lon": 55.20416113238066
                        },
                        {
                            "lat": 61.338026530700795,
                            "lon": 55.20409179149118
                        },
                        {
                            "lat": 61.337905208447125,
                            "lon": 55.205886313024145
                        },
                        {
                            "lat": 61.341043140342094,
                            "lon": 55.20595565852075
                        },
                        {
                            "lat": 61.344181085775226,
                            "lon": 55.20602492336548
                        },
                        {
                            "lat": 61.3473190447309,
                            "lon": 55.206094107557554
                        },
                        {
                            "lat": 61.35045701719342,
                            "lon": 55.20616321109621
                        },
                        {
                            "lat": 61.35057777620056,
                            "lon": 55.20436867116673
                        },
                        {
                            "lat": 61.35371562133747,
                            "lon": 55.20443768946545
                        },
                        {
                            "lat": 61.35685347994782,
                            "lon": 55.204506627114604
                        },
                        {
                            "lat": 61.356973943861604,
                            "lon": 55.2027120773108
                        },
                        {
                            "lat": 61.36011167512454,
                            "lon": 55.202780929734956
                        },
                        {
                            "lat": 61.36023198479307,
                            "lon": 55.20098637464879
                        },
                        {
                            "lat": 61.363369588704465,
                            "lon": 55.201055141858276
                        },
                        {
                            "lat": 61.363489744159104,
                            "lon": 55.19926058149591
                        },
                        {
                            "lat": 61.36360988619346,
                            "lon": 55.19746602042628
                        },
                        {
                            "lat": 61.366747221986955,
                            "lon": 55.1975346978687
                        },
                        {
                            "lat": 61.36988457118303,
                            "lon": 55.197603294679894
                        },
                        {
                            "lat": 61.36976471067527,
                            "lon": 55.199397864869695
                        },
                        {
                            "lat": 61.36662722071488,
                            "lon": 55.199329263501085
                        },
                        {
                            "lat": 61.36650720603818,
                            "lon": 55.201123828426624
                        },
                        {
                            "lat": 61.36964483677856,
                            "lon": 55.20119243435309
                        },
                        {
                            "lat": 61.37278248090991,
                            "lon": 55.20126095963689
                        },
                        {
                            "lat": 61.37290221402461,
                            "lon": 55.19946638560102
                        },
                        {
                            "lat": 61.376039730747216,
                            "lon": 55.199534825694315
                        },
                        {
                            "lat": 61.376159309720244,
                            "lon": 55.19774024640567
                        },
                        {
                            "lat": 61.37929669903003,
                            "lon": 55.19780860131875
                        },
                        {
                            "lat": 61.38243410167973,
                            "lon": 55.19787687559764
                        },
                        {
                            "lat": 61.38255338578564,
                            "lon": 55.196082286526774
                        },
                        {
                            "lat": 61.38569066100075,
                            "lon": 55.19615047564039
                        },
                        {
                            "lat": 61.385809791041304,
                            "lon": 55.19435588133485
                        },
                        {
                            "lat": 61.3889469388178,
                            "lon": 55.19442398529357
                        },
                        {
                            "lat": 61.392084099883135,
                            "lon": 55.19449200862656
                        },
                        {
                            "lat": 61.392202935158124,
                            "lon": 55.19269740457365
                        },
                        {
                            "lat": 61.39533996876309,
                            "lon": 55.19276534276668
                        },
                        {
                            "lat": 61.39545865004902,
                            "lon": 55.19097073349716
                        },
                        {
                            "lat": 61.398595556189605,
                            "lon": 55.191038586560566
                        },
                        {
                            "lat": 61.401732475568,
                            "lon": 55.19110635900667
                        },
                        {
                            "lat": 61.401614075723884,
                            "lon": 55.1929009772863
                        },
                        {
                            "lat": 61.39847701562358,
                            "lon": 55.1928332003378
                        },
                        {
                            "lat": 61.398358461817715,
                            "lon": 55.19462781341232
                        },
                        {
                            "lat": 61.40149566265561,
                            "lon": 55.19469559486361
                        },
                        {
                            "lat": 61.401377236361135,
                            "lon": 55.19649021173857
                        },
                        {
                            "lat": 61.39823989476997,
                            "lon": 55.19642242578408
                        },
                        {
                            "lat": 61.39812131447839,
                            "lon": 55.198217037453105
                        },
                        {
                            "lat": 61.39800272094088,
                            "lon": 55.20001164841936
                        },
                        {
                            "lat": 61.40114034408555,
                            "lon": 55.20007944338147
                        },
                        {
                            "lat": 61.401021878100416,
                            "lon": 55.20187405814936
                        },
                        {
                            "lat": 61.40090339888104,
                            "lon": 55.20366867221485
                        },
                        {
                            "lat": 61.404041316878164,
                            "lon": 55.20373639553076
                        },
                        {
                            "lat": 61.40717924809587,
                            "lon": 55.203804038190505
                        },
                        {
                            "lat": 61.4103171925185,
                            "lon": 55.20387160019335
                        },
                        {
                            "lat": 61.41345515013033,
                            "lon": 55.203939081538536
                        },
                        {
                            "lat": 61.41357306606582,
                            "lon": 55.20214444950722
                        },
                        {
                            "lat": 61.416710896025194,
                            "lon": 55.20221184571598
                        },
                        {
                            "lat": 61.419848739140456,
                            "lon": 55.20227916127093
                        },
                        {
                            "lat": 61.42298659539588,
                            "lon": 55.20234639617142
                        },
                        {
                            "lat": 61.42612446477582,
                            "lon": 55.20241355041665
                        },
                        {
                            "lat": 61.42926234726457,
                            "lon": 55.20248062400593
                        },
                        {
                            "lat": 61.42914513547906,
                            "lon": 55.204275278374276
                        },
                        {
                            "lat": 61.43228317189695,
                            "lon": 55.20434227575823
                        },
                        {
                            "lat": 61.43216608786796,
                            "lon": 55.206136933879556
                        },
                        {
                            "lat": 61.42902791059841,
                            "lon": 55.20606993204384
                        },
                        {
                            "lat": 61.42891067262061,
                            "lon": 55.2078645850146
                        },
                        {
                            "lat": 61.42577236758283,
                            "lon": 55.20779749805397
                        },
                        {
                            "lat": 61.42565497562486,
                            "lon": 55.20959214586799
                        },
                        {
                            "lat": 61.422516542822834,
                            "lon": 55.209524973772105
                        },
                        {
                            "lat": 61.42239899685332,
                            "lon": 55.211319616423225
                        },
                        {
                            "lat": 61.42553757055007,
                            "lon": 55.211386792982786
                        },
                        {
                            "lat": 61.42542015235649,
                            "lon": 55.213181439398326
                        },
                        {
                            "lat": 61.42855888008446,
                            "lon": 55.213248539733904
                        },
                        {
                            "lat": 61.431697620917554,
                            "lon": 55.21331555938072
                        },
                        {
                            "lat": 61.43158047146139,
                            "lon": 55.215110214009876
                        },
                        {
                            "lat": 61.43471936631601,
                            "lon": 55.21517715741561
                        },
                        {
                            "lat": 61.43460234471624,
                            "lon": 55.21697181579512
                        },
                        {
                            "lat": 61.4377413935964,
                            "lon": 55.21703868294875
                        },
                        {
                            "lat": 61.44088045553856,
                            "lon": 55.2171054694007
                        },
                        {
                            "lat": 61.44076370279404,
                            "lon": 55.218900135964525
                        },
                        {
                            "lat": 61.437624499884386,
                            "lon": 55.218833345074046
                        },
                        {
                            "lat": 61.43448531003873,
                            "lon": 55.21876647347656
                        },
                        {
                            "lat": 61.43134613327278,
                            "lon": 55.2186995211728
                        },
                        {
                            "lat": 61.4314633089138,
                            "lon": 55.21690486794058
                        },
                        {
                            "lat": 61.42832428620477,
                            "lon": 55.216837839385846
                        },
                        {
                            "lat": 61.428441589698174,
                            "lon": 55.2150431899093
                        },
                        {
                            "lat": 61.42530272104207,
                            "lon": 55.21497608511461
                        },
                        {
                            "lat": 61.42518527660484,
                            "lon": 55.21677073013163
                        },
                        {
                            "lat": 61.42204628012973,
                            "lon": 55.21670354017868
                        },
                        {
                            "lat": 61.41890729679511,
                            "lon": 55.21663626952774
                        },
                        {
                            "lat": 61.4157683266167,
                            "lon": 55.21656891817953
                        },
                        {
                            "lat": 61.412629369610194,
                            "lon": 55.216501486134796
                        },
                        {
                            "lat": 61.41251134823013,
                            "lon": 55.218296112559386
                        },
                        {
                            "lat": 61.41239331366046,
                            "lon": 55.220090738283055
                        },
                        {
                            "lat": 61.40925408792687,
                            "lon": 55.220023216568826
                        },
                        {
                            "lat": 61.40937226346173,
                            "lon": 55.218228595332185
                        },
                        {
                            "lat": 61.40623319189863,
                            "lon": 55.21816099740458
                        },
                        {
                            "lat": 61.403094133556536,
                            "lon": 55.21809331877733
                        },
                        {
                            "lat": 61.39995508845114,
                            "lon": 55.21802555945115
                        },
                        {
                            "lat": 61.39681605659813,
                            "lon": 55.21795771942677
                        },
                        {
                            "lat": 61.396934782705515,
                            "lon": 55.21616311548893
                        },
                        {
                            "lat": 61.39379590506003,
                            "lon": 55.2160951992805
                        },
                        {
                            "lat": 61.39391475882505,
                            "lon": 55.21430059915276
                        },
                        {
                            "lat": 61.397053495546814,
                            "lon": 55.21436851084819
                        },
                        {
                            "lat": 61.39717219512408,
                            "lon": 55.21257390550456
                        },
                        {
                            "lat": 61.39403359931032,
                            "lon": 55.212505998321724
                        },
                        {
                            "lat": 61.390895016774294,
                            "lon": 55.2124380104582
                        },
                        {
                            "lat": 61.38775644753168,
                            "lon": 55.21236994191475
                        },
                        {
                            "lat": 61.38787555651783,
                            "lon": 55.21057534942081
                        },
                        {
                            "lat": 61.384737141470566,
                            "lon": 55.210507204726404
                        },
                        {
                            "lat": 61.38159873974609,
                            "lon": 55.21043897935891
                        },
                        {
                            "lat": 61.381718117164276,
                            "lon": 55.20864439522185
                        },
                        {
                            "lat": 61.37857986964473,
                            "lon": 55.208576093720524
                        },
                        {
                            "lat": 61.37846035136004,
                            "lon": 55.21037067331905
                        },
                        {
                            "lat": 61.37532197632811,
                            "lon": 55.21030228660763
                        },
                        {
                            "lat": 61.372183614666014,
                            "lon": 55.21023381922534
                        },
                        {
                            "lat": 61.37206380126889,
                            "lon": 55.212028389024745
                        },
                        {
                            "lat": 61.36892531211619,
                            "lon": 55.2119598364174
                        },
                        {
                            "lat": 61.36880534443973,
                            "lon": 55.21375440095532
                        },
                        {
                            "lat": 61.36868536335802,
                            "lon": 55.2155489647867
                        },
                        {
                            "lat": 61.371824134309946,
                            "lon": 55.21561752650515
                        },
                        {
                            "lat": 61.371704280744055,
                            "lon": 55.21741209418612
                        },
                        {
                            "lat": 61.37158441378452,
                            "lon": 55.21920666116094
                        },
                        {
                            "lat": 61.36844536097059,
                            "lon": 55.21913809032975
                        },
                        {
                            "lat": 61.365306321568035,
                            "lon": 55.21906943880247
                        },
                        {
                            "lat": 61.36518615930575,
                            "lon": 55.220863995951774
                        },
                        {
                            "lat": 61.36506598361432,
                            "lon": 55.222658552394066
                        },
                        {
                            "lat": 61.36192667572226,
                            "lon": 55.222589811035675
                        },
                        {
                            "lat": 61.35878738127706,
                            "lon": 55.22252098897203
                        },
                        {
                            "lat": 61.358666910192795,
                            "lon": 55.22431553556494
                        },
                        {
                            "lat": 61.36180634561779,
                            "lon": 55.22438436220244
                        },
                        {
                            "lat": 61.3649457944917,
                            "lon": 55.22445310812931
                        },
                        {
                            "lat": 61.364825591935876,
                            "lon": 55.226247663157515
                        },
                        {
                            "lat": 61.364705375944745,
                            "lon": 55.22804221747867
                        },
                        {
                            "lat": 61.36156564506002,
                            "lon": 55.22797346241356
                        },
                        {
                            "lat": 61.3614452746026,
                            "lon": 55.22976801145789
                        },
                        {
                            "lat": 61.35830541614398,
                            "lon": 55.229699171096385
                        },
                        {
                            "lat": 61.35818489118887,
                            "lon": 55.23149371485774
                        },
                        {
                            "lat": 61.36132489069012,
                            "lon": 55.231562559794725
                        },
                        {
                            "lat": 61.3644649036485,
                            "lon": 55.231631323999686
                        },
                        {
                            "lat": 61.36760493004829,
                            "lon": 55.23170000747188
                        },
                        {
                            "lat": 61.370744969873805,
                            "lon": 55.231768610210544
                        },
                        {
                            "lat": 61.37388502310928,
                            "lon": 55.231837132214935
                        },
                        {
                            "lat": 61.37702508973907,
                            "lon": 55.23190557348429
                        },
                        {
                            "lat": 61.380165169747414,
                            "lon": 55.23197393401788
                        },
                        {
                            "lat": 61.3833052631186,
                            "lon": 55.23204221381495
                        },
                        {
                            "lat": 61.38644536983695,
                            "lon": 55.23211041287476
                        },
                        {
                            "lat": 61.38958548988671,
                            "lon": 55.23217853119655
                        },
                        {
                            "lat": 61.39272562325218,
                            "lon": 55.23224656877959
                        },
                        {
                            "lat": 61.395865769917656,
                            "lon": 55.232314525623124
                        },
                        {
                            "lat": 61.39900592986739,
                            "lon": 55.232382401726426
                        },
                        {
                            "lat": 61.402146103085705,
                            "lon": 55.23245019708876
                        },
                        {
                            "lat": 61.405286289556855,
                            "lon": 55.232517911709344
                        },
                        {
                            "lat": 61.40842648926514,
                            "lon": 55.23258554558747
                        },
                        {
                            "lat": 61.411566702194825,
                            "lon": 55.2326530987224
                        },
                        {
                            "lat": 61.414706928330226,
                            "lon": 55.23272057111339
                        },
                        {
                            "lat": 61.417847167655594,
                            "lon": 55.23278796275971
                        },
                        {
                            "lat": 61.42098742015522,
                            "lon": 55.232855273660604
                        },
                        {
                            "lat": 61.42412768581337,
                            "lon": 55.232922503815345
                        },
                        {
                            "lat": 61.42726796461435,
                            "lon": 55.232989653223214
                        },
                        {
                            "lat": 61.43040825654243,
                            "lon": 55.23305672188346
                        },
                        {
                            "lat": 61.43052553700259,
                            "lon": 55.231262074239616
                        },
                        {
                            "lat": 61.43064280435335,
                            "lon": 55.22946742589716
                        },
                        {
                            "lat": 61.42750279458394,
                            "lon": 55.22940036615204
                        },
                        {
                            "lat": 61.42762018988307,
                            "lon": 55.227605721568004
                        },
                        {
                            "lat": 61.430760058596746,
                            "lon": 55.22767277685614
                        },
                        {
                            "lat": 61.43389994041579,
                            "lon": 55.22773975141198
                        },
                        {
                            "lat": 61.43703983532451,
                            "lon": 55.22780664523481
                        },
                        {
                            "lat": 61.4401797433072,
                            "lon": 55.227873458323884
                        },
                        {
                            "lat": 61.4433196643481,
                            "lon": 55.22794019067845
                        },
                        {
                            "lat": 61.44343634130599,
                            "lon": 55.226145523166096
                        },
                        {
                            "lat": 61.446576134339445,
                            "lon": 55.226212170355645
                        },
                        {
                            "lat": 61.4466926572225,
                            "lon": 55.22441749771693
                        },
                        {
                            "lat": 61.44983232224449,
                            "lon": 55.224484059751816
                        },
                        {
                            "lat": 61.45297200027362,
                            "lon": 55.22455054106072
                        },
                        {
                            "lat": 61.45611169129418,
                            "lon": 55.22461694164294
                        },
                        {
                            "lat": 61.45622777808675,
                            "lon": 55.22282225505273
                        },
                        {
                            "lat": 61.45936734105653,
                            "lon": 55.222888570499904
                        },
                        {
                            "lat": 61.45925139529045,
                            "lon": 55.22468326149774
                        },
                        {
                            "lat": 61.462391112246706,
                            "lon": 55.22474950062438
                        },
                        {
                            "lat": 61.465530842147245,
                            "lon": 55.224815659022156
                        },
                        {
                            "lat": 61.46867058497636,
                            "lon": 55.224881736690335
                        },
                        {
                            "lat": 61.468555049388755,
                            "lon": 55.22667644018513
                        },
                        {
                            "lat": 61.47169494618083,
                            "lon": 55.22674244150961
                        },
                        {
                            "lat": 61.471810340718335,
                            "lon": 55.22494773362819
                        },
                        {
                            "lat": 61.474950109357444,
                            "lon": 55.225013649835034
                        },
                        {
                            "lat": 61.47506534996144,
                            "lon": 55.22321893687939
                        },
                        {
                            "lat": 61.47820499044367,
                            "lon": 55.223284767978946
                        },
                        {
                            "lat": 61.48134464378966,
                            "lon": 55.22335051835139
                        },
                        {
                            "lat": 61.48448430998369,
                            "lon": 55.223416187995966
                        },
                        {
                            "lat": 61.48762398901005,
                            "lon": 55.223481776912
                        },
                        {
                            "lat": 61.48773865263242,
                            "lon": 55.22168704579501
                        },
                        {
                            "lat": 61.49087820344496,
                            "lon": 55.22175254962805
                        },
                        {
                            "lat": 61.494017767056484,
                            "lon": 55.221817972736446
                        },
                        {
                            "lat": 61.49413213583279,
                            "lon": 55.220023232226765
                        },
                        {
                            "lat": 61.49424649182782,
                            "lon": 55.218228491026466
                        },
                        {
                            "lat": 61.49436083504351,
                            "lon": 55.21643374913557
                        },
                        {
                            "lat": 61.49122169443488,
                            "lon": 55.21636833906991
                        },
                        {
                            "lat": 61.48808256661935,
                            "lon": 55.21630284829568
                        },
                        {
                            "lat": 61.48494345161263,
                            "lon": 55.216237276813565
                        },
                        {
                            "lat": 61.481804349430405,
                            "lon": 55.21617162462428
                        },
                        {
                            "lat": 61.48191924373515,
                            "lon": 55.21437689946211
                        },
                        {
                            "lat": 61.478780295354895,
                            "lon": 55.2143111709346
                        },
                        {
                            "lat": 61.4756413598286,
                            "lon": 55.21424536170668
                        },
                        {
                            "lat": 61.475756523185204,
                            "lon": 55.21245064459335
                        },
                        {
                            "lat": 61.47889531776744,
                            "lon": 55.21251644944809
                        },
                        {
                            "lat": 61.48203412520165,
                            "lon": 55.21258217360781
                        },
                        {
                            "lat": 61.48517294547218,
                            "lon": 55.21264781707177
                        },
                        {
                            "lat": 61.488311778563315,
                            "lon": 55.212713379839286
                        },
                        {
                            "lat": 61.491450624459354,
                            "lon": 55.21277886190961
                        },
                        {
                            "lat": 61.494589483144594,
                            "lon": 55.21284426328205
                        },
                        {
                            "lat": 61.49772835460334,
                            "lon": 55.21290958395587
                        },
                        {
                            "lat": 61.5008672388199,
                            "lon": 55.21297482393039
                        },
                        {
                            "lat": 61.50098126182709,
                            "lon": 55.21118007129177
                        },
                        {
                            "lat": 61.50412001784178,
                            "lon": 55.21124522623627
                        },
                        {
                            "lat": 61.507258786580934,
                            "lon": 55.21131030048537
                        },
                        {
                            "lat": 61.50737251498948,
                            "lon": 55.20951553850316
                        },
                        {
                            "lat": 61.50748623069119,
                            "lon": 55.20772077583199
                        },
                        {
                            "lat": 61.507599933687985,
                            "lon": 55.20592601247183
                        },
                        {
                            "lat": 61.50446158769238,
                            "lon": 55.20586095119429
                        },
                        {
                            "lat": 61.50457541886937,
                            "lon": 55.204066191468236
                        },
                        {
                            "lat": 61.50468923732963,
                            "lon": 55.20227143105287
                        },
                        {
                            "lat": 61.50155118579936,
                            "lon": 55.20220629775244
                        },
                        {
                            "lat": 61.50166513239464,
                            "lon": 55.2004115409754
                        },
                        {
                            "lat": 61.49852723444274,
                            "lon": 55.20034633134001
                        },
                        {
                            "lat": 61.49864130914168,
                            "lon": 55.19855157820589
                        },
                        {
                            "lat": 61.49550356476422,
                            "lon": 55.19848629224655
                        },
                        {
                            "lat": 61.49561776753548,
                            "lon": 55.19669154275992
                        },
                        {
                            "lat": 61.49248017672862,
                            "lon": 55.196626180487655
                        },
                        {
                            "lat": 61.49236583314474,
                            "lon": 55.1984209256314
                        },
                        {
                            "lat": 61.49225147678722,
                            "lon": 55.200215670084276
                        },
                        {
                            "lat": 61.49213710765412,
                            "lon": 55.2020104138463
                        },
                        {
                            "lat": 61.48899910713985,
                            "lon": 55.20194495787835
                        },
                        {
                            "lat": 61.48586111941877,
                            "lon": 55.2018794212453
                        },
                        {
                            "lat": 61.485975770234006,
                            "lon": 55.20008468618668
                        },
                        {
                            "lat": 61.482837936159974,
                            "lon": 55.200019073248974
                        },
                        {
                            "lat": 61.47970011490858,
                            "lon": 55.19995337965293
                        },
                        {
                            "lat": 61.476562306495474,
                            "lon": 55.19988760539932
                        },
                        {
                            "lat": 61.47342451093636,
                            "lon": 55.1998217504888
                        },
                        {
                            "lat": 61.470286728246926,
                            "lon": 55.199755814922135
                        },
                        {
                            "lat": 61.46714895844284,
                            "lon": 55.19968979870001
                        },
                        {
                            "lat": 61.467264441356605,
                            "lon": 55.197895089185906
                        },
                        {
                            "lat": 61.46412682526426,
                            "lon": 55.19782899670046
                        },
                        {
                            "lat": 61.464242436073995,
                            "lon": 55.196034290883375
                        },
                        {
                            "lat": 61.46110497368947,
                            "lon": 55.19596812214563
                        },
                        {
                            "lat": 61.457967524233396,
                            "lon": 55.19590187276526
                        },
                        {
                            "lat": 61.45483008772145,
                            "lon": 55.19583554274303
                        },
                        {
                            "lat": 61.451692664169286,
                            "lon": 55.19576913207967
                        },
                        {
                            "lat": 61.448555253592616,
                            "lon": 55.19570264077586
                        },
                        {
                            "lat": 61.44541785600707,
                            "lon": 55.195636068832364
                        },
                        {
                            "lat": 61.44553429853885,
                            "lon": 55.19384138877679
                        },
                        {
                            "lat": 61.44565072806581,
                            "lon": 55.19204670802449
                        },
                        {
                            "lat": 61.44576714458989,
                            "lon": 55.19025202657548
                        },
                        {
                            "lat": 61.44263018226082,
                            "lon": 55.19018538727585
                        },
                        {
                            "lat": 61.44274672650257,
                            "lon": 55.18839070955698
                        },
                        {
                            "lat": 61.43960991790662,
                            "lon": 55.188323994067304
                        },
                        {
                            "lat": 61.43972658983473,
                            "lon": 55.18652932008311
                        },
                        {
                            "lat": 61.44286325772976,
                            "lon": 55.186596031141
                        },
                        {
                            "lat": 61.445999938637385,
                            "lon": 55.186662661587384
                        },
                        {
                            "lat": 61.445883548113066,
                            "lon": 55.188457344429764
                        },
                        {
                            "lat": 61.449020382722495,
                            "lon": 55.18852389868498
                        },
                        {
                            "lat": 61.448904119919824,
                            "lon": 55.19031858525216
                        },
                        {
                            "lat": 61.44878784413194,
                            "lon": 55.19211327112304
                        },
                        {
                            "lat": 61.451924973185264,
                            "lon": 55.19217975359258
                        },
                        {
                            "lat": 61.455062115210104,
                            "lon": 55.1922461554324
                        },
                        {
                            "lat": 61.45819927019082,
                            "lon": 55.192312476641725
                        },
                        {
                            "lat": 61.46133643811171,
                            "lon": 55.192378717219896
                        },
                        {
                            "lat": 61.46447361895711,
                            "lon": 55.192444877166146
                        },
                        {
                            "lat": 61.46435803397096,
                            "lon": 55.194239584371935
                        },
                        {
                            "lat": 61.46749536848888,
                            "lon": 55.19430566807577
                        },
                        {
                            "lat": 61.47063271590197,
                            "lon": 55.19437167114091
                        },
                        {
                            "lat": 61.47051739956571,
                            "lon": 55.196166386428246
                        },
                        {
                            "lat": 61.47365490064154,
                            "lon": 55.196232313233885
                        },
                        {
                            "lat": 61.476792414583116,
                            "lon": 55.19629815939407
                        },
                        {
                            "lat": 61.47690744935084,
                            "lon": 55.194503435352246
                        },
                        {
                            "lat": 61.477022471270445,
                            "lon": 55.19270871061763
                        },
                        {
                            "lat": 61.47713748034389,
                            "lon": 55.19091398519026
                        },
                        {
                            "lat": 61.477252476573106,
                            "lon": 55.18911925907012
                        },
                        {
                            "lat": 61.47736745996009,
                            "lon": 55.18732453225726
                        },
                        {
                            "lat": 61.47423064978803,
                            "lon": 55.187258707966045
                        },
                        {
                            "lat": 61.47434576104162,
                            "lon": 55.185463984833085
                        },
                        {
                            "lat": 61.47120910443048,
                            "lon": 55.185398084301085
                        },
                        {
                            "lat": 61.47132434351932,
                            "lon": 55.18360336485256
                        },
                        {
                            "lat": 61.46818784046518,
                            "lon": 55.183537388090784
                        },
                        {
                            "lat": 61.46830320735799,
                            "lon": 55.181742672331296
                        },
                        {
                            "lat": 61.46841856136939,
                            "lon": 55.17994795587794
                        },
                        {
                            "lat": 61.46853390250134,
                            "lon": 55.17815323873072
                        },
                        {
                            "lat": 61.468649230755815,
                            "lon": 55.176358520889664
                        },
                        {
                            "lat": 61.465513303169914,
                            "lon": 55.176292481070305
                        },
                        {
                            "lat": 61.46539783429271,
                            "lon": 55.178087194524686
                        },
                        {
                            "lat": 61.46226177897722,
                            "lon": 55.17802106972874
                        },
                        {
                            "lat": 61.45912573657052,
                            "lon": 55.17795486434362
                        },
                        {
                            "lat": 61.459241486687326,
                            "lon": 55.176160159678616
                        },
                        {
                            "lat": 61.45935722388167,
                            "lon": 55.174365454318604
                        },
                        {
                            "lat": 61.45947294815551,
                            "lon": 55.17257074826358
                        },
                        {
                            "lat": 61.45958865951081,
                            "lon": 55.170776041513584
                        },
                        {
                            "lat": 61.45645319240207,
                            "lon": 55.1707097731484
                        },
                        {
                            "lat": 61.45633734047685,
                            "lon": 55.17250447549689
                        },
                        {
                            "lat": 61.4532017457324,
                            "lon": 55.172438122158475
                        },
                        {
                            "lat": 61.45006616393775,
                            "lon": 55.17237168824905
                        },
                        {
                            "lat": 61.4501822969969,
                            "lon": 55.1705769947196
                        },
                        {
                            "lat": 61.45029841709256,
                            "lon": 55.16878230049397
                        },
                        {
                            "lat": 61.45041452422667,
                            "lon": 55.16698760557223
                        },
                        {
                            "lat": 61.44727937704262,
                            "lon": 55.166921104343906
                        },
                        {
                            "lat": 61.44739561173429,
                            "lon": 55.16512641314237
                        },
                        {
                            "lat": 61.450530618401245,
                            "lon": 55.16519290995437
                        },
                        {
                            "lat": 61.453665638025775,
                            "lon": 55.16525932621741
                        },
                        {
                            "lat": 61.45680067059228,
                            "lon": 55.16532566193082
                        },
                        {
                            "lat": 61.45691647080041,
                            "lon": 55.16353095680095
                        },
                        {
                            "lat": 61.453781578739324,
                            "lon": 55.16346462549282
                        },
                        {
                            "lat": 61.45064669961823,
                            "lon": 55.16339821364038
                        },
                        {
                            "lat": 61.44751183345274,
                            "lon": 55.163331721244305
                        },
                        {
                            "lat": 61.444376980258475,
                            "lon": 55.1632651483054
                        },
                        {
                            "lat": 61.44426061804052,
                            "lon": 55.16505983578218
                        },
                        {
                            "lat": 61.44112563733562,
                            "lon": 55.16499317787447
                        },
                        {
                            "lat": 61.43799066963519,
                            "lon": 55.16492643942002
                        },
                        {
                            "lat": 61.43810731284618,
                            "lon": 55.1631317608018
                        },
                        {
                            "lat": 61.441242140051074,
                            "lon": 55.16319849482431
                        },
                        {
                            "lat": 61.441358629764046,
                            "lon": 55.16140381107684
                        },
                        {
                            "lat": 61.43822394303907,
                            "lon": 55.161337081485875
                        },
                        {
                            "lat": 61.438340560215785,
                            "lon": 55.15954240147228
                        },
                        {
                            "lat": 61.441475106476474,
                            "lon": 55.159609126632084
                        },
                        {
                            "lat": 61.44159157019038,
                            "lon": 55.15781444149008
                        },
                        {
                            "lat": 61.43845716437834,
                            "lon": 55.15774772076102
                        },
                        {
                            "lat": 61.43857375552871,
                            "lon": 55.15595303935213
                        },
                        {
                            "lat": 61.43543950315992,
                            "lon": 55.15588624253406
                        },
                        {
                            "lat": 61.43532277157848,
                            "lon": 55.15768091950726
                        },
                        {
                            "lat": 61.432188391806456,
                            "lon": 55.15761403772953
                        },
                        {
                            "lat": 61.432305263816964,
                            "lon": 55.15581936519735
                        },
                        {
                            "lat": 61.432422122786015,
                            "lon": 55.154024691966725
                        },
                        {
                            "lat": 61.42928803689599,
                            "lon": 55.15395773855805
                        },
                        {
                            "lat": 61.42615396406102,
                            "lon": 55.15389070463754
                        },
                        {
                            "lat": 61.426271090780354,
                            "lon": 55.15209603960494
                        },
                        {
                            "lat": 61.42313717140799,
                            "lon": 55.15202892962953
                        },
                        {
                            "lat": 61.42325442543489,
                            "lon": 55.150234268353564
                        },
                        {
                            "lat": 61.426388204430964,
                            "lon": 55.150301373873155
                        },
                        {
                            "lat": 61.42952199649378,
                            "lon": 55.1503683988923
                        },
                        {
                            "lat": 61.429638956715024,
                            "lon": 55.14857372801124
                        },
                        {
                            "lat": 61.429755903887134,
                            "lon": 55.146779056431406
                        },
                        {
                            "lat": 61.42662239253404,
                            "lon": 55.146712040312046
                        },
                        {
                            "lat": 61.42673946699044,
                            "lon": 55.14491737248276
                        },
                        {
                            "lat": 61.426856528386075,
                            "lon": 55.14312270395431
                        },
                        {
                            "lat": 61.423723310739035,
                            "lon": 55.14305561625401
                        },
                        {
                            "lat": 61.423606109029535,
                            "lon": 55.144850280328235
                        },
                        {
                            "lat": 61.420472764144996,
                            "lon": 55.14478310768995
                        },
                        {
                            "lat": 61.42035540903166,
                            "lon": 55.146577766604686
                        },
                        {
                            "lat": 61.42023804082437,
                            "lon": 55.14837242481945
                        },
                        {
                            "lat": 61.41710442836524,
                            "lon": 55.1483051627672
                        },
                        {
                            "lat": 61.413970829017664,
                            "lon": 55.14823782022203
                        },
                        {
                            "lat": 61.41408847790514,
                            "lon": 55.14644317094397
                        },
                        {
                            "lat": 61.41420611366744,
                            "lon": 55.14464852096513
                        },
                        {
                            "lat": 61.41432373630656,
                            "lon": 55.14285387028557
                        },
                        {
                            "lat": 61.41444134582451,
                            "lon": 55.14105921890527
                        },
                        {
                            "lat": 61.41130832085885,
                            "lon": 55.14099181377086
                        },
                        {
                            "lat": 61.41142605753229,
                            "lon": 55.13919716616461
                        },
                        {
                            "lat": 61.411543781072965,
                            "lon": 55.13740251785723
                        },
                        {
                            "lat": 61.41467652550481,
                            "lon": 55.13746991404257
                        },
                        {
                            "lat": 61.417809283051874,
                            "lon": 55.13753722976768
                        },
                        {
                            "lat": 61.41769184003139,
                            "lon": 55.139331887018386
                        },
                        {
                            "lat": 61.420824750941144,
                            "lon": 55.13939912674621
                        },
                        {
                            "lat": 61.42094205369852,
                            "lon": 55.137604465031814
                        },
                        {
                            "lat": 61.42105934337191,
                            "lon": 55.13580980261753
                        },
                        {
                            "lat": 61.42117661996325,
                            "lon": 55.13401513950336
                        },
                        {
                            "lat": 61.41804412979598,
                            "lon": 55.1339479131654
                        },
                        {
                            "lat": 61.41816153352361,
                            "lon": 55.132153253813875
                        },
                        {
                            "lat": 61.4150291966663,
                            "lon": 55.132085951493416
                        },
                        {
                            "lat": 61.41189687291819,
                            "lon": 55.1320185687287
                        },
                        {
                            "lat": 61.411779188763916,
                            "lon": 55.13381321913927
                        },
                        {
                            "lat": 61.40864673793029,
                            "lon": 55.13374575145255
                        },
                        {
                            "lat": 61.40852890042329,
                            "lon": 55.13554039668321
                        },
                        {
                            "lat": 61.40539632250812,
                            "lon": 55.135472844064196
                        },
                        {
                            "lat": 61.40551430023904,
                            "lon": 55.133678203317736
                        },
                        {
                            "lat": 61.40238187570577,
                            "lon": 55.13361057473554
                        },
                        {
                            "lat": 61.40249998048507,
                            "lon": 55.13181593777633
                        },
                        {
                            "lat": 61.405632264812006,
                            "lon": 55.13188356186942
                        },
                        {
                            "lat": 61.40575021622898,
                            "lon": 55.13008891971924
                        },
                        {
                            "lat": 61.40888237351913,
                            "lon": 55.13015645888689
                        },
                        {
                            "lat": 61.40900017160497,
                            "lon": 55.1283618115519
                        },
                        {
                            "lat": 61.41213220185434,
                            "lon": 55.128429265804414
                        },
                        {
                            "lat": 61.41526424522451,
                            "lon": 55.12849663962406
                        },
                        {
                            "lat": 61.41538174984479,
                            "lon": 55.12670198263845
                        },
                        {
                            "lat": 61.41224984664018,
                            "lon": 55.126634613290754
                        },
                        {
                            "lat": 61.412367478307196,
                            "lon": 55.12483996007607
                        },
                        {
                            "lat": 61.40923572836944,
                            "lon": 55.12477251477769
                        },
                        {
                            "lat": 61.40610399156407,
                            "lon": 55.12470498905781
                        },
                        {
                            "lat": 61.40297226790669,
                            "lon": 55.124637382917165
                        },
                        {
                            "lat": 61.4030903068482,
                            "lon": 55.12284274244686
                        },
                        {
                            "lat": 61.403208332628154,
                            "lon": 55.121048101274376
                        },
                        {
                            "lat": 61.406339776044234,
                            "lon": 55.1211156984412
                        },
                        {
                            "lat": 61.40947123260428,
                            "lon": 55.12118321519792
                        },
                        {
                            "lat": 61.41260270229275,
                            "lon": 55.121250651543775
                        },
                        {
                            "lat": 61.41272029461524,
                            "lon": 55.11945599622618
                        },
                        {
                            "lat": 61.41585163731327,
                            "lon": 55.11952334769013
                        },
                        {
                            "lat": 61.4159690764372,
                            "lon": 55.11772868720163
                        },
                        {
                            "lat": 61.41283787382688,
                            "lon": 55.11766134020764
                        },
                        {
                            "lat": 61.41295543992967,
                            "lon": 55.11586668348818
                        },
                        {
                            "lat": 61.4098243904984,
                            "lon": 55.115799260568096
                        },
                        {
                            "lat": 61.409942083548906,
                            "lon": 55.114004607622164
                        },
                        {
                            "lat": 61.40681118729267,
                            "lon": 55.113937108786985
                        },
                        {
                            "lat": 61.40368030417243,
                            "lon": 55.113869529563
                        },
                        {
                            "lat": 61.40054943420377,
                            "lon": 55.11380186995088
                        },
                        {
                            "lat": 61.39741857740221,
                            "lon": 55.11373412995144
                        },
                        {
                            "lat": 61.39428773378336,
                            "lon": 55.113666309565346
                        },
                        {
                            "lat": 61.39115690336272,
                            "lon": 55.113598408793386
                        },
                        {
                            "lat": 61.39127542348509,
                            "lon": 55.11180378210225
                        },
                        {
                            "lat": 61.39440611387868,
                            "lon": 55.11187167836824
                        },
                        {
                            "lat": 61.39452448077776,
                            "lon": 55.11007704646781
                        },
                        {
                            "lat": 61.39765504435407,
                            "lon": 55.110144857852966
                        },
                        {
                            "lat": 61.40078562110907,
                            "lon": 55.11021258886214
                        },
                        {
                            "lat": 61.40090369481603,
                            "lon": 55.10841794726404
                        },
                        {
                            "lat": 61.404034144732215,
                            "lon": 55.10848559340726
                        },
                        {
                            "lat": 61.407164607793966,
                            "lon": 55.108553159178356
                        },
                        {
                            "lat": 61.41029508398573,
                            "lon": 55.10862064457657
                        },
                        {
                            "lat": 61.410412724566406,
                            "lon": 55.10682598882552
                        },
                        {
                            "lat": 61.41354307388029,
                            "lon": 55.106893389377255
                        },
                        {
                            "lat": 61.416673436291056,
                            "lon": 55.10696070956001
                        },
                        {
                            "lat": 61.41679078380364,
                            "lon": 55.105166044168044
                        },
                        {
                            "lat": 61.41366056137167,
                            "lon": 55.10509872845247
                        },
                        {
                            "lat": 61.413778035768125,
                            "lon": 55.103304066826844
                        },
                        {
                            "lat": 61.41389549707162,
                            "lon": 55.10150940450038
                        },
                        {
                            "lat": 61.41076556764156,
                            "lon": 55.10144201736482
                        },
                        {
                            "lat": 61.41088315578427,
                            "lon": 55.09964735880884
                        },
                        {
                            "lat": 61.40775337938892,
                            "lon": 55.099579895797525
                        },
                        {
                            "lat": 61.40462361611361,
                            "lon": 55.099512352439945
                        },
                        {
                            "lat": 61.40149386597392,
                            "lon": 55.099444728736806
                        },
                        {
                            "lat": 61.39836412898535,
                            "lon": 55.099377024688856
                        },
                        {
                            "lat": 61.395234405163464,
                            "lon": 55.09930924029682
                        },
                        {
                            "lat": 61.39535267974497,
                            "lon": 55.097514603473705
                        },
                        {
                            "lat": 61.39848226366218,
                            "lon": 55.0975823833683
                        },
                        {
                            "lat": 61.398600385174326,
                            "lon": 55.09578774134497
                        },
                        {
                            "lat": 61.39547094114624,
                            "lon": 55.09571996594744
                        },
                        {
                            "lat": 61.39234151029637,
                            "lon": 55.09565211021718
                        },
                        {
                            "lat": 61.39222310900795,
                            "lon": 55.09744674324111
                        },
                        {
                            "lat": 61.38909355146671,
                            "lon": 55.09737880267121
                        },
                        {
                            "lat": 61.38596400713675,
                            "lon": 55.09731078176476
                        },
                        {
                            "lat": 61.38584531285327,
                            "lon": 55.09910540506362
                        },
                        {
                            "lat": 61.38271564185349,
                            "lon": 55.099037299302644
                        },
                        {
                            "lat": 61.382596794428956,
                            "lon": 55.100831917377995
                        },
                        {
                            "lat": 61.379466996763384,
                            "lon": 55.1007637267522
                        },
                        {
                            "lat": 61.37633721235973,
                            "lon": 55.10069545578145
                        },
                        {
                            "lat": 61.376456339602576,
                            "lon": 55.09890084676026
                        },
                        {
                            "lat": 61.37657545356999,
                            "lon": 55.09710623703347
                        },
                        {
                            "lat": 61.37970495817287,
                            "lon": 55.09717449894514
                        },
                        {
                            "lat": 61.37982391898975,
                            "lon": 55.09537988398381
                        },
                        {
                            "lat": 61.37669455426397,
                            "lon": 55.09531162660107
                        },
                        {
                            "lat": 61.37681364168652,
                            "lon": 55.093517015463085
                        },
                        {
                            "lat": 61.379942866550735,
                            "lon": 55.09358526831729
                        },
                        {
                            "lat": 61.38006180085784,
                            "lon": 55.091790651945594
                        },
                        {
                            "lat": 61.38018072191309,
                            "lon": 55.08999603486876
                        },
                        {
                            "lat": 61.38029962971848,
                            "lon": 55.08820141708674
                        },
                        {
                            "lat": 61.38041852427603,
                            "lon": 55.08640679859963
                        },
                        {
                            "lat": 61.38053740558774,
                            "lon": 55.08461217940741
                        },
                        {
                            "lat": 61.38366594462087,
                            "lon": 55.08468032932788
                        },
                        {
                            "lat": 61.38378467291834,
                            "lon": 55.08288570490974
                        },
                        {
                            "lat": 61.38390338798949,
                            "lon": 55.08109107978695
                        },
                        {
                            "lat": 61.3870316607216,
                            "lon": 55.08115914037897
                        },
                        {
                            "lat": 61.390159946662486,
                            "lon": 55.081227120682996
                        },
                        {
                            "lat": 61.39027836902455,
                            "lon": 55.079432485831475
                        },
                        {
                            "lat": 61.39340652841329,
                            "lon": 55.07950038134272
                        },
                        {
                            "lat": 61.39653470097776,
                            "lon": 55.079568196569795
                        },
                        {
                            "lat": 61.39665283068679,
                            "lon": 55.07777355201243
                        },
                        {
                            "lat": 61.39677094723758,
                            "lon": 55.075978906752034
                        },
                        {
                            "lat": 61.399898853510045,
                            "lon": 55.07604663270854
                        },
                        {
                            "lat": 61.40001681720173,
                            "lon": 55.07425198225288
                        },
                        {
                            "lat": 61.396889050632126,
                            "lon": 55.07418426078861
                        },
                        {
                            "lat": 61.397007140872425,
                            "lon": 55.072389614122194
                        },
                        {
                            "lat": 61.39387952714243,
                            "lon": 55.072321816886095
                        },
                        {
                            "lat": 61.39376129721678,
                            "lon": 55.07411645905538
                        },
                        {
                            "lat": 61.390633556971174,
                            "lon": 55.0740485770539
                        },
                        {
                            "lat": 61.39051517417689,
                            "lon": 55.07584321401691
                        },
                        {
                            "lat": 61.387387307419665,
                            "lon": 55.07577524723979
                        },
                        {
                            "lat": 61.38425945386523,
                            "lon": 55.0757072001906
                        },
                        {
                            "lat": 61.38113161352904,
                            "lon": 55.07563907287006
                        },
                        {
                            "lat": 61.38101279841639,
                            "lon": 55.07743369558765
                        },
                        {
                            "lat": 61.37788483160762,
                            "lon": 55.077365483472036
                        },
                        {
                            "lat": 61.374756878050185,
                            "lon": 55.07729719108128
                        },
                        {
                            "lat": 61.37463777026,
                            "lon": 55.079091804038725
                        },
                        {
                            "lat": 61.371509690251486,
                            "lon": 55.079023426838035
                        },
                        {
                            "lat": 61.37162893775954,
                            "lon": 55.077228818416096
                        },
                        {
                            "lat": 61.3685010107512,
                            "lon": 55.077160365477255
                        },
                        {
                            "lat": 61.36862038467725,
                            "lon": 55.075365760889504
                        },
                        {
                            "lat": 61.37174817198525,
                            "lon": 55.07543420928792
                        },
                        {
                            "lat": 61.37186739293067,
                            "lon": 55.07363959945351
                        },
                        {
                            "lat": 61.37198660059779,
                            "lon": 55.071844988912865
                        },
                        {
                            "lat": 61.37210579498864,
                            "lon": 55.07005037766602
                        },
                        {
                            "lat": 61.36897842668858,
                            "lon": 55.06998194288643
                        },
                        {
                            "lat": 61.365851071678286,
                            "lon": 55.069913427855134
                        },
                        {
                            "lat": 61.36272372997323,
                            "lon": 55.069844832572876
                        },
                        {
                            "lat": 61.35959640158895,
                            "lon": 55.06977615704044
                        },
                        {
                            "lat": 61.3597161412467,
                            "lon": 55.06798156327444
                        },
                        {
                            "lat": 61.356588965828905,
                            "lon": 55.067912812052555
                        },
                        {
                            "lat": 61.353461803760844,
                            "lon": 55.06784398058724
                        },
                        {
                            "lat": 61.35033465505799,
                            "lon": 55.067775068879264
                        },
                        {
                            "lat": 61.34720751973587,
                            "lon": 55.0677060769294
                        },
                        {
                            "lat": 61.344080397809954,
                            "lon": 55.06763700473835
                        },
                        {
                            "lat": 61.34095328929571,
                            "lon": 55.06756785230691
                        },
                        {
                            "lat": 61.34083271188695,
                            "lon": 55.06936241863313
                        },
                        {
                            "lat": 61.340712121048924,
                            "lon": 55.0711569842491
                        },
                        {
                            "lat": 61.340591516779654,
                            "lon": 55.0729515491548
                        },
                        {
                            "lat": 61.340470899077076,
                            "lon": 55.074746113350194
                        },
                        {
                            "lat": 61.34035026793914,
                            "lon": 55.07654067683527
                        },
                        {
                            "lat": 61.340229623363825,
                            "lon": 55.078335239610034
                        },
                        {
                            "lat": 61.34010896534909,
                            "lon": 55.080129801674474
                        },
                        {
                            "lat": 61.3399882938929,
                            "lon": 55.08192436302853
                        }
                    ],
                    [
                        {
                            "lat": 61.374310016132455,
                            "lon": 55.13120370116789
                        },
                        {
                            "lat": 61.37419064958644,
                            "lon": 55.132998297485464
                        },
                        {
                            "lat": 61.37105835749003,
                            "lon": 55.1329298644701
                        },
                        {
                            "lat": 61.37117786422226,
                            "lon": 55.13113527269489
                        },
                        {
                            "lat": 61.374310016132455,
                            "lon": 55.13120370116789
                        }
                    ],
                    [
                        {
                            "lat": 61.37732295499668,
                            "lon": 55.133066650060826
                        },
                        {
                            "lat": 61.377203715340684,
                            "lon": 55.13486125020996
                        },
                        {
                            "lat": 61.37708446238442,
                            "lon": 55.13665584965357
                        },
                        {
                            "lat": 61.37395187655095,
                            "lon": 55.13658748800291
                        },
                        {
                            "lat": 61.37407126972663,
                            "lon": 55.13479289309715
                        },
                        {
                            "lat": 61.37419064958644,
                            "lon": 55.132998297485464
                        },
                        {
                            "lat": 61.37732295499668,
                            "lon": 55.133066650060826
                        }
                    ],
                    [
                        {
                            "lat": 61.38021706152015,
                            "lon": 55.136724130852855
                        },
                        {
                            "lat": 61.38009793549865,
                            "lon": 55.1385187341239
                        },
                        {
                            "lat": 61.37997879618843,
                            "lon": 55.140313336689815
                        },
                        {
                            "lat": 61.37684591656308,
                            "lon": 55.140245046424255
                        },
                        {
                            "lat": 61.376965196125894,
                            "lon": 55.13845044839167
                        },
                        {
                            "lat": 61.37708446238442,
                            "lon": 55.13665584965357
                        },
                        {
                            "lat": 61.38021706152015,
                            "lon": 55.136724130852855
                        }
                    ],
                    [
                        {
                            "lat": 61.41871106167279,
                            "lon": 55.17170291790933
                        },
                        {
                            "lat": 61.41557562335852,
                            "lon": 55.17163559776686
                        },
                        {
                            "lat": 61.41569330251275,
                            "lon": 55.16984095312361
                        },
                        {
                            "lat": 61.418828600282886,
                            "lon": 55.16990826879522
                        },
                        {
                            "lat": 61.41871106167279,
                            "lon": 55.17170291790933
                        }
                    ],
                    [
                        {
                            "lat": 61.40185086218994,
                            "lon": 55.18931174002474
                        },
                        {
                            "lat": 61.40498765408223,
                            "lon": 55.18937942735616
                        },
                        {
                            "lat": 61.40812445917899,
                            "lon": 55.18944703407418
                        },
                        {
                            "lat": 61.40800635397561,
                            "lon": 55.19124166204407
                        },
                        {
                            "lat": 61.404869408168544,
                            "lon": 55.19117405083475
                        },
                        {
                            "lat": 61.401732475568,
                            "lon": 55.19110635900667
                        },
                        {
                            "lat": 61.40185086218994,
                            "lon": 55.18931174002474
                        }
                    ],
                    [
                        {
                            "lat": 61.431814757284315,
                            "lon": 55.21152090405309
                        },
                        {
                            "lat": 61.43495337029043,
                            "lon": 55.211587838562345
                        },
                        {
                            "lat": 61.43483637484008,
                            "lon": 55.21338249833803
                        },
                        {
                            "lat": 61.431697620917554,
                            "lon": 55.21331555938072
                        },
                        {
                            "lat": 61.431814757284315,
                            "lon": 55.21152090405309
                        }
                    ],
                    [
                        {
                            "lat": 61.43658993496792,
                            "lon": 55.18646252841437
                        },
                        {
                            "lat": 61.43345329314503,
                            "lon": 55.18639565613556
                        },
                        {
                            "lat": 61.43031666438169,
                            "lon": 55.1863287032474
                        },
                        {
                            "lat": 61.427180048693586,
                            "lon": 55.18626166975063
                        },
                        {
                            "lat": 61.424043446096334,
                            "lon": 55.18619455564597
                        },
                        {
                            "lat": 61.42392607069349,
                            "lon": 55.18798920739125
                        },
                        {
                            "lat": 61.42078934051381,
                            "lon": 55.187922008215615
                        },
                        {
                            "lat": 61.41765262345831,
                            "lon": 55.18785472842822
                        },
                        {
                            "lat": 61.417770280237114,
                            "lon": 55.1860600856159
                        },
                        {
                            "lat": 61.42090685660564,
                            "lon": 55.186127360934144
                        },
                        {
                            "lat": 61.421024359575256,
                            "lon": 55.18433271295283
                        },
                        {
                            "lat": 61.42416080839265,
                            "lon": 55.184399903201246
                        },
                        {
                            "lat": 61.42729727031459,
                            "lon": 55.18446701284784
                        },
                        {
                            "lat": 61.430433745325395,
                            "lon": 55.184534041891894
                        },
                        {
                            "lat": 61.433570233409434,
                            "lon": 55.18460099033266
                        },
                        {
                            "lat": 61.436706734551045,
                            "lon": 55.18466785816942
                        },
                        {
                            "lat": 61.439843248734576,
                            "lon": 55.18473464540145
                        },
                        {
                            "lat": 61.43972658983473,
                            "lon": 55.18652932008311
                        },
                        {
                            "lat": 61.43658993496792,
                            "lon": 55.18646252841437
                        }
                    ],
                    [
                        {
                            "lat": 61.414633717006446,
                            "lon": 55.18599272969196
                        },
                        {
                            "lat": 61.414751501316715,
                            "lon": 55.18419809065348
                        },
                        {
                            "lat": 61.41788792387807,
                            "lon": 55.18426544210333
                        },
                        {
                            "lat": 61.417770280237114,
                            "lon": 55.1860600856159
                        },
                        {
                            "lat": 61.414633717006446,
                            "lon": 55.18599272969196
                        }
                    ],
                    [
                        {
                            "lat": 61.3519037614078,
                            "lon": 55.137901266155424
                        },
                        {
                            "lat": 61.35178335989712,
                            "lon": 55.139695827729625
                        },
                        {
                            "lat": 61.34865060058985,
                            "lon": 55.13962681333273
                        },
                        {
                            "lat": 61.34877114233483,
                            "lon": 55.13783225633979
                        },
                        {
                            "lat": 61.3519037614078,
                            "lon": 55.137901266155424
                        }
                    ],
                    [
                        {
                            "lat": 61.396170459511964,
                            "lon": 55.18020306311529
                        },
                        {
                            "lat": 61.39628904806583,
                            "lon": 55.1784084489243
                        },
                        {
                            "lat": 61.39942496960544,
                            "lon": 55.17847627044488
                        },
                        {
                            "lat": 61.399306521661906,
                            "lon": 55.180270889140786
                        },
                        {
                            "lat": 61.40244259703758,
                            "lon": 55.18033863458101
                        },
                        {
                            "lat": 61.402324276496294,
                            "lon": 55.18213325707424
                        },
                        {
                            "lat": 61.39918806049263,
                            "lon": 55.182065507134034
                        },
                        {
                            "lat": 61.396051857716714,
                            "lon": 55.18199767660325
                        },
                        {
                            "lat": 61.396170459511964,
                            "lon": 55.18020306311529
                        }
                    ],
                    [
                        {
                            "lat": 61.40942274639466,
                            "lon": 55.16970608011039
                        },
                        {
                            "lat": 61.40628748807794,
                            "lon": 55.16963852277024
                        },
                        {
                            "lat": 61.40640557567025,
                            "lon": 55.167843890869904
                        },
                        {
                            "lat": 61.40954069346443,
                            "lon": 55.167911443723575
                        },
                        {
                            "lat": 61.40942274639466,
                            "lon": 55.16970608011039
                        }
                    ],
                    [
                        {
                            "lat": 61.40557868562336,
                            "lon": 55.180406299435234
                        },
                        {
                            "lat": 61.408714787403575,
                            "lon": 55.18047388370273
                        },
                        {
                            "lat": 61.408596748124275,
                            "lon": 55.182268515179906
                        },
                        {
                            "lat": 61.40546050571204,
                            "lon": 55.18220092642312
                        },
                        {
                            "lat": 61.40557868562336,
                            "lon": 55.180406299435234
                        }
                    ],
                    [
                        {
                            "lat": 61.411733003717295,
                            "lon": 55.182336023343886
                        },
                        {
                            "lat": 61.41161509190688,
                            "lon": 55.18413065860401
                        },
                        {
                            "lat": 61.408478695664186,
                            "lon": 55.18406314595566
                        },
                        {
                            "lat": 61.408596748124275,
                            "lon": 55.182268515179906
                        },
                        {
                            "lat": 61.411733003717295,
                            "lon": 55.182336023343886
                        }
                    ],
                    [
                        {
                            "lat": 61.44658169634457,
                            "lon": 55.17768923692569
                        },
                        {
                            "lat": 61.44971768695963,
                            "lon": 55.17775576466034
                        },
                        {
                            "lat": 61.44960150202676,
                            "lon": 55.17955045540504
                        },
                        {
                            "lat": 61.446465370785106,
                            "lon": 55.1794839232513
                        },
                        {
                            "lat": 61.44658169634457,
                            "lon": 55.17768923692569
                        }
                    ],
                    [
                        {
                            "lat": 61.45111166452397,
                            "lon": 55.20474256611812
                        },
                        {
                            "lat": 61.450995425646745,
                            "lon": 55.20653725083784
                        },
                        {
                            "lat": 61.44785717012378,
                            "lon": 55.206470733021774
                        },
                        {
                            "lat": 61.44797354986459,
                            "lon": 55.20467605272176
                        },
                        {
                            "lat": 61.45111166452397,
                            "lon": 55.20474256611812
                        }
                    ],
                    [
                        {
                            "lat": 61.447042147742515,
                            "lon": 55.219033475621536
                        },
                        {
                            "lat": 61.443902918751974,
                            "lon": 55.21896684614727
                        },
                        {
                            "lat": 61.444019530527015,
                            "lon": 55.21717217515026
                        },
                        {
                            "lat": 61.447158618546084,
                            "lon": 55.217238800196704
                        },
                        {
                            "lat": 61.447042147742515,
                            "lon": 55.219033475621536
                        }
                    ],
                    [
                        {
                            "lat": 61.455647214346975,
                            "lon": 55.231795681050095
                        },
                        {
                            "lat": 61.45250695907118,
                            "lon": 55.23172926281205
                        },
                        {
                            "lat": 61.44936671679474,
                            "lon": 55.23166276382591
                        },
                        {
                            "lat": 61.449250282899584,
                            "lon": 55.233457438103954
                        },
                        {
                            "lat": 61.45239066627717,
                            "lon": 55.233523941510406
                        },
                        {
                            "lat": 61.45553106265606,
                            "lon": 55.23359036416339
                        },
                        {
                            "lat": 61.455647214346975,
                            "lon": 55.231795681050095
                        }
                    ],
                    [
                        {
                            "lat": 61.47123323902008,
                            "lon": 55.23392126610109
                        },
                        {
                            "lat": 61.4743737130767,
                            "lon": 55.23398720421812
                        },
                        {
                            "lat": 61.477514200024586,
                            "lon": 55.234053061576645
                        },
                        {
                            "lat": 61.48065469984799,
                            "lon": 55.2341188381759
                        },
                        {
                            "lat": 61.48379521253119,
                            "lon": 55.234184534015206
                        },
                        {
                            "lat": 61.48693573805846,
                            "lon": 55.234250149093846
                        },
                        {
                            "lat": 61.490076276414065,
                            "lon": 55.2343156834111
                        },
                        {
                            "lat": 61.4932168275823,
                            "lon": 55.23438113696622
                        },
                        {
                            "lat": 61.49635739154741,
                            "lon": 55.23444650975852
                        },
                        {
                            "lat": 61.499497968293674,
                            "lon": 55.234511801787285
                        },
                        {
                            "lat": 61.49961214433437,
                            "lon": 55.23271705742684
                        },
                        {
                            "lat": 61.49647170871871,
                            "lon": 55.232651769738084
                        },
                        {
                            "lat": 61.496586013110786,
                            "lon": 55.230857029027334
                        },
                        {
                            "lat": 61.493445731387254,
                            "lon": 55.230791664925356
                        },
                        {
                            "lat": 61.49356016409928,
                            "lon": 55.22899692786888
                        },
                        {
                            "lat": 61.490420036263956,
                            "lon": 55.22893148736469
                        },
                        {
                            "lat": 61.49053459726447,
                            "lon": 55.22713675396709
                        },
                        {
                            "lat": 61.4873946233135,
                            "lon": 55.227071237071726
                        },
                        {
                            "lat": 61.484254662183055,
                            "lon": 55.2270056394364
                        },
                        {
                            "lat": 61.481114713888836,
                            "lon": 55.2269399610618
                        },
                        {
                            "lat": 61.477974778446594,
                            "lon": 55.226874201948654
                        },
                        {
                            "lat": 61.477859653147505,
                            "lon": 55.2286689178946
                        },
                        {
                            "lat": 61.47471958950319,
                            "lon": 55.22860307366734
                        },
                        {
                            "lat": 61.47157953874422,
                            "lon": 55.228537148697605
                        },
                        {
                            "lat": 61.47146411840654,
                            "lon": 55.230331855192205
                        },
                        {
                            "lat": 61.46832393946712,
                            "lon": 55.230265845093335
                        },
                        {
                            "lat": 61.468208365129136,
                            "lon": 55.23206054650671
                        },
                        {
                            "lat": 61.46809277787042,
                            "lon": 55.233855247226266
                        },
                        {
                            "lat": 61.47123323902008,
                            "lon": 55.23392126610109
                        }
                    ],
                    [
                        {
                            "lat": 61.51845502551104,
                            "lon": 55.233107087597666
                        },
                        {
                            "lat": 61.51531451355039,
                            "lon": 55.233042284472965
                        },
                        {
                            "lat": 61.515201043191816,
                            "lon": 55.234837050453066
                        },
                        {
                            "lat": 61.518341696294684,
                            "lon": 55.23490185788563
                        },
                        {
                            "lat": 61.5214823620686,
                            "lon": 55.23496658454968
                        },
                        {
                            "lat": 61.524623040497836,
                            "lon": 55.23503123044452
                        },
                        {
                            "lat": 61.527763731566644,
                            "lon": 55.2350957955694
                        },
                        {
                            "lat": 61.530904435259295,
                            "lon": 55.23516027992367
                        },
                        {
                            "lat": 61.531017199887394,
                            "lon": 55.23336549245778
                        },
                        {
                            "lat": 61.5278766373447,
                            "lon": 55.23330101238998
                        },
                        {
                            "lat": 61.527989530500825,
                            "lon": 55.23150622852411
                        },
                        {
                            "lat": 61.53112995190931,
                            "lon": 55.23157070430585
                        },
                        {
                            "lat": 61.53124269132696,
                            "lon": 55.22977591546788
                        },
                        {
                            "lat": 61.53135541814226,
                            "lon": 55.2279811259439
                        },
                        {
                            "lat": 61.52821527895502,
                            "lon": 55.22791665873312
                        },
                        {
                            "lat": 61.525075152383934,
                            "lon": 55.22785211077312
                        },
                        {
                            "lat": 61.52496214336503,
                            "lon": 55.229646891721174
                        },
                        {
                            "lat": 61.52182188832691,
                            "lon": 55.22958225871666
                        },
                        {
                            "lat": 61.52170872555957,
                            "lon": 55.23137703468153
                        },
                        {
                            "lat": 61.518568342058266,
                            "lon": 55.23131231662211
                        },
                        {
                            "lat": 61.51845502551104,
                            "lon": 55.233107087597666
                        }
                    ]
                ]
            },
            {
                "type": "MultiLineString",
                "value": 175.0,
                "data": [
                    [
                        {
                            "lat": 61.339494314790166,
                            "lon": 55.13583020026845
                        },
                        {
                            "lat": 61.3363618897996,
                            "lon": 55.1357608732596
                        },
                        {
                            "lat": 61.336240800644674,
                            "lon": 55.13755541258508
                        },
                        {
                            "lat": 61.3393733658458,
                            "lon": 55.13762474419609
                        },
                        {
                            "lat": 61.339494314790166,
                            "lon": 55.13583020026845
                        }
                    ],
                    [
                        {
                            "lat": 61.3340588841818,
                            "lon": 55.16985699887472
                        },
                        {
                            "lat": 61.333937538043934,
                            "lon": 55.17165152469157
                        },
                        {
                            "lat": 61.3338161783601,
                            "lon": 55.17344604979728
                        },
                        {
                            "lat": 61.3336948051282,
                            "lon": 55.17524057419182
                        },
                        {
                            "lat": 61.33357341834619,
                            "lon": 55.177035097875205
                        },
                        {
                            "lat": 61.333452018012004,
                            "lon": 55.17882962084742
                        },
                        {
                            "lat": 61.33333060412357,
                            "lon": 55.18062414310842
                        },
                        {
                            "lat": 61.333209176678814,
                            "lon": 55.182418664658215
                        },
                        {
                            "lat": 61.333087735675726,
                            "lon": 55.18421318549677
                        },
                        {
                            "lat": 61.33296628111219,
                            "lon": 55.18600770562411
                        },
                        {
                            "lat": 61.332844812986174,
                            "lon": 55.187802225040166
                        },
                        {
                            "lat": 61.33272333129559,
                            "lon": 55.189596743744964
                        },
                        {
                            "lat": 61.332601836038386,
                            "lon": 55.191391261738474
                        },
                        {
                            "lat": 61.3324803272125,
                            "lon": 55.193185779020666
                        },
                        {
                            "lat": 61.332358804815854,
                            "lon": 55.19498029559154
                        },
                        {
                            "lat": 61.3322372688464,
                            "lon": 55.196774811451085
                        },
                        {
                            "lat": 61.33211571930205,
                            "lon": 55.198569326599284
                        },
                        {
                            "lat": 61.33199415618077,
                            "lon": 55.20036384103611
                        },
                        {
                            "lat": 61.33187257948045,
                            "lon": 55.20215835476155
                        },
                        {
                            "lat": 61.33175098919906,
                            "lon": 55.203952867775605
                        },
                        {
                            "lat": 61.33162938533451,
                            "lon": 55.20574738007824
                        },
                        {
                            "lat": 61.33150776788473,
                            "lon": 55.207541891669436
                        },
                        {
                            "lat": 61.33138613684768,
                            "lon": 55.20933640254921
                        },
                        {
                            "lat": 61.33126449222126,
                            "lon": 55.21113091271752
                        },
                        {
                            "lat": 61.331142834003394,
                            "lon": 55.212925422174344
                        },
                        {
                            "lat": 61.33102116219206,
                            "lon": 55.2147199309197
                        },
                        {
                            "lat": 61.33089947678514,
                            "lon": 55.21651443895353
                        },
                        {
                            "lat": 61.330777777780575,
                            "lon": 55.21830894627584
                        },
                        {
                            "lat": 61.3306560651763,
                            "lon": 55.22010345288662
                        },
                        {
                            "lat": 61.33053433897024,
                            "lon": 55.22189795878585
                        },
                        {
                            "lat": 61.330412599160326,
                            "lon": 55.2236924639735
                        },
                        {
                            "lat": 61.33029084574448,
                            "lon": 55.22548696844958
                        },
                        {
                            "lat": 61.33016907872063,
                            "lon": 55.22728147221407
                        },
                        {
                            "lat": 61.33004729808671,
                            "lon": 55.22907597526694
                        },
                        {
                            "lat": 61.329925503840634,
                            "lon": 55.23087047760817
                        },
                        {
                            "lat": 61.333065381520775,
                            "lon": 55.23094004909942
                        },
                        {
                            "lat": 61.336205272799425,
                            "lon": 55.23100953986545
                        },
                        {
                            "lat": 61.33934517766087,
                            "lon": 55.231078949905495
                        },
                        {
                            "lat": 61.34248509608942,
                            "lon": 55.23114827921879
                        },
                        {
                            "lat": 61.34562502806938,
                            "lon": 55.23121752780461
                        },
                        {
                            "lat": 61.34876497358501,
                            "lon": 55.23128669566216
                        },
                        {
                            "lat": 61.3519049326206,
                            "lon": 55.2313557827907
                        },
                        {
                            "lat": 61.355044905160455,
                            "lon": 55.23142478918948
                        },
                        {
                            "lat": 61.35818489118887,
                            "lon": 55.23149371485774
                        },
                        {
                            "lat": 61.35830541614398,
                            "lon": 55.229699171096385
                        },
                        {
                            "lat": 61.3614452746026,
                            "lon": 55.22976801145789
                        },
                        {
                            "lat": 61.36156564506002,
                            "lon": 55.22797346241356
                        },
                        {
                            "lat": 61.364705375944745,
                            "lon": 55.22804221747867
                        },
                        {
                            "lat": 61.364825591935876,
                            "lon": 55.226247663157515
                        },
                        {
                            "lat": 61.3649457944917,
                            "lon": 55.22445310812931
                        },
                        {
                            "lat": 61.36180634561779,
                            "lon": 55.22438436220244
                        },
                        {
                            "lat": 61.358666910192795,
                            "lon": 55.22431553556494
                        },
                        {
                            "lat": 61.35878738127706,
                            "lon": 55.22252098897203
                        },
                        {
                            "lat": 61.36192667572226,
                            "lon": 55.222589811035675
                        },
                        {
                            "lat": 61.36506598361432,
                            "lon": 55.222658552394066
                        },
                        {
                            "lat": 61.36518615930575,
                            "lon": 55.220863995951774
                        },
                        {
                            "lat": 61.365306321568035,
                            "lon": 55.21906943880247
                        },
                        {
                            "lat": 61.36844536097059,
                            "lon": 55.21913809032975
                        },
                        {
                            "lat": 61.37158441378452,
                            "lon": 55.21920666116094
                        },
                        {
                            "lat": 61.371704280744055,
                            "lon": 55.21741209418612
                        },
                        {
                            "lat": 61.371824134309946,
                            "lon": 55.21561752650515
                        },
                        {
                            "lat": 61.36868536335802,
                            "lon": 55.2155489647867
                        },
                        {
                            "lat": 61.36880534443973,
                            "lon": 55.21375440095532
                        },
                        {
                            "lat": 61.36892531211619,
                            "lon": 55.2119598364174
                        },
                        {
                            "lat": 61.37206380126889,
                            "lon": 55.212028389024745
                        },
                        {
                            "lat": 61.372183614666014,
                            "lon": 55.21023381922534
                        },
                        {
                            "lat": 61.37532197632811,
                            "lon": 55.21030228660763
                        },
                        {
                            "lat": 61.37846035136004,
                            "lon": 55.21037067331905
                        },
                        {
                            "lat": 61.37857986964473,
                            "lon": 55.208576093720524
                        },
                        {
                            "lat": 61.381718117164276,
                            "lon": 55.20864439522185
                        },
                        {
                            "lat": 61.38159873974609,
                            "lon": 55.21043897935891
                        },
                        {
                            "lat": 61.384737141470566,
                            "lon": 55.210507204726404
                        },
                        {
                            "lat": 61.38787555651783,
                            "lon": 55.21057534942081
                        },
                        {
                            "lat": 61.38775644753168,
                            "lon": 55.21236994191475
                        },
                        {
                            "lat": 61.390895016774294,
                            "lon": 55.2124380104582
                        },
                        {
                            "lat": 61.39403359931032,
                            "lon": 55.212505998321724
                        },
                        {
                            "lat": 61.39717219512408,
                            "lon": 55.21257390550456
                        },
                        {
                            "lat": 61.397053495546814,
                            "lon": 55.21436851084819
                        },
                        {
                            "lat": 61.39391475882505,
                            "lon": 55.21430059915276
                        },
                        {
                            "lat": 61.39379590506003,
                            "lon": 55.2160951992805
                        },
                        {
                            "lat": 61.396934782705515,
                            "lon": 55.21616311548893
                        },
                        {
                            "lat": 61.39681605659813,
                            "lon": 55.21795771942677
                        },
                        {
                            "lat": 61.39995508845114,
                            "lon": 55.21802555945115
                        },
                        {
                            "lat": 61.403094133556536,
                            "lon": 55.21809331877733
                        },
                        {
                            "lat": 61.40623319189863,
                            "lon": 55.21816099740458
                        },
                        {
                            "lat": 61.40937226346173,
                            "lon": 55.218228595332185
                        },
                        {
                            "lat": 61.40925408792687,
                            "lon": 55.220023216568826
                        },
                        {
                            "lat": 61.41239331366046,
                            "lon": 55.220090738283055
                        },
                        {
                            "lat": 61.41251134823013,
                            "lon": 55.218296112559386
                        },
                        {
                            "lat": 61.412629369610194,
                            "lon": 55.216501486134796
                        },
                        {
                            "lat": 61.4157683266167,
                            "lon": 55.21656891817953
                        },
                        {
                            "lat": 61.41890729679511,
                            "lon": 55.21663626952774
                        },
                        {
                            "lat": 61.42204628012973,
                            "lon": 55.21670354017868
                        },
                        {
                            "lat": 61.42518527660484,
                            "lon": 55.21677073013163
                        },
                        {
                            "lat": 61.42530272104207,
                            "lon": 55.21497608511461
                        },
                        {
                            "lat": 61.428441589698174,
                            "lon": 55.2150431899093
                        },
                        {
                            "lat": 61.42832428620477,
                            "lon": 55.216837839385846
                        },
                        {
                            "lat": 61.4314633089138,
                            "lon": 55.21690486794058
                        },
                        {
                            "lat": 61.43134613327278,
                            "lon": 55.2186995211728
                        },
                        {
                            "lat": 61.43448531003873,
                            "lon": 55.21876647347656
                        },
                        {
                            "lat": 61.437624499884386,
                            "lon": 55.218833345074046
                        },
                        {
                            "lat": 61.44076370279404,
                            "lon": 55.218900135964525
                        },
                        {
                            "lat": 61.44088045553856,
                            "lon": 55.2171054694007
                        },
                        {
                            "lat": 61.4377413935964,
                            "lon": 55.21703868294875
                        },
                        {
                            "lat": 61.43460234471624,
                            "lon": 55.21697181579512
                        },
                        {
                            "lat": 61.43471936631601,
                            "lon": 55.21517715741561
                        },
                        {
                            "lat": 61.43158047146139,
                            "lon": 55.215110214009876
                        },
                        {
                            "lat": 61.431697620917554,
                            "lon": 55.21331555938072
                        },
                        {
                            "lat": 61.42855888008446,
                            "lon": 55.213248539733904
                        },
                        {
                            "lat": 61.42542015235649,
                            "lon": 55.213181439398326
                        },
                        {
                            "lat": 61.42553757055007,
                            "lon": 55.211386792982786
                        },
                        {
                            "lat": 61.42239899685332,
                            "lon": 55.211319616423225
                        },
                        {
                            "lat": 61.422516542822834,
                            "lon": 55.209524973772105
                        },
                        {
                            "lat": 61.42565497562486,
                            "lon": 55.20959214586799
                        },
                        {
                            "lat": 61.42577236758283,
                            "lon": 55.20779749805397
                        },
                        {
                            "lat": 61.42891067262061,
                            "lon": 55.2078645850146
                        },
                        {
                            "lat": 61.42902791059841,
                            "lon": 55.20606993204384
                        },
                        {
                            "lat": 61.43216608786796,
                            "lon": 55.206136933879556
                        },
                        {
                            "lat": 61.43228317189695,
                            "lon": 55.20434227575823
                        },
                        {
                            "lat": 61.42914513547906,
                            "lon": 55.204275278374276
                        },
                        {
                            "lat": 61.42926234726457,
                            "lon": 55.20248062400593
                        },
                        {
                            "lat": 61.42612446477582,
                            "lon": 55.20241355041665
                        },
                        {
                            "lat": 61.42298659539588,
                            "lon": 55.20234639617142
                        },
                        {
                            "lat": 61.419848739140456,
                            "lon": 55.20227916127093
                        },
                        {
                            "lat": 61.416710896025194,
                            "lon": 55.20221184571598
                        },
                        {
                            "lat": 61.41357306606582,
                            "lon": 55.20214444950722
                        },
                        {
                            "lat": 61.41345515013033,
                            "lon": 55.203939081538536
                        },
                        {
                            "lat": 61.4103171925185,
                            "lon": 55.20387160019335
                        },
                        {
                            "lat": 61.40717924809587,
                            "lon": 55.203804038190505
                        },
                        {
                            "lat": 61.404041316878164,
                            "lon": 55.20373639553076
                        },
                        {
                            "lat": 61.40090339888104,
                            "lon": 55.20366867221485
                        },
                        {
                            "lat": 61.401021878100416,
                            "lon": 55.20187405814936
                        },
                        {
                            "lat": 61.40114034408555,
                            "lon": 55.20007944338147
                        },
                        {
                            "lat": 61.39800272094088,
                            "lon": 55.20001164841936
                        },
                        {
                            "lat": 61.39812131447839,
                            "lon": 55.198217037453105
                        },
                        {
                            "lat": 61.39823989476997,
                            "lon": 55.19642242578408
                        },
                        {
                            "lat": 61.401377236361135,
                            "lon": 55.19649021173857
                        },
                        {
                            "lat": 61.40149566265561,
                            "lon": 55.19469559486361
                        },
                        {
                            "lat": 61.398358461817715,
                            "lon": 55.19462781341232
                        },
                        {
                            "lat": 61.39847701562358,
                            "lon": 55.1928332003378
                        },
                        {
                            "lat": 61.401614075723884,
                            "lon": 55.1929009772863
                        },
                        {
                            "lat": 61.401732475568,
                            "lon": 55.19110635900667
                        },
                        {
                            "lat": 61.398595556189605,
                            "lon": 55.191038586560566
                        },
                        {
                            "lat": 61.39545865004902,
                            "lon": 55.19097073349716
                        },
                        {
                            "lat": 61.39533996876309,
                            "lon": 55.19276534276668
                        },
                        {
                            "lat": 61.392202935158124,
                            "lon": 55.19269740457365
                        },
                        {
                            "lat": 61.392084099883135,
                            "lon": 55.19449200862656
                        },
                        {
                            "lat": 61.3889469388178,
                            "lon": 55.19442398529357
                        },
                        {
                            "lat": 61.385809791041304,
                            "lon": 55.19435588133485
                        },
                        {
                            "lat": 61.38569066100075,
                            "lon": 55.19615047564039
                        },
                        {
                            "lat": 61.38255338578564,
                            "lon": 55.196082286526774
                        },
                        {
                            "lat": 61.38243410167973,
                            "lon": 55.19787687559764
                        },
                        {
                            "lat": 61.37929669903003,
                            "lon": 55.19780860131875
                        },
                        {
                            "lat": 61.376159309720244,
                            "lon": 55.19774024640567
                        },
                        {
                            "lat": 61.376039730747216,
                            "lon": 55.199534825694315
                        },
                        {
                            "lat": 61.37290221402461,
                            "lon": 55.19946638560102
                        },
                        {
                            "lat": 61.37278248090991,
                            "lon": 55.20126095963689
                        },
                        {
                            "lat": 61.36964483677856,
                            "lon": 55.20119243435309
                        },
                        {
                            "lat": 61.36650720603818,
                            "lon": 55.201123828426624
                        },
                        {
                            "lat": 61.36662722071488,
                            "lon": 55.199329263501085
                        },
                        {
                            "lat": 61.36976471067527,
                            "lon": 55.199397864869695
                        },
                        {
                            "lat": 61.36988457118303,
                            "lon": 55.197603294679894
                        },
                        {
                            "lat": 61.366747221986955,
                            "lon": 55.1975346978687
                        },
                        {
                            "lat": 61.36360988619346,
                            "lon": 55.19746602042628
                        },
                        {
                            "lat": 61.363489744159104,
                            "lon": 55.19926058149591
                        },
                        {
                            "lat": 61.363369588704465,
                            "lon": 55.201055141858276
                        },
                        {
                            "lat": 61.36023198479307,
                            "lon": 55.20098637464879
                        },
                        {
                            "lat": 61.36011167512454,
                            "lon": 55.202780929734956
                        },
                        {
                            "lat": 61.356973943861604,
                            "lon": 55.2027120773108
                        },
                        {
                            "lat": 61.35685347994782,
                            "lon": 55.204506627114604
                        },
                        {
                            "lat": 61.35371562133747,
                            "lon": 55.20443768946545
                        },
                        {
                            "lat": 61.35057777620056,
                            "lon": 55.20436867116673
                        },
                        {
                            "lat": 61.35045701719342,
                            "lon": 55.20616321109621
                        },
                        {
                            "lat": 61.3473190447309,
                            "lon": 55.206094107557554
                        },
                        {
                            "lat": 61.344181085775226,
                            "lon": 55.20602492336548
                        },
                        {
                            "lat": 61.341043140342094,
                            "lon": 55.20595565852075
                        },
                        {
                            "lat": 61.337905208447125,
                            "lon": 55.205886313024145
                        },
                        {
                            "lat": 61.338026530700795,
                            "lon": 55.20409179149118
                        },
                        {
                            "lat": 61.34116432178721,
                            "lon": 55.20416113238066
                        },
                        {
                            "lat": 61.34430212640976,
                            "lon": 55.20423039262358
                        },
                        {
                            "lat": 61.344423153523806,
                            "lon": 55.20243586117192
                        },
                        {
                            "lat": 61.34756083086983,
                            "lon": 55.20250503617147
                        },
                        {
                            "lat": 61.34768170368418,
                            "lon": 55.20071049941443
                        },
                        {
                            "lat": 61.347802562997835,
                            "lon": 55.19891596194807
                        },
                        {
                            "lat": 61.34466516719877,
                            "lon": 55.19884679613937
                        },
                        {
                            "lat": 61.344786153763785,
                            "lon": 55.19705226255852
                        },
                        {
                            "lat": 61.34164891222694,
                            "lon": 55.19698302071869
                        },
                        {
                            "lat": 61.341770026011964,
                            "lon": 55.19518849102788
                        },
                        {
                            "lat": 61.34189112627117,
                            "lon": 55.19339396062697
                        },
                        {
                            "lat": 61.34502808635917,
                            "lon": 55.19346319326774
                        },
                        {
                            "lat": 61.34816505995527,
                            "lon": 55.19353234529324
                        },
                        {
                            "lat": 61.34828586528676,
                            "lon": 55.191737804989764
                        },
                        {
                            "lat": 61.35142271167027,
                            "lon": 55.191806871811
                        },
                        {
                            "lat": 61.35455957152854,
                            "lon": 55.1918758580208
                        },
                        {
                            "lat": 61.35769644484588,
                            "lon": 55.19194476361841
                        },
                        {
                            "lat": 61.36083333160666,
                            "lon": 55.1920135886031
                        },
                        {
                            "lat": 61.36095356067789,
                            "lon": 55.1902190292711
                        },
                        {
                            "lat": 61.36409032016885,
                            "lon": 55.19028776907562
                        },
                        {
                            "lat": 61.36421039513241,
                            "lon": 55.18849320446998
                        },
                        {
                            "lat": 61.36734702734948,
                            "lon": 55.18856185910468
                        },
                        {
                            "lat": 61.3674669482367,
                            "lon": 55.18676728923155
                        },
                        {
                            "lat": 61.36758685573355,
                            "lon": 55.184972718651686
                        },
                        {
                            "lat": 61.37072322001797,
                            "lon": 55.18504128357233
                        },
                        {
                            "lat": 61.37385959767503,
                            "lon": 55.1851097678984
                        },
                        {
                            "lat": 61.373979210503464,
                            "lon": 55.183315187508846
                        },
                        {
                            "lat": 61.370842973487484,
                            "lon": 55.18324670773159
                        },
                        {
                            "lat": 61.36770674984214,
                            "lon": 55.18317814736509
                        },
                        {
                            "lat": 61.36782663056442,
                            "lon": 55.18138357537181
                        },
                        {
                            "lat": 61.36794649790249,
                            "lon": 55.17958900267182
                        },
                        {
                            "lat": 61.364810568870475,
                            "lon": 55.179520370834595
                        },
                        {
                            "lat": 61.36167465323629,
                            "lon": 55.179451658420305
                        },
                        {
                            "lat": 61.35853875101558,
                            "lon": 55.17938286542971
                        },
                        {
                            "lat": 61.358659026735566,
                            "lon": 55.177588305713854
                        },
                        {
                            "lat": 61.355523278527926,
                            "lon": 55.17751943672196
                        },
                        {
                            "lat": 61.352387543762994,
                            "lon": 55.1774504871606
                        },
                        {
                            "lat": 61.35226698687717,
                            "lon": 55.17924503772252
                        },
                        {
                            "lat": 61.349131124990734,
                            "lon": 55.17917600300746
                        },
                        {
                            "lat": 61.349251822456445,
                            "lon": 55.17738145703045
                        },
                        {
                            "lat": 61.34937250644814,
                            "lon": 55.17558691034433
                        },
                        {
                            "lat": 61.3494931769679,
                            "lon": 55.173792362949115
                        },
                        {
                            "lat": 61.35262861716166,
                            "lon": 55.17386138391057
                        },
                        {
                            "lat": 61.355764070809684,
                            "lon": 55.173930324313936
                        },
                        {
                            "lat": 61.3558844467916,
                            "lon": 55.17213576704753
                        },
                        {
                            "lat": 61.35901977334121,
                            "lon": 55.17220462231902
                        },
                        {
                            "lat": 61.35913999536514,
                            "lon": 55.17041005977171
                        },
                        {
                            "lat": 61.36227519481222,
                            "lon": 55.17047882992167
                        },
                        {
                            "lat": 61.3654104076626,
                            "lon": 55.17054751952201
                        },
                        {
                            "lat": 61.365530335249936,
                            "lon": 55.16875294713839
                        },
                        {
                            "lat": 61.36866542097606,
                            "lon": 55.16882155163208
                        },
                        {
                            "lat": 61.37180052007217,
                            "lon": 55.16889007557997
                        },
                        {
                            "lat": 61.371680873510655,
                            "lon": 55.17068465707072
                        },
                        {
                            "lat": 61.37481612647708,
                            "lon": 55.1707531050176
                        },
                        {
                            "lat": 61.37795139278424,
                            "lon": 55.17082147241186
                        },
                        {
                            "lat": 61.37807075831181,
                            "lon": 55.16902688183531
                        },
                        {
                            "lat": 61.37819011051625,
                            "lon": 55.16723229055339
                        },
                        {
                            "lat": 61.381325109124155,
                            "lon": 55.16730056832487
                        },
                        {
                            "lat": 61.38144430751877,
                            "lon": 55.1655059718035
                        },
                        {
                            "lat": 61.384579178941316,
                            "lon": 55.16557416450277
                        },
                        {
                            "lat": 61.38469822355737,
                            "lon": 55.16377956274815
                        },
                        {
                            "lat": 61.387832967790516,
                            "lon": 55.16384767038553
                        },
                        {
                            "lat": 61.39096772529382,
                            "lon": 55.16391569748864
                        },
                        {
                            "lat": 61.39108647569896,
                            "lon": 55.16212108598959
                        },
                        {
                            "lat": 61.39422110599119,
                            "lon": 55.16218902804574
                        },
                        {
                            "lat": 61.3973557495203,
                            "lon": 55.162256889571466
                        },
                        {
                            "lat": 61.397237280048365,
                            "lon": 55.164051510089074
                        },
                        {
                            "lat": 61.40037207726834,
                            "lon": 55.1641192955849
                        },
                        {
                            "lat": 61.4004904062707,
                            "lon": 55.162324670566015
                        },
                        {
                            "lat": 61.403625076226696,
                            "lon": 55.162392371028666
                        },
                        {
                            "lat": 61.403506887695976,
                            "lon": 55.1641870005435
                        },
                        {
                            "lat": 61.40664171131563,
                            "lon": 55.16425462496412
                        },
                        {
                            "lat": 61.406523650082136,
                            "lon": 55.166049258267854
                        },
                        {
                            "lat": 61.40965862736943,
                            "lon": 55.16611680663546
                        },
                        {
                            "lat": 61.40977654811167,
                            "lon": 55.16432216884604
                        },
                        {
                            "lat": 61.41291139806847,
                            "lon": 55.1643896321885
                        },
                        {
                            "lat": 61.41279361781951,
                            "lon": 55.16618427445827
                        },
                        {
                            "lat": 61.415928621416725,
                            "lon": 55.166251661735544
                        },
                        {
                            "lat": 61.41906363814544,
                            "lon": 55.166318968466605
                        },
                        {
                            "lat": 61.42219866799003,
                            "lon": 55.166386194650656
                        },
                        {
                            "lat": 61.422081296132575,
                            "lon": 55.16818084962977
                        },
                        {
                            "lat": 61.418946125773104,
                            "lon": 55.16811361898097
                        },
                        {
                            "lat": 61.418828600282886,
                            "lon": 55.16990826879522
                        },
                        {
                            "lat": 61.421963911172874,
                            "lon": 55.169975503909164
                        },
                        {
                            "lat": 61.42184651310893,
                            "lon": 55.17177015748884
                        },
                        {
                            "lat": 61.42498197765133,
                            "lon": 55.1718373165046
                        },
                        {
                            "lat": 61.42811745528429,
                            "lon": 55.17190439495591
                        },
                        {
                            "lat": 61.428000325243666,
                            "lon": 55.17369905675171
                        },
                        {
                            "lat": 61.431135956519334,
                            "lon": 55.17376605908776
                        },
                        {
                            "lat": 61.43101895398519,
                            "lon": 55.17556072463495
                        },
                        {
                            "lat": 61.434154738907566,
                            "lon": 55.175627650844746
                        },
                        {
                            "lat": 61.437290536877555,
                            "lon": 55.17569449647724
                        },
                        {
                            "lat": 61.440426347879566,
                            "lon": 55.17576126153169
                        },
                        {
                            "lat": 61.44356217189792,
                            "lon": 55.175827946007345
                        },
                        {
                            "lat": 61.443678612078585,
                            "lon": 55.1740332627122
                        },
                        {
                            "lat": 61.440542928651574,
                            "lon": 55.17396658266552
                        },
                        {
                            "lat": 61.44065949640921,
                            "lon": 55.172171903102004
                        },
                        {
                            "lat": 61.44077605115446,
                            "lon": 55.17037722284112
                        },
                        {
                            "lat": 61.43764066187362,
                            "lon": 55.17031047108845
                        },
                        {
                            "lat": 61.43775734415088,
                            "lon": 55.16851579456337
                        },
                        {
                            "lat": 61.43462210843655,
                            "lon": 55.16844896668714
                        },
                        {
                            "lat": 61.43473891821458,
                            "lon": 55.16665429390239
                        },
                        {
                            "lat": 61.43160383606278,
                            "lon": 55.16658738991359
                        },
                        {
                            "lat": 61.431720773310275,
                            "lon": 55.164792720873756
                        },
                        {
                            "lat": 61.42858584471707,
                            "lon": 55.16472574078338
                        },
                        {
                            "lat": 61.42870290940279,
                            "lon": 55.162931075492985
                        },
                        {
                            "lat": 61.42556813436418,
                            "lon": 55.16286401931206
                        },
                        {
                            "lat": 61.42568532645686,
                            "lon": 55.16106935777566
                        },
                        {
                            "lat": 61.422550704968856,
                            "lon": 55.161002225515155
                        },
                        {
                            "lat": 61.419416096575155,
                            "lon": 55.16093501272293
                        },
                        {
                            "lat": 61.419533556495935,
                            "lon": 55.159140359408234
                        },
                        {
                            "lat": 61.42266802443728,
                            "lon": 55.1592075677373
                        },
                        {
                            "lat": 61.42278533081341,
                            "lon": 55.1574129092598
                        },
                        {
                            "lat": 61.4196510033088,
                            "lon": 55.157345705393475
                        },
                        {
                            "lat": 61.416516688910114,
                            "lon": 55.157278421006836
                        },
                        {
                            "lat": 61.41639910166247,
                            "lon": 55.15907307055352
                        },
                        {
                            "lat": 61.41326465995255,
                            "lon": 55.15900570117387
                        },
                        {
                            "lat": 61.413382387632936,
                            "lon": 55.157211056100614
                        },
                        {
                            "lat": 61.410248099492925,
                            "lon": 55.157143610675526
                        },
                        {
                            "lat": 61.41036595445132,
                            "lon": 55.155348969379766
                        },
                        {
                            "lat": 61.41048379625896,
                            "lon": 55.153554327382786
                        },
                        {
                            "lat": 61.40734980208238,
                            "lon": 55.15348681040655
                        },
                        {
                            "lat": 61.40746777112317,
                            "lon": 55.15169217219122
                        },
                        {
                            "lat": 61.410601624917824,
                            "lon": 55.1517596846846
                        },
                        {
                            "lat": 61.41071944042992,
                            "lon": 55.149965041285206
                        },
                        {
                            "lat": 61.41083724279726,
                            "lon": 55.14817039718466
                        },
                        {
                            "lat": 61.4077036697196,
                            "lon": 55.148102893655825
                        },
                        {
                            "lat": 61.407821599279266,
                            "lon": 55.14630825333576
                        },
                        {
                            "lat": 61.40793951568254,
                            "lon": 55.14451361231413
                        },
                        {
                            "lat": 61.40480623641387,
                            "lon": 55.144446037268175
                        },
                        {
                            "lat": 61.401672970315154,
                            "lon": 55.14437838174285
                        },
                        {
                            "lat": 61.401554773278896,
                            "lon": 55.14617301378595
                        },
                        {
                            "lat": 61.40143656305505,
                            "lon": 55.1479676451267
                        },
                        {
                            "lat": 61.398303029499345,
                            "lon": 55.147899900127854
                        },
                        {
                            "lat": 61.398184665741205,
                            "lon": 55.149694526268156
                        },
                        {
                            "lat": 61.395051005048,
                            "lon": 55.14962669627741
                        },
                        {
                            "lat": 61.3919173575776,
                            "lon": 55.14955878579358
                        },
                        {
                            "lat": 61.39179869989769,
                            "lon": 55.1513534022182
                        },
                        {
                            "lat": 61.38866492531144,
                            "lon": 55.151285406727546
                        },
                        {
                            "lat": 61.38854611402123,
                            "lon": 55.15308001793368
                        },
                        {
                            "lat": 61.38541221232313,
                            "lon": 55.15301193742589
                        },
                        {
                            "lat": 61.38529324739142,
                            "lon": 55.15480654340743
                        },
                        {
                            "lat": 61.385174269184034,
                            "lon": 55.15660114868451
                        },
                        {
                            "lat": 61.385055277698946,
                            "lon": 55.158395753257146
                        },
                        {
                            "lat": 61.38818960059431,
                            "lon": 55.15846384732791
                        },
                        {
                            "lat": 61.388070736259834,
                            "lon": 55.16025845571787
                        },
                        {
                            "lat": 61.38493627293416,
                            "lon": 55.1601903571253
                        },
                        {
                            "lat": 61.381801822890225,
                            "lon": 55.16012217800985
                        },
                        {
                            "lat": 61.37866738614363,
                            "lon": 55.16005391837228
                        },
                        {
                            "lat": 61.37878667176299,
                            "lon": 55.158259323563705
                        },
                        {
                            "lat": 61.37565238875361,
                            "lon": 55.15819098794255
                        },
                        {
                            "lat": 61.37251811907079,
                            "lon": 55.15812257180607
                        },
                        {
                            "lat": 61.36938386273015,
                            "lon": 55.15805407515503
                        },
                        {
                            "lat": 61.36624961974728,
                            "lon": 55.157985497990175
                        },
                        {
                            "lat": 61.36311539013782,
                            "lon": 55.15791684031224
                        },
                        {
                            "lat": 61.35998117391739,
                            "lon": 55.15784810212198
                        },
                        {
                            "lat": 61.35986104578298,
                            "lon": 55.15964266962383
                        },
                        {
                            "lat": 61.35672670255511,
                            "lon": 55.15957384635221
                        },
                        {
                            "lat": 61.356606420586374,
                            "lon": 55.161368408576124
                        },
                        {
                            "lat": 61.35347195035516,
                            "lon": 55.16129950021285
                        },
                        {
                            "lat": 61.35033749356388,
                            "lon": 55.161230511328824
                        },
                        {
                            "lat": 61.35021691729037,
                            "lon": 55.163025063687385
                        },
                        {
                            "lat": 61.347082333517506,
                            "lon": 55.16295598969679
                        },
                        {
                            "lat": 61.34696160333361,
                            "lon": 55.16475053675933
                        },
                        {
                            "lat": 61.343826892583245,
                            "lon": 55.16468137765183
                        },
                        {
                            "lat": 61.343947763217855,
                            "lon": 55.16288683518164
                        },
                        {
                            "lat": 61.344068620363636,
                            "lon": 55.1610922920016
                        },
                        {
                            "lat": 61.34418946402263,
                            "lon": 55.159297748111754
                        },
                        {
                            "lat": 61.341055188062306,
                            "lon": 55.15922852226691
                        },
                        {
                            "lat": 61.341176158638355,
                            "lon": 55.157433982263676
                        },
                        {
                            "lat": 61.33804203657818,
                            "lon": 55.157364680508195
                        },
                        {
                            "lat": 61.33490792803189,
                            "lon": 55.15729529824638
                        },
                        {
                            "lat": 61.33478667665835,
                            "lon": 55.159089829040745
                        },
                        {
                            "lat": 61.33466541175321,
                            "lon": 55.16088435912407
                        },
                        {
                            "lat": 61.334544133314445,
                            "lon": 55.16267888849635
                        },
                        {
                            "lat": 61.33442284133997,
                            "lon": 55.16447341715757
                        },
                        {
                            "lat": 61.33430153582776,
                            "lon": 55.16626794510771
                        },
                        {
                            "lat": 61.33418021677571,
                            "lon": 55.16806247234679
                        },
                        {
                            "lat": 61.3340588841818,
                            "lon": 55.16985699887472
                        }
                    ],
                    [
                        {
                            "lat": 61.34877114233483,
                            "lon": 55.13783225633979
                        },
                        {
                            "lat": 61.34865060058985,
                            "lon": 55.13962681333273
                        },
                        {
                            "lat": 61.35178335989712,
                            "lon": 55.139695827729625
                        },
                        {
                            "lat": 61.3519037614078,
                            "lon": 55.137901266155424
                        },
                        {
                            "lat": 61.34877114233483,
                            "lon": 55.13783225633979
                        }
                    ],
                    [
                        {
                            "lat": 61.40640557567025,
                            "lon": 55.167843890869904
                        },
                        {
                            "lat": 61.40628748807794,
                            "lon": 55.16963852277024
                        },
                        {
                            "lat": 61.40942274639466,
                            "lon": 55.16970608011039
                        },
                        {
                            "lat": 61.40954069346443,
                            "lon": 55.167911443723575
                        },
                        {
                            "lat": 61.40640557567025,
                            "lon": 55.167843890869904
                        }
                    ],
                    [
                        {
                            "lat": 61.41569330251275,
                            "lon": 55.16984095312361
                        },
                        {
                            "lat": 61.41557562335852,
                            "lon": 55.17163559776686
                        },
                        {
                            "lat": 61.41871106167279,
                            "lon": 55.17170291790933
                        },
                        {
                            "lat": 61.418828600282886,
                            "lon": 55.16990826879522
                        },
                        {
                            "lat": 61.41569330251275,
                            "lon": 55.16984095312361
                        }
                    ],
                    [
                        {
                            "lat": 61.404869408168544,
                            "lon": 55.19117405083475
                        },
                        {
                            "lat": 61.40800635397561,
                            "lon": 55.19124166204407
                        },
                        {
                            "lat": 61.40812445917899,
                            "lon": 55.18944703407418
                        },
                        {
                            "lat": 61.40498765408223,
                            "lon": 55.18937942735616
                        },
                        {
                            "lat": 61.40185086218994,
                            "lon": 55.18931174002474
                        },
                        {
                            "lat": 61.401732475568,
                            "lon": 55.19110635900667
                        },
                        {
                            "lat": 61.404869408168544,
                            "lon": 55.19117405083475
                        }
                    ],
                    [
                        {
                            "lat": 61.44971768695963,
                            "lon": 55.17775576466034
                        },
                        {
                            "lat": 61.44658169634457,
                            "lon": 55.17768923692569
                        },
                        {
                            "lat": 61.446465370785106,
                            "lon": 55.1794839232513
                        },
                        {
                            "lat": 61.44960150202676,
                            "lon": 55.17955045540504
                        },
                        {
                            "lat": 61.44971768695963,
                            "lon": 55.17775576466034
                        }
                    ],
                    [
                        {
                            "lat": 61.44797354986459,
                            "lon": 55.20467605272176
                        },
                        {
                            "lat": 61.44785717012378,
                            "lon": 55.206470733021774
                        },
                        {
                            "lat": 61.450995425646745,
                            "lon": 55.20653725083784
                        },
                        {
                            "lat": 61.45111166452397,
                            "lon": 55.20474256611812
                        },
                        {
                            "lat": 61.44797354986459,
                            "lon": 55.20467605272176
                        }
                    ],
                    [
                        {
                            "lat": 61.431814757284315,
                            "lon": 55.21152090405309
                        },
                        {
                            "lat": 61.431697620917554,
                            "lon": 55.21331555938072
                        },
                        {
                            "lat": 61.43483637484008,
                            "lon": 55.21338249833803
                        },
                        {
                            "lat": 61.43495337029043,
                            "lon": 55.211587838562345
                        },
                        {
                            "lat": 61.431814757284315,
                            "lon": 55.21152090405309
                        }
                    ],
                    [
                        {
                            "lat": 61.444019530527015,
                            "lon": 55.21717217515026
                        },
                        {
                            "lat": 61.443902918751974,
                            "lon": 55.21896684614727
                        },
                        {
                            "lat": 61.447042147742515,
                            "lon": 55.219033475621536
                        },
                        {
                            "lat": 61.447158618546084,
                            "lon": 55.217238800196704
                        },
                        {
                            "lat": 61.444019530527015,
                            "lon": 55.21717217515026
                        }
                    ]
                ]
            }
        ],
        "pollution": [
            {
                "type": "MultiLineString",
                "value": 0.01,
                "data": [
                    [
                        {
                            "lat": 61.360912804774806,
                            "lon": 55.05003558668771
                        },
                        {
                            "lat": 61.35778702480761,
                            "lon": 55.04996688104375
                        },
                        {
                            "lat": 61.35766727892587,
                            "lon": 55.05176147733115
                        },
                        {
                            "lat": 61.36079319836844,
                            "lon": 55.05183018753108
                        },
                        {
                            "lat": 61.360912804774806,
                            "lon": 55.05003558668771
                        }
                    ],
                    [
                        {
                            "lat": 61.36680638243001,
                            "lon": 55.05555658509912
                        },
                        {
                            "lat": 61.366925736451726,
                            "lon": 55.053761976573476
                        },
                        {
                            "lat": 61.36704507718633,
                            "lon": 55.051967367340936
                        },
                        {
                            "lat": 61.363919131127105,
                            "lon": 55.051898817534585
                        },
                        {
                            "lat": 61.36079319836844,
                            "lon": 55.05183018753108
                        },
                        {
                            "lat": 61.36067357864605,
                            "lon": 55.05362478766676
                        },
                        {
                            "lat": 61.3637996508976,
                            "lon": 55.05369342222135
                        },
                        {
                            "lat": 61.36368015736549,
                            "lon": 55.05548802620083
                        },
                        {
                            "lat": 61.36680638243001,
                            "lon": 55.05555658509912
                        }
                    ],
                    [
                        {
                            "lat": 61.35766727892587,
                            "lon": 55.05176147733115
                        },
                        {
                            "lat": 61.35454137281478,
                            "lon": 55.051692686935546
                        },
                        {
                            "lat": 61.354421474112655,
                            "lon": 55.053487277953195
                        },
                        {
                            "lat": 61.357547519712575,
                            "lon": 55.053556072910474
                        },
                        {
                            "lat": 61.35766727892587,
                            "lon": 55.05176147733115
                        }
                    ],
                    [
                        {
                            "lat": 61.37943021138464,
                            "lon": 55.05403539194244
                        },
                        {
                            "lat": 61.37931141542458,
                            "lon": 55.05583001859974
                        },
                        {
                            "lat": 61.38243770677994,
                            "lon": 55.05589817644806
                        },
                        {
                            "lat": 61.382556363219564,
                            "lon": 55.05410354527112
                        },
                        {
                            "lat": 61.37943021138464,
                            "lon": 55.05403539194244
                        }
                    ],
                    [
                        {
                            "lat": 61.351295441861744,
                            "lon": 55.05341840279571
                        },
                        {
                            "lat": 61.348169422975324,
                            "lon": 55.053349447438734
                        },
                        {
                            "lat": 61.345043417468865,
                            "lon": 55.053280411883016
                        },
                        {
                            "lat": 61.3449230869169,
                            "lon": 55.055074988474125
                        },
                        {
                            "lat": 61.34480274296745,
                            "lon": 55.05686956435548
                        },
                        {
                            "lat": 61.34792902748586,
                            "lon": 55.05693860906757
                        },
                        {
                            "lat": 61.34804923192159,
                            "lon": 55.05514402860781
                        },
                        {
                            "lat": 61.3511753903083,
                            "lon": 55.055212988537484
                        },
                        {
                            "lat": 61.351295441861744,
                            "lon": 55.05341840279571
                        }
                    ],
                    [
                        {
                            "lat": 61.34444163071392,
                            "lon": 55.062253287740994
                        },
                        {
                            "lat": 61.34131494096702,
                            "lon": 55.062184149066766
                        },
                        {
                            "lat": 61.34119440383372,
                            "lon": 55.06397871752371
                        },
                        {
                            "lat": 61.341073853277294,
                            "lon": 55.065773285270424
                        },
                        {
                            "lat": 61.34095328929571,
                            "lon": 55.06756785230691
                        },
                        {
                            "lat": 61.344080397809954,
                            "lon": 55.06763700473835
                        },
                        {
                            "lat": 61.34420082218693,
                            "lon": 55.065842433115726
                        },
                        {
                            "lat": 61.34432123315424,
                            "lon": 55.06404786078327
                        },
                        {
                            "lat": 61.34444163071392,
                            "lon": 55.062253287740994
                        }
                    ],
                    [
                        {
                            "lat": 61.37246329852365,
                            "lon": 55.064666539688375
                        },
                        {
                            "lat": 61.37234414394955,
                            "lon": 55.066461153053744
                        },
                        {
                            "lat": 61.3754712462587,
                            "lon": 55.066529498514335
                        },
                        {
                            "lat": 61.375590261223465,
                            "lon": 55.06473488061627
                        },
                        {
                            "lat": 61.37246329852365,
                            "lon": 55.064666539688375
                        }
                    ],
                    [
                        {
                            "lat": 61.36296291670547,
                            "lon": 55.06625563522404
                        },
                        {
                            "lat": 61.362843329998725,
                            "lon": 55.068050234252155
                        },
                        {
                            "lat": 61.365970532069475,
                            "lon": 55.068118824985
                        },
                        {
                            "lat": 61.366089979157444,
                            "lon": 55.06632422140787
                        },
                        {
                            "lat": 61.36620941294417,
                            "lon": 55.064529617123775
                        },
                        {
                            "lat": 61.3630824900955,
                            "lon": 55.06446103548855
                        },
                        {
                            "lat": 61.36296291670547,
                            "lon": 55.06625563522404
                        }
                    ],
                    [
                        {
                            "lat": 61.34696691000452,
                            "lon": 55.07129522720806
                        },
                        {
                            "lat": 61.34684658503946,
                            "lon": 55.073089801283196
                        },
                        {
                            "lat": 61.34997413928055,
                            "lon": 55.073158806962134
                        },
                        {
                            "lat": 61.353101706908454,
                            "lon": 55.07322773238325
                        },
                        {
                            "lat": 61.35622928790765,
                            "lon": 55.073296577545776
                        },
                        {
                            "lat": 61.35634919390118,
                            "lon": 55.071501989756264
                        },
                        {
                            "lat": 61.359476648594956,
                            "lon": 55.071570750098616
                        },
                        {
                            "lat": 61.35959640158895,
                            "lon": 55.06977615704044
                        },
                        {
                            "lat": 61.3597161412467,
                            "lon": 55.06798156327444
                        },
                        {
                            "lat": 61.356588965828905,
                            "lon": 55.067912812052555
                        },
                        {
                            "lat": 61.35670883176717,
                            "lon": 55.06611822213835
                        },
                        {
                            "lat": 61.353581809311834,
                            "lon": 55.06604939523798
                        },
                        {
                            "lat": 61.353461803760844,
                            "lon": 55.06784398058724
                        },
                        {
                            "lat": 61.35033465505799,
                            "lon": 55.067775068879264
                        },
                        {
                            "lat": 61.35021449651561,
                            "lon": 55.06956964894928
                        },
                        {
                            "lat": 61.35009432459046,
                            "lon": 55.07136422831023
                        },
                        {
                            "lat": 61.34696691000452,
                            "lon": 55.07129522720806
                        }
                    ],
                    [
                        {
                            "lat": 61.344080397809954,
                            "lon": 55.06763700473835
                        },
                        {
                            "lat": 61.343959960021266,
                            "lon": 55.06943157565115
                        },
                        {
                            "lat": 61.34708722156932,
                            "lon": 55.06950065242345
                        },
                        {
                            "lat": 61.34720751973587,
                            "lon": 55.0677060769294
                        },
                        {
                            "lat": 61.344080397809954,
                            "lon": 55.06763700473835
                        }
                    ],
                    [
                        {
                            "lat": 61.37198660059779,
                            "lon": 55.071844988912865
                        },
                        {
                            "lat": 61.368859092643916,
                            "lon": 55.07177654959406
                        },
                        {
                            "lat": 61.36897842668858,
                            "lon": 55.06998194288643
                        },
                        {
                            "lat": 61.365851071678286,
                            "lon": 55.069913427855134
                        },
                        {
                            "lat": 61.36272372997323,
                            "lon": 55.069844832572876
                        },
                        {
                            "lat": 61.362604116627,
                            "lon": 55.0716394301862
                        },
                        {
                            "lat": 61.359476648594956,
                            "lon": 55.071570750098616
                        },
                        {
                            "lat": 61.35935688226266,
                            "lon": 55.07336534244896
                        },
                        {
                            "lat": 61.36248448995797,
                            "lon": 55.07343402709207
                        },
                        {
                            "lat": 61.36561211097808,
                            "lon": 55.073502631474376
                        },
                        {
                            "lat": 61.36873974530747,
                            "lon": 55.073571155595104
                        },
                        {
                            "lat": 61.37186739293067,
                            "lon": 55.07363959945351
                        },
                        {
                            "lat": 61.37499505383214,
                            "lon": 55.073707963048875
                        },
                        {
                            "lat": 61.3781227279964,
                            "lon": 55.07377624638043
                        },
                        {
                            "lat": 61.37824165631885,
                            "lon": 55.0719816267765
                        },
                        {
                            "lat": 61.375114121827934,
                            "lon": 55.07191334797394
                        },
                        {
                            "lat": 61.37523317656295,
                            "lon": 55.07011873219318
                        },
                        {
                            "lat": 61.375352218039204,
                            "lon": 55.068324115706645
                        },
                        {
                            "lat": 61.37222497610521,
                            "lon": 55.06825576571299
                        },
                        {
                            "lat": 61.37210579498864,
                            "lon": 55.07005037766602
                        },
                        {
                            "lat": 61.37198660059779,
                            "lon": 55.071844988912865
                        }
                    ],
                    [
                        {
                            "lat": 61.37836057139603,
                            "lon": 55.07018700646716
                        },
                        {
                            "lat": 61.37824165631885,
                            "lon": 55.0719816267765
                        },
                        {
                            "lat": 61.38136920405503,
                            "lon": 55.072049825319844
                        },
                        {
                            "lat": 61.38148797947236,
                            "lon": 55.07025520048722
                        },
                        {
                            "lat": 61.37836057139603,
                            "lon": 55.07018700646716
                        }
                    ],
                    [
                        {
                            "lat": 61.340712121048924,
                            "lon": 55.0711569842491
                        },
                        {
                            "lat": 61.340591516779654,
                            "lon": 55.0729515491548
                        },
                        {
                            "lat": 61.343719044200654,
                            "lon": 55.07302071534717
                        },
                        {
                            "lat": 61.34383950881883,
                            "lon": 55.071226145854105
                        },
                        {
                            "lat": 61.340712121048924,
                            "lon": 55.0711569842491
                        }
                    ],
                    [
                        {
                            "lat": 61.38113161352904,
                            "lon": 55.07563907287006
                        },
                        {
                            "lat": 61.37800378642666,
                            "lon": 55.075570865278955
                        },
                        {
                            "lat": 61.37788483160762,
                            "lon": 55.077365483472036
                        },
                        {
                            "lat": 61.38101279841639,
                            "lon": 55.07743369558765
                        },
                        {
                            "lat": 61.38113161352904,
                            "lon": 55.07563907287006
                        }
                    ],
                    [
                        {
                            "lat": 61.36359520714924,
                            "lon": 55.15073854497406
                        },
                        {
                            "lat": 61.3634752729728,
                            "lon": 55.15253311986954
                        },
                        {
                            "lat": 61.36660908142754,
                            "lon": 55.15260176387255
                        },
                        {
                            "lat": 61.3667288752503,
                            "lon": 55.15080718441959
                        },
                        {
                            "lat": 61.36359520714924,
                            "lon": 55.15073854497406
                        }
                    ],
                    [
                        {
                            "lat": 61.350940173138945,
                            "lon": 55.1522577389016
                        },
                        {
                            "lat": 61.347806431754805,
                            "lon": 55.152188692424396
                        },
                        {
                            "lat": 61.34792706766911,
                            "lon": 55.15039414039629
                        },
                        {
                            "lat": 61.34479348008736,
                            "lon": 55.15032501801596
                        },
                        {
                            "lat": 61.34467270383159,
                            "lon": 55.15211956545462
                        },
                        {
                            "lat": 61.344551914099284,
                            "lon": 55.15391411218353
                        },
                        {
                            "lat": 61.347685782379536,
                            "lon": 55.153983243743184
                        },
                        {
                            "lat": 61.350819664122774,
                            "lon": 55.154052294804934
                        },
                        {
                            "lat": 61.350940173138945,
                            "lon": 55.1522577389016
                        }
                    ],
                    [
                        {
                            "lat": 61.335392798251426,
                            "lon": 55.15011716795902
                        },
                        {
                            "lat": 61.33527160098364,
                            "lon": 55.15191170159733
                        },
                        {
                            "lat": 61.33515039019248,
                            "lon": 55.15370623452466
                        },
                        {
                            "lat": 61.33828421799015,
                            "lon": 55.15377560757353
                        },
                        {
                            "lat": 61.33816313404009,
                            "lon": 55.15557014439616
                        },
                        {
                            "lat": 61.34129711571612,
                            "lon": 55.1556394415503
                        },
                        {
                            "lat": 61.341418059297624,
                            "lon": 55.15384490012674
                        },
                        {
                            "lat": 61.34153898938493,
                            "lon": 55.152050357993
                        },
                        {
                            "lat": 61.338405288430415,
                            "lon": 55.15198107004033
                        },
                        {
                            "lat": 61.33852634536291,
                            "lon": 55.150186531796585
                        },
                        {
                            "lat": 61.335392798251426,
                            "lon": 55.15011716795902
                        }
                    ],
                    [
                        {
                            "lat": 61.36034147790096,
                            "lon": 55.15246439537018
                        },
                        {
                            "lat": 61.36046155242906,
                            "lon": 55.15066982503753
                        },
                        {
                            "lat": 61.35732791110534,
                            "lon": 55.15060102461072
                        },
                        {
                            "lat": 61.35419428319372,
                            "lon": 55.15053214369441
                        },
                        {
                            "lat": 61.354073927968415,
                            "lon": 55.15232670488546
                        },
                        {
                            "lat": 61.35720769622763,
                            "lon": 55.15239559037523
                        },
                        {
                            "lat": 61.36034147790096,
                            "lon": 55.15246439537018
                        }
                    ],
                    [
                        {
                            "lat": 61.360221389974335,
                            "lon": 55.15425896499514
                        },
                        {
                            "lat": 61.357087467935784,
                            "lon": 55.15419015543166
                        },
                        {
                            "lat": 61.353953559313396,
                            "lon": 55.154121265368005
                        },
                        {
                            "lat": 61.350819664122774,
                            "lon": 55.154052294804934
                        },
                        {
                            "lat": 61.35069914165925,
                            "lon": 55.15584684999933
                        },
                        {
                            "lat": 61.353833177226605,
                            "lon": 55.15591582514202
                        },
                        {
                            "lat": 61.35696722622778,
                            "lon": 55.155984719779966
                        },
                        {
                            "lat": 61.35684697110157,
                            "lon": 55.15777928342017
                        },
                        {
                            "lat": 61.35371278170599,
                            "lon": 55.15771038420751
                        },
                        {
                            "lat": 61.35057860574628,
                            "lon": 55.15764140448479
                        },
                        {
                            "lat": 61.34744444323801,
                            "lon": 55.15757234425274
                        },
                        {
                            "lat": 61.34431029419684,
                            "lon": 55.157503203512135
                        },
                        {
                            "lat": 61.34418946402263,
                            "lon": 55.159297748111754
                        },
                        {
                            "lat": 61.341055188062306,
                            "lon": 55.15922852226691
                        },
                        {
                            "lat": 61.34093420398588,
                            "lon": 55.161023061559895
                        },
                        {
                            "lat": 61.344068620363636,
                            "lon": 55.1610922920016
                        },
                        {
                            "lat": 61.34720305022817,
                            "lon": 55.16116144192484
                        },
                        {
                            "lat": 61.35033749356388,
                            "lon": 55.161230511328824
                        },
                        {
                            "lat": 61.35347195035516,
                            "lon": 55.16129950021285
                        },
                        {
                            "lat": 61.356606420586374,
                            "lon": 55.161368408576124
                        },
                        {
                            "lat": 61.35974090424192,
                            "lon": 55.16143723641792
                        },
                        {
                            "lat": 61.36287540130615,
                            "lon": 55.16150598373747
                        },
                        {
                            "lat": 61.36299540241752,
                            "lon": 55.15971141237852
                        },
                        {
                            "lat": 61.36612977244311,
                            "lon": 55.15978007461557
                        },
                        {
                            "lat": 61.366009911763484,
                            "lon": 55.16157465053405
                        },
                        {
                            "lat": 61.36914443559829,
                            "lon": 55.16164323680689
                        },
                        {
                            "lat": 61.37227897279494,
                            "lon": 55.16171174255524
                        },
                        {
                            "lat": 61.37239855260497,
                            "lon": 55.15991715753372
                        },
                        {
                            "lat": 61.375532962710004,
                            "lon": 55.15998557821332
                        },
                        {
                            "lat": 61.37866738614363,
                            "lon": 55.16005391837228
                        },
                        {
                            "lat": 61.378548087211286,
                            "lon": 55.16184851247554
                        },
                        {
                            "lat": 61.381682664399776,
                            "lon": 55.16191677664598
                        },
                        {
                            "lat": 61.381801822890225,
                            "lon": 55.16012217800985
                        },
                        {
                            "lat": 61.381920968083314,
                            "lon": 55.158327578668825
                        },
                        {
                            "lat": 61.37878667176299,
                            "lon": 55.158259323563705
                        },
                        {
                            "lat": 61.37565238875361,
                            "lon": 55.15819098794255
                        },
                        {
                            "lat": 61.37251811907079,
                            "lon": 55.15812257180607
                        },
                        {
                            "lat": 61.36938386273015,
                            "lon": 55.15805407515503
                        },
                        {
                            "lat": 61.36624961974728,
                            "lon": 55.157985497990175
                        },
                        {
                            "lat": 61.36311539013782,
                            "lon": 55.15791684031224
                        },
                        {
                            "lat": 61.36323536446912,
                            "lon": 55.15612226753862
                        },
                        {
                            "lat": 61.36636945367807,
                            "lon": 55.156190920657856
                        },
                        {
                            "lat": 61.36648927423749,
                            "lon": 55.15439634261865
                        },
                        {
                            "lat": 61.36335532541344,
                            "lon": 55.154327694057734
                        },
                        {
                            "lat": 61.360221389974335,
                            "lon": 55.15425896499514
                        }
                    ],
                    [
                        {
                            "lat": 61.33478667665835,
                            "lon": 55.159089829040745
                        },
                        {
                            "lat": 61.33792092560233,
                            "lon": 55.15915921590964
                        },
                        {
                            "lat": 61.341055188062306,
                            "lon": 55.15922852226691
                        },
                        {
                            "lat": 61.341176158638355,
                            "lon": 55.157433982263676
                        },
                        {
                            "lat": 61.33804203657818,
                            "lon": 55.157364680508195
                        },
                        {
                            "lat": 61.33490792803189,
                            "lon": 55.15729529824638
                        },
                        {
                            "lat": 61.33478667665835,
                            "lon": 55.159089829040745
                        }
                    ],
                    [
                        {
                            "lat": 61.33199415618077,
                            "lon": 55.20036384103611
                        },
                        {
                            "lat": 61.33187257948045,
                            "lon": 55.20215835476155
                        },
                        {
                            "lat": 61.33501020265884,
                            "lon": 55.20222785232445
                        },
                        {
                            "lat": 61.33513163858608,
                            "lon": 55.200433333982
                        },
                        {
                            "lat": 61.33199415618077,
                            "lon": 55.20036384103611
                        }
                    ],
                    [
                        {
                            "lat": 61.344544167119466,
                            "lon": 55.20064132901051
                        },
                        {
                            "lat": 61.3414066440711,
                            "lon": 55.20057207796995
                        },
                        {
                            "lat": 61.33826913455474,
                            "lon": 55.20050274629354
                        },
                        {
                            "lat": 61.338147839402644,
                            "lon": 55.202297269247644
                        },
                        {
                            "lat": 61.3412854896962,
                            "lon": 55.20236660553038
                        },
                        {
                            "lat": 61.344423153523806,
                            "lon": 55.20243586117192
                        },
                        {
                            "lat": 61.344544167119466,
                            "lon": 55.20064132901051
                        }
                    ],
                    [
                        {
                            "lat": 61.35045701719342,
                            "lon": 55.20616321109621
                        },
                        {
                            "lat": 61.3503362446951,
                            "lon": 55.20795775031673
                        },
                        {
                            "lat": 61.35347437148123,
                            "lon": 55.20802677778741
                        },
                        {
                            "lat": 61.3535950031471,
                            "lon": 55.206232233980714
                        },
                        {
                            "lat": 61.35045701719342,
                            "lon": 55.20616321109621
                        }
                    ],
                    [
                        {
                            "lat": 61.3430911113038,
                            "lon": 55.22217566810089
                        },
                        {
                            "lat": 61.34296993535132,
                            "lon": 55.223970191744336
                        },
                        {
                            "lat": 61.34610930329615,
                            "lon": 55.2240394219237
                        },
                        {
                            "lat": 61.346230338279106,
                            "lon": 55.222244893679644
                        },
                        {
                            "lat": 61.3430911113038,
                            "lon": 55.22217566810089
                        }
                    ],
                    [
                        {
                            "lat": 61.40756605214871,
                            "lon": 55.05464588438973
                        },
                        {
                            "lat": 61.40443979506944,
                            "lon": 55.05457837273698
                        },
                        {
                            "lat": 61.40432211532878,
                            "lon": 55.056373035403006
                        },
                        {
                            "lat": 61.407448511944445,
                            "lon": 55.05644055153297
                        },
                        {
                            "lat": 61.40756605214871,
                            "lon": 55.05464588438973
                        }
                    ],
                    [
                        {
                            "lat": 61.38568252826421,
                            "lon": 55.054171618392864
                        },
                        {
                            "lat": 61.385564011347014,
                            "lon": 55.055966254084176
                        },
                        {
                            "lat": 61.38243770677994,
                            "lon": 55.05589817644806
                        },
                        {
                            "lat": 61.38231903712857,
                            "lon": 55.05769280692016
                        },
                        {
                            "lat": 61.38544548123359,
                            "lon": 55.057760889071005
                        },
                        {
                            "lat": 61.38857193853683,
                            "lon": 55.057828891003616
                        },
                        {
                            "lat": 61.38869032911035,
                            "lon": 55.05603425150733
                        },
                        {
                            "lat": 61.388808706503106,
                            "lon": 55.05423961130698
                        },
                        {
                            "lat": 61.38568252826421,
                            "lon": 55.054171618392864
                        }
                    ],
                    [
                        {
                            "lat": 61.41069232231386,
                            "lon": 55.05471331582969
                        },
                        {
                            "lat": 61.41057492164794,
                            "lon": 55.05650798744485
                        },
                        {
                            "lat": 61.413701344423856,
                            "lon": 55.056575343137894
                        },
                        {
                            "lat": 61.413818605549395,
                            "lon": 55.05478066705612
                        },
                        {
                            "lat": 61.41069232231386,
                            "lon": 55.05471331582969
                        }
                    ],
                    [
                        {
                            "lat": 61.406978220228865,
                            "lon": 55.06361921308915
                        },
                        {
                            "lat": 61.40686061456541,
                            "lon": 55.06541387672394
                        },
                        {
                            "lat": 61.4099877221931,
                            "lon": 55.06548133500138
                        },
                        {
                            "lat": 61.41010518824073,
                            "lon": 55.06368666689267
                        },
                        {
                            "lat": 61.406978220228865,
                            "lon": 55.06361921308915
                        }
                    ],
                    [
                        {
                            "lat": 61.3785983618226,
                            "lon": 55.06659776373235
                        },
                        {
                            "lat": 61.37847947322995,
                            "lon": 55.06839238545244
                        },
                        {
                            "lat": 61.38160674166193,
                            "lon": 55.06846057494961
                        },
                        {
                            "lat": 61.38172549062575,
                            "lon": 55.06666594870705
                        },
                        {
                            "lat": 61.3785983618226,
                            "lon": 55.06659776373235
                        }
                    ],
                    [
                        {
                            "lat": 61.39387952714243,
                            "lon": 55.072321816886095
                        },
                        {
                            "lat": 61.39399774390033,
                            "lon": 55.07052717401344
                        },
                        {
                            "lat": 61.39087028300595,
                            "lon": 55.070459301016456
                        },
                        {
                            "lat": 61.38774283529283,
                            "lon": 55.0703913477626
                        },
                        {
                            "lat": 61.38786131818769,
                            "lon": 55.06859671319517
                        },
                        {
                            "lat": 61.38473402331968,
                            "lon": 55.06852868419743
                        },
                        {
                            "lat": 61.38461540077647,
                            "lon": 55.070323314252605
                        },
                        {
                            "lat": 61.384496765020984,
                            "lon": 55.0721179436032
                        },
                        {
                            "lat": 61.384378116051245,
                            "lon": 55.073912572249206
                        },
                        {
                            "lat": 61.38750582991083,
                            "lon": 55.07398061478493
                        },
                        {
                            "lat": 61.390633556971174,
                            "lon": 55.0740485770539
                        },
                        {
                            "lat": 61.39075192658019,
                            "lon": 55.072253939387075
                        },
                        {
                            "lat": 61.39387952714243,
                            "lon": 55.072321816886095
                        }
                    ],
                    [
                        {
                            "lat": 61.39712521796048,
                            "lon": 55.07059496675279
                        },
                        {
                            "lat": 61.397007140872425,
                            "lon": 55.072389614122194
                        },
                        {
                            "lat": 61.40013476775467,
                            "lon": 55.07245733109464
                        },
                        {
                            "lat": 61.40025270517087,
                            "lon": 55.070662679233806
                        },
                        {
                            "lat": 61.39712521796048,
                            "lon": 55.07059496675279
                        }
                    ],
                    [
                        {
                            "lat": 61.412293191913086,
                            "lon": 55.07811140144758
                        },
                        {
                            "lat": 61.40916509344442,
                            "lon": 55.07804399212412
                        },
                        {
                            "lat": 61.40928265110978,
                            "lon": 55.07624932892505
                        },
                        {
                            "lat": 61.40615470546165,
                            "lon": 55.076181843795794
                        },
                        {
                            "lat": 61.406037008071955,
                            "lon": 55.07797650251815
                        },
                        {
                            "lat": 61.40591929757055,
                            "lon": 55.07977116053869
                        },
                        {
                            "lat": 61.4090475226829,
                            "lon": 55.07983865462177
                        },
                        {
                            "lat": 61.41217576089339,
                            "lon": 55.079906068417
                        },
                        {
                            "lat": 61.41205831679104,
                            "lon": 55.081700734685406
                        },
                        {
                            "lat": 61.415186707843546,
                            "lon": 55.081768072658946
                        },
                        {
                            "lat": 61.41831511196516,
                            "lon": 55.081835330337896
                        },
                        {
                            "lat": 61.4214435291404,
                            "lon": 55.081902507721516
                        },
                        {
                            "lat": 61.42156055395877,
                            "lon": 55.0801078280684
                        },
                        {
                            "lat": 61.424688844406795,
                            "lon": 55.080174920705
                        },
                        {
                            "lat": 61.42457195935373,
                            "lon": 55.081969604809046
                        },
                        {
                            "lat": 61.42445506127808,
                            "lon": 55.08376428821361
                        },
                        {
                            "lat": 61.427583644296746,
                            "lon": 55.08383130945039
                        },
                        {
                            "lat": 61.42770040258961,
                            "lon": 55.08203662159978
                        },
                        {
                            "lat": 61.430828858832534,
                            "lon": 55.082103558092996
                        },
                        {
                            "lat": 61.43071224032442,
                            "lon": 55.08389825038431
                        },
                        {
                            "lat": 61.43059560882281,
                            "lon": 55.085692941976944
                        },
                        {
                            "lat": 61.43372435764625,
                            "lon": 55.085759807043104
                        },
                        {
                            "lat": 61.4338408493456,
                            "lon": 55.08396511101467
                        },
                        {
                            "lat": 61.436969471344774,
                            "lon": 55.084031891340736
                        },
                        {
                            "lat": 61.436853119449616,
                            "lon": 55.08582659179968
                        },
                        {
                            "lat": 61.43673675459006,
                            "lon": 55.08762129156068
                        },
                        {
                            "lat": 61.43986566917957,
                            "lon": 55.087688000432486
                        },
                        {
                            "lat": 61.442994596719934,
                            "lon": 55.08775462898792
                        },
                        {
                            "lat": 61.443110681934094,
                            "lon": 55.08595992038109
                        },
                        {
                            "lat": 61.43998189421741,
                            "lon": 55.085893296245914
                        },
                        {
                            "lat": 61.44009810630638,
                            "lon": 55.084098591361794
                        },
                        {
                            "lat": 61.4402143054484,
                            "lon": 55.0823038857802
                        },
                        {
                            "lat": 61.440330491645476,
                            "lon": 55.080509179501114
                        },
                        {
                            "lat": 61.440446664899525,
                            "lon": 55.07871447252455
                        },
                        {
                            "lat": 61.44357489347674,
                            "lon": 55.07878107898266
                        },
                        {
                            "lat": 61.4436909140437,
                            "lon": 55.07698636689036
                        },
                        {
                            "lat": 61.4468190157984,
                            "lon": 55.07705288864495
                        },
                        {
                            "lat": 61.44670313497947,
                            "lon": 55.07884760515022
                        },
                        {
                            "lat": 61.449831389392195,
                            "lon": 55.078914051026544
                        },
                        {
                            "lat": 61.452959656699406,
                            "lon": 55.07898041661089
                        },
                        {
                            "lat": 61.452844042503436,
                            "lon": 55.08077514123029
                        },
                        {
                            "lat": 61.45597246245902,
                            "lon": 55.080841430919385
                        },
                        {
                            "lat": 61.45608793688558,
                            "lon": 55.07904670190255
                        },
                        {
                            "lat": 61.45620339844867,
                            "lon": 55.07725197219024
                        },
                        {
                            "lat": 61.453075258016405,
                            "lon": 55.07718569129562
                        },
                        {
                            "lat": 61.44994713046114,
                            "lon": 55.07711933011362
                        },
                        {
                            "lat": 61.450062858637565,
                            "lon": 55.07532460850441
                        },
                        {
                            "lat": 61.44693488370929,
                            "lon": 55.07525817144302
                        },
                        {
                            "lat": 61.443806921687106,
                            "lon": 55.07519165410099
                        },
                        {
                            "lat": 61.44067897258652,
                            "lon": 55.07512505647907
                        },
                        {
                            "lat": 61.440562825212545,
                            "lon": 55.07691976485053
                        },
                        {
                            "lat": 61.43743474932048,
                            "lon": 55.07685308252623
                        },
                        {
                            "lat": 61.43755103642302,
                            "lon": 55.07505837857796
                        },
                        {
                            "lat": 61.434423113212155,
                            "lon": 55.074991620398386
                        },
                        {
                            "lat": 61.43430668638299,
                            "lon": 55.07678631991815
                        },
                        {
                            "lat": 61.431178636415595,
                            "lon": 55.07671947702704
                        },
                        {
                            "lat": 61.42805059943383,
                            "lon": 55.07665255385359
                        },
                        {
                            "lat": 61.424922575453174,
                            "lon": 55.07658555039856
                        },
                        {
                            "lat": 61.42179456448916,
                            "lon": 55.076518466662684
                        },
                        {
                            "lat": 61.4186665665573,
                            "lon": 55.07645130264665
                        },
                        {
                            "lat": 61.41553858167311,
                            "lon": 55.076384058351216
                        },
                        {
                            "lat": 61.41565584682064,
                            "lon": 55.07458938551406
                        },
                        {
                            "lat": 61.41878369199004,
                            "lon": 55.0746566253491
                        },
                        {
                            "lat": 61.41890080437709,
                            "lon": 55.072861947351356
                        },
                        {
                            "lat": 61.41901790372043,
                            "lon": 55.071067268653415
                        },
                        {
                            "lat": 61.415890337934066,
                            "lon": 55.0710000377379
                        },
                        {
                            "lat": 61.41577309890695,
                            "lon": 55.072794711976265
                        },
                        {
                            "lat": 61.412645406496054,
                            "lon": 55.07272739633315
                        },
                        {
                            "lat": 61.412528014712436,
                            "lon": 55.07452206540563
                        },
                        {
                            "lat": 61.412410609852095,
                            "lon": 55.076316733777105
                        },
                        {
                            "lat": 61.412293191913086,
                            "lon": 55.07811140144758
                        }
                    ],
                    [
                        {
                            "lat": 61.39387952714243,
                            "lon": 55.072321816886095
                        },
                        {
                            "lat": 61.39376129721678,
                            "lon": 55.07411645905538
                        },
                        {
                            "lat": 61.396889050632126,
                            "lon": 55.07418426078861
                        },
                        {
                            "lat": 61.397007140872425,
                            "lon": 55.072389614122194
                        },
                        {
                            "lat": 61.39387952714243,
                            "lon": 55.072321816886095
                        }
                    ],
                    [
                        {
                            "lat": 61.40013476775467,
                            "lon": 55.07245733109464
                        },
                        {
                            "lat": 61.40001681720173,
                            "lon": 55.07425198225288
                        },
                        {
                            "lat": 61.40314459691007,
                            "lon": 55.074319623447465
                        },
                        {
                            "lat": 61.40326240777366,
                            "lon": 55.07252496780269
                        },
                        {
                            "lat": 61.40013476775467,
                            "lon": 55.07245733109464
                        }
                    ],
                    [
                        {
                            "lat": 61.40279108557192,
                            "lon": 55.07970358616851
                        },
                        {
                            "lat": 61.402908935811176,
                            "lon": 55.077908932630386
                        },
                        {
                            "lat": 61.40302677292324,
                            "lon": 55.07611427839003
                        },
                        {
                            "lat": 61.399898853510045,
                            "lon": 55.07604663270854
                        },
                        {
                            "lat": 61.39677094723758,
                            "lon": 55.075978906752034
                        },
                        {
                            "lat": 61.39665283068679,
                            "lon": 55.07777355201243
                        },
                        {
                            "lat": 61.39653470097776,
                            "lon": 55.079568196569795
                        },
                        {
                            "lat": 61.39966288670247,
                            "lon": 55.07963593151197
                        },
                        {
                            "lat": 61.40279108557192,
                            "lon": 55.07970358616851
                        }
                    ],
                    [
                        {
                            "lat": 61.40869473180127,
                            "lon": 55.08522263790598
                        },
                        {
                            "lat": 61.41182338933041,
                            "lon": 55.08529006511898
                        },
                        {
                            "lat": 61.41194085960403,
                            "lon": 55.08349540025272
                        },
                        {
                            "lat": 61.40881234186329,
                            "lon": 55.083427977512706
                        },
                        {
                            "lat": 61.405683837224714,
                            "lon": 55.083360474474226
                        },
                        {
                            "lat": 61.40556608737626,
                            "lon": 55.0851551303892
                        },
                        {
                            "lat": 61.40869473180127,
                            "lon": 55.08522263790598
                        }
                    ],
                    [
                        {
                            "lat": 61.40746777112317,
                            "lon": 55.15169217219122
                        },
                        {
                            "lat": 61.40734980208238,
                            "lon": 55.15348681040655
                        },
                        {
                            "lat": 61.41048379625896,
                            "lon": 55.153554327382786
                        },
                        {
                            "lat": 61.410601624917824,
                            "lon": 55.1517596846846
                        },
                        {
                            "lat": 61.40746777112317,
                            "lon": 55.15169217219122
                        }
                    ],
                    [
                        {
                            "lat": 61.39168002897738,
                            "lon": 55.15314801793919
                        },
                        {
                            "lat": 61.39156134481462,
                            "lon": 55.15494263295658
                        },
                        {
                            "lat": 61.38842728947299,
                            "lon": 55.154874628435806
                        },
                        {
                            "lat": 61.38830845166469,
                            "lon": 55.15666923823389
                        },
                        {
                            "lat": 61.385174269184034,
                            "lon": 55.15660114868451
                        },
                        {
                            "lat": 61.385055277698946,
                            "lon": 55.158395753257146
                        },
                        {
                            "lat": 61.38818960059431,
                            "lon": 55.15846384732791
                        },
                        {
                            "lat": 61.388070736259834,
                            "lon": 55.16025845571787
                        },
                        {
                            "lat": 61.39120521285159,
                            "lon": 55.16032647378684
                        },
                        {
                            "lat": 61.39132393675374,
                            "lon": 55.15853186088042
                        },
                        {
                            "lat": 61.39445828616167,
                            "lon": 55.158599793913886
                        },
                        {
                            "lat": 61.39759264880245,
                            "lon": 55.1586676464276
                        },
                        {
                            "lat": 61.397474205771005,
                            "lon": 55.160462268350955
                        },
                        {
                            "lat": 61.400608722067396,
                            "lon": 55.16053004484464
                        },
                        {
                            "lat": 61.40072702466047,
                            "lon": 55.158735418420804
                        },
                        {
                            "lat": 61.4038614137201,
                            "lon": 55.15880310989278
                        },
                        {
                            "lat": 61.40374325156741,
                            "lon": 55.16059774081175
                        },
                        {
                            "lat": 61.403625076226696,
                            "lon": 55.162392371028666
                        },
                        {
                            "lat": 61.406759759372726,
                            "lon": 55.16245999095869
                        },
                        {
                            "lat": 61.40989445569313,
                            "lon": 55.1625275303553
                        },
                        {
                            "lat": 61.41302916517231,
                            "lon": 55.16259498921783
                        },
                        {
                            "lat": 61.413146919133005,
                            "lon": 55.16080034554628
                        },
                        {
                            "lat": 61.416281501291316,
                            "lon": 55.160867719399725
                        },
                        {
                            "lat": 61.419416096575155,
                            "lon": 55.16093501272293
                        },
                        {
                            "lat": 61.422550704968856,
                            "lon": 55.161002225515155
                        },
                        {
                            "lat": 61.42266802443728,
                            "lon": 55.1592075677373
                        },
                        {
                            "lat": 61.4258025054709,
                            "lon": 55.159274695539985
                        },
                        {
                            "lat": 61.428936999581204,
                            "lon": 55.15934174281555
                        },
                        {
                            "lat": 61.432071506752514,
                            "lon": 55.15940870956329
                        },
                        {
                            "lat": 61.432188391806456,
                            "lon": 55.15761403772953
                        },
                        {
                            "lat": 61.43532277157848,
                            "lon": 55.15768091950726
                        },
                        {
                            "lat": 61.43845716437834,
                            "lon": 55.15774772076102
                        },
                        {
                            "lat": 61.44159157019038,
                            "lon": 55.15781444149008
                        },
                        {
                            "lat": 61.444725988998975,
                            "lon": 55.15788108169372
                        },
                        {
                            "lat": 61.44484229928127,
                            "lon": 55.15608639142945
                        },
                        {
                            "lat": 61.447976590633836,
                            "lon": 55.156152946687236
                        },
                        {
                            "lat": 61.44786042078852,
                            "lon": 55.1579476413712
                        },
                        {
                            "lat": 61.4509948655434,
                            "lon": 55.158014120521806
                        },
                        {
                            "lat": 61.45412932324795,
                            "lon": 55.15808051914482
                        },
                        {
                            "lat": 61.45424521221337,
                            "lon": 55.15628581563746
                        },
                        {
                            "lat": 61.45111089494975,
                            "lon": 55.15621942142347
                        },
                        {
                            "lat": 61.45122691140837,
                            "lon": 55.1544247216291
                        },
                        {
                            "lat": 61.45134291492122,
                            "lon": 55.152630021138684
                        },
                        {
                            "lat": 61.44820889143638,
                            "lon": 55.15256355522996
                        },
                        {
                            "lat": 61.445074880910944,
                            "lon": 55.15249700881038
                        },
                        {
                            "lat": 61.44194088336054,
                            "lon": 55.15243038188062
                        },
                        {
                            "lat": 61.438806898800756,
                            "lon": 55.15236367444143
                        },
                        {
                            "lat": 61.43869033366885,
                            "lon": 55.1541583572456
                        },
                        {
                            "lat": 61.43555622171551,
                            "lon": 55.15409156486281
                        },
                        {
                            "lat": 61.432422122786015,
                            "lon": 55.154024691966725
                        },
                        {
                            "lat": 61.42928803689599,
                            "lon": 55.15395773855805
                        },
                        {
                            "lat": 61.42615396406102,
                            "lon": 55.15389070463754
                        },
                        {
                            "lat": 61.42301990429677,
                            "lon": 55.153823590205896
                        },
                        {
                            "lat": 61.42313717140799,
                            "lon": 55.15202892962953
                        },
                        {
                            "lat": 61.42000326511994,
                            "lon": 55.15196173914905
                        },
                        {
                            "lat": 61.41988585761881,
                            "lon": 55.153756395263876
                        },
                        {
                            "lat": 61.4167518240428,
                            "lon": 55.15368911981219
                        },
                        {
                            "lat": 61.4136178035843,
                            "lon": 55.15362176385158
                        },
                        {
                            "lat": 61.4135001021762,
                            "lon": 55.155416410326495
                        },
                        {
                            "lat": 61.41036595445132,
                            "lon": 55.155348969379766
                        },
                        {
                            "lat": 61.40723181987722,
                            "lon": 55.15528144792025
                        },
                        {
                            "lat": 61.40409769846949,
                            "lon": 55.15521384594866
                        },
                        {
                            "lat": 61.40421582107019,
                            "lon": 55.15341921292356
                        },
                        {
                            "lat": 61.40108185323796,
                            "lon": 55.15335153493459
                        },
                        {
                            "lat": 61.39794789860136,
                            "lon": 55.153283776440404
                        },
                        {
                            "lat": 61.394813957175955,
                            "lon": 55.15321593744169
                        },
                        {
                            "lat": 61.39168002897738,
                            "lon": 55.15314801793919
                        }
                    ],
                    [
                        {
                            "lat": 61.39469541340071,
                            "lon": 55.15501055696898
                        },
                        {
                            "lat": 61.3945768563966,
                            "lon": 55.15680517579305
                        },
                        {
                            "lat": 61.391442647407416,
                            "lon": 55.15673724727032
                        },
                        {
                            "lat": 61.39156134481462,
                            "lon": 55.15494263295658
                        },
                        {
                            "lat": 61.39469541340071,
                            "lon": 55.15501055696898
                        }
                    ],
                    [
                        {
                            "lat": 61.41663426303623,
                            "lon": 55.155483770759716
                        },
                        {
                            "lat": 61.416516688910114,
                            "lon": 55.157278421006836
                        },
                        {
                            "lat": 61.413382387632936,
                            "lon": 55.157211056100614
                        },
                        {
                            "lat": 61.4135001021762,
                            "lon": 55.155416410326495
                        },
                        {
                            "lat": 61.41663426303623,
                            "lon": 55.155483770759716
                        }
                    ],
                    [
                        {
                            "lat": 61.40699581596574,
                            "lon": 55.158870720842756
                        },
                        {
                            "lat": 61.407113824505664,
                            "lon": 55.15707608473232
                        },
                        {
                            "lat": 61.410248099492925,
                            "lon": 55.157143610675526
                        },
                        {
                            "lat": 61.41013023138176,
                            "lon": 55.15893825127003
                        },
                        {
                            "lat": 61.40699581596574,
                            "lon": 55.158870720842756
                        }
                    ],
                    [
                        {
                            "lat": 61.42278533081341,
                            "lon": 55.1574129092598
                        },
                        {
                            "lat": 61.42290262409924,
                            "lon": 55.15561825008267
                        },
                        {
                            "lat": 61.42603682427101,
                            "lon": 55.15568536897091
                        },
                        {
                            "lat": 61.42591967140829,
                            "lon": 55.157480032605065
                        },
                        {
                            "lat": 61.42278533081341,
                            "lon": 55.1574129092598
                        }
                    ],
                    [
                        {
                            "lat": 61.37589120086317,
                            "lon": 55.15460180528395
                        },
                        {
                            "lat": 61.37577180147066,
                            "lon": 55.15639639696607
                        },
                        {
                            "lat": 61.37890594407139,
                            "lon": 55.15646472804987
                        },
                        {
                            "lat": 61.379025203070896,
                            "lon": 55.15467013183074
                        },
                        {
                            "lat": 61.37589120086317,
                            "lon": 55.15460180528395
                        }
                    ],
                    [
                        {
                            "lat": 61.41137922878253,
                            "lon": 55.187719927021085
                        },
                        {
                            "lat": 61.411261277464604,
                            "lon": 55.18951456017803
                        },
                        {
                            "lat": 61.41439810892337,
                            "lon": 55.18958200566697
                        },
                        {
                            "lat": 61.41451591954266,
                            "lon": 55.187787368029795
                        },
                        {
                            "lat": 61.41137922878253,
                            "lon": 55.187719927021085
                        }
                    ],
                    [
                        {
                            "lat": 61.38616710134393,
                            "lon": 55.188972094192074
                        },
                        {
                            "lat": 61.383030389007416,
                            "lon": 55.18890392319562
                        },
                        {
                            "lat": 61.38291115817819,
                            "lon": 55.19069851508552
                        },
                        {
                            "lat": 61.38604801121087,
                            "lon": 55.190766690610644
                        },
                        {
                            "lat": 61.38616710134393,
                            "lon": 55.188972094192074
                        }
                    ],
                    [
                        {
                            "lat": 61.389303826978875,
                            "lon": 55.189040184579525
                        },
                        {
                            "lat": 61.38918487754401,
                            "lon": 55.19083478552148
                        },
                        {
                            "lat": 61.392321757161945,
                            "lon": 55.19090279981723
                        },
                        {
                            "lat": 61.39244056589661,
                            "lon": 55.18910819435729
                        },
                        {
                            "lat": 61.389303826978875,
                            "lon": 55.189040184579525
                        }
                    ],
                    [
                        {
                            "lat": 61.40729744567733,
                            "lon": 55.2020094151314
                        },
                        {
                            "lat": 61.40717924809587,
                            "lon": 55.203804038190505
                        },
                        {
                            "lat": 61.4103171925185,
                            "lon": 55.20387160019335
                        },
                        {
                            "lat": 61.41043524927796,
                            "lon": 55.20207697264547
                        },
                        {
                            "lat": 61.40729744567733,
                            "lon": 55.2020094151314
                        }
                    ],
                    [
                        {
                            "lat": 61.40717924809587,
                            "lon": 55.203804038190505
                        },
                        {
                            "lat": 61.404041316878164,
                            "lon": 55.20373639553076
                        },
                        {
                            "lat": 61.40392296525617,
                            "lon": 55.20553101339373
                        },
                        {
                            "lat": 61.40706103730951,
                            "lon": 55.205598660548
                        },
                        {
                            "lat": 61.40717924809587,
                            "lon": 55.203804038190505
                        }
                    ],
                    [
                        {
                            "lat": 61.4103171925185,
                            "lon": 55.20387160019335
                        },
                        {
                            "lat": 61.41019912256978,
                            "lon": 55.205666227040034
                        },
                        {
                            "lat": 61.41333722102129,
                            "lon": 55.20573371286906
                        },
                        {
                            "lat": 61.41345515013033,
                            "lon": 55.203939081538536
                        },
                        {
                            "lat": 61.4103171925185,
                            "lon": 55.20387160019335
                        }
                    ],
                    [
                        {
                            "lat": 61.43500505287531,
                            "lon": 55.0660181123265
                        },
                        {
                            "lat": 61.43488869086715,
                            "lon": 55.06781281533725
                        },
                        {
                            "lat": 61.438016055326614,
                            "lon": 55.067879555806705
                        },
                        {
                            "lat": 61.43813227768568,
                            "lon": 55.066084848369435
                        },
                        {
                            "lat": 61.43500505287531,
                            "lon": 55.0660181123265
                        }
                    ],
                    [
                        {
                            "lat": 61.44357489347674,
                            "lon": 55.07878107898266
                        },
                        {
                            "lat": 61.44345885998427,
                            "lon": 55.08057579037789
                        },
                        {
                            "lat": 61.44658724125054,
                            "lon": 55.08064232095883
                        },
                        {
                            "lat": 61.44670313497947,
                            "lon": 55.07884760515022
                        },
                        {
                            "lat": 61.44357489347674,
                            "lon": 55.07878107898266
                        }
                    ],
                    [
                        {
                            "lat": 61.44959986856891,
                            "lon": 55.08250349076359
                        },
                        {
                            "lat": 61.45272841542655,
                            "lon": 55.08256986515381
                        },
                        {
                            "lat": 61.452844042503436,
                            "lon": 55.08077514123029
                        },
                        {
                            "lat": 61.44971563542876,
                            "lon": 55.08070877124321
                        },
                        {
                            "lat": 61.44658724125054,
                            "lon": 55.08064232095883
                        },
                        {
                            "lat": 61.44647133460964,
                            "lon": 55.08243703607075
                        },
                        {
                            "lat": 61.446355415054825,
                            "lon": 55.08423175048598
                        },
                        {
                            "lat": 61.44948408881064,
                            "lon": 55.08429820958763
                        },
                        {
                            "lat": 61.44959986856891,
                            "lon": 55.08250349076359
                        }
                    ],
                    [
                        {
                            "lat": 61.441475106476474,
                            "lon": 55.159609126632084
                        },
                        {
                            "lat": 61.441358629764046,
                            "lon": 55.16140381107684
                        },
                        {
                            "lat": 61.444493329489546,
                            "lon": 55.16147046013171
                        },
                        {
                            "lat": 61.44460966573572,
                            "lon": 55.159675771261135
                        },
                        {
                            "lat": 61.441475106476474,
                            "lon": 55.159609126632084
                        }
                    ],
                    [
                        {
                            "lat": 61.433570233409434,
                            "lon": 55.18460099033266
                        },
                        {
                            "lat": 61.430433745325395,
                            "lon": 55.184534041891894
                        },
                        {
                            "lat": 61.43031666438169,
                            "lon": 55.1863287032474
                        },
                        {
                            "lat": 61.43019957036278,
                            "lon": 55.18812336390426
                        },
                        {
                            "lat": 61.427062813981706,
                            "lon": 55.188056325954356
                        },
                        {
                            "lat": 61.426945566176954,
                            "lon": 55.18985098145904
                        },
                        {
                            "lat": 61.43008246326666,
                            "lon": 55.18991802386247
                        },
                        {
                            "lat": 61.433219373435556,
                            "lon": 55.189984985646596
                        },
                        {
                            "lat": 61.433336339821075,
                            "lon": 55.188190321240214
                        },
                        {
                            "lat": 61.43345329314503,
                            "lon": 55.18639565613556
                        },
                        {
                            "lat": 61.43658993496792,
                            "lon": 55.18646252841437
                        },
                        {
                            "lat": 61.43972658983473,
                            "lon": 55.18652932008311
                        },
                        {
                            "lat": 61.439843248734576,
                            "lon": 55.18473464540145
                        },
                        {
                            "lat": 61.436706734551045,
                            "lon": 55.18466785816942
                        },
                        {
                            "lat": 61.433570233409434,
                            "lon": 55.18460099033266
                        }
                    ],
                    [
                        {
                            "lat": 61.424043446096334,
                            "lon": 55.18619455564597
                        },
                        {
                            "lat": 61.427180048693586,
                            "lon": 55.18626166975063
                        },
                        {
                            "lat": 61.42729727031459,
                            "lon": 55.18446701284784
                        },
                        {
                            "lat": 61.42416080839265,
                            "lon": 55.184399903201246
                        },
                        {
                            "lat": 61.421024359575256,
                            "lon": 55.18433271295283
                        },
                        {
                            "lat": 61.42090685660564,
                            "lon": 55.186127360934144
                        },
                        {
                            "lat": 61.424043446096334,
                            "lon": 55.18619455564597
                        }
                    ],
                    [
                        {
                            "lat": 61.42392607069349,
                            "lon": 55.18798920739125
                        },
                        {
                            "lat": 61.42078934051381,
                            "lon": 55.187922008215615
                        },
                        {
                            "lat": 61.4206718112978,
                            "lon": 55.18971665479721
                        },
                        {
                            "lat": 61.42055426895559,
                            "lon": 55.191511300678926
                        },
                        {
                            "lat": 61.4236912805602,
                            "lon": 55.191578508783365
                        },
                        {
                            "lat": 61.42380868218211,
                            "lon": 55.189783858437046
                        },
                        {
                            "lat": 61.42392607069349,
                            "lon": 55.18798920739125
                        }
                    ],
                    [
                        {
                            "lat": 61.43275137723822,
                            "lon": 55.19716363628912
                        },
                        {
                            "lat": 61.43263434551513,
                            "lon": 55.198958297203916
                        },
                        {
                            "lat": 61.43577197254559,
                            "lon": 55.19902520058821
                        },
                        {
                            "lat": 61.43588886347773,
                            "lon": 55.19723053522856
                        },
                        {
                            "lat": 61.43275137723822,
                            "lon": 55.19716363628912
                        }
                    ],
                    [
                        {
                            "lat": 61.44216387510854,
                            "lon": 55.19736409118034
                        },
                        {
                            "lat": 61.442047265764224,
                            "lon": 55.19915876541362
                        },
                        {
                            "lat": 61.44518493192107,
                            "lon": 55.1992254268533
                        },
                        {
                            "lat": 61.44530140046847,
                            "lon": 55.1974307481912
                        },
                        {
                            "lat": 61.44216387510854,
                            "lon": 55.19736409118034
                        }
                    ],
                    [
                        {
                            "lat": 61.42949673155816,
                            "lon": 55.19889131317291
                        },
                        {
                            "lat": 61.429379545956955,
                            "lon": 55.2006859689388
                        },
                        {
                            "lat": 61.432517300718544,
                            "lon": 55.200752957420406
                        },
                        {
                            "lat": 61.43263434551513,
                            "lon": 55.198958297203916
                        },
                        {
                            "lat": 61.42949673155816,
                            "lon": 55.19889131317291
                        }
                    ],
                    [
                        {
                            "lat": 61.42914513547906,
                            "lon": 55.204275278374276
                        },
                        {
                            "lat": 61.42902791059841,
                            "lon": 55.20606993204384
                        },
                        {
                            "lat": 61.43216608786796,
                            "lon": 55.206136933879556
                        },
                        {
                            "lat": 61.43228317189695,
                            "lon": 55.20434227575823
                        },
                        {
                            "lat": 61.42914513547906,
                            "lon": 55.204275278374276
                        }
                    ],
                    [
                        {
                            "lat": 61.43855928395541,
                            "lon": 55.20447602853708
                        },
                        {
                            "lat": 61.43844248163574,
                            "lon": 55.20627069554592
                        },
                        {
                            "lat": 61.44158069810259,
                            "lon": 55.20633745537509
                        },
                        {
                            "lat": 61.441697359564635,
                            "lon": 55.20454278393049
                        },
                        {
                            "lat": 61.43855928395541,
                            "lon": 55.20447602853708
                        }
                    ],
                    [
                        {
                            "lat": 61.42577236758283,
                            "lon": 55.20779749805397
                        },
                        {
                            "lat": 61.42891067262061,
                            "lon": 55.2078645850146
                        },
                        {
                            "lat": 61.42902791059841,
                            "lon": 55.20606993204384
                        },
                        {
                            "lat": 61.42588974642598,
                            "lon": 55.20600284954073
                        },
                        {
                            "lat": 61.42275159536637,
                            "lon": 55.20593568637096
                        },
                        {
                            "lat": 61.42263407565985,
                            "lon": 55.207730330421334
                        },
                        {
                            "lat": 61.42577236758283,
                            "lon": 55.20779749805397
                        }
                    ],
                    [
                        {
                            "lat": 61.42553757055007,
                            "lon": 55.211386792982786
                        },
                        {
                            "lat": 61.42542015235649,
                            "lon": 55.213181439398326
                        },
                        {
                            "lat": 61.42855888008446,
                            "lon": 55.213248539733904
                        },
                        {
                            "lat": 61.42867615736563,
                            "lon": 55.211453888859644
                        },
                        {
                            "lat": 61.42553757055007,
                            "lon": 55.211386792982786
                        }
                    ],
                    [
                        {
                            "lat": 61.44006291224303,
                            "lon": 55.22966812070369
                        },
                        {
                            "lat": 61.436922863198625,
                            "lon": 55.229601303173716
                        },
                        {
                            "lat": 61.43680587799678,
                            "lon": 55.23139596041484
                        },
                        {
                            "lat": 61.439946068118665,
                            "lon": 55.23146278238611
                        },
                        {
                            "lat": 61.44006291224303,
                            "lon": 55.22966812070369
                        }
                    ],
                    [
                        {
                            "lat": 61.45735730701938,
                            "lon": 55.059304636817544
                        },
                        {
                            "lat": 61.45747262721328,
                            "lon": 55.057509899455624
                        },
                        {
                            "lat": 61.45434602305027,
                            "lon": 55.05744366690253
                        },
                        {
                            "lat": 61.45121943174282,
                            "lon": 55.057377354120476
                        },
                        {
                            "lat": 61.45110383238416,
                            "lon": 55.05917208269163
                        },
                        {
                            "lat": 61.454230563273015,
                            "lon": 55.05923839987173
                        },
                        {
                            "lat": 61.45735730701938,
                            "lon": 55.059304636817544
                        }
                    ],
                    [
                        {
                            "lat": 61.48561264062364,
                            "lon": 55.05810238201087
                        },
                        {
                            "lat": 61.48248592145788,
                            "lon": 55.05803687155087
                        },
                        {
                            "lat": 61.48237171800059,
                            "lon": 55.059831643863546
                        },
                        {
                            "lat": 61.48549857676713,
                            "lon": 55.05989715866853
                        },
                        {
                            "lat": 61.48561264062364,
                            "lon": 55.05810238201087
                        }
                    ],
                    [
                        {
                            "lat": 61.47623252129021,
                            "lon": 55.057905609925314
                        },
                        {
                            "lat": 61.476118038637146,
                            "lon": 55.05970037353214
                        },
                        {
                            "lat": 61.4792448719521,
                            "lon": 55.059766048817856
                        },
                        {
                            "lat": 61.479359215008245,
                            "lon": 55.05797128085544
                        },
                        {
                            "lat": 61.47623252129021,
                            "lon": 55.057905609925314
                        }
                    ],
                    [
                        {
                            "lat": 61.462920317794904,
                            "lon": 55.0702053322057
                        },
                        {
                            "lat": 61.46604793788128,
                            "lon": 55.07027135471338
                        },
                        {
                            "lat": 61.46616291611572,
                            "lon": 55.06847660837039
                        },
                        {
                            "lat": 61.46627788154495,
                            "lon": 55.06668186133318
                        },
                        {
                            "lat": 61.463150540807426,
                            "lon": 55.066615847583414
                        },
                        {
                            "lat": 61.463035435711525,
                            "lon": 55.06841059024188
                        },
                        {
                            "lat": 61.462920317794904,
                            "lon": 55.0702053322057
                        }
                    ],
                    [
                        {
                            "lat": 61.465817942988934,
                            "lon": 55.07386084531657
                        },
                        {
                            "lat": 61.46570292632716,
                            "lon": 55.07565558957675
                        },
                        {
                            "lat": 61.468830978366114,
                            "lon": 55.07572154493925
                        },
                        {
                            "lat": 61.46894585529724,
                            "lon": 55.07392679630406
                        },
                        {
                            "lat": 61.465817942988934,
                            "lon": 55.07386084531657
                        }
                    ],
                    [
                        {
                            "lat": 61.474972510590504,
                            "lon": 55.07764797148271
                        },
                        {
                            "lat": 61.47485788759167,
                            "lon": 55.079442727465704
                        },
                        {
                            "lat": 61.47172953045765,
                            "lon": 55.07937692394529
                        },
                        {
                            "lat": 61.46860118610948,
                            "lon": 55.079311040127884
                        },
                        {
                            "lat": 61.46848627078007,
                            "lon": 55.08110578668132
                        },
                        {
                            "lat": 61.4716147549074,
                            "lon": 55.081171674869594
                        },
                        {
                            "lat": 61.47149996656939,
                            "lon": 55.08296642510034
                        },
                        {
                            "lat": 61.471385165441625,
                            "lon": 55.08476117463751
                        },
                        {
                            "lat": 61.47451394196549,
                            "lon": 55.08482699125577
                        },
                        {
                            "lat": 61.47439926787374,
                            "lon": 55.086621744466086
                        },
                        {
                            "lat": 61.47127035152221,
                            "lon": 55.08655592348111
                        },
                        {
                            "lat": 61.471155524809184,
                            "lon": 55.08835067163112
                        },
                        {
                            "lat": 61.471040685300615,
                            "lon": 55.09014541908753
                        },
                        {
                            "lat": 61.47092583299456,
                            "lon": 55.0919401658503
                        },
                        {
                            "lat": 61.4740551689223,
                            "lon": 55.092005999937804
                        },
                        {
                            "lat": 61.47416988135409,
                            "lon": 55.09021124880711
                        },
                        {
                            "lat": 61.47428458100392,
                            "lon": 55.08841649698321
                        },
                        {
                            "lat": 61.477413649978665,
                            "lon": 55.088482242011004
                        },
                        {
                            "lat": 61.477528197003345,
                            "lon": 55.08668748513209
                        },
                        {
                            "lat": 61.480657138895495,
                            "lon": 55.08675314547842
                        },
                        {
                            "lat": 61.4807715333261,
                            "lon": 55.0849583835506
                        },
                        {
                            "lat": 61.48390034813177,
                            "lon": 55.08502395922574
                        },
                        {
                            "lat": 61.48378609353465,
                            "lon": 55.08681872550435
                        },
                        {
                            "lat": 61.48367182620606,
                            "lon": 55.08861349109091
                        },
                        {
                            "lat": 61.48680093342763,
                            "lon": 55.0886789951416
                        },
                        {
                            "lat": 61.48668679323211,
                            "lon": 55.09047376438235
                        },
                        {
                            "lat": 61.48981605303996,
                            "lon": 55.09053919244683
                        },
                        {
                            "lat": 61.489702040008574,
                            "lon": 55.092333965337254
                        },
                        {
                            "lat": 61.48657264031677,
                            "lon": 55.092268532931456
                        },
                        {
                            "lat": 61.48344325334671,
                            "lon": 55.092203020187895
                        },
                        {
                            "lat": 61.48031387911391,
                            "lon": 55.0921374271073
                        },
                        {
                            "lat": 61.47718451763393,
                            "lon": 55.09207175369037
                        },
                        {
                            "lat": 61.477069932309995,
                            "lon": 55.09386650849079
                        },
                        {
                            "lat": 61.47695533421575,
                            "lon": 55.09566126259834
                        },
                        {
                            "lat": 61.480084975498656,
                            "lon": 55.09572694473096
                        },
                        {
                            "lat": 61.48019943368366,
                            "lon": 55.09393218626537
                        },
                        {
                            "lat": 61.483328947812076,
                            "lon": 55.093997783698306
                        },
                        {
                            "lat": 61.48321462953823,
                            "lon": 55.09579254651663
                        },
                        {
                            "lat": 61.48634429631896,
                            "lon": 55.09585806795458
                        },
                        {
                            "lat": 61.486230105232615,
                            "lon": 55.09765283442861
                        },
                        {
                            "lat": 61.48935992466951,
                            "lon": 55.09771827986065
                        },
                        {
                            "lat": 61.48924586080174,
                            "lon": 55.09951304998579
                        },
                        {
                            "lat": 61.49237583289867,
                            "lon": 55.09957841940096
                        },
                        {
                            "lat": 61.49550581769396,
                            "lon": 55.099643708455666
                        },
                        {
                            "lat": 61.49863581517206,
                            "lon": 55.09970891714921
                        },
                        {
                            "lat": 61.49852215848698,
                            "lon": 55.10150369958084
                        },
                        {
                            "lat": 61.50165230860171,
                            "lon": 55.1015688322345
                        },
                        {
                            "lat": 61.50176582531741,
                            "lon": 55.09977404548086
                        },
                        {
                            "lat": 61.50187932938142,
                            "lon": 55.09797925803746
                        },
                        {
                            "lat": 61.50500921222273,
                            "lon": 55.09804430169026
                        },
                        {
                            "lat": 61.50489584811448,
                            "lon": 55.099839093449944
                        },
                        {
                            "lat": 61.50478247137007,
                            "lon": 55.101633884520226
                        },
                        {
                            "lat": 61.50791264677651,
                            "lon": 55.101698856437345
                        },
                        {
                            "lat": 61.51104283480548,
                            "lon": 55.10176374798516
                        },
                        {
                            "lat": 61.51092972540244,
                            "lon": 55.103558546984154
                        },
                        {
                            "lat": 61.51406006603098,
                            "lon": 55.10362336246303
                        },
                        {
                            "lat": 61.513947084027215,
                            "lon": 55.10541816507482
                        },
                        {
                            "lat": 61.517077577259144,
                            "lon": 55.10548290447379
                        },
                        {
                            "lat": 61.516964722685756,
                            "lon": 55.10727771069382
                        },
                        {
                            "lat": 61.52009536852493,
                            "lon": 55.10734237400193
                        },
                        {
                            "lat": 61.523226026930125,
                            "lon": 55.10740695692195
                        },
                        {
                            "lat": 61.5233386014465,
                            "lon": 55.105612142124826
                        },
                        {
                            "lat": 61.52345116341433,
                            "lon": 55.10381732664057
                        },
                        {
                            "lat": 61.526581554322824,
                            "lon": 55.10388182061104
                        },
                        {
                            "lat": 61.52646913237079,
                            "lon": 55.10567664037549
                        },
                        {
                            "lat": 61.52635669788575,
                            "lon": 55.10747145945319
                        },
                        {
                            "lat": 61.52948738137631,
                            "lon": 55.10753588159492
                        },
                        {
                            "lat": 61.53261807738619,
                            "lon": 55.10760022334644
                        },
                        {
                            "lat": 61.535748785899855,
                            "lon": 55.107664484707065
                        },
                        {
                            "lat": 61.53563675903207,
                            "lon": 55.10945931590892
                        },
                        {
                            "lat": 61.535524719674186,
                            "lon": 55.11125414642512
                        },
                        {
                            "lat": 61.5354126678243,
                            "lon": 55.113048976255676
                        },
                        {
                            "lat": 61.53530060348051,
                            "lon": 55.114843805400554
                        },
                        {
                            "lat": 61.538431884793866,
                            "lon": 55.114908003410534
                        },
                        {
                            "lat": 61.54156317858743,
                            "lon": 55.11497212100692
                        },
                        {
                            "lat": 61.544694484845664,
                            "lon": 55.11503615818903
                        },
                        {
                            "lat": 61.54458282836216,
                            "lon": 55.116830999415825
                        },
                        {
                            "lat": 61.54771428719193,
                            "lon": 55.116894960428155
                        },
                        {
                            "lat": 61.54760275839553,
                            "lon": 55.11868980521601
                        },
                        {
                            "lat": 61.55073436980064,
                            "lon": 55.11875369004761
                        },
                        {
                            "lat": 61.550622968722486,
                            "lon": 55.120548538391915
                        },
                        {
                            "lat": 61.55375473270672,
                            "lon": 55.12061234703183
                        },
                        {
                            "lat": 61.55364345937797,
                            "lon": 55.122407198928045
                        },
                        {
                            "lat": 61.55677537594509,
                            "lon": 55.122470931365285
                        },
                        {
                            "lat": 61.55990730490648,
                            "lon": 55.122534583363475
                        },
                        {
                            "lat": 61.559796299550705,
                            "lon": 55.124329443032416
                        },
                        {
                            "lat": 61.55666423039694,
                            "lon": 55.12426578680881
                        },
                        {
                            "lat": 61.556553072452616,
                            "lon": 55.126060641569254
                        },
                        {
                            "lat": 61.55644190211023,
                            "lon": 55.1278554956466
                        },
                        {
                            "lat": 61.556330719367885,
                            "lon": 55.129650349040865
                        },
                        {
                            "lat": 61.556219524223714,
                            "lon": 55.13144520175203
                        },
                        {
                            "lat": 61.55308690654834,
                            "lon": 55.131381448157214
                        },
                        {
                            "lat": 61.552975558731994,
                            "lon": 55.133176295952616
                        },
                        {
                            "lat": 61.552864198494454,
                            "lon": 55.134971143064504
                        },
                        {
                            "lat": 61.55275282583383,
                            "lon": 55.13676598949285
                        },
                        {
                            "lat": 61.552641440748225,
                            "lon": 55.13856083523769
                        },
                        {
                            "lat": 61.55577461959098,
                            "lon": 55.13862460576535
                        },
                        {
                            "lat": 61.55566336240935,
                            "lon": 55.14041945506079
                        },
                        {
                            "lat": 61.55879669399607,
                            "lon": 55.14048314933025
                        },
                        {
                            "lat": 61.55868556474966,
                            "lon": 55.14227800217174
                        },
                        {
                            "lat": 61.56181904908417,
                            "lon": 55.142341620172026
                        },
                        {
                            "lat": 61.56193003798036,
                            "lon": 55.14054676310667
                        },
                        {
                            "lat": 61.56506339434657,
                            "lon": 55.14061029638937
                        },
                        {
                            "lat": 61.56517423052433,
                            "lon": 55.138815434423414
                        },
                        {
                            "lat": 61.568307458918454,
                            "lon": 55.13887888299886
                        },
                        {
                            "lat": 61.571440699661416,
                            "lon": 55.13894225108451
                        },
                        {
                            "lat": 61.57155124282728,
                            "lon": 55.13714738001694
                        },
                        {
                            "lat": 61.57468435557693,
                            "lon": 55.137210663410315
                        },
                        {
                            "lat": 61.57479474610056,
                            "lon": 55.13541578746008
                        },
                        {
                            "lat": 61.57166177366182,
                            "lon": 55.13535250826812
                        },
                        {
                            "lat": 61.568528813552575,
                            "lon": 55.13528914859634
                        },
                        {
                            "lat": 61.56841814240898,
                            "lon": 55.137084016138424
                        },
                        {
                            "lat": 61.565285054337636,
                            "lon": 55.137020571775444
                        },
                        {
                            "lat": 61.56539586578839,
                            "lon": 55.13522570844546
                        },
                        {
                            "lat": 61.56550666487847,
                            "lon": 55.13343084443345
                        },
                        {
                            "lat": 61.56561745160974,
                            "lon": 55.13163597973944
                        },
                        {
                            "lat": 61.56248479677,
                            "lon": 55.13157246754382
                        },
                        {
                            "lat": 61.56259571140285,
                            "lon": 55.12977760638413
                        },
                        {
                            "lat": 61.562706613665064,
                            "lon": 55.127982744542074
                        },
                        {
                            "lat": 61.56281750355849,
                            "lon": 55.12618788201768
                        },
                        {
                            "lat": 61.562928381085044,
                            "lon": 55.124393018810956
                        },
                        {
                            "lat": 61.5630392462466,
                            "lon": 55.12259815492191
                        },
                        {
                            "lat": 61.56315009904502,
                            "lon": 55.12080329035056
                        },
                        {
                            "lat": 61.563260939482205,
                            "lon": 55.11900842509692
                        },
                        {
                            "lat": 61.56639261283997,
                            "lon": 55.119071907786505
                        },
                        {
                            "lat": 61.566281912567725,
                            "lon": 55.120866777254165
                        },
                        {
                            "lat": 61.56941373843609,
                            "lon": 55.12093018372196
                        },
                        {
                            "lat": 61.56952429854151,
                            "lon": 55.11913531004561
                        },
                        {
                            "lat": 61.56963484631872,
                            "lon": 55.117340435687716
                        },
                        {
                            "lat": 61.572766404195356,
                            "lon": 55.117403753312644
                        },
                        {
                            "lat": 61.57287679950873,
                            "lon": 55.1156088740706
                        },
                        {
                            "lat": 61.56974538176961,
                            "lon": 55.115545560648286
                        },
                        {
                            "lat": 61.56661397635495,
                            "lon": 55.11548216680544
                        },
                        {
                            "lat": 61.56672463960145,
                            "lon": 55.11368729529209
                        },
                        {
                            "lat": 61.56359338664503,
                            "lon": 55.1136238252423
                        },
                        {
                            "lat": 61.56370417765601,
                            "lon": 55.11182895725956
                        },
                        {
                            "lat": 61.56683529050978,
                            "lon": 55.11189242309685
                        },
                        {
                            "lat": 61.569966415699874,
                            "lon": 55.11195580852491
                        },
                        {
                            "lat": 61.570076914183,
                            "lon": 55.110160931441015
                        },
                        {
                            "lat": 61.56694592908183,
                            "lon": 55.11009755021974
                        },
                        {
                            "lat": 61.56381495631509,
                            "lon": 55.1100340885946
                        },
                        {
                            "lat": 61.56392572262421,
                            "lon": 55.108239219247395
                        },
                        {
                            "lat": 61.56705655531947,
                            "lon": 55.108302676660756
                        },
                        {
                            "lat": 61.56716716922456,
                            "lon": 55.10650780241994
                        },
                        {
                            "lat": 61.56403647658522,
                            "lon": 55.10644434921796
                        },
                        {
                            "lat": 61.56414721819998,
                            "lon": 55.10464947850632
                        },
                        {
                            "lat": 61.56425794747038,
                            "lon": 55.10285460711247
                        },
                        {
                            "lat": 61.564368664398316,
                            "lon": 55.10105973503646
                        },
                        {
                            "lat": 61.56749893696331,
                            "lon": 55.101123175606546
                        },
                        {
                            "lat": 61.567609501556944,
                            "lon": 55.09932829863847
                        },
                        {
                            "lat": 61.570739646451464,
                            "lon": 55.099391654626736
                        },
                        {
                            "lat": 61.57386980365358,
                            "lon": 55.099454930242395
                        },
                        {
                            "lat": 61.57699997314779,
                            "lon": 55.099518125484764
                        },
                        {
                            "lat": 61.580130154918486,
                            "lon": 55.09958124035313
                        },
                        {
                            "lat": 61.58024014725785,
                            "lon": 55.09778634591843
                        },
                        {
                            "lat": 61.57711010547273,
                            "lon": 55.097723235238284
                        },
                        {
                            "lat": 61.57722022552299,
                            "lon": 55.095928344311154
                        },
                        {
                            "lat": 61.5773303333005,
                            "lon": 55.09413345270339
                        },
                        {
                            "lat": 61.577440428807066,
                            "lon": 55.092338560414966
                        },
                        {
                            "lat": 61.57755051204459,
                            "lon": 55.09054366744594
                        },
                        {
                            "lat": 61.57442104231341,
                            "lon": 55.09048049316757
                        },
                        {
                            "lat": 61.5745312531897,
                            "lon": 55.088685603709585
                        },
                        {
                            "lat": 61.57140193564536,
                            "lon": 55.08862235328149
                        },
                        {
                            "lat": 61.571512274129326,
                            "lon": 55.086827467339134
                        },
                        {
                            "lat": 61.56838310876808,
                            "lon": 55.08676414077228
                        },
                        {
                            "lat": 61.56827263039744,
                            "lon": 55.08855902251268
                        },
                        {
                            "lat": 61.56514333746148,
                            "lon": 55.08849561140384
                        },
                        {
                            "lat": 61.56503270687852,
                            "lon": 55.09029048825491
                        },
                        {
                            "lat": 61.56190328637157,
                            "lon": 55.09022699259383
                        },
                        {
                            "lat": 61.56179250354521,
                            "lon": 55.09202186454944
                        },
                        {
                            "lat": 61.56492206396619,
                            "lon": 55.092085364423845
                        },
                        {
                            "lat": 61.5680516367184,
                            "lon": 55.09214878394829
                        },
                        {
                            "lat": 61.56794112140628,
                            "lon": 55.09394366364347
                        },
                        {
                            "lat": 61.564811408722605,
                            "lon": 55.09388023991066
                        },
                        {
                            "lat": 61.561681708372035,
                            "lon": 55.09381673582257
                        },
                        {
                            "lat": 61.55855202037016,
                            "lon": 55.09375315137986
                        },
                        {
                            "lat": 61.5554223447325,
                            "lon": 55.09368948658327
                        },
                        {
                            "lat": 61.55229268147458,
                            "lon": 55.09362574143348
                        },
                        {
                            "lat": 61.549163030612,
                            "lon": 55.09356191593117
                        },
                        {
                            "lat": 61.54927438548472,
                            "lon": 55.091767061565996
                        },
                        {
                            "lat": 61.546144886953165,
                            "lon": 55.09170315995217
                        },
                        {
                            "lat": 61.546256369321526,
                            "lon": 55.08990830914291
                        },
                        {
                            "lat": 61.54636783926726,
                            "lon": 55.088113457649285
                        },
                        {
                            "lat": 61.5432386329502,
                            "lon": 55.088049484179706
                        },
                        {
                            "lat": 61.540109439069425,
                            "lon": 55.08798543037564
                        },
                        {
                            "lat": 61.53999768935116,
                            "lon": 55.08978027337427
                        },
                        {
                            "lat": 61.536868368038796,
                            "lon": 55.08971613498094
                        },
                        {
                            "lat": 61.536756465967834,
                            "lon": 55.09151097303855
                        },
                        {
                            "lat": 61.533627017227644,
                            "lon": 55.09144675004566
                        },
                        {
                            "lat": 61.53373905919568,
                            "lon": 55.089651916249224
                        },
                        {
                            "lat": 61.53060976283738,
                            "lon": 55.08958761717978
                        },
                        {
                            "lat": 61.53072193220025,
                            "lon": 55.08779278696356
                        },
                        {
                            "lat": 61.533851088678915,
                            "lon": 55.08785708176687
                        },
                        {
                            "lat": 61.53396310567925,
                            "lon": 55.08606224659865
                        },
                        {
                            "lat": 61.53407511019853,
                            "lon": 55.08426741074456
                        },
                        {
                            "lat": 61.530946233432566,
                            "lon": 55.08420312447232
                        },
                        {
                            "lat": 61.53083408906468,
                            "lon": 55.08599795606106
                        },
                        {
                            "lat": 61.527705084946675,
                            "lon": 55.08593358519711
                        },
                        {
                            "lat": 61.52781736916121,
                            "lon": 55.08413875787904
                        },
                        {
                            "lat": 61.52468851740008,
                            "lon": 55.084074310965384
                        },
                        {
                            "lat": 61.52457609334078,
                            "lon": 55.08586913400748
                        },
                        {
                            "lat": 61.524463656753824,
                            "lon": 55.087663956362555
                        },
                        {
                            "lat": 61.52133453781711,
                            "lon": 55.08759942056627
                        },
                        {
                            "lat": 61.51820543142548,
                            "lon": 55.08753480444039
                        },
                        {
                            "lat": 61.51507633759443,
                            "lon": 55.08747010798564
                        },
                        {
                            "lat": 61.51194725633952,
                            "lon": 55.0874053312027
                        },
                        {
                            "lat": 61.508818187676276,
                            "lon": 55.087340474092265
                        },
                        {
                            "lat": 61.50893132353621,
                            "lon": 55.08554567319876
                        },
                        {
                            "lat": 61.50580240732904,
                            "lon": 55.08548074006983
                        },
                        {
                            "lat": 61.50568913162024,
                            "lon": 55.087275536655085
                        },
                        {
                            "lat": 61.50256008818695,
                            "lon": 55.087210518891816
                        },
                        {
                            "lat": 61.5026735037427,
                            "lon": 55.08541572662013
                        },
                        {
                            "lat": 61.499544612792704,
                            "lon": 55.08535063285039
                        },
                        {
                            "lat": 61.49965815554162,
                            "lon": 55.083555844207524
                        },
                        {
                            "lat": 61.5027869066621,
                            "lon": 55.08362093365875
                        },
                        {
                            "lat": 61.50591567041702,
                            "lon": 55.08368594279525
                        },
                        {
                            "lat": 61.50904444679084,
                            "lon": 55.08375087161633
                        },
                        {
                            "lat": 61.50915755744211,
                            "lon": 55.08195606934496
                        },
                        {
                            "lat": 61.512286206599605,
                            "lon": 55.08202091354771
                        },
                        {
                            "lat": 61.5123991648452,
                            "lon": 55.08022610628563
                        },
                        {
                            "lat": 61.5092706554919,
                            "lon": 55.080161266384685
                        },
                        {
                            "lat": 61.50614215873814,
                            "lon": 55.080096346178216
                        },
                        {
                            "lat": 61.50602892088607,
                            "lon": 55.08189114483138
                        },
                        {
                            "lat": 61.50290029694704,
                            "lon": 55.08182614000769
                        },
                        {
                            "lat": 61.50301367459947,
                            "lon": 55.08003134566693
                        },
                        {
                            "lat": 61.4998852030914,
                            "lon": 55.079966264851556
                        },
                        {
                            "lat": 61.49999870789614,
                            "lon": 55.07817147413849
                        },
                        {
                            "lat": 61.49687038881521,
                            "lon": 55.07810631734239
                        },
                        {
                            "lat": 61.49675674422948,
                            "lon": 55.07990110373277
                        },
                        {
                            "lat": 61.49362829802919,
                            "lon": 55.07983586231131
                        },
                        {
                            "lat": 61.49374208239401,
                            "lon": 55.07804108024889
                        },
                        {
                            "lat": 61.49061378864808,
                            "lon": 55.07797576285871
                        },
                        {
                            "lat": 61.48748550759291,
                            "lon": 55.0779103651726
                        },
                        {
                            "lat": 61.48737144367571,
                            "lon": 55.07970513856311
                        },
                        {
                            "lat": 61.48725736705028,
                            "lon": 55.081499911262064
                        },
                        {
                            "lat": 61.48412881913928,
                            "lon": 55.08143442459249
                        },
                        {
                            "lat": 61.48100028395397,
                            "lon": 55.08136885761772
                        },
                        {
                            "lat": 61.48111464015515,
                            "lon": 55.07957409361269
                        },
                        {
                            "lat": 61.48122898361703,
                            "lon": 55.07777932891532
                        },
                        {
                            "lat": 61.48435723924406,
                            "lon": 55.07784488719122
                        },
                        {
                            "lat": 61.48447143021276,
                            "lon": 55.076050117452624
                        },
                        {
                            "lat": 61.48458560846154,
                            "lon": 55.07425534702206
                        },
                        {
                            "lat": 61.481457632330695,
                            "lon": 55.07418979744344
                        },
                        {
                            "lat": 61.48134331434157,
                            "lon": 55.07598456352556
                        },
                        {
                            "lat": 61.4782152112058,
                            "lon": 55.075918929309964
                        },
                        {
                            "lat": 61.47810074072734,
                            "lon": 55.07771369034556
                        },
                        {
                            "lat": 61.474972510590504,
                            "lon": 55.07764797148271
                        }
                    ],
                    [
                        {
                            "lat": 61.477986257496006,
                            "lon": 55.07950845068842
                        },
                        {
                            "lat": 61.47787176150989,
                            "lon": 55.08130321033852
                        },
                        {
                            "lat": 61.474743251822524,
                            "lon": 55.08123748275556
                        },
                        {
                            "lat": 61.47485788759167,
                            "lon": 55.079442727465704
                        },
                        {
                            "lat": 61.477986257496006,
                            "lon": 55.07950845068842
                        }
                    ],
                    [
                        {
                            "lat": 61.52669396374378,
                            "lon": 55.102087000159834
                        },
                        {
                            "lat": 61.52680636063553,
                            "lon": 55.10029217902189
                        },
                        {
                            "lat": 61.529936484086406,
                            "lon": 55.10035658406492
                        },
                        {
                            "lat": 61.53306662004902,
                            "lon": 55.10042090873904
                        },
                        {
                            "lat": 61.533178724469515,
                            "lon": 55.098626078372256
                        },
                        {
                            "lat": 61.53630873295349,
                            "lon": 55.09869031841366
                        },
                        {
                            "lat": 61.539438753916194,
                            "lon": 55.09875447809012
                        },
                        {
                            "lat": 61.53932692944724,
                            "lon": 55.100549316977734
                        },
                        {
                            "lat": 61.54245710285172,
                            "lon": 55.10061340054091
                        },
                        {
                            "lat": 61.54234540591185,
                            "lon": 55.102408242996155
                        },
                        {
                            "lat": 61.53921509251319,
                            "lon": 55.102344155180155
                        },
                        {
                            "lat": 61.53910324311219,
                            "lon": 55.10413899269738
                        },
                        {
                            "lat": 61.53597280217267,
                            "lon": 55.10407482024652
                        },
                        {
                            "lat": 61.53284237371756,
                            "lon": 55.10401056741472
                        },
                        {
                            "lat": 61.52971195776242,
                            "lon": 55.10394623420266
                        },
                        {
                            "lat": 61.526581554322824,
                            "lon": 55.10388182061104
                        },
                        {
                            "lat": 61.52669396374378,
                            "lon": 55.102087000159834
                        }
                    ],
                    [
                        {
                            "lat": 61.52367624971193,
                            "lon": 55.10022769361064
                        },
                        {
                            "lat": 61.52356371283552,
                            "lon": 55.10202251046917
                        },
                        {
                            "lat": 61.52043347447199,
                            "lon": 55.101957940405654
                        },
                        {
                            "lat": 61.52054615133118,
                            "lon": 55.10016312783188
                        },
                        {
                            "lat": 61.52065881563198,
                            "lon": 55.0983683145706
                        },
                        {
                            "lat": 61.52378877404552,
                            "lon": 55.098432876065026
                        },
                        {
                            "lat": 61.526918744999975,
                            "lon": 55.09849735719724
                        },
                        {
                            "lat": 61.52680636063553,
                            "lon": 55.10029217902189
                        },
                        {
                            "lat": 61.52367624971193,
                            "lon": 55.10022769361064
                        }
                    ],
                    [
                        {
                            "lat": 61.57431081915441,
                            "lon": 55.09227538194453
                        },
                        {
                            "lat": 61.571181221786325,
                            "lon": 55.09221212312209
                        },
                        {
                            "lat": 61.57129158486499,
                            "lon": 55.09041723854246
                        },
                        {
                            "lat": 61.57442104231341,
                            "lon": 55.09048049316757
                        },
                        {
                            "lat": 61.57431081915441,
                            "lon": 55.09227538194453
                        }
                    ],
                    [
                        {
                            "lat": 61.539885927179185,
                            "lon": 55.09157511568775
                        },
                        {
                            "lat": 61.54301540084614,
                            "lon": 55.09163917799252
                        },
                        {
                            "lat": 61.54290376613494,
                            "lon": 55.09343402387177
                        },
                        {
                            "lat": 61.53977415255159,
                            "lon": 55.09336995731608
                        },
                        {
                            "lat": 61.53664455142572,
                            "lon": 55.093305810410655
                        },
                        {
                            "lat": 61.536756465967834,
                            "lon": 55.09151097303855
                        },
                        {
                            "lat": 61.539885927179185,
                            "lon": 55.09157511568775
                        }
                    ],
                    [
                        {
                            "lat": 61.530497580974156,
                            "lon": 55.09138244670974
                        },
                        {
                            "lat": 61.527368157222924,
                            "lon": 55.09131806303147
                        },
                        {
                            "lat": 61.52748047897941,
                            "lon": 55.089523237773356
                        },
                        {
                            "lat": 61.53060976283738,
                            "lon": 55.08958761717978
                        },
                        {
                            "lat": 61.530497580974156,
                            "lon": 55.09138244670974
                        }
                    ],
                    [
                        {
                            "lat": 61.4965294170711,
                            "lon": 55.08349067444226
                        },
                        {
                            "lat": 61.496643086982075,
                            "lon": 55.08169588943274
                        },
                        {
                            "lat": 61.49977168564053,
                            "lon": 55.081761054874576
                        },
                        {
                            "lat": 61.49965815554162,
                            "lon": 55.083555844207524
                        },
                        {
                            "lat": 61.4965294170711,
                            "lon": 55.08349067444226
                        }
                    ],
                    [
                        {
                            "lat": 61.502219765682334,
                            "lon": 55.09259489156849
                        },
                        {
                            "lat": 61.5053492287497,
                            "lon": 55.092659922274805
                        },
                        {
                            "lat": 61.50523590253842,
                            "lon": 55.09445471610267
                        },
                        {
                            "lat": 61.50210629956195,
                            "lon": 55.09438968108125
                        },
                        {
                            "lat": 61.502219765682334,
                            "lon": 55.09259489156849
                        }
                    ],
                    [
                        {
                            "lat": 61.508365518145325,
                            "lon": 55.09451967077675
                        },
                        {
                            "lat": 61.5082523192302,
                            "lon": 55.09631446822543
                        },
                        {
                            "lat": 61.505122563696744,
                            "lon": 55.096249509241154
                        },
                        {
                            "lat": 61.50523590253842,
                            "lon": 55.09445471610267
                        },
                        {
                            "lat": 61.508365518145325,
                            "lon": 55.09451967077675
                        }
                    ],
                    [
                        {
                            "lat": 61.496302039250715,
                            "lon": 55.08708024238989
                        },
                        {
                            "lat": 61.49943105739192,
                            "lon": 55.087145420803175
                        },
                        {
                            "lat": 61.49931748933733,
                            "lon": 55.08894020806585
                        },
                        {
                            "lat": 61.49618833133747,
                            "lon": 55.08887502532799
                        },
                        {
                            "lat": 61.496302039250715,
                            "lon": 55.08708024238989
                        }
                    ],
                    [
                        {
                            "lat": 61.548606070047796,
                            "lon": 55.102536177496376
                        },
                        {
                            "lat": 61.548717486993674,
                            "lon": 55.10074132655145
                        },
                        {
                            "lat": 61.551847697700026,
                            "lon": 55.100805168997425
                        },
                        {
                            "lat": 61.55195896224324,
                            "lon": 55.099010313131956
                        },
                        {
                            "lat": 61.55508904536627,
                            "lon": 55.09907407097297
                        },
                        {
                            "lat": 61.55497792080923,
                            "lon": 55.100868931069606
                        },
                        {
                            "lat": 61.55486678386491,
                            "lon": 55.102663790482936
                        },
                        {
                            "lat": 61.55475563453141,
                            "lon": 55.10445864921294
                        },
                        {
                            "lat": 61.55464447280685,
                            "lon": 55.10625350725963
                        },
                        {
                            "lat": 61.55151382964582,
                            "lon": 55.10618973249171
                        },
                        {
                            "lat": 61.551625131403206,
                            "lon": 55.10439487867732
                        },
                        {
                            "lat": 61.54849464068163,
                            "lon": 55.10433102775724
                        },
                        {
                            "lat": 61.54536416238223,
                            "lon": 55.104267096453434
                        },
                        {
                            "lat": 61.545475731761904,
                            "lon": 55.102472250435135
                        },
                        {
                            "lat": 61.548606070047796,
                            "lon": 55.102536177496376
                        }
                    ],
                    [
                        {
                            "lat": 61.55520015753791,
                            "lon": 55.097279210193015
                        },
                        {
                            "lat": 61.558330113073886,
                            "lon": 55.097342883439396
                        },
                        {
                            "lat": 61.558219140874726,
                            "lon": 55.09913774844481
                        },
                        {
                            "lat": 61.55508904536627,
                            "lon": 55.09907407097297
                        },
                        {
                            "lat": 61.55520015753791,
                            "lon": 55.097279210193015
                        }
                    ],
                    [
                        {
                            "lat": 61.54905166332842,
                            "lon": 55.09535676961231
                        },
                        {
                            "lat": 61.55218145412863,
                            "lon": 55.09542059934996
                        },
                        {
                            "lat": 61.55207021438547,
                            "lon": 55.09721545658279
                        },
                        {
                            "lat": 61.548940283632106,
                            "lon": 55.0971516226094
                        },
                        {
                            "lat": 61.54905166332842,
                            "lon": 55.09535676961231
                        }
                    ],
                    [
                        {
                            "lat": 61.51462478718812,
                            "lon": 55.094649339080085
                        },
                        {
                            "lat": 61.51149514636706,
                            "lon": 55.0945845451028
                        },
                        {
                            "lat": 61.51160819275436,
                            "lon": 55.09278974266065
                        },
                        {
                            "lat": 61.51172122654427,
                            "lon": 55.09099493952991
                        },
                        {
                            "lat": 61.51485058755119,
                            "lon": 55.091059724909265
                        },
                        {
                            "lat": 61.514737693660585,
                            "lon": 55.09285453233878
                        },
                        {
                            "lat": 61.517867207148655,
                            "lon": 55.09291924167279
                        },
                        {
                            "lat": 61.51797996113804,
                            "lon": 55.091124429949794
                        },
                        {
                            "lat": 61.52110934728932,
                            "lon": 55.09118905465082
                        },
                        {
                            "lat": 61.52099673320305,
                            "lon": 55.092983870661946
                        },
                        {
                            "lat": 61.52088410656602,
                            "lon": 55.09477868598564
                        },
                        {
                            "lat": 61.520771467376285,
                            "lon": 55.09657350062185
                        },
                        {
                            "lat": 61.51764166146903,
                            "lon": 55.09650886305527
                        },
                        {
                            "lat": 61.51775444059295,
                            "lon": 55.094714052707936
                        },
                        {
                            "lat": 61.51462478718812,
                            "lon": 55.094649339080085
                        }
                    ],
                    [
                        {
                            "lat": 61.54189849361938,
                            "lon": 55.109587605968706
                        },
                        {
                            "lat": 61.541786734403566,
                            "lon": 55.11138244499968
                        },
                        {
                            "lat": 61.54167496272687,
                            "lon": 55.113177283345735
                        },
                        {
                            "lat": 61.53854380903644,
                            "lon": 55.113113170004844
                        },
                        {
                            "lat": 61.53865572080065,
                            "lon": 55.111318335913865
                        },
                        {
                            "lat": 61.53876762008844,
                            "lon": 55.10952350113762
                        },
                        {
                            "lat": 61.54189849361938,
                            "lon": 55.109587605968706
                        }
                    ],
                    [
                        {
                            "lat": 61.561016677945354,
                            "lon": 55.10458594912814
                        },
                        {
                            "lat": 61.56090579629211,
                            "lon": 55.10638081562345
                        },
                        {
                            "lat": 61.557775128360795,
                            "lon": 55.106317201637104
                        },
                        {
                            "lat": 61.55788615005063,
                            "lon": 55.10452233936347
                        },
                        {
                            "lat": 61.561016677945354,
                            "lon": 55.10458594912814
                        }
                    ],
                    [
                        {
                            "lat": 61.54816027804255,
                            "lon": 55.10971557443524
                        },
                        {
                            "lat": 61.545029379609254,
                            "lon": 55.10965163040149
                        },
                        {
                            "lat": 61.545140986307814,
                            "lon": 55.107856786436614
                        },
                        {
                            "lat": 61.54827174468091,
                            "lon": 55.10792072622668
                        },
                        {
                            "lat": 61.551402515479936,
                            "lon": 55.10798458562236
                        },
                        {
                            "lat": 61.554533298689364,
                            "lon": 55.108048364622974
                        },
                        {
                            "lat": 61.557664094293585,
                            "lon": 55.108112063227786
                        },
                        {
                            "lat": 61.55755304784713,
                            "lon": 55.109906924135515
                        },
                        {
                            "lat": 61.55442211217705,
                            "lon": 55.10984322130295
                        },
                        {
                            "lat": 61.55431091326807,
                            "lon": 55.11163807729958
                        },
                        {
                            "lat": 61.55419970196049,
                            "lon": 55.11343293261282
                        },
                        {
                            "lat": 61.55106849851246,
                            "lon": 55.113369140911935
                        },
                        {
                            "lat": 61.551179849915144,
                            "lon": 55.1115742898325
                        },
                        {
                            "lat": 61.55129118890368,
                            "lon": 55.10977943806929
                        },
                        {
                            "lat": 61.54816027804255,
                            "lon": 55.10971557443524
                        }
                    ],
                    [
                        {
                            "lat": 61.46245971788218,
                            "lon": 55.077384293114456
                        },
                        {
                            "lat": 61.46234453583273,
                            "lon": 55.07917903160491
                        },
                        {
                            "lat": 61.465472854562655,
                            "lon": 55.079245076014175
                        },
                        {
                            "lat": 61.46558789685236,
                            "lon": 55.07745033314261
                        },
                        {
                            "lat": 61.46245971788218,
                            "lon": 55.077384293114456
                        }
                    ],
                    [
                        {
                            "lat": 61.46222934095086,
                            "lon": 55.08097376940068
                        },
                        {
                            "lat": 61.462114133234635,
                            "lon": 55.08276850650171
                        },
                        {
                            "lat": 61.465242731530616,
                            "lon": 55.08283455967435
                        },
                        {
                            "lat": 61.46535779945605,
                            "lon": 55.08103981819143
                        },
                        {
                            "lat": 61.46222934095086,
                            "lon": 55.08097376940068
                        }
                    ],
                    [
                        {
                            "lat": 61.45910089528001,
                            "lon": 55.080907640309746
                        },
                        {
                            "lat": 61.45597246245902,
                            "lon": 55.080841430919385
                        },
                        {
                            "lat": 61.45585697516708,
                            "lon": 55.082636159240714
                        },
                        {
                            "lat": 61.45898554777494,
                            "lon": 55.08270237302359
                        },
                        {
                            "lat": 61.45910089528001,
                            "lon": 55.080907640309746
                        }
                    ],
                    [
                        {
                            "lat": 61.45272841542655,
                            "lon": 55.08256986515381
                        },
                        {
                            "lat": 61.452612775466804,
                            "lon": 55.084364588381405
                        },
                        {
                            "lat": 61.45574147500778,
                            "lon": 55.08443088686651
                        },
                        {
                            "lat": 61.45585697516708,
                            "lon": 55.082636159240714
                        },
                        {
                            "lat": 61.45272841542655,
                            "lon": 55.08256986515381
                        }
                    ],
                    [
                        {
                            "lat": 61.465242731530616,
                            "lon": 55.08283455967435
                        },
                        {
                            "lat": 61.465127650784396,
                            "lon": 55.084629300462915
                        },
                        {
                            "lat": 61.46501255721549,
                            "lon": 55.08642404055712
                        },
                        {
                            "lat": 61.46489745082188,
                            "lon": 55.088218779956975
                        },
                        {
                            "lat": 61.46802648141,
                            "lon": 55.088284765955486
                        },
                        {
                            "lat": 61.46814144796428,
                            "lon": 55.08649002217788
                        },
                        {
                            "lat": 61.47127035152221,
                            "lon": 55.08655592348111
                        },
                        {
                            "lat": 61.471385165441625,
                            "lon": 55.08476117463751
                        },
                        {
                            "lat": 61.46825640170943,
                            "lon": 55.08469527770631
                        },
                        {
                            "lat": 61.46837134264738,
                            "lon": 55.08290053254079
                        },
                        {
                            "lat": 61.465242731530616,
                            "lon": 55.08283455967435
                        }
                    ],
                    [
                        {
                            "lat": 61.455625961979194,
                            "lon": 55.086225613796785
                        },
                        {
                            "lat": 61.45551043607933,
                            "lon": 55.088020340031505
                        },
                        {
                            "lat": 61.458639428141026,
                            "lon": 55.08808656699427
                        },
                        {
                            "lat": 61.45875481420741,
                            "lon": 55.08629183636585
                        },
                        {
                            "lat": 61.455625961979194,
                            "lon": 55.086225613796785
                        }
                    ],
                    [
                        {
                            "lat": 61.458639428141026,
                            "lon": 55.08808656699427
                        },
                        {
                            "lat": 61.45852402921697,
                            "lon": 55.08988129692751
                        },
                        {
                            "lat": 61.46165317398727,
                            "lon": 55.08994744795832
                        },
                        {
                            "lat": 61.46176843306037,
                            "lon": 55.08815271363632
                        },
                        {
                            "lat": 61.458639428141026,
                            "lon": 55.08808656699427
                        }
                    ],
                    [
                        {
                            "lat": 61.46791150204464,
                            "lon": 55.09007950903906
                        },
                        {
                            "lat": 61.464782331601675,
                            "lon": 55.0900135186624
                        },
                        {
                            "lat": 61.46165317398727,
                            "lon": 55.08994744795832
                        },
                        {
                            "lat": 61.46153790207006,
                            "lon": 55.091742181585545
                        },
                        {
                            "lat": 61.46466719955291,
                            "lon": 55.09180825667347
                        },
                        {
                            "lat": 61.467796509866254,
                            "lon": 55.09187425142863
                        },
                        {
                            "lat": 61.46791150204464,
                            "lon": 55.09007950903906
                        }
                    ],
                    [
                        {
                            "lat": 61.47026247527854,
                            "lon": 55.15123239826844
                        },
                        {
                            "lat": 61.46712851476906,
                            "lon": 55.15116641982139
                        },
                        {
                            "lat": 61.46399456712334,
                            "lon": 55.15110036086441
                        },
                        {
                            "lat": 61.46086063235699,
                            "lon": 55.15103422139831
                        },
                        {
                            "lat": 61.46074506297605,
                            "lon": 55.15282893579229
                        },
                        {
                            "lat": 61.457611000706684,
                            "lon": 55.15276271142056
                        },
                        {
                            "lat": 61.45749527801516,
                            "lon": 55.15455742072214
                        },
                        {
                            "lat": 61.46062948069816,
                            "lon": 55.15462364949143
                        },
                        {
                            "lat": 61.46376369628006,
                            "lon": 55.15468979774163
                        },
                        {
                            "lat": 61.46689792474525,
                            "lon": 55.15475586547202
                        },
                        {
                            "lat": 61.46701322619002,
                            "lon": 55.152961142993725
                        },
                        {
                            "lat": 61.47014732710337,
                            "lon": 55.15302712582198
                        },
                        {
                            "lat": 61.47328144086681,
                            "lon": 55.153093028134315
                        },
                        {
                            "lat": 61.476415567464734,
                            "lon": 55.15315884992999
                        },
                        {
                            "lat": 61.47630068728432,
                            "lon": 55.154953585536994
                        },
                        {
                            "lat": 61.47943496712646,
                            "lon": 55.155019331180895
                        },
                        {
                            "lat": 61.482569259773776,
                            "lon": 55.15508499630137
                        },
                        {
                            "lat": 61.48570356521062,
                            "lon": 55.15515058089772
                        },
                        {
                            "lat": 61.488837883421425,
                            "lon": 55.15521608496923
                        },
                        {
                            "lat": 61.49197221439056,
                            "lon": 55.15528150851517
                        },
                        {
                            "lat": 61.49208639242476,
                            "lon": 55.15348675113358
                        },
                        {
                            "lat": 61.49522059570152,
                            "lon": 55.15355208981433
                        },
                        {
                            "lat": 61.4983548117034,
                            "lon": 55.15361734797344
                        },
                        {
                            "lat": 61.49846869615602,
                            "lon": 55.1518225812292
                        },
                        {
                            "lat": 61.50160278444411,
                            "lon": 55.15188775453808
                        },
                        {
                            "lat": 61.50148904041479,
                            "lon": 55.1536825256102
                        },
                        {
                            "lat": 61.50462328182007,
                            "lon": 55.15374762272386
                        },
                        {
                            "lat": 61.50775753590362,
                            "lon": 55.15381263931378
                        },
                        {
                            "lat": 61.51089180264982,
                            "lon": 55.153877575379184
                        },
                        {
                            "lat": 61.511005125397624,
                            "lon": 55.152082791355475
                        },
                        {
                            "lat": 61.50787099908054,
                            "lon": 55.15201785960192
                        },
                        {
                            "lat": 61.507984449596734,
                            "lon": 55.150223079201105
                        },
                        {
                            "lat": 61.51111843550035,
                            "lon": 55.15028800664319
                        },
                        {
                            "lat": 61.51123173295988,
                            "lon": 55.14849322124229
                        },
                        {
                            "lat": 61.50809788745411,
                            "lon": 55.148428298111305
                        },
                        {
                            "lat": 61.5049640546052,
                            "lon": 55.148363294471835
                        },
                        {
                            "lat": 61.504850476351876,
                            "lon": 55.15015807124522
                        },
                        {
                            "lat": 61.50171651578147,
                            "lon": 55.1500929827762
                        },
                        {
                            "lat": 61.50183023442879,
                            "lon": 55.14829821032459
                        },
                        {
                            "lat": 61.49869642694048,
                            "lon": 55.14823304567026
                        },
                        {
                            "lat": 61.49556263215587,
                            "lon": 55.14816780050958
                        },
                        {
                            "lat": 61.49544863272632,
                            "lon": 55.14996256430168
                        },
                        {
                            "lat": 61.49231471027284,
                            "lon": 55.1498972342976
                        },
                        {
                            "lat": 61.48918080055624,
                            "lon": 55.149831823783224
                        },
                        {
                            "lat": 61.48604690359213,
                            "lon": 55.14976633275929
                        },
                        {
                            "lat": 61.482913019396136,
                            "lon": 55.14970076122652
                        },
                        {
                            "lat": 61.479779147983876,
                            "lon": 55.14963510918563
                        },
                        {
                            "lat": 61.476645289370936,
                            "lon": 55.14956937663731
                        },
                        {
                            "lat": 61.47653043482629,
                            "lon": 55.15136411363007
                        },
                        {
                            "lat": 61.47339644863615,
                            "lon": 55.15129829620493
                        },
                        {
                            "lat": 61.47026247527854,
                            "lon": 55.15123239826844
                        }
                    ],
                    [
                        {
                            "lat": 61.48279844564166,
                            "lon": 55.15149550694357
                        },
                        {
                            "lat": 61.485932470235674,
                            "lon": 55.15156108283048
                        },
                        {
                            "lat": 61.48581802410914,
                            "lon": 55.153355832209954
                        },
                        {
                            "lat": 61.48268385910151,
                            "lon": 55.153290251968514
                        },
                        {
                            "lat": 61.48279844564166,
                            "lon": 55.15149550694357
                        }
                    ],
                    [
                        {
                            "lat": 61.45412932324795,
                            "lon": 55.15808051914482
                        },
                        {
                            "lat": 61.454013421348456,
                            "lon": 55.1598752219565
                        },
                        {
                            "lat": 61.457148032445616,
                            "lon": 55.159941544455265
                        },
                        {
                            "lat": 61.4572637938866,
                            "lon": 55.15814683723949
                        },
                        {
                            "lat": 61.45412932324795,
                            "lon": 55.15808051914482
                        }
                    ],
                    [
                        {
                            "lat": 61.47446085944119,
                            "lon": 55.183669261006976
                        },
                        {
                            "lat": 61.474575944988715,
                            "lon": 55.181874536487804
                        },
                        {
                            "lat": 61.474691017686126,
                            "lon": 55.18007981127553
                        },
                        {
                            "lat": 61.47155478309586,
                            "lon": 55.18001392387508
                        },
                        {
                            "lat": 61.471439569740475,
                            "lon": 55.18180864471056
                        },
                        {
                            "lat": 61.46830320735799,
                            "lon": 55.181742672331296
                        },
                        {
                            "lat": 61.46818784046518,
                            "lon": 55.183537388090784
                        },
                        {
                            "lat": 61.47132434351932,
                            "lon": 55.18360336485256
                        },
                        {
                            "lat": 61.47446085944119,
                            "lon": 55.183669261006976
                        }
                    ],
                    [
                        {
                            "lat": 61.45843096438748,
                            "lon": 55.188723077737706
                        },
                        {
                            "lat": 61.45831512375824,
                            "lon": 55.19051777753728
                        },
                        {
                            "lat": 61.46145215093505,
                            "lon": 55.19058401371491
                        },
                        {
                            "lat": 61.46156785083586,
                            "lon": 55.18878930951523
                        },
                        {
                            "lat": 61.45843096438748,
                            "lon": 55.188723077737706
                        }
                    ],
                    [
                        {
                            "lat": 61.44553429853885,
                            "lon": 55.19384138877679
                        },
                        {
                            "lat": 61.44541785600707,
                            "lon": 55.195636068832364
                        },
                        {
                            "lat": 61.448555253592616,
                            "lon": 55.19570264077586
                        },
                        {
                            "lat": 61.451692664169286,
                            "lon": 55.19576913207967
                        },
                        {
                            "lat": 61.45483008772145,
                            "lon": 55.19583554274303
                        },
                        {
                            "lat": 61.457967524233396,
                            "lon": 55.19590187276526
                        },
                        {
                            "lat": 61.46110497368947,
                            "lon": 55.19596812214563
                        },
                        {
                            "lat": 61.461220712363826,
                            "lon": 55.19417342003012
                        },
                        {
                            "lat": 61.45808340368318,
                            "lon": 55.19410717505107
                        },
                        {
                            "lat": 61.45819927019082,
                            "lon": 55.192312476641725
                        },
                        {
                            "lat": 61.455062115210104,
                            "lon": 55.1922461554324
                        },
                        {
                            "lat": 61.45494610794469,
                            "lon": 55.19404084943549
                        },
                        {
                            "lat": 61.45180882516403,
                            "lon": 55.19397444318409
                        },
                        {
                            "lat": 61.44867155535685,
                            "lon": 55.19390795629762
                        },
                        {
                            "lat": 61.44553429853885,
                            "lon": 55.19384138877679
                        }
                    ],
                    [
                        {
                            "lat": 61.4587874826064,
                            "lon": 55.2318620185393
                        },
                        {
                            "lat": 61.46192776383371,
                            "lon": 55.23192827527895
                        },
                        {
                            "lat": 61.46204362036029,
                            "lon": 55.23013358265721
                        },
                        {
                            "lat": 61.458903480224315,
                            "lon": 55.23006733032141
                        },
                        {
                            "lat": 61.45576335305426,
                            "lon": 55.2300009972414
                        },
                        {
                            "lat": 61.45262323886586,
                            "lon": 55.229934583417894
                        },
                        {
                            "lat": 61.45273950566317,
                            "lon": 55.22813990332796
                        },
                        {
                            "lat": 61.449599545541815,
                            "lon": 55.22807341318121
                        },
                        {
                            "lat": 61.44645959843157,
                            "lon": 55.22800684229781
                        },
                        {
                            "lat": 61.446343049496846,
                            "lon": 55.22980151354339
                        },
                        {
                            "lat": 61.449483137674825,
                            "lon": 55.22986808885166
                        },
                        {
                            "lat": 61.44936671679474,
                            "lon": 55.23166276382591
                        },
                        {
                            "lat": 61.45250695907118,
                            "lon": 55.23172926281205
                        },
                        {
                            "lat": 61.455647214346975,
                            "lon": 55.231795681050095
                        },
                        {
                            "lat": 61.4587874826064,
                            "lon": 55.2318620185393
                        }
                    ],
                    [
                        {
                            "lat": 61.44308627130276,
                            "lon": 55.23152952361221
                        },
                        {
                            "lat": 61.44296955521132,
                            "lon": 55.23332418903361
                        },
                        {
                            "lat": 61.446109912539065,
                            "lon": 55.23339085394478
                        },
                        {
                            "lat": 61.446226487533366,
                            "lon": 55.23159618409239
                        },
                        {
                            "lat": 61.44308627130276,
                            "lon": 55.23152952361221
                        }
                    ],
                    [
                        {
                            "lat": 61.49823313693338,
                            "lon": 55.05656882680783
                        },
                        {
                            "lat": 61.50135977985236,
                            "lon": 55.05663393176205
                        },
                        {
                            "lat": 61.50448643539257,
                            "lon": 55.05669895648193
                        },
                        {
                            "lat": 61.50437322178872,
                            "lon": 55.0584937597874
                        },
                        {
                            "lat": 61.504259995577236,
                            "lon": 55.06028856240335
                        },
                        {
                            "lat": 61.50738694293611,
                            "lon": 55.0603535155034
                        },
                        {
                            "lat": 61.507500029533375,
                            "lon": 55.05855870857964
                        },
                        {
                            "lat": 61.507613103538525,
                            "lon": 55.056763900966736
                        },
                        {
                            "lat": 61.51073978427467,
                            "lon": 55.056828765215755
                        },
                        {
                            "lat": 61.51085270610444,
                            "lon": 55.05503395261199
                        },
                        {
                            "lat": 61.507726164953404,
                            "lon": 55.05496909266469
                        },
                        {
                            "lat": 61.504599636390694,
                            "lon": 55.05490415248693
                        },
                        {
                            "lat": 61.5014731204318,
                            "lon": 55.05483913207941
                        },
                        {
                            "lat": 61.49834661709219,
                            "lon": 55.054774031442825
                        },
                        {
                            "lat": 61.495220126387416,
                            "lon": 55.054708850577896
                        },
                        {
                            "lat": 61.49510650665112,
                            "lon": 55.05650364161993
                        },
                        {
                            "lat": 61.49823313693338,
                            "lon": 55.05656882680783
                        }
                    ],
                    [
                        {
                            "lat": 61.49197988902105,
                            "lon": 55.05643837619909
                        },
                        {
                            "lat": 61.4918661170415,
                            "lon": 55.0582331622218
                        },
                        {
                            "lat": 61.494992874262614,
                            "lon": 55.058298431971295
                        },
                        {
                            "lat": 61.49510650665112,
                            "lon": 55.05650364161993
                        },
                        {
                            "lat": 61.49197988902105,
                            "lon": 55.05643837619909
                        }
                    ],
                    [
                        {
                            "lat": 61.498006138703694,
                            "lon": 55.06015841546694
                        },
                        {
                            "lat": 61.50113306082793,
                            "lon": 55.06022352905763
                        },
                        {
                            "lat": 61.50124642665174,
                            "lon": 55.05842873075481
                        },
                        {
                            "lat": 61.49811964413785,
                            "lon": 55.05836362148252
                        },
                        {
                            "lat": 61.494992874262614,
                            "lon": 55.058298431971295
                        },
                        {
                            "lat": 61.494879229219976,
                            "lon": 55.06009322163198
                        },
                        {
                            "lat": 61.498006138703694,
                            "lon": 55.06015841546694
                        }
                    ],
                    [
                        {
                            "lat": 61.48794163621799,
                            "lon": 55.07073126469471
                        },
                        {
                            "lat": 61.48782762311443,
                            "lon": 55.07252604085153
                        },
                        {
                            "lat": 61.49095548493701,
                            "lon": 55.072591425524166
                        },
                        {
                            "lat": 61.491069358327394,
                            "lon": 55.0707966450303
                        },
                        {
                            "lat": 61.48794163621799,
                            "lon": 55.07073126469471
                        }
                    ],
                    [
                        {
                            "lat": 61.48782762311443,
                            "lon": 55.07252604085153
                        },
                        {
                            "lat": 61.48469977399237,
                            "lon": 55.07246057589957
                        },
                        {
                            "lat": 61.48458560846154,
                            "lon": 55.07425534702206
                        },
                        {
                            "lat": 61.48771359731035,
                            "lon": 55.07432081631677
                        },
                        {
                            "lat": 61.48782762311443,
                            "lon": 55.07252604085153
                        }
                    ],
                    [
                        {
                            "lat": 61.48211073454763,
                            "lon": 55.16226396671149
                        },
                        {
                            "lat": 61.47897588003489,
                            "lon": 55.16219828414613
                        },
                        {
                            "lat": 61.4788610762344,
                            "lon": 55.16399302065607
                        },
                        {
                            "lat": 61.4819960712526,
                            "lon": 55.164058707583635
                        },
                        {
                            "lat": 61.48211073454763,
                            "lon": 55.16226396671149
                        }
                    ],
                    [
                        {
                            "lat": 61.51721845622148,
                            "lon": 55.053368609933116
                        },
                        {
                            "lat": 61.51710582610953,
                            "lon": 55.05516343181238
                        },
                        {
                            "lat": 61.52023240493258,
                            "lon": 55.055228051064056
                        },
                        {
                            "lat": 61.52034489546918,
                            "lon": 55.053433224899365
                        },
                        {
                            "lat": 61.51721845622148,
                            "lon": 55.053368609933116
                        }
                    ],
                    [
                        {
                            "lat": 61.5262611398938,
                            "lon": 55.05894671624568
                        },
                        {
                            "lat": 61.529388035457984,
                            "lon": 55.0590111033356
                        },
                        {
                            "lat": 61.529500132218786,
                            "lon": 55.057216265719354
                        },
                        {
                            "lat": 61.53262690065269,
                            "lon": 55.05728056829833
                        },
                        {
                            "lat": 61.53273884533332,
                            "lon": 55.05548572573104
                        },
                        {
                            "lat": 61.52961221649783,
                            "lon": 55.05542142741667
                        },
                        {
                            "lat": 61.52648560014223,
                            "lon": 55.055357048866895
                        },
                        {
                            "lat": 61.52335899628197,
                            "lon": 55.05529259008248
                        },
                        {
                            "lat": 61.52023240493258,
                            "lon": 55.055228051064056
                        },
                        {
                            "lat": 61.52011990186961,
                            "lon": 55.05702287654118
                        },
                        {
                            "lat": 61.52324663281175,
                            "lon": 55.05708741984008
                        },
                        {
                            "lat": 61.52637337626664,
                            "lon": 55.05715188289972
                        },
                        {
                            "lat": 61.5262611398938,
                            "lon": 55.05894671624568
                        }
                    ],
                    [
                        {
                            "lat": 61.51386647758557,
                            "lon": 55.05689354922831
                        },
                        {
                            "lat": 61.5137536827836,
                            "lon": 55.058688365440126
                        },
                        {
                            "lat": 61.516880528258184,
                            "lon": 55.058753073507006
                        },
                        {
                            "lat": 61.516993183455725,
                            "lon": 55.05695825300369
                        },
                        {
                            "lat": 61.51386647758557,
                            "lon": 55.05689354922831
                        }
                    ],
                    [
                        {
                            "lat": 61.51006198915977,
                            "lon": 55.06759762637431
                        },
                        {
                            "lat": 61.50693447058735,
                            "lon": 55.06753273630683
                        },
                        {
                            "lat": 61.5068213210007,
                            "lon": 55.0693275397847
                        },
                        {
                            "lat": 61.509948979266774,
                            "lon": 55.069392434156605
                        },
                        {
                            "lat": 61.51006198915977,
                            "lon": 55.06759762637431
                        }
                    ],
                    [
                        {
                            "lat": 61.51854354265689,
                            "lon": 55.08215036101787
                        },
                        {
                            "lat": 61.51865622128838,
                            "lon": 55.08035554516816
                        },
                        {
                            "lat": 61.5217847683472,
                            "lon": 55.080420144148334
                        },
                        {
                            "lat": 61.521672229525606,
                            "lon": 55.082214964283914
                        },
                        {
                            "lat": 61.52155967816467,
                            "lon": 55.084009783732085
                        },
                        {
                            "lat": 61.52468851740008,
                            "lon": 55.084074310965384
                        },
                        {
                            "lat": 61.52480092893369,
                            "lon": 55.082279487236285
                        },
                        {
                            "lat": 61.527929640865594,
                            "lon": 55.08234392987433
                        },
                        {
                            "lat": 61.53105836530576,
                            "lon": 55.08240829219732
                        },
                        {
                            "lat": 61.534187102238704,
                            "lon": 55.08247257420457
                        },
                        {
                            "lat": 61.53731585164888,
                            "lon": 55.08253677589541
                        },
                        {
                            "lat": 61.540444613520755,
                            "lon": 55.08260089726907
                        },
                        {
                            "lat": 61.54357338783878,
                            "lon": 55.08266493832491
                        },
                        {
                            "lat": 61.54670217458745,
                            "lon": 55.082728899062225
                        },
                        {
                            "lat": 61.54681359486146,
                            "lon": 55.08093404483119
                        },
                        {
                            "lat": 61.546925002722254,
                            "lon": 55.07913918991586
                        },
                        {
                            "lat": 61.547036398171734,
                            "lon": 55.07734433431618
                        },
                        {
                            "lat": 61.54390803085167,
                            "lon": 55.07728038630773
                        },
                        {
                            "lat": 61.5437964956082,
                            "lon": 55.07907523766482
                        },
                        {
                            "lat": 61.54368494793786,
                            "lon": 55.08087008833721
                        },
                        {
                            "lat": 61.54055631344301,
                            "lon": 55.080806051530026
                        },
                        {
                            "lat": 61.540668000920995,
                            "lon": 55.07901120510589
                        },
                        {
                            "lat": 61.53753951867617,
                            "lon": 55.07894709223975
                        },
                        {
                            "lat": 61.53742769139243,
                            "lon": 55.080741934410305
                        },
                        {
                            "lat": 61.534299081801656,
                            "lon": 55.08067773697876
                        },
                        {
                            "lat": 61.53117048468623,
                            "lon": 55.080613459236105
                        },
                        {
                            "lat": 61.52804190006164,
                            "lon": 55.08054910118302
                        },
                        {
                            "lat": 61.52815414675132,
                            "lon": 55.07875427180509
                        },
                        {
                            "lat": 61.525025714431315,
                            "lon": 55.078689837717114
                        },
                        {
                            "lat": 61.52513808839914,
                            "lon": 55.076895011927064
                        },
                        {
                            "lat": 61.52200980837993,
                            "lon": 55.07683050181513
                        },
                        {
                            "lat": 61.518881540894334,
                            "lon": 55.076765911405516
                        },
                        {
                            "lat": 61.515753285957935,
                            "lon": 55.0767012406989
                        },
                        {
                            "lat": 61.51564049265353,
                            "lon": 55.078496053633685
                        },
                        {
                            "lat": 61.51552768678254,
                            "lon": 55.08029086588035
                        },
                        {
                            "lat": 61.51541486834304,
                            "lon": 55.0820856774389
                        },
                        {
                            "lat": 61.51854354265689,
                            "lon": 55.08215036101787
                        }
                    ],
                    [
                        {
                            "lat": 61.51217323576807,
                            "lon": 55.083815720121244
                        },
                        {
                            "lat": 61.512060252348675,
                            "lon": 55.08561052600624
                        },
                        {
                            "lat": 61.51518919375089,
                            "lon": 55.08567529849155
                        },
                        {
                            "lat": 61.51530203733312,
                            "lon": 55.083880488309305
                        },
                        {
                            "lat": 61.51217323576807,
                            "lon": 55.083815720121244
                        }
                    ],
                    [
                        {
                            "lat": 61.517160374067636,
                            "lon": 55.15400720593373
                        },
                        {
                            "lat": 61.5172734159515,
                            "lon": 55.152212413302294
                        },
                        {
                            "lat": 61.52040758015703,
                            "lon": 55.15227710349416
                        },
                        {
                            "lat": 61.52029467870801,
                            "lon": 55.15407190042143
                        },
                        {
                            "lat": 61.52342899594854,
                            "lon": 55.154136514381854
                        },
                        {
                            "lat": 61.52656332577361,
                            "lon": 55.154201047814226
                        },
                        {
                            "lat": 61.52667594634716,
                            "lon": 55.1524062423112
                        },
                        {
                            "lat": 61.52678855435372,
                            "lon": 55.1506114361215
                        },
                        {
                            "lat": 61.52992261588212,
                            "lon": 55.15067588046583
                        },
                        {
                            "lat": 61.530035070914245,
                            "lon": 55.14888106931037
                        },
                        {
                            "lat": 61.52690114979522,
                            "lon": 55.14881662924515
                        },
                        {
                            "lat": 61.52376724123935,
                            "lon": 55.1487521086672
                        },
                        {
                            "lat": 61.52387996450949,
                            "lon": 55.14695730538822
                        },
                        {
                            "lat": 61.52701373267353,
                            "lon": 55.14702182168213
                        },
                        {
                            "lat": 61.527126302990595,
                            "lon": 55.145227013432454
                        },
                        {
                            "lat": 61.52399267520275,
                            "lon": 55.14516250142222
                        },
                        {
                            "lat": 61.52085905998987,
                            "lon": 55.14509790891062
                        },
                        {
                            "lat": 61.52074620892232,
                            "lon": 55.14689270858765
                        },
                        {
                            "lat": 61.52063334526226,
                            "lon": 55.14868750757723
                        },
                        {
                            "lat": 61.51749946187958,
                            "lon": 55.148622825975956
                        },
                        {
                            "lat": 61.51761246592763,
                            "lon": 55.14682803128108
                        },
                        {
                            "lat": 61.51447873554104,
                            "lon": 55.14676327346924
                        },
                        {
                            "lat": 61.51436559110692,
                            "lon": 55.14855806386408
                        },
                        {
                            "lat": 61.51425243404715,
                            "lon": 55.15035285357073
                        },
                        {
                            "lat": 61.51413926435984,
                            "lon": 55.15214764258918
                        },
                        {
                            "lat": 61.51402608204301,
                            "lon": 55.15394243091941
                        },
                        {
                            "lat": 61.517160374067636,
                            "lon": 55.15400720593373
                        }
                    ],
                    [
                        {
                            "lat": 61.50507762018604,
                            "lon": 55.14656851700912
                        },
                        {
                            "lat": 61.50194394038802,
                            "lon": 55.14650343718324
                        },
                        {
                            "lat": 61.50183023442879,
                            "lon": 55.14829821032459
                        },
                        {
                            "lat": 61.5049640546052,
                            "lon": 55.148363294471835
                        },
                        {
                            "lat": 61.50507762018604,
                            "lon": 55.14656851700912
                        }
                    ],
                    [
                        {
                            "lat": 61.51232683044649,
                            "lon": 55.180864173279
                        },
                        {
                            "lat": 61.50919044271188,
                            "lon": 55.18079925309455
                        },
                        {
                            "lat": 61.50907691738066,
                            "lon": 55.182594026099025
                        },
                        {
                            "lat": 61.51221344579655,
                            "lon": 55.182658950596185
                        },
                        {
                            "lat": 61.51232683044649,
                            "lon": 55.180864173279
                        }
                    ],
                    [
                        {
                            "lat": 61.533851088678915,
                            "lon": 55.08785708176687
                        },
                        {
                            "lat": 61.53373905919568,
                            "lon": 55.089651916249224
                        },
                        {
                            "lat": 61.536868368038796,
                            "lon": 55.08971613498094
                        },
                        {
                            "lat": 61.53698025764048,
                            "lon": 55.0879212962378
                        },
                        {
                            "lat": 61.533851088678915,
                            "lon": 55.08785708176687
                        }
                    ],
                    [
                        {
                            "lat": 61.533505873259486,
                            "lon": 55.14356097845919
                        },
                        {
                            "lat": 61.53037236073299,
                            "lon": 55.14349663172638
                        },
                        {
                            "lat": 61.52723886074829,
                            "lon": 55.143432204496165
                        },
                        {
                            "lat": 61.527126302990595,
                            "lon": 55.145227013432454
                        },
                        {
                            "lat": 61.53025994333778,
                            "lon": 55.145291444940625
                        },
                        {
                            "lat": 61.53014751339884,
                            "lon": 55.14708625746864
                        },
                        {
                            "lat": 61.53328130666978,
                            "lon": 55.14715061274706
                        },
                        {
                            "lat": 61.53316900458084,
                            "lon": 55.148945428862184
                        },
                        {
                            "lat": 61.53305668995996,
                            "lon": 55.15074024429141
                        },
                        {
                            "lat": 61.53619077657164,
                            "lon": 55.15080452759752
                        },
                        {
                            "lat": 61.539324875701524,
                            "lon": 55.15086873038347
                        },
                        {
                            "lat": 61.54245898733401,
                            "lon": 55.15093285264857
                        },
                        {
                            "lat": 61.545593111453464,
                            "lon": 55.15099689439214
                        },
                        {
                            "lat": 61.54570486441026,
                            "lon": 55.14920206192119
                        },
                        {
                            "lat": 61.54883886058029,
                            "lon": 55.14926601889539
                        },
                        {
                            "lat": 61.55197286920414,
                            "lon": 55.149329895351954
                        },
                        {
                            "lat": 61.552084328879324,
                            "lon": 55.147535053708545
                        },
                        {
                            "lat": 61.55219577611818,
                            "lon": 55.14574021138153
                        },
                        {
                            "lat": 61.54906204829278,
                            "lon": 55.145676343407324
                        },
                        {
                            "lat": 61.549173623473,
                            "lon": 55.14388150463735
                        },
                        {
                            "lat": 61.546040048471546,
                            "lon": 55.143817560402155
                        },
                        {
                            "lat": 61.54290648593386,
                            "lon": 55.14375353566606
                        },
                        {
                            "lat": 61.54301832938062,
                            "lon": 55.141958704708614
                        },
                        {
                            "lat": 61.53988491967683,
                            "lon": 55.14189460372852
                        },
                        {
                            "lat": 61.5367515224661,
                            "lon": 55.141830422254245
                        },
                        {
                            "lat": 61.533618137764066,
                            "lon": 55.141766160286465
                        },
                        {
                            "lat": 61.533505873259486,
                            "lon": 55.14356097845919
                        }
                    ],
                    [
                        {
                            "lat": 61.53954893078612,
                            "lon": 55.14727908177683
                        },
                        {
                            "lat": 61.53641511247075,
                            "lon": 55.14721488751669
                        },
                        {
                            "lat": 61.53652726164774,
                            "lon": 55.14542006644802
                        },
                        {
                            "lat": 61.53966093957928,
                            "lon": 55.14548425644584
                        },
                        {
                            "lat": 61.53954893078612,
                            "lon": 55.14727908177683
                        }
                    ],
                    [
                        {
                            "lat": 61.54592833291742,
                            "lon": 55.14561239492617
                        },
                        {
                            "lat": 61.54581660489762,
                            "lon": 55.14740722876586
                        },
                        {
                            "lat": 61.542682761600275,
                            "lon": 55.147343195526794
                        },
                        {
                            "lat": 61.54279463000771,
                            "lon": 55.145548365938794
                        },
                        {
                            "lat": 61.54592833291742,
                            "lon": 55.14561239492617
                        }
                    ],
                    [
                        {
                            "lat": 61.52981014830053,
                            "lon": 55.15247069093501
                        },
                        {
                            "lat": 61.52969766816756,
                            "lon": 55.154265500717905
                        },
                        {
                            "lat": 61.532832023114786,
                            "lon": 55.154329873092145
                        },
                        {
                            "lat": 61.53294436280524,
                            "lon": 55.15253505903472
                        },
                        {
                            "lat": 61.52981014830053,
                            "lon": 55.15247069093501
                        }
                    ],
                    [
                        {
                            "lat": 61.55138777250503,
                            "lon": 55.057664698678764
                        },
                        {
                            "lat": 61.554514627877246,
                            "lon": 55.05772843955345
                        },
                        {
                            "lat": 61.554403648080594,
                            "lon": 55.059523311140715
                        },
                        {
                            "lat": 61.55753065543956,
                            "lon": 55.05958697599165
                        },
                        {
                            "lat": 61.557641495607236,
                            "lon": 55.05779210018193
                        },
                        {
                            "lat": 61.560768375679466,
                            "lon": 55.05785568056345
                        },
                        {
                            "lat": 61.563895268078454,
                            "lon": 55.05791918069736
                        },
                        {
                            "lat": 61.56400581667131,
                            "lon": 55.05612429577628
                        },
                        {
                            "lat": 61.56087906388952,
                            "lon": 55.05606079985385
                        },
                        {
                            "lat": 61.55775232343263,
                            "lon": 55.05599722368913
                        },
                        {
                            "lat": 61.554625595316146,
                            "lon": 55.055933567282786
                        },
                        {
                            "lat": 61.551498879555524,
                            "lon": 55.0558698306355
                        },
                        {
                            "lat": 61.55138777250503,
                            "lon": 55.057664698678764
                        }
                    ],
                    [
                        {
                            "lat": 61.55373750968271,
                            "lon": 55.07029252631172
                        },
                        {
                            "lat": 61.5506096765951,
                            "lon": 55.07022875583453
                        },
                        {
                            "lat": 61.55049847050595,
                            "lon": 55.07202361840707
                        },
                        {
                            "lat": 61.55362644332922,
                            "lon": 55.07208739311471
                        },
                        {
                            "lat": 61.55373750968271,
                            "lon": 55.07029252631172
                        }
                    ],
                    [
                        {
                            "lat": 61.55284863231226,
                            "lon": 55.084651441596655
                        },
                        {
                            "lat": 61.55597759610586,
                            "lon": 55.08471516560198
                        },
                        {
                            "lat": 61.55608860926189,
                            "lon": 55.08292029935604
                        },
                        {
                            "lat": 61.55619961004943,
                            "lon": 55.081125432426916
                        },
                        {
                            "lat": 61.55307092593278,
                            "lon": 55.08106171687674
                        },
                        {
                            "lat": 61.54994225419828,
                            "lon": 55.08099792101126
                        },
                        {
                            "lat": 61.549830973751206,
                            "lon": 55.082792779480314
                        },
                        {
                            "lat": 61.552959785314535,
                            "lon": 55.08285657957849
                        },
                        {
                            "lat": 61.55284863231226,
                            "lon": 55.084651441596655
                        }
                    ],
                    [
                        {
                            "lat": 61.546479296792235,
                            "lon": 55.086318605471284
                        },
                        {
                            "lat": 61.54659074189833,
                            "lon": 55.08452375260892
                        },
                        {
                            "lat": 61.54346181530909,
                            "lon": 55.08445978762791
                        },
                        {
                            "lat": 61.54033290115234,
                            "lon": 55.08439574232303
                        },
                        {
                            "lat": 61.54022117633584,
                            "lon": 55.0861905866919
                        },
                        {
                            "lat": 61.54335023034683,
                            "lon": 55.08625463624617
                        },
                        {
                            "lat": 61.546479296792235,
                            "lon": 55.086318605471284
                        }
                    ],
                    [
                        {
                            "lat": 61.5370921347748,
                            "lon": 55.086126456809154
                        },
                        {
                            "lat": 61.53698025764048,
                            "lon": 55.0879212962378
                        },
                        {
                            "lat": 61.540109439069425,
                            "lon": 55.08798543037564
                        },
                        {
                            "lat": 61.54022117633584,
                            "lon": 55.0861905866919
                        },
                        {
                            "lat": 61.5370921347748,
                            "lon": 55.086126456809154
                        }
                    ],
                    [
                        {
                            "lat": 61.555644482407985,
                            "lon": 55.090099760240484
                        },
                        {
                            "lat": 61.55251509898245,
                            "lon": 55.0900360235496
                        },
                        {
                            "lat": 61.552403896425226,
                            "lon": 55.091830882833335
                        },
                        {
                            "lat": 61.555533419759186,
                            "lon": 55.09189462375349
                        },
                        {
                            "lat": 61.55866295547103,
                            "lon": 55.09195828432577
                        },
                        {
                            "lat": 61.55877387820955,
                            "lon": 55.09016341658879
                        },
                        {
                            "lat": 61.56190328637157,
                            "lon": 55.09022699259383
                        },
                        {
                            "lat": 61.56201405685302,
                            "lon": 55.08843211995571
                        },
                        {
                            "lat": 61.558884788587605,
                            "lon": 55.08836854816893
                        },
                        {
                            "lat": 61.5557555326808,
                            "lon": 55.088304896044214
                        },
                        {
                            "lat": 61.555644482407985,
                            "lon": 55.090099760240484
                        }
                    ],
                    [
                        {
                            "lat": 61.558463269056645,
                            "lon": 55.14586770580628
                        },
                        {
                            "lat": 61.558574423103806,
                            "lon": 55.14407285433042
                        },
                        {
                            "lat": 61.55868556474966,
                            "lon": 55.14227800217174
                        },
                        {
                            "lat": 61.55555209281456,
                            "lon": 55.14221430367307
                        },
                        {
                            "lat": 61.555440810804754,
                            "lon": 55.14400915160217
                        },
                        {
                            "lat": 61.55230721092262,
                            "lon": 55.14394536837091
                        },
                        {
                            "lat": 61.55219577611818,
                            "lon": 55.14574021138153
                        },
                        {
                            "lat": 61.555329516378016,
                            "lon": 55.14580399884807
                        },
                        {
                            "lat": 61.558463269056645,
                            "lon": 55.14586770580628
                        }
                    ],
                    [
                        {
                            "lat": 61.54928518620509,
                            "lon": 55.14208666518341
                        },
                        {
                            "lat": 61.549173623473,
                            "lon": 55.14388150463735
                        },
                        {
                            "lat": 61.55230721092262,
                            "lon": 55.14394536837091
                        },
                        {
                            "lat": 61.5524186332945,
                            "lon": 55.14215052467673
                        },
                        {
                            "lat": 61.54928518620509,
                            "lon": 55.14208666518341
                        }
                    ],
                    [
                        {
                            "lat": 61.60476343863843,
                            "lon": 55.055147500540365
                        },
                        {
                            "lat": 61.60163666666952,
                            "lon": 55.05508505197248
                        },
                        {
                            "lat": 61.59850990682214,
                            "lon": 55.055022523158684
                        },
                        {
                            "lat": 61.59840090628022,
                            "lon": 55.05681745473394
                        },
                        {
                            "lat": 61.601527805751566,
                            "lon": 55.05687998769512
                        },
                        {
                            "lat": 61.604654717346264,
                            "lon": 55.05694244040513
                        },
                        {
                            "lat": 61.607781641048824,
                            "lon": 55.05700481286324
                        },
                        {
                            "lat": 61.607890222713344,
                            "lon": 55.055209868861695
                        },
                        {
                            "lat": 61.60476343863843,
                            "lon": 55.055147500540365
                        }
                    ],
                    [
                        {
                            "lat": 61.61080012273183,
                            "lon": 55.05886205252512
                        },
                        {
                            "lat": 61.610691656541235,
                            "lon": 55.06065699930472
                        },
                        {
                            "lat": 61.613818883722125,
                            "lon": 55.06071921951047
                        },
                        {
                            "lat": 61.613927210250395,
                            "lon": 55.05892426860398
                        },
                        {
                            "lat": 61.61403552471549,
                            "lon": 55.05712931702113
                        },
                        {
                            "lat": 61.61090857684374,
                            "lon": 55.0570671050688
                        },
                        {
                            "lat": 61.61080012273183,
                            "lon": 55.05886205252512
                        }
                    ],
                    [
                        {
                            "lat": 61.74527558763527,
                            "lon": 55.06146486162798
                        },
                        {
                            "lat": 61.748403179861334,
                            "lon": 55.06152362596225
                        },
                        {
                            "lat": 61.74850548781456,
                            "lon": 55.05972850196346
                        },
                        {
                            "lat": 61.74537803531185,
                            "lon": 55.05966974152729
                        },
                        {
                            "lat": 61.74225059421889,
                            "lon": 55.05961090080963
                        },
                        {
                            "lat": 61.7421480068207,
                            "lon": 55.061406017006895
                        },
                        {
                            "lat": 61.74527558763527,
                            "lon": 55.06146486162798
                        }
                    ],
                    [
                        {
                            "lat": 61.62934756011444,
                            "lon": 55.06282408734473
                        },
                        {
                            "lat": 61.62945520029121,
                            "lon": 55.06102911655791
                        },
                        {
                            "lat": 61.629562828480545,
                            "lon": 55.05923414509656
                        },
                        {
                            "lat": 61.62967044468425,
                            "lon": 55.0574391729607
                        },
                        {
                            "lat": 61.62654343666086,
                            "lon": 55.05737736228465
                        },
                        {
                            "lat": 61.62643568080122,
                            "lon": 55.05917233032052
                        },
                        {
                            "lat": 61.62632791294046,
                            "lon": 55.06096729768152
                        },
                        {
                            "lat": 61.623200637592674,
                            "lon": 55.060905398537926
                        },
                        {
                            "lat": 61.62007337426333,
                            "lon": 55.06084341912774
                        },
                        {
                            "lat": 61.61996531503118,
                            "lon": 55.0626383775965
                        },
                        {
                            "lat": 61.62309271804383,
                            "lon": 55.062700361118
                        },
                        {
                            "lat": 61.62622013307675,
                            "lon": 55.06276226436761
                        },
                        {
                            "lat": 61.62934756011444,
                            "lon": 55.06282408734473
                        }
                    ],
                    [
                        {
                            "lat": 61.587487128817095,
                            "lon": 55.08175817043548
                        },
                        {
                            "lat": 61.587377526439475,
                            "lon": 55.08355307934609
                        },
                        {
                            "lat": 61.59050648558582,
                            "lon": 55.083615915551434
                        },
                        {
                            "lat": 61.59061594811223,
                            "lon": 55.08182100247189
                        },
                        {
                            "lat": 61.587487128817095,
                            "lon": 55.08175817043548
                        }
                    ],
                    [
                        {
                            "lat": 61.59050648558582,
                            "lon": 55.083615915551434
                        },
                        {
                            "lat": 61.59039701086183,
                            "lon": 55.0854108279519
                        },
                        {
                            "lat": 61.593526122074394,
                            "lon": 55.08547358799235
                        },
                        {
                            "lat": 61.59363545692979,
                            "lon": 55.08367867142791
                        },
                        {
                            "lat": 61.59050648558582,
                            "lon": 55.083615915551434
                        }
                    ],
                    [
                        {
                            "lat": 61.58424857950624,
                            "lon": 55.0834901628126
                        },
                        {
                            "lat": 61.584138825050566,
                            "lon": 55.08528506686918
                        },
                        {
                            "lat": 61.58726791184871,
                            "lon": 55.08534798757727
                        },
                        {
                            "lat": 61.587377526439475,
                            "lon": 55.08355307934609
                        },
                        {
                            "lat": 61.58424857950624,
                            "lon": 55.0834901628126
                        }
                    ],
                    [
                        {
                            "lat": 61.565364561646746,
                            "lon": 55.08490585565546
                        },
                        {
                            "lat": 61.56525395571693,
                            "lon": 55.0867007338707
                        },
                        {
                            "lat": 61.56838310876808,
                            "lon": 55.08676414077228
                        },
                        {
                            "lat": 61.56849357482863,
                            "lon": 55.08496925835015
                        },
                        {
                            "lat": 61.565364561646746,
                            "lon": 55.08490585565546
                        }
                    ],
                    [
                        {
                            "lat": 61.59880233792357,
                            "lon": 55.101753160445384
                        },
                        {
                            "lat": 61.60193274520829,
                            "lon": 55.10181571682798
                        },
                        {
                            "lat": 61.60204176974866,
                            "lon": 55.100020793902374
                        },
                        {
                            "lat": 61.602150782136995,
                            "lon": 55.09822587029911
                        },
                        {
                            "lat": 61.59902065486495,
                            "lon": 55.09816332221883
                        },
                        {
                            "lat": 61.59891150247807,
                            "lon": 55.09995824167113
                        },
                        {
                            "lat": 61.59880233792357,
                            "lon": 55.101753160445384
                        }
                    ],
                    [
                        {
                            "lat": 61.56749893696331,
                            "lon": 55.101123175606546
                        },
                        {
                            "lat": 61.56738836004462,
                            "lon": 55.10291805189283
                        },
                        {
                            "lat": 61.567277770798974,
                            "lon": 55.1047129274973
                        },
                        {
                            "lat": 61.57040833572678,
                            "lon": 55.10477629610038
                        },
                        {
                            "lat": 61.57051878494574,
                            "lon": 55.1029814162906
                        },
                        {
                            "lat": 61.57364922215823,
                            "lon": 55.10304470030512
                        },
                        {
                            "lat": 61.57375951905288,
                            "lon": 55.10124981561428
                        },
                        {
                            "lat": 61.57062922185334,
                            "lon": 55.10118653579939
                        },
                        {
                            "lat": 61.56749893696331,
                            "lon": 55.101123175606546
                        }
                    ],
                    [
                        {
                            "lat": 61.57688982854634,
                            "lon": 55.10131301505054
                        },
                        {
                            "lat": 61.576779671666536,
                            "lon": 55.10310790393569
                        },
                        {
                            "lat": 61.579910133455066,
                            "lon": 55.10317102718161
                        },
                        {
                            "lat": 61.580020150318184,
                            "lon": 55.10137613410752
                        },
                        {
                            "lat": 61.57688982854634,
                            "lon": 55.10131301505054
                        }
                    ],
                    [
                        {
                            "lat": 61.5735389129678,
                            "lon": 55.10483958431489
                        },
                        {
                            "lat": 61.57040833572678,
                            "lon": 55.10477629610038
                        },
                        {
                            "lat": 61.570297874194566,
                            "lon": 55.10657117522873
                        },
                        {
                            "lat": 61.57342859147969,
                            "lon": 55.10663446764358
                        },
                        {
                            "lat": 61.5735389129678,
                            "lon": 55.10483958431489
                        }
                    ],
                    [
                        {
                            "lat": 61.651273082214374,
                            "lon": 55.11537058771188
                        },
                        {
                            "lat": 61.64814150159594,
                            "lon": 55.11530928909322
                        },
                        {
                            "lat": 61.64803448207366,
                            "lon": 55.1171042682726
                        },
                        {
                            "lat": 61.651166202860075,
                            "lon": 55.11716557096034
                        },
                        {
                            "lat": 61.651273082214374,
                            "lon": 55.11537058771188
                        }
                    ],
                    [
                        {
                            "lat": 61.61465563292577,
                            "lon": 55.15059051471972
                        },
                        {
                            "lat": 61.61778989947092,
                            "lon": 55.15065270014703
                        },
                        {
                            "lat": 61.61789841016514,
                            "lon": 55.14885777066656
                        },
                        {
                            "lat": 61.61800690875111,
                            "lon": 55.147062840510365
                        },
                        {
                            "lat": 61.61487292308284,
                            "lon": 55.14700066334143
                        },
                        {
                            "lat": 61.61476428406623,
                            "lon": 55.148795589368625
                        },
                        {
                            "lat": 61.61465563292577,
                            "lon": 55.15059051471972
                        }
                    ],
                    [
                        {
                            "lat": 61.611412574780104,
                            "lon": 55.15232316930025
                        },
                        {
                            "lat": 61.611303758910715,
                            "lon": 55.15411808916364
                        },
                        {
                            "lat": 61.614438294265874,
                            "lon": 55.15418036339352
                        },
                        {
                            "lat": 61.61454696965959,
                            "lon": 55.152385439394685
                        },
                        {
                            "lat": 61.611412574780104,
                            "lon": 55.15232316930025
                        }
                    ],
                    [
                        {
                            "lat": 61.6110633317174,
                            "lon": 55.209822671945226
                        },
                        {
                            "lat": 61.6111723839819,
                            "lon": 55.2080277689112
                        },
                        {
                            "lat": 61.60803362707636,
                            "lon": 55.207965370445514
                        },
                        {
                            "lat": 61.604894882371426,
                            "lon": 55.20790289127749
                        },
                        {
                            "lat": 61.604785548185966,
                            "lon": 55.20969778601226
                        },
                        {
                            "lat": 61.60792443385045,
                            "lon": 55.2097602693326
                        },
                        {
                            "lat": 61.6110633317174,
                            "lon": 55.209822671945226
                        }
                    ]
                ]
            },
            {
                "type": "MultiLineString",
                "value": 0.07,
                "data": [
                    [
                        {
                            "lat": 61.34010896534909,
                            "lon": 55.080129801674474
                        },
                        {
                            "lat": 61.3399882938929,
                            "lon": 55.08192436302853
                        },
                        {
                            "lat": 61.33986760899322,
                            "lon": 55.083718923672215
                        },
                        {
                            "lat": 61.342995974646314,
                            "lon": 55.08378811739744
                        },
                        {
                            "lat": 61.34311651980189,
                            "lon": 55.081993552163915
                        },
                        {
                            "lat": 61.346244759138834,
                            "lon": 55.08206266101644
                        },
                        {
                            "lat": 61.34636515113578,
                            "lon": 55.08026809048884
                        },
                        {
                            "lat": 61.34648552972226,
                            "lon": 55.078473519251695
                        },
                        {
                            "lat": 61.34961350302184,
                            "lon": 55.078542538663385
                        },
                        {
                            "lat": 61.34973372849828,
                            "lon": 55.07674796213872
                        },
                        {
                            "lat": 61.35286157548706,
                            "lon": 55.076816896703974
                        },
                        {
                            "lat": 61.35598943585119,
                            "lon": 55.076885751000006
                        },
                        {
                            "lat": 61.35586948978419,
                            "lon": 55.07868033666472
                        },
                        {
                            "lat": 61.35574953035529,
                            "lon": 55.08047492162113
                        },
                        {
                            "lat": 61.3588776835103,
                            "lon": 55.08054370477177
                        },
                        {
                            "lat": 61.35899750321592,
                            "lon": 55.07874911525289
                        },
                        {
                            "lat": 61.36212552999404,
                            "lon": 55.07881781356504
                        },
                        {
                            "lat": 61.36525357010301,
                            "lon": 55.078886431600445
                        },
                        {
                            "lat": 61.368381623527334,
                            "lon": 55.078954969358364
                        },
                        {
                            "lat": 61.36826222300362,
                            "lon": 55.080749572532795
                        },
                        {
                            "lat": 61.371390429459126,
                            "lon": 55.08081803455368
                        },
                        {
                            "lat": 61.37451864920098,
                            "lon": 55.080886416290284
                        },
                        {
                            "lat": 61.377646882213675,
                            "lon": 55.08095471774187
                        },
                        {
                            "lat": 61.37752788763471,
                            "lon": 55.082749333818576
                        },
                        {
                            "lat": 61.37439951487113,
                            "lon": 55.082681027835974
                        },
                        {
                            "lat": 61.37428036726843,
                            "lon": 55.084475638675784
                        },
                        {
                            "lat": 61.37740887979841,
                            "lon": 55.08454394918979
                        },
                        {
                            "lat": 61.38053740558774,
                            "lon": 55.08461217940741
                        },
                        {
                            "lat": 61.38041852427603,
                            "lon": 55.08640679859963
                        },
                        {
                            "lat": 61.38354720309511,
                            "lon": 55.08647495304131
                        },
                        {
                            "lat": 61.3866758951445,
                            "lon": 55.08654302717978
                        },
                        {
                            "lat": 61.38980460040869,
                            "lon": 55.08661102101433
                        },
                        {
                            "lat": 61.389686125261555,
                            "lon": 55.08840565305031
                        },
                        {
                            "lat": 61.392814983532496,
                            "lon": 55.088473571085856
                        },
                        {
                            "lat": 61.39293331887214,
                            "lon": 55.08667893454421
                        },
                        {
                            "lat": 61.39305164102805,
                            "lon": 55.08488429729906
                        },
                        {
                            "lat": 61.39618023288129,
                            "lon": 55.08495212602358
                        },
                        {
                            "lat": 61.39606205051934,
                            "lon": 55.08674676776869
                        },
                        {
                            "lat": 61.399190795334746,
                            "lon": 55.08681452068701
                        },
                        {
                            "lat": 61.39907273961605,
                            "lon": 55.088609166224046
                        },
                        {
                            "lat": 61.40220163739763,
                            "lon": 55.088676843325224
                        },
                        {
                            "lat": 61.40231955330286,
                            "lon": 55.08688219329845
                        },
                        {
                            "lat": 61.40544832440814,
                            "lon": 55.08694978560228
                        },
                        {
                            "lat": 61.40533054831841,
                            "lon": 55.088744440113466
                        },
                        {
                            "lat": 61.4084594723628,
                            "lon": 55.08881195658804
                        },
                        {
                            "lat": 61.411588409515296,
                            "lon": 55.08887939274823
                        },
                        {
                            "lat": 61.41147089996988,
                            "lon": 55.09067405551121
                        },
                        {
                            "lat": 61.40834182298238,
                            "lon": 55.09060661487684
                        },
                        {
                            "lat": 61.40822416049187,
                            "lon": 55.09240127246408
                        },
                        {
                            "lat": 61.40810648488924,
                            "lon": 55.09419592934978
                        },
                        {
                            "lat": 61.41123584159329,
                            "lon": 55.09426337893373
                        },
                        {
                            "lat": 61.41436521139586,
                            "lon": 55.094330748186614
                        },
                        {
                            "lat": 61.41749459428145,
                            "lon": 55.09439803710769
                        },
                        {
                            "lat": 61.42062399023451,
                            "lon": 55.09446524569623
                        },
                        {
                            "lat": 61.42074110636107,
                            "lon": 55.09267057094247
                        },
                        {
                            "lat": 61.41761185027999,
                            "lon": 55.092603366812966
                        },
                        {
                            "lat": 61.41772909321303,
                            "lon": 55.0908086958179
                        },
                        {
                            "lat": 61.42085820943765,
                            "lon": 55.09087589548875
                        },
                        {
                            "lat": 61.42398733871024,
                            "lon": 55.09094301483697
                        },
                        {
                            "lat": 61.427116481015304,
                            "lon": 55.09101005386183
                        },
                        {
                            "lat": 61.43024563633726,
                            "lon": 55.091077012562586
                        },
                        {
                            "lat": 61.43012895284194,
                            "lon": 55.092871701360345
                        },
                        {
                            "lat": 61.43001225634322,
                            "lon": 55.09466638945934
                        },
                        {
                            "lat": 61.43314170441085,
                            "lon": 55.09473327671044
                        },
                        {
                            "lat": 61.43325826102964,
                            "lon": 55.09293858417368
                        },
                        {
                            "lat": 61.43638758220517,
                            "lon": 55.09300538665614
                        },
                        {
                            "lat": 61.43627116546826,
                            "lon": 55.09480008362539
                        },
                        {
                            "lat": 61.43940063949994,
                            "lon": 55.09486681020342
                        },
                        {
                            "lat": 61.43951691635301,
                            "lon": 55.09307210880703
                        },
                        {
                            "lat": 61.44264626345758,
                            "lon": 55.093138750625585
                        },
                        {
                            "lat": 61.44577562350339,
                            "lon": 55.093205312111124
                        },
                        {
                            "lat": 61.448904996474866,
                            "lon": 55.0932717932629
                        },
                        {
                            "lat": 61.44878913928512,
                            "lon": 55.09506650790885
                        },
                        {
                            "lat": 61.451918665058436,
                            "lon": 55.095132913132026
                        },
                        {
                            "lat": 61.45203438235649,
                            "lon": 55.093338194080204
                        },
                        {
                            "lat": 61.455163781132704,
                            "lon": 55.09340451456229
                        },
                        {
                            "lat": 61.45504820372831,
                            "lon": 55.095199238014686
                        },
                        {
                            "lat": 61.45817775527921,
                            "lon": 55.09526548255611
                        },
                        {
                            "lat": 61.461307319695585,
                            "lon": 55.09533164675556
                        },
                        {
                            "lat": 61.46443689696191,
                            "lon": 55.09539773061235
                        },
                        {
                            "lat": 61.46432172641578,
                            "lon": 55.09719246654014
                        },
                        {
                            "lat": 61.4642065430333,
                            "lon": 55.09898720177347
                        },
                        {
                            "lat": 61.46733641298357,
                            "lon": 55.099053214046656
                        },
                        {
                            "lat": 61.47046629575658,
                            "lon": 55.09911914596506
                        },
                        {
                            "lat": 61.47058119927202,
                            "lon": 55.09732440197688
                        },
                        {
                            "lat": 61.4737109549158,
                            "lon": 55.09739024917038
                        },
                        {
                            "lat": 61.476840723349305,
                            "lon": 55.097456016013005
                        },
                        {
                            "lat": 61.47672609970867,
                            "lon": 55.09925076873479
                        },
                        {
                            "lat": 61.47661146329191,
                            "lon": 55.10104552076368
                        },
                        {
                            "lat": 61.476496814097125,
                            "lon": 55.10284027209965
                        },
                        {
                            "lat": 61.4796270151724,
                            "lon": 55.10290597166832
                        },
                        {
                            "lat": 61.48275722901209,
                            "lon": 55.10297159086877
                        },
                        {
                            "lat": 61.48264284701318,
                            "lon": 55.10476635022649
                        },
                        {
                            "lat": 61.48577321359259,
                            "lon": 55.1048318934073
                        },
                        {
                            "lat": 61.485887455600654,
                            "lon": 55.10303712970025
                        },
                        {
                            "lat": 61.48901769492256,
                            "lon": 55.103102588162045
                        },
                        {
                            "lat": 61.48890359290726,
                            "lon": 55.10489735621312
                        },
                        {
                            "lat": 61.49203398494164,
                            "lon": 55.10496273864326
                        },
                        {
                            "lat": 61.49516438968018,
                            "lon": 55.105028040696965
                        },
                        {
                            "lat": 61.49829480710729,
                            "lon": 55.10509326237355
                        },
                        {
                            "lat": 61.49818111240886,
                            "lon": 55.10688804273458
                        },
                        {
                            "lat": 61.50131168252505,
                            "lon": 55.106953188356464
                        },
                        {
                            "lat": 61.504442265300646,
                            "lon": 55.10701825359449
                        },
                        {
                            "lat": 61.50432883799239,
                            "lon": 55.10881304190699
                        },
                        {
                            "lat": 61.507459573447264,
                            "lon": 55.10887803107333
                        },
                        {
                            "lat": 61.50757286072006,
                            "lon": 55.10708323844794
                        },
                        {
                            "lat": 61.51070346876778,
                            "lon": 55.107148142916124
                        },
                        {
                            "lat": 61.510590321532334,
                            "lon": 55.10894293984907
                        },
                        {
                            "lat": 61.51372108223203,
                            "lon": 55.1090077682335
                        },
                        {
                            "lat": 61.516851855530795,
                            "lon": 55.109072516225936
                        },
                        {
                            "lat": 61.516738975792315,
                            "lon": 55.110867321070096
                        },
                        {
                            "lat": 61.516626083468424,
                            "lon": 55.112662125226315
                        },
                        {
                            "lat": 61.51975714948359,
                            "lon": 55.11272680141036
                        },
                        {
                            "lat": 61.522888228070514,
                            "lon": 55.11279139719035
                        },
                        {
                            "lat": 61.5227756033408,
                            "lon": 55.11458620923877
                        },
                        {
                            "lat": 61.52590683457753,
                            "lon": 55.1146507288961
                        },
                        {
                            "lat": 61.52601931921361,
                            "lon": 55.112855912565585
                        },
                        {
                            "lat": 61.529150422897324,
                            "lon": 55.112920347535336
                        },
                        {
                            "lat": 61.52903807835677,
                            "lon": 55.11471516814265
                        },
                        {
                            "lat": 61.53216933466295,
                            "lon": 55.11477952697769
                        },
                        {
                            "lat": 61.53205711770847,
                            "lon": 55.11657435117039
                        },
                        {
                            "lat": 61.53518852664092,
                            "lon": 55.116638633859765
                        },
                        {
                            "lat": 61.53831994807107,
                            "lon": 55.11670283613092
                        },
                        {
                            "lat": 61.54145138198336,
                            "lon": 55.116766957983174
                        },
                        {
                            "lat": 61.54133957291272,
                            "lon": 55.11856179427451
                        },
                        {
                            "lat": 61.54122775137365,
                            "lon": 55.120356629880895
                        },
                        {
                            "lat": 61.544359478040384,
                            "lon": 55.12042067981576
                        },
                        {
                            "lat": 61.547491217161856,
                            "lon": 55.12048464931964
                        },
                        {
                            "lat": 61.54737966348902,
                            "lon": 55.122279492739146
                        },
                        {
                            "lat": 61.550511555220766,
                            "lon": 55.12234338605242
                        },
                        {
                            "lat": 61.55040012929355,
                            "lon": 55.124138233029115
                        },
                        {
                            "lat": 61.550288690939,
                            "lon": 55.12593307932195
                        },
                        {
                            "lat": 61.553420875488996,
                            "lon": 55.12599690067011
                        },
                        {
                            "lat": 61.55330956492494,
                            "lon": 55.12779175051596
                        },
                        {
                            "lat": 61.55319824194535,
                            "lon": 55.12958659967833
                        },
                        {
                            "lat": 61.55006577694024,
                            "lon": 55.12952276985614
                        },
                        {
                            "lat": 61.549954301292274,
                            "lon": 55.13131761409742
                        },
                        {
                            "lat": 61.54984281320939,
                            "lon": 55.13311245765484
                        },
                        {
                            "lat": 61.5497313126897,
                            "lon": 55.134907300528376
                        },
                        {
                            "lat": 61.54659843932362,
                            "lon": 55.134843377517285
                        },
                        {
                            "lat": 61.546486786069366,
                            "lon": 55.13663821546286
                        },
                        {
                            "lat": 61.54637512035894,
                            "lon": 55.138433052724146
                        },
                        {
                            "lat": 61.54626344219042,
                            "lon": 55.14022788930112
                        },
                        {
                            "lat": 61.54313016034992,
                            "lon": 55.14016387306646
                        },
                        {
                            "lat": 61.53999689098499,
                            "lon": 55.14009977634223
                        },
                        {
                            "lat": 61.53686363411126,
                            "lon": 55.140035599129156
                        },
                        {
                            "lat": 61.53697573324963,
                            "lon": 55.13824077531861
                        },
                        {
                            "lat": 61.53384262920219,
                            "lon": 55.13817652188349
                        },
                        {
                            "lat": 61.53373038974435,
                            "lon": 55.139971341427895
                        },
                        {
                            "lat": 61.530597157899805,
                            "lon": 55.13990700323917
                        },
                        {
                            "lat": 61.5274639385933,
                            "lon": 55.139842584563695
                        },
                        {
                            "lat": 61.52735140594856,
                            "lon": 55.141637394873236
                        },
                        {
                            "lat": 61.5242180588663,
                            "lon": 55.14157289142918
                        },
                        {
                            "lat": 61.524105373321056,
                            "lon": 55.14336769676921
                        },
                        {
                            "lat": 61.52097189846686,
                            "lon": 55.14330310854623
                        },
                        {
                            "lat": 61.521084724355184,
                            "lon": 55.14150830749442
                        },
                        {
                            "lat": 61.51795140243079,
                            "lon": 55.141443643069685
                        },
                        {
                            "lat": 61.51806435605793,
                            "lon": 55.13964884562369
                        },
                        {
                            "lat": 61.514931187059524,
                            "lon": 55.13958410500816
                        },
                        {
                            "lat": 61.51481809310877,
                            "lon": 55.141378898155665
                        },
                        {
                            "lat": 61.51470498654005,
                            "lon": 55.14317369061502
                        },
                        {
                            "lat": 61.51459186735145,
                            "lon": 55.14496848238621
                        },
                        {
                            "lat": 61.51145828995712,
                            "lon": 55.144903648374786
                        },
                        {
                            "lat": 61.51157154949866,
                            "lon": 55.14310886090821
                        },
                        {
                            "lat": 61.508438125092766,
                            "lon": 55.143043950708176
                        },
                        {
                            "lat": 61.50855151233421,
                            "lon": 55.14124916686259
                        },
                        {
                            "lat": 61.50541824091289,
                            "lon": 55.14118418048495
                        },
                        {
                            "lat": 61.50228498215635,
                            "lon": 55.14111911362085
                        },
                        {
                            "lat": 61.50217131424986,
                            "lon": 55.14291388883136
                        },
                        {
                            "lat": 61.49903792784407,
                            "lon": 55.142848737155965
                        },
                        {
                            "lat": 61.495904554136224,
                            "lon": 55.142783504990206
                        },
                        {
                            "lat": 61.49579059285889,
                            "lon": 55.144578270853835
                        },
                        {
                            "lat": 61.49567661886608,
                            "lon": 55.14637303602695
                        },
                        {
                            "lat": 61.492542977173386,
                            "lon": 55.14630771469797
                        },
                        {
                            "lat": 61.49265709152317,
                            "lon": 55.14451295386182
                        },
                        {
                            "lat": 61.48952360291855,
                            "lon": 55.14444755637554
                        },
                        {
                            "lat": 61.4894093482137,
                            "lon": 55.146242312869376
                        },
                        {
                            "lat": 61.486275732002646,
                            "lon": 55.1461768305419
                        },
                        {
                            "lat": 61.48314212855583,
                            "lon": 55.14611126771622
                        },
                        {
                            "lat": 61.483256663964944,
                            "lon": 55.14431651992298
                        },
                        {
                            "lat": 61.480123213647175,
                            "lon": 55.14425088095815
                        },
                        {
                            "lat": 61.48000853788884,
                            "lon": 55.14604562439308
                        },
                        {
                            "lat": 61.47687496001731,
                            "lon": 55.14597990057318
                        },
                        {
                            "lat": 61.47698977612294,
                            "lon": 55.14418516150188
                        },
                        {
                            "lat": 61.47385635140778,
                            "lon": 55.1441193615549
                        },
                        {
                            "lat": 61.47374139495683,
                            "lon": 55.14591409625725
                        },
                        {
                            "lat": 61.47060784272303,
                            "lon": 55.14584821144599
                        },
                        {
                            "lat": 61.470722939517366,
                            "lon": 55.14405348111792
                        },
                        {
                            "lat": 61.46758954046727,
                            "lon": 55.14398752019168
                        },
                        {
                            "lat": 61.467474303331514,
                            "lon": 55.14578224614012
                        },
                        {
                            "lat": 61.464340776797876,
                            "lon": 55.145716200340374
                        },
                        {
                            "lat": 61.464456154273115,
                            "lon": 55.143921478776875
                        },
                        {
                            "lat": 61.46132278095049,
                            "lon": 55.14385535687421
                        },
                        {
                            "lat": 61.45818942051501,
                            "lon": 55.143789154484466
                        },
                        {
                            "lat": 61.455056072982295,
                            "lon": 55.1437228716083
                        },
                        {
                            "lat": 61.45517185854584,
                            "lon": 55.14192816253605
                        },
                        {
                            "lat": 61.452038664247496,
                            "lon": 55.14186180358009
                        },
                        {
                            "lat": 61.44890548288115,
                            "lon": 55.141795364144485
                        },
                        {
                            "lat": 61.4487894166875,
                            "lon": 55.14359006439967
                        },
                        {
                            "lat": 61.445656107956644,
                            "lon": 55.14352354006866
                        },
                        {
                            "lat": 61.44252281219095,
                            "lon": 55.14345693525412
                        },
                        {
                            "lat": 61.4424064523943,
                            "lon": 55.14525162597377
                        },
                        {
                            "lat": 61.43927302928563,
                            "lon": 55.14518493624889
                        },
                        {
                            "lat": 61.439389529406036,
                            "lon": 55.143390249956816
                        },
                        {
                            "lat": 61.43625625961748,
                            "lon": 55.14332348417745
                        },
                        {
                            "lat": 61.4331230028409,
                            "lon": 55.143256637916764
                        },
                        {
                            "lat": 61.43323977057531,
                            "lon": 55.14146195979751
                        },
                        {
                            "lat": 61.43010666712854,
                            "lon": 55.14139503749941
                        },
                        {
                            "lat": 61.429989759091896,
                            "lon": 55.14318971117547
                        },
                        {
                            "lat": 61.4298728380121,
                            "lon": 55.14498438415283
                        },
                        {
                            "lat": 61.42673946699044,
                            "lon": 55.14491737248276
                        },
                        {
                            "lat": 61.426856528386075,
                            "lon": 55.14312270395431
                        },
                        {
                            "lat": 61.423723310739035,
                            "lon": 55.14305561625401
                        },
                        {
                            "lat": 61.42059010616636,
                            "lon": 55.14298844807531
                        },
                        {
                            "lat": 61.420707435097746,
                            "lon": 55.14119378776071
                        },
                        {
                            "lat": 61.41757438390933,
                            "lon": 55.1411265435688
                        },
                        {
                            "lat": 61.41444134582451,
                            "lon": 55.14105921890527
                        },
                        {
                            "lat": 61.41432373630656,
                            "lon": 55.14285387028557
                        },
                        {
                            "lat": 61.41745691468366,
                            "lon": 55.142921199418915
                        },
                        {
                            "lat": 61.41733943235242,
                            "lon": 55.1447158545687
                        },
                        {
                            "lat": 61.41722193691363,
                            "lon": 55.14651050901812
                        },
                        {
                            "lat": 61.41408847790514,
                            "lon": 55.14644317094397
                        },
                        {
                            "lat": 61.413970829017664,
                            "lon": 55.14823782022203
                        },
                        {
                            "lat": 61.41083724279726,
                            "lon": 55.14817039718466
                        },
                        {
                            "lat": 61.41095503202182,
                            "lon": 55.14637575238293
                        },
                        {
                            "lat": 61.407821599279266,
                            "lon": 55.14630825333576
                        },
                        {
                            "lat": 61.40793951568254,
                            "lon": 55.14451361231413
                        },
                        {
                            "lat": 61.40480623641387,
                            "lon": 55.144446037268175
                        },
                        {
                            "lat": 61.401672970315154,
                            "lon": 55.14437838174285
                        },
                        {
                            "lat": 61.401554773278896,
                            "lon": 55.14617301378595
                        },
                        {
                            "lat": 61.39842138005226,
                            "lon": 55.14610527328477
                        },
                        {
                            "lat": 61.39853971740203,
                            "lon": 55.14431064573895
                        },
                        {
                            "lat": 61.39540647769005,
                            "lon": 55.144242829257166
                        },
                        {
                            "lat": 61.395524942134514,
                            "lon": 55.14244820551075
                        },
                        {
                            "lat": 61.392391855933205,
                            "lon": 55.14238031305934
                        },
                        {
                            "lat": 61.38925878296225,
                            "lon": 55.142312340136876
                        },
                        {
                            "lat": 61.38937751474647,
                            "lon": 55.14051772470687
                        },
                        {
                            "lat": 61.38624459529572,
                            "lon": 55.14044967583187
                        },
                        {
                            "lat": 61.38311168910451,
                            "lon": 55.14038154649258
                        },
                        {
                            "lat": 61.3829926767738,
                            "lon": 55.14217615288175
                        },
                        {
                            "lat": 61.379859643587494,
                            "lon": 55.14210793855056
                        },
                        {
                            "lat": 61.37672662369394,
                            "lon": 55.142039643751275
                        },
                        {
                            "lat": 61.37684591656308,
                            "lon": 55.140245046424255
                        },
                        {
                            "lat": 61.37371305024402,
                            "lon": 55.14017667569667
                        },
                        {
                            "lat": 61.37359361710871,
                            "lon": 55.141971268484646
                        },
                        {
                            "lat": 61.370460623847414,
                            "lon": 55.14190281275139
                        },
                        {
                            "lat": 61.37058019724689,
                            "lon": 55.140108224507834
                        },
                        {
                            "lat": 61.36744735758725,
                            "lon": 55.14003969285844
                        },
                        {
                            "lat": 61.36732764392564,
                            "lon": 55.1418342765523
                        },
                        {
                            "lat": 61.364194677358995,
                            "lon": 55.14176565988808
                        },
                        {
                            "lat": 61.36407481006659,
                            "lon": 55.143560238319694
                        },
                        {
                            "lat": 61.360941716597004,
                            "lon": 55.14349153663036
                        },
                        {
                            "lat": 61.36106172416305,
                            "lon": 55.1416969627595
                        },
                        {
                            "lat": 61.35792878435341,
                            "lon": 55.1416281851673
                        },
                        {
                            "lat": 61.35479585794566,
                            "lon": 55.14155932711221
                        },
                        {
                            "lat": 61.3516629449554,
                            "lon": 55.141490388595024
                        },
                        {
                            "lat": 61.35178335989712,
                            "lon": 55.139695827729625
                        },
                        {
                            "lat": 61.34865060058985,
                            "lon": 55.13962681333273
                        },
                        {
                            "lat": 61.3485300453982,
                            "lon": 55.14142136961645
                        },
                        {
                            "lat": 61.3484094767579,
                            "lon": 55.14321592519095
                        },
                        {
                            "lat": 61.34527645038593,
                            "lon": 55.14314682116434
                        },
                        {
                            "lat": 61.345397159289675,
                            "lon": 55.14135227017728
                        },
                        {
                            "lat": 61.34226428664542,
                            "lon": 55.14128309027821
                        },
                        {
                            "lat": 61.339131427480986,
                            "lon": 55.14121382992005
                        },
                        {
                            "lat": 61.335998581812,
                            "lon": 55.14114448910351
                        },
                        {
                            "lat": 61.335877452130156,
                            "lon": 55.142939026296425
                        },
                        {
                            "lat": 61.33575630893523,
                            "lon": 55.14473356277844
                        },
                        {
                            "lat": 61.33563515222517,
                            "lon": 55.14652809854956
                        },
                        {
                            "lat": 61.335513981997934,
                            "lon": 55.148322633609745
                        },
                        {
                            "lat": 61.335392798251426,
                            "lon": 55.15011716795902
                        },
                        {
                            "lat": 61.33852634536291,
                            "lon": 55.150186531796585
                        },
                        {
                            "lat": 61.338405288430415,
                            "lon": 55.15198107004033
                        },
                        {
                            "lat": 61.34153898938493,
                            "lon": 55.152050357993
                        },
                        {
                            "lat": 61.341418059297624,
                            "lon": 55.15384490012674
                        },
                        {
                            "lat": 61.34129711571612,
                            "lon": 55.1556394415503
                        },
                        {
                            "lat": 61.33816313404009,
                            "lon": 55.15557014439616
                        },
                        {
                            "lat": 61.33804203657818,
                            "lon": 55.157364680508195
                        },
                        {
                            "lat": 61.341176158638355,
                            "lon": 55.157433982263676
                        },
                        {
                            "lat": 61.34431029419684,
                            "lon": 55.157503203512135
                        },
                        {
                            "lat": 61.34744444323801,
                            "lon": 55.15757234425274
                        },
                        {
                            "lat": 61.35057860574628,
                            "lon": 55.15764140448479
                        },
                        {
                            "lat": 61.35371278170599,
                            "lon": 55.15771038420751
                        },
                        {
                            "lat": 61.35684697110157,
                            "lon": 55.15777928342017
                        },
                        {
                            "lat": 61.35696722622778,
                            "lon": 55.155984719779966
                        },
                        {
                            "lat": 61.353833177226605,
                            "lon": 55.15591582514202
                        },
                        {
                            "lat": 61.35069914165925,
                            "lon": 55.15584684999933
                        },
                        {
                            "lat": 61.3475651195413,
                            "lon": 55.15577779435264
                        },
                        {
                            "lat": 61.347685782379536,
                            "lon": 55.153983243743184
                        },
                        {
                            "lat": 61.344551914099284,
                            "lon": 55.15391411218353
                        },
                        {
                            "lat": 61.34467270383159,
                            "lon": 55.15211956545462
                        },
                        {
                            "lat": 61.34479348008736,
                            "lon": 55.15032501801596
                        },
                        {
                            "lat": 61.34792706766911,
                            "lon": 55.15039414039629
                        },
                        {
                            "lat": 61.347806431754805,
                            "lon": 55.152188692424396
                        },
                        {
                            "lat": 61.350940173138945,
                            "lon": 55.1522577389016
                        },
                        {
                            "lat": 61.350819664122774,
                            "lon": 55.154052294804934
                        },
                        {
                            "lat": 61.353953559313396,
                            "lon": 55.154121265368005
                        },
                        {
                            "lat": 61.357087467935784,
                            "lon": 55.15419015543166
                        },
                        {
                            "lat": 61.360221389974335,
                            "lon": 55.15425896499514
                        },
                        {
                            "lat": 61.36335532541344,
                            "lon": 55.154327694057734
                        },
                        {
                            "lat": 61.36648927423749,
                            "lon": 55.15439634261865
                        },
                        {
                            "lat": 61.36636945367807,
                            "lon": 55.156190920657856
                        },
                        {
                            "lat": 61.36323536446912,
                            "lon": 55.15612226753862
                        },
                        {
                            "lat": 61.36311539013782,
                            "lon": 55.15791684031224
                        },
                        {
                            "lat": 61.36624961974728,
                            "lon": 55.157985497990175
                        },
                        {
                            "lat": 61.36938386273015,
                            "lon": 55.15805407515503
                        },
                        {
                            "lat": 61.37251811907079,
                            "lon": 55.15812257180607
                        },
                        {
                            "lat": 61.37565238875361,
                            "lon": 55.15819098794255
                        },
                        {
                            "lat": 61.37878667176299,
                            "lon": 55.158259323563705
                        },
                        {
                            "lat": 61.381920968083314,
                            "lon": 55.158327578668825
                        },
                        {
                            "lat": 61.381801822890225,
                            "lon": 55.16012217800985
                        },
                        {
                            "lat": 61.38493627293416,
                            "lon": 55.1601903571253
                        },
                        {
                            "lat": 61.385055277698946,
                            "lon": 55.158395753257146
                        },
                        {
                            "lat": 61.385174269184034,
                            "lon": 55.15660114868451
                        },
                        {
                            "lat": 61.38830845166469,
                            "lon": 55.15666923823389
                        },
                        {
                            "lat": 61.38842728947299,
                            "lon": 55.154874628435806
                        },
                        {
                            "lat": 61.39156134481462,
                            "lon": 55.15494263295658
                        },
                        {
                            "lat": 61.39168002897738,
                            "lon": 55.15314801793919
                        },
                        {
                            "lat": 61.394813957175955,
                            "lon": 55.15321593744169
                        },
                        {
                            "lat": 61.39794789860136,
                            "lon": 55.153283776440404
                        },
                        {
                            "lat": 61.40108185323796,
                            "lon": 55.15335153493459
                        },
                        {
                            "lat": 61.40421582107019,
                            "lon": 55.15341921292356
                        },
                        {
                            "lat": 61.40409769846949,
                            "lon": 55.15521384594866
                        },
                        {
                            "lat": 61.40723181987722,
                            "lon": 55.15528144792025
                        },
                        {
                            "lat": 61.41036595445132,
                            "lon": 55.155348969379766
                        },
                        {
                            "lat": 61.4135001021762,
                            "lon": 55.155416410326495
                        },
                        {
                            "lat": 61.4136178035843,
                            "lon": 55.15362176385158
                        },
                        {
                            "lat": 61.4167518240428,
                            "lon": 55.15368911981219
                        },
                        {
                            "lat": 61.41988585761881,
                            "lon": 55.153756395263876
                        },
                        {
                            "lat": 61.42000326511994,
                            "lon": 55.15196173914905
                        },
                        {
                            "lat": 61.42313717140799,
                            "lon": 55.15202892962953
                        },
                        {
                            "lat": 61.42301990429677,
                            "lon": 55.153823590205896
                        },
                        {
                            "lat": 61.42615396406102,
                            "lon": 55.15389070463754
                        },
                        {
                            "lat": 61.42928803689599,
                            "lon": 55.15395773855805
                        },
                        {
                            "lat": 61.432422122786015,
                            "lon": 55.154024691966725
                        },
                        {
                            "lat": 61.43555622171551,
                            "lon": 55.15409156486281
                        },
                        {
                            "lat": 61.43869033366885,
                            "lon": 55.1541583572456
                        },
                        {
                            "lat": 61.438806898800756,
                            "lon": 55.15236367444143
                        },
                        {
                            "lat": 61.44194088336054,
                            "lon": 55.15243038188062
                        },
                        {
                            "lat": 61.445074880910944,
                            "lon": 55.15249700881038
                        },
                        {
                            "lat": 61.44820889143638,
                            "lon": 55.15256355522996
                        },
                        {
                            "lat": 61.45134291492122,
                            "lon": 55.152630021138684
                        },
                        {
                            "lat": 61.454476951349854,
                            "lon": 55.15269640653579
                        },
                        {
                            "lat": 61.457611000706684,
                            "lon": 55.15276271142056
                        },
                        {
                            "lat": 61.46074506297605,
                            "lon": 55.15282893579229
                        },
                        {
                            "lat": 61.46086063235699,
                            "lon": 55.15103422139831
                        },
                        {
                            "lat": 61.46399456712334,
                            "lon": 55.15110036086441
                        },
                        {
                            "lat": 61.46712851476906,
                            "lon": 55.15116641982139
                        },
                        {
                            "lat": 61.47026247527854,
                            "lon": 55.15123239826844
                        },
                        {
                            "lat": 61.47339644863615,
                            "lon": 55.15129829620493
                        },
                        {
                            "lat": 61.47653043482629,
                            "lon": 55.15136411363007
                        },
                        {
                            "lat": 61.476645289370936,
                            "lon": 55.14956937663731
                        },
                        {
                            "lat": 61.479779147983876,
                            "lon": 55.14963510918563
                        },
                        {
                            "lat": 61.482913019396136,
                            "lon": 55.14970076122652
                        },
                        {
                            "lat": 61.48604690359213,
                            "lon": 55.14976633275929
                        },
                        {
                            "lat": 61.48918080055624,
                            "lon": 55.149831823783224
                        },
                        {
                            "lat": 61.49231471027284,
                            "lon": 55.1498972342976
                        },
                        {
                            "lat": 61.49544863272632,
                            "lon": 55.14996256430168
                        },
                        {
                            "lat": 61.49556263215587,
                            "lon": 55.14816780050958
                        },
                        {
                            "lat": 61.49869642694048,
                            "lon": 55.14823304567026
                        },
                        {
                            "lat": 61.50183023442879,
                            "lon": 55.14829821032459
                        },
                        {
                            "lat": 61.50194394038802,
                            "lon": 55.14650343718324
                        },
                        {
                            "lat": 61.50507762018604,
                            "lon": 55.14656851700912
                        },
                        {
                            "lat": 61.5049640546052,
                            "lon": 55.148363294471835
                        },
                        {
                            "lat": 61.50809788745411,
                            "lon": 55.148428298111305
                        },
                        {
                            "lat": 61.51123173295988,
                            "lon": 55.14849322124229
                        },
                        {
                            "lat": 61.51436559110692,
                            "lon": 55.14855806386408
                        },
                        {
                            "lat": 61.51447873554104,
                            "lon": 55.14676327346924
                        },
                        {
                            "lat": 61.51761246592763,
                            "lon": 55.14682803128108
                        },
                        {
                            "lat": 61.52074620892232,
                            "lon": 55.14689270858765
                        },
                        {
                            "lat": 61.52085905998987,
                            "lon": 55.14509790891062
                        },
                        {
                            "lat": 61.52399267520275,
                            "lon": 55.14516250142222
                        },
                        {
                            "lat": 61.527126302990595,
                            "lon": 55.145227013432454
                        },
                        {
                            "lat": 61.52723886074829,
                            "lon": 55.143432204496165
                        },
                        {
                            "lat": 61.53037236073299,
                            "lon": 55.14349663172638
                        },
                        {
                            "lat": 61.533505873259486,
                            "lon": 55.14356097845919
                        },
                        {
                            "lat": 61.533618137764066,
                            "lon": 55.141766160286465
                        },
                        {
                            "lat": 61.5367515224661,
                            "lon": 55.141830422254245
                        },
                        {
                            "lat": 61.53988491967683,
                            "lon": 55.14189460372852
                        },
                        {
                            "lat": 61.54301832938062,
                            "lon": 55.141958704708614
                        },
                        {
                            "lat": 61.54290648593386,
                            "lon": 55.14375353566606
                        },
                        {
                            "lat": 61.546040048471546,
                            "lon": 55.143817560402155
                        },
                        {
                            "lat": 61.549173623473,
                            "lon": 55.14388150463735
                        },
                        {
                            "lat": 61.54928518620509,
                            "lon": 55.14208666518341
                        },
                        {
                            "lat": 61.5524186332945,
                            "lon": 55.14215052467673
                        },
                        {
                            "lat": 61.55230721092262,
                            "lon": 55.14394536837091
                        },
                        {
                            "lat": 61.555440810804754,
                            "lon": 55.14400915160217
                        },
                        {
                            "lat": 61.55555209281456,
                            "lon": 55.14221430367307
                        },
                        {
                            "lat": 61.55868556474966,
                            "lon": 55.14227800217174
                        },
                        {
                            "lat": 61.55879669399607,
                            "lon": 55.14048314933025
                        },
                        {
                            "lat": 61.55566336240935,
                            "lon": 55.14041945506079
                        },
                        {
                            "lat": 61.55577461959098,
                            "lon": 55.13862460576535
                        },
                        {
                            "lat": 61.552641440748225,
                            "lon": 55.13856083523769
                        },
                        {
                            "lat": 61.55275282583383,
                            "lon": 55.13676598949285
                        },
                        {
                            "lat": 61.552864198494454,
                            "lon": 55.134971143064504
                        },
                        {
                            "lat": 61.552975558731994,
                            "lon": 55.133176295952616
                        },
                        {
                            "lat": 61.55308690654834,
                            "lon": 55.131381448157214
                        },
                        {
                            "lat": 61.556219524223714,
                            "lon": 55.13144520175203
                        },
                        {
                            "lat": 61.556330719367885,
                            "lon": 55.129650349040865
                        },
                        {
                            "lat": 61.55644190211023,
                            "lon": 55.1278554956466
                        },
                        {
                            "lat": 61.556553072452616,
                            "lon": 55.126060641569254
                        },
                        {
                            "lat": 61.55666423039694,
                            "lon": 55.12426578680881
                        },
                        {
                            "lat": 61.559796299550705,
                            "lon": 55.124329443032416
                        },
                        {
                            "lat": 61.55990730490648,
                            "lon": 55.122534583363475
                        },
                        {
                            "lat": 61.55677537594509,
                            "lon": 55.122470931365285
                        },
                        {
                            "lat": 61.55364345937797,
                            "lon": 55.122407198928045
                        },
                        {
                            "lat": 61.55375473270672,
                            "lon": 55.12061234703183
                        },
                        {
                            "lat": 61.550622968722486,
                            "lon": 55.120548538391915
                        },
                        {
                            "lat": 61.55073436980064,
                            "lon": 55.11875369004761
                        },
                        {
                            "lat": 61.54760275839553,
                            "lon": 55.11868980521601
                        },
                        {
                            "lat": 61.54771428719193,
                            "lon": 55.116894960428155
                        },
                        {
                            "lat": 61.54458282836216,
                            "lon": 55.116830999415825
                        },
                        {
                            "lat": 61.544694484845664,
                            "lon": 55.11503615818903
                        },
                        {
                            "lat": 61.54156317858743,
                            "lon": 55.11497212100692
                        },
                        {
                            "lat": 61.538431884793866,
                            "lon": 55.114908003410534
                        },
                        {
                            "lat": 61.53530060348051,
                            "lon": 55.114843805400554
                        },
                        {
                            "lat": 61.5354126678243,
                            "lon": 55.113048976255676
                        },
                        {
                            "lat": 61.535524719674186,
                            "lon": 55.11125414642512
                        },
                        {
                            "lat": 61.53563675903207,
                            "lon": 55.10945931590892
                        },
                        {
                            "lat": 61.535748785899855,
                            "lon": 55.107664484707065
                        },
                        {
                            "lat": 61.53261807738619,
                            "lon": 55.10760022334644
                        },
                        {
                            "lat": 61.52948738137631,
                            "lon": 55.10753588159492
                        },
                        {
                            "lat": 61.52635669788575,
                            "lon": 55.10747145945319
                        },
                        {
                            "lat": 61.52646913237079,
                            "lon": 55.10567664037549
                        },
                        {
                            "lat": 61.526581554322824,
                            "lon": 55.10388182061104
                        },
                        {
                            "lat": 61.52345116341433,
                            "lon": 55.10381732664057
                        },
                        {
                            "lat": 61.5233386014465,
                            "lon": 55.105612142124826
                        },
                        {
                            "lat": 61.523226026930125,
                            "lon": 55.10740695692195
                        },
                        {
                            "lat": 61.52009536852493,
                            "lon": 55.10734237400193
                        },
                        {
                            "lat": 61.516964722685756,
                            "lon": 55.10727771069382
                        },
                        {
                            "lat": 61.517077577259144,
                            "lon": 55.10548290447379
                        },
                        {
                            "lat": 61.513947084027215,
                            "lon": 55.10541816507482
                        },
                        {
                            "lat": 61.51406006603098,
                            "lon": 55.10362336246303
                        },
                        {
                            "lat": 61.51092972540244,
                            "lon": 55.103558546984154
                        },
                        {
                            "lat": 61.51104283480548,
                            "lon": 55.10176374798516
                        },
                        {
                            "lat": 61.50791264677651,
                            "lon": 55.101698856437345
                        },
                        {
                            "lat": 61.50478247137007,
                            "lon": 55.101633884520226
                        },
                        {
                            "lat": 61.50489584811448,
                            "lon": 55.099839093449944
                        },
                        {
                            "lat": 61.50500921222273,
                            "lon": 55.09804430169026
                        },
                        {
                            "lat": 61.50187932938142,
                            "lon": 55.09797925803746
                        },
                        {
                            "lat": 61.50176582531741,
                            "lon": 55.09977404548086
                        },
                        {
                            "lat": 61.50165230860171,
                            "lon": 55.1015688322345
                        },
                        {
                            "lat": 61.49852215848698,
                            "lon": 55.10150369958084
                        },
                        {
                            "lat": 61.49863581517206,
                            "lon": 55.09970891714921
                        },
                        {
                            "lat": 61.49550581769396,
                            "lon": 55.099643708455666
                        },
                        {
                            "lat": 61.49237583289867,
                            "lon": 55.09957841940096
                        },
                        {
                            "lat": 61.48924586080174,
                            "lon": 55.09951304998579
                        },
                        {
                            "lat": 61.48935992466951,
                            "lon": 55.09771827986065
                        },
                        {
                            "lat": 61.486230105232615,
                            "lon": 55.09765283442861
                        },
                        {
                            "lat": 61.48634429631896,
                            "lon": 55.09585806795458
                        },
                        {
                            "lat": 61.48321462953823,
                            "lon": 55.09579254651663
                        },
                        {
                            "lat": 61.483328947812076,
                            "lon": 55.093997783698306
                        },
                        {
                            "lat": 61.48019943368366,
                            "lon": 55.09393218626537
                        },
                        {
                            "lat": 61.480084975498656,
                            "lon": 55.09572694473096
                        },
                        {
                            "lat": 61.47695533421575,
                            "lon": 55.09566126259834
                        },
                        {
                            "lat": 61.477069932309995,
                            "lon": 55.09386650849079
                        },
                        {
                            "lat": 61.47718451763393,
                            "lon": 55.09207175369037
                        },
                        {
                            "lat": 61.48031387911391,
                            "lon": 55.0921374271073
                        },
                        {
                            "lat": 61.48344325334671,
                            "lon": 55.092203020187895
                        },
                        {
                            "lat": 61.48657264031677,
                            "lon": 55.092268532931456
                        },
                        {
                            "lat": 61.489702040008574,
                            "lon": 55.092333965337254
                        },
                        {
                            "lat": 61.48981605303996,
                            "lon": 55.09053919244683
                        },
                        {
                            "lat": 61.48668679323211,
                            "lon": 55.09047376438235
                        },
                        {
                            "lat": 61.48680093342763,
                            "lon": 55.0886789951416
                        },
                        {
                            "lat": 61.48367182620606,
                            "lon": 55.08861349109091
                        },
                        {
                            "lat": 61.48378609353465,
                            "lon": 55.08681872550435
                        },
                        {
                            "lat": 61.48390034813177,
                            "lon": 55.08502395922574
                        },
                        {
                            "lat": 61.4807715333261,
                            "lon": 55.0849583835506
                        },
                        {
                            "lat": 61.480657138895495,
                            "lon": 55.08675314547842
                        },
                        {
                            "lat": 61.477528197003345,
                            "lon": 55.08668748513209
                        },
                        {
                            "lat": 61.477413649978665,
                            "lon": 55.088482242011004
                        },
                        {
                            "lat": 61.47428458100392,
                            "lon": 55.08841649698321
                        },
                        {
                            "lat": 61.47416988135409,
                            "lon": 55.09021124880711
                        },
                        {
                            "lat": 61.4740551689223,
                            "lon": 55.092005999937804
                        },
                        {
                            "lat": 61.47092583299456,
                            "lon": 55.0919401658503
                        },
                        {
                            "lat": 61.471040685300615,
                            "lon": 55.09014541908753
                        },
                        {
                            "lat": 61.471155524809184,
                            "lon": 55.08835067163112
                        },
                        {
                            "lat": 61.47127035152221,
                            "lon": 55.08655592348111
                        },
                        {
                            "lat": 61.46814144796428,
                            "lon": 55.08649002217788
                        },
                        {
                            "lat": 61.46802648141,
                            "lon": 55.088284765955486
                        },
                        {
                            "lat": 61.46489745082188,
                            "lon": 55.088218779956975
                        },
                        {
                            "lat": 61.46501255721549,
                            "lon": 55.08642404055712
                        },
                        {
                            "lat": 61.465127650784396,
                            "lon": 55.084629300462915
                        },
                        {
                            "lat": 61.465242731530616,
                            "lon": 55.08283455967435
                        },
                        {
                            "lat": 61.46837134264738,
                            "lon": 55.08290053254079
                        },
                        {
                            "lat": 61.46825640170943,
                            "lon": 55.08469527770631
                        },
                        {
                            "lat": 61.471385165441625,
                            "lon": 55.08476117463751
                        },
                        {
                            "lat": 61.47149996656939,
                            "lon": 55.08296642510034
                        },
                        {
                            "lat": 61.4716147549074,
                            "lon": 55.081171674869594
                        },
                        {
                            "lat": 61.46848627078007,
                            "lon": 55.08110578668132
                        },
                        {
                            "lat": 61.46860118610948,
                            "lon": 55.079311040127884
                        },
                        {
                            "lat": 61.46871608863751,
                            "lon": 55.077516292880524
                        },
                        {
                            "lat": 61.46558789685236,
                            "lon": 55.07745033314261
                        },
                        {
                            "lat": 61.465472854562655,
                            "lon": 55.079245076014175
                        },
                        {
                            "lat": 61.46234453583273,
                            "lon": 55.07917903160491
                        },
                        {
                            "lat": 61.46245971788218,
                            "lon": 55.077384293114456
                        },
                        {
                            "lat": 61.459331551742444,
                            "lon": 55.07731817279674
                        },
                        {
                            "lat": 61.45620339844867,
                            "lon": 55.07725197219024
                        },
                        {
                            "lat": 61.45608793688558,
                            "lon": 55.07904670190255
                        },
                        {
                            "lat": 61.45597246245902,
                            "lon": 55.080841430919385
                        },
                        {
                            "lat": 61.45910089528001,
                            "lon": 55.080907640309746
                        },
                        {
                            "lat": 61.45898554777494,
                            "lon": 55.08270237302359
                        },
                        {
                            "lat": 61.45585697516708,
                            "lon": 55.082636159240714
                        },
                        {
                            "lat": 61.45574147500778,
                            "lon": 55.08443088686651
                        },
                        {
                            "lat": 61.452612775466804,
                            "lon": 55.084364588381405
                        },
                        {
                            "lat": 61.45272841542655,
                            "lon": 55.08256986515381
                        },
                        {
                            "lat": 61.44959986856891,
                            "lon": 55.08250349076359
                        },
                        {
                            "lat": 61.44948408881064,
                            "lon": 55.08429820958763
                        },
                        {
                            "lat": 61.446355415054825,
                            "lon": 55.08423175048598
                        },
                        {
                            "lat": 61.44647133460964,
                            "lon": 55.08243703607075
                        },
                        {
                            "lat": 61.4433428135643,
                            "lon": 55.08237050107606
                        },
                        {
                            "lat": 61.4402143054484,
                            "lon": 55.0823038857802
                        },
                        {
                            "lat": 61.44009810630638,
                            "lon": 55.084098591361794
                        },
                        {
                            "lat": 61.43998189421741,
                            "lon": 55.085893296245914
                        },
                        {
                            "lat": 61.443110681934094,
                            "lon": 55.08595992038109
                        },
                        {
                            "lat": 61.442994596719934,
                            "lon": 55.08775462898792
                        },
                        {
                            "lat": 61.43986566917957,
                            "lon": 55.087688000432486
                        },
                        {
                            "lat": 61.43673675459006,
                            "lon": 55.08762129156068
                        },
                        {
                            "lat": 61.436853119449616,
                            "lon": 55.08582659179968
                        },
                        {
                            "lat": 61.436969471344774,
                            "lon": 55.084031891340736
                        },
                        {
                            "lat": 61.4338408493456,
                            "lon": 55.08396511101467
                        },
                        {
                            "lat": 61.43372435764625,
                            "lon": 55.085759807043104
                        },
                        {
                            "lat": 61.43059560882281,
                            "lon": 55.085692941976944
                        },
                        {
                            "lat": 61.43071224032442,
                            "lon": 55.08389825038431
                        },
                        {
                            "lat": 61.430828858832534,
                            "lon": 55.082103558092996
                        },
                        {
                            "lat": 61.42770040258961,
                            "lon": 55.08203662159978
                        },
                        {
                            "lat": 61.427583644296746,
                            "lon": 55.08383130945039
                        },
                        {
                            "lat": 61.42445506127808,
                            "lon": 55.08376428821361
                        },
                        {
                            "lat": 61.42457195935373,
                            "lon": 55.081969604809046
                        },
                        {
                            "lat": 61.424688844406795,
                            "lon": 55.080174920705
                        },
                        {
                            "lat": 61.42156055395877,
                            "lon": 55.0801078280684
                        },
                        {
                            "lat": 61.4214435291404,
                            "lon": 55.081902507721516
                        },
                        {
                            "lat": 61.41831511196516,
                            "lon": 55.081835330337896
                        },
                        {
                            "lat": 61.415186707843546,
                            "lon": 55.081768072658946
                        },
                        {
                            "lat": 61.41205831679104,
                            "lon": 55.081700734685406
                        },
                        {
                            "lat": 61.41217576089339,
                            "lon": 55.079906068417
                        },
                        {
                            "lat": 61.4090475226829,
                            "lon": 55.07983865462177
                        },
                        {
                            "lat": 61.40591929757055,
                            "lon": 55.07977116053869
                        },
                        {
                            "lat": 61.406037008071955,
                            "lon": 55.07797650251815
                        },
                        {
                            "lat": 61.40615470546165,
                            "lon": 55.076181843795794
                        },
                        {
                            "lat": 61.40302677292324,
                            "lon": 55.07611427839003
                        },
                        {
                            "lat": 61.402908935811176,
                            "lon": 55.077908932630386
                        },
                        {
                            "lat": 61.40279108557192,
                            "lon": 55.07970358616851
                        },
                        {
                            "lat": 61.39966288670247,
                            "lon": 55.07963593151197
                        },
                        {
                            "lat": 61.39653470097776,
                            "lon": 55.079568196569795
                        },
                        {
                            "lat": 61.39665283068679,
                            "lon": 55.07777355201243
                        },
                        {
                            "lat": 61.39677094723758,
                            "lon": 55.075978906752034
                        },
                        {
                            "lat": 61.396889050632126,
                            "lon": 55.07418426078861
                        },
                        {
                            "lat": 61.39376129721678,
                            "lon": 55.07411645905538
                        },
                        {
                            "lat": 61.390633556971174,
                            "lon": 55.0740485770539
                        },
                        {
                            "lat": 61.39051517417689,
                            "lon": 55.07584321401691
                        },
                        {
                            "lat": 61.387387307419665,
                            "lon": 55.07577524723979
                        },
                        {
                            "lat": 61.38750582991083,
                            "lon": 55.07398061478493
                        },
                        {
                            "lat": 61.384378116051245,
                            "lon": 55.073912572249206
                        },
                        {
                            "lat": 61.38125041540794,
                            "lon": 55.07384444944745
                        },
                        {
                            "lat": 61.38136920405503,
                            "lon": 55.072049825319844
                        },
                        {
                            "lat": 61.37824165631885,
                            "lon": 55.0719816267765
                        },
                        {
                            "lat": 61.3781227279964,
                            "lon": 55.07377624638043
                        },
                        {
                            "lat": 61.37800378642666,
                            "lon": 55.075570865278955
                        },
                        {
                            "lat": 61.37487597257355,
                            "lon": 55.075502577418
                        },
                        {
                            "lat": 61.37499505383214,
                            "lon": 55.073707963048875
                        },
                        {
                            "lat": 61.37186739293067,
                            "lon": 55.07363959945351
                        },
                        {
                            "lat": 61.36873974530747,
                            "lon": 55.073571155595104
                        },
                        {
                            "lat": 61.36561211097808,
                            "lon": 55.073502631474376
                        },
                        {
                            "lat": 61.36248448995797,
                            "lon": 55.07343402709207
                        },
                        {
                            "lat": 61.35935688226266,
                            "lon": 55.07336534244896
                        },
                        {
                            "lat": 61.359476648594956,
                            "lon": 55.071570750098616
                        },
                        {
                            "lat": 61.35634919390118,
                            "lon": 55.071501989756264
                        },
                        {
                            "lat": 61.35622928790765,
                            "lon": 55.073296577545776
                        },
                        {
                            "lat": 61.353101706908454,
                            "lon": 55.07322773238325
                        },
                        {
                            "lat": 61.34997413928055,
                            "lon": 55.073158806962134
                        },
                        {
                            "lat": 61.34684658503946,
                            "lon": 55.073089801283196
                        },
                        {
                            "lat": 61.34696691000452,
                            "lon": 55.07129522720806
                        },
                        {
                            "lat": 61.35009432459046,
                            "lon": 55.07136422831023
                        },
                        {
                            "lat": 61.35021449651561,
                            "lon": 55.06956964894928
                        },
                        {
                            "lat": 61.34708722156932,
                            "lon": 55.06950065242345
                        },
                        {
                            "lat": 61.343959960021266,
                            "lon": 55.06943157565115
                        },
                        {
                            "lat": 61.34083271188695,
                            "lon": 55.06936241863313
                        },
                        {
                            "lat": 61.340712121048924,
                            "lon": 55.0711569842491
                        },
                        {
                            "lat": 61.34383950881883,
                            "lon": 55.071226145854105
                        },
                        {
                            "lat": 61.343719044200654,
                            "lon": 55.07302071534717
                        },
                        {
                            "lat": 61.340591516779654,
                            "lon": 55.0729515491548
                        },
                        {
                            "lat": 61.340470899077076,
                            "lon": 55.074746113350194
                        },
                        {
                            "lat": 61.34035026793914,
                            "lon": 55.07654067683527
                        },
                        {
                            "lat": 61.340229623363825,
                            "lon": 55.078335239610034
                        },
                        {
                            "lat": 61.34010896534909,
                            "lon": 55.080129801674474
                        }
                    ],
                    [
                        {
                            "lat": 61.455279345658056,
                            "lon": 55.09160979041427
                        },
                        {
                            "lat": 61.458408617433264,
                            "lon": 55.091676026165565
                        },
                        {
                            "lat": 61.45829319278801,
                            "lon": 55.09347075470844
                        },
                        {
                            "lat": 61.455163781132704,
                            "lon": 55.09340451456229
                        },
                        {
                            "lat": 61.455279345658056,
                            "lon": 55.09160979041427
                        }
                    ],
                    [
                        {
                            "lat": 61.43915651616688,
                            "lon": 55.14697962184337
                        },
                        {
                            "lat": 61.43602296571916,
                            "lon": 55.14691284719779
                        },
                        {
                            "lat": 61.43613961917531,
                            "lon": 55.1451181660366
                        },
                        {
                            "lat": 61.43927302928563,
                            "lon": 55.14518493624889
                        },
                        {
                            "lat": 61.43915651616688,
                            "lon": 55.14697962184337
                        }
                    ],
                    [
                        {
                            "lat": 61.462114133234635,
                            "lon": 55.08276850650171
                        },
                        {
                            "lat": 61.46222934095086,
                            "lon": 55.08097376940068
                        },
                        {
                            "lat": 61.46535779945605,
                            "lon": 55.08103981819143
                        },
                        {
                            "lat": 61.465242731530616,
                            "lon": 55.08283455967435
                        },
                        {
                            "lat": 61.462114133234635,
                            "lon": 55.08276850650171
                        }
                    ],
                    [
                        {
                            "lat": 61.38113161352904,
                            "lon": 55.07563907287006
                        },
                        {
                            "lat": 61.38101279841639,
                            "lon": 55.07743369558765
                        },
                        {
                            "lat": 61.37788483160762,
                            "lon": 55.077365483472036
                        },
                        {
                            "lat": 61.37800378642666,
                            "lon": 55.075570865278955
                        },
                        {
                            "lat": 61.38113161352904,
                            "lon": 55.07563907287006
                        }
                    ],
                    [
                        {
                            "lat": 61.390159946662486,
                            "lon": 55.081227120682996
                        },
                        {
                            "lat": 61.39328824579662,
                            "lon": 55.08129502069829
                        },
                        {
                            "lat": 61.39316995000222,
                            "lon": 55.0830896593504
                        },
                        {
                            "lat": 61.39004151110713,
                            "lon": 55.08302175483065
                        },
                        {
                            "lat": 61.390159946662486,
                            "lon": 55.081227120682996
                        }
                    ],
                    [
                        {
                            "lat": 61.38715022282707,
                            "lon": 55.079364510036825
                        },
                        {
                            "lat": 61.3870316607216,
                            "lon": 55.08115914037897
                        },
                        {
                            "lat": 61.38390338798949,
                            "lon": 55.08109107978695
                        },
                        {
                            "lat": 61.38402208983636,
                            "lon": 55.07929645395948
                        },
                        {
                            "lat": 61.38715022282707,
                            "lon": 55.079364510036825
                        }
                    ],
                    [
                        {
                            "lat": 61.405683837224714,
                            "lon": 55.083360474474226
                        },
                        {
                            "lat": 61.40881234186329,
                            "lon": 55.083427977512706
                        },
                        {
                            "lat": 61.41194085960403,
                            "lon": 55.08349540025272
                        },
                        {
                            "lat": 61.41182338933041,
                            "lon": 55.08529006511898
                        },
                        {
                            "lat": 61.40869473180127,
                            "lon": 55.08522263790598
                        },
                        {
                            "lat": 61.40556608737626,
                            "lon": 55.0851551303892
                        },
                        {
                            "lat": 61.405683837224714,
                            "lon": 55.083360474474226
                        }
                    ],
                    [
                        {
                            "lat": 61.35720769622763,
                            "lon": 55.15239559037523
                        },
                        {
                            "lat": 61.354073927968415,
                            "lon": 55.15232670488546
                        },
                        {
                            "lat": 61.35419428319372,
                            "lon": 55.15053214369441
                        },
                        {
                            "lat": 61.35732791110534,
                            "lon": 55.15060102461072
                        },
                        {
                            "lat": 61.36046155242906,
                            "lon": 55.15066982503753
                        },
                        {
                            "lat": 61.36034147790096,
                            "lon": 55.15246439537018
                        },
                        {
                            "lat": 61.35720769622763,
                            "lon": 55.15239559037523
                        }
                    ],
                    [
                        {
                            "lat": 61.36660908142754,
                            "lon": 55.15260176387255
                        },
                        {
                            "lat": 61.3634752729728,
                            "lon": 55.15253311986954
                        },
                        {
                            "lat": 61.36359520714924,
                            "lon": 55.15073854497406
                        },
                        {
                            "lat": 61.3667288752503,
                            "lon": 55.15080718441959
                        },
                        {
                            "lat": 61.36660908142754,
                            "lon": 55.15260176387255
                        }
                    ],
                    [
                        {
                            "lat": 61.37890594407139,
                            "lon": 55.15646472804987
                        },
                        {
                            "lat": 61.37577180147066,
                            "lon": 55.15639639696607
                        },
                        {
                            "lat": 61.37589120086317,
                            "lon": 55.15460180528395
                        },
                        {
                            "lat": 61.379025203070896,
                            "lon": 55.15467013183074
                        },
                        {
                            "lat": 61.37890594407139,
                            "lon": 55.15646472804987
                        }
                    ],
                    [
                        {
                            "lat": 61.40734980208238,
                            "lon": 55.15348681040655
                        },
                        {
                            "lat": 61.40746777112317,
                            "lon": 55.15169217219122
                        },
                        {
                            "lat": 61.410601624917824,
                            "lon": 55.1517596846846
                        },
                        {
                            "lat": 61.41048379625896,
                            "lon": 55.153554327382786
                        },
                        {
                            "lat": 61.40734980208238,
                            "lon": 55.15348681040655
                        }
                    ],
                    [
                        {
                            "lat": 61.458639428141026,
                            "lon": 55.08808656699427
                        },
                        {
                            "lat": 61.45551043607933,
                            "lon": 55.088020340031505
                        },
                        {
                            "lat": 61.455625961979194,
                            "lon": 55.086225613796785
                        },
                        {
                            "lat": 61.45875481420741,
                            "lon": 55.08629183636585
                        },
                        {
                            "lat": 61.458639428141026,
                            "lon": 55.08808656699427
                        }
                    ],
                    [
                        {
                            "lat": 61.46176843306037,
                            "lon": 55.08815271363632
                        },
                        {
                            "lat": 61.46165317398727,
                            "lon": 55.08994744795832
                        },
                        {
                            "lat": 61.45852402921697,
                            "lon": 55.08988129692751
                        },
                        {
                            "lat": 61.458639428141026,
                            "lon": 55.08808656699427
                        },
                        {
                            "lat": 61.46176843306037,
                            "lon": 55.08815271363632
                        }
                    ],
                    [
                        {
                            "lat": 61.464782331601675,
                            "lon": 55.0900135186624
                        },
                        {
                            "lat": 61.46791150204464,
                            "lon": 55.09007950903906
                        },
                        {
                            "lat": 61.467796509866254,
                            "lon": 55.09187425142863
                        },
                        {
                            "lat": 61.46466719955291,
                            "lon": 55.09180825667347
                        },
                        {
                            "lat": 61.46153790207006,
                            "lon": 55.091742181585545
                        },
                        {
                            "lat": 61.46165317398727,
                            "lon": 55.08994744795832
                        },
                        {
                            "lat": 61.464782331601675,
                            "lon": 55.0900135186624
                        }
                    ],
                    [
                        {
                            "lat": 61.352621390564195,
                            "lon": 55.080406058189936
                        },
                        {
                            "lat": 61.35274148971432,
                            "lon": 55.0786114778013
                        },
                        {
                            "lat": 61.34961350302184,
                            "lon": 55.078542538663385
                        },
                        {
                            "lat": 61.349493264152514,
                            "lon": 55.080337114478915
                        },
                        {
                            "lat": 61.34937301188827,
                            "lon": 55.08213168958534
                        },
                        {
                            "lat": 61.352501278034666,
                            "lon": 55.08220063786983
                        },
                        {
                            "lat": 61.352621390564195,
                            "lon": 55.080406058189936
                        }
                    ],
                    [
                        {
                            "lat": 61.34913246716685,
                            "lon": 55.08572083767071
                        },
                        {
                            "lat": 61.352261012829196,
                            "lon": 55.08578979510344
                        },
                        {
                            "lat": 61.35538957187706,
                            "lon": 55.0858586722404
                        },
                        {
                            "lat": 61.355509571403765,
                            "lon": 55.08406408940898
                        },
                        {
                            "lat": 61.358638004051805,
                            "lon": 55.08413288168578
                        },
                        {
                            "lat": 61.35875785045626,
                            "lon": 55.082338293582715
                        },
                        {
                            "lat": 61.3556295575625,
                            "lon": 55.08226950586922
                        },
                        {
                            "lat": 61.352501278034666,
                            "lon": 55.08220063786983
                        },
                        {
                            "lat": 61.35238115212367,
                            "lon": 55.08399521684101
                        },
                        {
                            "lat": 61.349252746227066,
                            "lon": 55.08392626398259
                        },
                        {
                            "lat": 61.34913246716685,
                            "lon": 55.08572083767071
                        }
                    ],
                    [
                        {
                            "lat": 61.336240800644674,
                            "lon": 55.13755541258508
                        },
                        {
                            "lat": 61.3393733658458,
                            "lon": 55.13762474419609
                        },
                        {
                            "lat": 61.339494314790166,
                            "lon": 55.13583020026845
                        },
                        {
                            "lat": 61.33961525024526,
                            "lon": 55.134035655630406
                        },
                        {
                            "lat": 61.33648296544966,
                            "lon": 55.13396633322327
                        },
                        {
                            "lat": 61.3363618897996,
                            "lon": 55.1357608732596
                        },
                        {
                            "lat": 61.336240800644674,
                            "lon": 55.13755541258508
                        }
                    ],
                    [
                        {
                            "lat": 61.3519037614078,
                            "lon": 55.137901266155424
                        },
                        {
                            "lat": 61.35178335989712,
                            "lon": 55.139695827729625
                        },
                        {
                            "lat": 61.354916132635424,
                            "lon": 55.1397647616705
                        },
                        {
                            "lat": 61.35503639390976,
                            "lon": 55.137970195520346
                        },
                        {
                            "lat": 61.3519037614078,
                            "lon": 55.137901266155424
                        }
                    ],
                    [
                        {
                            "lat": 61.33515039019248,
                            "lon": 55.15370623452466
                        },
                        {
                            "lat": 61.33502916587593,
                            "lon": 55.15550076674103
                        },
                        {
                            "lat": 61.33816313404009,
                            "lon": 55.15557014439616
                        },
                        {
                            "lat": 61.33828421799015,
                            "lon": 55.15377560757353
                        },
                        {
                            "lat": 61.33515039019248,
                            "lon": 55.15370623452466
                        }
                    ],
                    [
                        {
                            "lat": 61.33792092560233,
                            "lon": 55.15915921590964
                        },
                        {
                            "lat": 61.33779980111054,
                            "lon": 55.16095375060046
                        },
                        {
                            "lat": 61.34093420398588,
                            "lon": 55.161023061559895
                        },
                        {
                            "lat": 61.341055188062306,
                            "lon": 55.15922852226691
                        },
                        {
                            "lat": 61.33792092560233,
                            "lon": 55.15915921590964
                        }
                    ],
                    [
                        {
                            "lat": 61.343947763217855,
                            "lon": 55.16288683518164
                        },
                        {
                            "lat": 61.343826892583245,
                            "lon": 55.16468137765183
                        },
                        {
                            "lat": 61.34696160333361,
                            "lon": 55.16475053675933
                        },
                        {
                            "lat": 61.347082333517506,
                            "lon": 55.16295598969679
                        },
                        {
                            "lat": 61.35021691729037,
                            "lon": 55.163025063687385
                        },
                        {
                            "lat": 61.35009632755926,
                            "lon": 55.16481961533691
                        },
                        {
                            "lat": 61.35323106524454,
                            "lon": 55.164888613383866
                        },
                        {
                            "lat": 61.353351514520845,
                            "lon": 55.163094057152655
                        },
                        {
                            "lat": 61.3564861251933,
                            "lon": 55.16316297009187
                        },
                        {
                            "lat": 61.359620749292134,
                            "lon": 55.16323180250426
                        },
                        {
                            "lat": 61.36275538680169,
                            "lon": 55.163300554389075
                        },
                        {
                            "lat": 61.365890037706386,
                            "lon": 55.16336922574558
                        },
                        {
                            "lat": 61.366009911763484,
                            "lon": 55.16157465053405
                        },
                        {
                            "lat": 61.36612977244311,
                            "lon": 55.15978007461557
                        },
                        {
                            "lat": 61.36299540241752,
                            "lon": 55.15971141237852
                        },
                        {
                            "lat": 61.36287540130615,
                            "lon": 55.16150598373747
                        },
                        {
                            "lat": 61.35974090424192,
                            "lon": 55.16143723641792
                        },
                        {
                            "lat": 61.356606420586374,
                            "lon": 55.161368408576124
                        },
                        {
                            "lat": 61.35347195035516,
                            "lon": 55.16129950021285
                        },
                        {
                            "lat": 61.35033749356388,
                            "lon": 55.161230511328824
                        },
                        {
                            "lat": 61.34720305022817,
                            "lon": 55.16116144192484
                        },
                        {
                            "lat": 61.344068620363636,
                            "lon": 55.1610922920016
                        },
                        {
                            "lat": 61.343947763217855,
                            "lon": 55.16288683518164
                        }
                    ],
                    [
                        {
                            "lat": 61.33767866310071,
                            "lon": 55.16274828458067
                        },
                        {
                            "lat": 61.334544133314445,
                            "lon": 55.16267888849635
                        },
                        {
                            "lat": 61.33442284133997,
                            "lon": 55.16447341715757
                        },
                        {
                            "lat": 61.33755751157081,
                            "lon": 55.164542817850204
                        },
                        {
                            "lat": 61.33767866310071,
                            "lon": 55.16274828458067
                        }
                    ],
                    [
                        {
                            "lat": 61.33755751157081,
                            "lon": 55.164542817850204
                        },
                        {
                            "lat": 61.33743634651875,
                            "lon": 55.1663373504091
                        },
                        {
                            "lat": 61.340571170733966,
                            "lon": 55.16640667517752
                        },
                        {
                            "lat": 61.34069219532377,
                            "lon": 55.16461213801521
                        },
                        {
                            "lat": 61.33755751157081,
                            "lon": 55.164542817850204
                        }
                    ],
                    [
                        {
                            "lat": 61.340571170733966,
                            "lon": 55.16640667517752
                        },
                        {
                            "lat": 61.340450132635596,
                            "lon": 55.16820121162956
                        },
                        {
                            "lat": 61.34358511083927,
                            "lon": 55.16827046046274
                        },
                        {
                            "lat": 61.34370600845773,
                            "lon": 55.16647591941222
                        },
                        {
                            "lat": 61.340571170733966,
                            "lon": 55.16640667517752
                        }
                    ],
                    [
                        {
                            "lat": 61.37117786422226,
                            "lon": 55.13113527269489
                        },
                        {
                            "lat": 61.37105835749003,
                            "lon": 55.1329298644701
                        },
                        {
                            "lat": 61.37419064958644,
                            "lon": 55.132998297485464
                        },
                        {
                            "lat": 61.374310016132455,
                            "lon": 55.13120370116789
                        },
                        {
                            "lat": 61.37117786422226,
                            "lon": 55.13113527269489
                        }
                    ],
                    [
                        {
                            "lat": 61.34985510771601,
                            "lon": 55.16840871650895
                        },
                        {
                            "lat": 61.349734477599796,
                            "lon": 55.17020326603141
                        },
                        {
                            "lat": 61.35286963674328,
                            "lon": 55.170272277825816
                        },
                        {
                            "lat": 61.3529901263578,
                            "lon": 55.16847772372048
                        },
                        {
                            "lat": 61.34985510771601,
                            "lon": 55.16840871650895
                        }
                    ],
                    [
                        {
                            "lat": 61.38231903712857,
                            "lon": 55.05769280692016
                        },
                        {
                            "lat": 61.382200354263496,
                            "lon": 55.059487436687355
                        },
                        {
                            "lat": 61.38532693792189,
                            "lon": 55.059555523353374
                        },
                        {
                            "lat": 61.38544548123359,
                            "lon": 55.057760889071005
                        },
                        {
                            "lat": 61.38231903712857,
                            "lon": 55.05769280692016
                        }
                    ],
                    [
                        {
                            "lat": 61.37395187655095,
                            "lon": 55.13658748800291
                        },
                        {
                            "lat": 61.37708446238442,
                            "lon": 55.13665584965357
                        },
                        {
                            "lat": 61.377203715340684,
                            "lon": 55.13486125020996
                        },
                        {
                            "lat": 61.37732295499668,
                            "lon": 55.133066650060826
                        },
                        {
                            "lat": 61.37419064958644,
                            "lon": 55.132998297485464
                        },
                        {
                            "lat": 61.37407126972663,
                            "lon": 55.13479289309715
                        },
                        {
                            "lat": 61.37395187655095,
                            "lon": 55.13658748800291
                        }
                    ],
                    [
                        {
                            "lat": 61.37684591656308,
                            "lon": 55.140245046424255
                        },
                        {
                            "lat": 61.37997879618843,
                            "lon": 55.140313336689815
                        },
                        {
                            "lat": 61.38009793549865,
                            "lon": 55.1385187341239
                        },
                        {
                            "lat": 61.38021706152015,
                            "lon": 55.136724130852855
                        },
                        {
                            "lat": 61.37708446238442,
                            "lon": 55.13665584965357
                        },
                        {
                            "lat": 61.376965196125894,
                            "lon": 55.13845044839167
                        },
                        {
                            "lat": 61.37684591656308,
                            "lon": 55.140245046424255
                        }
                    ],
                    [
                        {
                            "lat": 61.378548087211286,
                            "lon": 55.16184851247554
                        },
                        {
                            "lat": 61.37541352333781,
                            "lon": 55.16178016777838
                        },
                        {
                            "lat": 61.37529407063501,
                            "lon": 55.163574756637736
                        },
                        {
                            "lat": 61.378428774963986,
                            "lon": 55.163643105873504
                        },
                        {
                            "lat": 61.378548087211286,
                            "lon": 55.16184851247554
                        }
                    ],
                    [
                        {
                            "lat": 61.37783201393158,
                            "lon": 55.17261606228302
                        },
                        {
                            "lat": 61.37795139278424,
                            "lon": 55.17082147241186
                        },
                        {
                            "lat": 61.37481612647708,
                            "lon": 55.1707531050176
                        },
                        {
                            "lat": 61.371680873510655,
                            "lon": 55.17068465707072
                        },
                        {
                            "lat": 61.37156121359277,
                            "lon": 55.17247923785526
                        },
                        {
                            "lat": 61.37469660709078,
                            "lon": 55.172547690348125
                        },
                        {
                            "lat": 61.37783201393158,
                            "lon": 55.17261606228302
                        }
                    ],
                    [
                        {
                            "lat": 61.384102867578996,
                            "lon": 55.17275256447592
                        },
                        {
                            "lat": 61.380967434099524,
                            "lon": 55.1726843536592
                        },
                        {
                            "lat": 61.380848182470984,
                            "lon": 55.174478947360676
                        },
                        {
                            "lat": 61.38398375650376,
                            "lon": 55.17454716270777
                        },
                        {
                            "lat": 61.384102867578996,
                            "lon": 55.17275256447592
                        }
                    ],
                    [
                        {
                            "lat": 61.40001681720173,
                            "lon": 55.07425198225288
                        },
                        {
                            "lat": 61.399898853510045,
                            "lon": 55.07604663270854
                        },
                        {
                            "lat": 61.40302677292324,
                            "lon": 55.07611427839003
                        },
                        {
                            "lat": 61.40314459691007,
                            "lon": 55.074319623447465
                        },
                        {
                            "lat": 61.40001681720173,
                            "lon": 55.07425198225288
                        }
                    ],
                    [
                        {
                            "lat": 61.40928265110978,
                            "lon": 55.07624932892505
                        },
                        {
                            "lat": 61.40916509344442,
                            "lon": 55.07804399212412
                        },
                        {
                            "lat": 61.412293191913086,
                            "lon": 55.07811140144758
                        },
                        {
                            "lat": 61.412410609852095,
                            "lon": 55.076316733777105
                        },
                        {
                            "lat": 61.40928265110978,
                            "lon": 55.07624932892505
                        }
                    ],
                    [
                        {
                            "lat": 61.39156134481462,
                            "lon": 55.15494263295658
                        },
                        {
                            "lat": 61.391442647407416,
                            "lon": 55.15673724727032
                        },
                        {
                            "lat": 61.3945768563966,
                            "lon": 55.15680517579305
                        },
                        {
                            "lat": 61.39469541340071,
                            "lon": 55.15501055696898
                        },
                        {
                            "lat": 61.39156134481462,
                            "lon": 55.15494263295658
                        }
                    ],
                    [
                        {
                            "lat": 61.410248099492925,
                            "lon": 55.157143610675526
                        },
                        {
                            "lat": 61.407113824505664,
                            "lon": 55.15707608473232
                        },
                        {
                            "lat": 61.40699581596574,
                            "lon": 55.158870720842756
                        },
                        {
                            "lat": 61.41013023138176,
                            "lon": 55.15893825127003
                        },
                        {
                            "lat": 61.410248099492925,
                            "lon": 55.157143610675526
                        }
                    ],
                    [
                        {
                            "lat": 61.40072702466047,
                            "lon": 55.158735418420804
                        },
                        {
                            "lat": 61.400608722067396,
                            "lon": 55.16053004484464
                        },
                        {
                            "lat": 61.40374325156741,
                            "lon": 55.16059774081175
                        },
                        {
                            "lat": 61.4038614137201,
                            "lon": 55.15880310989278
                        },
                        {
                            "lat": 61.40072702466047,
                            "lon": 55.158735418420804
                        }
                    ],
                    [
                        {
                            "lat": 61.39628904806583,
                            "lon": 55.1784084489243
                        },
                        {
                            "lat": 61.39942496960544,
                            "lon": 55.17847627044488
                        },
                        {
                            "lat": 61.399306521661906,
                            "lon": 55.180270889140786
                        },
                        {
                            "lat": 61.40244259703758,
                            "lon": 55.18033863458101
                        },
                        {
                            "lat": 61.40256090436877,
                            "lon": 55.17854401138553
                        },
                        {
                            "lat": 61.402679198491896,
                            "lon": 55.17674938748785
                        },
                        {
                            "lat": 61.40581500586461,
                            "lon": 55.17681704335403
                        },
                        {
                            "lat": 61.40593314619857,
                            "lon": 55.175022414260724
                        },
                        {
                            "lat": 61.402797479408925,
                            "lon": 55.17495476288801
                        },
                        {
                            "lat": 61.399661825823344,
                            "lon": 55.17488703094527
                        },
                        {
                            "lat": 61.39954340432525,
                            "lon": 55.17668165104636
                        },
                        {
                            "lat": 61.39640762338032,
                            "lon": 55.176613834030306
                        },
                        {
                            "lat": 61.39628904806583,
                            "lon": 55.1784084489243
                        }
                    ],
                    [
                        {
                            "lat": 61.41839630169995,
                            "lon": 55.128563933010106
                        },
                        {
                            "lat": 61.4182789241576,
                            "lon": 55.13035859376209
                        },
                        {
                            "lat": 61.42141113390782,
                            "lon": 55.130425811175485
                        },
                        {
                            "lat": 61.421528371265026,
                            "lon": 55.12863114596178
                        },
                        {
                            "lat": 61.41839630169995,
                            "lon": 55.128563933010106
                        }
                    ],
                    [
                        {
                            "lat": 61.41130832085885,
                            "lon": 55.14099181377086
                        },
                        {
                            "lat": 61.41444134582451,
                            "lon": 55.14105921890527
                        },
                        {
                            "lat": 61.41455894222326,
                            "lon": 55.13926456682427
                        },
                        {
                            "lat": 61.41142605753229,
                            "lon": 55.13919716616461
                        },
                        {
                            "lat": 61.40829318597413,
                            "lon": 55.13912968504009
                        },
                        {
                            "lat": 61.40817530902798,
                            "lon": 55.140924328166285
                        },
                        {
                            "lat": 61.41130832085885,
                            "lon": 55.14099181377086
                        }
                    ],
                    [
                        {
                            "lat": 61.41432373630656,
                            "lon": 55.14285387028557
                        },
                        {
                            "lat": 61.41119057105061,
                            "lon": 55.142786460676014
                        },
                        {
                            "lat": 61.4110728081056,
                            "lon": 55.14458110688005
                        },
                        {
                            "lat": 61.41420611366744,
                            "lon": 55.14464852096513
                        },
                        {
                            "lat": 61.41432373630656,
                            "lon": 55.14285387028557
                        }
                    ],
                    [
                        {
                            "lat": 61.42290262409924,
                            "lon": 55.15561825008267
                        },
                        {
                            "lat": 61.42278533081341,
                            "lon": 55.1574129092598
                        },
                        {
                            "lat": 61.42591967140829,
                            "lon": 55.157480032605065
                        },
                        {
                            "lat": 61.42603682427101,
                            "lon": 55.15568536897091
                        },
                        {
                            "lat": 61.42290262409924,
                            "lon": 55.15561825008267
                        }
                    ],
                    [
                        {
                            "lat": 61.4135001021762,
                            "lon": 55.155416410326495
                        },
                        {
                            "lat": 61.413382387632936,
                            "lon": 55.157211056100614
                        },
                        {
                            "lat": 61.416516688910114,
                            "lon": 55.157278421006836
                        },
                        {
                            "lat": 61.41663426303623,
                            "lon": 55.155483770759716
                        },
                        {
                            "lat": 61.4135001021762,
                            "lon": 55.155416410326495
                        }
                    ],
                    [
                        {
                            "lat": 61.430433745325395,
                            "lon": 55.184534041891894
                        },
                        {
                            "lat": 61.42729727031459,
                            "lon": 55.18446701284784
                        },
                        {
                            "lat": 61.427180048693586,
                            "lon": 55.18626166975063
                        },
                        {
                            "lat": 61.43031666438169,
                            "lon": 55.1863287032474
                        },
                        {
                            "lat": 61.430433745325395,
                            "lon": 55.184534041891894
                        }
                    ],
                    [
                        {
                            "lat": 61.4436909140437,
                            "lon": 55.07698636689036
                        },
                        {
                            "lat": 61.44357489347674,
                            "lon": 55.07878107898266
                        },
                        {
                            "lat": 61.44670313497947,
                            "lon": 55.07884760515022
                        },
                        {
                            "lat": 61.4468190157984,
                            "lon": 55.07705288864495
                        },
                        {
                            "lat": 61.4436909140437,
                            "lon": 55.07698636689036
                        }
                    ],
                    [
                        {
                            "lat": 61.445888508004934,
                            "lon": 55.139934147694625
                        },
                        {
                            "lat": 61.4457723144624,
                            "lon": 55.14172884423
                        },
                        {
                            "lat": 61.44890548288115,
                            "lon": 55.141795364144485
                        },
                        {
                            "lat": 61.4490215361272,
                            "lon": 55.140000663192964
                        },
                        {
                            "lat": 61.445888508004934,
                            "lon": 55.139934147694625
                        }
                    ],
                    [
                        {
                            "lat": 61.44263915900683,
                            "lon": 55.141662243837345
                        },
                        {
                            "lat": 61.43950601653005,
                            "lon": 55.14159556296722
                        },
                        {
                            "lat": 61.439389529406036,
                            "lon": 55.143390249956816
                        },
                        {
                            "lat": 61.44252281219095,
                            "lon": 55.14345693525412
                        },
                        {
                            "lat": 61.44263915900683,
                            "lon": 55.141662243837345
                        }
                    ],
                    [
                        {
                            "lat": 61.45354968437029,
                            "lon": 55.167054026246284
                        },
                        {
                            "lat": 61.45041452422667,
                            "lon": 55.16698760557223
                        },
                        {
                            "lat": 61.45029841709256,
                            "lon": 55.16878230049397
                        },
                        {
                            "lat": 61.45343371777087,
                            "lon": 55.16884872557943
                        },
                        {
                            "lat": 61.45354968437029,
                            "lon": 55.167054026246284
                        }
                    ],
                    [
                        {
                            "lat": 61.443212773343724,
                            "lon": 55.1812119917108
                        },
                        {
                            "lat": 61.44634903223664,
                            "lon": 55.1812786088803
                        },
                        {
                            "lat": 61.446465370785106,
                            "lon": 55.1794839232513
                        },
                        {
                            "lat": 61.44658169634457,
                            "lon": 55.17768923692569
                        },
                        {
                            "lat": 61.44344571871653,
                            "lon": 55.1776226286055
                        },
                        {
                            "lat": 61.44332925253247,
                            "lon": 55.17941731050665
                        },
                        {
                            "lat": 61.443212773343724,
                            "lon": 55.1812119917108
                        }
                    ],
                    [
                        {
                            "lat": 61.43275137723822,
                            "lon": 55.19716363628912
                        },
                        {
                            "lat": 61.4296139040702,
                            "lon": 55.197096656708254
                        },
                        {
                            "lat": 61.42949673155816,
                            "lon": 55.19889131317291
                        },
                        {
                            "lat": 61.43263434551513,
                            "lon": 55.198958297203916
                        },
                        {
                            "lat": 61.43275137723822,
                            "lon": 55.19716363628912
                        }
                    ],
                    [
                        {
                            "lat": 61.4663928341709,
                            "lon": 55.0648871136017
                        },
                        {
                            "lat": 61.46627788154495,
                            "lon": 55.06668186133318
                        },
                        {
                            "lat": 61.46940523508576,
                            "lon": 55.06674779482454
                        },
                        {
                            "lat": 61.46952004805859,
                            "lon": 55.06495304272001
                        },
                        {
                            "lat": 61.4663928341709,
                            "lon": 55.0648871136017
                        }
                    ],
                    [
                        {
                            "lat": 61.477986257496006,
                            "lon": 55.07950845068842
                        },
                        {
                            "lat": 61.47485788759167,
                            "lon": 55.079442727465704
                        },
                        {
                            "lat": 61.474743251822524,
                            "lon": 55.08123748275556
                        },
                        {
                            "lat": 61.47787176150989,
                            "lon": 55.08130321033852
                        },
                        {
                            "lat": 61.477986257496006,
                            "lon": 55.07950845068842
                        }
                    ],
                    [
                        {
                            "lat": 61.471385165441625,
                            "lon": 55.08476117463751
                        },
                        {
                            "lat": 61.47127035152221,
                            "lon": 55.08655592348111
                        },
                        {
                            "lat": 61.47439926787374,
                            "lon": 55.086621744466086
                        },
                        {
                            "lat": 61.47451394196549,
                            "lon": 55.08482699125577
                        },
                        {
                            "lat": 61.471385165441625,
                            "lon": 55.08476117463751
                        }
                    ],
                    [
                        {
                            "lat": 61.47046629575658,
                            "lon": 55.09911914596506
                        },
                        {
                            "lat": 61.47035137943392,
                            "lon": 55.10091388925958
                        },
                        {
                            "lat": 61.47348141496612,
                            "lon": 55.10097974519238
                        },
                        {
                            "lat": 61.4735961913368,
                            "lon": 55.09918499752801
                        },
                        {
                            "lat": 61.47046629575658,
                            "lon": 55.09911914596506
                        }
                    ],
                    [
                        {
                            "lat": 61.46841856136939,
                            "lon": 55.17994795587794
                        },
                        {
                            "lat": 61.46830320735799,
                            "lon": 55.181742672331296
                        },
                        {
                            "lat": 61.471439569740475,
                            "lon": 55.18180864471056
                        },
                        {
                            "lat": 61.47155478309586,
                            "lon": 55.18001392387508
                        },
                        {
                            "lat": 61.46841856136939,
                            "lon": 55.17994795587794
                        }
                    ],
                    [
                        {
                            "lat": 61.48782762311443,
                            "lon": 55.07252604085153
                        },
                        {
                            "lat": 61.48771359731035,
                            "lon": 55.07432081631677
                        },
                        {
                            "lat": 61.490841598861614,
                            "lon": 55.07438620532685
                        },
                        {
                            "lat": 61.49095548493701,
                            "lon": 55.072591425524166
                        },
                        {
                            "lat": 61.48782762311443,
                            "lon": 55.07252604085153
                        }
                    ],
                    [
                        {
                            "lat": 61.48737144367571,
                            "lon": 55.07970513856311
                        },
                        {
                            "lat": 61.48748550759291,
                            "lon": 55.0779103651726
                        },
                        {
                            "lat": 61.48759955880383,
                            "lon": 55.07611559109047
                        },
                        {
                            "lat": 61.48447143021276,
                            "lon": 55.076050117452624
                        },
                        {
                            "lat": 61.48435723924406,
                            "lon": 55.07784488719122
                        },
                        {
                            "lat": 61.48122898361703,
                            "lon": 55.07777932891532
                        },
                        {
                            "lat": 61.48111464015515,
                            "lon": 55.07957409361269
                        },
                        {
                            "lat": 61.48100028395397,
                            "lon": 55.08136885761772
                        },
                        {
                            "lat": 61.48412881913928,
                            "lon": 55.08143442459249
                        },
                        {
                            "lat": 61.48725736705028,
                            "lon": 55.081499911262064
                        },
                        {
                            "lat": 61.48737144367571,
                            "lon": 55.07970513856311
                        }
                    ],
                    [
                        {
                            "lat": 61.49687038881521,
                            "lon": 55.07810631734239
                        },
                        {
                            "lat": 61.49374208239401,
                            "lon": 55.07804108024889
                        },
                        {
                            "lat": 61.49362829802919,
                            "lon": 55.07983586231131
                        },
                        {
                            "lat": 61.49675674422948,
                            "lon": 55.07990110373277
                        },
                        {
                            "lat": 61.49687038881521,
                            "lon": 55.07810631734239
                        }
                    ],
                    [
                        {
                            "lat": 61.5092706554919,
                            "lon": 55.080161266384685
                        },
                        {
                            "lat": 61.5123991648452,
                            "lon": 55.08022610628563
                        },
                        {
                            "lat": 61.512286206599605,
                            "lon": 55.08202091354771
                        },
                        {
                            "lat": 61.50915755744211,
                            "lon": 55.08195606934496
                        },
                        {
                            "lat": 61.50904444679084,
                            "lon": 55.08375087161633
                        },
                        {
                            "lat": 61.50591567041702,
                            "lon": 55.08368594279525
                        },
                        {
                            "lat": 61.5027869066621,
                            "lon": 55.08362093365875
                        },
                        {
                            "lat": 61.49965815554162,
                            "lon": 55.083555844207524
                        },
                        {
                            "lat": 61.499544612792704,
                            "lon": 55.08535063285039
                        },
                        {
                            "lat": 61.5026735037427,
                            "lon": 55.08541572662013
                        },
                        {
                            "lat": 61.50256008818695,
                            "lon": 55.087210518891816
                        },
                        {
                            "lat": 61.50568913162024,
                            "lon": 55.087275536655085
                        },
                        {
                            "lat": 61.50580240732904,
                            "lon": 55.08548074006983
                        },
                        {
                            "lat": 61.50893132353621,
                            "lon": 55.08554567319876
                        },
                        {
                            "lat": 61.508818187676276,
                            "lon": 55.087340474092265
                        },
                        {
                            "lat": 61.51194725633952,
                            "lon": 55.0874053312027
                        },
                        {
                            "lat": 61.51507633759443,
                            "lon": 55.08747010798564
                        },
                        {
                            "lat": 61.51820543142548,
                            "lon": 55.08753480444039
                        },
                        {
                            "lat": 61.52133453781711,
                            "lon": 55.08759942056627
                        },
                        {
                            "lat": 61.524463656753824,
                            "lon": 55.087663956362555
                        },
                        {
                            "lat": 61.52457609334078,
                            "lon": 55.08586913400748
                        },
                        {
                            "lat": 61.52468851740008,
                            "lon": 55.084074310965384
                        },
                        {
                            "lat": 61.52155967816467,
                            "lon": 55.084009783732085
                        },
                        {
                            "lat": 61.521672229525606,
                            "lon": 55.082214964283914
                        },
                        {
                            "lat": 61.5217847683472,
                            "lon": 55.080420144148334
                        },
                        {
                            "lat": 61.51865622128838,
                            "lon": 55.08035554516816
                        },
                        {
                            "lat": 61.51854354265689,
                            "lon": 55.08215036101787
                        },
                        {
                            "lat": 61.51541486834304,
                            "lon": 55.0820856774389
                        },
                        {
                            "lat": 61.51552768678254,
                            "lon": 55.08029086588035
                        },
                        {
                            "lat": 61.51564049265353,
                            "lon": 55.078496053633685
                        },
                        {
                            "lat": 61.51251211050677,
                            "lon": 55.07843129833505
                        },
                        {
                            "lat": 61.50938374094213,
                            "lon": 55.07836646273552
                        },
                        {
                            "lat": 61.506255383975116,
                            "lon": 55.07830154683577
                        },
                        {
                            "lat": 61.503127039621276,
                            "lon": 55.07823655063652
                        },
                        {
                            "lat": 61.50301367459947,
                            "lon": 55.08003134566693
                        },
                        {
                            "lat": 61.50290029694704,
                            "lon": 55.08182614000769
                        },
                        {
                            "lat": 61.50602892088607,
                            "lon": 55.08189114483138
                        },
                        {
                            "lat": 61.50614215873814,
                            "lon": 55.080096346178216
                        },
                        {
                            "lat": 61.5092706554919,
                            "lon": 55.080161266384685
                        }
                    ],
                    [
                        {
                            "lat": 61.51530203733312,
                            "lon": 55.083880488309305
                        },
                        {
                            "lat": 61.51518919375089,
                            "lon": 55.08567529849155
                        },
                        {
                            "lat": 61.512060252348675,
                            "lon": 55.08561052600624
                        },
                        {
                            "lat": 61.51217323576807,
                            "lon": 55.083815720121244
                        },
                        {
                            "lat": 61.51530203733312,
                            "lon": 55.083880488309305
                        }
                    ],
                    [
                        {
                            "lat": 61.485932470235674,
                            "lon": 55.15156108283048
                        },
                        {
                            "lat": 61.48279844564166,
                            "lon": 55.15149550694357
                        },
                        {
                            "lat": 61.48268385910151,
                            "lon": 55.153290251968514
                        },
                        {
                            "lat": 61.48581802410914,
                            "lon": 55.153355832209954
                        },
                        {
                            "lat": 61.485932470235674,
                            "lon": 55.15156108283048
                        }
                    ],
                    [
                        {
                            "lat": 61.4788610762344,
                            "lon": 55.16399302065607
                        },
                        {
                            "lat": 61.47572609403115,
                            "lon": 55.16392725317842
                        },
                        {
                            "lat": 61.47561113689664,
                            "lon": 55.16572198462789
                        },
                        {
                            "lat": 61.478746259619015,
                            "lon": 55.16578775647346
                        },
                        {
                            "lat": 61.47863143018676,
                            "lon": 55.16758249159826
                        },
                        {
                            "lat": 61.48176670626269,
                            "lon": 55.16764818725138
                        },
                        {
                            "lat": 61.48188139515827,
                            "lon": 55.16585344776362
                        },
                        {
                            "lat": 61.4819960712526,
                            "lon": 55.164058707583635
                        },
                        {
                            "lat": 61.4788610762344,
                            "lon": 55.16399302065607
                        }
                    ],
                    [
                        {
                            "lat": 61.514092029513726,
                            "lon": 55.053303914739594
                        },
                        {
                            "lat": 61.51397925982832,
                            "lon": 55.05509873232813
                        },
                        {
                            "lat": 61.51710582610953,
                            "lon": 55.05516343181238
                        },
                        {
                            "lat": 61.51721845622148,
                            "lon": 55.053368609933116
                        },
                        {
                            "lat": 61.514092029513726,
                            "lon": 55.053303914739594
                        }
                    ],
                    [
                        {
                            "lat": 61.53753951867617,
                            "lon": 55.07894709223975
                        },
                        {
                            "lat": 61.534411048889275,
                            "lon": 55.07888289906711
                        },
                        {
                            "lat": 61.53128259157581,
                            "lon": 55.07881862558865
                        },
                        {
                            "lat": 61.53117048468623,
                            "lon": 55.080613459236105
                        },
                        {
                            "lat": 61.534299081801656,
                            "lon": 55.08067773697876
                        },
                        {
                            "lat": 61.53742769139243,
                            "lon": 55.080741934410305
                        },
                        {
                            "lat": 61.53753951867617,
                            "lon": 55.07894709223975
                        }
                    ],
                    [
                        {
                            "lat": 61.52781736916121,
                            "lon": 55.08413875787904
                        },
                        {
                            "lat": 61.527705084946675,
                            "lon": 55.08593358519711
                        },
                        {
                            "lat": 61.53083408906468,
                            "lon": 55.08599795606106
                        },
                        {
                            "lat": 61.530946233432566,
                            "lon": 55.08420312447232
                        },
                        {
                            "lat": 61.53407511019853,
                            "lon": 55.08426741074456
                        },
                        {
                            "lat": 61.53720399944363,
                            "lon": 55.08433161669502
                        },
                        {
                            "lat": 61.5370921347748,
                            "lon": 55.086126456809154
                        },
                        {
                            "lat": 61.54022117633584,
                            "lon": 55.0861905866919
                        },
                        {
                            "lat": 61.54033290115234,
                            "lon": 55.08439574232303
                        },
                        {
                            "lat": 61.54346181530909,
                            "lon": 55.08445978762791
                        },
                        {
                            "lat": 61.54357338783878,
                            "lon": 55.08266493832491
                        },
                        {
                            "lat": 61.540444613520755,
                            "lon": 55.08260089726907
                        },
                        {
                            "lat": 61.53731585164888,
                            "lon": 55.08253677589541
                        },
                        {
                            "lat": 61.534187102238704,
                            "lon": 55.08247257420457
                        },
                        {
                            "lat": 61.53105836530576,
                            "lon": 55.08240829219732
                        },
                        {
                            "lat": 61.527929640865594,
                            "lon": 55.08234392987433
                        },
                        {
                            "lat": 61.52480092893369,
                            "lon": 55.082279487236285
                        },
                        {
                            "lat": 61.52468851740008,
                            "lon": 55.084074310965384
                        },
                        {
                            "lat": 61.52781736916121,
                            "lon": 55.08413875787904
                        }
                    ],
                    [
                        {
                            "lat": 61.50183023442879,
                            "lon": 55.14829821032459
                        },
                        {
                            "lat": 61.50171651578147,
                            "lon": 55.1500929827762
                        },
                        {
                            "lat": 61.504850476351876,
                            "lon": 55.15015807124522
                        },
                        {
                            "lat": 61.5049640546052,
                            "lon": 55.148363294471835
                        },
                        {
                            "lat": 61.50183023442879,
                            "lon": 55.14829821032459
                        }
                    ],
                    [
                        {
                            "lat": 61.587377526439475,
                            "lon": 55.08355307934609
                        },
                        {
                            "lat": 61.58726791184871,
                            "lon": 55.08534798757727
                        },
                        {
                            "lat": 61.59039701086183,
                            "lon": 55.0854108279519
                        },
                        {
                            "lat": 61.59050648558582,
                            "lon": 55.083615915551434
                        },
                        {
                            "lat": 61.587377526439475,
                            "lon": 55.08355307934609
                        }
                    ],
                    [
                        {
                            "lat": 61.549163030612,
                            "lon": 55.09356191593117
                        },
                        {
                            "lat": 61.55229268147458,
                            "lon": 55.09362574143348
                        },
                        {
                            "lat": 61.552403896425226,
                            "lon": 55.091830882833335
                        },
                        {
                            "lat": 61.55251509898245,
                            "lon": 55.0900360235496
                        },
                        {
                            "lat": 61.555644482407985,
                            "lon": 55.090099760240484
                        },
                        {
                            "lat": 61.5557555326808,
                            "lon": 55.088304896044214
                        },
                        {
                            "lat": 61.552626289148094,
                            "lon": 55.08824116358223
                        },
                        {
                            "lat": 61.55273746692407,
                            "lon": 55.086446302931236
                        },
                        {
                            "lat": 61.549608375656476,
                            "lon": 55.08638249436654
                        },
                        {
                            "lat": 61.546479296792235,
                            "lon": 55.086318605471284
                        },
                        {
                            "lat": 61.54335023034683,
                            "lon": 55.08625463624617
                        },
                        {
                            "lat": 61.54022117633584,
                            "lon": 55.0861905866919
                        },
                        {
                            "lat": 61.540109439069425,
                            "lon": 55.08798543037564
                        },
                        {
                            "lat": 61.5432386329502,
                            "lon": 55.088049484179706
                        },
                        {
                            "lat": 61.54636783926726,
                            "lon": 55.088113457649285
                        },
                        {
                            "lat": 61.546256369321526,
                            "lon": 55.08990830914291
                        },
                        {
                            "lat": 61.546144886953165,
                            "lon": 55.09170315995217
                        },
                        {
                            "lat": 61.54927438548472,
                            "lon": 55.091767061565996
                        },
                        {
                            "lat": 61.549163030612,
                            "lon": 55.09356191593117
                        }
                    ],
                    [
                        {
                            "lat": 61.53698025764048,
                            "lon": 55.0879212962378
                        },
                        {
                            "lat": 61.536868368038796,
                            "lon": 55.08971613498094
                        },
                        {
                            "lat": 61.53999768935116,
                            "lon": 55.08978027337427
                        },
                        {
                            "lat": 61.540109439069425,
                            "lon": 55.08798543037564
                        },
                        {
                            "lat": 61.53698025764048,
                            "lon": 55.0879212962378
                        }
                    ],
                    [
                        {
                            "lat": 61.53373905919568,
                            "lon": 55.089651916249224
                        },
                        {
                            "lat": 61.533627017227644,
                            "lon": 55.09144675004566
                        },
                        {
                            "lat": 61.536756465967834,
                            "lon": 55.09151097303855
                        },
                        {
                            "lat": 61.536868368038796,
                            "lon": 55.08971613498094
                        },
                        {
                            "lat": 61.53373905919568,
                            "lon": 55.089651916249224
                        }
                    ],
                    [
                        {
                            "lat": 61.55855202037016,
                            "lon": 55.09375315137986
                        },
                        {
                            "lat": 61.561681708372035,
                            "lon": 55.09381673582257
                        },
                        {
                            "lat": 61.564811408722605,
                            "lon": 55.09388023991066
                        },
                        {
                            "lat": 61.56794112140628,
                            "lon": 55.09394366364347
                        },
                        {
                            "lat": 61.5680516367184,
                            "lon": 55.09214878394829
                        },
                        {
                            "lat": 61.56492206396619,
                            "lon": 55.092085364423845
                        },
                        {
                            "lat": 61.56179250354521,
                            "lon": 55.09202186454944
                        },
                        {
                            "lat": 61.56190328637157,
                            "lon": 55.09022699259383
                        },
                        {
                            "lat": 61.55877387820955,
                            "lon": 55.09016341658879
                        },
                        {
                            "lat": 61.55866295547103,
                            "lon": 55.09195828432577
                        },
                        {
                            "lat": 61.555533419759186,
                            "lon": 55.09189462375349
                        },
                        {
                            "lat": 61.5554223447325,
                            "lon": 55.09368948658327
                        },
                        {
                            "lat": 61.55855202037016,
                            "lon": 55.09375315137986
                        }
                    ],
                    [
                        {
                            "lat": 61.53977415255159,
                            "lon": 55.09336995731608
                        },
                        {
                            "lat": 61.54290376613494,
                            "lon": 55.09343402387177
                        },
                        {
                            "lat": 61.54301540084614,
                            "lon": 55.09163917799252
                        },
                        {
                            "lat": 61.539885927179185,
                            "lon": 55.09157511568775
                        },
                        {
                            "lat": 61.536756465967834,
                            "lon": 55.09151097303855
                        },
                        {
                            "lat": 61.53664455142572,
                            "lon": 55.093305810410655
                        },
                        {
                            "lat": 61.53977415255159,
                            "lon": 55.09336995731608
                        }
                    ],
                    [
                        {
                            "lat": 61.57375951905288,
                            "lon": 55.10124981561428
                        },
                        {
                            "lat": 61.57386980365358,
                            "lon": 55.099454930242395
                        },
                        {
                            "lat": 61.570739646451464,
                            "lon": 55.099391654626736
                        },
                        {
                            "lat": 61.567609501556944,
                            "lon": 55.09932829863847
                        },
                        {
                            "lat": 61.56749893696331,
                            "lon": 55.101123175606546
                        },
                        {
                            "lat": 61.57062922185334,
                            "lon": 55.10118653579939
                        },
                        {
                            "lat": 61.57375951905288,
                            "lon": 55.10124981561428
                        }
                    ],
                    [
                        {
                            "lat": 61.56414721819998,
                            "lon": 55.10464947850632
                        },
                        {
                            "lat": 61.56403647658522,
                            "lon": 55.10644434921796
                        },
                        {
                            "lat": 61.56716716922456,
                            "lon": 55.10650780241994
                        },
                        {
                            "lat": 61.567277770798974,
                            "lon": 55.1047129274973
                        },
                        {
                            "lat": 61.56738836004462,
                            "lon": 55.10291805189283
                        },
                        {
                            "lat": 61.56749893696331,
                            "lon": 55.101123175606546
                        },
                        {
                            "lat": 61.564368664398316,
                            "lon": 55.10105973503646
                        },
                        {
                            "lat": 61.56425794747038,
                            "lon": 55.10285460711247
                        },
                        {
                            "lat": 61.56414721819998,
                            "lon": 55.10464947850632
                        }
                    ],
                    [
                        {
                            "lat": 61.545140986307814,
                            "lon": 55.107856786436614
                        },
                        {
                            "lat": 61.545029379609254,
                            "lon": 55.10965163040149
                        },
                        {
                            "lat": 61.54816027804255,
                            "lon": 55.10971557443524
                        },
                        {
                            "lat": 61.54827174468091,
                            "lon": 55.10792072622668
                        },
                        {
                            "lat": 61.545140986307814,
                            "lon": 55.107856786436614
                        }
                    ],
                    [
                        {
                            "lat": 61.53865572080065,
                            "lon": 55.111318335913865
                        },
                        {
                            "lat": 61.53854380903644,
                            "lon": 55.113113170004844
                        },
                        {
                            "lat": 61.54167496272687,
                            "lon": 55.113177283345735
                        },
                        {
                            "lat": 61.541786734403566,
                            "lon": 55.11138244499968
                        },
                        {
                            "lat": 61.54189849361938,
                            "lon": 55.109587605968706
                        },
                        {
                            "lat": 61.53876762008844,
                            "lon": 55.10952350113762
                        },
                        {
                            "lat": 61.53865572080065,
                            "lon": 55.111318335913865
                        }
                    ],
                    [
                        {
                            "lat": 61.60113522330301,
                            "lon": 55.16649511622589
                        },
                        {
                            "lat": 61.601025888430364,
                            "lon": 55.1682900182297
                        },
                        {
                            "lat": 61.60416151162749,
                            "lon": 55.168352567308666
                        },
                        {
                            "lat": 61.6042707059049,
                            "lon": 55.16655766115042
                        },
                        {
                            "lat": 61.60113522330301,
                            "lon": 55.16649511622589
                        }
                    ],
                    [
                        {
                            "lat": 61.688827894908506,
                            "lon": 55.17001092971127
                        },
                        {
                            "lat": 61.6887224856277,
                            "lon": 55.171805945352986
                        },
                        {
                            "lat": 61.691858584920375,
                            "lon": 55.17186624184811
                        },
                        {
                            "lat": 61.691963853539235,
                            "lon": 55.170071222201116
                        },
                        {
                            "lat": 61.688827894908506,
                            "lon": 55.17001092971127
                        }
                    ]
                ]
            },
            {
                "type": "MultiLineString",
                "value": 0.28,
                "data": [
                    [
                        {
                            "lat": 61.34636515113578,
                            "lon": 55.08026809048884
                        },
                        {
                            "lat": 61.346244759138834,
                            "lon": 55.08206266101644
                        },
                        {
                            "lat": 61.34311651980189,
                            "lon": 55.081993552163915
                        },
                        {
                            "lat": 61.342995974646314,
                            "lon": 55.08378811739744
                        },
                        {
                            "lat": 61.33986760899322,
                            "lon": 55.083718923672215
                        },
                        {
                            "lat": 61.339746910647996,
                            "lon": 55.085513483605524
                        },
                        {
                            "lat": 61.339626198855186,
                            "lon": 55.08730804282842
                        },
                        {
                            "lat": 61.339505473612775,
                            "lon": 55.089102601340926
                        },
                        {
                            "lat": 61.3393847349187,
                            "lon": 55.09089715914297
                        },
                        {
                            "lat": 61.33926398277093,
                            "lon": 55.09269171623458
                        },
                        {
                            "lat": 61.339143217167404,
                            "lon": 55.094486272615754
                        },
                        {
                            "lat": 61.339022438106106,
                            "lon": 55.096280828286396
                        },
                        {
                            "lat": 61.338901645584976,
                            "lon": 55.09807538324657
                        },
                        {
                            "lat": 61.33878083960198,
                            "lon": 55.09986993749626
                        },
                        {
                            "lat": 61.33866002015507,
                            "lon": 55.10166449103541
                        },
                        {
                            "lat": 61.338539187242205,
                            "lon": 55.10345904386402
                        },
                        {
                            "lat": 61.33841834086134,
                            "lon": 55.10525359598207
                        },
                        {
                            "lat": 61.338297481010436,
                            "lon": 55.107048147389555
                        },
                        {
                            "lat": 61.34142766474969,
                            "lon": 55.10711740081994
                        },
                        {
                            "lat": 61.34154838465462,
                            "lon": 55.105322844817294
                        },
                        {
                            "lat": 61.341669091105054,
                            "lon": 55.10352828810447
                        },
                        {
                            "lat": 61.344799008420345,
                            "lon": 55.103597451998304
                        },
                        {
                            "lat": 61.34491956150137,
                            "lon": 55.10180288998632
                        },
                        {
                            "lat": 61.341789784103014,
                            "lon": 55.10173373068152
                        },
                        {
                            "lat": 61.34191046365056,
                            "lon": 55.09993917254843
                        },
                        {
                            "lat": 61.34203112974974,
                            "lon": 55.098144613705244
                        },
                        {
                            "lat": 61.34516062736082,
                            "lon": 55.0982137638332
                        },
                        {
                            "lat": 61.345281140143314,
                            "lon": 55.09641919969212
                        },
                        {
                            "lat": 61.34540163949704,
                            "lon": 55.094624634841374
                        },
                        {
                            "lat": 61.3422724216111,
                            "lon": 55.0945554938886
                        },
                        {
                            "lat": 61.34239304737738,
                            "lon": 55.092760932915134
                        },
                        {
                            "lat": 61.34552212542405,
                            "lon": 55.09283006928094
                        },
                        {
                            "lat": 61.34865121689539,
                            "lon": 55.092899125331236
                        },
                        {
                            "lat": 61.348771549571914,
                            "lon": 55.09110455447993
                        },
                        {
                            "lat": 61.35190051462461,
                            "lon": 55.091173525638034
                        },
                        {
                            "lat": 61.351780321775905,
                            "lon": 55.09296810106531
                        },
                        {
                            "lat": 61.35490944005005,
                            "lon": 55.09303699648237
                        },
                        {
                            "lat": 61.35502949306888,
                            "lon": 55.09124241648446
                        },
                        {
                            "lat": 61.35815848488926,
                            "lon": 55.09131122701845
                        },
                        {
                            "lat": 61.358038571702316,
                            "lon": 55.09310581158168
                        },
                        {
                            "lat": 61.36116771671714,
                            "lon": 55.0931745463625
                        },
                        {
                            "lat": 61.36429687507903,
                            "lon": 55.093243200824084
                        },
                        {
                            "lat": 61.36742604677245,
                            "lon": 55.09331177496567
                        },
                        {
                            "lat": 61.370555231781836,
                            "lon": 55.09338026878653
                        },
                        {
                            "lat": 61.37043586464221,
                            "lon": 55.09517487085153
                        },
                        {
                            "lat": 61.3703164842001,
                            "lon": 55.096969472210105
                        },
                        {
                            "lat": 61.37344596224057,
                            "lon": 55.09703789478821
                        },
                        {
                            "lat": 61.37332670838252,
                            "lon": 55.09883249998035
                        },
                        {
                            "lat": 61.376456339602576,
                            "lon": 55.09890084676026
                        },
                        {
                            "lat": 61.37633721235973,
                            "lon": 55.10069545578145
                        },
                        {
                            "lat": 61.379466996763384,
                            "lon": 55.1007637267522
                        },
                        {
                            "lat": 61.382596794428956,
                            "lon": 55.100831917377995
                        },
                        {
                            "lat": 61.38271564185349,
                            "lon": 55.099037299302644
                        },
                        {
                            "lat": 61.38584531285327,
                            "lon": 55.09910540506362
                        },
                        {
                            "lat": 61.38596400713675,
                            "lon": 55.09731078176476
                        },
                        {
                            "lat": 61.38909355146671,
                            "lon": 55.09737880267121
                        },
                        {
                            "lat": 61.39222310900795,
                            "lon": 55.09744674324111
                        },
                        {
                            "lat": 61.392104694523795,
                            "lon": 55.09924137556144
                        },
                        {
                            "lat": 61.395234405163464,
                            "lon": 55.09930924029682
                        },
                        {
                            "lat": 61.39836412898535,
                            "lon": 55.099377024688856
                        },
                        {
                            "lat": 61.39848226366218,
                            "lon": 55.0975823833683
                        },
                        {
                            "lat": 61.40161186074408,
                            "lon": 55.09765008292412
                        },
                        {
                            "lat": 61.4017298423651,
                            "lon": 55.09585543640907
                        },
                        {
                            "lat": 61.40485931270302,
                            "lon": 55.09592305113896
                        },
                        {
                            "lat": 61.407988796172546,
                            "lon": 55.09599058553395
                        },
                        {
                            "lat": 61.40787109433977,
                            "lon": 55.097785241016524
                        },
                        {
                            "lat": 61.41100073082248,
                            "lon": 55.097852699551645
                        },
                        {
                            "lat": 61.41413038040771,
                            "lon": 55.097920077745066
                        },
                        {
                            "lat": 61.41726004307994,
                            "lon": 55.09798737559605
                        },
                        {
                            "lat": 61.41714274787299,
                            "lon": 55.099782043789645
                        },
                        {
                            "lat": 61.41702543959262,
                            "lon": 55.10157671128287
                        },
                        {
                            "lat": 61.41389549707162,
                            "lon": 55.10150940450038
                        },
                        {
                            "lat": 61.41401294528414,
                            "lon": 55.09971474147313
                        },
                        {
                            "lat": 61.41088315578427,
                            "lon": 55.09964735880884
                        },
                        {
                            "lat": 61.41076556764156,
                            "lon": 55.10144201736482
                        },
                        {
                            "lat": 61.41064796639234,
                            "lon": 55.103236675219605
                        },
                        {
                            "lat": 61.413778035768125,
                            "lon": 55.103304066826844
                        },
                        {
                            "lat": 61.41366056137167,
                            "lon": 55.10509872845247
                        },
                        {
                            "lat": 61.41679078380364,
                            "lon": 55.105166044168044
                        },
                        {
                            "lat": 61.41690811823684,
                            "lon": 55.103371378075664
                        },
                        {
                            "lat": 61.42003821378295,
                            "lon": 55.103438608965405
                        },
                        {
                            "lat": 61.42015539518904,
                            "lon": 55.10164393771156
                        },
                        {
                            "lat": 61.42027256353528,
                            "lon": 55.0998492657577
                        },
                        {
                            "lat": 61.42340239225543,
                            "lon": 55.099916407376554
                        },
                        {
                            "lat": 61.42653223401792,
                            "lon": 55.09998346864544
                        },
                        {
                            "lat": 61.42641534554595,
                            "lon": 55.10177814950462
                        },
                        {
                            "lat": 61.42954534027536,
                            "lon": 55.10184513486754
                        },
                        {
                            "lat": 61.429428578730246,
                            "lon": 55.10363981947256
                        },
                        {
                            "lat": 61.432558726430464,
                            "lon": 55.10370672891852
                        },
                        {
                            "lat": 61.43267534801799,
                            "lon": 55.101912039873724
                        },
                        {
                            "lat": 61.4327919566078,
                            "lon": 55.10011735013051
                        },
                        {
                            "lat": 61.43592183740407,
                            "lon": 55.10018417034521
                        },
                        {
                            "lat": 61.43580536875829,
                            "lon": 55.10197886452247
                        },
                        {
                            "lat": 61.43893540248076,
                            "lon": 55.10204560881307
                        },
                        {
                            "lat": 61.43905173118051,
                            "lon": 55.10025091020706
                        },
                        {
                            "lat": 61.44218163792157,
                            "lon": 55.10031756971533
                        },
                        {
                            "lat": 61.44531155761171,
                            "lon": 55.10038414886932
                        },
                        {
                            "lat": 61.44519550880986,
                            "lon": 55.10217885631682
                        },
                        {
                            "lat": 61.44832558138543,
                            "lon": 55.102245359528546
                        },
                        {
                            "lat": 61.44820965961555,
                            "lon": 55.104040070692385
                        },
                        {
                            "lat": 61.45133988508049,
                            "lon": 55.104106497950895
                        },
                        {
                            "lat": 61.45145566688097,
                            "lon": 55.1023117823792
                        },
                        {
                            "lat": 61.454585765280875,
                            "lon": 55.10237812486809
                        },
                        {
                            "lat": 61.45771587656963,
                            "lon": 55.102444386994435
                        },
                        {
                            "lat": 61.45783136555208,
                            "lon": 55.100649661927726
                        },
                        {
                            "lat": 61.46096134975442,
                            "lon": 55.100715839299355
                        },
                        {
                            "lat": 61.460846000731685,
                            "lon": 55.10251056875758
                        },
                        {
                            "lat": 61.46397613775151,
                            "lon": 55.10257667015675
                        },
                        {
                            "lat": 61.464091346812516,
                            "lon": 55.10078193631238
                        },
                        {
                            "lat": 61.46722135671089,
                            "lon": 55.10084795296599
                        },
                        {
                            "lat": 61.46710628761349,
                            "lon": 55.10264269119128
                        },
                        {
                            "lat": 61.47023645030212,
                            "lon": 55.10270863186042
                        },
                        {
                            "lat": 61.47012150835924,
                            "lon": 55.10450337376754
                        },
                        {
                            "lat": 61.473251823842055,
                            "lon": 55.104569238441194
                        },
                        {
                            "lat": 61.47313700908475,
                            "lon": 55.10636398402563
                        },
                        {
                            "lat": 61.470006553603305,
                            "lon": 55.106298114980945
                        },
                        {
                            "lat": 61.46989158603238,
                            "lon": 55.10809285550062
                        },
                        {
                            "lat": 61.46977660564454,
                            "lon": 55.10988759532657
                        },
                        {
                            "lat": 61.47290734116993,
                            "lon": 55.109953473114444
                        },
                        {
                            "lat": 61.47302218152803,
                            "lon": 55.108158728916706
                        },
                        {
                            "lat": 61.47615278982505,
                            "lon": 55.10822452195001
                        },
                        {
                            "lat": 61.479283410907875,
                            "lon": 55.1082902345998
                        },
                        {
                            "lat": 61.48241404476096,
                            "lon": 55.10835586686541
                        },
                        {
                            "lat": 61.48229962450378,
                            "lon": 55.1101506241466
                        },
                        {
                            "lat": 61.48543041114907,
                            "lon": 55.110216180377805
                        },
                        {
                            "lat": 61.48554469136873,
                            "lon": 55.1084214187461
                        },
                        {
                            "lat": 61.488675350715624,
                            "lon": 55.10848689024114
                        },
                        {
                            "lat": 61.48856121053541,
                            "lon": 55.11028165621804
                        },
                        {
                            "lat": 61.49169202264727,
                            "lon": 55.110347051666615
                        },
                        {
                            "lat": 61.49482284746905,
                            "lon": 55.11041236672281
                        },
                        {
                            "lat": 61.4979536849852,
                            "lon": 55.11047760138591
                        },
                        {
                            "lat": 61.50108453518017,
                            "lon": 55.11054275565522
                        },
                        {
                            "lat": 61.50421539803837,
                            "lon": 55.11060782953004
                        },
                        {
                            "lat": 61.50734627354426,
                            "lon": 55.11067282300963
                        },
                        {
                            "lat": 61.50723296100914,
                            "lon": 55.11246761425683
                        },
                        {
                            "lat": 61.50711963583998,
                            "lon": 55.11426240481493
                        },
                        {
                            "lat": 61.51025080413054,
                            "lon": 55.11432732651565
                        },
                        {
                            "lat": 61.51338198504146,
                            "lon": 55.1143921678091
                        },
                        {
                            "lat": 61.5165131785572,
                            "lon": 55.11445692869457
                        },
                        {
                            "lat": 61.51640026105674,
                            "lon": 55.116251731474875
                        },
                        {
                            "lat": 61.51953160726709,
                            "lon": 55.11631641624479
                        },
                        {
                            "lat": 61.52266296605301,
                            "lon": 55.11638102060001
                        },
                        {
                            "lat": 61.52255031620523,
                            "lon": 55.118175831274016
                        },
                        {
                            "lat": 61.52568182767592,
                            "lon": 55.11824035949668
                        },
                        {
                            "lat": 61.52881335169294,
                            "lon": 55.11830480729793
                        },
                        {
                            "lat": 61.5319448882407,
                            "lon": 55.11836917467702
                        },
                        {
                            "lat": 61.535076437303644,
                            "lon": 55.118433461633295
                        },
                        {
                            "lat": 61.53496433546675,
                            "lon": 55.12022828872111
                        },
                        {
                            "lat": 61.53809603717724,
                            "lon": 55.12029249951577
                        },
                        {
                            "lat": 61.54122775137365,
                            "lon": 55.120356629880895
                        },
                        {
                            "lat": 61.54133957291272,
                            "lon": 55.11856179427451
                        },
                        {
                            "lat": 61.54145138198336,
                            "lon": 55.116766957983174
                        },
                        {
                            "lat": 61.53831994807107,
                            "lon": 55.11670283613092
                        },
                        {
                            "lat": 61.53518852664092,
                            "lon": 55.116638633859765
                        },
                        {
                            "lat": 61.53205711770847,
                            "lon": 55.11657435117039
                        },
                        {
                            "lat": 61.53216933466295,
                            "lon": 55.11477952697769
                        },
                        {
                            "lat": 61.52903807835677,
                            "lon": 55.11471516814265
                        },
                        {
                            "lat": 61.529150422897324,
                            "lon": 55.112920347535336
                        },
                        {
                            "lat": 61.52601931921361,
                            "lon": 55.112855912565585
                        },
                        {
                            "lat": 61.52590683457753,
                            "lon": 55.1146507288961
                        },
                        {
                            "lat": 61.5227756033408,
                            "lon": 55.11458620923877
                        },
                        {
                            "lat": 61.522888228070514,
                            "lon": 55.11279139719035
                        },
                        {
                            "lat": 61.51975714948359,
                            "lon": 55.11272680141036
                        },
                        {
                            "lat": 61.516626083468424,
                            "lon": 55.112662125226315
                        },
                        {
                            "lat": 61.516738975792315,
                            "lon": 55.110867321070096
                        },
                        {
                            "lat": 61.516851855530795,
                            "lon": 55.109072516225936
                        },
                        {
                            "lat": 61.51372108223203,
                            "lon": 55.1090077682335
                        },
                        {
                            "lat": 61.510590321532334,
                            "lon": 55.10894293984907
                        },
                        {
                            "lat": 61.51070346876778,
                            "lon": 55.107148142916124
                        },
                        {
                            "lat": 61.50757286072006,
                            "lon": 55.10708323844794
                        },
                        {
                            "lat": 61.507459573447264,
                            "lon": 55.10887803107333
                        },
                        {
                            "lat": 61.50432883799239,
                            "lon": 55.10881304190699
                        },
                        {
                            "lat": 61.504442265300646,
                            "lon": 55.10701825359449
                        },
                        {
                            "lat": 61.50131168252505,
                            "lon": 55.106953188356464
                        },
                        {
                            "lat": 61.49818111240886,
                            "lon": 55.10688804273458
                        },
                        {
                            "lat": 61.49829480710729,
                            "lon": 55.10509326237355
                        },
                        {
                            "lat": 61.49516438968018,
                            "lon": 55.105028040696965
                        },
                        {
                            "lat": 61.49203398494164,
                            "lon": 55.10496273864326
                        },
                        {
                            "lat": 61.48890359290726,
                            "lon": 55.10489735621312
                        },
                        {
                            "lat": 61.48901769492256,
                            "lon": 55.103102588162045
                        },
                        {
                            "lat": 61.485887455600654,
                            "lon": 55.10303712970025
                        },
                        {
                            "lat": 61.48577321359259,
                            "lon": 55.1048318934073
                        },
                        {
                            "lat": 61.48264284701318,
                            "lon": 55.10476635022649
                        },
                        {
                            "lat": 61.48275722901209,
                            "lon": 55.10297159086877
                        },
                        {
                            "lat": 61.4796270151724,
                            "lon": 55.10290597166832
                        },
                        {
                            "lat": 61.476496814097125,
                            "lon": 55.10284027209965
                        },
                        {
                            "lat": 61.47661146329191,
                            "lon": 55.10104552076368
                        },
                        {
                            "lat": 61.47672609970867,
                            "lon": 55.09925076873479
                        },
                        {
                            "lat": 61.476840723349305,
                            "lon": 55.097456016013005
                        },
                        {
                            "lat": 61.4737109549158,
                            "lon": 55.09739024917038
                        },
                        {
                            "lat": 61.47058119927202,
                            "lon": 55.09732440197688
                        },
                        {
                            "lat": 61.47046629575658,
                            "lon": 55.09911914596506
                        },
                        {
                            "lat": 61.46733641298357,
                            "lon": 55.099053214046656
                        },
                        {
                            "lat": 61.4642065430333,
                            "lon": 55.09898720177347
                        },
                        {
                            "lat": 61.46432172641578,
                            "lon": 55.09719246654014
                        },
                        {
                            "lat": 61.46443689696191,
                            "lon": 55.09539773061235
                        },
                        {
                            "lat": 61.461307319695585,
                            "lon": 55.09533164675556
                        },
                        {
                            "lat": 61.45817775527921,
                            "lon": 55.09526548255611
                        },
                        {
                            "lat": 61.45504820372831,
                            "lon": 55.095199238014686
                        },
                        {
                            "lat": 61.455163781132704,
                            "lon": 55.09340451456229
                        },
                        {
                            "lat": 61.45203438235649,
                            "lon": 55.093338194080204
                        },
                        {
                            "lat": 61.451918665058436,
                            "lon": 55.095132913132026
                        },
                        {
                            "lat": 61.44878913928512,
                            "lon": 55.09506650790885
                        },
                        {
                            "lat": 61.448904996474866,
                            "lon": 55.0932717932629
                        },
                        {
                            "lat": 61.44577562350339,
                            "lon": 55.093205312111124
                        },
                        {
                            "lat": 61.44264626345758,
                            "lon": 55.093138750625585
                        },
                        {
                            "lat": 61.43951691635301,
                            "lon": 55.09307210880703
                        },
                        {
                            "lat": 61.43940063949994,
                            "lon": 55.09486681020342
                        },
                        {
                            "lat": 61.43627116546826,
                            "lon": 55.09480008362539
                        },
                        {
                            "lat": 61.43638758220517,
                            "lon": 55.09300538665614
                        },
                        {
                            "lat": 61.43325826102964,
                            "lon": 55.09293858417368
                        },
                        {
                            "lat": 61.43314170441085,
                            "lon": 55.09473327671044
                        },
                        {
                            "lat": 61.43001225634322,
                            "lon": 55.09466638945934
                        },
                        {
                            "lat": 61.43012895284194,
                            "lon": 55.092871701360345
                        },
                        {
                            "lat": 61.43024563633726,
                            "lon": 55.091077012562586
                        },
                        {
                            "lat": 61.427116481015304,
                            "lon": 55.09101005386183
                        },
                        {
                            "lat": 61.42398733871024,
                            "lon": 55.09094301483697
                        },
                        {
                            "lat": 61.42085820943765,
                            "lon": 55.09087589548875
                        },
                        {
                            "lat": 61.41772909321303,
                            "lon": 55.0908086958179
                        },
                        {
                            "lat": 61.41761185027999,
                            "lon": 55.092603366812966
                        },
                        {
                            "lat": 61.42074110636107,
                            "lon": 55.09267057094247
                        },
                        {
                            "lat": 61.42062399023451,
                            "lon": 55.09446524569623
                        },
                        {
                            "lat": 61.41749459428145,
                            "lon": 55.09439803710769
                        },
                        {
                            "lat": 61.41436521139586,
                            "lon": 55.094330748186614
                        },
                        {
                            "lat": 61.41123584159329,
                            "lon": 55.09426337893373
                        },
                        {
                            "lat": 61.40810648488924,
                            "lon": 55.09419592934978
                        },
                        {
                            "lat": 61.40822416049187,
                            "lon": 55.09240127246408
                        },
                        {
                            "lat": 61.40834182298238,
                            "lon": 55.09060661487684
                        },
                        {
                            "lat": 61.41147089996988,
                            "lon": 55.09067405551121
                        },
                        {
                            "lat": 61.411588409515296,
                            "lon": 55.08887939274823
                        },
                        {
                            "lat": 61.4084594723628,
                            "lon": 55.08881195658804
                        },
                        {
                            "lat": 61.40533054831841,
                            "lon": 55.088744440113466
                        },
                        {
                            "lat": 61.40544832440814,
                            "lon": 55.08694978560228
                        },
                        {
                            "lat": 61.40231955330286,
                            "lon": 55.08688219329845
                        },
                        {
                            "lat": 61.40220163739763,
                            "lon": 55.088676843325224
                        },
                        {
                            "lat": 61.39907273961605,
                            "lon": 55.088609166224046
                        },
                        {
                            "lat": 61.399190795334746,
                            "lon": 55.08681452068701
                        },
                        {
                            "lat": 61.39606205051934,
                            "lon": 55.08674676776869
                        },
                        {
                            "lat": 61.39618023288129,
                            "lon": 55.08495212602358
                        },
                        {
                            "lat": 61.39305164102805,
                            "lon": 55.08488429729906
                        },
                        {
                            "lat": 61.39293331887214,
                            "lon": 55.08667893454421
                        },
                        {
                            "lat": 61.392814983532496,
                            "lon": 55.088473571085856
                        },
                        {
                            "lat": 61.389686125261555,
                            "lon": 55.08840565305031
                        },
                        {
                            "lat": 61.38980460040869,
                            "lon": 55.08661102101433
                        },
                        {
                            "lat": 61.3866758951445,
                            "lon": 55.08654302717978
                        },
                        {
                            "lat": 61.38354720309511,
                            "lon": 55.08647495304131
                        },
                        {
                            "lat": 61.38041852427603,
                            "lon": 55.08640679859963
                        },
                        {
                            "lat": 61.38053740558774,
                            "lon": 55.08461217940741
                        },
                        {
                            "lat": 61.37740887979841,
                            "lon": 55.08454394918979
                        },
                        {
                            "lat": 61.37428036726843,
                            "lon": 55.084475638675784
                        },
                        {
                            "lat": 61.37439951487113,
                            "lon": 55.082681027835974
                        },
                        {
                            "lat": 61.37752788763471,
                            "lon": 55.082749333818576
                        },
                        {
                            "lat": 61.377646882213675,
                            "lon": 55.08095471774187
                        },
                        {
                            "lat": 61.37451864920098,
                            "lon": 55.080886416290284
                        },
                        {
                            "lat": 61.371390429459126,
                            "lon": 55.08081803455368
                        },
                        {
                            "lat": 61.36826222300362,
                            "lon": 55.080749572532795
                        },
                        {
                            "lat": 61.368381623527334,
                            "lon": 55.078954969358364
                        },
                        {
                            "lat": 61.36525357010301,
                            "lon": 55.078886431600445
                        },
                        {
                            "lat": 61.36212552999404,
                            "lon": 55.07881781356504
                        },
                        {
                            "lat": 61.35899750321592,
                            "lon": 55.07874911525289
                        },
                        {
                            "lat": 61.3588776835103,
                            "lon": 55.08054370477177
                        },
                        {
                            "lat": 61.35574953035529,
                            "lon": 55.08047492162113
                        },
                        {
                            "lat": 61.35586948978419,
                            "lon": 55.07868033666472
                        },
                        {
                            "lat": 61.35598943585119,
                            "lon": 55.076885751000006
                        },
                        {
                            "lat": 61.35286157548706,
                            "lon": 55.076816896703974
                        },
                        {
                            "lat": 61.34973372849828,
                            "lon": 55.07674796213872
                        },
                        {
                            "lat": 61.34961350302184,
                            "lon": 55.078542538663385
                        },
                        {
                            "lat": 61.34648552972226,
                            "lon": 55.078473519251695
                        },
                        {
                            "lat": 61.34636515113578,
                            "lon": 55.08026809048884
                        }
                    ],
                    [
                        {
                            "lat": 61.37067458562101,
                            "lon": 55.091585666015156
                        },
                        {
                            "lat": 61.37380364408889,
                            "lon": 55.09165407497579
                        },
                        {
                            "lat": 61.373684430091714,
                            "lon": 55.09344868228592
                        },
                        {
                            "lat": 61.370555231781836,
                            "lon": 55.09338026878653
                        },
                        {
                            "lat": 61.37067458562101,
                            "lon": 55.091585666015156
                        }
                    ],
                    [
                        {
                            "lat": 61.38283447603363,
                            "lon": 55.097242680522484
                        },
                        {
                            "lat": 61.38295329697139,
                            "lon": 55.09544806103754
                        },
                        {
                            "lat": 61.386082688193426,
                            "lon": 55.09551615776149
                        },
                        {
                            "lat": 61.38596400713675,
                            "lon": 55.09731078176476
                        },
                        {
                            "lat": 61.38283447603363,
                            "lon": 55.097242680522484
                        }
                    ],
                    [
                        {
                            "lat": 61.39535267974497,
                            "lon": 55.097514603473705
                        },
                        {
                            "lat": 61.39547094114624,
                            "lon": 55.09571996594744
                        },
                        {
                            "lat": 61.398600385174326,
                            "lon": 55.09578774134497
                        },
                        {
                            "lat": 61.39848226366218,
                            "lon": 55.0975823833683
                        },
                        {
                            "lat": 61.39535267974497,
                            "lon": 55.097514603473705
                        }
                    ],
                    [
                        {
                            "lat": 61.4735961913368,
                            "lon": 55.09918499752801
                        },
                        {
                            "lat": 61.47348141496612,
                            "lon": 55.10097974519238
                        },
                        {
                            "lat": 61.47035137943392,
                            "lon": 55.10091388925958
                        },
                        {
                            "lat": 61.47046629575658,
                            "lon": 55.09911914596506
                        },
                        {
                            "lat": 61.4735961913368,
                            "lon": 55.09918499752801
                        }
                    ],
                    [
                        {
                            "lat": 61.35274148971432,
                            "lon": 55.0786114778013
                        },
                        {
                            "lat": 61.352621390564195,
                            "lon": 55.080406058189936
                        },
                        {
                            "lat": 61.352501278034666,
                            "lon": 55.08220063786983
                        },
                        {
                            "lat": 61.34937301188827,
                            "lon": 55.08213168958534
                        },
                        {
                            "lat": 61.349493264152514,
                            "lon": 55.080337114478915
                        },
                        {
                            "lat": 61.34961350302184,
                            "lon": 55.078542538663385
                        },
                        {
                            "lat": 61.35274148971432,
                            "lon": 55.0786114778013
                        }
                    ],
                    [
                        {
                            "lat": 61.3556295575625,
                            "lon": 55.08226950586922
                        },
                        {
                            "lat": 61.35875785045626,
                            "lon": 55.082338293582715
                        },
                        {
                            "lat": 61.358638004051805,
                            "lon": 55.08413288168578
                        },
                        {
                            "lat": 61.355509571403765,
                            "lon": 55.08406408940898
                        },
                        {
                            "lat": 61.35538957187706,
                            "lon": 55.0858586722404
                        },
                        {
                            "lat": 61.352261012829196,
                            "lon": 55.08578979510344
                        },
                        {
                            "lat": 61.34913246716685,
                            "lon": 55.08572083767071
                        },
                        {
                            "lat": 61.349252746227066,
                            "lon": 55.08392626398259
                        },
                        {
                            "lat": 61.35238115212367,
                            "lon": 55.08399521684101
                        },
                        {
                            "lat": 61.352501278034666,
                            "lon": 55.08220063786983
                        },
                        {
                            "lat": 61.3556295575625,
                            "lon": 55.08226950586922
                        }
                    ],
                    [
                        {
                            "lat": 61.34865121689539,
                            "lon": 55.092899125331236
                        },
                        {
                            "lat": 61.348530870809704,
                            "lon": 55.094693695473346
                        },
                        {
                            "lat": 61.35166011553357,
                            "lon": 55.09476267578375
                        },
                        {
                            "lat": 61.351780321775905,
                            "lon": 55.09296810106531
                        },
                        {
                            "lat": 61.34865121689539,
                            "lon": 55.092899125331236
                        }
                    ],
                    [
                        {
                            "lat": 61.337813906865414,
                            "lon": 55.11422634591353
                        },
                        {
                            "lat": 61.340944650544074,
                            "lon": 55.11429561772867
                        },
                        {
                            "lat": 61.34106542428744,
                            "lon": 55.11250106456681
                        },
                        {
                            "lat": 61.341186184568144,
                            "lon": 55.110706510694705
                        },
                        {
                            "lat": 61.338055720890296,
                            "lon": 55.11063724807277
                        },
                        {
                            "lat": 61.33793482061697,
                            "lon": 55.11243179734847
                        },
                        {
                            "lat": 61.337813906865414,
                            "lon": 55.11422634591353
                        }
                    ],
                    [
                        {
                            "lat": 61.33720913586261,
                            "lon": 55.123199078078855
                        },
                        {
                            "lat": 61.33708814119869,
                            "lon": 55.124993622379826
                        },
                        {
                            "lat": 61.336967133042215,
                            "lon": 55.12678816597004
                        },
                        {
                            "lat": 61.336846111391125,
                            "lon": 55.12858270884953
                        },
                        {
                            "lat": 61.336725076243354,
                            "lon": 55.130377251018224
                        },
                        {
                            "lat": 61.33660402759688,
                            "lon": 55.13217179247615
                        },
                        {
                            "lat": 61.33648296544966,
                            "lon": 55.13396633322327
                        },
                        {
                            "lat": 61.33961525024526,
                            "lon": 55.134035655630406
                        },
                        {
                            "lat": 61.339494314790166,
                            "lon": 55.13583020026845
                        },
                        {
                            "lat": 61.3393733658458,
                            "lon": 55.13762474419609
                        },
                        {
                            "lat": 61.336240800644674,
                            "lon": 55.13755541258508
                        },
                        {
                            "lat": 61.336119697982824,
                            "lon": 55.13934995119973
                        },
                        {
                            "lat": 61.335998581812,
                            "lon": 55.14114448910351
                        },
                        {
                            "lat": 61.339131427480986,
                            "lon": 55.14121382992005
                        },
                        {
                            "lat": 61.34226428664542,
                            "lon": 55.14128309027821
                        },
                        {
                            "lat": 61.345397159289675,
                            "lon": 55.14135227017728
                        },
                        {
                            "lat": 61.34527645038593,
                            "lon": 55.14314682116434
                        },
                        {
                            "lat": 61.3484094767579,
                            "lon": 55.14321592519095
                        },
                        {
                            "lat": 61.3485300453982,
                            "lon": 55.14142136961645
                        },
                        {
                            "lat": 61.34865060058985,
                            "lon": 55.13962681333273
                        },
                        {
                            "lat": 61.35178335989712,
                            "lon": 55.139695827729625
                        },
                        {
                            "lat": 61.3516629449554,
                            "lon": 55.141490388595024
                        },
                        {
                            "lat": 61.35479585794566,
                            "lon": 55.14155932711221
                        },
                        {
                            "lat": 61.35792878435341,
                            "lon": 55.1416281851673
                        },
                        {
                            "lat": 61.36106172416305,
                            "lon": 55.1416969627595
                        },
                        {
                            "lat": 61.360941716597004,
                            "lon": 55.14349153663036
                        },
                        {
                            "lat": 61.36407481006659,
                            "lon": 55.143560238319694
                        },
                        {
                            "lat": 61.364194677358995,
                            "lon": 55.14176565988808
                        },
                        {
                            "lat": 61.36732764392564,
                            "lon": 55.1418342765523
                        },
                        {
                            "lat": 61.36744735758725,
                            "lon": 55.14003969285844
                        },
                        {
                            "lat": 61.37058019724689,
                            "lon": 55.140108224507834
                        },
                        {
                            "lat": 61.370460623847414,
                            "lon": 55.14190281275139
                        },
                        {
                            "lat": 61.37359361710871,
                            "lon": 55.141971268484646
                        },
                        {
                            "lat": 61.37371305024402,
                            "lon": 55.14017667569667
                        },
                        {
                            "lat": 61.37684591656308,
                            "lon": 55.140245046424255
                        },
                        {
                            "lat": 61.37672662369394,
                            "lon": 55.142039643751275
                        },
                        {
                            "lat": 61.379859643587494,
                            "lon": 55.14210793855056
                        },
                        {
                            "lat": 61.3829926767738,
                            "lon": 55.14217615288175
                        },
                        {
                            "lat": 61.38311168910451,
                            "lon": 55.14038154649258
                        },
                        {
                            "lat": 61.38624459529572,
                            "lon": 55.14044967583187
                        },
                        {
                            "lat": 61.38937751474647,
                            "lon": 55.14051772470687
                        },
                        {
                            "lat": 61.38925878296225,
                            "lon": 55.142312340136876
                        },
                        {
                            "lat": 61.392391855933205,
                            "lon": 55.14238031305934
                        },
                        {
                            "lat": 61.395524942134514,
                            "lon": 55.14244820551075
                        },
                        {
                            "lat": 61.39540647769005,
                            "lon": 55.144242829257166
                        },
                        {
                            "lat": 61.39853971740203,
                            "lon": 55.14431064573895
                        },
                        {
                            "lat": 61.39842138005226,
                            "lon": 55.14610527328477
                        },
                        {
                            "lat": 61.401554773278896,
                            "lon": 55.14617301378595
                        },
                        {
                            "lat": 61.401672970315154,
                            "lon": 55.14437838174285
                        },
                        {
                            "lat": 61.40480623641387,
                            "lon": 55.144446037268175
                        },
                        {
                            "lat": 61.40793951568254,
                            "lon": 55.14451361231413
                        },
                        {
                            "lat": 61.407821599279266,
                            "lon": 55.14630825333576
                        },
                        {
                            "lat": 61.41095503202182,
                            "lon": 55.14637575238293
                        },
                        {
                            "lat": 61.41083724279726,
                            "lon": 55.14817039718466
                        },
                        {
                            "lat": 61.413970829017664,
                            "lon": 55.14823782022203
                        },
                        {
                            "lat": 61.41408847790514,
                            "lon": 55.14644317094397
                        },
                        {
                            "lat": 61.41722193691363,
                            "lon": 55.14651050901812
                        },
                        {
                            "lat": 61.41733943235242,
                            "lon": 55.1447158545687
                        },
                        {
                            "lat": 61.41745691468366,
                            "lon": 55.142921199418915
                        },
                        {
                            "lat": 61.41432373630656,
                            "lon": 55.14285387028557
                        },
                        {
                            "lat": 61.41444134582451,
                            "lon": 55.14105921890527
                        },
                        {
                            "lat": 61.41757438390933,
                            "lon": 55.1411265435688
                        },
                        {
                            "lat": 61.420707435097746,
                            "lon": 55.14119378776071
                        },
                        {
                            "lat": 61.42059010616636,
                            "lon": 55.14298844807531
                        },
                        {
                            "lat": 61.423723310739035,
                            "lon": 55.14305561625401
                        },
                        {
                            "lat": 61.426856528386075,
                            "lon": 55.14312270395431
                        },
                        {
                            "lat": 61.42673946699044,
                            "lon": 55.14491737248276
                        },
                        {
                            "lat": 61.4298728380121,
                            "lon": 55.14498438415283
                        },
                        {
                            "lat": 61.429989759091896,
                            "lon": 55.14318971117547
                        },
                        {
                            "lat": 61.43010666712854,
                            "lon": 55.14139503749941
                        },
                        {
                            "lat": 61.43323977057531,
                            "lon": 55.14146195979751
                        },
                        {
                            "lat": 61.4331230028409,
                            "lon": 55.143256637916764
                        },
                        {
                            "lat": 61.43625625961748,
                            "lon": 55.14332348417745
                        },
                        {
                            "lat": 61.439389529406036,
                            "lon": 55.143390249956816
                        },
                        {
                            "lat": 61.43950601653005,
                            "lon": 55.14159556296722
                        },
                        {
                            "lat": 61.44263915900683,
                            "lon": 55.141662243837345
                        },
                        {
                            "lat": 61.44252281219095,
                            "lon": 55.14345693525412
                        },
                        {
                            "lat": 61.445656107956644,
                            "lon": 55.14352354006866
                        },
                        {
                            "lat": 61.4487894166875,
                            "lon": 55.14359006439967
                        },
                        {
                            "lat": 61.44890548288115,
                            "lon": 55.141795364144485
                        },
                        {
                            "lat": 61.452038664247496,
                            "lon": 55.14186180358009
                        },
                        {
                            "lat": 61.45517185854584,
                            "lon": 55.14192816253605
                        },
                        {
                            "lat": 61.455056072982295,
                            "lon": 55.1437228716083
                        },
                        {
                            "lat": 61.45818942051501,
                            "lon": 55.143789154484466
                        },
                        {
                            "lat": 61.46132278095049,
                            "lon": 55.14385535687421
                        },
                        {
                            "lat": 61.464456154273115,
                            "lon": 55.143921478776875
                        },
                        {
                            "lat": 61.464340776797876,
                            "lon": 55.145716200340374
                        },
                        {
                            "lat": 61.467474303331514,
                            "lon": 55.14578224614012
                        },
                        {
                            "lat": 61.46758954046727,
                            "lon": 55.14398752019168
                        },
                        {
                            "lat": 61.470722939517366,
                            "lon": 55.14405348111792
                        },
                        {
                            "lat": 61.47060784272303,
                            "lon": 55.14584821144599
                        },
                        {
                            "lat": 61.47374139495683,
                            "lon": 55.14591409625725
                        },
                        {
                            "lat": 61.47385635140778,
                            "lon": 55.1441193615549
                        },
                        {
                            "lat": 61.47698977612294,
                            "lon": 55.14418516150188
                        },
                        {
                            "lat": 61.47687496001731,
                            "lon": 55.14597990057318
                        },
                        {
                            "lat": 61.48000853788884,
                            "lon": 55.14604562439308
                        },
                        {
                            "lat": 61.480123213647175,
                            "lon": 55.14425088095815
                        },
                        {
                            "lat": 61.483256663964944,
                            "lon": 55.14431651992298
                        },
                        {
                            "lat": 61.48314212855583,
                            "lon": 55.14611126771622
                        },
                        {
                            "lat": 61.486275732002646,
                            "lon": 55.1461768305419
                        },
                        {
                            "lat": 61.4894093482137,
                            "lon": 55.146242312869376
                        },
                        {
                            "lat": 61.48952360291855,
                            "lon": 55.14444755637554
                        },
                        {
                            "lat": 61.49265709152317,
                            "lon": 55.14451295386182
                        },
                        {
                            "lat": 61.492542977173386,
                            "lon": 55.14630771469797
                        },
                        {
                            "lat": 61.49567661886608,
                            "lon": 55.14637303602695
                        },
                        {
                            "lat": 61.49579059285889,
                            "lon": 55.144578270853835
                        },
                        {
                            "lat": 61.495904554136224,
                            "lon": 55.142783504990206
                        },
                        {
                            "lat": 61.49903792784407,
                            "lon": 55.142848737155965
                        },
                        {
                            "lat": 61.50217131424986,
                            "lon": 55.14291388883136
                        },
                        {
                            "lat": 61.50228498215635,
                            "lon": 55.14111911362085
                        },
                        {
                            "lat": 61.50541824091289,
                            "lon": 55.14118418048495
                        },
                        {
                            "lat": 61.50855151233421,
                            "lon": 55.14124916686259
                        },
                        {
                            "lat": 61.508438125092766,
                            "lon": 55.143043950708176
                        },
                        {
                            "lat": 61.51157154949866,
                            "lon": 55.14310886090821
                        },
                        {
                            "lat": 61.51145828995712,
                            "lon": 55.144903648374786
                        },
                        {
                            "lat": 61.51459186735145,
                            "lon": 55.14496848238621
                        },
                        {
                            "lat": 61.51470498654005,
                            "lon": 55.14317369061502
                        },
                        {
                            "lat": 61.51481809310877,
                            "lon": 55.141378898155665
                        },
                        {
                            "lat": 61.514931187059524,
                            "lon": 55.13958410500816
                        },
                        {
                            "lat": 61.51806435605793,
                            "lon": 55.13964884562369
                        },
                        {
                            "lat": 61.51795140243079,
                            "lon": 55.141443643069685
                        },
                        {
                            "lat": 61.521084724355184,
                            "lon": 55.14150830749442
                        },
                        {
                            "lat": 61.52097189846686,
                            "lon": 55.14330310854623
                        },
                        {
                            "lat": 61.524105373321056,
                            "lon": 55.14336769676921
                        },
                        {
                            "lat": 61.5242180588663,
                            "lon": 55.14157289142918
                        },
                        {
                            "lat": 61.52735140594856,
                            "lon": 55.141637394873236
                        },
                        {
                            "lat": 61.5274639385933,
                            "lon": 55.139842584563695
                        },
                        {
                            "lat": 61.530597157899805,
                            "lon": 55.13990700323917
                        },
                        {
                            "lat": 61.53373038974435,
                            "lon": 55.139971341427895
                        },
                        {
                            "lat": 61.53384262920219,
                            "lon": 55.13817652188349
                        },
                        {
                            "lat": 61.53070953767526,
                            "lon": 55.13811218796625
                        },
                        {
                            "lat": 61.527576458684415,
                            "lon": 55.138047773567564
                        },
                        {
                            "lat": 61.52444339224529,
                            "lon": 55.13798327868816
                        },
                        {
                            "lat": 61.52131033837348,
                            "lon": 55.13791870332872
                        },
                        {
                            "lat": 61.518177297084605,
                            "lon": 55.137854047489945
                        },
                        {
                            "lat": 61.515044268394234,
                            "lon": 55.13778931117253
                        },
                        {
                            "lat": 61.51515733711483,
                            "lon": 55.135994516648786
                        },
                        {
                            "lat": 61.51202446132909,
                            "lon": 55.13592970415654
                        },
                        {
                            "lat": 61.508891598171175,
                            "lon": 55.135864811192405
                        },
                        {
                            "lat": 61.50877824887149,
                            "lon": 55.137659597104694
                        },
                        {
                            "lat": 61.5056452580703,
                            "lon": 55.13759461935567
                        },
                        {
                            "lat": 61.50575874765668,
                            "lon": 55.13579983775709
                        },
                        {
                            "lat": 61.50262590980117,
                            "lon": 55.1357347838513
                        },
                        {
                            "lat": 61.50251227993007,
                            "lon": 55.13752956113082
                        },
                        {
                            "lat": 61.499379314466346,
                            "lon": 55.1374644224309
                        },
                        {
                            "lat": 61.49949308462027,
                            "lon": 55.13566964947575
                        },
                        {
                            "lat": 61.49636027212958,
                            "lon": 55.135604434631155
                        },
                        {
                            "lat": 61.49624636169477,
                            "lon": 55.13739920325658
                        },
                        {
                            "lat": 61.493113421630945,
                            "lon": 55.13733390360861
                        },
                        {
                            "lat": 61.489980494290414,
                            "lon": 55.13726852348767
                        },
                        {
                            "lat": 61.490094685281214,
                            "lon": 55.13547376353764
                        },
                        {
                            "lat": 61.49020886353499,
                            "lon": 55.133679002896415
                        },
                        {
                            "lat": 61.487076229467995,
                            "lon": 55.13361355099422
                        },
                        {
                            "lat": 61.483943608151634,
                            "lon": 55.133548018631146
                        },
                        {
                            "lat": 61.48405805415623,
                            "lon": 55.13175326599387
                        },
                        {
                            "lat": 61.48092558584615,
                            "lon": 55.13168765752619
                        },
                        {
                            "lat": 61.480810999601545,
                            "lon": 55.1334824058079
                        },
                        {
                            "lat": 61.47767840383331,
                            "lon": 55.13341671252519
                        },
                        {
                            "lat": 61.47454582086249,
                            "lon": 55.13335093878377
                        },
                        {
                            "lat": 61.471413250704714,
                            "lon": 55.133285084584294
                        },
                        {
                            "lat": 61.46828069337556,
                            "lon": 55.13321914992754
                        },
                        {
                            "lat": 61.4651481488906,
                            "lon": 55.13315313481418
                        },
                        {
                            "lat": 61.46526343630613,
                            "lon": 55.13135840839022
                        },
                        {
                            "lat": 61.46213104490931,
                            "lon": 55.13129231720854
                        },
                        {
                            "lat": 61.46224645967971,
                            "lon": 55.12949759447744
                        },
                        {
                            "lat": 61.45911422136713,
                            "lon": 55.12943142723839
                        },
                        {
                            "lat": 61.45922976346126,
                            "lon": 55.127636708204676
                        },
                        {
                            "lat": 61.45609767822899,
                            "lon": 55.12757046491923
                        },
                        {
                            "lat": 61.455981995941634,
                            "lon": 55.12936517955554
                        },
                        {
                            "lat": 61.452849783418785,
                            "lon": 55.129298851429645
                        },
                        {
                            "lat": 61.452965605897425,
                            "lon": 55.127504141196056
                        },
                        {
                            "lat": 61.449833546482125,
                            "lon": 55.12743773703588
                        },
                        {
                            "lat": 61.44670149999872,
                            "lon": 55.12737125243941
                        },
                        {
                            "lat": 61.44681758990658,
                            "lon": 55.12557655033059
                        },
                        {
                            "lat": 61.443685696540406,
                            "lon": 55.12550998971688
                        },
                        {
                            "lat": 61.443801913657005,
                            "lon": 55.123715291329354
                        },
                        {
                            "lat": 61.44067017340414,
                            "lon": 55.123648654709335
                        },
                        {
                            "lat": 61.437538446125956,
                            "lon": 55.12358193766586
                        },
                        {
                            "lat": 61.437421948706806,
                            "lon": 55.12537662720157
                        },
                        {
                            "lat": 61.43429009427054,
                            "lon": 55.125309825301464
                        },
                        {
                            "lat": 61.434173443695286,
                            "lon": 55.12710450970514
                        },
                        {
                            "lat": 61.43104146210489,
                            "lon": 55.12703762293809
                        },
                        {
                            "lat": 61.42790949353985,
                            "lon": 55.126970655739115
                        },
                        {
                            "lat": 61.42779254960181,
                            "lon": 55.128765330559226
                        },
                        {
                            "lat": 61.424660453904174,
                            "lon": 55.1286982784784
                        },
                        {
                            "lat": 61.42477753801574,
                            "lon": 55.12690360810895
                        },
                        {
                            "lat": 61.42164559554815,
                            "lon": 55.12683648004827
                        },
                        {
                            "lat": 61.421762806759176,
                            "lon": 55.12504181343496
                        },
                        {
                            "lat": 61.4218800049001,
                            "lon": 55.12324714612183
                        },
                        {
                            "lat": 61.41874835579699,
                            "lon": 55.12317994655281
                        },
                        {
                            "lat": 61.41886568099263,
                            "lon": 55.12138528300003
                        },
                        {
                            "lat": 61.41573418509406,
                            "lon": 55.12131800747806
                        },
                        {
                            "lat": 61.41561671977755,
                            "lon": 55.12311266656544
                        },
                        {
                            "lat": 61.41549924136179,
                            "lon": 55.12490732495224
                        },
                        {
                            "lat": 61.41863101751766,
                            "lon": 55.12497460940545
                        },
                        {
                            "lat": 61.41851366615264,
                            "lon": 55.12676927155786
                        },
                        {
                            "lat": 61.41538174984479,
                            "lon": 55.12670198263845
                        },
                        {
                            "lat": 61.41224984664018,
                            "lon": 55.126634613290754
                        },
                        {
                            "lat": 61.412367478307196,
                            "lon": 55.12483996007607
                        },
                        {
                            "lat": 61.40923572836944,
                            "lon": 55.12477251477769
                        },
                        {
                            "lat": 61.409117956554404,
                            "lon": 55.1265671635155
                        },
                        {
                            "lat": 61.405986079603025,
                            "lon": 55.12649963331342
                        },
                        {
                            "lat": 61.40610399156407,
                            "lon": 55.12470498905781
                        },
                        {
                            "lat": 61.40297226790669,
                            "lon": 55.124637382917165
                        },
                        {
                            "lat": 61.4030903068482,
                            "lon": 55.12284274244686
                        },
                        {
                            "lat": 61.39995873648082,
                            "lon": 55.12277506037864
                        },
                        {
                            "lat": 61.39682717929056,
                            "lon": 55.12270729789644
                        },
                        {
                            "lat": 61.39670886009815,
                            "lon": 55.12450192937656
                        },
                        {
                            "lat": 61.39984055741286,
                            "lon": 55.1245696963565
                        },
                        {
                            "lat": 61.39972236516576,
                            "lon": 55.12636433163179
                        },
                        {
                            "lat": 61.40285421580161,
                            "lon": 55.12643202268527
                        },
                        {
                            "lat": 61.402736150530984,
                            "lon": 55.12822666175118
                        },
                        {
                            "lat": 61.39960415973754,
                            "lon": 55.12815896620446
                        },
                        {
                            "lat": 61.396472182127205,
                            "lon": 55.12809119022779
                        },
                        {
                            "lat": 61.39334021771561,
                            "lon": 55.12802333382194
                        },
                        {
                            "lat": 61.390208266518286,
                            "lon": 55.127955396987616
                        },
                        {
                            "lat": 61.39032689240727,
                            "lon": 55.1261607759266
                        },
                        {
                            "lat": 61.38719509458938,
                            "lon": 55.12609276317909
                        },
                        {
                            "lat": 61.38707632855082,
                            "lon": 55.1278873797256
                        },
                        {
                            "lat": 61.3839444038288,
                            "lon": 55.12781928203658
                        },
                        {
                            "lat": 61.380812492367795,
                            "lon": 55.12775110392134
                        },
                        {
                            "lat": 61.38093153869947,
                            "lon": 55.12595649641985
                        },
                        {
                            "lat": 61.38105057175655,
                            "lon": 55.12416188821336
                        },
                        {
                            "lat": 61.377918953843626,
                            "lon": 55.124093638733186
                        },
                        {
                            "lat": 61.377799780658584,
                            "lon": 55.12588824240962
                        },
                        {
                            "lat": 61.37466803590784,
                            "lon": 55.12581990797995
                        },
                        {
                            "lat": 61.37153630446282,
                            "lon": 55.125751493131595
                        },
                        {
                            "lat": 61.368404586339096,
                            "lon": 55.12568299786528
                        },
                        {
                            "lat": 61.36828497944545,
                            "lon": 55.12747758721284
                        },
                        {
                            "lat": 61.365153134523155,
                            "lon": 55.127409006977565
                        },
                        {
                            "lat": 61.36527288155225,
                            "lon": 55.12561442218178
                        },
                        {
                            "lat": 61.36214119011784,
                            "lon": 55.12554576608182
                        },
                        {
                            "lat": 61.35900951205143,
                            "lon": 55.125477029566184
                        },
                        {
                            "lat": 61.359129525961755,
                            "lon": 55.12368245318207
                        },
                        {
                            "lat": 61.35599800139269,
                            "lon": 55.123613640818796
                        },
                        {
                            "lat": 61.35286649022075,
                            "lon": 55.12354474804663
                        },
                        {
                            "lat": 61.3497349924615,
                            "lon": 55.12347577486636
                        },
                        {
                            "lat": 61.34985541327877,
                            "lon": 55.121681211491165
                        },
                        {
                            "lat": 61.349975820669826,
                            "lon": 55.11988664740691
                        },
                        {
                            "lat": 61.34684461650744,
                            "lon": 55.11981760298461
                        },
                        {
                            "lat": 61.343713425784784,
                            "lon": 55.11974847816632
                        },
                        {
                            "lat": 61.343834099871586,
                            "lon": 55.117953922542476
                        },
                        {
                            "lat": 61.34070306266112,
                            "lon": 55.117884721921676
                        },
                        {
                            "lat": 61.340823863335984,
                            "lon": 55.116090170180286
                        },
                        {
                            "lat": 61.3376929796336,
                            "lon": 55.11602089376794
                        },
                        {
                            "lat": 61.33757203891945,
                            "lon": 55.11781544091169
                        },
                        {
                            "lat": 61.33745108472093,
                            "lon": 55.11960998734478
                        },
                        {
                            "lat": 61.337330117036,
                            "lon": 55.12140453306718
                        },
                        {
                            "lat": 61.33720913586261,
                            "lon": 55.123199078078855
                        }
                    ],
                    [
                        {
                            "lat": 61.3519037614078,
                            "lon": 55.137901266155424
                        },
                        {
                            "lat": 61.35503639390976,
                            "lon": 55.137970195520346
                        },
                        {
                            "lat": 61.354916132635424,
                            "lon": 55.1397647616705
                        },
                        {
                            "lat": 61.35178335989712,
                            "lon": 55.139695827729625
                        },
                        {
                            "lat": 61.3519037614078,
                            "lon": 55.137901266155424
                        }
                    ],
                    [
                        {
                            "lat": 61.376965196125894,
                            "lon": 55.13845044839167
                        },
                        {
                            "lat": 61.37708446238442,
                            "lon": 55.13665584965357
                        },
                        {
                            "lat": 61.38021706152015,
                            "lon": 55.136724130852855
                        },
                        {
                            "lat": 61.38009793549865,
                            "lon": 55.1385187341239
                        },
                        {
                            "lat": 61.37997879618843,
                            "lon": 55.140313336689815
                        },
                        {
                            "lat": 61.37684591656308,
                            "lon": 55.140245046424255
                        },
                        {
                            "lat": 61.376965196125894,
                            "lon": 55.13845044839167
                        }
                    ],
                    [
                        {
                            "lat": 61.37395187655095,
                            "lon": 55.13658748800291
                        },
                        {
                            "lat": 61.37407126972663,
                            "lon": 55.13479289309715
                        },
                        {
                            "lat": 61.37419064958644,
                            "lon": 55.132998297485464
                        },
                        {
                            "lat": 61.37732295499668,
                            "lon": 55.133066650060826
                        },
                        {
                            "lat": 61.377203715340684,
                            "lon": 55.13486125020996
                        },
                        {
                            "lat": 61.37708446238442,
                            "lon": 55.13665584965357
                        },
                        {
                            "lat": 61.37395187655095,
                            "lon": 55.13658748800291
                        }
                    ],
                    [
                        {
                            "lat": 61.37105835749003,
                            "lon": 55.1329298644701
                        },
                        {
                            "lat": 61.37117786422226,
                            "lon": 55.13113527269489
                        },
                        {
                            "lat": 61.374310016132455,
                            "lon": 55.13120370116789
                        },
                        {
                            "lat": 61.37419064958644,
                            "lon": 55.132998297485464
                        },
                        {
                            "lat": 61.37105835749003,
                            "lon": 55.1329298644701
                        }
                    ],
                    [
                        {
                            "lat": 61.41420611366744,
                            "lon": 55.14464852096513
                        },
                        {
                            "lat": 61.4110728081056,
                            "lon": 55.14458110688005
                        },
                        {
                            "lat": 61.41119057105061,
                            "lon": 55.142786460676014
                        },
                        {
                            "lat": 61.41432373630656,
                            "lon": 55.14285387028557
                        },
                        {
                            "lat": 61.41420611366744,
                            "lon": 55.14464852096513
                        }
                    ],
                    [
                        {
                            "lat": 61.41130832085885,
                            "lon": 55.14099181377086
                        },
                        {
                            "lat": 61.40817530902798,
                            "lon": 55.140924328166285
                        },
                        {
                            "lat": 61.40829318597413,
                            "lon": 55.13912968504009
                        },
                        {
                            "lat": 61.41142605753229,
                            "lon": 55.13919716616461
                        },
                        {
                            "lat": 61.41455894222326,
                            "lon": 55.13926456682427
                        },
                        {
                            "lat": 61.41444134582451,
                            "lon": 55.14105921890527
                        },
                        {
                            "lat": 61.41130832085885,
                            "lon": 55.14099181377086
                        }
                    ],
                    [
                        {
                            "lat": 61.4457723144624,
                            "lon": 55.14172884423
                        },
                        {
                            "lat": 61.445888508004934,
                            "lon": 55.139934147694625
                        },
                        {
                            "lat": 61.4490215361272,
                            "lon": 55.140000663192964
                        },
                        {
                            "lat": 61.44890548288115,
                            "lon": 55.141795364144485
                        },
                        {
                            "lat": 61.4457723144624,
                            "lon": 55.14172884423
                        }
                    ],
                    [
                        {
                            "lat": 61.440553816135285,
                            "lon": 55.12544334867362
                        },
                        {
                            "lat": 61.44043744588979,
                            "lon": 55.12723804194051
                        },
                        {
                            "lat": 61.437305438295425,
                            "lon": 55.127171316039515
                        },
                        {
                            "lat": 61.437421948706806,
                            "lon": 55.12537662720157
                        },
                        {
                            "lat": 61.440553816135285,
                            "lon": 55.12544334867362
                        }
                    ],
                    [
                        {
                            "lat": 61.380693432759514,
                            "lon": 55.129545710717785
                        },
                        {
                            "lat": 61.377561394416006,
                            "lon": 55.12947744764614
                        },
                        {
                            "lat": 61.377680594183374,
                            "lon": 55.127682845380605
                        },
                        {
                            "lat": 61.380812492367795,
                            "lon": 55.12775110392134
                        },
                        {
                            "lat": 61.380693432759514,
                            "lon": 55.129545710717785
                        }
                    ],
                    [
                        {
                            "lat": 61.36202130295533,
                            "lon": 55.12734034632052
                        },
                        {
                            "lat": 61.36190140242278,
                            "lon": 55.129134925851695
                        },
                        {
                            "lat": 61.35876944407803,
                            "lon": 55.129066180210785
                        },
                        {
                            "lat": 61.35888948475755,
                            "lon": 55.12727160524242
                        },
                        {
                            "lat": 61.36202130295533,
                            "lon": 55.12734034632052
                        }
                    ],
                    [
                        {
                            "lat": 61.42454335673411,
                            "lon": 55.13049294814847
                        },
                        {
                            "lat": 61.42767559262086,
                            "lon": 55.13056000468034
                        },
                        {
                            "lat": 61.427558622595015,
                            "lon": 55.132354678102416
                        },
                        {
                            "lat": 61.42442624650355,
                            "lon": 55.1322876171191
                        },
                        {
                            "lat": 61.42454335673411,
                            "lon": 55.13049294814847
                        }
                    ],
                    [
                        {
                            "lat": 61.4182789241576,
                            "lon": 55.13035859376209
                        },
                        {
                            "lat": 61.41839630169995,
                            "lon": 55.128563933010106
                        },
                        {
                            "lat": 61.421528371265026,
                            "lon": 55.12863114596178
                        },
                        {
                            "lat": 61.42141113390782,
                            "lon": 55.130425811175485
                        },
                        {
                            "lat": 61.4182789241576,
                            "lon": 55.13035859376209
                        }
                    ],
                    [
                        {
                            "lat": 61.38402208983636,
                            "lon": 55.07929645395948
                        },
                        {
                            "lat": 61.38390338798949,
                            "lon": 55.08109107978695
                        },
                        {
                            "lat": 61.3870316607216,
                            "lon": 55.08115914037897
                        },
                        {
                            "lat": 61.38715022282707,
                            "lon": 55.079364510036825
                        },
                        {
                            "lat": 61.38402208983636,
                            "lon": 55.07929645395948
                        }
                    ],
                    [
                        {
                            "lat": 61.390159946662486,
                            "lon": 55.081227120682996
                        },
                        {
                            "lat": 61.39004151110713,
                            "lon": 55.08302175483065
                        },
                        {
                            "lat": 61.39316995000222,
                            "lon": 55.0830896593504
                        },
                        {
                            "lat": 61.39328824579662,
                            "lon": 55.08129502069829
                        },
                        {
                            "lat": 61.390159946662486,
                            "lon": 55.081227120682996
                        }
                    ],
                    [
                        {
                            "lat": 61.39765504435407,
                            "lon": 55.110144857852966
                        },
                        {
                            "lat": 61.39452448077776,
                            "lon": 55.11007704646781
                        },
                        {
                            "lat": 61.39440611387868,
                            "lon": 55.11187167836824
                        },
                        {
                            "lat": 61.39753681746848,
                            "lon": 55.11193949425366
                        },
                        {
                            "lat": 61.39765504435407,
                            "lon": 55.110144857852966
                        }
                    ],
                    [
                        {
                            "lat": 61.41017743029059,
                            "lon": 55.110415299626396
                        },
                        {
                            "lat": 61.41005976347896,
                            "lon": 55.112209953974926
                        },
                        {
                            "lat": 61.413190532816614,
                            "lon": 55.11227736794647
                        },
                        {
                            "lat": 61.41330805960474,
                            "lon": 55.11048270912427
                        },
                        {
                            "lat": 61.41017743029059,
                            "lon": 55.110415299626396
                        }
                    ],
                    [
                        {
                            "lat": 61.4037982641746,
                            "lon": 55.11207488487984
                        },
                        {
                            "lat": 61.406929007259784,
                            "lon": 55.112142459619136
                        },
                        {
                            "lat": 61.40704681409288,
                            "lon": 55.110347809749584
                        },
                        {
                            "lat": 61.40391621102721,
                            "lon": 55.110280239494585
                        },
                        {
                            "lat": 61.40078562110907,
                            "lon": 55.11021258886214
                        },
                        {
                            "lat": 61.40066753423899,
                            "lon": 55.11200722975776
                        },
                        {
                            "lat": 61.4037982641746,
                            "lon": 55.11207488487984
                        }
                    ],
                    [
                        {
                            "lat": 61.41283787382688,
                            "lon": 55.11766134020764
                        },
                        {
                            "lat": 61.41272029461524,
                            "lon": 55.11945599622618
                        },
                        {
                            "lat": 61.41585163731327,
                            "lon": 55.11952334769013
                        },
                        {
                            "lat": 61.4159690764372,
                            "lon": 55.11772868720163
                        },
                        {
                            "lat": 61.41283787382688,
                            "lon": 55.11766134020764
                        }
                    ],
                    [
                        {
                            "lat": 61.42606460194944,
                            "lon": 55.10716218788675
                        },
                        {
                            "lat": 61.42919501659239,
                            "lon": 55.1072291865861
                        },
                        {
                            "lat": 61.42931180416991,
                            "lon": 55.10543450337875
                        },
                        {
                            "lat": 61.429428578730246,
                            "lon": 55.10363981947256
                        },
                        {
                            "lat": 61.4262984440452,
                            "lon": 55.10357282966457
                        },
                        {
                            "lat": 61.42618152951369,
                            "lon": 55.10536750912527
                        },
                        {
                            "lat": 61.42606460194944,
                            "lon": 55.10716218788675
                        }
                    ],
                    [
                        {
                            "lat": 61.4253627627656,
                            "lon": 55.11793024577069
                        },
                        {
                            "lat": 61.42223152092211,
                            "lon": 55.11786313998389
                        },
                        {
                            "lat": 61.4221143619796,
                            "lon": 55.119657809396294
                        },
                        {
                            "lat": 61.425245743916754,
                            "lon": 55.119724919637065
                        },
                        {
                            "lat": 61.4253627627656,
                            "lon": 55.11793024577069
                        }
                    ],
                    [
                        {
                            "lat": 61.425245743916754,
                            "lon": 55.119724919637065
                        },
                        {
                            "lat": 61.42512871201933,
                            "lon": 55.121519592804084
                        },
                        {
                            "lat": 61.4282602471163,
                            "lon": 55.12158662708473
                        },
                        {
                            "lat": 61.4283771389025,
                            "lon": 55.11979194946864
                        },
                        {
                            "lat": 61.425245743916754,
                            "lon": 55.119724919637065
                        }
                    ],
                    [
                        {
                            "lat": 61.43429009427054,
                            "lon": 55.125309825301464
                        },
                        {
                            "lat": 61.434406731837996,
                            "lon": 55.12351514019963
                        },
                        {
                            "lat": 61.434523356399644,
                            "lon": 55.12172045439958
                        },
                        {
                            "lat": 61.431391795248274,
                            "lon": 55.12165358095013
                        },
                        {
                            "lat": 61.4282602471163,
                            "lon": 55.12158662708473
                        },
                        {
                            "lat": 61.428143342295115,
                            "lon": 55.123381304001846
                        },
                        {
                            "lat": 61.43127503055585,
                            "lon": 55.12344826231138
                        },
                        {
                            "lat": 61.43115825284207,
                            "lon": 55.12524294297403
                        },
                        {
                            "lat": 61.43429009427054,
                            "lon": 55.125309825301464
                        }
                    ],
                    [
                        {
                            "lat": 61.42802642443696,
                            "lon": 55.125175980219986
                        },
                        {
                            "lat": 61.4248946090708,
                            "lon": 55.12510893704006
                        },
                        {
                            "lat": 61.42477753801574,
                            "lon": 55.12690360810895
                        },
                        {
                            "lat": 61.42790949353985,
                            "lon": 55.126970655739115
                        },
                        {
                            "lat": 61.42802642443696,
                            "lon": 55.125175980219986
                        }
                    ],
                    [
                        {
                            "lat": 61.44507944707252,
                            "lon": 55.10397356306748
                        },
                        {
                            "lat": 61.44194924746695,
                            "lon": 55.10390697507694
                        },
                        {
                            "lat": 61.441833032811104,
                            "lon": 55.10570167671185
                        },
                        {
                            "lat": 61.444963372397716,
                            "lon": 55.10576826912129
                        },
                        {
                            "lat": 61.44507944707252,
                            "lon": 55.10397356306748
                        }
                    ],
                    [
                        {
                            "lat": 61.439389529406036,
                            "lon": 55.143390249956816
                        },
                        {
                            "lat": 61.43927302928563,
                            "lon": 55.14518493624889
                        },
                        {
                            "lat": 61.4424064523943,
                            "lon": 55.14525162597377
                        },
                        {
                            "lat": 61.44252281219095,
                            "lon": 55.14345693525412
                        },
                        {
                            "lat": 61.439389529406036,
                            "lon": 55.143390249956816
                        }
                    ],
                    [
                        {
                            "lat": 61.43613961917531,
                            "lon": 55.1451181660366
                        },
                        {
                            "lat": 61.43602296571916,
                            "lon": 55.14691284719779
                        },
                        {
                            "lat": 61.43915651616688,
                            "lon": 55.14697962184337
                        },
                        {
                            "lat": 61.43927302928563,
                            "lon": 55.14518493624889
                        },
                        {
                            "lat": 61.43613961917531,
                            "lon": 55.1451181660366
                        }
                    ],
                    [
                        {
                            "lat": 61.455279345658056,
                            "lon": 55.09160979041427
                        },
                        {
                            "lat": 61.455163781132704,
                            "lon": 55.09340451456229
                        },
                        {
                            "lat": 61.45829319278801,
                            "lon": 55.09347075470844
                        },
                        {
                            "lat": 61.458408617433264,
                            "lon": 55.091676026165565
                        },
                        {
                            "lat": 61.455279345658056,
                            "lon": 55.09160979041427
                        }
                    ],
                    [
                        {
                            "lat": 61.47256274326798,
                            "lon": 55.11533770154742
                        },
                        {
                            "lat": 61.46943158755993,
                            "lon": 55.115271810641815
                        },
                        {
                            "lat": 61.46931655588489,
                            "lon": 55.11706654769266
                        },
                        {
                            "lat": 61.47244785168494,
                            "lon": 55.117132442971624
                        },
                        {
                            "lat": 61.47256274326798,
                            "lon": 55.11533770154742
                        }
                    ],
                    [
                        {
                            "lat": 61.4884470576297,
                            "lon": 55.112076421503524
                        },
                        {
                            "lat": 61.48833289199655,
                            "lon": 55.11387118609757
                        },
                        {
                            "lat": 61.491463984237875,
                            "lon": 55.11393659022704
                        },
                        {
                            "lat": 61.491578009798495,
                            "lon": 55.11214182129235
                        },
                        {
                            "lat": 61.4884470576297,
                            "lon": 55.112076421503524
                        }
                    ],
                    [
                        {
                            "lat": 61.47267762204186,
                            "lon": 55.1135429594298
                        },
                        {
                            "lat": 61.47256274326798,
                            "lon": 55.11533770154742
                        },
                        {
                            "lat": 61.475693911785164,
                            "lon": 55.11540351204894
                        },
                        {
                            "lat": 61.47580865048065,
                            "lon": 55.1136087655637
                        },
                        {
                            "lat": 61.47267762204186,
                            "lon": 55.1135429594298
                        }
                    ],
                    [
                        {
                            "lat": 61.54715651881826,
                            "lon": 55.12586917752549
                        },
                        {
                            "lat": 61.54704492781658,
                            "lon": 55.127664018892325
                        },
                        {
                            "lat": 61.546933324368155,
                            "lon": 55.12945885957495
                        },
                        {
                            "lat": 61.54682170847113,
                            "lon": 55.131253699573314
                        },
                        {
                            "lat": 61.546710080123574,
                            "lon": 55.13304853888743
                        },
                        {
                            "lat": 61.543577359490165,
                            "lon": 55.1329845396511
                        },
                        {
                            "lat": 61.54346557841185,
                            "lon": 55.13477937403193
                        },
                        {
                            "lat": 61.543353784863626,
                            "lon": 55.13657420772812
                        },
                        {
                            "lat": 61.540220796129674,
                            "lon": 55.136510119514476
                        },
                        {
                            "lat": 61.54010884980198,
                            "lon": 55.138304948270886
                        },
                        {
                            "lat": 61.53697573324963,
                            "lon": 55.13824077531861
                        },
                        {
                            "lat": 61.53686363411126,
                            "lon": 55.140035599129156
                        },
                        {
                            "lat": 61.53999689098499,
                            "lon": 55.14009977634223
                        },
                        {
                            "lat": 61.54313016034992,
                            "lon": 55.14016387306646
                        },
                        {
                            "lat": 61.54626344219042,
                            "lon": 55.14022788930112
                        },
                        {
                            "lat": 61.54637512035894,
                            "lon": 55.138433052724146
                        },
                        {
                            "lat": 61.546486786069366,
                            "lon": 55.13663821546286
                        },
                        {
                            "lat": 61.54659843932362,
                            "lon": 55.134843377517285
                        },
                        {
                            "lat": 61.5497313126897,
                            "lon": 55.134907300528376
                        },
                        {
                            "lat": 61.54984281320939,
                            "lon": 55.13311245765484
                        },
                        {
                            "lat": 61.549954301292274,
                            "lon": 55.13131761409742
                        },
                        {
                            "lat": 61.55006577694024,
                            "lon": 55.12952276985614
                        },
                        {
                            "lat": 61.55319824194535,
                            "lon": 55.12958659967833
                        },
                        {
                            "lat": 61.55330956492494,
                            "lon": 55.12779175051596
                        },
                        {
                            "lat": 61.553420875488996,
                            "lon": 55.12599690067011
                        },
                        {
                            "lat": 61.550288690939,
                            "lon": 55.12593307932195
                        },
                        {
                            "lat": 61.55040012929355,
                            "lon": 55.124138233029115
                        },
                        {
                            "lat": 61.550511555220766,
                            "lon": 55.12234338605242
                        },
                        {
                            "lat": 61.54737966348902,
                            "lon": 55.122279492739146
                        },
                        {
                            "lat": 61.547491217161856,
                            "lon": 55.12048464931964
                        },
                        {
                            "lat": 61.544359478040384,
                            "lon": 55.12042067981576
                        },
                        {
                            "lat": 61.54122775137365,
                            "lon": 55.120356629880895
                        },
                        {
                            "lat": 61.54111591736425,
                            "lon": 55.122151464802336
                        },
                        {
                            "lat": 61.5442477841983,
                            "lon": 55.12221551898889
                        },
                        {
                            "lat": 61.5441360778996,
                            "lon": 55.12401035747742
                        },
                        {
                            "lat": 61.54726809737511,
                            "lon": 55.12407433547441
                        },
                        {
                            "lat": 61.54715651881826,
                            "lon": 55.12586917752549
                        }
                    ]
                ]
            },
            {
                "type": "MultiLineString",
                "value": 0.97,
                "data": [
                    [
                        {
                            "lat": 61.34239304737738,
                            "lon": 55.092760932915134
                        },
                        {
                            "lat": 61.3422724216111,
                            "lon": 55.0945554938886
                        },
                        {
                            "lat": 61.34540163949704,
                            "lon": 55.094624634841374
                        },
                        {
                            "lat": 61.345281140143314,
                            "lon": 55.09641919969212
                        },
                        {
                            "lat": 61.34516062736082,
                            "lon": 55.0982137638332
                        },
                        {
                            "lat": 61.34203112974974,
                            "lon": 55.098144613705244
                        },
                        {
                            "lat": 61.34191046365056,
                            "lon": 55.09993917254843
                        },
                        {
                            "lat": 61.341789784103014,
                            "lon": 55.10173373068152
                        },
                        {
                            "lat": 61.34491956150137,
                            "lon": 55.10180288998632
                        },
                        {
                            "lat": 61.344799008420345,
                            "lon": 55.103597451998304
                        },
                        {
                            "lat": 61.341669091105054,
                            "lon": 55.10352828810447
                        },
                        {
                            "lat": 61.34154838465462,
                            "lon": 55.105322844817294
                        },
                        {
                            "lat": 61.34142766474969,
                            "lon": 55.10711740081994
                        },
                        {
                            "lat": 61.338297481010436,
                            "lon": 55.107048147389555
                        },
                        {
                            "lat": 61.33817660768743,
                            "lon": 55.10884269808646
                        },
                        {
                            "lat": 61.338055720890296,
                            "lon": 55.11063724807277
                        },
                        {
                            "lat": 61.341186184568144,
                            "lon": 55.110706510694705
                        },
                        {
                            "lat": 61.34106542428744,
                            "lon": 55.11250106456681
                        },
                        {
                            "lat": 61.340944650544074,
                            "lon": 55.11429561772867
                        },
                        {
                            "lat": 61.337813906865414,
                            "lon": 55.11422634591353
                        },
                        {
                            "lat": 61.3376929796336,
                            "lon": 55.11602089376794
                        },
                        {
                            "lat": 61.340823863335984,
                            "lon": 55.116090170180286
                        },
                        {
                            "lat": 61.34070306266112,
                            "lon": 55.117884721921676
                        },
                        {
                            "lat": 61.343834099871586,
                            "lon": 55.117953922542476
                        },
                        {
                            "lat": 61.343713425784784,
                            "lon": 55.11974847816632
                        },
                        {
                            "lat": 61.34684461650744,
                            "lon": 55.11981760298461
                        },
                        {
                            "lat": 61.349975820669826,
                            "lon": 55.11988664740691
                        },
                        {
                            "lat": 61.34985541327877,
                            "lon": 55.121681211491165
                        },
                        {
                            "lat": 61.3497349924615,
                            "lon": 55.12347577486636
                        },
                        {
                            "lat": 61.35286649022075,
                            "lon": 55.12354474804663
                        },
                        {
                            "lat": 61.35599800139269,
                            "lon": 55.123613640818796
                        },
                        {
                            "lat": 61.359129525961755,
                            "lon": 55.12368245318207
                        },
                        {
                            "lat": 61.35900951205143,
                            "lon": 55.125477029566184
                        },
                        {
                            "lat": 61.36214119011784,
                            "lon": 55.12554576608182
                        },
                        {
                            "lat": 61.36527288155225,
                            "lon": 55.12561442218178
                        },
                        {
                            "lat": 61.365153134523155,
                            "lon": 55.127409006977565
                        },
                        {
                            "lat": 61.36828497944545,
                            "lon": 55.12747758721284
                        },
                        {
                            "lat": 61.368404586339096,
                            "lon": 55.12568299786528
                        },
                        {
                            "lat": 61.37153630446282,
                            "lon": 55.125751493131595
                        },
                        {
                            "lat": 61.37466803590784,
                            "lon": 55.12581990797995
                        },
                        {
                            "lat": 61.377799780658584,
                            "lon": 55.12588824240962
                        },
                        {
                            "lat": 61.377918953843626,
                            "lon": 55.124093638733186
                        },
                        {
                            "lat": 61.38105057175655,
                            "lon": 55.12416188821336
                        },
                        {
                            "lat": 61.38093153869947,
                            "lon": 55.12595649641985
                        },
                        {
                            "lat": 61.380812492367795,
                            "lon": 55.12775110392134
                        },
                        {
                            "lat": 61.3839444038288,
                            "lon": 55.12781928203658
                        },
                        {
                            "lat": 61.38707632855082,
                            "lon": 55.1278873797256
                        },
                        {
                            "lat": 61.38719509458938,
                            "lon": 55.12609276317909
                        },
                        {
                            "lat": 61.39032689240727,
                            "lon": 55.1261607759266
                        },
                        {
                            "lat": 61.390208266518286,
                            "lon": 55.127955396987616
                        },
                        {
                            "lat": 61.39334021771561,
                            "lon": 55.12802333382194
                        },
                        {
                            "lat": 61.396472182127205,
                            "lon": 55.12809119022779
                        },
                        {
                            "lat": 61.39960415973754,
                            "lon": 55.12815896620446
                        },
                        {
                            "lat": 61.402736150530984,
                            "lon": 55.12822666175118
                        },
                        {
                            "lat": 61.40285421580161,
                            "lon": 55.12643202268527
                        },
                        {
                            "lat": 61.39972236516576,
                            "lon": 55.12636433163179
                        },
                        {
                            "lat": 61.39984055741286,
                            "lon": 55.1245696963565
                        },
                        {
                            "lat": 61.39670886009815,
                            "lon": 55.12450192937656
                        },
                        {
                            "lat": 61.39682717929056,
                            "lon": 55.12270729789644
                        },
                        {
                            "lat": 61.39995873648082,
                            "lon": 55.12277506037864
                        },
                        {
                            "lat": 61.4030903068482,
                            "lon": 55.12284274244686
                        },
                        {
                            "lat": 61.40297226790669,
                            "lon": 55.124637382917165
                        },
                        {
                            "lat": 61.40610399156407,
                            "lon": 55.12470498905781
                        },
                        {
                            "lat": 61.405986079603025,
                            "lon": 55.12649963331342
                        },
                        {
                            "lat": 61.409117956554404,
                            "lon": 55.1265671635155
                        },
                        {
                            "lat": 61.40923572836944,
                            "lon": 55.12477251477769
                        },
                        {
                            "lat": 61.412367478307196,
                            "lon": 55.12483996007607
                        },
                        {
                            "lat": 61.41224984664018,
                            "lon": 55.126634613290754
                        },
                        {
                            "lat": 61.41538174984479,
                            "lon": 55.12670198263845
                        },
                        {
                            "lat": 61.41851366615264,
                            "lon": 55.12676927155786
                        },
                        {
                            "lat": 61.41863101751766,
                            "lon": 55.12497460940545
                        },
                        {
                            "lat": 61.41549924136179,
                            "lon": 55.12490732495224
                        },
                        {
                            "lat": 61.41561671977755,
                            "lon": 55.12311266656544
                        },
                        {
                            "lat": 61.41573418509406,
                            "lon": 55.12131800747806
                        },
                        {
                            "lat": 61.41886568099263,
                            "lon": 55.12138528300003
                        },
                        {
                            "lat": 61.41874835579699,
                            "lon": 55.12317994655281
                        },
                        {
                            "lat": 61.4218800049001,
                            "lon": 55.12324714612183
                        },
                        {
                            "lat": 61.421762806759176,
                            "lon": 55.12504181343496
                        },
                        {
                            "lat": 61.42164559554815,
                            "lon": 55.12683648004827
                        },
                        {
                            "lat": 61.42477753801574,
                            "lon": 55.12690360810895
                        },
                        {
                            "lat": 61.4248946090708,
                            "lon": 55.12510893704006
                        },
                        {
                            "lat": 61.42802642443696,
                            "lon": 55.125175980219986
                        },
                        {
                            "lat": 61.42790949353985,
                            "lon": 55.126970655739115
                        },
                        {
                            "lat": 61.43104146210489,
                            "lon": 55.12703762293809
                        },
                        {
                            "lat": 61.434173443695286,
                            "lon": 55.12710450970514
                        },
                        {
                            "lat": 61.43429009427054,
                            "lon": 55.125309825301464
                        },
                        {
                            "lat": 61.437421948706806,
                            "lon": 55.12537662720157
                        },
                        {
                            "lat": 61.437538446125956,
                            "lon": 55.12358193766586
                        },
                        {
                            "lat": 61.44067017340414,
                            "lon": 55.123648654709335
                        },
                        {
                            "lat": 61.443801913657005,
                            "lon": 55.123715291329354
                        },
                        {
                            "lat": 61.443685696540406,
                            "lon": 55.12550998971688
                        },
                        {
                            "lat": 61.44681758990658,
                            "lon": 55.12557655033059
                        },
                        {
                            "lat": 61.44670149999872,
                            "lon": 55.12737125243941
                        },
                        {
                            "lat": 61.449833546482125,
                            "lon": 55.12743773703588
                        },
                        {
                            "lat": 61.452965605897425,
                            "lon": 55.127504141196056
                        },
                        {
                            "lat": 61.452849783418785,
                            "lon": 55.129298851429645
                        },
                        {
                            "lat": 61.455981995941634,
                            "lon": 55.12936517955554
                        },
                        {
                            "lat": 61.45609767822899,
                            "lon": 55.12757046491923
                        },
                        {
                            "lat": 61.45922976346126,
                            "lon": 55.127636708204676
                        },
                        {
                            "lat": 61.45911422136713,
                            "lon": 55.12943142723839
                        },
                        {
                            "lat": 61.46224645967971,
                            "lon": 55.12949759447744
                        },
                        {
                            "lat": 61.46213104490931,
                            "lon": 55.13129231720854
                        },
                        {
                            "lat": 61.46526343630613,
                            "lon": 55.13135840839022
                        },
                        {
                            "lat": 61.4651481488906,
                            "lon": 55.13315313481418
                        },
                        {
                            "lat": 61.46828069337556,
                            "lon": 55.13321914992754
                        },
                        {
                            "lat": 61.471413250704714,
                            "lon": 55.133285084584294
                        },
                        {
                            "lat": 61.47454582086249,
                            "lon": 55.13335093878377
                        },
                        {
                            "lat": 61.47767840383331,
                            "lon": 55.13341671252519
                        },
                        {
                            "lat": 61.480810999601545,
                            "lon": 55.1334824058079
                        },
                        {
                            "lat": 61.48092558584615,
                            "lon": 55.13168765752619
                        },
                        {
                            "lat": 61.48405805415623,
                            "lon": 55.13175326599387
                        },
                        {
                            "lat": 61.483943608151634,
                            "lon": 55.133548018631146
                        },
                        {
                            "lat": 61.487076229467995,
                            "lon": 55.13361355099422
                        },
                        {
                            "lat": 61.49020886353499,
                            "lon": 55.133679002896415
                        },
                        {
                            "lat": 61.490094685281214,
                            "lon": 55.13547376353764
                        },
                        {
                            "lat": 61.489980494290414,
                            "lon": 55.13726852348767
                        },
                        {
                            "lat": 61.493113421630945,
                            "lon": 55.13733390360861
                        },
                        {
                            "lat": 61.49624636169477,
                            "lon": 55.13739920325658
                        },
                        {
                            "lat": 61.49636027212958,
                            "lon": 55.135604434631155
                        },
                        {
                            "lat": 61.49949308462027,
                            "lon": 55.13566964947575
                        },
                        {
                            "lat": 61.499379314466346,
                            "lon": 55.1374644224309
                        },
                        {
                            "lat": 61.50251227993007,
                            "lon": 55.13752956113082
                        },
                        {
                            "lat": 61.50262590980117,
                            "lon": 55.1357347838513
                        },
                        {
                            "lat": 61.50575874765668,
                            "lon": 55.13579983775709
                        },
                        {
                            "lat": 61.5056452580703,
                            "lon": 55.13759461935567
                        },
                        {
                            "lat": 61.50877824887149,
                            "lon": 55.137659597104694
                        },
                        {
                            "lat": 61.508891598171175,
                            "lon": 55.135864811192405
                        },
                        {
                            "lat": 61.51202446132909,
                            "lon": 55.13592970415654
                        },
                        {
                            "lat": 61.51515733711483,
                            "lon": 55.135994516648786
                        },
                        {
                            "lat": 61.515044268394234,
                            "lon": 55.13778931117253
                        },
                        {
                            "lat": 61.518177297084605,
                            "lon": 55.137854047489945
                        },
                        {
                            "lat": 61.52131033837348,
                            "lon": 55.13791870332872
                        },
                        {
                            "lat": 61.52444339224529,
                            "lon": 55.13798327868816
                        },
                        {
                            "lat": 61.527576458684415,
                            "lon": 55.138047773567564
                        },
                        {
                            "lat": 61.53070953767526,
                            "lon": 55.13811218796625
                        },
                        {
                            "lat": 61.53384262920219,
                            "lon": 55.13817652188349
                        },
                        {
                            "lat": 61.53697573324963,
                            "lon": 55.13824077531861
                        },
                        {
                            "lat": 61.54010884980198,
                            "lon": 55.138304948270886
                        },
                        {
                            "lat": 61.540220796129674,
                            "lon": 55.136510119514476
                        },
                        {
                            "lat": 61.543353784863626,
                            "lon": 55.13657420772812
                        },
                        {
                            "lat": 61.54346557841185,
                            "lon": 55.13477937403193
                        },
                        {
                            "lat": 61.543577359490165,
                            "lon": 55.1329845396511
                        },
                        {
                            "lat": 61.546710080123574,
                            "lon": 55.13304853888743
                        },
                        {
                            "lat": 61.54682170847113,
                            "lon": 55.131253699573314
                        },
                        {
                            "lat": 61.546933324368155,
                            "lon": 55.12945885957495
                        },
                        {
                            "lat": 61.54704492781658,
                            "lon": 55.127664018892325
                        },
                        {
                            "lat": 61.54715651881826,
                            "lon": 55.12586917752549
                        },
                        {
                            "lat": 61.54726809737511,
                            "lon": 55.12407433547441
                        },
                        {
                            "lat": 61.5441360778996,
                            "lon": 55.12401035747742
                        },
                        {
                            "lat": 61.5442477841983,
                            "lon": 55.12221551898889
                        },
                        {
                            "lat": 61.54111591736425,
                            "lon": 55.122151464802336
                        },
                        {
                            "lat": 61.54122775137365,
                            "lon": 55.120356629880895
                        },
                        {
                            "lat": 61.53809603717724,
                            "lon": 55.12029249951577
                        },
                        {
                            "lat": 61.53496433546675,
                            "lon": 55.12022828872111
                        },
                        {
                            "lat": 61.535076437303644,
                            "lon": 55.118433461633295
                        },
                        {
                            "lat": 61.5319448882407,
                            "lon": 55.11836917467702
                        },
                        {
                            "lat": 61.52881335169294,
                            "lon": 55.11830480729793
                        },
                        {
                            "lat": 61.52568182767592,
                            "lon": 55.11824035949668
                        },
                        {
                            "lat": 61.52255031620523,
                            "lon": 55.118175831274016
                        },
                        {
                            "lat": 61.52266296605301,
                            "lon": 55.11638102060001
                        },
                        {
                            "lat": 61.51953160726709,
                            "lon": 55.11631641624479
                        },
                        {
                            "lat": 61.51640026105674,
                            "lon": 55.116251731474875
                        },
                        {
                            "lat": 61.5165131785572,
                            "lon": 55.11445692869457
                        },
                        {
                            "lat": 61.51338198504146,
                            "lon": 55.1143921678091
                        },
                        {
                            "lat": 61.51025080413054,
                            "lon": 55.11432732651565
                        },
                        {
                            "lat": 61.50711963583998,
                            "lon": 55.11426240481493
                        },
                        {
                            "lat": 61.50723296100914,
                            "lon": 55.11246761425683
                        },
                        {
                            "lat": 61.50734627354426,
                            "lon": 55.11067282300963
                        },
                        {
                            "lat": 61.50421539803837,
                            "lon": 55.11060782953004
                        },
                        {
                            "lat": 61.50108453518017,
                            "lon": 55.11054275565522
                        },
                        {
                            "lat": 61.4979536849852,
                            "lon": 55.11047760138591
                        },
                        {
                            "lat": 61.49482284746905,
                            "lon": 55.11041236672281
                        },
                        {
                            "lat": 61.49169202264727,
                            "lon": 55.110347051666615
                        },
                        {
                            "lat": 61.48856121053541,
                            "lon": 55.11028165621804
                        },
                        {
                            "lat": 61.488675350715624,
                            "lon": 55.10848689024114
                        },
                        {
                            "lat": 61.48554469136873,
                            "lon": 55.1084214187461
                        },
                        {
                            "lat": 61.48543041114907,
                            "lon": 55.110216180377805
                        },
                        {
                            "lat": 61.48229962450378,
                            "lon": 55.1101506241466
                        },
                        {
                            "lat": 61.48241404476096,
                            "lon": 55.10835586686541
                        },
                        {
                            "lat": 61.479283410907875,
                            "lon": 55.1082902345998
                        },
                        {
                            "lat": 61.47615278982505,
                            "lon": 55.10822452195001
                        },
                        {
                            "lat": 61.47302218152803,
                            "lon": 55.108158728916706
                        },
                        {
                            "lat": 61.47290734116993,
                            "lon": 55.109953473114444
                        },
                        {
                            "lat": 61.46977660564454,
                            "lon": 55.10988759532657
                        },
                        {
                            "lat": 61.46989158603238,
                            "lon": 55.10809285550062
                        },
                        {
                            "lat": 61.470006553603305,
                            "lon": 55.106298114980945
                        },
                        {
                            "lat": 61.47313700908475,
                            "lon": 55.10636398402563
                        },
                        {
                            "lat": 61.473251823842055,
                            "lon": 55.104569238441194
                        },
                        {
                            "lat": 61.47012150835924,
                            "lon": 55.10450337376754
                        },
                        {
                            "lat": 61.47023645030212,
                            "lon": 55.10270863186042
                        },
                        {
                            "lat": 61.46710628761349,
                            "lon": 55.10264269119128
                        },
                        {
                            "lat": 61.46722135671089,
                            "lon": 55.10084795296599
                        },
                        {
                            "lat": 61.464091346812516,
                            "lon": 55.10078193631238
                        },
                        {
                            "lat": 61.46397613775151,
                            "lon": 55.10257667015675
                        },
                        {
                            "lat": 61.460846000731685,
                            "lon": 55.10251056875758
                        },
                        {
                            "lat": 61.46096134975442,
                            "lon": 55.100715839299355
                        },
                        {
                            "lat": 61.45783136555208,
                            "lon": 55.100649661927726
                        },
                        {
                            "lat": 61.45771587656963,
                            "lon": 55.102444386994435
                        },
                        {
                            "lat": 61.454585765280875,
                            "lon": 55.10237812486809
                        },
                        {
                            "lat": 61.45145566688097,
                            "lon": 55.1023117823792
                        },
                        {
                            "lat": 61.45133988508049,
                            "lon": 55.104106497950895
                        },
                        {
                            "lat": 61.44820965961555,
                            "lon": 55.104040070692385
                        },
                        {
                            "lat": 61.44832558138543,
                            "lon": 55.102245359528546
                        },
                        {
                            "lat": 61.44519550880986,
                            "lon": 55.10217885631682
                        },
                        {
                            "lat": 61.44531155761171,
                            "lon": 55.10038414886932
                        },
                        {
                            "lat": 61.44218163792157,
                            "lon": 55.10031756971533
                        },
                        {
                            "lat": 61.43905173118051,
                            "lon": 55.10025091020706
                        },
                        {
                            "lat": 61.43893540248076,
                            "lon": 55.10204560881307
                        },
                        {
                            "lat": 61.43580536875829,
                            "lon": 55.10197886452247
                        },
                        {
                            "lat": 61.43592183740407,
                            "lon": 55.10018417034521
                        },
                        {
                            "lat": 61.4327919566078,
                            "lon": 55.10011735013051
                        },
                        {
                            "lat": 61.43267534801799,
                            "lon": 55.101912039873724
                        },
                        {
                            "lat": 61.432558726430464,
                            "lon": 55.10370672891852
                        },
                        {
                            "lat": 61.429428578730246,
                            "lon": 55.10363981947256
                        },
                        {
                            "lat": 61.42954534027536,
                            "lon": 55.10184513486754
                        },
                        {
                            "lat": 61.42641534554595,
                            "lon": 55.10177814950462
                        },
                        {
                            "lat": 61.42653223401792,
                            "lon": 55.09998346864544
                        },
                        {
                            "lat": 61.42340239225543,
                            "lon": 55.099916407376554
                        },
                        {
                            "lat": 61.42027256353528,
                            "lon": 55.0998492657577
                        },
                        {
                            "lat": 61.42015539518904,
                            "lon": 55.10164393771156
                        },
                        {
                            "lat": 61.42003821378295,
                            "lon": 55.103438608965405
                        },
                        {
                            "lat": 61.41690811823684,
                            "lon": 55.103371378075664
                        },
                        {
                            "lat": 61.41679078380364,
                            "lon": 55.105166044168044
                        },
                        {
                            "lat": 61.41366056137167,
                            "lon": 55.10509872845247
                        },
                        {
                            "lat": 61.413778035768125,
                            "lon": 55.103304066826844
                        },
                        {
                            "lat": 61.41064796639234,
                            "lon": 55.103236675219605
                        },
                        {
                            "lat": 61.41076556764156,
                            "lon": 55.10144201736482
                        },
                        {
                            "lat": 61.41088315578427,
                            "lon": 55.09964735880884
                        },
                        {
                            "lat": 61.41401294528414,
                            "lon": 55.09971474147313
                        },
                        {
                            "lat": 61.41389549707162,
                            "lon": 55.10150940450038
                        },
                        {
                            "lat": 61.41702543959262,
                            "lon": 55.10157671128287
                        },
                        {
                            "lat": 61.41714274787299,
                            "lon": 55.099782043789645
                        },
                        {
                            "lat": 61.41726004307994,
                            "lon": 55.09798737559605
                        },
                        {
                            "lat": 61.41413038040771,
                            "lon": 55.097920077745066
                        },
                        {
                            "lat": 61.41100073082248,
                            "lon": 55.097852699551645
                        },
                        {
                            "lat": 61.40787109433977,
                            "lon": 55.097785241016524
                        },
                        {
                            "lat": 61.407988796172546,
                            "lon": 55.09599058553395
                        },
                        {
                            "lat": 61.40485931270302,
                            "lon": 55.09592305113896
                        },
                        {
                            "lat": 61.4017298423651,
                            "lon": 55.09585543640907
                        },
                        {
                            "lat": 61.40161186074408,
                            "lon": 55.09765008292412
                        },
                        {
                            "lat": 61.39848226366218,
                            "lon": 55.0975823833683
                        },
                        {
                            "lat": 61.39836412898535,
                            "lon": 55.099377024688856
                        },
                        {
                            "lat": 61.395234405163464,
                            "lon": 55.09930924029682
                        },
                        {
                            "lat": 61.392104694523795,
                            "lon": 55.09924137556144
                        },
                        {
                            "lat": 61.39222310900795,
                            "lon": 55.09744674324111
                        },
                        {
                            "lat": 61.38909355146671,
                            "lon": 55.09737880267121
                        },
                        {
                            "lat": 61.38596400713675,
                            "lon": 55.09731078176476
                        },
                        {
                            "lat": 61.38584531285327,
                            "lon": 55.09910540506362
                        },
                        {
                            "lat": 61.38271564185349,
                            "lon": 55.099037299302644
                        },
                        {
                            "lat": 61.382596794428956,
                            "lon": 55.100831917377995
                        },
                        {
                            "lat": 61.379466996763384,
                            "lon": 55.1007637267522
                        },
                        {
                            "lat": 61.37633721235973,
                            "lon": 55.10069545578145
                        },
                        {
                            "lat": 61.376456339602576,
                            "lon": 55.09890084676026
                        },
                        {
                            "lat": 61.37332670838252,
                            "lon": 55.09883249998035
                        },
                        {
                            "lat": 61.37344596224057,
                            "lon": 55.09703789478821
                        },
                        {
                            "lat": 61.3703164842001,
                            "lon": 55.096969472210105
                        },
                        {
                            "lat": 61.37043586464221,
                            "lon": 55.09517487085153
                        },
                        {
                            "lat": 61.370555231781836,
                            "lon": 55.09338026878653
                        },
                        {
                            "lat": 61.36742604677245,
                            "lon": 55.09331177496567
                        },
                        {
                            "lat": 61.36429687507903,
                            "lon": 55.093243200824084
                        },
                        {
                            "lat": 61.36116771671714,
                            "lon": 55.0931745463625
                        },
                        {
                            "lat": 61.358038571702316,
                            "lon": 55.09310581158168
                        },
                        {
                            "lat": 61.35815848488926,
                            "lon": 55.09131122701845
                        },
                        {
                            "lat": 61.35502949306888,
                            "lon": 55.09124241648446
                        },
                        {
                            "lat": 61.35490944005005,
                            "lon": 55.09303699648237
                        },
                        {
                            "lat": 61.351780321775905,
                            "lon": 55.09296810106531
                        },
                        {
                            "lat": 61.35166011553357,
                            "lon": 55.09476267578375
                        },
                        {
                            "lat": 61.348530870809704,
                            "lon": 55.094693695473346
                        },
                        {
                            "lat": 61.34865121689539,
                            "lon": 55.092899125331236
                        },
                        {
                            "lat": 61.34552212542405,
                            "lon": 55.09283006928094
                        },
                        {
                            "lat": 61.34239304737738,
                            "lon": 55.092760932915134
                        }
                    ],
                    [
                        {
                            "lat": 61.43115825284207,
                            "lon": 55.12524294297403
                        },
                        {
                            "lat": 61.43127503055585,
                            "lon": 55.12344826231138
                        },
                        {
                            "lat": 61.428143342295115,
                            "lon": 55.123381304001846
                        },
                        {
                            "lat": 61.4282602471163,
                            "lon": 55.12158662708473
                        },
                        {
                            "lat": 61.431391795248274,
                            "lon": 55.12165358095013
                        },
                        {
                            "lat": 61.434523356399644,
                            "lon": 55.12172045439958
                        },
                        {
                            "lat": 61.434406731837996,
                            "lon": 55.12351514019963
                        },
                        {
                            "lat": 61.43429009427054,
                            "lon": 55.125309825301464
                        },
                        {
                            "lat": 61.43115825284207,
                            "lon": 55.12524294297403
                        }
                    ],
                    [
                        {
                            "lat": 61.42512871201933,
                            "lon": 55.121519592804084
                        },
                        {
                            "lat": 61.425245743916754,
                            "lon": 55.119724919637065
                        },
                        {
                            "lat": 61.4283771389025,
                            "lon": 55.11979194946864
                        },
                        {
                            "lat": 61.4282602471163,
                            "lon": 55.12158662708473
                        },
                        {
                            "lat": 61.42512871201933,
                            "lon": 55.121519592804084
                        }
                    ],
                    [
                        {
                            "lat": 61.4221143619796,
                            "lon": 55.119657809396294
                        },
                        {
                            "lat": 61.42223152092211,
                            "lon": 55.11786313998389
                        },
                        {
                            "lat": 61.4253627627656,
                            "lon": 55.11793024577069
                        },
                        {
                            "lat": 61.425245743916754,
                            "lon": 55.119724919637065
                        },
                        {
                            "lat": 61.4221143619796,
                            "lon": 55.119657809396294
                        }
                    ],
                    [
                        {
                            "lat": 61.54044465132476,
                            "lon": 55.132920459946504
                        },
                        {
                            "lat": 61.54033272996996,
                            "lon": 55.134715290073004
                        },
                        {
                            "lat": 61.53719989401356,
                            "lon": 55.13465112564121
                        },
                        {
                            "lat": 61.537087819883105,
                            "lon": 55.136445950822626
                        },
                        {
                            "lat": 61.53395485613954,
                            "lon": 55.13638170165327
                        },
                        {
                            "lat": 61.530821904914575,
                            "lon": 55.13631737200711
                        },
                        {
                            "lat": 61.52768896622382,
                            "lon": 55.13625296188485
                        },
                        {
                            "lat": 61.527801461213414,
                            "lon": 55.13445814951555
                        },
                        {
                            "lat": 61.52466867535501,
                            "lon": 55.134393663199255
                        },
                        {
                            "lat": 61.52153590206011,
                            "lon": 55.13432909641358
                        },
                        {
                            "lat": 61.51840314134431,
                            "lon": 55.13426444915925
                        },
                        {
                            "lat": 61.51851604458116,
                            "lon": 55.13246964896231
                        },
                        {
                            "lat": 61.515383436721265,
                            "lon": 55.132404925536974
                        },
                        {
                            "lat": 61.512250841469765,
                            "lon": 55.1323401216497
                        },
                        {
                            "lat": 61.51236401260318,
                            "lon": 55.13054532936356
                        },
                        {
                            "lat": 61.509231570217445,
                            "lon": 55.13048044932229
                        },
                        {
                            "lat": 61.50934486895501,
                            "lon": 55.128685660654554
                        },
                        {
                            "lat": 61.5062125794312,
                            "lon": 55.12862070447028
                        },
                        {
                            "lat": 61.50308030255871,
                            "lon": 55.12855566783684
                        },
                        {
                            "lat": 61.50319386907603,
                            "lon": 55.126760887109164
                        },
                        {
                            "lat": 61.50006174507529,
                            "lon": 55.12669577434972
                        },
                        {
                            "lat": 61.50017543911688,
                            "lon": 55.124900997254535
                        },
                        {
                            "lat": 61.497043467984035,
                            "lon": 55.124835808380055
                        },
                        {
                            "lat": 61.49715728951874,
                            "lon": 55.12304103492191
                        },
                        {
                            "lat": 61.497271098361146,
                            "lon": 55.121246260773376
                        },
                        {
                            "lat": 61.50040278916605,
                            "lon": 55.121311440994255
                        },
                        {
                            "lat": 61.50051644517749,
                            "lon": 55.11951666182921
                        },
                        {
                            "lat": 61.503648008514666,
                            "lon": 55.11958175730272
                        },
                        {
                            "lat": 61.506779584509125,
                            "lon": 55.11964677235443
                        },
                        {
                            "lat": 61.509911173145305,
                            "lon": 55.119711706983594
                        },
                        {
                            "lat": 61.51304277440762,
                            "lon": 55.119776561189525
                        },
                        {
                            "lat": 61.516174388280476,
                            "lon": 55.1198413349715
                        },
                        {
                            "lat": 61.5193060147483,
                            "lon": 55.11990602832881
                        },
                        {
                            "lat": 61.52243765379553,
                            "lon": 55.11997064126079
                        },
                        {
                            "lat": 61.525569305406556,
                            "lon": 55.120035173766716
                        },
                        {
                            "lat": 61.528700969565826,
                            "lon": 55.12009962584587
                        },
                        {
                            "lat": 61.52858857490609,
                            "lon": 55.121894443707355
                        },
                        {
                            "lat": 61.531720391757744,
                            "lon": 55.12195881963203
                        },
                        {
                            "lat": 61.53485222112837,
                            "lon": 55.12202311512322
                        },
                        {
                            "lat": 61.53798406300242,
                            "lon": 55.12208733018023
                        },
                        {
                            "lat": 61.53787207633976,
                            "lon": 55.123882160159326
                        },
                        {
                            "lat": 61.54100407088262,
                            "lon": 55.12394629903883
                        },
                        {
                            "lat": 61.540892211926874,
                            "lon": 55.125741132590356
                        },
                        {
                            "lat": 61.544024359142355,
                            "lon": 55.12580519528139
                        },
                        {
                            "lat": 61.54391262792468,
                            "lon": 55.12760003240074
                        },
                        {
                            "lat": 61.543800884244696,
                            "lon": 55.12939486883549
                        },
                        {
                            "lat": 61.54368912810049,
                            "lon": 55.131189704585616
                        },
                        {
                            "lat": 61.543577359490165,
                            "lon": 55.1329845396511
                        },
                        {
                            "lat": 61.54044465132476,
                            "lon": 55.132920459946504
                        }
                    ],
                    [
                        {
                            "lat": 61.49738489451316,
                            "lon": 55.11945148593457
                        },
                        {
                            "lat": 61.49749867797675,
                            "lon": 55.11765671040543
                        },
                        {
                            "lat": 61.50063008851605,
                            "lon": 55.11772188197422
                        },
                        {
                            "lat": 61.50051644517749,
                            "lon": 55.11951666182921
                        },
                        {
                            "lat": 61.49738489451316,
                            "lon": 55.11945148593457
                        }
                    ],
                    [
                        {
                            "lat": 61.42931180416991,
                            "lon": 55.10543450337875
                        },
                        {
                            "lat": 61.42919501659239,
                            "lon": 55.1072291865861
                        },
                        {
                            "lat": 61.42606460194944,
                            "lon": 55.10716218788675
                        },
                        {
                            "lat": 61.42618152951369,
                            "lon": 55.10536750912527
                        },
                        {
                            "lat": 61.4262984440452,
                            "lon": 55.10357282966457
                        },
                        {
                            "lat": 61.429428578730246,
                            "lon": 55.10363981947256
                        },
                        {
                            "lat": 61.42931180416991,
                            "lon": 55.10543450337875
                        }
                    ],
                    [
                        {
                            "lat": 61.40078562110907,
                            "lon": 55.11021258886214
                        },
                        {
                            "lat": 61.40391621102721,
                            "lon": 55.110280239494585
                        },
                        {
                            "lat": 61.40704681409288,
                            "lon": 55.110347809749584
                        },
                        {
                            "lat": 61.406929007259784,
                            "lon": 55.112142459619136
                        },
                        {
                            "lat": 61.4037982641746,
                            "lon": 55.11207488487984
                        },
                        {
                            "lat": 61.40066753423899,
                            "lon": 55.11200722975776
                        },
                        {
                            "lat": 61.40078562110907,
                            "lon": 55.11021258886214
                        }
                    ],
                    [
                        {
                            "lat": 61.39765504435407,
                            "lon": 55.110144857852966
                        },
                        {
                            "lat": 61.39753681746848,
                            "lon": 55.11193949425366
                        },
                        {
                            "lat": 61.39440611387868,
                            "lon": 55.11187167836824
                        },
                        {
                            "lat": 61.39452448077776,
                            "lon": 55.11007704646781
                        },
                        {
                            "lat": 61.39765504435407,
                            "lon": 55.110144857852966
                        }
                    ],
                    [
                        {
                            "lat": 61.41330805960474,
                            "lon": 55.11048270912427
                        },
                        {
                            "lat": 61.413190532816614,
                            "lon": 55.11227736794647
                        },
                        {
                            "lat": 61.41005976347896,
                            "lon": 55.112209953974926
                        },
                        {
                            "lat": 61.41017743029059,
                            "lon": 55.110415299626396
                        },
                        {
                            "lat": 61.41330805960474,
                            "lon": 55.11048270912427
                        }
                    ],
                    [
                        {
                            "lat": 61.4159690764372,
                            "lon": 55.11772868720163
                        },
                        {
                            "lat": 61.41585163731327,
                            "lon": 55.11952334769013
                        },
                        {
                            "lat": 61.41272029461524,
                            "lon": 55.11945599622618
                        },
                        {
                            "lat": 61.41283787382688,
                            "lon": 55.11766134020764
                        },
                        {
                            "lat": 61.4159690764372,
                            "lon": 55.11772868720163
                        }
                    ],
                    [
                        {
                            "lat": 61.47244785168494,
                            "lon": 55.117132442971624
                        },
                        {
                            "lat": 61.46931655588489,
                            "lon": 55.11706654769266
                        },
                        {
                            "lat": 61.46943158755993,
                            "lon": 55.115271810641815
                        },
                        {
                            "lat": 61.47256274326798,
                            "lon": 55.11533770154742
                        },
                        {
                            "lat": 61.47244785168494,
                            "lon": 55.117132442971624
                        }
                    ],
                    [
                        {
                            "lat": 61.47267762204186,
                            "lon": 55.1135429594298
                        },
                        {
                            "lat": 61.47580865048065,
                            "lon": 55.1136087655637
                        },
                        {
                            "lat": 61.475693911785164,
                            "lon": 55.11540351204894
                        },
                        {
                            "lat": 61.47256274326798,
                            "lon": 55.11533770154742
                        },
                        {
                            "lat": 61.47267762204186,
                            "lon": 55.1135429594298
                        }
                    ],
                    [
                        {
                            "lat": 61.444963372397716,
                            "lon": 55.10576826912129
                        },
                        {
                            "lat": 61.441833032811104,
                            "lon": 55.10570167671185
                        },
                        {
                            "lat": 61.44194924746695,
                            "lon": 55.10390697507694
                        },
                        {
                            "lat": 61.44507944707252,
                            "lon": 55.10397356306748
                        },
                        {
                            "lat": 61.444963372397716,
                            "lon": 55.10576826912129
                        }
                    ],
                    [
                        {
                            "lat": 61.491463984237875,
                            "lon": 55.11393659022704
                        },
                        {
                            "lat": 61.48833289199655,
                            "lon": 55.11387118609757
                        },
                        {
                            "lat": 61.4884470576297,
                            "lon": 55.112076421503524
                        },
                        {
                            "lat": 61.491578009798495,
                            "lon": 55.11214182129235
                        },
                        {
                            "lat": 61.491463984237875,
                            "lon": 55.11393659022704
                        }
                    ],
                    [
                        {
                            "lat": 61.348771549571914,
                            "lon": 55.09110455447993
                        },
                        {
                            "lat": 61.34865121689539,
                            "lon": 55.092899125331236
                        },
                        {
                            "lat": 61.351780321775905,
                            "lon": 55.09296810106531
                        },
                        {
                            "lat": 61.35190051462461,
                            "lon": 55.091173525638034
                        },
                        {
                            "lat": 61.348771549571914,
                            "lon": 55.09110455447993
                        }
                    ],
                    [
                        {
                            "lat": 61.35888948475755,
                            "lon": 55.12727160524242
                        },
                        {
                            "lat": 61.35876944407803,
                            "lon": 55.129066180210785
                        },
                        {
                            "lat": 61.36190140242278,
                            "lon": 55.129134925851695
                        },
                        {
                            "lat": 61.36202130295533,
                            "lon": 55.12734034632052
                        },
                        {
                            "lat": 61.35888948475755,
                            "lon": 55.12727160524242
                        }
                    ],
                    [
                        {
                            "lat": 61.37380364408889,
                            "lon": 55.09165407497579
                        },
                        {
                            "lat": 61.37067458562101,
                            "lon": 55.091585666015156
                        },
                        {
                            "lat": 61.370555231781836,
                            "lon": 55.09338026878653
                        },
                        {
                            "lat": 61.373684430091714,
                            "lon": 55.09344868228592
                        },
                        {
                            "lat": 61.37380364408889,
                            "lon": 55.09165407497579
                        }
                    ],
                    [
                        {
                            "lat": 61.38295329697139,
                            "lon": 55.09544806103754
                        },
                        {
                            "lat": 61.38283447603363,
                            "lon": 55.097242680522484
                        },
                        {
                            "lat": 61.38596400713675,
                            "lon": 55.09731078176476
                        },
                        {
                            "lat": 61.386082688193426,
                            "lon": 55.09551615776149
                        },
                        {
                            "lat": 61.38295329697139,
                            "lon": 55.09544806103754
                        }
                    ],
                    [
                        {
                            "lat": 61.377680594183374,
                            "lon": 55.127682845380605
                        },
                        {
                            "lat": 61.377561394416006,
                            "lon": 55.12947744764614
                        },
                        {
                            "lat": 61.380693432759514,
                            "lon": 55.129545710717785
                        },
                        {
                            "lat": 61.380812492367795,
                            "lon": 55.12775110392134
                        },
                        {
                            "lat": 61.377680594183374,
                            "lon": 55.127682845380605
                        }
                    ],
                    [
                        {
                            "lat": 61.39547094114624,
                            "lon": 55.09571996594744
                        },
                        {
                            "lat": 61.39535267974497,
                            "lon": 55.097514603473705
                        },
                        {
                            "lat": 61.39848226366218,
                            "lon": 55.0975823833683
                        },
                        {
                            "lat": 61.398600385174326,
                            "lon": 55.09578774134497
                        },
                        {
                            "lat": 61.39547094114624,
                            "lon": 55.09571996594744
                        }
                    ],
                    [
                        {
                            "lat": 61.437421948706806,
                            "lon": 55.12537662720157
                        },
                        {
                            "lat": 61.437305438295425,
                            "lon": 55.127171316039515
                        },
                        {
                            "lat": 61.44043744588979,
                            "lon": 55.12723804194051
                        },
                        {
                            "lat": 61.440553816135285,
                            "lon": 55.12544334867362
                        },
                        {
                            "lat": 61.437421948706806,
                            "lon": 55.12537662720157
                        }
                    ],
                    [
                        {
                            "lat": 61.42477753801574,
                            "lon": 55.12690360810895
                        },
                        {
                            "lat": 61.424660453904174,
                            "lon": 55.1286982784784
                        },
                        {
                            "lat": 61.42779254960181,
                            "lon": 55.128765330559226
                        },
                        {
                            "lat": 61.42790949353985,
                            "lon": 55.126970655739115
                        },
                        {
                            "lat": 61.42477753801574,
                            "lon": 55.12690360810895
                        }
                    ],
                    [
                        {
                            "lat": 61.42454335673411,
                            "lon": 55.13049294814847
                        },
                        {
                            "lat": 61.42442624650355,
                            "lon": 55.1322876171191
                        },
                        {
                            "lat": 61.427558622595015,
                            "lon": 55.132354678102416
                        },
                        {
                            "lat": 61.42767559262086,
                            "lon": 55.13056000468034
                        },
                        {
                            "lat": 61.42454335673411,
                            "lon": 55.13049294814847
                        }
                    ]
                ]
            },
            {
                "type": "MultiLineString",
                "value": 1.0,
                "data": [
                    [
                        {
                            "lat": 61.50063008851605,
                            "lon": 55.11772188197422
                        },
                        {
                            "lat": 61.49749867797675,
                            "lon": 55.11765671040543
                        },
                        {
                            "lat": 61.49738489451316,
                            "lon": 55.11945148593457
                        },
                        {
                            "lat": 61.50051644517749,
                            "lon": 55.11951666182921
                        },
                        {
                            "lat": 61.50063008851605,
                            "lon": 55.11772188197422
                        }
                    ],
                    [
                        {
                            "lat": 61.49715728951874,
                            "lon": 55.12304103492191
                        },
                        {
                            "lat": 61.497043467984035,
                            "lon": 55.124835808380055
                        },
                        {
                            "lat": 61.50017543911688,
                            "lon": 55.124900997254535
                        },
                        {
                            "lat": 61.50006174507529,
                            "lon": 55.12669577434972
                        },
                        {
                            "lat": 61.50319386907603,
                            "lon": 55.126760887109164
                        },
                        {
                            "lat": 61.50308030255871,
                            "lon": 55.12855566783684
                        },
                        {
                            "lat": 61.5062125794312,
                            "lon": 55.12862070447028
                        },
                        {
                            "lat": 61.50934486895501,
                            "lon": 55.128685660654554
                        },
                        {
                            "lat": 61.509231570217445,
                            "lon": 55.13048044932229
                        },
                        {
                            "lat": 61.51236401260318,
                            "lon": 55.13054532936356
                        },
                        {
                            "lat": 61.512250841469765,
                            "lon": 55.1323401216497
                        },
                        {
                            "lat": 61.515383436721265,
                            "lon": 55.132404925536974
                        },
                        {
                            "lat": 61.51851604458116,
                            "lon": 55.13246964896231
                        },
                        {
                            "lat": 61.518628935225216,
                            "lon": 55.130674848077696
                        },
                        {
                            "lat": 61.515496467610966,
                            "lon": 55.13061012894892
                        },
                        {
                            "lat": 61.51560948589421,
                            "lon": 55.12881533167281
                        },
                        {
                            "lat": 61.512477171114554,
                            "lon": 55.12875053638898
                        },
                        {
                            "lat": 61.51259031700576,
                            "lon": 55.126955742725926
                        },
                        {
                            "lat": 61.50945815505685,
                            "lon": 55.12689087129798
                        },
                        {
                            "lat": 61.50632600574175,
                            "lon": 55.12682591942549
                        },
                        {
                            "lat": 61.50643941940288,
                            "lon": 55.125031133691515
                        },
                        {
                            "lat": 61.50655282041653,
                            "lon": 55.123236347268325
                        },
                        {
                            "lat": 61.509684689361016,
                            "lon": 55.12330129051838
                        },
                        {
                            "lat": 61.51281657093546,
                            "lon": 55.12336615333455
                        },
                        {
                            "lat": 61.51292967897777,
                            "lon": 55.121571357606236
                        },
                        {
                            "lat": 61.51606143300083,
                            "lon": 55.12163613568782
                        },
                        {
                            "lat": 61.519193199620766,
                            "lon": 55.12170083333941
                        },
                        {
                            "lat": 61.52232497882199,
                            "lon": 55.12176545056036
                        },
                        {
                            "lat": 61.525456770588974,
                            "lon": 55.12182998734989
                        },
                        {
                            "lat": 61.52858857490609,
                            "lon": 55.121894443707355
                        },
                        {
                            "lat": 61.528700969565826,
                            "lon": 55.12009962584587
                        },
                        {
                            "lat": 61.525569305406556,
                            "lon": 55.120035173766716
                        },
                        {
                            "lat": 61.52243765379553,
                            "lon": 55.11997064126079
                        },
                        {
                            "lat": 61.5193060147483,
                            "lon": 55.11990602832881
                        },
                        {
                            "lat": 61.516174388280476,
                            "lon": 55.1198413349715
                        },
                        {
                            "lat": 61.51304277440762,
                            "lon": 55.119776561189525
                        },
                        {
                            "lat": 61.509911173145305,
                            "lon": 55.119711706983594
                        },
                        {
                            "lat": 61.506779584509125,
                            "lon": 55.11964677235443
                        },
                        {
                            "lat": 61.503648008514666,
                            "lon": 55.11958175730272
                        },
                        {
                            "lat": 61.50051644517749,
                            "lon": 55.11951666182921
                        },
                        {
                            "lat": 61.50040278916605,
                            "lon": 55.121311440994255
                        },
                        {
                            "lat": 61.497271098361146,
                            "lon": 55.121246260773376
                        },
                        {
                            "lat": 61.49715728951874,
                            "lon": 55.12304103492191
                        }
                    ],
                    [
                        {
                            "lat": 61.53798406300242,
                            "lon": 55.12208733018023
                        },
                        {
                            "lat": 61.53485222112837,
                            "lon": 55.12202311512322
                        },
                        {
                            "lat": 61.534740094286605,
                            "lon": 55.123817940839615
                        },
                        {
                            "lat": 61.53787207633976,
                            "lon": 55.123882160159326
                        },
                        {
                            "lat": 61.53798406300242,
                            "lon": 55.12208733018023
                        }
                    ],
                    [
                        {
                            "lat": 61.53787207633976,
                            "lon": 55.123882160159326
                        },
                        {
                            "lat": 61.53776007718741,
                            "lon": 55.12567698945308
                        },
                        {
                            "lat": 61.540892211926874,
                            "lon": 55.125741132590356
                        },
                        {
                            "lat": 61.54100407088262,
                            "lon": 55.12394629903883
                        },
                        {
                            "lat": 61.53787207633976,
                            "lon": 55.123882160159326
                        }
                    ],
                    [
                        {
                            "lat": 61.540892211926874,
                            "lon": 55.125741132590356
                        },
                        {
                            "lat": 61.540780340495104,
                            "lon": 55.12753596545689
                        },
                        {
                            "lat": 61.54066845658544,
                            "lon": 55.12933079763845
                        },
                        {
                            "lat": 61.54055656019595,
                            "lon": 55.131125629134985
                        },
                        {
                            "lat": 61.54044465132476,
                            "lon": 55.132920459946504
                        },
                        {
                            "lat": 61.543577359490165,
                            "lon": 55.1329845396511
                        },
                        {
                            "lat": 61.54368912810049,
                            "lon": 55.131189704585616
                        },
                        {
                            "lat": 61.543800884244696,
                            "lon": 55.12939486883549
                        },
                        {
                            "lat": 61.54391262792468,
                            "lon": 55.12760003240074
                        },
                        {
                            "lat": 61.544024359142355,
                            "lon": 55.12580519528139
                        },
                        {
                            "lat": 61.540892211926874,
                            "lon": 55.125741132590356
                        }
                    ],
                    [
                        {
                            "lat": 61.52466867535501,
                            "lon": 55.134393663199255
                        },
                        {
                            "lat": 61.52478129806366,
                            "lon": 55.13259885442439
                        },
                        {
                            "lat": 61.52164866503381,
                            "lon": 55.13253429192503
                        },
                        {
                            "lat": 61.51851604458116,
                            "lon": 55.13246964896231
                        },
                        {
                            "lat": 61.51840314134431,
                            "lon": 55.13426444915925
                        },
                        {
                            "lat": 61.52153590206011,
                            "lon": 55.13432909641358
                        },
                        {
                            "lat": 61.52466867535501,
                            "lon": 55.134393663199255
                        }
                    ],
                    [
                        {
                            "lat": 61.530934259619706,
                            "lon": 55.13452255536179
                        },
                        {
                            "lat": 61.527801461213414,
                            "lon": 55.13445814951555
                        },
                        {
                            "lat": 61.52768896622382,
                            "lon": 55.13625296188485
                        },
                        {
                            "lat": 61.530821904914575,
                            "lon": 55.13631737200711
                        },
                        {
                            "lat": 61.53395485613954,
                            "lon": 55.13638170165327
                        },
                        {
                            "lat": 61.53406707055828,
                            "lon": 55.134586880737245
                        },
                        {
                            "lat": 61.530934259619706,
                            "lon": 55.13452255536179
                        }
                    ]
                ]
            },
            {
                "type": "MultiLineString",
                "value": 2.0,
                "data": [
                    [
                        {
                            "lat": 61.509684689361016,
                            "lon": 55.12330129051838
                        },
                        {
                            "lat": 61.50655282041653,
                            "lon": 55.123236347268325
                        },
                        {
                            "lat": 61.50643941940288,
                            "lon": 55.125031133691515
                        },
                        {
                            "lat": 61.50632600574175,
                            "lon": 55.12682591942549
                        },
                        {
                            "lat": 61.50945815505685,
                            "lon": 55.12689087129798
                        },
                        {
                            "lat": 61.51259031700576,
                            "lon": 55.126955742725926
                        },
                        {
                            "lat": 61.512477171114554,
                            "lon": 55.12875053638898
                        },
                        {
                            "lat": 61.51560948589421,
                            "lon": 55.12881533167281
                        },
                        {
                            "lat": 61.515496467610966,
                            "lon": 55.13061012894892
                        },
                        {
                            "lat": 61.518628935225216,
                            "lon": 55.130674848077696
                        },
                        {
                            "lat": 61.51851604458116,
                            "lon": 55.13246964896231
                        },
                        {
                            "lat": 61.52164866503381,
                            "lon": 55.13253429192503
                        },
                        {
                            "lat": 61.52478129806366,
                            "lon": 55.13259885442439
                        },
                        {
                            "lat": 61.52489390821072,
                            "lon": 55.13080404496257
                        },
                        {
                            "lat": 61.52176141543033,
                            "lon": 55.130739486749135
                        },
                        {
                            "lat": 61.52187415325159,
                            "lon": 55.12894468088596
                        },
                        {
                            "lat": 61.51874181327842,
                            "lon": 55.12888004650537
                        },
                        {
                            "lat": 61.518854678742656,
                            "lon": 55.12708524424537
                        },
                        {
                            "lat": 61.51896753161986,
                            "lon": 55.125290441297714
                        },
                        {
                            "lat": 61.51583548464895,
                            "lon": 55.1252257350564
                        },
                        {
                            "lat": 61.51594846512429,
                            "lon": 55.12343093571612
                        },
                        {
                            "lat": 61.519080371911905,
                            "lon": 55.123495637662394
                        },
                        {
                            "lat": 61.52221229128275,
                            "lon": 55.12356025917264
                        },
                        {
                            "lat": 61.52534422322124,
                            "lon": 55.123624800246205
                        },
                        {
                            "lat": 61.52847616771176,
                            "lon": 55.12368926088236
                        },
                        {
                            "lat": 61.531608124738746,
                            "lon": 55.123753641080384
                        },
                        {
                            "lat": 61.534740094286605,
                            "lon": 55.123817940839615
                        },
                        {
                            "lat": 61.53485222112837,
                            "lon": 55.12202311512322
                        },
                        {
                            "lat": 61.531720391757744,
                            "lon": 55.12195881963203
                        },
                        {
                            "lat": 61.52858857490609,
                            "lon": 55.121894443707355
                        },
                        {
                            "lat": 61.525456770588974,
                            "lon": 55.12182998734989
                        },
                        {
                            "lat": 61.52232497882199,
                            "lon": 55.12176545056036
                        },
                        {
                            "lat": 61.519193199620766,
                            "lon": 55.12170083333941
                        },
                        {
                            "lat": 61.51606143300083,
                            "lon": 55.12163613568782
                        },
                        {
                            "lat": 61.51292967897777,
                            "lon": 55.121571357606236
                        },
                        {
                            "lat": 61.51281657093546,
                            "lon": 55.12336615333455
                        },
                        {
                            "lat": 61.509684689361016,
                            "lon": 55.12330129051838
                        }
                    ],
                    [
                        {
                            "lat": 61.5279139436551,
                            "lon": 55.132663336459714
                        },
                        {
                            "lat": 61.52478129806366,
                            "lon": 55.13259885442439
                        },
                        {
                            "lat": 61.52466867535501,
                            "lon": 55.134393663199255
                        },
                        {
                            "lat": 61.527801461213414,
                            "lon": 55.13445814951555
                        },
                        {
                            "lat": 61.5279139436551,
                            "lon": 55.132663336459714
                        }
                    ],
                    [
                        {
                            "lat": 61.53719989401356,
                            "lon": 55.13465112564121
                        },
                        {
                            "lat": 61.53406707055828,
                            "lon": 55.134586880737245
                        },
                        {
                            "lat": 61.53395485613954,
                            "lon": 55.13638170165327
                        },
                        {
                            "lat": 61.537087819883105,
                            "lon": 55.136445950822626
                        },
                        {
                            "lat": 61.53719989401356,
                            "lon": 55.13465112564121
                        }
                    ]
                ]
            },
            {
                "type": "MultiLineString",
                "value": 3.0,
                "data": [
                    [
                        {
                            "lat": 61.51896753161986,
                            "lon": 55.125290441297714
                        },
                        {
                            "lat": 61.518854678742656,
                            "lon": 55.12708524424537
                        },
                        {
                            "lat": 61.51874181327842,
                            "lon": 55.12888004650537
                        },
                        {
                            "lat": 61.52187415325159,
                            "lon": 55.12894468088596
                        },
                        {
                            "lat": 61.52198687849945,
                            "lon": 55.12714987433546
                        },
                        {
                            "lat": 61.52209959117587,
                            "lon": 55.125355067097686
                        },
                        {
                            "lat": 61.52523166330145,
                            "lon": 55.12541961245566
                        },
                        {
                            "lat": 61.52534422322124,
                            "lon": 55.123624800246205
                        },
                        {
                            "lat": 61.52221229128275,
                            "lon": 55.12356025917264
                        },
                        {
                            "lat": 61.519080371911905,
                            "lon": 55.123495637662394
                        },
                        {
                            "lat": 61.51594846512429,
                            "lon": 55.12343093571612
                        },
                        {
                            "lat": 61.51583548464895,
                            "lon": 55.1252257350564
                        },
                        {
                            "lat": 61.51896753161986,
                            "lon": 55.125290441297714
                        }
                    ],
                    [
                        {
                            "lat": 61.53462795493954,
                            "lon": 55.12561276587029
                        },
                        {
                            "lat": 61.53776007718741,
                            "lon": 55.12567698945308
                        },
                        {
                            "lat": 61.53787207633976,
                            "lon": 55.123882160159326
                        },
                        {
                            "lat": 61.534740094286605,
                            "lon": 55.123817940839615
                        },
                        {
                            "lat": 61.53462795493954,
                            "lon": 55.12561276587029
                        }
                    ],
                    [
                        {
                            "lat": 61.52176141543033,
                            "lon": 55.130739486749135
                        },
                        {
                            "lat": 61.52489390821072,
                            "lon": 55.13080404496257
                        },
                        {
                            "lat": 61.5250065057981,
                            "lon": 55.12900923481385
                        },
                        {
                            "lat": 61.52187415325159,
                            "lon": 55.12894468088596
                        },
                        {
                            "lat": 61.52176141543033,
                            "lon": 55.130739486749135
                        }
                    ],
                    [
                        {
                            "lat": 61.5279139436551,
                            "lon": 55.132663336459714
                        },
                        {
                            "lat": 61.52802641355079,
                            "lon": 55.130868522717286
                        },
                        {
                            "lat": 61.52489390821072,
                            "lon": 55.13080404496257
                        },
                        {
                            "lat": 61.52478129806366,
                            "lon": 55.13259885442439
                        },
                        {
                            "lat": 61.5279139436551,
                            "lon": 55.132663336459714
                        }
                    ],
                    [
                        {
                            "lat": 61.530934259619706,
                            "lon": 55.13452255536179
                        },
                        {
                            "lat": 61.53104660179251,
                            "lon": 55.13272773803027
                        },
                        {
                            "lat": 61.5279139436551,
                            "lon": 55.132663336459714
                        },
                        {
                            "lat": 61.527801461213414,
                            "lon": 55.13445814951555
                        },
                        {
                            "lat": 61.530934259619706,
                            "lon": 55.13452255536179
                        }
                    ]
                ]
            },
            {
                "type": "MultiLineString",
                "value": 4.0,
                "data": [
                    [
                        {
                            "lat": 61.52534422322124,
                            "lon": 55.123624800246205
                        },
                        {
                            "lat": 61.52523166330145,
                            "lon": 55.12541961245566
                        },
                        {
                            "lat": 61.52836374798098,
                            "lon": 55.125484077370864
                        },
                        {
                            "lat": 61.52847616771176,
                            "lon": 55.12368926088236
                        },
                        {
                            "lat": 61.52534422322124,
                            "lon": 55.123624800246205
                        }
                    ],
                    [
                        {
                            "lat": 61.52198687849945,
                            "lon": 55.12714987433546
                        },
                        {
                            "lat": 61.52187415325159,
                            "lon": 55.12894468088596
                        },
                        {
                            "lat": 61.5250065057981,
                            "lon": 55.12900923481385
                        },
                        {
                            "lat": 61.525119090827715,
                            "lon": 55.1272144239782
                        },
                        {
                            "lat": 61.52523166330145,
                            "lon": 55.12541961245566
                        },
                        {
                            "lat": 61.52209959117587,
                            "lon": 55.125355067097686
                        },
                        {
                            "lat": 61.52198687849945,
                            "lon": 55.12714987433546
                        }
                    ],
                    [
                        {
                            "lat": 61.53764806554345,
                            "lon": 55.12747181806148
                        },
                        {
                            "lat": 61.540780340495104,
                            "lon": 55.12753596545689
                        },
                        {
                            "lat": 61.540892211926874,
                            "lon": 55.125741132590356
                        },
                        {
                            "lat": 61.53776007718741,
                            "lon": 55.12567698945308
                        },
                        {
                            "lat": 61.53764806554345,
                            "lon": 55.12747181806148
                        }
                    ],
                    [
                        {
                            "lat": 61.52489390821072,
                            "lon": 55.13080404496257
                        },
                        {
                            "lat": 61.52802641355079,
                            "lon": 55.130868522717286
                        },
                        {
                            "lat": 61.5281388709024,
                            "lon": 55.129073708288345
                        },
                        {
                            "lat": 61.5250065057981,
                            "lon": 55.12900923481385
                        },
                        {
                            "lat": 61.52489390821072,
                            "lon": 55.13080404496257
                        }
                    ],
                    [
                        {
                            "lat": 61.5279139436551,
                            "lon": 55.132663336459714
                        },
                        {
                            "lat": 61.53104660179251,
                            "lon": 55.13272773803027
                        },
                        {
                            "lat": 61.531158931434945,
                            "lon": 55.13093292001259
                        },
                        {
                            "lat": 61.52802641355079,
                            "lon": 55.130868522717286
                        },
                        {
                            "lat": 61.5279139436551,
                            "lon": 55.132663336459714
                        }
                    ]
                ]
            },
            {
                "type": "MultiLineString",
                "value": 5.0,
                "data": [
                    [
                        {
                            "lat": 61.52836374798098,
                            "lon": 55.125484077370864
                        },
                        {
                            "lat": 61.53149584519887,
                            "lon": 55.12554846184265
                        },
                        {
                            "lat": 61.53462795493954,
                            "lon": 55.12561276587029
                        },
                        {
                            "lat": 61.534740094286605,
                            "lon": 55.123817940839615
                        },
                        {
                            "lat": 61.531608124738746,
                            "lon": 55.123753641080384
                        },
                        {
                            "lat": 61.52847616771176,
                            "lon": 55.12368926088236
                        },
                        {
                            "lat": 61.52836374798098,
                            "lon": 55.125484077370864
                        }
                    ],
                    [
                        {
                            "lat": 61.52523166330145,
                            "lon": 55.12541961245566
                        },
                        {
                            "lat": 61.525119090827715,
                            "lon": 55.1272144239782
                        },
                        {
                            "lat": 61.5250065057981,
                            "lon": 55.12900923481385
                        },
                        {
                            "lat": 61.5281388709024,
                            "lon": 55.129073708288345
                        },
                        {
                            "lat": 61.52825131571183,
                            "lon": 55.12727889317286
                        },
                        {
                            "lat": 61.52836374798098,
                            "lon": 55.125484077370864
                        },
                        {
                            "lat": 61.52523166330145,
                            "lon": 55.12541961245566
                        }
                    ],
                    [
                        {
                            "lat": 61.530934259619706,
                            "lon": 55.13452255536179
                        },
                        {
                            "lat": 61.53406707055828,
                            "lon": 55.134586880737245
                        },
                        {
                            "lat": 61.534179272460335,
                            "lon": 55.1327920591354
                        },
                        {
                            "lat": 61.53104660179251,
                            "lon": 55.13272773803027
                        },
                        {
                            "lat": 61.530934259619706,
                            "lon": 55.13452255536179
                        }
                    ]
                ]
            },
            {
                "type": "MultiLineString",
                "value": 6.0,
                "data": [
                    [
                        {
                            "lat": 61.53127124854886,
                            "lon": 55.12913810130876
                        },
                        {
                            "lat": 61.5281388709024,
                            "lon": 55.129073708288345
                        },
                        {
                            "lat": 61.52802641355079,
                            "lon": 55.130868522717286
                        },
                        {
                            "lat": 61.531158931434945,
                            "lon": 55.13093292001259
                        },
                        {
                            "lat": 61.53127124854886,
                            "lon": 55.12913810130876
                        }
                    ],
                    [
                        {
                            "lat": 61.54066845658544,
                            "lon": 55.12933079763845
                        },
                        {
                            "lat": 61.53753604140599,
                            "lon": 55.12926664598451
                        },
                        {
                            "lat": 61.5374240047731,
                            "lon": 55.13106147322215
                        },
                        {
                            "lat": 61.54055656019595,
                            "lon": 55.131125629134985
                        },
                        {
                            "lat": 61.54066845658544,
                            "lon": 55.12933079763845
                        }
                    ]
                ]
            },
            {
                "type": "LineString",
                "value": 7.0,
                "data": [
                    [
                        {
                            "lat": 61.54066845658544,
                            "lon": 55.12933079763845
                        },
                        {
                            "lat": 61.540780340495104,
                            "lon": 55.12753596545689
                        },
                        {
                            "lat": 61.53764806554345,
                            "lon": 55.12747181806148
                        },
                        {
                            "lat": 61.53753604140599,
                            "lon": 55.12926664598451
                        },
                        {
                            "lat": 61.54066845658544,
                            "lon": 55.12933079763845
                        }
                    ]
                ]
            },
            {
                "type": "MultiLineString",
                "value": 8.0,
                "data": [
                    [
                        {
                            "lat": 61.52836374798098,
                            "lon": 55.125484077370864
                        },
                        {
                            "lat": 61.52825131571183,
                            "lon": 55.12727889317286
                        },
                        {
                            "lat": 61.5281388709024,
                            "lon": 55.129073708288345
                        },
                        {
                            "lat": 61.53127124854886,
                            "lon": 55.12913810130876
                        },
                        {
                            "lat": 61.531383553136216,
                            "lon": 55.12734328191876
                        },
                        {
                            "lat": 61.53149584519887,
                            "lon": 55.12554846184265
                        },
                        {
                            "lat": 61.52836374798098,
                            "lon": 55.125484077370864
                        }
                    ],
                    [
                        {
                            "lat": 61.53462795493954,
                            "lon": 55.12561276587029
                        },
                        {
                            "lat": 61.53451580308529,
                            "lon": 55.12740759021522
                        },
                        {
                            "lat": 61.53764806554345,
                            "lon": 55.12747181806148
                        },
                        {
                            "lat": 61.53776007718741,
                            "lon": 55.12567698945308
                        },
                        {
                            "lat": 61.53462795493954,
                            "lon": 55.12561276587029
                        }
                    ],
                    [
                        {
                            "lat": 61.531158931434945,
                            "lon": 55.13093292001259
                        },
                        {
                            "lat": 61.53104660179251,
                            "lon": 55.13272773803027
                        },
                        {
                            "lat": 61.534179272460335,
                            "lon": 55.1327920591354
                        },
                        {
                            "lat": 61.53429146184758,
                            "lon": 55.130997236847776
                        },
                        {
                            "lat": 61.531158931434945,
                            "lon": 55.13093292001259
                        }
                    ]
                ]
            },
            {
                "type": "MultiLineString",
                "value": 9.0,
                "data": [
                    [
                        {
                            "lat": 61.53440363872194,
                            "lon": 55.129202413874374
                        },
                        {
                            "lat": 61.53127124854886,
                            "lon": 55.12913810130876
                        },
                        {
                            "lat": 61.531158931434945,
                            "lon": 55.13093292001259
                        },
                        {
                            "lat": 61.53429146184758,
                            "lon": 55.130997236847776
                        },
                        {
                            "lat": 61.53440363872194,
                            "lon": 55.129202413874374
                        }
                    ],
                    [
                        {
                            "lat": 61.54044465132476,
                            "lon": 55.132920459946504
                        },
                        {
                            "lat": 61.53731195564295,
                            "lon": 55.13285629977438
                        },
                        {
                            "lat": 61.53719989401356,
                            "lon": 55.13465112564121
                        },
                        {
                            "lat": 61.54033272996996,
                            "lon": 55.134715290073004
                        },
                        {
                            "lat": 61.54044465132476,
                            "lon": 55.132920459946504
                        }
                    ]
                ]
            },
            {
                "type": "MultiLineString",
                "value": 10.0,
                "data": [
                    [
                        {
                            "lat": 61.53462795493954,
                            "lon": 55.12561276587029
                        },
                        {
                            "lat": 61.53149584519887,
                            "lon": 55.12554846184265
                        },
                        {
                            "lat": 61.531383553136216,
                            "lon": 55.12734328191876
                        },
                        {
                            "lat": 61.53451580308529,
                            "lon": 55.12740759021522
                        },
                        {
                            "lat": 61.53462795493954,
                            "lon": 55.12561276587029
                        }
                    ],
                    [
                        {
                            "lat": 61.53753604140599,
                            "lon": 55.12926664598451
                        },
                        {
                            "lat": 61.53440363872194,
                            "lon": 55.129202413874374
                        },
                        {
                            "lat": 61.53429146184758,
                            "lon": 55.130997236847776
                        },
                        {
                            "lat": 61.5374240047731,
                            "lon": 55.13106147322215
                        },
                        {
                            "lat": 61.53753604140599,
                            "lon": 55.12926664598451
                        }
                    ]
                ]
            },
            {
                "type": "MultiLineString",
                "value": 13.0,
                "data": [
                    [
                        {
                            "lat": 61.53451580308529,
                            "lon": 55.12740759021522
                        },
                        {
                            "lat": 61.531383553136216,
                            "lon": 55.12734328191876
                        },
                        {
                            "lat": 61.53127124854886,
                            "lon": 55.12913810130876
                        },
                        {
                            "lat": 61.53440363872194,
                            "lon": 55.129202413874374
                        },
                        {
                            "lat": 61.53451580308529,
                            "lon": 55.12740759021522
                        }
                    ],
                    [
                        {
                            "lat": 61.53731195564295,
                            "lon": 55.13285629977438
                        },
                        {
                            "lat": 61.534179272460335,
                            "lon": 55.1327920591354
                        },
                        {
                            "lat": 61.53406707055828,
                            "lon": 55.134586880737245
                        },
                        {
                            "lat": 61.53719989401356,
                            "lon": 55.13465112564121
                        },
                        {
                            "lat": 61.53731195564295,
                            "lon": 55.13285629977438
                        }
                    ]
                ]
            },
            {
                "type": "LineString",
                "value": 16.0,
                "data": [
                    [
                        {
                            "lat": 61.53753604140599,
                            "lon": 55.12926664598451
                        },
                        {
                            "lat": 61.53764806554345,
                            "lon": 55.12747181806148
                        },
                        {
                            "lat": 61.53451580308529,
                            "lon": 55.12740759021522
                        },
                        {
                            "lat": 61.53440363872194,
                            "lon": 55.129202413874374
                        },
                        {
                            "lat": 61.53753604140599,
                            "lon": 55.12926664598451
                        }
                    ]
                ]
            },
            {
                "type": "LineString",
                "value": 17.0,
                "data": [
                    [
                        {
                            "lat": 61.54044465132476,
                            "lon": 55.132920459946504
                        },
                        {
                            "lat": 61.54055656019595,
                            "lon": 55.131125629134985
                        },
                        {
                            "lat": 61.5374240047731,
                            "lon": 55.13106147322215
                        },
                        {
                            "lat": 61.53731195564295,
                            "lon": 55.13285629977438
                        },
                        {
                            "lat": 61.54044465132476,
                            "lon": 55.132920459946504
                        }
                    ]
                ]
            },
            {
                "type": "LineString",
                "value": 22.0,
                "data": [
                    [
                        {
                            "lat": 61.53731195564295,
                            "lon": 55.13285629977438
                        },
                        {
                            "lat": 61.5374240047731,
                            "lon": 55.13106147322215
                        },
                        {
                            "lat": 61.53429146184758,
                            "lon": 55.130997236847776
                        },
                        {
                            "lat": 61.534179272460335,
                            "lon": 55.1327920591354
                        },
                        {
                            "lat": 61.53731195564295,
                            "lon": 55.13285629977438
                        }
                    ]
                ]
            }
        ]
    },
    "metadata": {
        "pollutant": "H2S",
        "datetime": "2023-01-14T23:00:00",
        "wind_speed_kph": 0.85,
        "wind_dir_deg": 80.0,
        "point_pollution_centres": {
            "0118": {
                "lat": 61.54417001842768,
                "lon": 55.11159832246242
            },
            "0207": {
                "lat": 61.50385415938939,
                "lon": 55.14616751666491
            },
            "0004": {
                "lat": 61.54091803667068,
                "lon": 55.11058201759424
            },
            "0042": {
                "lat": 61.54875204683935,
                "lon": 55.11121656749404
            },
            "0304": {
                "lat": 61.49687151825445,
                "lon": 55.145072532729884
            },
            "0310": {
                "lat": 61.495228976875694,
                "lon": 55.14480019824957
            },
            "0027": {
                "lat": 61.49507150539657,
                "lon": 55.14075874241352
            },
            "0048": {
                "lat": 61.50083950257853,
                "lon": 55.14135324812144
            },
            "0110": {
                "lat": 61.50059888383153,
                "lon": 55.145150089879394
            },
            "0111": {
                "lat": 61.498219348590595,
                "lon": 55.143437339141975
            },
            "0010": {
                "lat": 61.54119946505001,
                "lon": 55.112606849082105
            },
            "0063": {
                "lat": 61.53905458442004,
                "lon": 55.13370789345574
            }
        },
        "areal_pollution_centres": {
            "156": {
                "lat": 61.53972200625556,
                "lon": 55.12967084736995
            },
            "157": {
                "lat": 61.53729779687739,
                "lon": 55.12881248255793
            }
        }
    }
};