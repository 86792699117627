import React, {useState} from 'react';
import {localeService} from '../../services/locale.service';
import {useTranslation} from 'react-i18next';
import AddVirtualDevice from './AddVirtualDevice';
import AddModbusDevice from './AddModbusDevice';
import AddMQTTDevice from './AddMQTTDevice';
import {canDo} from '../../services/permitions.service';

function AddDevice() {
  const [deviceType, setDeviceType] = useState(null);
  const {t} = useTranslation();

  return <>
    <div className={'page-header pt-5 mb-5'}>
      <div className={'container'}>
        <h2>{localeService.isRussian() ? 'Подключение устройства' : 'Add Device'}</h2>

        {(canDo('ROLE_ADD_NEW_DEVICE') || canDo('ROLE_ADD_DELETE_DEVICE') || canDo('ROLE_CREATE_DEVICE')) &&
        <div className={'mt-4'}>
          <label className={'ms-3'}>{localeService.isRussian() ? 'Выберите тип подключаемого устройства' : 'Select device type'}</label>
          <ul className="mt-3 nav nav-tabs justify-content-start" style={{marginBottom: '-2rem'}}>
            <li className="nav-item">
              <button type="button" className={'nav-link ' + (deviceType === 'mqtt' ? 'active' : '')}
                      onClick={() => setDeviceType('mqtt')}>MQTT
              </button>
            </li>
            <li className="nav-item">
              <button type="button" className={'nav-link ' + (deviceType === 'modbus' ? 'active' : '')}
                      onClick={() => setDeviceType('modbus')}>MODBUS
              </button>
            </li>
            <li className="nav-item">
              <button type="button" className={'nav-link ' + (deviceType === 'virtual' ? 'active' : '')}
                      onClick={() => setDeviceType('virtual')}>{t('virtual')}</button>
            </li>
          </ul>
        </div>
        }


      </div>
    </div>
    <div className={'container-700'}>
      {!(canDo('ROLE_ADD_NEW_DEVICE') || canDo('ROLE_ADD_DELETE_DEVICE') || canDo('ROLE_CREATE_DEVICE')) &&
      <div className={'row'}>
        <div
          className="alert alert-danger">{localeService.isRussian() ? 'У Вас не достаточно прав для добавления нового устройства' : 'You do not have enough rights to add a new device'}
        </div>
      </div>
      }
      {deviceType === 'modbus' && <AddModbusDevice/>}
      {deviceType === 'virtual' && <AddVirtualDevice/>}
      {deviceType === 'mqtt' && <AddMQTTDevice/>}
    </div>
  </>;

}

export default AddDevice;