/*eslint-disable no-unused-vars*/

import React from 'react';
import {ReactSVG} from 'react-svg';
import {setDatetimeFilter, zoomOut} from '../../redux/actions/dashboardActions';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import SelectTimeRange from '../../components/SelectTimeRange';
import svgOut from '../../sources/images/interface/zoom-out.svg';
import '../../styles/filter-panel.scss';
import {localeService} from '../../services/locale.service';
import svgEdit from '../../sources/images/interface/settings.svg';
import svgSave from '../../sources/images/interface/save.svg';
import {useHistory} from 'react-router-dom';
import Modal from '../../components/Modal';
import Loader from '../../components/Loader';
import SelectTimerInterval from '../../components/SelectTimerInterval';
import moment from 'moment';
import {dashboardConstants} from '../../constants';
import {dashboardService} from '../../services';
import CheckBox from '../../components/CheckBox';
import WidgetLoader from '../../components/WidgetLoader';

function DashboardFilterPanel({datetimeFilter, setDatetimeFilter, zoomHistory, zoomOut, dashboardId, dashboard, widgetList}) {
    const history = useHistory();
    const [saveOpen, setSaveOpen] = React.useState(false);
    const [saveFetching, setSaveFetching] = React.useState(false);
    const [isDefaultDashboard, setDefaultDashboard] = React.useState(false);

    React.useEffect(() => {
        let defaultDashboard = localStorage.getItem('ems_default_dashboard_id');
        if(defaultDashboard !== undefined && defaultDashboard === dashboardId){
            setDefaultDashboard(true);
        }
    }, []);

    React.useEffect(() => {
        if(datetimeFilter.interval.value !== 0){
            let id = setInterval(() => {
                datetimeFilterUpdater();
            }, datetimeFilter.interval.value * 1000);
            return () => clearInterval(id);
        }

    }, [datetimeFilter]);

    React.useEffect(() => {
        if(dashboard.parameters && dashboard.parameters.presets && dashboard.parameters.presets.datetimeFilter && !dashboard.parameters.presets.datetimeFilter.datetimeRange.value.startsWith('last')){
            setDatetimeFilter({
                startDate:  moment(dashboard.parameters.presets.datetimeFilter.startDate),
                finishDate: moment(dashboard.parameters.presets.datetimeFilter.finishDate),
                datetimeRange: dashboard.parameters.presets.datetimeFilter.datetimeRange,
                interval: dashboard.parameters.presets.datetimeFilter.interval
            });
        }else{
            setDatetimeFilter({
                startDate:  moment().subtract(30, 'minutes'),
                finishDate: moment(),
                datetimeRange: dashboardConstants.DATETIME_FILTER_LIST[6],
                interval: dashboardConstants.TIMER_INTERVAL_LIST[0]
            });
        }
    }, [dashboardId]);

    const onChangeDatetimeRange = (dtFilter) => {
        let newDatetimeFilter = dtFilter;
        console.log(dtFilter);
        if (!dtFilter.datetimeRange.value.startsWith('last')) {
            newDatetimeFilter.interval = dashboardConstants.TIMER_INTERVAL_LIST[0];
        }
        setDatetimeFilter(newDatetimeFilter);
    };

    const datetimeFilterUpdater = () => {
        if (datetimeFilter.datetimeRange.value.startsWith('last')) {
            const dateModifier = datetimeFilter.datetimeRange.value.replace('last', '').split(',');
            const newDatetimeFilter = datetimeFilter;
            setDatetimeFilter({
                ...newDatetimeFilter,
                startDate: moment().subtract(dateModifier[0], dateModifier[1]),
                finishDate: moment()
            });
        }
    };

    const onChangeTimer = (interval) => {
        setDatetimeFilter({
            ...datetimeFilter,
            interval: interval
        });
    };

    const saveDashboardPresets = () => {
        setSaveFetching(true);
        if(isDefaultDashboard){
            localStorage.setItem('ems_default_dashboard_id', dashboardId);
        }
        dashboardService.saveDashboardPresets(dashboardId, datetimeFilter, widgetList).then(
            result => {
                console.log(result);
            }
        ).finally(() => {
            setSaveOpen(false);
            setSaveFetching(false);
        });
    };


    return (
        <>
        <div className={'dashboard-filter-panel'}>
            {zoomHistory && zoomHistory.length > 0 &&
            <div style={{marginRight: '15px'}}>
                <button type="button"
                        className={'btn svg-btn'}
                        onClick={() => zoomOut()}>
                    <ReactSVG src={svgOut}/>
                </button>
            </div>
            }
            <SelectTimeRange onChange={(datetimeRange) => onChangeDatetimeRange(datetimeRange)}
                             datetimeFilter={datetimeFilter}/>
            <SelectTimerInterval onChange={(timerInterval) => onChangeTimer(timerInterval)}
                                 datetimeFilter={datetimeFilter}/>
            <button className={'btn svg-btn'} style={{marginLeft: '1rem'}}
                    title={localeService.isRussian() ? 'Редактировать дашборд' : 'Edit Dashboard'} onClick={() => {
                history.push('/edit_dashboard/' + dashboardId);
            }}>
                <ReactSVG src={svgEdit}/>
            </button>

            <button className={'btn svg-btn'} style={{marginLeft: '1rem'}}
                    title={localeService.isRussian() ? 'Сохранить настройки дашборда' : 'Save Dashboard Properties'}
                    onClick={() => setSaveOpen(true)}>
                <ReactSVG src={svgSave}/>
            </button>
        </div>
            <Modal isOpen={saveOpen} isConfirm={true} width={'600px'} onCancel={() => setSaveOpen(false)} onSubmit={() => saveDashboardPresets()}>
                {!saveFetching && <h5 className={'mb-5'}>{localeService.isRussian() ? 'Хотите сохранить настройки дашборда и виджетов?' : 'Want to keep the dashboard and widgets setup?'}</h5>}
                {saveFetching && <Loader waitText={localeService.isRussian() ? 'Сохраниение дашборда...' : 'Dashboard saving...'}/>}
                <CheckBox checked={isDefaultDashboard}
                          onChange={result => {
                              setDefaultDashboard(result);
                          }}
                          label={localeService.isRussian() ? 'Дашборд по умолчанию' : 'Default Dashboard'}/>
            </Modal>
            </>
    );
}

DashboardFilterPanel.propTypes = {
    datetimeFilter: PropTypes.object,
    zoomHistory: PropTypes.array,
    setDatetimeFilter: PropTypes.func,
    zoomOut: PropTypes.func,
    dashboardId: PropTypes.number,
    widgetList: PropTypes.array,
    dashboard: PropTypes.object
};

const mapStateToProps = state => {
    const datetimeFilter = state.dashboardReducer.datetimeFilter;
    const zoomHistory = state.dashboardReducer.zoomHistory;
    const widgetList = state.dashboardReducer.widgetList;
    return {datetimeFilter, zoomHistory, widgetList};
};

const mapDispatchToProps = {
    setDatetimeFilter: setDatetimeFilter,
    zoomOut: zoomOut
};
export default connect(mapStateToProps, mapDispatchToProps)(DashboardFilterPanel);
