import React, {useState} from 'react';
import PropTypes from 'prop-types';
import '../styles/popover.scss';

function Popover({children, position, style, type}) {

    const [isOpen, setOpen] = useState(false);

    return <>
        <div className={'popover ' + position + (isOpen ? ' open' : '')} style={style}>
            <button className={'toggle-popover btn eco-btn round-btn ' + position + (type === 'INFO' ? ' default' : '') + (type === 'WARNING' ? ' danger' : '')}
                    onMouseLeave={() => setOpen(false)}
                    onMouseOver={(e) => {
                        e.preventDefault();
                        setOpen(!isOpen);
                    }}>
                {type==='INFO' && 'i'}
                {type==='WARNING' && '!'}
            </button>
            <div className={'popover-content'}>
                {children}
            </div>
        </div>

    </>;
}

Popover.propTypes = {
    children: PropTypes.node,
    position: PropTypes.oneOf('top-right', 'top', 'top-left', 'right'),
    style: PropTypes.object,
    type: PropTypes.oneOf('INFO', 'WARNING')
};

Popover.defaultProps = {
    position: 'top-right',
    type: 'INFO'
};

export default Popover;