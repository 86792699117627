//no-unused-vars

import React, { useState} from 'react';
import { Map, Placemark, Polygon, YMaps, ZoomControl} from "react-yandex-maps";

function MapTest() {
    // const [files, setFiles] = useState("");
    const [data, setData] = useState();

    const handleChange = e => {
        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8");
        fileReader.onload = e => {
            // console.log("e.target.result", e.target.result);
            // setFiles(e.target.result);
            setData(JSON.parse(e.target.result));
        };
    };

    return (
        <div className={'container'}>
            <h1>Map Test</h1>

            <input type="file" onChange={handleChange}/>

            <YMaps>
                <Map
                    style={
                        {
                            width: 800,
                            height: 400,
                            margin: '0 auto',
                            // top: -36,
                            // left: -3,
                            // border: '6px solid lightgrey',
                            // borderRadius: '12px',
                            // position: 'absolute'
                        }
                    }
                    defaultState={{zoom: 6, center: [52.62987152345293, 39.552472022501775]}}>
                    <ZoomControl options={{position: 'left'}}/>
                    {/*{data && data.geometry?.heightmap.map(((hmData, i) => {*/}
                    {/*    return hmData.data.map((pol, j) => {*/}
                    {/*        let pData = pol.map(p => {*/}
                    {/*            return [p.lon, p.lat];*/}
                    {/*        });*/}
                    {/*        console.log(`pol${i}${j}`, pData);*/}
                    {/*        return <GeoObject key={`pol${i}${j}`}*/}
                    {/*            geometry={{*/}
                    {/*                type: "LineString",*/}
                    {/*                coordinates: pData,*/}
                    {/*            }}*/}
                    {/*            options={{*/}
                    {/*                geodesic: true,*/}
                    {/*                strokeWidth: 2,*/}
                    {/*                strokeColor: "#0000FF",*/}
                    {/*            }}*/}
                    {/*        />;*/}
                    {/*    });*/}
                    {/*}))}*/}

                    {/*{data && data.geometry?.pollution.map(((hmData, i) => {*/}
                    {/*    return hmData.data.map((pol, j) => {*/}
                    {/*        let pData = pol.map(p => {*/}
                    {/*            return [p.lon, p.lat];*/}
                    {/*        });*/}
                    {/*        console.log(`pol${i}${j}`, pData);*/}
                    {/*        return <GeoObject key={`pol${i}${j}`}*/}
                    {/*                          geometry={{*/}
                    {/*                              type: "LineString",*/}
                    {/*                              coordinates: pData,*/}
                    {/*                          }}*/}
                    {/*                          options={{*/}
                    {/*                              geodesic: true,*/}
                    {/*                              strokeWidth: 2,*/}
                    {/*                              strokeColor: "#ff4b00",*/}
                    {/*                          }}*/}
                    {/*        />;*/}
                    {/*    });*/}
                    {/*}))}*/}

                    {data && data.geometry?.heightmap.map(((hmData, i) => {
                        const cloudData = hmData.data.map((pol) => {
                            return pol.map(p => {
                                return [p.lon, p.lat];
                            });

                        });
                        return <Polygon key={"hmData"+i} geometry={cloudData}
                                        options={{
                                            fillColor: '#4172ff',
                                            strokeColor: '#001646',
                                            opacity: hmData.value / 10000 * 10,
                                            strokeWidth: 1,
                                            strokeStyle: 'solid'
                                        }}/>;
                    }))}

                    {data && data.geometry?.pollution.map(((hmData, i) => {
                        const cloudData = hmData.data.map((pol) => {
                            return pol.map(p => {
                                return [p.lon, p.lat];
                            });

                        });
                        return <Polygon key={"hmData"+i} geometry={cloudData}
                                               options={{
                                                   fillColor: '#7a0c0d',
                                                   strokeColor: '#ff6a00',
                                                   opacity: hmData.value / 100 * 15,
                                                   strokeWidth: 0,
                                                   strokeStyle: 'solid'
                                               }}/>;
                    }))}



                    {data && data.metadata.pollution_centres && Object.keys(data.metadata.pollution_centres).map(pc => {
                        return <Placemark key={pc} geometry={[data.metadata.pollution_centres[pc].lon, data.metadata.pollution_centres[pc].lat]}
                                          properties={{
                                              iconColor: "#279d00",
                                              balloonContentHeader: pc,
                                              //balloonContentFooter: (localeService.isRussian() ? 'Нагрузка: ' : 'Capacity: ')+point.device.variabels.find(item => item.id == point.sourceID).capacity.name
                                          }}/>;
                    })}


                </Map>
            </YMaps>


        </div>

    );
}

export default MapTest;


