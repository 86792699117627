import React, {useState} from 'react';
import {localeService} from '../../services/locale.service';
import InputText from '../../components/InputText';
//import '../../styles/import-form.scss';
import Select from '../../components/Select';
import {useTranslation} from 'react-i18next';

/*import {importFromEnergoSphereTest, importFromEnergoSphere} from '../../services/import.service';*/

function ImportForm() {

    const [connectionConfig, setConnectionConfig] = useState({});
    const [testSuccess, setTestSuccess] = useState(false);
    const [generator, setGenerator] = useState({type: 'NONE', config: {}});
    const {t} = useTranslation();

    const onHandleChange = (generator) => {
        setGenerator(generator);
    };

    const onTestConnection = () => {
        /*importFromEnergoSphereTest(connectionConfig);*/
        setTestSuccess(true);
    };

    /*const uploadEnergoSphere = (e) => {
    importFromEnergoSphere(connectionConfig);
    };*/

    return (
        <div className={'container-700'}>
            <form className={'row'}>

                <label className={'mb-3'}>{localeService.isRussian() ? 'Подключение к базе данных' : 'Connection with database'}</label>
                <InputText label={'URL'} isRequired={true}
                           onChange={text => setConnectionConfig({...connectionConfig, url: text})}
                           value={connectionConfig.url}/>
                <InputText label={localeService.isRussian() ? 'База данных' : 'Database'}
                           isRequired={true}
                           onChange={text => setConnectionConfig({...connectionConfig, db: text})}
                           value={connectionConfig.db}/>
                    <div className="col-md-6">
                        <InputText label={localeService.isRussian() ? 'Пользователь' : 'User'}
                                   isRequired={true}
                                   onChange={text => setConnectionConfig({...connectionConfig, user: text})}
                                   value={connectionConfig.user}/>
                    </div>
                    <div className="col-md-6">
                        <InputText label={localeService.isRussian() ? 'Пароль' : 'Password'}
                                   isRequired={true}
                                   onChange={text => setConnectionConfig({...connectionConfig, password: text})}
                                   value={connectionConfig.password}/>
                    </div>
                <Select label={t('response_period')}
                        value={{value: '', name: t('check_response_period')}}
                        onSelect={val => {
                            const config = {...generator.config, cron: val.value};
                            const newGenerator = ({...generator, config: config});
                            onHandleChange(newGenerator);
                        }}
                        valueList={[
                            {value: '', name: t('check_response_period')},
                            {value: '0 * * * * *', name: t('every_minute')},
                            {value: '0 */15 * * * *', name: t('every_15minute')},
                            {value: '0 */30 * * * *', name: t('every_30minute')},
                            {value: '0 0 0 * * * *', name: t('every_hour')},
                            {value: '* * */1 * * ?', name: t('every_day')},
                            {value: '* * * */7 * ?', name: t('every_week')},
                            {value: '* * * */1 * ?', name: t('every_month')},
                            {value: '* * * * */1 ?', name: t('every_year')}
                        ]}/>

                <div className={'mt-5 d-flex justify-content-center'}>
                <button
                    className={testSuccess ? 'import-test-btn-active btn eco-btn' : 'import-test-btn btn eco-btn default'}
                    onClick={(e) => {
                        e.preventDefault();
                        onTestConnection();
                    }}>
                    {testSuccess ? localeService.isRussian() ? 'Сервис работает нормально'
                        : 'Data source is working' : localeService.isRussian() ? 'Тест' : 'Test'}
                </button>
                </div>

            </form>
        </div>
    );
}

export default ImportForm;