import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import '../styles/dict-of-time.scss';
import {localeService} from '../services/locale.service';
import ClickOutside from './ClickOutside';
import {useTranslation} from 'react-i18next';

function DictOfTime({onChange, label}) {
    const {t} = useTranslation();

    const [open, setOpen] = React.useState(false);
    const [selectedValues, setSelectedValues] = React.useState([]);
    const [selectMode, setSelectMode] = React.useState(false);
    const [dictOfTime, setDictOfTime] = React.useState([...Array(24).keys()].map((_, i) => {
        return {
            id: i,
            start: moment().hours(i).minutes(0).seconds(0).format('HH:mm:ss'),
            end: moment().hours(i).minutes(59).seconds(0).format('HH:mm:ss'),
            selected: false
        };
    }));

    const selectTime = (time) => {
        let newSelectedValues = selectedValues;
        if (time.selected) {
            newSelectedValues = (selectedValues.filter(el => el.id !== time.id));
        } else {
            newSelectedValues = ([...selectedValues, {...time, selected: true}]);
        }

        let newDictOfTime = [...Array(24).keys()].map((_, i) => {
            return {
                id: i,
                start: moment().hours(i).minutes(0).seconds(0).format('HH:mm:ss'),
                end: moment().hours(i).minutes(59).seconds(0).format('HH:mm:ss'),
                selected: newSelectedValues.findIndex(el => el.id === i) >= 0
            };
        });

        setDictOfTime(newDictOfTime);
        setSelectedValues(newSelectedValues);
        // onChange(recalculateDictOfTime(newDictOfTime));
    };

    const recalculateDictOfTime = (dictOfTime) => {
        let values = [];
        dictOfTime.map((el) => {
            if (el.selected && dictOfTime[el.id - 1] !== undefined && !dictOfTime[el.id - 1].selected) {
                values.push(el.start + ',');
            }
            if (el.selected && dictOfTime[el.id - 1] === undefined) {
                values.push(el.start + ',');
            }
            if (!el.selected && dictOfTime[el.id - 1] !== undefined && dictOfTime[el.id - 1].selected) {
                values[values.length - 1] = values[values.length - 1] + dictOfTime[el.id - 1].end;
            }
            if (el.selected && dictOfTime[el.id + 1] === undefined) {
                values[values.length - 1] = values[values.length - 1] + el.end;
            }
        });
        return values;
    };

    const setHours = () => {
        onChange(recalculateDictOfTime(dictOfTime));
    };

    return <div className={'input-group understroke dict-of-time'}>
        <div className={'input-group-prepend'}>
            <label>{label}</label>
        </div>
        <p onClick={() => setOpen(!open)} style={{cursor: 'pointer'}}>
            {selectedValues.length > 0 && recalculateDictOfTime(dictOfTime).map((el, i) => {
                return <span key={i}>{el + '   '}</span>;
            })}
            {selectedValues.length === 0 && 'Выбрать часы'}
        </p>
        <ClickOutside onClick={() => setOpen(false)}>
            <div className={'group'} style={{width: '100%', display: open ? 'block' : 'none'}}>
                <label
                    className={'group-header'}>{localeService.isRussian() ? 'Выберите часы' : 'Choose hours'}</label>
                <div className={'hours-line'}
                     onMouseLeave={() => setSelectMode(false)}>
                    {dictOfTime.map(el => {
                        return <button key={el.id}
                                       type={'button'}
                                       style={{}}
                                       className={'hour ' + (el.selected ? ' active' : '')}
                                       onMouseDown={() => {
                                           setSelectMode(true);
                                           selectTime(el);
                                       }}
                                       onMouseUp={() => setSelectMode(false)}
                                       onMouseOver={() => {
                                           if (selectMode) {
                                               selectTime(el);
                                           }
                                       }}>
                            <span className={'label'}>{moment().hours(el.id).minutes(0).format('HH:mm')}</span>
                        </button>;
                    })}
                </div>
                <div className={'row to-right'}>
                    <button className={'btn eco-btn success'} onClick={(e) => {
                        e.preventDefault();
                        setOpen(false);
                        setHours();
                    }}>{t('apply')}</button>
                    <button className={'btn eco-btn default'}
                            onClick={(e) => {
                                e.preventDefault();
                                setOpen(false);
                            }}>{t('cancel')}</button>
                </div>
            </div>
        </ClickOutside>
    </div>;
}

DictOfTime.propTypes = {
    onChange: PropTypes.func,
    label: PropTypes.string
};

export default DictOfTime;