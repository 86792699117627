import React from 'react';
import PropTypes from 'prop-types';
import {apiConstants, dashboardConstants} from '../../../constants';
import WidgetChartView from './WidgetChartView';
import WidgetChartProps from './WidgetChartCreate';
import Modal from '../../../components/Modal';
import {localeService} from '../../../services/locale.service';
// import WidgetChartPreview from './WidgetChartPreview';

function WidgetChart({widgetProps, mode, closeEditSingleMode, sizeParams}) {
    return (
        <div>
            {mode === dashboardConstants.VIEW_MODE &&
                <WidgetChartView widgetProps={widgetProps}/>
            }
            {mode === dashboardConstants.EDITION_MODE &&
            <div className={'widget-preview'} style={{width: sizeParams.width - (12*2), height: (70 * widgetProps.h) - 30 }}>
                {widgetProps.image && widgetProps.image.id && <img style={{maxWidth: '100%', maxHeight: '100%'}} src={apiConstants.API_URL + '/file/download?fileId=' + widgetProps.image.id}/>}
            {/*<WidgetChartPreview widgetProps={widgetProps} sizeParams={sizeParams}/>*/}
            </div>
            }
            {mode === dashboardConstants.EDIT_SINGLE_MODE &&
            <Modal isOpen={mode === dashboardConstants.EDIT_SINGLE_MODE} isClosable={false} footer={false} onCancel={() => closeEditSingleMode()} title={localeService.isRussian() ? 'Настройка виджета' : 'Edit Widget'}>
                <WidgetChartProps widgetProps={widgetProps}/>
            </Modal>
            }
            </div>
    );
}

WidgetChart.propTypes = {
    widgetProps: PropTypes.object,
    closeEditSingleMode: PropTypes.func,
    size: PropTypes.object,
    sizeParams: PropTypes.object,
    mode: PropTypes.oneOf([dashboardConstants.EDIT_SINGLE_MODE, dashboardConstants.EDITION_MODE, dashboardConstants.VIEW_MODE]),
};

export default WidgetChart;