import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import AddAdditionalParamsToCostCalculationWidget from '../../../components/AddAdditionalParamsToCostCalculationWidget';
import Loader from '../../../components/Loader';
import {capacityCostCalculationService} from '../../../services/capacityCostCalculation.service';
import {localeService} from '../../../services/locale.service';
import * as numeral from 'numeral';
import {withSize} from 'react-sizeme';
import {Cell, Legend, Pie, PieChart, Tooltip} from 'recharts';
import {updateWidget} from '../../../redux/actions/dashboardActions';

function CapacityCostView({widgetProps, datetimeFilter, analyticFunctionList, size, updateWidget}) {
    const {t} = useTranslation();
    const [additionalParameters, setAdditionalParameters] = React.useState([]);
    const electricityCostFunction = analyticFunctionList.filter(el => el.name === 'electricity-cost-calculation');
    const [isFetching, setFetching] = React.useState(false);
    const [capacityCostMethods, setCapacityCostMethods] = React.useState([]);

    const chartWidth = size.width;
    const chartHeight = (100 * widgetProps.h) - 40;

    React.useEffect(() => {
        if (electricityCostFunction.length === 1 && widgetProps.parameters.method) {

            // Определяем методы доступные для ценовой категории
            if (widgetProps.parameters.method && widgetProps.parameters.method.name) {
                setCapacityCostMethods(capacityCostCalculationService.initMethods(widgetProps.parameters.method.name, widgetProps.parameters.viewType.code));
            }

            //Определяем доболнительные параметры функции для ценовой категории
            let additionalParams = [];
            electricityCostFunction[0].args.map(arg => {
                if (widgetProps.parameters.method.name === 'one_category_*') {
                    if (['tariff_one_ee', 'tariff_losses_three'].includes(arg.name)) {
                        additionalParams.push({
                            ...arg, values: Array.from(Array(arg.count), () => {
                                return '';
                            })
                        });
                    }
                }
                if (widgetProps.parameters.method.name === 'two_category_three_zones_*') {
                    if (['tariff_two_three_zones_ee', 'time_two_cat_night_zones', 'time_two_cat_peak_zones', 'tariff_losses_three'].includes(arg.name)) {
                        additionalParams.push({
                            ...arg, values: Array.from(Array(arg.count), () => {
                                return '';
                            })
                        });
                    }
                }
                if (widgetProps.parameters.method.name === 'two_category_two_zones_*') {
                    if (['tariff_two_two_zones_ee', 'time_two_cat_night_zones', 'tariff_losses_three'].includes(arg.name)) {
                        additionalParams.push({
                            ...arg, values: Array.from(Array(arg.count), () => {
                                return '';
                            })
                        });
                    }
                }
                if (widgetProps.parameters.method.name === 'three_category_*') {
                    if (['tariff_losses_three', 'tariff_sales', 'actual_volume_other_service', 'volume_other_service'].includes(arg.name)) {
                        additionalParams.push({
                            ...arg, values: Array.from(Array(arg.count), () => {
                                return '';
                            })
                        });
                    }
                }
                if (widgetProps.parameters.method.name === 'four_category_*') {
                    if (['time_four_cat', 'volume_other_service', 'actual_volume_other_service', 'tariff_sales', 'tariff_maintenance_four', 'tariff_losses_four'].includes(arg.name)) {
                        additionalParams.push({
                            ...arg, values: Array.from(Array(arg.count), () => {
                                return '';
                            })
                        });
                    }
                }
            });
            setAdditionalParameters(additionalParams);
        }
    }, []);

    const submitFunction = (additionalArgs) => {
        if (electricityCostFunction.length === 1) {
            setFetching(true);
            capacityCostCalculationService.runCalculate(capacityCostMethods, additionalArgs, widgetProps, datetimeFilter).then(
                response => {
                    console.log('response', response, size);
                    setCapacityCostMethods(response);

                    //добавляем настройки виджета в redux в предустановки
                    updateWidget({...widgetProps, parameters: {...widgetProps.parameters, presets: {additionalArgs: additionalArgs} }});
                }
            ).finally(() => setFetching(false));
        }
    };

    return <div>
        <div className={'widget-management-console'}>
            <AddAdditionalParamsToCostCalculationWidget funcArgs={additionalParameters}
                                                        onSubmit={(args) => submitFunction(args)}/>
        </div>
        {isFetching && <Loader/>}
        {!isFetching && widgetProps.parameters.viewType.code === 'COST_MAKING' &&
        <table className={'table'}>
            <thead>
            <tr>
                <th>{t('name')}</th>
                <th>{t('value')}</th>
            </tr>
            </thead>
            <tbody>
            {capacityCostMethods.map((method, mId) => {
                return <tr key={mId}>
                    <td>{method.description}</td>
                    <td>{method.value}</td>
                </tr>;
            })}
            </tbody>
        </table>
        }
        {!isFetching && widgetProps.parameters.viewType.code === 'AVERAGE_COST' &&
        <table className={'table'}>
            <thead>
            <tr>
                <th>{t('name')}</th>
                <th>{t('value')}</th>
            </tr>
            </thead>
            <tbody>
            {capacityCostMethods.map((method, mId) => {
                return <tr key={mId}>
                    <td>{method.description}</td>
                    <td>{method.value}</td>
                </tr>;
            })}
            {capacityCostMethods[0] && capacityCostMethods[0].value !== '?' && capacityCostMethods[1] && capacityCostMethods[1].value !== '?' &&
            <tr>
                <th>{localeService.isRussian() ? 'Среднепривиденная цена' : 'Average cost'}</th>
                <th>{(capacityCostMethods[1].value / capacityCostMethods[0].value).toFixed(2)}</th>
            </tr>}
            </tbody>
        </table>
        }

        {!isFetching && widgetProps.parameters.viewType.code === 'ELECTRICITY_COST' &&
        <PieChart width={chartWidth}
                  height={chartHeight}>
            <Pie
                data={capacityCostMethods}
                dataKey={'value'}
                nameKey={'name'}
                innerRadius={0}
                outerRadius={Math.min(chartWidth, chartHeight) / 2 - 40}
                label={(labelProps) => {
                    return numeral(labelProps.value).format('0.0 a');
                }}
                labelLine={true}
                isAnimationActive={false}
            >
                {
                    capacityCostMethods.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color}/>
                    ))
                }
            </Pie>
            <Legend/>
            <Tooltip/>
        </PieChart>}

    </div>;
}

CapacityCostView.propTypes = {
    widgetProps: PropTypes.object,
    datetimeFilter: PropTypes.object,
    analyticFunctionList: PropTypes.array,
    size: PropTypes.object,
    updateWidget: PropTypes.func
};

const mapStateToProps = state => {
    const datetimeFilter = state.dashboardReducer.datetimeFilter;
    const analyticFunctionList = state.analyticReducer.analyticFunctionList;
    return {datetimeFilter, analyticFunctionList};
};

const mapDispatchToProps = {
    updateWidget: updateWidget
};

export default withSize()(connect(mapStateToProps, mapDispatchToProps)(CapacityCostView));