import React, {useState} from 'react';
import {ReactSVG} from 'react-svg';
import ReactFileReader from 'react-file-reader';
import {localeService} from '../services/locale.service';
import PropTypes from 'prop-types';
import svgImage from '../sources/images/interface/image.svg';

function ImageUploader({onChange, withPreloader, returnBase64}) {
    const [image, setImage] = useState({
        name: 'default',
        type: 'data:image/svg+xml;base64',
        content: 'PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA5OS41IDc2Ljc0Ij4NCiAgICA8dGl0bGU+QXNzZXQgNDwvdGl0bGU+DQogICAgPGcgaWQ9IkxheWVyXzIiIGRhdGEtbmFtZT0iTGF5ZXIgMiIgc3R5bGU9ImZpbGw6ICMwMDVlYTMiPg0KICAgICAgICA8ZyBpZD0iTGF5ZXJfMS0yIiBkYXRhLW5hbWU9IkxheWVyIDEiPg0KICAgICAgICAgICAgPHBhdGggZD0iTTk5LjUsMzUuN0g4OC4xMWEzOC4zNCwzOC4zNCwwLDAsMC03Ni41LDBIMFY0MUgxMS42MWEzOC4zNCwzOC4zNCwwLDAsMCw3Ni41LDBIOTkuNVpNNDkuODYsNzEuMzlBMzMuMDUsMzMuMDUsMCwwLDEsMTcsNDFIMzMuMzlWNjAuNjdMNjAuNzcsNDMuMzJWNjAuNDJoNS4zNFY0MUg4Mi43NUEzMy4wNSwzMy4wNSwwLDAsMSw0OS44Niw3MS4zOVpNMzguNzMsMjUuNzIsNTguNjQsMzguMzQsMzguNzMsNTFabTI3LjM4LDEwVjE2LjMySDYwLjc3djE3TDMzLjM5LDE2VjM1LjdIMTdhMzMsMzMsMCwwLDEsNjUuNzgsMFoiLz4NCiAgICAgICAgPC9nPg0KICAgIDwvZz4NCjwvc3ZnPg=='
    });

        const getBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        };


        const handleFiles = (file) => {
            getBase64(file[0]).then(
                data => {
                    const fileBase64 = data.split(',');
                    const selectedFile = {
                        type: fileBase64[0],
                        name: file[0].name,
                        content: fileBase64[1]
                    };
                    setImage(selectedFile);
                    let base64Img = fileBase64[0]+','+fileBase64[1];
                    onChange(returnBase64 ? base64Img : file[0]);
                }
            );

        };


        return (
            <div className="form-group">
                <div className="input-group file-uploader">
                    {withPreloader && <span className="load-icon-preview">
                        {image && <img src={image.type + ',' + image.content} width={30 + 'px'} alt={'Capacity Icon'}/>}
                    </span>}
                    <div style={{marginLeft: '15px'}}>
                        <small><b>{image.name || ''}</b></small>
                        <br/>
                        <ReactFileReader
                            fileTypes={['.png', '.jpg', '.gif']}
                            base64={false}
                            multipleFiles={false}
                            handleFiles={(file) => handleFiles(file)}
                            disabled={false}
                        >
                            <button type="button" className={'btn eco-btn default'} style={{display: 'flex'}}>
                                <ReactSVG src={svgImage} style={{width:'2rem', height:'2rem'}}/>
                                {localeService.isRussian() ? 'Изменить' : 'Change'}
                            </button>
                        </ReactFileReader>
                    </div>
                </div>
            </div>
        );
    }

    ImageUploader.propTypes = {
        onChange: PropTypes.func,
        withPreloader: PropTypes.bool,
        returnBase64: PropTypes.bool
    };

    ImageUploader.defaultProps = {
        withPreloader: true,
        returnBase64: false
    };

    export default ImageUploader;