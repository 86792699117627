import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ClickOutside from '../../../../components/ClickOutside';
import {useTranslation} from 'react-i18next';
import Modal from '../../../../components/Modal';
import {localeService} from '../../../../services/locale.service';
import {dashboardService} from '../../../../services/index';
import InputText from '../../../../components/InputText';
import ColorPicker from '../../../../components/ColorPicker';
import Select from '../../../../components/Select';
import AlertError from '../../../../components/AlertError';
import Loader from '../../../../components/Loader';
import AddAnalyticParametersToChart from '../../../../components/AddAnalyticParametersToChart';
import {analyticService} from '../../../../services/analyticService';
import {predictionFunctionCodes} from '../../../../constants/analytics.constants';
import Modelica from './analyticForms/Modelica';

function AddPredictionFunctionToChart({onApplyFunction, analyticFunctionList, transformationFunctionList, dataSources, datetimeFilter}) {
  const {t} = useTranslation();
  const [isDropDownOpened, setDropDownOpened] = useState(false);
  const [selectedFunction, setSelectedFunction] = useState(null);
  const [isOpenConfigurator, setOpenConfigurator] = useState(false);
  const [error, setError] = useState(null);
  const [isFetching, setFetching] = useState(false);

  const onSelectFunction = (func) => {
    setSelectedFunction({
      ...func,
      idx: new Date().getTime(),
      functionParams: analyticService.initTransformationFunctionParameters(func),
      parameters: {
        dataSourceName: 'F(x): ' + func.name,
        color: dashboardService.getRandomColor(),
        dataSource: dataSources.length === 1 ? {
          id: dataSources[0].id,
          name: dataSources[0].parameters.dataSourceName
        } : null
      },
      args: analyticService.initAnalyticFunctionParameters(func, datetimeFilter)
    });
    setDropDownOpened(false);
    setOpenConfigurator(true);
  };

  const onSubmitFunction = () => {
    setFetching(true);
    const dataSourceId = selectedFunction.parameters.dataSource.id;
    analyticService.calculateAnalyticFunction(dataSourceId, selectedFunction, datetimeFilter).then(
      data => {
        if (!data || data.length === 0) {
          setError(localeService.isRussian() ? 'Не удалось применить функцию. Попробуйте использовать другие параметры.' : 'Cannot apply function. Try to change function parameters.');
        } else {
          const functionDataSource = {
            ...selectedFunction,
            data: data
          };
          onApplyFunction(functionDataSource);
          setOpenConfigurator(false);
          setDropDownOpened(false);
          setSelectedFunction(null);
          onApplyFunction(functionDataSource);
        }
      }
    ).finally(
      () => {
        setFetching(false);
      }
    );
  };

  const onSubmitModelicaFunction = () => {
    setFetching(true);
    const dataSourceId = selectedFunction.parameters.dataSource.id;
    analyticService.calculateAnalyticFunction(dataSourceId, selectedFunction, datetimeFilter).then(
      data => {
        if (!data || data.length === 0) {
          setError(localeService.isRussian() ? 'Не удалось применить функцию. Попробуйте использовать другие параметры.' : 'Cannot apply function. Try to change function parameters.');
        } else {
          const functionDataSource = {
            ...selectedFunction,
            data: data
          };
          onApplyFunction(functionDataSource);
          setOpenConfigurator(false);
          setDropDownOpened(false);
          setSelectedFunction(null);
          onApplyFunction(functionDataSource);
        }
      }
    ).finally(
      () => {
        setFetching(false);
      }
    );
  };

  const onSubmitHolterWintersFunction = () => {
    setFetching(true);
    dashboardService.getWidgetFunctionData(selectedFunction.parameters.dataSource.id, datetimeFilter.startDate, datetimeFilter.finishDate,
      selectedFunction.functionCode, selectedFunction.functionParams)
      .then(
        result => {
          const functionDataSource = {
            ...selectedFunction,
            data: result.list
          };
          return functionDataSource;
        }, () => {
          setError(localeService.isRussian() ? 'Не удалось применить функцию. Попробуйте использовать другие параметры.' : 'Cannot apply function. Try to change function parameters.');
        }
      ).then(
      functionDataSource => {
        setOpenConfigurator(false);
        setDropDownOpened(false);
        setSelectedFunction(null);
        onApplyFunction({...functionDataSource, args: selectedFunction.args});
      }
    ).finally(
      () => {
        setFetching(false);
      }
    );
  };

  return (<>
    <div className={'dropdown-container function-list' + (isDropDownOpened ? ' open' : '')}>
      <button className={'btn svg-btn'}
              title={localeService.isRussian() ? 'Функции прогнозирования' : 'Prediction Functions'}
              onClick={() => {
                setDropDownOpened(!isDropDownOpened);
                setSelectedFunction(false);
              }}>
        <span style={{fontStyle: 'italic', fontSize: '1.2rem', fontWeight: 'bold'}}>P</span>
      </button>
      <ClickOutside onClick={() => setDropDownOpened(false)}>
        <div className={'dropdown' + (isDropDownOpened ? ' open' : '')}>
          <ul>
            {analyticFunctionList.map((func, i) => {
              if (predictionFunctionCodes.findIndex(el => el.code === func.code) >= 0) {
                return <li key={'analytic-' + i} onClick={() => onSelectFunction({
                  ...func,
                  functionCode: func.code,
                  sourceType: 'ANALYTIC_FUNCTION'
                })}>
                  <span>{t('functionName.' + func.name)}</span>
                </li>;
              }
            })}
            {transformationFunctionList.map((func) => {
              if (func.code === 'HOLT_WINTERS') {
                return <li key={'holt'} onClick={() => onSelectFunction({
                  ...func,
                  functionCode: func.code,
                  sourceType: 'TRANSFORMATION_FUNCTION'
                })}>
                  <span>{t('functionName.' + func.name)}</span>
                </li>;
              }
            })}
          </ul>
        </div>
      </ClickOutside>
      <Modal onCancel={() => {
        setOpenConfigurator(false);
        setSelectedFunction(null);
      }} footer={false} isClosable={false}
             isOpen={isOpenConfigurator}
             title={localeService.isRussian() ? 'Параметры применения функции' : 'Function Configuration'}>
        {selectedFunction && !error && !isFetching &&
        <form>
          {(selectedFunction.functionCode === 'ANALYSISMODELICADEMO' &&
            <Modelica dataSources={dataSources}
                      datetimeFilter={datetimeFilter}
                      selectedFunction={selectedFunction}
                      setSelectedFunction={(params) => setSelectedFunction(params)}/>) ||
          <>
            <Select label={localeService.isRussian() ? 'Источник данных' : 'Data Source'}
                    placeholder={localeService.isRussian() ? 'Выберите источник данных' : 'Select data source'}
                    valueList={dataSources.map(ds => {
                      return {id: ds.id, name: ds.parameters.dataSourceName};
                    })}
                    onSelect={(ds) => setSelectedFunction({
                      ...selectedFunction,
                      parameters: {...selectedFunction.parameters, dataSource: ds}
                    })}
                    defaultValue={dataSources.length === 1 ? {
                      id: dataSources[0].id,
                      name: dataSources[0].parameters.dataSourceName
                    } : null}/>
            <div style={{padding: 0, display: 'flex', width: '100%', position: 'relative'}}>
              <InputText label={t('name')}
                         value={selectedFunction.parameters.dataSourceName}
                         onChange={text => setSelectedFunction({
                           ...selectedFunction,
                           parameters: {...selectedFunction.parameters, dataSourceName: text}
                         })}/>
              <ColorPicker style={{marginLeft: '1rem'}} color={selectedFunction.parameters.color}
                           setColor={(color) =>
                             setSelectedFunction({
                               ...selectedFunction,
                               parameters: {...selectedFunction.parameters, color: color}
                             })}/>
            </div>

            {selectedFunction && selectedFunction.args && selectedFunction.args.length > 0 &&
            <AddAnalyticParametersToChart datetimeFilter={datetimeFilter} demandFunction={selectedFunction}
                                          onChangeParam={func => {
                                            setSelectedFunction({...selectedFunction, ...func});
                                          }}/>
            }

            {selectedFunction.sourceType === 'TRANSFORMATION_FUNCTION' &&
            <div style={{marginTop: '20px'}}>{
              selectedFunction.functionParams.map((param, i) => {
                return (<InputText key={'funcParam' + i}
                                   label={selectedFunction.code === 'HOLT_WINTERS' ?
                                     (t('holtWintersArgs.' + i))
                                     : ((localeService.isRussian() ? 'Параметр ' : 'Parameter ') + (i + 1))}
                  // isDecimal={true}
                                   value={param}
                                   onChange={(text) => {
                                     let params = selectedFunction.functionParams;
                                     params[i] = text;
                                     setSelectedFunction({
                                       ...selectedFunction,
                                       functionParams: params
                                     });
                                   }}
                />);
              })
            }
            </div>
            }
          </>
          }
          <hr/>
          <div className={'d-flex justify-content-center'}>
            <button type="button" className={'btn eco-btn success'} onClick={(e) => {
              console.log('selectedFunc', selectedFunction);
              e.preventDefault();
              if (selectedFunction.parameters.dataSource === null) {
                setError(localeService.isRussian() ? 'Выберите источник данных, к которому необходимо применить функцию' : 'Select Data Source');
              } else {
                // setOpenConfigurator(false);
                if (selectedFunction.functionCode === 'HOLT_WINTERS') {
                  onSubmitHolterWintersFunction();
                } else if(selectedFunction.functionCode === 'ANALYSISMODELICADEMO'){
                  onSubmitModelicaFunction();
                } else {
                  onSubmitFunction();
                }

              }
            }}>{t('apply')}</button>
            <button className={'btn eco-btn danger'} onClick={() => {
              setOpenConfigurator(false);
              setSelectedFunction(null);
            }}>{t('cancel')}</button>
          </div>
        </form>
        }

        {isFetching &&
        <Loader waitText={localeService.isRussian() ? 'Загрузка функции...' : 'Loading function...'}/>}

        <AlertError isOpen={error !== null} onCancel={() => setError(null)} message={error}/>


      </Modal>
    </div>
  </>);
}

AddPredictionFunctionToChart.propTypes = {
  analyticFunctionList: PropTypes.array,
  transformationFunctionList: PropTypes.array,
  onApplyFunction: PropTypes.func,
  dataSources: PropTypes.array,
  datetimeFilter: PropTypes.object
};


const mapStateToProps = state => {
  const analyticFunctionList = state.analyticReducer.analyticFunctionList;
  const transformationFunctionList = state.analyticReducer.transformationFunctionList;
  const datetimeFilter = state.dashboardReducer.datetimeFilter;
  return {analyticFunctionList, datetimeFilter, transformationFunctionList};
};


export default connect(mapStateToProps, null)(AddPredictionFunctionToChart);