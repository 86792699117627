import '../../../styles/dropdown.scss';

import React, {  useState } from 'react';

import PropTypes from 'prop-types';
import { localeService } from '../../../services/locale.service';
import {uuidv4} from '../../../utils/uuid';


function findPath(capacity,tree){
  const item=tree.find(item=>item.name===capacity.name);
  return item!==undefined;
}

function CapacityTreeView({ capacityTree, selectedCapacity, onSelect,treeRoute }) {
  
 
  
  const onChange = (capacity) => {

    onSelect(capacity);
  };
  const getId=(capacity)=>{
    if(!capacity){
      return 0;
    }
    return capacity.id;
  };

  return (
    <React.Fragment>
      <div className={'capacity-tree-view '}>
        <ul style={{ listStyle: 'none' }}>
          <li>
            <span
              onClick={() =>
                onChange({
                  id: 0,
                  name: localeService.isRussian()
                    ? 'Корневой источник'
                    : 'Root Source',
                })
              }
            >
              {localeService.isRussian()
                ? 'Корневой источник'
                : 'Root Source'}
            </span>
          </li>
          <ul className={'tree-parent open'} style={{ listStyle: 'none' }}>
            {capacityTree.map((capacity) => {
              return (
                <CapacityChild
                  treeRoute={treeRoute}
                  idSelection={getId(selectedCapacity)}
                  key={'cap-' + uuidv4()}
                  capacity={capacity}
                  onChange={onChange}
                />
              );
            })}
          </ul>
        </ul>
      </div>
    </React.Fragment>
  );
}



function CapacityChild({ capacity, onChange, idSelection,treeRoute }) {
  
  const [isOpen, setIsOpen] = useState(idSelection === capacity.id );
  React.useEffect(()=>{
    const path=findPath(capacity,treeRoute);
    setIsOpen(path);
    console.log({id:idSelection,cid:capacity.id});
  },[treeRoute.length,idSelection]);
  return (
    <>
      <li>
        <div
          className={`tree-item ${
             idSelection === capacity.id ? 'tree-item--active' : ''
          }`}
        >
          {capacity.child && capacity.child.length > 0 && (
            <button
              type={'button'}
              className={'toggle-tree-item'}
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? '-' : '+'}
            </button>
          )}
          <span onClick={() => onChange(capacity)}>{capacity.name}</span>
        </div>
      </li>
      {capacity.child && capacity.child.length > 0 && (
        <ul
          className={'tree-parent' + (isOpen ? ' open' : '')}
          style={{ listStyle: 'none' }}
        >
          {capacity.child.map((childCapacity) => {
            return (
              <CapacityChild
                treeRoute={treeRoute}
                idSelection={idSelection}
                key={'cap-' + childCapacity.id}
                capacity={childCapacity}
                onChange={onChange}
              />
            );
          })}
        </ul>
      )}
    </>
  );
}

CapacityChild.propTypes = {
  treeRoute:PropTypes.array,
  capacity: PropTypes.object,
  onChange: PropTypes.func,
  idSelection: PropTypes.number,
};
CapacityTreeView.propTypes = {
  selectedCapacity: PropTypes.object,
  capacityTree: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  isAlwaysOpen: PropTypes.bool,
  treeRoute:PropTypes.array.isRequired,
};

CapacityTreeView.defaultProps = {
  isAlwaysOpen: false,
};

export default CapacityTreeView;
