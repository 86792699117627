import { dashboardConstants } from '../../constants/index';

export const addWidget = widget => ({
    type: dashboardConstants.ADD_WIDGET,
    widget: widget
});

export const updateWidget = widget => ({
    type: dashboardConstants.UPDATE_WIDGET,
    widget: widget
});

export const deleteWidget = (widgetId) => ({
    type: dashboardConstants.DELETE_WIDGET,
    widgetId: widgetId
});

export const updateWidgetsPosition = (widgetList) => ({
    type: dashboardConstants.UPDATE_DASHBOARD_WIDGETS_POSITION,
    widgetList: widgetList
});

export const setEditedWidgets = (widgetList) => ({
    type: dashboardConstants.SET_EDITED_WIDGETS,
    widgetList: widgetList
});

export const setDatetimeFilter = (datetimeFilter) => ({
    type: dashboardConstants.SET_DATETIME_FILTER,
    datetimeFilter: datetimeFilter
});

export const setDataSourceList = (dataSourceList) => ({
    type: dashboardConstants.SET_DATASOURCE_LIST,
    dataSourceList: dataSourceList
});

export const updateDashboardList = (dashboardList) => ({
    type: dashboardConstants.UPDATE_DASHBOARD_LIST,
    dashboardList: dashboardList
});

export const zoomIn = (datetimeFilter) => ({
    type: dashboardConstants.ZOOM_IN,
    datetimeFilter: datetimeFilter
});

export const zoomOut = () => ({ type: dashboardConstants.ZOOM_OUT });

export const clearDashboard = () => ({
    type: dashboardConstants.CLEAR_DASHBOARD
});