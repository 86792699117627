import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import '../../styles/sidebar.scss';
import {localeService} from '../../services/locale.service';
import 'react-contexify/dist/ReactContexify.css';
import svgPlus from '../../sources/img/simple_plus.svg';
import {connect} from 'react-redux';
import {updateDashboardList} from '../../redux/actions/dashboardActions';
import {dashboardService} from '../../services/dashboard.service';
import {ReactSVG} from 'react-svg';
import {Item, Menu, Separator, Submenu, useContextMenu} from 'react-contexify';
import Modal from '../../components/Modal';
import InputText from '../../components/InputText';
import {canDo} from '../../services/permitions.service';
import Loader from '../../components/Loader';


function DashboardList({dashboardList, updateDashboardList}) {
  const [tagList, setTagList] = useState([]);
  const [contextDashboard, setContextDashboard] = useState(null);
  const [newTagName, setNewTagName] = useState('');
  const [isCreateTagOpened, setCreateTagOpened] = useState(false);
  const [dashboardsInGroups, setDashboardsInGroups] = useState([]);
  const [isFetching, setFetching] = useState(false);

  React.useEffect(() => {
    reloadDashboardList();
  }, []);


  const reloadDashboardList = () => {
    setFetching(true);
    dashboardService.getDashboardList()
      .then((dbList) => {
        updateDashboardList(dbList);
      });
    dashboardService.getTagList()
      .then(
        response => {
          setTagList(response.tegs);
          return response.tegs;
        }
      )
      .then(
        tagList => {
          Promise.all(
            tagList.map((tag) => {
              return dashboardService.getDashboardListByTag(tag).then(
                resp => {
                  return {tag: tag, dashboardList: resp.dashboards};
                }
              );
            })
          ).then(
            tList => {
              setTagList(tList);
              let dbInGroups = [];
              tList.map(tag => {
                tag.dashboardList.map(db => {
                  dbInGroups.push(db.id);
                });
              });
              setDashboardsInGroups(dbInGroups);
            }
          );
        }
      ).finally(() => setFetching(false));

  };

  const {show, hideAll} = useContextMenu({
    id: 'DASHBOARD-MENU'
  });

  function displayMenu(e, db, fromTag) {
    setContextDashboard({...db, fromTag: fromTag});
    // put whatever custom logic you need
    // you can even decide to not display the Menu
    show(e);
  }

  const moveDashboardToTag = (dashboardId, tag) => {
    dashboardService.addTagToDashboard(dashboardId, tag).then(
      response => {
        console.log(response);
        reloadDashboardList();
      }
    );
  };

  const createTag = () => {
    moveDashboardToTag(contextDashboard.id, newTagName);
    setNewTagName('');
    setCreateTagOpened(false);
    reloadDashboardList();
  };

  const removeDashboardFromTag = (dashboardId, tag) => {
    dashboardService.deleteTagFromDashboard(dashboardId, tag.tag).then(
      () => {
        reloadDashboardList();
      }
    );
  };

  return (<>
      <div className={'page-header pt-5 mb-5'}>
        <div className={'container-700 d-flex justify-content-between'}>
          <h2>{localeService.isRussian() ? 'Список дашбордов' : 'Dashboard List'}</h2>
          {(canDo('ROLE_ADD_DELETE_DASHBOARD') || canDo('ROLE_ADD_NEW_DASHBOARD') || canDo('ROLE_CREATE_DASHBOARD')) &&
          <Link to="/create_dashboard">
            <button className={'btn eco-btn outlined'}>
              <ReactSVG src={svgPlus}/>
              <span>{localeService.isRussian() ? 'Создать дашборд' : 'Add Dashboard'}</span>
            </button>
          </Link>
          }
        </div>
      </div>
      <div className={'container-700'}>
        {isFetching && <Loader/>}
        {!isFetching && dashboardList &&
        <ul className={'dashboard-tree'} style={{paddingBottom: '3rem'}}>
          {tagList.length > 0 && tagList.map((tag, i) => {
            if (tag.dashboardList && tag.dashboardList.length > 0) {
              return <DashboardGroup key={i} dashboardGroup={tag} displayMenu={(e, db) => displayMenu(e, db, tag)}/>;
            }
          })}
          {dashboardList.sort((a, b) => a.id - b.id).filter(el => !dashboardsInGroups.includes(el.id)).map((db, i) => {
              return (
                <li key={i} className={'tree-item'} onContextMenu={(e) => displayMenu(e, db, null)}>
                  <Link to={'/dashboard/' + db.id}
                        title={db.name}
                        key={i}
                  >{db.name}
                  </Link>
                  {/*<button className={'three-dots btn svg-btn show-on-parent-hovered'} onClick={(e) => displayMenu(e, db, null)}></button>*/}
                </li>
              );
            }
          )}

          <Menu id={'DASHBOARD-MENU'}>
            <Item disabled>
              {contextDashboard && contextDashboard.name}
            </Item>
            <Separator/>
            {contextDashboard && !contextDashboard.fromTag && <Submenu label="Переместить дашборд">
              {tagList.map((tag, i) => {
                return <Item key={i} onClick={() => moveDashboardToTag(contextDashboard.id, tag.tag)}>
                  {tag.tag}
                </Item>;
              })}
              <Separator/>
              <Item key={'createTag'} onClick={() => setCreateTagOpened(true)}>
                Создать ярлык
              </Item>
            </Submenu>}
            {contextDashboard && contextDashboard.fromTag &&
            <Item key={'remove_from_group'}
                  onClick={() => removeDashboardFromTag(contextDashboard.id, contextDashboard.fromTag)}>
              Удалить дашборд из группы
            </Item>}
            <Separator/>
            <Item key={'close'} onClick={() => hideAll()}>
              Закрыть меню
            </Item>
          </Menu>

          <Modal isClosable={false}
                 width={'500px'}
                 isOpen={isCreateTagOpened}
                 title={localeService.isRussian() ? 'Создание нового ярлыка' : 'Create New Tag'}
                 onCancel={() => setCreateTagOpened(false)}
                 onSubmit={() => createTag()}>
            <form>
              <InputText notInline={true} label={localeService.isRussian() ? 'Введите название ярлыка' : 'Tag Name'}
                         value={newTagName} onChange={text => setNewTagName(text)}/>
            </form>
          </Modal>

        </ul>
        }
      </div>
    </>
  );
}

function DashboardGroup({dashboardGroup, displayMenu}) {
  const [isOpen, setIsOpen] = useState(false);
  return (<li className={'db-group'} key={dashboardGroup.tag + 'name'} onClick={() => setIsOpen(!isOpen)}>
      <div className={'db-group-title'}>
        <span className={'db-group-collapsed ' + (isOpen ? 'open' : '')}/>
        <span className={'db-group-name'}>{dashboardGroup.tag}</span>
      </div>
      <ul className={'dashboard-tree tree-parent' + (isOpen ? ' open' : '')} key={dashboardGroup.tag + 'ul'}>
        {dashboardGroup.dashboardList && dashboardGroup.dashboardList.map((db, i) => {
          return <li key={i} className={'tree-item'} onContextMenu={(e) => displayMenu(e, db)}>
            <Link to={'/dashboard/' + db.id}
                  title={db.name}
                  key={db.tag + i}
            >{db.name}
            </Link>
            {/*<button className={'three-dots btn svg-btn show-on-parent-hovered'} onClick={(e) => displayMenu(e, db)}></button>*/}
          </li>;
        })}
      </ul>
    </li>
  );
}


DashboardGroup.propTypes = {
  dashboardGroup: PropTypes.object,
  setShowDBList: PropTypes.func,
  displayMenu: PropTypes.func
};

DashboardList.propTypes = {
  dashboardList: PropTypes.array,
  updateDashboardList: PropTypes.func
};

const mapStateToProps = state => {
  const dashboardList = state.dashboardReducer.dashboardList;
  return {dashboardList};
};

const mapDispatchToProps = {
  updateDashboardList: updateDashboardList
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardList);


