// /* eslint-disable no-unused-vars */
import React, {useState} from 'react';

import AddScenario from './AddScenario';
import EditScenario from './EditScenario';
import Modal from '../../components/Modal';
import ScenarioTable from './ScenarioTable';
import SmartLoader from '../../components/SmartLoader';
import {localeService} from '../../services/locale.service';
import {scenarioService} from '../../services/scenario.service';
import {useTranslation} from 'react-i18next';
import scenarioTransform from '../../utils/scenarioTransform';
import {canDo} from '../../services/permitions.service';

function ScenarioPage() {
  const {t} = useTranslation();
  const [isFetch, setIsFetch] = useState(false);
  const [scenarioList, setScenarioList] = useState([]);
  const [activeModal, setActiveModal] = React.useState(false);
  const [activeEditModal, setActiveEditModal] = React.useState(false);
  const [selectedScenary, setSelectedScenary] = React.useState(undefined);
  const [createScenarioStatus, setCreateScenarioStatus] = React.useState(
    undefined
  );
  const [editScenarioStatus, setScenarioStatus] = React.useState(undefined);
  React.useEffect(() => {
    if (canDo('ROLE_LIST_SCRIPT')) {
      loadScenarios();
    }
  }, []);
  const loadScenarios = () => {
    setIsFetch(true);
    scenarioService.getScenarioList().then((data) => {
      setScenarioList(data.list.map(item => scenarioTransform(item)));


      setIsFetch(false);
      setCreateScenarioStatus(undefined);
      setScenarioStatus(undefined);
    });
  };
  const handleCreateScenario = (status, setter, err) => {
    if (err) {
      setter(true);
      setCreateScenarioStatus(true);
      setScenarioStatus(true);
    }
    if (status) {
      setter(false);
      setCreateScenarioStatus(undefined);

      loadScenarios();
    }
  };
  const startScript = (scenario, actionType) => {
    switch (actionType) {
      case 'ACTIVATE':
        scenario.active = !scenario.active;
        scenario.scriptId = scenario.id;
        scenarioService
          .saveScenario(scenario)
          .then(() => {
            scenarioService
              .getScenarioList()
              .then((data) => setScenarioList(data.list));
          })
          .catch((err) => console.log(err));
        return;
      case 'REMOVE':
        scenarioService.deleteScenario(scenario.id).then(() => {
          scenarioService
            .getScenarioList()
            .then((data) => setScenarioList(data.list));
        });
        return;
      case 'EDIT':
        setActiveEditModal(true);
        setSelectedScenary(scenario);
        return;
    }
  };
  return (<>
      {!canDo('ROLE_LIST_SCRIPT') &&
      <div className={'page-header pt-5 mb-5'}>
        <div className={'container-700'}>
          <h3>{localeService.isRussian() ? 'У Вас недостаточно прав для просмотра списка сценариев!' : 'You have no access to view Scenarium List!'}</h3>
        </div>
      </div>
      }

      {canDo('ROLE_LIST_SCRIPT') &&
      <>
        <div className={'page-header pt-5 mb-5'}>
          <div className={'container d-flex justify-content-between'}>
            <h2>{localeService.isRussian()
              ? 'Список сценариев'
              : 'Scenario List'}</h2>
            {canDo('') && <button
              className="btn eco-btn default   "
              onClick={() => setActiveModal(true)}
              title={t('create') + ' ' + t('scenario.name').toLowerCase()}
            >
              {t('create') + ' ' + t('scenario.name').toLowerCase()}
            </button>}
          </div>
        </div>
        <div className={'container'}>
          <SmartLoader
            status={isFetch}
            waitText={
              localeService.isRussian()
                ? 'Загрузка сценариев'
                : 'Load Scenario List'
            }
          >
            <ScenarioTable
              action={startScript}
              onSelect={(scenario) => {
                setActiveEditModal(true);
                setSelectedScenary(scenario);
              }}
              source={scenarioList}
            />
          </SmartLoader>
        </div>
        {/*<div>*/}
        {/*<nav className="page-header">*/}
        {/*<ul className="nav-left">*/}
        {/*<li>*/}
        {/*<h5>*/}
        {/*{localeService.isRussian()*/}
        {/*? 'Список сценариев'*/}
        {/*: 'Scenario List'}*/}
        {/*</h5>*/}
        {/*</li>*/}
        {/*</ul>*/}
        {/*<ul className="nav-right">*/}
        {/*<li>*/}
        {/*<button*/}
        {/*className="btn eco-btn default   "*/}
        {/*onClick={() => setActiveModal(true)}*/}
        {/*title={t('create') + ' ' + t('scenario.name').toLowerCase()}*/}
        {/*>*/}
        {/*{t('create') + ' ' + t('scenario.name').toLowerCase()}*/}
        {/*</button>*/}
        {/*</li>*/}
        {/*</ul>*/}
        {/*</nav>*/}
        {/*<div className="mr1 ml1" style={{height: '1000px'}}>*/}
        {/*<SmartLoader*/}
        {/*status={isFetch}*/}
        {/*waitText={*/}
        {/*localeService.isRussian()*/}
        {/*? 'Загрузка сценариев'*/}
        {/*: 'Load Scenario List'*/}
        {/*}*/}
        {/*>*/}
        {/*<ScenarioTable*/}
        {/*action={startScript}*/}
        {/*onSelect={(scenario) => {*/}
        {/*setActiveEditModal(true);*/}
        {/*setSelectedScenary(scenario);*/}
        {/*}}*/}
        {/*source={scenarioList}*/}
        {/*/>*/}
        {/*</SmartLoader>*/}
        {/*</div>*/}
        {/*</div>*/}
        <Modal
          title={
            localeService.isRussian() ? 'Создание сценария' : 'Create scenario'
          }
          isOpen={activeModal}
          footer={false}
          isClosable={false}
          onCancel={() => {
            setActiveModal(false);
          }}
        >
          <SmartLoader
            waitText={
              localeService.isRussian()
                ? 'Загрузка сценария'
                : 'Loading scenario...'
            }
            failText={
              localeService.isRussian()
                ? 'Не все поля заполнены'
                : 'Not all fields are filled in'
            }
            failStatus={createScenarioStatus}
            actionButton={() => (
              <React.Fragment>
                <div>
                  <button
                    className="btn eco-btn default   "
                    onClick={() => setActiveModal(false)}
                  >
                    {t('close')}
                  </button>
                </div>
                <div>
                  <button
                    className="btn eco-btn default   "
                    onClick={() => {
                      setCreateScenarioStatus(false);
                    }}
                  >
                    {t('edit')}
                  </button>
                </div>
              </React.Fragment>
            )}
          >
            {!createScenarioStatus && (
              <AddScenario
                onCancel={() => setActiveModal(false)}
                onCreate={(status, err) =>
                  handleCreateScenario(status, setActiveModal, err)
                }
              />
            )}
          </SmartLoader>
        </Modal>

        <Modal
          title={
            localeService.isRussian()
              ? 'Редактирование сценария'
              : 'Edit scenario'
          }
          isOpen={activeEditModal}
          footer={false}
          isClosable={false}
          onCancel={() => {
            setActiveEditModal(false);
          }}
        >
          {selectedScenary && (
            <SmartLoader
              waitText={
                localeService.isRussian()
                  ? 'Загрузка сценария'
                  : 'Loading scenario...'
              }
              failText={
                localeService.isRussian()
                  ? 'Не все поля заполнены'
                  : 'Not all fields are filled in'
              }
              failStatus={editScenarioStatus}
              actionButton={() => (
                <React.Fragment>
                  <div>
                    <button
                      className="btn eco-btn default   "
                      onClick={() => setActiveEditModal(false)}
                    >
                      {t('close')}
                    </button>
                  </div>
                  <div>
                    <button
                      className="btn eco-btn default   "
                      onClick={() => {
                        setScenarioStatus(false);
                      }}
                    >
                      {t('edit')}
                    </button>
                  </div>
                </React.Fragment>
              )}
            >
              {!editScenarioStatus && (
                <EditScenario
                  onCancel={() => setActiveEditModal(false)}
                  onCreate={(status, err) =>
                    handleCreateScenario(status, setActiveEditModal, err)
                  }
                  scenario={selectedScenary}
                />
              )}
            </SmartLoader>
          )}
        </Modal>
      </>}
    </>
  );
}

export default ScenarioPage;
