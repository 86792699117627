import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {localeService} from '../../../services/locale.service';
import {dashboardConstants} from '../../../constants';
import EditWidgetDataSource from '../EditWidgetDataSource';
import {updateWidget} from '../../../redux/actions/dashboardActions';
import {connect} from 'react-redux';
import InputText from '../../../components/InputText';
import {dashboardService} from '../../../services';
import ColorPicker from '../../../components/ColorPicker';

function GroupChartCreate({widgetProps, updateWidget, dashboardDataSources}) {
    const [widget, setWidget] = useState({...widgetProps,
        parameters: {...widgetProps.parameters,
            groupDataSourceName: widgetProps.parameters && widgetProps.parameters.groupDataSourceName || 'Group Data',
            groupColor: widgetProps.parameters && widgetProps.parameters.groupColor || dashboardService.getRandomColor()
        }});

    const {t} = useTranslation();
    const createDataSource = () => {
        const dataSources = [...widget.dataSources, {
            idx: new Date().getTime(), parameters: {dataSourceName: '', type: 'MAIN_DATASOURCE', color: dashboardService.getRandomColor()},
            additionalDataSources: [
                {idx: 'startHourDS', parameters: {dataSourceName: '', type: 'START_HOUR_DATASOURCE'}},
                {idx: 'durationDS', parameters: {dataSourceName: '', type: 'DURATION_DATASOURCE'}},
                {idx: 'valueDS', parameters: {dataSourceName: '', type: 'VALUE_DATASOURCE'}}
            ]
        }];
        setWidget({...widget, dataSources: dataSources});
    };

    React.useEffect( () => {
        let dsList = [];
        widgetProps.dataSources.map(ds => {
            if (ds.parameters.type === 'MAIN_DATASOURCE') {
                let mainDataSource = ds;
                mainDataSource.additionalDataSources = [];
                widgetProps.dataSources.map(addDS => {
                    if (addDS.parameters.parentDataSource && addDS.parameters.parentDataSource === parseInt(mainDataSource.sourceID)) {
                        mainDataSource.additionalDataSources.push(addDS);
                    }
                });
                dsList.push(mainDataSource);
            }
        });
        setWidget({...widget, dataSources: dsList});
    }, []);


    console.log('group widget', widget);

    return <>

        {dashboardDataSources.length > 0 &&
        <form>
            <InputText label={t('name')} value={widget.name}
                       onChange={(text) => setWidget({...widget, name: text})}/>
            <div className={'row'} style={{padding: 0, display: 'flex', width: '100%', position: 'relative'}}>
                <InputText label={localeService.isRussian() ? 'Название группы источников данных' : 'Group Data Source Name'}
                                        value={widget.parameters.groupDataSourceName}
                                        onChange={text => setWidget({
                                            ...widget,
                                            parameters: {...widget, groupDataSourceName: text}
                                        })}/>
                <ColorPicker style={{margin: '0 1rem'}} color={widget.parameters.groupColor} setColor={(color) =>
                    setWidget({...widget, parameters: {...widget.parameters, groupColor: color}})}/>
            </div>
            {widget.dataSources.map((ds, key) => {
                if (ds.parameters.type === 'MAIN_DATASOURCE') {
                    return <EditWidgetDataSource key={key}
                                                 withAdditionalDataSources={true}
                                                 dataSourceNumber={key + 1}
                                                 widgetDataSource={ds}
                                                 onChangeDataSource={(ds) => {
                                                     widget.dataSources[key] = ds;
                                                     setWidget(widget);
                                                 }}
                                                 onDeleteDataSource={() => {
                                                     let dsList = widget.dataSources;
                                                     dsList.splice(key, 1);
                                                     if(key >= 0){
                                                         setWidget({...widget, dataSources: dsList});
                                                     }
                                                 }}
                                                 withColor={true} withUnit={false}
                    />;
                }
            })
            }
        </form>
        }
        <div className={'d-flex justify-content-center'}>
            {dashboardDataSources.length > 0 &&
            <>
                <button type={'button'} className={'btn eco-btn default mr1'}
                        onClick={() => createDataSource()}>
                    {localeService.isRussian() ? 'Добавить источник данных' : 'Add Data Source'}
                </button>
                <button type={'button'} className={'btn eco-btn success mr1'}
                        onClick={() => {
                            let allDataSources = [];
                            widget.dataSources.map((ds) => {
                                if (ds.parameters.type === 'MAIN_DATASOURCE') {
                                    allDataSources = [...allDataSources, ds, ...ds.additionalDataSources];
                                }
                            });
                            updateWidget({
                                ...widget,
                                dataSources: allDataSources,
                                mode: dashboardConstants.EDITION_MODE
                            });
                        }}>
                    {localeService.isRussian() ? 'Сохранить' : 'Save'}
                </button>
            </>
            }
            <button type={'button'} className={'btn eco-btn danger'}
                    onClick={() => {
                        updateWidget({...widgetProps, mode: dashboardConstants.EDITION_MODE});
                    }}>
                {t('cancel')}
            </button>
        </div>

    </>;
}

GroupChartCreate.propTypes = {
    widgetProps: PropTypes.object,
    updateWidget: PropTypes.func,
    dashboardDataSources: PropTypes.array
};

const mapStateToProps = state => {
    const dashboardDataSources = state.dashboardReducer.dataSourceList;
    return {dashboardDataSources};
};

const mapDispatchToProps = {
    updateWidget: updateWidget
};
export default connect(mapStateToProps, mapDispatchToProps)(GroupChartCreate);