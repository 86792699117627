import React, {useState} from 'react';
import UploadFile from './UploadFile';
import {localeService} from '../../services/locale.service';
import SetColsToDatasource from './SetColsToDatasource';
import Modal from '../../components/Modal';
import Loader from '../../components/Loader';
import {Link} from 'react-router-dom';
import csvFile from '../../files/CSV_import_template.csv';
import xlsFile from '../../files/XLS_import_template.xlsx';
import xmlFile from '../../files/XML_import_template.xml';
import svgCSV from '../../sources/images/interface/csv.svg';
import svgXLS from '../../sources/images/interface/xls.svg';
import svgXML from '../../sources/images/interface/xml.svg';
import {ReactSVG} from 'react-svg';
import {importService} from '../../services';

function ImportFromFile() {

    const [importProps, setImportProps] = useState(null);
    const [importResut, setImportResult] = useState({isFetching:false, status: '', message:''});
    const [isOpenImportLoader, setOpenImportLoader] = useState(false);

    const onStartImport = (relations) => {
        setOpenImportLoader(true);
        setImportResult({...importResut, isFetching:true});
        if (importProps.importConf.filename.endsWith('xml')) {
            importService.importDataFrom80020File(relations, importProps.preparseredData.fileUUID).then(
                results => {
                    setImportResult({...importResut, isFetching:false, status: 'SUCCESS'});
                    console.log('importResults', results);
                },error => {
                    setImportResult({isFetching:false, status: 'FAIL', message: error});
                }
            );
        }else{
            importService.importDataFromFile(relations, importProps.preparseredData.fileUUID).then(
                results => {
                    setImportResult({...importResut, isFetching:false, status: 'SUCCESS'});
                    console.log('importResults', results);
                },error => {
                    setImportResult({isFetching:false, status: 'FAIL', message: error});
                }
            );
        }

    };
    return(<div>
        {/*ссылки для скачивания примеров шаблонов*/}
        {!importProps &&
        <div className={'container-700 to-right'}>
            <p className={'help-text'}>
                {localeService.isRussian() ? 'Скачать примеры файлов для импорта:' : 'Download file templates'}
            </p>
            <Link to={csvFile} target="_blank" download>
                <button className={'btn lg-btn svg-btn default big mr2'}
                        title={localeService.isRussian() ? 'Скачать шаблон .CSV' : 'Download .CSV template'}>
                    <ReactSVG src={svgCSV}/>
                </button>
            </Link>
            <Link to={xlsFile} target="_blank" download>
                <button className={'btn lg-btn svg-btn default big mr2'}
                        title={localeService.isRussian() ? 'Скачать шаблон .XLSX' : 'Download .XLSX template'}>
                    <ReactSVG src={svgXLS}/>
                </button>
            </Link>
            <Link to={xmlFile} target="_blank" download>
                <button className={'btn lg-btn svg-btn default big'}
                        title={localeService.isRussian() ? 'Скачать шаблон .XML' : 'Download .XML template'}>
                    <ReactSVG src={svgXML}/>
                </button>
            </Link>
        </div>
        }

        {/*Загрузка файла и настройка парсера*/}
        {!importProps &&
        <UploadFile onUpload={(importProps) => setImportProps(importProps)}/>
        }

        {/*Задать соответствие колонки из файла для переменной устройства*/}
        {importProps &&
        <SetColsToDatasource onStartImport={(relations) => onStartImport(relations)}
                             onCancel={() => setImportProps(null)}
                             importConfig={importProps}/>}

        {/*Лоадер для импорта*/}
        <Modal isOpen={isOpenImportLoader} onCancel={() => setOpenImportLoader(false)} footer={false}>
            {importResut.isFetching && <Loader waitText={localeService.isRussian() ? 'Идет импорт данных...' : 'Import in process...'}/>}
            {importResut.status === 'SUCCESS' && <h5>{localeService.isRussian() ? 'Данные были успешно загружены' : 'Data has been successfully imported.'}</h5>}
            {importResut.status === 'FAIL' &&
            <div className="alert alert-danger">{importResut.message}</div>}
            <div className={'to-right'}>
                <button className={'btn eco-btn success'} onClick={() => {
                    setImportProps(null);
                    setOpenImportLoader(false);
                    setImportResult({isFetching:false, status: '', message:''});
                }}>Ok</button>
            </div>
        </Modal>
    </div>);}

export default ImportFromFile;