import {dashboardConstants} from '../constants';
import moment from 'moment/moment';
import {capacityService} from './capacity.service';

export function generateDayAndNightDataSource(startDate, endDate) {
  const dayAndNightDS = [];
  let datetime = startDate;
  const finishDate = endDate;
  const timeOffset = 0; //moment(new  Date()).utcOffset() / 60;
  dayAndNightDS.push({
    'time_upload': moment(datetime).unix().valueOf(),
    'value': 0,
    'datetime': datetime.format('DD/MM/YY HH:mm:ss'),
    'color': (moment(datetime).hours() < (7 - timeOffset) || moment(datetime).hours() >= (23 - timeOffset)) ? dashboardConstants.NIGHT_COLOR : dashboardConstants.DAY_COLOR,
    'time': (moment(datetime).hours() < (7 - timeOffset) || moment(datetime).hours() >= (23 - timeOffset)) ? 'night' : 'day'
  });
  if (moment(datetime).hours() < (7 - timeOffset)) {
    datetime = moment(datetime).set('hours', (7 - timeOffset)).set('minute', 0).set('second', 0);
    if (datetime < finishDate) {
      dayAndNightDS.push({
        'time_upload': moment(datetime).unix().valueOf(),
        'value': 0,
        'datetime': datetime.format('DD/MM/YY HH:mm:ss'),
        'color': dashboardConstants.DAY_COLOR,
        'time': 'day'
      });
    }

    datetime = moment(datetime).set('hours', (23 - timeOffset)).set('minute', 0).set('second', 0);
    if (datetime < finishDate) {
      dayAndNightDS.push({
        'time_upload': moment(datetime).unix().valueOf(),
        'value': 0,
        'datetime': datetime.format('DD/MM/YY HH:mm:ss'),
        'color': dashboardConstants.NIGHT_COLOR,
        'time': 'night'
      });
    }
  } else if (moment(datetime).hours() >= (7 - timeOffset) && moment(datetime).hours() < (23 - timeOffset)) {
    datetime = moment(datetime).set('hours', (23 - timeOffset)).set('minute', 0).set('second', 0);
    if (datetime < finishDate) {
      dayAndNightDS.push({
        'time_upload': moment(datetime).unix().valueOf(),
        'value': 0,
        'datetime': datetime.format('DD/MM/YY HH:mm:ss'),
        'color': dashboardConstants.NIGHT_COLOR,
        'time': 'night'
      });
    }
  }
  datetime = moment(datetime).add(1, 'days');

  generateNextDayAndNight(dayAndNightDS, datetime, finishDate);

  return {
    id: 'dayAndNight',
    parameters: JSON.stringify({dataSourceName: 'Day and Night', color: '#ccc'}),
    data: dayAndNightDS,
    sourceType: 'DAY_AND_NIGHT'
  };
}

function generateNextDayAndNight(dayAndNightDS, currentDatetime, finishDate) {
  const timeOffset = 0; //moment().utcOffset() / 60;
  let datetime = currentDatetime;
  datetime = moment(datetime).set('hours', (7 - timeOffset)).set('minute', 0).set('second', 0);
  if (datetime < finishDate) {
    dayAndNightDS.push({
      'time_upload': moment(datetime).unix().valueOf(),
      'value': 0,
      'datetime': datetime.format('DD/MM/YY HH:mm:ss'),
      'color': dashboardConstants.DAY_COLOR,
      'time': 'day'
    });
  } else {
    dayAndNightDS.push({
      'time_upload': moment(finishDate).unix().valueOf(),
      'value': 0,
      'datetime': finishDate.format('DD/MM/YY HH:mm:ss')
    });
    return dayAndNightDS;
  }
  datetime = moment(datetime).set('hours', (23 - timeOffset)).set('minute', 0).set('second', 0);
  if (datetime < finishDate) {
    dayAndNightDS.push({
      'time_upload': moment(datetime).unix().valueOf(),
      'value': 0,
      'datetime': datetime.format('DD/MM/YY HH:mm:ss'),
      'color': dashboardConstants.NIGHT_COLOR,
      'time': 'night'
    });
  } else {
    dayAndNightDS.push({
      'time_upload': moment(finishDate).unix().valueOf(),
      'value': 0,
      'datetime': finishDate.format('DD/MM/YY HH:mm:ss')
    });
    return dayAndNightDS;
  }

  datetime = moment(datetime).add(1, 'days');
  generateNextDayAndNight(dayAndNightDS, datetime, finishDate);
}

function generateFunctionBacklights(functionDataSources, dataSources) {
  let backlightsAreas = [];
  if (functionDataSources) {
    functionDataSources.map((source) => {
      if (source.functionCode === 'DEMAND_RESPONSE_DISCHARGE') {
        const startHour = Number.parseInt(source.args[source.args.findIndex(el => el.name === 'discharge_start_hour')].values[0]);
        const endHour = startHour + Number.parseInt(source.args[source.args.findIndex(el => el.name === 'discharge_duration')].values[0]);
        let compareWithExpected = null;
        functionDataSources.map((source3) => {
          if (source3.functionCode === 'DEMAND_RESPONSE_EXPECTED' && source3.data.length === source.data.length && source3.data[0].time_upload === source.data[0].time_upload && source3.data[source3.data.length - 1].time_upload === source.data[source.data.length - 1].time_upload) {
            compareWithExpected = source3;
          }
        });
        if (compareWithExpected) {
          [...Array(source.data.length - 1)].map((e, id) => {
            const timeUpload = moment.unix(source.data[id].time_upload).hours();
            if (timeUpload >= startHour && timeUpload < endHour) {
              let color = 'grey';

              if (compareWithExpected.data[id].value > source.data[id].value) {
                color = '#B71C1C';
              } else {
                color = 'green';
              }
              backlightsAreas = [...backlightsAreas, {
                color: color,
                x1: source.data[id].time_upload,
                x2: source.data[id + 1].time_upload,
                y1: Math.min(source.data[id].value, compareWithExpected.data[id].value),
                y2: Math.max(source.data[id].value, compareWithExpected.data[id].value)
              }];
              backlightsAreas = [...backlightsAreas, {
                color: 'url(#' + color + ')',
                x1: source.data[id].time_upload,
                x2: source.data[id + 1].time_upload
              }];
            }
          });
        }
        return [...Array(source.data.length - 1)].map((e, id) => {
          const timeUpload = moment.unix(source.data[id].time_upload).hours();
          if (timeUpload >= startHour && timeUpload < endHour) {
            const dataSourceIdx = dataSources[0].data.findIndex(el => el.time_upload === source.data[id].time_upload);
            let color = 'grey';
            if (dataSourceIdx >= 0) {
              if (dataSources[0].data[dataSourceIdx].value > source.data[id].value) {
                color = '#B71C1C';
              } else {
                color = 'green';
              }
              backlightsAreas = [...backlightsAreas, {
                color: color,
                x1: source.data[id].time_upload,
                x2: source.data[id + 1].time_upload,
                y1: Math.min(source.data[id].value, dataSources[0].data[dataSourceIdx].value),
                y2: Math.max(source.data[id].value, dataSources[0].data[dataSourceIdx].value)
              }];
              backlightsAreas = [...backlightsAreas, {
                color: 'url(#' + color + ')',
                x1: source.data[id].time_upload,
                x2: source.data[id + 1].time_upload
              }];
            }
          } else {
            return null;
          }
        });
      }
    });
  }
  return backlightsAreas;
}

function generateTestChartData(dataSources) {
  let data = [];
  const names = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
  names.map((name) => {
    let sources = {};
    if (dataSources.length > 0) {
      dataSources.map((ds, i) => {
        sources['value' + i] = Math.floor(Math.random() * Math.floor(1000));
        sources['color' + i] = ds.parameters.color;
      });
    } else {
      [...Array(10).keys()].map((_, i) => {
        sources['value' + i] = Math.floor(Math.random() * Math.floor(1000));
        sources['color' + i] = '#898989';
      });
    }
    data.push({
      name: name,
      ...sources
    });
  });
  return data;
}

function generateTestDotsCloudChartData(dataSources) {
  let data = [];
  if (dataSources.length > 0) {
    dataSources.map((ds, i) => {
      let chartData = [];
      [...Array(10).keys()].map(() => {
        chartData.push({
          x: Math.floor(Math.random() * Math.floor(1000)),
          y: Math.floor(Math.random() * Math.floor(1000)),
          z: 100
        });
      });
      data.push({
        name: 'Source_' + i,
        color: ds.parameters.color,
        data: [...chartData]
      });
    });
  } else {
    let chartData = [];
    [...Array(10).keys()].map(() => {
      chartData.push({
        x: Math.floor(Math.random() * Math.floor(1000)),
        y: Math.floor(Math.random() * Math.floor(1000)),
        z: 100
      });
    });
    data.push({
      name: 'Source_0',
      color: '#898989',
      data: [...chartData]
    });
  }

  return data;
}

function generateTestData(dataSources) {
  let data = [];
  if (dataSources.length === 0) {
    data = [
      {
        name: 1,
        value: Math.floor(Math.random() * Math.floor(1000)),
        color: '#898989'
      },
      {
        name: 2,
        value: Math.floor(Math.random() * Math.floor(1000)),
        color: '#a5a5a5'
      }
    ];
  } else {
    dataSources.map((ds, i) => {
      let sources = {
        name: i + 1
      };
      sources['value'] = Math.floor(Math.random() * Math.floor(1000));
      sources['color'] = ds.parameters.color;
      data.push(sources);
    });
  }
  return data;
}

const modifyChartData = (data, normalization) => {
  if (normalization && data && data.length > 0) {
    let max = getMax(data);
    let min = getMin(data);
    return data.map(el => {
      let value = (el.value - min) / (max - min);
      return {...el, value: value};
    });
  } else {
    return data;
  }
};

const getMax = (data) => {
  let max = data[0].value;
  data.map(el => {
    if (el.value > max) {
      max = el.value;
    }
  });
  return max;
};

const getMin = (data) => {
  let min = data[0].value;
  data.map(el => {
    if (el.value < min) {
      min = el.value;
    }
  });
  return min;
};


const getValueFromPassport = async (limit) => {
  return capacityService.getCapacity(limit.props.capacityId).then(
    capacity => {
      if (capacity.passportDynamic) {
        let pIdx = capacity.passportDynamic.findIndex(el => el.id === limit.props.passportId);
        if (pIdx >= 0) {
          let passport = capacity.passportDynamic[pIdx];
          let bIdx = passport.blocks.findIndex(el => el.id === limit.props.blockId);
          if (bIdx >= 0) {
            let block = passport.blocks[bIdx];
            let fIdx = block.fields.findIndex(el => el.id === limit.props.fieldId);
            let field = block.fields[fIdx];
            if (field && field.value) {
              return field.value;
            }
          }
        }
      }
    }, () => {
      return null;
    });
};

const calculateChartLimitAreas = (chartLimits, dataSources) => {
  let limitValues = chartLimits.values;
  limitValues.map(limit => {
    const limitValue = Number.parseFloat(limit.value);//значение порога
    let limitAreaData = []; //массив данных для подсветки порога
    if (limit.dataSource && limit.dataSource.id) {
      const dataSource = dataSources.find(el => el.id === limit.dataSource.id);
      if (dataSource && dataSource.data) {
        let isEnter = false;
        let areaData = [];
        dataSource.data.map(data => {
          if (data.value >= limitValue) {
            if (!isEnter) {
              isEnter = true;
            }
            areaData.push(data);
          }
          if (data.value < limitValue && isEnter) {
            areaData.push(data);
            isEnter = false;
            limitAreaData.push(areaData);
            areaData = [];
          }
        });
        limit.areaData = limitAreaData;
      }
    }
  });
  return {isVisible: chartLimits.isVisible, values: limitValues};
};

const initLimitsPresets = (presets) => {
  return Promise.all(presets.limits.values.map(async limit => {
    if (limit.type && limit.type === 'PASSPORT_VALUE') {
      let value = await getValueFromPassport(limit).then(
        response => {
          return response;
        }
      );
      return {...limit, value: value/*, areaData: []*/};
    } else {
      return {...limit};
    }
  })).then(result => {
    return {...presets.limits, values: result/*, areaData: []*/};
  });

};

const getDataSourceWithMaxDataCount = (dataSourceList) => {
  let bestDataSource =
    {
      id: 0,
      maxCount: 0
    };
  dataSourceList.map((ds,i) => {
    if (ds.data.length > bestDataSource.maxCount) {
      bestDataSource.maxCount =  ds.data.length;
      bestDataSource.id = i;
    }
  });
  return bestDataSource;
};

const dataSourceMove = (arr, fromIndex, toIndex) => {
  let element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr; // for testing
};

export const widgetsService = {
  initLimitsPresets,
  calculateChartLimitAreas,
  modifyChartData,
  generateDayAndNightDataSource,
  generateFunctionBacklights,
  generateTestChartData,
  generateTestDotsCloudChartData,
  generateTestData,
  getDataSourceWithMaxDataCount,
  dataSourceMove
};