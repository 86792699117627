import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

function CustomTooltip({label, widgetData, functionDataSources}) {
        return (
            <div className="custom-tooltip" style={{backgroundColor: '#ffffffcf', zIndex: '200', padding: '20px', fontSize: '80%'}}>
                <p><b>{label && moment.unix(label).format('DD/MM/YY HH:mm:ss')}</b></p>
                {widgetData.dataSources.map((source, i) => {
                    if (source.data && !source.parameters.isHidden) {
                        const idx = source.data.findIndex(el => el.time_upload === label);
                        if (idx >= 0) {
                            return (<div key={'source_tooltip_' + i}>
                                <i style={{borderBottom: '2px solid ' + source.parameters.color}}>
                                    <small>{source.parameters.dataSourceName}</small>
                                </i>
                                {/*<p>*/}
                                <b style={{marginLeft: '1rem'}}> {Number.parseFloat(source.data[idx].value).toFixed(3)}</b>
                                {/*</p>*/}
                            </div>);
                        }
                    }
                })}
                {functionDataSources && functionDataSources.map((source, i) => {
                    if (source.data) {
                        const idx = source.data.findIndex(el => el.time_upload === label);
                        if (idx >= 0) {
                            const param = source.parameters;
                            return (<div key={'function_tooltip_' + i}>
                                <i style={{borderBottom: '2px solid ' + param.color}}>
                                    <small>{param.dataSourceName}</small>
                                </i>
                                <p><span style={{color: param.color}}
                                         className={'glyphicon glyphicon-stop'}/> {Number.parseFloat(source.data[idx].value).toFixed(3)}
                                </p>
                            </div>);
                        }
                    }
                })}
            </div>
        );
}

CustomTooltip.propTypes = {
    label: PropTypes.number,
    widgetData: PropTypes.object,
    functionDataSources: PropTypes.array
};

export default CustomTooltip;