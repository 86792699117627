import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import DatePicker from 'react-datepicker';

function SelectDate({ selectedDate, format, onChange, label }) {
  return (
    <>
      <div className={'input-group mb-3'}>
        {label && <div className={'input-group-text'}>
          <label>{label}</label>
        </div>}
        <div className={'form-control'} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '0 0.6rem'}}>
        <DatePicker
          dateFormat={format}
          // className={'datetime-picker form-control'}
          // locale="ru"
          showPopperArrow={false}
          selected={selectedDate}
          onChange={(date, event) => {
            event.preventDefault();
            onChange(moment(date));
          }}
        />
        </div>
      </div>
    </>
  );
}

SelectDate.propTypes = {
  selectedDate: PropTypes.object,
  onChange: PropTypes.func,
  format: PropTypes.string,
  label: PropTypes.string,
};
SelectDate.defaultProps = {
  format: 'dd MMMM, yyyy',
};

export default SelectDate;
