import React from 'react';
import PropTypes from 'prop-types';
import {dashboardService, localeService} from '../../../services';
import {connect} from 'react-redux';
import moment from 'moment/moment';
import Loader from '../../../components/Loader';
import SeletTimeGroup from './components/SeletTimeGroup';
import {CSVLink} from 'react-csv';
import {ReactSVG} from 'react-svg';
import svgExportToCSV from '../../../sources/images/interface/export_data.svg';
import {useTranslation} from 'react-i18next';
import {widgetsService} from '../../../services/widget.service';
import SetTableLimits from './components/SetTableLimits';
import {updateWidget} from '../../../redux/actions/dashboardActions';
import {imageService} from '../../../services/image.service';

function TableWidgetView({widgetProps, datetimeFilter, size, updateWidget}) {
  const {t} = useTranslation();
  const [widgetData, setWidgetData] = React.useState(widgetProps);
  const [timeGroup, setTimeGroup] = React.useState(datetimeFilter.timeGroup);
  const [isDropDownOpen, setDropDownOpen] = React.useState(false);
  const [isFetching, setFetching] = React.useState(false);
  const [csvReport, setCSVReport] = React.useState({data: [], headers: [], filename: 'table_widget_data.csv'});
  const [limits, setLimits] = React.useState({isVisible: false, values: []});
  const [initialized, setInitialized] = React.useState(false);
  const [isReverse, setReverse] = React.useState(false);

  React.useEffect(() => {
    let widgetPresets = widgetProps.parameters.presets;
    if (widgetPresets && widgetPresets.limits) {
      widgetsService.initLimitsPresets(widgetProps.parameters.presets, widgetData.dataSources).then(
        limitPresets => {
          setLimits(limitPresets);
          setInitialized(true);
        }
      );
    }
  }, []);

  React.useEffect(() => {
    if (widgetProps.parameters.type === 'TIME_SERIES') {
      setFetching(true);
      setTimeGroup(datetimeFilter.timeGroup);
      dashboardService.loadWidgetData(widgetData.dataSources, datetimeFilter).then(
        dataSources => {
          setWidgetData({...widgetData, dataSources: dataSources});
          renderDataForCsvReport(dataSources);
          setFetching(false);
        }
      );
    }
    if (widgetProps.parameters.type === 'FUNCTION') {
      setFetching(true);
      Promise.all(
        widgetProps.dataSources.map((dataSource) => {
          if (dataSource.parameters) {
            return dashboardService.getWidgetFunctionData(dataSource.id, datetimeFilter.startDate, datetimeFilter.finishDate, widgetProps.parameters.function.functionCode, widgetProps.parameters.function.functionParams, null)
              .then(result => {
                  return {
                    ...dataSource,
                    value: (result.list && result.list[0]) ? Number.parseFloat(result.list[0].value.toFixed(3)) : null
                  };
                }
              );
          } else {
            return null;
          }
        })
      ).then(datas => {
        console.log('New Data', datas);
        setWidgetData({...widgetData, dataSources: datas});
      }).finally(
        () => setFetching(false)
      );
    }
  }, [datetimeFilter, initialized]);

  const renderDataForCsvReport = (dataSources) => {
    let headers = [{label: localeService.isRussian() ? 'Дата / Время' : 'Datetime', key: 'time_upload'}];
    dataSources.map((ds) => {
      headers.push({
        label: ds.parameters.dataSourceName + (ds.parameters.unit ? (' ,' + ds.parameters.unit) : ''),
        key: ds.id.toString()
      });
    });

    let data = [];
    if (dataSources && dataSources.length > 0 && dataSources[0].data) {
      Array.from(Array(dataSources[0].data.length), (_, i) => {
        let dataRow = {};
        dataRow.time_upload = moment.utc(moment.unix(dataSources[0].data[i].time_upload)).format('DD/MM HH:mm:ss');
        dataSources.map((ds) => {
          dataRow[ds.id.toString()] = ds.data[i] && ds.data[i].value.toString() || '';
        });
        data.push(dataRow);
      });
    }
    setCSVReport({...csvReport, data: data, headers: headers});

  };

  const reloadTimeSeries = (tg) => {
    setTimeGroup(tg);
    setDropDownOpen(false);
    if (widgetProps.parameters && widgetProps.parameters.type && widgetProps.parameters.type === 'TIME_SERIES') {
      setFetching(true);
      dashboardService.loadWidgetDataGroupedBy(widgetData.dataSources, datetimeFilter, tg).then(
        dataSources => {
          renderDataForCsvReport(dataSources);
          setWidgetData({...widgetData, dataSources: dataSources});
        }
      ).then(() => {
        setFetching(false);
      });
    }
  };

  const renderTimeSeries = (widgetDataSources, limits) => {
    const eArray = Array.from(Array(widgetsService.getDataSourceWithMaxDataCount(widgetDataSources).maxCount).keys());
    if (isReverse) {
      eArray.reverse();
    }
    return (<tbody>
    {eArray.map((i) => {
      return <tr key={i}>
        <td>{widgetDataSources[0] && widgetDataSources[0].data[i] && moment(moment.unix(widgetDataSources[0].data[i].time_upload)).format('DD/MM HH:mm:ss')}</td>
        {widgetDataSources.sort((a, b) => a.parameters.number - b.parameters.number).map((ds, j) => {
          let isAlertData = false;
          let color = 'inherit';
          if (limits && limits.isVisible && limits.values) {
            let dsLimit = limits.values.find(el => el.dataSource.id === ds.id);
            if (dsLimit && dsLimit.value && ds.data[i]) {
              isAlertData = dsLimit.value <= Number.parseFloat(ds.data[i].value);
              if (isAlertData) {
                color = imageService.hexToRGB(dsLimit.color, 0.7);
              }
            }
          }
          return <td key={j}
                     title={isAlertData ? (localeService.isRussian() ? 'Превышение порогового значения!' : 'Threshold exceeded!') : ''}
                     style={{backgroundColor: color}}
          >
            {ds.data[i] && Number.parseFloat(ds.data[i].value).toFixed(3) || ''}
          </td>;
        })}
      </tr>;
    })}
    </tbody>);
  };

  const renderTimeSeriesHorizontally = (widgetDataSources) => {
    const bestDataSource = widgetsService.getDataSourceWithMaxDataCount(widgetDataSources);
    const eArray = Array.from(Array(widgetsService.getDataSourceWithMaxDataCount(widgetDataSources).maxCount).keys());
    if (isReverse) {
      eArray.reverse();
    }
    return (<tbody>
    <tr>
      <th style={{whiteSpace: 'nowrap', cursor: 'pointer'}} onClick={() => setReverse(!isReverse)}>
              <span className={'d-flex align-items-center'}>
                <strong>{localeService.isRussian() ? 'Дата/Время' : 'Datetime'}</strong>
                <button onClick={() => setReverse(!isReverse)} style={{height: '4px'}}
                        className={'btn arrow-btn btn-sm ' + (!isReverse ? 'to-left' : 'to-right')}/>
              </span>
      </th>
      {eArray.map((i) => {
        return <td key={`dtc${i}`} style={{whiteSpace: 'nowrap'}}>{moment(moment.unix(widgetDataSources[bestDataSource.id].data[i].time_upload)).format('DD/MM HH:mm:ss')}</td>;
      })}
    </tr>
    {widgetDataSources.map((ds, dsIdx) => {
      const dsArray = ds.data ? Array.from(Array(ds.data.length).keys()) : [];
      if (isReverse) {
        eArray.reverse();
      }
      return (<tr key={`ds${dsIdx}`}>
        <th>{ds.parameters.dataSourceName}{ds.parameters.unit ? (' ,' + ds.parameters.unit) : ''}</th>
        {ds.data && ds.data.length > 0 && dsArray.map((dsdIdx) => {
          const dsData = ds.data[dsdIdx];
          let isAlertData = false;
          let color = 'inherit';
          if (limits && limits.isVisible && limits.values) {
            let dsLimit = limits.values.find(el => el.dataSource.id === ds.id);
            if (dsLimit && dsLimit.value) {
              isAlertData = dsLimit.value <= Number.parseFloat(dsData.value);
              if (isAlertData) {
                color = imageService.hexToRGB(dsLimit.color, 0.7);
              }
            }
          }
          return <td key={'dsd' + dsdIdx}
                     title={isAlertData ? (localeService.isRussian() ? 'Превышение порогового значения!' : 'Threshold exceeded!') : ''}
                     style={{backgroundColor: color}}
          >
            {dsData.value && Number.parseFloat(dsData.value).toFixed(3) || ''}
          </td>;
        })}
      </tr>);
    })}
    </tbody>);
  };

  const saveLimitPresets = (limitPreset) => {
    updateWidget({
      ...widgetProps, parameters: {
        ...widgetProps.parameters, presets: {
          ...widgetProps.parameters.presets,
          limits: {
            ...limitPreset, values: limitPreset.values.map(limit => {
              return {...limit, areaData: []};
            })
          }
        }
      }
    });
  };

  function renderFunctionTable(dataSources) {
    return <tbody>
    {dataSources && dataSources.map((ds, i) => {
      let isAlertData = false;
      let color = 'inherit';
      if (limits && limits.isVisible && limits.values) {
        let dsLimit = limits.values.find(el => el.dataSource.id === ds.id);
        if (dsLimit && dsLimit.value && ds.value) {
          isAlertData = dsLimit.value <= Number.parseFloat(ds.value);
          if (isAlertData) {
            color = imageService.hexToRGB(dsLimit.color, 0.5);
          }
        }
      }
      return <tr key={i}>
        <td>{ds.parameters.dataSourceName}</td>
        <td style={{backgroundColor: color}}>{Number.parseFloat(ds.value).toFixed(3)}</td>
      </tr>;
    })}
    </tbody>;
  }

  return <>
    {widgetProps.parameters && widgetProps.parameters.tableContent && (!widgetProps.parameters.type || widgetProps.parameters.type === 'MANUAL') &&
    <div className={'scrollable-zone'}>
      <table className="table" style={{margin: 0 + 'px', border: '1px border grey'}}>
        {widgetProps.parameters.showHeader &&
        <thead>
        <tr>
          {
            widgetProps.parameters.tableContent[0].map((col, j) => {
              return (<th key={j}>{col}</th>);
            })
          }
        </tr>
        </thead>
        }
        <tbody>
        {widgetProps.parameters.tableContent.map((row, i) => {
          if (widgetProps.parameters.showHeader && i === 0) {
            return;
          } else {
            return (
              <tr key={i}>
                {
                  row.map((col, j) => {
                    return <td key={j}>{col}</td>;
                  })
                }
              </tr>
            );
          }
        })}
        </tbody>
      </table>
    </div>
    }
    {widgetProps.parameters && widgetProps.dataSources && widgetProps.parameters.type && widgetProps.parameters.type === 'TIME_SERIES' &&
    <div className="time-series-table">
      <div className={'widget-management-console'}>
        <div className={'dropdown-container datetime-range' + (isDropDownOpen ? ' open' : '')}>
          <SeletTimeGroup timeGroup={timeGroup} datetimeFilter={datetimeFilter}
                          onChangeTimeGroup={(el) => reloadTimeSeries(el)}/>
        </div>
        <CSVLink {...csvReport} separator={';'}>
          <button type="button"
                  title={localeService.isRussian() ? 'Экспорт данных в CSV' : 'Export data to CSV'}
                  className={'btn svg-btn eco-btn dark'} onClick={e => e.stopPropagation()}>
            <ReactSVG src={svgExportToCSV}/>
          </button>
        </CSVLink>
        {limits && <SetTableLimits limits={limits} dataSources={widgetProps.dataSources} onChange={el => {
          // const newLimits = widgetsService.calculateChartLimitAreas(el, widgetData.dataSources);
          setLimits(el);
          renderTimeSeries(widgetProps.dataSources, el);
          saveLimitPresets(el);
        }}/>}
      </div>
      {!isFetching &&
      <div className={'scrollable-zone'} style={{width: size.width + 6, height: widgetProps.h * 100 - 30}}>
        {!widgetProps.parameters.buildHorizontally &&
        <table className="table" style={{margin: 0 + 'px', border: '1px border grey'}}>
          {widgetProps.parameters.showHeader &&
          <thead>
          <tr>
            <th style={{whiteSpace: 'nowrap', cursor: 'pointer'}} onClick={() => setReverse(!isReverse)}>
              <span className={'d-flex align-items-center'}>
                <strong>{localeService.isRussian() ? 'Дата/Время' : 'Datetime'}</strong>
                <button onClick={() => setReverse(!isReverse)} style={{height: '4px'}}
                        className={'btn arrow-btn btn-sm ' + (!isReverse ? 'to-up' : 'to-down')}/>
              </span>
            </th>
            {
              widgetProps.dataSources.sort((a, b) => a.parameters.number - b.parameters.number).map((ds, j) => {
                return (<th style={{whiteSpace: 'nowrap'}}
                  key={j}>{ds.parameters.dataSourceName}{ds.parameters.unit ? (' ,' + ds.parameters.unit) : ''}</th>);
              })
            }
          </tr>
          </thead>
          }
          {widgetData.dataSources.length > 0 && widgetData.dataSources[0].data && widgetData.dataSources[0].data.length === 0 &&
          <tbody>
          <tr>
            <td
              colSpan={widgetData.dataSources.length + 1}> {localeService ? 'Нет данных за выбранный период' : 'Empty data'}</td>
          </tr>
          </tbody>
          }
          {widgetData.dataSources.length > 0 && widgetData.dataSources[0].data && widgetData.dataSources[0].data.length > 0 &&
          renderTimeSeries(widgetData.dataSources, limits)
          }
        </table>}

        {widgetProps.parameters.buildHorizontally &&
        <table className="table" style={{margin: 0 + 'px', border: '1px border grey'}}>
          {widgetData.dataSources.length > 0 && widgetData.dataSources[0].data && widgetData.dataSources[0].data.length > 0 &&
          renderTimeSeriesHorizontally(widgetData.dataSources, limits)
          }
        </table>
        }
      </div>}
      {isFetching && <Loader/>}
    </div>
    }

    {widgetData.parameters && widgetData.dataSources && widgetData.parameters.type && widgetData.parameters.type === 'FUNCTION' &&
    <div>
      <div className={'widget-management-console'}>
        {limits && <SetTableLimits limits={limits} dataSources={widgetProps.dataSources} onChange={el => {
          // const newLimits = widgetsService.calculateChartLimitAreas(el, widgetData.dataSources);
          setLimits(el);
          renderFunctionTable(widgetData.dataSources, el);
          saveLimitPresets(el);
        }}/>}
      </div>
      <table className="table" style={{margin: 0 + 'px', border: '1px border grey', borderCollapse: 'collapse'}}>
        <thead>
        <tr>
          <th>{t('data_source')}</th>
          <th>{t('value')}</th>
        </tr>
        </thead>
        {renderFunctionTable(widgetData.dataSources, limits)}
      </table>
    </div>
    }
  </>;
}

TableWidgetView.propTypes = {
  widgetProps: PropTypes.object,
  datetimeFilter: PropTypes.object,
  size: PropTypes.object,
  updateWidget: PropTypes.func
};

const mapStateToProps = state => {
  const datetimeFilter = state.dashboardReducer.datetimeFilter;
  return {datetimeFilter};
};


const mapDispatchToProps = {
  updateWidget: updateWidget
};

export default (connect(mapStateToProps, mapDispatchToProps)(TableWidgetView));
