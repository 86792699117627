import React, {useState} from 'react';
import {deviceConstants} from '../../constants';
import {useTranslation} from 'react-i18next';
import Select from '../../components/Select';
import InputText from '../../components/InputText';
import {localeService} from '../../services/locale.service';
import PropTypes from 'prop-types';
import '../../styles/tooltip.scss';
import SelectDeviceVariableFromCapacityTree from '../../components/SelectDeviceVariableFromCapacityTree';
import {capacityService} from '../../services/capacity.service';
// import ANALOG_FUNCTION from '../../sources/images/ANALOG_FUNCTION.jpg';
// import ANALOG_RANDOM from '../../sources/images/ANALOG_RANDOM.jpg';
// import DIGITAL_FIXED_COUNT from '../../sources/images/DIGITAL_FIXED_COUNT.jpg';
// import DIGITAL_RANDOM_PERIOD from '../../sources/images/DIGITAL_RANDOM_PERIOD.jpg';


function CreateVirtualVariableGenerator({onChange}) {
  const [generator, setGenerator] = useState({type: 'NONE', config: {}});
  const [capacityTree, setCapacityTree] = useState([]);
  const {t} = useTranslation();


  React.useEffect(() => {
    capacityService.getCapacityTree()
      .then((response) => {
        setCapacityTree(response.list);
      });
  }, []);

  const onHandleChange = (generator) => {
    // if(generator.type === 'LINEAR_VARIABLE_TRANSFORMATION'){
    //   generator.config.scale=1;
    //   generator.config.offset=0;
    //   generator.config.variableID=null;
    // }else{
    //   generator.config = {};
    // }
    setGenerator(generator);
    onChange(generator);
  };

  return <div>
    <Select onSelect={el => {
      let newGenerator = {config: {}, type: el.value};
      if(el.value === 'LINEAR_VARIABLE_TRANSFORMATION'){
        newGenerator.config.scale='1';
        newGenerator.config.offset='0';
        newGenerator.config.variableID=null;
      }
      onHandleChange(newGenerator);
    }}
            value={deviceConstants.generatorList[0]} valueList={deviceConstants.generatorList}
            label={t('generator')}/>
    {generator && generator.type !== 'NONE' &&
    <div className={'device-info'} style={{width: '100%', margin: '4rem 0 2rem', paddingLeft: '2rem'}}>
            <span
              className={'title'}>{localeService.isRussian() ? 'Конфигурация генератора' : 'Generator Configuration'}
            </span>

      {generator.type === 'ANALOG_RANDOM' &&
      <div>
        <div className="tip-wrapper">
          <div className="tip-btn">
            {localeService.isRussian() ? 'Помощь' : 'Help me!'}
            <span className="right-tooltip tooltip">
                            {localeService.isRussian() ? 'НЕПРЕРЫВНЫЙ СЛУЧАЙНЫЙ СИГНАЛ - генератор сигналов, природа которых близка к аналоговым (непрерывным) сигналам. Такие сигналы могут принимать любые значения в заданном диапазоне от МИНИМАЛЬНОГО ЗНАЧЕНИЯ до МАКСИМАЛЬНОГО ЗНАЧЕНИЯ и идти с определенной частотой, то есть количеством изменений в единицу времени.'
                              : 'CONTINUOUS RANDOM SIGNAL - is a generator of signals whose nature is close to analog (continuous) signals. Such signals can take any values in a given range from the MINIMUM VALUE to the MAXIMUM VALUE and go with a certain frequency, that is, the number of changes per unit of time.'}

                        </span>
            {/*<span className="bottom-tooltip tooltip image-tooltip">*/}
            {/*<img className={'graph-help-image'} src={ANALOG_RANDOM}/>*/}
            {/*</span>*/}
          </div>
        </div>
        <InputText label={localeService.isRussian() ? 'Минимальное значение =' : 'Minimal value ='}
          // isCeloe={true}
                   isDecimal={true}
                   isRequired={true}
                   value={generator.config.minValue}
                   placeHolder={(localeService.isRussian() ? 'Например: ' : 'For example: ') + '-1'}
                   onChange={val => {
                     const config = {...generator.config, minValue: val};
                     const newGenerator = ({...generator, config: config});
                     onHandleChange(newGenerator);
                   }}/>
        <InputText label={localeService.isRussian() ? 'Максимальное значение =' : 'Maximal value ='}
          // isCeloe={true}
                   isDecimal={true}
                   isRequired={true} value={generator.config.maxValue}
                   placeHolder={(localeService.isRussian() ? 'Например: ' : 'For example: ') + '1'}
                   onChange={val => {
                     const config = {...generator.config, maxValue: val};
                     const newGenerator = ({...generator, config: config});
                     onHandleChange(newGenerator);
                   }}/>
      </div>
      }

      {generator.type === 'ANALOG_FUNCTION' &&
      <div>
        <div className="tip-wrapper">
          <div className="tip-btn">
            {localeService.isRussian() ? 'Помощь' : 'Help me!'}
            <span className="right-tooltip tooltip">
                            {localeService.isRussian() ? 'НЕПРЕРЫВНЫЙ СИГНАЛ, ЗАДАННЫЙ ФУНЦИЕЙ - это генератор непрерывных сигналов, определяемых ФУНКЦИЕЙ с одной переменной, которая всегда называется time. Часто такой сигнал нужен, чтобы промоделировать простые профили нагрузок, которые повторяются раз в сутки, но могут быть и другие периоды времени, например неделя, месяц, год. ФУНКЦИЮ для данного сигнала необходимо писать на языке javaScript, используя библиотеку Math!'
                              : 'CONTINUOUS SIGNAL SET BY A FUNCTION - is a generator of continuous signals defined by a FUNCTION with one variable, which is always called time. Often such a signal is needed to simulate simple load profiles that are repeated once a day, but there may be other periods of time, for example, week, month, year. The FUNCTION for this signal must be written in javaScript using the Math library!'}
                        </span>
            {/*<span className="bottom-tooltip tooltip image-tooltip" style={{bottom: '-550px'}}>*/}
            {/*<img className={'graph-help-image'} src={ANALOG_FUNCTION}/>*/}
            {/*</span>*/}
          </div>
        </div>
        <InputText label={localeService.isRussian() ? 'Функция:' : 'Function:'} isRequired={true}
                   value={generator.config.mathExpression}
                   placeHolder={(localeService.isRussian() ? 'Например: ' : 'For example: ') + 'Math.sin(t)'}
                   onChange={val => {
                     const config = {...generator.config, mathExpression: val};
                     const newGenerator = ({...generator, config: config});
                     onHandleChange(newGenerator);
                   }}/>
      </div>
      }
      {generator.type === 'DIGITAL_RANDOM_PERIOD' &&
      <div>
        <div className="tip-wrapper">
          <div className="tip-btn">
            {localeService.isRussian() ? 'Помощь' : 'Help me!'}
            <span className="right-tooltip tooltip">
                            {localeService.isRussian() ? 'ДИСКРЕТНЫЙ СИГНАЛ СО СЛУЧАЙНЫМ ПЕРИОДОМ - это генератор дискретных (заданных ступенчатой функцией 0 или 1) сигналов. Имитирует сигнал с датчика открытия/закрытия, движения, включения или выключения оборудования, и других с дискретным выходом. Минимальное значение всегда 0, максимальное значение всегда 1. Но период срабатывания всегда разный - случайный, задаётся ОТ и ДО. Как случайный по времени переход в 1, так и обратно случайный по времени переход в 0.'
                              : 'SIGNAL SIGNAL WITH A RANDOM PERIOD - is a generator of discrete (specified by a step function 0 or 1) signals. It simulates a signal from a sensor for opening / closing, movement, turning on or off equipment, and others with a digital output. The minimum value is always 0, the maximum value is always 1. But the response period is always different - random, it is set by FROM and TO. Both a time-random transition to 1 and vice versa a time-random transition to 0.'}
                        </span>
            {/*<span className="bottom-tooltip tooltip image-tooltip">*/}
            {/*<img className={'graph-help-image'} src={DIGITAL_RANDOM_PERIOD}/>*/}
            {/*</span>*/}
          </div>
        </div>
        <InputText label={localeService.isRussian() ? 'От' : 'From'} inputType={'time'} isRequired={true}
                   value={generator.config.from}
                   placeHolder={(localeService.isRussian() ? 'Например: ' : 'For example: ') + '00:00'}
                   onChange={val => {
                     const config = {...generator.config, from: val};
                     const newGenerator = ({...generator, config: config});
                     onHandleChange(newGenerator);
                   }}/>
        <InputText label={localeService.isRussian() ? 'До' : 'To'} inputType={'time'} isRequired={true}
                   value={generator.config.to}
                   placeHolder={(localeService.isRussian() ? 'Например: ' : 'For example: ') + '12:00'}
                   onChange={val => {
                     const config = {...generator.config, to: val};
                     const newGenerator = ({...generator, config: config});
                     onHandleChange(newGenerator);
                   }}/>
      </div>
      }
      {generator.type === 'DIGITAL_FIXED_COUNT' &&
      <div>
        <div className="tip-wrapper">
          <div className="tip-btn">
            {localeService.isRussian() ? 'Помощь' : 'Help me!'}
            <span className="right-tooltip tooltip">
                            {localeService.isRussian() ? 'ДИСКРЕТНЫЙ ФИКСИРОВАННЫЙ СИГНАЛ - это генератор дискретных данных, который имитирует в заданном интервале времени (ОТ и ДО) некоторое КОЛИЧЕСТВО единичных сигналов , то есть сигналов длительностью в один дискрет времени. Если КОЛИЧЕСТВО = 1, а ОТ и ДО часы в течение дня, то на этом интервале будет 1 случайный единичный сигнал. Для этого генератора нужно сделать флаг период сигнала. Если этот флаг установлен, то функция будет повторяться через указанный промежуток времени.'
                              : 'DISCRETE FIXED SIGNAL - is a discrete data generator that simulates in a given time interval (FROM and TO) a certain COUNT of single signals, that is, signals lasting one discrete time. If COUNT = 1, and FROM and TO are hours during the day, then on this interval there will be 1 random unit signal. For this generator, you need to make a flag signal period. If this flag is set, then the function will be repeated after the specified period of time.'}
                        </span>
            {/*<span className="bottom-tooltip tooltip image-tooltip" style={{bottom: '-640px'}}>*/}
            {/*<img className={'graph-help-image'} src={DIGITAL_FIXED_COUNT}/>*/}
            {/*</span>*/}
          </div>
        </div>
        <InputText label={localeService.isRussian() ? 'От' : 'From'} inputType={'time'} isRequired={true}
                   value={generator.config.from}
                   placeHolder={(localeService.isRussian() ? 'Например: ' : 'For example: ') + '00:00'}
                   onChange={val => {
                     const config = {...generator.config, from: val};
                     const newGenerator = ({...generator, config: config});
                     onHandleChange(newGenerator);
                   }}/>
        <InputText label={localeService.isRussian() ? 'До' : 'To'} inputType={'time'} isRequired={true}
                   value={generator.config.to}
                   placeHolder={(localeService.isRussian() ? 'Например: ' : 'For example: ') + '12:00'}
                   onChange={val => {
                     const config = {...generator.config, to: val};
                     const newGenerator = ({...generator, config: config});
                     onHandleChange(newGenerator);
                   }}/>
        <InputText label={localeService.isRussian() ? 'Количество' : 'Count'} isInteger={true} isRequired={true}
                   value={generator.config.count}
                   placeHolder={(localeService.isRussian() ? 'Например: ' : 'For example: ') + '1'}
                   onChange={val => {
                     const config = {...generator.config, count: val};
                     const newGenerator = ({...generator, config: config});
                     onHandleChange(newGenerator);
                   }}/>
      </div>
      }

      {generator.type === 'LINEAR_VARIABLE_TRANSFORMATION' &&
      <div>
        <InputText label={localeService.isRussian() ? 'Коэффициент масштабирования' : 'Scale'}
                   isRequired={true} isDecimal={true}
                   defaultValue={1}
                   value={generator.config.scale}
                   onChange={val => {
                     const newGenerator = ({...generator, config: {...generator.config, scale: val + ''}});
                     onHandleChange(newGenerator);
                   }}/>
        <InputText label={localeService.isRussian() ? 'Коэффициент смещения' : 'Offset'}
                   isRequired={true} isDecimal={true}
                   defaultValue={0}
                   value={generator.config.offset}
                   onChange={val => {
                     const newGenerator = ({...generator, config: {...generator.config, offset: val+''}});
                     onHandleChange(newGenerator);
                   }}/>
        <div className={'input-group mb-3'}>
          <div className={'input-group-text'}>
            <label>{localeService.isRussian() ? 'Переменная устройства' : 'Device variable'}</label>
          </div>
          <SelectDeviceVariableFromCapacityTree capacityTree={capacityTree}
                                                onSelect={(device) => {
                                                  const newGenerator = ({...generator, config: {...generator.config, variableID: device.id + ''}});
                                                  onHandleChange(newGenerator);
                                                }}/>
        </div>
      </div>
      }

      {generator.type !== 'LINEAR_VARIABLE_TRANSFORMATION' &&
      <Select label={t('cron')}
              placeholder={localeService.isRussian() ? 'Выберите' : 'Select time'}
        // value={{value: '', name: t('check_cron')}}
              onSelect={val => {
                const config = {...generator.config, cron: val.value};
                const newGenerator = ({...generator, config: config});
                onHandleChange(newGenerator);
              }}
              valueList={[
                {value: '', name: t('check_cron')},
                {value: '0 */1 * * * *', name: t('every_minute')},
                {value: '0 */15 * * * *', name: t('every_15minute')},
                {value: '0 */30 * * * *', name: t('every_30minute')},
                {value: '0 0 */1 * * *', name: t('every_hour')},
                {value: '0 0 0 * * *', name: t('every_day')},
                {value: '0 0 * * 0', name: t('every_week')},
                {value: '0 0 1 * *', name: t('every_month')},
                {value: '0 0 1 1 *', name: t('every_year')}
              ]}/>
      }

    </div>
    }
  </div>;
}

CreateVirtualVariableGenerator.propTypes = {
  onChange: PropTypes.func
};

export default CreateVirtualVariableGenerator;