import React from 'react';
import Portal from './Portal';
import PropTypes from 'prop-types';
import '../styles/modal.scss';
// import ClickOutside from './ClickOutside';
import { useTranslation } from 'react-i18next';


function Modal({title, isOpen, onCancel, children, isClosable, footer, onSubmit, isConfirm, width, height, overflow}) {
    const { t } = useTranslation();
    return (
        <>
            {isOpen &&
            <Portal>
                <div className={'modal-layout open'}>
                    {/*<ClickOutside onClick={() => onCancel()} disabled={!isClosable}>*/}
                        <div className="modal-container" style={{maxWidth: width}}>
                            {isClosable && <button type='button' onClick={() => onCancel()} style={{zIndex: 100}} className={'btn close-btn'}>&times;</button>}
                            {title && <div className={'modal-header'}>
                                <div className={'modal-title'}>{title}</div>
                            </div>}
                            <div className={'modal-body'} style={{maxWidth: width, maxHeight: height, overflow: overflow}}>
                                {children}
                            </div>
                            {footer &&
                            <div className={'modal-footer'}>
                                <button type={'button'} className={'btn eco-btn default'} onClick={()=>onCancel()}>{t(isConfirm ? 'no':'close')}</button>
                                {onSubmit &&
                                <button type={'button'} className={'btn eco-btn success ml1'} onClick={() => onSubmit()}>{t(isConfirm ? 'yes' : 'apply')}</button>
                                }
                            </div>}
                        </div>
                    {/*</ClickOutside>*/}
                </div>
            </Portal>
            }
        </>
    );
}

Modal.propTypes = {
    title: PropTypes.string,
    isOpen: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    children: PropTypes.node,
    isClosable: PropTypes.bool,
    footer: PropTypes.bool,
    isConfirm: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string,
    overflow: PropTypes.string
};

Modal.defaultProps = {
    title: null,
    isOpen: false,
    onCancel: () => {},
    onSubmit: () => {},
    children: null,
    isClosable: true,
    footer: true,
    isConfirm: false,
    width: 'auto',
    height: 'auto',
    overflow: 'unset'
};


export default Modal;
