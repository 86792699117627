import React from 'react';
import {Link, Route, Switch, useLocation} from 'react-router-dom';
import {localeService} from '../../services/locale.service';
import Profile from './Profile';
import {organizationService} from '../../services/organization.service';
import FieldView from '../../components/FieldView';
import UsersManagement from './UsersManagement';
import AccessTariffs from './AccessTariffs';
import Modal from '../../components/Modal';
import InputText from '../../components/InputText';
import {useTranslation} from 'react-i18next';
import Loader from '../../components/Loader';
import AlertError from '../../components/AlertError';
import Roles from './Roles';
import {logout} from '../../services/login.service';
import {useHistory} from 'react-router-dom';
import {canDo} from '../../services/permitions.service';

function OfficePage() {
    let location = useLocation();
    const history = useHistory();

    return <>
        <div className={'page-header pt-5 mb-5'}>
            <div className={'container'}>{/*className={'container-700'}>*/}
                <div className={'d-flex justify-content-between'}>
                    <h2>{localeService.isRussian() ? 'Личный кабинет' : 'Office'}</h2>
                    <button type="button" onClick={()=>{
                        logout();
                        history.go('/login');
                    }}
                            className={'btn eco-btn danger'}>{localeService.isRussian() ? 'Выйти из системы' : 'Logout'}
                    </button>
                </div>
                {/*<label>{localeService.isRussian() ? 'Выберите тип импорта' : 'Select Import type'}</label>*/}
            </div>
            <div className={'container'}>{/*className={'container-700'}>*/}
            <ul className="nav nav-tabs justify-content-start" style={{marginBottom: '-2rem', marginTop:'5rem'}}>
                <li className="nav-item">
                    <Link to="/office">
                        <button type="button"
                                className={'nav-link ' + (location.pathname === '/office' ? 'active' : '')}>
                            {localeService.isRussian() ? 'Профиль' : 'Profile'}
                        </button>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/office/organization">
                        <button type="button"
                                className={'nav-link ' + (location.pathname === '/office/organization' ? 'active' : '')}>
                            {localeService.isRussian() ? 'Организация' : 'Organization'}
                        </button>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/office/roles">
                        <button type="button"
                                className={'nav-link ' + (location.pathname === '/office/roles' ? 'active' : '')}>
                            {localeService.isRussian() ? 'Роли и права доступа' : 'Roles and access rights'}
                        </button>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/office/users_management">
                        <button type="button"
                                className={'nav-link ' + (location.pathname === '/office/users_management' ? 'active' : '')}>
                            {localeService.isRussian() ? 'Пользователи' : 'Account List'}
                        </button>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/office/access_tariffs">
                        <button type="button"
                                className={'nav-link ' + (location.pathname === '/office/access_tariffs' ? 'active' : '')}>
                            {localeService.isRussian() ? 'Тарифы доступа' : 'Access Tariffs'}
                        </button>
                    </Link>
                </li>
            </ul>
            </div>
        </div>
        {/*/!*Панель управления видом и режимом настройки*!/*/}
        {/*<nav className="page-header">*/}
            {/*<ul className="nav-left">*/}
                {/*<li>*/}
                    {/*<Link to="/office">*/}
                        {/*<button type="button"*/}
                                {/*className={'btn eco-btn default ' + (location.pathname === '/office' ? 'active' : '')}>*/}
                            {/*{localeService.isRussian() ? 'Профиль' : 'Profile'}*/}
                        {/*</button>*/}
                    {/*</Link>*/}
                {/*</li>*/}
                {/*<li>*/}
                    {/*<Link to="/office/organization">*/}
                        {/*<button type="button"*/}
                                {/*className={'btn eco-btn default ' + (location.pathname === '/office/organization' ? 'active' : '')}>*/}
                            {/*{localeService.isRussian() ? 'Организация' : 'Organization'}*/}
                        {/*</button>*/}
                    {/*</Link>*/}
                {/*</li>*/}
                {/*<li>*/}
                    {/*<Link to="/office/roles">*/}
                        {/*<button type="button"*/}
                                {/*className={'btn eco-btn default ' + (location.pathname === '/office/roles' ? 'active' : '')}>*/}
                            {/*{localeService.isRussian() ? 'Роли и права доступа' : 'Roles and access rights'}*/}
                        {/*</button>*/}
                    {/*</Link>*/}
                {/*</li>*/}
                {/*<li>*/}
                    {/*<Link to="/office/users_management">*/}
                        {/*<button type="button"*/}
                                {/*className={'btn eco-btn default ' + (location.pathname === '/office/users_management' ? 'active' : '')}>*/}
                            {/*{localeService.isRussian() ? 'Пользователи' : 'Account List'}*/}
                        {/*</button>*/}
                    {/*</Link>*/}
                {/*</li>*/}
                {/*<li>*/}
                    {/*<Link to="/office/access_tariffs">*/}
                        {/*<button type="button"*/}
                                {/*className={'btn eco-btn default ' + (location.pathname === '/office/access_tariffs' ? 'active' : '')}>*/}
                            {/*{localeService.isRussian() ? 'Тарифы доступа' : 'Access Tariffs'}*/}
                        {/*</button>*/}
                    {/*</Link>*/}
                {/*</li>*/}
            {/*</ul>*/}
            {/*<ul className="nav-right">*/}
                {/*<li>*/}
                    {/*<Link to="/login">*/}
                        {/*<button type="button"*/}
                                {/*className={'btn eco-btn danger'}>{localeService.isRussian() ? 'Выйти из системы' : 'Logout'}*/}
                        {/*</button>*/}
                    {/*</Link>*/}
                {/*</li>*/}
            {/*</ul>*/}
        {/*</nav>*/}
        <div className={'container'}>
            <Switch>
                <Route exact={true} path={'/office/'} component={Profile}/>
                <Route exact={true} path={'/office/organization'} component={Organization}/>
                <Route exact={true} path={'/office/users_management'} component={UsersManagement}/>
                <Route exact={true} path={'/office/roles'} component={Roles}/>
                <Route exact={true} path={'/office/access_tariffs'} component={AccessTariffs}/>
            </Switch>
        </div>
    </>;
}

function Organization() {
    const {t} = useTranslation();
    const [organization, setOrganization] = React.useState();
    const [isOpen, setOpen] = React.useState(false);
    const [isFetching, setFetching] = React.useState(false);
    const [error, setError] = React.useState('');

    React.useEffect(() => {
        reloadOrganization();
    }, []);

    const reloadOrganization = () => {
        organizationService.getOrganizationInfo().then(
            resp => {
                setOrganization(resp.model);
            }
        );
    };

    const applyInfo = () => {
        setFetching(true);
        organizationService.editOrganizationInfo(organization).then(
            response => {
                console.log(response);
            }, error => {
                setError('Cannot update Info! ' + error);
            }
        ).finally(() => {
            setFetching(false);
            setOpen(false);
            reloadOrganization();
        });
    };

    return <>
        <AlertError isOpen={error !== ''} message={error}/>
        {organization &&
        <div className={'row'}>
            <div className={'col-60'} style={{margin: '1rem auto', float: 'none', display: 'block'}}>
                <FieldView label={localeService.isRussian() ? 'Наименование организации' : 'Organization Name'}
                           value={organization.name}/>
                <FieldView label={localeService.isRussian() ? 'Дата создания' : 'Created'}
                           value={organization.dateCreated}/>
                <h5>{localeService.isRussian() ? 'Контакты' : 'Contact'}</h5>
                <p/>
                <FieldView label={localeService.isRussian() ? 'Адрес' : 'Address'} value={organization.address}/>
                <FieldView label={localeService.isRussian() ? 'Телефон' : 'Phones'} value={organization.phones}/>

                <h5>{localeService.isRussian() ? 'Реквизиты' : 'Details'}</h5>
                <p/>
                <FieldView label={localeService.isRussian() ? 'ИНН' : 'INN'} value={organization.inn}/>
                <FieldView label={localeService.isRussian() ? 'КПП' : 'KPP'} value={organization.kpp}/>
              {canDo() && <div className={'to-right'} onClick={() => setOpen(true)}>
                    <button className={'btn eco-btn default'}>{localeService.isRussian() ? 'Редактировать информацию' : 'Edit Info'}</button>
                </div>}
            </div>
        </div>
        }
        <Modal isOpen={isOpen}
               isClosable={false}
               footer={false}
               title={localeService.isRussian() ? 'Редактирование информации об организации' : 'Edit Organization Info'}>
            {organization && !isFetching && <form>
                <InputText label={localeService.isRussian() ? 'Наименование организации' : 'Organization Name'}
                           value={organization.name} onChange={text => setOrganization({...organization, name: text})}/>
                <h5>{localeService.isRussian() ? 'Контакты' : 'Contact'}</h5>
                <p/>
                <InputText label={localeService.isRussian() ? 'Адрес' : 'Address'}
                           value={organization.address} onChange={text => setOrganization({...organization, address: text})}/>
                <InputText label={localeService.isRussian() ? 'Телефон' : 'Phones'}
                           value={organization.phones} onChange={text => setOrganization({...organization, phones: text})}/>

                <h5>{localeService.isRussian() ? 'Реквизиты' : 'Details'}</h5>
                <p/>
                <InputText label={localeService.isRussian() ? 'ИНН' : 'INN'}
                           value={organization.inn} onChange={text => setOrganization({...organization, inn: text})}/>
                <InputText label={localeService.isRussian() ? 'КПП' : 'KPP'}
                           value={organization.kpp} onChange={text => setOrganization({...organization, kpp: text})}/>
                <hr/>
                <div className={'d-flex justify-content-center'}>
                    <button className={'btn eco-btn success'} onClick={() => applyInfo()}>{t('apply')}</button>
                    <button className={'btn eco-btn default'}
                            onClick={() => {
                                setOpen(false);
                                reloadOrganization();
                            }}>{localeService.isRussian() ? 'Отмена' : 'Cancel'}</button>
                </div>
            </form>}
            {isFetching && <Loader/>}
        </Modal>
    </>;
}

export default OfficePage;