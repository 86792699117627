/* eslint-disable no-unused-vars */

import React, { useState } from 'react';

import ImageUploader from '../../../components/ImageUploader';
import InputText from '../../../components/InputText';
import Loader from '../../../components/Loader';
import PropTypes from 'prop-types';
import SelectCapacityTree from '../../../components/SelectCapacityTree';
import { capacityService } from '../../../services';
import { localeService } from '../../../services/locale.service';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';

function CapacityForm({
  parentCapacity,
  capacityTree,
  onCancel,
  selectedCapacity,
  titleText,
  buttonText,
}) {
  const { t } = useTranslation();
  const [isFetching] = useState(false);
  const [error, setError] = useState('');

  const handleSubmitForm = (values) => {
    const idValue = values.ownerCapacityId ? values.ownerCapacityId.id : 0;
    capacityService
      .createCapacity({
        ...values,
        ownerCapacityId: idValue,
        group: (idValue) === 0,
      })
      .then(() => {
        onCancel({ needToReload: true, id: values.id || null });
      })
      .catch((e) => {
        console.log('error', e);
        setError(e);
        // setError(`Ошибка ${titleText}`);
      });
  };
  const validateForm = (formData) => {
    if (!formData.name) {
      return { name: localeService.isRussian() ? 'Введите наименование источника' : 'Enter Required Fields!' };
    }
  };

  // const createCapacity = () => {
  //     setFetching(true);
  //     capacityService.createCapacity(capacity).then(
  //         () => {
  //             onCancel({needToReload: true});
  //         },
  //         (error) => {
  //             setFetching(false);
  //             setError('Error: ' + error.errorMsg);
  //         }
  //     );
  // };

  return (
    <div>
      <h3 style={{ margin: '0 0 2rem 0' }}>{titleText}</h3>
      <hr />
      {!isFetching && !error && (
        <Form
          validate={validateForm}
          initialValues={
            selectedCapacity
              ? {
                  ...selectedCapacity,
                  ownerCapacityId: parentCapacity,
                }
              : { ownerCapacityId: parentCapacity }
          }
          onSubmit={handleSubmitForm}
          render={({ form, handleSubmit, values }) => (
            <div className={'mt-5'}>
              <form className="form" onSubmit={handleSubmit}>
                {values.ownerCapacityId !== 0 && (
                  <Field name="imgView">
                    {({ input }) => (
                      <ImageUploader
                        onChange={(file) => input.onChange(file)}
                      />
                    )}
                  </Field>
                )}
                <Field name="name">
                  {({ input, meta }) => (
                    <InputText
                      className={'mt-3'}
                      value={input.value}
                      label={t('name') + ':'}
                      isRequired={true}
                      warningText={meta.error}
                      onChange={(text) => input.onChange(text)}
                    />
                  )}
                </Field>

                <div className={'input-group understroke required'}>
                  <div className={'input-group-text'}>
                    <label>
                      {(localeService.isRussian()
                        ? 'Корневой источник'
                        : 'Root Source') + ':'}
                    </label>
                  </div>
                  <Field name="ownerCapacityId">
                    {({ input }) => (
                      <SelectCapacityTree
                        capacityTree={capacityTree}
                        capacity={input.value}
                        onSelect={(capacity) => {
                          console.log(capacity);
                          input.onChange(capacity.id);
                        }}
                      />
                    )}
                  </Field>
                </div>
                {isFetching && <Loader waitText={t('wait')} />}
                {error && <div className="alert alert-danger">{error}</div>}
                <hr className={'mt-5'}/>
                <div className={'mt-5 d-flex justify-content-center'}>
                  <button type={'submit'} className={'btn eco-btn success'}>
                    {t(buttonText)}
                  </button>
                  {onCancel && (
                    <button
                      type="reset"
                      className={'btn eco-btn danger ml2'}
                      onClick={() => onCancel()}
                    >
                      {t('cancel')}
                    </button>
                  )}
                </div>
              </form>
            </div>
          )}
        />
      )}
    </div>
  );
}

CapacityForm.propTypes = {
  parentCapacity: PropTypes.number,
  capacityTree: PropTypes.array.isRequired,
  onCancel: PropTypes.func,
  selectedCapacity: PropTypes.object,
  titleText: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};

CapacityForm.defaultProps = {
  parentCapacity: 0,
  capacityTree: [],
  onCancel: null,
  selectedCapacity: null,
};
export default CapacityForm;
