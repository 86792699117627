/* eslint-disable no-unused-vars */
import '../../../styles/scenario-editor.scss';
import {Field, Form} from 'react-final-form';

import {capacityService, deviceService} from '../../../services';

import CustomInput from '../../../components/CustomInput';
import PropTypes from 'prop-types';
import React from 'react';
import {ReactSVG} from 'react-svg';
import ScenarioControl from './ScenarioControl';
import SelectCapacityTree from '../../../components/SelectCapacityTree';
import {scenarioConstants} from '../../../constants';
import svgTrash from '../../../sources/images/trash.svg';
import {useTranslation} from 'react-i18next';
import {localeService} from '../../../services/locale.service';
import {capacityConstants} from '../../../constants/capacity.constants';
import {uuidv4} from '../../../utils/uuid';
import LocalAccordion from './LocalAccordion';
import Multicast from './Multicast';
import arrayMutators from 'final-form-arrays';
import {FieldArray} from 'react-final-form-arrays';
import '../../../styles/scenario.scss';
import {canDo} from '../../../services/permitions.service';

/**
 *
 * @param passport
 * @param capacity
 * @returns {*}
 */
const convertPassport = (passport, capacity, headers) => {
    const passportName = headers.name;

    return headers
    && headers.groups.length > 1
        ? headers.groups.map(group => group.items)
            // .filter(item => item.type === 'number')
            // .map(item => ({name: `${capacity.name}_${item.id}`}))
            .reduce((a, b) => [...a, ...b])
            .filter(item => item.type && item.type !== 'uploader')
            .map(item => ({name: `${capacity.name}_${passportName}_${item.label}`, value: passport[item.id] || 0}))
        : headers.groups[0].items
            .filter(item => item.type && item.type !== 'uploader')
            .map(item => ({name: `${capacity.name}_${passportName}_${item.label}`, value: passport[item.id] || 0}));
};


const transformStatements = (model) =>
    model.scriptStatementList.statements.length > 0 &&
    model.scriptStatementList.statements.map((item, index) => ({
        ...item,
        payload: {
            conditons: transformToArray(item.payload),
            actionThen: createCondition(item.payload.actionThen),
            actionOtherwise: createCondition(item.payload.actionOtherwise)
        },
        uuid: uuidv4(),
        code: String.fromCharCode(65 + index),
        disabled: false,
    }));


/**
 * Выполняем преобразование элементов переменных
 * @param {*} variable
 * @param {*} countMap
 */


export const createCondition = (args) => {

    return {...args, ...transformPayload(args.type, args.payload),};
};

export const clearPayload = (payload) => {
    const newPayload = {};
    const keys = Object.keys(payload).filter(key => !key.startsWith('variable') && !key.startsWith('device'));
    keys.forEach(item => {
        Object.assign(newPayload, {[item]: payload[item]});
    });
    return newPayload;
};

export const transformPayload = (type, payload) => {

    if (payload.variable2Name) {
        return {
            type: {type, value: createVariable(payload.variable1Id, payload.variable1Name, payload.device1Id)},
            payload: {
                ...clearPayload(payload),
                comparisonVariable: {value: createVariable(payload.variable2Id, payload.variable2Name, payload.device2Id)}
            }
        };
    }
    if (payload.variableName) {
        return {
            type: {type, value: createVariable(payload.variableId, payload.variableName, payload.deviceId)},
            payload: clearPayload(payload)
        };
    }
    if (type) {
        return {type: {type}, payload: clearPayload(payload)};
    }
};

export const createVariable = (id, name, uuid, prefix = '') => {
    return {[`variable${prefix}Id`]: id, [`variable${prefix}Name`]: name, [`device${prefix}Id`]: uuid};

};


export const transformOperator = (operator) => {
    const arr = [];

    if (operator && operator.condition1) {
        arr.push(createCondition({group: operator.type, ...operator.condition1}));
    }
    if (operator && operator.condition2) {
        arr.push(createCondition({group: operator.condition1 ? 'EMPTY' : operator.type, ...operator.condition2}));
    }
    if (operator && operator.operator1) {
        arr.push(...transformOperator(operator.operator1));
    }

    return arr;
};


export const transformConditionsToTree = (arr) => {
    if (arr.length < 2) {
        return {condition1: conditionToTree(arr[0]), type: arr[0].group};
    }
    if (arr.length > 2) {
        return {
            type: arr[0].group,
            operator1: transformConditionsToTree(arr.slice(1, arr.length)),
            condition2: conditionToTree(arr[0])
        };

    } else {
        return {
            type: arr[0].group,
            condition1: conditionToTree(arr[0]),
            condition2: conditionToTree(arr[1])
        };
    }


};
/**
 * Преобразование дерева в массив
 * @param payload
 * @return {[]}
 */
export const transformToArray = (payload) => {
    const arr = transformOperator(payload.conditons);

    return arr;
};


export const conditionToTree = (args) => {

    const condition = {type: args.type.type,};


    if (condition.type === 'VARIABLE_VARIABLE') {
        const v1 = args.type.value;
        const v2 = args.payload.comparisonVariable;

        if (v1 && v2) {

            return ({
                ...condition,
                payload: {
                    comparisonOperator: args.payload.comparisonOperator,
                    ...createVariable(v1.variableId, v1.variableName, v1.deviceId, '1'),
                    ...createVariable(v2.value.variableId, v2.value.variableName, v2.value.deviceId, '2')
                }
            });
        }
    }

    if (condition.type === 'VARIABLE') {
        const v1 = args.type.value;
        if (v1) {
            return ({
                ...condition,
                payload: {
                    comparisonOperator: args.payload.comparisonOperator,
                    ...v1,
                    comparisonValue: args.payload.comparisonValue,
                }
            });
        }
    }

    if (condition.type === 'SET_VARIABLE') {
        const v1 = args.type.value;
        if (v1) {
            return ({
                ...condition,
                payload: {
                    ...v1,
                    setValue: args.payload.setValue,
                }
            });
        }
    }

    if (condition.type) {
        if (args.payload.comparisonValue && args.payload.comparisonOperator) {
            return ({
                ...condition, payload: {
                    comparisonOperator: args.payload.comparisonOperator,
                    comparisonValue: args.payload.comparisonValue,
                }
            });
        }

    }
    return null;

};

/**
 *
 * @param {Array} conditions
 * @return {*}
 */
export const checkConditionArray = (conditions) => {
    if (conditions.filter(x => x.group === 'EMPTY').length > 1) {
        let idx = -1;
        conditions.forEach((x, index) => {
            if (x.group === 'EMPTY') {
                idx = index;
            }
        });


        if (idx > -1) {
            return conditions.filter((x, index) => index !== idx);
        }

    }
    return conditions;

};

export const exportTree = (model) => {
    return {
        ...model,
        scriptStatementList: {
            statements: [...model.scriptStatementList.statements.length > 0 &&
            model.scriptStatementList.statements.map((item, index) => ({
                type: item.type,
                payload: {
                    conditons: transformConditionsToTree(checkConditionArray(item.payload.conditons)),
                    actionThen: conditionToTree(item.payload.actionThen),
                    actionOtherwise: conditionToTree(item.payload.actionOtherwise)
                },
            }))],
        }
    };
};


// export const transformConditon = (conditons) => {
//     const arr = Object.entries(conditons).filter(([k, v]) => k !== 'type').map(([k, v]) => ({
//         key: k, ...v,
//         prefix: conditons.type
//     }));
//
//     return arr;
// };

export const VariableContext = React.createContext({variables: [], passports: []});

const ScenarioEditor = ({scenarioModel, onCreate, onCancel}) => {

    const {t} = useTranslation();
    const [capacityTree, setCapacityTree] = React.useState([]);
    const [passports, setPassports] = React.useState([]);
    // eslint-disable-next-line no-unused-vars
    const [selectedCapacities, setSelectedCapacities] = React.useState([]);


    const checkPassport = (capacity) => {
        const {passportStorage, passportDelivery, passportConnect} = capacity;
        const arr = [];

        if (passportStorage !== undefined) {
            arr.push({
                passport: passportStorage,
                capacity: capacity,
                headers: capacityConstants.GET_STORAGE_PASSPORT(),
            });
        }
        if (passportDelivery !== undefined) {
            arr.push({
                passport: passportDelivery,
                capacity: capacity,
                headers: capacityConstants.GET_DELIVERY_PASSPORT(),
            });
        }
        if (passportConnect !== undefined) {
            arr.push({
                passport: passportConnect,
                capacity: capacity,
                headers: capacityConstants.GET_CONNECT_PASSPORT(),
            });
        }
        return arr;
    };


    const createDuplicate = (model, length) => {
        return {
            ...model,
            uuid: uuidv4(),
            code: String.fromCharCode(65 + length),
            disabled: model.disabled,
        };
    };


    const createStatement = (modelStatement) => {
        const model = {
            ...modelStatement,
            capacityIds: [...selectedCapacities.map(cap => cap.id)],

        };

        onCreate(model);
    };


    const reloadCapacityTree = () => {
        const mapper = scenarioModel.capacityIds
        && scenarioModel.capacityIds.length > 0
            ? scenarioModel.capacityIds.map(item => capacityService.getCapacity(item)) : [];
        if (mapper.length > 0)
            Promise.all(mapper).then(res => {

                if (res.length > 0) {
                    Promise.all(res.map(capacity =>
                            deviceService.getVariablesByCapacity(capacity.id)
                                .then(data => ({
                                    ...capacity,
                                    variables: data.map((item) => ({
                                        ...item,
                                        name: capacity.name + '__' + item.name,
                                    }))
                                }))
                        )
                    ).then(r => {
                        setSelectedCapacities(r);
                    });
                }

            });
        capacityService.getCapacityTree().then((response) => {
            setCapacityTree(response.list);
        });
    };

    React.useEffect(() => {
        reloadCapacityTree();
    }, []);

    const removeCapacity = (capacity) => {
        setSelectedCapacities([
            ...selectedCapacities.filter((item) => item.id !== capacity.id),
        ]);
    };
    /**
     * Загрузка паспортов
     * @param capacity
     */
    const loadPassport = ({capacity}) => {
        capacityService
            .getCapacity(capacity.id)
            .then((source) => {
                const passportList = checkPassport(source);
                setPassports([...passports, ...passportList.map(item => convertPassport(item.passport, item.capacity, item.headers)).reduce((a, b) => [...a, ...b])]);
            })
            .catch((err) => console.log(err));
    };
    const addStatement = (length) =>
        ({
            ...scenarioConstants.STATEMENT_SAMPLE,
            uuid: uuidv4(),
            code: String.fromCharCode(65 + length),
            disabled: false,
        });

    const renderCapacities = (capacities, onRemove) => {
        return (
            <React.Fragment>
                <ul className="capacity-list">
                    {capacities.map((capacity) => (
                        <li
                            key={capacity.id}
                            className="capacity-list__item"
                            onClick={() => onRemove(capacity)}
                        >
                            <span className="capacity-list__item__text">{capacity.name}</span>
                            <button className="btn svg-btn dark ms-3 ">
                                <ReactSVG src={svgTrash}/>
                            </button>
                        </li>
                    ))}
                </ul>
            </React.Fragment>
        );
    };

    const loadVariables = (capacity) => {
        if (
            selectedCapacities.length < 1 ||
            !selectedCapacities.find((item) => item.id === capacity.id)
        ) {
            loadPassport({capacity});
            deviceService.getVariablesByCapacity(capacity.id).then((variables) => {
                const newVariables = variables.map((item) => ({
                    ...item,
                    name: capacity.name + '__' + item.name,
                }));
                setSelectedCapacities([
                    ...selectedCapacities,
                    {...capacity, variables: newVariables},
                ]);
            });
        }
    };
    const selectedVariables = React.useMemo(() => selectedCapacities.length > 0
        ? selectedCapacities
            .map((cap) => cap.variables)
            .reduce((a, b) => [...a, ...b]).map((variable) => ({
                deviceId: variable.deviceGUID,
                variableName: variable.name,
                variableId: variable.id,
            }))
        : [], [selectedCapacities.length]);

    const renderSelectedCapacities = React.useMemo(() =>
        renderCapacities(selectedCapacities, removeCapacity), [selectedCapacities.length]);
    // eslint-disable-next-line react/prop-types
    const memorizedScenarioModel = React.useMemo(() => {
        return {...scenarioModel, scriptStatementList: {statements: transformStatements(scenarioModel)}};
    }, [scenarioModel]);


    return (
        <React.Fragment>
            <main className="scenario-editor">
                <Form
                    mutators={{
                        ...arrayMutators
                    }}
                    initialValues={memorizedScenarioModel}

                    onSubmit={(e) => {
                        const data = exportTree(e);
                        createStatement(data);
                    }}
                    render={({handleSubmit, values, submitting}) => (
                        <form onSubmit={handleSubmit}>
                            <VariableContext.Provider value={{variables: selectedVariables, passports}}>
                                <header className="scenario-editor__header">
                                    <LocalAccordion
                                        key={'header-1'}
                                        style={{padding: '1.25rem 0'}}
                                        scenarioStatement={{
                                            code: localeService.isRussian()
                                                ? 'Настройка сценария'
                                                : 'Scenario configuration',
                                        }}
                                        hasActions={false}
                                    >
                                        <div>
                                            <Field name="name">
                                                {({input}) => (<CustomInput
                                                    isRequired={true}
                                                    {...input}
                                                    isInteger={false}
                                                    label={t('scenario.scenario_name')}
                                                />)}
                                            </Field>
                                            <Field name="delaySeconds">
                                                {({input}) => (<CustomInput
                                                    min="1"
                                                    isRequired={true}
                                                    {...input}
                                                    isInteger={true}
                                                    label={t('scenario.delay')}
                                                />)}
                                            </Field>
                                            <div>
                                                <Multicast/>
                                            </div>
                                            <br/>
                                            <div>
                                                <label className="accordion__name">
                                                    {t('selected_capacity')}
                                                </label>
                                                {renderSelectedCapacities}
                                                <div
                                                    style={{
                                                        // color: '@default-text-color',
                                                        // padding: '6px 10px',
                                                        borderRadius: '5px',
                                                        // backgroundColor: '#ececec ',
                                                        border: '1px solid grey'
                                                    }}
                                                >
                                                    <SelectCapacityTree
                                                        capacityTree={capacityTree}
                                                        onSelect={(e) => loadVariables(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </LocalAccordion>
                                </header>
                                <div className="scenario-editor__statement">
                                    <FieldArray name="scriptStatementList.statements" key={'mainFieldArray'}>
                                        {({fields}) => {
                                            return <React.Fragment>
                                                {fields.map((name, index) => {
                                                    if (!fields.value[index]) {
                                                        return '';
                                                    }

                                                    console.log('index' + index, fields.value[index].payload);

                                                    return (<LocalAccordion
                                                        key={`Accordion${index}`}
                                                        scenarioStatement={fields.value[index]}
                                                        onCreateDuplicate={() =>
                                                            fields.insert(index + 1, createDuplicate(fields.value[index], fields.value.length))}
                                                        onRemove={() =>
                                                            fields.remove(index)}
                                                        onMoveUp={() => {
                                                            if (index - 1 > -1)
                                                                fields.swap(index - 1, index);

                                                        }}
                                                        onMoveDown={() => {
                                                            if (index + 1 < fields.length)
                                                                fields.swap(index, index + 1);
                                                        }}
                                                        onDisable={() => {
                                                            const newValue = {
                                                                ...fields.value[index],
                                                                disabled: !fields.value[index].disabled
                                                            };
                                                            fields.update(index, newValue);
                                                        }}
                                                        hasActions={true}
                                                    >
                                                        <ScenarioControl
                                                            name={name}
                                                            source={fields.value[index] ? fields.value[index].payload : undefined}
                                                        />
                                                    </LocalAccordion>);


                                                })}
                                                <button
                                                    className="ml1 mb1 btn eco-btn default "
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        fields.push(addStatement(fields.value ? fields.value.length : 0));
                                                    }}
                                                >
                                                    {t('scenario.add_condition')}
                                                </button>
                                            </React.Fragment>;
                                        }}
                                    </FieldArray>

                                </div>
                                <hr/>
                              {canDo('ROLE_CREATE_SCRIPT') && <div className={'d-flex justify-content-center'}>
                                        <button
                                            className={`ml1 btn eco-btn ${!submitting ? 'default' : 'danger'}`}
                                            type="submit"
                                            title={t('save')}
                                            disabled={submitting}
                                        >
                                            {t('save')}
                                        </button>
                                        <button
                                            className="ml1 btn eco-btn danger "
                                            onClick={(e) => {
                                                e.preventDefault();
                                                onCancel();
                                            }}
                                            title={t('cancel')}
                                        >
                                            {t('cancel')}
                                        </button>
                                </div>}
                            </VariableContext.Provider>
                        </form>)}
                >


                </Form>
            </main>
        </React.Fragment>
    );
};

ScenarioEditor.propTypes = {
    scenarioModel: PropTypes.object,
    variables: PropTypes.array,
    onCreate: PropTypes.func,
    onCancel: PropTypes.func,
};
export default ScenarioEditor;

