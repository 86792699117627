/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import { scenarioConstants } from '../../constants';
import { scenarioService } from '../../services/scenario.service';
import ScenarioEditor from './components/ScenarioEditor';
import { PropTypes } from 'prop-types';
import {localeService} from '../../services/locale.service';

const AddScenario = ({ onCancel, onCreate }) => {
  const [selectedScenary] = useState({ ...scenarioConstants.SCENARIO_SAMPLE });
  const saveData = (scenario) => {
    console.log(scenario);
    delete scenario['scriptId'];
    scenarioService
      .saveScenario(scenario)
      .then(() => {
        alert(localeService.isRussian() ? 'Сценарий успешно создан!' : 'Success');
        onCreate(true, undefined);
      })
      .catch((err) => {
        alert('Error! ' + err);
        onCreate(false, err);
      });
  };

  return (
    <div>
      <div className={'page-header pt-5 mb-5'}>
        <div className={'container d-flex justify-content-between'}>
          <h2>{localeService.isRussian() ? 'Конструктор сценариев' : 'Scenario Creator'}</h2>
        </div>
      </div>
      <div className={'container mb-5'}>
      {selectedScenary && (
        <ScenarioEditor
          onCancel={onCancel}
          scenarioModel={selectedScenary}
          onCreate={saveData}
        />
      )}
      </div>
    </div>
  );
};
AddScenario.propTypes = {
  onCancel: PropTypes.func,
  onCreate: PropTypes.func,
};
export default AddScenario;
