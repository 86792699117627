import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {localeService} from '../../services/locale.service';
import InputText from '../../components/InputText';

function SetImportConfiguration({onSetProps, fileName}) {

    const [importConfig, setImportConfig] = useState({datetimeFormat: 'dd.MM.yyyy HH:mm:ss', filename: fileName});
    return (
        <form>
            <h5 style={{margin: '1rem auto'}}>{fileName}</h5>
            {fileName.toLowerCase().endsWith('csv') &&
            <div>
                <p className={'help-text'}>{localeService.isRussian() ? 'Выберите разделитель между ячейками строки' : 'Check the cell delimiter'}</p>
                <div className='radio'>
                    <label>
                        <input type='radio' name='delimiter'
                               checked={importConfig.delimiter === ',' ? 'checked' : ''} onChange={() => {
                            setImportConfig({...importConfig, delimiter: ','});
                        }}/>
                        {localeService.isRussian() ? 'Запятая' : 'Comma'} (,)
                    </label>
                </div>
                <div className="radio">
                    <label>
                        <input type="radio" name="delimiter"
                               checked={importConfig.delimiter === '.' ? 'checked' : ''} onChange={() => {
                            setImportConfig({...importConfig, delimiter: '.'});
                        }}/>
                        {localeService.isRussian() ? 'Точка' : 'Point'} (.)
                    </label>
                </div>
                <div className="radio">
                    <label>
                        <input type="radio" name="delimiter"
                               checked={importConfig.delimiter === ';' ? 'checked' : ''} onChange={() => {
                            setImportConfig({...importConfig, delimiter: ';'});
                        }}/>
                        {localeService.isRussian() ? 'Точка с запятой' : 'Semicolon'} (;)
                    </label>
                </div>
            </div>

            }
            <p></p>
            {(fileName.toLowerCase().endsWith('csv') || fileName.toLowerCase().endsWith('xls') || fileName.endsWith('xlsx')) &&
            <InputText
                label={localeService.isRussian() ? 'Формат даты и времени' : 'Datetime format'}
                value={importConfig.datetimeFormat}
                onChange={(text) => {
                    setImportConfig({...importConfig, datetimeFormat: text});
                }}/>


            }
            <div className={'to-right'}>
                <button className={'btn eco-btn default'} onClick={() => onSetProps(importConfig)}>{localeService.isRussian() ? 'Загрузить' : 'Upload'}</button>
            </div>
        </form>
    );
}

SetImportConfiguration.propTypes = {
    onSetProps: PropTypes.func,
    fileName: PropTypes.string
}
;

export default SetImportConfiguration;