import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../components/Modal';
import {localeService} from '../../../../services/locale.service';
import moment from 'moment/moment';
import {ReactSVG} from 'react-svg';
import svgTable from '../../../../sources/images/interface/table_view.svg';

function ShowChartDataInTable({dataSources, functionDataSources}) {
    const [isOpened, setOpened] = useState(false);
    const [tableData, setTableData] = useState([]);

    const recombineData = () => {
        let resultData = [];

        //1. Заполняем временной ряд датами
        dataSources.map(ds => {
           ds.data.map(data => {
              if(resultData.findIndex(el => el.time_upload === data.time_upload) < 0){
                  resultData.push({time_upload: data.time_upload, data: Array.from(Array(dataSources.length + functionDataSources.length), () => '')});
              }
           });
        });
        functionDataSources.map(ds => {
            ds.data.map(data => {
                if(resultData.findIndex(el => el.time_upload === data.time_upload) < 0){
                    resultData.push({time_upload: data.time_upload, data: Array.from(Array(dataSources.length + functionDataSources.length), () => '')});
                }
            });
        });

        //2. Заполняем временной ряд данными
        dataSources.map((ds, i) => {
            ds.data.map(value => {
                const resultArrayIndex = resultData.findIndex(el => el.time_upload === value.time_upload);
                resultData[resultArrayIndex].data[i] = value.value;
            });
        });
        functionDataSources.map((fds,j) => {
            fds.data.map(value => {
                const resultArrayIndex = resultData.findIndex(el => el.time_upload === value.time_upload);
                resultData[resultArrayIndex].data[dataSources.length + j] = value.value;
            });
        });
        setTableData(resultData);
    };

    return (<>
        <button className={'btn svg-btn'}
                title={localeService.isRussian() ? 'Показать данные в таблице' : 'Show Datasources in Table'}
                onClick={() => {
                    recombineData();
                    setOpened(true);
                }}>
            <ReactSVG src={svgTable}/>
        </button>
        <Modal onCancel={() => setOpened(false)} footer={false} isClosable={true} height={'50vh'} width={'90vw'} overflow={'auto'}
               isOpen={isOpened}
               title={localeService.isRussian() ? 'Данные источников' : 'Data Sources'}>
            {dataSources.length > 0 &&
            <table className={'table'}>
                <thead>
                <tr>
                    <th>{localeService.isRussian() ? 'Дата \\ Источник данных' : 'Datetime \\ Datasources'}</th>
                    {dataSources.map((ds, i) => {
                        return <th key={'ds_th' + i}>{ds.parameters.dataSourceName}</th>;
                    })}
                    {functionDataSources.map((fds, i) => {
                        return <th key={'fds_th' + i}>{fds.parameters.dataSourceName}</th>;
                    })}
                </tr>
                </thead>
                <tbody>
                {tableData.length > 0 && tableData.sort((a,b) => a.time_upload - b.time_upload).map((row, rowIdx) => {
                    return <tr key={'row' + rowIdx}>
                            <td>{moment.unix(row.time_upload).format('DD/MM/YYYY HH:mm:ss')}</td>
                        {row.data.map((value, i) =>{
                                return <td key={i}>{value}</td>;
                            })}
                    </tr>;
                })}
                </tbody>
            </table>
            }
        </Modal>
    </>);
}

ShowChartDataInTable.propTypes = {
    dataSources: PropTypes.array,
    functionDataSources: PropTypes.array
};


export default ShowChartDataInTable;