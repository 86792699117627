import React, {useState} from 'react';
// import {localeService} from '../services/locale.service';
import {useTranslation} from 'react-i18next';
import {loginService} from '../services/login.service';
import {Link, useHistory, useLocation} from 'react-router-dom';
import logoSvg from '../sources/images/eco_logo.svg';
import svgShow from '../sources/images/interface/show.svg';
import svgHide from '../sources/images/interface/hide.svg';
import {ReactSVG} from 'react-svg';
import {apiConstants} from '../constants';
import {dashboardService} from '../services';
import '../styles/login.scss';
import {localeService} from '../services/locale.service';

function LoginPage() {

    let history = useHistory();
    let location = useLocation();
    const {t} = useTranslation();
    const [submitted, setSubmitted] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isFetching, setFetching] = useState(false);
    const [errorMessage, setError] = useState('');

    React.useEffect(() => {
        loginService.logout();
    }, []);

    function handleSubmit() {
        setSubmitted(true);
        setError('');
        if (username && password) {
            setFetching(true);
            loginService.authorizeUser(username, password).then(
                async response => {
                    if (response) {
                        apiConstants.SESSION_UPDATER_INTERVAL = setInterval(() => loginService.authorizeUser(username, password), 1000 * 60 * 5);
                        let defaultDashboard = localStorage.getItem('ems_default_dashboard_id');
                        if (defaultDashboard === undefined || !parseInt(defaultDashboard)) {
                            await dashboardService.getDashboardList().then(
                                dbList => {
                                    if (dbList.length > 0) {
                                        defaultDashboard = dbList[0].id;
                                    }
                                }
                            );
                        }
                        let {from} = location.state !== undefined ? location.state : {from: {pathname: defaultDashboard ? ('/dashboard/' + defaultDashboard) : '/'}};
                        history.replace(from);
                    } else {
                        setSubmitted(false);
                        setFetching(false);
                    }
                }, error => {
                    setError(error.toString());
                    setSubmitted(false);
                    setFetching(false);
                }
            );
        }
    }

    return (<div className={'login-page'}>
            <div className="login-container">
                <div className={'header'}>
                </div>
                <div className={'body'}>
                    <div className={'login-content'}>
                        <div className={'logo-header'}>
                          <div className={'logo'}>
                            <ReactSVG src={logoSvg}/>
                            <label>DIGITAL<br/>MONITORING</label>
                          </div>
                            {/*<select className="form-select" aria-label="Default select example">*/}
                                {/*<option selected={localStorage.getItem('ems-language') === 'ru'} value={'ru'}>Рус</option>*/}
                                {/*<option selected={localStorage.getItem('ems-language') !== 'ru'} value={'en'}>Eng</option>*/}
                            {/*</select>*/}
                        </div>
                        <form name="form" onSubmit={handleSubmit.bind(this)}
                              className={'mt-5 needs-validation ' + (submitted ? 'was-validated' : '')}>
                            <div className="mb-3">
                                <label className="form-label">Email</label>
                                <input type="text" className="form-control" name="username" value={username}
                                       onChange={el => setUsername(el.target.value)} required/>
                                <div className="invalid-feedback">
                                    {t('login_is_required')}
                                </div>
                            </div>
                            <div className={'mb-3'}>
                                <label htmlFor="password">{t('password')}</label>
                                <div className="input-group mb-3 has-validation">
                                    <div className={'d-flex justify-content-between'} style={{width: '100%'}}>
                                    <input type={!showPassword ? 'password' : 'text'} className="form-control"
                                           name="password"
                                           value={password} onChange={el => setPassword(el.target.value)} required/>
                                        <button className={'btn eco-btn default'} onClick={(e) => {
                                            e.preventDefault();
                                            setShowPassword(!showPassword);
                                        }}>
                                            <ReactSVG src={showPassword ? svgShow : svgHide}/>
                                        </button>
                                    {/*<span className="input-group-text" id="basic-addon2" style={{cursor: 'pointer'}}*/}
                                          {/*onClick={() => setShowPassword(!showPassword)}>*/}
                                    {/*<img style={{width: 20, height: 20}} src={showPassword ? svgShow : svgHide}*/}
                                         {/*alt={'Show symbols'}/>*/}
                                {/*</span>*/}
                                    </div>
                                    <div className="invalid-feedback">{t('password_is_required')}</div>
                                </div>
                            </div>
                            <div className="mt-4 d-flex justify-content-between align-items-center">
                                <button type="button" className={'btn eco-btn success'}
                                        onClick={handleSubmit.bind(this)}>
                                    {isFetching ? <small>{t('wait')}</small> : t('sign_in')}
                                </button>
                                <Link to={'/register_organization'} className="card-link">
                                    <span className={'link'}>{localeService.isRussian() ? 'Зарегистрироваться' : 'Registration'}</span>
                                </Link>
                            </div>
                            {errorMessage &&
                            <div className="mt-4 alert alert-danger">{errorMessage}</div>
                            }
                        </form>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default LoginPage;

