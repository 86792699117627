import {apiConstants} from '../constants';
import {apiService} from './api.service';
import {loginService} from './login.service';


export function getCapacityTree() {
  return apiService.fetchGet(apiConstants.GET_CAPACITY_TREE);
}

export function createCapacity(capacity) {

  const user = loginService.getCurrentUser();
  const formData = new FormData();
  if (capacity.id) {
    formData.append('capacityId', capacity.id);
  }
  formData.append('group', capacity.group);
  formData.append('name', capacity.name);
  formData.append('organizationId', 1);
  if (capacity.ownerCapacityId) {
    formData.append('ownerCapacityId', capacity.ownerCapacityId);
  }
  if (capacity.imgView) {
    formData.append('imgView', capacity.imgView, capacity.imgView.name);
  }
  const config = {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + user.sessionResp.authenticationToken,
      'user-agent-token': user.userAgentToken
      // 'Content-type': 'multipart/form-data'
    },
    body: formData
  };

  const url = apiService.addProps(apiConstants.CREATE_LOAD);
  return fetch(url, config)
    .then(response => {
      const jResponse = response.json();
      if (!response.ok) {
        return jResponse.then(err => Promise.reject(err.message));
      }
      return jResponse;
    });
}


export function deleteLoad(id) {
  return apiService.fetchPost(apiConstants.DELETE_LOAD, null, {id: id});
}

export function getCapacity(id) {
  return apiService.fetchGet(apiConstants.GET_CAPACITY, {id: id});
}

export function getCapacityChildren(id) {
  return apiService.fetchGet(apiConstants.GET_CAPACITY_SUB_LIST, {id});
}

export function getCapacityByVariableId(variableId) {
  return apiService.fetchGet(apiConstants.GET_CAPACITY_BY_VARIABLE_ID, {variable_id: variableId});
}

export const capacityService = {
  getCapacity,
  getCapacityTree,
  getCapacityChildren,
  createCapacity,
  deleteLoad,
  getCapacityByVariableId
};

