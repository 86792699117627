/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {capacityService} from '../../services/capacity.service';
import InputText from '../../components/InputText';
import {deviceService} from '../../services';
import Loader from '../../components/Loader';
import {useHistory} from 'react-router-dom';
import CheckBox from '../../components/CheckBox';
import SelectCapacityTree from '../../components/SelectCapacityTree';

function EditDeviceVariable({editableVariable, device, onCancel}) {
    const [variable, setVariable] = useState({...editableVariable, timeout: editableVariable.periodUpdate});
    const [capacityTree, setCapacityTree] = useState([]);
    const [isFetching, setFetching] = useState(false);
    const [result, setResult] = useState(null);
    const {t} = useTranslation();

    const saveVariable = () => {
        if (variable.protocol.name === 'VIRTUAL') {
            setFetching(true);
            deviceService.editVirtualVariable(variable)
                .then(
                    response => {
                        console.log(response);
                        setResult({
                            isOk: response.status === 'SUCCESS',
                            message: response.status === 'SUCCESS' ? 'Успешно!' : 'Ошибка!'
                        });
                        setFetching(false);
                    }
                );
        }
        if (variable.protocol.name === 'MQTT') {
            setFetching(true);
            const editableDevice = {
                id: device.id,
                name: device.name,
                relations: device.variabels.filter(v => (v.variableId !== variable.variableId && v.isActive)).map(el => {
                    return {
                        active: el.isActive,
                        capacityID: el.capacity.id,
                        timeout: el.periodUpdate,
                        variableId: el.variableId
                    };
                })
            };

            if(variable.isActive) {
                editableDevice.relations.push(
                    {
                        active: variable.isActive,
                        capacityID: variable.capacity.id,
                        timeout: parseInt(variable.timeout),
                        variableId: variable.variableId
                    });
            }

            console.log('editableDevice', editableDevice);

            deviceService.editDevice(editableDevice).then(
                response => {
                    console.log(response);
                    setResult({
                        isOk: response.status === 'SUCCESS',
                        message: response.status === 'SUCCESS' ? 'Успешно!' : 'Ошибка!'
                    });
                }
            ).finally(() => {
                setFetching(false);
            });
        }
    };

    const deleteVariable = () => {
        if (variable.protocol.name === 'VIRTUAL') {
            setFetching(true);
            deviceService.deleteVirtualVariable(variable.deviceId, variable.id)
                .then(
                    response => {
                        setResult({
                            isOk: response.status === 'SUCCESS',
                            message: response.status === 'SUCCESS' ? 'Успешно!' : 'Ошибка!'
                        });
                        setFetching(false);
                    }
                );
        }
    };

    useEffect(() => {
        capacityService.getCapacityTree()
            .then((response) => {
                setCapacityTree(response.list);
            });
    }, []);

    console.log('variable', variable);

    return (
        <>
            {!isFetching && !result &&
            <>
                <form>
                    <InputText label={t('name')} value={variable.name}
                               onChange={text => setVariable({...variable, name: text})}/>
                    {device.deviceProtocol.name === 'MQTT' &&
                    <>
                        <CheckBox label={t('is_active')} checked={variable.isActive}
                                  onChange={(checked) => setVariable({...variable, isActive: checked})}/>
                        <InputText label={t('timeout')} value={variable.timeout / 1000} isDecimal={true}
                                   onChange={text => setVariable({...variable, timeout: text * 1000})}/>
                        <div className={'input-group understroke required'}>
                            <div className={'input-group-prepend'}>
                                <label>{t('capacity') + ':'}</label>
                            </div>
                            {capacityTree.length > 0 &&
                            <SelectCapacityTree label={t('capacity')} capacityTree={capacityTree}
                                                capacity={variable.capacity}
                                                onSelect={(capacity) => setVariable({
                                                    ...variable,
                                                    capacity: capacity
                                                })}/>}
                        </div>
                    </>
                    }

                </form>
                <div className={'d-flex justify-content-center'}>
                    <button type={'button'} className={'btn eco-btn success ml2'}
                            onClick={() => saveVariable()}>{t('save')}</button>

                    {variable.protocol.name === 'VIRTUAL' &&
                    <button type={'button'} className={'btn eco-btn danger ml2'}
                            onClick={() => deleteVariable()}>{t('delete')}</button>}
                    <button type={'button'} className={'btn eco-btn outlined default ml2'}
                            onClick={() => onCancel()}>{t('cancel')}</button>
                </div>
            </>
            }
            {isFetching && <Loader/>}

            {!isFetching && result &&
            <div className={'d-flex justify-content-center'}>
                <h3>{result.message}</h3>
                <button type={'button'} className={'btn eco-btn outlined default ml2'}
                        onClick={() => {
                            onCancel(result.isOk);
                        }}>{t('ok')}</button>
            </div>
            }

        </>
    );
}

EditDeviceVariable.propTypes = {
    editableVariable: PropTypes.object,
    device: PropTypes.object,
    onCancel: PropTypes.func,
    variableList: PropTypes.array
};

export default EditDeviceVariable;