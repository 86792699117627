import React from 'react';
import {organizationService} from '../../services/organization.service';
import {useTranslation} from 'react-i18next';
import {localeService} from '../../services/locale.service';
import EditOrganizationUser from './EditOrganizationUser';
import Loader from '../../components/Loader';
import Modal from '../../components/Modal';
import AddNewUserToOrganization from './AddNewUserToOrganization';
import {canDo} from '../../services/permitions.service';

function UsersManagement() {
    const {t} = useTranslation();
    const [userList, setUserList] = React.useState([]);
    const [roleList, setRoleList] = React.useState([]);
    const [isFetching, setFetching] = React.useState(false);
    const [isAddOpen, setAddOpen] = React.useState(false);

    React.useEffect(() => {
        organizationService.getOrganizationRoleList().then(
            roleList => setRoleList(roleList)
        );
        reloadUserList();
    }, []);

    const reloadUserList = () => {
        setFetching(true);
        organizationService.getOrganizationUserList().then(
            resp => {
                setUserList(resp.list);
            }
        ).finally(() => setFetching(false));
    };

    return <>
        <div className={'d-flex justify-content-between mb-5'}>
            <h2>{localeService.isRussian() ? 'Список пользователей организации' : 'Organization User List'}</h2>
          {canDo('ROLE_ADD_NEW_MEMBER') &&
          <button type={'button'}
                            onClick={() => setAddOpen(true)}
                            className={'btn eco-btn success'}>
                        {localeService.isRussian() ? 'Добавить пользователя' : 'Add User'}
                    </button>}
        </div>
        {userList && <table className={'mt-5 device-list-table'} cellPadding={0} cellSpacing={0}>
            <thead>
            <tr>
                <th>N</th>
                <th>{t('email')}</th>
                <th>{t('first_name')}</th>
                <th>{t('middle_name')}</th>
                <th>{t('last_name')}</th>
                <th width="30%">{localeService.isRussian() ? 'Роль' : 'Role'}</th>
                <th>{localeService.isRussian() ? 'Активирован' : 'Enabled'}</th>
                <th/>
            </tr>
            </thead>
            <tbody>
            {isFetching && <tr><td colSpan={8}><Loader/></td></tr>}
            {!isFetching && userList.map((user, n) => {
                return <tr key={'tr-' + n}>
                    <td>{n + 1}</td>
                    <td>{user.email}</td>
                    <td>{user.first_name}</td>
                    <td>{user.middle_name}</td>
                    <td>{user.last_name}</td>
                    <td>{user.posts[0].name}</td>
                    <td>{t(user.enabled + '')}</td>
                    <td>
                        {roleList && canDo() && <EditOrganizationUser editableUser={user}
                                              roleList={roleList}
                                              onSubmit={() => reloadUserList()}/>}
                    </td>
                </tr>;
            })}
            </tbody>
        </table>
        }

        <Modal isOpen={isAddOpen} footer={false} isClosable={false}
               title={localeService.isRussian() ? 'Добавить нового пользователя в организацию' : 'Add User to Organization'}
               onCancel={() => setAddOpen(false)}>
            <AddNewUserToOrganization roleList={roleList} onSubmit={(result) => {
                setAddOpen(false);
                if(result){
                    reloadUserList();
                }
            }}/>
        </Modal>

    </>;
}

export default UsersManagement;