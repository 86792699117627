/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {scenarioService} from '../../services/scenario.service';
import ScenarioEditor from './components/ScenarioEditor';

const EditScenario = ({scenario, onCreate, onCancel}) => {
    const [selectedScenary, setSelectedScenary] = useState(undefined);

    useEffect(() => {
        setSelectedScenary(scenario);
    }, [scenario]);
    const saveData = (scenario) => {
        console.log('edit_scenario');
        console.log({scriptId: selectedScenary.id, ...scenario});

        scenarioService
            .saveScenario({scriptId: selectedScenary.id, ...scenario})
            .then((res) => {
                onCreate(true, undefined);
                console.info(res);
            })
            .catch((err) => onCreate(false, err));
    };


    return (
        <div>
            {selectedScenary && (
                <ScenarioEditor
                    onCancel={onCancel}
                    scenarioModel={selectedScenary}
                    onCreate={saveData}
                />
            )}
        </div>
    );
};
EditScenario.propTypes = {
    scenario: PropTypes.object,
    onCancel: PropTypes.func,
    onCreate: PropTypes.func,
};
export default EditScenario;
