import React from 'react';
import PropTypes from 'prop-types';
import {localeService} from '../services/locale.service';
import EditFunctionArg from './EditFunctionArg';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import {ReactSVG} from 'react-svg';
import svgDelete from '../sources/images/interface/basket.svg';
import {useTranslation} from 'react-i18next';
import {dashboardService} from '../services';
import Loader from './Loader';


function AddAnalyticParametersToGroupChart({analyticFunction, onChangeParam, datetimeFilter}) {
    const {t} = useTranslation();
    const [isFetchingAdditionalProps, setFetchingAdditionalProps] = React.useState(false);
    React.useEffect(() => {
        const targetDay = analyticFunction.args.filter(el => el.name === 'target_day');
        if (targetDay.length === 1) {
            reloadSpecialArgs(targetDay[0].values[0]);
        }
    }, []);

    React.useEffect(() => {
    }, [analyticFunction]);

    const onChange = (func) => {
        onChangeParam(func);
    };

    const reloadSpecialArgs = (targetDay) => {
        setFetchingAdditionalProps(true);
        const start = moment(targetDay).set('hours', 0).set('minute', 0).set('second', 0);
        const finish = moment(targetDay).set('hours', 23).set('minute', 59).set('second', 59);
        Promise.all(analyticFunction.dataSourcesArgs.map(dsParams => {
            return Promise.all(dsParams.args.map(async (dsArg) => {
                if (dsArg.name === 'discharge_start_hour') {
                    const startHourDS = dsParams.additionalDataSources.filter(el => el.parameters.type === 'START_HOUR_DATASOURCE');
                    if (startHourDS.length === 1) {
                        const newValues = await getSpecialData(startHourDS[0].id, start, finish);
                        return {...dsArg, values: newValues};
                    } else {
                        return {...dsArg, values: [undefined]};
                    }
                } else if (dsArg.name === 'discharge_duration') {
                    const startHourDS = dsParams.additionalDataSources.filter(el => el.parameters.type === 'DURATION_DATASOURCE');
                    if (startHourDS.length === 1) {
                        const newValues = await getSpecialData(startHourDS[0].id, start, finish);
                        return {...dsArg, values: newValues};
                    } else {
                        return {...dsArg, values: [undefined]};
                    }
                } else if (dsArg.name === 'discharge_value') {
                    const startHourDS = dsParams.additionalDataSources.filter(el => el.parameters.type === 'VALUE_DATASOURCE');
                    if (startHourDS.length === 1) {
                        const newValues = await getSpecialData(startHourDS[0].id, start, finish);
                        return {...dsArg, values: newValues};
                    } else {
                        return {...dsArg, values: [undefined]};
                    }

                } else {
                    return dsArg;
                }
            })).then(newArgs => {
                return {...dsParams, args: newArgs};
            });
        })).then(dataSourcesArgs => {
            onChange({...analyticFunction, dataSourcesArgs: dataSourcesArgs});
        }).finally(() => setFetchingAdditionalProps(false));
    };

    const getSpecialData = async (dsId, start, finish) => {
        return dashboardService.getDataSourceDataWithTimeGrouped(dsId, start, finish, '24h').then(response => {
            if (response.list.length > 0) {
                return [parseInt(response.list[0].value)];
            } else {
                return [undefined];
            }
        });
    };

    return <div>
        {analyticFunction.args.map((arg, i) => {
            if (['discharge_start_hour', 'discharge_duration', 'discharge_value'].findIndex(el => el === arg.name) < 0) {
                return <EditFunctionArg key={i} arg={arg} onChange={arg => {
                    if (arg.name === 'target_day') {
                        let date = moment(arg.values[0]);
                        let paramFunction = analyticFunction;
                        delete paramFunction.startDate;
                        delete paramFunction.finishDate;
                        if (date.isBefore(datetimeFilter.startDate)) {
                            paramFunction.startDate = date.set('hours', 0).set('minute', 0).set('second', 0);
                        }
                        if (date.isAfter(datetimeFilter.finishDate.set('hours', 0).set('minute', 0).set('second', 0))) {
                            paramFunction.finishDate = date.set('hours', 23).set('minute', 59).set('second', 59);
                        }
                        paramFunction.args[i].values[0] = date.format('YYYY-MM-DD');
                        onChange(paramFunction);
                        reloadSpecialArgs(date);
                    } else {
                        let args = analyticFunction.args;
                        args[i] = arg;
                        onChange({...analyticFunction, args: args});
                    }
                }}/>;
            }
        })}
        {isFetchingAdditionalProps && <Loader/>}
        {!isFetchingAdditionalProps &&
        analyticFunction.dataSourcesArgs.map(dataSourceArg => {
            return (
                <div className={'group'} key={dataSourceArg.dsId}>
                    <label
                        className={'group-header'}>{localeService.isRussian() ? 'Источник данных ' : 'Data Source '}{dataSourceArg.dsName}</label>
                    {dataSourceArg.args.map((param, i) => {
                        if (param.count < 0) {
                            return (<>
                                <div key={'add-remove-param-' + i} className={'row'} style={{marginTop: '-2rem'}}>
                                    <button type="button" className="btn eco-btn default"
                                            onClick={() => {
                                                let dsArgs = analyticFunction.dataSourcesArgs.map(el => {
                                                    if (el.dsId === dataSourceArg.dsId) {
                                                        let newArg = {
                                                            ...param,
                                                            values: [...param.values, moment(datetimeFilter.finishDate).format('YYYY-MM-DD')]
                                                        };
                                                        return {
                                                            ...el, args: el.args.map(arg => {
                                                                    if (arg.name === param.name) {
                                                                        return newArg;
                                                                    }
                                                                    else {
                                                                        return arg;
                                                                    }
                                                                }
                                                            )
                                                        };
                                                    } else {
                                                        return el;
                                                    }
                                                });
                                                onChange({...analyticFunction, dataSourcesArgs: dsArgs});
                                            }}>
                                        {t('add') + ' ' + t('analytic_parameter.' + param.name)}

                                    </button>
                                </div>
                                {param && param.values.map((val, j) => {
                                    // let selectedDate = new Date((param.args && param.args[i] && param.args[i].values[j]) ? moment(param.args[i].values[j]) : moment(datetimeFilter.startDate));
                                    return (
                                        <div key={'removable-date-' + i + j} className={'input-group understroke'}
                                             style={{justifyContent: 'space-between'}}>
                                            <div className={'input-group-prepend'}>
                                                <label>{t('analytic_parameter.' + param.name) + (j + 1)}</label>
                                                <DatePicker
                                                    // dateFormat="DD-MMM-YYYY"
                                                    key={'removable-date-' + j + i}
                                                    showPopperArrow={false}
                                                    selected={new Date(param.values[j])}
                                                    onChange={date => {
                                                        let dsArgs = analyticFunction.dataSourcesArgs.map(el => {
                                                            if (el.dsId === dataSourceArg.dsId) {
                                                                return {
                                                                    ...el, args: el.args.map(arg => {
                                                                        if (arg.name === param.name) {
                                                                            let newValues = arg.values;
                                                                            newValues[j] = moment(date).format('YYYY-MM-DD');
                                                                            return {...arg, values: newValues};
                                                                        } else {
                                                                            return arg;
                                                                        }
                                                                    })
                                                                };
                                                            } else {
                                                                return el;
                                                            }
                                                        });
                                                        onChange({...analyticFunction, dataSourcesArgs: dsArgs});
                                                    }}
                                                />
                                            </div>
                                            <div className={'input-group-append'}>
                                                <button className={'btn eco-btn danger round-btn'}
                                                        title={localeService.isRussian() ? 'Удалить параметр' : 'Remove parameter'}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            let dsArgs = analyticFunction.dataSourcesArgs.map(el => {
                                                                if (el.dsId === dataSourceArg.dsId) {
                                                                    return {
                                                                        ...el, args: el.args.map(arg => {
                                                                                if (arg.name === param.name) {
                                                                                    let newValues = arg.values;
                                                                                    newValues.splice(j, 1);
                                                                                    return {...arg, values: newValues};
                                                                                }
                                                                                else {
                                                                                    return arg;
                                                                                }
                                                                            }
                                                                        )
                                                                    };
                                                                } else {
                                                                    return el;
                                                                }
                                                            });
                                                            onChange({...analyticFunction, dataSourcesArgs: dsArgs});
                                                        }}>
                                                    <ReactSVG src={svgDelete}/>
                                                </button>
                                            </div>
                                        </div>

                                    );
                                })

                                }

                            </>);
                        } else {
                            return <EditFunctionArg key={i} arg={param}
                                                    onChange={newArg => {
                                                        let dsArgs = analyticFunction.dataSourcesArgs.map(el => {
                                                            if (el.dsId === dataSourceArg.dsId) {
                                                                return {
                                                                    ...el, args: el.args.map(arg => {
                                                                        if (arg.name === param.name) {
                                                                            return newArg;
                                                                        } else {
                                                                            return arg;
                                                                        }
                                                                    })
                                                                };
                                                            } else {
                                                                return el;
                                                            }
                                                        });
                                                        onChange({...analyticFunction, dataSourcesArgs: dsArgs});
                                                    }}/>;
                        }
                    })}
                </div>
            );
        })}

    </div>;
}

AddAnalyticParametersToGroupChart.propTypes = {
    analyticFunction: PropTypes.object,
    dataSources: PropTypes.array,
    datetimeFilter: PropTypes.object,
    onChangeParam: PropTypes.func
};

export default AddAnalyticParametersToGroupChart;