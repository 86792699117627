import i18next from 'i18next';
import {apiConstants} from '../constants';
import {loginService} from './login.service';
import moment from 'moment';

function getTimeZoneOffset(){
    return moment().utcOffset() / 60;
}

function getAccessToken() {
    const accessToken = sessionStorage.getItem('access_token');
    return accessToken;
}

function getAuthorizationHeader() {
    const accessToken = getAccessToken();
    if (accessToken) {
        return 'Bearer ' + accessToken;
    }
    return '';
}

// function getOrganizationID() {
//     const organizationID = sessionStorage.getItem('organizationID');
//     return organizationID;
// }

function addProps(url) {
    const organizationID = JSON.parse(sessionStorage.getItem('eco_user')).organizationID;
    const newURL = url + '?' + 'organizationId=' + organizationID + '&lang=' + i18next.language;
    return newURL;
}

function saveTokens(access, userAgentToken) {
    sessionStorage.setItem('access_token', access.authenticationToken);
    sessionStorage.setItem('ua_token', userAgentToken);
}

function removeTokens() {
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('ua_token');
}

function fetchURL(url, config) {
    return fetch(url, config)
        .then(response => {
            if (response.status === 401) {
                removeTokens();
                window.location = '/login';
            }
            const jResponse = response.json();
            if (!response.ok) {
                return jResponse.then(err => {
                    if (err.errorCode === 'TOKEN_VALIDATION_ERROR') {
                        removeTokens();
                        window.location = '/login';
                    }
                    return jResponse; // Promise.reject((err.errorCode || '') + (err.errorMsg || '') + '');
                });
            }
            return jResponse;
        })
        .then(jResponse => {
            if (jResponse.status !== 'SUCCESS') {
                //throw new Error((jResponse.errorCode || '') + (jResponse.errorMsg || '') + '');
                // return Promise.reject((jResponse.errorCode || '') + (jResponse.errorMsg || '') + '');
              return Promise.reject(jResponse);
            }
            return jResponse;
        });
}

function fetchPost(url, body, urlParams) {
    const user = loginService.getCurrentUser();
    const config = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + user.sessionResp.authenticationToken,
            'user-agent-token': user.userAgentToken,
            'Content-Type': 'application/json'
        }
    };
    if (body) {
        config.body = body;
    }
    let requestURL = url;
    requestURL += '?' + 'organizationId=' + user.organizationID + '&lang=' + i18next.language;
    if (urlParams) {
        Object.keys(urlParams).forEach(function (key) {
            requestURL += '&' + key + '=' + urlParams[key];
        });
    }

    return apiService.fetchURL(requestURL, config);
}

function fetchGet(url, urlParams) {
    const user = loginService.getCurrentUser();
    const config = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + user.sessionResp.authenticationToken,
            'Content-Type': 'application/json',
            'user-agent-token': user.userAgentToken
        }
    };
    let requestURL = url;
    requestURL += '?' + 'organizationId=' + user.organizationID + '&lang=' + i18next.language;
    if (urlParams) {
        Object.keys(urlParams).forEach(function (key) {
            requestURL += '&' + key + '=' + urlParams[key];
        });
    }
    return fetchURL(requestURL, config);
    // .then(response => {
    //     if (response.status === 401) {
    //         removeTokens();
    //         window.location = '/login';
    //     }
    //     const jResponse = response.json();
    //     if (!response.ok) {
    //         return jResponse.then(err => {
    //             if(err.errorCode === 'TOKEN_VALIDATION_ERROR'){
    //                 removeTokens();
    //                 window.location = '/login';
    //             }
    //             return Promise.reject(err.message);
    //         });
    //     }
    //     return jResponse;
    // })
    // .then(jResponse => {
    //     if (jResponse.status !== 'SUCCESS') {
    //         return Promise.reject(jResponse.errorMsg);
    //     }
    //     return jResponse;
    // });
}

function getCurrentServerTime() {
    return fetchGet(apiConstants.CURRENT_TIME)
        .then((resp) => {
            const time = resp.currentTime.replace(/["]/g, '');
            return time;
        });
}

export const apiService = {
    getAccessToken,
    saveTokens,
    removeTokens,
    getAuthorizationHeader,
    addProps,
    fetchURL,
    fetchPost,
    fetchGet,
    getCurrentServerTime,
    getTimeZoneOffset
};
