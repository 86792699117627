import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {localeService} from '../../../services/locale.service';
import Popover from '../../../components/Popover';
import moment from 'moment/moment';
import {dashboardService} from '../../../services/dashboard.service';
import WidgetLoader from '../../../components/WidgetLoader';

function SwitcherWidgetView({widgetProps}) {
    const [isFetching, setFetching] = useState(false);
    const [warning, setWarning] = useState('');
    const [btnState, setBtnState] = useState('undefined');

    const btnOnValue = widgetProps.parameters.btnOnValue ? Number.parseInt(widgetProps.parameters.btnOnValue.toString()) : undefined;
    const btnOffValue = widgetProps.parameters.btnOffValue ? Number.parseInt(widgetProps.parameters.btnOffValue.toString()) : undefined;

    const setValue = () => {
        setFetching(true);
        setWarning('');
        const dateSet = moment.utc(new Date()).add(widgetProps.parameters.timeOffset, 'hours').format('YYYY-MM-DD HH:mm:ss');
        const value = btnState !== 'undefined' ? (btnState === 'off' ? btnOnValue : btnOffValue) : btnOnValue;
        {widgetProps.dataSources && widgetProps.dataSources.length > 0 &&
            dashboardService.widgetDataSet(widgetProps.dataSources[0].id, dateSet, value).then(
                () => {
                    if (value === btnOnValue) {
                        setBtnState('on');
                    } else if (value === btnOffValue) {
                        setBtnState('off');
                    } else {
                        setWarning(localeService.isRussian() ? 'Не удалось определить текущее состояние!' : 'Cannot define current button state!');
                    }
                }, () => {
                    setWarning(localeService.isRussian() ? 'Не удалось получить текущее состояние!' : 'Cannot get current button state!');
                }
            ).finally(() => setFetching(false));
        }
    };

    React.useEffect(() => {
        setFetching(true);
        {
            widgetProps.dataSources && widgetProps.dataSources.length > 0 &&
            dashboardService.getWidgetFunctionData(widgetProps.dataSources[0].id, moment(new Date('2000-01-01')), moment(new Date()), 'SELECTORS_LAST', [], 0)
                .then(
                    result => {
                        if (result.list.length > 0) {
                            const value = result.list[0].value;
                            if (value === btnOnValue) {
                                setBtnState('on');
                            } else if (value === btnOffValue) {
                                setBtnState('off');
                            } else {
                                setBtnState('undefined');
                                setWarning(localeService.isRussian() ? 'Не удалось получить текущее состояние!' : 'Cannot get current button state!');
                            }
                        } else {
                            setWarning(localeService.isRussian() ? 'Не удалось получить текущее состояние!' : 'Cannot get current button state!');
                        }
                    }, () => {
                        setWarning(localeService.isRussian() ? 'Не удалось получить текущее состояние!' : 'Cannot get current button state!');
                    }
                ).finally(
                () => setFetching(false)
            );
        }
    }, []);

    return (
        <div style={{
            height: (100 * widgetProps.h) - (widgetProps.name !== '' ? 100 : 20),
            display: 'flex',
            alignItems: 'center',
            marginTop: widgetProps.name === '' ? '-2rem' : 0,
            justifyContent: 'center'
        }}>
            {isFetching && <WidgetLoader/>}

            {warning !== '' &&
            <Popover type={'WARNING'} position={'top-left'}>
                <p>{warning}</p>
            </Popover>
            }
            <button className={'btn eco-btn form-control switcher ' + 'switch-' + btnState}
                    onClick={() => setValue()}/>
        </div>
    );
}

SwitcherWidgetView.propTypes = {
    widgetProps: PropTypes.object
};

export default SwitcherWidgetView;