import {apiService} from './api.service';
import {apiConstants} from '../constants';
import {loginService} from './login.service';


function uploadImage(image) {
    const user = loginService.getCurrentUser();
    const formData = new FormData();
    formData.append('imgView', image);
    const config = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + user.sessionResp.authenticationToken,
            'user-agent-token': user.userAgentToken
            // 'Content-Type': 'image/png'
        },
        body: formData
    };

    const url = apiService.addProps(apiConstants.IMAGE_UPLOAD);
    return fetch(url, config)
        .then(response => {
            const jResponse = response.json();
            if (!response.ok) {
                return jResponse.then(err => Promise.reject(err.message));
            }
            return jResponse;
        });
}

function getImage(imgName) {
    const config = {
        method: 'GET',
    };
    return fetch(apiConstants.IMAGE_GET + '?name=' + imgName, config)
        .then(response => response.blob())
        .then(blob =>
            new Promise(callback => {
            let reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = function () {
                callback(this.result);
            };
        }));

}

function hexToRGB(hex, opacity){
  hex = '0x' + hex.replace(/[#]/gi, '');
  console.log('hex', hex);
  let r = hex >> 16 & 0xFF;
  let g = hex >> 8 & 0xFF;
  let b = hex & 0xFF;
  return `rgba(${r},${g},${b},${opacity})`;
}

export const imageService = {
    uploadImage,
    getImage,
  hexToRGB
};