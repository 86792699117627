/* eslint-disable no-unused-vars */
import {useTranslation} from 'react-i18next';
import React from 'react';
import CustomSelect from '../../../../components/CustomSelect';
import CustomInput from '../../../../components/CustomInput';
import {Field, Form, useField} from 'react-final-form';
import PropTypes from 'prop-types';


const isObject = (data) => {
    return Object.keys(data).length > 0;
};

/**
 *
 * @param availableVariables {Array} Cписок достпных переменных
 * @param condition {{prefix:string,payload:{}}} Условие объеденения
 * @param onChange {} Изменение модели
 * @returns {*}
 */
function ControlSetValue({
                             availableVariables,
                             condition,
                             onChange,
                             label,
                             name,
                             passportList
                         }) {

    const {t} = useTranslation();
    const field = useField(`${name}.type.type`);


    //----

    //-- data arrays
    const groupConditions = [
        {name: '-', value: 'EMPTY'},
        {name: t('scenario.or'), value: 'OR'},
        {name: t('scenario.and'), value: 'AND'}
    ];

    const defaultOperators = [
        {name: '>', value: 'GT'},
        {name: '!=', value: 'NE'},
        {name: '==', value: 'EQ'},
        {name: '>=', value: 'GE'},
        {name: '<', value: 'LT'},
        {name: '=<', value: 'LE'}
    ];
    const variablesType = [
        {name: t('value'), value: {type: 'SET_VARIABLE'}},
        // {name: t('scenario.variable'), value: {type: 'SET_VARIABLE'}},
        // {name: 'Паспорт', value: {type: 'PASSPORT'}},
    ];
    const memorizedVariables = React.useMemo(() => availableVariables.map(item => ({
        name: item.variableName,
        value: {value: item, type: field.input.value}
    })), [availableVariables.length, field.input.value]);


    const findSource = (item, input) => {
        if (!input.value.value && item.value.type === 'SET_VARIABLE') {
            return false;
        }
        if (input.value.value && item.value.value) {
            return input.value.value.variableName === item.value.value.variableName;
        }

        return input.value.type === item.value.type;

    };

    //---

    const findVariableType = (item, input) => {
        return item.value.type === input.value;
    };

    const types = {
        PASSPORT: (<div className="ml1 mr2" style={{width: '650px'}}>
            <Field name={`${name}.payload.setPassport`}>
                {({input}) => (<CustomSelect
                    isRequired={true}
                    key={204}
                    valueList={passportList}
                    value={input.value}
                    onSelect={e => input.onChange(e.value)}
                />)}
            </Field>
        </div>),

        SET_VARIABLE: (<div className="ml1" style={{width: '225px'}}>
            <Field name={`${name}.payload.setValue`}>
                {({input}) => (<CustomInput
                    isRequired={true}
                    key={314}
                    value={input.value}
                    onChange={(e) => {
                        input.onChange(e);
                    }}
                    isDecimal={true}
                    // isInteger={true}
                />)}
            </Field>
        </div>),

        // SET_VARIABLE: (<div className="ml1 mr2" style={{width: '210px'}}>
        //     <Field name={`${name}.payload.comparisonVariable`}>
        //         {({input}) => (<CustomSelect
        //             isRequired={true}
        //             key={206}
        //             valueList={memorizedVariables}
        //             value={memorizedVariables.find(item => findSource(item, input))}
        //             onSelect={(e) => {
        //                 input.onChange(e.value);
        //             }}
        //         />)}
        //     </Field>
        // </div>),
    };


    return (
        <div className="scenario-right">
            <div style={{width: '300px'}}>
                <Field name={`${name}.type`}>
                    {({input, meta}) => <CustomSelect
                        isRequired={true}
                        key={200}
                        label={label}
                        valueList={memorizedVariables}
                        value={memorizedVariables.find(item => findSource(item, input))}
                        onSelect={e => input.onChange(e.value)}
                    />}

                </Field>
            </div>

            <div style={{marginLeft: '1rem'}}>
                <Field name={`${name}.payload.comparisonOperator`}>
                    {({input, meta}) => <CustomSelect
                        isRequired={true}
                        key={201}
                        label=""
                        color={'@default-text-color'}
                        fontWeight="bold"
                        valueList={[]}
                        value={{name: '=='}}
                        onSelect={e => input.onChange(e.value)}
                    />}

                </Field>

            </div>
            <div style={{marginLeft: '1rem'}}>
                <Field name={`${name}.type.type`} initialValue={variablesType[0]}>
                    {({input, meta}) => <CustomSelect
                        isRequired={true}
                        key={204}
                        label=""
                        color={'@default-text-color'}
                        fontWeight="bold"
                        valueList={[]}
                        value={{name: t('value')}}
                        onSelect={e => {

                            input.onChange(e.value.type);
                        }}

                    />}

                </Field>

            </div>
            {types[field.input.value]}

        </div>
    );
}

ControlSetValue.propTypes = {
    availableVariables: PropTypes.array,
    condition: PropTypes.object,
    onChange: PropTypes.func,
    name: PropTypes.string.isRequired,
    conditionIndex: PropTypes.number,
    onRemoveAction: PropTypes.func,
    addCondition: PropTypes.func,
    passportList: PropTypes.array,

    label: PropTypes.string,
};
export default ControlSetValue;
