/* eslint-disable react/prop-types */
import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch, useHistory} from 'react-router-dom';
import LoginPage from './containers/LoginPage';
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import ru from './locales/ru';
import en from './locales/en';
import {loginService} from './services/login.service';
import './styles/eco-buttons.scss';
import './styles/app.scss';
import './styles/z-index.css';
import './styles/alert.css';
import EcoHeader from './containers/EcoHeader';
import CapacityList from './containers/capacity/CapacityList';
import CreateDashboard from './containers/dashboard/CreateDashboard';
import {Provider} from 'react-redux';
import store from './redux/store';
import DeviceList from './containers/devices/DeviceList';
import AddDevice from './containers/devices/AddDevice';
import Import from './containers/import/Import';
import Dashboard from './containers/dashboard/Dashboard';
import './styles/react-datepicker.css';
import Device from './containers/devices/Device';
import OfficePage from './containers/office/OfficePage';
import OrganizationCreate from './containers/OrganizationCreate';
import ConfirmEmail from './containers/ConfirmEmail';
import Sso from './containers/Sso';
import EditDashboard from './containers/dashboard/EditDashboard';
import Idle from 'react-idle';
import ScenarioPage from './containers/scenario/ScenarioPage';
import {localeService} from './services/locale.service';
import ErrorPage from './containers/ErrorPage';
import PassportCreate from './containers/capacity/components/Passport/PassportCreate';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/main.scss';
import DashboardList from './containers/dashboard/DashboardList';
import AddScenario from './containers/scenario/AddScenario';
import packageJson from '../package.json';
import MapTest from './containers/MapTest';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: en,
      ru: ru
    },
    lng: localStorage.getItem('ems-language') || 'ru',
    fallbackLng: localStorage.getItem('ems-language') || 'ru',

    interpolation: {
      escapeValue: false
    }
  });

function App() {
  React.useEffect(() => {
    if (!localStorage.getItem('ems-language')) {
      localeService.setLanguage('ru');
    }
  }, []);
  return (
    <Provider store={store}>
      <Router>
        <div className={'eco-container container-fluid'}>
          <Switch>
            <Route path={'/login'} component={LoginPage}/>
            <Route path={'/register_organization'} component={OrganizationCreate}/>
            <Route path={'/confirm/email'} component={ConfirmEmail}/>
            <Route path={'/sso'} component={Sso}/>
              <Route path={'/map'} component={MapTest}/>
            <MainApp path={'/'}>
              {/*<SideBar />*/}
              <EcoHeader/>
              <div className={'main-container'}>
                <div className={'content'}>
                  <Switch>
                    <Route exact path={'/'}/>
                    <Route path={'/office'} component={OfficePage}/>
                    <Route path={'/dashboards'} component={DashboardList}/>
                    <Route path={'/dashboard/:id'} render={(props) => <Dashboard
                      dashboardId={parseInt(props.match.params.id)}/>}/>
                    <Route path={'/edit_dashboard/:id'} render={(props) => <EditDashboard
                      dashboardId={parseInt(props.match.params.id)}/>}/>
                    {/*<Route path={"/organization"} component={OrganizationPage}/>*/}
                    <Route exact path={'/import'} component={Import}/>
                    {/*<Route exact path={"/reports"} component={ReportsPage}/>*/}
                    <Route exact path={'/capacity'} component={CapacityList}/>
                    <Route exact path={'/create_dashboard'} component={CreateDashboard}/>
                    <Route path={'/device_list/:page'}
                           render={(props) => <DeviceList page={parseInt(props.match.params.page)}/>}/>
                    <Route path={'/device/:id'}
                           render={(props) => <Device deviceId={parseInt(props.match.params.id)}/>}/>
                    <Route exact path={'/add_device'} component={AddDevice}/>
                    <Route path={'/scenario_list'} component={ScenarioPage}/>
                    <Route path={'/add_scenario'} component={AddScenario}/>
                    <Route path={'/passport/create'} component={PassportCreate}/>
                    {/*<Route path={'/add_scenario'} component={AddScenario}/>*/}
                    {/*<Route path={'/scenario/:id'} render={(props)=><EditScenario scenarioId={parseInt(props.match.params.id)} />}/>*/}

                    <Route component={ErrorPage}/>
                  </Switch>
                  <span className={'app-version'}>{localeService.isRussian() ? 'Версия' : 'Version '} {packageJson.version} © ecomonitoring.io, 2020</span>
                </div>
              </div>
            </MainApp>
          </Switch>
        </div>
      </Router>
    </Provider>
  );
}


function MainApp({children, ...rest}) {
  const history = useHistory();
  return (
    <Route {...rest}
           render={({location}) => {
             const currentUser = loginService.getCurrentUser();
             if (currentUser) {
               return <>
                 <Idle
                   timeout={1000 * 3600}
                   onChange={({idle}) => {
                     if (idle) {
                       loginService.logout();
                       history.push('/login');
                     }
                   }}
                 />
                 {children}
               </>;
             } else {
               return <Redirect
                 to={{
                   pathname: '/login',
                   state: {from: location}
                 }}
               />;
             }
           }}
    />
  );
}


// eslint-disable-next-line no-undef
export default App;
