import React from 'react';
import {organizationService} from '../../services/organization.service';
import Loader from '../../components/Loader';
import {localeService} from '../../services/locale.service';
import Modal from '../../components/Modal';
import InputText from '../../components/InputText';
import SelectMultiple from '../../components/SelectMultiple';
import AlertError from '../../components/AlertError';
import {useTranslation} from 'react-i18next';
import {ReactSVG} from 'react-svg';
import svgDelete from '../../sources/images/interface/basket.svg';
import svgEdit from '../../sources/images/interface/edit.svg';
import '../../styles/device-list.scss';
import {canDo} from '../../services/permitions.service';

function Roles() {
  const initNewRole = {
    name: '',
    accessibleRole: []
  };

  const {t} = useTranslation();
  const [roleList, setRoleList] = React.useState([]);
  const [isFetching, setFetching] = React.useState(false);
  const [isFetchingCreate, setFetchingCreate] = React.useState(false);
  const [accessibleRoleList, setAccessibleRoleList] = React.useState([]);
  const [isCreateRoleOpen, setCreateRoleOpen] = React.useState(false);
  const [error, setError] = React.useState('');
  const [newRole, setNewRole] = React.useState(initNewRole);


  React.useEffect(() => {
    reloadRoleList();
    organizationService.getAccessibleRoleList().then(
      response => {
        console.log(response.list);
        setAccessibleRoleList(response.list);
      }
    );
  }, []);

  const reloadRoleList = () => {
    setFetching(true);
    organizationService.getOrganizationRoleList().then(
      response => {
        setRoleList(response);
      }
    ).finally(() => {
      setFetching(false);
    });
  };

  const createRole = () => {
    setFetchingCreate(true);
    organizationService.createOrganizationRole(newRole).then(
      () => {
        setCreateRoleOpen(false);
        setNewRole(initNewRole);
        reloadRoleList();
      }, error => {
        setError(error.toString());
      }
    ).finally(() => {
      setFetchingCreate(false);
    });
  };

  const deleteRole = (role) => {
    if (window.confirm(localeService.isRussian() ? 'Вы уверенны, что хотите удалить роль "' + role.name + '"?' : 'Are you sure you want to delete role "' + role.name + '"?')) {
      setFetching(true);
      organizationService.deleteOrganizationRole(role.id).then(
        result => {
          console.log(result);
        }
        , error => {
          setError((error.errorCode || '') + ' ' + (error.errorMsg || '') + '');
        }
      ).finally(() => {
        reloadRoleList();
      });
    }
  };

  const editRole = (role) => {
    // console.log(role);
    setNewRole(role);
    setCreateRoleOpen(true);
  };

  return <>
    <div className={'d-flex justify-content-between mb-5'}>
      <h2>{localeService.isRussian() ? 'Роли и права доступа' : 'Roles and access rights'}</h2>
      {canDo() && <button className={'btn eco-btn success'} onClick={() => {
        setNewRole(initNewRole);
        setCreateRoleOpen(true);
      }}>
        {localeService.isRussian() ? 'Создать роль' : 'Creat Role'}
      </button>}
    </div>

    <table className={'mt-5 device-list-table'} cellSpacing={0} cellPadding={0}>
      <thead>
      <tr>
        <th>{t('name')}</th>
        <th>{localeService.isRussian() ? 'Права доступа' : 'Accessible Rights'}</th>
        <th width="0">
        </th>
      </tr>
      </thead>

      <tbody>
      {isFetching && <Loader/>}
      <AlertError message={error} isOpen={error !== ''} onCancel={() => setError('')}/>
      {!isFetching && roleList.map((role, i) => {
        return <tr //className={'editable-row'}
          key={i}>
          <td>
            {role.name}
          </td>
          <td>
            {role.accessibleRole.map((access) => {
              return <span className={'help-text mr1'} style={{fontSize: '70%'}}
                           key={access.id}>{t('roles.' + access.name)}</span>;
            })}
          </td>
            {canDo() && <td align="right" className={'editable-col d-flex'}>
            <button className={'btn svg-btn'}
                    onClick={() => editRole(role)}>
              <ReactSVG src={svgEdit}/>
            </button>
            <button className={'ms-3 btn svg-btn danger'}
                    onClick={() => deleteRole(role)}>
              <ReactSVG src={svgDelete}/>
            </button>
          </td>}
        </tr>;
      })}
      </tbody>

    </table>
    <Modal isOpen={isCreateRoleOpen} footer={false} isClosable={false}
           title={localeService.isRussian() ? 'Создание новой роли' : 'Create Role'}>
      {!isFetchingCreate && <form>
        <InputText label={localeService.isRussian() ? 'Название роли' : 'New Role Name'}
                   value={newRole.name}
                   onChange={text => setNewRole({...newRole, name: text})}/>
        <SelectMultiple label={localeService.isRussian() ? 'Права доступа' : 'Accessible Rights'}
                        isTranslateName={true}
                        translatePrefix={'roles.'}
                        defaultValue={newRole.accessibleRole}
                        valueList={accessibleRoleList}
                        onSelect={(arl) => setNewRole({...newRole, accessibleRole: arl})}
        />
        <AlertError message={error} isOpen={error !== ''} onCancel={() => setError('')}/>
        {!error && <div className={'d-flex justify-content-center mt-5'}>
          <button className={'btn eco-btn success'} onClick={() => {
            if (newRole.name === '') {
              setError(localeService.isRussian() ? 'Введите название роли' : 'Enter Role Name');
            } else if (newRole.accessibleRole.length === 0) {
              setError(localeService.isRussian() ? 'Выберите хотя бы одно право доступа для роли' : 'Choose at least one access right for the role');
            } else {
              createRole();
            }
          }}>{newRole.id ? t('update') : t('create')}</button>
          <button className={'btn eco-btn danger'}
                  onClick={() => setCreateRoleOpen(false)}>{t('cancel')}</button>
        </div>}
      </form>}
      {isFetchingCreate && <Loader/>}
    </Modal>
  </>;
}

export default Roles;