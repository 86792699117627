import React, {useState} from 'react';
import {localeService} from '../../services/locale.service';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import InputText from '../../components/InputText';
import SelectCapacityTree from '../../components/SelectCapacityTree';

function CreateModbusVariable({onAddVariable, onCancel, capacityTree}) {
    const [virtualVariable, setVirtualVariable] = useState({name: '', id: new Date().getTime()});
    const [errorMsg, setErrorMsg] = useState('');
    const {t} = useTranslation();

    const onSubmit = (e) => {
        console.log('virVar', virtualVariable);
        e.preventDefault();
        setErrorMsg('');
        if(virtualVariable.name === ''){
            setErrorMsg(localeService.isRussian() ? 'Введите имя пременной' : 'Enter Device Variable Name!');
        } else if (!virtualVariable.capacity){
            setErrorMsg(localeService.isRussian() ? 'Выберите источник для перменной!' : 'Select variable capacity!');
        } else {
            onAddVariable(virtualVariable);
        }
    };

    return <div className={'container-70'} onSubmit={() => onSubmit()}>
        <form>
            <InputText label={localeService.isRussian() ? 'Имя переменной' : 'Variable Name'} isRequired={true}
                       onChange={text => setVirtualVariable({...virtualVariable, name: text})} value={virtualVariable.name}/>
            <div className={'input-group understroke required'}>
                <div className={'input-group-prepend'}>
                    <label>{t('capacity')}</label>
                </div>
                <SelectCapacityTree capacityTree={capacityTree} onSelect={(capacity) => setVirtualVariable({...virtualVariable, capacity: capacity})} capacity={virtualVariable.capacity}/>
            </div>
            {errorMsg &&
            <div className="alert alert-danger">{errorMsg}</div>
            }
            <div className={'form-group to-right mt2'}>
                <button className={'btn eco-btn success'} onClick={(e) => onSubmit(e)}>{t('create')}</button>
                <button className={'btn eco-btn danger'} onClick={() => onCancel()}>{t('cancel')}</button>
            </div>
        </form>
    </div>;
}

CreateModbusVariable.propTypes = {
    onAddVariable: PropTypes.func,
    onCancel: PropTypes.func,
    capacityTree: PropTypes.array
};

export default CreateModbusVariable;