import React from 'react';
import {localeService} from '../services/locale.service';
import '../styles/simple-dd.scss';

function ChangeLanguage() {

    const [open, setOpen] = React.useState(false);

    return (
        <div className={'simple-dd' + (open ? ' show' : '')}>
            <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown"
                    aria-expanded="false" onClick={() => setOpen(!open)}>
                {localeService.isRussian() ? 'Рус' : 'Eng'}
            </button>
            <ul className={'dropdown-menu ' + (open ? ' show' : '')} aria-labelledby="dropdownMenuButton1" style={{minWidth: '3rem'}}>
                <li onClick={() => {
                    localeService.setLanguage('ru');
                    setOpen(false);
                }}>
                    Рус
                </li>
                <li onClick={() => {
                    localeService.setLanguage('en');
                    setOpen(false);
                }}>
                    Eng
                </li>
            </ul>
        </div>
    );
}


export default ChangeLanguage;