import React from 'react';
import PropTypes from 'prop-types';
// import RangeSlider from 'react-bootstrap-range-slider';
import RangeStepInput from 'react-range-step-input';
import '../styles/slider.scss';

function Slider({min, max, value, onSetValue, isDisabled}) {

    const [currentValue, setCurrentValue] = React.useState(value);

    React.useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    const onChange = (e) => {
        onSetValue(e);
    };

    return <>
        <RangeStepInput min={min} max={max} value={currentValue} disabled={isDisabled}
                     onAfterChange={changeEvent => onChange(changeEvent.target.value)}
                     onChange={changeEvent => setCurrentValue(changeEvent.target.value)}/>
    </>;
}

Slider.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    value: PropTypes.number,
    onSetValue: PropTypes.func,
    isDisabled: PropTypes.bool
};

Slider.defaultProps = {
    min: 0,
    max: 100,
    value: 50,
    isDisabled: false
};

export default Slider;