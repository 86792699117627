/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { localeService } from '../services/locale.service';
import '../styles/dropdown.scss';
import ClickOutside from './ClickOutside';


function SelectCapacityTree({ capacityTree, capacity, onSelect, isAlwaysOpen, width, inTable }) {

    const [isOpen, setIsOpen] = useState(isAlwaysOpen);

    const [selectedCapacity, setSelectedCapacity] = useState(capacity ? {
        id: capacity.id,
        name: capacity.name
    } : { id: null, name: '' });

    const onChange = (capacity) => {
        setIsOpen(isAlwaysOpen);
        const newCapacity = { name: capacity.name, id: capacity.id };
        setSelectedCapacity(newCapacity);
        onSelect(newCapacity);
    };

    useEffect(() => {
        const rootCapacity = {
            id: 0,
            name: localeService.isRussian() ? 'Корневой источник' : 'Root Source',
            child: capacityTree
        };
        const capacityName = getSelectedCapacityName(rootCapacity, selectedCapacity.id);
        setSelectedCapacity({ ...selectedCapacity, name: capacityName });
    }, []);


    return <div className={'dropdown-container ' + (inTable ? 'in-table ' : '') + (isOpen ? 'open ' : '') + (isAlwaysOpen ? ' is-always-open ' : '')}>
        {!isAlwaysOpen &&
            <span className={'selected-item'} onClick={() => setIsOpen(!isOpen)}>{selectedCapacity.name}</span>}
        <ClickOutside disabled={isAlwaysOpen} onClick={() => setIsOpen(isAlwaysOpen)}>
            <div className={'dropdown' + (isOpen ? ' open' : '')} style={{ width: width ? (width + 'px') : 'auto' }}>
                <div className={'dropdown-content'}>
                    <ul>
                        <li><span onClick={() => onChange({
                            id: 0,
                            name: localeService.isRussian() ? 'Корневой источник' : 'Root Source'
                        })}>{localeService.isRussian() ? 'Корневой источник' : 'Root Source'}</span></li>
                        <ul className={'tree-parent open'}>
                            {capacityTree.map((capacity) => {
                                return <CapacityChild key={'cap-' + capacity.id} capacity={capacity} selectedCapacity = {selectedCapacity}
                                    onChange={onChange} />;
                            })}
                        </ul>
                    </ul>
                </div>
            </div>
        </ClickOutside>
    </div>;
}

function getSelectedCapacityName(capacity, capacityId) {
    if (capacity.id === capacityId) {
        return capacity.name;
    } else {
        if (capacity.child && capacity.child.length > 0) {
            return capacity.child.map(subCapacity => {
                return getSelectedCapacityName(subCapacity, capacityId);
            });
        }
    }

}

function CapacityChild({ capacity, onChange, selectedCapacity }) {
    const [isOpen, setIsOpen] = useState(false);
    return (<>
        <li>
            <div className={'tree-item ' + (selectedCapacity.id === capacity.id ? 'active' :'')}>
                {capacity.child && capacity.child.length > 0 &&
                    <button type={'button'} className={'toggle-tree-item'}
                        onClick={() => setIsOpen(!isOpen)}>{isOpen ? '-' : '+'}</button>}
                <span onClick={() => onChange(capacity)}>{capacity.name}</span>
            </div>
        </li>
        {capacity.child && capacity.child.length > 0 &&
            <ul className={'tree-parent' + (isOpen ? ' open' : '')}>
                {capacity.child.map(childCapacity => {
                    return <CapacityChild key={'cap-' + childCapacity.id} capacity={childCapacity} selectedCapacity = {selectedCapacity}
                        onChange={onChange} />;
                })}
            </ul>
        }
    </>
    );
}

SelectCapacityTree.propTypes = {
    capacity: PropTypes.object,
    capacityTree: PropTypes.array.isRequired,
    onSelect: PropTypes.func,
    isAlwaysOpen: PropTypes.bool,
    width: PropTypes.number,
    inTable: PropTypes.bool
};

SelectCapacityTree.defaultProps = {
    isAlwaysOpen: false,
    width: null
};

export default SelectCapacityTree;
