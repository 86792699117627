import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {dashboardService} from '../../services/index';
import Loader from '../../components/Loader';
import {localeService} from '../../services/locale.service';
import '../../styles/widget-carousel.scss';
import DropItem from './DropItem';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import {apiConstants} from '../../constants';

function WidgetList({type}) {
    const {t} = useTranslation();
    const [widgetsType, setWidgetsType] = useState();
    const [open, setOpen] = useState(false);
    const [widgetList, setWidgetList] = useState({informationWidgets: null, controlWidgets: null});
    const [isFetchingWidgetTypeList, setIsFetchingWidgetTypeList] = useState(false);

    useEffect(() => {
        setWidgetsType(type);
        setIsFetchingWidgetTypeList(true);
        dashboardService.getWidgetTypeList()
            .then(
                widgetList => {
                    setWidgetList(widgetList);
                    setIsFetchingWidgetTypeList(false);
                }
            );
    }, []);

    return (
        <div>
            {isFetchingWidgetTypeList && <Loader
                waitText={localeService.isRussian() ? 'Загрузка списка типов виджетов...' : 'Loading widget type list...'}/>}
            {!isFetchingWidgetTypeList &&
            <>
                <ul className="nav nav-pills justify-content-start mb-5">
                    <li className={'nav-link'}>
                        <label>{localeService.isRussian() ? 'Типы виджетов' : 'Widget Types'}</label>
                    </li>
                    <li className="nav-item dropdown" style={{position: 'relative'}}>
                        <button
                            className={'nav-link btn eco-btn default dropdown-toggle'}
                            onClick={() => setOpen(!open)}>{localeService.isRussian() ? (widgetsType === 'widgets_info' ? 'Информационные' : 'Для управления') : (widgetsType === 'widgets_info' ? 'Informational' : 'Management')}</button>
                        <ul className={'dropdown-menu' + (open ? ' show' : '')}>
                            <li>
                                <button className="dropdown-item" onClick={() => {
                                    setOpen(!open);
                                    setWidgetsType('widgets_info');
                                }}>
                                    {localeService.isRussian() ? 'Информационные Виджеты' : 'Informational Widgets'}
                                </button>
                            </li>
                            <li>
                                <button className="dropdown-item" onClick={() => {
                                    setOpen(!open);
                                    setWidgetsType('widgets_manage');
                                }}>
                                    {localeService.isRussian() ? 'Виджеты для управления' : 'Management Widgets'}
                                </button>
                            </li>
                        </ul>
                    </li>
                </ul>
                <div className={'widget-type-list'}>
                    {widgetList.informationWidgets && widgetsType === 'widgets_info' &&
                    widgetList.informationWidgets.map((widget, j) => {
                        return (
                            <DropItem key={'drag-info-' + j} widget={widget}>
                                <div key={'info-widget-' + j} className="сhoose-dashboard-item">
                                    <label>{t(widget.name)}</label>
                                    {widget.img && widget.img.id && <img
                                        src={apiConstants.API_URL + '/file/download?fileId=' + widget.img.id}/>}
                                </div>
                            </DropItem>
                        );
                    })

                    }
                    {widgetList.controlWidgets && widgetsType === 'widgets_manage' &&
                    widgetList.controlWidgets.map((widget, j) => {
                        return <DropItem key={'drag-control-' + j} widget={widget}>
                            <div key={'control-widget-' + j} className="сhoose-dashboard-item">
                                <label>{t(widget.name)}</label>
                                {widget.img && widget.img.id && <img
                                    src={apiConstants.API_URL + '/file/download?fileId=' + widget.img.id}/>}
                            </div>
                        </DropItem>;
                    })

                    }
                </div>
            </>
            }
        </div>
    );
}

WidgetList.propTypes = {
    type: PropTypes.string
};

export default WidgetList;