import React, {useState} from 'react';
import {localeService} from '../../../../services/locale.service';
import InputText from '../../../../components/InputText';
//import '../../../../styles/form.scss';
import '../../../../styles/passport-view.scss';
import {useTranslation} from 'react-i18next';
import {ReactSVG} from 'react-svg';
import svgDelete from '../../../../sources/images/interface/basket.svg';
import svgAdd from '../../../../sources/images/interface/add.svg';
import Select from '../../../../components/Select';

const PassportCreate = () => {
    const myPassport = {
        name:'',
        blocks:[]
    };

    const [passport, setPassport]=useState(myPassport);
    const {t} = useTranslation();

    const createAll = (n,key,blockKey) =>{
        switch(n) {
            case 'block': passport.blocks=[...passport.blocks, {header: '', fields: [], filesBtns: []}];
                break;
            case 'field': passport.blocks[key].fields = [...passport.blocks[key].fields, {type: '', name: '', unit: '', fixedValues: []}];
                break;
            case 'fileBtn': passport.blocks[key].filesBtns = [...passport.blocks[key].filesBtns, {btnDesc: ''}];
                break;
            default: passport.blocks[blockKey].fields[key].fixedValues = [...passport.blocks[blockKey].fields[key].fixedValues, {value: ''}];
                break;
        }
        setPassport({...passport});
    };

    const deleteBlock = (key) => {
        let blocks = passport.blocks;
        blocks.splice(key, 1);
        if(key>=0){
            passport.blocks=blocks;
            setPassport({...passport});
        }
    };
    const deleteField = (key,blockKey) => {
        let fields = passport.blocks[blockKey].fields;
        fields.splice(key, 1);
        if(key>=0){
            passport.blocks[blockKey].fields = fields;
            setPassport({...passport});
        }
    };
    const deleteFileBtn = (key,blockKey) => {
        let filesBtns = passport.blocks[blockKey].filesBtns;
        filesBtns.splice(key, 1);
        if(key>=0){
            passport.blocks[blockKey].filesBtns = filesBtns;
            setPassport({...passport});
        }
    };
    const deleteFixedValue = (key,blockKey,fieldKey) => {
        let fixedValues = passport.blocks[blockKey].fields[fieldKey].fixedValues;
        fixedValues.splice(key, 1);
        if(key>=0){
            passport.blocks[blockKey].fields[fieldKey].fixedValues = fixedValues;
            setPassport({...passport});
        }
    };

    const updateField = (key, text, fn, blockKey, fieldKey) => {
        switch(fn) {
            case 'header': passport.blocks[key].header = text;
                break;
            case 'type': passport.blocks[blockKey].fields[key].type = text; clearField(blockKey,key);
                break;
            case 'name': passport.blocks[blockKey].fields[key].name = text;
                break;
            case 'unit': passport.blocks[blockKey].fields[key].unit = text;
                break;
            case 'fixedValue': passport.blocks[blockKey].fields[fieldKey].fixedValues[key].value = text;
                break;
            default: passport.blocks[blockKey].filesBtns[key].btnDesc=text;
                break;
        }
        setPassport({...passport});
    };

    const clearField=(blockKey,key)=>{
        passport.blocks[blockKey].fields[key].name='';
        passport.blocks[blockKey].fields[key].unit='';
        passport.blocks[blockKey].fields[key].fixedValues=[];
        setPassport({...passport});
    };

    return(
        <>
            <nav className="page-header">
                <ul className="nav-left">
                    <li><h5>{localeService.isRussian() ? 'Конструктор паспортов' : 'Passport constructor'}</h5></li>
                </ul>
            </nav>
            <div className={'passport-constructor-box'}>
                <div className={'passport-constructor'}>
                    <form>
                        <div className={'mixed-input'}>
                            <InputText label={localeService.isRussian() ? 'Новый тип паспорта: ' : 'New passport type: '}
                                       className={'head-input'}
                                       value={passport.name} onChange={text => setPassport({...passport, name: text})}/>
                            <button type={'button'} className={'btn eco-btn default'}
                                    onClick={() => createAll('block')}>
                                {localeService.isRussian() ? 'Добавить блок' : 'Add block'}
                            </button>
                        </div>
                        {passport.blocks.length > 0 && passport.blocks.map((block, key)=>{
                            const blockKey=key;
                            return(
                                <div key={key} style={{marginBottom:'30px'}}>
                                    <div className={'mixed-input'}>
                                        <InputText label={localeService.isRussian() ? 'Заголовок блока: ' : 'Block header: '}
                                                   className={'passport-block-header'}
                                                   value={block.header} onChange={text => updateField(key, text, 'header')}/>
                                        <button className={'danger svg-round-btn ml1'}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    deleteBlock(key);
                                                }}
                                                title={localeService.isRussian() ? 'Удалить блок' : 'Delete block'}>
                                            <ReactSVG src={svgDelete}/>
                                        </button>
                                        <button type={'button'} className={'btn eco-btn default'}
                                                onClick={() => createAll('field',key)}>
                                            {localeService.isRussian() ? 'Добавить поле' : 'Add Field'}
                                        </button>
                                        <button type={'button'} className={'btn eco-btn default'}
                                                onClick={() => createAll('fileBtn',key)}>
                                            {localeService.isRussian() ? 'Добавить кнопку' : 'Add button'}
                                        </button>
                                    </div>
                                    {passport.blocks[key].fields.length > 0 && passport.blocks[key].fields.map((field, key) => {
                                        const fieldKey=key;
                                        return(
                                            <div key={key}>
                                                <div className={'mixed-input'}>
                                                    <Select
                                                        style={{marginBottom:'0'}}
                                                        valueList={[
                                                            {value: 'MIXED_FIELD', name: localeService.isRussian() ? 'Смешанный' : 'Mixed'},
                                                            {value: 'INTEGER_FIELD', name: localeService.isRussian() ? 'Число' : 'Integer'},
                                                            {value: 'BOOLEAN_FIELD', name: localeService.isRussian() ? 'Чекбокс' : 'Checkbox'},
                                                            {value: 'FIXED_VALUES_FIELD', name: localeService.isRussian() ? 'Фиксированные значения' : 'Fixed values'},
                                                        ]}
                                                        label={t('type')}
                                                        onSelect={el => {
                                                            updateField(key,el.value,'type',blockKey);}}/>
                                                    {passport.blocks[blockKey].fields[key].type === 'FIXED_VALUES_FIELD' ?
                                                        <div>
                                                            <button type={'button'}
                                                                    onClick={() => {
                                                                        createAll('fixedValueField',key,blockKey);
                                                                    }}
                                                                    className={'success svg-round-btn'}
                                                                    title={localeService.isRussian() ? 'Добавить значение' : 'Add variable'}>
                                                                <ReactSVG src={svgAdd}/>
                                                            </button>
                                                        </div>
                                                        :
                                                        <InputText
                                                            className={'passport-constructor-field'}
                                                            placeHolder={t('passport.field_name')}
                                                            value={field.name}
                                                            onChange={text => updateField(key, text, 'name', blockKey)}/>
                                                    }
                                                    {passport.blocks[blockKey].fields[key].type !== 'BOOLEAN_FIELD' &&
                                                    <InputText
                                                        className={'passport-constructor-field'}
                                                        placeHolder={t('unit')}
                                                        value={field.unit}
                                                        onChange={text => updateField(key, text, 'unit', blockKey)}/>
                                                    }
                                                    <button className={'danger svg-round-btn ml1'}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                deleteField(key,blockKey);
                                                            }}
                                                            title={localeService.isRussian() ? 'Удалить поле' : 'Delete field'}>
                                                        <ReactSVG src={svgDelete}/>
                                                    </button>
                                                </div>
                                                {passport.blocks[blockKey].fields[key].type === 'FIXED_VALUES_FIELD' &&
                                                <div className={'row'} style={{padding: '0'}}>
                                                    <div className={'col-50 add-variables-box'}>
                                                        {passport.blocks[blockKey].fields[key].fixedValues.length > 0 && passport.blocks[blockKey].fields[key].fixedValues.map((fixedValue, key) => {
                                                            return(
                                                                <div key={key} className={'mixed-input'}>
                                                                    <InputText
                                                                        className={'add-variables-row'}
                                                                        placeHolder={t('value')+' '+(key+1)}
                                                                        value={fixedValue.value}
                                                                        isDecimal={true}
                                                                        onChange={text => updateField(key, text, 'fixedValue', blockKey, fieldKey)}/>
                                                                    <button className={'danger svg-round-btn ml1'}
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                deleteFixedValue(key,blockKey,fieldKey);
                                                                            }}
                                                                            title={localeService.isRussian() ? 'Удалить значение' : 'Delete value'}>
                                                                        <ReactSVG src={svgDelete}/>
                                                                    </button>
                                                                </div>);})}
                                                    </div>
                                                </div>
                                                }
                                            </div>
                                        );
                                    })}
                                    {passport.blocks[key].filesBtns.length > 0 && passport.blocks[key].filesBtns.map((field, key) => {
                                        return(
                                            <div key={key} className={'mixed-input'}>
                                                <div>
                                                    <button className={'btn eco-btn non-working passport-file-btn'}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                            }}>
                                                        {localeService.isRussian() ? 'Кнопка загрузки файла' : 'File upload button'}
                                                    </button>
                                                </div>
                                                <InputText
                                                    className={'passport-constructor-field'}
                                                    placeHolder={localeService.isRussian() ? 'Описание кнопки' : 'Button description'}
                                                    value={field.btnDesc}
                                                    onChange={text => updateField(key, text, 'btnDesc', blockKey)}/>
                                                <button className={'danger svg-round-btn ml1'}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            deleteFileBtn(key,blockKey);
                                                        }}
                                                        title={localeService.isRussian() ? 'Удалить кнопку' : 'Delete button'}>
                                                    <ReactSVG src={svgDelete}/>
                                                </button>
                                            </div>
                                        );
                                    })
                                    }
                                </div>);
                        })}
                        {passport.blocks.length > 0 &&
                        <div className={'to-right'}>
                            <button type={'button'} className={'btn eco-btn success'}
                                    onClick={() => alert('Сохранение пока что не работает!')}>
                                {t('save')}
                            </button>
                        </div>
                        }
                    </form>
                </div>
            </div>
        </>
    );
};

export default PassportCreate;