import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {localeService} from '../../../services/locale.service';
import {dashboardConstants} from '../../../constants';
import {updateWidget} from '../../../redux/actions/dashboardActions';
import {connect} from 'react-redux';
import InputText from '../../../components/InputText';
import {useTranslation} from 'react-i18next';
import CheckBox from '../../../components/CheckBox';
import {ReactSVG} from 'react-svg';
import svgAddColEnd from '../../../sources/images/interface/add_col_end.svg';
import svgDelColEnd from '../../../sources/images/interface/del_col_end.svg';
import svgAddRowEnd from '../../../sources/images/interface/add_row_bottom.svg';
import svgDelRowEnd from '../../../sources/images/interface/del_row_bottom.svg';
import EditWidgetDataSource from '../EditWidgetDataSource';
import AddFunctionToDatasource from '../AddFunctionToDatasource';
import {widgetsService} from '../../../services/widget.service';

function TableWidgetCreate({widgetProps, updateWidget, dashboardDataSources}) {
    const [widget, setWidget] = useState(widgetProps);
    const {t} = useTranslation();
    const tableTypes = ['MANUAL', 'TIME_SERIES', 'FUNCTION'];

    React.useEffect(() => {
        if (!widget.parameters) {
            setWidget({
                ...widget,
                dataSources: [],
                parameters: {
                    showHeader: true,
                    type: tableTypes[0],
                    tableContent: [['']],
                    buildHorizontally: false
                }
            });
        }
    }, []);

    const addCol = (e) => {
        e.preventDefault();
        let table = widget.parameters.tableContent;
        table.map((row) => {
            row.push('');
        });
        setWidget({...widget, parameters: {...widget.parameters, tableContent: table}});
    };

    const addRow = (e) => {
        e.preventDefault();
        let table = widget.parameters.tableContent;
        let row = [];
        for (let i = 0; i < table[0].length; i++) {
            row.push('');
        }
        table.push(row);
        setWidget({...widget, parameters: {...widget.parameters, tableContent: table}});
    };

    const deleteRow = (e) => {
        e.preventDefault();
        let table = widget.parameters.tableContent;
        if (table.length > 1) {
            table.pop();
            setWidget({...widget, parameters: {...widget.parameters, tableContent: table}});
        }
    };

    const deleteCol = (e) => {
        e.preventDefault();
        let table = widget.parameters.tableContent;
        if (table[0].length > 2) {
            table.map(row => {
                row.pop();
            });
            setWidget({...widget, parameters: {...widget.parameters, tableContent: table}});
        }
    };

    const changeTableType = (typeId) => {
        setWidget({...widget, parameters: {...widget.parameters, type: tableTypes[typeId]}});
    };

    const createDataSource = () => {
        const dataSources = [...widget.dataSources, {idx: 'new', parameters: {}}];
        setWidget({...widget, dataSources: dataSources});
    };

  const deleteDataSource = (idx) => {
    let dsList = widget.dataSources;
    dsList.splice(idx, 1);
    if(idx>=0){
      setWidget({...widget, dataSources: dsList});
    }

  };

    return <>
        <form>
            <InputText label={t('name')} value={widget.name}
                       onChange={(text) => setWidget({...widget, name: text})}/>
            {widget.parameters &&
            <nav className="nav justify-content-start align-items-center mb-5">
                <li className="nav-item me-5">
                    <b>{localeService.isRussian() ? 'Тип таблицы:' : 'Table type:'}</b>
                </li>
                <li className="nav-item me-5">
                    <button
                        className={'nav-link btn eco-btn dark ' + (widget.parameters.type === tableTypes[0] && 'active')}
                        onClick={(e) => {
                            e.preventDefault();
                            changeTableType(0);
                        }}>
                        {localeService.isRussian() ? 'Ручной ввод данных' : 'Manual input'}
                    </button>
                </li>
                <li className="nav-item me-5">
                    <button
                        className={'nav-link btn eco-btn dark ' + (widget.parameters.type === tableTypes[1] && 'active')}
                        onClick={(e) => {
                            e.preventDefault();
                            changeTableType(1);
                        }}>
                        {localeService.isRussian() ? 'Временной ряд' : 'Time series'}
                    </button>
                </li>
                <li className="nav-item me-5">
                    <button
                        className={'nav-link btn eco-btn dark ' + (widget.parameters.type === tableTypes[2] && 'active')}
                        onClick={(e) => {
                            e.preventDefault();
                            changeTableType(2);
                        }}>
                        {localeService.isRussian() ? 'Функция' : 'Function'}
                    </button>
                </li>
            </nav>
            }
            <hr/>

            {widget.parameters && widget.parameters.type === tableTypes[0] &&
            <>
                <div className={'d-flex justify-content-between align-items-center'}>
                <CheckBox onChange={(checked) => {
                    setWidget({...widget, parameters: {...widget.parameters, showHeader: checked}});
                }}
                          label={localeService.isRussian() ? 'Отображать первую строку, как заголовок' : 'Show header row'}
                          checked={widget.parameters.showHeader}/>
                <div className={'d-flex'}>
                    <button className={'btn svg-btn extra-large ms-3'} onClick={(e) => addCol(e)}
                            title={localeService.isRussian() ? 'Добавить столбец в конец таблицы' : 'Add column at the end of table'}>
                        <ReactSVG src={svgAddColEnd}/>
                    </button>
                    <button className={'btn svg-btn extra-large ms-3'} onClick={(e) => deleteCol(e)}
                            title={localeService.isRussian() ? 'Удалить последний столбец' : 'Delete last column'}>
                        <ReactSVG src={svgDelColEnd}/>
                    </button>
                    <button className={'btn svg-btn extra-large ms-3'} onClick={(e) => addRow(e)}
                            title={localeService.isRussian() ? 'Добавить строку в конец таблицы' : 'Add row at the end of table'}>
                        <ReactSVG src={svgAddRowEnd}/>
                    </button>
                    <button className={'btn svg-btn extra-large ms-3'} onClick={(e) => deleteRow(e)}
                            title={localeService.isRussian() ? 'Удалить последнюю строку' : 'Delete last row'}>
                        <ReactSVG src={svgDelRowEnd}/>
                    </button>
                </div>
                </div>
                <div className={'scrollable-zone'}>
                    <h5>{localeService.isRussian() ? 'Заполните поля таблицы' : 'Fill out table Fields'}</h5>
                    <table className="mb-5" style={{margin: 0 + 'px', border: '1px border grey', borderRadius: '0.5rem'}}>
                        {widget.parameters.showHeader &&
                        <thead>
                        <tr>
                            {
                                widget.parameters.tableContent[0].map((col, j) => {
                                    return (<th key={j}>
                                        <input type={'text'}
                                               placeholder={localeService.isRussian() ? 'Введите значение' : 'Enter value'}
                                               value={col}
                                               onChange={(e) => {
                                                   let tableContent = widget.parameters.tableContent;
                                                   tableContent[0][j] = e.target.value;
                                                   setWidget({
                                                       ...widget,
                                                       parameters: {...widget.parameters, tableContent: tableContent}
                                                   });
                                               }}/>
                                    </th>);
                                })
                            }
                        </tr>
                        </thead>
                        }
                        <tbody>
                        {widget.parameters.tableContent.map((row, i) => {
                            if (widget.parameters.showHeader && i === 0) {
                                return;
                            } else {
                                return (
                                    <tr key={i}>
                                        {
                                            row.map((col, j) => {
                                                return (<td key={j}>
                                                    <input type={'text'}
                                                           placeholder={localeService.isRussian() ? 'Введите значение' : 'Enter value'}
                                                           value={col}
                                                           onChange={(e) => {
                                                               let tableContent = widget.parameters.tableContent;
                                                               tableContent[i][j] = e.target.value;
                                                               setWidget({
                                                                   ...widget,
                                                                   parameters: {
                                                                       ...widget.parameters,
                                                                       tableContent: tableContent
                                                                   }
                                                               });
                                                           }}/>
                                                </td>);
                                            })
                                        }
                                    </tr>
                                );
                            }
                        })}
                        </tbody>
                    </table>
                </div>
            </>}

            {widget.parameters && (widget.parameters.type === tableTypes[1] || widget.parameters.type === tableTypes[2]) &&
            <>
              <CheckBox onChange={(checked) => {
                setWidget({...widget, parameters: {...widget.parameters, buildHorizontally: checked}});
              }}
                        label={localeService.isRussian() ? 'Горизонтально' : 'Horizontally'}
                        checked={widget.parameters.buildHorizontally}/>
                {widget.dataSources && widget.dataSources.length > 0 && widget.dataSources.map((dataSource, key) => {
                    return <EditWidgetDataSource key={key} dataSourceNumber={key + 1} widgetDataSource={dataSource}
                                                 onChangeDataSource={(ds) => {
                                                     widget.dataSources[key] = ds;
                                                     setWidget(widget);
                                                 }}
                                                 onDeleteDataSource={() => deleteDataSource(key)}
                                                 moveUp={() => {
                                                     if(key > 0){
                                                       setWidget({
                                                         ...widget,
                                                         dataSources: widgetsService.dataSourceMove(widget.dataSources, key, key-1)
                                                       });
                                                     }
                                                 }}
                                                 moveDown={() => {
                                                   if(key < widget.dataSources.length){
                                                     setWidget({
                                                       ...widget,
                                                       dataSources: widgetsService.dataSourceMove(widget.dataSources, key, key+1)
                                                     });
                                                   }
                                                 }}
                    />;
                })}
                {dashboardDataSources.length > 0 &&
                <button type={'button'} className={'btn eco-btn default'}
                        onClick={() => createDataSource()}>
                    {localeService.isRussian() ? 'Добавить источник данных' : 'Add Data Source'}
                </button>
                }
            </>
            }

            {widget.parameters && (widget.parameters.type === tableTypes[2]) && widget.dataSources && widget.dataSources.length > 0 &&
            <AddFunctionToDatasource withDemand={false} appliedFunction={widget.parameters.function ? {
                ...widget.parameters.function,
                name: widget.parameters.function.functionName
            } : null}
                                     onApplyFunction={(appliedFunction) => {
                                         setWidget({
                                             ...widget,
                                             parameters: {
                                                 ...widget.parameters,
                                                 function: appliedFunction
                                             }
                                         });
                                     }}/>
            }


            <div className={'d-flex justify-content-center'}>
                <button type={'button'} className={'btn eco-btn success'}
                        onClick={() => {
                            updateWidget({...widget, mode: dashboardConstants.EDITION_MODE});
                        }}>
                    {localeService.isRussian() ? 'Сохранить' : 'Save'}
                </button>
                <button type={'button'} className={'btn eco-btn danger'}
                        onClick={() => {
                            updateWidget({...widgetProps, mode: dashboardConstants.EDITION_MODE});
                        }}>
                    {t('cancel')}
                </button>
            </div>
        </form>

    </>;
}

TableWidgetCreate.propTypes = {
    widgetProps: PropTypes.object,
    updateWidget: PropTypes.func,
    dashboardDataSources: PropTypes.array
};

const mapStateToProps = state => {
    const dashboardDataSources = state.dashboardReducer.dataSourceList;
    return {dashboardDataSources};
};

const mapDispatchToProps = {
    updateWidget: updateWidget
};
export default connect(mapStateToProps, mapDispatchToProps)(TableWidgetCreate);