import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ClickOutside from '../../../../components/ClickOutside';
import {useTranslation} from 'react-i18next';
import Modal from '../../../../components/Modal';
import {localeService} from '../../../../services/locale.service';
import {dashboardService} from '../../../../services/index';
import InputText from '../../../../components/InputText';
import ColorPicker from '../../../../components/ColorPicker';
import Select from '../../../../components/Select';
import AlertError from '../../../../components/AlertError';
import Loader from '../../../../components/Loader';

function AddFunctionToChart({onApplyFunction, transformationFunctionList, dataSources, datetimeFilter, widgetWidth}) {
    const {t} = useTranslation();
    const [isDropDownOpened, setDropDownOpened] = useState(false);
    const [selectedFunction, setSelectedFunction] = useState(null);
    const [isOpenConfigurator, setOpenConfigurator] = useState(false);
    const [error, setError] = useState(null);
    const [isFetching, setFetching] = useState(false);

    const onSelectFunction = (func) => {
        setSelectedFunction({
            ...func,
            idx: new Date().getTime(),
            functionParams: Array.from(Array(func.countParam), () => 0),
            parameters: {
                dataSourceName: 'F(x): ' + func.name,
                color: dashboardService.getRandomColor(),
                dataSource: dataSources.length === 1 ? {
                    id: dataSources[0].id,
                    name: dataSources[0].parameters.dataSourceName
                } : null
            }
        });
        setDropDownOpened(false);
        setOpenConfigurator(true);
    };

    const onSubmitFunction = () => {
            setFetching(true);
            dashboardService.getWidgetFunctionData(selectedFunction.parameters.dataSource.id, datetimeFilter.startDate, datetimeFilter.finishDate,
                selectedFunction.functionCode, selectedFunction.functionParams, widgetWidth, datetimeFilter.timeGroup)
                .then(
                    result => {
                        const functionDataSource = {
                            ...selectedFunction,
                            data: result.list
                        };
                        return functionDataSource;
                    }, () => {
                        setError(localeService.isRussian() ? 'Не удалось применить функцию. Попробуйте использовать другие параметры.' : 'Cannot apply function. Try to change function parameters.');
                    }
                ).then(
                functionDataSource => {
                    setOpenConfigurator(false);
                    setDropDownOpened(false);
                    setSelectedFunction(null);
                    onApplyFunction({...functionDataSource, args: selectedFunction.args});
                }
            ).finally(
                () => {
                    setFetching(false);
                }
            );
    };

    return (<>
        <div className={'dropdown-container function-list' + (isDropDownOpened ? ' open' : '')}>
            <button className={'btn svg-btn'}
                    title={'Transformation Functions'}
                    onClick={() => {
                setDropDownOpened(!isDropDownOpened);
                setSelectedFunction(false);
            }}>
                <span style={{fontStyle: 'italic', fontSize: '1.2rem', fontWeight: 'bold', letterSpacing: 0}}>F(x)</span>
            </button>
            <ClickOutside onClick={() => setDropDownOpened(false)}>
                <div className={'dropdown' + (isDropDownOpened ? ' open' : '')}>
                    <ul>
                        {transformationFunctionList.map((func, i) => {
                            if(func.code !== 'HOLT_WINTERS') {
                                return <li key={'trans-' + i}
                                           onClick={() => onSelectFunction({
                                               ...func,
                                               functionCode: func.code,
                                               sourceType: 'TRANSFORMATION_FUNCTION'
                                           })}>
                                    <span>{t('functionName.' + func.name)}</span>
                                </li>;
                            }
                        })}

                    </ul>
                </div>
            </ClickOutside>
            <Modal onCancel={() => {
                setOpenConfigurator(false);
                setSelectedFunction(null);
            }} footer={false} isClosable={false}
                   isOpen={isOpenConfigurator}
                   title={localeService.isRussian() ? 'Параметры применения функции' : 'Function Configuration'}>
                {selectedFunction && !error && !isFetching &&
                <form>
                    <Select label={localeService.isRussian() ? 'Источник данных' : 'Data Source'}
                            valueList={dataSources.map(ds => {
                                return {id: ds.id, name: ds.parameters.dataSourceName};
                            })}
                            onSelect={(ds) => setSelectedFunction({
                                ...selectedFunction,
                                parameters: {...selectedFunction.parameters, dataSource: ds}
                            })}
                            defaultValue={dataSources.length === 1 ? {
                                id: dataSources[0].id,
                                name: dataSources[0].parameters.dataSourceName
                            } : null}/>
                    <div className={'row'} style={{padding: 0, display: 'flex', width: '100%', position: 'relative'}}>
                        <InputText label={t('name')}
                                   value={selectedFunction.parameters.dataSourceName}
                                   onChange={text => setSelectedFunction({
                                       ...selectedFunction,
                                       parameters: {...selectedFunction.parameters, dataSourceName: text}
                                   })}/>
                        <ColorPicker style={{margin: '0 1rem'}} color={selectedFunction.parameters.color}
                                     setColor={(color) =>
                                         setSelectedFunction({
                                             ...selectedFunction,
                                             parameters: {...selectedFunction.parameters, color: color}
                                         })}/>
                    </div>

                    {selectedFunction.sourceType === 'TRANSFORMATION_FUNCTION' &&
                    <div style={{marginTop: '20px'}}>{
                        selectedFunction.functionParams.map((param, i) => {
                            return (<InputText key={'funcParam' + i}
                                               label={(localeService.isRussian() ? 'Параметр ' : 'Parameter ') + (i + 1)}
                                               // isDecimal={true}
                                               value={param}
                                               onChange={(text) => {
                                                   let params = selectedFunction.functionParams;
                                                   params[i] = text;
                                                   setSelectedFunction({
                                                       ...selectedFunction,
                                                       functionParams: params
                                                   });
                                               }}
                            />);
                        })
                    }
                    </div>
                    }

                    <hr/>
                    <div className={'d-flex justify-content-center'}>
                        <button type="button" className={'btn eco-btn success'} onClick={(e) => {
                            e.preventDefault();
                            if (selectedFunction.parameters.dataSource === null) {
                                setError(localeService.isRussian() ? 'Выберите источник данных, к которому необходимо применить функцию' : 'Select Data Source');
                            } else {
                                // setOpenConfigurator(false);
                                onSubmitFunction();
                            }
                        }}>{t('apply')}</button>
                        <button className={'btn eco-btn danger'} onClick={() => {
                            setOpenConfigurator(false);
                            setSelectedFunction(null);
                        }}>{t('cancel')}</button>
                    </div>
                </form>
                }

                {isFetching &&
                <Loader waitText={localeService.isRussian() ? 'Загрузка функции...' : 'Loading function...'}/>}

                <AlertError isOpen={error !== null} onCancel={() => setError(null)} message={error}/>


            </Modal>
        </div>
    </>);
}

AddFunctionToChart.propTypes = {
    transformationFunctionList: PropTypes.array,
    analyticFunctionList: PropTypes.array,
    onApplyFunction: PropTypes.func,
    dataSources: PropTypes.array,
    datetimeFilter: PropTypes.object,
    widgetWidth: PropTypes.number
};


const mapStateToProps = state => {
    const transformationFunctionList = state.analyticReducer.transformationFunctionList;
    const analyticFunctionList = state.analyticReducer.analyticFunctionList;
    const datetimeFilter = state.dashboardReducer.datetimeFilter;
    return {transformationFunctionList, analyticFunctionList, datetimeFilter};
};


export default connect(mapStateToProps, null)(AddFunctionToChart);