import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Loader from '../../components/Loader';
import {localeService} from '../../services/locale.service';
import {dashboardService} from '../../services';
import DashboardFilterPanel from './DashboardFilterPanel';
import WidgetsGrid from './WidgetsGrid';
import {SizeMe} from 'react-sizeme';
import {analyticService} from '../../services/analyticService';
import {uploadFunctionList} from '../../redux/actions/analyticsActions';
import {connect} from 'react-redux';
import {clearDashboard, setEditedWidgets} from '../../redux/actions/dashboardActions';
import AlertError from '../../components/AlertError';
import RenameDashboardModal from './RenameDashboardModal';

function Dashboard({dashboardId, uploadFunctionList, setEditedWidgets}) {

    const [dashboard, setDashboard] = useState(null);
    const [isFetchingDashboard, setFetchingDashboard] = useState(false);
    const [widgetList, setWidgetList] = useState([]);
    const [isFetchingWidgetList, setFetchingWidgetList] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        analyticService.getFunctionList().then(
            functionList => {
                uploadFunctionList(functionList);
            }
        );
    }, []);

    useEffect(() => {
        setError('');
        setFetchingDashboard(true);
        setDashboard(null);
        setWidgetList([]);
        clearDashboard();
        dashboardService.getDashboard(dashboardId).then(
            response => {
                setDashboard(response);
                setFetchingDashboard(false);
                setFetchingWidgetList(true);
                dashboardService.getDashboardWidgetList(dashboardId)
                    .then(
                        widgetList => {
                            setWidgetList(widgetList);

                            //добавляем список виджетов в redux
                            setEditedWidgets(widgetList);

                            setFetchingWidgetList(false);
                        }
                    );
            }, () => {
                setFetchingDashboard(false);
                setError(localeService.isRussian() ? 'Не удалось загрузить дашборд. Данный дашборд либо не существует, либо у Вас нет прав на его просмотр.' : 'Cannot load Dashboard');
            }
        );
    }, [dashboardId]);

    return (
        <SizeMe>{({size}) =>
            <>
                <div className={'page-header dashboard-header'}>
                    {dashboard && <div className={'d-flex justify-content-between'}>
                        <div style={{display: 'flex'}}>
                            <h4 style={{margin: '0 1rem 0 0'}}>{dashboard.name}</h4>
                            <RenameDashboardModal dashboard={dashboard} onRename={(newName) => setDashboard({...dashboard, name: newName})}/>
                        </div>

                        <DashboardFilterPanel dashboardId={dashboardId} dashboard={dashboard}/>
                        </div>}
                </div>
            <div className={'dashboard-container'}>
                {isFetchingDashboard &&
                <Loader waitText={localeService.isRussian() ? 'Загрузка дашборда...' : 'Loading dashboard...'}/>}
                <AlertError isOpen={error != ''} isClosable={false} message={error}/>
                {isFetchingWidgetList &&
                <Loader
                    waitText={localeService.isRussian() ? 'Загрузка списка виджетов...' : 'Loading Widget List...'}/>}
                <WidgetsGrid widgetList={widgetList} size={{...size, width: size.width - 16}}/>
            </div>
                </>
        }</SizeMe>
    );
}

Dashboard.propTypes = {
    dashboardId: PropTypes.number,
    uploadFunctionList: PropTypes.func,
    setEditedWidgets: PropTypes.func
};

const mapDispatchToProps = {
    uploadFunctionList: uploadFunctionList,
    setEditedWidgets: setEditedWidgets
};
export default connect(null, mapDispatchToProps)(Dashboard);