import React from 'react';
import PropTypes from 'prop-types';
import {localeService} from '../../../services/locale.service';
import {dashboardConstants} from '../../../constants';
import Modal from '../../../components/Modal';
// import {YMaps, Map, Placemark} from 'react-yandex-maps';
import MapWidgetCreate from './MapWidgetCreate';
import MapWidgetView from './MapWidgetView';
import {withSize} from 'react-sizeme';

function MapWidget({widgetProps, mode, onCloseEditSingleMode, size}) {
    // const mapState = {
    //     center: [55.751574, 37.573856],
    //     zoom: 9,
    // };

    return (<div>
        {mode === dashboardConstants.VIEW_MODE &&
        <MapWidgetView widgetProps={widgetProps} size={size}/>
        }
        {mode === dashboardConstants.EDITION_MODE &&
            <div className={'ymaps-logo'}
                 style={
                     {
                         width: size.width,
                         height: (widgetProps.h * 100)
                     }
                 }
            />}
        {/*<YMaps>*/}
            {/*<Map*/}
                {/*style={*/}
                    {/*{*/}
                        {/*width: '100%',*/}
                        {/*height: '100%',*/}
                        {/*top: '-12px',*/}
                        {/*left: '-12px',*/}
                        {/*border: '12px solid lightgrey',*/}
                        {/*position: 'absolute'*/}
                    {/*}*/}
                {/*}*/}
                {/*state={mapState}>*/}
                {/*<Placemark*/}
                    {/*geometry={[55.684758, 37.738521]}*/}
                    {/*options={{*/}
                        {/*iconColor: '#B71C1C',*/}
                    {/*}}*/}
                {/*/>*/}
            {/*</Map>*/}
        {/*</YMaps>*/}
        {mode === dashboardConstants.EDIT_SINGLE_MODE &&
        <Modal isOpen={mode === dashboardConstants.EDIT_SINGLE_MODE} isClosable={false} footer={false} onCancel={() => onCloseEditSingleMode()} title={localeService.isRussian() ? 'Настройка виджета' : 'Edit Widget'}>
        <MapWidgetCreate widgetProps={widgetProps} onClose = {() => onCloseEditSingleMode()}/>
        </Modal>
        }
    </div>);
}

MapWidget.propTypes = {
    widgetProps: PropTypes.object,
    onCloseEditSingleMode: PropTypes.func,
    size: PropTypes.object,
    mode: PropTypes.oneOf([dashboardConstants.EDIT_SINGLE_MODE, dashboardConstants.EDITION_MODE, dashboardConstants.VIEW_MODE]),
    sizeParams: PropTypes.object
};

export default withSize()(MapWidget);