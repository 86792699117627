import React from 'react';
import PropTypes from 'prop-types';
import {localeService} from '../../../../services/locale.service';
import Modal from '../../../../components/Modal';
import Select from '../../../../components/Select';
import InputText from '../../../../components/InputText';
import SelectDate from '../../../../components/SelectDate';
import {useTranslation} from 'react-i18next';

function AddFieldToPassport({onAddField}) {
  const {t} = useTranslation();
  const [field, setField] = React.useState(null);

  const createField = () => {
    setField({
      type: '',
      name: '',
      unit: '',
      value: ''
    });
  };

  const addField = () => {
    onAddField({
      order: 0,
      type: field.type,
      column_name: field.name + (field.unit ? ', ' + field.unit : ''),
      value: field.type === 'INTEGER' || field.type === 'BIG_DECIMAL' ? parseFloat(field.value) : field.value
    });
    setField(null);
  };

  return (
    <>
      <button type={'button'} className={'btn eco-btn default mb-3'}
              onClick={() => createField()}>
        {localeService.isRussian() ? 'Добавить поле' : 'Add Field'}
      </button>
      <Modal onCancel={() => setField(null)} isClosable={false} isOpen={field !== null} width={'700px'}
             title={localeService.isRussian() ? 'Добавление поля в паспорт' : 'Add new field'} onSubmit={() => addField()}>
        {field && <form>
          <Select
            style={{marginBottom: '0', minWidth: '18rem'}}
            valueList={[
              {
                value: 'BIG_DECIMAL',
                name: localeService.isRussian() ? 'Десятичное число' : 'Decimal Number'
              },
              {
                value: 'INTEGER',
                name: localeService.isRussian() ? 'Целое число' : 'Integer Number'
              },
              {
                value: 'STRING',
                name: localeService.isRussian() ? 'Строка' : 'String'
              },
              {
                value: 'DATE',
                name: localeService.isRussian() ? 'Дата' : 'Date'
              },
            ]}
            label={t('type')}
            placeholder={localeService.isRussian() ? 'Выберите тип поля' : 'Choose field type'}
            onSelect={el => setField({...field, type: el.value})}/>
          <InputText
            className={'passport-constructor-field'}
            label={t('passport.field_name')}
            value={field.name}
            onChange={text => setField({...field, name: text})}/>

          {field.type !== 'DATE' &&
          <InputText
            className={'passport-constructor-field'}
            label={t('unit')}
            value={field.unit}
            onChange={text => setField({...field, unit: text})}/>}

          {field.type === 'DATE' &&
          <SelectDate selectedDate={field.value === '' ? new Date() : new Date(field.value)}
                      onChange={date => setField({...field, value: date.format('YYYY/MM/DD')})}/>}

          {field.type !== 'DATE' &&
          <InputText
            className={'passport-constructor-field'}
            label={t('value')}
            value={field.value + ''}
            isDecimal={field.type === 'BIG_DECIMAL'}
            isInteger={field.type === 'INTEGER'}
            onChange={text => setField({...field, value: text})}/>}
        </form>}
      </Modal>

    </>
  );
}

AddFieldToPassport.propTypes = {
  onAddField: PropTypes.func
};

export default AddFieldToPassport;