import {localeService} from '../../../services/locale.service';
import EditWidgetDataSource from '../EditWidgetDataSource';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {updateWidget} from '../../../redux/actions/dashboardActions';
import {dashboardConstants} from '../../../constants';
import InputText from '../../../components/InputText';
import AddFunctionToDatasource from '../AddFunctionToDatasource';

function MapWidgetCreate({widgetProps, updateWidget, dashboardDataSources}) {
    const [widget, setWidget] = useState(widgetProps);
    const {t} = useTranslation();

    const createDataSource = () => {
        const dataSources = [...widget.dataSources, {idx: 'new', parameters: {}}];
        setWidget({...widget, dataSources: dataSources});
    };

    const deleteDataSource = (idx) => {
        let dsList = widget.dataSources;
        dsList.splice(idx, 1);
        if(idx>=0){
            setWidget({...widget, dataSources: dsList});
        }

    };

    return (
    <div title = {localeService.isRussian() ? 'Настройка виджета' : 'Edit Widget'}>
        <form>
            <InputText label={t('name')} value={widget.name}
                       onChange={(text) => setWidget({...widget, name: text})}/>

            <div className={'row'}>
                <p className={'help-text'}>
                    <b>{localeService.isRussian() ? 'Настройка источников данных' : 'Data Sources Configuration'}</b>
                </p>
            </div>

            {widget.dataSources.length > 0 && widget.dataSources.map((dataSource, key) => {
                return <EditWidgetDataSource key={key} dataSourceNumber={key + 1} widgetDataSource={dataSource}
                                             onChangeDataSource={(ds) => {
                                                 widget.dataSources[key] = ds;
                                                 setWidget(widget);
                                             }}
                                             onDeleteDataSource={() => {
                                                 deleteDataSource(key);
                                                 // setWidget({...widget, dataSources: widget.dataSources.filter(el => el.idx !== ds.idx)});
                                             }}
                />;
            })}

            {widget.dataSources.length > 0 &&
            <AddFunctionToDatasource withDemand={false} influxOnly={true} appliedFunction={widget.parameters && widget.parameters.function ? {
                ...widget.parameters.function,
                name: widget.parameters.function.functionName
            } : null}
                                     onApplyFunction={(appliedFunction) => {
                                         setWidget({
                                             ...widget,
                                             parameters: {
                                                 ...widget.parameters,
                                                 function: appliedFunction
                                             }
                                         });
                                     }}/>
            }

        <div className={'d-flex justify-content-center'}>
            {/*{() => createDataSource()}*/}
            {dashboardDataSources.length > 0 &&
            <button type={'button'} className={'btn eco-btn default'}
                    onClick={() => createDataSource()}>
                {localeService.isRussian() ? 'Добавить источник данных' : 'Add Data Source'}
            </button>
            }
            <button type={'button'} className={'btn eco-btn success'}
                    onClick={() => {

                        updateWidget({...widget, mode: dashboardConstants.EDITION_MODE});
                    }}>
                {localeService.isRussian() ? 'Сохранить' : 'Save'}
            </button>
            <button type={'button'} className={'btn eco-btn danger'}
                    onClick={() => {
                        updateWidget({...widgetProps, mode: dashboardConstants.EDITION_MODE});
                    }}>
                {t('cancel')}
            </button>
        </div>
        </form>
    </div>);
}

MapWidgetCreate.propTypes = {
    widgetProps: PropTypes.object,
    dashboardDataSources: PropTypes.array,
    updateWidget: PropTypes.func,
};

const mapStateToProps = state => {
    const dashboardDataSources = state.dashboardReducer.dataSourceList;
    return {dashboardDataSources};
};
const mapDispatchToProps = {
    updateWidget: updateWidget
};
export default connect(mapStateToProps, mapDispatchToProps)(MapWidgetCreate);