import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {withSize} from 'react-sizeme';
import {connect} from 'react-redux';
import {dashboardConstants} from '../../../constants';
import Loader from '../../../components/Loader';
import {capacityService} from '../../../services/capacity.service';
import {localeService} from '../../../services/locale.service';
import {dashboardService} from '../../../services';
import moment from 'moment';
import {ComposedChart, XAxis, YAxis, Line, Tooltip,CartesianGrid, ReferenceArea} from 'recharts';
import numeral from 'numeral';
import CustomTooltip from '../../../components/CustomTooltip';
import '../../../styles/electricity-storage.scss';
import AddAdditionalParamsToCostCalculationWidget from '../../../components/AddAdditionalParamsToCostCalculationWidget';
import {updateWidget} from '../../../redux/actions/dashboardActions';
import {capacityCostCalculationService} from '../../../services/capacityCostCalculation.service';
import Select from '../../../components/Select';

function ElectricityStorageView({widgetProps, analyticFunctionList, size, datetimeFilter}) {

    const [isUpdating, setUpdating] = useState(false);
    const [storage, setStorage] = useState([]);
    const [hourSO, setHourSO] = useState([]);
    const [peakHour, setPeakHour] = useState([]);
    const [dataArray, setDataArray] = useState([]);
    const [widgetData, setWidgetData] = useState(widgetProps);
    const [backlights, setBacklights] = useState([]);
    const [range, setRange] = useState([]);
    const [capacityCostMethods, setCapacityCostMethods] = React.useState([]);
    const [isFetching, setFetching] = React.useState(false);
    const [timeCharge, setTimeCharge] = useState([]);
    const electricityCostFunction = analyticFunctionList.filter(el => el.name === 'electricity-cost-calculation');
    const [mode,setMode] = useState('manual');

    const getBackColor = (hours) => {
        var hoursList = [];
        var values = [];
        hours.map((h) => {
            values.push(h.value);
            if (h.value === 1){
            var area = {
                color: '#ff9a1f',
                x1: h.time_upload,
                x2: h.time_upload + 3600,
            };
            hoursList.push(area);
            }          
        });
        let k = 0;
        let labels = [];
        for (let i=k;i<values.length-1;i++)
        {
            if (values[i] === 1 && values[i+1] === 0) labels.push('c '+ i+':00 до '+(i+1)+':00;\n');
            else if (values[i] === 1 && values[i+1] === 1){
                var arr = values.slice(i);
                let ind = arr.indexOf(0)+i;
                labels.push('c '+i+':00 до '+ (ind) +':00;\n');
                i=ind;
            }
        }
        setRange(labels);
        return hoursList;
    };

    const findPeak = (time) => {
        var peakArea = {
            color: 'url(#red)',
            x1: time,
            x2: time + 3600,
        };
        return peakArea;
    };

    const findHour = (list) =>{
        let hour='-';
        list.map((el)=>{
            if (el.value == 1) {
                hour=el.time_upload;                
            }
        });
        if (hour!='-') setPeakHour(findPeak(hour));
        else setPeakHour(-1);
    };

    // const findDischarge = (ds, hour, storage) =>{
    //     var dsDischarge = [];
    //     ds.map((item)=>{
    //         if (item.time_upload === hour.x1)
    //         {
    //             var newItem ={
    //             value: item.value - storage,
    //             time_upload: item.time_upload
    //             };            
    //             dsDischarge.push(newItem);           
    //         }
    //         else dsDischarge.push(item);
    //     });
    //     return dsDischarge;
    // };

    const setParams = () => {
    let additionalParams = [];
    electricityCostFunction[0].args.map(arg => {
        if (widgetProps.parameters.method.name.startsWith('one_category')) {
            if (['tariff_one_ee', 'tariff_losses_three'].includes(arg.name)) {
                additionalParams.push({
                    ...arg, values: Array.from(Array(arg.count), () => {
                        return '';
                    })
                });
            }
        }
        if (widgetProps.parameters.method.name.startsWith('two_category_three_zones')) {
            if (['tariff_two_three_zones_ee', 'time_two_cat_night_zones', 'time_two_cat_peak_zones', 'tariff_losses_three'].includes(arg.name)) {
                additionalParams.push({
                    ...arg, values: Array.from(Array(arg.count), () => {
                        return '';
                    })
                });
            }
        }
        if (widgetProps.parameters.method.name.startsWith('two_category_two_zones')) {
            if (['tariff_two_two_zones_ee', 'time_two_cat_night_zones', 'tariff_losses_three'].includes(arg.name)) {
                additionalParams.push({
                    ...arg, values: Array.from(Array(arg.count), () => {
                        return '';
                    })
                });
            }
        }
        if (widgetProps.parameters.method.name.startsWith('three_category')) {            
            if (['tariff_losses_three', 'tariff_sales', 'actual_volume_other_service', 'volume_other_service'].includes(arg.name)) {
                additionalParams.push({
                    ...arg, values: Array.from(Array(arg.count), () => {
                        return '';
                    })
                });
            }
        }
        if (widgetProps.parameters.method.name.startsWith('four_category')) {
            if (['time_four_cat', 'volume_other_service', 'actual_volume_other_service', 'tariff_sales', 'tariff_maintenance_four', 'tariff_losses_four'].includes(arg.name)) {
                additionalParams.push({
                    ...arg, values: Array.from(Array(arg.count), () => {
                        return '';
                    })
                });
            }
        } 
        if (mode=='manual') 
        {
            if (['time_charging_energy_storage', 'time_return_energy_storage'].includes(arg.name)) {
                additionalParams.push({
                    ...arg, values: Array.from(Array(arg.count), () => {
                        return '';
                    })
                });
        } 
        }   
        if (['capacity_energy_storage','power_return_energy_storage', 'power_charging_energy_storage'].includes(arg.name)) {
                additionalParams.push({
                    ...arg, values: Array.from(Array(arg.count), () => {
                        return '';
                    })
                });
        }   
    });
    return additionalParams;
    //setAdditionalParameters(additionalParams);
    };

    const submitFunction = (additionalArgs) => {
        if (electricityCostFunction.length === 1) {
            setFetching(true);
            let sendArgs = additionalArgs;
            let modeArg = {
                name: 'mode_energy_storage',
                count: 1,
                type: 'SELECT',
                values:  [mode],
            };
            sendArgs.push(modeArg);
            capacityCostCalculationService.runCalculate(capacityCostMethods, sendArgs, widgetProps, datetimeFilter).then(
                response => {
                    console.log('response', response, size);
                    setCapacityCostMethods(response);

                    //добавляем настройки виджета в redux в предустановки
                    updateWidget({...widgetProps, parameters: {...widgetProps.parameters, presets: {additionalArgs: additionalArgs} }});
                }
            ).finally(() => setFetching(false));
        }
    };

    /**
     *Обновление данных по изменению @datetimeFilter
     * */
    useEffect((() => {
        console.log('ElectricityStorage Props', widgetProps);
        if(widgetProps.dataSources.length > 0) {
            let method = widgetProps.parameters.method && widgetProps.parameters.method.name;
            if (method) {
                setUpdating(true);

                setCapacityCostMethods(capacityCostCalculationService.initMethods(method.substring(0, method.length - 1) + 'energy_storage_effect_*', 'STORAGE_EFFECT'));
                setParams();
                widgetProps.dataSources.map((dataSource) => {
                    if (dataSource.parameters.type === 'PEAK_HOURS_DATASOURCE') {
                        dashboardService.getDashboardWidgetDataSourceCapacityProfile(dataSource.id, datetimeFilter.startDate, datetimeFilter.finishDate)
                            .then(pickHours => {
                                setHourSO(pickHours.list);
                                setBacklights(getBackColor(pickHours.list));

                            });
                    }
                    if (dataSource.parameters.type === 'MAIN_DATASOURCE') {
                        dashboardService.getDataSourceDataWithTimeGrouped(dataSource.id, datetimeFilter.startDate, datetimeFilter.finishDate, '1h')
                            .then(result => {
                                    setDataArray(result.list);
                                }
                            );
                    }
                });
                console.log('peaks', hourSO);
                let methodList = [{
                    name: 'peak_hours',
                    methodName: 'peak_hours',
                    description: 'Часы пиковой нагрузки',
                }];
                let args = [{
                    name: 'start_peaks_date',
                    count: 1,
                    values: [datetimeFilter.startDate.format('YYYY-MM-DD HH:mm:ss')],
                }];
                capacityCostCalculationService.getPeakHour(methodList, args, widgetProps, datetimeFilter).then(
                    response => {
                        console.log('ElectricityStorage Response', response);
                        if (response && response[0].value && response[0].value.length > 1) findHour(response[0].value);
                    }
                );

                var ds = [];
                ds.push(widgetProps.dataSources[0]);
                dashboardService.loadWidgetDataTimeGrouped(ds, datetimeFilter, size.width).then(
                    dataSources => {
                        setWidgetData({...widgetData, dataSources: dataSources});
                    }
                );
                if (widgetProps.parameters.capacity) {
                    capacityService.getCapacity(widgetProps.parameters.capacity.id).then(
                        loadedCapacity => {
                            var elecStorage = typeof(loadedCapacity.passportStorage) === 'undefined' ? 500 : loadedCapacity.passportStorage.powerStorage;
                            var time = typeof(loadedCapacity.passportStorage) === 'undefined' ? 5 : loadedCapacity.passportStorage.timeFullCharge;
                            setStorage(elecStorage);
                            setTimeCharge(time);
                        });
                }
                setUpdating(false);
            }
        }

    }), [datetimeFilter]);


    return <>
        {isUpdating && <Loader waitText={localeService.isRussian() ? 'Загрузка данных...' : 'Uploading data...'}/>}        
        {!isUpdating && dataArray.length > 0 &&
        <div>
        <div className={'left'}>
        <ComposedChart
                width={0.8*size.width}
                height={(100 * widgetProps.h) - 30}>

                <CartesianGrid strokeDasharray="3 3"/>

                <XAxis
                     dataKey="time_upload"
                     tickFormatter={(number) => {
                         return moment.unix(number).utc().format(moment.duration(datetimeFilter.finishDate.diff(datetimeFilter.startDate)).asHours() > 24 ? 'DD/MM HH:mm' : 'HH:mm:ss');
                     }}
                     allowDataOverflow={true}
                     type="number"
                     allowDecimals={false}
                     // allowDuplicatedCategory={false}
                     scale={'utc'}
                     domain={[moment(datetimeFilter.startDate).unix().valueOf(), moment(datetimeFilter.finishDate).unix().valueOf()]}
                 />

                <YAxis dataKey="value"
                       type="number" 
                       //domain={['auto', 'auto']}
                       tickFormatter={(value) => {
                           return numeral(value).format('0.0 a');
                       }}
                />
                 <Tooltip
                    content={(e) => e.active &&
                        <CustomTooltip label={e.label} widgetData={widgetData}
                                       functionDataSources={[]}/>}
                    cursor={{stroke: '@default-color', strokeWidth: 2, opacity: '0.7'}}/>
                <Line              
                        type={'stepAfter'}     
                            dataKey="value"
                            data={dataArray}                           
                            strokeWidth={'3px'}
                            name={'electricity-storage'}
                            key={'lineChart'}
                            background={{fill: '#eee'}}
                            dot={false}
                            activeDot={false}
                            animationDuration={0}
                        />
                {/* <Line              
                        type={'stepAfter'}     
                            dataKey="value"
                            data={findDischarge(dataArray, peakHour, storage)}                           
                            strokeWidth={'1px'}
                            name={'with-storage'}
                            stroke="#ce12ce"
                            key={'line'}
                            background={{fill: '#eee'}}
                            dot={false}
                            activeDot={false}
                            animationDuration={0}
                        /> */}
                {/*Заливка для подсветки функций*/}
                <defs>
                    <linearGradient id="red" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="yellow" stopOpacity={0}/>
                        <stop offset="95%" stopColor="yellow" stopOpacity={0.8}/>
                    </linearGradient>
                </defs>
                {backlights && backlights.map((backlight,i) => {                                   
                    return <ReferenceArea key={'back-'+i} fill={backlight.color} x1={backlight.x1} x2={backlight.x2}/>;
                })}
                {peakHour && 
                <ReferenceArea key={'peakH'} fill={peakHour.color} x1={peakHour.x1} x2={peakHour.x2}
                />}
                {peakHour && storage && dataArray.length > new Date(peakHour.x1 * 1000).getUTCHours() &&
                <ReferenceArea key={'dis'} fill={'#008000'} x1={peakHour.x1} x2={peakHour.x2}
                 y1={dataArray[(new Date(peakHour.x1 * 1000).getUTCHours())-1].value} y2={dataArray[new Date(peakHour.x1 * 1000).getUTCHours()-1].value-storage}
                />}
                {timeCharge &&                
                    <ReferenceArea key={'charge'} 
                    fill={'#9932CC'}
                    x1={dataArray[0].time_upload}
                    x2={dataArray[0].time_upload+(3600*timeCharge)}
                    y1={0.00}
                    y2={500.00}
                    />
                }
                
        </ComposedChart>
        </div>
        <div className={'right'}>       
        <p className='main-label'>Сводная информация</p>       
        <label className='SO-font'> </label>   <label className='h-label'>Часы CO: </label>
        {range}
        <p></p>
        <label className='EL-font'> </label>   <label className='h-label'>Час пиковой нагрузки:</label>
        с {moment.unix(peakHour.x1).utc().format('HH:mm')} до {moment.unix(peakHour.x2).utc().format('HH:mm')}
        <p></p>
        <label className='CH-font'> </label>   <label className='h-label'>Время зарядки СНЭ:</label>
        с {moment.unix(dataArray[0].time_upload).utc().format('HH:mm')} до {moment.unix(dataArray[0].time_upload+(3600*timeCharge)).utc().format('HH:mm')}
        <p>Состояние СНЭ: {storage} кВт*ч </p>
        &nbsp;
        <p className='r-label'>Рекомендация</p>
        Использовать СНЭ: с {moment.unix(peakHour.x1).utc().format('HH:mm')} по {moment.unix(peakHour.x2).utc().format('HH:mm')}
        &nbsp;
        <Select isRequired={true}
            translateName={true}
            value={widgetProps.parameters}
            valueList={[{name:'auto'},{name:'manual'}]}
            onSelect={(el)=>{setMode(el.name);}}
            label={localeService.isRussian() ? 'Режим работы накопителя' : 'Storage mode'}>          
        </Select>
        <p className='r-label'>Экономия</p> 
        <AddAdditionalParamsToCostCalculationWidget funcArgs={setParams()}
                                                    onSubmit={(args) => submitFunction(args)}/>
        {!isFetching && <p>Эл. мощность:  {capacityCostMethods[0].value} руб/месяц</p>}
        {!isFetching && <p>Сетевая мощность:  {capacityCostMethods[1].value} руб/месяц</p>}
        {!isFetching && <p>Итого:  {capacityCostMethods[2].value} руб/месяц</p>}
        
        </div>
        </div>
        }
    </>;
}


ElectricityStorageView.propTypes = {
    widgetProps: PropTypes.object,
    mode: PropTypes.oneOf([dashboardConstants.EDITION_MODE, dashboardConstants.VIEW_MODE]),
    size: PropTypes.object,
    analyticFunctionList: PropTypes.array,
    datetimeFilter: PropTypes.object,
    updateWidget: PropTypes.func
};

const mapStateToProps = state => {
    const datetimeFilter = state.dashboardReducer.datetimeFilter;
    const analyticFunctionList = state.analyticReducer.analyticFunctionList;
    return {datetimeFilter, analyticFunctionList};
};

const mapDispatchToProps = {
    updateWidget: updateWidget
};

export default withSize()(connect(mapStateToProps, mapDispatchToProps, null)(ElectricityStorageView));