import React, {useState} from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {localeService} from '../services/locale.service';
import ClickOutside from './ClickOutside';

function SelectDateMultiple({onSetDateList, label, datetimeFilter, disabled}) {

    const [dateList, setDateList] = useState([]);
    const [isOpen, setOpen] = useState(false);
    return (
        <>
            <div className={'selected-multiple-dates'} style={{
                width: '100%',
                border: '1px solid #ccc',
                borderRadius: '0.5rem',
                display: 'inline-block',
                padding: '1rem',
                boxSizing: 'border-box'
            }}>
                <button className={'btn eco-btn success'} disabled={disabled} onClick={() => setOpen(!isOpen)}>{label}</button>
                <ClickOutside onClick={() => setOpen(false)}>
                <div style={{display: isOpen ? 'block' : 'none',
                    position: 'absolute',
                    top: '0',
                    width: '230px',
                    backgroundColor: 'white',
                    padding: '2rem',
                    border: '1px solid grey',
                    borderRadius: '0.5rem',
                    textAlign: 'center',
                    zIndex:1
                }}>
                            <DatePicker
                                selected={new Date(datetimeFilter.startDate)}
                                inline
                                onChange={(el) => {
                                    const date = moment(el);
                                    let newDateList = dateList;
                                    const idx = dateList.findIndex(el => el.isSame(date, 'day'));
                                    if (idx < 0) {
                                        newDateList = [...dateList, date];
                                        // onSetDateList({action: 'add', date: date, dateList: newDateList});

                                    }/* else {
                                        newDateList = newDateList.slice(idx, -1);
                                        // onSetDateList({action: 'remove', date: date, dateList: newDateList});
                                    }*/
                                    setDateList(newDateList);
                                }}
                                dateFormat="YYYY-MM-DD"
                                highlightDates={dateList.map(el => new Date(el))}
                                minDate={datetimeFilter.startDate}
                                maxDate={datetimeFilter.finishDate}
                            />
                    <button className={'btn eco-btn success'} onClick={() => {
                        onSetDateList(dateList);
                        setOpen(false);
                    }}>
                        {localeService.isRussian() ? 'Применить' : 'Apply'}
                    </button>
                    <button className={'btn eco-btn danger'} onClick={() => setOpen(false)}>{localeService.isRussian() ? 'Отмена' : 'Cancel'}</button>
                </div>
                </ClickOutside>
                {/*{dateList.length === 0 &&*/}
                {/*<p>No selected dates</p>*/}
                {/*}*/}
                {/*{!isOpen && dateList.length > 0 && dateList.map((date, i) => {*/}
                    {/*return <div key={'selectedDate-' + i} style={{*/}
                        {/*float: 'left',*/}
                        {/*margin: '0 8px 4px 0',*/}
                        {/*padding: '0 8px',*/}
                        {/*position: 'relative',*/}
                        {/*border: '1px solid #cccccc',*/}
                        {/*borderRadius: '4px'*/}
                    {/*}}>*/}
                        {/*{date.format('YYYY-MM-DD')}*/}

                        {/*/*<button type={'button'}*/}
                                {/*className={'button icon-btn btn btn-danger'}*/}
                                {/*style={{*/}
                                    {/*position: 'absolute',*/}
                                    {/*backgroundColor: 'white',*/}
                                    {/*top: '-5px',*/}
                                    {/*width: '10px',*/}
                                    {/*height: '10px',*/}
                                    {/*fontSize: '14px'*/}
                                {/*}}*/}
                                {/*onClick={() => {*/}
                                    {/*let newDateList = dateList;*/}
                                    {/*newDateList = newDateList.slice(i, -1);*/}
                                    {/*setDateList(newDateList);*/}
                                    {/*onSetDateList({action: 'remove', date: date, dateList: newDateList});*/}
                                {/*}}>*/}
                            {/*<span className={'glyphicon glyphicon-remove'}/>*/}
                        {/*</button>*/}
                    {/*</div>;*/}
                {/*})}*/}
            </div>
        </>

    );
}

SelectDateMultiple.propTypes = {
    disabled: PropTypes.bool,
    onSetDateList: PropTypes.func,
    label: PropTypes.string,
    datetimeFilter: PropTypes.object
};

export default SelectDateMultiple;
