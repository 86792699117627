import React from 'react';
import PropTypes from 'prop-types';
import '../styles/pagination.scss';

Pagination.propTypes = {
    pageCount: PropTypes.number.isRequired,
    pageNumber: PropTypes.number.isRequired,
    onSelectPage: PropTypes.func.isRequired
};

export function Pagination({pageCount, pageNumber, onSelectPage}) {

    return <div>
        {pageCount > 1 &&
        <ul className="pagination">
            {pageNumber !== 0 &&
            <li>
                <button type={'btn'}
                        onClick={() => onSelectPage(pageNumber - 1)}
                        className={'btn eco-btn default'}>
                    <span aria-hidden="true">&laquo;</span>
                </button>
            </li>
            }
            {
                [...Array(pageCount + 1).keys()].map((i) => {
                    return (
                        <li key={'devtr' + i}>
                            <button type={'btn'}
                                    onClick={() => onSelectPage(i)}
                                    className={'btn eco-btn default' + (pageNumber === i ? ' active' : '')}>
                                <span aria-hidden="true">{i + 1}</span>
                            </button>
                        </li>
                    );
                })
            }
            {(pageNumber < pageCount) &&
            <li>
                <button type={'btn'}
                        onClick={() => onSelectPage(pageNumber + 1)}
                        className={'btn eco-btn default'}>
                    <span aria-hidden="true">&raquo;</span>
                </button>
            </li>
            }
        </ul>
        }
    </div>;
}



