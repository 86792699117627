import React, {useState} from 'react';
import PropTypes from 'prop-types';
import EditWidgetDataSource from '../EditWidgetDataSource';
import {localeService} from '../../../services/locale.service';
import {updateWidget} from '../../../redux/actions/dashboardActions';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import InputText from '../../../components/InputText';
import {dashboardConstants} from '../../../constants';

function WidgetChartCreate({widgetProps, updateWidget, dashboardDataSources}) {
    const [widget, setWidget] = useState(widgetProps);
    const {t} = useTranslation();

    const createDataSource = () => {
        const dataSources = [...widget.dataSources, {idx: 'new', parameters: {}}];
        setWidget({...widget, dataSources: dataSources});
    };

    const deleteDataSource = (idx) => {
        let dsList = widget.dataSources;
        dsList.splice(idx, 1);
        if(idx>=0){
            setWidget({...widget, dataSources: dsList});
        }

    };

    return (
        <>
            <form>
                <InputText label={t('name')} value={widget.name}
                           onChange={(text) => setWidget({...widget, name: text})}/>

                <div className={'row'}>
                    <p className={'help-text'}>
                        <b>{localeService.isRussian() ? 'Настройка источников данных' : 'Data Sources Configuration'}</b>
                    </p>
                </div>

                {widget.dataSources.length > 0 && widget.dataSources.map((dataSource, key) => {
                    return <EditWidgetDataSource key={key} dataSourceNumber={key + 1} widgetDataSource={dataSource}
                                                 onChangeDataSource={(ds) => {
                                                     widget.dataSources[key] = ds;
                                                     setWidget(widget);
                                                 }}
                                                 onDeleteDataSource={() => {
                                                     deleteDataSource(key);
                                                     // setWidget({...widget, dataSources: widget.dataSources.filter(el => el.idx !== ds.idx)});
                                                 }}
                    />;
                })}

                <div className={'d-flex justify-content-center'}>
                    {dashboardDataSources.length > 0 &&
                    <button type={'button'} className={'btn eco-btn default'}
                            onClick={() => createDataSource()}>
                        {localeService.isRussian() ? 'Добавить источник данных' : 'Add Data Source'}
                    </button>
                    }
                    <button type={'button'} className={'btn eco-btn success'}
                            onClick={() => {
                                updateWidget({...widget, mode: dashboardConstants.EDITION_MODE});
                            }}>
                        {localeService.isRussian() ? 'Сохранить' : 'Save'}
                    </button>
                    <button type={'button'} className={'btn eco-btn danger'}
                            onClick={() => {
                                updateWidget({...widgetProps, mode: dashboardConstants.EDITION_MODE});
                            }}>
                        {t('cancel')}
                    </button>
                </div>
            </form>
        </>
    );
}

WidgetChartCreate.propTypes = {
    widgetProps: PropTypes.object,
    updateWidget: PropTypes.func,
    dashboardDataSources: PropTypes.array
};

const mapStateToProps = state => {
    const dashboardDataSources = state.dashboardReducer.dataSourceList;
    return {dashboardDataSources};
};

const mapDispatchToProps = {
    updateWidget: updateWidget
};
export default connect(mapStateToProps, mapDispatchToProps)(WidgetChartCreate);