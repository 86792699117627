import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Select from '../../components/Select';
import {localeService} from '../../services/locale.service';
import {connect} from 'react-redux';
import InputText from '../../components/InputText';
import {ReactSVG} from 'react-svg';
import svgDelete from '../../sources/images/interface/basket.svg';
import ColorPicker from '../../components/ColorPicker';
import {dashboardService} from '../../services';
import {useTranslation} from 'react-i18next';

function EditWidgetDataSource({
                                dataSourceNumber, widgetDataSource, onChangeDataSource, dataSourceList, onDeleteDataSource,
                                withColor, withUnit, removable, withAdditionalDataSources, isCollapsed, moveUp, moveDown
                              }) {

  const {t} = useTranslation();
  const initNewDS = {
    idx: new Date().getTime(),
    parameters: {
      dataSourceName: '',
      color: dashboardService.getRandomColor()
    }
  };

  const [dataSource, setDataSource] = useState(widgetDataSource);
  const [showAdditionalDS, setShowAdditionalDS] = useState(false);

  useEffect(() => {
    if (widgetDataSource.idx === 'new') {
      onChangeDataSource(initNewDS);
    }
    setDataSource(widgetDataSource.idx === 'new' ? initNewDS : widgetDataSource);
  }, [widgetDataSource]);

  const onChange = (ds) => {
    setDataSource({...dataSource, ...ds});
    onChangeDataSource({...dataSource, ...ds});
  };

  return (!isCollapsed ?
      <>
        {dataSource.idx !== 'new' &&
        <div className={'group row'}>
          <div className={'d-flex justify-content-between'} style={{marginTop: '-1rem', marginBottom: '1rem'}}>
            <label>{localeService.isRussian() ? 'Источник данных ' : 'Data Source '} {dataSourceNumber}</label>
            {moveUp != undefined && moveDown != undefined &&
            <div className={'d-flex flex-column'}>
              <button className={'btn arrow-btn-small to-up'} style={{height: '20px'}}
                      title={localeService.isRussian() ? 'Поднять наверх ' : 'Move Up '}
                      onClick={(e) => {
                        e.preventDefault();
                        moveUp();
                      }}/>
              <button className={'btn arrow-btn-small to-down'} style={{height: '20px'}}
                      title={localeService.isRussian() ? 'Опустить вниз ' : 'Move Down '}
                      onClick={(e) => {
                        e.preventDefault();
                        moveDown();
                      }}/>
            </div>}
          </div>
          {/*<label className={'group-header'}>{localeService.isRussian() ? 'Источник данных ' : 'Data Source '}*/}
            {/*{dataSourceNumber}*/}
          {/*</label>*/}

          {dataSourceList.length === 0 &&
          <p>{localeService.isRussian() ? 'Добавьте источники данных дашборда на вкладке "Источники данных"' : 'Add Data Sources for Dashbord on the tab "Data Sources"'}</p>}

          <div className={'d-flex justify-content-between'}>
            {dataSourceList.length > 0 &&
            <Select label={localeService.isRussian() ? 'Выберите источник данных' : 'Select Data Source'}
                    type={'datasource_with_info'}
                    valueList={dataSourceList || []}
                    value={dataSourceList.find(el => el.id === (widgetDataSource.sourceID ? Number.parseInt(widgetDataSource.sourceID) : widgetDataSource.id))}
                    withAdditionalName={true}
                    onSelect={(ds) => {
                      let newDS = dataSource;
                      if (newDS.additionalDataSources) {
                        newDS.additionalDataSources.map((ads) => {
                          ads.parameters.parentDataSource = ds.id;
                        });
                      }
                      onChange({
                        ...newDS,
                        sourceID: ds.id,
                        parameters: {...newDS.parameters, dataSourceName: ds.name}
                      });
                    }}/>}
          </div>

          <div className={'d-flex justify-content-between'}>
            <InputText label={localeService.isRussian() ? 'Имя источника данных' : 'Data Source Name'}
                       value={dataSource.parameters.dataSourceName || ''}
                       onChange={text => onChange({
                         ...dataSource,
                         parameters: {...dataSource.parameters, dataSourceName: text}
                       })}/>

            {withUnit && <InputText label={t('unit')}
                                    value={dataSource.parameters.unit || ''}
                                    onChange={text => onChange({
                                      ...dataSource,
                                      parameters: {...dataSource.parameters, unit: text}
                                    })}/>}
            {withColor &&
            <ColorPicker color={dataSource.parameters.color} setColor={(color) =>
              onChange({...dataSource, parameters: {...dataSource.parameters, color: color}})}/>}
          </div>

          {withAdditionalDataSources && <button className={'btn eco-btn default'} onClick={(e) => {
            e.preventDefault();
            setShowAdditionalDS(!showAdditionalDS);
          }}>
            {localeService.isRussian() ? 'Добавить дополнительные источники данных' : 'Add Additional Data Sources'}
          </button>}
          {widgetDataSource.additionalDataSources && showAdditionalDS &&
          <>
            <p><b>{localeService.isRussian() ? 'Дополнительные параметры' : 'Additional Data Sources'}</b></p>
            {dataSource.additionalDataSources.map((ads, i) => {
              return <Select key={'additional-ds-' + i} label={ads.parameters.type}
                             valueList={dataSourceList.map(ds => {
                               return {
                                 ...ds,
                                 name: ds.name + (ds.capacity ? (' (' + ds.capacity.name + ') ') : '')
                               };
                             })}
                             value={ads}
                             onSelect={(ds) => {
                               let additionalDSList = dataSource.additionalDataSources;
                               additionalDSList[i] = {
                                 sourceID: ds.id,
                                 name: ds.name,
                                 parameters: {...ads.parameters, dataSourceName: ds.name}
                               };
                               onChange({
                                 ...dataSource,
                                 additionalDataSources: additionalDSList
                               });
                             }}/>;
            })}
          </>
          }

          {removable &&
          <div className={'d-flex justify-content-end'}>
            <button className={'btn eco-btn danger'}
                    onClick={(e) => {
                      e.preventDefault();
                      onDeleteDataSource(dataSource);
                    }}>
              <ReactSVG
                src={svgDelete}/>
              <span>{localeService.isRussian() ? 'Удалить источник данных' : 'Delete Data Source'}</span>
            </button>
          </div>
          }
        </div>
        }
      </>
      : <>
        <div className={'group row'}>
          <label
            className={'group-header'}>{localeService.isRussian() ? 'Источник данных ' : 'Data Source '}{dataSourceNumber}</label>
          <div className={'d-flex justify-content-between'}>
            <p>{dataSource.parameters.dataSourceName || (localeService.isRussian() ? 'Источник данных не выбран' : 'Data Source is not selected')}</p>

          </div>
          {removable &&
          <div className={'d-flex justify-content-end'}>
            <button className={'btn eco-btn danger'}
                    title={localeService.isRussian() ? 'Удалить источник данных' : 'Delete Data Source'}
                    onClick={(e) => {
                      e.preventDefault();
                      onDeleteDataSource(dataSource);
                    }}>
              <ReactSVG
                src={svgDelete}/>
            </button>
          </div>
          }
        </div>
      </>
  );
}

EditWidgetDataSource.propTypes = {
  widgetDataSource: PropTypes.object,
  onChangeDataSource: PropTypes.func,
  dataSourceList: PropTypes.array,
  dataSourceNumber: PropTypes.number,
  onDeleteDataSource: PropTypes.func,
  withColor: PropTypes.bool,
  withUnit: PropTypes.bool,
  removable: PropTypes.bool,
  withAdditionalDataSources: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  moveUp: PropTypes.func,
  moveDown: PropTypes.func
};

EditWidgetDataSource.defaultProps = {
  withColor: true,
  withUnit: true,
  removable: true,
  withAdditionalDataSources: false,
  isCollapsed: false
};
const mapStateToProps = state => {
  const dataSourceList = state.dashboardReducer.dataSourceList;
  return {dataSourceList};
};
// const mapDispatchToProps = {
//     updateWidget: updateWidget
// };
export default connect(mapStateToProps, null)(EditWidgetDataSource);
