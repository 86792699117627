import {apiService} from './api.service';
import {apiConstants} from '../constants';
import moment from 'moment/moment';
import {localeService} from './locale.service';

function getTransformationsFunctionList() {
    return apiService.fetchGet(apiConstants.GET_TRANSFORMATIONS_FUNCTION_LIST);
}

function getAnalyticsFunctionList() {
    return apiService.fetchGet(apiConstants.GET_ANALITICS_FUNCTION_LIST)
        .then(response => {
            const data = JSON.parse(JSON.parse(response.list));
            let funcList = [];
            Object.keys(data).forEach(function(key) {
                funcList.push(data[key]);
            });

            return {list: funcList
                    .filter(func => (func.analysis_code))
                    .map(func =>{
                        return {name: func.analysis_name, code: func.analysis_code, args: func.parameters, outputsCount: func.outputs_count};
                    })};
        })
        .catch(() => {
            return {list: []};
        });

}

export function getAggregationFunctionList() {
    return apiService.fetchGet(apiConstants.GET_AGGREGATION_FUNCTION_LIST);
}

function getFunctionList() {
    return Promise.all(
        [
            getTransformationsFunctionList().then(
                result => {
                    return result.list;
                }
            ),
            getAggregationFunctionList().then(
                result => {
                    return result.list;
                }
            ),
            getAnalyticsFunctionList().then(
                result => {
                    return result.list;
                }
            )
        ]
    ).then(
        functionList => {
            return {transformationFunctionList: functionList[0], aggregationFunctionList: functionList[1], analyticFunctionList: functionList[2]};
        }
    );
}

function calculateAnalyticFunction(dataSourceId, selectedFunction, datetimeFilter){
    let timeOffset = moment().utcOffset();
    console.log('calculateAnalyticFunction', timeOffset);
    let start = selectedFunction.startDate ? moment(selectedFunction.startDate).subtract(timeOffset, 'minutes') : moment(datetimeFilter.startDate).subtract(timeOffset, 'minutes');//.set('hours', 0).set('minute', 0).set('second', 0);
    const targetDayIdx = selectedFunction.args.findIndex(el => el.name === 'target_day');

    if (targetDayIdx >= 0) {
        const startDay = moment(selectedFunction.args[targetDayIdx].values[0]);
        if (startDay) {
            start = startDay.subtract(45, 'days').subtract(timeOffset, 'minutes');
        }
    }

    const finish = selectedFunction.finishDate ? moment(selectedFunction.finishDate).subtract(timeOffset, 'minutes') : moment(datetimeFilter.finishDate).subtract(timeOffset, 'minutes');//.set('hours', 23).set('minute', 59).set('second', 59);


    const requestBody = {
        filter: {
            dataSourceId: dataSourceId,
            dateFrom: start.format('YYYY-MM-DD HH:mm:ss'),
            dateTo: finish.format('YYYY-MM-DD HH:mm:ss')
        },
        countResult: selectedFunction.outputsCount,
        type: selectedFunction.name,
        args: []
    };

    selectedFunction.args.map(arg => {
        if (arg.values && arg.values.length > 0) {
            requestBody.args.push({
                name: arg.name,
                count: arg.values.length,
                values: arg.values
            });
        }
    });

    return runAnalytic(requestBody)
        .then(response => {
            if(selectedFunction.outputsCount === 1){
                return getAnalyticResult(dataSourceId, response.results[0], start, finish)
                    .then(
                        result => {
                            return result.list;
                        }, () => {
                            return [];
                        }
                    );
            }else{
                return Promise.all(response.results.map(resultId => {
                        return getAnalyticResult(dataSourceId, resultId, start, finish)
                            .then(
                                result => {
                                    return result.list;
                                }, () => {
                                    return [];
                                }
                            );
                    }
                ));
            }
        }, () => {
            return [];
        }
    );
}


function calculateElectricityCost(dataSourceId, selectedFunction, datetimeFilter){
    let start = selectedFunction.startDate ? selectedFunction.startDate : datetimeFilter.startDate.set('hours', 0).set('minute', 0).set('second', 0);
    const finish = datetimeFilter.finishDate.set('hours', 23).set('minute', 59).set('second', 59);


    const requestBody = {
        filter: {
            dataSourceId: dataSourceId,
            dateFrom: start.format('YYYY-MM-DD HH:mm:ss'),
            dateTo: finish.format('YYYY-MM-DD HH:mm:ss')
        },
        type: selectedFunction.name,
        args: []
    };

    selectedFunction.args.map(arg => {
        if (arg.values && arg.values.length > 0) {
            requestBody.args.push({
                name: arg.name,
                count: arg.values.length,
                values: arg.values
            });
        }
    });

    return runAnalytic(requestBody)
        .then(response => {
            // const resultId = response.resultId.replace(/"/g, '');
            const resultId = response.results[0];
                return getAnalyticResult(dataSourceId, resultId, start, finish)
                    .then(
                        result => {
                            return result.list;
                        }, () => {
                            return [];
                        }
                    );
            }, () => {
                return [];
            }
        );
}

function runAnalytic(requestBody) {
    const url = apiConstants.ANALYTIC_RUN;
    return apiService.fetchPost(url, JSON.stringify(requestBody));
}

function getAnalyticResult(dataSourceId, resultId, startDate, finishDate) {
    const body = {
        resultId: resultId,
        dateFrom: startDate.format('YYYY-MM-DD HH:mm:ss'),
        dateTo: finishDate.format('YYYY-MM-DD HH:mm:ss'),
        timeGroup: '1s'
    };
    const url = apiConstants.ANALYTIC_GET_RESULT;
    return apiService.fetchPost(url, JSON.stringify(body));
}

function initAnalyticFunctionParameters(func, datetimeFilter){
    let funcArgs = func && func.args || [];
    funcArgs.map((arg, i) => {
        arg.idx = i;
        if (arg.count > 0) {
            if (arg.type !== 'SELECT') {
                arg.values = Array.from(Array(arg.count), () => {
                    if (arg.type === 'DATE') {
                        return moment(datetimeFilter.finishDate).format('YYYY-MM-DD');
                    }
                    if (arg.type === 'BOOLEAN') {
                        return 'false';
                    }
                    if (arg.type === 'INTEGER' || arg.type === 'FLOAT') {
                        return 0;
                    }
                    return '';
                });
            }
            if (arg.type === 'SELECT') {
                if (!arg.selectValues) {
                    arg.selectValues = [...Array(arg.options.length).keys()].map((_, j) => {
                        return {name: arg.options[j],idx: j};
                    });
                }
                arg.values = Array.from(Array(arg.count), () => {
                    return '';
                });
            }
        } else {
            arg.values = [];
        }
    });
    return funcArgs;
}

function pivotTableAddTargetDay(widgetDataSources, date, datetimeFilter){
    return Promise.all(
        widgetDataSources.map((ds) => {
        if (ds.parameters.type === 'MAIN_DATASOURCE') {
            return Promise.all(ds.dataArray.map((dsData) => {
                if (moment.unix(dsData.date).isSame(date, 'day') && dsData.count > 0) {
                    const start = datetimeFilter.startDate.set('hours', 0).set('minute', 0).set('second', 0);
                    const finish = datetimeFilter.finishDate.set('hours', 23).set('minute', 59).set('second', 59);
                    const funcParams = {
                        args: [
                            {name: 'target_day', count: 1, values: [date.format('YYYY-MM-DD')]},
                            {name: 'exception_days', count: 0, values: []},
                            {name: 'except_weekends', count: 1, values: ['true']},
                            {name: 'discharge_start_hour', count: 1, values: [dsData.startHour]},
                            {name: 'discharge_duration', count: 1, values: [dsData.duration]},
                            {name: 'discharge_value', count: 1, values: [dsData.value]},
                            {name: 'mode', count: 1, values: ['fact']}
                        ],
                        filter: {
                            dataSourceId: ds.id,
                            dateFrom: start.format('YYYY-MM-DD HH:mm:ss'),
                            dateTo: finish.format('YYYY-MM-DD HH:mm:ss')
                        },
                        type: 'DEMAND_RESPONSE_BOOLEAN'
                    };
                    return runAnalytic(funcParams)
                        .then(response => {
                            // const resultId = response.resultId.replace(/"/g, '');
                            const resultId = response.results[0];
                            return getAnalyticResult(ds.id, resultId, start, finish, null)
                                .then(
                                    response2 => {
                                        const results = response2.list;
                                        if (!results || results.length === 0) {
                                            alert(localeService.isRussian() ? 'Нет данных для отображения, попробуйте ввести другие параметры.' : 'No data to display. Try to change function parameters.');
                                            return dsData;
                                        } else {
                                            return {...dsData, boolean: response2.list};
                                        }
                                    }, () => {
                                        return {...dsData, boolean: 'error'};
                                    }
                                );
                        }, () => {
                            return {...dsData, boolean: 'error'};
                        });
                } else {
                    return dsData;
                }
            })).then(
                dataArray => {
                    return {...ds, dataArray: dataArray};
                }
            );
        } else {
            return ds;
        }
    })).then(
        newWidgetDataSources => {
            return newWidgetDataSources;
        }
    );
}

function pivotTableRecalculateTargetDays(widgetDataSources, targetDateList, datetimeFilter){
    return Promise.all(widgetDataSources.map((ds) => {
            if (ds.parameters.type === 'MAIN_DATASOURCE') {
                return Promise.all(ds.dataArray.map((dsData) => {
                    let targetDate = null;
                    targetDateList.map(date => {
                        if(moment.unix(dsData.date).isSame(date, 'day')){
                            targetDate = date;
                        }
                    });

                    if (targetDate && dsData.count > 0) {
                        const start = datetimeFilter.startDate.set('hours', 0).set('minute', 0).set('second', 0);
                        const finish = datetimeFilter.finishDate.set('hours', 23).set('minute', 59).set('second', 59);
                        const funcParams = {
                            args: [
                                {name: 'target_day', count: 1, values: [targetDate.format('YYYY-MM-DD')]},
                                {name: 'exception_days', count: 0, values: []},
                                {name: 'except_weekends', count: 1, values: ['true']},
                                {name: 'discharge_start_hour', count: 1, values: [dsData.startHour]},
                                {name: 'discharge_duration', count: 1, values: [dsData.duration]},
                                {name: 'discharge_value', count: 1, values: [dsData.value]},
                                {name: 'mode', count: 1, values: ['fact']}
                            ],
                            filter: {
                                dataSourceId: ds.id,
                                dateFrom: start.format('YYYY-MM-DD HH:mm:ss'),
                                dateTo: finish.format('YYYY-MM-DD HH:mm:ss')
                            },
                            type: 'DEMAND_RESPONSE_BOOLEAN'
                        };
                        return runAnalytic(funcParams)
                            .then(response => {
                                // const resultId = response.resultId.replace(/"/g, '');
                                const resultId = response.results[0];
                                return getAnalyticResult(ds.id, resultId, start, finish, null)
                                    .then(
                                        response2 => {
                                            const results = response2.list;
                                            console.log('result::', response2);
                                            if (!results || results.length === 0) {
                                                alert(localeService.isRussian() ? 'Нет данных для отображения, попробуйте ввести другие параметры.' : 'No data to display. Try to change function parameters.');
                                                return dsData;
                                            } else {
                                                return {...dsData, boolean: response2.list};
                                            }
                                        }, () => {
                                            return {...dsData, boolean: 'error'};
                                        }
                                    );
                            }, () => {
                                return {...dsData, boolean: 'error'};
                            });
                    } else {
                        return dsData;
                    }
                })).then(
                    dataArray => {
                        return {...ds, dataArray: dataArray};
                    }
                );
            } else {
                return ds;
            }
        })).then(
        newWidgetDataSources => {
            return newWidgetDataSources;
        }
    );
}

function initTransformationFunctionParameters(func){
    if(func.code === 'HOLT_WINTERS'){
        return ['24', '24', '1h'];
    }else{
        return Array.from(Array(func.countParam), () => 0);
    }
}

export const analyticService = {
    calculateElectricityCost,
    pivotTableAddTargetDay,
    pivotTableRecalculateTargetDays,
    initAnalyticFunctionParameters,
    initTransformationFunctionParameters,
    getAnalyticsFunctionList,
    getTransformationsFunctionList,
    getAggregationFunctionList,
    getFunctionList,
    calculateAnalyticFunction
};