import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {dashboardConstants} from '../../../constants/index';
import {updateWidget, zoomIn} from '../../../redux/actions/dashboardActions';
import {connect} from 'react-redux';
import {Area, CartesianGrid, ComposedChart, Line, ReferenceArea, ReferenceLine, Tooltip, XAxis, YAxis} from 'recharts';
import {dashboardService} from '../../../services';
import moment from 'moment';
import numeral from 'numeral';
import {withSize} from 'react-sizeme';
import svgShowDayAndNight from '../../../sources/images/interface/day-night.svg';
import burgerSvg from '../../../sources/img/burger.svg';
import closeSvg from '../../../sources/img/close.svg';
import {ReactSVG} from 'react-svg';
import CustomTooltip from '../../../components/CustomTooltip';
import CustomLegend from '../../../components/CustomLegend';
import AddFunctionToChart from './components/AddFunctionToChart';
import {widgetsService} from '../../../services/widget.service';
// import AddDemandFunctionToChart from './components/AddDemandFunctionToChart';
import {localeService} from '../../../services/locale.service';
import AddPredictionFunctionToChart from './components/AddPredictionFunctionToChart';
// import AddPeakHoursToChart from './components/AddPeakHoursToChart';
import ShowChartDataInTable from './components/ShowChartDataInTable';
// import WidgetLoader from '../../../components/WidgetLoader';
import {CSVLink} from 'react-csv';
import svgExportToCSV from '../../../sources/images/interface/export_data.svg';

import SeletTimeGroup from './components/SeletTimeGroup';
import SetChartLimits from './components/SetChartLimits';
import ClickOutside from '../../../components/ClickOutside';


function WidgetChartView({widgetProps, datetimeFilter, size, zoomIn, updateWidget}) {

  const [functionDataSources, setFunctionDataSources] = useState([]);
  const [limits, setLimits] = useState({isVisible: false, values: []});
  const [isFetching, setFetching] = useState(true);
  const [selectedArea, setSelectedArea] = useState({left: '', right: ''});
  const [dayAndNightDataSource, setDayAndNightDataSource] = useState({isVisible: false});
  const [functionBacklights, setFunctionBacklights] = useState([]);
  const [normalization, setNormalization] = useState(false);
  const [timeGroup, setTimeGroup] = useState(datetimeFilter.timeGroup);
  const [csvReport, setCSVReport] = React.useState({data: [], headers: [], filename: 'table_widget_data.csv'});
  const [consoleOpen, setConsoleOpen] = React.useState(false);
  const [widgetData, setWidgetData] = useState(widgetProps);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    let widgetPresets = widgetProps.parameters.presets;//localStorage.getItem('widget_presets_' + widgetProps.id);
    if (widgetPresets) {
      if (widgetPresets.functionDataSources) {
        setFunctionDataSources(widgetPresets.functionDataSources);
        setFetching(true);
        dashboardService.loadFunctionDataSources(widgetProps.parameters.presets.functionDataSources, datetimeFilter, size.width).then(
          functionDataSources => {
            setFunctionDataSources(functionDataSources);
            setFetching(false);
          }
        );
      }
      if (widgetPresets.limits) {
        widgetsService.initLimitsPresets(widgetProps.parameters.presets, widgetData.dataSources).then(
          limitPresets => {
            setLimits(limitPresets);
            // setLimits(widgetsService.calculateChartLimitAreas(limitPresets, widgetProps.dataSources));
            setInitialized(true);
          }
        );
      } else {
        setInitialized(true);
      }
    } else {
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (!initialized) {
      return;
    }
    setTimeGroup(datetimeFilter.timeGroup);
    setSelectedArea({left: '', right: ''});
    setFetching(true);
    dashboardService.loadWidgetData(widgetData.dataSources, datetimeFilter).then(
      dataSources => {
        setWidgetData({...widgetData, dataSources: dataSources});

        if (limits && limits.values.length > 0) { //пересчитываем пороги
          const newLimits = widgetsService.calculateChartLimitAreas(limits, dataSources);
          setLimits(newLimits);
        }

        //генерируем временной ряд подсветки функций
        const functionBacklights = widgetsService.generateFunctionBacklights(functionDataSources, dataSources);
        setFunctionBacklights(functionBacklights);
        setFetching(false);
        return dataSources;
      }
    );
    dashboardService.loadFunctionDataSources(functionDataSources, datetimeFilter, size.width, datetimeFilter.timeGroup).then(
      functionDataSources => {
        setFunctionDataSources(functionDataSources);
        return functionDataSources;
      }
    );

    //генерируем временной ряд дня и ночи
    const dayAndNightDS = widgetsService.generateDayAndNightDataSource(datetimeFilter.startDate, datetimeFilter.finishDate);
    setDayAndNightDataSource({...dayAndNightDS, isVisible: dayAndNightDS.isVisible});
  }, [datetimeFilter, initialized]);

  useEffect(() => {
    renderDataForCsvReport(widgetData.dataSources, functionDataSources);
  }, [widgetData, functionDataSources]);

  const renderDataForCsvReport = (dataSources, functionDataSources) => {
    let headers = [{label: localeService.isRussian() ? 'Дата / Время' : 'Datetime', key: 'time_upload'}];
    let datetimeArray = [];
    dataSources.map((ds) => {
      headers.push({
        label: ds.parameters.dataSourceName + (ds.parameters.unit ? (' ,' + ds.parameters.unit) : ''),
        key: ds.id.toString()
      });
      if (ds.data) {
        ds.data.map(el => {
          if (!datetimeArray.includes(el.time_upload)) {
            datetimeArray.push(el.time_upload);
          }
        });
      }
    });
    functionDataSources.map((fds, i) => {
      headers.push({label: fds.parameters.dataSourceName, key: 'func' + i});
      if (fds.data) {
        fds.data.map(el => {
          if (!datetimeArray.includes(el.time_upload)) {
            datetimeArray.push(el.time_upload);
          }
        });
      }
    });

    let data = [];
    datetimeArray.sort((a, b) => b - a).map(timeUpload => {
      let dataRow = {};
      dataRow.time_upload = moment.utc(moment.unix(timeUpload)).format('DD/MM HH:mm:ss');
      dataSources.map((ds) => {
        let dataIdx = ds.data.findIndex(el => el.time_upload === timeUpload);
        if (dataIdx < 0) {
          dataRow[ds.id.toString()] = '';
        } else {
          dataRow[ds.id.toString()] = ds.data[dataIdx].value;
        }

      });
      functionDataSources.map((fds, i) => {
        let dataIdx = fds.data.findIndex(el => el.time_upload === timeUpload);
        if (dataIdx < 0) {
          dataRow['func' + i] = '';
        } else {
          dataRow['func' + i] = fds.data[dataIdx].value;
        }
      });
      data.push(dataRow);
    });
    setCSVReport({...csvReport, data: data, headers: headers});

  };

  const onChangeTimeGroup = (tg) => {
    setTimeGroup(tg);
    setFetching(true);
    dashboardService.loadWidgetDataGroupedBy(widgetData.dataSources, datetimeFilter, tg).then(
      dataSources => {
        setWidgetData({...widgetData, dataSources: dataSources});

        //генерируем временной ряд подсветки функций
        const functionBacklights = widgetsService.generateFunctionBacklights(functionDataSources, dataSources);
        setFunctionBacklights(functionBacklights);
        setFetching(false);
      }
    );
    dashboardService.loadFunctionDataSources(functionDataSources, datetimeFilter, size.width, tg).then(
      functionDataSources => {
        setFunctionDataSources(functionDataSources);
      }
    );
  };


  // Зуммироние
  const zoom = () => {
    let {left, right} = selectedArea;

    if (left === right || right === '') {
      setSelectedArea({left: '', right: ''});
      return;
    }

    if (left > right) {
      [left, right] = [right, left];
    }

    zoomIn({
      ...datetimeFilter,
      startDate: moment(moment.unix(left)),
      finishDate: moment(moment.unix(right)),
      datetimeRange: dashboardConstants.DATETIME_FILTER_LIST[0]
    });

  };

  const setFunctionDataSourceList = (newFunctDSList) => {
    setFunctionDataSources(newFunctDSList);

    updateWidget({
      ...widgetProps, parameters: {
        ...widgetProps.parameters, presets: {
          functionDataSources: newFunctDSList.map(el => {
            const ds = {...el};
            delete ds.data;
            return ds;
          })
        }
      }
    });
  };

  const saveLimitPresets = (limitPreset) => {
    updateWidget({
      ...widgetProps, parameters: {
        ...widgetProps.parameters, presets: {
          ...widgetProps.parameters.presets,
          limits: {
            ...limitPreset, values: limitPreset.values.map(limit => {
              return {...limit, areaData: []};
            })
          }
        }
      }
    });
  };

  return (
    <div className={'widget-content' + (isFetching ? ' data-loading' : '')}>
      <ClickOutside onClick={() => setConsoleOpen(false)}>
        <div className={'widget-management-console'}>
          {/*{isFetching && <WidgetLoader isSignalStyle ={true}/>}*/}
          <button className={'btn svg-btn'} onClick={() => setConsoleOpen(!consoleOpen)}>
            {!consoleOpen ? <ReactSVG src={burgerSvg}/> : <ReactSVG src={closeSvg}/>}
          </button>
          <div className={'wm-content ' + (consoleOpen ? 'opened' : 'closed')}>
            <button type="button"
                    title={localeService.isRussian() ? 'Показать/Скрыть подсветку дня и ночи' : 'Show/Hide Day and Night Backlight'}
                    className={'btn default svg-btn' + (dayAndNightDataSource.isVisible ? ' active' : '')}
                    onClick={() => {
                      setDayAndNightDataSource({
                        ...dayAndNightDataSource,
                        isVisible: !dayAndNightDataSource.isVisible
                      });
                    }}>
              <ReactSVG src={svgShowDayAndNight}/>
            </button>
            {limits && <SetChartLimits limits={limits} dataSources={widgetData.dataSources} onChange={el => {
              const newLimits = widgetsService.calculateChartLimitAreas(el, widgetData.dataSources);
              setLimits(newLimits);
              saveLimitPresets(el);
            }}/>}
            <button type="button"
                    title={localeService.isRussian() ? 'Нормализация' : 'Normalization'}
                    className={'btn default svg-btn' + (normalization ? ' active' : '')}
                    onClick={() => setNormalization(!normalization)}>
              <span style={{fontStyle: 'italic', fontSize: '1.2rem', fontWeight: 'bold'}}>N</span>
            </button>
            <button type="button"
                    title={localeService.isRussian() ? 'Экспорт данных в CSV' : 'Export data to CSV'}
                    className={'btn default svg-btn'} onClick={e => e.stopPropagation()}>
              <CSVLink {...csvReport}>
                <ReactSVG src={svgExportToCSV}/>
              </CSVLink>
            </button>
            {/*<AddPeakHoursToChart dataSources={widgetData.dataSources} onApplyFunction={peakHoursDS => {*/}
            {/*if (peakHoursDS !== null) {*/}
            {/*setPeakHoursDataSources([peakHoursDS]);*/}
            {/*} else {*/}
            {/*setPeakHoursDataSources([]);*/}
            {/*}*/}

            {/*}}/>*/}
            <ShowChartDataInTable dataSources={widgetData.dataSources}
                                  functionDataSources={functionDataSources}/>
            <AddFunctionToChart
              onApplyFunction={functionDS => {
                //добавляем настройки виджета в redux в предустановки
                // addFunctionToPreset(functionDS);
                setFunctionDataSourceList([...functionDataSources, functionDS]);
              }}
              dataSources={widgetData.dataSources} widgetWidth={size.width}/>
            {/*<AddDemandFunctionToChart*/}
            {/*onApplyFunction={functionDS => {*/}
            {/*//добавляем настройки виджета в redux в предустановки*/}
            {/*// addFunctionToPreset(functionDS);*/}
            {/*setFunctionDataSourceList([...functionDataSources, functionDS]);*/}
            {/*}}*/}
            {/*dataSources={widgetData.dataSources} widgetWidth={size.width}/>*/}
            <AddPredictionFunctionToChart
              onApplyFunction={functionDS => {
                //добавляем настройки виджета в redux в предустановки
                // addFunctionToPreset(functionDS);
                setFunctionDataSourceList([...functionDataSources, functionDS]);
              }}
              dataSources={widgetData.dataSources} widgetWidth={size.width}/>
            <SeletTimeGroup timeGroup={timeGroup} datetimeFilter={datetimeFilter}
                            onChangeTimeGroup={tg => onChangeTimeGroup(tg)}/>
          </div>
        </div>
      </ClickOutside>
      <ComposedChart
        width={size.width}
        height={(100 * widgetProps.h) - 30}
        onMouseDown={(e) => e && !isFetching && setSelectedArea({...selectedArea, left: e.activeLabel})}
        onMouseMove={(e) => !isFetching && selectedArea.left && setSelectedArea({
          ...selectedArea,
          right: e.activeLabel
        })}
        onMouseUp={() => zoom()}>
        <CartesianGrid strokeDasharray="3 3"/>
        {/*Отображение дня/ночи */}
        {dayAndNightDataSource.isVisible && dayAndNightDataSource.data && dayAndNightDataSource.data.map((point, i) => {
          if (i < dayAndNightDataSource.data.length - 1) {
            return (<ReferenceArea x1={dayAndNightDataSource.data[i].time_upload}
                                   x2={dayAndNightDataSource.data[i + 1].time_upload}
                                   key={'day-n-night-k' + i}
                                   fillOpacity={0.2}
                                   fill={dayAndNightDataSource.data[i].color}/>);
          }
        })}
        <XAxis
          dataKey="time_upload"
          tickFormatter={(number) => {
            return moment.unix(number).format(moment.duration(datetimeFilter.finishDate.diff(datetimeFilter.startDate)).asHours() > 24 ? 'DD/MM HH:mm' : 'HH:mm:ss');
          }}
          allowDataOverflow={true}
          type="number"
          allowDecimals={false}
          // allowDuplicatedCategory={false}
          scale={'utc'}
          domain={[moment(moment(datetimeFilter.startDate)).unix().valueOf(), moment(moment(datetimeFilter.finishDate)).unix().valueOf()]}
        />
        <YAxis dataKey="value"
               type="number" domain={['auto', 'dataMax']}
               tickFormatter={(value) => {
                 return numeral(value).format('0.000 a');
               }}
        />
        <Tooltip
          content={(e) => e.active && selectedArea.left === '' &&
            <CustomTooltip label={e.label} widgetData={widgetData}
                           functionDataSources={functionDataSources}/>}
          cursor={{stroke: 'black', strokeWidth: 2, opacity: '0.7'}}/>
        {/*<Legend align={'center'} content={() =>*/}
        {/*<CustomLegend widgetDataSources={widgetData.dataSources} functionDataSources={functionDataSources}*/}
        {/*onDeleteFunction={(funcDS) => {*/}
        {/*// deleteFunctionFromPreset(funcDS.idx);*/}
        {/*setFunctionDataSourceList(functionDataSources.filter(el => el.idx !== funcDS.idx));*/}
        {/*}}/>}/>*/}

        {limits !== null && limits.values.length > 0 && limits.isVisible &&
        limits.values.map((limit, lid) => {
          return [
            limit.areaData && limit.areaData.map((areaData, i) => {
              return <Area key={'limitArea' + limit.dataSource.id + i + lid}
                           type={widgetProps.widgetType === dashboardConstants.WIDGET_STEP_CHART ? 'stepAfter' : 'line'}
                           dataKey="value"
                           data={areaData}
                           stroke={limit.color}
                           fillOpacity={0.4}
                           fill={limit.color}
              />;
            }),
            <ReferenceLine key={'limit' + lid} y={limit.value} stroke={limit.color}
              // label={p => <CustomizedLabel params={p} limitValue={limit.value}/>}
            />
          ];
        })
        }
        {dayAndNightDataSource.data &&
        <Line
          type={'monotone'}
          dataKey="value"
          data={dayAndNightDataSource.data}
          stroke={'#cccccc'}
          key={dayAndNightDataSource.id}
          name={'Day and Night'}
          background={{fill: '#eee'}}
          dot={false}
          activeDot={false}
        />
        }
        {widgetData.dataSources && widgetData.dataSources.map((source) => {
          if (!source.parameters.isHidden) {
            if (widgetProps.widgetType === dashboardConstants.WIDGET_LINE_CHART || widgetProps.widgetType === dashboardConstants.WIDGET_STEP_CHART) {
              return (<Line
                type={widgetProps.widgetType === dashboardConstants.WIDGET_STEP_CHART ? 'stepAfter' : 'line'}
                dataKey="value"
                data={widgetsService.modifyChartData(source.data, normalization)}
                stroke={source.parameters.color}
                strokeWidth={'1px'}
                strokeOpacity={0.8}
                name={source.parameters.dataSourceName}
                key={'lineChart' + source.id}
                background={{fill: '#eee'}}
                dot={false}
                activeDot={false}
                animationDuration={0}
              />);
            }
            if (widgetProps.widgetType === dashboardConstants.WIDGET_COLUMN_CHART) {
              return (<Area
                dataKey="value"
                type={'stepAfter'}
                data={widgetsService.modifyChartData(source.data, normalization)}
                name={source.parameters.dataSourceName}
                key={'areaChart' + source.id}
                stroke={source.parameters.color}
                fillOpacity={0.6}
                fill={source.parameters.color}
                animationDuration={0}
                dot={false}
                activeDot={false}
              />);
            }
          }
        })}

        {functionDataSources && functionDataSources.map((source, i) => {
          let functionSource = [];

          /**
           *Не показываем временной ряд для пердиктора Хольта-Винтерса от Питона
           * */
          if (source.functionCode !== 'PREDICTION-HOLT-WINTERS') {
            functionSource.push(<Line
              type={widgetProps.widgetType === dashboardConstants.WIDGET_STEP_CHART ? 'stepAfter' : 'line'}
              dataKey="value"
              data={widgetsService.modifyChartData(source.outputsCount > 1 ? source.data[0] : source.data, normalization)}
              stroke={source.parameters.color}
              strokeWidth={'2px'}
              name={source.parameters.dataSourceName}
              key={'func_ds_' + i}
              background={{fill: '#eee'}}
              dot={false}
              activeDot={false}
              // strokeDasharray={'5 5'}
              animationDuration={0}
            />);
          }


          /**
           * Показываем доверительный интервал пердиктора Хольта-Винтерса от Питона. Объединяем два ряда для отображения доверительного интервала
           * */
          if (source.functionCode === 'PREDICTION-HOLT-WINTERS' && source.data.length === 3) {
            const uppedLineData = widgetsService.modifyChartData(source.data[2], normalization);
            const lowerLineData = widgetsService.modifyChartData(source.data[1], normalization);
            functionSource.push(<Area
              type={widgetProps.widgetType === dashboardConstants.WIDGET_STEP_CHART ? 'stepAfter' : 'line'}
              dataKey='value' key={'porog_1' + i}
              data={uppedLineData.map((upperLine, ind) => {
                return {
                  time_upload: upperLine.time_upload,
                  value: [
                    upperLine.value,
                    lowerLineData[ind].value
                  ]
                };
              })}
              // stackId={i}
              stroke='none'
              dot={false}
              fillOpacity={0.3}
              fill='grey'/>);
          }
          return functionSource;
        })}

        {/*Заливка для подсветки функций*/}
        <defs>
          <linearGradient id="red" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="red" stopOpacity={0}/>
            <stop offset="95%" stopColor="red" stopOpacity={0.4}/>
          </linearGradient>
          <linearGradient id="green" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="green" stopOpacity={0}/>
            <stop offset="95%" stopColor="green" stopOpacity={0.4}/>
          </linearGradient>
          <linearGradient id="grey" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="grey" stopOpacity={0}/>
            <stop offset="95%" stopColor="grey" stopOpacity={0.4}/>
          </linearGradient>
        </defs>

        {/* Подсветка для функций */}
        {functionBacklights && functionBacklights.map((backlight, i) => {
          return <ReferenceArea key={i} fill={backlight.color} x1={backlight.x1} x2={backlight.x2}
                                y1={backlight.y1 || null} y2={backlight.y2 || null}
          />;
        })}

        {/*выделение области зума*/}
        {(selectedArea.left && selectedArea.right) &&
        <ReferenceArea x1={selectedArea.left} x2={selectedArea.right}
                       strokeOpacity={0.3}/>}
      </ComposedChart>
      <CustomLegend widgetDataSources={widgetData.dataSources}
                    onHideDataSources={dsList => setWidgetData({...widgetData, dataSources: dsList})}
                    functionDataSources={functionDataSources}
                    onDeleteFunction={(funcDS) => {
                      // deleteFunctionFromPreset(funcDS.idx);
                      setFunctionDataSourceList(functionDataSources.filter(el => el.idx !== funcDS.idx));
                    }}/>
    </div>
  );

}

WidgetChartView.propTypes = {
  widgetProps: PropTypes.object,
  mode: PropTypes.oneOf([dashboardConstants.EDITION_MODE, dashboardConstants.VIEW_MODE]),
  size: PropTypes.object,
  datetimeFilter: PropTypes.object,
  zoomIn: PropTypes.func,
  updateWidget: PropTypes.func
};

// const CustomizedLabel = ({params, limitValue}) => {
//     console.log('props', params, limitValue);
//     // return <svg width="400" height="180">
//     //     <rect x="50" y="20" rx="20" ry="20" width="150" height="150"
//     //           style="fill:red;stroke:black;stroke-width:5;opacity:0.5" />
//     // </svg>;
//     return <text offset={params.offset} x={params.viewBox.x} y={params.viewBox.y} className="recharts-text recharts-label" textAnchor="middle" title="Test"><tspan x="26" dy="0.355em">{limitValue}</tspan></text>;
// };
//
// CustomizedLabel.propTypes = {
//     params: PropTypes.object,
//     limitValue: PropTypes.string
// };

const mapStateToProps = state => {
  const datetimeFilter = state.dashboardReducer.datetimeFilter;
  return {datetimeFilter};
};

const mapDispatchToProps = {
  zoomIn: zoomIn,
  updateWidget: updateWidget
};
export default withSize()(connect(mapStateToProps, mapDispatchToProps)(WidgetChartView));
