import React from 'react';
import PropTypes from 'prop-types';

function CheckBox({label, checked, onChange, inTable}) {

    return <div className={'input-group ' + (inTable ? 'in-table' : 'understroke')}>
        <div className={'input-group-prepend'}>
            <button type={'button'} className={'check-box' + (checked ? ' checked':'')}
                    onClick={(e) => {
                        e.preventDefault();
                        onChange(!checked);
                    }}></button>
        </div>
        {label && <label>{label}</label>}
    </div>;
}

CheckBox.propTypes = {
    label: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    inTable: PropTypes.bool
};

export default CheckBox;