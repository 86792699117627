import React from 'react';
import PropTypes from 'prop-types';

function AlertError({message, isOpen, onCancel, isClosable}) {

    return <>
        {(isOpen || isClosable) &&
        <div className={'row'}>
            <div className="alert alert-danger">{message}
                {onCancel && !isClosable && <div className={'to-right'}><button className={'btn eco-btn danger'} onClick={() => onCancel()}>Ok</button></div> }
            </div>
        </div>}
    </>;
}

AlertError.propTypes = {
    message: PropTypes.string,
    isOpen: PropTypes.bool,
    onCancel: PropTypes.func,
    isClosable: PropTypes.bool
};

AlertError.defaultProps = {
    isClosable: false
};

export default AlertError;