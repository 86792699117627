import React, {useEffect, useState} from 'react';
import {localeService} from '../../services/locale.service';
import ReactGridLayout from 'react-grid-layout';
import '../../styles/dashboard.scss';
import '../../styles/dropdown.scss';
import '../../../node_modules/react-grid-layout/css/styles.css';
import '../../../node_modules/react-resizable/css/styles.css';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import DropZone from './DropZone';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {clearDashboard, updateDashboardList, updateWidgetsPosition} from '../../redux/actions/dashboardActions';
import {SizeMe} from 'react-sizeme';
import {dashboardConstants} from '../../constants';
import Widget from './widgets/Widget';
import Modal from '../../components/Modal';
import InputText from '../../components/InputText';
import {useTranslation} from 'react-i18next';
import {dashboardService} from '../../services';
import Loader from '../../components/Loader';
import {Link} from 'react-router-dom';
import AlertError from '../../components/AlertError';
import {uploadFunctionList} from '../../redux/actions/analyticsActions';
import {analyticService} from '../../services/analyticService';
import SelectDashboardDatasources from './SelectDashboardDatasources';
import WidgetList from './WidgetList';
import {canDo} from '../../services/permitions.service';

function CreateDashboard({widgetList, dataSourceList, clearDashboard, updateWidgetsPosition, uploadFunctionList, updateDashboardList}) {
  const {t} = useTranslation();
  const [widgets, setWidgets] = useState(widgetList);
  const [isBlocked, setBlocked] = useState(false);
  const [dashboardName, setDashboardName] = useState('');
  const [page, setPage] = useState('data_sources');

  const [createDashboardForm, setCreateDashboardForm] = useState({
    isOpen: false,
    isFetching: false,
    newDashboardID: null,
    error: ''
  });

  useEffect(() => {
    clearDashboard();
    analyticService.getFunctionList().then(
      functionList => {
        uploadFunctionList(functionList);
      }
    );
  }, []);
  useEffect(() => {
    if (widgetList.findIndex(el => el.mode === dashboardConstants.EDIT_SINGLE_MODE) >= 0) {
      setBlocked(true);
    } else {
      setBlocked(false);
    }
    setWidgets(widgetList);
  }, [widgetList]);


  const saveDashboard = (e) => {
    e.preventDefault();
    setCreateDashboardForm({...createDashboardForm, isFetching: true});
    dashboardService.createDashboard({name: dashboardName, widgetList: widgetList})
      .then(resp => {
        if (resp.dashboardID) {
          dashboardService.getDashboardList()
            .then((dbList) => {
              updateDashboardList(dbList);
            });
          setCreateDashboardForm({
            ...createDashboardForm,
            newDashboardID: resp.dashboardID,
            isFetching: false
          });
        } else {
          setCreateDashboardForm({
            ...createDashboardForm,
            isFetching: false,
            error: localeService.isRussian() ? 'Не удалось сохранить дашборд!' : 'Cannot save dashboard'
          });
        }
      }, error => {
        setCreateDashboardForm({...createDashboardForm, isFetching: false, error: JSON.stringify(error)});
      });
  };


  const closeSavingForm = () => {
    setCreateDashboardForm({
      isOpen: false,
      isFetching: false,
      newDashboardID: null,
      error: ''
    });
  };

  return (<>
    {!(canDo('ROLE_ADD_DELETE_DASHBOARD') || canDo('ROLE_ADD_NEW_DASHBOARD')) &&
    <div>
      <div className={'row'}>
        <h2 className={'mb-5'}>{localeService.isRussian() ? 'Создание дашборда' : 'Create Dashboard'}</h2>
        <div
          className="alert alert-danger">{localeService.isRussian() ? 'У Вас не достаточно прав для создания дашборда' : 'You do not have enough rights to create new dashboard'}
        </div>
      </div>
    </div>
    }
    {(canDo('ROLE_ADD_DELETE_DASHBOARD') || canDo('ROLE_ADD_NEW_DASHBOARD')) &&
    <>
      <div className={'divided-page page-2-1'}>
        <div className={'divided-page-header'} style={{minHeight: '12rem'}}>
          <div className={'main-content'}>
            <h2 className={'mb-5'}>{localeService.isRussian() ? 'Создание дашборда' : 'Create Dashboard'}</h2>
            <div className={'d-flex justify-content-between'}>
              <ul className="nav nav-pills">
                <li className="nav-item">
                  <button className={'nav-link ' + (page === 'data_sources' ? 'active' : '')}
                          onClick={() => setPage('data_sources')}>
                    {localeService.isRussian() ? 'Источники данных' : 'Select Data Sources'}</button>
                </li>
                <li className="nav-item">
                  <button className={'nav-link ' + (page.includes('widgets') ? 'active' : '')}
                          onClick={() => {
                            setPage('widgets_info');
                          }}>
                    {localeService.isRussian() ? 'Виджеты' : 'Widgets'}</button>
                </li>
              </ul>
              <button className={'btn eco-btn success'} onClick={() => setCreateDashboardForm({
                ...createDashboardForm,
                isOpen: true
              })}>{localeService.isRussian() ? 'Создать дашборд' : 'Create Dashboard'}</button>
            </div>
          </div>
          <div className={'info-content'}>
            {page === 'data_sources' &&
            <div className={'help-block'}>
              <p>Перед тем, как создать дашборд необходимо выбрать источники данных.</p>
              <p>Для этого выберите источник из списка и отметьте необходимые переменные
                устройств.</p>
            </div>}
            {(page === 'widgets_info' || page === 'widgets_manage') &&
            <div className={'help-block'}>
              <p>Для создания дашборда перетаскивайте необходимые виджеты в левую часть экрана. Виджеты
                можно масштабировать и изменять их положение. При наведении на виджет доступно его
                редактирование.</p>
            </div>}
          </div>
        </div>
        <div className={'divided-page-body'}>
          <DndProvider backend={HTML5Backend}>
            <div className={'main-content'}>
              {page === 'data_sources' && <SelectDashboardDatasources newStyle={true}/>}
              {(page === 'widgets_info' || page === 'widgets_manage') &&
              <SizeMe>{({size}) =>
                <DropZone>
                  <ReactGridLayout className="dashboard-grid" layout={widgetList} cols={6}
                                   rowHeight={70}
                                   onLayoutChange={(items) => {
                                     updateWidgetsPosition(items);
                                   }}
                                   isDraggable={!isBlocked}
                                   width={size.width} margin={[4, 4]}>
                    {widgets.map(widget => {
                      return <div key={widget.id} data-grid={widget}>
                        <Widget mode={widget.mode} widgetProps={widget}
                                sizeParams={{
                                  w: widget.w,
                                  h: widget.h,
                                  width: (size.width / 6 * widget.w),
                                  height: (70 * widget.h)
                                }}
                        />
                      </div>;
                    })}
                  </ReactGridLayout>
                </DropZone>}</SizeMe>
              }
            </div>
            <div className={'info-content'}>
              {page === 'data_sources' &&
              <div className={'d-flex flex-column align-items-start'}>
                <b
                  className={'step mb-5'}>3. {localeService.isRussian() ? 'Источники данных дашборда' : 'Dashboard Data Sources'}</b>
                {dataSourceList.map(el => {
                  return <p key={el.id} className={'datasource-info'}>
                    {el.name}
                    {/*<p className={'help-text'}><b>{t('capacity')}: </b>{el.capacity.name}</p>*/}
                  </p>;
                })}
              </div>}
              {page.includes('widgets') && <WidgetList type={page}/>}
            </div>
          </DndProvider>
        </div>
      </div>
      <Modal isOpen={createDashboardForm.isOpen}
             isClosable={false}
             footer={false}
             title={localeService.isRussian() ? 'Создать дашборд' : 'Create Dashboard'}>
        <form>
          <InputText label={localeService.isRussian() ? 'Название дашборда' : 'Dashboard Name'}
                     value={dashboardName} onChange={(text) => setDashboardName(text)}/>
          {!createDashboardForm.isFetching && !createDashboardForm.newDashboardID && !createDashboardForm.error &&
          <div className={'d-flex justify-content-center'}>
            <button className={'btn eco-btn danger'}
                    onClick={() => closeSavingForm()}>{t('cancel')}</button>
            {dashboardName !== '' && <button className={'btn eco-btn success'}
                                             onClick={(e) => saveDashboard(e)}>{t('save')}</button>}
          </div>}
          {createDashboardForm.error &&
          <AlertError isClosable={false} message={createDashboardForm.error}/>}
          {createDashboardForm.isFetching && <Loader
            waitText={localeService.isRussian() ? 'Сохранение дашборда...' : 'Saving dashboard...'}/>}
          {!createDashboardForm.isFetching && createDashboardForm.newDashboardID &&
          <div className={'row'}>
            <div
              className={'to-center'}>{localeService.isRussian() ? 'Дашборд успешно создан!' : 'Dshboard is successfully created!'}
            </div>
            <div className={'to-center'}>
              <Link to={'/dashboard/' + createDashboardForm.newDashboardID}>
                <button type="button" className={'btn eco-btn success'}>Ok</button>
              </Link>
            </div>
          </div>
          }
        </form>
      </Modal>
    </>
    }
  </>);
}

CreateDashboard.propTypes = {
  widgetList: PropTypes.array,
  dataSourceList: PropTypes.array,
  clearDashboard: PropTypes.func,
  updateWidgetsPosition: PropTypes.func,
  uploadFunctionList: PropTypes.func,
  updateDashboardList: PropTypes.func
};

const mapStateToProps = state => {
  const widgetList = state.dashboardReducer.widgetList;
  const dataSourceList = state.dashboardReducer.dataSourceList;
  return {widgetList, dataSourceList};
};
const mapDispatchToProps = {
  clearDashboard: clearDashboard,
  uploadFunctionList: uploadFunctionList,
  updateWidgetsPosition: updateWidgetsPosition,
  updateDashboardList: updateDashboardList
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateDashboard);
