import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Select from '../../components/Select';
import {localeService} from '../../services/locale.service';
import {connect} from 'react-redux';
import InputText from '../../components/InputText';
import {ReactSVG} from 'react-svg';
import svgDelete from '../../sources/images/interface/basket.svg';
import ColorPicker from '../../components/ColorPicker';
import {dashboardService} from '../../services';
import {useTranslation} from 'react-i18next';
import AddFunctionToDatasource from './AddFunctionToDatasource';

function EditLinesWidget({dataSourceNumber, widgetDataSource, onChangeDataSource, dataSourceList, onDeleteDataSource}) {

    const { t } = useTranslation();
    const initNewDS = {
        idx: new Date().getTime(),
        parameters: {
            dataSourceName: '',
            color: dashboardService.getRandomColor()
        }
    };

    const [dataSource, setDataSource] = useState(widgetDataSource);

    useEffect(() => {
        if (widgetDataSource.idx === 'new') {
            onChangeDataSource(initNewDS);
        }
        setDataSource(widgetDataSource.idx === 'new' ? initNewDS : widgetDataSource);
    }, [widgetDataSource]);

    const onChange = (ds) => {
        setDataSource({...dataSource, ...ds});
        onChangeDataSource({...dataSource, ...ds});
    };

    return (
        <>
            {dataSource.idx !== 'new' &&
            <div className={'group'}>
                <label
                    className={'group-header'}>{localeService.isRussian() ? 'Источник данных ' : 'Data Source '}{dataSourceNumber}</label>
                {dataSourceList.length === 0 &&
                <p>{localeService.isRussian() ? 'Добавьте источники данных дашборда на вкладке "Источники данных"' : 'Add Data Sources for Dashbord on the tab "Data Sources"'}</p>}
                {dataSourceList.length > 0 &&
                <Select label={localeService.isRussian() ? 'Выберите источник данных' : 'Select Data Source'}
                        valueList={dataSourceList || []}
                        value={widgetDataSource}
                        onSelect={(ds) => onChange({
                            ...ds,
                            sourceID: ds.id,
                            parameters: {...dataSource.parameters, dataSourceName: ds.name}
                        })}/>}
                <div className={'row'} style={{padding: 0, display: 'flex', width: '100%'}}>
                    <InputText label={localeService.isRussian() ? 'Имя источника данных' : 'Data Source Name'}
                               value={dataSource.parameters.dataSourceName || ''}
                               onChange={text => onChange({
                                   ...dataSource,
                                   parameters: {...dataSource.parameters, dataSourceName: text}
                               })}/>
                </div>
                <div className={'row'} style={{padding: 0, display: 'flex', width: '100%'}}>
                    <InputText label={t('unit')}
                               value={dataSource.parameters.unit || ''}
                               onChange={text => onChange({
                                   ...dataSource,
                                   parameters: {...dataSource.parameters, unit: text}
                               })}/>
                    <ColorPicker style={{margin: '0 1rem'}} color={dataSource.parameters.color} setColor={(color) =>
                        onChange({...dataSource, parameters: {...dataSource.parameters, color: color}})}/>
                    <button className={'danger svg-round-btn'}
                            onClick={(e) => {
                                e.preventDefault();
                                onDeleteDataSource(dataSource.idx);
                            }}>
                        <ReactSVG src={svgDelete}/>
                    </button>                   
                </div>
                <AddFunctionToDatasource appliedFunction={{functionCode: dataSource.parameters.functionCode, functionParams: dataSource.parameters.functionParams, name: dataSource.parameters.functionName}}
                onApplyFunction ={(appliedFunction) => {                  
                    dataSource.parameters.functionCode = appliedFunction.functionCode;
                    dataSource.parameters.functionParams = appliedFunction.functionParams;
                    dataSource.parameters.functionName = appliedFunction.functionName;
                }}/>
            </div>
            }
        </>
    );
}

EditLinesWidget.propTypes = {
    widgetDataSource: PropTypes.object,
    onChangeDataSource: PropTypes.func,
    dataSourceList: PropTypes.array,
    dataSourceNumber: PropTypes.number,
    onDeleteDataSource: PropTypes.func
};
const mapStateToProps = state => {
    const dataSourceList = state.dashboardReducer.dataSourceList;
    return {dataSourceList};
};

export default connect(mapStateToProps, null)(EditLinesWidget);
