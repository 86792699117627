// const DOMAIN_URL = 'https://ecomonitoring.io/api';
const DOMAIN_URL = 'https://digitalmonitoring.ru/api';
// const DOMAIN_URL = '/api';
// const DOMAIN_URL = 'https://82.148.31.128/api';
// eslint-disable-next-line no-undef
// const PUBLIC_URL = process.env.NODE_ENV === 'development ' ? '' : 'https://ecomonitoring.io/api';
// const DOMAIN_URL = 'http://localhost:8080';
const PUBLIC_URL = '/api';

export const apiConstants = {
  CURRENT_TIME: DOMAIN_URL + '/system/current/time',
  PUBLIC_URL: PUBLIC_URL,
  API_URL: DOMAIN_URL,
  // USER_AUTHORIZATION_URL: DOMAIN_URL + '/oauth/token',
  USER_AUTH_URL: DOMAIN_URL + '/auth/login',

  GET_USER: DOMAIN_URL + '/user',

  UPLOAD_RELATION_DATA: DOMAIN_URL + '/data/upload/and/relation',
  UPLOAD_RELATION_DATA_80020: DOMAIN_URL + '/data/upload/and/relation/80020',
  UPLOAD_CSV_FILE_URL: DOMAIN_URL + '/data/parse',
  UPLOAD_XML_FILE_URL: DOMAIN_URL + '/data/xml/parse',
  UPLOAD_80020_FILE_URL: DOMAIN_URL + '/data/80020/parse',
  UPLOAD_EXCEL_FILE_URL: DOMAIN_URL + '/data/excel/parse',
  UPLOAD_DATA_MANUAL: DOMAIN_URL + '/data/upload/and/relation/manual',
  CHECK_CSV_FILE: DOMAIN_URL + '/file/csv/check',
  SAVE_CSV_FILE: DOMAIN_URL + '/file/csv',
  COMPLETE_UPLOAD_CSV_FILE: DOMAIN_URL + '/file/csv',

  UPLOAD_FROM_ENERGO_SPHERE_TEST: DOMAIN_URL + '/data/upload/energosphere/test',
  UPLOAD_FROM_ENERGO_SPHERE: DOMAIN_URL + '/data/upload/energosphere',

  GET_CAPACITY_LIST: DOMAIN_URL + '/capacity/list',
  GET_CAPACITY_TREE: DOMAIN_URL + '/capacity/tree',
  GET_CAPACITY_SUB_LIST: DOMAIN_URL + '/capacity/get/sub',
  CREATE_LOAD: DOMAIN_URL + '/capacity/save',
  DELETE_LOAD: DOMAIN_URL + '/capacity/delete',
  GET_CAPACITY: DOMAIN_URL + '/capacity/get',
  GET_CAPACITY_BY_VARIABLE_ID: DOMAIN_URL + '/capacity/device_variable/get',

  FIND_DEVICE: DOMAIN_URL + '/device/mqtt/check',
  GET_DEVICE_LIST: DOMAIN_URL + '/device/list',
  GET_VARIABLE_LIST_BY_CAPACITY: DOMAIN_URL + '/device/variables/by/capacity',
  ADD_REAL_DEVICE: DOMAIN_URL + '/device/mqtt/add',
  EDIT_DEVICE: DOMAIN_URL + '/device/mqtt/edit',
  ADD_VIRTUAL_DEVICE: DOMAIN_URL + '/device/virtual/add',
  ADD_MODBUS_DEVICE: DOMAIN_URL + '/device/modbus/add',
  ADD_VIRTUAL_VARIABLE: DOMAIN_URL + '/device/virtual/add/variable',
  DELETE_VIRTUAL_VARIABLE: DOMAIN_URL + '/device/virtual/delete/variable',
  EDIT_VIRTUAL_VARIABLE: DOMAIN_URL + '/device/virtual/edit/variable',
  DEVICE_STOP: DOMAIN_URL + '/device/stop',
  DEVICE_START: DOMAIN_URL + '/device/start',
  DEVICE_DROP: DOMAIN_URL + '/device/drop',
  DEVICE_VARIABLE_STOP: DOMAIN_URL + '/device/variable/stop',
  DEVICE_VARIABLE_START: DOMAIN_URL + '/device/variable/start',
  GET_DEVICE_VARIABLE_LIST: DOMAIN_URL + '/device/mqtt/variables',
  GET_VIRTUAL_DEVICE_VARIABLE_LIST: DOMAIN_URL + '/device/variables',
  DEVICE_RENAME: DOMAIN_URL + '/device/rename',
  DEVICE_DELETE: DOMAIN_URL + '/device/delete',
  VARIABLE_RENAME: DOMAIN_URL + '/device/variable/rename',
  GET_DEVICE: DOMAIN_URL + '/device/get',
  DEVICE_LOCATION: DOMAIN_URL + '/device/location',
  SET_DEVICE_TIMEZONE: DOMAIN_URL + '/device/timezone',
  GET_DEVICE_BY_SOURCEID: DOMAIN_URL + '/device/by/sourceId',


  CREATE_DASHBOARD: DOMAIN_URL + '/dashboard/create',
  EDIT_DASHBOARD: DOMAIN_URL + '/dashboard/modify',
  CREATE_WIDGET: DOMAIN_URL + '/widget/create',
  EDIT_WIDGET: DOMAIN_URL + '/widget/edit',
  GET_DASHBOARD_LIST: DOMAIN_URL + '/dashboard/list',
  GET_DASHBOARD: DOMAIN_URL + '/dashboard/get',
  GET_WIDGET_TYPE_LIST: DOMAIN_URL + '/widget/type/list',
  GET_WIDGET_LIST: DOMAIN_URL + '/widget/list',
  GET_TRANSFORMATIONS_FUNCTION_LIST: DOMAIN_URL + '/widget/transformations/function/list',
  GET_AGGREGATION_FUNCTION_LIST: DOMAIN_URL + '/widget/aggregation/function/list',
  GET_ANALITICS_FUNCTION_LIST: DOMAIN_URL + '/widget/analytics/function/list',
  GET_WIDGET_DATASOURCE_LIST: DOMAIN_URL + '/widget/data/list',
  GET_WIDGET_CHART_FUNCTION_DATA: DOMAIN_URL + '/widget/data/function',
  GET_WIDGET_DATASOURCE_COUNT: DOMAIN_URL + '/widget/data/count',
  DELETE_WIDGET: DOMAIN_URL + '/widget/delete',
  DELETE_DASHBOARD: DOMAIN_URL + '/dashboard/delete',
  ANALYTIC_RUN: DOMAIN_URL + '/widget/analytics/run',
  ANALYTIC_GET_RESULT: DOMAIN_URL + '/widget/data/result',
  WIDGET_SET_DATA: DOMAIN_URL + '/widget/data/set',

  GET_SCRIPT_LIST: DOMAIN_URL + '/rscript/list',

  ORGANIZATION_LIST: DOMAIN_URL + '/organization/list',
  ORGANIZATION_INFO: DOMAIN_URL + '/organization/info',
  ORGANIZATION_ROLE_LIST: DOMAIN_URL + '/organization/role/list',
  ORGANIZATION_ROLE_CREATE: DOMAIN_URL + '/organization/role/create',
  ORGANIZATION_ROLE_DELETE: DOMAIN_URL + '/organization/role/delete',
  ORGANIZATION_ADD_USER: DOMAIN_URL + '/organization/user/add',
  ORGANIZATION_EDIT_USER: DOMAIN_URL + '/organization/user/edit',
  ORGANIZATION_DELETE_USER: DOMAIN_URL + '/organization/user/delete',
  ORGANIZATION_INFO_EDIT: DOMAIN_URL + '/organization/info/edit',
  ORGANIZATION_USER_LIST: DOMAIN_URL + '/organization/user/list',
  ORGANIZATION_ACCESSIBLE_ROLE_LIST: DOMAIN_URL + '/organization/accessible/role',
  ORGANIZATION_BALANCE: DOMAIN_URL + '/organization/balance',
  ORGANIZATION_TARIFF: DOMAIN_URL + '/organization/tariff',
  ORGANIZATION_ADD_BALANCE: DOMAIN_URL + '/organization/add/balance',

  USER_ACTIVATE: DOMAIN_URL + '/auth/set/password',
  USER_EDIT: DOMAIN_URL + '/user/edit',
  CONFIRM_EMAIL: DOMAIN_URL + '/auth/confirm',

  REGISTER_NEW_ORGANIZATION_ADMINISTRATOR: DOMAIN_URL + '/auth/registration/company',


  PASSPORT_ADD_CONNECT: DOMAIN_URL + '/capacity/passport/connect',
  PASSPORT_ADD_DELIVERY: DOMAIN_URL + '/capacity/passport/delivery',
  PASSPORT_ADD_STORAGE: DOMAIN_URL + '/capacity/passport/storage',
  PASSPORT_ADD_DYNAMIC: DOMAIN_URL + '/capacity/passport/dynamic',
  PASSPORT_DOWNLOAD: DOMAIN_URL + '/file/download',
  PASSPORT_CREATE: DOMAIN_URL + '/passport/create',
  PASSPORT_DELETE: DOMAIN_URL + '/passport/delete',
  GET_PASSPORT_LIST: DOMAIN_URL + '/passport/list',
  PASSPORT_UPLOAD_FILE: DOMAIN_URL + '/capacity/passport/dynamic/block_file',
  PASSPORT_LIST_BY_VARIABLE_ID: DOMAIN_URL + '/capacity/device_variable/get',

  CAPACITY_PASSPORT_CREATE: DOMAIN_URL + '/capacity/passport/dynamic',
  CAPACITY_PASSPORT_DELETE: DOMAIN_URL + '/capacity/passport/',

  TARIFF_LIST: DOMAIN_URL + '/tariff/list',
  TARIFF_CHANGE: DOMAIN_URL + '/tariff/change',
  SCENARIO_GET_LIST: DOMAIN_URL + '/script/auto/list',
  SCENARIO_GET: DOMAIN_URL + '/script/auto/',
  SCENARIO_DELETE: DOMAIN_URL + '/script/auto/delete',
  SCENARIO_ADD: DOMAIN_URL + '/script/auto/save',
  SCENARIO_GET_BY_CAPACITY: DOMAIN_URL + '/script/auto/list/by/capacityId',
  SCENARIO_GET_BY_VARIABLE: DOMAIN_URL + '/script/auto/list/by/variable',

  SESSION_UPDATER_INTERVAL: null,

  GET_TAG_LIST: DOMAIN_URL + '/dashboard/tegs',
  ADD_TAG: DOMAIN_URL + '/dashboard/tegs/add',
  DELETE_TAG: DOMAIN_URL + '/dashboard/tegs/delete',
  GET_DASHBOARD_LIST_BY_TAG: DOMAIN_URL + '/dashboard/list/by/tegs',


  IMAGE_UPLOAD: DOMAIN_URL + '/data/upload/image',
  IMAGE_GET: DOMAIN_URL + '/file/download/image'

};
