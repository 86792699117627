import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {localeService} from '../services/locale.service';
import {ReactSVG} from 'react-svg';
import Clock from 'react-live-clock';
import '../styles/header.scss';
import logoSvg from '../sources/images/eco_logo.svg';
import svgCapacity from '../sources/img/capacity.svg';
import svgDashboards from '../sources/img/dashboards.svg';
import svgDevices from '../sources/img/devices.svg';
import svgImport from '../sources/img/import.svg';
import svgScenariums from '../sources/img/scenariums.svg';
import userSvg from '../sources/img/user.svg';
import ChangeLanguage from '../components/ChangeLanguage';
import {useTranslation} from 'react-i18next';

function EcoHeader() {
  let location = useLocation();
  const {t} = useTranslation();
  return (
    <header className={'eco-header'}>
      <div className={'d-flex justify-content-between'} style={{width: '100%'}}>
        <div className={'logo'}>
          <ReactSVG src={logoSvg}/>
          <label>DIGITAL<br/>MONITORING</label>
        </div>
        <ul className="header-menu">
          <li className={location.pathname === '/capacity' ? 'active' : ''}>
            <Link to="/capacity">
              <ReactSVG src={svgCapacity}/>
              <label>Источники</label>
              {/*<label>{t('capacity_list')}</label>*/}
            </Link>
          </li>
          <li className={location.pathname === '/import' ? 'active' : ''}>
            <Link to="/import">
              <ReactSVG src={svgImport}/>
              <label>{t('import')}</label>
            </Link>

          </li>
          <li className={location.pathname === '/device_list/0' ? 'active' : ''}>
            <Link to="/device_list/0">
              <ReactSVG src={svgDevices}/>
              <label>{t('device_list')}</label>
            </Link>
          </li>
          <li className={location.pathname === '/scenario_list' ? 'active' : ''}>
            <Link to="/scenario_list">
              <ReactSVG src={svgScenariums}/>
              <label> {localeService.isRussian() ? 'Сценарии' : 'Scenarios'} </label>
            </Link>
          </li>
          <li className={location.pathname === '/dashboards' ? 'active' : ''}>
            <Link to="/dashboards">
              <ReactSVG src={svgDashboards}/>
              <label>{localeService.isRussian() ? 'Дашборды' : 'Dashboards'}</label>
            </Link>
          </li>
        </ul>
        <div className={'office'}>
          <Link className={'personal-office'} to="/office">
            <ReactSVG src={userSvg}/>
          </Link>
          <div className={'d-flex justify-content-end'}>
            <div className="clock me-3">
              <Clock format={'HH:mm'} ticking={true}/>
            </div>
            <ChangeLanguage/>
          </div>

        </div>
      </div>

    </header>

  );
}

export default EcoHeader;


