import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {localeService} from '../../../services/locale.service';
import ClickOutside from '../../../components/ClickOutside';
// import InputText from '../../../components/InputText';

const CapacitySearch = ({capacityTree, onSelect}) => {
  // const values=capacityTree.

  const recurrentLoader = (parentCapacity, func) => {
    const {capacity} = parentCapacity;
    if (capacity.isGroup && capacity.child) {
      capacity.child.map(item => recurrentLoader({parent: parentCapacity, capacity: item}, func));
      func(parentCapacity);
    } else {
      func(parentCapacity);
    }
  };

  const [capacityList, setList] = React.useState([]);
  const [value, setValue] = useState('');
  React.useEffect(() => {
    const arr = [];

    capacityTree.forEach(item => recurrentLoader({parent: null, capacity: item}, (data) => arr.push(data)));

    setList(arr);
  }, [capacityTree]);
  return (
    <>
      <div className="input-group mb-3">
        <span className="input-group-text">{localeService.isRussian() ? 'Поиск' : 'Search'}</span>
        <input type="text" className="form-control" value={value} onChange={e => setValue(e.target.value)}/>
      </div>

      <ClickOutside disabled={value.length === 0} i onClick={() => setValue('')}>
        <div className={'ml2 dropdown' + (value.length > 0 ? ' open' : '')}
             style={{width: 400, top: 120, minWidth: 400}}>
          <div className={'dropdown-content'}>
            <ul>
              {capacityList.length > 0 && capacityList.filter(({capacity}) => {

                if (capacity.name.indexOf(value) > -1) {
                  return true;
                }
              }).map((item) => <li key={item.capacity.id} onClick={() => {
                onSelect(item);
                setValue('');
              }}>
                {item.capacity.name}
              </li>)}
            </ul>
          </div>
        </div>
      </ClickOutside>
    </>

  );
};

CapacitySearch.propTypes = {
  capacityTree: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default CapacitySearch;
