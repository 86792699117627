import React, {useState} from 'react';
import {localeService} from '../../services/locale.service';
import ImportForm from './ImportEnergoSphere';
import ImportFromFile from './ImportFromFile';
import ImportManual from './ImportManual';
// import {Link} from 'react-router-dom';

function Import() {

    const [importType, setImportType] = useState(null);

    return (<>
        <div className={'page-header pt-5 mb-5'}>
            <div className={'container-700'}>
            <h2>{localeService.isRussian() ? 'Импорт данных' : 'Data Import'}</h2>
                <label>{localeService.isRussian() ? 'Выберите тип импорта' : 'Select Import type'}</label>
            </div>
                <ul className="nav nav-tabs justify-content-center" style={{marginBottom: '-2rem'}}>
                <li className="nav-item">
                    <button className={'nav-link ' + (importType === 'importFromFile' ? 'active' : '')} onClick={() => setImportType('importFromFile')}>
                        {localeService.isRussian() ? 'Импорт из файла' : 'Import from file'}</button>
                </li>
                {/*<li className="nav-item">*/}
                    {/*<button className={'nav-link ' + (importType === 'importForm' ? 'active' : '')} onClick={() => setImportType('importForm')}>*/}
                        {/*{localeService.isRussian() ? 'Импорт из EnergoSphere' : 'Import from EnergoSphere'}</button>*/}
                {/*</li>*/}
                <li className="nav-item">
                    <button className={'nav-link ' + (importType === 'manualImport' ? 'active' : '')} onClick={() => setImportType('manualImport')}>
                        {localeService.isRussian() ? 'Ручной ввод данных' : 'Manual Data Input'}</button>
                </li>
            </ul>
        </div>
        <div>
            {/*<div className={'container-700'}>*/}
                {/*<form>*/}
                    {/*<div className={'form-group'}>*/}
                        {/*<label><h5*/}
                            {/*style={{minWidth: '180px'}}>{localeService.isRussian() ? 'Tип импорта' : 'Import type'}</h5>*/}
                        {/*</label>*/}
                        {/*<div className="input-group">*/}
                            {/*<button type="button"*/}
                                    {/*className={'btn eco-btn default ' + (importType === 'importFromFile' ? 'active' : '')}*/}
                                    {/*onClick={() => setImportType('importFromFile')}>{localeService.isRussian() ? 'Импорт из файла' : 'Import from file'}</button>*/}
                            {/*<button type="button"*/}
                                    {/*className={'btn eco-btn default ' + (importType === 'importForm' ? 'active' : '')}*/}
                                    {/*onClick={() => setImportType('importForm')}>{localeService.isRussian() ? 'Импорт из EnergoSphere' : 'Import from EnergoSphere'}</button>*/}
                            {/*<button type="button"*/}
                                    {/*className={'btn eco-btn default ' + (importType === 'manualImport' ? 'active' : '')}*/}
                                    {/*onClick={() => setImportType('manualImport')}>{localeService.isRussian() ? 'Ручной ввод данных' : 'Manual Data Input'}</button>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</form>*/}
            {/*</div>*/}

            {importType === 'importFromFile' && <ImportFromFile/>}
            {importType === 'importForm' && <ImportForm/>}
            {importType === 'manualImport' && <ImportManual/>}


        </div>
    </>);
}

export default Import;