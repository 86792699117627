import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {dashboardConstants} from '../constants/dashboard.constants';
// import ClickOutside from './ClickOutside';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {ReactSVG} from 'react-svg';
import svgArrow from '../sources/images/interface/arrow.svg';
import DatePicker from 'react-datepicker';
import {localeService} from '../services/locale.service';
// import ClickOutside from './ClickOutside';

function SelectTimeRange({onChange, datetimeFilter}) {
    const {t} = useTranslation();
    const dataList = dashboardConstants.DATETIME_FILTER_LIST;
    const [isCustomDateRangeOpened, setCustomDateRangeOpened] = useState(false);
    const [isCustomMonthOpened, setCustomMonthOpened] = useState(false);
    const [isCustomDayOpened, setCustomDayOpened] = useState(false);
    const [isCustomYearOpened, setCustomYearOpened] = useState(false);
    const [isDropDownOpen, setDropDownOpen] = useState(false);
    const [currentDateFilter, setCurrentDatefilter] = useState(datetimeFilter);

    React.useEffect(() => {
        setCurrentDatefilter(datetimeFilter);
    },[datetimeFilter]);

    const onSelectFilter = (el) => {
        if (el.value === 'custom_month') {
            setCustomMonthOpened(true);
            setCurrentDatefilter({...currentDateFilter, datetimeRange: el, interval: dashboardConstants.TIMER_INTERVAL_LIST[0]});
        } else if (el.value === 'custom') {
            setCustomDateRangeOpened(true);
            setCurrentDatefilter({...currentDateFilter, datetimeRange: el, interval: dashboardConstants.TIMER_INTERVAL_LIST[0]});
        } else if (el.value === 'choose_year') {
            setCustomYearOpened(true);
            setDropDownOpen(true);
            setCurrentDatefilter({...currentDateFilter, datetimeRange: el, interval: dashboardConstants.TIMER_INTERVAL_LIST[0]});
        } else if (el.value === 'custom_day') {
            setCustomDayOpened(true);
            setCurrentDatefilter({...currentDateFilter, datetimeRange: el, interval: dashboardConstants.TIMER_INTERVAL_LIST[0]});
        } else {
            setDropDownOpen(false);
            if (el.value.startsWith('last')) {
                const dateModifier = el.value.replace('last', '').split(',');
                const newDatetimeFilter = {
                    ...datetimeFilter,
                    startDate: moment().subtract(dateModifier[0], dateModifier[1]),
                    finishDate: moment().add(1, 'hours'),
                    datetimeRange: el
                };
                onChange(newDatetimeFilter);
            }
        }
    };

    const applyCustomMonth = () => {
        setCustomMonthOpened(false);
        setDropDownOpen(false);
        const startDate = moment(currentDateFilter.startDate.toDate()).startOf('month');
        const finishDate = moment(currentDateFilter.startDate.toDate()).endOf('month');
        const newDatetimeFilter = {
            ...currentDateFilter,
            startDate: startDate,
            finishDate: finishDate
        };
        onChange(newDatetimeFilter);
    };

    const applyCustomYear = () => {
        setCustomYearOpened(false);
        setDropDownOpen(false);
        const startDate = moment(currentDateFilter.startDate.toDate()).startOf('year');
        const finishDate = moment(currentDateFilter.startDate.toDate()).endOf('year');
        const newDatetimeFilter = {
            ...currentDateFilter,
            startDate: startDate,
            finishDate: finishDate
        };
        onChange(newDatetimeFilter);
    };

    // const CustomTimeInput = ({ time, onChange }) => (
    //     <input
    //         value={time}
    //         onChange={e => onChange(e.target.value)}
    //         style={{ border: 'solid 1px pink' }}
    //     />
    // );

    return (
        <>
            {/*переключение месяца*/}
            {datetimeFilter.datetimeRange.value === 'custom_month' &&
            <div className="select-custom-month">
                <button type={'button'} className={'btn svg-btn'} onClick={() => {
                    const date = moment(currentDateFilter.startDate).subtract(1, 'months');
                    const startDate = moment(date).startOf('month');
                    const finishDate = moment(date).endOf('month');
                    const newDatetimeFilter = {
                        ...currentDateFilter,
                        startDate: startDate,
                        finishDate: finishDate
                    };
                    setCurrentDatefilter(newDatetimeFilter);
                    onChange(newDatetimeFilter);
                }}>
                    <ReactSVG style={{transform: 'rotate(90deg)', width: '20px'}} src={svgArrow}/>
                </button>
                <b onClick={() => {
                    setCustomMonthOpened(true);
                    setDropDownOpen(true);
                }} style={{cursor: 'poiner'}}>{currentDateFilter.startDate.format('YYYY/MM')}</b>
                <button type={'button'} className={'default btn svg-btn'} onClick={() => {
                    const date = moment(currentDateFilter.startDate).add(1, 'months');
                    const startDate = moment(date).startOf('month');
                    const finishDate = moment(date).endOf('month');
                    const newDatetimeFilter = {
                        ...currentDateFilter,
                        startDate: startDate,
                        finishDate: finishDate
                    };
                    setCurrentDatefilter(newDatetimeFilter);
                    onChange(newDatetimeFilter);
                }}>
                    <ReactSVG style={{transform: 'rotate(-90deg)', width: '20px'}} src={svgArrow}/>

                </button>
            </div>
            }

            {/*переключение дня*/}
            {datetimeFilter.datetimeRange.value === 'custom_day' &&
            <div className="select-custom-month">
                <button type={'button'} className={'default btn svg-btn'} onClick={() => {
                    const newDatetimeFilter = {
                        ...currentDateFilter,
                        startDate: moment(currentDateFilter.startDate).subtract(1, 'days'),
                        finishDate: moment(currentDateFilter.finishDate).subtract(1, 'days')
                    };
                    setCurrentDatefilter(newDatetimeFilter);
                    onChange(newDatetimeFilter);
                }}>
                    <ReactSVG style={{transform: 'rotate(90deg)', height: '20px', width: '20px'}} src={svgArrow}/>
                </button>
                <b onClick={() => {
                    setCustomDayOpened(true);
                    setDropDownOpen(true);
                }} style={{cursor: 'pointer'}}>{currentDateFilter.finishDate.format('DD MMM')}</b>
                <button type={'button'} className={'default btn svg-btn'} onClick={() => {
                    const newDatetimeFilter = {
                        ...currentDateFilter,
                        startDate: moment(currentDateFilter.startDate).add(1, 'days'),
                        finishDate: moment(currentDateFilter.finishDate).add(1, 'days')
                    };
                    setCurrentDatefilter(newDatetimeFilter);
                    onChange(newDatetimeFilter);
                }}>
                    <ReactSVG style={{transform: 'rotate(-90deg)', height: '20px', width: '20px'}} src={svgArrow}/>

                </button>
            </div>
            }

            {/*переключение года*/}
            {datetimeFilter.datetimeRange.value === 'choose_year' &&
            <div className="select-custom-month">
                <button type={'button'} className={'default btn svg-btn'} onClick={() => {
                    const newDatetimeFilter = {
                        ...currentDateFilter,
                        startDate: moment(currentDateFilter.startDate).subtract(1, 'years'),
                        finishDate: moment(currentDateFilter.finishDate).subtract(1, 'years')
                    };
                    setCurrentDatefilter(newDatetimeFilter);
                    onChange(newDatetimeFilter);
                }}>
                    <ReactSVG style={{transform: 'rotate(90deg)', width: '20px'}} src={svgArrow}/>
                </button>
                <b onClick={() => {
                    setCustomYearOpened(true);
                    setDropDownOpen(true);
                }} style={{cursor: 'pointer'}}>{currentDateFilter.finishDate.format('YYYY')}</b>
                <button type={'button'} className={'default btn svg-btn'} onClick={() => {
                    const newDatetimeFilter = {
                        ...currentDateFilter,
                        startDate: moment(currentDateFilter.startDate).add(1, 'years'),
                        finishDate: moment(currentDateFilter.finishDate).add(1, 'years')
                    };
                    setCurrentDatefilter(newDatetimeFilter);
                    onChange(newDatetimeFilter);
                }}>
                    <ReactSVG style={{transform: 'rotate(-90deg)', width: '20px'}} src={svgArrow}/>

                </button>
            </div>
            }

            <div className={'dropdown-container datetime-range' + (isDropDownOpen ? ' open' : '')}>
                <label>{localeService.isRussian() ? 'Фильтр времени':'Datetime Filter'}</label>
                {/*<ClickOutside onClick={() => {*/}
                    {/*setDropDownOpen(false);*/}
                    {/*setCurrentDatefilter(datetimeFilter);*/}
                    {/*setCustomDateRangeOpened(false);*/}
                    {/*setCustomMonthOpened(false);*/}
                    {/*setCustomDayOpened(false);*/}
                    {/*setCustomYearOpened(false);*/}
                {/*}}>*/}
                <button className={'btn eco-btn dark outlined selected-item'} style={{height: '2rem'}} onClick={() => {
                    setDropDownOpen(!isDropDownOpen);
                }}>
                    {t(datetimeFilter.datetimeRange.name)}
                </button>

                    <div className={'dropdown' + (isDropDownOpen ? ' open' : '')} style={{maxHeight: '560px!important'}}>
                        {!isCustomMonthOpened && !isCustomDateRangeOpened && !isCustomDayOpened && !isCustomYearOpened &&
                        <ul>
                            {dataList.map((el, i) => {
                                return <li key={'val-' + i} onClick={() => onSelectFilter(el)}><span>{t(el.name)}</span>
                                </li>;
                            })}
                        </ul>
                        }


                        {/*выбор месяца*/}
                        {isCustomMonthOpened &&
                        <div style={{width: '400px', margin: '20px 0'}}>
                            <div className={'year-picker'}>
                                <button className={'btn svg-btn'} onClick={() => {
                                    const date = moment(currentDateFilter.startDate).subtract(1, 'years');
                                    setCurrentDatefilter({...currentDateFilter, startDate: date});
                                }}>
                                    <ReactSVG style={{transform: 'rotate(90deg)'}} src={svgArrow}/>
                                </button>
                                <b style={{letterSpacing: '6px'}}>{currentDateFilter.startDate.format('YYYY')}</b>
                                <button className={'btn svg-btn'} onClick={() => {
                                    const date = moment(currentDateFilter.startDate).add(1, 'years');
                                        setCurrentDatefilter({...currentDateFilter, startDate: date});
                                }}>
                                    <ReactSVG style={{transform: 'rotate(-90deg)'}} src={svgArrow}/>
                                </button>
                            </div>
                            <hr style={{margin: '1rem 0'}}/>
                            <div className={'month-picker'}>
                                {Array.from(Array(12), () => {
                                }).map((el, i) => {
                                    return (
                                        <button type={'button'}
                                                key={'month_selector_' + i}
                                                className={'btn eco-btn default ' + (currentDateFilter.startDate.month() === i ? ' active' : '')}
                                                onClick={() => {
                                                    let date = moment(currentDateFilter.startDate.toDate()).startOf('day');
                                                    date = moment(date).set('month', i);
                                                    setCurrentDatefilter({...currentDateFilter, startDate: date});
                                                }}>{t('months.' + moment().month(i).format('MMMM'))}</button>);
                                })}
                            </div>
                            <hr style={{margin: '1rem 0'}}/>
                            <div className={'d-flex justify-content-center'}>
                                <button type={'button'} className={'btn eco-btn success'}
                                        onClick={() => applyCustomMonth()}>{t('apply')}</button>
                                &#160;&#160;
                                <button type={'button'} className={'btn eco-btn danger'}
                                        onClick={() => setCustomMonthOpened(false)}>{t('cancel')}</button>
                            </div>
                        </div>
                        }

                        {/*выбор года*/}
                        {isCustomYearOpened  &&
                        <div style={{width: '400px', margin: '20px 0'}}>
                            <div className={'year-picker'}>
                                <button className={'btn svg-btn'} onClick={() => {
                                    const date = moment(currentDateFilter.startDate).subtract(1, 'years');
                                    setCurrentDatefilter({...currentDateFilter, startDate: date});
                                }}>
                                    <ReactSVG style={{transform: 'rotate(90deg)'}} src={svgArrow}/>
                                </button>
                                <b style={{letterSpacing: '6px'}}>{currentDateFilter.startDate.format('YYYY')}</b>
                                <button className={'btn svg-btn'} onClick={() => {
                                    const date = moment(currentDateFilter.startDate).add(1, 'years');
                                    setCurrentDatefilter({...currentDateFilter, startDate: date});
                                }}>
                                    <ReactSVG style={{transform: 'rotate(-90deg)'}} src={svgArrow}/>
                                </button>
                            </div>
                            {/*<hr style={{margin: '1rem 0'}}/>*/}
                            {/*<div className={'month-picker'}>*/}
                                {/*{Array.from(Array(12), () => {*/}
                                {/*}).map((el, i) => {*/}
                                    {/*return (*/}
                                        {/*<button type={'button'}*/}
                                                {/*key={'month_selector_' + i}*/}
                                                {/*className={'btn eco-btn default ' + (currentDateFilter.startDate.month() === i ? ' active' : '')}*/}
                                                {/*onClick={() => {*/}
                                                    {/*let date = moment(currentDateFilter.startDate.toDate()).startOf('day');*/}
                                                    {/*date = moment(date).set('month', i);*/}
                                                    {/*setCurrentDatefilter({...currentDateFilter, startDate: date});*/}
                                                {/*}}>{moment().month(i).format('MMMM')}</button>);*/}
                                {/*})}*/}
                            {/*</div>*/}
                            <hr style={{margin: '1rem 0'}}/>
                            <div className={'d-flex justify-content-center'}>
                                <button type={'button'} className={'btn eco-btn success'}
                                        onClick={() => applyCustomYear()}>{t('apply')}</button>
                                &#160;&#160;
                                <button type={'button'} className={'btn eco-btn danger'}
                                        onClick={() => setCustomYearOpened(false)}>{t('cancel')}</button>
                            </div>
                        </div>
                        }


                        {/*выбор конкретного периода времени*/}
                        {isCustomDateRangeOpened &&
                        <div style={{width: '500px', margin: '20px 0'}}>
                            <div className={'row'}>
                                <div className={'col-50'}>
                                    <p style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>с {currentDateFilter.startDate.format('DD-MM-YYYY HH:mm')}</p>
                                    <DatePicker
                                        selected={new Date(currentDateFilter.startDate)}
                                        inline
                                        onChange={(date) => {
                                            setCurrentDatefilter({...currentDateFilter, startDate: moment(date.getTime()).startOf('day')});
                                        }}
                                        dateFormat="DD-MM-YYYY"
                                    />
                                </div>
                                <div className={'col-50'}>
                                    <p style={{
                                        textAlign: 'left',
                                        fontWeight: 'bold'
                                    }}>по {currentDateFilter.finishDate.format('DD-MM-YYYY HH:mm')}</p>
                                    <DatePicker
                                        inline
                                        selected={new Date(currentDateFilter.finishDate)}
                                        onChange={(date) => {
                                            setCurrentDatefilter({...currentDateFilter, finishDate: moment(date.getTime()).endOf('day')});
                                        }}
                                        dateFormat="DD-MM-YYYY HH:mm"
                                        // showTimeInput
                                        // customTimeInput={<CustomTimeInput time={currentDateFilter.finish}/>}
                                    />
                                </div>
                            </div>
                            <hr style={{margin: '1rem 0'}}/>
                            <div className={'d-flex justify-content-center'}>
                                <button type={'button'} className={'btn eco-btn success'}
                                        onClick={() => {
                                            onChange(currentDateFilter);
                                            setCustomDateRangeOpened(false);
                                            setDropDownOpen(false);
                                        }}>{t('apply')}</button>
                                &#160;&#160;
                                <button type={'button'} className={'btn eco-btn danger'}
                                        onClick={() => setCustomDateRangeOpened(false)}>{t('cancel')}</button>
                            </div>
                        </div>
                        }
                        {/*выбор дня*/}
                        {isCustomDayOpened &&
                        <div style={{width: '300px', margin: '20px 0'}}>
                            <div className={'row'}>
                                <DatePicker
                                    selected={new Date(currentDateFilter.startDate)}
                                    inline
                                    onChange={(date) => {
                                        const start = moment(date).startOf('day');
                                        const finish = moment(date).endOf('day');
                                        setCurrentDatefilter({
                                            ...currentDateFilter,
                                            startDate: start,
                                            finishDate: finish
                                        });
                                    }}
                                    dateFormat="DD-MM-YYYY"
                                />
                            </div>
                            <hr style={{margin: '1rem 0'}}/>
                            <div className={'d-flex justify-content-center'}>
                                <button type={'button'} className={'btn eco-btn success'}
                                        onClick={() => {
                                            onChange(currentDateFilter);
                                            setCustomDayOpened(false);
                                            setDropDownOpen(false);
                                        }}>{t('apply')}</button>
                                &#160;&#160;
                                <button type={'button'} className={'btn eco-btn danger'}
                                        onClick={() => setCustomDayOpened(false)}>{t('cancel')}</button>
                            </div>
                        </div>
                        }

                    </div>
                {/*</ClickOutside>*/}
            </div>
        </>
    );
}

SelectTimeRange.propTypes = {
    onChange: PropTypes.func,
    datetimeFilter: PropTypes.object
};

export default SelectTimeRange;