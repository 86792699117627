/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {localeService} from '../services/locale.service';
import '../styles/dropdown.scss';
import ClickOutside from './ClickOutside';
import {deviceService} from '../services/device.service';
import '../styles/tree-view.scss';

// eslint-disable-next-line no-unused-vars
function TreeView({capacityTree, capacity, onSelectDevice}) {
    const [isOpen, setIsOpen] = useState(false); //статус открытия элемента дерерва
    /**
     * Список выбранных нагрузок,
     * Указание списка выбранных нагрузок
     * @type {Array<Capacity>}
     */
    const [selectedCapacity, setSelectedCapacity] = useState(capacity ? {
        id: capacity.id,
        name: capacity.name
    } : {id: null, name: ''});
    const [variable, selectVariable] = React.useState(undefined);


    useEffect(() => {
        onSelectDevice(variable);
       setIsOpen(!isOpen);
    }, [variable]);
    /**
     * Добавление выбранной нагрузки
     * @param {Capacity} capacity
     */
        // eslint-disable-next-line no-unused-vars
    const onChange = (capacity) => {
            setIsOpen(false);
            //const newCapacity = { name: capacity.name, id: capacity.id };
            //setSelectedCapacity(newCapacity);
            //onSelectDevice(newCapacity);
        };

    useEffect(() => {
        const rootCapacity = {
            id: 0,
            name: localeService.isRussian() ? 'Корневой источник' : 'Root Source',
            child: capacityTree
        };
        const capacityName = getSelectedCapacityName(rootCapacity, selectedCapacity.id);
        setSelectedCapacity({...selectedCapacity, name: capacityName});
    }, []);


    return <div className={'dropdown-container' + (isOpen ? ' open' : '')}>
        {variable &&
        <div className="btn-tab-default variable-item">
            <span>&nbsp;{variable.name},&nbsp;{variable.mode},&nbsp;{variable.type}</span>

        </div>
        }
        <span className={'selected-item'} onClick={() => setIsOpen(!isOpen)}>{selectedCapacity.name}</span>
        <ClickOutside onClick={() => setIsOpen(false)}>
            <div className={'dropdown' + (isOpen ? ' open' : '')}>
                <ul>
                    <li><span onClick={() => onChange({
                        id: 0,
                        name: localeService.isRussian() ? 'Корневой источник' : 'Root Source'
                    })}>{localeService.isRussian() ? 'Корневой источник' : 'Root Source'}</span></li>
                    <ul className={'tree-parent open'}>
                        {capacityTree && capacityTree.map((capacity) =>
                            <CapacityChild
                                isRootOpen={isOpen}
                                selectVariable={selectVariable}
                                key={'cap-' + capacity.id}
                                capacity={capacity}
                                onChange={onChange}
                            />
                        )}
                    </ul>
                </ul>
            </div>
        </ClickOutside>
    </div>;
}

function getSelectedCapacityName(capacity, capacityId) {
    if (capacity.id === capacityId) {
        return capacity.name;
    } else {
        if (capacity.child && capacity.child.length > 0) {
            return capacity.child.map(subCapacity => {
                return getSelectedCapacityName(subCapacity, capacityId);
            });
        }
    }

}

//TODO: добавить список переменных
function CapacityChild({capacity, onChange, selectVariable, isRootOpen}) {
    /**
     * Состояние открытия списка дочерних элементов
     */
    const [isOpen, setIsOpen] = useState(false);

    /**
     * -----------------------------------------------
     * Рендер списка элементов
     */
    return isRootOpen && (
        <React.Fragment>
            <li>
                <div className={'tree-item'}>
                    {capacity.child && capacity.child.length > 0 &&
                    <button type={'button'} className={'toggle-tree-item'}
                            onClick={() => setIsOpen(!isOpen)}>{isOpen ? '-' : '+'}</button>}
                    <span onClick={() => setIsOpen(!isOpen)}>{capacity.name}</span>
                </div>
            </li>
            <React.Fragment>
                {capacity.child && capacity.child.length > 0 &&
                <ul className={'tree-parent' + (isOpen ? ' open' : '')}>
                    {capacity.child.map(childCapacity =>
                        <CapacityChild
                            isRootOpen={isOpen}
                            selectVariable={selectVariable}
                            key={'cap-' + childCapacity.id}
                            capacity={childCapacity}
                            onChange={onChange}/>
                    )}
                </ul>
                }
                {isOpen && <MetricChild
                    isRootOpen={isOpen}
                    selectVariable={selectVariable}
                    isOpen={isOpen}
                    capacity={capacity}
                />}
            </React.Fragment>
        </React.Fragment>);
}

// eslint-disable-next-line no-unused-vars
const MetricChild = ({capacity, selectVariable, isRootOpen}) => {
    /**
     * Список переменных @param{Array} virableLisst
     * Назначение списка переменных @param{Array} setVariableLists
     */
        // eslint-disable-next-line no-unused-vars
    const [variableList, setVariableList] = useState([]);
    /**
     * Открытие списка дочерних элементов
     */
    const [metricOpen, setMetricOpen] = useState(false);
    /**
     * Преобразование переменных для выбора в чекбоксе
     * @param {Array} variables
     */
    const mapper = (variables) => variables && variables.length > 0 ? variables.map(item => ({
        ...item,
        active: false
    })) : [];

    useEffect(() => {
        // Получение списка переменных
        deviceService.getVariablesByCapacity(capacity.id).then(item => setVariableList(mapper(item)));
        console.log('update s');
    }, []);
    /**
     * Условие для проверки чекбоксов переменных
     */

    return isRootOpen && variableList && variableList.length > 0 ? (

        <React.Fragment>
            <ul className="tree-parent open">
                <li className="metric-view">
                    <div className={'tree-item'}>
                        {variableList && variableList.length > 0 &&
                        <button type={'button'} className={'toggle-tree-item'}
                                onClick={() => setMetricOpen(!metricOpen)}>{metricOpen ? '-' : '+'}</button>}
                        <span>Список переменных</span>

                    </div>
                </li>
                <ul className={'tree-parent open'}>
                    {metricOpen && variableList.map(metricItem => {
                        metricItem.root = capacity;
                        return <MetricItem
                            active={metricItem.active}
                            variable={metricItem}
                            key={metricItem.id}
                            selectVariable={selectVariable}/>;

                    })}
                </ul>
            </ul>
        </React.Fragment>
    ) : (<p>Загрузка переменных.....</p>);
};
/**
 * Переменная
 * @param {*} param0
 */
const MetricItem = ({variable, selectVariable}) => {
    return (
        <React.Fragment>
            <li className="metric-view">
                <div className={'tree-item'}>
                    <span onClick={() => {
                        selectVariable(variable);
                    }}>{variable.name}</span>

                </div>
            </li>
        </React.Fragment>
    );
};

TreeView.propTypes = {
    capacity: PropTypes.object,
    capacityTree: PropTypes.array.isRequired,
    onSelectDevice: PropTypes.func
};

export default TreeView;
