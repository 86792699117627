import {apiConstants, dashboardConstants} from '../../../constants';
import {localeService} from '../../../services/locale.service';
import Modal from '../../../components/Modal';
import LineWidgetProps from './LinesWidgetCreate';
import LinesWidgetView from './LinesWidgetView';
import React from 'react';
import PropTypes from 'prop-types';
// import BenchmarkingPreview from './BenchmarkingPreview';

function LinesWidget({widgetProps, mode, closeEditSingleMode, sizeParams}) {
    return (
        <>
            {mode === dashboardConstants.VIEW_MODE &&
            <LinesWidgetView widgetProps={widgetProps}/>
            }
            {mode === dashboardConstants.EDITION_MODE &&
            <div className={'widget-preview'} style={{width: sizeParams.width - (12*2) , height: (70 * widgetProps.h) - 30 }}>
                {widgetProps.image && widgetProps.image.id && <img style={{maxWidth: '100%', maxHeight: '100%'}} src={apiConstants.API_URL + '/file/download?fileId=' + widgetProps.image.id}/>}
            </div>
            }
            {/*{mode === dashboardConstants.EDITION_MODE &&*/}
            {/*<BenchmarkingPreview widgetProps={widgetProps} sizeParams={sizeParams}/>*/}
            {/*}*/}
            {mode === dashboardConstants.EDIT_SINGLE_MODE &&
            <Modal isOpen={mode === dashboardConstants.EDIT_SINGLE_MODE} isClosable={false} footer={false} onCancel={() => closeEditSingleMode()} title={localeService.isRussian() ? 'Настройка виджета' : 'Edit Widget'}>
                <LineWidgetProps widgetProps={widgetProps} onClose = {() => closeEditSingleMode()}/>
            </Modal>
            }
        </>
    );
}

LinesWidget.propTypes = {
    widgetProps: PropTypes.object,
    closeEditSingleMode: PropTypes.func,
    sizeParams: PropTypes.object,
    mode: PropTypes.oneOf([dashboardConstants.EDIT_SINGLE_MODE, dashboardConstants.EDITION_MODE, dashboardConstants.VIEW_MODE]),
};

export default LinesWidget;