import React, {useState} from 'react';
import {PropTypes} from 'prop-types';
import {useTranslation} from 'react-i18next';
import InputText from '../../../../components/InputText';
import SelectDate from '../../../../components/SelectDate';
import {deletePassportFile, editCapacityPassport} from '../../../../services/passport.service';
import {localeService} from '../../../../services/locale.service';
import {capacityService} from '../../../../services/capacity.service';
import {apiConstants} from '../../../../constants';
import UploadFileToBlock from './Form/UploadFileToBlock';
import {ReactSVG} from 'react-svg';
import svgDelete from '../../../../sources/images/interface/basket.svg';
import svgDownload from '../../../../sources/images/interface/download.svg';
import AddFieldToPassport from './AddFieldToPassport';
import moment from 'moment';

const EditCapacityPassport = ({isEditionMode, passport, cancelFunc, capacity}) => {

  const [newPassport, setNewPassport] = useState(passport);

  React.useEffect(() => {
    setNewPassport(passport);
  }, [passport]);

  const updateField = (key, text, fn, blockKey) => {
    let nPass = {...newPassport};
    nPass.blocks[blockKey].fields[key].value = text;
    setNewPassport(nPass);
  };

  const reloadPassport = () => {
    capacityService.getCapacity(capacity.id).then(
      capacity => {
        const dynamicPassportList = capacity.passportDynamic;
        const pass = dynamicPassportList.find(el => el.id === passport.id);
        console.log('pass', pass);
        setNewPassport(pass);
      }
    );
  };

  const deleteField = (key, blockKey) => {
    let fields = newPassport.blocks[blockKey].fields;
    fields.splice(key, 1);
    if (key >= 0) {
      newPassport.blocks[blockKey].fields = fields;
      setNewPassport({...newPassport});
    }
  };

  const {t} = useTranslation();

  const updatePassport = () => {
    let requestBody = {
      id: newPassport.id,
      name: newPassport.name,
      capacity_id: capacity.id,
      blocks: newPassport.blocks.map((block, bIdx) => {
        return {
          id: block.id,
          header: block.header,
          order: bIdx,
          fields: block.fields.map((field, fIdx) => {
            let nField = {
              column_name: field.column_name,
              order: fIdx,
              type: field.type,
              value: field.type === 'INTEGER' || field.type === 'BIG_DECIMAL' ? parseFloat(field.value) : field.value
            };
            if(field.type === 'DATE' && field.value.year){
              let date = moment(field.value.year + '/' + field.value.month + '/' + field.value.day);
              nField.value = date.format('YYYY/MM/DD');
            }
            if (field.id) {
              nField.id = field.id;
            }
            return nField;
          })
        };
      })
    };
    editCapacityPassport(requestBody).then(
      () => {
        alert(localeService.isRussian() ? 'Паспорт был успешно обновлен' : 'The Passport was successfully updated!');
        cancelFunc();
      }, error => {
        alert((localeService.isRussian() ? 'Ошибка при обновлении паспорта!  ' : 'Error!  ') + error);
      }
    );
  };

  const deleteFile = (fileId, blockId) => {
    deletePassportFile(fileId, blockId).then(
      (response) => {
        console.log(response);
        reloadPassport();
      },
      error => {
        alert((localeService.isRussian() ? 'Ошибка при удалении файла паспорта!  ' : 'Error!  ') + error);
      }
    );
  };

  return (
    <div className={'container-700'}>
      <form className={'passport-form'}>
        <div className="passport-header-preview">
          {passport.name}
        </div>
        <hr/>
        {newPassport.blocks && newPassport.blocks.map((blocks, blockKey) => {
          return (
            <div key={blockKey}>
              <div className={'d-flex justify-content-between'}>
                <h4>{blocks.header}</h4>
                {isEditionMode && <AddFieldToPassport onAddField={f => {
                  newPassport.blocks[blockKey].fields = [...newPassport.blocks[blockKey].fields, f];
                  setNewPassport({...newPassport});
                }}/>}
              </div>
              {blocks.fields && blocks.fields.map((field, fieldKey) => {
                return (
                    <div key={blockKey + '-' + fieldKey} className={'d-flex justify-content-between'}>
                      {field.type === 'STRING' &&
                      <InputText
                        disable={!isEditionMode}
                        label={field.column_name}
                        value={field.value || ''/*passportData[blockKey][key].value*/}
                        onChange={text => {
                          updateField(fieldKey, text, 'value', blockKey);
                        }}
                      />
                      }
                      {field.type === 'INTEGER' &&
                      <InputText
                        disable={!isEditionMode}
                        label={field.column_name}
                        value={field.value || ''}
                        isInteger={true}
                        onChange={text => {
                          updateField(fieldKey, text, 'value', blockKey);
                        }}
                      />
                      }
                      {field.type === 'BIG_DECIMAL' &&
                      <InputText
                        disable={!isEditionMode}
                        label={field.column_name}
                        value={field.value || ''}
                        isDecimal={true}
                        onChange={text => {
                          updateField(fieldKey, text, 'value', blockKey);
                        }}
                      />
                      }
                      {field.type === 'DATE' && field.value && !isEditionMode &&
                      <InputText
                        disable={!isEditionMode}
                        label={field.column_name}
                        value={(field.value.year && field.value.month && field.value.day) ? moment(field.value.year + '/' + field.value.month + '/' + field.value.day).format('YYYY/MM/DD') : field.value}
                      />
                      }
                      {field.type === 'DATE' && field.value && isEditionMode &&
                      <SelectDate label={field.column_name}
                                  selectedDate={new Date((field.value.year && field.value.month && field.value.day) ?
                                    (field.value.year + '/' + field.value.month + '/' + field.value.day) : field.value)}
                                  onChange={date => updateField(fieldKey, date.format('YYYY/MM/DD'), 'value', blockKey)}/>}

                      {isEditionMode && <button className={'btn eco-btn danger mb-3'}
                              onClick={(e) => {
                                e.preventDefault();
                                window.confirm(localeService.isRussian() ? 'Вы уверены?' : 'Are you sure?') ? deleteField(fieldKey, blockKey) : e.preventDefault();
                              }}
                              title={localeService.isRussian() ? 'Удалить поле' : 'Delete field'}>
                        <ReactSVG src={svgDelete}/>
                      </button>}
                    </div>
                );
              })}

              <div className={'d-flex justify-content-end mt-3 me-3'}>
                {isEditionMode && <button
                  className="btn eco-btn default "
                  onClick={(e) => {
                    e.preventDefault();
                    updatePassport();
                  }}
                >
                  {t('update') + ' ' + t('passport.name')}
                </button>}
              </div>

              <div className={'mt-5 d-flex align-items-center'}>
                <p
                  className={'help-text me-3'}>{localeService.isRussian() ? 'Прикрепленные файлы' : 'Attached Files'}</p>
                {isEditionMode && <UploadFileToBlock blockId={blocks.id} onUpload={() => reloadPassport()}/>}
              </div>
              <ul className={'passport-file-block mt1'}>
                {blocks.fields && blocks.fields.some(field => field.type === 'FILE') && blocks.fields.filter(el => el.type === 'FILE').map((field, fileKey) => {
                  return <li key={fileKey}>
                    <p className={'help-text'}>{field.column_name}</p>
                    <div>
                      <button className={'default btn svg-btn'}
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(apiConstants.PASSPORT_DOWNLOAD + '?fileId=' + field.value.id, '_self');
                              }}
                              title={localeService.isRussian() ? 'Скачать файл' : 'Download file'}>
                        <ReactSVG src={svgDownload}/>
                      </button>
                      {isEditionMode && <button className={'danger btn svg-btn ml1'}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  window.confirm(localeService.isRussian() ? 'Вы уверены?' : 'Are you sure?') ? deleteFile(field.id, blocks.id) : e.preventDefault();
                                                }}
                                                title={localeService.isRussian() ? 'Удалить файл' : 'Delete file'}>
                        <ReactSVG src={svgDelete}/>
                      </button>}
                    </div>
                  </li>;
                })}
              </ul>
            </div>
          );
        })}

        <div className="d-flex justify-content-end">
          <button
            className="btn eco-btn danger "
            onClick={(e) => {
              e.preventDefault();
              cancelFunc();
            }}
          >
            {t('close')}
          </button>
        </div>


      </form>
    </div>
  );
};

EditCapacityPassport.propTypes = {
  passport: PropTypes.object,
  capacity: PropTypes.object,
  cancelFunc: PropTypes.func,
  isEditionMode: PropTypes.bool
};

export default EditCapacityPassport;