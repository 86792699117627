import React from 'react';
import PropTypes from 'prop-types';
import {localeService} from '../../../services/locale.service';
import {dashboardConstants} from '../../../constants';
import Modal from '../../../components/Modal';
import DataSetterConfig from './DataSetterCreate';
import {useTranslation} from 'react-i18next';
import DataSetterView from './DataSetterView';

function DataSetter({widgetProps, mode, closeEditSingleMode}) {
    const {t} = useTranslation();
    return <>
        {mode === dashboardConstants.VIEW_MODE &&
        <DataSetterView widgetProps={widgetProps}/>
        }
        {mode === dashboardConstants.EDITION_MODE &&
        <form>
            <div style={{
                // height: (100 * widgetProps.h) - (widgetProps.name !== '' ? 40 : 0),
                display: 'flex',
                alignItems: 'center',
                // marginTop: widgetProps.name === '' ? '-2rem' : 0,
            }}>
                <button className={'btn eco-btn success form-control'}
                        disabled={true}>{(widgetProps.parameters && widgetProps.parameters.btnName) || t('data_setter')}</button>
            </div>
        </form>
        }
        {mode === dashboardConstants.EDIT_SINGLE_MODE &&
        <Modal isOpen={mode === dashboardConstants.EDIT_SINGLE_MODE} isClosable={false} footer={false}
               onCancel={() => closeEditSingleMode()}
               title={localeService.isRussian() ? 'Настройка виджета' : 'Edit Widget'}>
            <DataSetterConfig widgetProps={widgetProps} onClose={() => closeEditSingleMode()}/>
        </Modal>
        }
    </>;
}

DataSetter.propTypes = {
    widgetProps: PropTypes.object,
    closeEditSingleMode: PropTypes.func,
    mode: PropTypes.oneOf([dashboardConstants.EDIT_SINGLE_MODE, dashboardConstants.EDITION_MODE, dashboardConstants.VIEW_MODE]),
};

export default DataSetter;