import {getCurrentUser} from './login.service';

export function canDo(actionName) {
  const user = getCurrentUser();
  if (user.sessionResp.companyPost[0].accessibleRole) {
    const roles = user.sessionResp.companyPost[0].accessibleRole;
    if (roles.includes(actionName) || roles.includes('ROLE_ADMIN')) {
      return true;
    }else{
      return false;
    }
  }else{
    return false;
  }

  // const sessProps = JSON.parse(sessionStorage.getItem('sess_resp'))
  // if (sessProps && sessProps.roles && sessProps.roles.includes('ROLE_ADMIN') >= 0) {
  //   return true;
  // }
  // return false;
}

export const permitionsService = {
  canDo
};


// const user = {
//   sessionResp: {
//     authenticationToken: 019826
//     f0-8
//     cd7-4
//     bbb-ba08 - d830963b80e4,
//     sessionExpiresIn: 3600,
//     roles: [ROLE_USER],
//     companyPost: [{
//       organizationId: 1,
//       organizationName: ECOMONITORING,
//       organizationPostId: 7,
//       OrganizationPostName: Главный редактор,
//       accessibleRole: [ROLE_CREATE_NEW_ORGANIZATION, ROLE_ADD_NEW_MEMBER, ROLE_DELETE_NEW_MEMBER, ROLE_ADD_NEW_DEVICE, ROLE_ADD_DELETE_DEVICE, ROLE_ADD_NEW_DASHBOARD, ROLE_ADD_DELETE_DASHBOARD]
//     }],
//     status: SUCCESS
//   }, organizationID: 1, userAgentToken: DlCCPgGYYv
// };


// const admin = {
//   sessionResp: {
//     authenticationToken: c4f79747 - 8865 - 4449 - 8
//     de3-c5f3e562dcf1,
//     sessionExpiresIn: 3600,
//     roles: [],
//     companyPost: [{
//       organizationId: 1,
//       organizationName: ECOMONITORING,
//       organizationPostId: 1,
//       OrganizationPostName: Администратор,
//       accessibleRole: [ROLE_ADMIN]
//     }],
//     status: SUCCESS
//   }, organizationID: 1, userAgentToken: xvZeHY34BW
// };

// const ROLES = [
//   {
//     "id": 1,
//     "name": "ROLE_ADMIN"
//   },
//   {
//     "id": 2,
//     "name": "ROLE_USER"
//   },
//   {
//     "id": 3,
//     "name": "ROLE_CREATE_NEW_ORGANIZATION"
//   },
//   {
//     "id": 4,
//     "name": "ROLE_ADD_NEW_MEMBER"
//   },
//   {
//     "id": 5,
//     "name": "ROLE_DELETE_NEW_MEMBER"
//   },
//   {
//     "id": 6,
//     "name": "ROLE_ADD_NEW_DEVICE"
//   },
//   {
//     "id": 7,
//     "name": "ROLE_ADD_DELETE_DEVICE"
//   },
//   {
//     "id": 8,
//     "name": "ROLE_ADD_NEW_DASHBOARD"
//   },
//   {
//     "id": 9,
//     "name": "ROLE_ADD_DELETE_DASHBOARD"
//   }
// ];