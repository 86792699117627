export const capacityConstants = {
  GET_DELIVERY_PASSPORT: () => ({
    type: 'delivery',
    name: 'Паспорта точек учета',
    groups: [
      {
        name: null,
        items: [
          {
            type: 'number',
            id: 'number',
            label: '№ Точки учета',

            value: '',
          },
          {
            id: 'name',
            label: 'Наименование объекта',

            value: '',
          },
        ],
      },
      {
        name: 'Данные по точкам учета электроэнергии',
        items: [
          {
            id: 'dType',
            label: 'Тип установленного счетчика',

            value: '',
          },
          {
            id: 'dAccuracyClass',
            label: 'Класс точности',

            value: '',
          },
          {
            id: 'dTypeAR',
            label: 'Вид и направление измеряемой энергии',

            value: '',
          },

          {
            id: 'dDateNextCheck',
            type: 'datepicker',
            label: 'Дата следующей проверки',

            value: '',
          },
          {
            id: 'dPaasport',
            type: 'uploader',
            label: 'Паспорт',

            value: '',
          },
          {
            id: 'dInspectionReport',
            type: 'uploader',
            label:
              'Акт осмотра приборов учета инспектора сетевой/сбытовой компании',

            value: '',
          },
        ],
      },
      {
        name: 'Данные по ТТ',
        items: [
          {
            id: 'ttType',
            label: 'Тип ТТ',

            value: '',
          },
          {
            id: 'ttCofTransform',
            label: 'Коэф. трансформации',
            type: 'number',
            value: '',
          },
          {
            id: 'ttCount',
            type: 'number',
            label: 'Кол-во',

            value: '',
          },
          {
            id: 'thDateNextCheck',
            type: 'datepicker',
            label: 'Дата следующей проверки',

            value: '',
          },
          {
            id: 'thPaasport',
            type: 'uploader',
            label: 'Паспорт',

            value: '',
          },
        ],
      },
      {
        items: [
          {
            id: 'subAbonent',
            type: 'checkbox',
            label: 'Признак отнесения к субабонетам/транзитным точкам (да/нет)',
            value: false,
          },
          {
            id: 'channelConnect',
            label: 'Канал связи',
            value: '',
          },
          {
            id: 'comment',
            label: 'Примечание',
            value: '',
          },
        ],
      },
    ],
  }),
  GET_CONNECT_PASSPORT: () => ({
    type: 'connect',
    name: 'Информации о присоеденении',
    groups: [
      {
        items: [
          {
            id: 'maxPower',
            type: 'number',
            label: 'Максимально разрешенная мощность, кВт',
            value: '',
          },
          {
            id: 'connectPower',
            type: 'number',
            label: 'Присоединенная мощность, кВА',
            value: '',
          },
          {
            id: 'tariffLevelVolt',
            type: 'number',
            label: 'Тарифный уровень напряжения',
            value: '',
          },
          {
            id: 'priceCategory',
            type: 'number',
            label: 'Ценовая категория',
            value: '',
          },
          {
            id: 'actDemarcation',
            type: 'uploader',
            label:
              'Акт разграничения балансовой принадлежности / Акт технологического присоединения',
            value: '',
          },
          {
            id: 'energyContract',
            type: 'uploader',
            label: 'Договор энергоснабжения',
            value: '',
          },
        ],
      },
    ],
  }),
  GET_STORAGE_PASSPORT: () => ({
    type: 'storage',
    name: 'Паспорта накопителя',
    groups: [
      {
        items: [
          {
            id: 'powerStorage',
            type: 'number',
            label: 'Мощность накопителя (отдача), кВт',

            value: '',
          },
          {
            id: 'capacityStorage',
            type: 'number',
            label: 'Емкость  накопителя, кВт*ч',

            value: '',
          },
          {
            id: 'efficiencyStorage',
            type: 'number',
            label: 'КПД накопителя (заряд/разряд)',

            value: '',
          },
          {
            id: 'timeFullDischarge',
            type: 'number',
            label: 'Время полного разряда',

            value: '',
          },
          {
            id: 'timeFullCharge',
            type: 'number',
            label: 'Время полного заряда',

            value: '',
          },
          {
            id: 'factorLowPower',
            type: 'number',
            label: 'Коэффициент падения отдачи мощности в зависимости от часов работы*',

            value: '',
          },
          {
            id: 'passport',
            type: 'uploader',
            label: 'Паспорт ',
            value: '',
          },
        ],
      },
    ],
  }),
};
