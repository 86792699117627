import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {dashboardConstants} from '../constants/dashboard.constants';
import ClickOutside from './ClickOutside';
import {useTranslation} from 'react-i18next';
// import {ReactSVG} from 'react-svg';
// import svgUpdate from '../sources/images/interface/update.svg';
import {localeService} from '../services/locale.service';

function SelectTimerInterval({onChange, datetimeFilter}) {
    const {t} = useTranslation();
    const timerList = dashboardConstants.TIMER_INTERVAL_LIST;
    const [isDropDownOpen, setDropDownOpen] = useState(false);

    // useEffect(() => {
    //     setCurrentDatefilter(datetimeFilter);
    // },[datetimeFilter]);

    const onSelectTimer = (el) => {
        setDropDownOpen(false);
        onChange(el);
    };

    return (
        <>
            <div className={'dropdown-container datetime-range ml1' + (isDropDownOpen ? ' open' : '')}>
                <label>{localeService.isRussian() ? 'Автообновление':'Auto update'}</label>
                <button className={'btn eco-btn dark selected-item'}
                        style={{
                            display: 'flex',
                            padding: '0 2rem 0 0.5rem',
                            alignItems: 'center',
                            height: '2rem',
                            minWidth: '8rem',
                            justifyContent: 'space-between'
                        }}
                        title={localeService.isRussian() ? 'Автообновление' : 'Auto-update'}
                        onClick={() => setDropDownOpen(!isDropDownOpen)}>
                    {/*{<ReactSVG src={svgUpdate}/>}*/}
                    {datetimeFilter.interval.name}
                </button>
                <ClickOutside onClick={() => setDropDownOpen(false)}>
                    <div className={'dropdown' + (isDropDownOpen ? ' open' : '')}>
                        <ul>
                            {timerList.map((el, i) => {
                                return <li key={'val-' + i} onClick={() => onSelectTimer(el)}><span>{t(el.name)}</span>
                                </li>;
                            })}
                        </ul>
                    </div>
                </ClickOutside>
            </div>
        </>
    );
}

SelectTimerInterval.propTypes = {
    onChange: PropTypes.func,
    datetimeFilter: PropTypes.object
};

export default SelectTimerInterval;