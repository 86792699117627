import {analyticService} from './analyticService';
import {dashboardService} from './dashboard.service';

export const capacityCostCalculationService = {
    initMethods,
    runCalculate,
    getPeakHour,
    getPeakHours
};

export function runCalculate(methodList, args, widgetProps, datetimeFilter) {
    return Promise.all(
        methodList.map(method => {
            const paramFunction = {
                name: 'electricity-cost-calculation',
                args: [...args,
                    {
                        name: 'method',
                        count: 1,
                        values: [method.methodName]
                    },
                    {
                        name: 'region',
                        count: 1,
                        values: [widgetProps.parameters.region.name]
                    },
                    {
                        name: 'retailer',
                        count: 1,
                        values: [widgetProps.parameters.retailer.name]
                    },
                ]
            };
            console.log('paramFunction', paramFunction);
            return analyticService.calculateElectricityCost(parseInt(widgetProps.dataSources[0].id), paramFunction, datetimeFilter).then(
                response => {
                    return {...method, value: response.length === 1 ? response[0].value : '?'};
                }
            );
        })
    );
    // .then(results => {
    //     console.log('Calculation Results', results);
    // });
}

export function initMethods(priceCategory, widgetType) {
    let methods = [];
    if (widgetType === 'AVERAGE_COST') {
        methods = [
            {
                name: 'val_summ_kw',
                methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_summ_kw',
                description: 'Сумма всех КВт'
            },
            {
                name: 'val_total',
                methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_total',
                description: 'Общая стоимость, руб'
            }
        ];
    }
    if (widgetType === 'ELECTRICITY_COST') {
        methods = [
            {
                name: 'val_transfer',
                methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_transfer',
                description: 'Стоимость за передачу электроэнерги, руб',
                color: dashboardService.getRandomColor(0)
            }
        ];
        if (priceCategory === 'one_category_*') {
            methods = [...methods,
                {
                    name: 'val_calculate_ee',
                    methodName: 'one_category_val_calculate_ee',
                    description: 'Стоимость электроэнергии, руб',
                    color: dashboardService.getRandomColor(1)
                }
            ];

        }
        if (priceCategory === 'two_category_two_zones_*') {
            methods = [...methods,
                {
                    name: 'val_calculate_ee_night',
                    methodName: 'two_category_two_zones_val_calculate_ee_night',
                    description: 'Стоимость электроэнергии в ночное время, руб',
                    color: dashboardService.getRandomColor(1)
                },
                {
                    name: 'val_calculate_ee_day',
                    methodName: 'two_category_two_zones_val_calculate_ee_day',
                    description: 'Стоимость электроэнергии в дневное время, руб',
                    color: dashboardService.getRandomColor(2)
                }
            ];
        }
        if (priceCategory === 'two_category_three_zones_*') {
            methods = [...methods,
                {
                    name: 'val_calculate_ee_night',
                    methodName: 'two_category_three_zones_val_calculate_ee_night',
                    description: 'Стоимость электроэнергии в ночное время, руб',
                    color: dashboardService.getRandomColor(1)
                },
                {
                    name: 'calculate_ee_semipeak',
                    methodName: 'two_category_three_zones_val_calculate_ee_semipeak',
                    description: 'Стоимость электроэнергии в полупиковое время, руб',
                    color: dashboardService.getRandomColor(2)
                },
                {
                    name: 'calculate_ee_peak',
                    methodName: 'two_category_three_zones_val_calculate_ee_peak',
                    description: 'Стоимость электроэнергии в пиковое время, руб',
                    color: dashboardService.getRandomColor(3)
                }
            ];
        }

        if (priceCategory === 'three_category_*') {
            methods = [...methods,
                {
                    name: 'val_calculate_ee',
                    methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_calculate_ee',
                    description: 'Стоимость электроэнергии, руб',
                    color: dashboardService.getRandomColor(1)
                },
                {
                    name: 'val_power_cost',
                    methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_power_cost',
                    description: 'Стоимость мощности, руб',
                    color: dashboardService.getRandomColor(2)
                }
            ];
        }        

        if (priceCategory === 'four_category_*') {
            methods = [...methods,
                {
                    name: 'val_calculate_ee',
                    methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_calculate_ee',
                    description: 'Стоимость электроэнергии, руб',
                    color: dashboardService.getRandomColor(1)
                },
                {
                    name: 'val_power_cost',
                    methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_power_cost',
                    description: 'Стоимость мощности, руб',
                    color: dashboardService.getRandomColor(2)
                },
                {
                    name: 'val_maintenance',
                    methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_maintenance',
                    description: 'Стоимость за содержание, руб',
                    color: dashboardService.getRandomColor(3)
                }

            ];
        }
    }
    if (widgetType === 'COST_MAKING') {
        methods = [
            {
                name: 'val_summ_kw',
                methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_summ_kw',
                description: 'Сумма всех КВт',
            },
            {
                name: 'val_transfer',
                methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_transfer',
                description: 'Стоимость за передачу электроэнерги, руб'
            },
            {
                name: 'val_total',
                methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_total',
                description: 'Общая стоимость, руб'
            }
        ];
        if (priceCategory === 'one_category_*') {
            methods = [...methods,
                {
                    name: 'val_calculate_ee',
                    methodName: 'one_category_val_calculate_ee',
                    description: 'Стоимость электроэнергии, руб'
                }
            ];

        }
        if (priceCategory === 'two_category_two_zones_*') {
            methods = [...methods,
                {
                    name: 'val_calculate_ee_night',
                    methodName: 'two_category_two_zones_val_calculate_ee_night',
                    description: 'Стоимость электроэнергии в ночное время, руб'
                },
                {
                    name: 'val_calculate_ee_day',
                    methodName: 'two_category_two_zones_val_calculate_ee_day',
                    description: 'Стоимость электроэнергии в дневное время, руб'
                }
            ];
        }
        if (priceCategory === 'two_category_three_zones_*') {
            methods = [...methods,
                {
                    name: 'val_calculate_ee_night',
                    methodName: 'two_category_three_zones_val_calculate_ee_night',
                    description: 'Стоимость электроэнергии в ночное время, руб'
                },
                {
                    name: 'calculate_ee_semipeak',
                    methodName: 'two_category_three_zones_val_calculate_ee_semipeak',
                    description: 'Стоимость электроэнергии в полупиковое время, руб'
                },
                {
                    name: 'calculate_ee_peak',
                    methodName: 'two_category_three_zones_val_calculate_ee_peak',
                    description: 'Стоимость электроэнергии в пиковое время, руб'
                }
            ];
        }

        if (priceCategory === 'three_category_*') {
            methods = [...methods,
                {
                    name: 'val_sales_add',
                    methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_sales_add',
                    description: 'Стоимость сбытовой надбавки, руб'
                },
                {
                    name: 'val_calculate_ee',
                    methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_calculate_ee',
                    description: 'Стоимость электроэнергии, руб'
                },
                {
                    name: 'val_power_cost',
                    methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_power_cost',
                    description: 'Стоимость мощности, руб'
                },
                {
                    name: 'val_other_services',
                    methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_other_services',
                    description: 'Стоимость за иные услуги, руб'
                }
            ];
        }

        if (priceCategory === 'four_category_*') {
            methods = [...methods,
                {
                    name: 'val_sales_add',
                    methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_sales_add',
                    description: 'Стоимость сбытовой надбавки, руб'
                },
                {
                    name: 'val_calculate_ee',
                    methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_calculate_ee',
                    description: 'Стоимость электроэнергии, руб'
                },
                {
                    name: 'val_power_cost',
                    methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_power_cost',
                    description: 'Стоимость мощности, руб'
                },
                {
                    name: 'val_other_services',
                    methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_other_services',
                    description: 'Стоимость за иные услуги, руб'
                },
                {
                    name: 'val_maintenance',
                    methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_maintenance',
                    description: 'Стоимость за содержание (услуга 4 ЦК мощность), руб'
                }

            ];
        }
    }
    if (widgetType === 'STORAGE_EFFECT') {
            methods = [
                {
                    name: 'val_calculate_ee',
                    methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_calculate_ee',
                    description: 'Стоимость электроэнергии, руб',
                    color: dashboardService.getRandomColor(1)
                },
                {
                    name: 'val_power_cost',
                    methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_power_cost',
                    description: 'Стоимость мощности, руб',
                    color: dashboardService.getRandomColor(2)
                },               
                {
                    name: 'val_total',
                    methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_power_cost',
                    description: 'Общая стоимость, руб',
                    color: dashboardService.getRandomColor(2)
                }
            ];       
    }
    return methods;
}

export function getPeakHour(methodList, args, widgetProps, datetimeFilter) {
    return Promise.all(
        methodList.map(method => {
            const paramFunction = {
                name: 'electricity-cost-calculation',
                args: [...args,
                    {
                        name: 'method',
                        count: 1,
                        values: [method.methodName]
                    },
                    {
                        name: 'region',
                        count: 1,
                        values: [widgetProps.parameters.region.name]
                    },
                    {
                        name: 'retailer',
                        count: 1,
                        values: [widgetProps.parameters.retailer.name]
                    },
                ]
            };
            if(widgetProps.dataSources[0]){
                return analyticService.calculateElectricityCost(parseInt(widgetProps.dataSources[0].id), paramFunction, datetimeFilter).then(
                    response => {
                        return {...method, value: response.length > 1 ? response : '?'};
                    }
                );
            }

        })
    );
}

export function getPeakHours(params, datetimeFilter) {
    const paramFunction = {
                name: 'electricity-cost-calculation',
                args: [
                    {
                        name: 'method',
                        count: 1,
                        values: ['peak_hours']
                    },
                    {
                        name: 'region',
                        count: 1,
                        values: [params.regionName]
                    },
                    {
                        name: 'retailer',
                        count: 1,
                        values: [params.retailerName]
                    },
                    {
                        name: 'start_peaks_date',
                        count: 1,
                        values: [params.startPeakDate.format('YYYY-MM-DD HH:mm:ss')]
                    }
                ]
            };
            console.log('paramFunction', paramFunction);
            return analyticService.calculateElectricityCost(params.dataSourceId, paramFunction, datetimeFilter);
}