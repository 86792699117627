import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {ReactSVG} from 'react-svg';
import svgEdit from '../../sources/images/interface/edit.svg';
import Modal from '../../components/Modal';
import {localeService} from '../../services/locale.service';
import InputText from '../../components/InputText';
import {useTranslation} from 'react-i18next';
import Loader from '../../components/Loader';
import {organizationService} from '../../services/organization.service';
import AlertError from '../../components/AlertError';
import Select from '../../components/Select';
import {canDo} from '../../services/permitions.service';

function EditOrganizationUser({editableUser, roleList, onSubmit}) {
    const {t} = useTranslation();
    const [user, setUser] = useState(editableUser);
    const [isEditOpened, setEditOpened] = useState(false);
    const [isChanged, setChanged] = useState(false);
    const [isConfirm, setConfirm] = useState(false);
    const [isFetching, setFetching] = useState(false);
    const [error, setError] = useState('');

    React.useEffect(() => {
        if(user !== editableUser){
            setChanged(true);
        }
    });

    const deleteUser = () => {
      setFetching(true);
      organizationService.deleteUser(user.id, user.posts[0].id).then(
          () => {
              setEditOpened(false);
              setConfirm(false);
              setError('');
              onSubmit();
          },error => {
              console.log(error);
              setError((localeService.isRussian() ? 'Невозможно удалить пользователя: ' : 'Cannot delete user: ' ) + error);
          }
      ).finally(() => setFetching(false));
    };

    const applyChanges = () => {
        setFetching(true);
        organizationService.editUser({
            firstName: user.first_name,
            id: user.id,
            lastName: user.last_name,
            middleName: user.middle_name,
            organizationPostId: user.posts[0].id
        }).then(
            () => {
                setEditOpened(false);
                setConfirm(false);
                setError('');
                onSubmit();
            }
            ,error => setError((localeService.isRussian() ? 'Невозможно обновить информацию пользователя: ' : 'Cannot update user: ' ) + error)
        ).finally(() => setFetching(false));
    };

    return <>
            <button className={'btn svg-btn'} onClick={() => setEditOpened(true)}>
                <ReactSVG src={svgEdit}/>
            </button>
        <Modal isOpen={isEditOpened} footer={false} isClosable={false} onCancel={() => setEditOpened(false)}
               title={localeService.isRussian() ? 'Редактирование пользователя' : 'Edit User'}>
            {!isFetching && !error &&
            <>
                {!isConfirm && <form>
                    <InputText label={t('first_name')} value={user.first_name}
                               onChange={text => setUser({...user, first_name: text})}/>
                    <InputText label={t('middle_name')} value={user.middle_name}
                               onChange={text => setUser({...user, middle_name: text})}/>
                    <InputText label={t('last_name')} value={user.last_name}
                               onChange={text => setUser({...user, last_name: text})}/>
                    <Select label={t('role')} valueList={roleList} value={user.posts[0] || null}
                    onSelect={role => setUser({...user, posts:[role]})}/>
                    <hr/>
                    <div className={'d-flex justify-content-center'}>
                        <button className={'btn eco-btn danger mr1'} onClick={() => setConfirm(true)}>{t('delete')}</button>
                        {isChanged && canDo() && <button className={'btn eco-btn success mr1'}
                                              onClick={() => applyChanges()}>{t('apply')}</button>}
                        <button className={'btn eco-btn default'}
                                onClick={() => setEditOpened(false)}>{t('cancel')}</button>
                    </div>
                </form>}
                {isConfirm &&
                <form>
                    <h5 style={{textAlign: 'center'}}>{localeService.isRussian() ? 'Вы уверенны, что хотите удалить пользователя?' : 'Are you sure, you want to delete this user from Organization Account?'}</h5>
                    <hr/>
                    <div className={'d-flex justify-content-center'}>
                        <button className={'btn eco-btn danger mr1'} onClick={() => deleteUser()}>{t('yes')}</button>
                        <button className={'btn eco-btn default'} onClick={() => setConfirm(false)}>{t('cancel')}</button>
                    </div>
                </form>
                }
            </>
            }
            {isFetching && <Loader/>}
            <AlertError isOpen={error!== ''} message={error} onCancel={() => {
                setEditOpened(false);
                setConfirm(false);
                setError('');
                setUser(editableUser);
            }}/>

        </Modal>
    </>;
}

EditOrganizationUser.propTypes = {
    editableUser: PropTypes.object,
    roleList: PropTypes.array,
    onSubmit: PropTypes.func
};


export default EditOrganizationUser;