import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {localeService} from '../../services/locale.service';
import Select from '../../components/Select';
import InputText from '../../components/InputText';

function AddFunctionToDatasource({aggregationFunctionList, analyticFunctionList, onApplyFunction, appliedFunction, influxOnly}) {


  const [selectedFunction, setSelectedFunction] = useState(appliedFunction);
  const [availableFunctionList, setFunctionList] = useState(aggregationFunctionList);

  React.useEffect(() => {
    if(!influxOnly) {
      const analyticFuncList = analyticFunctionList.filter(el => el.code === 'DEMAND_RESPONSE_CHECK' || el.code === 'DEMAND_RESPONSE_BOOLEAN' || el.code === 'DEMAND_RESPONSE_RRMSE');
      setFunctionList([...availableFunctionList, ...analyticFuncList]);
    }
  }, []);

  // React.useEffect(() => {
  //     if(appliedFunction !== undefined){
  //         const idx = availableFunctionList.findIndex(el => el.code === appliedFunction.functionCode);
  //         if(idx >= 0){
  //             console.log(availableFunctionList[idx].name);
  //         }
  //
  //     }
  // }, [appliedFunction]);

  const onChange = (func) => {
    setSelectedFunction(func);
    onApplyFunction(func);
  };


  return <>
    <div className={'group'}>
      <label
        className={'group-header'}>{localeService.isRussian() ? 'Применить функцию ' : 'Apply Function'}</label>
      <Select label={localeService.isRussian() ? 'Выберите функцию' : 'Select Function'}
              valueList={availableFunctionList || []}
              value={selectedFunction}
              onSelect={f => {
                let selectedFunc = {
                  functionCode: f.code,
                  functionParams: Array.from(Array(f.countParam), () => 0),
                  functionName: f.name
                };
                onChange(selectedFunc);
              }}/>
      {selectedFunction && selectedFunction.functionParams && !selectedFunction.functionCode.startsWith('DEMAND') && selectedFunction.functionParams.length > 0 &&
      Array.from(Array(selectedFunction.functionParams.length).keys()).map(i => {
        return (<InputText key={'funcParam' + i}
                           label={(localeService.isRussian() ? 'Необходимый для функции параметр ' : 'Required Function Parameter ') + (i + 1)}
                           isInteger={true}
                           value={selectedFunction.functionParams[i]}
                           onChange={(text) => {
                             let funcParams = selectedFunction.functionParams;
                             funcParams[i] = text;
                             onChange({...selectedFunction, functionParams: funcParams});
                           }}
        />);
      })
      }
    </div>

  </>;
}

AddFunctionToDatasource.propTypes = {
  aggregationFunctionList: PropTypes.array,
  analyticFunctionList: PropTypes.array,
  onApplyFunction: PropTypes.func,
  selectedFunction: PropTypes.object,
  appliedFunction: PropTypes.object,
  influxOnly: PropTypes.bool
};

AddFunctionToDatasource.defaultProps = {
  influxOnly: false
};

const mapStateToProps = state => {
  const aggregationFunctionList = state.analyticReducer.aggregationFunctionList;
  const analyticFunctionList = state.analyticReducer.analyticFunctionList;
  return {aggregationFunctionList, analyticFunctionList};
};


export default connect(mapStateToProps, null)(AddFunctionToDatasource);