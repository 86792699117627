import {apiService} from './api.service';
import {apiConstants} from '../constants';
import {loginService} from './login.service';

export function sendPassport(passport, passportType) {
  const user = loginService.getCurrentUser();
  let formData = new FormData();
  for (let [key, value] of Object.entries(passport)) {
    if (value !== '' && value !== undefined) {
      if (value instanceof Blob) {
        formData.append(key, value, value.name);
      } else
        formData.append(key, value);

    }


  }
  const config = {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + user.sessionResp.authenticationToken,
      'user-agent-token': user.userAgentToken,
    },
    body: formData
  };

  let type;
  switch (passportType) {
    case 'connect':
      type = apiConstants.PASSPORT_ADD_CONNECT;
      break;
    case 'delivery':
      type = apiConstants.PASSPORT_ADD_DELIVERY;
      break;
    case 'storage':
      type = apiConstants.PASSPORT_ADD_STORAGE;
  }
  const url = apiService.addProps(type);
  return fetch(url, config)
    .then(response => {
      response;
      console.log(response);
      const jResponse = response.json();
      if (!response.ok) {
        return jResponse.then(() => Promise.reject('cannot send Passport data'));
      }
      return jResponse;
    });

}

export function createPassport(passportBlocks, passportName) {
  return apiService.fetchPost(apiConstants.PASSPORT_CREATE,
    JSON.stringify({
      blocks: passportBlocks,
      name: passportName,
    }));
}

export function createCapacityPassport(passportBlocks, passportName, capacityId) {
  const newPassport = {
    capacity_id: capacityId,
    name: passportName,
    blocks: passportBlocks.map((passportBlock, bIdx) => {
      return {
        header: passportBlock.header,
        order: bIdx,
        fields: passportBlock.fields.map((pField, fIdx) => {
          return {
            order: fIdx,
            type: pField.type,
            column_name: pField.name + (pField.unit ? ', ' + pField.unit : ''),
            value: pField.type === 'INTEGER' || pField.type === 'BIG_DECIMAL' ? parseFloat(pField.value) : pField.value
          };
        })
      };
    })
  };
  return apiService.fetchPost(apiConstants.CAPACITY_PASSPORT_CREATE, JSON.stringify(newPassport));
}

export function deleteCapacityPassport(passportId, passportType) {
  const user = loginService.getCurrentUser();
  const config = {
    method: 'DELETE',
    headers: {
      'Authorization': 'Bearer ' + user.sessionResp.authenticationToken,
      'user-agent-token': user.userAgentToken
    }
  };
  let requestURL = apiConstants.CAPACITY_PASSPORT_DELETE + passportId + '?type=' + passportType;
  return apiService.fetchURL(requestURL, config);
}

export function editCapacityPassport(passport) {
  return apiService.fetchPost(apiConstants.CAPACITY_PASSPORT_CREATE, JSON.stringify(passport));
}

export function getPassportList() {
  return apiService.fetchGet(apiConstants.GET_PASSPORT_LIST);
}

export function deletePassport(id) {
  return apiService.fetchGet(apiConstants.PASSPORT_DELETE, {id: id});
}

export function createDynamicCapacityPassport(requestBody) {
  return apiService.fetchPost(apiConstants.PASSPORT_ADD_DYNAMIC, JSON.stringify(requestBody));
}

export function deletePassportFile(fileId, blockId){
  const user = loginService.getCurrentUser();
  const config = {
    method: 'DELETE',
    headers: {
      'Authorization': 'Bearer ' + user.sessionResp.authenticationToken,
      'user-agent-token': user.userAgentToken
    }
  };
  let requestURL = apiConstants.PASSPORT_UPLOAD_FILE + '?block_id=' + blockId + '&files_id=' + fileId;
  return apiService.fetchURL(requestURL, config);
}

export function uploadPassportFile(file, blockId, columnName){

  const user = loginService.getCurrentUser();
  const formData = new FormData();
  formData.append('file', file);
  const config = {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + user.sessionResp.authenticationToken,
      'user-agent-token': user.userAgentToken
      // 'Content-Type': 'image/png'
    },
    body: formData
  };

  const url = apiConstants.PASSPORT_UPLOAD_FILE + '?block_id=' + blockId + '&column_name=' + columnName;
  return fetch(url, config)
    .then(response => {
      const jResponse = response.json();
      if (!response.ok) {
        return jResponse.then(err => Promise.reject(err.message));
      }
      return jResponse;
    });
}

export function appendFieldType(type) {
  if (type === 'MIXED_FIELD') {
    return 'STRING';
  } else if (type === 'INTEGER_FIELD') {
    return 'INTEGER';
  } else if (type === 'BOOLEAN_FIELD') {
    return 'STRING';
  } else if (type === 'FIXED_VALUES_FIELD') {
    return 'INTEGER';
  } else {
    return 'STRING';
  }
}

export function getVariablePassportList(variableId){
  return apiService.fetchGet(apiConstants.PASSPORT_LIST_BY_VARIABLE_ID, {variable_id: variableId});
}