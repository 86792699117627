import React from 'react';
import PropTypes from 'prop-types';
import {apiConstants, dashboardConstants} from '../../../constants';
import {localeService} from '../../../services/locale.service';
import Modal from '../../../components/Modal';
import PieChartView from './PieWidgetView';
import PieChartProps from './PieWidgetCreate';
// import PieWidgetPreview from './PieWidgetPreview';
import {withSize} from 'react-sizeme';

function PieWidget({widgetProps, mode, closeEditSingleMode, size}) {
    return (
        <div>
            {mode === dashboardConstants.VIEW_MODE &&
            <PieChartView widgetProps={widgetProps} size={size}/>
            }
            {mode === dashboardConstants.EDITION_MODE &&
                <div className={'widget-preview'} style={{width: size.width, height: (70 * widgetProps.h) - 30 }}>
                    {widgetProps.image && widgetProps.image.id && <img style={{maxWidth: '100%', maxHeight: '100%'}} src={apiConstants.API_URL + '/file/download?fileId=' + widgetProps.image.id}/>}
            {/*<PieWidgetPreview widgetProps={widgetProps} sizeParams={sizeParams} />*/}
                </div>
            }
            {mode === dashboardConstants.EDIT_SINGLE_MODE &&
            <Modal isOpen={mode === dashboardConstants.EDIT_SINGLE_MODE} isClosable={false} footer={false} onCancel={() => closeEditSingleMode()} title={localeService.isRussian() ? 'Настройка виджета' : 'Edit Widget'}>
                <PieChartProps widgetProps={widgetProps} onClose = {() => closeEditSingleMode()}/>
            </Modal>
            }
        </div>
    );
}

PieWidget.propTypes = {
    widgetProps: PropTypes.object,
    closeEditSingleMode: PropTypes.func,
    mode: PropTypes.oneOf([dashboardConstants.EDIT_SINGLE_MODE, dashboardConstants.EDITION_MODE, dashboardConstants.VIEW_MODE]),
    sizeParams: PropTypes.object,
    size: PropTypes.object
};

export default withSize()(PieWidget);