import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {updateWidget} from '../../../redux/actions/dashboardActions';
import {localeService} from '../../../services/locale.service';
import InputText from '../../../components/InputText';
import {dashboardConstants} from '../../../constants';
import EditWidgetDataSource from '../EditWidgetDataSource';

function ButtonWidgetCreate({widgetProps, updateWidget, dashboardDataSources}) {
    const {t} = useTranslation();
    const [widget, setWidget] = React.useState(initWidget(widgetProps));


    const createDataSource = () => {
        const dataSources = [...widget.dataSources, {
            idx: 'new', parameters: {
                btnName: localeService.isRussian() ? 'Вкл' : 'On',
                btnOnValue: 1
            }
        }];
        setWidget({...widget, dataSources: dataSources});
    };

    return <>
        <form>
            <InputText label={t('name')} value={widget.name}
                       onChange={(text) => setWidget({...widget, name: text})}/>

            <div className={'row'}>
                <p className={'help-text'}>
                    <b>{localeService.isRussian() ? 'Настройка источников данных' : 'Data Sources Configuration'}</b>
                </p>
            </div>

            {widget.dataSources.length > 0 &&

            <>{widget.dataSources.map((dataSource, key) => {
                return <EditWidgetDataSource key={key} dataSourceNumber={key + 1}
                                             withColor={false}
                                             withUnit={false}
                                             removable={false}
                                             widgetDataSource={dataSource}
                                             onChangeDataSource={(ds) => {
                                                 widget.dataSources[key] = ds;
                                                 setWidget(widget);
                                             }}
                                             onDeleteDataSource={(dsIndex) => {
                                                 setWidget({
                                                     ...widget,
                                                     dataSources: widget.dataSources.filter(el => el.idx !== dsIndex)
                                                 });
                                             }}
                />;
            })}


                <p className={'help-text'}>{localeService.isRussian() ? 'Дополнительные параметры' : 'Additional options'}</p>

                <InputText
                    label={localeService.isRussian() ? 'Имя нажатой кнопки' : 'Button On name'}
                    onChange={(text) => setWidget({...widget, parameters: {...widget.parameters, btnName: text}})}
                    value={widget.parameters.btnName}/>
                <InputText
                    isDecimal={true}
                    label={localeService.isRussian() ? 'Значение нажатой кнопки' : 'Button On Value'}
                    onChange={(text) => setWidget({...widget, parameters: {...widget.parameters, btnValue: text}})}
                    value={widget.parameters.btnValue}/>
                <InputText
                    isInteger={true}
                    onChange={(text) => setWidget({...widget, parameters: {...widget.parameters, timeOffset: text}})}
                    label={localeService.isRussian() ? 'Смещение времени (часов)' : 'Time Offset (hours)'}
                    value={widget.parameters.timeOffset}/>

            </>
            }

            <div className={'d-flex justify-content-center'}>
                {dashboardDataSources.length > 0 && widget.dataSources.length === 0 &&
                <button type={'button'} className={'btn eco-btn default'}
                        onClick={() => createDataSource()}>
                    {localeService.isRussian() ? 'Добавить источник данных' : 'Add Data Source'}
                </button>
                }
                <button type={'button'} className={'btn eco-btn success'}
                        onClick={() => {
                            updateWidget({...widget, mode: dashboardConstants.EDITION_MODE});
                        }}>
                    {localeService.isRussian() ? 'Сохранить' : 'Save'}
                </button>
                <button type={'button'} className={'btn eco-btn danger'}
                        onClick={() => {
                            updateWidget({...widgetProps, mode: dashboardConstants.EDITION_MODE});
                        }}>
                    {t('cancel')}
                </button>
            </div>
        </form>
    </>;
}

function initWidget(widget) {
    const initWidget = widget;
    if (!initWidget.parameters) {
        initWidget.parameters = {};
    }
    if (!initWidget.parameters.btnName) {
        initWidget.parameters.btnName = localeService.isRussian() ? 'Вкл' : 'On';
    }
    if (!initWidget.parameters.btnValue) {
        initWidget.parameters.btnValue = 1;
    }
    if (!initWidget.parameters.timeOffset) {
        initWidget.parameters.timeOffset = 0;
    }
    return initWidget;
}

ButtonWidgetCreate.propTypes = {
    widgetProps: PropTypes.object,
    updateWidget: PropTypes.func,
    dashboardDataSources: PropTypes.array
};

const mapStateToProps = state => {
    const dashboardDataSources = state.dashboardReducer.dataSourceList;
    return {dashboardDataSources};
};

const mapDispatchToProps = {
    updateWidget: updateWidget
};
export default connect(mapStateToProps, mapDispatchToProps)(ButtonWidgetCreate);