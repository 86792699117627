import React from 'react';
import Modal from './Modal';
import {localeService} from '../services';
import {ReactSVG} from 'react-svg';
import svgPassport from '../sources/images/interface/device_passport.svg';
import {capacityService} from '../services/capacity.service';
import SelectCapacityTree from './SelectCapacityTree';
import Select from './Select';
import PropTypes from 'prop-types';

function SetValueFromPassport({limit, onChange}) {
  const [isOpened, setOpened] = React.useState(false);
  const [capacityTree, setCapacityTree] = React.useState([]);
  const [selectedCapacity, setSelectedCapacity] = React.useState(null);
  const [selectedPassport, setSelectedPassport] = React.useState(null);
  const [passportFields, setPassportFields] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState(null);

  React.useEffect(() => {
    if (isOpened) {
      capacityService.getCapacityTree()
        .then((response) => {
          setCapacityTree(response.list);
        });
    }
  }, [isOpened]);

  React.useEffect(() => {
    if(limit.props && limit.props.capacityId && !selectedCapacity){
      capacityService.getCapacity(limit.props.capacityId).then(
        capacity => {
          setSelectedCapacity(capacity);

          if(limit.props && limit.props.passportId && !selectedPassport && capacity.passportDynamic){
            let passport = capacity.passportDynamic.find(el => el.id ===limit.props.passportId);
            if(passport){
              setSelectedPassport(passport);
              let fields = [];
              passport.blocks.map(block => {
                block.fields.map(field => {
                  if (field.type === 'BIG_DECIMAL' || field.type === 'INTEGER') {
                    fields.push({
                      fieldId: field.id,
                      passportId: passport.id,
                      capacityId: capacity.id,
                      blockId: block.id,
                      name: field.value + '   (' + field.column_name + ')',
                      value: field.value
                    });
                  }
                });
              });
              setPassportFields(fields);


              let bIdx = passport.blocks.findIndex(el => el.id === limit.props.blockId);
              if (bIdx >= 0) {
                let block = passport.blocks[bIdx];
                let fIdx = block.fields.findIndex(el => el.id === limit.props.fieldId);
                let field = block.fields[fIdx];
                if (field && field.value) {
                  setSelectedValue({...limit.props, value: field.value, name: field.value + '   (' + field.column_name + ')'});
                }
              }
            }
          }
        }
      );
    }
  }, [limit]);

  const selectCapacity = (capacity) => {
    capacityService.getCapacity(capacity.id).then(
      response => {
        setSelectedCapacity(response);
        setSelectedPassport(null);
        setSelectedValue(null);
      }
    );
  };

  return (
    <>
      <div className={'d-flex align-items-center  mb-3'}>
        <button className={'btn eco-btn dark me-5'}
                onClick={(e) => {
                  e.preventDefault();
                  setOpened(true);
                }} title={localeService.isRussian() ? 'Задать значение из паспорта' : 'Set value from passport'}>
          <ReactSVG src={svgPassport}/>
          <span>{localeService.isRussian() ? 'Выберите паспорт' : 'Select passport'}</span>
        </button>
        {selectedValue && <b>{selectedValue.name}</b>}
      </div>

      <Modal isClosable={true} onCancel={() => setOpened(false)} isOpen={isOpened} width={'600px'}
             onSubmit={() => {
               if(selectedValue !== null){
                 onChange(selectedValue);
               }
               setOpened(false);
             }}
             title={localeService.isRussian() ? 'Выбрать значение из паспорта' : 'Set Value from Passport'}>
        <form>
          {capacityTree &&
          <div className="mb-3 input-group">
            <div className="input-group-text">
              <label>{localeService.isRussian() ? 'Выберите источник' : 'Select Capacity'}</label></div>
            <SelectCapacityTree capacityTree={capacityTree} onSelect={el => selectCapacity(el)}
                                capacity={selectedCapacity}/>
          </div>}
          {selectedCapacity && selectedCapacity.passportDynamic &&
          <Select label={localeService.isRussian() ? 'Выберите пасспорт' : 'Select Passport'}
                  valueList={selectedCapacity.passportDynamic} value={selectedPassport}
                  onSelect={el => {
                    setSelectedPassport(el);
                    let fields = [];
                    el.blocks.map(block => {
                      block.fields.map(field => {
                        if (field.type === 'BIG_DECIMAL' || field.type === 'INTEGER') {
                          fields.push({
                            fieldId: field.id,
                            passportId: el.id,
                            capacityId: selectedCapacity.id,
                            blockId: block.id,
                            name: field.value + '   (' + field.column_name + ')',
                            value: field.value
                          });
                        }
                      });
                    });
                    setPassportFields(fields);
                  }}/>
          }
          {selectedCapacity && !selectedCapacity.passportDynamic &&
          <p>{localeService.isRussian() ? 'У выбранного источника не найден ни один паспорт' : 'There are no Passport into selected Source'}</p>
          }
          {selectedPassport && passportFields.length === 0 &&
          <p>{localeService.isRussian() ? 'У выбранного паспорта нет значений' : 'There are no values into selected passport'}</p>
          }
          {selectedPassport && passportFields.length > 0 &&
          <Select label={localeService.isRussian() ? 'Выберите поле' : 'Select Passport Field'}
                  valueList={passportFields} value={selectedValue}
                  onSelect={el => {
                    setSelectedValue(el);
                  }}/>
          }
        </form>
      </Modal>
    </>
  );
}

SetValueFromPassport.propTypes = {
  onChange: PropTypes.func,
  limit: PropTypes.object
};


export default SetValueFromPassport;