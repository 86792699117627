/*eslint-disable react/prop-types*/
/* eslint-disable no-unused-vars */
import '../../styles/capacity.scss';
import '../../styles/capacity-view.scss';
import React, {useEffect, useMemo, useState} from 'react';
import Accordion from './components/Accordion';
import CapacityTreeView from './components/CapacityTreeView';
import Loader from '../../components/Loader';
import Modal from './../../components/Modal';
import PropTypes from 'prop-types';
import {ReactSVG} from 'react-svg';
import {capacityService} from '../../services';
import defaultImage from '../../sources/images/img_banner.svg';
import {localeService} from '../../services/locale.service';
import svgAdd from '../../sources/images/interface/add.svg';
import svgBlocks from '../../sources/images/interface/dot-matrix.svg';
import svgEdit from '../../sources/images/interface/edit.svg';
import svgTrash from '../../sources/images/trash.svg';
import svgTree from '../../sources/images/interface/bullets.svg';
import {useTranslation} from 'react-i18next';
import CapacityForm from './components/CapacityForm';
import {apiConstants} from '../../constants';
import CapacityPassportList from './components/Passport/CapacityPassportList';
import CapacitySearch from './components/CapacitySearch';
import {canDo} from '../../services/permitions.service';
// import ListView from 'components/ListView';
// import svgCode from 'sources/images/process.svg';


/**
 * Представление списка переменных ввиде представления
 * @param {*} param0
 */
const CapacityListView = ({
                            list,
                            setList,
                            activeCapacity,
                            setActiveCapacity,
                            onDeleteCapacity,
                            onCreateCapacity,
                          }) => {
  return (
    <div className={'capacity-grid '}>
      <React.Fragment>
        {list &&
        list.length > 0 &&
        list.map((subCapacity) => (
          <CapacityInnerView
            innerCapacity={subCapacity}
            onDeleteCapacity={onDeleteCapacity}
            active={activeCapacity === subCapacity.id}
            setActive={setActiveCapacity}
            setList={setList}
            key={subCapacity.id}
          />
        ))}
        <div
          className="capacity-item  capacity-folder"
          onClick={() => onCreateCapacity()}
        >
          <ReactSVG className="image add-item" src={svgAdd}/>
        </div>
      </React.Fragment>
    </div>
  );
};
/**
 * Внутреннее представление нагрузки
 */
const CapacityInnerView = ({
                             innerCapacity,
                             setList,
                             active,
                             setActive,
                             onDeleteCapacity,
                           }) => {
  const hasChildren =
    innerCapacity.child !== undefined && innerCapacity.child.length > 0;
  const {fileId} = innerCapacity;
  const activateItem = () => {
    setActive(innerCapacity);
  };
  const moveInside = () => {
    if (hasChildren) {
      setList(innerCapacity);
    }
  };

  const style =
    innerCapacity.status === 'SUCCESS' ? 'status-active' : 'status-disactive';
  return (
    <React.Fragment>
      <div
        className={`capacity-item ${hasChildren ? 'capacity-folder ' : ''} ${active ? 'capacity-item-selected' : ''
          }`}
        onClick={() => activateItem()}
        onDoubleClick={() => moveInside()}
      >
        {/*{!hasChildren && (*/}
        {/*<div className="status-view">*/}
        {/*<div className={`status ${style}`}/>*/}
        {/*<ReactSVG*/}
        {/*className="status__image"*/}
        {/*onClick={() => onDeleteCapacity(innerCapacity)}*/}
        {/*src={svgTrash}*/}
        {/*alt=""*/}
        {/*/>*/}
        {/*</div>*/}
        {/*)}*/}

        <div className={'image'}>
          {!fileId && <img src={defaultImage} alt=""/>}
          {/*{!fileId &&*/}
          {/*<ReactSVG*/}
          {/*    title={''}*/}
          {/*    src={'/src/sources/images/loads/' + (innerCapacity.image !== undefined ? innerCapacity.image : 'diode.svg')}/>}*/}
          {innerCapacity.img && (
            <img
              src={innerCapacity.img.type + ',' + innerCapacity.img.content}
              alt={'Capacity Icon'}
            />
          )}
          {fileId && (
            <img
              alt={'Capacity Icon'}
              src={apiConstants.API_URL + '/file/download?fileId=' + fileId}
            />
          )}
        </div>
        <label>{innerCapacity.name}</label>
      </div>
    </React.Fragment>
  );
};

/**
 * Хлебные крошки
 * @constructor
 */
const BreadCumbView = ({capacityRoutes, selectRoute}) =>
  capacityRoutes && (
    <React.Fragment>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          {capacityRoutes.length > 0 &&
          capacityRoutes.map((cap, i) => {
            return (
              <li className="breadcrumb-item" key={'li' + i} onClick={() => selectRoute(cap)}>
                {i === 0 ? <h2>{cap.name}</h2> : cap.name}
              </li>
            );
          })}
        </ol>
        {/*<ol className="breadcrumb">*/}
        {/*<li className="breadcrumb-item"><a href="#">Home</a></li>*/}
        {/*<li className="breadcrumb-item"><a href="#">Library</a></li>*/}
        {/*<li className="breadcrumb-item active" aria-current="page">Data</li>*/}
        {/*</ol>*/}
      </nav>
    </React.Fragment>
  );

function CapacityList() {
  const {t} = useTranslation();

  const [size, changeSize] = React.useState(true);

  const [viewMode, setViewMode] = useState('blocks');
  // eslint-disable-next-line no-unused-vars

  const [deleteModal, setDeleteModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [capacityTree, setCapacityTree] = useState([]);
  const [isFetchingCapacityList, setIsFetchingCapacityList] = useState(false);
  const [selectedCapacity, setSelectedCapacity] = useState(undefined);

  const [capacityList, setList] = React.useState(capacityTree);
  const [routes, addRoute] = React.useState([]);

  /**
   * Обновление нагрузки после действий
   */
  const updateCapacity = (capacity) => {
    //setSelectedCapacity(capacity);
    capacityService.getCapacity(capacity.id).then((loadedCapacity) => {
      setSelectedCapacity(loadedCapacity);
    });
  };

  /**
   *
   * @param {{capacity:Object,parent:Object}} childrenNode
   */
  const findPath = (childrenNode) => {

    if (!childrenNode.parent)
      return [childrenNode.capacity];

    return [childrenNode.capacity, ...findPath(childrenNode.parent)];
  };
  const findNode = (id) => {
    if (!id) {
      return null;
    }
    let data = null;
    const localFind = (prev, current) => {

      if (current.id === id) {

        data = prev;
      } else {
        if (current.isGroup && current.child) {
          current.child.forEach(child => localFind(current, child));
        }

      }
    };
    capacityTree.forEach(child => {
      localFind(null, child);
    });
    return data;
  };
  const parentCapacity = useMemo(() =>
      findNode(selectedCapacity && selectedCapacity.id)
    , [selectedCapacity, capacityTree.length]);


  /**
   * Обработка нажатия при выборе в хлебных крошках
   * @param capacity
   */
  const selectRoute = (capacity) => {

    const index = routes.findIndex((item) => item.id === capacity.id);
    if (index !== -1) {
      if (index === 0) {
        addRoute([...routes.slice(0, 1)]);
      } else {
        if (routes.length - 1 !== index) {
          addRoute([...routes.slice(0, index + 1)]);
        }
      }
    } else {
      addRoute([...routes, capacity]);
    }

    setList(capacity.child);
  };
  /**
   * Уставновление списка дочерних элементов нагрузки
   * @param {Capacity} capacity
   */
  const setListData = (capacity) => {
    if (capacity.child !== undefined) {
      addRoute([...routes, capacity]);
      setList(capacity.child);
    }
  };
  /**
   * Выбор нагрузки
   * @param {Capacity} capacity
   */
  const selectCapacityItem = (capacity) => {
    selectCapacity(capacity);
    changeSize(true);
  };
  /**
   * Перезагрузка дерева нагрузок
   */
  const reloadCapacityTree = () => {
    setIsFetchingCapacityList(true);
    capacityService.getCapacityTree().then((response) => {
      setIsFetchingCapacityList(false);
      setCapacityTree(response.list);

      if (routes.length > 1) {
        const last = routes[routes.length - 1];
        capacityService.getCapacityChildren(last.id).then(res => {

          setList(res.list);
        }).catch(() => {
          addRoute([
            {
              name: t('capacity_list'),
              child: response.list,
            },
          ]);
          setList(response.list);
        });
      } else {
        addRoute([
          {
            name: t('capacity_list'),
            child: response.list,
          },
        ]);
        setList(response.list);
      }

    });
  };
  useEffect(() => {
    reloadCapacityTree();
  }, []);

  const selectCapacity = (capacity) => {
    setSelectedCapacity(capacity);
    // capacityService.getCapacity(capacity.id).then(
    //     loadedCapacity => {
    //         setSelectedCapacity(loadedCapacity);
    //     }
    // );
  };
  /**
   * Проверка есть ли у паспорта объектов доступные нагрузки
   * @param {Capacity} capacity Нагрузка
   */
  const checkPassportArray = (capacity) => {
    const {passportStorage, passportDelivery, passportConnect} = capacity;
    const arr = [passportStorage, passportDelivery, passportConnect];
    return arr.filter((item) => item !== undefined);
  };
  /**
   * Удаление выбранной нагрузки
   */
  const deleteCapacity = () => {
    const elem = checkPassportArray(selectedCapacity);
    if (elem.length > 0) {
      alert('Вы не можете удалить источник у которого есть паспорта');
    } else
      capacityService
        .deleteLoad(selectedCapacity.id)
        .then(() => {
          reloadCapacityTree();
          setSelectedCapacity(undefined);
        })
        .then(() => setDeleteModal(false));
  };

  return (
    <>
      {!canDo('ROLE_LIST_CAPACITY') &&
      <div className={'page-header pt-5 mb-5'}>
        <div className={'container-700'}>
          <h3>{localeService.isRussian() ? 'У Вас недостаточно прав для просмотра списка источников!' : 'You have no access to view capacity list!'}</h3>
        </div>
      </div>
      }
      {canDo('ROLE_LIST_CAPACITY') && <div className={'divided-page page-8-3'}>
        <div className={'divided-page-header'}>
          <div className={'main-content'}>
            <div className={'d-flex justify-content-between align-items-start mt-4'}>
              <div className={'d-flex'}>
                <BreadCumbView
                  capacityRoutes={routes}
                  selectRoute={selectRoute}
                />
              </div>
              <div className={'d-flex'}>
                <button
                  type={'button'}
                  className={
                    'btn svg-btn ' +
                    (viewMode === 'blocks' ? 'active' : '')
                  }
                  onClick={() => setViewMode('blocks')}
                  title={
                    localeService.isRussian()
                      ? 'Режим просмотра: плитка'
                      : 'Swith view mode to Blocks'
                  }
                >
                  <ReactSVG src={svgBlocks}/>
                </button>
                <button
                  type={'button'}
                  className={
                    'btn svg-btn ms-3 ' +
                    (viewMode === 'tree' ? 'active' : '')
                  }
                  onClick={() => setViewMode('tree')}
                  title={
                    localeService.isRussian()
                      ? 'Режим просмотра: дерево'
                      : 'Swith view mode to Tree'
                  }
                >
                  <ReactSVG src={svgTree}/>
                </button>
              </div>
            </div>
            <div className={'d-flex justify-content-between'}>
              <CapacitySearch capacityTree={capacityTree} onSelect={(c) => {
                if (c.parent) {
                  setListData(c.parent.capacity);
                  const arr = [...findPath(c), {
                    name: localeService.isRussian() ? 'Корень' : 'Root',
                    child: capacityTree,
                  }].reverse();
                  arr.pop();
                  addRoute(arr);

                } else
                  setListData([]);


                selectCapacityItem(c.capacity);
              }}/>
              {canDo('ROLE_CREATE_CAPACITY') && <button className={'btn eco-btn outlined mb-3'}
                      onClick={() => setCreateModal(true)}>{localeService.isRussian() ? 'Создать источник' : 'Create Source'}</button>}
            </div>
          </div>
          <div className={'info-content'}>
            <header className="d-flex justify-content-between mt-4">
              {selectedCapacity && (
                <div>
                  <label>{t('selected_capacity')}</label>
                  <br/>
                  <h3> {selectedCapacity.name} </h3>
                </div>
              )}
              {selectedCapacity && (
                <React.Fragment>
                  <div className="capacity-detail__header__actions">
                    {canDo('ROLE_CREATE_CAPACITY') && <button
                      className={'btn svg-btn me-3'}
                      onClick={() => setEditModal(true)}
                      title={t('edit')}
                    >
                      <ReactSVG src={svgEdit}/>
                    </button>}
                    {canDo('ROLE_DELETE_CAPACITY') && <button
                      className={'btn svg-btn'}
                      onClick={() => setDeleteModal(true)}
                      title={t('delete')}
                    >
                      <ReactSVG src={svgTrash}/>
                    </button>}
                  </div>
                </React.Fragment>
              )}
            </header>
          </div>
        </div>
        <div className={'divided-page-body'}>
          <div className={'main-content'}>
            {isFetchingCapacityList && <Loader waitText={t('wait')}/>}
            {viewMode == 'blocks' ? (
              <CapacityListView
                activeCapacity={selectedCapacity && selectedCapacity.id}
                setActiveCapacity={selectCapacityItem}
                list={capacityList}
                setList={setListData}
                onDeleteCapacity={(capacity) => {
                  setSelectedCapacity(capacity);
                  setDeleteModal(true);
                }}
                onCreateCapacity={() => setCreateModal(true)}
                size={size}
              />
            ) : (
              <CapacityTreeView
                treeRoute={routes}
                capacityTree={capacityTree}
                selectedCapacity={selectedCapacity}
                onSelect={(cap) => {
                  selectCapacityItem(cap);
                  setListData(cap);
                }}
              />
            )}
          </div>
          <div className={'info-content'}>
            {selectedCapacity && (
              <div className="capacity-detail__content">
                <Accordion
                  label={localeService.isRussian() ? 'Паспорта' : 'Passports'}
                >
                  {selectedCapacity &&
                  <CapacityPassportList capacity={selectedCapacity}/>}
                </Accordion>
                {/*<Accordion*/}
                {/*label={*/}
                {/*localeService.isRussian()*/}
                {/*? 'Сценарии управления'*/}
                {/*: 'Scenarios'*/}
                {/*}*/}
                {/*>*/}
                {/*{selectedCapacity && (*/}
                {/*<ScenarioList capacity={selectedCapacity}/>*/}
                {/*)}*/}
                {/*</Accordion>*/}
                {/*<Accordion*/}
                {/*label={*/}
                {/*localeService.isRussian()*/}
                {/*? 'Дашборды'*/}
                {/*: 'Dashboards'*/}
                {/*}*/}
                {/*>*/}
                {/*{selectedCapacity && (*/}
                {/*<section className="passport-list">*/}
                {/*{selectedCapacity && <Loader waitText={t('wait')} />}*/}
                {/*<div className="mr1 ml1">*/}
                {/*<ListView*/}
                {/*img={svgCode}*/}
                {/*renderName={(source) => source.name}*/}
                {/*viewAllText={localeService.isRussian() ? 'Cмотреть все' : 'View all'}*/}
                {/*/>*/}
                {/*</div>*/}
                {/*</section>*/}
                {/*)}*/}
                {/*</Accordion>*/}
              </div>
            )}
          </div>
        </div>
      </div>}
      <React.Fragment>
        <Modal
          title={'Вы точно хотите удалить источник'}
          isOpen={deleteModal}
          footer={false}
          onCancel={() => {
            setDeleteModal(false);
          }}
        >
          <button
            className="btn eco-btn default  active "
            onClick={() => deleteCapacity()}
          >
            {localeService.isRussian() ? 'Удалить источник' : 'Delete Source'}
          </button>
          &nbsp; &nbsp; &nbsp; &nbsp;
          <button
            className="btn eco-btn default  active "
            onClick={() => setDeleteModal(false)}
          >
            {localeService.isRussian() ? 'Отмена' : 'Cancel'}
          </button>
        </Modal>
        <Modal
          isOpen={createModal}
          footer={false}
          onCancel={() => setCreateModal(false)}
          isClosable={true}
          width={600}
        >
          <CapacityForm
            titleText={localeService.isRussian() ? 'Создать источник' : 'Create Source'}
            capacityTree={capacityTree}
            selectedCapacity={null}
            parentCapacity={selectedCapacity}
            buttonText={'create'}
            onCancel={(result) => {
              setCreateModal(false);
              if (result && result.needToReload) {
                reloadCapacityTree();
              }
            }}
          />
        </Modal>
        <Modal
          isOpen={editModal}
          footer={false}
          onCancel={() => {
            setEditModal(false);
          }}
        >
          <CapacityForm
            titleText={localeService.isRussian() ? 'Редактирование источника' : 'Edit Source'}
            capacityTree={capacityTree}
            parentCapacity={parentCapacity}
            selectedCapacity={selectedCapacity}
            buttonText={'apply'}

            onCancel={(result) => {
              setEditModal(false);
              if (result && result.needToReload) {
                updateCapacity(result);
                reloadCapacityTree();
              }

            }}
          />
        </Modal>
      </React.Fragment>
    </>
  );
}

CapacityInnerView.propTypes = {
  innerCapacity: PropTypes.object,
  active: PropTypes.bool,
  setList: PropTypes.func,
  setActive: PropTypes.func,
  onDeleteCapacity: PropTypes.func,
};

CapacityListView.propTypes = {
  list: PropTypes.array,
  setList: PropTypes.func,
  activeCapacity: PropTypes.number,
  setActiveCapacity: PropTypes.func,
  size: PropTypes.bool,
  onDeleteCapacity: PropTypes.func,
  onCreateCapacity: PropTypes.func,
};

export default CapacityList;
