
function setLanguage(language) {
    localStorage.setItem('eco-language', language);
    // history.go(0);
}

function isRussian() {
    if(localStorage.getItem('eco-language') === 'ru') {
        return true;
    } else {
        return false;
    }
}

export const localeService = {
    setLanguage,
    isRussian
};

