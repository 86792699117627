import '../../../styles/accordion.scss';

import PropTypes from 'prop-types';
import React from 'react';

export default function Accordion({ children, label }) {
  const [active, setActive] = React.useState(false);
  return (
    <div className="accordion">
      <header className="accordion-header">
        <button
          className={'btn btn-sm arrow-btn me-2 ' + (active ? 'to-down' : 'to-right')}
          onClick={() => setActive(!active)}
        ></button>
          <button className={'btn btn-link'} onClick={() => setActive(!active)}><h4> {label} </h4></button>
      </header>
      {active && <div className="accordion-content">{children}</div>}
      <hr className={'light'}/>
    </div>
  );
}

Accordion.propTypes = {
  children: PropTypes.object,
  label: PropTypes.string,
};
