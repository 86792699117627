import React from 'react';
import {localeService} from '../services/locale.service';
import InputText from '../components/InputText';
import {useTranslation} from 'react-i18next';
import AlertError from '../components/AlertError';
import {apiService} from '../services/api.service';
import {apiConstants} from '../constants';
import AlertSuccess from '../components/AlertSuccess';
import {ReactSVG} from 'react-svg';
import logoSvg from '../sources/images/eco_logo.svg';
import {Link} from 'react-router-dom';

function OrganizationCreate() {
  const {t} = useTranslation();
  const [error, setError] = React.useState('');
  const [success, setSuccess] = React.useState('');
  const [newUser, setNewUser] = React.useState({
    email: '',
    firstName: '',
    middleName: '',
    lastName: '',
    company: ''
  });

  const onRegister = () => {
    if (newUser.email !== '' && newUser.firstName !== '' && newUser.lastName !== '' && newUser.companyName !== '') {
      const config = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Origin': apiConstants.API_URL
        },
        body: JSON.stringify(newUser)
      };
      apiService.fetchURL(apiConstants.REGISTER_NEW_ORGANIZATION_ADMINISTRATOR, config).then(
        () => {
          setSuccess(localeService.isRussian() ? 'Чтобы продолжить регистрацию, проверьте вашу электронную почту и подтвердите email. ' : 'To continue registering, check your email. ');
        }, (error) => {
          if (error.errorCode === 'ALREDY_EXIST') {
            setError(localeService.isRussian() ? 'Введенный email уже зарегистрирован в нашей системе!' : 'Entered email is already exist in the System!');
          } else {
            setError(localeService.isRussian() ? 'Невозможно зарегистрировать нового пользователя!' : 'Cannot register new user!');
          }
        }
      );
    } else {
      setError(localeService.isRussian() ? 'Пожалйуста, заполните все обязательные поля!' : 'Please fill out all required fields!');
    }
  };

  return <>
    <div className={'login-page'}>
      <div className="login-container">
        <div className={'header'}>
        </div>
        <div className={'body'}>
          <div className={'login-content'} style={{minWidth: 700}}>
            <div className={'logo-header'}>
              <div className={'logo'}>
                <ReactSVG src={logoSvg}/>
                <label>DIGITAL<br/>MONITORING</label>
              </div>
              <h4 style={{
                color: 'white',
                marginTop: '-1rem',
                textAlign: 'right',
                maxWidth: '400px'
              }}>{localeService.isRussian() ? 'Регистрация администратора новой компании' : 'Administrator Registration'}</h4>
            </div>

            <form className={'mt-5'}>
              {/*<div className={'row'}>*/}
              {/*<div className={'col-30'}>*/}
              {/*<div>*/}
              {/*<ReactFileReader*/}
              {/*fileTypes={['.png', '.jpg', '.gif']}*/}
              {/*base64={false}*/}
              {/*multipleFiles={false}*/}
              {/*handleFiles={(file) => handleFiles(file)}*/}
              {/*disabled={false}*/}
              {/*>*/}
              {/*{company.logo &&*/}
              {/*<img src={company.logo.type + ',' + company.logo.file} alt={'Capacity Icon'}*/}
              {/*style={{*/}
              {/*maxWidth: '100%',*/}
              {/*height: 'auto',*/}
              {/*cursor: 'pointer',*/}
              {/*border: '1px solid grey',*/}
              {/*borderRadius: '0.5rem'*/}
              {/*}}/>}*/}
              {/*</ReactFileReader>*/}
              {/*</div>*/}
              {/*</div>*/}
              {/*<div className={'col-70'}>*/}
              {/*<InputText label={localeService.isRussian() ? 'Название компании' : 'Company Name'}*/}
              {/*value={company.name}*/}
              {/*notInline={true}*/}
              {/*onChange={text => setCompany({...company, name: text})}/>*/}
              {/*</div>*/}
              {/*</div>*/}
              {/*<h5 style={{*/}
              {/*marginTop: '-2rem',*/}
              {/*textAlign: 'right',*/}
              {/*letterSpacing: '0.2rem'*/}
              {/*}}>{localeService.isRussian() ? 'Данные администратора' : 'Owner information'}</h5>*/}
              {/*<hr/>*/}
              <InputText label={localeService.isRussian() ? 'Название компании' : 'Company Name'}
                         value={newUser.companyName}
                         isRequired={true}
                         onChange={text => setNewUser({...newUser, companyName: text})}/>
              <InputText label={'email'}
                         value={newUser.email}
                         isRequired={true}
                         onChange={text => setNewUser({...newUser, email: text})}/>
              <InputText label={t('last_name')}
                         value={newUser.lastName}
                         isRequired={true}
                         onChange={text => setNewUser({...newUser, lastName: text})}/>
              <InputText label={t('first_name')}
                         value={newUser.firstName}
                         isRequired={true}
                         onChange={text => setNewUser({...newUser, firstName: text})}/>
              <InputText label={t('middle_name')}
                         value={newUser.middleName}
                         onChange={text => setNewUser({...newUser, middleName: text})}/>

              <div className={'d-flex justify-content-between mt-5'}>
                <Link to={'/login'} className="card-link">
                  <span className={'link'}>{localeService.isRussian() ? 'Назад к авторизации' : 'Back to authorization'}</span>
                </Link>
                <button className={'btn eco-btn success'} onClick={(e) => {
                  e.preventDefault();
                  onRegister();
                }}>
                  {localeService.isRussian() ? 'Зарегистрироваться' : 'Register'}
                </button>
              </div>
              <AlertError isOpen={error !== ''} message={error} onCancel={() => setError('')}/>
              <AlertSuccess isOpen={success !== ''} message={success} isClosable={false}/>

            </form>
          </div>
        </div>
      </div>
    </div>
  </>;
}

export default OrganizationCreate;