import React from 'react';
import PropTypes from 'prop-types';
import {ReactSVG} from 'react-svg';
import svgDelete from '../sources/images/interface/basket.svg';
import {localeService} from '../services/locale.service';
import svgShow from '../sources/images/interface/show.svg';
import svgHide from '../sources/images/interface/hide.svg';
import {useTranslation} from 'react-i18next';

function CustomLegend({widgetDataSources, functionDataSources, onDeleteFunction, onHideDataSources}) {
  const [isOpen, setOpen] = React.useState(false);
  const {t} = useTranslation();

  return (
    <div className={'legend-container' + (isOpen ? ' is-open' : '')}>
      {!isOpen && <div className={'d-flex justify-content-start'}>
        <button className={'btn-link'}
              onClick={(e) => {
                e.preventDefault();
                setOpen(true);
              }}>{localeService.isRussian() ? 'Показать легенду' : 'Show Legend'}</button>
      </div>}
      {isOpen && <div className={'d-flex justify-content-between'}>
        <small className={'ms-3 mt-3'}>{localeService.isRussian() ? 'Источники данных' : 'Data Sources'}</small>
        <button type='button' onClick={() => setOpen(false)} style={{zIndex: 100}} className={'btn close-btn'}>&times;</button>
      </div>}
      <ul className={'legend'}>
        {widgetDataSources.map((dataSource, i) => {
          return <li key={'ds-legend-' + i} style={{color: dataSource.parameters.color}}>
            <span className="li-text">{dataSource.parameters.dataSourceName}</span>
            <button className={'btn hide-btn'} title={dataSource.parameters.isHidden ? t('show') : t('hide')} onClick={(e) => {
              e.preventDefault();
              if(!dataSource.parameters.isHidden){
                dataSource.parameters.isHidden = true;
              }else{
                dataSource.parameters.isHidden = false;
              }

              onHideDataSources(widgetDataSources);
            }}>
              <ReactSVG src={!dataSource.parameters.isHidden ? svgShow : svgHide}/>
            </button>
          </li>;
        })}
        {functionDataSources.map((functionDataSource, i) => {
          const dsParams = functionDataSource.parameters;
          return <li key={'ds-legend-' + i} className={'removable'} style={{color: dsParams.color}}>
            <span className="li-text">{dsParams.dataSourceName}</span>
            <button className={'btn svg-btn small'}
                    title={localeService.isRussian() ? 'Отменить функцию' : 'Delete Function Data Source'}
                    onClick={(e) => {
                      e.preventDefault();
                      onDeleteFunction(functionDataSource);
                    }}>
              <ReactSVG src={svgDelete}/>
            </button>
          </li>;
        })}
      </ul>
    </div>
  );
}

CustomLegend.propTypes = {
  widgetDataSources: PropTypes.array,
  functionDataSources: PropTypes.array,
  onDeleteFunction: PropTypes.func,
  onHideDataSources: PropTypes.func
};

export default CustomLegend;