import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setDataSourceList } from '../../redux/actions/dashboardActions';
import TreeView from '../../components/TreeView';
import { capacityService } from '../../services/capacity.service';
import SelectDataSourcesFromCapacityTree from '../../components/SelectDataSourcesFromCapacityTree';

function SelectDashboardDatasources({ dataSourceList, setDataSourceList, isDropDown, newStyle}) {
    const [capacityTree, setCapacityTree] = useState([]);
    useEffect(() => {
        capacityService.getCapacityTree()
            .then((response) => {
                setCapacityTree(response.list);
            });
    }, []);

    return (
        <>
            {capacityTree.length > 0 && isDropDown && !newStyle &&
                <TreeView capacityTree={capacityTree} onSelectDevice={(dataSourceList) => setDataSourceList(dataSourceList)} />
            }
            {capacityTree.length > 0 && !isDropDown && !newStyle &&
                <SelectDataSourcesFromCapacityTree dataSourceList={dataSourceList} capacityTree={capacityTree} onSelectDevice={(dataSourceList) => setDataSourceList(dataSourceList)} />
            }
            {capacityTree.length > 0 && newStyle &&
                <SelectDataSourcesFromCapacityTree dataSourceList={dataSourceList} capacityTree={capacityTree} newStyle={true} onSelectDevice={(dataSourceList) => setDataSourceList(dataSourceList)} />
            }
        </>
    );
}

SelectDashboardDatasources.propTypes = {
    dataSourceList: PropTypes.array,
    setDataSourceList: PropTypes.func,
    isDropDown: PropTypes.bool,
    newStyle: PropTypes.bool
};

SelectDashboardDatasources.defaultProps = {
    isDropDown: true
};

const mapStateToProps = state => {
    const dataSourceList = state.dashboardReducer.dataSourceList;
    return { dataSourceList };
};
const mapDispatchToProps = {
    setDataSourceList: setDataSourceList
};
export default connect(mapStateToProps, mapDispatchToProps)(SelectDashboardDatasources);